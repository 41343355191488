import { Pipe, PipeTransform } from '@angular/core';
import { DokumentEintragModel } from '@core/models/dokument-eintrag.model';

@Pipe({
  name: 'prozesseFilterPipe',
  pure: false,
})
export class ProzesseFilterPipe implements PipeTransform {
  transform(prozesseEintragList: DokumentEintragModel[], prozesseEintragFilter: string) {
    const filteredProzesseEintragList: DokumentEintragModel[] = [];
    prozesseEintragList.forEach((value) => {
      if (
        !prozesseEintragFilter ||
        value.name.toLocaleLowerCase().includes(String(prozesseEintragFilter).toLocaleLowerCase())
      )
        filteredProzesseEintragList.push(value);
    });
    return filteredProzesseEintragList;
  }
}

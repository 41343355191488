import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { of, race } from 'rxjs';

import { DashboardModuleState } from '@app/modules/dashboard/store';

import * as fromActions from '@app/modules/dashboard/store/dashboard.actions';
import * as fromSelectors from '@app/modules/dashboard/store/dashboard.selectors';

@Injectable({ providedIn: 'root' })
export class DashboardResolver implements Resolve<Action> {
  constructor(private store: Store<DashboardModuleState>, private action$: Actions) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    // Check if dashboard is already initialized
    let isInitialized = false;
    this.store
      .select(fromSelectors.isDashboardInitialized)
      .pipe(take(1))
      .subscribe((initialized) => {
        isInitialized = initialized;
      });

    if (isInitialized) {
      return of(null);
    }

    this.store.dispatch(fromActions.initDashboard());
    const responseOK = this.action$.pipe(ofType(fromActions.initDashboardSuccess));
    const responseError = this.action$.pipe(ofType(fromActions.initDashboardFail));
    return race(responseOK, responseError).pipe(take(1));
  }
}

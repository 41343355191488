import { CommonModule, DecimalPipe, SlicePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgModule } from '@angular/core';

import { sharedDirectives } from './directives';
import { sharedComponents } from './components';
import { sharedPipes } from './pipes';

import { ModalFavoriteComponent } from './components/modal-favorite/modal-favorite.component';
import { MaterialModule } from './material.module';
@NgModule({
  imports: [CommonModule, NgbModule, NgrxFormsModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    MaterialModule,
    ...sharedComponents,
    ...sharedPipes,
    ...sharedDirectives,
  ],
  declarations: [...sharedComponents, ...sharedPipes, ...sharedDirectives, ModalFavoriteComponent],
  providers: [DecimalPipe, SlicePipe, ...sharedPipes],
})
export class SharedModule {}

import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { DATATABLE_CONFIG } from '@app/core/constants/datatable-config';

@Directive({
  selector: '[appFluidHeight]',
})
export class FluidHeightDirective {
  @Input() set numberLines(count: number) {
    this.rowsCount = count;
    this.setHeight();
  }

  @Input()
  hasSummary = false;

  @Input()
  windowHeightOffset: number = 556;

  private rowsCount: number;

  private domElement: HTMLElement;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement as HTMLElement;
  }

  private setHeight() {
    const windowHeight = window?.innerHeight;
    const maxHeight = windowHeight - this.windowHeightOffset;
    const initialNumberRows = this.hasSummary ? 4 : 3;
    const numberRows = this.hasSummary ? 3 : 2;

    const innerHeight =
      this.rowsCount > 1
        ? numberRows * DATATABLE_CONFIG.rowHeight * this.rowsCount
        : initialNumberRows * DATATABLE_CONFIG.rowHeight;
    const height = innerHeight > maxHeight ? maxHeight : innerHeight;

    this.renderer.setStyle(this.domElement, 'height', `${height}px`);
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '@app/core/store/actions/system-konfiguration.actions';
import { SystemKonfigurationModel } from '@core/models/system-konfiguration.model';

const initialState: SystemKonfigurationModel = {
  wartungArbeitenAktiv: null,
  wartungArbeitenText: null,
  berechtigungMatrixAktiv: null,
  emailBenachrichtigungAktiv: null,
  problemMeldungAktiv: null,
  taskStartenAktiv: null,
  workflowStartenAktiv: null,
  modulDashboardAktiv: null,
  tasksAnzeigenAktiv: null,
  workflowsAnzeigenAktiv: null,
  favoritenAnzeigenAktiv: null,
  monitorAnzeigenAktiv: null,
  modulRplAktiv: null,
  referenzbildungAktiv: null,
  investitionAnlegenAktiv: null,
  investitionBearbeitenAktiv: null,
  investitionLoeschenAktiv: null,
  projektAnlegenAktiv: null,
  projektBearbeitenAktiv: null,
  projekteAnzeigenAktiv: null,
  umsaetzeAnzeigenAktiv: null,
  modulGbdAktiv: null,
  gebaeudeDetailsAktiv: null,
};

const systemKonfigurationReducer = createReducer(
  initialState,
  on(fromActions.loadSystemKonfigurationSuccess, (state, { systemKonfiguration }) => ({
    ...state,
    ...systemKonfiguration,
  }))
);

export function reducer(state: SystemKonfigurationModel | undefined, action: Action) {
  return systemKonfigurationReducer(state, action);
}

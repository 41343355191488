import { TasksStateModel } from '@core/models/tasks-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '@core/store/actions/tasks.actions';
import { TaskGenericMetaData } from '@core/portal-api';

const initialState: TasksStateModel = {
  tasks: [],
  selectedTask: null,
};

const tasksReducer = createReducer(
  initialState,
  on(fromActions.loadTasksSuccess, (state, { tasks }) => {
    return { ...state, tasks };
  }),
  on(fromActions.setSelectedTask, (state, { task }) => {
    return { ...state, selectedTask: task };
  })
);

export function reducer(state: TasksStateModel | undefined, action: Action) {
  return tasksReducer(state, action);
}

export const getSimpleTasks = (state: TasksStateModel) => {
  const taskModels = state.tasks.filter(
    (f) => f.taskType !== TaskGenericMetaData.TaskTypeEnum.WorkflowReferenzbildungStarten
  );
  return taskModels;
};

export const getWorkflows = (state: TasksStateModel) => {
  const taskModels = state.tasks.filter(
    (f) => f.taskType === TaskGenericMetaData.TaskTypeEnum.WorkflowReferenzbildungStarten
  );
  return taskModels;
};

export const getSelectedTask = (state: TasksStateModel) => state.selectedTask;

import { ValidationErrors } from 'ngrx-forms';

export function germanNumberStringGreaterThan(
  compared: number
): <T extends number | null | undefined>(value: T) => ValidationErrors {
  return <T extends number | null | undefined>(value: T): ValidationErrors => {
    if (value && /^\d{1,3}(\.\d{3})*(\,\d+)?$/.test(String(value))) {
      const convertedNumber = Number(String(value).replace(/\./gi, '').replace(',', '.'));
      if (!isNaN(convertedNumber) && convertedNumber >= compared) {
        return {};
      }
    } else if (value && /^\d+$/.test(String(value))) {
      if (value >= compared) {
        return {};
      }
    }
    return {
      validNumberStringGreaterThan: {
        actual: value,
        expected: compared,
      },
    };
  };
}

import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { convertNumberToFormattedString } from '@shared/utils';

@Directive({
  selector: '[appNumberFormatter]',
})
export class NumberFormatterDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.el.value = convertNumberToFormattedString(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = convertNumberToFormattedString(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = convertNumberToFormattedString(value);
  }
}

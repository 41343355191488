import { Pipe, PipeTransform } from '@angular/core';

import { WirtschaftseinheitGroup } from '@app/core/models/wirtschaftseinheit-group.model';
import { Wirtschaftseinheit } from '@app/core/models/wirtschaftseinheit.model';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { AnwenderRolle } from '@app/core/portal-api';
import EbeneEnum = AnwenderRolle.EbeneEnum;

@Pipe({
  name: 'weFilterPipe',
  pure: false,
})
export class WeFilterPipe implements PipeTransform {
  transform(
    items: WirtschaftseinheitGroup[],
    anwenderData: AnwenderDataModel,
    applyFilterAssigned: boolean
  ): WirtschaftseinheitGroup[] {
    const newItems: WirtschaftseinheitGroup[] = [];

    items.forEach((item) => {
      const weList: Wirtschaftseinheit[] = [];
      item.wirtschaftseinheitList.forEach((weEntry) => {
        if (!applyFilterAssigned) {
          weList.push(weEntry);
        } else {
          anwenderData.rollen.forEach((rolle) => {
            if (
              rolle.ebene === EbeneEnum.Alle ||
              (rolle.ebene === EbeneEnum.Region && rolle.referenzObjektID === weEntry.regionObjektID) ||
              (rolle.ebene === EbeneEnum.Wirtschaftseinheit && rolle.referenzObjektID === weEntry.weObjektID)
            ) {
              if (weList.findIndex((obj) => obj.id === weEntry.id) === -1) {
                weList.push(weEntry);
              }
            }
          });
        }
      });

      newItems.push({
        name: item.name,
        wirtschaftseinheitList: weList,
      });
    });

    return newItems;
  }
}

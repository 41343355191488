import { createAction, props } from '@ngrx/store';
import { TaskModel } from '@core/models/task.model';
import { TaskCreateModel } from '@core/models/task-create.model';
import { TaskCloseModel } from '@core/models/task-close.model';

export const loadTasks = createAction('[Core/Tasks] Load tasks');
export const loadTasksSuccess = createAction('[Core/Tasks] Load tasks success', props<{ tasks: TaskModel[] }>());
export const loadTasksFail = createAction('[Core/Tasks] Load tasks fail');

export const setSelectedTask = createAction('[Core/Tasks] Set selected task', props<{ task: TaskModel }>());

export const startTask = createAction('[Core/Tasks] Start task', props<{ taskCreate: TaskCreateModel }>());

export const startTaskSuccess = createAction('[Core/Tasks] Start task success');

export const startTaskFail = createAction('[Core/Tasks] Start task fail', props<{ error: unknown }>());

export const closeTask = createAction('[Core/Tasks] Close task', props<{ taskClose: TaskCloseModel }>());

export const closeTaskSuccess = createAction('[Core/Tasks] Close task success');

export const closeTaskFail = createAction('[Core/Tasks] Close task fail', props<{ error: unknown }>());

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Projektvorschlag { 
    empfaenger?: string;
    faelligkeit?: string;
    erstelltvon?: string;
    vorhabenIDs?: Array<string>;
}


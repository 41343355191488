import { createFormGroupState, formGroupReducer, FormGroupState } from 'ngrx-forms';
import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
  RahmenplanDetailsData,
  RahmenplanDetailsForm,
  RahmenplanDetailsUI,
} from '@app/core/models/rahmenplan-details-state.model';

import * as rahmenplanDetailsAction from '../actions/rahmenplan-details.actions';
import * as rahmenplanActions from '../actions/rahmenplan.actions';

export const WIRTSCHAFTSEINHEIT_FORM_ID = 'WIRTSCHAFTSEINHEIT_GROUP_FORM';

export interface RahmenplanDetailsState {
  form: FormGroupState<RahmenplanDetailsForm>;
  data: RahmenplanDetailsData;
  ui: RahmenplanDetailsUI;
}

const initialFormStateValue: RahmenplanDetailsForm = {
  searchData: '',
};

const initialDataState: RahmenplanDetailsData = {
  wirtschaftseinheitGroups: [],
  investitionList: [],
  gebaeudeList: [],
  umsatzList: [],
};

const initialUIState: RahmenplanDetailsUI = {
  isLoadingWirtschaftseinheitList: false,
  isLoadingGebaeudeList: false,
  isLoadingInvestitionList: false,
  isLoadingUmsatzList: false,
  isExportingData: false,
  selectedWirtschaftseinheitID: '',
};

const initialFormState = createFormGroupState<RahmenplanDetailsForm>(WIRTSCHAFTSEINHEIT_FORM_ID, initialFormStateValue);

const wirtschaftseinheitGroupReducer = createReducer(
  initialDataState,
  on(rahmenplanActions.initRahmenplanModuleSuccess, (state, { wirtschaftseinheitGroupData }) => ({
    ...state,
    wirtschaftseinheitGroups: wirtschaftseinheitGroupData.wirtschaftseinheitGroups,
  })),
  on(rahmenplanDetailsAction.loadUmsaetzeSuccess, (state, { umsatzList }) => {
    const we = umsatzList.reduce((obj, item) => Object.assign(obj, { [item.year]: item.we }), {});
    const totalReference = umsatzList.reduce(
      (obj, item) => Object.assign(obj, { [item.year]: item.totalReference }),
      {}
    );
    const total = umsatzList.reduce((obj, item) => Object.assign(obj, { [item.year]: item.total }), {});
    const difference = Object.keys(total).reduce(
      (acc, curr) => ((acc[curr] = total[curr] - totalReference[curr]), acc),
      {}
    );

    const rows = [
      { label: 'WE', ...we },
      { label: 'Region Gesamt (Referenz 11/2021)', ...totalReference },
      { label: 'Region Gesamt (Aktuell)', ...total },
      { label: 'Differenz (Aktuell - Referenz)', ...difference },
    ];
    const columns = umsatzList.map((o) => o.year).sort();
    if (columns.length > 0) {
      columns.unshift('Gesamt');
      columns.unshift('label');
      columns.pop();
    }

    return { ...state, umsatzColumns: columns, umsatzRows: rows };
  }),
  on(rahmenplanDetailsAction.loadWeGebaeudeListSuccess, (state, { gebaeudeList }) => ({
    ...state,
    gebaeudeList,
  })),
  on(rahmenplanDetailsAction.loadInvestitionListSuccess, (state, { investitionList }) => ({
    ...state,
    investitionList,
  })),
  on(rahmenplanDetailsAction.selectWirtschaftseinheit, (state) => ({
    ...state,
    investitionList: [],
    umsatzRows: [],
  }))
);

const wirtschaftseinheitGroupUIReducer = createReducer(
  initialUIState,
  on(rahmenplanDetailsAction.loadWirtschaftseinheitGroup, (state) => ({
    ...state,
    isLoadingWirtschaftseinheitList: true,
  })),
  on(
    rahmenplanDetailsAction.loadWirtschaftseinheitGroupSuccess,
    rahmenplanDetailsAction.loadWirtschaftseinheitGroupFail,
    (state) => ({ ...state, isLoadingWirtschaftseinheitList: false })
  ),
  on(rahmenplanDetailsAction.exportVorhaben, (state) => ({ ...state, isExportingData: true })),
  on(rahmenplanDetailsAction.exportVorhabenFail, (state) => ({
    ...state,
    isExportingData: false,
  })),
  on(rahmenplanDetailsAction.loadInvestitionList, (state) => ({
    ...state,
    isLoadingInvestitionList: true,
  })),
  on(
    rahmenplanDetailsAction.loadInvestitionListFail,
    rahmenplanDetailsAction.loadInvestitionListSuccess,

    (state) => ({
      ...state,
      isLoadingInvestitionList: false,
    })
  ),

  on(rahmenplanDetailsAction.loadUmsaetze, (state) => ({
    ...state,
    isLoadingUmsatzList: true,
  })),
  on(rahmenplanDetailsAction.loadUmsaetzeFail, rahmenplanDetailsAction.loadUmsaetzeSuccess, (state) => ({
    ...state,
    isLoadingUmsatzList: false,
  })),
  on(rahmenplanDetailsAction.setSelectedWeID, (state, { weID }) => ({
    ...state,
    selectedWirtschaftseinheitID: weID,
  }))
);

const formReducer = (state = initialFormState, action: Action) => {
  return formGroupReducer(state, action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers = combineReducers<RahmenplanDetailsState, any>({
  data: wirtschaftseinheitGroupReducer,
  form: formReducer,
  ui: wirtschaftseinheitGroupUIReducer,
});

export function reducer(state: RahmenplanDetailsState | undefined, action: Action) {
  return reducers(state, action);
}

import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { CoorProjekt } from '@core/portal-api';
import { Projekt } from '../models/projekt-anlegen-state.model';

@Injectable()
export class ProjektAnlegenConverter implements Converter<Projekt, CoorProjekt> {
  convertToApiModel(item: Projekt): CoorProjekt {
    const result: CoorProjekt = {
      Aktion: item.action,
      TMP_Projekt_Nr: item.id,
      Projektbezeichnung: item.projektbezeichnung,
      Begruendung_Ausnahme: item.begruendungAusnahme,
      Projektart: item.projektart,
      Angebotspreis_Brutto: item.angebotspreisBrutto,
      Angebotspreis_Netto: item.angebotspreisNetto,
      Richtwert_Intern_Brutto: item.richtwertInternBrutto,
      Richtwert_Intern_Netto: item.richtwertInternNetto,
      Richtwert_Extern_Brutto: item.richtwertExternBrutto,
      Richtwert_Extern_Netto: item.richtwertExternNetto,
      Projektflaeche_Gesamt: item.projektflaecheGesamt,
      Baubeginn: item.baubeginn,
      Bauende: item.bauFertigstellung,
      Projektsteuerer: item.projektsteuerer,
      vohabenIdList: item.investitionList as string[],
    };
    return result;
  }
}

import { Converter } from '@core/models/converter';
import { CacheInvalidateModel } from '@core/models/cache-invalidate.model';
import { CacheInvalidate } from '@core/portal-api';

export class CacheInvalidateConverter implements Converter<CacheInvalidateModel, CacheInvalidate> {
  convertToApiModel(cacheInvalidateModel: CacheInvalidateModel): CacheInvalidate {
    if (cacheInvalidateModel === null || cacheInvalidateModel === undefined) {
      return undefined;
    }

    const cacheInvalidate: CacheInvalidate = {
      invalidateMasterData: cacheInvalidateModel.invalidateMasterData,
      invalidateRpl: cacheInvalidateModel.invalidateRpl,
    };

    return cacheInvalidate;
  }
}

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { UserProfile } from '@app/core/models/user-profile.model';

import * as fromUserProfile from '@core/store/reducers/user-profile.reducer';
import * as fromUserConfig from '@core/store/reducers/user-config.reducer';
import * as fromAnwenderData from '@core/store/reducers/anwender-data.reducer';
import * as fromRechtematrix from '@core/store/reducers/rechtematrix.reducers';
import { RechtematrixStateModel } from '@core/store/reducers/rechtematrix.reducers';
import * as fromFavorites from '@core/store/reducers/favorites.reducer';
import * as fromSystemKonfiguration from '@core/store/reducers/system-konfiguration.reducer';
import * as fromTasks from '@core/store/reducers/tasks.reducer';
import * as fromChatbot from '@core/store/reducers/chatbot.reducer';

import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { FavoriteModel } from '@app/core/models/favorite.model';
import { SystemKonfigurationModel } from '@core/models/system-konfiguration.model';
import { TasksStateModel } from '@core/models/tasks-state.model';
import { ChatbotStateModel } from '@core/models/chatbot-state.model';

export interface CoreModuleState {
  userConfig: fromUserConfig.UserConfigState;
  userProfile: UserProfile;
  anwenderData: AnwenderDataModel;
  rechtematrix: RechtematrixStateModel;
  favorites: FavoriteModel[];
  systemKonfiguration: SystemKonfigurationModel;
  tasks: TasksStateModel;
  chatbotState: ChatbotStateModel;
}

export const coreModuleReducers: ActionReducerMap<CoreModuleState> = {
  userConfig: fromUserConfig.reducer,
  userProfile: fromUserProfile.reducer,
  anwenderData: fromAnwenderData.reducer,
  rechtematrix: fromRechtematrix.reducer,
  favorites: fromFavorites.reducer,
  systemKonfiguration: fromSystemKonfiguration.reducer,
  tasks: fromTasks.reducer,
  chatbotState: fromChatbot.reducer,
};

export const getCoreModuleState = createFeatureSelector<CoreModuleState>('core');

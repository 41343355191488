import { Converter } from '@app/core/models/converter';
import { RechtematrixEntryModel } from '@core/models/rechtematrix-entry.model';
import { BerechtigungMatrix } from '@core/portal-api';
import { Injectable } from '@angular/core';

@Injectable()
export class RechtematrixEntryConverter implements Converter<RechtematrixEntryModel, BerechtigungMatrix> {
  convertFromApiModel(berechtigungMatrix: BerechtigungMatrix): RechtematrixEntryModel {
    if (berechtigungMatrix === null || berechtigungMatrix === undefined) {
      return undefined;
    }

    const rechtematrix: RechtematrixEntryModel = {
      modulID: berechtigungMatrix.modulID,
      fokus: berechtigungMatrix.fokus,
      funktion: berechtigungMatrix.funktion,
      rollenIntern: berechtigungMatrix.rollenIntern,
      rollenIAM: berechtigungMatrix.rollenIAM,
      zustaendig: berechtigungMatrix.zustaendig,
      isPermitted: false,
    };

    return rechtematrix;
  }
}

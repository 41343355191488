import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppAuthGuard } from '@core/guards/app-auth.guard';
import { PreloadDataGuard } from '@core/guards/preload-data.guard';
import { RahmenplanResolver } from '@core/resolvers/rahmenplan.resolver';
import { DashboardResolver } from './core/resolvers/dashboard.resolver';

const routes: Routes = [
  {
    path: 'rahmenplan',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    resolve: {
      rahmenplan: RahmenplanResolver,
    },
    loadChildren: () => import('./modules/rahmenplan/rahmenplan.module').then((m) => m.RahmenplanModule),
  },
  {
    path: 'gebaeude',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    loadChildren: () => import('./modules/gebaeude/gebaeude.module').then((m) => m.GebaeudeModule),
  },
  {
    path: 'admin',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    loadChildren: () => import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
  },
  {
    path: 'handbuch',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    loadChildren: () => import('./modules/handbuch/handbuch.module').then((m) => m.HandbuchModule),
  },
  {
    path: 'prozesse',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    loadChildren: () => import('./modules/prozesse/prozesse.module').then((m) => m.ProzesseModule),
  },
  {
    path: '',
    canActivate: [AppAuthGuard, PreloadDataGuard],
    resolve: {
      dashboard: DashboardResolver,
    },
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

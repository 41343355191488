import { Component, OnInit } from '@angular/core';
import { TaskModel } from '@core/models/task.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-task-close',
  templateUrl: './modal-task-close.component.html',
  styleUrls: ['./modal-task-close.component.scss'],
})
export class ModalTaskCloseComponent implements OnInit {
  selectedTask: TaskModel;

  parentWorkflow: TaskModel;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onSaveReferenzbildungClose() {
    this.activeModal.close('close');
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }
}

const C_V_STM_GTY = [
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '1',
    GTY_GEBAUDETYP: 'Schulgeb?ude',
    GTY_GTY_OBJ_ID_PKT: 5266202,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '2',
    GTY_GEBAUDETYP: 'Sporthalle',
    GTY_GTY_OBJ_ID_PKT: 5266203,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '3',
    GTY_GEBAUDETYP: 'Mobilklasse',
    GTY_GTY_OBJ_ID_PKT: 7215368,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '4',
    GTY_GEBAUDETYP: 'Freianlage',
    GTY_GTY_OBJ_ID_PKT: 7215373,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '5',
    GTY_GEBAUDETYP: 'Garage / Lager / Bunker',
    GTY_GTY_OBJ_ID_PKT: 7215378,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '6',
    GTY_GEBAUDETYP: 'Schulgeb. Gewerbeschule',
    GTY_GTY_OBJ_ID_PKT: 7215383,
  },
  {
    GO_TYP: 'GTY',
    GTY_GTY_OBJ_ID_PKF: '7',
    GTY_GEBAUDETYP: 'Hamburger Klassenhaus',
    GTY_GTY_OBJ_ID_PKT: 7215388,
  },
];

export const getGebaeudeTypByName = (name: string) => {
  return C_V_STM_GTY.find((value) => value.GTY_GEBAUDETYP === name);
};

import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { Gebaeude } from '@app/core/models/gebaeude.model';
import { Gebaeudegroupobject } from '@core/portal-api';

@Injectable()
export class GebaeudeConverter implements Converter<Gebaeude, Gebaeudegroupobject> {
  convertFromApiModel(gebaeude: Gebaeudegroupobject): Gebaeude {
    if (gebaeude == null) {
      return undefined;
    }
    const result: Gebaeude = {
      id: gebaeude?.gebaeude?.sapGebaeudeId ? gebaeude?.gebaeude?.sapGebaeudeId.toString() : '',
      name: gebaeude?.gebaeude?.gebaeudeName ? gebaeude.gebaeude.gebaeudeName : '',
      number: gebaeude?.gebaeude?.gebaeudeNummer ? gebaeude.gebaeude.gebaeudeNummer : '',
      school: gebaeude?.gebaeude?.schulname ? gebaeude.gebaeude.schulname : '',
      type: gebaeude?.gebaeude?.gebaeudeType ? gebaeude.gebaeude.gebaeudeType : '',
      note: gebaeude?.gebaeude?.bemerkung ? gebaeude.gebaeude.bemerkung : '',
      gklBefore: gebaeude?.aktuellerzustand?.gk ? gebaeude.aktuellerzustand.gk : '',
      gklAfter: gebaeude?.zukuenftigerzustand?.gk ? gebaeude.zukuenftigerzustand.gk : '',
      rentalAreaBefore: gebaeude?.aktuellerzustand?.mietflaeche ? gebaeude.aktuellerzustand.mietflaeche : '',
      rentalAreaAfter: gebaeude?.zukuenftigerzustand?.mietflaeche ? gebaeude.zukuenftigerzustand.mietflaeche : '',
      rentalBSBBefore: gebaeude?.aktuellerzustand?.mieterbsb ? gebaeude.aktuellerzustand.mieterbsb : '',
      rentalBSBAfter: gebaeude?.zukuenftigerzustand?.mieterbsb ? gebaeude.zukuenftigerzustand.mieterbsb : '',
      rentalOthersBefore: gebaeude?.aktuellerzustand?.mieters ? gebaeude.aktuellerzustand.mieters : '',
      rentalOthersAfter: gebaeude?.zukuenftigerzustand?.mieters ? gebaeude.zukuenftigerzustand.mieters : '',
      isGueltig: !!gebaeude.gebaeude.isGueltig,
      benutzerBefore: gebaeude?.aktuellerzustand?.mieters
        ? gebaeude?.aktuellerzustand?.mieters
        : gebaeude?.aktuellerzustand?.mieterbsb,
      benutzerAfter: gebaeude?.zukuenftigerzustand?.mieterbsb
        ? gebaeude?.zukuenftigerzustand?.mieterbsb
        : gebaeude?.zukuenftigerzustand?.mieters,
    };
    return result;
  }
}

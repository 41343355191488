import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input()
  lineCount = 3;
  @Input()
  repeat = 3;

  // How many lines should one card have
  numberLines = [];
  // How many times repeat cards
  numberCards = [];

  ngOnInit(): void {
    this.numberLines.length = this.lineCount;
    this.numberCards.length = this.repeat;
  }
}

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Aufgabe Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Name</strong>
      </div>
      <p>{{ selectedTask.kurzbeschreibung }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Status</strong>
      </div>
      <p>{{ selectedTask.status }}</p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Erstellt am</strong>
      </div>
      <p>{{ selectedTask.erstellungsDatum | date }}</p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Fällig am</strong>
      </div>
      <p>{{ selectedTask.faelligkeitsDatum | date }}</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="mt-2" *ngIf="parentWorkflow">
    <p class="mt-3">Diese Aufgabe ist eine Unteraufgabe des Workflows:</p>
    <div class="d-flex align-items-center">
      <mat-icon style="color: #0085c0">arrow_circle_up</mat-icon>
      <div class="text-truncate ml-2">
        <span class="custom-parent-workflow-header">{{ parentWorkflow.kurzbeschreibung }}</span>
        <p class="mb-0 text-truncate">
          <i>Endet am: {{ parentWorkflow.faelligkeitsDatum | date }} / Status: {{ parentWorkflow.status }}</i>
        </p>
      </div>
    </div>
  </div>
  <br />
  <div *ngIf="selectedTask.taskType === 'REFERENZBILDUNG_SPERREN'">
    <span>
      Inhalt dieser Aufgabe:
      <ul>
        <li>Sperrung Referenzbildung</li>
        <li>Nach Abschluss dieser Aufgabe wird auch der Worklow, zu dem diese Aufgabe gehört, abgeschlossen.</li>
      </ul>
    </span>
    <div class="modal-footer">
      <button type="button" mat-stroked-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
        abbrechen
      </button>
      <button mat-raised-button color="primary" (click)="onSaveReferenzbildungClose()">aufgabe durchführen</button>
    </div>
  </div>
</div>

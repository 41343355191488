import { Pipe, PipeTransform } from '@angular/core';
import { Gebaeudeanlage } from '@app/core/models/gebaeudeanlage.model';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { AnwenderRolle } from '@core/portal-api';
import EbeneEnum = AnwenderRolle.EbeneEnum;

@Pipe({
  name: 'gebaeudeFilterPipe',
  pure: false,
})
export class GebaeudeFilterPipe implements PipeTransform {
  transform(
    gebaeudeList: Gebaeudeanlage[],
    anwenderData: AnwenderDataModel,
    applyFilterAssigned: boolean
  ): Gebaeudeanlage[] {
    const gebaeudeMap = new Map<string, Gebaeudeanlage>();

    if (anwenderData) {
      if (gebaeudeList && gebaeudeList.length > 0) {
        gebaeudeList.forEach((gebaeudeEntry) => {
          if (!applyFilterAssigned) {
            gebaeudeMap.set(gebaeudeEntry.id, gebaeudeEntry);
          } else {
            anwenderData.rollen.forEach((rolle) => {
              if (
                rolle.ebene === EbeneEnum.Alle ||
                (rolle.ebene === EbeneEnum.Region && rolle.referenzObjektID === gebaeudeEntry.weRegionID) ||
                (rolle.ebene === EbeneEnum.Wirtschaftseinheit && rolle.referenzObjektID === gebaeudeEntry.weID)
              ) {
                if (!gebaeudeMap.has(gebaeudeEntry.id)) gebaeudeMap.set(gebaeudeEntry.id, gebaeudeEntry);
              }
            });
          }
        });
      }
    }
    return [...gebaeudeMap.values()];
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Ein Log Eintrag, welcher ins Server Log geschrieben wird
 */
export interface LogEintrag { 
    /**
     * Der Log Level
     */
    logLevel: LogEintrag.LogLevelEnum;
    /**
     * Die ID des Moduls, in welchem der Fehler aufgetreten ist
     */
    modulID: string;
    /**
     * Der Kontext, in welchem der Fehler aufgetreten ist
     */
    fehlerKontext?: LogEintrag.FehlerKontextEnum;
    /**
     * Die ID des Objektes, in dessen Kontext der Fehler aufgetreten ist
     */
    kontextObjektID?: string;
    /**
     * Der Name des Services, in welchem der Fehler aufgetreten ist
     */
    serviceName: string;
    /**
     * Die eindeutige ID des Fehlers
     */
    fehlerID?: string;
    /**
     * Der Fehlertext
     */
    fehlerText: string;
    /**
     * Der Stacktrace als base64 encoded String
     */
    fehlerStack?: string;
    /**
     * Kann mehrere Log Eintraege als zusammengehoerig markieren
     */
    correlationID?: string;
}
export namespace LogEintrag {
    export type LogLevelEnum = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL';
    export const LogLevelEnum = {
        Trace: 'TRACE' as LogLevelEnum,
        Debug: 'DEBUG' as LogLevelEnum,
        Info: 'INFO' as LogLevelEnum,
        Warn: 'WARN' as LogLevelEnum,
        Error: 'ERROR' as LogLevelEnum,
        Fatal: 'FATAL' as LogLevelEnum
    };
    export type FehlerKontextEnum = 'WIRTSCHAFTSEINHEIT' | 'GEBAEUDE' | 'INVESTITION' | 'PROJEKT' | 'DASHBOARD' | 'BERECHTIGUNG';
    export const FehlerKontextEnum = {
        Wirtschaftseinheit: 'WIRTSCHAFTSEINHEIT' as FehlerKontextEnum,
        Gebaeude: 'GEBAEUDE' as FehlerKontextEnum,
        Investition: 'INVESTITION' as FehlerKontextEnum,
        Projekt: 'PROJEKT' as FehlerKontextEnum,
        Dashboard: 'DASHBOARD' as FehlerKontextEnum,
        Berechtigung: 'BERECHTIGUNG' as FehlerKontextEnum
    };
}



import { Action, createReducer, on } from '@ngrx/store';

import {
  INVESTITIONEN_FILTERS_INITIAL_VALUES,
  GEBAEUDE_FILTERS_INITIAL_VALUES,
  PROJEKTE_FILTERS_INITIAL_VALUES,
  FilterConfigState,
} from '@app/core/models/filter-config.model';
import { TableColumn } from '@app/core/models/table-column.model';

import { DatatableConfigState } from '@app/core/models/datatable-config.model';
import { GEBAEUDE_TABLE_CONFIG } from '@app/core/constants/gebaeude-table-config';
import { INVESTITIONEN_TABLE_CONFIG } from '@app/core/constants/investition-table-config';
import { PROJEKTE_TABLE_CONFIG } from '@app/core/constants/projekte-table-config';

import * as fromActions from '@app/core/store/actions/user-config.actions';

export const initialTablesvalues: DatatableConfigState = {
  gebaeude: {
    columns: GEBAEUDE_TABLE_CONFIG,
  },
  investition: {
    columns: INVESTITIONEN_TABLE_CONFIG,
  },
  projekte: {
    columns: PROJEKTE_TABLE_CONFIG,
  },
};

export const initialFilterConfig = {
  investition: INVESTITIONEN_FILTERS_INITIAL_VALUES,
  gebaeude: GEBAEUDE_FILTERS_INITIAL_VALUES,
  projekte: PROJEKTE_FILTERS_INITIAL_VALUES,
};

export interface UserConfigState {
  tablesConfig: DatatableConfigState;
  filterConfig: FilterConfigState;
}

const initialState: UserConfigState = {
  tablesConfig: initialTablesvalues,
  filterConfig: initialFilterConfig,
};
const userConfigReducer = createReducer(
  initialState,
  on(fromActions.loadUserConfigSuccess, fromActions.setTableConfigValues, (state, { config }) => {
    const tablesConfig: DatatableConfigState = {
      investition: {
        ...config.investition,
        columns: [],
      },
      gebaeude: {
        ...config.gebaeude,
        columns: [],
      },
      projekte: {
        ...config.projekte,
        columns: [],
      },
    };

    for (const key of Object.keys(config)) {
      if (config[key]) {
        tablesConfig[key].columns = updateConfigTable(config[key].columns, key);
      } else {
        tablesConfig[key] = initialTablesvalues[key];
      }
    }

    return { ...state, tablesConfig };
  }),
  on(fromActions.updateInvestitionFilterConfig, (state, { config }) => {
    return {
      ...state,
      filterConfig: {
        ...state.filterConfig,
        investition: config,
      },
    };
  }),
  on(fromActions.updateGebaeudeFilterConfig, (state, { config }) => {
    return {
      ...state,
      filterConfig: {
        ...state.filterConfig,
        gebaeude: config,
      },
    };
  }),
  on(fromActions.updateProjekteFilterConfig, (state, { config }) => {
    return {
      ...state,
      filterConfig: {
        ...state.filterConfig,
        projekte: config,
      },
    };
  }),
  on(fromActions.resetInvestitionFilterConfig, (state) => ({
    ...state,
    filterConfig: { ...state.filterConfig, investition: INVESTITIONEN_FILTERS_INITIAL_VALUES },
  })),
  on(fromActions.resetProjektFilterConfig, (state) => ({
    ...state,
    filterConfig: { ...state.filterConfig, projekte: PROJEKTE_FILTERS_INITIAL_VALUES },
  })),
  on(fromActions.updateInvestitionTableConfig, (state, { config }) => {
    const columnsConfig = updateConfigTable(config.columns, 'investition');

    return {
      ...state,
      tablesConfig: {
        ...state.tablesConfig,
        investition: {
          ...config,
          columns: columnsConfig,
          configID: state.tablesConfig.investition.configID,
        },
      },
    };
  }),
  on(fromActions.updateGebaeudeTableConfig, (state, { config }) => {
    const columnsConfig = updateConfigTable(config.columns, 'gebaeude');
    return {
      ...state,
      tablesConfig: {
        ...state.tablesConfig,
        gebaeude: {
          ...config,
          columns: columnsConfig,
          configID: state.tablesConfig.gebaeude.configID,
        },
      },
    };
  }),
  on(fromActions.updateProjekteTableConfig, (state, { config }) => {
    const columnsConfig = updateConfigTable(config.columns, 'projekte');
    return {
      ...state,
      tablesConfig: {
        ...state.tablesConfig,
        projekte: {
          ...config,
          columns: columnsConfig,
          configID: state.tablesConfig.projekte.configID,
        },
      },
    };
  }),
  on(fromActions.resetGebaeudeTableConfigSuccess, (state) => {
    const tablesConfig: DatatableConfigState = { ...state.tablesConfig, gebaeude: initialTablesvalues.gebaeude };
    return { ...state, tablesConfig };
  }),
  on(fromActions.resetInvestitionTableConfigSuccess, (state) => {
    const tablesConfig: DatatableConfigState = { ...state.tablesConfig, investition: initialTablesvalues.investition };
    return { ...state, tablesConfig };
  }),
  on(fromActions.resetProjekteTableConfigSuccess, (state) => {
    const tablesConfig: DatatableConfigState = { ...state.tablesConfig, projekte: initialTablesvalues.projekte };
    return { ...state, tablesConfig };
  })
);

export function reducer(state: UserConfigState | undefined, action: Action) {
  return userConfigReducer(state, action);
}

const updateConfigTable = (cols: TableColumn[], key: string): TableColumn[] => {
  const newConfig = initialTablesvalues[key].columns.filter((c) => !cols.some((col) => col.prop === c.prop));

  // Merge the saved column config with the default one
  const tempConfig = cols.map((col) => {
    const initialCol = initialTablesvalues[key].columns.find((c) => c.prop === col.prop);
    return { ...initialCol, width: col.width, showColumn: col.showColumn };
  });

  // Merge the saved config with the default one
  const columnsConfig = [...tempConfig, ...newConfig];
  return columnsConfig;
};

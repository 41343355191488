import * as coreState from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { ChatbotStateModel } from '@core/models/chatbot-state.model';

export const selectChatbotState = createSelector(
  coreState.getCoreModuleState,
  (state: coreState.CoreModuleState) => state.chatbotState
);

export const selectKonversation = createSelector(
  selectChatbotState,
  (state: ChatbotStateModel) => state.konversationen[0]
);

import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { ChoiceListItem, ChoiceListType, ChecklistTypeEnum } from '@core/models/choice-list-item.model';

import * as fromAPI from '@core/portal-api';
import { BUILDING_CLASS_FUTURE, REGIONEN } from '../constants/choicelist';
import * as fromWizardRulesAction from '@app/modules/rahmenplan/store/actions/wizard-rules.actions';
import { WizardRulesServiceAPI } from '@core/services/wizard-rules.service';
import { WizardRule, WizardRulesService } from '@core/portal-api';

@Injectable()
export class InvestitionChoiceListService {
  constructor(private service: fromAPI.ChoiceListsService, private wizardRulesService: WizardRulesService) {}

  getSchule(weId: string): Observable<ChoiceListItem[]> {
    return this.service.choiceListSchulen(weId).pipe(
      map((res) => {
        //19186
        if (!res.data) res.data = [];
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.SCHOOL));
        });
        return ret;
      })
    );
  }

  getProjektarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListProjektarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.PROJECT_TYPE));
        });
        return ret;
      })
    );
  }

  getProgrammarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListProgrammarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.PROGRAM_TYPE));
        });
        return ret;
      })
    );
  }

  getWizardRules(): Observable<WizardRule[]> {
    return this.wizardRulesService.getWizardRules().pipe(
      map((wizardRules) => {
        return wizardRules.data;
      })
    );
  }

  getUnterprogrammarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListUnterprogrammarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.SUB_PROGRAM_TYPE));
        });
        return ret;
      })
    );
  }

  getAuspraegungUnterprogrammarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListAuspraegungUprogrammarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.SUB_PROGRAM_VALUE));
        });
        return ret;
      })
    );
  }

  getKunden(): Observable<ChoiceListItem[]> {
    return this.service.choiceListNutzer().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.KUNDEN));
        });
        return ret;
      })
    );
  }

  getGebaeude(weID: string): Observable<ChoiceListItem[]> {
    return this.service.choiceListGebaeude(weID).pipe(
      map((res) => {
        //19186
        if (!res.data) res.data = [];
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.BUILDING));
        });
        return ret;
      })
    );
  }

  getProjektnummer(weID: string): Observable<ChoiceListItem[]> {
    return this.service.choiceListProjekte(weID).pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        if (res.data) {
          res.data.forEach((item) => {
            ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.PROJECT_NUMBER));
          });
        }
        return ret;
      })
    );
  }

  getPreisarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListPreisarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.PRICE_TYPE));
        });
        return ret;
      })
    );
  }

  getFinanzierungsarten(): Observable<ChoiceListItem[]> {
    return this.service.choiceListFinanzierungsarten().pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.SPONSOR_TYPE));
        });
        return ret;
      })
    );
  }

  getGebaeudeClassFuture(): Observable<ChoiceListItem[]> {
    return of(BUILDING_CLASS_FUTURE);
  }

  fillChecklistItem(item: fromAPI.ChoiceListItem, itemType: ChoiceListType): ChoiceListItem {
    const ret: ChoiceListItem = {
      id: item.id,
      parentID: item.parentID,
      value: item.value,
      type: itemType,
      validFrom: item.gueltigVon,
      validTo: item.gueltigBis,
    };
    return ret;
  }

  getGebaeudesForNewInvestition(weID: string): Observable<ChoiceListItem[]> {
    const ret: ChoiceListItem[] = [];
    // Add Gebaeude Numbers for project type 'Zubau' and 'Ersatzbau' (with the project type id 1 or 2)
    // Create dropdown list items Schulbau 1-3, Sporthalle 1-3 with id in format of weID/1-6
    for (let projectType = 1; projectType <= 2; projectType++) {
      for (let index = 1; index <= 9; index++) {
        ret.push({
          id: weID + '/' + index,
          parentID: '' + projectType,
          value: index <= 3 ? 'Schulbau ' + index : index <= 6 ? 'Neubau ' + (index - 3) : 'Sporthalle ' + (index - 6),
          type: ChecklistTypeEnum.PROJECT_TYPE,
        });
      }
    }
    return of(ret);
  }

  getAvailableYearForGebaeudeConstruction(): Observable<ChoiceListItem[]> {
    const ret: ChoiceListItem[] = [];

    const currentYear = new Date().getFullYear();
    const MAX_NUMBER_YEARS = currentYear + 50;
    const MIN_NUMBER_YEARS = currentYear - 10;

    for (let year = MIN_NUMBER_YEARS; year <= MAX_NUMBER_YEARS; year++) {
      ret.push({
        id: '' + year,
        value: '' + year,
        type: null,
      });
    }
    return of(ret);
  }

  getProjektsteuerer(weID: string): Observable<ChoiceListItem[]> {
    return this.service.choiceListAnwender(['PSI', 'PLT', 'PLF', 'RL', 'RCO'], weID).pipe(
      map((res) => {
        const ret: ChoiceListItem[] = [];
        res.data.forEach((item) => {
          ret.push(this.fillChecklistItem(item, ChecklistTypeEnum.PROJEKTSTEUERER));
        });

        const prjstMap = new Map<string, ChoiceListItem>();

        ret.forEach((item) => {
          if (item.parentID === 'WIRTSCHAFTSEINHEIT') {
            if (!prjstMap.has(item.id)) {
              prjstMap.set(item.id, item);
            }
          }
        });

        ret.forEach((item) => {
          if (item.parentID === 'REGION') {
            if (!prjstMap.has(item.id)) {
              prjstMap.set(item.id, item);
            }
          }
        });

        const filteredPrjstList: ChoiceListItem[] = [...prjstMap.values()];

        return filteredPrjstList;
      })
    );
  }

  getRegionen(): Observable<ChoiceListItem[]> {
    return of(REGIONEN);
  }
}

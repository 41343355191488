import { ValidationErrors } from 'ngrx-forms';

export const futureDate = <T extends string | null | undefined>(value: T, range: number): ValidationErrors => {
  if (!value) {
    return {
      dateInFuture: {
        actual: value,
      },
    };
  }

  const parts = value.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  const date = new Date(year, month, day);

  let today = new Date();
  today.setDate(today.getDate() + range);
  today = new Date(today.getFullYear(), today.getMonth(), today.getDay());

  if (date >= today) {
    return {};
  } else {
    return {
      dateInFuture: {
        actual: value,
      },
    };
  }
};

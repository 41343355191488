import { Action, createReducer, on } from '@ngrx/store';

import { RechtematrixEntryModel } from '@core/models/rechtematrix-entry.model';

import * as fromActions from '@app/core/store/actions/rechtematrix.actions';

export interface RechtematrixStateModel {
  rechteMatrixEntries: RechtematrixEntryModel[];
}
const initialState: RechtematrixStateModel = {
  rechteMatrixEntries: [],
};

const rechtematrixReducer = createReducer(
  initialState,
  on(fromActions.loadRechtematrixSuccess, (state, { rechtematrix }) => ({
    ...state,
    rechteMatrixEntries: rechtematrix,
  })),
  on(fromActions.updateRechtematrixSuccess, fromActions.updateRechtematrixWithWESuccess, (state, { rechtematrix }) => ({
    ...state,
    rechteMatrixEntries: rechtematrix,
  }))
);

export function reducer(state: RechtematrixStateModel | undefined, action: Action) {
  return rechtematrixReducer(state, action);
}

//export const getRechtematrix = (state: RechtematrixStateModel) => state.rechteMatrixEntries;

import { ValidationErrors } from 'ngrx-forms';

export function requiredIf(actualValue: boolean): <T extends string | null | undefined>(value: T) => ValidationErrors {
  return <T extends string | null | undefined>(value: T): ValidationErrors => {
    if ((actualValue && value !== '' && value != null) || !actualValue) {
      return {};
    }
    return {
      requiredIf: {
        actual: value as string,
      },
    };
  };
}

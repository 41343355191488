<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Workflow starten</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="custom-mat-select">
    <mat-form-field appearance="outline">
      <mat-label>Workflow auswählen</mat-label>
      <mat-select #workflowDropdown name="workflowTypes" [(value)]="selectedWorkflowType">
        <mat-option value="WORKFLOW_REFERENZBILDUNG_STARTEN">Referenzbildung</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="card">
    <div class="mt-2 mb-2 ml-2 mr-2" *ngIf="selectedWorkflowType === 'WORKFLOW_REFERENZBILDUNG_STARTEN'">
      <div *ngIf="!referenzbildungAktiv; else workflowAlreadyActive">
        <form #workflowForm="ngForm" (ngSubmit)="onSave(workflowForm)" id="workflowForm">
          <span>
            <strong>Referenzbildung starten</strong>
            <br /><br />
            Inhalt dieses Workflows:
            <ul>
              <li>Freigabe Referenzbildung</li>
              <li>Controller erhalten eine zum ausgewählten Datum fällige Aufgabe zum Sperren der Referenzbildung</li>
            </ul>
          </span>
          <div class="row row-cols-sm-auto">
            <div class="col-6">Endet am:</div>
          </div>
          <div class="row row-cols-sm-auto">
            <div class="col-6">
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="dd.mm.yyyy"
                  name="endDate"
                  [(ngModel)]="dateModel"
                  ngbDatepicker
                  [minDate]="minDate"
                  #d="ngbDatepicker"
                  required
                />
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </form>
        <br />
        <div class="modal-footer">
          <button type="button" mat-stroked-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
            abbrechen
          </button>
          <button mat-raised-button color="primary" type="submit" form="workflowForm" [disabled]="!workflowForm.valid">
            speichern
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #workflowAlreadyActive>
    <div class="alert alert-warning" role="alert">
      Ein Workflow dieses Typs ist bereits aktiv und kann nicht nochmals gestartet werden.
    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
        abbrechen
      </button>
    </div>
  </ng-template>
</div>

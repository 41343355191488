import { SliderFilterModel } from './slider-filter.model';

export interface FilterConfigState {
  investition: InvestitionFilterConfig;
  gebaeude: GebaeudeFilterConfig;
  projekte: ProjekteFilterConfig;
}
export interface InvestitionFilterConfig {
  showOldInvestitionen?: boolean;
  projektArt?: string[];
  programmArt?: string[];
  projektNummer?: string[];
  nutzer?: string[];
  bauBeginn?: string[];
  bauFertigstellung?: string[];
  auftragStatus?: string[];
}

export const INVESTITIONEN_FILTERS_INITIAL_VALUES: InvestitionFilterConfig = {
  showOldInvestitionen: false,
  projektArt: [],
  programmArt: [],
  projektNummer: [],
  nutzer: [],
  bauBeginn: [],
  bauFertigstellung: [],
  auftragStatus: [],
};

export interface GebaeudeFilterConfig {
  filter?: {
    weRegion?: string[];
    weBezeichnung?: string[];
    weBezirk?: string[];
    gebaeudeKlasse?: string[];
  };
  slider?: SliderFilterModel[];
}

export const GEBAEUDE_FILTERS_INITIAL_VALUES: GebaeudeFilterConfig = {
  filter: {
    weRegion: [],
    weBezeichnung: [],
    weBezirk: [],
    gebaeudeKlasse: [],
  },
  slider: [],
};

export interface ProjekteFilterConfig {
  projektArt?: string[];
  programmArt?: string[];
  projektNummer?: string[];
  nutzer?: string[];
  bauBeginn?: string[];
  bauFertigstellung?: string[];
  auftragStatus?: string[];
}

export const PROJEKTE_FILTERS_INITIAL_VALUES: ProjekteFilterConfig = {
  projektArt: [],
  programmArt: [],
  projektNummer: [],
  nutzer: [],
  bauBeginn: [],
  bauFertigstellung: [],
  auftragStatus: [],
};

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProblemMeldungModel } from '@core/models/problem-meldung-model';
import { ProblemMeldung } from '@core/portal-api';
import MeldungKontextEnum = ProblemMeldung.MeldungKontextEnum;

@Component({
  selector: 'app-modal-meldung',
  templateUrl: './modal-meldung.component.html',
  styleUrls: ['./modal-meldung.component.scss'],
})
export class ModalMeldungComponent {
  @Input()
  isFreeText: boolean;
  @Input()
  modulID: string;
  @Input()
  meldungTitel: string;
  @Input()
  meldungKontext: MeldungKontextEnum;
  @Input()
  kontextObjektID: string;
  @Input()
  meldungLink;

  meldungText: string;

  constructor(public activeModal: NgbActiveModal) {}

  onSave({ form }) {
    if (form.valid) {
      const problemMeldung: ProblemMeldungModel = {
        problemLevel: ProblemMeldung.ProblemLevelEnum.Fehler,
        modulID: this.modulID,
        meldungKontext: this.meldungKontext,
        kontextObjektID: this.kontextObjektID,
        meldungTitel: this.meldungTitel,
        meldungText: form.value.meldungText,
        meldungLink: this.meldungLink,
      };
      this.activeModal.close(problemMeldung);
    }
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }
}

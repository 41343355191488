import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { InvestitionFilterConfig } from '../models/filter-config.model';

import * as fromRahmenplanActions from '@app/modules/rahmenplan/store/actions/rahmenplan-details.actions';
import * as fromActions from '@core/store/actions/user-config.actions';
import * as fromRahmenplanSelectors from '@app/modules/rahmenplan/store/selectors/rahmenplan.selectors';
import * as fromReducer from '@app/modules/rahmenplan/store/reducers';

@Injectable()
export class RahmenplanDetailsGuard implements CanActivate {
  constructor(private store: Store<fromReducer.RahmenplanModuleState>) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isInitialized = false;
    this.store
      .select(fromRahmenplanSelectors.isWeInitialized)
      .pipe(take(1))
      .subscribe((initialized) => {
        isInitialized = initialized;
      });

    const filterParams = route.queryParams['filter'];
    const weID = route.queryParams['weID'];
    if (weID) {
      this.store.dispatch(fromRahmenplanActions.setSelectedWeID({ weID }));
    }
    if (!isInitialized && filterParams) {
      const config: InvestitionFilterConfig = JSON.parse(filterParams);
      this.store.dispatch(fromActions.updateInvestitionFilterConfig({ config }));
    }

    return true;
  }
}

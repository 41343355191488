import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { of, race } from 'rxjs';

import { RahmenplanModuleState } from '@app/modules/rahmenplan/store/reducers';

import * as actions from '@app/modules/rahmenplan/store/actions/rahmenplan.actions';
import * as selectors from '@app/modules/rahmenplan/store/selectors/rahmenplan.selectors';

@Injectable({ providedIn: 'root' })
export class RahmenplanResolver implements Resolve<Action> {
  constructor(private store: Store<RahmenplanModuleState>, private action$: Actions, private router: Router) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    // Check if rahmenplan is already initialized
    let isInitialized = false;
    this.store
      .select(selectors.isRahmenplanInitialized)
      .pipe(take(1))
      .subscribe((initialized) => {
        isInitialized = initialized;
      });

    if (isInitialized) {
      return of(null);
    }

    this.store.dispatch(actions.initRahmenplanModule());
    const responseOK = this.action$.pipe(ofType(actions.initRahmenplanModuleSuccess));
    const responseError = this.action$.pipe(
      ofType(actions.initRahmenplanModuleFailure),
      tap(() => {
        this.router.navigate(['/']);
      })
    );
    return race(responseOK, responseError).pipe(take(1));
  }
}

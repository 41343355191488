import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { AnalyseNamedSeries } from '@core/portal-api';
import { AnalyseNamedSeriesModel } from '@core/models/analyse-named-series.model';

@Injectable()
export class AnalyseNamedSeriesConverterService implements Converter<AnalyseNamedSeriesModel, AnalyseNamedSeries> {
  convertFromApiModel(analyseNamedSeries: AnalyseNamedSeries): AnalyseNamedSeriesModel {
    if (analyseNamedSeries === null || analyseNamedSeries === undefined) {
      return undefined;
    }

    const analyseNamedSeriesModel: AnalyseNamedSeriesModel = {
      name: analyseNamedSeries.name,
      series: [],
    };

    return analyseNamedSeriesModel;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SbhChatbotService } from '@core/services/sbh-chatbot.service';
import * as fromActions from '@core/store/actions/chatbot.actions';

@Injectable()
export class ChatbotEffects {
  constructor(private actions$: Actions, private sbhChatbotService: SbhChatbotService) {}

  loadChatbotGruppen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadChatbotGruppen),
      switchMap(() =>
        this.sbhChatbotService.getChatbotGruppen().pipe(
          switchMap((gruppen) => [fromActions.loadChatbotGruppenSuccess({ gruppen })]),
          catchError((error) => of(fromActions.loadChatbotGruppenFailure({ error })))
        )
      )
    )
  );
}

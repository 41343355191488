import { filter, map, take, tap } from 'rxjs/operators';
import { CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as userProfileActions from '@app/core/store/actions/user-profile.actions';
import * as fromSelectors from '@app/core/store/selectors/user-profile.selectors';
import * as userConfigActions from '@app/core/store/actions/user-config.actions';
import * as anwenderDataActions from '@app/core/store/actions/anwender-data.actions';
import * as systemKonfigurationActions from '@app/core/store/actions/system-konfiguration.actions';
import * as chatbotActions from '@app/core/store/actions/chatbot.actions';
import * as fromReducer from '@app/core/store/reducers';

@Injectable()
export class PreloadDataGuard implements CanActivate {
  constructor(private store: Store<fromReducer.CoreModuleState>) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loadUserData();
  }

  private loadUserData(): Observable<boolean> {
    return this.store.select(fromSelectors.getUser).pipe(
      map((user) => !!user.emailAddress),
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(userProfileActions.loadProfile());
          this.store.dispatch(userConfigActions.loadUserConfig());
          this.store.dispatch(anwenderDataActions.loadAnwenderData());
          this.store.dispatch(systemKonfigurationActions.loadSystemKonfiguration());
          this.store.dispatch(chatbotActions.loadChatbotGruppen());
        }
      }),
      filter((loaded) => !!loaded),
      take(1)
    );
  }
}

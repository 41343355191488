import { Injectable } from '@angular/core';
import { Converter } from '@app/core/models/converter';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { Anwender } from '@core/portal-api';

@Injectable()
export class AnwenderDataConverter implements Converter<AnwenderDataModel, Anwender> {
  convertFromApiModel(anwender: Anwender): AnwenderDataModel {
    if (anwender === null || anwender === undefined) {
      return undefined;
    }

    const anwenderData: AnwenderDataModel = {
      email: anwender.email,
      internExtern: anwender.internExtern,
      leitzeichen: anwender.leitzeichen,
      firma: anwender.firma,
      anrede: anwender.anrede,
      nachname: anwender.nachname,
      vorname: anwender.vorname,
      festnetznummer: anwender.festnetznummer,
      mobiltelefon: anwender.mobiltelefon,
      gueltig: anwender.gueltig,
      gueltigVon: anwender.gueltigVon,
      gueltigBis: anwender.gueltigBis,
      benutzerName: anwender.benutzerName,
      funktion: anwender.funktion,
      module: anwender.module ? anwender.module : [],
      rollen: [],
    };

    return anwenderData;
  }

  convertToApiModel(anwender: AnwenderDataModel): Anwender {
    if (anwender == null || anwender == undefined) {
      return undefined;
    }

    const anwenderData: Anwender = {
      email: anwender.email,
      internExtern: anwender.internExtern,
      leitzeichen: anwender.leitzeichen,
      firma: anwender.firma,
      anrede: anwender.anrede,
      nachname: anwender.nachname,
      vorname: anwender.vorname,
      festnetznummer: anwender.festnetznummer,
      mobiltelefon: anwender.mobiltelefon,
      gueltig: anwender.gueltig,
      gueltigVon: anwender.gueltigVon,
      gueltigBis: anwender.gueltigBis,
      benutzerName: anwender.benutzerName,
      funktion: anwender.funktion,
      module: anwender.module,
      rollen: [],
    };

    return anwenderData;
  }
}

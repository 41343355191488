import { createAction, props } from '@ngrx/store';
import { ChoiceListItem } from '@app/core/models/choice-list-item.model';
import { AnalyseSeriesModel } from '@core/models/analyse-series.model';
import { AnalyseNamedSeriesModel } from '@core/models/analyse-named-series.model';

export const loadChartData = createAction('[Dashboard] Load chart data');

export const loadChartDataSuccess = createAction(
  '[Dashboard] Load chart data success',
  props<{
    investitionGesamt: AnalyseSeriesModel[];
    investitionJahr: AnalyseNamedSeriesModel[];
    referenzwerte: AnalyseNamedSeriesModel[];
  }>()
);
export const loadChartDataFail = createAction('[Dashboard] Load chart data fail');

export const refreshChartData = createAction('[Dashboard] Refresh chart data');

export const refreshChartDataSuccess = createAction(
  '[Dashboard] Refresh chart data success',
  props<{
    investitionGesamt: AnalyseSeriesModel[];
    investitionJahr: AnalyseNamedSeriesModel[];
    referenzwerte: AnalyseNamedSeriesModel[];
  }>()
);
export const refreshChartDataFail = createAction('[Dashboard] Refresh chart data fail');

export const loadDashboardChoicelists = createAction('[Dashboard] Load dashboard choicelists');
export const loadDashboardChoicelistsSuccess = createAction(
  '[Dashboard] Load dashboard choicelists success',
  props<{
    regionen: ChoiceListItem[];
  }>()
);
export const loadDashboardChoicelistsFail = createAction(
  '[Dashboard] Load dashboard choicelists fail',
  props<{ error: unknown }>()
);

export const initDashboard = createAction('[Dashboard] Init dashboard module');
export const initDashboardSuccess = createAction('[Dashboard] Init dashboard module success');
export const initDashboardFail = createAction('[Dashboard] Init dashboard module fail');

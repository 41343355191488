import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { Vorhabenobject } from '@core/portal-api';
import { Investition } from '@app/core/models/investition.model';
import { ProjektModel } from '@core/models/projekt.model';

@Injectable()
export class InvestitionConverter implements Converter<Investition, Vorhabenobject> {
  convertFromApiModel(vorhaben: Vorhabenobject): Investition {
    if (vorhaben == null) {
      return undefined;
    }

    let projekt: ProjektModel = null;
    if (vorhaben.projekt) {
      projekt = {
        projektNummer: vorhaben.projekt.projektNummer,
        projektName: vorhaben.projekt.projektName,
        projektBeginn: vorhaben.projekt.projektBeginn,
        projektEnde: vorhaben.projekt.projektEnde,
        erpNummer: vorhaben.projekt.erpNummer,
        projektBeschreibung: vorhaben.projekt.projektBeschreibung,
        coorStatus: vorhaben.projekt.coorStatus,
        projektNotiz: vorhaben.projekt.projektNotiz,
        projektAnmerkung: vorhaben.projekt.projektAnmerkung,
      };
    }

    const investition: Investition = {
      id: vorhaben?.vorhabenID ? vorhaben.vorhabenID.toString() : '',
      adresse: vorhaben?.adresse ? vorhaben?.adresse : '',
      projektNummer: vorhaben?.projektnummer ? vorhaben?.projektnummer : '',
      angebotspreis:
        vorhaben?.angebotspreis !== null && vorhaben?.angebotspreis !== undefined ? vorhaben?.angebotspreis : '',
      bauBeginn: vorhaben?.baubeginn ? vorhaben.baubeginn : '1900',
      bauFertigstellung: vorhaben?.baufertigstellung ? vorhaben?.baufertigstellung : '1900',
      bemerkung: vorhaben?.bemerkungprojekt ? vorhaben?.bemerkungprojekt : '',
      beschreibung: vorhaben?.projetbeschreibung ? vorhaben?.projetbeschreibung : '',
      flaechenAnsatz: vorhaben?.fazprojektrealiesierung,
      leistungsphase: vorhaben?.leistungsphase ? vorhaben.leistungsphase.toString() : '',
      mieterBSB: vorhaben?.mieterbsb ? vorhaben?.mieterbsb : '',
      mieterSonstige: vorhaben?.mietersonstige ? vorhaben?.mietersonstige : '',
      programmArt: vorhaben?.flaechenart ? vorhaben?.flaechenart : '',
      projektArt: vorhaben?.projektart ? vorhaben?.projektart : '',
      projektsteuerung: vorhaben?.projektsteuerung ? vorhaben?.projektsteuerung : '',
      gebaeudeNummer: vorhaben?.gebaeude?.gebaeudeNummer ? vorhaben?.gebaeude?.gebaeudeNummer : '',
      gebaeudeName: vorhaben?.gebaeude?.gebaeudeName ? vorhaben?.gebaeude?.gebaeudeName : '',
      gebaeudeAktuellZustandsklasse: vorhaben.gebaeudeklasseAktuell ? vorhaben?.gebaeudeklasseAktuell : '',
      gebaeudeSAPId: vorhaben?.gebaeude?.sapGebaeudeId ? vorhaben?.gebaeude?.sapGebaeudeId : '',
      gebaeudeID: vorhaben?.gebaeude?.gebaeudeId ? vorhaben.gebaeude.gebaeudeId.toString() : '',
      gebaeudeIsAussenanlage: vorhaben?.gebaeude?.gebaeudeNummer
        ? vorhaben.gebaeude.gebaeudeNummer >= 50 && vorhaben?.gebaeude?.gebaeudeNummer <= 59
        : false,
      sondersachverhalt: vorhaben.gebaeude?.bemerkung ? vorhaben.gebaeude?.bemerkung : '',
      nutzer: vorhaben?.nutzer ? vorhaben.nutzer : '',
      kunde: vorhaben.nutzer,
      unterProgrammArt: vorhaben?.unterProgrammArt ? vorhaben.unterProgrammArt : '',
      auspraegungUnterProgrammArt: vorhaben?.auspraegungUnterProgrammArt ? vorhaben.auspraegungUnterProgrammArt : '',
      archived: vorhaben?.archived ? vorhaben.archived : false,
      isBerechnungsartManuell: vorhaben?.berechnungManuell ? vorhaben.berechnungManuell : '',
      schulName: vorhaben?.gebaeude?.schulname ? vorhaben?.gebaeude?.schulname : '',
      auftragStatus: vorhaben.auftragStatus ? vorhaben.auftragStatus : '',
      isEditable: vorhaben.editable ? vorhaben.editable : false,
      isArchivable: vorhaben.archivable ? vorhaben.archivable : false,
      gebaeudeklasseZukunft: vorhaben.gebaeudeklasseZukunft ? vorhaben.gebaeudeklasseZukunft : '',
      eligibleForProjectSuggestion: vorhaben.assignable,
      isCancelable: vorhaben.cancelable,
      includeForFlaechensumme: vorhaben.includeForFlaechensumme,
      preisart: vorhaben.preisart,
      isNeubau: vorhaben?.gebaeude?.isNeubau ? true : false,
      finanzierungsart: vorhaben.finanzierungsart,
      richtwertIntern: String(vorhaben.richtwert ? vorhaben.richtwert : 0),
      richtwertExtern: String(vorhaben.richtwertExt ? vorhaben.richtwertExt : 0),
      letzteAenderungungDatum: vorhaben.letzteAenderungungDatum || '',
      subteilprojektnummer: vorhaben.subteilprojektnummer || '',
      referenzbildungDatum: vorhaben.referenzbildungDatum || '',
      projekt: projekt != null ? projekt : null,
    };

    // Implementierung 7969: Neubau in Auftragsübersicht anzeigen
    // see also InvestitionChoiceListService.getGebaeudesForNewInvestition()
    if (investition.isNeubau && investition.gebaeudeSAPId) {
      if (new RegExp(/^([0-9]+)\/[0-9]$/).test(investition.gebaeudeSAPId)) {
        const gebaeudeNumber = +investition.gebaeudeSAPId.split('/')[1];
        if (gebaeudeNumber > 6) {
          investition.gebaeudeName = 'Sporthalle ' + (gebaeudeNumber - 6);
        } else if (gebaeudeNumber > 3 && gebaeudeNumber <= 6) {
          investition.gebaeudeName = 'Neubau ' + (gebaeudeNumber - 3);
        } else {
          investition.gebaeudeName = 'Schulbau ' + gebaeudeNumber;
        }
      }
    }

    const currentYear = new Date().getFullYear();
    if (vorhaben.baufertigstellung && +vorhaben.baufertigstellung >= currentYear) investition.alteInvestition = false;
    else investition.alteInvestition = true;

    return investition;
  }
}

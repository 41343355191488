/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SystemKonfigurationModulDashboard { 
    /**
     * Flag ob das Dashboard Modul aktiv ist
     */
    modulAktiv?: boolean;
    /**
     * Flag ob Tasks angezeigt werden sollen
     */
    tasksAnzeigenAktiv?: boolean;
    /**
     * Flag ob Workflows angezeigt werden sollen
     */
    workflowsAnzeigenAktiv?: boolean;
    /**
     * Flag ob Favoriten angezeigt werden sollen
     */
    favoritenAnzeigenAktiv?: boolean;
    /**
     * Flag ob Monitor angezeigt werden soll
     */
    monitorAnzeigenAktiv?: boolean;
}


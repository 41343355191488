import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { AnalyseSeriesModel } from '@core/models/analyse-series.model';
import { AnalyseSeries } from '@core/portal-api';

@Injectable()
export class AnalyseSeriesConverterService implements Converter<AnalyseSeriesModel, AnalyseSeries> {
  convertFromApiModel(analyseSeries: AnalyseSeries): AnalyseSeriesModel {
    if (analyseSeries === null || analyseSeries === undefined) {
      return undefined;
    }

    const analyseSeriesModel: AnalyseSeriesModel = {
      name: analyseSeries.name,
      value: analyseSeries.value,
      extra: analyseSeries.extra,
    };

    return analyseSeriesModel;
  }
}

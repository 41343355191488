import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromState from '@core/store/reducers';
import { SbhTaskService } from '@core/services/sbh-task.service';
import * as fromActions from '@core/store/actions/tasks.actions';
import * as fromUserProfileSelector from '@core/store/selectors/user-profile.selectors';
import { TaskCreateModel } from '@core/models/task-create.model';
import * as fromNotificationActions from '@core/store/actions/notifications.actions';
import * as fromSystemKonfigurationActions from '@core/store/actions/system-konfiguration.actions';
import { NotificationType } from '@core/models/notification.model';
import { TaskCloseModel } from '@core/models/task-close.model';

@Injectable()
export class TasksEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromState.CoreModuleState>,
    private service: SbhTaskService
  ) {}

  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTasks),
      withLatestFrom(this.store.select(fromUserProfileSelector.getUserEmail), (_, userID) => userID),
      switchMap((userID) =>
        this.service.loadTasks(userID).pipe(
          map((tasks) => fromActions.loadTasksSuccess({ tasks })),
          catchError(() => of(fromActions.loadTasksFail()))
        )
      )
    )
  );

  startTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startTask),
      map((action) => action.taskCreate),
      switchMap((taskCreate: TaskCreateModel) =>
        this.service.createTask(taskCreate).pipe(
          map(() => fromActions.startTaskSuccess()),
          catchError((error) => of(fromActions.startTaskFail({ error })))
        )
      )
    )
  );

  startTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startTaskSuccess),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Workflow wurde erfolgreich gestartet', type: NotificationType.SUCCESS },
        }),
        fromActions.loadTasks(),
        fromSystemKonfigurationActions.loadSystemKonfiguration(),
      ])
    )
  );

  startTaskFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.startTaskFail),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Workflow konnte nicht angelegt werden', type: NotificationType.ERROR },
        }),
      ])
    )
  );

  closeTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeTask),
      map((action) => action.taskClose),
      switchMap((taskClose: TaskCloseModel) =>
        this.service.closeTask(taskClose).pipe(
          map(() => fromActions.closeTaskSuccess()),
          catchError((error) => of(fromActions.closeTaskFail({ error })))
        )
      )
    )
  );

  closeTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeTaskSuccess),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Aufgabe wurde erfolgreich abgeschlossen', type: NotificationType.SUCCESS },
        }),
        fromActions.loadTasks(),
        fromSystemKonfigurationActions.loadSystemKonfiguration(),
      ])
    )
  );

  closeTaskFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.closeTaskFail),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Aufgabe konnte nicht abgeschlossen werden', type: NotificationType.ERROR },
        }),
      ])
    )
  );
}

import {
  DatatableConfigState,
  GebaeudeColumnsConfig,
  InvestitionColumnsConfig,
  ProjekteColumnsConfig,
} from '@app/core/models/datatable-config.model';
import {
  GebaeudeFilterConfig,
  InvestitionFilterConfig,
  ProjekteFilterConfig,
} from '@app/core/models/filter-config.model';
import { createAction, props } from '@ngrx/store';

export const loadUserConfig = createAction('[Core/Config] Load user specific configuration');
export const loadUserConfigSuccess = createAction(
  '[Core/Config] Load user specific configuration success',
  props<{ config: DatatableConfigState }>()
);
export const loadUserConfigFail = createAction(
  '[Core/Config] Load user specific configuration fail',
  props<{ error: unknown }>()
);

export const setTableConfigValues = createAction(
  '[Core/Config] Set the table config with minimal values',
  props<{ config: DatatableConfigState }>()
);

export const updateInvestitionTableConfig = createAction(
  '[Core/Config] Update investition table config in the store',
  props<{ config: InvestitionColumnsConfig }>()
);

export const updateGebaeudeTableConfig = createAction(
  '[Core/Config] Update gebaeude table config in the store',
  props<{ config: GebaeudeColumnsConfig }>()
);

export const updateProjekteTableConfig = createAction(
  '[Core/Config] Update projekte table config in the store',
  props<{ config: ProjekteColumnsConfig }>()
);

export const resetGebaeudeTableConfig = createAction('[Core/Config] Reset gebaeude table config');
export const resetGebaeudeTableConfigSuccess = createAction('[Core/Config] Reset gebaeude table config success');
export const resetGebaeudeTableConfigFail = createAction(
  '[Core/Config] Reset gebaeude table config fail',
  props<{ error: unknown }>()
);

export const resetInvestitionTableConfig = createAction('[Core/Config] Reset investition table config');
export const resetInvestitionTableConfigSuccess = createAction('[Core/Config] Reset investition table config success');
export const resetInvestitionTableConfigFail = createAction(
  '[Core/Config] Reset investition table config fail',
  props<{ error: unknown }>()
);

export const resetProjekteTableConfig = createAction('[Core/Config] Reset projekte table config');
export const resetProjekteTableConfigSuccess = createAction('[Core/Config] Reset projekte table config success');
export const resetProjekteTableConfigFail = createAction(
  '[Core/Config] Reset projekte table config fail',
  props<{ error: unknown }>()
);

export const saveInvestitionTableConfig = createAction('[Core/Config] Save investition table configuration');
export const saveInvestitionTableConfigSuccess = createAction(
  '[Core/Config] Save investition table configuration success'
);
export const saveInvestitionTableConfigFail = createAction(
  '[Core/Config] Save investition table configuration fail',
  props<{ error: unknown }>()
);

export const saveGebaeudeTableConfig = createAction('[Core/Config] Save gebaeude table configuration');
export const saveGebaeudeTableConfigSuccess = createAction('[Core/Config] Save gebaeude table configuration success');
export const saveGebaeudeTableConfigFail = createAction(
  '[Core/Config] Save gebaeude table configuration fail',
  props<{ error: unknown }>()
);

export const saveProjekteTableConfig = createAction('[Core/Config] Save projekte table configuration');
export const saveProjekteTableConfigSuccess = createAction('[Core/Config] Save projekte table configuration success');
export const saveProjekteTableConfigFail = createAction(
  '[Core/Config] Save projekte table configuration fail',
  props<{ error: unknown }>()
);

export const updateInvestitionFilterConfig = createAction(
  '[Core/Config] Update investition filter config in the store',
  props<{ config: InvestitionFilterConfig }>()
);

export const updateProjekteFilterConfig = createAction(
  '[Core/Config] Update projekte filter config in the store',
  props<{ config: ProjekteFilterConfig }>()
);

export const updateGebaeudeFilterConfig = createAction(
  '[Core/Config] Update gebaeude filter config in the store',
  props<{ config: GebaeudeFilterConfig }>()
);
export const resetInvestitionFilterConfig = createAction(
  '[Core/Config] Reset the investition filter config in the store'
);
export const resetProjektFilterConfig = createAction('[Core/Config] Reset the projekt filter config in the store');

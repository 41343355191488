/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Dataobjet eines Projekts
 */
export interface ProjektObject { 
    /**
     * Eindeutige Nummer eines Projekts
     */
    projektNummer?: string;
    /**
     * Name des Projekts
     */
    projektName?: string;
    /**
     * Datum des Projektbeginns (YYYY-MM-DD)
     */
    projektBeginn?: string;
    /**
     * Datum des Projektendes (YYYY-MM-DD)
     */
    projektEnde?: string;
    /**
     * ERP Nummer des Projekts
     */
    erpNummer?: string;
    /**
     * Beschreibung des Projekts
     */
    projektBeschreibung?: string;
    /**
     * COOR Status des Projekt
     */
    coorStatus?: string;
    /**
     * Notiz zum Projekts
     */
    projektNotiz?: string;
    /**
     * Anmerkung zum Projekt
     */
    projektAnmerkung?: string;
}


import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { ChatbotGruppe } from '@core/portal-api';
import { ChatbotGruppeModel } from '@core/models/chatbot-gruppe.model';

@Injectable()
export class ChatbotGruppeConverter implements Converter<ChatbotGruppeModel, ChatbotGruppe> {
  convertFromApiModel(chatbotGruppe: ChatbotGruppe): ChatbotGruppeModel {
    if (chatbotGruppe === null || chatbotGruppe === undefined) {
      return undefined;
    }

    const chatbotGruppeModel: ChatbotGruppeModel = {
      id: chatbotGruppe.id,
      label: chatbotGruppe.label,
      position: chatbotGruppe.position,
      eintragList: [],
    };

    if (chatbotGruppe.eintragList) {
      chatbotGruppe.eintragList.forEach((value) => {
        chatbotGruppeModel.eintragList.push({
          id: value.id,
          schlagwort: value.schlagwort,
          frage: value.frage,
          antwort: value.antwort,
          gruppeID: value.gruppeID,
        });
      });
    }

    return chatbotGruppeModel;
  }
}

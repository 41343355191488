export * from './administration.service';
import { AdministrationService } from './administration.service';
export * from './analysen.service';
import { AnalysenService } from './analysen.service';
export * from './anwender.service';
import { AnwenderService } from './anwender.service';
export * from './berechtigung.service';
import { BerechtigungService } from './berechtigung.service';
export * from './chatbot.service';
import { ChatbotService } from './chatbot.service';
export * from './choiceLists.service';
import { ChoiceListsService } from './choiceLists.service';
export * from './dokumente.service';
import { DokumenteService } from './dokumente.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './kommunikation.service';
import { KommunikationService } from './kommunikation.service';
export * from './konfiguration.service';
import { KonfigurationService } from './konfiguration.service';
export * from './logging.service';
import { LoggingService } from './logging.service';
export * from './projekt.service';
import { ProjektService } from './projekt.service';
export * from './projekte.service';
import { ProjekteService } from './projekte.service';
export * from './stammdaten.service';
import { StammdatenService } from './stammdaten.service';
export * from './task.service';
import { TaskService } from './task.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './vorhaben.service';
import { VorhabenService } from './vorhaben.service';
export * from './wizardRules.service';
import { WizardRulesService } from './wizardRules.service';
export const APIS = [AdministrationService, AnalysenService, AnwenderService, BerechtigungService, ChatbotService, ChoiceListsService, DokumenteService, ExportService, KommunikationService, KonfigurationService, LoggingService, ProjektService, ProjekteService, StammdatenService, TaskService, UserService, VorhabenService, WizardRulesService];

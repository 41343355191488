import { createSelector } from '@ngrx/store';
import { encode } from 'js-base64';

import * as coreState from '@core/store/reducers';

export const getUser = createSelector(
  coreState.getCoreModuleState,
  (state: coreState.CoreModuleState) => state.userProfile
);

export const getUserEmail = createSelector(getUser, (profile) => profile.emailAddress);

export const getAnwenderEmailBase64 = createSelector(getUserEmail, (email) => {
  const base64Email = encode(email);
  return base64Email;
});

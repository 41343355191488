import { ValidationErrors } from 'ngrx-forms';

export function germanNumberStringMaxLength(
  len: number
): <T extends number | null | undefined>(value: T) => ValidationErrors {
  return <T extends number | null | undefined>(value: T): ValidationErrors => {
    if (value == null) {
      return {};
    }
    if (value && /^\d{1,3}(\.\d{3})*(\,\d+)?$/.test(String(value))) {
      const currentLength = String(value).replace(/\./gi, '').replace(',', '').length;
      if (currentLength <= len) {
        return {};
      }
    } else if (value && /^\d+$/.test(String(value))) {
      const currentLength = String(value).length;
      if (currentLength <= len - 2) {
        return {};
      }
    } else if (!/^\d+$/.test(String(value))) {
      return {};
    }

    return {
      validNumberStringMaxLength: {
        actual: value,
        expected: len,
      },
    };
  };
}

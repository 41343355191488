import { Injectable } from '@angular/core';

import { UserProfile } from '../models/user-profile.model';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';

@Injectable()
export class UserProfileService {
  constructor(private keycloakService: KeycloakService) {}

  getCurrentUsersProfile() {
    return this.convertUserProfileData(this.keycloakService.getKeycloakInstance().tokenParsed);
  }

  private convertUserProfileData(data: KeycloakTokenParsed): UserProfile {
    const result = {
      authUserID: '',
      id: '',
      firstName: data['given_name'],
      lastName: data['family_name'],
      displayName: data['given_name'] + ',' + data['family_name'],
      emailAddress: data['email'],
      userSettings: '',
      userName: data['preferred_username'],
      userRoles: data['realm_access'].roles,
    };
    return result;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export type AuthConfig = {
  realm: string;
  clientID: string;
  url: string;
};

export type AppConfig = {
  roleEnabled: boolean;
  readRoleName: string[];
  writeRoleName: string[];
  approveRoleName: string[];
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private authConfig: AuthConfig;
  private appConfig: AppConfig;

  constructor(private http: HttpClient) {}

  public getAuthConfig() {
    return this.authConfig;
  }

  public getAppConfig() {
    return this.appConfig;
  }

  public loadAuthConfig() {
    return this.http
      .get<AuthConfig>(`${environment.assetsUrl}/config/keycloak.json`)
      .toPromise()
      .then((data) => {
        this.authConfig = data;
      });
  }

  public loadAppConfig() {
    return this.http
      .get<AppConfig>(`${environment.assetsUrl}/config/app.json`)
      .toPromise()
      .then((data) => {
        this.appConfig = data;
      });
  }
}

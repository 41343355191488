import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  JsonPatchDocument,
  ResponseObject,
  TaskGenericCreate,
  TaskGenericMetaData,
  TaskService,
} from '@core/portal-api';

import { UserProfileService } from '@core/services/user-profile.service';
import { convertDateStringToDate, convertDateToIsoString } from '@shared/utils';
import { TaskEditModel } from '../models/task-edit.model';
import { ProjectProposalModel } from '../models/project-proposal.model';
import { InvestitionTechnical } from '../models/investition-technical.model';
import { TaskModel } from '@core/models/task.model';
import { TasksConverter } from '@core/converters/tasks-converter.service';
import { TaskCreateModel } from '@core/models/task-create.model';
import { TaskCloseModel } from '@core/models/task-close.model';
import TaskTypeEnum = TaskGenericCreate.TaskTypeEnum;

@Injectable()
export class SbhTaskService {
  constructor(
    private taskService: TaskService,
    private userService: UserProfileService,
    private converter: TasksConverter
  ) {}

  loadTasks(userID: string): Observable<TaskModel[]> {
    return this.taskService.getGenericTaskList(userID).pipe(
      map((res) => {
        const tasks: TaskModel[] = [];
        if (!res.data) res.data = [];
        res.data.forEach((task) => {
          const taskModel = this.converter.convertFromApiModel(task);
          if (task.subtask) {
            task.subtask.forEach((subtask) => {
              taskModel.subtasks.push(this.converter.convertFromApiModel(subtask));
            });
          }
          tasks.push(taskModel);
        });
        return tasks;
      })
    );
  }

  updateTask(data: TaskEditModel) {
    const body = [
      {
        op: JsonPatchDocument.OpEnum.Replace,
        path: '/' + data.taskId,
        value:
          data.taskType === TaskGenericMetaData.TaskTypeEnum.ProjektvorschlagPruefen
            ? {
                genehmigungsstatus: data.status,
                kommentar: data.comment,
              }
            : data.taskType === TaskGenericMetaData.TaskTypeEnum.ProjektvorschlagAnlegen
            ? { projektnummer: data.projectNumber }
            : {},
      },
    ];
    return this.taskService.patchGenericTask(data.taskId, body);
  }

  createTask(data: TaskCreateModel): Observable<ResponseObject> {
    const body: TaskGenericCreate = {
      taskType: data.taskType,
      absenderID: this.userService.getCurrentUsersProfile().emailAddress,
      empfaengerID: this.userService.getCurrentUsersProfile().emailAddress,
      faelligkeitsDatum: convertDateToIsoString(convertDateStringToDate(data.faelligkeitsDatum)),
    };
    return this.taskService.postGenericTask(body);
  }

  closeTask(data: TaskCloseModel): Observable<ResponseObject> {
    const body = [
      {
        op: JsonPatchDocument.OpEnum.Replace,
        path: '/' + data.taskID,
        value: {
          genehmigungsstatus: data.genehmigungStatus,
          kommentar: data.kommentar,
        },
      },
    ];
    return this.taskService.patchGenericTask(data.taskID, body);
  }

  createProjectProposition(data: ProjectProposalModel) {
    const body: TaskGenericCreate = {
      taskType: TaskTypeEnum.ProjektvorschlagPruefen,
      absenderID: this.userService.getCurrentUsersProfile().emailAddress,
      empfaengerID: data.recipient,
      faelligkeitsDatum: convertDateToIsoString(convertDateStringToDate(data.dueDate)),
      auftragIDList: data.projectIdList,
    };
    return this.taskService.postGenericTask(body);
  }

  createInvestitionCancelTask(dto: InvestitionTechnical): Observable<ResponseObject> {
    const serverModel: TaskGenericCreate = {
      taskType: TaskTypeEnum.AuftragsabbruchPruefen,
      absenderID: this.userService.getCurrentUsersProfile().emailAddress,
      empfaengerID: this.userService.getCurrentUsersProfile().emailAddress,
      auftragIDList: [dto.id],
    };
    return this.taskService.postGenericTask(serverModel);
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Projekt Informationen
 */
export interface Vorhaben { 
    /**
     * Eindeutige ID des Auftrags (UUID)
     */
    vorhabenID?: string;
    /**
     * Eindeutige Prozess ID
     */
    processID?: string;
    /**
     * Eindeutige Projektart ID
     */
    projektartID: number;
    /**
     * Eindeutige Programmart ID
     */
    programmartID: number;
    /**
     * Eindeutige Unterrogrammart ID
     */
    unterprogrammartID?: number;
    /**
     * Eindeutige Unterprogramm Auspraegung ID
     */
    auspraegungUnterprogrammartID?: number;
    /**
     * Eindeutige Nutzer ID
     */
    nutzerID?: number;
    /**
     * Die Beschreibung des Projekts
     */
    beschreibung: string;
    /**
     * Die gesamte Projektflaeche (decimal 9,2)
     */
    projektflaecheGesamt?: number;
    /**
     * Die BSB Projektflaeche (decimal 9,2)
     */
    projektflaecheBSB?: number;
    /**
     * Sonstige Projektflaeche (decimal 9,2)
     */
    projektflaecheSonstige?: number;
    /**
     * Datum des Baubeginns (YYYY-MM-DD)
     */
    baubeginn: string;
    /**
     * Datum der Baufertigstellung (YYYY-MM-DD)
     */
    baufertigstellung: string;
    /**
     * Datum der Abrechnung (YYYY-MM-DD)
     */
    abrechnungsDatum?: string;
    /**
     * Erfolgt die Projektsteuerung extern?
     */
    projektsteuerungExtern?: boolean;
    /**
     * Erfolgt die Berechnung manuell?
     */
    berechnungManuell?: boolean;
    /**
     * Das gesamte Budget (decimal 11,2)
     */
    budgetGesamt?: number;
    /**
     * Automatisch ermittelter Richtwert (decimal 11,2)
     */
    richtwert?: number;
    /**
     * Automatisch ermittelter Richtwert externePs (decimal 11,2)
     */
    richtwertExt?: number;
    /**
     * Die aktuelle Gebaeudeklasse
     */
    gebaeudeklasseAktuell?: number;
    /**
     * Die zukuenftige Gebaeudeklasse
     */
    gebaeudeklasseZukunft?: number;
    /**
     * Die zukuenftige Gebaeudeflaeche (decimal 9,2)
     */
    gebaeudeflaecheZukunft?: number;
    /**
     * Bemerkung zum Gebaeude
     */
    gebaeudeBemerkung?: string;
    /**
     * Die zukuenftige BSB Mietflaeche (decimal 9,2)
     */
    mietflaecheBSBZukunft?: number;
    /**
     * Die zukuenftige sonstige Mietflaeche (decimal 9,2)
     */
    mietflaecheSonstigeZukunft?: number;
    /**
     * Die HH ID des Bezirks
     */
    bezirkHHID?: number;
    /**
     * Die ID der Region
     */
    regionID?: number;
    /**
     * Die ID der Wirtschaftseinheit
     */
    weID?: string;
    /**
     * Die ID des Gebaeudes
     */
    gebaeudeID?: string;
    /**
     * Die ID des Gebaeudetyps
     */
    gebaeudetypID?: number;
    /**
     * Die BSB ID der Region
     */
    regionBSBID?: number;
    /**
     * Die BSB ID der Schule
     */
    schuleBSBID?: number;
    /**
     * Die BSB ID der Schulart
     */
    schulartBSBID?: string;
    /**
     * Die BSB ID der Schulform
     */
    schulformBSBID?: string;
    /**
     * Die BKS Projekt ID
     */
    projektIDBKS?: string;
    /**
     * Die BKS ID des Teilprojekts
     */
    teilpropjektIDBKS?: string;
    /**
     * Die BKS ID der Subteil Region
     */
    subteilprojektIDBKS?: string;
    /**
     * Eindeutige Preisart ID
     */
    preisart?: number;
    /**
     * Eindeutige Finanzierungsart ID
     */
    finanzierungsart?: number;
    /**
     * Der Benutzer, der das Projekt erfasst hat
     */
    erfasstVonBenutzer?: string;
    /**
     * Das Datum der Erfassung (YYYY-MM-DD hh:mm:ss.nnn)
     */
    erfasstAm?: string;
    /**
     * Die letzte aenderung des Benutzers
     */
    letzteAenderungVonBenutzer?: string;
    /**
     * Das Datum der letzten aenderung (YYYY-MM-DD hh:mm:ss.nnn)
     */
    letzteAenderungAm?: string;
    /**
     * Handelt es sich um ein Archivprojekt
     */
    archivProjekt?: boolean;
    /**
     * Datum der Referendzbildung (YYYY-MM-DD)
     */
    referenzbildungDatum?: string;
}


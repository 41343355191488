import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements OnInit {
  @Output() clickOutside = new EventEmitter<void>();

  captured = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onClick(target: any) {
    // Ignore the first click which opens the panel
    if (!this.captured) {
      return;
    }

    const clickedInside =
      this.elementRef.nativeElement.contains(target) ||
      this.elementRef.nativeElement.innerHTML.includes(target.outerHTML) ||
      target.className.includes('ng-option-label') ||
      target.className.includes('ng-option') ||
      target.className.includes('mat-checkbox-inner-container') ||
      target.className.includes('left');

    // Ignore clicks on icons
    const clickedOnIcon = target instanceof SVGPathElement || target.getAttribute('data-click-out-ignore');

    if (!clickedInside && !clickedOnIcon) {
      this.clickOutside.emit();
    }
  }

  ngOnInit() {
    fromEvent(document, 'click', { capture: true })
      .pipe(take(1))
      .subscribe(() => (this.captured = true));
  }
}

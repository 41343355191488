import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { DokumentGruppe } from '@core/portal-api';
import { DokumentGruppeModel } from '@core/models/dokument-gruppe.model';

@Injectable()
export class DokumentGruppeConverter implements Converter<DokumentGruppeModel, DokumentGruppe> {
  convertFromApiModel(dokumentGruppe: DokumentGruppe): DokumentGruppeModel {
    if (dokumentGruppe === null || dokumentGruppe === undefined) {
      return undefined;
    }

    const dokumentGruppeModel: DokumentGruppeModel = {
      id: dokumentGruppe.id,
      name: dokumentGruppe.name,
      position: dokumentGruppe.position,
      eintraege: [],
    };

    if (dokumentGruppe.eintraege) {
      dokumentGruppe.eintraege.forEach((value) => {
        dokumentGruppeModel.eintraege.push({
          id: value.id,
          name: value.name,
          link: value.link,
          beschreibung: value.beschreibung,
          zoomLevel: value.zoomLevel,
          sequenzAnzahl: value.sequenzAnzahl,
          position: value.position,
        });
      });
    }

    return dokumentGruppeModel;
  }
}

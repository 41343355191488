import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskModel } from '@core/models/task.model';
import { TaskGenericMetaData } from '@core/portal-api';

@Component({
  selector: 'app-modal-workflow-details',
  templateUrl: './modal-workflow-details.component.html',
  styleUrls: ['./modal-workflow-details.component.scss'],
})
export class ModalWorkflowDetailsComponent implements OnInit {
  selectedWorkflow: TaskModel;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onCancel() {
    this.activeModal.dismiss('cancel');
  }

  getSubtaskStatus(selectedTask: TaskModel): string {
    if (selectedTask.subtasks) {
      const totalSubtasks = selectedTask.subtasks.length;
      const closedSubtasks = selectedTask.subtasks.filter(
        (task) => task.status === TaskGenericMetaData.StatusEnum.Geschlossen
      ).length;

      return '(' + closedSubtasks + '/' + totalSubtasks + ' abgeschlossen)';
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyseSeriesModel } from '@core/models/analyse-series.model';
import { AnalysenService } from '@core/portal-api';
import { AnalyseSeriesConverterService } from '@core/converters/analyse-series-converter.service';
import { AnalyseNamedSeriesConverterService } from '@core/converters/analyse-named-series-converter.service';
import { AnalyseNamedSeriesModel } from '@core/models/analyse-named-series.model';

@Injectable()
export class ChartsService {
  constructor(
    private analysenService: AnalysenService,
    private analyseSeriesConverter: AnalyseSeriesConverterService,
    private analyseNamedSeriesConverter: AnalyseNamedSeriesConverterService
  ) {}

  loadAnalyseSeries(chartTyp: string): Observable<AnalyseSeriesModel[]> {
    return this.analysenService.getAnalyseSeries(chartTyp).pipe(
      map((res) => {
        const analyseSeriesModelList: AnalyseSeriesModel[] = [];
        if (!res.data) res.data = [];
        res.data.forEach((series) => {
          analyseSeriesModelList.push(this.analyseSeriesConverter.convertFromApiModel(series));
        });
        return analyseSeriesModelList;
      })
    );
  }

  loadAnalyseNamedSeries(chartTyp: string): Observable<AnalyseNamedSeriesModel[]> {
    return this.analysenService.getAnalyseNamedSeries(chartTyp).pipe(
      map((res) => {
        const analyseNamedSeriesModelList: AnalyseNamedSeriesModel[] = [];
        if (!res.data) res.data = [];
        res.data.forEach((series) => {
          const analyseNamedSeries = this.analyseNamedSeriesConverter.convertFromApiModel(series);
          series.series.forEach((value) => {
            analyseNamedSeries.series.push(this.analyseSeriesConverter.convertFromApiModel(value));
          });
          analyseNamedSeriesModelList.push(analyseNamedSeries);
        });
        return analyseNamedSeriesModelList;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { encode, decode } from 'js-base64';

import { FavoriteModel, FavoriteStructure } from '../models/favorite.model';
import { Converter } from '@app/core/models/converter';
import { IsJsonString } from '@app/shared/utils';

import * as fromAPI from '@core/portal-api';

@Injectable()
export class FavoritesConverter implements Converter<FavoriteModel, fromAPI.AnwenderFavorit> {
  convertFromApiModel(favorite: fromAPI.AnwenderFavorit): FavoriteModel {
    if (favorite === null || favorite === undefined || Object.keys(favorite).length === 0) {
      return undefined;
    }
    const str = decode(favorite.referenzStruktur);

    if (IsJsonString(str)) {
      const result: FavoriteModel = {
        id: favorite.favoritID,
        referenceStructure: JSON.parse(str) as FavoriteStructure,
        referenceType: favorite.referenzTyp,
        createdOn: favorite.erstelltAm,
        description: favorite.beschreibung,
      };
      return result;
    } else {
      return undefined;
    }
  }
  convertToApiModel(favorite: FavoriteModel): fromAPI.AnwenderFavorit {
    if (favorite === null || favorite === undefined) {
      return undefined;
    }

    const str = JSON.stringify(favorite.referenceStructure) || '';
    const referenceStructure = encode(str);

    const result: fromAPI.AnwenderFavorit = {
      favoritID: favorite.id,
      referenzStruktur: referenceStructure,
      referenzTyp: favorite.referenceType,
      erstelltAm: favorite.createdOn,
      beschreibung: favorite.description,
    };

    return result;
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Rolle eines Anwenders innerhalb einer Region oder WE
 */
export interface AnwenderRolle { 
    ebene?: AnwenderRolle.EbeneEnum;
    /**
     * Eindeutige Objekt ID der Region oder WE (je nach ebene)
     */
    referenzObjektID?: string;
    /**
     * Die ID der Rolle
     */
    rolleID?: string;
}
export namespace AnwenderRolle {
    export type EbeneEnum = 'REGION' | 'WIRTSCHAFTSEINHEIT' | 'ALLE';
    export const EbeneEnum = {
        Region: 'REGION' as EbeneEnum,
        Wirtschaftseinheit: 'WIRTSCHAFTSEINHEIT' as EbeneEnum,
        Alle: 'ALLE' as EbeneEnum
    };
}



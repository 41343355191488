/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Action, combineReducers, createReducer, on } from '@ngrx/store';
import {
  createFormGroupState,
  disable,
  enable,
  formGroupReducer,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  SetErrorsAction,
  setValue,
  SetValueAction,
  UnfocusAction,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { greaterThanOrEqualTo, maxLength, minLength, notEqualTo, required } from 'ngrx-forms/validation';
import { germanNumberStringGreaterThan, germanNumberStringMaxLength, validNumberString } from '@shared/validators';

import { convertNumberToFormattedString } from '@shared/utils';
import { InvestitionUI, InvestitionData, initialInvestitionFormState } from '@app/core/models/investition-state.model';
import { InvestitionTechnical } from '@app/core/models/investition-technical.model';

import * as fromInvestitionAction from '@app/modules/rahmenplan/store/actions/investition.actions';
import * as fromRahmenPlanAction from '@app/modules/rahmenplan/store/actions/rahmenplan.actions';

import { Kunden, Projektart } from '@app/core/constants/wizard-rules-matrix';

export const FORM_ID = 'NEW_PROJECT_ORDER_FORM_ID';
export const FORM_FIELD_DESCRIPTION_MAX_LENGTH = 100;
import { ProgrammartWizardRule, WizardRule } from '@core/portal-api';
import { WizardFieldsConfig } from '@core/models/wizard-fields-config.model';

export interface InvestitionState {
  data: InvestitionData;
  ui: InvestitionUI;
}

const initDataModel: InvestitionData = {
  investition: null,
  investitionTechnical: {
    id: null,
    projektart: null,
    schule: null,
    programmart: null,
    unterprogrammart: null,
    auspraegungUnterprogrammart: null,
    beschreibung: null,
    baubeginn: new Date().getFullYear(),
    bauFertigstellung: new Date().getFullYear(),
    abrechnungsDatum: null,
    flaechenAnsatz: 0,
    gebaeudeKlasseZukunft: '0',
    kunde: null,
    isBerechnungsartManuell: 'false',
    angebotspreis: null,
    richtwert: null,
    richtwertExt: null,
    berechnungsformel: null,
    sondersachverhalt: null,
    gebaeude: null,
    preisart: null,
    finanzierungsart: null,
    projektIDBKS: null,
  },
};

const initUIModel: InvestitionUI = {
  isDeleting: false,
  isAborting: false,
  isSaving: false,
  triedSaving: false,
  choiceListLoaded: false,
  hasEmptyChoiecList: false,
  isCalculating: false,
  form: createFormGroupState<InvestitionTechnical>(FORM_ID, initialInvestitionFormState),
  wizardrules: [],
  prognose: null,
};

const projectOrderDataReducer = createReducer(
  initDataModel,
  on(fromInvestitionAction.showInvestitionAction, (state, { pOrder }) => ({
    ...state,
    investition: pOrder ? pOrder : state.investition,
  })),
  on(fromInvestitionAction.loadInvestitionTechnicalSuccess, (state, { investition: dto }) => {
    return {
      ...state,
      investitionTechnical: dto,
    };
  }),
  on(fromInvestitionAction.loadInvestitionSuccess, (state, { po }) => {
    return {
      ...state,
      investition: po,
    };
  }),
  on(fromInvestitionAction.calculateInvestitionPriceSuccess, (state, { priceOffer }) => ({ ...state, priceOffer })),
  on(fromInvestitionAction.resetPriceOffer, (state) => ({ ...state, priceOffer: null })),
  on(fromInvestitionAction.resetState, () => initDataModel)
);

function getWizardConfig(wizardrules: WizardRule[], kunde: number, projektart: number, programmart: number) {
  const wizardrulestmp = wizardrules;
  let wizardfields;
  let programmartWizardRule: ProgrammartWizardRule;

  if (kunde && projektart && programmart) {
    wizardfields = wizardrulestmp.filter((wizardrule) => {
      if (wizardrule.kundeId === +kunde && wizardrule.projektart.projektartID === +projektart) {
        return wizardrule;
      }
    });

    for (let i = 0; i < wizardfields[0]['projektart'].programmarten.length; i++) {
      if (wizardfields[0]['projektart'].programmarten[i].programmartId == programmart) {
        programmartWizardRule = wizardfields[0]['projektart'].programmarten[i];
      }
    }

    // Passing the negated boolean values because the array with hidden fields is smaller
    return {
      showPreisart: programmartWizardRule.wizardfields.preisart,
      showGebaeudeklasse: programmartWizardRule.wizardfields.gebaeudeklasse,
      showUnterprogrammart: programmartWizardRule.wizardfields.unterProgrammart,
      showRichtwert: programmartWizardRule.wizardfields.richtwert,
    } as WizardFieldsConfig;
  }
}

const projectOderUIReducer = createReducer(
  initUIModel,
  onNgrxForms(),
  on(fromRahmenPlanAction.initRahmenplanModuleSuccess, (state, { wizardrules }) => ({
    ...state,
    wizardrules: wizardrules,
  })),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    let form = state.form;

    if (action.controlId === form.controls.projektart.id) {
      form = formGroupReducer(form, new SetValueAction(form.controls.programmart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.unterprogrammart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.auspraegungUnterprogrammart.id, undefined));
    }

    if (action.controlId === form.controls.programmart.id) {
      form = formGroupReducer(form, new SetValueAction(form.controls.unterprogrammart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.auspraegungUnterprogrammart.id, undefined));

      const { kunde, projektart, programmart } = form.value;
      let richtwert = false;

      if (kunde && projektart && programmart) {
        richtwert = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showRichtwert;
      }

      if (richtwert) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwert.id, {
            required: { actual: null },
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 },
          })
        );
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwertExt.id, {
            required: { actual: null },
            validNumberStringGreaterThan: { actual: form.value.richtwertExt, expected: 0 },
          })
        );
      }
    }

    if (action.controlId === form.controls.richtwert.id) {
      if (form.value.kunde && form.value.projektart && form.value.programmart) {
        const richtwert = getWizardConfig(
          state.wizardrules,
          +form.value.kunde,
          +form.value.projektart,
          +form.value.programmart
        ).showRichtwert;

        if (richtwert && +form.value.richtwert < 0) {
          new SetErrorsAction(form.controls.richtwert.id, {
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 }, //greaterthan
          });
        }
        if (richtwert && form.value.richtwert != null && +form.value.richtwert > 0) {
          form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
        }
      }
    }

    if (action.controlId === form.controls.richtwertExt.id) {
      if (form.value.kunde && form.value.projektart && form.value.programmart) {
        const richtwert = getWizardConfig(
          state.wizardrules,
          +form.value.kunde,
          +form.value.projektart,
          +form.value.programmart
        ).showRichtwert;

        if (richtwert && +form.value.richtwertExt < 0) {
          form = formGroupReducer(
            form,
            new SetErrorsAction(form.controls.richtwertExt.id, {
              validNumberStringGreaterThan: { actual: form.value.richtwertExt, expected: 0 },
            })
          );
        }
        if (richtwert && form.value.richtwertExt == null) {
          form = formGroupReducer(
            form,
            new SetErrorsAction(form.controls.richtwertExt.id, {
              required: { actual: null },
            })
          );
        }
        if (richtwert && form.value.richtwertExt != null && +form.value.richtwertExt >= 0) {
          form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
        }
      }
    }

    if (action.controlId === form.controls.kunde.id) {
      if (form.value.kunde !== Kunden.BSB && form.value.kunde !== Kunden.HIBB) {
        form = formGroupReducer(form, new SetValueAction(form.controls.schule.id, undefined));
      }
      form = formGroupReducer(form, new SetValueAction(form.controls.projektart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.programmart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.unterprogrammart.id, undefined));
      form = formGroupReducer(form, new SetValueAction(form.controls.auspraegungUnterprogrammart.id, undefined));
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
    }

    if (action.controlId === form.controls.projektart.id) {
      if (form.value.kunde === Kunden.BSB || form.value.kunde === Kunden.HIBB) {
        if (form.value.projektart === Projektart.Zubau || form.value.projektart === Projektart.Ersatzbau) {
          form = formGroupReducer(form, new SetValueAction(form.controls.gebaeudeKlasseZukunft.id, '1'));
        } else if (form.value.projektart === Projektart.Sanierung) {
          form = formGroupReducer(form, new SetValueAction(form.controls.gebaeudeKlasseZukunft.id, '2'));
        } else if (
          form.value.projektart === Projektart.Umbau ||
          form.value.projektart === Projektart.Sonderbestellung
        ) {
          form = formGroupReducer(form, new SetValueAction(form.controls.gebaeudeKlasseZukunft.id, '0'));
        } else {
          // form = formGroupReducer(form, new SetValueAction(form.controls.gebaeude.id, choicelists.gebaeude[0].id));
        }
      } else {
        form = formGroupReducer(form, new SetValueAction(form.controls.gebaeudeKlasseZukunft.id, '0'));
      }

      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
    }

    if (action.controlId === form.controls.beschreibung.id) {
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
    }
    if (action.controlId === form.controls.flaechenAnsatz.id) {
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
      form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
    }

    const triggerPriceCalFieldArray = [
      state.form.controls.projektart.id,
      state.form.controls.programmart.id,
      state.form.controls.unterprogrammart.id,
      state.form.controls.auspraegungUnterprogrammart.id,
      state.form.controls.flaechenAnsatz.id,
      state.form.controls.gebaeudeKlasseZukunft.id,
      state.form.controls.preisart.id,
    ];
    if (triggerPriceCalFieldArray.includes(action.controlId)) {
      form = updateGroup(form, {
        isBerechnungsartManuell: (control) => setValue(control, null),
        berechnungsformel: (control) => setValue(control, null),
        angebotspreis: (control) => disable(setValue(control, null)),
      });
    }
    return { ...state, form };
  }),
  onNgrxFormsAction(UnfocusAction, (state, action) => {
    let form = state.form;
    if (action.controlId === state.form.controls.flaechenAnsatz.id) {
      form = formGroupReducer(
        form,
        new SetValueAction(
          form.controls.flaechenAnsatz.id,
          convertNumberToFormattedString(state.form.value.flaechenAnsatz)
        )
      );
    }
    if (action.controlId === state.form.controls.angebotspreis.id) {
      form = formGroupReducer(
        form,
        new SetValueAction(
          form.controls.angebotspreis.id,
          convertNumberToFormattedString(state.form.value.angebotspreis)
        )
      );
    }
    if (action.controlId === state.form.controls.richtwert.id) {
      if (form.controls.richtwert.value < 0) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwert.id, {
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 },
          })
        );
      } else if (form.controls.richtwert.value == null) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwert.id, {
            required: { actual: null },
          })
        );
      } else if (state.form.controls.richtwert.value != null && state.form.controls.richtwert.value >= 0) {
        form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwert.id, {}));
      } else if (Number.isNaN(Number.parseFloat(String(state.form.controls.richtwert.value)))) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwert.id, {
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 },
          })
        );
      }

      form = formGroupReducer(
        form,
        new SetValueAction(form.controls.richtwert.id, convertNumberToFormattedString(state.form.value.richtwert))
      );
    }
    if (action.controlId === state.form.controls.richtwertExt.id) {
      if (form.controls.richtwertExt.value < 0) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwertExt.id, {
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 },
          })
        );
      } else if (form.controls.richtwertExt.value == null) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwertExt.id, {
            required: { actual: null },
          })
        );
      } else if (state.form.controls.richtwertExt.value >= 0 && state.form.controls.richtwertExt.value != null) {
        form = formGroupReducer(form, new SetErrorsAction(form.controls.richtwertExt.id, {}));
      } else if (Number.isNaN(Number.parseFloat(String(state.form.controls.richtwertExt.value)))) {
        form = formGroupReducer(
          form,
          new SetErrorsAction(form.controls.richtwertExt.id, {
            validNumberStringGreaterThan: { actual: form.value.richtwert, expected: 0 },
          })
        );
      }

      form = formGroupReducer(
        form,
        new SetValueAction(form.controls.richtwertExt.id, convertNumberToFormattedString(state.form.value.richtwertExt))
      );
    }
    return { ...state, form };
  }),
  on(fromInvestitionAction.resetState, (state) => ({
    isDeleting: false,
    isAborting: false,
    isSaving: false,
    triedSaving: false,
    choiceListLoaded: false,
    hasEmptyChoiecList: false,
    isCalculating: false,
    form: createFormGroupState<InvestitionTechnical>(FORM_ID, initialInvestitionFormState),
    wizardrules: state.wizardrules,
    prognose: null,
  })),
  on(fromInvestitionAction.loadInvestitionTechnicalSuccess, (state, { investition: dto }) => ({
    ...state,
    triedSaving: false,
    form: reset(
      updateGroup(state.form, {
        id: (control) => setValue(control, dto.id),
        projektart: (control) => setValue(control, dto.projektart),
        schule: (control) => setValue(control, dto.schule),
        programmart: (control) => setValue(control, dto.programmart),
        unterprogrammart: (control) => {
          if (!dto.unterprogrammart) {
            return enable(setValue(control, undefined));
          } else {
            return enable(setValue(control, dto.unterprogrammart));
          }
        },
        auspraegungUnterprogrammart: (control) => {
          if (!dto.auspraegungUnterprogrammart) {
            return enable(setValue(control, undefined));
          }
          return enable(setValue(control, dto.auspraegungUnterprogrammart));
        },
        beschreibung: (control) => setValue(control, dto.beschreibung),
        baubeginn: (control) => setValue(control, dto.baubeginn),
        bauFertigstellung: (control) => setValue(control, dto.bauFertigstellung),
        flaechenAnsatz: (control) => {
          // @ts-ignore
          return setValue(control, convertNumberToFormattedString(dto.flaechenAnsatz));
        },
        gebaeudeKlasseZukunft: (control) => setValue(control, dto.gebaeudeKlasseZukunft),
        isBerechnungsartManuell: (control) => setValue(control, dto.isBerechnungsartManuell),
        angebotspreis: (control) => {
          if (dto.isBerechnungsartManuell === 'false') {
            // @ts-ignore
            // TODO types should be fixed
            return disable(setValue(control, convertNumberToFormattedString(dto.angebotspreis)));
          } else {
            // @ts-ignore
            // TODO types should be fixed
            return setValue(control, convertNumberToFormattedString(dto.angebotspreis));
          }
        },
        berechnungsformel: (control) => setValue(control, dto.berechnungsformel),
        sondersachverhalt: (control) => setValue(control, dto.sondersachverhalt),
        gebaeude: (control) => setValue(control, dto.gebaeude),
        sapWeID: (control) => setValue(control, dto.sapWeID),
        preisart: (control) => setValue(control, dto.preisart),
        finanzierungsart: (control) => setValue(control, dto.finanzierungsart),

        kunde: (control) => setValue(control, dto.kunde),
        projektIDBKS: (control) => setValue(control, dto.projektIDBKS),
        bezirkHHID: (control) => setValue(control, dto.bezirkHHID),
        archivProjekt: (control) => setValue(control, dto.archivProjekt),
        erfasstAm: (control) => setValue(control, dto.erfasstAm),
        erfasstVonBenutzer: (control) => setValue(control, dto.erfasstVonBenutzer),
        gebaeudeflaecheZukunft: (control) => setValue(control, dto.gebaeudeflaecheZukunft),
        gebaeudetypID: (control) => setValue(control, dto.gebaeudetypID),
        mietflaecheBSBZukunft: (control) => setValue(control, dto.mietflaecheBSBZukunft),
        mietflaecheSonstigeZukunft: (control) => setValue(control, dto.mietflaecheSonstigeZukunft),
        processID: (control) => setValue(control, dto.processID),
        projektflaecheSonstige: (control) => setValue(control, dto.projektflaecheSonstige),
        projektsteuerungExtern: (control) => setValue(control, dto.projektsteuerungExtern),
        regionBSBID: (control) => setValue(control, dto.regionBSBID),
        regionID: (control) => setValue(control, dto.regionID),
        // @ts-ignore
        // TODO types should be fixed
        richtwert: (control) => setValue(control, convertNumberToFormattedString(dto.richtwert)),
        // @ts-ignore
        // TODO types should be fixed
        richtwertExt: (control) => setValue(control, convertNumberToFormattedString(dto.richtwertExt)),
        schulartBSBID: (control) => setValue(control, dto.schulartBSBID),
        schulformBSBID: (control) => setValue(control, dto.schulformBSBID),
        subteilprojektIDBKS: (control) => setValue(control, dto.subteilprojektIDBKS),
        teilpropjektIDBKS: (control) => setValue(control, dto.teilpropjektIDBKS),
      })
    ),
  })),
  on(fromRahmenPlanAction.initRahmenplanModuleSuccess, (state, { wizardrules }) => ({
    ...state,
    wizardrules: wizardrules,
  })),
  on(fromInvestitionAction.calculateInvestitionPrice, (state) => ({
    ...state,
    wizardrules: state.wizardrules,
    isCalculating: true,
  })),
  on(fromInvestitionAction.calculateInvestitionPriceFail, (state) => ({ ...state, isCalculating: false })),
  on(fromInvestitionAction.calculateInvestitionPriceSuccess, (state, { priceOffer }) => ({
    ...state,
    isCalculating: false,
    form: updateGroup(state.form, {
      isBerechnungsartManuell: (control) =>
        setValue(control, priceOffer.calculationType === 'MANUELL' ? 'true' : 'false'),
      berechnungsformel: (control) => setValue(control, priceOffer.formula),
      angebotspreis: (control) => {
        if (priceOffer.calculationType === 'MANUELL') {
          const price =
            priceOffer.price || priceOffer.price === 0
              ? priceOffer.price
              : state.form.value.angebotspreis
              ? state.form.value.angebotspreis
              : 0;
          // @ts-ignore
          return enable(setValue(control, convertNumberToFormattedString(price)));
        } else {
          // @ts-ignore
          return disable(setValue(control, convertNumberToFormattedString(priceOffer.price)));
        }
      },
      richtwert: (control, form) => {
        const { kunde, projektart, programmart } = form.value;
        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showRichtwert
          ? convertNumberToFormattedString(0)
          : null;
        // @ts-ignore
        return setValue(control, value);
      },
      richtwertExt: (control, form) => {
        const { kunde, projektart, programmart } = form.value;
        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showRichtwert
          ? convertNumberToFormattedString(0)
          : null;
        // @ts-ignore
        return setValue(control, value);
      },
    }),
  })),
  on(fromInvestitionAction.getKostenPrognoseSuccess, (state, { prognose }) => ({ ...state, prognose: prognose })),
  on(fromInvestitionAction.deleteInvestition, (state) => ({ ...state, isDeleting: true })),
  on(fromInvestitionAction.deleteInvestitionSuccess, fromInvestitionAction.deleteInvestitionFail, (state) => ({
    ...state,
    isDeleting: false,
  })),
  on(fromInvestitionAction.abortInvestition, (state) => ({ ...state, isAborting: true })),
  on(fromInvestitionAction.abortInvestitionFail, fromInvestitionAction.abortInvestitionSuccess, (state) => ({
    ...state,
    isAborting: false,
    form: reset(state.form),
  })),
  on(fromInvestitionAction.validateInvestition, (state) => ({
    ...state,
    wizardrules: state.wizardrules,
    triedSaving: true,
    form: updateGroup(state.form, {
      gebaeudeKlasseZukunft: (control, form) => {
        const { kunde, projektart, programmart } = form.value;

        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showGebaeudeklasse
          ? String(form.value.gebaeudeKlasseZukunft)
          : null;

        return setValue(control, value);
      },

      richtwert: (control, form) => {
        const { kunde, projektart, programmart } = form.value;

        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showRichtwert
          ? form.value.richtwert
          : null;

        return setValue(control, value);
      },

      richtwertExt: (control, form) => {
        const { kunde, projektart, programmart } = form.value;

        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showRichtwert
          ? form.value.richtwertExt
          : null;

        return setValue(control, value);
      },

      preisart: (control, form) => {
        const { kunde, projektart, programmart } = form.value;

        const value = getWizardConfig(state.wizardrules, +kunde, +projektart, +programmart).showPreisart
          ? String(form.value.preisart)
          : null;

        return setValue(control, value);
      },
    }),
  })),

  on(fromInvestitionAction.saveInvestition, (state) => ({
    ...state,
    wizardrules: state.wizardrules,
    isSaving: true,
  })),

  on(fromInvestitionAction.saveInvestitionSuccess, fromInvestitionAction.saveInvestitionFailure, (state) => ({
    ...state,
    wizardrules: state.wizardrules,
    isSaving: false,
    triedSaving: false,
  }))
);

//  usageID: validate(required, minLength(1), notEqualTo('-1')),
//   schoolID: validate(required, minLength(1), notEqualTo('-1')),
const validateForm = updateGroup<InvestitionTechnical>({
  kunde: validate(required),
  schule: (control, form) => {
    return form.value.kunde === Kunden.BSB || form.value.kunde === Kunden.HIBB
      ? validate(control, required)
      : validate(control, minLength(0));
  },
  projektart: validate(required, minLength(1), notEqualTo('-1')),
  programmart: validate(required, notEqualTo('-1')),
  unterprogrammart: (control, form) => {
    return (form.value.kunde === Kunden.BSB || form.value.kunde === Kunden.HIBB) &&
      (form.value.projektart === '1' || form.value.projektart === '2') &&
      (form.value.programmart === '8' || form.value.programmart === '13')
      ? validate(control, required)
      : validate(control, minLength(0));
  },
  auspraegungUnterprogrammart: (control, form) => {
    return (form.value.kunde === Kunden.BSB || form.value.kunde === Kunden.HIBB) &&
      (form.value.projektart === '1' || form.value.projektart === '2') &&
      (form.value.programmart === '8' || form.value.programmart === '13') &&
      form.value.unterprogrammart
      ? validate(control, required)
      : validate(control, minLength(0));
  },
  gebaeude: validate(required),
  beschreibung: validate(required, maxLength(FORM_FIELD_DESCRIPTION_MAX_LENGTH)),
  baubeginn: validate(required, greaterThanOrEqualTo(2010)),
  bauFertigstellung: (control, dto) => {
    return dto && dto.value.baubeginn ? validate(control, greaterThanOrEqualTo(dto.value.baubeginn)) : control;
  },
  flaechenAnsatz: validate(
    required,
    validNumberString,
    germanNumberStringGreaterThan(0),
    germanNumberStringMaxLength(11)
  ),
  angebotspreis: validate(validNumberString),
  finanzierungsart: validate(required, notEqualTo('null')),
});

const validatedProjectOderUIReducer = wrapReducerWithFormStateUpdate(projectOderUIReducer, (s) => s.form, validateForm);

export const reducers = combineReducers<InvestitionState, Action>({
  data: projectOrderDataReducer,
  ui: validatedProjectOderUIReducer,
});

export function reducer(state: InvestitionState | undefined, action: Action) {
  return reducers(state, action);
}

export const getDataState = (state: InvestitionState) => state.data;
export const getUIState = (state: InvestitionState) => state.ui;

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  StammdatenService,
  WirtschafteinheitListeResponse,
  WirtschafteinheitResponse,
  WirtschaftseinheitenGroupObject,
} from '../portal-api';
import { Wirtschaftseinheit } from '@app/core/models/wirtschaftseinheit.model';
import { WirtschaftseinheitConverter } from '@core/converters/wirtschaftseinheit-converter.service';
import { WirtschaftseinheitGroup } from '../models/wirtschaftseinheit-group.model';
import { RahmenplanDetailsData } from '../models/rahmenplan-details-state.model';

@Injectable()
export class WirtschaftseinheitService {
  constructor(private service: StammdatenService, private weConverter: WirtschaftseinheitConverter) {}

  getWirtschaftseinheitGroups(): Observable<RahmenplanDetailsData> {
    return this.service.getWirtschaftseinheiten().pipe(
      map((res: WirtschafteinheitListeResponse) => {
        const wirtschaftseinheitGroupDataModel: RahmenplanDetailsData = {
          wirtschaftseinheitGroups: res.data.length > 0 ? res.data.map((obj) => this.convertWEGroup(obj)) : [],
        };
        return wirtschaftseinheitGroupDataModel;
      })
    );
  }

  getWirtschaftseinheit(weId: string): Observable<Wirtschaftseinheit> {
    return this.service.getWirtschaftseinheitenById(weId).pipe(
      map((res: WirtschafteinheitResponse) => {
        return this.weConverter.convertFromApiModel(res.data);
      })
    );
  }

  private convertWEGroup(obj: WirtschaftseinheitenGroupObject): WirtschaftseinheitGroup {
    const modelList = [];
    obj.wirtschaftseinheiten.forEach((serverModel) => {
      if (serverModel) {
        const we = this.weConverter.convertFromApiModel(serverModel);
        modelList.push(we);
      }
    });

    const ret: WirtschaftseinheitGroup = {
      name: obj.name,
      wirtschaftseinheitList: modelList,
    };
    return ret;
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SystemKonfigurationModulRPL { 
    /**
     * Flag ob das Rahmenplan Modul aktiv ist
     */
    modulAktiv?: boolean;
    /**
     * Flag ob Referenzwerte gebildet werden koennen
     */
    referenzbildungAktiv?: boolean;
    /**
     * Flag ob Investitionen angelegt werden koennen
     */
    investitionAnlegenAktiv?: boolean;
    /**
     * Flag ob Investitionen bearbeitet werden koennen
     */
    investitionBearbeitenAktiv?: boolean;
    /**
     * Flag ob Investitionen geloescht werden koennen
     */
    investitionLoeschenAktiv?: boolean;
    /**
     * Flag ob Projekte angelegt werden koennen
     */
    projektAnlegenAktiv?: boolean;
    /**
     * Flag ob Projekte bearbeitet werden koennen
     */
    projektBearbeitenAktiv?: boolean;
    /**
     * Flag ob Projekte angezeigt werden sollen
     */
    projekteAnzeigenAktiv?: boolean;
    /**
     * Flag ob Umsaetze angezeigt werden sollen
     */
    umsaetzeAnzeigenAktiv?: boolean;
}


/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SystemKonfigurationModulSWP { 
    /**
     * Flag ob aktuell Wartungsarbeiten durchgefuehrt werden
     */
    wartungArbeitenAktiv?: boolean;
    /**
     * Anzeigetext, falls Wartungsarbeiten durchgefuehrt werden
     */
    wartungArbeitenText?: string;
    /**
     * Flag ob die Rechtematrix aktiv ist
     */
    berechtigungMatrixAktiv?: boolean;
    /**
     * Flag ob Emails versendet werden sollen
     */
    emailBenachrichtigungAktiv?: boolean;
    /**
     * Flag ob Probleme gemeldet werden koennen
     */
    problemMeldungAktiv?: boolean;
    /**
     * Flag ob Tasks gestartet werden koennen
     */
    taskStartenAktiv?: boolean;
    /**
     * Flag ob Workflows gestartet werden koennen
     */
    workflowStartenAktiv?: boolean;
    /**
     * Flag ob Favoriten angelegt werden koennen
     */
    favoritenAnlegenAktiv?: boolean;
    /**
     * Flag ob Nutzer Konfigurationen gespeichert werden koennen
     */
    konfigurationSpeichernAktiv?: boolean;
}


import { createAction, props } from '@ngrx/store';
import { RechtematrixEntryModel } from '@core/models/rechtematrix-entry.model';

export const loadRechtematrix = createAction('[Core/Rechtematrix] Load Rechtematrix');

export const loadRechtematrixSuccess = createAction(
  '[Core/Rechtematrix] Load Rechtematrix success',
  props<{ rechtematrix: RechtematrixEntryModel[] }>()
);

export const loadRechtematrixFailure = createAction(
  '[Core/Rechtematrix] Load Rechtematrix failure',
  props<{ error: unknown }>()
);

export const updateRechtematrix = createAction('[Core/Rechtematrix] Update Rechtematrix');
export const updateRechtematrixSuccess = createAction(
  '[Core/Rechtematrix] Update Rechtematrix success',
  props<{ rechtematrix: RechtematrixEntryModel[] }>()
);

export const updateRechtematrixFailure = createAction(
  '[Core/Rechtematrix] Update Rechtematrix failure',
  props<{ error: unknown }>()
);

export const updateRechtematrixWithWE = createAction('[Core/Rechtematrix] Update Rechtematrix with WE');
export const updateRechtematrixWithWESuccess = createAction(
  '[Core/Rechtematrix] Update Rechtematrix with WE success',
  props<{ rechtematrix: RechtematrixEntryModel[] }>()
);
export const updateRechtematrixWithWEFailure = createAction(
  '[Core/Rechtematrix] Update Rechtematrix with WE failure',
  props<{ error: unknown }>()
);

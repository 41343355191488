import { CanDeactivate, UrlTree } from '@angular/router';
import { catchError, flatMap, map, skipWhile, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { ModalConfirmationComponent } from '@shared/components/modal-confirmation/modal-confirmation.component';
import { UnsavedData } from '@core/models/unsaved-date-guard';

@Injectable()
export class UnsavedDataGuard implements CanDeactivate<UnsavedData> {
  constructor(private modalService: NgbModal) {}

  onConfirmSavingChanges(component: UnsavedData): Observable<boolean> | boolean {
    if (component.isDataDiscarded()) {
      const modalRef = this.modalService.open(ModalConfirmationComponent);
      modalRef.componentInstance.headerText = 'Ungespeicherte Eingaben';
      modalRef.componentInstance.confirmationText = 'Ihre Eingaben gehen verloren. Wollen Sie wirklich abbrechen?';
      modalRef.componentInstance.cancelButtonText = 'Ja, abbrechen';
      modalRef.componentInstance.confirmButtonText = 'Zurück';

      const modalObservable = from(modalRef.result).pipe(
        map((result) => {
          if (result) {
            return false;
          }
        }),
        catchError(() => of(true))
      );

      return modalObservable;
    } else if (component.isDataUnsaved()) {
      const modalRef = this.modalService.open(ModalConfirmationComponent);
      modalRef.componentInstance.headerText = 'Ungespeicherte Eingaben prüfen';
      modalRef.componentInstance.confirmationText =
        'Sie haben einige Änderungen noch nicht gespeichert. Sollen sie gespeichert werden?';
      modalRef.componentInstance.cancelButtonText = 'Nicht speichern';
      modalRef.componentInstance.confirmButtonText = 'Speichern';
      modalRef.componentInstance.showSpinner$ = component.isSaving$;
      modalRef.componentInstance.isGuardModal = true;

      // Wir warten bis wir mit dem speichern fertig sind
      const isSavingObservable = component.isSaving$.pipe(
        skipWhile((isSaving) => isSaving === true),
        take(1),
        map(() => true)
      );

      // Modal observable
      // TODO damit das Modal länger bleibt, müssen wir mit event emitters arbeiten, und erst später das Modal entfernen
      const modalObservable = from(modalRef.result).pipe(
        map((result) => {
          if (result) {
            component.onSaveDataConfirmed();
          }
        }),
        catchError(() => of(true))
      );

      return modalObservable.pipe(flatMap(() => isSavingObservable));
    }
    return true;
  }

  canDeactivate(
    component: UnsavedData
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (typeof component.isDataUnsaved !== 'function' || typeof component.onSaveDataConfirmed !== 'function') {
      throw new Error('Component is not implementing the UnsavedData interface');
    }

    return this.onConfirmSavingChanges(component);
  }
}

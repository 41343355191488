import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isLoggedOut = false;
  constructor(private keycloak: KeycloakService, private modalService: NgbModal) {}

  private handleAuthError(err: HttpErrorResponse): Observable<never> {
    if (err.status === 401 && !this.isLoggedOut) {
      this.isLoggedOut = true;
      const modalRef = this.modalService.open(ModalConfirmationComponent);
      modalRef.componentInstance.headerText = 'Session abgelaufen';
      modalRef.componentInstance.confirmationText = 'Sie wurden abgemeldet. Bitte melden Sie sich erneut an.';
      modalRef.componentInstance.confirmButtonText = 'Anmelden';
      modalRef.componentInstance.isGuardModal = true;
      modalRef.componentInstance.cancelButtonText = undefined;

      modalRef.result.then((result) => {
        if (result) {
          let url = document.location.protocol + '//' + document.location.hostname;
          if (document.location.port !== '') {
            url = url + ':' + document.location.port;
          }
          url = url + environment.logoutUrl;

          this.keycloak.logout(url);
        }
      });
    }
    return throwError(err);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error) => this.handleAuthError(error)));
  }
}

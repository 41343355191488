import { Pipe, PipeTransform } from '@angular/core';

import { filterFunction } from '../utils';
import { SliderFilterModel } from '@core/models/slider-filter.model';

@Pipe({
  name: 'filterFunction',
  pure: false,
})
export class FilterFunctionPipe implements PipeTransform {
  transform(items: any[], filter: { [key: string]: string[] | string }, sliderFilter?: SliderFilterModel[]): any[] {
    if (!items || ((!filter || Object.keys(filter).length === 0) && (!sliderFilter || sliderFilter.length === 0))) {
      return items;
    }

    return filterFunction(items, filter, sliderFilter);
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debugMode: true,
  // basePathAuth: 'https://192.168.1.43/auth',
  // clientID: 'frontend-client-local',
  //apiUrl: 'http://192.168.1.50:5555/api/sbh/rp/v1',
  apiUrl: 'https://sbh.is.dev.dp.dsecurecloud.de:8899/api/sbh/rp/v1',
  assetsUrl: './assets',
  logoutUrl: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

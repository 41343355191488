import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { ColumnType, TableColumn } from '@core/models/table-column.model';
import { convertDateToString } from '../utils';

@Pipe({
  name: 'tableProps',
})
export class TableProps implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  numberComparator = (propA, propB, ra, rb, dir) => {
    if (dir === 'asc') {
      if (propB === '') {
        return -1;
      }
      if (propA === '') {
        return 1;
      }
    }
    if (Number(propA) < Number(propB)) {
      return -1;
    }
    if (Number(propA) > Number(propB)) {
      return 1;
    }

    return 0;
  };
  decimalPipe = (num) => {
    return isNaN(num) ? num : this._decimalPipe.transform(num, '1.2-2');
  };

  datePipe = (date: string) => {
    return date ? convertDateToString(new Date(date)) : '';
  };

  transform(items: TableColumn[]): unknown {
    if (!items) {
      return items;
    }

    const columns = [];
    items.forEach((col, index) => {
      const tempCol = { ...col, width: col.width || 160 };
      if (tempCol.type === ColumnType.NUMBER) {
        if (tempCol.hasPipe) {
          tempCol.pipe = { transform: this.decimalPipe };
        }
        if (tempCol.hasComparator) {
          tempCol.comparator = this.numberComparator;
        }
      } else if (tempCol.type === ColumnType.DATE) {
        tempCol.pipe = { transform: this.datePipe };
      }
      columns[index] = tempCol;
    });

    return columns;
  }
}

import { DatatableConfigs } from './datatable-config.model';
import { TableColumn } from './table-column.model';

export const MAX_NUMBER_FAVORITES = 16;
export interface FavoriteModel {
  id?: string;
  referenceType: string;
  referenceStructure: FavoriteStructure;
  description: string;
  createdOn?: string;
}

export interface FavoriteStructure {
  weID?: string;
  poID?: string;
  name: string;
  description: string;
  filterConfig?: unknown;
  tableConfig?: DatatableConfigs;
  tableLayoutConfig?: TableColumn[];
}

export interface FavoriteList {
  wirtschaftseinheiten?: FavoriteModel[];
  gebaeude?: FavoriteModel[];
  investitionen?: FavoriteModel[];
}
export const FAVORITES_GEBAEUDE_ID = 'FAVORITES_GEBAEUDE_ID';
export const FAVORITES_WIRTSCHAFTSEINHEITEN_ID = 'FAVORITES_WIRTSCHAFTSEINHEITEN_ID';
export const FAVORITES_FILTERS_ID = 'FAVORITES_FILTERS_ID';
export const FAVORITES_INVESTITIONEN_ID = 'FAVORITES_INVESTITIONEN_ID';

export type FavoritesType = 'GEBAEUDE' | 'WIRTSCHAFTSEINHEITEN' | 'FILTERS' | 'INVESTITIONEN';
export const FavoritesType = {
  Gebaeude: 'GEBAEUDE' as FavoritesType,
  Wirtschaftseinheiten: 'WIRTSCHAFTSEINHEITEN' as FavoritesType,
  Filters: 'FILTERS' as FavoritesType,
  Investitionen: 'INVESTITIONEN' as FavoritesType,
};

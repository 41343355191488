import { Injectable } from '@angular/core';
import { encode, decode } from 'js-base64';

import { DatatableConfig, DatatableConfigs } from '@core/models/datatable-config.model';
import { Converter } from '@app/core/models/converter';
import { IsJsonString } from '@app/shared/utils';

import * as fromAPI from '@core/portal-api';

@Injectable()
export class UserConfigConverter implements Converter<DatatableConfigs, fromAPI.DatatableConfig> {
  getConfigValue(config: string): DatatableConfigs {
    const str = decode(config);
    if (IsJsonString(str)) {
      return JSON.parse(str);
    }
    return undefined;
  }

  convertFromApiModel(config: fromAPI.DatatableConfig): DatatableConfigs {
    if (config === null || config === undefined) {
      return undefined;
    }

    const result = this.getConfigValue(config.configValue);

    return result;
  }

  convertToApiModel(config: DatatableConfigs): fromAPI.DatatableConfig {
    if (config === null || config === undefined) {
      return undefined;
    }

    const configStr = JSON.stringify(config) || '';
    const configValue = encode(configStr);

    const result: fromAPI.DatatableConfig = {
      configID: config.configID,
      configValue: configValue,
      userID: '',
      datatableID: '',
    };

    return result;
  }
}

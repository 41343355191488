/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Berechneter Preis fuer ein Vorhaben
 */
export interface VorhabenPreisberechnung { 
    /**
     * Berechneter Preis; ist 0 wenn art = MANUELL
     */
    preis: number;
    /**
     * Art des berechneten Preises:  * `AUTOMATISCH` - Der Preis wurde automatisch anhand von hinterlegten Regeln berechnet  * `MANUELL` - Der Preis konnte nicht automatisch berechnet werden und muss manuell eingegeben werden 
     */
    art: VorhabenPreisberechnung.ArtEnum;
    /**
     * Formel des berechneten Preises
     */
    formel?: string;
}
export namespace VorhabenPreisberechnung {
    export type ArtEnum = 'AUTOMATISCH' | 'MANUELL';
    export const ArtEnum = {
        Automatisch: 'AUTOMATISCH' as ArtEnum,
        Manuell: 'MANUELL' as ArtEnum
    };
}



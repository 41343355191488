import { createSelector } from '@ngrx/store';
import * as coreState from '@core/store/reducers';
import { BerechtigungFunktionModel } from '@core/models/berechtigung-funktion.model';

export const selectRechtematrix = createSelector(
  coreState.getCoreModuleState,
  (state: coreState.CoreModuleState) => state.rechtematrix
);

export const selectRechtematrixEntries = createSelector(
  selectRechtematrix,
  (rechtematrix) => rechtematrix.rechteMatrixEntries
);

export const selectBerechtigungFunktion = createSelector(selectRechtematrixEntries, (matrixEntries) => {
  const berechtigungFunktion: BerechtigungFunktionModel = {
    startseiteAnpassen:
      matrixEntries.filter((v) => v.modulID === 'SWP' && v.fokus === 'STARTSEITE' && v.funktion === 'ANPASSEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'SWP' && v.fokus === 'STARTSEITE' && v.funktion === 'ANPASSEN' && v.isPermitted
      ).length > 0,
    aufgabenBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'SWP' && v.fokus === 'AUFGABEN' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'SWP' && v.fokus === 'AUFGABEN' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    kalenderBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'SWP' && v.fokus === 'KALENDER' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'SWP' && v.fokus === 'KALENDER' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    favoritenAnlegen:
      matrixEntries.filter((v) => v.modulID === 'SWP' && v.fokus === 'FAVORITEN' && v.funktion === 'ANLEGEN').length ===
        0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'SWP' && v.fokus === 'FAVORITEN' && v.funktion === 'ANLEGEN' && v.isPermitted
      ).length > 0,
    tabelleInvestitionenAufrufen:
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'AUFRUFEN'
      ).length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'AUFRUFEN' && v.isPermitted
      ).length > 0,
    tabelleInvestitionenKonfigurieren:
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'KONFIGURIEREN'
      ).length === 0 ||
      matrixEntries.filter(
        (v) =>
          v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'KONFIGURIEREN' && v.isPermitted
      ).length > 0,
    tabelleInvestitionenFiltern:
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'FILTERN'
      ).length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'TABELLE_INVESTITIONEN' && v.funktion === 'FILTERN' && v.isPermitted
      ).length > 0,
    investitionSelektieren:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'SELEKTIEREN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'SELEKTIEREN' && v.isPermitted
      ).length > 0,
    investitionAnlegen:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'ANLEGEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'ANLEGEN' && v.isPermitted
      ).length > 0,
    investitionAnzeigen:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'ANZEIGEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'ANZEIGEN' && v.isPermitted
      ).length > 0,
    investitionBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    investitionLoeschen:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'LOESCHEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'INVESTITION' && v.funktion === 'LOESCHEN' && v.isPermitted
      ).length > 0,
    projektAnlegen:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'PROJEKT' && v.funktion === 'ANLEGEN').length ===
        0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'PROJEKT' && v.funktion === 'ANLEGEN' && v.isPermitted
      ).length > 0,
    projektHinzufuegen:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'PROJEKT' && v.funktion === 'HINZUFUEGEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'PROJEKT' && v.funktion === 'HINZUFUEGEN' && v.isPermitted
      ).length > 0,
    tabelleGebaeudeAufrufen:
      matrixEntries.filter((v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'AUFRUFEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'AUFRUFEN' && v.isPermitted
      ).length > 0,
    tabelleGebaeudeKonfigurieren:
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'KONFIGURIEREN'
      ).length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'KONFIGURIEREN' && v.isPermitted
      ).length > 0,
    tabelleGebaeudeFiltern:
      matrixEntries.filter((v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'FILTERN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'TABELLE_GEBAEUDE' && v.funktion === 'FILTERN' && v.isPermitted
      ).length > 0,
    gebaeudeSelektieren:
      matrixEntries.filter((v) => v.modulID === 'GBD' && v.fokus === 'GEBAEUDE' && v.funktion === 'SELEKTIEREN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'GEBAEUDE' && v.funktion === 'SELEKTIEREN' && v.isPermitted
      ).length > 0,
    gebaeudeAnzeigen:
      matrixEntries.filter((v) => v.modulID === 'GBD' && v.fokus === 'GEBAEUDE' && v.funktion === 'ANZEIGEN').length ===
        0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'GEBAEUDE' && v.funktion === 'ANZEIGEN' && v.isPermitted
      ).length > 0,
    rplBenutzerhilfeBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'BENUTZERHILFE' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'BENUTZERHILFE' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    gbdBenutzerhilfeBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'GBD' && v.fokus === 'BENUTZERHILFE' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'GBD' && v.fokus === 'BENUTZERHILFE' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    sysadminBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'ADM' && v.fokus === 'SYSADMIN' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'ADM' && v.fokus === 'SYSADMIN' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
    workflowReferenzbildungStarten:
      matrixEntries.filter(
        (v) => v.modulID === 'SWP' && v.fokus === 'WORKFLOW_REFERENZBILDUNG' && v.funktion === 'STARTEN'
      ).length === 0 ||
      matrixEntries.filter(
        (v) =>
          v.modulID === 'SWP' && v.fokus === 'WORKFLOW_REFERENZBILDUNG' && v.funktion === 'STARTEN' && v.isPermitted
      ).length > 0,
    referenzbildungBearbeiten:
      matrixEntries.filter((v) => v.modulID === 'RPL' && v.fokus === 'REFERENZBILDUNG' && v.funktion === 'BEARBEITEN')
        .length === 0 ||
      matrixEntries.filter(
        (v) => v.modulID === 'RPL' && v.fokus === 'REFERENZBILDUNG' && v.funktion === 'BEARBEITEN' && v.isPermitted
      ).length > 0,
  };

  return berechtigungFunktion;
});

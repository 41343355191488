import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceListItem } from '@core/models/choice-list-item.model';

@Pipe({
  name: 'choiceListFilter',
})
export class ChoiceListFilterPipe implements PipeTransform {
  transform(choiceListItems: ChoiceListItem[], filter: string | number): unknown {
    if (choiceListItems == null || filter == null) {
      return [];
    }
    if (!choiceListItems || !filter) {
      return choiceListItems;
    }

    return choiceListItems.filter((item) => {
      if (typeof filter === 'number') {
        filter = '' + filter;
      }
      if (item.parentID && item.parentID.trim() === filter.trim()) {
        return item;
      }
    });
  }
}

import { ArrayFilterPipe } from '@shared/pipes/array-filter.pipe';
import { BruttoNettoPipe } from '@shared/pipes/brutto-netto.pipe';
import { ChoiceListFilterPipe } from '@shared/pipes/choice-list-filter.pipe';
import { ConfigFilterPipe } from '@shared/pipes/config-filter.pipe';
import { FilterFunctionPipe } from '@shared/pipes/filter-function.pipe';
import { GebaeudeFilterPipe } from '@shared/pipes/gebaeude-filter.pipe';
import { GreaterThanPipe } from '@shared/pipes/greaterThan.pipe';
import { HandbuchFilterPipe } from '@shared/pipes/handbuch-filter.pipe';
import { NullishPipe } from '@shared/pipes/nullish.pipe';
import { OrderPipe } from '@shared/pipes/order.pipe';
import { ProzesseFilterPipe } from '@shared/pipes/prozesse-filter.pipe';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { TableProps } from '@shared/pipes/table-props.pipe';
import { WeFilterPipe } from '@shared/pipes/we-filter.pipe';
import { AnwenderListFilterPipe } from './anwender-list-filter.pipe';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sharedPipes: any[] = [
  ArrayFilterPipe,
  FilterFunctionPipe,
  ChoiceListFilterPipe,
  NullishPipe,
  OrderPipe,
  TableProps,
  GreaterThanPipe,
  BruttoNettoPipe,
  WeFilterPipe,
  GebaeudeFilterPipe,
  ConfigFilterPipe,
  SafeUrlPipe,
  HandbuchFilterPipe,
  ProzesseFilterPipe,
  AnwenderListFilterPipe,
];

const C_V_STM_BZK = [
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '1',
    BZK_BEZEICHNUNG: 'Mitte',
    BZK_BZK_OBJ_ID_PKT: 5490178,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '2',
    BZK_BEZEICHNUNG: 'Altona',
    BZK_BZK_OBJ_ID_PKT: 5490174,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '3',
    BZK_BEZEICHNUNG: 'Eimsb?ttel',
    BZK_BZK_OBJ_ID_PKT: 5490176,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '4',
    BZK_BEZEICHNUNG: 'Nord',
    BZK_BZK_OBJ_ID_PKT: 5490179,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '5',
    BZK_BEZEICHNUNG: 'Wandsbek',
    BZK_BZK_OBJ_ID_PKT: 5490180,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '6',
    BZK_BEZEICHNUNG: 'Bergedorf',
    BZK_BZK_OBJ_ID_PKT: 5490175,
  },
  {
    GO_TYP: 'BZK',
    BZK_NUMMER_PKF: '7',
    BZK_BEZEICHNUNG: 'Harburg',
    BZK_BZK_OBJ_ID_PKT: 5490177,
  },
];

export const getBezirkByName = (name: string) => {
  return C_V_STM_BZK.find((value) => value.BZK_BEZEICHNUNG === name);
};

const C_V_STM_RGN = [
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '1',
    RGN_BEZEICHNUNG_PKF: 'Mitte',
    RGN_KURZZEICHEN: 'M 1',
    RGN_LEITZEICHEN: 'M 1',
    RGN_RGN_OBJ_ID_PKT: 1799940,
    RGN_BEREICH_OBJ_ID_FKT: 5372019,
    RGN_REGLTG_OBJ_ID_FKT: 1800490,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '2',
    RGN_BEZEICHNUNG_PKF: 'Altona',
    RGN_KURZZEICHEN: 'R A',
    RGN_LEITZEICHEN: 'RA 1',
    RGN_RGN_OBJ_ID_PKT: 1799936,
    RGN_BEREICH_OBJ_ID_FKT: 5372019,
    RGN_REGLTG_OBJ_ID_FKT: 1800392,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '3',
    RGN_BEZEICHNUNG_PKF: 'Eimsbüttel',
    RGN_KURZZEICHEN: 'M 3',
    RGN_LEITZEICHEN: 'M 3',
    RGN_RGN_OBJ_ID_PKT: 1799938,
    RGN_BEREICH_OBJ_ID_FKT: 5372019,
    RGN_REGLTG_OBJ_ID_FKT: 5916647,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '4',
    RGN_BEZEICHNUNG_PKF: 'Nord',
    RGN_KURZZEICHEN: 'N 3',
    RGN_LEITZEICHEN: 'N 3',
    RGN_RGN_OBJ_ID_PKT: 1799941,
    RGN_BEREICH_OBJ_ID_FKT: 5372018,
    RGN_REGLTG_OBJ_ID_FKT: 1800403,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '5',
    RGN_BEZEICHNUNG_PKF: 'Wandsbek-Nord',
    RGN_KURZZEICHEN: 'N 1',
    RGN_LEITZEICHEN: 'N 1',
    RGN_RGN_OBJ_ID_PKT: 1799943,
    RGN_BEREICH_OBJ_ID_FKT: 5372018,
    RGN_REGLTG_OBJ_ID_FKT: 1800449,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '6',
    RGN_BEZEICHNUNG_PKF: 'Wandsbek-S�d',
    RGN_KURZZEICHEN: 'N 2',
    RGN_LEITZEICHEN: 'N 2',
    RGN_RGN_OBJ_ID_PKT: 1799944,
    RGN_BEREICH_OBJ_ID_FKT: 5372018,
    RGN_REGLTG_OBJ_ID_FKT: 5925343,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '7',
    RGN_BEZEICHNUNG_PKF: 'Bergedorf',
    RGN_KURZZEICHEN: 'M 4',
    RGN_LEITZEICHEN: 'M 4',
    RGN_RGN_OBJ_ID_PKT: 1799937,
    RGN_BEREICH_OBJ_ID_FKT: 5372019,
    RGN_REGLTG_OBJ_ID_FKT: 5448329,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '8',
    RGN_BEZEICHNUNG_PKF: 'HIBB',
    RGN_KURZZEICHEN: 'R H',
    RGN_LEITZEICHEN: 'RH 1',
    RGN_RGN_OBJ_ID_PKT: 1799939,
    RGN_BEREICH_OBJ_ID_FKT: 5372018,
    RGN_REGLTG_OBJ_ID_FKT: 1800327,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '9',
    RGN_BEZEICHNUNG_PKF: 'S�d',
    RGN_KURZZEICHEN: 'S 1',
    RGN_LEITZEICHEN: 'S 1',
    RGN_RGN_OBJ_ID_PKT: 1799942,
    RGN_BEREICH_OBJ_ID_FKT: 5372549,
    RGN_REGLTG_OBJ_ID_FKT: 6882207,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '10',
    RGN_BEZEICHNUNG_PKF: 'Unibau',
    RGN_KURZZEICHEN: 'UB 1',
    RGN_LEITZEICHEN: 'UB 1',
    RGN_RGN_OBJ_ID_PKT: 6993694,
    RGN_BEREICH_OBJ_ID_FKT: 5372549,
    RGN_REGLTG_OBJ_ID_FKT: 7029964,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '11',
    RGN_BEZEICHNUNG_PKF: 'HEOS',
    RGN_KURZZEICHEN: null,
    RGN_LEITZEICHEN: '1 HEOS',
    RGN_RGN_OBJ_ID_PKT: 5445906,
    RGN_BEREICH_OBJ_ID_FKT: null,
    RGN_REGLTG_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '12',
    RGN_BEZEICHNUNG_PKF: 'Kommunalbau',
    RGN_KURZZEICHEN: 'S 2',
    RGN_LEITZEICHEN: 'S 2',
    RGN_RGN_OBJ_ID_PKT: 5418905,
    RGN_BEREICH_OBJ_ID_FKT: 5372549,
    RGN_REGLTG_OBJ_ID_FKT: 6882207,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '13',
    RGN_BEZEICHNUNG_PKF: 'GMH Projekte Projektsteuerung',
    RGN_KURZZEICHEN: 'S 3',
    RGN_LEITZEICHEN: 'S 3',
    RGN_RGN_OBJ_ID_PKT: 6791231,
    RGN_BEREICH_OBJ_ID_FKT: 5372549,
    RGN_REGLTG_OBJ_ID_FKT: 6882207,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '20',
    RGN_BEZEICHNUNG_PKF: 'TFM',
    RGN_KURZZEICHEN: 'ZFM 1',
    RGN_LEITZEICHEN: '1 TFM',
    RGN_RGN_OBJ_ID_PKT: 1800624,
    RGN_BEREICH_OBJ_ID_FKT: null,
    RGN_REGLTG_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '22',
    RGN_BEZEICHNUNG_PKF: 'IFM',
    RGN_KURZZEICHEN: 'ZFM 2',
    RGN_LEITZEICHEN: 'ZFM 2',
    RGN_RGN_OBJ_ID_PKT: 1800625,
    RGN_BEREICH_OBJ_ID_FKT: null,
    RGN_REGLTG_OBJ_ID_FKT: 6985712,
  },
  {
    GO_TYP: 'RGN',
    RGN_NUMMER: '98',
    RGN_BEZEICHNUNG_PKF: 'Fremdbestand',
    RGN_KURZZEICHEN: null,
    RGN_LEITZEICHEN: '1 Fremdbestand',
    RGN_RGN_OBJ_ID_PKT: 5446962,
    RGN_BEREICH_OBJ_ID_FKT: null,
    RGN_REGLTG_OBJ_ID_FKT: null,
  },
];

export const getRegionByID = (id: number) => {
  return C_V_STM_RGN.find((value) => value.RGN_RGN_OBJ_ID_PKT === +id);
};

export interface ChoiceListItem {
  id: string;
  value: string;
  type: ChoiceListType;
  parentID?: string;
  validFrom?: string;
  validTo?: string;
}

export type ChoiceListType =
  | 'school'
  | 'project-order-type'
  | 'project-type'
  | 'program-type'
  | 'sub-program-type'
  | 'sub-program-value'
  | 'kunden'
  | 'price-type'
  | 'gebaeude'
  | 'sponsor-type'
  | 'partner_type'
  | 'region';

export const ChecklistTypeEnum = {
  SCHOOL: 'school' as ChoiceListType,
  PROJECT_ORDER_TYPE: 'project-order-type' as ChoiceListType,
  PROJECT_TYPE: 'project-type' as ChoiceListType,
  PROGRAM_TYPE: 'program-type' as ChoiceListType,
  SUB_PROGRAM_TYPE: 'sub-program-type' as ChoiceListType,
  SUB_PROGRAM_VALUE: 'sub-program-value' as ChoiceListType,
  KUNDEN: 'kunden' as ChoiceListType,
  BUILDING: 'gebaeude' as ChoiceListType,
  BUILDING_CLASS_FUTURE: 'gebaeude-class-future' as ChoiceListType,
  PROJECT_NUMBER: 'project-number' as ChoiceListType,
  PRICE_TYPE: 'price-type' as ChoiceListType,
  SPONSOR_TYPE: 'sponsor-type' as ChoiceListType,
  PARTNER_TYPE: 'partner-type' as ChoiceListType,
  PROJEKTSTEUERER: 'projektsteuerer' as ChoiceListType,
  REGION: 'region' as ChoiceListType,
};

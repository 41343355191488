import { TableColumn } from '@core/models/table-column.model';

export interface DatatableConfig {
  configID?: string;
  userID?: string;
  datatableID: string;
  configValue: GebaeudeColumnsConfig | InvestitionColumnsConfig | ProjekteColumnsConfig;
}
export interface DatatableConfigState {
  gebaeude: GebaeudeColumnsConfig;
  investition: InvestitionColumnsConfig;
  projekte: ProjekteColumnsConfig;
}

export type DatatableConfigs = GebaeudeColumnsConfig | InvestitionColumnsConfig | ProjekteColumnsConfig;

export interface GebaeudeColumnsConfig {
  configID?: string;
  columns: TableColumn[];
}

export interface InvestitionColumnsConfig {
  configID?: string;
  columns: TableColumn[];
}

export interface ProjekteColumnsConfig {
  configID?: string;
  columns: TableColumn[];
}

export const INVESTITIONEN_TABLE_CONFIG_ID = 'INVESTITIONEN_TABLE_ID';
export const GEBAEUDE_TABLE_CONFIG_ID = 'GEBAEUDE_TABLE_ID';
export const PROJEKTE_TABLE_CONFIG_ID = 'PROJEKTE_TABLE_ID';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StammdatenService } from '@core/portal-api';
import { GebaeudeanlageConverter } from '@app/core/converters/gebaeudeanlage-converter.service';
import { Gebaeudeanlage } from '@app/core/models/gebaeudeanlage.model';

@Injectable()
export class GebaeudeService {
  constructor(private stammdatenService: StammdatenService, private gebaeudeConverter: GebaeudeanlageConverter) {}

  getAllGebaeude(): Observable<Gebaeudeanlage[]> {
    return this.stammdatenService.getGebaeudeAnlage().pipe(
      map((res) => {
        const gebaeudeList: Gebaeudeanlage[] = [];

        res.data.forEach((serverModel) => {
          const model: Gebaeudeanlage = this.gebaeudeConverter.convertFromApiModel(serverModel);
          gebaeudeList.push(model);
        });
        return gebaeudeList;
      })
    );
  }

  getAllGebaeudeByWeID(id: string): Observable<Gebaeudeanlage[]> {
    return this.stammdatenService.getGebaeudeAnlage(id).pipe(
      map((res) => {
        //19186
        if (!res.data) res.data = [];
        const gebaeudeList: Gebaeudeanlage[] = [];

        res.data.forEach((serverModel) => {
          const model: Gebaeudeanlage = this.gebaeudeConverter.convertFromApiModel(serverModel);
          gebaeudeList.push(model);
        });

        const weNummer = id;

        //add Schulbau 1-3
        for (let i = 1; i <= 3; i++) {
          gebaeudeList.push({
            id: 'Schulbau' + '/' + i,
            gebaeudeBezeichnung: 'Schulbau ' + i,
            gebaeudeSapID: weNummer + '/' + i,
          });
        }

        //add Neubau 1-3
        for (let i = 4; i <= 6; i++) {
          gebaeudeList.push({
            id: 'Neubau' + '/' + (i - 3),
            gebaeudeBezeichnung: 'Neubau ' + (i - 3),
            gebaeudeSapID: weNummer + '/' + i,
          });
        }

        // add Sporthalle 1-3
        for (let i = 7; i <= 9; i++) {
          gebaeudeList.push({
            id: 'Sporthalle' + '/' + (i - 6),
            gebaeudeBezeichnung: 'Sporthalle ' + (i - 6),
            gebaeudeSapID: weNummer + '/' + i,
          });
        }

        return gebaeudeList;
      })
    );
  }

  getGebaeudeByID(id: string): Observable<Gebaeudeanlage> {
    return this.stammdatenService.getGebaeudeAnlageByID(id).pipe(
      map((res) => {
        return this.gebaeudeConverter.convertFromApiModel(res.data);
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '@core/models/table-column.model';

@Pipe({
  name: 'configFilterPipe',
  pure: false,
})
export class ConfigFilterPipe implements PipeTransform {
  transform(tableColumns: TableColumn[], filter: string | number): TableColumn[] {
    if (!tableColumns || !filter) {
      return tableColumns;
    }

    const filteredColumns: TableColumn[] = [];
    tableColumns.forEach((column) => {
      if (column.name.toLocaleLowerCase().includes(String(filter).toLocaleLowerCase())) {
        filteredColumns.push(column);
      }
    });

    return filteredColumns;
  }
}

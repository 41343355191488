import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCoreState from '@core/store/reducers';
import * as fromChatbotActions from '@core/store/actions/chatbot.actions';
import { ChatbotMessageModel } from '@core/models/chatbot-message.model';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { ChatbotService } from '@core/portal-api';
import { ChatbotGruppeModel } from '@core/models/chatbot-gruppe.model';
import { ChatbotGruppeConverter } from '@core/converters/chatbot-gruppe-converter.service';
import { ROLLEN_MAP } from '@core/constants/chatbot-config';

@Injectable()
export class SbhChatbotService {
  modulID: string;

  modulName: string;

  anwenderData: AnwenderDataModel;

  chatbotGruppen: ChatbotGruppeModel[];

  constructor(
    private chatbotService: ChatbotService,
    private chatbotGruppeConverterService: ChatbotGruppeConverter,
    private coreStore: Store<fromCoreState.CoreModuleState>
  ) {
    this.coreStore.dispatch(fromChatbotActions.initInitialKonversation());
  }

  getChatbotGruppen(): Observable<ChatbotGruppeModel[]> {
    return this.chatbotService.getChatbotGruppenList().pipe(
      map((res) => {
        const chatbotGruppeModelList = [];
        res.data.forEach((value) => {
          const chatbotGruppeModel = this.chatbotGruppeConverterService.convertFromApiModel(value);
          chatbotGruppeModelList.push(chatbotGruppeModel);
        });
        this.chatbotGruppen = chatbotGruppeModelList;
        return chatbotGruppeModelList;
      })
    );
  }

  initialize(modulID: string, modulName: string, anwenderData: AnwenderDataModel): void {
    this.modulID = modulID;
    this.modulName = modulName;
    this.anwenderData = anwenderData;
    //this.getSystemAntwort('Initialisierung', 'ALLGEMEIN');
  }

  getChatbotAntwort(frage: string, chatbotGroup: string): string {
    const filteredChatbotGruppen = this.chatbotGruppen.filter((value) => value.id === chatbotGroup);

    const frageList = frage.split(' ');

    let topAnswer = '';
    let topCount = 0;
    if (filteredChatbotGruppen && filteredChatbotGruppen.length > 0) {
      filteredChatbotGruppen[0].eintragList.forEach((value) => {
        const schlagwortList = value.schlagwort.split('|');
        let count = 0;
        schlagwortList.forEach((schlagwort) => {
          frageList.forEach((frageToken) => {
            if (schlagwort.toLowerCase() === frageToken.toLowerCase().replace('?', '')) count++;
          });
        });
        if (count > topCount) {
          topCount = count;
          topAnswer = value.antwort;
        }
      });
    } else {
      topAnswer = null;
    }
    return topAnswer;
  }

  getSystemAntwort(msg: string, chatbotGruppe: string) {
    if (msg) {
      if (msg !== 'Initialisierung' && msg !== 'Modul') {
        const anwenderMessage = new ChatbotMessageModel('anwender', msg);
        this.coreStore.dispatch(fromChatbotActions.addMessageToKonversation({ message: anwenderMessage }));
      }
      const systemMessage = new ChatbotMessageModel('system', this.getSystemMessage(msg, chatbotGruppe));
      if (msg === 'Was sind meine Rollen im System?') {
        systemMessage.inhalt = this.getRollenAntwort();
      }
      setTimeout(() => {
        this.coreStore.dispatch(fromChatbotActions.addMessageToKonversation({ message: systemMessage }));
      }, 1500);
    }
  }

  getSystemMessage(frage: string, chatbotGruppe: string) {
    let antwort = this.getChatbotAntwort(frage, chatbotGruppe);
    if (antwort) {
      antwort = antwort
        .replace('__MODUL__', this.modulName)
        .replace(
          '__ANWENDER__',
          this.anwenderData.anrede + ' ' + this.anwenderData.vorname + ' ' + this.anwenderData.nachname
        )
        .replace('__INTEXT__', this.anwenderData.internExtern === 'Intern' ? 'interner' : 'externer')
        .replace('__FIRMA__', this.anwenderData.firma)
        .replace('__FESTNETZ__', this.anwenderData.festnetznummer)
        .replace('__MOBIL__', this.anwenderData.mobiltelefon)
        .replace('__EMAIL__', this.anwenderData.email);
    }
    return antwort || 'Ich kann Ihre Anfrage nicht verstehen. Könnten Sie bitte spezifischer fragen?';
  }

  getRollenAntwort(): string {
    let rollenAntwort = 'Sie üben aktuell folgende Rollen im System aus: ';
    this.anwenderData.rollen.forEach((value) => {
      rollenAntwort += ROLLEN_MAP[value.rolleID] + ', ';
    });
    return rollenAntwort;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DokumentEintragModel } from '@core/models/dokument-eintrag.model';

@Pipe({
  name: 'handbuchFilterPipe',
  pure: false,
})
export class HandbuchFilterPipe implements PipeTransform {
  transform(handbuchEintragList: DokumentEintragModel[], handbuchEintragFilter: string, applyFilterAssigned?: boolean) {
    const filteredHandbuchEintragList: DokumentEintragModel[] = [];
    handbuchEintragList.forEach((value) => {
      if (applyFilterAssigned && value.id === 'G3-01') {
        // do nothing
      } else {
        if (
          !handbuchEintragFilter ||
          value.name.toLocaleLowerCase().includes(String(handbuchEintragFilter).toLocaleLowerCase())
        )
          filteredHandbuchEintragList.push(value);
      }
    });
    return filteredHandbuchEintragList;
  }
}

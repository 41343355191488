import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { KeycloakService } from 'keycloak-angular';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BerechtigungModulModel } from '@core/models/berechtigung-modul.model';
import { UserProfile } from '@core/models/user-profile.model';
import { environment } from 'environments/environment';

import * as coreState from '@core/store/reducers';
import * as fromSelectors from '@core/store/selectors/user-profile.selectors';
import * as fromAnwenderDataSelectors from '@core/store/selectors/anwender-data.selectors';
import { ModalChatbotComponent } from '@shared/components/modal-chatbot/modal-chatbot.component';
import { AnwenderDataModel } from '@core/models/anwender-data.model';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit {
  expanded = false;
  userProfile$: Observable<UserProfile>;
  anwenderData$: Observable<AnwenderDataModel>;
  berechtigungModul$: Observable<BerechtigungModulModel>;
  subscriptions$ = new Subscription();

  anwenderData: AnwenderDataModel;
  activeUrl: string;

  toggleMenu = false;
  isLoading = false;

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(
    private keycloakService: KeycloakService,
    private store: Store<coreState.CoreModuleState>,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.userProfile$ = this.store.select(fromSelectors.getUser);
    this.berechtigungModul$ = this.store.select(fromAnwenderDataSelectors.selectBerechtigungModul);
    this.anwenderData$ = this.store.select(fromAnwenderDataSelectors.selectAnwenderData);

    this.subscriptions$.add(
      this.router.events
        .pipe(
          tap((ev) => {
            if (ev instanceof NavigationStart) {
              this.isLoading = true;
            }
            if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
              this.activeUrl = ev.url.replace('/', '');
              this.isLoading = false;
            }
          })
        )
        .subscribe()
    );

    this.subscriptions$.add(
      this.anwenderData$.subscribe((anwenderData: AnwenderDataModel) => {
        this.anwenderData = anwenderData;
      })
    );
  }

  onLogout() {
    let url = document.location.protocol + '//' + document.location.hostname;
    if (document.location.port !== '') {
      url = url + ':' + document.location.port;
    }

    url = url + environment.logoutUrl;

    this.keycloakService.logout(url);
  }

  onOpenChatbot() {
    const modalOptions = {
      size: 'xl',
    };
    const modalRef = this.modalService.open(ModalChatbotComponent, modalOptions);
    modalRef.componentInstance.activeUrl = this.activeUrl;
    modalRef.componentInstance.anwenderData = this.anwenderData;

    modalRef.result.then(
      (result) => {
        if (result) {
          //this.cancelEvent.emit();
        }
      },
      (err) => {
        if (err !== 'cancel') {
          throw new Error('Error while closing the modal: ' + err.toString());
        }
      }
    );
  }
}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-favorite',
  templateUrl: './modal-favorite.component.html',
  styleUrls: ['./modal-favorite.component.scss'],
})
export class ModalFavoriteComponent {
  @Input()
  name: string;
  @Input()
  description: string;
  @Input()
  canSaveFilter = true;

  constructor(public activeModal: NgbActiveModal) {}

  onSave({ form }) {
    if (form.valid) {
      this.activeModal.close(form.value);
    }
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }
}

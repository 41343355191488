import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { initializer } from './core/authentication/keycloak-init';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import localDE from '@angular/common/locales/de';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BASE_PATH } from '@core/portal-api';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '@core/config/configuration.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NpnSliderModule } from 'npn-slider';
import { roleInitializer, RoleService } from '@core/authorization/role.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './core/config/router-serializer';

registerLocaleData(localDE);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.assetsUrl}/help-text/`, '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    NpnSliderModule,
    NgxSliderModule,
    HttpClientModule,
    NgbToastModule,
    StoreModule.forRoot({ router: routerReducer }, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [ConfigurationService, KeycloakService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: roleInitializer,
      deps: [ConfigurationService, RoleService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

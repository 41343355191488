import { InvestitionChoiceListService } from '@app/core/services/choicelists.service';
import { InvestitionService } from '@app/core/services/investition.service';
import { AnwenderDataService } from '@core/services/anwender-data.service';
import { CachingService } from '@core/services/caching.service';
import { ChartsService } from '@core/services/charts.service';
import { DokumentGruppeService } from '@core/services/dokument-gruppe.service';
import { FavoritesService } from '@core/services/favorites.service';
import { GebaeudeService } from '@core/services/gebaeude.service';
import { LogEintragService } from '@core/services/log-eintrag.service';
import { ProblemMeldungService } from '@core/services/problem-meldung.service';
import { RechtematrixService } from '@core/services/rechtematrix.service';
import { ReferenzBildungService } from '@core/services/referenz-bildung.service';
import { SbhChatbotService } from '@core/services/sbh-chatbot.service';
import { SbhTaskService } from '@core/services/sbh-task.service';
import { SystemKonfigurationService } from '@core/services/system-konfiguration.service';
import { UserConfig } from '@core/services/user-config.service';
import { UserProfileService } from '@core/services/user-profile.service';
import { WirtschaftseinheitService } from '@core/services/wirtschaftseinheit.service';
import { AdminBerechtigungService } from './admin-berechtigung.service';
import { BerechtigungAnwenderService } from './berechtigung-anwender.service';
import { BerechtigungModulService } from './berechtigung-modul.service';
import { WirtschaftseinheitDetailsService } from './wirtschaftseinheit-details.service';
import { KostenPrognoseService } from '@core/services/kosten-prognose.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const services: any[] = [
  UserProfileService,
  WirtschaftseinheitService,
  SbhTaskService,
  WirtschaftseinheitDetailsService,
  InvestitionChoiceListService,
  InvestitionService,
  UserConfig,
  GebaeudeService,
  AnwenderDataService,
  RechtematrixService,
  FavoritesService,
  ChartsService,
  LogEintragService,
  ProblemMeldungService,
  CachingService,
  SystemKonfigurationService,
  ReferenzBildungService,
  DokumentGruppeService,
  SbhChatbotService,
  AdminBerechtigungService,
  BerechtigungAnwenderService,
  BerechtigungModulService,
  KostenPrognoseService,
];

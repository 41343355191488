import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent {
  @Input()
  headerText: string;
  @Input()
  confirmationText: string;
  @Input()
  confirmButtonText: string;
  @Input()
  cancelButtonText = 'Abbrechen';
  @Input()
  showSpinner$: Observable<boolean>;

  // Workaround until refactoring
  @Input()
  isGuardModal: boolean;

  @Input()
  useExplicitCancelResult = false;

  @Output()
  confirmClicked = new EventEmitter<void>();

  constructor(public activeModal: NgbActiveModal) {}

  onCancel() {
    if (this.useExplicitCancelResult) this.activeModal.close('cancel');
    else this.activeModal.dismiss('cancel');
  }

  onSave() {
    if (this.isGuardModal) {
      this.activeModal.close('confirmed');
    }

    if (this.showSpinner$) {
      this.confirmClicked.emit();
    } else {
      this.activeModal.close('confirmed');
    }
  }
}

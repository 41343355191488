/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Datensatz einer Wirtschaftseinheit
 */
export interface WirtschaftseinheitenObject { 
    /**
     * Eindeutige Id der Wirtschaftseinheit
     */
    id?: string;
    /**
     * Name der Wirtschaftseinheit
     */
    name?: string;
    /**
     * SAP ID der Wirtschaftseinheit
     */
    sapWeID?: string;
    /**
     * Eindeutige Objekt ID der Wirtschaftseinheit
     */
    weObjektID?: string;
    /**
     * Bezirkt in der die Wirtschaftseinheit sich befindet
     */
    bezirk?: string;
    /**
     * Region in der die Wirtschaftseinheit sich befindet
     */
    seplRegion?: number;
    /**
     * Region in der die Wirtschaftseinheit sich befindet
     */
    region?: string;
    /**
     * Eindeutige Objekt ID der Region der Wirtschaftseinheit
     */
    regionObjektID?: string;
    /**
     * true, wenn Wirtschafseinheit gueltig ist
     */
    isGueltig?: boolean;
}


<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Problem melden</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #problemMeldung="ngForm" (ngSubmit)="onSave(problemMeldung)" id="problemMeldung">
    <mat-form-field appearance="outline" class="d-block w-100">
      <mat-label>Titel</mat-label>
      <input matInput required name="meldungTitel" [ngModel]="meldungTitel" disabled="{{ !isFreeText }}" />

      <mat-error>Bitte prüfen Sie Ihre Eingabe.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block w-100">
      <mat-label>Kommentar</mat-label>
      <textarea matInput rows="10" required name="meldungText" [ngModel]="meldungText"></textarea>

      <mat-error>Bitte prüfen Sie Ihre Eingabe.</mat-error>
    </mat-form-field>
    <span
      >* Es wird ein Support-Ticket erstellt. Bitte beschreiben Sie das Problem daher so detailliert wie möglich.</span
    >
  </form>
</div>
<div class="modal-footer">
  <button type="button" mat-stroked-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
    abbrechen
  </button>
  <button mat-raised-button color="primary" type="submit" form="problemMeldung" [disabled]="!problemMeldung.valid">
    speichern
  </button>
</div>

import { Action, createReducer, on } from '@ngrx/store';

import { UserProfile } from '@core/models/user-profile.model';

import * as UserProfileActions from '@app/core/store/actions/user-profile.actions';

const initialState: UserProfile = {
  authUserID: null,
  id: null,
  firstName: null,
  lastName: null,
  displayName: null,
};

const userProfileReducer = createReducer(
  initialState,
  on(UserProfileActions.loadProfileSuccess, (state, { profile }) => ({ ...state, ...profile }))
);

export function reducer(state: UserProfile | undefined, action: Action) {
  return userProfileReducer(state, action);
}

export const getUserProfileData = (state: UserProfile) => state;

<div id="chatModal">
  <div class="modal-header" style="background: #f7f7eb">
    <h4 class="modal-title" id="modal-basic-title">Chatbot Smart Workplace</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div>
    <span
      *ngFor="let g of chatbotService.chatbotGruppen"
      [ngClass]="selectedMessageGroup === g.id ? 'badge-dark' : 'badge badge-primary'"
      class="badge ml-2 mt-2 custom-badge"
      (click)="onSelectMessageGroup(g.id)"
      >{{ g.label }}</span
    >
  </div>
  <div class="modal-body" style="height: 750px; overflow-y: auto">
    <div class="message from">{{ getGreeting() }}</div>
    <ng-container *ngFor="let message of (konversation$ | async)?.messages">
      <div
        class="message"
        [ngClass]="{ from: message.quelle === 'system', to: message.quelle === 'anwender' }"
        [innerHTML]="message.inhalt"
      ></div>
    </ng-container>
    <span class="blink ml-3 mt-2 mb-2" style="font-size: 16px; font-weight: bold; color: #003dc0" *ngIf="antwortPending"
      >Anfrage wird bearbeitet...</span
    >
    <div class="custom-mat-input">
      <mat-form-field appearance="outline">
        <mat-label>Chat Bot</mat-label>
        <input id="inputMessage" matInput [(ngModel)]="value" (keyup.enter)="sendMessage()" [ngbTypeahead]="search" />
      </mat-form-field>
      <button
        style="background: white; border-color: whitesmoke"
        class="custom-button-link mb-3 ml-2"
        (click)="sendMessage()"
      >
        <mat-icon class="mt-2" style="color: #2095fe">chat</mat-icon>
      </button>
      <button
        style="background: white; border-color: whitesmoke"
        class="custom-button-link mb-3 ml-2"
        (click)="clearMessages()"
      >
        <mat-icon class="mt-2" style="color: #2095fe">delete</mat-icon>
      </button>
    </div>

    <ng-template #dialogInfo>
      <span [className]="'float-right'">Anzahl Nachrichten: {{ (konversation$ | async)?.messages?.length }}</span>
    </ng-template>
  </div>

  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
      abbrechen
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Converter } from '@app/core/models/converter';
import { AnwenderRolleModel } from '@core/models/anwender-rolle.model';
import { AnwenderRolle } from '@core/portal-api';

@Injectable()
export class AnwenderRolleConverter implements Converter<AnwenderRolleModel, AnwenderRolle> {
  convertFromApiModel(anwenderRolle: AnwenderRolle): AnwenderRolleModel {
    if (anwenderRolle === null || anwenderRolle === undefined) {
      return undefined;
    }

    const anwenderRolleModel: AnwenderRolleModel = {
      ebene: anwenderRolle.ebene,
      referenzObjektID: anwenderRolle.referenzObjektID,
      rolleID: anwenderRolle.rolleID,
    };

    return anwenderRolleModel;
  }

  convertToApiModel(anwenderRolleData: AnwenderRolleModel): AnwenderRolle {
    if (anwenderRolleData === null || anwenderRolleData === undefined) {
      return undefined;
    }

    let ebene: AnwenderRolle.EbeneEnum;
    if (anwenderRolleData.ebene === 'ALLE') {
      ebene = AnwenderRolle.EbeneEnum.Alle;
    } else if (anwenderRolleData.ebene === 'REGION') {
      ebene = AnwenderRolle.EbeneEnum.Region;
    } else {
      ebene = AnwenderRolle.EbeneEnum.Wirtschaftseinheit;
    }

    const anwenderRolle: AnwenderRolle = {
      ebene: ebene,
      referenzObjektID: anwenderRolleData.referenzObjektID,
      rolleID: anwenderRolleData.rolleID,
    };

    return anwenderRolle;
  }
}

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { FavoritesService } from '@app/core/services/favorites.service';
import { NotificationType } from '@app/core/models/notification.model';
import { FavoriteModel } from '@app/core/models/favorite.model';

import * as fromUserProfileSelector from '@core/store/selectors/user-profile.selectors';
import * as fromUserConfigSelectors from '@core/store/selectors/user-config.selectors';
import * as fromNotificationActions from '@app/core/store/actions/notifications.actions';
import * as fromActions from '@app/core/store/actions/favorites.actions';
import * as fromState from '@core/store/reducers';

@Injectable()
export class FavoritesEffects {
  constructor(
    private actions$: Actions,
    private service: FavoritesService,
    private store: Store<fromState.CoreModuleState>
  ) {}

  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFavorites),
      withLatestFrom(this.store.select(fromUserProfileSelector.getAnwenderEmailBase64), (_, userID) => userID),
      switchMap((userID) =>
        this.service.loadFavorites(userID).pipe(
          map((favorites) => fromActions.loadFavoritesSuccess({ favorites })),
          catchError(() => of(fromActions.loadFavoritesFail()))
        )
      )
    )
  );

  prepareWeFavoriteWithFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.prepareWeFavoriteWithFilter),
      withLatestFrom(
        this.store.select(fromUserConfigSelectors.getInvestitionFilterConfig),
        this.store.select(fromUserConfigSelectors.getInvestitionTableConfigMin),
        this.store.select(fromUserConfigSelectors.getProjekteFilterConfig),
        this.store.select(fromUserConfigSelectors.getProjekteTableConfigMin),
        (action, invFilterConfig, invLayoutConfig) => ({ fav: action.favorite, invFilterConfig, invLayoutConfig })
      ),
      map(({ fav, invFilterConfig, invLayoutConfig }) => {
        const favorite: FavoriteModel = {
          ...fav,
          referenceStructure: {
            ...fav.referenceStructure,
            filterConfig: invFilterConfig,
            tableLayoutConfig: invLayoutConfig.columns,
            tableConfig: {
              configID: invLayoutConfig.configID,
              columns: invLayoutConfig.columns,
            },
          },
        };
        return fromActions.addWeFavorite({ favorite });
      })
    )
  );

  prepareGebaeudeFavoriteWithFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.prepareGebaeudeFavoriteWithFilter),
      withLatestFrom(
        this.store.select(fromUserConfigSelectors.getGebaeudeFilterConfig),
        this.store.select(fromUserConfigSelectors.getGebaeudeTableConfigMin),
        (action, filterConfig, layoutConfig) => ({ fav: action.favorite, filterConfig, layoutConfig })
      ),
      map(({ fav, filterConfig, layoutConfig }) => {
        const favorite: FavoriteModel = {
          ...fav,
          referenceStructure: {
            ...fav.referenceStructure,
            filterConfig,
            tableLayoutConfig: layoutConfig.columns,
          },
        };
        return fromActions.addGebaeudeFavorite({ favorite });
      })
    )
  );

  addFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addWeFavorite, fromActions.addInvestitionFavorite, fromActions.addGebaeudeFavorite),
      withLatestFrom(this.store.select(fromUserProfileSelector.getAnwenderEmailBase64), (action, userID) => ({
        userID,
        favorite: action.favorite,
      })),
      switchMap(({ userID, favorite }) =>
        this.service.addFavorite(userID, favorite).pipe(
          map(() => fromActions.addFavoriteSuccess()),
          catchError(() => of(fromActions.addFavoriteFail()))
        )
      )
    )
  );

  addFavoriteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addFavoriteSuccess),
      switchMap(() => [
        fromActions.loadFavorites(),
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Favorit erfolgreich angelegt', type: NotificationType.SUCCESS },
        }),
      ])
    )
  );

  removeFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeFavorite),
      withLatestFrom(this.store.select(fromUserProfileSelector.getAnwenderEmailBase64), (action, userID) => ({
        id: action.id,
        userID,
      })),
      switchMap(({ userID, id }) => {
        return this.service.deleteFavorite(userID, id).pipe(
          map(() => fromActions.removeFavoriteSuccess()),
          catchError(() => of(fromActions.removeFavoriteFail()))
        );
      })
    )
  );

  removeFavoriteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeFavoriteSuccess),
      map(() => fromActions.loadFavorites())
    )
  );
}

import { createAction, props } from '@ngrx/store';
import { Gebaeudeanlage } from '@core/models/gebaeudeanlage.model';

export const loadGebaeude = createAction('[Gebaeude Details] Load gebaeude', props<{ id: string }>());

export const loadGebaeudeSuccess = createAction(
  '[Gebaeude Details] Load gebaeude success',
  props<{ gebaeude: Gebaeudeanlage }>()
);

export const loadGebaeudeFailure = createAction(
  '[Gebaeude Details] Load gebaeude failure',
  props<{ error: TypeError; objektID: string }>()
);

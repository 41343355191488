import { createAction, props } from '@ngrx/store';

import { FavoriteModel } from '@app/core/models/favorite.model';

export const loadFavorites = createAction('[Core/Favorites] Load favorites');
export const loadFavoritesSuccess = createAction(
  '[Core/Favorites] Load favorites success',
  props<{ favorites: FavoriteModel[] }>()
);
export const loadFavoritesFail = createAction('[Core/Favorites] Load favorites fail');

export const addWeFavorite = createAction(
  '[Core/Favorites] Add Wirtschaftseinheit as favorite',
  props<{ favorite: FavoriteModel }>()
);

export const prepareWeFavoriteWithFilter = createAction(
  '[Core/Favorites] Add Wirtschaftseinheit as favorite with filter',
  props<{ favorite: FavoriteModel }>()
);

export const addInvestitionFavorite = createAction(
  '[Core/Favorites] Add Investition as favorite',
  props<{ favorite: FavoriteModel }>()
);

export const addGebaeudeFavorite = createAction(
  '[Core/Favorites] Add Gebaeude as favorite',
  props<{ favorite: FavoriteModel }>()
);
export const prepareGebaeudeFavoriteWithFilter = createAction(
  '[Core/Favorites] Add Gebaeude as favorite with filter',
  props<{ favorite: FavoriteModel }>()
);

export const addFavoriteSuccess = createAction('[Core/Favorites] Add Favorite success');
export const addFavoriteFail = createAction('[Core/Favorites] Add Favorite fail');

export const removeFavorite = createAction('[Core/Favorites] Remove Favorite', props<{ id: string }>());
export const removeFavoriteSuccess = createAction('[Core/Favorites] Remove Favorite success');
export const removeFavoriteFail = createAction('[Core/Favorites] Remove Favorite fail');

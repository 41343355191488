import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgEinheitMapping } from '../models/admin-berechtigung-state.model';
import { StammdatenService, WirtschafteinheitListeResponse } from '../portal-api';

@Injectable()
export class AdminBerechtigungService {
  constructor(private service: StammdatenService) {}

  getEbeneRollenMapping(): Observable<OrgEinheitMapping> {
    return this.service.getWirtschaftseinheiten().pipe(
      map((res: WirtschafteinheitListeResponse) => {
        const regionList: { id: string; name: string; wirtschaftseinheitList: { id: string; name: string }[] }[] = [];
        const orgEinheit: OrgEinheitMapping = { regionList: regionList };
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].wirtschaftseinheiten.length; j++) {
            const region = {
              id: res.data[i].wirtschaftseinheiten[j].regionObjektID,
              name: res.data[i].wirtschaftseinheiten[j].region,
              wirtschaftseinheitList: [],
            };
            const we = {
              id: res.data[i].wirtschaftseinheiten[j].weObjektID,
              name: res.data[i].wirtschaftseinheiten[j].name,
            };
            if (
              orgEinheit.regionList.findIndex((x) => x.id === res.data[i].wirtschaftseinheiten[j].regionObjektID) === -1
            ) {
              region.wirtschaftseinheitList.push(we);
              orgEinheit.regionList.push(region);
            }
            if (
              !orgEinheit.regionList[
                orgEinheit.regionList.findIndex((x) => x.id === res.data[i].wirtschaftseinheiten[j].regionObjektID)
              ].wirtschaftseinheitList.includes(we)
            ) {
              orgEinheit.regionList[
                orgEinheit.regionList.findIndex((x) => x.id === res.data[i].wirtschaftseinheiten[j].regionObjektID)
              ].wirtschaftseinheitList.push(we);
            }
          }
        }
        const orgEinheitMapping: OrgEinheitMapping = orgEinheit;
        return orgEinheitMapping;
      })
    );
  }
}

<ul class="list-group list-group-flush">
  <li class="list-group-item" *ngFor="let card of numberCards">
    <span
      *ngFor="let line of numberLines"
      class="loader pulse"
      aria-label="loading"
      aria-busy="true"
      role="progressbar"
      tabindex="0"
    >
    </span>
  </li>
</ul>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnwenderRolle, BerechtigungService } from '@core/portal-api';
import { RechtematrixEntryConverter } from '@core/converters/rechtematrix-entry-converter.service';
import { RechtematrixEntryModel } from '@core/models/rechtematrix-entry.model';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { UserProfile } from '@core/models/user-profile.model';
import { Wirtschaftseinheit } from '@app/core/models/wirtschaftseinheit.model';
import EbeneEnum = AnwenderRolle.EbeneEnum;

@Injectable()
export class RechtematrixService {
  constructor(private berechtigungService: BerechtigungService, private converterService: RechtematrixEntryConverter) {}

  getRechtematrix(): Observable<RechtematrixEntryModel[]> {
    return this.berechtigungService.getBerechtigungMatrix().pipe(
      map((res) => {
        const rechtematrix: RechtematrixEntryModel[] = [];
        res.data.forEach((matrixEntry) => rechtematrix.push(this.converterService.convertFromApiModel(matrixEntry)));

        return rechtematrix;
      })
    );
  }

  updateRechtematrixPermittance(
    rechtematrixEntryList: RechtematrixEntryModel[],
    anwenderData: AnwenderDataModel,
    userProfile: UserProfile,
    wirtschaftseinheit?: Wirtschaftseinheit
  ): Observable<RechtematrixEntryModel[]> {
    const updatedMatrixEntryList: RechtematrixEntryModel[] = [];
    rechtematrixEntryList.forEach((matrixEntry) => {
      const updatedMatrixEntry: RechtematrixEntryModel = { ...matrixEntry };
      let isPermitted = false;
      if (!matrixEntry.rollenIntern || matrixEntry.rollenIntern.length === 0) {
        isPermitted = true;
      } else {
        for (const anwenderRolle of anwenderData.rollen) {
          if (matrixEntry.rollenIntern.includes(anwenderRolle.rolleID)) {
            if (anwenderRolle.ebene === EbeneEnum.Alle) {
              isPermitted = true;
              break;
            } else {
              if (!matrixEntry.zustaendig) {
                isPermitted = true;
                break;
              } else {
                if (wirtschaftseinheit) {
                  if (
                    (anwenderRolle.ebene === EbeneEnum.Wirtschaftseinheit &&
                      wirtschaftseinheit.weObjektID === anwenderRolle.referenzObjektID) ||
                    (anwenderRolle.ebene === EbeneEnum.Region &&
                      wirtschaftseinheit.regionObjektID === anwenderRolle.referenzObjektID)
                  ) {
                    isPermitted = true;
                    break;
                  }
                }
              }
            }
          }
        }
      }

      const isPermittedIAM: boolean = userProfile.userRoles.some((v) => matrixEntry.rollenIAM.includes(v));

      updatedMatrixEntry.isPermitted = isPermitted && isPermittedIAM;
      updatedMatrixEntryList.push(updatedMatrixEntry);
    });

    return of(updatedMatrixEntryList);
  }
}

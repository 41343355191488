/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MessageObject } from './messageObject';


export interface ResponseObject { 
    /**
     * Status der Anwort
     */
    status: ResponseObject.StatusEnum;
    /**
     * Antwortmeldung
     */
    message: string;
    infos?: Array<MessageObject>;
    warnings?: Array<MessageObject>;
    errors?: Array<MessageObject>;
}
export namespace ResponseObject {
    export type StatusEnum = 'OK' | 'ERROR';
    export const StatusEnum = {
        Ok: 'OK' as StatusEnum,
        Error: 'ERROR' as StatusEnum
    };
}



import { ColumnType, TableColumn } from '../models/table-column.model';

export const GEBAEUDE_TABLE_CONFIG: TableColumn[] = [
  { name: 'Wirtschaftseinheit', prop: 'weBezeichnung', showColumn: true, hasFilter: true, width: 160 },
  {
    name: 'Gebäudenummer',
    prop: 'gebaeudeNummer',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    width: 70,
  },
  { name: 'Gebäudename', prop: 'gebaeudeBezeichnung', showColumn: true, width: 160 },
  { name: 'Region', prop: 'weRegion', showColumn: true, hasFilter: true, width: 160 },
  { name: 'Bezirk', prop: 'weBezirk', showColumn: true, hasFilter: true, width: 160 },
  {
    name: 'Nettogebäudefläche in ㎡',
    prop: 'nettogrundflaeche',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Nutzfläche in ㎡',
    prop: 'nutzflaeche',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Technikfläche in ㎡',
    prop: 'technikflaeche',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Verkehrsfläche in ㎡',
    prop: 'verkehrsflaeche',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Mietfläche Gesamt in ㎡',
    prop: 'mietflaecheGesamt',
    cellClass: 'text-right',

    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Mietfläche BSB in ㎡',
    prop: 'mietflaecheBSB',
    cellClass: 'text-right',

    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Mietfläche Sonstige in ㎡',
    prop: 'mietflaecheSonstige',
    cellClass: 'text-right',

    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: true,
    width: 160,
    hasSlider: true,
  },
  {
    name: 'Gebäudeklasse',
    prop: 'gebaeudeKlasse',
    cellClass: 'text-right',
    showColumn: true,
    hasFilter: true,
    width: 160,
  },
  {
    name: 'Gebäudeklasse seit',
    prop: 'gebaeudeKlasseSeit',
    showColumn: false,
    hasFilter: true,
    width: 160,
    cellClass: 'text-right',
  },
  { name: 'Änderungsgrund Gebäudeklasse', prop: 'gebaeudeKlasseGrund', showColumn: false, hasFilter: true, width: 160 },
  { name: 'Gebäudetyp', prop: 'gebaeudeTyp', showColumn: false, hasFilter: true, width: 160 },
  { name: 'Gebäudeart', prop: 'gebaeudeArt', showColumn: false, hasFilter: true, width: 160 },
  {
    name: 'Baujahr',
    prop: 'baujahr',
    cellClass: 'text-right',

    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: false,
    width: 160,
  },
  {
    name: 'Längengrad',
    prop: 'laengengradOestlich',
    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: false,
    width: 160,
  },
  {
    name: 'Breitengrad',
    prop: 'breitengradNoerdlich',
    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: false,
    width: 160,
  },
  { name: 'Gültig?', prop: 'gueltigJN', showColumn: false, width: 160 },
  { name: 'Gültig von', prop: 'gueltigVon', showColumn: false, width: 160, cellClass: 'text-right' },
  { name: 'Gültig bis', prop: 'gueltigBis', showColumn: false, width: 160, cellClass: 'text-right' },
  {
    name: 'WE-Nummer',
    prop: 'weNummer',
    type: ColumnType.NUMBER,
    showColumn: false,
    hasComparator: true,
    hasPipe: false,
    hasFilter: true,
    width: 160,
  },
  { name: 'Dienstleister', prop: 'weFmDienstleister', showColumn: false, hasFilter: true, width: 160 },
  { name: 'Stadtteil', prop: 'weStadtteil', showColumn: false, hasFilter: true, width: 160 },
];

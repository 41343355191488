import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ifNull',
})
export class NullishPipe implements PipeTransform {
  transform(expression: unknown, defaultText: string): unknown {
    if (expression === null || expression === undefined) {
      return defaultText;
    }

    return expression;
  }
}

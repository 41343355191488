import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { UserProfileService } from '@core/services/user-profile.service';

import * as fromActions from '../actions/user-profile.actions';
import * as fromFavoritesActions from '@app/core/store/actions/favorites.actions';
import * as fromTasksActions from '@app/core/store/actions/tasks.actions';

@Injectable()
export class UserProfileEffects {
  constructor(private userService: UserProfileService, private actions$: Actions) {}

  loadUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadProfile),
      mergeMap(() =>
        of(this.userService.getCurrentUsersProfile()).pipe(
          map((profile) => fromActions.loadProfileSuccess({ profile })),
          catchError(() => of(fromActions.loadProfileFail()))
        )
      )
    );
  });

  loadUserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProfileSuccess),
      switchMap(() => [fromFavoritesActions.loadFavorites(), fromTasksActions.loadTasks()])
    )
  );
}

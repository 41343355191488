import { createAction, props } from '@ngrx/store';
import { AnwenderDataModel } from '@core/models/anwender-data.model';

export const loadAnwenderData = createAction('[Core/AnwenderData] Load anwender data');

export const loadAnwenderDataSuccess = createAction(
  '[Core/AnwenderData] Load anwender data success',
  props<{ anwender: AnwenderDataModel }>()
);

export const loadAnwenderDataFailure = createAction(
  '[Core/AnwenderData] Load anwender data failure',
  props<{ error: unknown }>()
);

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Die Module eines Anwenders
 */
export interface BerechtigungModul { 
    /**
     * Das Modul der Anwender
     */
    modulID: string;
    emailList?: Array<string>;
    removeEmailList?: Array<string>;
}


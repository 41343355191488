import { Injectable } from '@angular/core';
import { BerechtigungModulConverter } from '../converters/berechtigung-modul-converter.service';
import { BerechtigungModulBatchModel } from '../models/berechtigung-modul-batch.model';
import { BerechtigungService } from '../portal-api';

@Injectable()
export class BerechtigungModulService {
  constructor(private berechtigungService: BerechtigungService, private converter: BerechtigungModulConverter) {}

  updateModuleBatch(moduleBatch: BerechtigungModulBatchModel) {
    const moduleBatchDTO = this.converter.convertToApiModel(moduleBatch);

    return this.berechtigungService.postBerechtigungModul(moduleBatchDTO);
  }
}

import { Injectable } from '@angular/core';
import { BerechtigungAnwenderModel } from '../models/berechtigung-anwender.model';
import { Converter } from '../models/converter';
import { BerechtigungAnwender } from '../portal-api';

@Injectable()
export class BerechtigungAnwenderConverter implements Converter<BerechtigungAnwenderModel, BerechtigungAnwender> {
  convertToApiModel(berechtigungAnwenderData: BerechtigungAnwenderModel): BerechtigungAnwender {
    if (berechtigungAnwenderData === null || berechtigungAnwenderData === undefined) {
      return undefined;
    }

    let ebene: BerechtigungAnwender.EbeneIDEnum;
    if (berechtigungAnwenderData.ebeneID.toUpperCase() === 'ALLE') {
      ebene = BerechtigungAnwender.EbeneIDEnum.Alle;
    } else if (berechtigungAnwenderData.ebeneID.toUpperCase() === 'REGION') {
      ebene = BerechtigungAnwender.EbeneIDEnum.Region;
    } else {
      ebene = BerechtigungAnwender.EbeneIDEnum.Wirtschaftseinheit;
    }

    const berechtigungAnwender: BerechtigungAnwender = {
      ebeneID: ebene,
      rolleID: berechtigungAnwenderData.rolleID,
      refObjID: berechtigungAnwenderData.refObjID,
      emailList: berechtigungAnwenderData.emailList,
      removeEmailList: berechtigungAnwenderData.removeEmailList,
    };

    return berechtigungAnwender;
  }
}

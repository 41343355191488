import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResponseObject, VorhabenService } from '@core/portal-api';

@Injectable()
export class ReferenzBildungService {
  constructor(private vorhabenService: VorhabenService) {}

  saveReferenzbildung(investitionRefs: string[]): Observable<ResponseObject> {
    //return of(null);
    return this.vorhabenService.postVorhabenReferenzwertbildung(investitionRefs);
  }
}

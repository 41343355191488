import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DatepickerFormatterService } from '@core/config/datepicker-formatter.service';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { DatepickerAdapterService } from '@core/config/datepicker-adapter.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SharedModule } from '@shared/shared.module';
import { converters } from '@core/converters';
import { ApiModule } from '@core/portal-api';
import { services } from '@core/services';
import { guards } from '@core/guards';

import * as fromReducers from '@core/store/reducers';
import * as fromEffects from '@core/store/effects';

@NgModule({
  imports: [
    ApiModule,
    RouterModule,
    SharedModule,
    MatSnackBarModule,
    StoreModule.forFeature('core', fromReducers.coreModuleReducers),
    EffectsModule.forFeature([...fromEffects.coreEffects]),
    MatProgressSpinnerModule,
  ],
  providers: [
    ...guards,
    ...converters,
    ...services,
    { provide: NgbDateAdapter, useClass: DatepickerAdapterService },
    { provide: NgbDateNativeAdapter, useValue: undefined },
    { provide: NgbDateParserFormatter, useClass: DatepickerFormatterService },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (keycloak: KeycloakService, modalService: NgbModal) => {
        return new AuthInterceptor(keycloak, modalService);
      },
      multi: true,
      deps: [KeycloakService, NgbModal],
    },
  ],
  declarations: [NavigationMenuComponent],
  exports: [NavigationMenuComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, ngbModalConfig: NgbModalConfig) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    ngbModalConfig.backdrop = 'static';
  }
}

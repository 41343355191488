import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdministrationService } from './api/administration.service';
import { AnalysenService } from './api/analysen.service';
import { AnwenderService } from './api/anwender.service';
import { BerechtigungService } from './api/berechtigung.service';
import { ChatbotService } from './api/chatbot.service';
import { ChoiceListsService } from './api/choiceLists.service';
import { DokumenteService } from './api/dokumente.service';
import { ExportService } from './api/export.service';
import { KommunikationService } from './api/kommunikation.service';
import { KonfigurationService } from './api/konfiguration.service';
import { LoggingService } from './api/logging.service';
import { ProjektService } from './api/projekt.service';
import { ProjekteService } from './api/projekte.service';
import { StammdatenService } from './api/stammdaten.service';
import { TaskService } from './api/task.service';
import { UserService } from './api/user.service';
import { VorhabenService } from './api/vorhaben.service';
import { WizardRulesService } from './api/wizardRules.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

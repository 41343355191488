import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Investition } from '@app/core/models/investition.model';
import { ExportService, ProjektService, StammdatenService, VorhabenService } from '@core/portal-api';
import { Umsatz } from '@app/core/models/umsatz.model';
import { InvestitionConverter } from '@app/core/converters/investition-converter.service';
import { prepareFileToDownload } from '@app/shared/utils';
import { ProjektAnlegenConverter } from '../converters/projekt-anlegen-converter.service';
import { Projekt } from '../models/projekt-anlegen-state.model';

@Injectable()
export class WirtschaftseinheitDetailsService {
  constructor(
    private vorhabenService: VorhabenService,
    private stammdatenService: StammdatenService,
    private exportService: ExportService,
    private projectConverter: InvestitionConverter,
    private projectService: ProjektService,
    private projektAnlegenConverter: ProjektAnlegenConverter
  ) {}

  getProjektList(id: string): Observable<Investition[]> {
    return this.vorhabenService.getVorhabenByWirtschaftseinheiten(id).pipe(
      map((res) => {
        const ret: Investition[] = [];

        res.data.forEach((serverObject) => {
          if (serverObject) {
            const projectModel: Investition = this.projectConverter.convertFromApiModel(serverObject);
            ret.push(projectModel);
          }
        });
        return ret;
      })
    );
  }

  saveProject(project: Projekt) {
    const result = this.projektAnlegenConverter.convertToApiModel(project);
    return this.projectService.postProjekt(result);
  }

  getUmsatzList(id: string): Observable<Umsatz[]> {
    return this.stammdatenService.getUmsaetzeByWirtschaftseinheiten(id).pipe(
      map((res) => {
        const umsatzList = [];
        res.data.forEach((serverModel) => {
          const model: Umsatz = {
            we: serverModel?.we,
            total: serverModel?.regionGesamt,
            totalReference: serverModel?.regionGesamtRef,
            year: serverModel?.head,
            regionSanierung: serverModel?.regionSanierung,
            regionAbriss: serverModel?.regionAbriss,
            regionZuschuss: serverModel?.regionZuschuss,
            regionUmbau: serverModel?.regionUmbau,
            regionZubau: serverModel?.regionZubau,
          };
          umsatzList.push(model);
        });
        return umsatzList;
      })
    );
  }

  exportVorhaben(weID: string) {
    return this.exportService.getExportVorhaben(weID).pipe(
      map((res) => {
        if (res.file && res.filename && res.mimetype) {
          prepareFileToDownload(res.file, res.filename, res.mimetype);
        }
      })
    );
  }
}

export const ROLLEN_MAP = {
  BA: 'Betriebsarbeiter',
  BL: 'Bereichsleitung',
  BLA: 'Bereichsleitung-Assistenz',
  BSB: 'Planung-Extern BSB',
  FI3: 'Bilanzbuchhaltung',
  FI4: 'Kreditorenbuchhaltung',
  GL: 'Geschäftsleitung',
  GLA: 'Geschäftsleitung-Assistenz',
  GST: 'Gast',
  HGV: 'Controlling-Extern HGV',
  HM: 'Hausmeister',
  MA: 'Moduladministrator',
  OCO: 'Operatives Controlling',
  OM: 'Objektmanager',
  OML: 'Objektmanagement Leitung',
  PLF: 'Projektleitung Freianlagen',
  PLT: 'Projektleitg. techn.Gbd - Ausrüstung',
  PSE: 'Projektsteuerung-Extern',
  PSI: 'Projektsteuerung-Intern',
  RCO: 'Regional-Controllingleitung',
  RL: 'Regionalleitung',
  RLA: 'Regionalleitung-Assistenz',
  SA: 'Systemadministrator',
  STS: 'Stabsstellen(CO / DuP / KM / RM)',
  UCO: 'Unternehmens - Controlling',
  ZFM: 'ZFM - Abteilungsleitung',
  ZFMS: 'ZFM - Sachbearbeitung',
};

import { Component, Input, OnInit } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as fromCoreState from '@core/store/reducers';
import * as fromChatbotSelectors from '@core/store/selectors/chatbot.selectors';
import * as fromChatbotActions from '@core/store/actions/chatbot.actions';
import { SbhChatbotService } from '@core/services/sbh-chatbot.service';
import { ChatbotMessageModel } from '@core/models/chatbot-message.model';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { ChatbotKonversationModel } from '@core/models/chatbot-konversation.model';

@Component({
  selector: 'app-modal-chatbot',
  templateUrl: './modal-chatbot.component.html',
  styleUrls: ['./modal-chatbot.component.scss'],
})
export class ModalChatbotComponent implements OnInit {
  @Input()
  activeUrl: string;

  @Input()
  anwenderData: AnwenderDataModel;

  modulID: string;
  modulName: string;
  selectedMessageGroup: string;

  messageList: ChatbotMessageModel[] = [];
  konversation$: Observable<ChatbotKonversationModel>;

  value: string;

  antwortPending = false;

  constructor(
    public chatbotService: SbhChatbotService,
    public activeModal: NgbActiveModal,
    private coreStore: Store<fromCoreState.CoreModuleState>
  ) {}

  ngOnInit() {
    this.konversation$ = this.coreStore.select(fromChatbotSelectors.selectKonversation);
    this.determineChatbotContext();
    this.chatbotService.initialize(this.modulID, this.modulName, this.anwenderData);
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.getFragen()
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  getFragen(): string[] {
    const fragenList: string[] = [];
    const filteredChatbotGruppeModel = this.chatbotService.chatbotGruppen.filter(
      (gruppe) => gruppe.id === this.selectedMessageGroup
    );
    if (filteredChatbotGruppeModel) {
      filteredChatbotGruppeModel[0].eintragList.forEach((eintrag) => {
        if (eintrag.frage) fragenList.push(eintrag.frage);
      });
    }
    return fragenList;
  }

  sendMessage() {
    this.antwortPending = true;
    this.chatbotService.getSystemAntwort(this.value, this.selectedMessageGroup);
    this.value = '';
    setTimeout(() => {
      this.antwortPending = false;
    }, 1500);

    /*
    document.querySelector('#chatModal').scrollBy({
      top: 200,
      left: 100,
      behavior: 'smooth',
    });
     */
  }

  clearMessages(): void {
    this.coreStore.dispatch(fromChatbotActions.clearInitialKonversation());
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }

  onSelectMessageGroup(messageGroup: string) {
    this.selectedMessageGroup = messageGroup;
  }

  determineChatbotContext() {
    if (!this.activeUrl) this.activeUrl = 'start';
    if (this.activeUrl) {
      if (this.activeUrl === 'start') {
        this.modulID = 'SWP';
        this.modulName = 'Dashboard';
        this.selectedMessageGroup = 'ALLGEMEIN';
      } else if (this.activeUrl === 'admin') {
        this.modulID = 'ADM';
        this.modulName = 'Administration';
        this.selectedMessageGroup = 'ALLGEMEIN';
      } else if (this.activeUrl.startsWith('rahmenplan')) {
        this.modulID = 'RPL';
        this.modulName = 'Rahmenplan';
        this.selectedMessageGroup = 'INVESTITION';
      } else if (this.activeUrl === 'gebaeude') {
        this.modulID = 'GBD';
        this.modulName = 'Gebäude';
        this.selectedMessageGroup = 'ALLGEMEIN';
      } else if (this.activeUrl === 'handbuch') {
        this.modulID = 'OGH';
        this.modulName = 'Organisationshadbuch';
        this.selectedMessageGroup = 'ALLGEMEIN';
      } else if (this.activeUrl === 'prozesse') {
        this.modulID = 'PRZ';
        this.modulName = 'Prozesse';
        this.selectedMessageGroup = 'PROZESSE';
      } else {
        this.modulID = 'SWP';
        this.modulName = 'Dashboard';
        this.selectedMessageGroup = 'ALLGEMEIN';
      }
    }
  }

  getGreeting(): string {
    return (
      'Hallo ' +
      this.anwenderData.anrede +
      ' ' +
      this.anwenderData.vorname +
      ' ' +
      this.anwenderData.nachname +
      ' ich bin der SWP Chatbot. Womit kann ich Ihnen helfen?'
    );
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Daten fuer die Anlage eines Tasks
 */
export interface TaskGenericCreate { 
    /**
     * Der Typ des Tasks
     */
    taskType: TaskGenericCreate.TaskTypeEnum;
    /**
     * Der Ersteller des Tasks
     */
    absenderID: string;
    /**
     * Der Empfaenger des Tasks
     */
    empfaengerID: string;
    /**
     * Faelligkeitsdatum des Tasks (YYYY-MM-DD)
     */
    faelligkeitsDatum?: string;
    /**
     * Projektnummer zum Task
     */
    projektnummer?: string;
    /**
     * Begruendung nur bei AUFTRAGSABBRUCH_PRUEFEN
     */
    begruendung?: string;
    /**
     * Liste von AuftragIDs zum Task
     */
    auftragIDList?: Array<string>;
}
export namespace TaskGenericCreate {
    export type TaskTypeEnum = 'PROJEKTVORSCHLAG_PRUEFEN' | 'PROJEKTZUORDNUNG_PRUEFEN' | 'AUFTRAGSABBRUCH_PRUEFEN' | 'WORKFLOW_REFERENZBILDUNG_STARTEN' | 'REFERENZBILDUNG_SPERREN';
    export const TaskTypeEnum = {
        ProjektvorschlagPruefen: 'PROJEKTVORSCHLAG_PRUEFEN' as TaskTypeEnum,
        ProjektzuordnungPruefen: 'PROJEKTZUORDNUNG_PRUEFEN' as TaskTypeEnum,
        AuftragsabbruchPruefen: 'AUFTRAGSABBRUCH_PRUEFEN' as TaskTypeEnum,
        WorkflowReferenzbildungStarten: 'WORKFLOW_REFERENZBILDUNG_STARTEN' as TaskTypeEnum,
        ReferenzbildungSperren: 'REFERENZBILDUNG_SPERREN' as TaskTypeEnum
    };
}



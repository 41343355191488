import { Injectable } from '@angular/core';
import { LogEintragConverter } from '@core/converters/log-eintrag-converter.service';
import { LogEintragModel } from '@core/models/log-eintrag.model';
import { Observable, of } from 'rxjs';
import { LogEintrag, LoggingService, ResponseObject } from '@core/portal-api';
import { Action, Store } from '@ngrx/store';
import { encode } from 'js-base64';
import * as coreState from '@core/store/reducers';
import * as fromActions from '@core/store/actions/log-eintrag.action';
import FehlerKontextEnum = LogEintrag.FehlerKontextEnum;

@Injectable()
export class LogEintragService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  errorFunctions: { [K: string]: Function } = {
    FATAL: console.error,
    ERROR: console.error,
    WARN: console.warn,
    INFO: console.info,
    DEBUG: console.debug,
    TRACE: console.trace,
  };

  constructor(
    private coreStore: Store<coreState.CoreModuleState>,
    private loggingService: LoggingService,
    private converter: LogEintragConverter
  ) {}

  saveLogEintrag(logEintragModel: LogEintragModel): Observable<ResponseObject> {
    const logEintrag: LogEintrag = this.converter.convertToApiModel(logEintragModel);

    return this.loggingService.postLogEintrag([logEintrag]);
  }

  logFromGlobalError(error: TypeError, action: Action): Observable<any> {
    const globalLogEintrag: LogEintragModel = {
      logLevel: 'ERROR',
      modulID: this.extractModuleFromAction(action),
      fehlerKontext: this.extractFehlerKontextFromAction(action),
      kontextObjektID: action['objektID'] ? action['objektID'] : 'NONE',
      serviceName: action.type,
      fehlerText: error.message,
      fehlerStack: encode(error.stack),
      fehlerID: 'NONE',
    };

    this.logLogEintrag(globalLogEintrag);

    return of(null);
  }

  logLogEintrag(logEintrag: LogEintragModel) {
    this.errorFunctions[logEintrag.logLevel](logEintrag);
    this.coreStore.dispatch(fromActions.saveLogEintrag({ logEintrag }));
  }

  extractModuleFromAction(action: Action): string {
    if (action.type.includes('Gebaeude')) return 'GBD';
    else if (action.type.includes('Investition') || action.type.includes('investition')) return 'RPL';
    else if (action.type.includes('Rahmenplan') || action.type.includes('investition')) return 'RPL';
    return '';
  }

  extractFehlerKontextFromAction(action: Action): FehlerKontextEnum {
    if (action.type.includes('Gebaeude')) return FehlerKontextEnum.Gebaeude;
    else if (action.type.includes('Investition')) return FehlerKontextEnum.Investition;
    else if (action.type.includes('investition list')) return FehlerKontextEnum.Wirtschaftseinheit;
    else if (action.type.includes('choicelists')) return FehlerKontextEnum.Wirtschaftseinheit;
    else if (action.type.includes('Umsatz')) return FehlerKontextEnum.Wirtschaftseinheit;
    return null;
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import { AnwenderDataModel } from '@core/models/anwender-data.model';

import * as fromActions from '@app/core/store/actions/anwender-data.actions';

const initialState: AnwenderDataModel = {
  email: null,
  internExtern: null,
  leitzeichen: null,
  firma: null,
  anrede: null,
  nachname: null,
  vorname: null,
  festnetznummer: null,
  mobiltelefon: null,
  gueltig: null,
  gueltigVon: null,
  gueltigBis: null,
  benutzerName: null,
  funktion: null,
  module: [],
  rollen: [],
};

const andenderDataReducer = createReducer(
  initialState,
  on(fromActions.loadAnwenderDataSuccess, (state, { anwender }) => ({ ...state, ...anwender }))
);

export function reducer(state: AnwenderDataModel | undefined, action: Action) {
  return andenderDataReducer(state, action);
}

export const getAnwenderData = (state: AnwenderDataModel) => state;

import { GebaeudeConverter } from '@app/core/converters/gebaeude-converter.service';
import { GebaeudeanlageConverter } from '@app/core/converters/gebaeudeanlage-converter.service';
import { InvestitionConverter } from '@app/core/converters/investition-converter.service';
import { InvestitionTechnicalConverter } from '@app/core/converters/investition-technical-converter.service';
import { AnalyseNamedSeriesConverterService } from '@core/converters/analyse-named-series-converter.service';
import { AnalyseSeriesConverterService } from '@core/converters/analyse-series-converter.service';
import { AnwenderDataConverter } from '@core/converters/anwender-data-converter.service';
import { AnwenderRolleConverter } from '@core/converters/anwender-rolle-converter.service';
import { CacheInvalidateConverter } from '@core/converters/cache-invalidate-converter.service';
import { ChatbotGruppeConverter } from '@core/converters/chatbot-gruppe-converter.service';
import { DokumentGruppeConverter } from '@core/converters/dokument-gruppe-converter.service';
import { LogEintragConverter } from '@core/converters/log-eintrag-converter.service';
import { ProblemMeldungConverter } from '@core/converters/problem-meldung-converter.service';
import { ProjektAnlegenConverter } from '@core/converters/projekt-anlegen-converter.service';
import { RechtematrixEntryConverter } from '@core/converters/rechtematrix-entry-converter.service';
import { SystemKonfigurationConverter } from '@core/converters/system-konfiguration-converter.service';
import { TasksConverter } from '@core/converters/tasks-converter.service';
import { UserConfigConverter } from '@core/converters/user-config-converter.service';
import { WirtschaftseinheitConverter } from '@core/converters/wirtschaftseinheit-converter.service';
import { BerechtigungAnwenderConverter } from './berechtigung-anwender-converter.service';
import { BerechtigungModulConverter } from './berechtigung-modul-converter.service';
import { FavoritesConverter } from './favorites-converter.service';

export const converters = [
  InvestitionConverter,
  InvestitionTechnicalConverter,
  WirtschaftseinheitConverter,
  GebaeudeConverter,
  UserConfigConverter,
  GebaeudeanlageConverter,
  AnwenderDataConverter,
  AnwenderRolleConverter,
  RechtematrixEntryConverter,
  ProjektAnlegenConverter,
  FavoritesConverter,
  LogEintragConverter,
  ProblemMeldungConverter,
  CacheInvalidateConverter,
  SystemKonfigurationConverter,
  TasksConverter,
  AnalyseSeriesConverterService,
  AnalyseNamedSeriesConverterService,
  DokumentGruppeConverter,
  ChatbotGruppeConverter,
  BerechtigungAnwenderConverter,
  BerechtigungModulConverter,
];

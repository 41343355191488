const C_V_STM_WE = [
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702014,
    WE_SAP_WE_ID_PKF: '7000/702014',
    WE_SAP_WE_BEZEICHNUNG: 'Bernstorffstra?e 147, 22767 Hamburg, 702014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Bernstorffstra?e 147',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559143',
    WE_LÄNGENGRAD_OESTLICH: '9.958349',
    WE_ANZAHL_SCHUELER: 53,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989082,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 5305174,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702022,
    WE_SAP_WE_ID_PKF: '7000/702022',
    WE_SAP_WE_BEZEICHNUNG: 'Carsten-Rehder-Stra?e 34, 22767 Hamburg, 702022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Carsten-Rehder-Stra?e 34',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.545267',
    WE_LÄNGENGRAD_OESTLICH: '9.949090',
    WE_ANZAHL_SCHUELER: 146,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989090,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 5305174,
    WE_EN_2_OBJ_ID_FKT: 2074545,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702075,
    WE_SAP_WE_ID_PKF: '7000/702075',
    WE_SAP_WE_BEZEICHNUNG: 'Struenseestra?e 20-32, 22767 Hamburg, 702075',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Struenseestra?e 20-32',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 198,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.16',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5453335,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 5455937,
    WE_EN_2_OBJ_ID_FKT: 2074545,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702056,
    WE_SAP_WE_ID_PKF: '7000/702056',
    WE_SAP_WE_BEZEICHNUNG: 'Thadenstra?e 147, 22767 Hamburg, 702056',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Thadenstra?e 147',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.557043',
    WE_LÄNGENGRAD_OESTLICH: '9.954849',
    WE_ANZAHL_SCHUELER: 541,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989124,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074548,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702042,
    WE_SAP_WE_ID_PKF: '7000/702042',
    WE_SAP_WE_BEZEICHNUNG: 'Thedestra?e 100, 22767 Hamburg, 702042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Thedestra?e 100',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553833',
    WE_LÄNGENGRAD_OESTLICH: '9.949859',
    WE_ANZAHL_SCHUELER: 546,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989110,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074553,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702009,
    WE_SAP_WE_ID_PKF: '7000/702009',
    WE_SAP_WE_BEZEICHNUNG: 'Arnkielstra?e 2-4, 22769 Hamburg, 702009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Arnkielstra?e 2-4',
    WE_PLZ: '22769',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.563953',
    WE_LÄNGENGRAD_OESTLICH: '9.952949',
    WE_ANZAHL_SCHUELER: 402,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989077,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074547,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702057,
    WE_SAP_WE_ID_PKF: '7000/702057',
    WE_SAP_WE_BEZEICHNUNG: 'Haubachstra?e 55, 22765 Hamburg, 702057',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Haubachstra?e 55',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559373',
    WE_LÄNGENGRAD_OESTLICH: '9.941289',
    WE_ANZAHL_SCHUELER: 488,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989125,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2074562,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702070,
    WE_SAP_WE_ID_PKF: '7000/702070',
    WE_SAP_WE_BEZEICHNUNG: 'Haubachstra?e 60, 22765 Hamburg, 702070',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Haubachstra?e 60',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559285',
    WE_LÄNGENGRAD_OESTLICH: '9.942218',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989136,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 2074549,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702072,
    WE_SAP_WE_ID_PKF: '7000/702072',
    WE_SAP_WE_BEZEICHNUNG: 'Haubachstra?e 62, 22765 Hamburg, 702072',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Haubachstra?e 62',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559366',
    WE_LÄNGENGRAD_OESTLICH: '9.941289',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989137,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702002,
    WE_SAP_WE_ID_PKF: '7000/702002',
    WE_SAP_WE_BEZEICHNUNG: 'Kieler Stra?e 40, 22769 Hamburg, 702002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Kieler Stra?e 40',
    WE_PLZ: '22769',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566463',
    WE_LÄNGENGRAD_OESTLICH: '9.945549',
    WE_ANZAHL_SCHUELER: 1824,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989070,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074551,
    WE_EN_2_OBJ_ID_FKT: 7395374,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702001,
    WE_SAP_WE_ID_PKF: '7000/702001',
    WE_SAP_WE_BEZEICHNUNG: 'Max-Brauer-Allee 83-85, 22765 Hamburg, 702001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Max-Brauer-Allee 83-85',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555023',
    WE_LÄNGENGRAD_OESTLICH: '9.940889',
    WE_ANZAHL_SCHUELER: 1167,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989069,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 2074549,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702078,
    WE_SAP_WE_ID_PKF: '7000/702078',
    WE_SAP_WE_BEZEICHNUNG: 'Recha-Ellern-Weg 1, 22765 Hamburg, 702078',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Recha-Ellern-Weg 1',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5489779,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074551,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702045,
    WE_SAP_WE_ID_PKF: '7000/702045',
    WE_SAP_WE_BEZEICHNUNG: 'Bei der Paul-Gerhardt-Kirche 1-3, 22761 Hamburg, 702045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Bahrenfeld',
    WE_STRASSE_NR: 'Bei der Paul-Gerhardt-Kirche 1-3',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.561424',
    WE_LÄNGENGRAD_OESTLICH: '9.922370',
    WE_ANZAHL_SCHUELER: 878,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989113,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2076314,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702036,
    WE_SAP_WE_ID_PKF: '7000/702036',
    WE_SAP_WE_BEZEICHNUNG: 'Kielkamp 16, 22761 Hamburg, 702036',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Bahrenfeld',
    WE_STRASSE_NR: 'Kielkamp 16',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573290',
    WE_LÄNGENGRAD_OESTLICH: '9.899529',
    WE_ANZAHL_SCHUELER: 175,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989104,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074557,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702047,
    WE_SAP_WE_ID_PKF: '7000/702047',
    WE_SAP_WE_BEZEICHNUNG: 'Mendelssohnstra?e 86, 22761 Hamburg, 702047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Bahrenfeld',
    WE_STRASSE_NR: 'Mendelssohnstra?e 86',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.564573',
    WE_LÄNGENGRAD_OESTLICH: '9.906729',
    WE_ANZAHL_SCHUELER: 342,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989115,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7030261,
    WE_EN_1_OBJ_ID_FKT: 2074558,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702031,
    WE_SAP_WE_ID_PKF: '7000/702031',
    WE_SAP_WE_BEZEICHNUNG: 'Osdorfer Weg 24, 22607 Hamburg, 702031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Bahrenfeld',
    WE_STRASSE_NR: 'Osdorfer Weg 24',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567343',
    WE_LÄNGENGRAD_OESTLICH: '9.893869',
    WE_ANZAHL_SCHUELER: 435,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989099,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2074588,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702010,
    WE_SAP_WE_ID_PKF: '7000/702010',
    WE_SAP_WE_BEZEICHNUNG: 'Regerstra?e 21, 22761 Hamburg, 702010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Bahrenfeld',
    WE_STRASSE_NR: 'Regerstra?e 21',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.568246',
    WE_LÄNGENGRAD_OESTLICH: '9.908299',
    WE_ANZAHL_SCHUELER: 1022,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989078,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074561,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702016,
    WE_SAP_WE_ID_PKF: '7000/702016',
    WE_SAP_WE_BEZEICHNUNG: 'Frahmstra?e 15 a-b, 22587 Hamburg, 702016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Frahmstra?e 15 a-b',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.563963',
    WE_LÄNGENGRAD_OESTLICH: '9.822129',
    WE_ANZAHL_SCHUELER: 1090,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989084,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074585,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702020,
    WE_SAP_WE_ID_PKF: '7000/702020',
    WE_SAP_WE_BEZEICHNUNG: 'Hessepark 5, 22587 Hamburg, 702020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Hessepark 5',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559833',
    WE_LÄNGENGRAD_OESTLICH: '9.808949',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989088,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074575,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702019,
    WE_SAP_WE_ID_PKF: '7000/702019',
    WE_SAP_WE_BEZEICHNUNG: 'Kahlkamp 9, 22587 Hamburg, 702019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Kahlkamp 9',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.560943',
    WE_LÄNGENGRAD_OESTLICH: '9.804939',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989087,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074575,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702030,
    WE_SAP_WE_ID_PKF: '7000/702030',
    WE_SAP_WE_BEZEICHNUNG: 'Karstenstra?e 22, 22587 Hamburg, 702030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Karstenstra?e 22',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.564433',
    WE_LÄNGENGRAD_OESTLICH: '9.801239',
    WE_ANZAHL_SCHUELER: 503,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989098,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074574,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702018,
    WE_SAP_WE_ID_PKF: '7000/702018',
    WE_SAP_WE_BEZEICHNUNG: 'Oesterleystra?e 27, 22587 Hamburg, 702018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Oesterleystra?e 27',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.563014',
    WE_LÄNGENGRAD_OESTLICH: '9.806746',
    WE_ANZAHL_SCHUELER: 859,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989086,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074575,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702043,
    WE_SAP_WE_ID_PKF: '7000/702043',
    WE_SAP_WE_BEZEICHNUNG: 'Willh?den 74, 22587 Hamburg, 702043',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Blankenese',
    WE_STRASSE_NR: 'Willh?den 74',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571523',
    WE_LÄNGENGRAD_OESTLICH: '9.806599',
    WE_ANZAHL_SCHUELER: 954,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989111,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074577,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702062,
    WE_SAP_WE_ID_PKF: '7000/702062',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | R?bbek 4, 22607 Hamburg, 702062',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Gro? Flottbek',
    WE_STRASSE_NR: 'R?bbek 4',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567259',
    WE_LÄNGENGRAD_OESTLICH: '9.877148',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.16',
    WE_WE_OBJ_ID_PKT: 1989130,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5341372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702074,
    WE_SAP_WE_ID_PKF: '7000/702074',
    WE_SAP_WE_BEZEICHNUNG: 'Ohlenkamp 15 a, 22607 Hamburg, 702074',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Gro? Flottbek',
    WE_STRASSE_NR: 'Ohlenkamp 15 a',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567043',
    WE_LÄNGENGRAD_OESTLICH: '9.885639',
    WE_ANZAHL_SCHUELER: 637,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5290579,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074595,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702061,
    WE_SAP_WE_ID_PKF: '7000/702061',
    WE_SAP_WE_BEZEICHNUNG: 'Windm?hlenweg 17, 22607 Hamburg, 702061',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Gro? Flottbek',
    WE_STRASSE_NR: 'Windm?hlenweg 17',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566949',
    WE_LÄNGENGRAD_OESTLICH: '9.870789',
    WE_ANZAHL_SCHUELER: 499,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989129,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 2074594,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702017,
    WE_SAP_WE_ID_PKF: '7000/702017',
    WE_SAP_WE_BEZEICHNUNG: 'Grotefendweg 20, 22589 Hamburg, 702017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Iserbrook',
    WE_STRASSE_NR: 'Grotefendweg 20',
    WE_PLZ: '22589',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567336',
    WE_LÄNGENGRAD_OESTLICH: '9.829170',
    WE_ANZAHL_SCHUELER: 232,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989085,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2074573,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702035,
    WE_SAP_WE_ID_PKF: '7000/702035',
    WE_SAP_WE_BEZEICHNUNG: 'Mus?usstra?e 29, 22589 Hamburg, 702035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Iserbrook',
    WE_STRASSE_NR: 'Mus?usstra?e 29',
    WE_PLZ: '22589',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.580513',
    WE_LÄNGENGRAD_OESTLICH: '9.811039',
    WE_ANZAHL_SCHUELER: 264,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989103,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074580,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702053,
    WE_SAP_WE_ID_PKF: '7000/702053',
    WE_SAP_WE_BEZEICHNUNG: 'Schenefelder Landstra?e 206, 22589 Hamburg, 702053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Iserbrook',
    WE_STRASSE_NR: 'Schenefelder Landstra?e 206',
    WE_PLZ: '22589',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.577439',
    WE_LÄNGENGRAD_OESTLICH: '9.826499',
    WE_ANZAHL_SCHUELER: 295,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989121,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074583,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702015,
    WE_SAP_WE_ID_PKF: '7000/702015',
    WE_SAP_WE_BEZEICHNUNG: 'B?ttcherkamp 144, 22549 Hamburg, 702015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'B?ttcherkamp 144',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.591556',
    WE_LÄNGENGRAD_OESTLICH: '9.861629',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989083,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 5212534,
    WE_EN_2_OBJ_ID_FKT: 2074569,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702021,
    WE_SAP_WE_ID_PKF: '7000/702021',
    WE_SAP_WE_BEZEICHNUNG: 'B?ttcherkamp 146, 22549 Hamburg, 702021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'B?ttcherkamp 146',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.592366',
    WE_LÄNGENGRAD_OESTLICH: '9.858759',
    WE_ANZAHL_SCHUELER: 74,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989089,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 5212534,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702027,
    WE_SAP_WE_ID_PKF: '7000/702027',
    WE_SAP_WE_BEZEICHNUNG: 'B?ttcherkamp 181, 22549 Hamburg, 702027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'B?ttcherkamp 181',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.591223',
    WE_LÄNGENGRAD_OESTLICH: '9.856069',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989095,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074564,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702025,
    WE_SAP_WE_ID_PKF: '7000/702025',
    WE_SAP_WE_BEZEICHNUNG: 'Fahrenort 76, 22547 Hamburg, 702025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Fahrenort 76',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.599693',
    WE_LÄNGENGRAD_OESTLICH: '9.868969',
    WE_ANZAHL_SCHUELER: 503,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989093,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074563,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702077,
    WE_SAP_WE_ID_PKF: '7000/702077',
    WE_SAP_WE_BEZEICHNUNG: 'Flurstra?e 15, 22549 Hamburg, 702077',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Flurstra?e 15',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1120,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5479587,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074572,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702024,
    WE_SAP_WE_ID_PKF: '7000/702024',
    WE_SAP_WE_BEZEICHNUNG: 'Franzosenkoppel 118, 22547 Hamburg, 702024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Franzosenkoppel 118',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.608289',
    WE_LÄNGENGRAD_OESTLICH: '9.871609',
    WE_ANZAHL_SCHUELER: 418,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989092,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074566,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702039,
    WE_SAP_WE_ID_PKF: '7000/702039',
    WE_SAP_WE_BEZEICHNUNG: 'Langbargheide 40, 22547 Hamburg, 702039',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Langbargheide 40',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.596196',
    WE_LÄNGENGRAD_OESTLICH: '9.889899',
    WE_ANZAHL_SCHUELER: 287,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989107,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074571,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702007,
    WE_SAP_WE_ID_PKF: '7000/702007',
    WE_SAP_WE_BEZEICHNUNG: 'Luruper Hauptstra?e  131-133, 22547 Hamburg, 702007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Luruper Hauptstra?e  131-133',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.590576',
    WE_LÄNGENGRAD_OESTLICH: '9.873159',
    WE_ANZAHL_SCHUELER: 314,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989075,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074572,
    WE_EN_2_OBJ_ID_FKT: 2074567,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702028,
    WE_SAP_WE_ID_PKF: '7000/702028',
    WE_SAP_WE_BEZEICHNUNG: 'Rispenweg 28, 22547 Hamburg, 702028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Rispenweg 28',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.597669',
    WE_LÄNGENGRAD_OESTLICH: '9.893199',
    WE_ANZAHL_SCHUELER: 778,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989096,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074565,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702026,
    WE_SAP_WE_ID_PKF: '7000/702026',
    WE_SAP_WE_BEZEICHNUNG: 'Swatten Weg 10, 22547 Hamburg, 702026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Swatten Weg 10',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.599433',
    WE_LÄNGENGRAD_OESTLICH: '9.858655',
    WE_ANZAHL_SCHUELER: 228,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989094,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074563,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702059,
    WE_SAP_WE_ID_PKF: '7000/702059',
    WE_SAP_WE_BEZEICHNUNG: 'Veermoor 4, 22547 Hamburg, 702059',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Veermoor 4',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.606536',
    WE_LÄNGENGRAD_OESTLICH: '9.874759',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989127,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074572,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702008,
    WE_SAP_WE_ID_PKF: '7000/702008',
    WE_SAP_WE_BEZEICHNUNG: 'Vorhornweg 2, 22547 Hamburg, 702008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Lurup',
    WE_STRASSE_NR: 'Vorhornweg 2',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.590496',
    WE_LÄNGENGRAD_OESTLICH: '9.882830',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989076,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2074572,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702050,
    WE_SAP_WE_ID_PKF: '7000/702050',
    WE_SAP_WE_BEZEICHNUNG: 'Quellental 21, 22609 Hamburg, 702050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Nienstedten',
    WE_STRASSE_NR: 'Quellental 21',
    WE_PLZ: '22609',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552353',
    WE_LÄNGENGRAD_OESTLICH: '9.861559',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989118,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074584,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702055,
    WE_SAP_WE_ID_PKF: '7000/702055',
    WE_SAP_WE_BEZEICHNUNG: 'Schulkamp 1-3, 22609 Hamburg, 702055',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Nienstedten',
    WE_STRASSE_NR: 'Schulkamp 1-3',
    WE_PLZ: '22609',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553112',
    WE_LÄNGENGRAD_OESTLICH: '9.847181',
    WE_ANZAHL_SCHUELER: 287,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989123,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074584,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702013,
    WE_SAP_WE_ID_PKF: '7000/702013',
    WE_SAP_WE_BEZEICHNUNG: 'Bornheide 2, 22549 Hamburg, 702013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Bornheide 2',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583990',
    WE_LÄNGENGRAD_OESTLICH: '9.853984',
    WE_ANZAHL_SCHUELER: 324,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989081,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7030261,
    WE_EN_1_OBJ_ID_FKT: 2074568,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702076,
    WE_SAP_WE_ID_PKF: '7000/702076',
    WE_SAP_WE_BEZEICHNUNG: 'Gl?ckst?dter Weg 70, 22549 Hamburg, 702076',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Gl?ckst?dter Weg 70',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 823,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5461810,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074564,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702012,
    WE_SAP_WE_ID_PKF: '7000/702012',
    WE_SAP_WE_BEZEICHNUNG: 'Gl?ckst?dter Weg 75, 22549 Hamburg, 702012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Gl?ckst?dter Weg 75',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.589213',
    WE_LÄNGENGRAD_OESTLICH: '9.853869',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989080,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7030261,
    WE_EN_1_OBJ_ID_FKT: 2074568,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702029,
    WE_SAP_WE_ID_PKF: '7000/702029',
    WE_SAP_WE_BEZEICHNUNG: 'Goosacker 41, 22549 Hamburg, 702029',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Goosacker 41',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.570974',
    WE_LÄNGENGRAD_OESTLICH: '9.849419',
    WE_ANZAHL_SCHUELER: 432,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989097,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074587,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702041,
    WE_SAP_WE_ID_PKF: '7000/702041',
    WE_SAP_WE_BEZEICHNUNG: 'Knabeweg 3, 22549 Hamburg, 702041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Knabeweg 3',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573373',
    WE_LÄNGENGRAD_OESTLICH: '9.856419',
    WE_ANZAHL_SCHUELER: 915,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989109,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074591,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702038,
    WE_SAP_WE_ID_PKF: '7000/702038',
    WE_SAP_WE_BEZEICHNUNG: 'Kroonhorst 25, 22549 Hamburg, 702038',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Kroonhorst 25',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.588413',
    WE_LÄNGENGRAD_OESTLICH: '9.846539',
    WE_ANZAHL_SCHUELER: 273,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989106,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074570,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702060,
    WE_SAP_WE_ID_PKF: '7000/702060',
    WE_SAP_WE_BEZEICHNUNG: 'Wesperloh 19, 22549 Hamburg, 702060',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Wesperloh 19',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575816',
    WE_LÄNGENGRAD_OESTLICH: '9.860789',
    WE_ANZAHL_SCHUELER: 461,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989128,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074593,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702033,
    WE_SAP_WE_ID_PKF: '7000/702033',
    WE_SAP_WE_BEZEICHNUNG: 'Hochrad 2, 22605 Hamburg, 702033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Hochrad 2',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554183',
    WE_LÄNGENGRAD_OESTLICH: '9.876089',
    WE_ANZAHL_SCHUELER: 1121,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989101,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 2074589,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702032,
    WE_SAP_WE_ID_PKF: '7000/702032',
    WE_SAP_WE_BEZEICHNUNG: 'Holmbrook 10, 22605 Hamburg, 702032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Holmbrook 10',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552052',
    WE_LÄNGENGRAD_OESTLICH: '9.900475',
    WE_ANZAHL_SCHUELER: 178,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989100,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074556,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702054,
    WE_SAP_WE_ID_PKF: '7000/702054',
    WE_SAP_WE_BEZEICHNUNG: 'Holmbrook 20, 22605 Hamburg, 702054',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Holmbrook 20',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552646',
    WE_LÄNGENGRAD_OESTLICH: '9.899109',
    WE_ANZAHL_SCHUELER: 292,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989122,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7061458,
    WE_EN_1_OBJ_ID_FKT: 2074531,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702037,
    WE_SAP_WE_ID_PKF: '7000/702037',
    WE_SAP_WE_BEZEICHNUNG: 'Klein Flottbeker Weg 64, 22605 Hamburg, 702037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Klein Flottbeker Weg 64',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553459',
    WE_LÄNGENGRAD_OESTLICH: '9.883469',
    WE_ANZAHL_SCHUELER: 368,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989105,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074592,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702049,
    WE_SAP_WE_ID_PKF: '7000/702049',
    WE_SAP_WE_BEZEICHNUNG: 'Othmarscher Kirchenweg 145, 22763 Hamburg, 702049',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Othmarscher Kirchenweg 145',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552543',
    WE_LÄNGENGRAD_OESTLICH: '9.902689',
    WE_ANZAHL_SCHUELER: 392,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989117,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074552,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702023,
    WE_SAP_WE_ID_PKF: '7000/702023',
    WE_SAP_WE_BEZEICHNUNG: 'Otto-Ernst-Stra?e 34, 22605 Hamburg, 702023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Otto-Ernst-Stra?e 34',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558053',
    WE_LÄNGENGRAD_OESTLICH: '9.874169',
    WE_ANZAHL_SCHUELER: 970,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989091,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800478,
    WE_EN_1_OBJ_ID_FKT: 2074586,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702079,
    WE_SAP_WE_ID_PKF: '7000/702079',
    WE_SAP_WE_BEZEICHNUNG: 'Schwengelkamp 0, 22763 Hamburg geplant, 702079',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Schwengelkamp 0',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6996162,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7030261,
    WE_EN_1_OBJ_ID_FKT: 7361194,
    WE_EN_2_OBJ_ID_FKT: 7361195,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702058,
    WE_SAP_WE_ID_PKF: '7000/702058',
    WE_SAP_WE_BEZEICHNUNG: 'Trenknerweg 136, 22605 Hamburg, 702058',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Trenknerweg 136',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548213',
    WE_LÄNGENGRAD_OESTLICH: '9.910189',
    WE_ANZAHL_SCHUELER: 478,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989126,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074560,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702048,
    WE_SAP_WE_ID_PKF: '7000/702048',
    WE_SAP_WE_BEZEICHNUNG: 'Walderseestra?e 99, 22605 Hamburg, 702048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Othmarschen',
    WE_STRASSE_NR: 'Walderseestra?e 99',
    WE_PLZ: '22605',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554606',
    WE_LÄNGENGRAD_OESTLICH: '9.883369',
    WE_ANZAHL_SCHUELER: 871,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989116,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074590,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702063,
    WE_SAP_WE_ID_PKF: '7000/702063',
    WE_SAP_WE_BEZEICHNUNG: 'Bleickenallee 5, 22763 Hamburg, 702063',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Bleickenallee 5',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.550673',
    WE_LÄNGENGRAD_OESTLICH: '9.921049',
    WE_ANZAHL_SCHUELER: 154,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989131,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074550,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702046,
    WE_SAP_WE_ID_PKF: '7000/702046',
    WE_SAP_WE_BEZEICHNUNG: 'Daimlerstra?e 40, 22763 Hamburg, 702046',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Daimlerstra?e 40',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559019',
    WE_LÄNGENGRAD_OESTLICH: '9.920989',
    WE_ANZAHL_SCHUELER: 728,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989114,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2076314,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702011,
    WE_SAP_WE_ID_PKF: '7000/702011',
    WE_SAP_WE_BEZEICHNUNG: 'Gau?stra?e 171, 22765 Hamburg, 702011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Gau?stra?e 171',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558557',
    WE_LÄNGENGRAD_OESTLICH: '9.926828',
    WE_ANZAHL_SCHUELER: 442,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989079,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 2074554,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702004,
    WE_SAP_WE_ID_PKF: '7000/702004',
    WE_SAP_WE_BEZEICHNUNG: 'Hohenzollernring 57-61, 22763 Hamburg, 702004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Hohenzollernring 57-61',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.551526',
    WE_LÄNGENGRAD_OESTLICH: '9.916009',
    WE_ANZAHL_SCHUELER: 883,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989072,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074550,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702052,
    WE_SAP_WE_ID_PKF: '7000/702052',
    WE_SAP_WE_BEZEICHNUNG: 'Rothestra?e 22, 22765 Hamburg, 702052',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Rothestra?e 22',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548593',
    WE_LÄNGENGRAD_OESTLICH: '9.928889',
    WE_ANZAHL_SCHUELER: 517,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989120,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 2074559,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702034,
    WE_SAP_WE_ID_PKF: '7000/702034',
    WE_SAP_WE_BEZEICHNUNG: 'Iserbarg 2, 22559 Hamburg, 702034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Rissen',
    WE_STRASSE_NR: 'Iserbarg 2',
    WE_PLZ: '22559',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575093',
    WE_LÄNGENGRAD_OESTLICH: '9.761019',
    WE_ANZAHL_SCHUELER: 397,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989102,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7030261,
    WE_EN_1_OBJ_ID_FKT: 2074579,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702040,
    WE_SAP_WE_ID_PKF: '7000/702040',
    WE_SAP_WE_BEZEICHNUNG: 'Lehmkuhlenweg 19-21, 22589 Hamburg, 702040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Rissen',
    WE_STRASSE_NR: 'Lehmkuhlenweg 19-21',
    WE_PLZ: '22589',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584926',
    WE_LÄNGENGRAD_OESTLICH: '9.790917',
    WE_ANZAHL_SCHUELER: 318,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989108,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5177034,
    WE_EN_1_OBJ_ID_FKT: 2074581,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702044,
    WE_SAP_WE_ID_PKF: '7000/702044',
    WE_SAP_WE_BEZEICHNUNG: 'Marschweg 10, 22559 Hamburg, 702044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Rissen',
    WE_STRASSE_NR: 'Marschweg 10',
    WE_PLZ: '22559',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584813',
    WE_LÄNGENGRAD_OESTLICH: '9.761867',
    WE_ANZAHL_SCHUELER: 360,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989112,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7198585,
    WE_EN_1_OBJ_ID_FKT: 2074582,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702051,
    WE_SAP_WE_ID_PKF: '7000/702051',
    WE_SAP_WE_BEZEICHNUNG: 'Vo?hagen 15, 22559 Hamburg, 702051',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Rissen',
    WE_STRASSE_NR: 'Vo?hagen 15',
    WE_PLZ: '22559',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.580019',
    WE_LÄNGENGRAD_OESTLICH: '9.751629',
    WE_ANZAHL_SCHUELER: 1333,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989119,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 7085591,
    WE_EN_1_OBJ_ID_FKT: 2074576,
    WE_EN_2_OBJ_ID_FKT: 5400907,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702003,
    WE_SAP_WE_ID_PKF: '7000/702003',
    WE_SAP_WE_BEZEICHNUNG: 'Altonaer Stra?e 38, 20357 Hamburg, 702003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Sternschanze',
    WE_STRASSE_NR: 'Altonaer Stra?e 38',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.564280',
    WE_LÄNGENGRAD_OESTLICH: '9.964645',
    WE_ANZAHL_SCHUELER: 419,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989071,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074544,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702081,
    WE_SAP_WE_ID_PKF: '7000/702081',
    WE_SAP_WE_BEZEICHNUNG: 'Bahrenfelder Stra?e 260, 22765 Hamburg, 702081',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Bahrenfelder Stra?e 260',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '25.03.20',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7101935,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 5452933,
    WE_EN_1_OBJ_ID_FKT: 7361193,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702006,
    WE_SAP_WE_ID_PKF: '7000/702006',
    WE_SAP_WE_BEZEICHNUNG: 'Ludwigstra?e 7-9, 20357 Hamburg, 702006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Ludwigstra?e 7-9',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559799',
    WE_LÄNGENGRAD_OESTLICH: '9.965997',
    WE_ANZAHL_SCHUELER: 178,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989074,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 1800543,
    WE_EN_1_OBJ_ID_FKT: 2074544,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 702068,
    WE_SAP_WE_ID_PKF: '7000/702068',
    WE_SAP_WE_BEZEICHNUNG: 'Museumstra?e 19, 22765 Hamburg, 702068',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Ottensen',
    WE_STRASSE_NR: 'Museumstra?e 19',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548013',
    WE_LÄNGENGRAD_OESTLICH: '9.934339',
    WE_ANZAHL_SCHUELER: 1823,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989135,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074811,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 702066,
    WE_SAP_WE_ID_PKF: '7000/702066',
    WE_SAP_WE_BEZEICHNUNG: 'Gerritstra?e 9, 22767 Hamburg, 702066',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Gerritstra?e 9',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554167',
    WE_LÄNGENGRAD_OESTLICH: '9.954511',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989133,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074802,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 702064,
    WE_SAP_WE_ID_PKF: '7000/702064',
    WE_SAP_WE_BEZEICHNUNG: 'Max-Brauer-Allee 134-136, 22765 Hamburg, 702064',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Altstadt',
    WE_STRASSE_NR: 'Max-Brauer-Allee 134-136',
    WE_PLZ: '22765',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.556992',
    WE_LÄNGENGRAD_OESTLICH: '9.946696',
    WE_ANZAHL_SCHUELER: 1485,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989132,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074802,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 702067,
    WE_SAP_WE_ID_PKF: '7000/702067',
    WE_SAP_WE_BEZEICHNUNG: 'Ohlenkamp  15 a, 22607 Hamburg, 702067',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Gro? Flottbek',
    WE_STRASSE_NR: 'Ohlenkamp  15 a',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567036',
    WE_LÄNGENGRAD_OESTLICH: '9.885639',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989134,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074825,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708005,
    WE_SAP_WE_ID_PKF: '7000/708005',
    WE_SAP_WE_BEZEICHNUNG: 'Waitzstra?e 31, 22607 Hamburg, 708005',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Gro? Flottbek',
    WE_STRASSE_NR: 'Waitzstra?e 31',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236645,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251915,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708009,
    WE_SAP_WE_ID_PKF: '7000/708009',
    WE_SAP_WE_BEZEICHNUNG: 'Hemmingstedter Weg 142, 22609 Hamburg, 708009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Hemmingstedter Weg 142',
    WE_PLZ: '22609',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236649,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7531677,
    WE_EN_1_OBJ_ID_FKT: 7251904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708022,
    WE_SAP_WE_ID_PKF: '7000/708022',
    WE_SAP_WE_BEZEICHNUNG: 'Wittenbergener Weg 110, 22559 Hamburg, 708022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Rissen',
    WE_STRASSE_NR: 'Wittenbergener Weg 110',
    WE_PLZ: '22559',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236662,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7371755,
    WE_EN_1_OBJ_ID_FKT: 7251917,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706047,
    WE_SAP_WE_ID_PKF: '7000/706047',
    WE_SAP_WE_BEZEICHNUNG: 'Kurf?rstendeich 47, 21037 Hamburg, 706047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Allerm?he',
    WE_STRASSE_NR: 'Kurf?rstendeich 47',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.466902',
    WE_LÄNGENGRAD_OESTLICH: '10.193771',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5395772,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073523,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706004,
    WE_SAP_WE_ID_PKF: '7000/706004',
    WE_SAP_WE_BEZEICHNUNG: 'Kirchenstegel 12, 21039 Hamburg, 706004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Altengamme',
    WE_STRASSE_NR: 'Kirchenstegel 12',
    WE_PLZ: '21039',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.428639',
    WE_LÄNGENGRAD_OESTLICH: '10.270089',
    WE_ANZAHL_SCHUELER: 126,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989380,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073522,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706012,
    WE_SAP_WE_ID_PKF: '7000/706012',
    WE_SAP_WE_BEZEICHNUNG: 'Ernst-Henning-Stra?e 20, 21029 Hamburg, 706012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Ernst-Henning-Stra?e 20',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.484213',
    WE_LÄNGENGRAD_OESTLICH: '10.227069',
    WE_ANZAHL_SCHUELER: 453,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989388,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073539,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706026,
    WE_SAP_WE_ID_PKF: '7000/706026',
    WE_SAP_WE_BEZEICHNUNG: 'Fiddigshagen 11, 21035 Hamburg, 706026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Fiddigshagen 11',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.480329',
    WE_LÄNGENGRAD_OESTLICH: '10.195059',
    WE_ANZAHL_SCHUELER: 480,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989402,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073515,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706014,
    WE_SAP_WE_ID_PKF: '7000/706014',
    WE_SAP_WE_BEZEICHNUNG: 'Friedrich-Frank-Bogen 25, 21033 Hamburg, 706014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Friedrich-Frank-Bogen 25',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.493166',
    WE_LÄNGENGRAD_OESTLICH: '10.179559',
    WE_ANZAHL_SCHUELER: 247,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989390,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073516,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706017,
    WE_SAP_WE_ID_PKF: '7000/706017',
    WE_SAP_WE_BEZEICHNUNG: 'Hermann-Distel-Stra?e 25, 21029 Hamburg, 706017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Hermann-Distel-Stra?e 25',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.494306',
    WE_LÄNGENGRAD_OESTLICH: '10.220689',
    WE_ANZAHL_SCHUELER: 749,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989393,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073520,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706022,
    WE_SAP_WE_ID_PKF: '7000/706022',
    WE_SAP_WE_BEZEICHNUNG: 'Reinbeker Weg 76, 21029 Hamburg, 706022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Reinbeker Weg 76',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.491576',
    WE_LÄNGENGRAD_OESTLICH: '10.227789',
    WE_ANZAHL_SCHUELER: 987,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989398,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073530,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706030,
    WE_SAP_WE_ID_PKF: '7000/706030',
    WE_SAP_WE_BEZEICHNUNG: 'Sander Stra?e 11, 21029 Hamburg, 706030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Sander Stra?e 11',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.495356',
    WE_LÄNGENGRAD_OESTLICH: '10.217449',
    WE_ANZAHL_SCHUELER: 366,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989406,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073532,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706040,
    WE_SAP_WE_ID_PKF: '7000/706040',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Mittlerer Landweg  28, 21033 Hamburg, 706040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Billwerder',
    WE_STRASSE_NR: 'Mittlerer Landweg  28',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.500943',
    WE_LÄNGENGRAD_OESTLICH: '10.136679',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.10',
    WE_GUELTIG_BIS: '15.07.16',
    WE_WE_OBJ_ID_PKT: 1989415,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5455812,
    WE_EN_1_OBJ_ID_FKT: 2073528,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706025,
    WE_SAP_WE_ID_PKF: '7000/706025',
    WE_SAP_WE_BEZEICHNUNG: 'Mittlerer Landweg 48, 21033 Hamburg, 706025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Billwerder',
    WE_STRASSE_NR: 'Mittlerer Landweg 48',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.498496',
    WE_LÄNGENGRAD_OESTLICH: '10.132629',
    WE_ANZAHL_SCHUELER: 117,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989401,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073528,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706011,
    WE_SAP_WE_ID_PKF: '7000/706011',
    WE_SAP_WE_BEZEICHNUNG: 'Gramkowweg 5, 21039 Hamburg, 706011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Curslack',
    WE_STRASSE_NR: 'Gramkowweg 5',
    WE_PLZ: '21039',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.448859',
    WE_LÄNGENGRAD_OESTLICH: '10.219399',
    WE_ANZAHL_SCHUELER: 281,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989387,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073517,
    WE_EN_2_OBJ_ID_FKT: 2073580,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706015,
    WE_SAP_WE_ID_PKF: '7000/706015',
    WE_SAP_WE_BEZEICHNUNG: 'Durchdeich 108, 21037 Hamburg, 706015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Durchdeich 108',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.448707',
    WE_LÄNGENGRAD_OESTLICH: '10.133603',
    WE_ANZAHL_SCHUELER: 186,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989391,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073511,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706013,
    WE_SAP_WE_ID_PKF: '7000/706013',
    WE_SAP_WE_BEZEICHNUNG: 'Heinrich-Osterath-Stra?e 45, 21037 Hamburg AGV, 706013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Heinrich-Osterath-Stra?e 45',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.443973',
    WE_LÄNGENGRAD_OESTLICH: '10.164069',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989389,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073580,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706032,
    WE_SAP_WE_ID_PKF: '7000/706032',
    WE_SAP_WE_BEZEICHNUNG: 'Kirchenheerweg 223, 21037 Hamburg, 706032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Kirchenheerweg 223',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.400910',
    WE_LÄNGENGRAD_OESTLICH: '10.183811',
    WE_ANZAHL_SCHUELER: 223,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989408,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073521,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706046,
    WE_SAP_WE_ID_PKF: '7000/706046',
    WE_SAP_WE_BEZEICHNUNG: 'Kirchenheerweg 85, 21037 Hamburg, 706046',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Kirchenheerweg 85',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.419916',
    WE_LÄNGENGRAD_OESTLICH: '10.201829',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5361605,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073580,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706042,
    WE_SAP_WE_ID_PKF: '7000/706042',
    WE_SAP_WE_BEZEICHNUNG: 'Kirchwerder Hausdeich 341, 21037 Hamburg, 706042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Kirchwerder Hausdeich 341',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.419916',
    WE_LÄNGENGRAD_OESTLICH: '10.201829',
    WE_ANZAHL_SCHUELER: 1177,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989417,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073580,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706044,
    WE_SAP_WE_ID_PKF: '7000/706044',
    WE_SAP_WE_BEZEICHNUNG: 'Sporthallenweg 7, 21037 Hamburg, 706044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Kirchwerder',
    WE_STRASSE_NR: 'Sporthallenweg 7',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.449536',
    WE_LÄNGENGRAD_OESTLICH: '10.135399',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989419,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073511,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706005,
    WE_SAP_WE_ID_PKF: '7000/706005',
    WE_SAP_WE_BEZEICHNUNG: 'An der Twiete 23, 21031 Hamburg, 706005',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'An der Twiete 23',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.502766',
    WE_LÄNGENGRAD_OESTLICH: '10.203239',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989381,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073509,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706008,
    WE_SAP_WE_ID_PKF: '7000/706008',
    WE_SAP_WE_BEZEICHNUNG: 'Billwerder Stra?e 31, 21033 Hamburg, 706008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Billwerder Stra?e 31',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.492036',
    WE_LÄNGENGRAD_OESTLICH: '10.194079',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989384,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073538,
    WE_EN_2_OBJ_ID_FKT: 5305180,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706020,
    WE_SAP_WE_ID_PKF: '7000/706020',
    WE_SAP_WE_BEZEICHNUNG: 'Binnenfeldredder 5, 21031 Hamburg, 706020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Binnenfeldredder 5',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.504533',
    WE_LÄNGENGRAD_OESTLICH: '10.208159',
    WE_ANZAHL_SCHUELER: 1047,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989396,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073509,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706021,
    WE_SAP_WE_ID_PKF: '7000/706021',
    WE_SAP_WE_BEZEICHNUNG: 'Binnenfeldredder 7, 21031 Hamburg, 706021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Binnenfeldredder 7',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.505303',
    WE_LÄNGENGRAD_OESTLICH: '10.206539',
    WE_ANZAHL_SCHUELER: 1210,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989397,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073510,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706018,
    WE_SAP_WE_ID_PKF: '7000/706018',
    WE_SAP_WE_BEZEICHNUNG: 'Heidhorst 16, 21031 Hamburg, 706018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Heidhorst 16',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.516783',
    WE_LÄNGENGRAD_OESTLICH: '10.168929',
    WE_ANZAHL_SCHUELER: 300,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989394,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073518,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706007,
    WE_SAP_WE_ID_PKF: '7000/706007',
    WE_SAP_WE_BEZEICHNUNG: 'Ladenbeker Weg 13, 21033 Hamburg, 706007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Ladenbeker Weg 13',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.494599',
    WE_LÄNGENGRAD_OESTLICH: '10.186659',
    WE_ANZAHL_SCHUELER: 1327,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989383,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073523,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706019,
    WE_SAP_WE_ID_PKF: '7000/706019',
    WE_SAP_WE_BEZEICHNUNG: 'Leuschnerstra?e 13, 21031 Hamburg, 706019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Leuschnerstra?e 13',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.496104',
    WE_LÄNGENGRAD_OESTLICH: '10.206860',
    WE_ANZAHL_SCHUELER: 288,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989395,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073524,
    WE_EN_2_OBJ_ID_FKT: 5305180,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706041,
    WE_SAP_WE_ID_PKF: '7000/706041',
    WE_SAP_WE_BEZEICHNUNG: 'Leuschnerstra?e 84, 21031 Hamburg, 706041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Leuschnerstra?e 84',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.504243',
    WE_LÄNGENGRAD_OESTLICH: '10.205229',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989416,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073509,
    WE_EN_2_OBJ_ID_FKT: 2073510,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706023,
    WE_SAP_WE_ID_PKF: '7000/706023',
    WE_SAP_WE_BEZEICHNUNG: 'Max-Eichholz-Ring 25, 21031 Hamburg, 706023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Max-Eichholz-Ring 25',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.507143',
    WE_LÄNGENGRAD_OESTLICH: '10.217759',
    WE_ANZAHL_SCHUELER: 519,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989399,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073526,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706024,
    WE_SAP_WE_ID_PKF: '7000/706024',
    WE_SAP_WE_BEZEICHNUNG: 'Mendelstra?e 6, 21031 Hamburg, 706024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Mendelstra?e 6',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.506479',
    WE_LÄNGENGRAD_OESTLICH: '10.192649',
    WE_ANZAHL_SCHUELER: 345,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989400,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073527,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706028,
    WE_SAP_WE_ID_PKF: '7000/706028',
    WE_SAP_WE_BEZEICHNUNG: 'Reinbeker Redder 274, 21031 Hamburg, 706028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Reinbeker Redder 274',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.512476',
    WE_LÄNGENGRAD_OESTLICH: '10.205839',
    WE_ANZAHL_SCHUELER: 89,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989404,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 5305180,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706029,
    WE_SAP_WE_ID_PKF: '7000/706029',
    WE_SAP_WE_BEZEICHNUNG: 'Richard-Linde-Weg 49, 21033 Hamburg, 706029',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Richard-Linde-Weg 49',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.500823',
    WE_LÄNGENGRAD_OESTLICH: '10.187679',
    WE_ANZAHL_SCHUELER: 1110,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989405,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5419278,
    WE_EN_1_OBJ_ID_FKT: 2073531,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706009,
    WE_SAP_WE_ID_PKF: '7000/706009',
    WE_SAP_WE_BEZEICHNUNG: 'Schulenburgring 4, 21031 Hamburg, 706009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Schulenburgring 4',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.507333',
    WE_LÄNGENGRAD_OESTLICH: '10.195849',
    WE_ANZAHL_SCHUELER: 718,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989385,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073533,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706031,
    WE_SAP_WE_ID_PKF: '7000/706031',
    WE_SAP_WE_BEZEICHNUNG: 'Weidemoor 1, 21033 Hamburg, 706031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Weidemoor 1',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.512564',
    WE_LÄNGENGRAD_OESTLICH: '10.160037',
    WE_ANZAHL_SCHUELER: 158,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989407,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073541,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706002,
    WE_SAP_WE_ID_PKF: '7000/706002',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Von-Haeften-Stra?e 1, 21035 Hamburg AGV, 706002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Von-Haeften-Stra?e 1',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487448',
    WE_LÄNGENGRAD_OESTLICH: '10.168067',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.05.22',
    WE_WE_OBJ_ID_PKT: 1989378,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2073512,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706006,
    WE_SAP_WE_ID_PKF: '7000/706006',
    WE_SAP_WE_BEZEICHNUNG: 'Ebner-Eschenbach-Weg 43, 21035 Hamburg, 706006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Ebner-Eschenbach-Weg 43',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.482113',
    WE_LÄNGENGRAD_OESTLICH: '10.174199',
    WE_ANZAHL_SCHUELER: 488,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989382,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7061061,
    WE_EN_1_OBJ_ID_FKT: 2073512,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706001,
    WE_SAP_WE_ID_PKF: '7000/706001',
    WE_SAP_WE_BEZEICHNUNG: 'Felix-Jud-Ring  29-33, 21035 Hamburg, 706001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Felix-Jud-Ring  29-33',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.486725',
    WE_LÄNGENGRAD_OESTLICH: '10.147209',
    WE_ANZAHL_SCHUELER: 378,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989377,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073506,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706016,
    WE_SAP_WE_ID_PKF: '7000/706016',
    WE_SAP_WE_BEZEICHNUNG: 'Margit-Zinke-Stra?e 7-11, 21035 Hamburg, 706016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Margit-Zinke-Stra?e 7-11',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487503',
    WE_LÄNGENGRAD_OESTLICH: '10.165889',
    WE_ANZAHL_SCHUELER: 359,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989392,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073525,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706010,
    WE_SAP_WE_ID_PKF: '7000/706010',
    WE_SAP_WE_BEZEICHNUNG: 'Von-Moltke-Bogen 40/44, 21035 Hamburg, 706010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Von-Moltke-Bogen 40/44',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.486458',
    WE_LÄNGENGRAD_OESTLICH: '10.162763',
    WE_ANZAHL_SCHUELER: 706,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989386,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5456938,
    WE_EN_1_OBJ_ID_FKT: 2073525,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706043,
    WE_SAP_WE_ID_PKF: '7000/706043',
    WE_SAP_WE_BEZEICHNUNG: 'Walter-Rothenburg-Weg 37, 21035 Hamburg, 706043',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Walter-Rothenburg-Weg 37',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.485436',
    WE_LÄNGENGRAD_OESTLICH: '10.151969',
    WE_ANZAHL_SCHUELER: 397,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989418,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073534,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706003,
    WE_SAP_WE_ID_PKF: '7000/706003',
    WE_SAP_WE_BEZEICHNUNG: 'Walter-Rothenburg-Weg 41, 21035 Hamburg, 706003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Neuallerm?he',
    WE_STRASSE_NR: 'Walter-Rothenburg-Weg 41',
    WE_PLZ: '21035',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.485838',
    WE_LÄNGENGRAD_OESTLICH: '10.153168',
    WE_ANZAHL_SCHUELER: 666,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989379,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7139260,
    WE_EN_1_OBJ_ID_FKT: 2073535,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706027,
    WE_SAP_WE_ID_PKF: '7000/706027',
    WE_SAP_WE_BEZEICHNUNG: 'Elversweg 44, 21037 Hamburg, 706027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Ochsenwerder',
    WE_STRASSE_NR: 'Elversweg 44',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.471973',
    WE_LÄNGENGRAD_OESTLICH: '10.073309',
    WE_ANZAHL_SCHUELER: 269,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989403,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073513,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706045,
    WE_SAP_WE_ID_PKF: '7000/706045',
    WE_SAP_WE_BEZEICHNUNG: 'Overwerder Bogen 80, 21037 Hamburg, 706045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Ochsenwerder',
    WE_STRASSE_NR: 'Overwerder Bogen 80',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.436773',
    WE_LÄNGENGRAD_OESTLICH: '10.103753',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2033547,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5487027,
    WE_EN_1_OBJ_ID_FKT: 2073580,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706037,
    WE_SAP_WE_ID_PKF: '7000/706037',
    WE_SAP_WE_BEZEICHNUNG: 'Chrysanderstra?e 4, 21029 Hamburg, 706037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Chrysanderstra?e 4',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487403',
    WE_LÄNGENGRAD_OESTLICH: '10.215909',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989413,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074820,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706038,
    WE_SAP_WE_ID_PKF: '7000/706038',
    WE_SAP_WE_BEZEICHNUNG: 'Hassestra?e 13, 21029 Hamburg, 706038',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Hassestra?e 13',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.485003',
    WE_LÄNGENGRAD_OESTLICH: '10.215979',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989414,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074836,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706034,
    WE_SAP_WE_ID_PKF: '7000/706034',
    WE_SAP_WE_BEZEICHNUNG: 'Ladenbeker Furtweg 151, 21033 Hamburg, 706034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Ladenbeker Furtweg 151',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.496049',
    WE_LÄNGENGRAD_OESTLICH: '10.181452',
    WE_ANZAHL_SCHUELER: 1235,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989410,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074814,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706035,
    WE_SAP_WE_ID_PKF: '7000/706035',
    WE_SAP_WE_BEZEICHNUNG: 'Ladenbeker Furtweg 155, 21033 Hamburg, 706035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Ladenbeker Furtweg 155',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.494771',
    WE_LÄNGENGRAD_OESTLICH: '10.182907',
    WE_ANZAHL_SCHUELER: 965,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989411,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074820,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706033,
    WE_SAP_WE_ID_PKF: '7000/706033',
    WE_SAP_WE_BEZEICHNUNG: 'Ladenbeker Furtweg 159, 21033 Hamburg, 706033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Ladenbeker Furtweg 159',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.494377',
    WE_LÄNGENGRAD_OESTLICH: '10.183180',
    WE_ANZAHL_SCHUELER: 1374,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989409,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074819,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 706036,
    WE_SAP_WE_ID_PKF: '7000/706036',
    WE_SAP_WE_BEZEICHNUNG: 'Wentorfer Stra?e 13, 21029 Hamburg, 706036',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Bergedorf',
    WE_STRASSE_NR: 'Wentorfer Stra?e 13',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487419',
    WE_LÄNGENGRAD_OESTLICH: '10.217929',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989412,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074820,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708007,
    WE_SAP_WE_ID_PKF: '7000/708007',
    WE_SAP_WE_BEZEICHNUNG: 'Leuschnerstra?e 21, 21031 Hamburg, 708007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Leuschnerstra?e 21',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236647,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251908,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703011,
    WE_SAP_WE_ID_PKF: '7000/703011',
    WE_SAP_WE_BEZEICHNUNG: 'D?rpsweg 10, 22527 Hamburg, 703011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'D?rpsweg 10',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.608433',
    WE_LÄNGENGRAD_OESTLICH: '9.913569',
    WE_ANZAHL_SCHUELER: 894,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989148,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074630,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703061,
    WE_SAP_WE_ID_PKF: '7000/703061',
    WE_SAP_WE_BEZEICHNUNG: 'Ekenknick 18, 22523 Hamburg, 703061',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Ekenknick 18',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.607366',
    WE_LÄNGENGRAD_OESTLICH: '9.901159',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989196,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074640,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703017,
    WE_SAP_WE_ID_PKF: '7000/703017',
    WE_SAP_WE_BEZEICHNUNG: 'Furtweg 56, 22523 Hamburg, 703017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Furtweg 56',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.604920',
    WE_LÄNGENGRAD_OESTLICH: '9.900317',
    WE_ANZAHL_SCHUELER: 392,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989154,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074635,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703018,
    WE_SAP_WE_ID_PKF: '7000/703018',
    WE_SAP_WE_BEZEICHNUNG: 'Heidacker 13, 22523 Hamburg, 703018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Heidacker 13',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.618918',
    WE_LÄNGENGRAD_OESTLICH: '9.890304',
    WE_ANZAHL_SCHUELER: 405,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989155,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074636,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703013,
    WE_SAP_WE_ID_PKF: '7000/703013',
    WE_SAP_WE_BEZEICHNUNG: 'Lohkampstra?e 145, 22523 Hamburg, 703013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Lohkampstra?e 145',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.613186',
    WE_LÄNGENGRAD_OESTLICH: '9.887819',
    WE_ANZAHL_SCHUELER: 798,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989150,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074640,
    WE_EN_2_OBJ_ID_FKT: 2074629,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703060,
    WE_SAP_WE_ID_PKF: '7000/703060',
    WE_SAP_WE_BEZEICHNUNG: 'Niekampsweg 25, 22523 Hamburg, 703060',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Niekampsweg 25',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.608956',
    WE_LÄNGENGRAD_OESTLICH: '9.896209',
    WE_ANZAHL_SCHUELER: 402,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989195,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074640,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703040,
    WE_SAP_WE_ID_PKF: '7000/703040',
    WE_SAP_WE_BEZEICHNUNG: 'Rungwisch 23, 22523 Hamburg, 703040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Rungwisch 23',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.611343',
    WE_LÄNGENGRAD_OESTLICH: '9.911169',
    WE_ANZAHL_SCHUELER: 307,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989176,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074639,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703053,
    WE_SAP_WE_ID_PKF: '7000/703053',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Gustav-Falke-Stra?e 42, beendet 2_18 Neu 703069, 703053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Gustav-Falke-Stra?e 42',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573136',
    WE_LÄNGENGRAD_OESTLICH: '9.974259',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '28.02.18',
    WE_WE_OBJ_ID_PKT: 1989189,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074597,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703002,
    WE_SAP_WE_ID_PKF: '7000/703002',
    WE_SAP_WE_BEZEICHNUNG: 'Bismarckstra?e  83-85, 20253 Hamburg, 703002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Bismarckstra?e  83-85',
    WE_PLZ: '20253',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575436',
    WE_LÄNGENGRAD_OESTLICH: '9.967839',
    WE_ANZAHL_SCHUELER: 383,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989139,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074603,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703024,
    WE_SAP_WE_ID_PKF: '7000/703024',
    WE_SAP_WE_BEZEICHNUNG: 'Bogenstra?e 36, 20144 Hamburg, 703024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Bogenstra?e 36',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.574267',
    WE_LÄNGENGRAD_OESTLICH: '9.973700',
    WE_ANZAHL_SCHUELER: 920,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989161,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074602,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703020,
    WE_SAP_WE_ID_PKF: '7000/703020',
    WE_SAP_WE_BEZEICHNUNG: 'Bogenstra?e 59, 20253 Hamburg, 703020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Bogenstra?e 59',
    WE_PLZ: '20253',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575436',
    WE_LÄNGENGRAD_OESTLICH: '9.970789',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989157,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2076317,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703014,
    WE_SAP_WE_ID_PKF: '7000/703014',
    WE_SAP_WE_BEZEICHNUNG: 'Bundesstra?e 78, 20144 Hamburg, 703014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Bundesstra?e 78',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571033',
    WE_LÄNGENGRAD_OESTLICH: '9.972009',
    WE_ANZAHL_SCHUELER: 1010,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989151,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074597,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703004,
    WE_SAP_WE_ID_PKF: '7000/703004',
    WE_SAP_WE_BEZEICHNUNG: 'Bundesstra?e 94, 20144 Hamburg, 703004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Bundesstra?e 94',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572536',
    WE_LÄNGENGRAD_OESTLICH: '9.968449',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989141,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074603,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703012,
    WE_SAP_WE_ID_PKF: '7000/703012',
    WE_SAP_WE_BEZEICHNUNG: 'Eduardstra?e 28, 20257 Hamburg, 703012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Eduardstra?e 28',
    WE_PLZ: '20257',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571973',
    WE_LÄNGENGRAD_OESTLICH: '9.947109',
    WE_ANZAHL_SCHUELER: 212,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989149,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074610,
    WE_EN_2_OBJ_ID_FKT: 5305175,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703015,
    WE_SAP_WE_ID_PKF: '7000/703015',
    WE_SAP_WE_BEZEICHNUNG: 'Gustav-Falke-Stra?e 21, 20144 Hamburg, 703015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Gustav-Falke-Stra?e 21',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573256',
    WE_LÄNGENGRAD_OESTLICH: '9.973559',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989152,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074597,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703069,
    WE_SAP_WE_ID_PKF: '7000/703069',
    WE_SAP_WE_BEZEICHNUNG: 'Gustav-Falke-Stra?e 42, 20144 Hamburg, 703069',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Gustav-Falke-Stra?e 42',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6830384,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074604,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703027,
    WE_SAP_WE_ID_PKF: '7000/703027',
    WE_SAP_WE_BEZEICHNUNG: 'Kaiser-Friedrich-Ufer 6, 20259 Hamburg, 703027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Kaiser-Friedrich-Ufer 6',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573463',
    WE_LÄNGENGRAD_OESTLICH: '9.966419',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989164,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074600,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703028,
    WE_SAP_WE_ID_PKF: '7000/703028',
    WE_SAP_WE_BEZEICHNUNG: 'Kielortallee 18-20, 20144 Hamburg, 703028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Kielortallee 18-20',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572183',
    WE_LÄNGENGRAD_OESTLICH: '9.973900',
    WE_ANZAHL_SCHUELER: 437,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989165,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074604,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703029,
    WE_SAP_WE_ID_PKF: '7000/703029',
    WE_SAP_WE_BEZEICHNUNG: 'Lutterothstra?e 34-36, 20255 Hamburg, 703029',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Lutterothstra?e 34-36',
    WE_PLZ: '20255',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.581026',
    WE_LÄNGENGRAD_OESTLICH: '9.953229',
    WE_ANZAHL_SCHUELER: 389,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989166,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074611,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703037,
    WE_SAP_WE_ID_PKF: '7000/703037',
    WE_SAP_WE_BEZEICHNUNG: 'Rellinger Stra?e  13-15, 20257 Hamburg, 703037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Rellinger Stra?e  13-15',
    WE_PLZ: '20257',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.574140',
    WE_LÄNGENGRAD_OESTLICH: '9.942282',
    WE_ANZAHL_SCHUELER: 376,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989173,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074613,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703070,
    WE_SAP_WE_ID_PKF: '7000/703070',
    WE_SAP_WE_BEZEICHNUNG: 'Schwenckestra?e 91, 20259 Hamburg, 703070',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Schwenckestra?e 91',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 219,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.08.18',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6950694,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2076326,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703071,
    WE_SAP_WE_ID_PKF: '7000/703071',
    WE_SAP_WE_BEZEICHNUNG: 'Telemannstra?e 10, 20255 Hamburg, 703071',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Telemannstra?e 10',
    WE_PLZ: '20255',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.08.18',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6960568,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703043,
    WE_SAP_WE_ID_PKF: '7000/703043',
    WE_SAP_WE_BEZEICHNUNG: 'Tornquiststra?e 60, 20259 Hamburg, 703043',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Tornquiststra?e 60',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573623',
    WE_LÄNGENGRAD_OESTLICH: '9.953499',
    WE_ANZAHL_SCHUELER: 348,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989179,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074605,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703019,
    WE_SAP_WE_ID_PKF: '7000/703019',
    WE_SAP_WE_BEZEICHNUNG: 'Bogenstra?e 32, 20144 Hamburg, 703019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Bogenstra?e 32',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573576',
    WE_LÄNGENGRAD_OESTLICH: '9.974799',
    WE_ANZAHL_SCHUELER: 960,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989156,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074601,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703072,
    WE_SAP_WE_ID_PKF: '7000/703072',
    WE_SAP_WE_BEZEICHNUNG: 'Isestra?e  144-146, 20149 Hamburg, 703072',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Isestra?e  144-146',
    WE_PLZ: '20149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583691',
    WE_LÄNGENGRAD_OESTLICH: '9.992277',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.05.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7238604,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 7039675,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703047,
    WE_SAP_WE_ID_PKF: '7000/703047',
    WE_SAP_WE_BEZEICHNUNG: 'Klosterstieg 17, 20149 Hamburg, 703047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Klosterstieg 17',
    WE_PLZ: '20149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.574076',
    WE_LÄNGENGRAD_OESTLICH: '9.996719',
    WE_ANZAHL_SCHUELER: 707,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989183,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074607,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703025,
    WE_SAP_WE_ID_PKF: '7000/703025',
    WE_SAP_WE_BEZEICHNUNG: 'Lehmweg 14-15, 20251 Hamburg, 703025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Hoheluft-Ost',
    WE_STRASSE_NR: 'Lehmweg 14-15',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.580001',
    WE_LÄNGENGRAD_OESTLICH: '9.977465',
    WE_ANZAHL_SCHUELER: 273,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989162,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074602,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703023,
    WE_SAP_WE_ID_PKF: '7000/703023',
    WE_SAP_WE_BEZEICHNUNG: 'Christian-F?rster-Stra?e 21, 20253 Hamburg, 703023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Hoheluft-West',
    WE_STRASSE_NR: 'Christian-F?rster-Stra?e 21',
    WE_PLZ: '20253',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583032',
    WE_LÄNGENGRAD_OESTLICH: '9.963542',
    WE_ANZAHL_SCHUELER: 722,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989160,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074599,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703022,
    WE_SAP_WE_ID_PKF: '7000/703022',
    WE_SAP_WE_BEZEICHNUNG: 'Wrangelstra?e 80, 20253 Hamburg, 703022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Hoheluft-West',
    WE_STRASSE_NR: 'Wrangelstra?e 80',
    WE_PLZ: '20253',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.581566',
    WE_LÄNGENGRAD_OESTLICH: '9.967199',
    WE_ANZAHL_SCHUELER: 330,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989159,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074598,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703009,
    WE_SAP_WE_ID_PKF: '7000/703009',
    WE_SAP_WE_BEZEICHNUNG: 'Corveystra?e 6, 22529 Hamburg, 703009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Lokstedt',
    WE_STRASSE_NR: 'Corveystra?e 6',
    WE_PLZ: '22529',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.596836',
    WE_LÄNGENGRAD_OESTLICH: '9.969219',
    WE_ANZAHL_SCHUELER: 1074,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989146,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074618,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703010,
    WE_SAP_WE_ID_PKF: '7000/703010',
    WE_SAP_WE_BEZEICHNUNG: 'D?hrnstra?e 42, 22529 Hamburg, 703010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Lokstedt',
    WE_STRASSE_NR: 'D?hrnstra?e 42',
    WE_PLZ: '22529',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.595713',
    WE_LÄNGENGRAD_OESTLICH: '9.954029',
    WE_ANZAHL_SCHUELER: 493,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989147,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074622,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703021,
    WE_SAP_WE_ID_PKF: '7000/703021',
    WE_SAP_WE_BEZEICHNUNG: 'Hinter der Lieth 61, 22529 Hamburg, 703021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Lokstedt',
    WE_STRASSE_NR: 'Hinter der Lieth 61',
    WE_PLZ: '22529',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.603063',
    WE_LÄNGENGRAD_OESTLICH: '9.945449',
    WE_ANZAHL_SCHUELER: 410,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989158,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074623,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703045,
    WE_SAP_WE_ID_PKF: '7000/703045',
    WE_SAP_WE_BEZEICHNUNG: 'Vizelinstra?e 50, 22529 Hamburg, 703045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Lokstedt',
    WE_STRASSE_NR: 'Vizelinstra?e 50',
    WE_PLZ: '22529',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.588953',
    WE_LÄNGENGRAD_OESTLICH: '9.951499',
    WE_ANZAHL_SCHUELER: 415,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989181,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074627,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703005,
    WE_SAP_WE_ID_PKF: '7000/703005',
    WE_SAP_WE_BEZEICHNUNG: 'Bindfeldweg 37, 22459 Hamburg, 703005',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Bindfeldweg 37',
    WE_PLZ: '22459',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.623389',
    WE_LÄNGENGRAD_OESTLICH: '9.946219',
    WE_ANZAHL_SCHUELER: 488,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989142,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074616,
    WE_EN_2_OBJ_ID_FKT: 5305175,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703006,
    WE_SAP_WE_ID_PKF: '7000/703006',
    WE_SAP_WE_BEZEICHNUNG: 'Bondenwald 14 b, 22453 Hamburg, 703006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Bondenwald 14 b',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.622403',
    WE_LÄNGENGRAD_OESTLICH: '9.938579',
    WE_ANZAHL_SCHUELER: 1000,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989143,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 2074617,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703008,
    WE_SAP_WE_ID_PKF: '7000/703008',
    WE_SAP_WE_BEZEICHNUNG: 'Burgunderweg 2, 22453 Hamburg, 703008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Burgunderweg 2',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.628293',
    WE_LÄNGENGRAD_OESTLICH: '9.962339',
    WE_ANZAHL_SCHUELER: 414,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989145,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074621,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703033,
    WE_SAP_WE_ID_PKF: '7000/703033',
    WE_SAP_WE_BEZEICHNUNG: 'Paul-Sorge-Stra?e 133-135, 22455 Hamburg, 703033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Paul-Sorge-Stra?e 133-135',
    WE_PLZ: '22455',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.633661',
    WE_LÄNGENGRAD_OESTLICH: '9.951994',
    WE_ANZAHL_SCHUELER: 1041,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989170,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074628,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703034,
    WE_SAP_WE_ID_PKF: '7000/703034',
    WE_SAP_WE_BEZEICHNUNG: 'Sachsenweg 74-76, 22455 Hamburg, 703034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Sachsenweg 74-76',
    WE_PLZ: '22455',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.641141',
    WE_LÄNGENGRAD_OESTLICH: '9.960227',
    WE_ANZAHL_SCHUELER: 1776,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989171,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074619,
    WE_EN_2_OBJ_ID_FKT: 2074628,
    WE_EN_3_OBJ_ID_FKT: '2074625',
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703035,
    WE_SAP_WE_ID_PKF: '7000/703035',
    WE_SAP_WE_BEZEICHNUNG: 'Sethweg 56, 22455 Hamburg, 703035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Sethweg 56',
    WE_PLZ: '22455',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.633520',
    WE_LÄNGENGRAD_OESTLICH: '9.942949',
    WE_ANZAHL_SCHUELER: 419,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989172,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800466,
    WE_EN_1_OBJ_ID_FKT: 2074626,
    WE_EN_2_OBJ_ID_FKT: 2074628,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703032,
    WE_SAP_WE_ID_PKF: '7000/703032',
    WE_SAP_WE_BEZEICHNUNG: 'Wagrierweg 18, 22455 Hamburg, 703032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Wagrierweg 18',
    WE_PLZ: '22455',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.641594',
    WE_LÄNGENGRAD_OESTLICH: '9.944399',
    WE_ANZAHL_SCHUELER: 279,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989169,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074624,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703067,
    WE_SAP_WE_ID_PKF: '7000/703067',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Mittelweg 42 a, beendet 7_21, 703067',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Mittelweg 42 a',
    WE_PLZ: '20148',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.07.21',
    WE_WE_OBJ_ID_PKT: 5503984,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2076318,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703076,
    WE_SAP_WE_ID_PKF: '7000/703076',
    WE_SAP_WE_BEZEICHNUNG: 'Bundesstra?e 41, 20146 Hamburg, 703076',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Bundesstra?e 41',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566576',
    WE_LÄNGENGRAD_OESTLICH: '9.978269',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.04.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7234836,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 7223267,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703073,
    WE_SAP_WE_ID_PKF: '7000/703073',
    WE_SAP_WE_BEZEICHNUNG: 'Bundesstra?e 58, 20146 Hamburg, 703073',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Bundesstra?e 58',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.569190',
    WE_LÄNGENGRAD_OESTLICH: '9.974589',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.20',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7083731,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 7223267,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703044,
    WE_SAP_WE_ID_PKF: '7000/703044',
    WE_SAP_WE_BEZEICHNUNG: 'Turmweg 33, 20148 Hamburg, 703044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Turmweg 33',
    WE_PLZ: '20148',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571216',
    WE_LÄNGENGRAD_OESTLICH: '9.992539',
    WE_ANZAHL_SCHUELER: 581,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989180,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2076318,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703003,
    WE_SAP_WE_ID_PKF: '7000/703003',
    WE_SAP_WE_BEZEICHNUNG: 'Anna-Susanna-Stieg 3, 22457 Hamburg, 703003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Schnelsen',
    WE_STRASSE_NR: 'Anna-Susanna-Stieg 3',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.642346',
    WE_LÄNGENGRAD_OESTLICH: '9.918889',
    WE_ANZAHL_SCHUELER: 540,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989140,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074633,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703016,
    WE_SAP_WE_ID_PKF: '7000/703016',
    WE_SAP_WE_BEZEICHNUNG: 'Frohmestra?e 42, 22457 Hamburg, 703016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Schnelsen',
    WE_STRASSE_NR: 'Frohmestra?e 42',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.632463',
    WE_LÄNGENGRAD_OESTLICH: '9.919679',
    WE_ANZAHL_SCHUELER: 410,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989153,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074634,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703026,
    WE_SAP_WE_ID_PKF: '7000/703026',
    WE_SAP_WE_BEZEICHNUNG: 'Halstenbeker Stra?e 41, 22457 Hamburg, 703026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Schnelsen',
    WE_STRASSE_NR: 'Halstenbeker Stra?e 41',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.624849',
    WE_LÄNGENGRAD_OESTLICH: '9.903639',
    WE_ANZAHL_SCHUELER: 1666,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989163,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074631,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703038,
    WE_SAP_WE_ID_PKF: '7000/703038',
    WE_SAP_WE_BEZEICHNUNG: 'R?nnkamp 3-5, 22457 Hamburg, 703038',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Schnelsen',
    WE_STRASSE_NR: 'R?nnkamp 3-5',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.646796',
    WE_LÄNGENGRAD_OESTLICH: '9.919249',
    WE_ANZAHL_SCHUELER: 229,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989174,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074637,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703039,
    WE_SAP_WE_ID_PKF: '7000/703039',
    WE_SAP_WE_BEZEICHNUNG: 'R?thmoorweg 9, 22459 Hamburg, 703039',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Schnelsen',
    WE_STRASSE_NR: 'R?thmoorweg 9',
    WE_PLZ: '22459',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.627269',
    WE_LÄNGENGRAD_OESTLICH: '9.923408',
    WE_ANZAHL_SCHUELER: 225,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989175,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074638,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703041,
    WE_SAP_WE_ID_PKF: '7000/703041',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Sportplatzring 73, 22527 Hamburg, 703041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Sportplatzring 73',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.594220',
    WE_LÄNGENGRAD_OESTLICH: '9.930419',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '30.08.22',
    WE_WE_OBJ_ID_PKT: 1989177,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800508,
    WE_EN_1_OBJ_ID_FKT: 2074615,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703042,
    WE_SAP_WE_ID_PKF: '7000/703042',
    WE_SAP_WE_BEZEICHNUNG: 'Brehmweg 60, 22527 Hamburg, 703042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Brehmweg 60',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584406',
    WE_LÄNGENGRAD_OESTLICH: '9.940719',
    WE_ANZAHL_SCHUELER: 1241,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989178,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074615,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703007,
    WE_SAP_WE_ID_PKF: '7000/703007',
    WE_SAP_WE_BEZEICHNUNG: 'Brehmweg 62, 22527 Hamburg, 703007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Brehmweg 62',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.586003',
    WE_LÄNGENGRAD_OESTLICH: '9.941822',
    WE_ANZAHL_SCHUELER: 251,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989144,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7020907,
    WE_EN_1_OBJ_ID_FKT: 2074609,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703031,
    WE_SAP_WE_ID_PKF: '7000/703031',
    WE_SAP_WE_BEZEICHNUNG: 'Molkenbuhrstra?e 2, 22525 Hamburg, 703031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Molkenbuhrstra?e 2',
    WE_PLZ: '22525',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.589283',
    WE_LÄNGENGRAD_OESTLICH: '9.925709',
    WE_ANZAHL_SCHUELER: 304,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989168,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800360,
    WE_EN_1_OBJ_ID_FKT: 2074612,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703001,
    WE_SAP_WE_ID_PKF: '7000/703001',
    WE_SAP_WE_BEZEICHNUNG: 'Wegenkamp 3, 22527 Hamburg, 703001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Wegenkamp 3',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600613',
    WE_LÄNGENGRAD_OESTLICH: '9.925149',
    WE_ANZAHL_SCHUELER: 943,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989138,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074608,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703046,
    WE_SAP_WE_ID_PKF: '7000/703046',
    WE_SAP_WE_BEZEICHNUNG: 'Wegenkamp 9, 22527 Hamburg, 703046',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Wegenkamp 9',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.602836',
    WE_LÄNGENGRAD_OESTLICH: '9.924019',
    WE_ANZAHL_SCHUELER: 269,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989182,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 7035707,
    WE_EN_1_OBJ_ID_FKT: 2074614,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703030,
    WE_SAP_WE_ID_PKF: '7000/703030',
    WE_SAP_WE_BEZEICHNUNG: 'Baumacker 10, 22523 Hamburg, 703030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Baumacker 10',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.616276',
    WE_LÄNGENGRAD_OESTLICH: '9.898509',
    WE_ANZAHL_SCHUELER: 293,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989167,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2055931,
    WE_EN_1_OBJ_ID_FKT: 2074632,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703064,
    WE_SAP_WE_ID_PKF: '7000/703064',
    WE_SAP_WE_BEZEICHNUNG: 'Niekampsweg 25 b , 22523 Hamburg, 703064',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eidelstedt',
    WE_STRASSE_NR: 'Niekampsweg 25 b ',
    WE_PLZ: '22523',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.608956',
    WE_LÄNGENGRAD_OESTLICH: '9.896219',
    WE_ANZAHL_SCHUELER: 782,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2862423,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074813,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703055,
    WE_SAP_WE_ID_PKF: '7000/703055',
    WE_SAP_WE_BEZEICHNUNG: 'Lutterothstra?e 78-80, 20255 Hamburg, 703055',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Lutterothstra?e 78-80',
    WE_PLZ: '20255',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.581783',
    WE_LÄNGENGRAD_OESTLICH: '9.946619',
    WE_ANZAHL_SCHUELER: 360,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989191,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074823,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703054,
    WE_SAP_WE_ID_PKF: '7000/703054',
    WE_SAP_WE_BEZEICHNUNG: 'Schlankreye 1, 20144 Hamburg, 703054',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Schlankreye 1',
    WE_PLZ: '20144',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572433',
    WE_LÄNGENGRAD_OESTLICH: '9.969969',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989190,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074823,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703058,
    WE_SAP_WE_ID_PKF: '7000/703058',
    WE_SAP_WE_BEZEICHNUNG: 'Schwenckestra?e 91-93, 20259 Hamburg, 703058',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Schwenckestra?e 91-93',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.579643',
    WE_LÄNGENGRAD_OESTLICH: '9.950519',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989193,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074826,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703050,
    WE_SAP_WE_ID_PKF: '7000/703050',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Isestra?e  144-146, 20149 Hamburg Neue WE 703072, 703050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Isestra?e  144-146',
    WE_PLZ: '20149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583691',
    WE_LÄNGENGRAD_OESTLICH: '9.992277',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 1989186,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074500,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703048,
    WE_SAP_WE_ID_PKF: '7000/703048',
    WE_SAP_WE_BEZEICHNUNG: 'Niendorfer Marktplatz  7, 22459 Hamburg, 703048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Niendorfer Marktplatz  7',
    WE_PLZ: '22459',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.617721',
    WE_LÄNGENGRAD_OESTLICH: '9.948847',
    WE_ANZAHL_SCHUELER: 999,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989184,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074842,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703052,
    WE_SAP_WE_ID_PKF: '7000/703052',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Bundesstra?e 41, 20146 Hamburg beendet 7_21, 703052',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Bundesstra?e 41',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566576',
    WE_LÄNGENGRAD_OESTLICH: '9.978269',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '20.07.21',
    WE_WE_OBJ_ID_PKT: 1989188,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 7223267,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703051,
    WE_SAP_WE_ID_PKF: '7000/703051',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Bundesstra?e 58, 20146 Hamburg, 703051',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Bundesstra?e 58',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.569190',
    WE_LÄNGENGRAD_OESTLICH: '9.974589',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.19',
    WE_WE_OBJ_ID_PKT: 1989187,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 6842810,
    WE_EN_1_OBJ_ID_FKT: 2074804,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703057,
    WE_SAP_WE_ID_PKF: '7000/703057',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Telemannstra?e 10, 20255 Hamburg 31.07.18 beendet, 703057',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Telemannstra?e 10',
    WE_PLZ: '20255',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.578023',
    WE_LÄNGENGRAD_OESTLICH: '9.952499',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.07.18',
    WE_WE_OBJ_ID_PKT: 1989192,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 1800433,
    WE_EN_1_OBJ_ID_FKT: 2074826,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703059,
    WE_SAP_WE_ID_PKF: '7000/703059',
    WE_SAP_WE_BEZEICHNUNG: 'Reichsbahnstra?e 53, 22525 Hamburg, 703059',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Reichsbahnstra?e 53',
    WE_PLZ: '22525',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600403',
    WE_LÄNGENGRAD_OESTLICH: '9.906419',
    WE_ANZAHL_SCHUELER: 1436,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989194,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074813,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708011,
    WE_SAP_WE_ID_PKF: '7000/708011',
    WE_SAP_WE_BEZEICHNUNG: 'Felix-Dahn-Str.3, 20357 Hamburg, 708011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Felix-Dahn-Str.3',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236651,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251901,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708014,
    WE_SAP_WE_ID_PKF: '7000/708014',
    WE_SAP_WE_BEZEICHNUNG: 'Moorkamp 3, 20357 Hamburg, 708014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Moorkamp 3',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236654,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251910,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708015,
    WE_SAP_WE_ID_PKF: '7000/708015',
    WE_SAP_WE_BEZEICHNUNG: 'Moorkamp 7-9, 20357 Hamburg, 708015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Moorkamp 7-9',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236655,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251911,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708010,
    WE_SAP_WE_ID_PKF: '7000/708010',
    WE_SAP_WE_BEZEICHNUNG: 'Weidenstieg 29, 20259 Hamburg, 708010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Eimsb?ttel',
    WE_STRASSE_NR: 'Weidenstieg 29',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236650,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251916,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708013,
    WE_SAP_WE_ID_PKF: '7000/708013',
    WE_SAP_WE_BEZEICHNUNG: 'Hohe Weide 12-14, 20259 Hamburg, 708013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Hohe Weide 12-14',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236653,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251905,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708012,
    WE_SAP_WE_ID_PKF: '7000/708012',
    WE_SAP_WE_BEZEICHNUNG: 'Hohe Weide 16, 20259 Hamburg, 708012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Harvestehude',
    WE_STRASSE_NR: 'Hohe Weide 16',
    WE_PLZ: '20259',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236652,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251906,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708002,
    WE_SAP_WE_ID_PKF: '7000/708002',
    WE_SAP_WE_BEZEICHNUNG: 'Hartsprung 23, 22529 Hamburg, 708002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Lokstedt',
    WE_STRASSE_NR: 'Hartsprung 23',
    WE_PLZ: '22529',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 395,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.08.20',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7148617,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7531677,
    WE_EN_1_OBJ_ID_FKT: 7257751,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708023,
    WE_SAP_WE_ID_PKF: '7000/708023',
    WE_SAP_WE_BEZEICHNUNG: 'Mittelweg 42 a, 20148 Hamburg, 708023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Mittelweg 42 a',
    WE_PLZ: '20148',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7267087,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251909,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708017,
    WE_SAP_WE_ID_PKF: '7000/708017',
    WE_SAP_WE_BEZEICHNUNG: 'Mittelweg 42, 20148 Hamburg, 708017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Rotherbaum',
    WE_STRASSE_NR: 'Mittelweg 42',
    WE_PLZ: '20148',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236657,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251909,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 703062,
    WE_SAP_WE_ID_PKF: '7000/703062',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Mittelweg 42, 20148 Hamburg, 703062',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wirtschaftseinheiten beendet',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Mittelweg 42',
    WE_PLZ: '20148',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571283',
    WE_LÄNGENGRAD_OESTLICH: '9.994069',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.08.16',
    WE_WE_OBJ_ID_PKT: 1989197,
    WE_RGN_OBJ_ID_FKT: 5490070,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074834,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 708001,
    WE_SAP_WE_ID_PKF: '7000/708001',
    WE_SAP_WE_BEZEICHNUNG: 'Winterhuder Weg 11, 22085 Hamburg, 708001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Hamburg-Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'Winterhuder Weg 11',
    WE_PLZ: '22085',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5913911,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 5305176,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 707024,
    WE_SAP_WE_ID_PKF: '7000/707024',
    WE_SAP_WE_BEZEICHNUNG: 'G?hlbachtal 38, 21073 Hamburg, 707024',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'G?hlbachtal 38',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.454436',
    WE_LÄNGENGRAD_OESTLICH: '9.964319',
    WE_ANZAHL_SCHUELER: 1404,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989442,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074829,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 707066,
    WE_SAP_WE_ID_PKF: '7000/707066',
    WE_SAP_WE_BEZEICHNUNG: 'Woellmerstra?e 1, 21075 Hamburg, 707066',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Woellmerstra?e 1',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.465308',
    WE_LÄNGENGRAD_OESTLICH: '9.959457',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989480,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074844,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708020,
    WE_SAP_WE_ID_PKF: '7000/708020',
    WE_SAP_WE_BEZEICHNUNG: 'Fischbeker Heideweg 44, 21149 Hamburg, 708020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben',
    WE_STRASSE_NR: 'Fischbeker Heideweg 44',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236660,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7371755,
    WE_EN_1_OBJ_ID_FKT: 7251902,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7040,
    WE_SAP_WE_NUMMER: 707065,
    WE_SAP_WE_ID_PKF: '7000/707065',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Lange Striepen 7, 21147 Hamburg, 707065',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Lange Striepen 7',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.475483',
    WE_LÄNGENGRAD_OESTLICH: '9.886319',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.12',
    WE_WE_OBJ_ID_PKT: 1989479,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702080,
    WE_SAP_WE_ID_PKF: '7000/702080',
    WE_SAP_WE_BEZEICHNUNG: 'Wohlwillstra?e 46, 20359 Hamburg, 702080',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Wohlwillstra?e 46',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555390',
    WE_LÄNGENGRAD_OESTLICH: '9.962795',
    WE_ANZAHL_SCHUELER: 419,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.04.19',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7020098,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 5455937,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701038,
    WE_SAP_WE_ID_PKF: '7000/701038',
    WE_SAP_WE_BEZEICHNUNG: 'Oststeinbeker Weg 29, 22117 Hamburg, 701038',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Fremdbestand',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Oststeinbeker Weg 29',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.538136',
    WE_LÄNGENGRAD_OESTLICH: '10.133359',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989032,
    WE_RGN_OBJ_ID_FKT: 5446962,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5341372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701046,
    WE_SAP_WE_ID_PKF: '7000/701046',
    WE_SAP_WE_BEZEICHNUNG: 'Steinbeker Marktstra?e 8-10, 22117 Hamburg, 701046',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Fremdbestand',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Steinbeker Marktstra?e 8-10',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.531321',
    WE_LÄNGENGRAD_OESTLICH: '10.127813',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989040,
    WE_RGN_OBJ_ID_FKT: 5446962,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 5341372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701025,
    WE_SAP_WE_ID_PKF: '7000/701025',
    WE_SAP_WE_BEZEICHNUNG: 'Am Dalmannkai 18, 20457 Hamburg, 701025',
    WE_FM_DIENSTLEISTER: 'Otto Wulff',
    WE_REGION: 'Fremdbestand',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'HafenCity',
    WE_STRASSE_NR: 'Am Dalmannkai 18',
    WE_PLZ: '20457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.541762',
    WE_LÄNGENGRAD_OESTLICH: '9.994510',
    WE_ANZAHL_SCHUELER: 343,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989020,
    WE_RGN_OBJ_ID_FKT: 5446962,
    WE_OM_OBJ_ID_FKT: 6766014,
    WE_EN_1_OBJ_ID_FKT: 2074501,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701056,
    WE_SAP_WE_ID_PKF: '7000/701056',
    WE_SAP_WE_BEZEICHNUNG: 'Anckelmannstra?e  10, 701056',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Borgfelde',
    WE_STRASSE_NR: 'Anckelmannstra?e  10',
    WE_PLZ: null,
    WE_ORT: null,
    WE_BREITENGRAD_NOERDLICH: '53.553369',
    WE_LÄNGENGRAD_OESTLICH: '10.032269',
    WE_ANZAHL_SCHUELER: 3207,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989050,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074821,
    WE_EN_2_OBJ_ID_FKT: 2074825,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701052,
    WE_SAP_WE_ID_PKF: '7000/701052',
    WE_SAP_WE_BEZEICHNUNG: 'Burgstra?e 33, 20535 Hamburg, 701052',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Borgfelde',
    WE_STRASSE_NR: 'Burgstra?e 33',
    WE_PLZ: '20535',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.556303',
    WE_LÄNGENGRAD_OESTLICH: '10.040079',
    WE_ANZAHL_SCHUELER: 1492,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989046,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074845,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701072,
    WE_SAP_WE_ID_PKF: '7000/701072',
    WE_SAP_WE_BEZEICHNUNG: 'Hinrichsenstra?e 35, 20535 Hamburg, 701072',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Borgfelde',
    WE_STRASSE_NR: 'Hinrichsenstra?e 35',
    WE_PLZ: '20535',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559930',
    WE_LÄNGENGRAD_OESTLICH: '10.036029',
    WE_ANZAHL_SCHUELER: 1552,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989064,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074845,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701051,
    WE_SAP_WE_ID_PKF: '7000/701051',
    WE_SAP_WE_BEZEICHNUNG: 'Sorbenstra?e 15, 20537 Hamburg, 701051',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Sorbenstra?e 15',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.550050',
    WE_LÄNGENGRAD_OESTLICH: '10.041752',
    WE_ANZAHL_SCHUELER: 1456,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989045,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800288,
    WE_EN_1_OBJ_ID_FKT: 2074808,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701064,
    WE_SAP_WE_ID_PKF: '7000/701064',
    WE_SAP_WE_BEZEICHNUNG: 'Budapester Stra?e 58, 20359 Hamburg, 701064',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Budapester Stra?e 58',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554603',
    WE_LÄNGENGRAD_OESTLICH: '9.965879',
    WE_ANZAHL_SCHUELER: 267,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989056,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074835,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701054,
    WE_SAP_WE_ID_PKF: '7000/701054',
    WE_SAP_WE_BEZEICHNUNG: 'Brekelbaums Park 10, 20537 Hamburg, 701054',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Borgfelde',
    WE_STRASSE_NR: 'Brekelbaums Park 10',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553622',
    WE_LÄNGENGRAD_OESTLICH: '10.035945',
    WE_ANZAHL_SCHUELER: 1408,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989048,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 5194947,
    WE_EN_1_OBJ_ID_FKT: 2074815,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701053,
    WE_SAP_WE_ID_PKF: '7000/701053',
    WE_SAP_WE_BEZEICHNUNG: 'Brekelbaums Park 6, 20537 Hamburg, 701053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Borgfelde',
    WE_STRASSE_NR: 'Brekelbaums Park 6',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553715',
    WE_LÄNGENGRAD_OESTLICH: '10.035315',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989047,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 5194947,
    WE_EN_1_OBJ_ID_FKT: 7374557,
    WE_EN_2_OBJ_ID_FKT: 2074845,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701055,
    WE_SAP_WE_ID_PKF: '7000/701055',
    WE_SAP_WE_BEZEICHNUNG: 'Ebelingplatz 9, 20537 Hamburg, 701055',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Ebelingplatz 9',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554548',
    WE_LÄNGENGRAD_OESTLICH: '10.047567',
    WE_ANZAHL_SCHUELER: 1914,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989049,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074810,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701060,
    WE_SAP_WE_ID_PKF: '7000/701060',
    WE_SAP_WE_BEZEICHNUNG: 'Wendenstra?e 166, 20537 Hamburg, 701060',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Wendenstra?e 166',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.549209',
    WE_LÄNGENGRAD_OESTLICH: '10.033596',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989054,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074828,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701058,
    WE_SAP_WE_ID_PKF: '7000/701058',
    WE_SAP_WE_BEZEICHNUNG: 'Holstenwall 14-17, 20355 Hamburg, 701058',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neustadt',
    WE_STRASSE_NR: 'Holstenwall 14-17',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552167',
    WE_LÄNGENGRAD_OESTLICH: '9.975783',
    WE_ANZAHL_SCHUELER: 1184,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989052,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 5194947,
    WE_EN_1_OBJ_ID_FKT: 7374557,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701071,
    WE_SAP_WE_ID_PKF: '7000/701071',
    WE_SAP_WE_BEZEICHNUNG: 'Zeughausmarkt 32, 20459 Hamburg, 701071',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neustadt',
    WE_STRASSE_NR: 'Zeughausmarkt 32',
    WE_PLZ: '20459',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548703',
    WE_LÄNGENGRAD_OESTLICH: '9.973639',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989063,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074802,
    WE_EN_2_OBJ_ID_FKT: 2074801,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701063,
    WE_SAP_WE_ID_PKF: '7000/701063',
    WE_SAP_WE_BEZEICHNUNG: 'Holzdamm 5, 20099 Hamburg, 701063',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Georg',
    WE_STRASSE_NR: 'Holzdamm 5',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.556550',
    WE_LÄNGENGRAD_OESTLICH: '10.005312',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989055,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2071474,
    WE_EN_1_OBJ_ID_FKT: 2074821,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701059,
    WE_SAP_WE_ID_PKF: '7000/701059',
    WE_SAP_WE_BEZEICHNUNG: 'Wallstra?e 2, 22087 Hamburg, 701059',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Georg',
    WE_STRASSE_NR: 'Wallstra?e 2',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.557736',
    WE_LÄNGENGRAD_OESTLICH: '10.022919',
    WE_ANZAHL_SCHUELER: 672,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989053,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074822,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701057,
    WE_SAP_WE_ID_PKF: '7000/701057',
    WE_SAP_WE_BEZEICHNUNG: 'Bei der Hauptfeuerwache 1, 20099 Hamburg, 701057',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Bei der Hauptfeuerwache 1',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555340',
    WE_LÄNGENGRAD_OESTLICH: '10.023512',
    WE_ANZAHL_SCHUELER: 690,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989051,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 5194947,
    WE_EN_1_OBJ_ID_FKT: 7374557,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 707022,
    WE_SAP_WE_ID_PKF: '7000/707022',
    WE_SAP_WE_BEZEICHNUNG: 'Dratelnstra?e 24, 21109 Hamburg, 707022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Dratelnstra?e 24',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.500566',
    WE_LÄNGENGRAD_OESTLICH: '10.002389',
    WE_ANZAHL_SCHUELER: 1678,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989440,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 1800488,
    WE_EN_1_OBJ_ID_FKT: 2074804,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 707021,
    WE_SAP_WE_ID_PKF: '7000/707021',
    WE_SAP_WE_BEZEICHNUNG: 'Dratelnstra?e 26, 21109 Hamburg, 707021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Dratelnstra?e 26',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.499733',
    WE_LÄNGENGRAD_OESTLICH: '10.002859',
    WE_ANZAHL_SCHUELER: 2214,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989439,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 1800488,
    WE_EN_1_OBJ_ID_FKT: 2074818,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 707023,
    WE_SAP_WE_ID_PKF: '7000/707023',
    WE_SAP_WE_BEZEICHNUNG: 'Dratelnstra?e 28, 21109 Hamburg, 707023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Dratelnstra?e 28',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.498909',
    WE_LÄNGENGRAD_OESTLICH: '10.002579',
    WE_ANZAHL_SCHUELER: 2506,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989441,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 1800488,
    WE_EN_1_OBJ_ID_FKT: 2074843,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701068,
    WE_SAP_WE_ID_PKF: '7000/701068',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Wohlwillstra?e 46, 20359 Hamburg; beendet 3_19, 701068',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Wohlwillstra?e 46',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555390',
    WE_LÄNGENGRAD_OESTLICH: '9.962795',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.03.19',
    WE_WE_OBJ_ID_PKT: 1989060,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074808,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701032,
    WE_SAP_WE_ID_PKF: '7000/701032',
    WE_SAP_WE_BEZEICHNUNG: 'Billbrookdeich 266, 22113 Hamburg, 701032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billbrook',
    WE_STRASSE_NR: 'Billbrookdeich 266',
    WE_PLZ: '22113',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.532833',
    WE_LÄNGENGRAD_OESTLICH: '10.110389',
    WE_ANZAHL_SCHUELER: 117,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989026,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074516,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701036,
    WE_SAP_WE_ID_PKF: '7000/701036',
    WE_SAP_WE_BEZEICHNUNG: 'Archenholzstra?e 55, 22117 Hamburg, 701036',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Archenholzstra?e 55',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.543660',
    WE_LÄNGENGRAD_OESTLICH: '10.119189',
    WE_ANZAHL_SCHUELER: 357,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989030,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074511,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701007,
    WE_SAP_WE_ID_PKF: '7000/701007',
    WE_SAP_WE_BEZEICHNUNG: 'Bonhoefferstra?e 13, 22117 Hamburg, 701007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Bonhoefferstra?e 13',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.544986',
    WE_LÄNGENGRAD_OESTLICH: '10.136609',
    WE_ANZAHL_SCHUELER: 246,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989002,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074519,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701009,
    WE_SAP_WE_ID_PKF: '7000/701009',
    WE_SAP_WE_BEZEICHNUNG: 'Dringsheide 10-12, 22119 Hamburg, 701009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Dringsheide 10-12',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.561615',
    WE_LÄNGENGRAD_OESTLICH: '10.122060',
    WE_ANZAHL_SCHUELER: 379,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989004,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2074520,
    WE_EN_2_OBJ_ID_FKT: 5218680,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701015,
    WE_SAP_WE_ID_PKF: '7000/701015',
    WE_SAP_WE_BEZEICHNUNG: 'Hauskoppelstieg 12, 22111 Hamburg, 701015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Hauskoppelstieg 12',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.541626',
    WE_LÄNGENGRAD_OESTLICH: '10.103619',
    WE_ANZAHL_SCHUELER: 106,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989010,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 5218680,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701033,
    WE_SAP_WE_ID_PKF: '7000/701033',
    WE_SAP_WE_BEZEICHNUNG: 'M?mmelmannsberg 54, 22115 Hamburg, 701033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'M?mmelmannsberg 54',
    WE_PLZ: '22115',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.528783',
    WE_LÄNGENGRAD_OESTLICH: '10.139689',
    WE_ANZAHL_SCHUELER: 442,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989027,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 2074513,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701034,
    WE_SAP_WE_ID_PKF: '7000/701034',
    WE_SAP_WE_BEZEICHNUNG: 'M?mmelmannsberg 75, 22115 Hamburg, 701034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'M?mmelmannsberg 75',
    WE_PLZ: '22115',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.530256',
    WE_LÄNGENGRAD_OESTLICH: '10.148319',
    WE_ANZAHL_SCHUELER: 1140,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989028,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074528,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701031,
    WE_SAP_WE_ID_PKF: '7000/701031',
    WE_SAP_WE_BEZEICHNUNG: 'Oberschleems 9, 22117 Hamburg, 701031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Oberschleems 9',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.537433',
    WE_LÄNGENGRAD_OESTLICH: '10.119209',
    WE_ANZAHL_SCHUELER: 347,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989025,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074516,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701028,
    WE_SAP_WE_ID_PKF: '7000/701028',
    WE_SAP_WE_BEZEICHNUNG: '?jendorfer H?he 12, 22117 Hamburg, 701028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: '?jendorfer H?he 12',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.542246',
    WE_LÄNGENGRAD_OESTLICH: '10.130369',
    WE_ANZAHL_SCHUELER: 717,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989023,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074529,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701027,
    WE_SAP_WE_ID_PKF: '7000/701027',
    WE_SAP_WE_BEZEICHNUNG: 'Pergamentweg 1-5, 22117 Hamburg, 701027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Pergamentweg 1-5',
    WE_PLZ: '22117',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.541154',
    WE_LÄNGENGRAD_OESTLICH: '10.120989',
    WE_ANZAHL_SCHUELER: 566,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989022,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074515,
    WE_EN_2_OBJ_ID_FKT: 2074529,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701040,
    WE_SAP_WE_ID_PKF: '7000/701040',
    WE_SAP_WE_BEZEICHNUNG: 'Rahewinkel 9, 22115 Hamburg, 701040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Rahewinkel 9',
    WE_PLZ: '22115',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.526773',
    WE_LÄNGENGRAD_OESTLICH: '10.155009',
    WE_ANZAHL_SCHUELER: 397,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989034,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 2074514,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701080,
    WE_SAP_WE_ID_PKF: '7000/701080',
    WE_SAP_WE_BEZEICHNUNG: 'Sonnenland 27, 22115 Hamburg, 701080',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Sonnenland 27',
    WE_PLZ: '22115',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.534346',
    WE_LÄNGENGRAD_OESTLICH: '10.131529',
    WE_ANZAHL_SCHUELER: 293,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989068,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074517,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701045,
    WE_SAP_WE_ID_PKF: '7000/701045',
    WE_SAP_WE_BEZEICHNUNG: 'Steinadlerweg 26, 22119 Hamburg, 701045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Steinadlerweg 26',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.551593',
    WE_LÄNGENGRAD_OESTLICH: '10.106779',
    WE_ANZAHL_SCHUELER: 581,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989039,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074527,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701082,
    WE_SAP_WE_ID_PKF: '7000/701082',
    WE_SAP_WE_BEZEICHNUNG: 'Steinfeldtstra?e 1, 22119 Hamburg, 701082',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Steinfeldtstra?e 1',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.547436',
    WE_LÄNGENGRAD_OESTLICH: '10.101709',
    WE_ANZAHL_SCHUELER: 50,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2137304,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 5218680,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701048,
    WE_SAP_WE_ID_PKF: '7000/701048',
    WE_SAP_WE_BEZEICHNUNG: 'Sterntalerstra?e 42, 22119 Hamburg, 701048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billstedt',
    WE_STRASSE_NR: 'Sterntalerstra?e 42',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.560355',
    WE_LÄNGENGRAD_OESTLICH: '10.111873',
    WE_ANZAHL_SCHUELER: 294,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989042,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2074526,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701041,
    WE_SAP_WE_ID_PKF: '7000/701041',
    WE_SAP_WE_BEZEICHNUNG: 'Richardstra?e 85, 22089 Hamburg, 701041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Eilbek',
    WE_STRASSE_NR: 'Richardstra?e 85',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567392',
    WE_LÄNGENGRAD_OESTLICH: '10.039769',
    WE_ANZAHL_SCHUELER: 369,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989035,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074506,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701014,
    WE_SAP_WE_ID_PKF: '7000/701014',
    WE_SAP_WE_BEZEICHNUNG: 'Ritterstra?e 44, 22089 Hamburg, 701014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Eilbek',
    WE_STRASSE_NR: 'Ritterstra?e 44',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.564546',
    WE_LÄNGENGRAD_OESTLICH: '10.045049',
    WE_ANZAHL_SCHUELER: 414,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989009,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074504,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701050,
    WE_SAP_WE_ID_PKF: '7000/701050',
    WE_SAP_WE_BEZEICHNUNG: 'Wielandstra?e 9, 22089 Hamburg, 701050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Eilbek',
    WE_STRASSE_NR: 'Wielandstra?e 9',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.570404',
    WE_LÄNGENGRAD_OESTLICH: '10.054173',
    WE_ANZAHL_SCHUELER: 460,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989044,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074507,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701084,
    WE_SAP_WE_ID_PKF: '7000/701084',
    WE_SAP_WE_BEZEICHNUNG: 'Baakenallee 33, 20457 Hamburg, 701084',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'HafenCity',
    WE_STRASSE_NR: 'Baakenallee 33',
    WE_PLZ: '20457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.541562',
    WE_LÄNGENGRAD_OESTLICH: '10.006929',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5307765,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 5324442,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701087,
    WE_SAP_WE_ID_PKF: '7000/701087',
    WE_SAP_WE_BEZEICHNUNG: 'Lohsepark 0, 20457 Hamburg, 701087',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'HafenCity',
    WE_STRASSE_NR: 'Lohsepark 0',
    WE_PLZ: '20457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 234,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5491328,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 5324442,
    WE_EN_2_OBJ_ID_FKT: 7266295,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701074,
    WE_SAP_WE_ID_PKF: '7000/701074',
    WE_SAP_WE_BEZEICHNUNG: 'Beltgens Garten 25, 20537 Hamburg, 701074',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Beltgens Garten 25',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553113',
    WE_LÄNGENGRAD_OESTLICH: '10.043379',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989065,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2076313,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701013,
    WE_SAP_WE_ID_PKF: '7000/701013',
    WE_SAP_WE_BEZEICHNUNG: 'Ebelingplatz 8, 20537 Hamburg, 701013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Ebelingplatz 8',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554606',
    WE_LÄNGENGRAD_OESTLICH: '10.046976',
    WE_ANZAHL_SCHUELER: 579,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989008,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2074497,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701012,
    WE_SAP_WE_ID_PKF: '7000/701012',
    WE_SAP_WE_BEZEICHNUNG: 'Griesstra?e 101, 20535 Hamburg, 701012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Griesstra?e 101',
    WE_PLZ: '20535',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.564546',
    WE_LÄNGENGRAD_OESTLICH: '10.061179',
    WE_ANZAHL_SCHUELER: 575,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989007,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074509,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701018,
    WE_SAP_WE_ID_PKF: '7000/701018',
    WE_SAP_WE_BEZEICHNUNG: 'Hohe Landwehr 19, 20535 Hamburg, 701018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Hohe Landwehr 19',
    WE_PLZ: '20535',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.557386',
    WE_LÄNGENGRAD_OESTLICH: '10.041119',
    WE_ANZAHL_SCHUELER: 373,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989013,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074505,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701037,
    WE_SAP_WE_ID_PKF: '7000/701037',
    WE_SAP_WE_BEZEICHNUNG: 'Osterbrook 17-19, 20537 Hamburg, 701037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Osterbrook 17-19',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548992',
    WE_LÄNGENGRAD_OESTLICH: '10.056340',
    WE_ANZAHL_SCHUELER: 307,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989031,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074494,
    WE_EN_2_OBJ_ID_FKT: 5305173,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701039,
    WE_SAP_WE_ID_PKF: '7000/701039',
    WE_SAP_WE_BEZEICHNUNG: 'Pr?benweg 24, 20537 Hamburg, 701039',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Pr?benweg 24',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554000',
    WE_LÄNGENGRAD_OESTLICH: '10.051479',
    WE_ANZAHL_SCHUELER: 112,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989033,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 5305173,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701086,
    WE_SAP_WE_ID_PKF: '7000/701086',
    WE_SAP_WE_BEZEICHNUNG: 'Steinhauerdamm 17, 22087 Hamburg, 701086',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Steinhauerdamm 17',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5482179,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074498,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701088,
    WE_SAP_WE_ID_PKF: '7000/701088',
    WE_SAP_WE_BEZEICHNUNG: 'Bauerberg 0, 22111 Hamburg, 701088',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Bauerberg 0',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.06.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5925373,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800537,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701016,
    WE_SAP_WE_ID_PKF: '7000/701016',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Weddestra?e 28, 22111 Hamburg AGV, 701016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Weddestra?e 28',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.548303',
    WE_LÄNGENGRAD_OESTLICH: '10.086879',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '30.11.22',
    WE_WE_OBJ_ID_PKT: 1989011,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5341372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701006,
    WE_SAP_WE_ID_PKF: '7000/701006',
    WE_SAP_WE_BEZEICHNUNG: 'Beim Pachthof 15-17, 22111 Hamburg, 701006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Beim Pachthof 15-17',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.551271',
    WE_LÄNGENGRAD_OESTLICH: '10.082834',
    WE_ANZAHL_SCHUELER: 385,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989001,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074518,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701021,
    WE_SAP_WE_ID_PKF: '7000/701021',
    WE_SAP_WE_BEZEICHNUNG: 'Horner Weg 89, 22111 Hamburg, 701021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Horner Weg 89',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555466',
    WE_LÄNGENGRAD_OESTLICH: '10.068384',
    WE_ANZAHL_SCHUELER: 672,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989016,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 5193445,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701023,
    WE_SAP_WE_ID_PKF: '7000/701023',
    WE_SAP_WE_BEZEICHNUNG: 'Querkamp 68, 22119 Hamburg, 701023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Querkamp 68',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.557531',
    WE_LÄNGENGRAD_OESTLICH: '10.103837',
    WE_ANZAHL_SCHUELER: 440,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989018,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800372,
    WE_EN_1_OBJ_ID_FKT: 2074527,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701022,
    WE_SAP_WE_ID_PKF: '7000/701022',
    WE_SAP_WE_BEZEICHNUNG: 'Rhiemsweg 6, 22111 Hamburg, 701022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Rhiemsweg 6',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554319',
    WE_LÄNGENGRAD_OESTLICH: '10.074591',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989017,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 7438981,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701019,
    WE_SAP_WE_ID_PKF: '7000/701019',
    WE_SAP_WE_BEZEICHNUNG: 'Rhiemsweg 61, 22111 Hamburg, 701019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Rhiemsweg 61',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558803',
    WE_LÄNGENGRAD_OESTLICH: '10.075379',
    WE_ANZAHL_SCHUELER: 294,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989014,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074512,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701020,
    WE_SAP_WE_ID_PKF: '7000/701020',
    WE_SAP_WE_BEZEICHNUNG: 'Snitgerreihe 2, 22111 Hamburg, 701020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Snitgerreihe 2',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.556390',
    WE_LÄNGENGRAD_OESTLICH: '10.072289',
    WE_ANZAHL_SCHUELER: 437,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989015,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 5193445,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701044,
    WE_SAP_WE_ID_PKF: '7000/701044',
    WE_SAP_WE_BEZEICHNUNG: 'Speckenreye 11, 22119 Hamburg, 701044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Speckenreye 11',
    WE_PLZ: '22119',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554190',
    WE_LÄNGENGRAD_OESTLICH: '10.095789',
    WE_ANZAHL_SCHUELER: 226,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989038,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 2074524,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701047,
    WE_SAP_WE_ID_PKF: '7000/701047',
    WE_SAP_WE_BEZEICHNUNG: 'Stengelestra?e 38, 22111 Hamburg, 701047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Horn',
    WE_STRASSE_NR: 'Stengelestra?e 38',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.550503',
    WE_LÄNGENGRAD_OESTLICH: '10.093779',
    WE_ANZAHL_SCHUELER: 425,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989041,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2076311,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701042,
    WE_SAP_WE_ID_PKF: '7000/701042',
    WE_SAP_WE_BEZEICHNUNG: 'Kurze Stra?e 30, 20355 Hamburg, 701042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neustadt',
    WE_STRASSE_NR: 'Kurze Stra?e 30',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.553053',
    WE_LÄNGENGRAD_OESTLICH: '9.978772',
    WE_ANZAHL_SCHUELER: 391,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989036,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074502,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701002,
    WE_SAP_WE_ID_PKF: '7000/701002',
    WE_SAP_WE_BEZEICHNUNG: 'Neust?dter Stra?e 60, 20355 Hamburg, 701002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neustadt',
    WE_STRASSE_NR: 'Neust?dter Stra?e 60',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.554063',
    WE_LÄNGENGRAD_OESTLICH: '9.980619',
    WE_ANZAHL_SCHUELER: 563,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1988997,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074508,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701035,
    WE_SAP_WE_ID_PKF: '7000/701035',
    WE_SAP_WE_BEZEICHNUNG: 'Insel Neuwerk 5, 27499 Hamburg, 701035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neuwerk',
    WE_STRASSE_NR: 'Insel Neuwerk 5',
    WE_PLZ: '27499',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.919512',
    WE_LÄNGENGRAD_OESTLICH: '8.490050',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989029,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074500,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701030,
    WE_SAP_WE_ID_PKF: '7000/701030',
    WE_SAP_WE_BEZEICHNUNG: 'Marckmannstra?e 60, 20539 Hamburg, 701030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Rothenburgsort',
    WE_STRASSE_NR: 'Marckmannstra?e 60',
    WE_PLZ: '20539',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.535340',
    WE_LÄNGENGRAD_OESTLICH: '10.038618',
    WE_ANZAHL_SCHUELER: 114,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989024,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074522,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701008,
    WE_SAP_WE_ID_PKF: '7000/701008',
    WE_SAP_WE_BEZEICHNUNG: 'Marckmannstra?e 61, 20539 Hamburg, 701008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Rothenburgsort',
    WE_STRASSE_NR: 'Marckmannstra?e 61',
    WE_PLZ: '20539',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.535776',
    WE_LÄNGENGRAD_OESTLICH: '10.041129',
    WE_ANZAHL_SCHUELER: 285,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989003,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 2053707,
    WE_EN_1_OBJ_ID_FKT: 2074493,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701017,
    WE_SAP_WE_ID_PKF: '7000/701017',
    WE_SAP_WE_BEZEICHNUNG: 'Greifswalder Stra?e 40, 20099 Hamburg, 701017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Georg',
    WE_STRASSE_NR: 'Greifswalder Stra?e 40',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.556053',
    WE_LÄNGENGRAD_OESTLICH: '10.011589',
    WE_ANZAHL_SCHUELER: 414,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989012,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2074499,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701011,
    WE_SAP_WE_ID_PKF: '7000/701011',
    WE_SAP_WE_BEZEICHNUNG: 'Rostocker Stra?e 41, 20099 Hamburg, 701011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Georg',
    WE_STRASSE_NR: 'Rostocker Stra?e 41',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558114',
    WE_LÄNGENGRAD_OESTLICH: '10.017060',
    WE_ANZAHL_SCHUELER: 1141,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989006,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 7033443,
    WE_EN_1_OBJ_ID_FKT: 2074509,
    WE_EN_2_OBJ_ID_FKT: 2074491,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701026,
    WE_SAP_WE_ID_PKF: '7000/701026',
    WE_SAP_WE_BEZEICHNUNG: 'Westphalensweg 7, 20099 Hamburg, 701026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Georg',
    WE_STRASSE_NR: 'Westphalensweg 7',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555904',
    WE_LÄNGENGRAD_OESTLICH: '10.025675',
    WE_ANZAHL_SCHUELER: 882,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989021,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5325079,
    WE_EN_1_OBJ_ID_FKT: 2074498,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701078,
    WE_SAP_WE_ID_PKF: '7000/701078',
    WE_SAP_WE_BEZEICHNUNG: 'Bernhard-Nocht-Stra?e 12-14, 20359 Hamburg, 701078',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Bernhard-Nocht-Stra?e 12-14',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.546636',
    WE_LÄNGENGRAD_OESTLICH: '9.959019',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989066,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074495,
    WE_EN_2_OBJ_ID_FKT: 2074508,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701003,
    WE_SAP_WE_ID_PKF: '7000/701003',
    WE_SAP_WE_BEZEICHNUNG: 'Friedrichstra?e 55, 20359 Hamburg, 701003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Friedrichstra?e 55',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.547743',
    WE_LÄNGENGRAD_OESTLICH: '9.958559',
    WE_ANZAHL_SCHUELER: 355,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1988998,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074508,
    WE_EN_2_OBJ_ID_FKT: 2074495,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701001,
    WE_SAP_WE_ID_PKF: '7000/701001',
    WE_SAP_WE_BEZEICHNUNG: 'Holstenglacis 6, 20355 Hamburg, 701001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Holstenglacis 6',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558186',
    WE_LÄNGENGRAD_OESTLICH: '9.977039',
    WE_ANZAHL_SCHUELER: 488,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1988996,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800307,
    WE_EN_1_OBJ_ID_FKT: 2074492,
    WE_EN_2_OBJ_ID_FKT: 2074510,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701085,
    WE_SAP_WE_ID_PKF: '7000/701085',
    WE_SAP_WE_BEZEICHNUNG: 'Laeiszstra?e 12, 20357 Hamburg, 701085',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Laeiszstra?e 12',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559077',
    WE_LÄNGENGRAD_OESTLICH: '9.970033',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.15',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5398768,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 5305173,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701079,
    WE_SAP_WE_ID_PKF: '7000/701079',
    WE_SAP_WE_BEZEICHNUNG: 'St. Pauli Fischmarkt 24, 22767 Hamburg, 701079',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'St. Pauli Fischmarkt 24',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.545996',
    WE_LÄNGENGRAD_OESTLICH: '9.957732',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989067,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 5891836,
    WE_EN_1_OBJ_ID_FKT: 2074508,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701091,
    WE_SAP_WE_ID_PKF: '7000/701091',
    WE_SAP_WE_BEZEICHNUNG: 'Wohlwillstra?e 46, 20359 Hamburg, 701091',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Wohlwillstra?e 46',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.555390',
    WE_LÄNGENGRAD_OESTLICH: '9.962795',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7430553,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7432166,
    WE_EN_2_OBJ_ID_FKT: 5455937,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701090,
    WE_SAP_WE_ID_PKF: '7000/701090',
    WE_SAP_WE_BEZEICHNUNG: 'Schumacherwerder 1 - geplant 2028, 701090',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Mitte',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Schumacherwerder 1 - geplant 2028',
    WE_PLZ: '20457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7054147,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7427892,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708018,
    WE_SAP_WE_ID_PKF: '7000/708018',
    WE_SAP_WE_BEZEICHNUNG: 'Beltgens Garten 25, 20537 Hamburg, 708018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Beltgens Garten 25',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236658,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251898,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708003,
    WE_SAP_WE_ID_PKF: '7000/708003',
    WE_SAP_WE_BEZEICHNUNG: 'Karolinenstr.35, 20357 Hamburg, 708003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Karolinenstr.35',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236643,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 5448333,
    WE_EN_1_OBJ_ID_FKT: 7251907,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708008,
    WE_SAP_WE_ID_PKF: '7000/708008',
    WE_SAP_WE_BEZEICHNUNG: 'Seilerstra?e 42, 20359 Hamburg, 708008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'Seilerstra?e 42',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236648,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251914,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708021,
    WE_SAP_WE_ID_PKF: '7000/708021',
    WE_SAP_WE_BEZEICHNUNG: 'Moorwerder Hauptdeich 31, 21109 Hamburg, 708021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Moorwerder Hauptdeich 31',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236661,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7371755,
    WE_EN_1_OBJ_ID_FKT: 7251912,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703068,
    WE_SAP_WE_ID_PKF: '7000/703068',
    WE_SAP_WE_BEZEICHNUNG: 'Ifflandstra?e 30, 22087 Hamburg, 703068',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Ifflandstra?e 30',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.560903',
    WE_LÄNGENGRAD_OESTLICH: '10.022558',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.18',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6780850,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 2141633,
    WE_EN_1_OBJ_ID_FKT: 2074607,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704063,
    WE_SAP_WE_ID_PKF: '7000/704063',
    WE_SAP_WE_BEZEICHNUNG: 'Uferstra?e 9, 22081 Hamburg, 704063',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Uferstra?e 9',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.570870',
    WE_LÄNGENGRAD_OESTLICH: '10.039464',
    WE_ANZAHL_SCHUELER: 611,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989260,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074841,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704068,
    WE_SAP_WE_ID_PKF: '7000/704068',
    WE_SAP_WE_BEZEICHNUNG: 'Wagnerstra?e 60, 22081 Hamburg, 704068',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Wagnerstra?e 60',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571520',
    WE_LÄNGENGRAD_OESTLICH: '10.038939',
    WE_ANZAHL_SCHUELER: 1258,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989264,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074801,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704070,
    WE_SAP_WE_ID_PKF: '7000/704070',
    WE_SAP_WE_BEZEICHNUNG: 'Angerstra?e 31-33, 22087 Hamburg, 704070',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Angerstra?e 31-33',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.561237',
    WE_LÄNGENGRAD_OESTLICH: '10.034959',
    WE_ANZAHL_SCHUELER: 2052,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989266,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074812,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704069,
    WE_SAP_WE_ID_PKF: '7000/704069',
    WE_SAP_WE_BEZEICHNUNG: 'Angerstra?e 4, 22087 Hamburg, 704069',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Angerstra?e 4',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558887',
    WE_LÄNGENGRAD_OESTLICH: '10.027420',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989265,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074812,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704077,
    WE_SAP_WE_ID_PKF: '7000/704077',
    WE_SAP_WE_BEZEICHNUNG: 'Uferstra?e 1a, 22081 Hamburg, 704077',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'Uferstra?e 1a',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.568223',
    WE_LÄNGENGRAD_OESTLICH: '10.033149',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989272,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074801,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704071,
    WE_SAP_WE_ID_PKF: '7000/704071',
    WE_SAP_WE_BEZEICHNUNG: 'Richardstra?e 1, 22081 Hamburg, 704071',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Richardstra?e 1',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573094',
    WE_LÄNGENGRAD_OESTLICH: '10.034288',
    WE_ANZAHL_SCHUELER: 1258,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989267,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074807,
    WE_EN_2_OBJ_ID_FKT: 7530635,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704079,
    WE_SAP_WE_ID_PKF: '7000/704079',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Eilbektal 35, 22089 Hamburg, 704079',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eilbek',
    WE_STRASSE_NR: 'Eilbektal 35',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572846',
    WE_LÄNGENGRAD_OESTLICH: '10.048409',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.07.22',
    WE_WE_OBJ_ID_PKT: 1989274,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074821,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704066,
    WE_SAP_WE_ID_PKF: '7000/704066',
    WE_SAP_WE_BEZEICHNUNG: 'Kellinghusenstra?e 11, 20249 Hamburg, 704066',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Kellinghusenstra?e 11',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.590536',
    WE_LÄNGENGRAD_OESTLICH: '9.991160',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989262,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074825,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704064,
    WE_SAP_WE_ID_PKF: '7000/704064',
    WE_SAP_WE_BEZEICHNUNG: 'Angerstra?e 7-11, 22087 Hamburg, 704064',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Angerstra?e 7-11',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.559316',
    WE_LÄNGENGRAD_OESTLICH: '10.029927',
    WE_ANZAHL_SCHUELER: 1177,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989261,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074803,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704067,
    WE_SAP_WE_ID_PKF: '7000/704067',
    WE_SAP_WE_BEZEICHNUNG: 'Tessenowweg 3, 22297 Hamburg, 704067',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Tessenowweg 3',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.609386',
    WE_LÄNGENGRAD_OESTLICH: '10.028439',
    WE_ANZAHL_SCHUELER: 2180,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989263,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 7258370,
    WE_EN_1_OBJ_ID_FKT: 2074826,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704041,
    WE_SAP_WE_ID_PKF: '7000/704041',
    WE_SAP_WE_BEZEICHNUNG: 'Alsterdorfer Stra?e 420, 22297 Hamburg, 704041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Alsterdorf',
    WE_STRASSE_NR: 'Alsterdorfer Stra?e 420',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.613633',
    WE_LÄNGENGRAD_OESTLICH: '10.020869',
    WE_ANZAHL_SCHUELER: 111,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989238,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 2074648,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704052,
    WE_SAP_WE_ID_PKF: '7000/704052',
    WE_SAP_WE_BEZEICHNUNG: 'Sengelmannstra?e 50, 20249 Hamburg, 704052',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Alsterdorf',
    WE_STRASSE_NR: 'Sengelmannstra?e 50',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.614523',
    WE_LÄNGENGRAD_OESTLICH: '10.020029',
    WE_ANZAHL_SCHUELER: 108,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989249,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 5305177,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704034,
    WE_SAP_WE_ID_PKF: '7000/704034',
    WE_SAP_WE_BEZEICHNUNG: 'Wilhelm-Metzger-Stra?e 4, 22297 Hamburg, 704034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Alsterdorf',
    WE_STRASSE_NR: 'Wilhelm-Metzger-Stra?e 4',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.604691',
    WE_LÄNGENGRAD_OESTLICH: '9.998192',
    WE_ANZAHL_SCHUELER: 788,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989231,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 2077131,
    WE_EN_1_OBJ_ID_FKT: 2074646,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704012,
    WE_SAP_WE_ID_PKF: '7000/704012',
    WE_SAP_WE_BEZEICHNUNG: 'Benzenbergweg 2, 22307 Hamburg, 704012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Benzenbergweg 2',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600643',
    WE_LÄNGENGRAD_OESTLICH: '10.046899',
    WE_ANZAHL_SCHUELER: 908,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989209,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074666,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704081,
    WE_SAP_WE_ID_PKF: '7000/704081',
    WE_SAP_WE_BEZEICHNUNG: 'Bramfelder Stra?e 157, 22305 Hamburg, 704081',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Bramfelder Stra?e 157',
    WE_PLZ: '22305',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.592119',
    WE_LÄNGENGRAD_OESTLICH: '10.056872',
    WE_ANZAHL_SCHUELER: 60,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5324670,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074665,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704010,
    WE_SAP_WE_ID_PKF: '7000/704010',
    WE_SAP_WE_BEZEICHNUNG: 'Fraenkelstra?e 3, 22307 Hamburg, 704010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Fraenkelstra?e 3',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.598608',
    WE_LÄNGENGRAD_OESTLICH: '10.037297',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989207,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074661,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704030,
    WE_SAP_WE_ID_PKF: '7000/704030',
    WE_SAP_WE_BEZEICHNUNG: 'Genslerstra?e 33, 22307 Hamburg, 704030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Genslerstra?e 33',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.592886',
    WE_LÄNGENGRAD_OESTLICH: '10.038424',
    WE_ANZAHL_SCHUELER: 374,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989227,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074661,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704042,
    WE_SAP_WE_ID_PKF: '7000/704042',
    WE_SAP_WE_BEZEICHNUNG: 'L?mmersieth 72a, 22305 Hamburg, 704042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'L?mmersieth 72a',
    WE_PLZ: '22305',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587855',
    WE_LÄNGENGRAD_OESTLICH: '10.059134',
    WE_ANZAHL_SCHUELER: 233,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989239,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 2074663,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704013,
    WE_SAP_WE_ID_PKF: '7000/704013',
    WE_SAP_WE_BEZEICHNUNG: 'Langenfort  68-70, 22307 Hamburg, 704013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Langenfort  68-70',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.599486',
    WE_LÄNGENGRAD_OESTLICH: '10.049049',
    WE_ANZAHL_SCHUELER: 302,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989210,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074666,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704047,
    WE_SAP_WE_ID_PKF: '7000/704047',
    WE_SAP_WE_BEZEICHNUNG: 'Langenfort 5, 22307 Hamburg, 704047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Langenfort 5',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600385',
    WE_LÄNGENGRAD_OESTLICH: '10.042617',
    WE_ANZAHL_SCHUELER: 802,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989244,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074658,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704011,
    WE_SAP_WE_ID_PKF: '7000/704011',
    WE_SAP_WE_BEZEICHNUNG: 'Tieloh 28, 22307 Hamburg, 704011',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Tieloh 28',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.593263',
    WE_LÄNGENGRAD_OESTLICH: '10.051376',
    WE_ANZAHL_SCHUELER: 311,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989208,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074665,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704016,
    WE_SAP_WE_ID_PKF: '7000/704016',
    WE_SAP_WE_BEZEICHNUNG: 'Wittenkamp 15, 22307 Hamburg, 704016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-Nord',
    WE_STRASSE_NR: 'Wittenkamp 15',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.597060',
    WE_LÄNGENGRAD_OESTLICH: '10.048397',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989213,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 5455058,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704002,
    WE_SAP_WE_ID_PKF: '7000/704002',
    WE_SAP_WE_BEZEICHNUNG: 'Brucknerstra?e 1, 22083 Hamburg, 704002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Brucknerstra?e 1',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583026',
    WE_LÄNGENGRAD_OESTLICH: '10.039469',
    WE_ANZAHL_SCHUELER: 329,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989199,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 2074656,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704015,
    WE_SAP_WE_ID_PKF: '7000/704015',
    WE_SAP_WE_BEZEICHNUNG: 'Brucknerstra?e 17, 22083 Hamburg, 704015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Brucknerstra?e 17',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583271',
    WE_LÄNGENGRAD_OESTLICH: '10.037432',
    WE_ANZAHL_SCHUELER: 83,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989212,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 5305176,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704037,
    WE_SAP_WE_ID_PKF: '7000/704037',
    WE_SAP_WE_BEZEICHNUNG: 'Humboldtstra?e 30, 22083 Hamburg, 704037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Humboldtstra?e 30',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573490',
    WE_LÄNGENGRAD_OESTLICH: '10.027250',
    WE_ANZAHL_SCHUELER: 509,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989234,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2074662,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704058,
    WE_SAP_WE_ID_PKF: '7000/704058',
    WE_SAP_WE_BEZEICHNUNG: 'Humboldtstra?e 89, 22083 Hamburg, 704058',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Humboldtstra?e 89',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.576666',
    WE_LÄNGENGRAD_OESTLICH: '10.021839',
    WE_ANZAHL_SCHUELER: 318,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989255,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 5333626,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704060,
    WE_SAP_WE_ID_PKF: '7000/704060',
    WE_SAP_WE_BEZEICHNUNG: 'Imstedt  18-20, 22083 Hamburg, 704060',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Imstedt  18-20',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.579573',
    WE_LÄNGENGRAD_OESTLICH: '10.027739',
    WE_ANZAHL_SCHUELER: 280,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989257,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 5333626,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704032,
    WE_SAP_WE_ID_PKF: '7000/704032',
    WE_SAP_WE_BEZEICHNUNG: 'Von-Essen-Stra?e 82-84, 22081 Hamburg, 704032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Von-Essen-Stra?e 82-84',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.576456',
    WE_LÄNGENGRAD_OESTLICH: '10.045670',
    WE_ANZAHL_SCHUELER: 127,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989229,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074657,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704001,
    WE_SAP_WE_ID_PKF: '7000/704001',
    WE_SAP_WE_BEZEICHNUNG: 'Zeisigstra?e 3, 22081 Hamburg, 704001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Barmbek-S?d',
    WE_STRASSE_NR: 'Zeisigstra?e 3',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.577403',
    WE_LÄNGENGRAD_OESTLICH: '10.047782',
    WE_ANZAHL_SCHUELER: 291,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989198,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 2074656,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704007,
    WE_SAP_WE_ID_PKF: '7000/704007',
    WE_SAP_WE_BEZEICHNUNG: 'Alter Teichweg  200, 22049 Hamburg, 704007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Dulsberg',
    WE_STRASSE_NR: 'Alter Teichweg  200',
    WE_PLZ: '22049',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587504',
    WE_LÄNGENGRAD_OESTLICH: '10.069562',
    WE_ANZAHL_SCHUELER: 1723,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989204,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2074664,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704009,
    WE_SAP_WE_ID_PKF: '7000/704009',
    WE_SAP_WE_BEZEICHNUNG: 'Krausestra?e 53, 22049 Hamburg, 704009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Dulsberg',
    WE_STRASSE_NR: 'Krausestra?e 53',
    WE_PLZ: '22049',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.580763',
    WE_LÄNGENGRAD_OESTLICH: '10.057089',
    WE_ANZAHL_SCHUELER: 557,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989206,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074665,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704078,
    WE_SAP_WE_ID_PKF: '7000/704078',
    WE_SAP_WE_BEZEICHNUNG: 'Vogesenstra?e 11, 22049 Hamburg, 704078',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Dulsberg',
    WE_STRASSE_NR: 'Vogesenstra?e 11',
    WE_PLZ: '22049',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.582139',
    WE_LÄNGENGRAD_OESTLICH: '10.059756',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989273,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074665,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704059,
    WE_SAP_WE_ID_PKF: '7000/704059',
    WE_SAP_WE_BEZEICHNUNG: 'Erikastra?e 41, 20251 Hamburg, 704059',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Erikastra?e 41',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.593376',
    WE_LÄNGENGRAD_OESTLICH: '9.984479',
    WE_ANZAHL_SCHUELER: 437,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989256,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2076324,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704020,
    WE_SAP_WE_ID_PKF: '7000/704020',
    WE_SAP_WE_BEZEICHNUNG: 'Hegestra?e 35, 20249 Hamburg, 704020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Hegestra?e 35',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584844',
    WE_LÄNGENGRAD_OESTLICH: '9.984536',
    WE_ANZAHL_SCHUELER: 816,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989217,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074645,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704039,
    WE_SAP_WE_ID_PKF: '7000/704039',
    WE_SAP_WE_BEZEICHNUNG: 'Knauerstra?e 22, 20249 Hamburg, 704039',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Knauerstra?e 22',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.590396',
    WE_LÄNGENGRAD_OESTLICH: '9.990729',
    WE_ANZAHL_SCHUELER: 243,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989236,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074652,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704075,
    WE_SAP_WE_ID_PKF: '7000/704075',
    WE_SAP_WE_BEZEICHNUNG: 'Loogestra?e 21, 20249 Hamburg, 704075',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Loogestra?e 21',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587243',
    WE_LÄNGENGRAD_OESTLICH: '9.989569',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989270,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074645,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704053,
    WE_SAP_WE_ID_PKF: '7000/704053',
    WE_SAP_WE_BEZEICHNUNG: 'Robert-Koch-Stra?e 15, 20249 Hamburg, 704053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Robert-Koch-Stra?e 15',
    WE_PLZ: '20249',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.588196',
    WE_LÄNGENGRAD_OESTLICH: '9.983309',
    WE_ANZAHL_SCHUELER: 275,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989250,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 2074644,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704048,
    WE_SAP_WE_ID_PKF: '7000/704048',
    WE_SAP_WE_BEZEICHNUNG: 'Schottm?llerstra?e 23, 20251 Hamburg, 704048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Eppendorf',
    WE_STRASSE_NR: 'Schottm?llerstra?e 23',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.591796',
    WE_LÄNGENGRAD_OESTLICH: '9.985779',
    WE_ANZAHL_SCHUELER: 199,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989245,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074654,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704006,
    WE_SAP_WE_ID_PKF: '7000/704006',
    WE_SAP_WE_BEZEICHNUNG: 'Erdkampsweg 89, 22335 Hamburg, 704006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Fuhlsb?ttel',
    WE_STRASSE_NR: 'Erdkampsweg 89',
    WE_PLZ: '22335',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.632186',
    WE_LÄNGENGRAD_OESTLICH: '10.018449',
    WE_ANZAHL_SCHUELER: 687,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989203,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074670,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704023,
    WE_SAP_WE_ID_PKF: '7000/704023',
    WE_SAP_WE_BEZEICHNUNG: 'Eschenweg 1, 22335 Hamburg, 704023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Fuhlsb?ttel',
    WE_STRASSE_NR: 'Eschenweg 1',
    WE_PLZ: '22335',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.625475',
    WE_LÄNGENGRAD_OESTLICH: '10.015197',
    WE_ANZAHL_SCHUELER: 166,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989220,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 5305176,
    WE_EN_2_OBJ_ID_FKT: 2074677,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704050,
    WE_SAP_WE_ID_PKF: '7000/704050',
    WE_SAP_WE_BEZEICHNUNG: 'Ohkampring 13, 22339 Hamburg, 704050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Fuhlsb?ttel',
    WE_STRASSE_NR: 'Ohkampring 13',
    WE_PLZ: '22339',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.636989',
    WE_LÄNGENGRAD_OESTLICH: '10.021939',
    WE_ANZAHL_SCHUELER: 413,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989247,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2074676,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704018,
    WE_SAP_WE_ID_PKF: '7000/704018',
    WE_SAP_WE_BEZEICHNUNG: 'Br?dermannsweg 2, 22453 Hamburg, 704018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Gro? Borstel',
    WE_STRASSE_NR: 'Br?dermannsweg 2',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.607023',
    WE_LÄNGENGRAD_OESTLICH: '9.977779',
    WE_ANZAHL_SCHUELER: 401,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989215,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 2074641,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704046,
    WE_SAP_WE_ID_PKF: '7000/704046',
    WE_SAP_WE_BEZEICHNUNG: 'Lokstedter Damm 38, 22453 Hamburg, 704046',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Gro? Borstel',
    WE_STRASSE_NR: 'Lokstedter Damm 38',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.607559',
    WE_LÄNGENGRAD_OESTLICH: '9.970809',
    WE_ANZAHL_SCHUELER: 138,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989243,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 2074653,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704022,
    WE_SAP_WE_ID_PKF: '7000/704022',
    WE_SAP_WE_BEZEICHNUNG: 'Curschmannstra?e 39, 20251 Hamburg, 704022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hoheluft-Ost',
    WE_STRASSE_NR: 'Curschmannstra?e 39',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587666',
    WE_LÄNGENGRAD_OESTLICH: '9.976379',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989219,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074654,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704021,
    WE_SAP_WE_ID_PKF: '7000/704021',
    WE_SAP_WE_BEZEICHNUNG: 'L?wenstra?e 58, 20251 Hamburg, 704021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hoheluft-Ost',
    WE_STRASSE_NR: 'L?wenstra?e 58',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.586968',
    WE_LÄNGENGRAD_OESTLICH: '9.973575',
    WE_ANZAHL_SCHUELER: 1122,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989218,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074654,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704024,
    WE_SAP_WE_ID_PKF: '7000/704024',
    WE_SAP_WE_BEZEICHNUNG: 'Flughafenstra?e 89, 22415 Hamburg, 704024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hummelsb?ttel',
    WE_STRASSE_NR: 'Flughafenstra?e 89',
    WE_PLZ: '22415',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.640743',
    WE_LÄNGENGRAD_OESTLICH: '10.022209',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989221,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2074676,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704019,
    WE_SAP_WE_ID_PKF: '7000/704019',
    WE_SAP_WE_BEZEICHNUNG: 'Eberhofweg 63, 22415 Hamburg, 704019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Eberhofweg 63',
    WE_PLZ: '22415',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.650733',
    WE_LÄNGENGRAD_OESTLICH: '10.014719',
    WE_ANZAHL_SCHUELER: 366,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989216,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 2074672,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704028,
    WE_SAP_WE_ID_PKF: '7000/704028',
    WE_SAP_WE_BEZEICHNUNG: 'Foorthkamp 36, 22419 Hamburg, 704028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Foorthkamp 36',
    WE_PLZ: '22419',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.659333',
    WE_LÄNGENGRAD_OESTLICH: '10.011069',
    WE_ANZAHL_SCHUELER: 187,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989225,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074668,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704025,
    WE_SAP_WE_ID_PKF: '7000/704025',
    WE_SAP_WE_BEZEICHNUNG: 'Foorthkamp 42, 22419 Hamburg, 704025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Foorthkamp 42',
    WE_PLZ: '22419',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.659379',
    WE_LÄNGENGRAD_OESTLICH: '10.013259',
    WE_ANZAHL_SCHUELER: 157,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989222,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074668,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704033,
    WE_SAP_WE_ID_PKF: '7000/704033',
    WE_SAP_WE_BEZEICHNUNG: 'Fritz-Schumacher-Allee 200, 22417 Hamburg, 704033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Fritz-Schumacher-Allee 200',
    WE_PLZ: '22417',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.670193',
    WE_LÄNGENGRAD_OESTLICH: '10.030939',
    WE_ANZAHL_SCHUELER: 896,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989230,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 2074671,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704043,
    WE_SAP_WE_ID_PKF: '7000/704043',
    WE_SAP_WE_BEZEICHNUNG: 'Grellkamp 38-40, 22415 Hamburg, 704043',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Grellkamp 38-40',
    WE_PLZ: '22415',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.652080',
    WE_LÄNGENGRAD_OESTLICH: '9.996699',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989240,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 7397418,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704040,
    WE_SAP_WE_ID_PKF: '7000/704040',
    WE_SAP_WE_BEZEICHNUNG: 'Krohnstieg 107, 22415 Hamburg, 704040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Krohnstieg 107',
    WE_PLZ: '22415',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.647596',
    WE_LÄNGENGRAD_OESTLICH: '10.000219',
    WE_ANZAHL_SCHUELER: 268,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989237,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074674,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704049,
    WE_SAP_WE_ID_PKF: '7000/704049',
    WE_SAP_WE_BEZEICHNUNG: 'Neubergerweg 2, 22419 Hamburg, 704049',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Neubergerweg 2',
    WE_PLZ: '22419',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.667493',
    WE_LÄNGENGRAD_OESTLICH: '10.002629',
    WE_ANZAHL_SCHUELER: 429,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989246,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 2074675,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704054,
    WE_SAP_WE_ID_PKF: '7000/704054',
    WE_SAP_WE_BEZEICHNUNG: 'Stockflethweg 160, 22417 Hamburg, 704054',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Stockflethweg 160',
    WE_PLZ: '22417',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.679249',
    WE_LÄNGENGRAD_OESTLICH: '10.019849',
    WE_ANZAHL_SCHUELER: 361,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989251,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 2074678,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704008,
    WE_SAP_WE_ID_PKF: '7000/704008',
    WE_SAP_WE_BEZEICHNUNG: 'Tangstedter Landstra?e 300, 22417 Hamburg, 704008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Tangstedter Landstra?e 300',
    WE_PLZ: '22417',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.670382',
    WE_LÄNGENGRAD_OESTLICH: '10.027758',
    WE_ANZAHL_SCHUELER: 1441,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989205,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 2074679,
    WE_EN_2_OBJ_ID_FKT: 2074669,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704027,
    WE_SAP_WE_ID_PKF: '7000/704027',
    WE_SAP_WE_BEZEICHNUNG: 'Timmerloh 27-29, 22417 Hamburg, 704027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Langenhorn',
    WE_STRASSE_NR: 'Timmerloh 27-29',
    WE_PLZ: '22417',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.656444',
    WE_LÄNGENGRAD_OESTLICH: '10.022289',
    WE_ANZAHL_SCHUELER: 581,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989224,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074668,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704031,
    WE_SAP_WE_ID_PKF: '7000/704031',
    WE_SAP_WE_BEZEICHNUNG: 'Ballerstaedtweg 1, 22337 Hamburg, 704031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Ohlsdorf',
    WE_STRASSE_NR: 'Ballerstaedtweg 1',
    WE_PLZ: '22337',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.612088',
    WE_LÄNGENGRAD_OESTLICH: '10.034338',
    WE_ANZAHL_SCHUELER: 350,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989228,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 7050948,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704051,
    WE_SAP_WE_ID_PKF: '7000/704051',
    WE_SAP_WE_BEZEICHNUNG: 'Ratsm?hlendamm 39, 22335 Hamburg, 704051',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Ohlsdorf',
    WE_STRASSE_NR: 'Ratsm?hlendamm 39',
    WE_PLZ: '22335',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.626803',
    WE_LÄNGENGRAD_OESTLICH: '10.020767',
    WE_ANZAHL_SCHUELER: 389,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989248,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800386,
    WE_EN_1_OBJ_ID_FKT: 2074677,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704004,
    WE_SAP_WE_ID_PKF: '7000/704004',
    WE_SAP_WE_BEZEICHNUNG: 'Schluchtweg 1, 22337 Hamburg, 704004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Ohlsdorf',
    WE_STRASSE_NR: 'Schluchtweg 1',
    WE_PLZ: '22337',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.632636',
    WE_LÄNGENGRAD_OESTLICH: '10.047299',
    WE_ANZAHL_SCHUELER: 754,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989201,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 5305171,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704003,
    WE_SAP_WE_ID_PKF: '7000/704003',
    WE_SAP_WE_BEZEICHNUNG: 'Struckholt  27-29, 22337 Hamburg, 704003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Ohlsdorf',
    WE_STRASSE_NR: 'Struckholt  27-29',
    WE_PLZ: '22337',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.628043',
    WE_LÄNGENGRAD_OESTLICH: '10.030059',
    WE_ANZAHL_SCHUELER: 965,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989200,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2074667,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704045,
    WE_SAP_WE_ID_PKF: '7000/704045',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Finkenau 42, 22081 Hamburg beendet 9_17, 704045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'Finkenau 42',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.568393',
    WE_LÄNGENGRAD_OESTLICH: '10.032709',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '26.09.17',
    WE_WE_OBJ_ID_PKT: 1989242,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704044,
    WE_SAP_WE_ID_PKF: '7000/704044',
    WE_SAP_WE_BEZEICHNUNG: 'Lerchenfeld 10, 22081 Hamburg, 704044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'Lerchenfeld 10',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.568765',
    WE_LÄNGENGRAD_OESTLICH: '10.029260',
    WE_ANZAHL_SCHUELER: 1038,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989241,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2076323,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704057,
    WE_SAP_WE_ID_PKF: '7000/704057',
    WE_SAP_WE_BEZEICHNUNG: 'Winterhuder Weg 126-128, 22085 Hamburg, 704057',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'Winterhuder Weg 126-128',
    WE_PLZ: '22085',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.577875',
    WE_LÄNGENGRAD_OESTLICH: '10.017790',
    WE_ANZAHL_SCHUELER: 387,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989254,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 7086002,
    WE_EN_1_OBJ_ID_FKT: 2074659,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704005,
    WE_SAP_WE_ID_PKF: '7000/704005',
    WE_SAP_WE_BEZEICHNUNG: 'Alsterdorfer Stra?e 39, 22299 Hamburg, 704005',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Alsterdorfer Stra?e 39',
    WE_PLZ: '22299',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.596080',
    WE_LÄNGENGRAD_OESTLICH: '9.996734',
    WE_ANZAHL_SCHUELER: 306,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989202,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800319,
    WE_EN_1_OBJ_ID_FKT: 2074650,
    WE_EN_2_OBJ_ID_FKT: 2074648,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704082,
    WE_SAP_WE_ID_PKF: '7000/704082',
    WE_SAP_WE_BEZEICHNUNG: 'Barmbeker Stra?e 30-32,  22303 Hamburg, 704082',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Barmbeker Stra?e 30-32',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584972',
    WE_LÄNGENGRAD_OESTLICH: '10.017573',
    WE_ANZAHL_SCHUELER: 248,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.08.15',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5434100,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 2076319,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704014,
    WE_SAP_WE_ID_PKF: '7000/704014',
    WE_SAP_WE_BEZEICHNUNG: 'Borgweg 17 a, 22303 Hamburg, 704014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Borgweg 17 a',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.591383',
    WE_LÄNGENGRAD_OESTLICH: '10.014139',
    WE_ANZAHL_SCHUELER: 154,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989211,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 5366015,
    WE_EN_1_OBJ_ID_FKT: 2074800,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704017,
    WE_SAP_WE_ID_PKF: '7000/704017',
    WE_SAP_WE_BEZEICHNUNG: 'Carl-Cohn-Stra?e 2, 22297 Hamburg, 704017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Carl-Cohn-Stra?e 2',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.602094',
    WE_LÄNGENGRAD_OESTLICH: '10.010966',
    WE_ANZAHL_SCHUELER: 555,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989214,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074651,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704026,
    WE_SAP_WE_ID_PKF: '7000/704026',
    WE_SAP_WE_BEZEICHNUNG: 'Forsmannstra?e 32, 22303 Hamburg, 704026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Forsmannstra?e 32',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584676',
    WE_LÄNGENGRAD_OESTLICH: '10.013788',
    WE_ANZAHL_SCHUELER: 221,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989223,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 2076319,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704035,
    WE_SAP_WE_ID_PKF: '7000/704035',
    WE_SAP_WE_BEZEICHNUNG: 'Grasweg 72-76, 22303 Hamburg, 704035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Grasweg 72-76',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.593006',
    WE_LÄNGENGRAD_OESTLICH: '10.010557',
    WE_ANZAHL_SCHUELER: 1477,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989232,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074647,
    WE_EN_2_OBJ_ID_FKT: 2074800,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704029,
    WE_SAP_WE_ID_PKF: '7000/704029',
    WE_SAP_WE_BEZEICHNUNG: 'Maria-Louisen-Stra?e 114, 22301 Hamburg, 704029',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Maria-Louisen-Stra?e 114',
    WE_PLZ: '22301',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.589630',
    WE_LÄNGENGRAD_OESTLICH: '10.006172',
    WE_ANZAHL_SCHUELER: 878,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989226,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 2074642,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704056,
    WE_SAP_WE_ID_PKF: '7000/704056',
    WE_SAP_WE_BEZEICHNUNG: 'Meerweinstra?e 26-28, 22303 Hamburg, 704056',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Meerweinstra?e 26-28',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587243',
    WE_LÄNGENGRAD_OESTLICH: '10.027175',
    WE_ANZAHL_SCHUELER: 1360,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989253,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 6785771,
    WE_EN_1_OBJ_ID_FKT: 2074655,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704036,
    WE_SAP_WE_ID_PKF: '7000/704036',
    WE_SAP_WE_BEZEICHNUNG: 'Po?moorweg 22, 22301 Hamburg, 704036',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Winterhude',
    WE_STRASSE_NR: 'Po?moorweg 22',
    WE_PLZ: '22301',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.586913',
    WE_LÄNGENGRAD_OESTLICH: '10.011389',
    WE_ANZAHL_SCHUELER: 338,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989233,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800451,
    WE_EN_1_OBJ_ID_FKT: 2074643,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704080,
    WE_SAP_WE_ID_PKF: '7000/704080',
    WE_SAP_WE_BEZEICHNUNG: 'Angerstra?e 4a, Steinhauerdamm 17, 22087 Hamburg, 704080',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Angerstra?e 4a',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.558881',
    WE_LÄNGENGRAD_OESTLICH: '10.027420',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2078144,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704073,
    WE_SAP_WE_ID_PKF: '7000/704073',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Barmbeker Stra?e 30-32, 22303 Hamburg, 704073',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Barmbeker Stra?e 30-32',
    WE_PLZ: '22303',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.585834',
    WE_LÄNGENGRAD_OESTLICH: '10.018446',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.07.16',
    WE_WE_OBJ_ID_PKT: 1989268,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074834,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704055,
    WE_SAP_WE_ID_PKF: '7000/704055',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Osterbekstra?e 107, 22083 Hamburg beendet 30.6.14, 704055',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Osterbekstra?e 107',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '30.06.14',
    WE_WE_OBJ_ID_PKT: 1989252,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2076325,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704074,
    WE_SAP_WE_ID_PKF: '7000/704074',
    WE_SAP_WE_BEZEICHNUNG: 'Dieselstra?e 6, 22307 Hamburg, 704074',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Dieselstra?e 6',
    WE_PLZ: '22307',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.592590',
    WE_LÄNGENGRAD_OESTLICH: '10.053410',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989269,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5341372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704083,
    WE_SAP_WE_ID_PKF: '7000/704083',
    WE_SAP_WE_BEZEICHNUNG: 'Eilbektal 35, 22089 Hamburg, 704083',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Eilbektal 35',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7225401,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 3622167,
    WE_EN_1_OBJ_ID_FKT: 2076323,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704076,
    WE_SAP_WE_ID_PKF: '7000/704076',
    WE_SAP_WE_BEZEICHNUNG: 'Erikastra?e neben 196, 20251 Hamburg, 704076',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Erikastra?e neben 196',
    WE_PLZ: '20251',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600832',
    WE_LÄNGENGRAD_OESTLICH: '9.985372',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989271,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2076324,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708016,
    WE_SAP_WE_ID_PKF: '7000/708016',
    WE_SAP_WE_BEZEICHNUNG: 'F?hrhausstra?e 3a, 22085 Hamburg, 708016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Uhlenhorst',
    WE_STRASSE_NR: 'F?hrhausstra?e 3a',
    WE_PLZ: '22085',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236656,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7251903,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 704062,
    WE_SAP_WE_ID_PKF: '7000/704062',
    WE_SAP_WE_BEZEICHNUNG: 'Eulenkamp 46, 22049 Hamburg, 704062',
    WE_FM_DIENSTLEISTER: 'HEOS',
    WE_REGION: 'HEOS',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Eulenkamp 46',
    WE_PLZ: '22049',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.581203',
    WE_LÄNGENGRAD_OESTLICH: '10.070759',
    WE_ANZAHL_SCHUELER: 1327,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989259,
    WE_RGN_OBJ_ID_FKT: 5445906,
    WE_OM_OBJ_ID_FKT: 1800539,
    WE_EN_1_OBJ_ID_FKT: 2074827,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 705096,
    WE_SAP_WE_ID_PKF: '7000/705096',
    WE_SAP_WE_BEZEICHNUNG: 'Hermelinweg 8, 22159 Hamburg, 705096',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Hermelinweg 8',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.614514',
    WE_LÄNGENGRAD_OESTLICH: '10.121413',
    WE_ANZAHL_SCHUELER: 1665,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989370,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: 2049891,
    WE_EN_1_OBJ_ID_FKT: 2074816,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 705097,
    WE_SAP_WE_ID_PKF: '7000/705097',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Neusurenland 134, 22159 Hamburg beendet 16.06.16, 705097',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Neusurenland 134',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.615686',
    WE_LÄNGENGRAD_OESTLICH: '10.118043',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '16.06.16',
    WE_WE_OBJ_ID_PKT: 1989371,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074808,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 705098,
    WE_SAP_WE_ID_PKF: '7000/705098',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Stephanstra?e 15, 22047 Hamburg, beendet, 705098',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Stephanstra?e 15',
    WE_PLZ: '22047',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.583506',
    WE_LÄNGENGRAD_OESTLICH: '10.086989',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '02.06.16',
    WE_WE_OBJ_ID_PKT: 1989372,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074827,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 705095,
    WE_SAP_WE_ID_PKF: '7000/705095',
    WE_SAP_WE_BEZEICHNUNG: 'Gropiusring 43 T1, 22309 Hamburg, 705095',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Gropiusring 43 T1',
    WE_PLZ: '22309',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.613657',
    WE_LÄNGENGRAD_OESTLICH: '10.057613',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989369,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074839,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708004,
    WE_SAP_WE_ID_PKF: '7000/708004',
    WE_SAP_WE_BEZEICHNUNG: 'Berner Heerweg 183, 22159 Hamburg, 708004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Berner Heerweg 183',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236644,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7486811,
    WE_EN_1_OBJ_ID_FKT: 7251899,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708006,
    WE_SAP_WE_ID_PKF: '7000/708006',
    WE_SAP_WE_BEZEICHNUNG: 'Rolfinckstra?e 6a, 22391 Hamburg, 708006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wellingsb?ttel',
    WE_STRASSE_NR: 'Rolfinckstra?e 6a',
    WE_PLZ: '22391',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236646,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 5448333,
    WE_EN_1_OBJ_ID_FKT: 7251913,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708019,
    WE_SAP_WE_ID_PKF: '7000/708019',
    WE_SAP_WE_BEZEICHNUNG: 'Bredenbekstra?e 61, 22397 Hamburg, 708019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wohldorf-Ohlstedt',
    WE_STRASSE_NR: 'Bredenbekstra?e 61',
    WE_PLZ: '22397',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '11.06.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7236659,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7371755,
    WE_EN_1_OBJ_ID_FKT: 7251900,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705020,
    WE_SAP_WE_ID_PKF: '7000/705020',
    WE_SAP_WE_BEZEICHNUNG: 'Bergstedter Alte Landstra?e 12, 22395 Hamburg, 705020',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bergstedt',
    WE_STRASSE_NR: 'Bergstedter Alte Landstra?e 12',
    WE_PLZ: '22395',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.671566',
    WE_LÄNGENGRAD_OESTLICH: '10.132299',
    WE_ANZAHL_SCHUELER: 370,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989294,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074734,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705021,
    WE_SAP_WE_ID_PKF: '7000/705021',
    WE_SAP_WE_BEZEICHNUNG: 'Volksdorfer Damm 218, 22395 Hamburg, 705021',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bergstedt',
    WE_STRASSE_NR: 'Volksdorfer Damm 218',
    WE_PLZ: '22395',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.671767',
    WE_LÄNGENGRAD_OESTLICH: '10.136943',
    WE_ANZAHL_SCHUELER: 1112,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989295,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074739,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705027,
    WE_SAP_WE_ID_PKF: '7000/705027',
    WE_SAP_WE_BEZEICHNUNG: 'Volksdorfer Damm 74, 22359 Hamburg, 705027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bergstedt',
    WE_STRASSE_NR: 'Volksdorfer Damm 74',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.665429',
    WE_LÄNGENGRAD_OESTLICH: '10.148236',
    WE_ANZAHL_SCHUELER: 328,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989301,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074735,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705031,
    WE_SAP_WE_ID_PKF: '7000/705031',
    WE_SAP_WE_BEZEICHNUNG: 'Duvenstedter Markt 12, 22397 Hamburg, 705031',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Duvenstedt',
    WE_STRASSE_NR: 'Duvenstedter Markt 12',
    WE_PLZ: '22397',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.703283',
    WE_LÄNGENGRAD_OESTLICH: '10.107639',
    WE_ANZAHL_SCHUELER: 356,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989305,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074736,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705042,
    WE_SAP_WE_ID_PKF: '7000/705042',
    WE_SAP_WE_BEZEICHNUNG: 'Gr?tzm?hlenweg 38, 22339 Hamburg, 705042',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Hummelsb?ttel',
    WE_STRASSE_NR: 'Gr?tzm?hlenweg 38',
    WE_PLZ: '22339',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.646013',
    WE_LÄNGENGRAD_OESTLICH: '10.039599',
    WE_ANZAHL_SCHUELER: 426,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989316,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074723,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705051,
    WE_SAP_WE_ID_PKF: '7000/705051',
    WE_SAP_WE_BEZEICHNUNG: 'Hummelsb?ttler Hauptstra?e 107, 22339 Hamburg, 705051',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Hummelsb?ttel',
    WE_STRASSE_NR: 'Hummelsb?ttler Hauptstra?e 107',
    WE_PLZ: '22339',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.647196',
    WE_LÄNGENGRAD_OESTLICH: '10.039979',
    WE_ANZAHL_SCHUELER: 734,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989325,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074718,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705077,
    WE_SAP_WE_ID_PKF: '7000/705077',
    WE_SAP_WE_BEZEICHNUNG: 'Poppenb?ttler Stieg 7, 22339 Hamburg, 705077',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Hummelsb?ttel',
    WE_STRASSE_NR: 'Poppenb?ttler Stieg 7',
    WE_PLZ: '22339',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.645086',
    WE_LÄNGENGRAD_OESTLICH: '10.050639',
    WE_ANZAHL_SCHUELER: 378,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989351,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7360729,
    WE_EN_1_OBJ_ID_FKT: 2074728,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705057,
    WE_SAP_WE_ID_PKF: '7000/705057',
    WE_SAP_WE_BEZEICHNUNG: 'Redderbarg 46-48, 22397 Hamburg, 705057',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Lemsahl-Mellingstedt',
    WE_STRASSE_NR: 'Redderbarg 46-48',
    WE_PLZ: '22397',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.681606',
    WE_LÄNGENGRAD_OESTLICH: '10.102569',
    WE_ANZAHL_SCHUELER: 399,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989331,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074738,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705045,
    WE_SAP_WE_ID_PKF: '7000/705045',
    WE_SAP_WE_BEZEICHNUNG: 'Harksheider Stra?e 70, 22399 Hamburg, 705045',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Poppenb?ttel',
    WE_STRASSE_NR: 'Harksheider Stra?e 70',
    WE_PLZ: '22399',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.664603',
    WE_LÄNGENGRAD_OESTLICH: '10.073710',
    WE_ANZAHL_SCHUELER: 902,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989319,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 2074720,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705046,
    WE_SAP_WE_ID_PKF: '7000/705046',
    WE_SAP_WE_BEZEICHNUNG: 'Hinsbleek 14, 22391 Hamburg, 705046',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Poppenb?ttel',
    WE_STRASSE_NR: 'Hinsbleek 14',
    WE_PLZ: '22391',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.650533',
    WE_LÄNGENGRAD_OESTLICH: '10.074019',
    WE_ANZAHL_SCHUELER: 326,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989320,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7360729,
    WE_EN_1_OBJ_ID_FKT: 2074724,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705028,
    WE_SAP_WE_ID_PKF: '7000/705028',
    WE_SAP_WE_BEZEICHNUNG: 'M?ssenredder 59, 22399 Hamburg, 705028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Poppenb?ttel',
    WE_STRASSE_NR: 'M?ssenredder 59',
    WE_PLZ: '22399',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.655389',
    WE_LÄNGENGRAD_OESTLICH: '10.067248',
    WE_ANZAHL_SCHUELER: 951,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989302,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074715,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705063,
    WE_SAP_WE_ID_PKF: '7000/705063',
    WE_SAP_WE_BEZEICHNUNG: 'M?ssenredder 61, 22399 Hamburg, 705063',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Poppenb?ttel',
    WE_STRASSE_NR: 'M?ssenredder 61',
    WE_PLZ: '22399',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.655398',
    WE_LÄNGENGRAD_OESTLICH: '10.066743',
    WE_ANZAHL_SCHUELER: 464,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989337,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074725,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705076,
    WE_SAP_WE_ID_PKF: '7000/705076',
    WE_SAP_WE_BEZEICHNUNG: 'Schulbergredder 13-21, 22399 Hamburg, 705076',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Poppenb?ttel',
    WE_STRASSE_NR: 'Schulbergredder 13-21',
    WE_PLZ: '22399',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.657899',
    WE_LÄNGENGRAD_OESTLICH: '10.081654',
    WE_ANZAHL_SCHUELER: 976,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989350,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7360729,
    WE_EN_1_OBJ_ID_FKT: 2074728,
    WE_EN_2_OBJ_ID_FKT: 2074717,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705080,
    WE_SAP_WE_ID_PKF: '7000/705080',
    WE_SAP_WE_BEZEICHNUNG: 'Ahrenshooper Stra?e 1-3, 22147 Hamburg, 705080',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Ahrenshooper Stra?e 1-3',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600513',
    WE_LÄNGENGRAD_OESTLICH: '10.131769',
    WE_ANZAHL_SCHUELER: 415,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989354,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074757,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705018,
    WE_SAP_WE_ID_PKF: '7000/705018',
    WE_SAP_WE_BEZEICHNUNG: 'Bekassinenau 32, 22147 Hamburg, 705018',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Bekassinenau 32',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.612807',
    WE_LÄNGENGRAD_OESTLICH: '10.137901',
    WE_ANZAHL_SCHUELER: 433,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989292,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 2074750,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705069,
    WE_SAP_WE_ID_PKF: '7000/705069',
    WE_SAP_WE_BEZEICHNUNG: 'Birrenkovenallee 12, 22143 Hamburg, 705069',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Birrenkovenallee 12',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.611174',
    WE_LÄNGENGRAD_OESTLICH: '10.158742',
    WE_ANZAHL_SCHUELER: 894,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989343,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074747,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705004,
    WE_SAP_WE_ID_PKF: '7000/705004',
    WE_SAP_WE_BEZEICHNUNG: 'Brockdorffstra?e 2, 22149 Hamburg, 705004',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Brockdorffstra?e 2',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.596381',
    WE_LÄNGENGRAD_OESTLICH: '10.152767',
    WE_ANZAHL_SCHUELER: 116,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989278,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074760,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705025,
    WE_SAP_WE_ID_PKF: '7000/705025',
    WE_SAP_WE_BEZEICHNUNG: 'Brockdorffstra?e 64, 22149 Hamburg, 705025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Brockdorffstra?e 64',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.596313',
    WE_LÄNGENGRAD_OESTLICH: '10.163489',
    WE_ANZAHL_SCHUELER: 451,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989299,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074752,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705008,
    WE_SAP_WE_ID_PKF: '7000/705008',
    WE_SAP_WE_BEZEICHNUNG: 'Brunskamp 17, 22149 Hamburg, 705008',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Brunskamp 17',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.593923',
    WE_LÄNGENGRAD_OESTLICH: '10.181508',
    WE_ANZAHL_SCHUELER: 227,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989282,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074749,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705060,
    WE_SAP_WE_ID_PKF: '7000/705060',
    WE_SAP_WE_BEZEICHNUNG: 'Deepenhorn 1, 22145 Hamburg, 705060',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Deepenhorn 1',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.628904',
    WE_LÄNGENGRAD_OESTLICH: '10.164985',
    WE_ANZAHL_SCHUELER: 707,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989334,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074761,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705070,
    WE_SAP_WE_ID_PKF: '7000/705070',
    WE_SAP_WE_BEZEICHNUNG: 'Delingsdorfer Weg 6, 22143 Hamburg, 705070',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Delingsdorfer Weg 6',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.611967',
    WE_LÄNGENGRAD_OESTLICH: '10.157978',
    WE_ANZAHL_SCHUELER: 962,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989344,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074762,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705041,
    WE_SAP_WE_ID_PKF: '7000/705041',
    WE_SAP_WE_BEZEICHNUNG: 'Gro?lohering 11, 22143 Hamburg, 705041',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Gro?lohering 11',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600817',
    WE_LÄNGENGRAD_OESTLICH: '10.178070',
    WE_ANZAHL_SCHUELER: 217,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989315,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074743,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705003,
    WE_SAP_WE_ID_PKF: '7000/705003',
    WE_SAP_WE_BEZEICHNUNG: 'H?llenkamp 19, 22149 Hamburg, 705003',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'H?llenkamp 19',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.593870',
    WE_LÄNGENGRAD_OESTLICH: '10.151931',
    WE_ANZAHL_SCHUELER: 674,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989277,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074760,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705061,
    WE_SAP_WE_ID_PKF: '7000/705061',
    WE_SAP_WE_BEZEICHNUNG: 'Islandstra?e 25, 22145 Hamburg, 705061',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Islandstra?e 25',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.628896',
    WE_LÄNGENGRAD_OESTLICH: '10.160468',
    WE_ANZAHL_SCHUELER: 471,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989335,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 2074755,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705055,
    WE_SAP_WE_ID_PKF: '7000/705055',
    WE_SAP_WE_BEZEICHNUNG: 'Kamminer Stra?e 4, 22147 Hamburg, 705055',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Kamminer Stra?e 4',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.618383',
    WE_LÄNGENGRAD_OESTLICH: '10.141239',
    WE_ANZAHL_SCHUELER: 336,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989329,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074753,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705056,
    WE_SAP_WE_ID_PKF: '7000/705056',
    WE_SAP_WE_BEZEICHNUNG: 'Kielkoppelstra?e 16 d, 22149 Hamburg, 705056',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Kielkoppelstra?e 16 d',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.587926',
    WE_LÄNGENGRAD_OESTLICH: '10.169429',
    WE_ANZAHL_SCHUELER: 239,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989330,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074760,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705065,
    WE_SAP_WE_ID_PKF: '7000/705065',
    WE_SAP_WE_BEZEICHNUNG: 'Nydamer Weg 44, 22145 Hamburg, 705065',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Nydamer Weg 44',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.617003',
    WE_LÄNGENGRAD_OESTLICH: '10.154389',
    WE_ANZAHL_SCHUELER: 427,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989339,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074745,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705075,
    WE_SAP_WE_ID_PKF: '7000/705075',
    WE_SAP_WE_BEZEICHNUNG: 'Paracelsusstra?e 30, 22147 Hamburg, 705075',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Paracelsusstra?e 30',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.597654',
    WE_LÄNGENGRAD_OESTLICH: '10.135817',
    WE_ANZAHL_SCHUELER: 126,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989349,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074756,
    WE_EN_2_OBJ_ID_FKT: 2074712,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705064,
    WE_SAP_WE_ID_PKF: '7000/705064',
    WE_SAP_WE_BEZEICHNUNG: 'Rahlstedter Stra?e 190, 22143 Hamburg, 705064',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Rahlstedter Stra?e 190',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.604914',
    WE_LÄNGENGRAD_OESTLICH: '10.166074',
    WE_ANZAHL_SCHUELER: 502,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989338,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800545,
    WE_EN_1_OBJ_ID_FKT: 2074744,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705079,
    WE_SAP_WE_ID_PKF: '7000/705079',
    WE_SAP_WE_BEZEICHNUNG: 'Scharbeutzer Stra?e 36, 22147 Hamburg, 705079',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Scharbeutzer Stra?e 36',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.600543',
    WE_LÄNGENGRAD_OESTLICH: '10.145105',
    WE_ANZAHL_SCHUELER: 1082,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989353,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074748,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705101,
    WE_SAP_WE_ID_PKF: '7000/705101',
    WE_SAP_WE_BEZEICHNUNG: 'Schierenberg 50, 22145 Hamburg, 705101',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Schierenberg 50',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.624803',
    WE_LÄNGENGRAD_OESTLICH: '10.149389',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989375,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074746,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705062,
    WE_SAP_WE_ID_PKF: '7000/705062',
    WE_SAP_WE_BEZEICHNUNG: 'Schierenberg 60, 22145 Hamburg, 705062',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Schierenberg 60',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.624866',
    WE_LÄNGENGRAD_OESTLICH: '10.151219',
    WE_ANZAHL_SCHUELER: 945,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989336,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074746,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705082,
    WE_SAP_WE_ID_PKF: '7000/705082',
    WE_SAP_WE_BEZEICHNUNG: 'Sieker Landstra?e 18, 22143 Hamburg, 705082',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Sieker Landstra?e 18',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.606526',
    WE_LÄNGENGRAD_OESTLICH: '10.170669',
    WE_ANZAHL_SCHUELER: 84,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989356,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 5305178,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705103,
    WE_SAP_WE_ID_PKF: '7000/705103',
    WE_SAP_WE_BEZEICHNUNG: 'Stapelfelder Stra?e 7, 22143 Hamburg, 705103',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Stapelfelder Stra?e 7',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.605672',
    WE_LÄNGENGRAD_OESTLICH: '10.167858',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989376,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800545,
    WE_EN_1_OBJ_ID_FKT: 2074744,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705093,
    WE_SAP_WE_ID_PKF: '7000/705093',
    WE_SAP_WE_BEZEICHNUNG: 'Wildschwanbrook 9, 22145 Hamburg, 705093',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Wildschwanbrook 9',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.627655',
    WE_LÄNGENGRAD_OESTLICH: '10.171738',
    WE_ANZAHL_SCHUELER: 262,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989367,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800471,
    WE_EN_1_OBJ_ID_FKT: 2074759,
    WE_EN_2_OBJ_ID_FKT: 5305178,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705066,
    WE_SAP_WE_ID_PKF: '7000/705066',
    WE_SAP_WE_BEZEICHNUNG: 'Alsterredder 26, 22395 Hamburg, 705066',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Sasel',
    WE_STRASSE_NR: 'Alsterredder 26',
    WE_PLZ: '22395',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.659003',
    WE_LÄNGENGRAD_OESTLICH: '10.101009',
    WE_ANZAHL_SCHUELER: 862,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989340,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074719,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705002,
    WE_SAP_WE_ID_PKF: '7000/705002',
    WE_SAP_WE_BEZEICHNUNG: 'Alsterredder 28, 22395 Hamburg, 705002',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Sasel',
    WE_STRASSE_NR: 'Alsterredder 28',
    WE_PLZ: '22395',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.658205',
    WE_LÄNGENGRAD_OESTLICH: '10.100355',
    WE_ANZAHL_SCHUELER: 536,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989276,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074722,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705043,
    WE_SAP_WE_ID_PKF: '7000/705043',
    WE_SAP_WE_BEZEICHNUNG: 'Hasenweg 40, 22393 Hamburg, 705043',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Sasel',
    WE_STRASSE_NR: 'Hasenweg 40',
    WE_PLZ: '22393',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.638366',
    WE_LÄNGENGRAD_OESTLICH: '10.118569',
    WE_ANZAHL_SCHUELER: 472,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989317,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074716,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705081,
    WE_SAP_WE_ID_PKF: '7000/705081',
    WE_SAP_WE_BEZEICHNUNG: 'Redder 4, 22393 Hamburg, 705081',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Sasel',
    WE_STRASSE_NR: 'Redder 4',
    WE_PLZ: '22393',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.651025',
    WE_LÄNGENGRAD_OESTLICH: '10.110098',
    WE_ANZAHL_SCHUELER: 478,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989355,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074726,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705001,
    WE_SAP_WE_ID_PKF: '7000/705001',
    WE_SAP_WE_BEZEICHNUNG: 'Ahrensburger Weg 12, 22359 Hamburg, 705001',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Ahrensburger Weg 12',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.653719',
    WE_LÄNGENGRAD_OESTLICH: '10.172769',
    WE_ANZAHL_SCHUELER: 445,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989275,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5347032,
    WE_EN_1_OBJ_ID_FKT: 2074731,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705090,
    WE_SAP_WE_ID_PKF: '7000/705090',
    WE_SAP_WE_BEZEICHNUNG: 'Ahrensburger Weg 30, 22359 Hamburg, 705090',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Ahrensburger Weg 30',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.653324',
    WE_LÄNGENGRAD_OESTLICH: '10.172957',
    WE_ANZAHL_SCHUELER: 922,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989364,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074740,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705037,
    WE_SAP_WE_ID_PKF: '7000/705037',
    WE_SAP_WE_BEZEICHNUNG: 'Eulenkrugstra?e 166, 22359 Hamburg, 705037',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Eulenkrugstra?e 166',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.645413',
    WE_LÄNGENGRAD_OESTLICH: '10.183479',
    WE_ANZAHL_SCHUELER: 331,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989311,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5361199,
    WE_EN_1_OBJ_ID_FKT: 2074737,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705092,
    WE_SAP_WE_ID_PKF: '7000/705092',
    WE_SAP_WE_BEZEICHNUNG: 'Im Allhorn 45, 22359 Hamburg, 705092',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Im Allhorn 45',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.651805',
    WE_LÄNGENGRAD_OESTLICH: '10.173651',
    WE_ANZAHL_SCHUELER: 1230,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989366,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074741,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705026,
    WE_SAP_WE_ID_PKF: '7000/705026',
    WE_SAP_WE_BEZEICHNUNG: 'Im Regestall 25, 22359 Hamburg, 705026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Im Regestall 25',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.664680',
    WE_LÄNGENGRAD_OESTLICH: '10.150979',
    WE_ANZAHL_SCHUELER: 1022,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989300,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074729,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705010,
    WE_SAP_WE_ID_PKF: '7000/705010',
    WE_SAP_WE_BEZEICHNUNG: 'Saseler Weg 30, 22359 Hamburg, 705010',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'Saseler Weg 30',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.649211',
    WE_LÄNGENGRAD_OESTLICH: '10.148791',
    WE_ANZAHL_SCHUELER: 388,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989284,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074733,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705091,
    WE_SAP_WE_ID_PKF: '7000/705091',
    WE_SAP_WE_BEZEICHNUNG: 'V?rn Barkholt 6, 22359 Hamburg, 705091',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Volksdorf',
    WE_STRASSE_NR: 'V?rn Barkholt 6',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.652173',
    WE_LÄNGENGRAD_OESTLICH: '10.166599',
    WE_ANZAHL_SCHUELER: 248,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989365,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7198474,
    WE_EN_1_OBJ_ID_FKT: 2074740,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705052,
    WE_SAP_WE_ID_PKF: '7000/705052',
    WE_SAP_WE_BEZEICHNUNG: 'Am Pfeilshof 20, 22393 Hamburg, 705052',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wellingsb?ttel',
    WE_STRASSE_NR: 'Am Pfeilshof 20',
    WE_PLZ: '22393',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.639865',
    WE_LÄNGENGRAD_OESTLICH: '10.103546',
    WE_ANZAHL_SCHUELER: 1261,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989326,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 2074721,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705083,
    WE_SAP_WE_ID_PKF: '7000/705083',
    WE_SAP_WE_BEZEICHNUNG: 'Strenge 5, 22391 Hamburg, 705083',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wellingsb?ttel',
    WE_STRASSE_NR: 'Strenge 5',
    WE_PLZ: '22391',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.637177',
    WE_LÄNGENGRAD_OESTLICH: '10.078588',
    WE_ANZAHL_SCHUELER: 434,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989357,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 5924350,
    WE_EN_1_OBJ_ID_FKT: 2074727,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705009,
    WE_SAP_WE_ID_PKF: '7000/705009',
    WE_SAP_WE_BEZEICHNUNG: 'Kupferredder 12, 22397 Hamburg, 705009',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wohldorf-Ohlstedt',
    WE_STRASSE_NR: 'Kupferredder 12',
    WE_PLZ: '22397',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.697536',
    WE_LÄNGENGRAD_OESTLICH: '10.136019',
    WE_ANZAHL_SCHUELER: 231,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989283,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 7360729,
    WE_EN_1_OBJ_ID_FKT: 2074732,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705067,
    WE_SAP_WE_ID_PKF: '7000/705067',
    WE_SAP_WE_BEZEICHNUNG: 'Sthamerstra?e 55, 22397 Hamburg, 705067',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wohldorf-Ohlstedt',
    WE_STRASSE_NR: 'Sthamerstra?e 55',
    WE_PLZ: '22397',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.692623',
    WE_LÄNGENGRAD_OESTLICH: '10.142698',
    WE_ANZAHL_SCHUELER: 709,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989341,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 2074730,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705040,
    WE_SAP_WE_ID_PKF: '7000/705040',
    WE_SAP_WE_BEZEICHNUNG: 'Am Damm 47, 22175 Hamburg, 705040',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Am Damm 47',
    WE_PLZ: '22175',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.630123',
    WE_LÄNGENGRAD_OESTLICH: '10.097169',
    WE_ANZAHL_SCHUELER: 1199,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989314,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074704,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705023,
    WE_SAP_WE_ID_PKF: '7000/705023',
    WE_SAP_WE_BEZEICHNUNG: 'Bramfelder Dorfplatz 5, 22179 Hamburg, 705023',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Bramfelder Dorfplatz 5',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.614163',
    WE_LÄNGENGRAD_OESTLICH: '10.077529',
    WE_ANZAHL_SCHUELER: 487,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989297,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074714,
    WE_EN_2_OBJ_ID_FKT: 2074699,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705034,
    WE_SAP_WE_ID_PKF: '7000/705034',
    WE_SAP_WE_BEZEICHNUNG: 'Eenstock 15, 22179 Hamburg, 705034',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Eenstock 15',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.605196',
    WE_LÄNGENGRAD_OESTLICH: '10.094389',
    WE_ANZAHL_SCHUELER: 282,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989308,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074709,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705013,
    WE_SAP_WE_ID_PKF: '7000/705013',
    WE_SAP_WE_BEZEICHNUNG: 'Fabriciusstra?e 150, 22177 Hamburg, 705013',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Fabriciusstra?e 150',
    WE_PLZ: '22177',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.605819',
    WE_LÄNGENGRAD_OESTLICH: '10.066789',
    WE_ANZAHL_SCHUELER: 304,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989287,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 7361061,
    WE_EN_2_OBJ_ID_FKT: 5305179,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705038,
    WE_SAP_WE_ID_PKF: '7000/705038',
    WE_SAP_WE_BEZEICHNUNG: 'Fahrenkr?n 115, 22179 Hamburg, 705038',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Fahrenkr?n 115',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.616228',
    WE_LÄNGENGRAD_OESTLICH: '10.097690',
    WE_ANZAHL_SCHUELER: 430,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989312,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2074710,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705024,
    WE_SAP_WE_ID_PKF: '7000/705024',
    WE_SAP_WE_BEZEICHNUNG: 'Hegholt 44, 22179 Hamburg, 705024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Hegholt 44',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.606049',
    WE_LÄNGENGRAD_OESTLICH: '10.081159',
    WE_ANZAHL_SCHUELER: 543,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989298,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074699,
    WE_EN_2_OBJ_ID_FKT: 2074714,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705044,
    WE_SAP_WE_ID_PKF: '7000/705044',
    WE_SAP_WE_BEZEICHNUNG: 'Heidst?cken 33, 22179 Hamburg, 705044',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Heidst?cken 33',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.615930',
    WE_LÄNGENGRAD_OESTLICH: '10.091998',
    WE_ANZAHL_SCHUELER: 78,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989318,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 5305179,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705014,
    WE_SAP_WE_ID_PKF: '7000/705014',
    WE_SAP_WE_BEZEICHNUNG: 'Heinrich-Helbing-Stra?e 50, 22177 Hamburg, 705014',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Heinrich-Helbing-Stra?e 50',
    WE_PLZ: '22177',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.597263',
    WE_LÄNGENGRAD_OESTLICH: '10.056842',
    WE_ANZAHL_SCHUELER: 332,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989288,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074707,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705015,
    WE_SAP_WE_ID_PKF: '7000/705015',
    WE_SAP_WE_BEZEICHNUNG: 'Hohnerkamp 58, 22175 Hamburg, 705015',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Hohnerkamp 58',
    WE_PLZ: '22175',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.624206',
    WE_LÄNGENGRAD_OESTLICH: '10.092479',
    WE_ANZAHL_SCHUELER: 209,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989289,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 5305178,
    WE_EN_2_OBJ_ID_FKT: 2074702,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705054,
    WE_SAP_WE_ID_PKF: '7000/705054',
    WE_SAP_WE_BEZEICHNUNG: 'H?hnkoppelort 24, 22179 Hamburg, 705054',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'H?hnkoppelort 24',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.612800',
    WE_LÄNGENGRAD_OESTLICH: '10.082802',
    WE_ANZAHL_SCHUELER: 639,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989328,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074706,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705047,
    WE_SAP_WE_ID_PKF: '7000/705047',
    WE_SAP_WE_BEZEICHNUNG: 'Thomas-Mann-Stra?e 2, 22175 Hamburg, 705047',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Thomas-Mann-Stra?e 2',
    WE_PLZ: '22175',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.627093',
    WE_LÄNGENGRAD_OESTLICH: '10.111639',
    WE_ANZAHL_SCHUELER: 306,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989321,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074702,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705072,
    WE_SAP_WE_ID_PKF: '7000/705072',
    WE_SAP_WE_BEZEICHNUNG: 'Turnierstieg 24, 22179 Hamburg, 705072',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Bramfeld',
    WE_STRASSE_NR: 'Turnierstieg 24',
    WE_PLZ: '22179',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.599803',
    WE_LÄNGENGRAD_OESTLICH: '10.091379',
    WE_ANZAHL_SCHUELER: 565,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989346,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074705,
    WE_EN_2_OBJ_ID_FKT: 7538881,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705035,
    WE_SAP_WE_ID_PKF: '7000/705035',
    WE_SAP_WE_BEZEICHNUNG: 'An der Berner Au 12, 22159 Hamburg, 705035',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'An der Berner Au 12',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.617209',
    WE_LÄNGENGRAD_OESTLICH: '10.121073',
    WE_ANZAHL_SCHUELER: 595,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989309,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2076328,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705100,
    WE_SAP_WE_ID_PKF: '7000/705100',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Berner Heerweg 183, 22159 Hamburg, 705100',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Berner Heerweg 183',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.607205',
    WE_LÄNGENGRAD_OESTLICH: '10.119746',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.07.21',
    WE_WE_OBJ_ID_PKT: 1989374,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 5423275,
    WE_EN_1_OBJ_ID_FKT: 2076328,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705032,
    WE_SAP_WE_ID_PKF: '7000/705032',
    WE_SAP_WE_BEZEICHNUNG: 'Berner Heerweg 99, 22159 Hamburg, 705032',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Berner Heerweg 99',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.599103',
    WE_LÄNGENGRAD_OESTLICH: '10.117479',
    WE_ANZAHL_SCHUELER: 431,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989306,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074700,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705084,
    WE_SAP_WE_ID_PKF: '7000/705084',
    WE_SAP_WE_BEZEICHNUNG: 'Bramfelder Weg 121, 22159 Hamburg, 705084',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Bramfelder Weg 121',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.613712',
    WE_LÄNGENGRAD_OESTLICH: '10.107284',
    WE_ANZAHL_SCHUELER: 296,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989358,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074711,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705089,
    WE_SAP_WE_ID_PKF: '7000/705089',
    WE_SAP_WE_BEZEICHNUNG: 'Eckerkoppel 125, 22159 Hamburg, 705089',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Eckerkoppel 125',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.597233',
    WE_LÄNGENGRAD_OESTLICH: '10.101019',
    WE_ANZAHL_SCHUELER: 276,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989363,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093623,
    WE_EN_1_OBJ_ID_FKT: 2074713,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705036,
    WE_SAP_WE_ID_PKF: '7000/705036',
    WE_SAP_WE_BEZEICHNUNG: 'Hermelinweg 10, 22159 Hamburg, 705036',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Hermelinweg 10',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.614226',
    WE_LÄNGENGRAD_OESTLICH: '10.121143',
    WE_ANZAHL_SCHUELER: 779,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989310,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2076328,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705048,
    WE_SAP_WE_ID_PKF: '7000/705048',
    WE_SAP_WE_BEZEICHNUNG: 'Lienaustra?e 32, 22159 Hamburg, 705048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Lienaustra?e 32',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.629506',
    WE_LÄNGENGRAD_OESTLICH: '10.125190',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989322,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074702,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705039,
    WE_SAP_WE_ID_PKF: '7000/705039',
    WE_SAP_WE_BEZEICHNUNG: 'Swebenh?he 50, 22159 Hamburg, 705039',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Swebenh?he 50',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.612120',
    WE_LÄNGENGRAD_OESTLICH: '10.109185',
    WE_ANZAHL_SCHUELER: 800,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989313,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074703,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705085,
    WE_SAP_WE_ID_PKF: '7000/705085',
    WE_SAP_WE_BEZEICHNUNG: 'Tegelweg 104, 22159 Hamburg, 705085',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Farmsen-Berne',
    WE_STRASSE_NR: 'Tegelweg 104',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.601218',
    WE_LÄNGENGRAD_OESTLICH: '10.108285',
    WE_ANZAHL_SCHUELER: 148,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989359,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074712,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705019,
    WE_SAP_WE_ID_PKF: '7000/705019',
    WE_SAP_WE_BEZEICHNUNG: 'Bekkamp 52, 22045 Hamburg, 705019',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Bekkamp 52',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575303',
    WE_LÄNGENGRAD_OESTLICH: '10.141691',
    WE_ANZAHL_SCHUELER: 168,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989293,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074751,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705029,
    WE_SAP_WE_ID_PKF: '7000/705029',
    WE_SAP_WE_BEZEICHNUNG: 'Charlottenburger Stra?e 84, 22045 Hamburg, 705029',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Charlottenburger Stra?e 84',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.586867',
    WE_LÄNGENGRAD_OESTLICH: '10.141679',
    WE_ANZAHL_SCHUELER: 318,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989303,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074691,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705074,
    WE_SAP_WE_ID_PKF: '7000/705074',
    WE_SAP_WE_BEZEICHNUNG: 'Denksteinweg 17, 22043 Hamburg, 705074',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Denksteinweg 17',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571920',
    WE_LÄNGENGRAD_OESTLICH: '10.132076',
    WE_ANZAHL_SCHUELER: 457,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989348,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074697,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705073,
    WE_SAP_WE_ID_PKF: '7000/705073',
    WE_SAP_WE_BEZEICHNUNG: 'Jenfelder Allee 53, 22043 Hamburg, 705073',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Jenfelder Allee 53',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.576473',
    WE_LÄNGENGRAD_OESTLICH: '10.127879',
    WE_ANZAHL_SCHUELER: 1327,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989347,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074686,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705053,
    WE_SAP_WE_ID_PKF: '7000/705053',
    WE_SAP_WE_BEZEICHNUNG: 'Jenfelder Stra?e 252, 22045 Hamburg, 705053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Jenfelder Stra?e 252',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.575200',
    WE_LÄNGENGRAD_OESTLICH: '10.133093',
    WE_ANZAHL_SCHUELER: 344,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989327,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074692,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705068,
    WE_SAP_WE_ID_PKF: '7000/705068',
    WE_SAP_WE_BEZEICHNUNG: '?jendorfer Damm 8, 22043 Hamburg, 705068',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: '?jendorfer Damm 8',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567952',
    WE_LÄNGENGRAD_OESTLICH: '10.133908',
    WE_ANZAHL_SCHUELER: 280,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989342,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074693,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705071,
    WE_SAP_WE_ID_PKF: '7000/705071',
    WE_SAP_WE_BEZEICHNUNG: 'Oppelner Stra?e 45, 22045 Hamburg, 705071',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Jenfeld',
    WE_STRASSE_NR: 'Oppelner Stra?e 45',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.573393',
    WE_LÄNGENGRAD_OESTLICH: '10.144329',
    WE_ANZAHL_SCHUELER: 222,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989345,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074694,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705094,
    WE_SAP_WE_ID_PKF: '7000/705094',
    WE_SAP_WE_BEZEICHNUNG: 'Hammer Stra?e 124, 22043 Hamburg, 705094',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Marienthal',
    WE_STRASSE_NR: 'Hammer Stra?e 124',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.563211',
    WE_LÄNGENGRAD_OESTLICH: '10.066862',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989368,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 7493633,
    WE_EN_2_OBJ_ID_FKT: 7493634,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705058,
    WE_SAP_WE_ID_PKF: '7000/705058',
    WE_SAP_WE_BEZEICHNUNG: 'Holstenhofweg 86, 22043 Hamburg, 705058',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Marienthal',
    WE_STRASSE_NR: 'Holstenhofweg 86',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566050',
    WE_LÄNGENGRAD_OESTLICH: '10.105707',
    WE_ANZAHL_SCHUELER: 917,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989332,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074684,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705049,
    WE_SAP_WE_ID_PKF: '7000/705049',
    WE_SAP_WE_BEZEICHNUNG: 'Oktaviostra?e 143, 22043 Hamburg, 705049',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Marienthal',
    WE_STRASSE_NR: 'Oktaviostra?e 143',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.567063',
    WE_LÄNGENGRAD_OESTLICH: '10.103709',
    WE_ANZAHL_SCHUELER: 700,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989323,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074697,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705050,
    WE_SAP_WE_ID_PKF: '7000/705050',
    WE_SAP_WE_BEZEICHNUNG: 'Schimmelmannstra?e 70, 22043 Hamburg, 705050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Marienthal',
    WE_STRASSE_NR: 'Schimmelmannstra?e 70',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.571466',
    WE_LÄNGENGRAD_OESTLICH: '10.102449',
    WE_ANZAHL_SCHUELER: 532,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989324,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074682,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705099,
    WE_SAP_WE_ID_PKF: '7000/705099',
    WE_SAP_WE_BEZEICHNUNG: 'Zitzewitzstra?e 51, 22043 Hamburg, 705099',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Marienthal',
    WE_STRASSE_NR: 'Zitzewitzstra?e 51',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.562276',
    WE_LÄNGENGRAD_OESTLICH: '10.073419',
    WE_ANZAHL_SCHUELER: 75,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989373,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 5305179,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705078,
    WE_SAP_WE_ID_PKF: '7000/705078',
    WE_SAP_WE_BEZEICHNUNG: 'Potsdamer Stra?e 6, 22149 Hamburg, 705078',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Potsdamer Stra?e 6',
    WE_PLZ: '22149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.584403',
    WE_LÄNGENGRAD_OESTLICH: '10.150479',
    WE_ANZAHL_SCHUELER: 255,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989352,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: 2074695,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705016,
    WE_SAP_WE_ID_PKF: '7000/705016',
    WE_SAP_WE_BEZEICHNUNG: 'Appelhoff 2, 22309 Hamburg, 705016',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Steilshoop',
    WE_STRASSE_NR: 'Appelhoff 2',
    WE_PLZ: '22309',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.606377',
    WE_LÄNGENGRAD_OESTLICH: '10.058679',
    WE_ANZAHL_SCHUELER: 256,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989290,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074708,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705007,
    WE_SAP_WE_ID_PKF: '7000/705007',
    WE_SAP_WE_BEZEICHNUNG: 'Borchertring 38, 22309 Hamburg AGV, 705007',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Steilshoop',
    WE_STRASSE_NR: 'Borchertring 38',
    WE_PLZ: '22309',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.617523',
    WE_LÄNGENGRAD_OESTLICH: '10.062509',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989281,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2076327,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705033,
    WE_SAP_WE_ID_PKF: '7000/705033',
    WE_SAP_WE_BEZEICHNUNG: 'Edwin-Scharff-Ring 56, 22309 Hamburg, 705033',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Steilshoop',
    WE_STRASSE_NR: 'Edwin-Scharff-Ring 56',
    WE_PLZ: '22309',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.615493',
    WE_LÄNGENGRAD_OESTLICH: '10.048209',
    WE_ANZAHL_SCHUELER: 267,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989307,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2074701,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705104,
    WE_SAP_WE_ID_PKF: '7000/705104',
    WE_SAP_WE_BEZEICHNUNG: 'Gropiusring 43, 22309 Hamburg, 705104',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Steilshoop',
    WE_STRASSE_NR: 'Gropiusring 43',
    WE_PLZ: '22309',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.613657',
    WE_LÄNGENGRAD_OESTLICH: '10.057613',
    WE_ANZAHL_SCHUELER: 820,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2862424,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800410,
    WE_EN_1_OBJ_ID_FKT: 2076327,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705086,
    WE_SAP_WE_ID_PKF: '7000/705086',
    WE_SAP_WE_BEZEICHNUNG: 'Barenkrug 16, 22159 Hamburg, 705086',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Tonndorf',
    WE_STRASSE_NR: 'Barenkrug 16',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.594274',
    WE_LÄNGENGRAD_OESTLICH: '10.125341',
    WE_ANZAHL_SCHUELER: 1567,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989360,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2076329,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705088,
    WE_SAP_WE_ID_PKF: '7000/705088',
    WE_SAP_WE_BEZEICHNUNG: 'Rahlaukamp 1 a, 22045 Hamburg, 705088',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Tonndorf',
    WE_STRASSE_NR: 'Rahlaukamp 1 a',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.585979',
    WE_LÄNGENGRAD_OESTLICH: '10.128487',
    WE_ANZAHL_SCHUELER: 221,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989362,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2074683,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705087,
    WE_SAP_WE_ID_PKF: '7000/705087',
    WE_SAP_WE_BEZEICHNUNG: 'Sonnenweg 90, 22045 Hamburg, 705087',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Tonndorf',
    WE_STRASSE_NR: 'Sonnenweg 90',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.594171',
    WE_LÄNGENGRAD_OESTLICH: '10.122073',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989361,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6796417,
    WE_EN_1_OBJ_ID_FKT: 2076329,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705017,
    WE_SAP_WE_ID_PKF: '7000/705017',
    WE_SAP_WE_BEZEICHNUNG: 'Bandwirkerstra?e 56-58, 22041 Hamburg, 705017',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Bandwirkerstra?e 56-58',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.576116',
    WE_LÄNGENGRAD_OESTLICH: '10.066629',
    WE_ANZAHL_SCHUELER: 236,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989291,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074689,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705022,
    WE_SAP_WE_ID_PKF: '7000/705022',
    WE_SAP_WE_BEZEICHNUNG: 'Bovestra?e 10-12, 22041 Hamburg, 705022',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Bovestra?e 10-12',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572625',
    WE_LÄNGENGRAD_OESTLICH: '10.078888',
    WE_ANZAHL_SCHUELER: 409,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989296,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074690,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705030,
    WE_SAP_WE_ID_PKF: '7000/705030',
    WE_SAP_WE_BEZEICHNUNG: 'Neumann-Reichardt-Stra?e 20, 22041 Hamburg, 705030',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Neumann-Reichardt-Stra?e 20',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572482',
    WE_LÄNGENGRAD_OESTLICH: '10.078505',
    WE_ANZAHL_SCHUELER: 874,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989304,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7085574,
    WE_EN_1_OBJ_ID_FKT: 2074681,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705012,
    WE_SAP_WE_ID_PKF: '7000/705012',
    WE_SAP_WE_BEZEICHNUNG: 'Stephanstra?e 103, 22047 Hamburg, 705012',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Stephanstra?e 103',
    WE_PLZ: '22047',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.589506',
    WE_LÄNGENGRAD_OESTLICH: '10.085079',
    WE_ANZAHL_SCHUELER: 535,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989286,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074688,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705006,
    WE_SAP_WE_ID_PKF: '7000/705006',
    WE_SAP_WE_BEZEICHNUNG: 'Waldd?rferstra?e 243, 22047 Hamburg, 705006',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Waldd?rferstra?e 243',
    WE_PLZ: '22047',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.582206',
    WE_LÄNGENGRAD_OESTLICH: '10.095069',
    WE_ANZAHL_SCHUELER: 312,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989280,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074687,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705005,
    WE_SAP_WE_ID_PKF: '7000/705005',
    WE_SAP_WE_BEZEICHNUNG: 'Waldd?rferstra?e 91, 22041 Hamburg, 705005',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Waldd?rferstra?e 91',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.579376',
    WE_LÄNGENGRAD_OESTLICH: '10.075971',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989279,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074687,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705059,
    WE_SAP_WE_ID_PKF: '7000/705059',
    WE_SAP_WE_BEZEICHNUNG: 'Witth?fftstra?e 8, 22041 Hamburg, 705059',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Wandsbek',
    WE_STRASSE_NR: 'Witth?fftstra?e 8',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.572763',
    WE_LÄNGENGRAD_OESTLICH: '10.072802',
    WE_ANZAHL_SCHUELER: 960,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989333,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 7093624,
    WE_EN_1_OBJ_ID_FKT: 2074685,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702083,
    WE_SAP_WE_ID_PKF: '7000/702083',
    WE_SAP_WE_BEZEICHNUNG: 'August-Kirch-Stra?e 13, 22587 Hamburg in Planung, 702083',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'August-Kirch-Stra?e 13',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7413392,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375115,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702084,
    WE_SAP_WE_ID_PKF: '7000/702084',
    WE_SAP_WE_BEZEICHNUNG: 'Dohrnweg 6, 22767 Hamburg, 702084',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Dohrnweg 6',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '14.07.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7454916,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: 6988878,
    WE_EN_1_OBJ_ID_FKT: 5455937,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 702082,
    WE_SAP_WE_ID_PKF: '7000/702082',
    WE_SAP_WE_BEZEICHNUNG: 'Kielkamp 16a, 22587 Hamburg in Planung, 702082',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Altona',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Kielkamp 16a',
    WE_PLZ: '22587',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7413391,
    WE_RGN_OBJ_ID_FKT: 1799936,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375114,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706052,
    WE_SAP_WE_ID_PKF: '7000/706052',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 2, 21029 Hamburg, 706052',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 2',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7062813,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5448392,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706053,
    WE_SAP_WE_ID_PKF: '7000/706053',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 3, 21029 Hamburg, 706053',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 3',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7062814,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 5455812,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706048,
    WE_SAP_WE_ID_PKF: '7000/706048',
    WE_SAP_WE_BEZEICHNUNG: 'Oberbillwerder 1, 21033 Hamburg, 706048',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Oberbillwerder 1',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.10.19',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7058639,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 1800347,
    WE_EN_1_OBJ_ID_FKT: 2073535,
    WE_EN_2_OBJ_ID_FKT: 7441215,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706049,
    WE_SAP_WE_ID_PKF: '7000/706049',
    WE_SAP_WE_BEZEICHNUNG: 'Oberbillwerder 2, 21033 Hamburg, 706049',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Oberbillwerder 2',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.10.19',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7058640,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2073523,
    WE_EN_2_OBJ_ID_FKT: 7441217,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706050,
    WE_SAP_WE_ID_PKF: '7000/706050',
    WE_SAP_WE_BEZEICHNUNG: 'Oberbillwerder 3, 21033 Hamburg, 706050',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Oberbillwerder 3',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.10.19',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7058641,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2073518,
    WE_EN_2_OBJ_ID_FKT: 7441224,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 706051,
    WE_SAP_WE_ID_PKF: '7000/706051',
    WE_SAP_WE_BEZEICHNUNG: 'Sander Damm 5, 21029 Hamburg, 706051',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Bergedorf',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Sander Damm 5',
    WE_PLZ: '21029',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7062812,
    WE_RGN_OBJ_ID_FKT: 1799937,
    WE_OM_OBJ_ID_FKT: 7085672,
    WE_EN_1_OBJ_ID_FKT: 7441203,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7030,
    WE_SAP_WE_NUMMER: 701000,
    WE_SAP_WE_ID_PKF: '7000/701000',
    WE_SAP_WE_BEZEICHNUNG: 'An der Stadthausbr?cke 1, 20355 Hamburg, 701000',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Eigenbestand',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'An der Stadthausbr?cke 1',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5461213,
    WE_RGN_OBJ_ID_FKT: 6981843,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703074,
    WE_SAP_WE_ID_PKF: '7000/703074',
    WE_SAP_WE_BEZEICHNUNG: 'Holsteiner Chaussee 345, 22457 Hamburg, 703074',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Holsteiner Chaussee 345',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7223672,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800285,
    WE_EN_1_OBJ_ID_FKT: 7407415,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703075,
    WE_SAP_WE_ID_PKF: '7000/703075',
    WE_SAP_WE_BEZEICHNUNG: 'Julius-Vosseler-Stra?e 36, 22527 Hamburg, 703075',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Julius-Vosseler-Stra?e 36',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 136,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7223673,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 1800508,
    WE_EN_1_OBJ_ID_FKT: 7478932,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 703077,
    WE_SAP_WE_ID_PKF: '7000/703077',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 1, 22457 Hamburg, 703077',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Eimsb?ttel',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 1',
    WE_PLZ: '22457',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7426509,
    WE_RGN_OBJ_ID_FKT: 1799938,
    WE_OM_OBJ_ID_FKT: 6925907,
    WE_EN_1_OBJ_ID_FKT: 7427109,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 701069,
    WE_SAP_WE_ID_PKF: '7000/701069',
    WE_SAP_WE_BEZEICHNUNG: 'Kleine Freiheit 68, 22767 Hamburg, 701069',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'HIBB',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Kleine Freiheit 68',
    WE_PLZ: '22767',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.552566',
    WE_LÄNGENGRAD_OESTLICH: '9.957249',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989061,
    WE_RGN_OBJ_ID_FKT: 1799939,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 2074808,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 701024,
    WE_SAP_WE_ID_PKF: '7000/701024',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Karolinenstra?e 35, 20357 Hamburg beendet, 701024',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Mitte',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Karolinenstra?e 35',
    WE_PLZ: '20357',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.12',
    WE_WE_OBJ_ID_PKT: 1989019,
    WE_RGN_OBJ_ID_FKT: 1799940,
    WE_OM_OBJ_ID_FKT: 1800490,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 704084,
    WE_SAP_WE_ID_PKF: '7000/704084',
    WE_SAP_WE_BEZEICHNUNG: 'Hebebrandstra?e 1, 22297 Hamburg, 704084',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Nord',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Hebebrandstra?e 1',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7225402,
    WE_RGN_OBJ_ID_FKT: 1799941,
    WE_OM_OBJ_ID_FKT: 1800546,
    WE_EN_1_OBJ_ID_FKT: 7445600,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708026,
    WE_SAP_WE_ID_PKF: '7000/708026',
    WE_SAP_WE_BEZEICHNUNG: 'Eilbektal 35, 22089 Hamburg, 708026',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Eilbektal 35',
    WE_PLZ: '22089',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7403566,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708025,
    WE_SAP_WE_ID_PKF: '7000/708025',
    WE_SAP_WE_BEZEICHNUNG: 'Holzdamm 5, 20099 Hamburg, 708025',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Holzdamm 5',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7403565,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7401209,
    WE_EN_1_OBJ_ID_FKT: 2074491,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708024,
    WE_SAP_WE_ID_PKF: '7000/708024',
    WE_SAP_WE_BEZEICHNUNG: 'Isestra?e 144-146, 20149 Hamburg, 708024',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Isestra?e 144-146',
    WE_PLZ: '20149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7403564,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7405000,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708027,
    WE_SAP_WE_ID_PKF: '7000/708027',
    WE_SAP_WE_BEZEICHNUNG: 'Kugelfang 28, 22297 Hamburg, 708027',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Kugelfang 28',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7403567,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 1800371,
    WE_EN_1_OBJ_ID_FKT: 7405003,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7080,
    WE_SAP_WE_NUMMER: 708028,
    WE_SAP_WE_ID_PKF: '7000/708028',
    WE_SAP_WE_BEZEICHNUNG: 'Richardstra?e 1, 22081 Hamburg, 708028',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'SNI',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Richardstra?e 1',
    WE_PLZ: '22081',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '15.03.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7531270,
    WE_RGN_OBJ_ID_FKT: 7245319,
    WE_OM_OBJ_ID_FKT: 7433109,
    WE_EN_1_OBJ_ID_FKT: 7530635,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705106,
    WE_SAP_WE_ID_PKF: '7000/705106',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 1, 22359 Hamburg, 705106',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 1',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7396822,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800510,
    WE_EN_1_OBJ_ID_FKT: 7407619,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705111,
    WE_SAP_WE_ID_PKF: '7000/705111',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 5, 22399 Hamburg, 705111',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Wandsbek-Nord',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 5',
    WE_PLZ: '22399',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7426513,
    WE_RGN_OBJ_ID_FKT: 1799943,
    WE_OM_OBJ_ID_FKT: 1800432,
    WE_EN_1_OBJ_ID_FKT: 7427302,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705105,
    WE_SAP_WE_ID_PKF: '7000/705105',
    WE_SAP_WE_BEZEICHNUNG: 'Lienaustra?e 14-18, 22159 Hamburg, 705105',
    WE_FM_DIENSTLEISTER: 'SBH',
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Lienaustra?e 14-18',
    WE_PLZ: '22159',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7255693,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 6771788,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705107,
    WE_SAP_WE_ID_PKF: '7000/705107',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 2, 22359 Hamburg, 705107',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 2',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7426510,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800459,
    WE_EN_1_OBJ_ID_FKT: 7427108,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705108,
    WE_SAP_WE_ID_PKF: '7000/705108',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 3, 22359 Hamburg, 705108',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 3',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7426511,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800459,
    WE_EN_1_OBJ_ID_FKT: 7427300,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7010,
    WE_SAP_WE_NUMMER: 705110,
    WE_SAP_WE_ID_PKF: '7000/705110',
    WE_SAP_WE_BEZEICHNUNG: 'Musterstra?e 4, 22359 Hamburg, 705110',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: 'Wandsbek-S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Musterstra?e 4',
    WE_PLZ: '22359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7426512,
    WE_RGN_OBJ_ID_FKT: 1799944,
    WE_OM_OBJ_ID_FKT: 1800459,
    WE_EN_1_OBJ_ID_FKT: 7427301,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7040,
    WE_SAP_WE_NUMMER: 707063,
    WE_SAP_WE_ID_PKF: '7000/707063',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Baererstra?e 45 b, 21073 Hamburg, 707063',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: null,
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Baererstra?e 45 b',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.11',
    WE_WE_OBJ_ID_PKT: 6792487,
    WE_RGN_OBJ_ID_FKT: null,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7020,
    WE_SAP_WE_NUMMER: 709999,
    WE_SAP_WE_ID_PKF: '7000/709999',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | D? AA Berufliche Schulen, 709999',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: null,
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'D? AA Berufliche Schulen',
    WE_PLZ: null,
    WE_ORT: null,
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.10',
    WE_GUELTIG_BIS: '12.12.14',
    WE_WE_OBJ_ID_PKT: 5455417,
    WE_RGN_OBJ_ID_FKT: null,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7000,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7040,
    WE_SAP_WE_NUMMER: 707059,
    WE_SAP_WE_ID_PKF: '7000/707059',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Kurdamm 8, 21107 Hamburg, 707059',
    WE_FM_DIENSTLEISTER: null,
    WE_REGION: null,
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Kurdamm 8',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.17',
    WE_WE_OBJ_ID_PKT: 6792484,
    WE_RGN_OBJ_ID_FKT: null,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749010,
    WE_SAP_WE_ID_PKF: '7400/749010',
    WE_SAP_WE_BEZEICHNUNG: 'Bornheide 76, 22549 Hamburg, 749010',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Osdorf',
    WE_STRASSE_NR: 'Bornheide 76',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793134,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749026,
    WE_SAP_WE_ID_PKF: '7400/749026',
    WE_SAP_WE_BEZEICHNUNG: 'Pl?ner Stra?e 10, 22769 Hamburg, 749026',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Pl?ner Stra?e 10',
    WE_PLZ: '22769',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793147,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749027,
    WE_SAP_WE_ID_PKF: '7400/749027',
    WE_SAP_WE_BEZEICHNUNG: 'Waidmannstra?e 26, 22769 Hamburg, 749027',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Altona',
    WE_STADTTEIL: 'Altona-Nord',
    WE_STRASSE_NR: 'Waidmannstra?e 26',
    WE_PLZ: '22769',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793148,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749058,
    WE_SAP_WE_ID_PKF: '7400/749058',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 1-3, 22761 Hamburg, 749058',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Bahrenfeld',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 1-3',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: null,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7545076,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749008,
    WE_SAP_WE_ID_PKF: '7400/749008',
    WE_SAP_WE_BEZEICHNUNG: 'Binnenfeldredder 90, 21031 Hamburg, 749008',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Binnenfeldredder 90',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793132,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749030,
    WE_SAP_WE_ID_PKF: '7400/749030',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Osterrade 51, 21031 Hamburg  beendet 12_21, 749030',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Osterrade 51',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.04.19',
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7022861,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749025,
    WE_SAP_WE_ID_PKF: '7400/749025',
    WE_SAP_WE_BEZEICHNUNG: 'Havighorster Weg 16, 21031 Hamburg, 749025',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Bergedorf',
    WE_STADTTEIL: 'Lohbr?gge',
    WE_STRASSE_NR: 'Havighorster Weg 16',
    WE_PLZ: '21031',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793146,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749024,
    WE_SAP_WE_ID_PKF: '7400/749024',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Papenreye 1a, 22453 Hamburg  beendet 12_21, 749024',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Niendorf',
    WE_STRASSE_NR: 'Papenreye 1a',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 6793145,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749032,
    WE_SAP_WE_ID_PKF: '7400/749032',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Kieler Stra?e 433, 22525 Hamburg beendet 12_21, 749032',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: 'Stellingen',
    WE_STRASSE_NR: 'Kieler Stra?e 433',
    WE_PLZ: '22525',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.04.19',
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7022863,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743065,
    WE_SAP_WE_ID_PKF: '7400/743065',
    WE_SAP_WE_BEZEICHNUNG: 'Bundestra?e 57, 20146 Hamburg, 743065',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Bundestra?e 57',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.568968',
    WE_LÄNGENGRAD_OESTLICH: '9.974059',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5418960,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743066,
    WE_SAP_WE_ID_PKF: '7400/743066',
    WE_SAP_WE_BEZEICHNUNG: 'Sedanstra?e 16-18, 20146 Hamburg, 743066',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: 'Eimsb?ttel',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Sedanstra?e 16-18',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.566254',
    WE_LÄNGENGRAD_OESTLICH: '9.978764',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5418961,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747075,
    WE_SAP_WE_ID_PKF: '7400/747075',
    WE_SAP_WE_BEZEICHNUNG: 'Vogelh?ttendeich 120, 21107 Hamburg, 747075',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Hamburg-Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Vogelh?ttendeich 120',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.08.18',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6971954,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 7262530,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749007,
    WE_SAP_WE_ID_PKF: '7400/749007',
    WE_SAP_WE_BEZEICHNUNG: 'Am Aschenland 13, 21147 Hamburg, 749007',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Am Aschenland 13',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793131,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749001,
    WE_SAP_WE_ID_PKF: '7400/749001',
    WE_SAP_WE_BEZEICHNUNG: 'In der Alten Forst 1, 21077 Hamburg, 749001',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'In der Alten Forst 1',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793127,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749013,
    WE_SAP_WE_ID_PKF: '7400/749013',
    WE_SAP_WE_BEZEICHNUNG: 'Rieckhoffstra?e 12, 21077 Hamburg, 749013',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Rieckhoffstra?e 12',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793135,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749031,
    WE_SAP_WE_ID_PKF: '7400/749031',
    WE_SAP_WE_BEZEICHNUNG: 'Schlachthofstra?e 1-3, 21073 Hamburg, 749031',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Schlachthofstra?e 1-3',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.04.19',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7022862,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749022,
    WE_SAP_WE_ID_PKF: '7400/749022',
    WE_SAP_WE_BEZEICHNUNG: 'Geutensweg 30, 21147 Hamburg, 749022',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Geutensweg 30',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793143,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749017,
    WE_SAP_WE_ID_PKF: '7400/749017',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Karl-Arnold-Ring 11, 21109 Hamburg, 749017',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Karl-Arnold-Ring 11',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487239',
    WE_LÄNGENGRAD_OESTLICH: '10.022519',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.20',
    WE_WE_OBJ_ID_PKT: 1989430,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747030,
    WE_SAP_WE_ID_PKF: '7400/747030',
    WE_SAP_WE_BEZEICHNUNG: 'Estebogen 3, 21129 Hamburg, 747030',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Cranz',
    WE_STRASSE_NR: 'Estebogen 3',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.529963',
    WE_LÄNGENGRAD_OESTLICH: '9.773919',
    WE_ANZAHL_SCHUELER: 95,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989447,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2074792,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747003,
    WE_SAP_WE_ID_PKF: '7400/747003',
    WE_SAP_WE_BEZEICHNUNG: 'Baererstra?e 81, 21073 Hamburg, 747003',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'Baererstra?e 81',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.454176',
    WE_LÄNGENGRAD_OESTLICH: '9.974009',
    WE_ANZAHL_SCHUELER: 471,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989422,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074776,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747004,
    WE_SAP_WE_ID_PKF: '7400/747004',
    WE_SAP_WE_BEZEICHNUNG: 'Dempwolffstra?e 7, 21073 Hamburg, 747004',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'Dempwolffstra?e 7',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.458849',
    WE_LÄNGENGRAD_OESTLICH: '9.964955',
    WE_ANZAHL_SCHUELER: 240,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989423,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074771,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747031,
    WE_SAP_WE_ID_PKF: '7400/747031',
    WE_SAP_WE_BEZEICHNUNG: 'Ehestorfer Weg 14, 21075 Hamburg, 747031',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'Ehestorfer Weg 14',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.455466',
    WE_LÄNGENGRAD_OESTLICH: '9.951079',
    WE_ANZAHL_SCHUELER: 819,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989448,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074784,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747042,
    WE_SAP_WE_ID_PKF: '7400/747042',
    WE_SAP_WE_BEZEICHNUNG: 'In der Alten Forst 1, 21077 Hamburg, 747042',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'In der Alten Forst 1',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.449078',
    WE_LÄNGENGRAD_OESTLICH: '9.935350',
    WE_ANZAHL_SCHUELER: 625,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989458,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074774,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747032,
    WE_SAP_WE_ID_PKF: '7400/747032',
    WE_SAP_WE_BEZEICHNUNG: 'Kirchenhang 33, 21073 Hamburg AGV, 747032',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'Kirchenhang 33',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.454306',
    WE_LÄNGENGRAD_OESTLICH: '9.955559',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989449,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074784,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747040,
    WE_SAP_WE_ID_PKF: '7400/747040',
    WE_SAP_WE_BEZEICHNUNG: 'Triftstra?e 43, 21075 Hamburg, 747040',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Ei?endorf',
    WE_STRASSE_NR: 'Triftstra?e 43',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.456056',
    WE_LÄNGENGRAD_OESTLICH: '9.945069',
    WE_ANZAHL_SCHUELER: 948,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989456,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074767,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747063,
    WE_SAP_WE_ID_PKF: '7400/747063',
    WE_SAP_WE_BEZEICHNUNG: 'Baererstra?e 45b, 21073 Hamburg, 747063',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Baererstra?e 45b',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989477,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074766,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747064,
    WE_SAP_WE_ID_PKF: '7400/747064',
    WE_SAP_WE_BEZEICHNUNG: 'Bunatwiete 20, 21073 Hamburg, 747064',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Bunatwiete 20',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.456155',
    WE_LÄNGENGRAD_OESTLICH: '9.982589',
    WE_ANZAHL_SCHUELER: 468,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989478,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074785,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747039,
    WE_SAP_WE_ID_PKF: '7400/747039',
    WE_SAP_WE_BEZEICHNUNG: 'Ei?endorfer Stra?e 26, 21073 Hamburg, 747039',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Ei?endorfer Stra?e 26',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.460366',
    WE_LÄNGENGRAD_OESTLICH: '9.974339',
    WE_ANZAHL_SCHUELER: 1237,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989455,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074785,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747012,
    WE_SAP_WE_ID_PKF: '7400/747012',
    WE_SAP_WE_BEZEICHNUNG: 'Kerschensteinerstra?e 16, 21073 Hamburg, 747012',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Kerschensteinerstra?e 16',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.461771',
    WE_LÄNGENGRAD_OESTLICH: '9.973471',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989431,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074776,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747074,
    WE_SAP_WE_ID_PKF: '7400/747074',
    WE_SAP_WE_BEZEICHNUNG: 'Maretstra?e 50, 21073 Hamburg, Center Maretstra?e, 747074',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Maretstra?e 50',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6844734,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747048,
    WE_SAP_WE_ID_PKF: '7400/747048',
    WE_SAP_WE_BEZEICHNUNG: 'Maretstra?e 50, 21073 Hamburg, 747048',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Maretstra?e 50',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.454160',
    WE_LÄNGENGRAD_OESTLICH: '9.981769',
    WE_ANZAHL_SCHUELER: 834,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989464,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074766,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747009,
    WE_SAP_WE_ID_PKF: '7400/747009',
    WE_SAP_WE_BEZEICHNUNG: 'Schwarzenbergstra?e 50, 21073 Hamburg, 747009',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Schwarzenbergstra?e 50',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.462840',
    WE_LÄNGENGRAD_OESTLICH: '9.976253',
    WE_ANZAHL_SCHUELER: 100,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989428,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 7115639,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747019,
    WE_SAP_WE_ID_PKF: '7400/747019',
    WE_SAP_WE_BEZEICHNUNG: 'Schwarzenbergstra?e 72, 21073 Hamburg, 747019',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Harburg',
    WE_STRASSE_NR: 'Schwarzenbergstra?e 72',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.463256',
    WE_LÄNGENGRAD_OESTLICH: '9.974239',
    WE_ANZAHL_SCHUELER: 216,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989437,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 5305181,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747010,
    WE_SAP_WE_ID_PKF: '7400/747010',
    WE_SAP_WE_BEZEICHNUNG: 'Hausbrucher Bahnhofstra?e 19, 21147 Hamburg, 747010',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Hausbruch',
    WE_STRASSE_NR: 'Hausbrucher Bahnhofstra?e 19',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.474423',
    WE_LÄNGENGRAD_OESTLICH: '9.893579',
    WE_ANZAHL_SCHUELER: 85,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989429,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074779,
    WE_EN_2_OBJ_ID_FKT: 2074799,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747047,
    WE_SAP_WE_ID_PKF: '7400/747047',
    WE_SAP_WE_BEZEICHNUNG: 'Lange Striepen 51, 21147 Hamburg, 747047',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Hausbruch',
    WE_STRASSE_NR: 'Lange Striepen 51',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.476916',
    WE_LÄNGENGRAD_OESTLICH: '9.885949',
    WE_ANZAHL_SCHUELER: 458,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989463,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074793,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747037,
    WE_SAP_WE_ID_PKF: '7400/747037',
    WE_SAP_WE_BEZEICHNUNG: 'Alter Postweg 30-38, 21075 Hamburg, 747037',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Alter Postweg 30-38',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.465017',
    WE_LÄNGENGRAD_OESTLICH: '9.964981',
    WE_ANZAHL_SCHUELER: 876,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989454,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074765,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747038,
    WE_SAP_WE_ID_PKF: '7400/747038',
    WE_SAP_WE_BEZEICHNUNG: 'Alter Postweg 32, 21075 Hamburg, 747038',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Alter Postweg 32',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793123,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747073,
    WE_SAP_WE_ID_PKF: '7400/747073',
    WE_SAP_WE_BEZEICHNUNG: 'Am Johannisland 4, 21147 Hamburg, Center BGZ S?der, 747073',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Am Johannisland 4',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6844733,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747067,
    WE_SAP_WE_ID_PKF: '7400/747067',
    WE_SAP_WE_BEZEICHNUNG: 'Am Johannisland 4, 21147 Hamburg, 747067',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Am Johannisland 4',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.476123',
    WE_LÄNGENGRAD_OESTLICH: '9.854431',
    WE_ANZAHL_SCHUELER: 564,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2032474,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074788,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747006,
    WE_SAP_WE_ID_PKF: '7400/747006',
    WE_SAP_WE_BEZEICHNUNG: 'Grumbrechtstra?e 63, 21075 Hamburg, 747006',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Grumbrechtstra?e 63',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.468479',
    WE_LÄNGENGRAD_OESTLICH: '9.955769',
    WE_ANZAHL_SCHUELER: 678,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989425,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074773,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747058,
    WE_SAP_WE_ID_PKF: '7400/747058',
    WE_SAP_WE_BEZEICHNUNG: 'Weusthoffstra?e 95, 21075 Hamburg, 747058',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Heimfeld',
    WE_STRASSE_NR: 'Weusthoffstra?e 95',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.460596',
    WE_LÄNGENGRAD_OESTLICH: '9.956699',
    WE_ANZAHL_SCHUELER: 640,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989473,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074783,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747041,
    WE_SAP_WE_ID_PKF: '7400/747041',
    WE_SAP_WE_BEZEICHNUNG: 'Am Pavillon 15, 21077 Hamburg, 747041',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Marmstorf',
    WE_STRASSE_NR: 'Am Pavillon 15',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.432671',
    WE_LÄNGENGRAD_OESTLICH: '9.966929',
    WE_ANZAHL_SCHUELER: 615,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989457,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074768,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747014,
    WE_SAP_WE_ID_PKF: '7400/747014',
    WE_SAP_WE_BEZEICHNUNG: 'Ernst-Bergeest-Weg 54, 21077 Hamburg, 747014',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Marmstorf',
    WE_STRASSE_NR: 'Ernst-Bergeest-Weg 54',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.438376',
    WE_LÄNGENGRAD_OESTLICH: '9.963349',
    WE_ANZAHL_SCHUELER: 460,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989432,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074777,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747016,
    WE_SAP_WE_ID_PKF: '7400/747016',
    WE_SAP_WE_BEZEICHNUNG: 'Nymphenweg 20, 21077 Hamburg, 747016',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Marmstorf',
    WE_STRASSE_NR: 'Nymphenweg 20',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.440286',
    WE_LÄNGENGRAD_OESTLICH: '9.970400',
    WE_ANZAHL_SCHUELER: 48,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989434,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074779,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747008,
    WE_SAP_WE_ID_PKF: '7400/747008',
    WE_SAP_WE_BEZEICHNUNG: 'Sinstorfer Weg 40, 21077 Hamburg, 747008',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Marmstorf',
    WE_STRASSE_NR: 'Sinstorfer Weg 40',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.430876',
    WE_LÄNGENGRAD_OESTLICH: '9.969909',
    WE_ANZAHL_SCHUELER: 196,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989427,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 7067473,
    WE_EN_2_OBJ_ID_FKT: 2074769,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747029,
    WE_SAP_WE_ID_PKF: '7400/747029',
    WE_SAP_WE_BEZEICHNUNG: 'Arp-Schnitger-Stieg 19, 21129 Hamburg, 747029',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neuenfelde',
    WE_STRASSE_NR: 'Arp-Schnitger-Stieg 19',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.516213',
    WE_LÄNGENGRAD_OESTLICH: '9.809619',
    WE_ANZAHL_SCHUELER: 315,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989446,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2074791,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747072,
    WE_SAP_WE_ID_PKF: '7400/747072',
    WE_SAP_WE_BEZEICHNUNG: 'Cuxhavener Stra?e 271 a, 21149 Hamburg, 747072',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Cuxhavener Stra?e 271 a',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793126,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074790,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747055,
    WE_SAP_WE_ID_PKF: '7400/747055',
    WE_SAP_WE_BEZEICHNUNG: 'Falkenbergsweg 5, 21149 Hamburg, 747055',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Falkenbergsweg 5',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.469883',
    WE_LÄNGENGRAD_OESTLICH: '9.864079',
    WE_ANZAHL_SCHUELER: 1098,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989471,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074790,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747002,
    WE_SAP_WE_ID_PKF: '7400/747002',
    WE_SAP_WE_BEZEICHNUNG: 'Fischbeker Moor 6, 21149 Hamburg, 747002',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Fischbeker Moor 6',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.473890',
    WE_LÄNGENGRAD_OESTLICH: '9.836509',
    WE_ANZAHL_SCHUELER: 870,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989421,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2076431,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747015,
    WE_SAP_WE_ID_PKF: '7400/747015',
    WE_SAP_WE_BEZEICHNUNG: 'Francoper Stra?e 32, 21147 Hamburg, 747015',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Francoper Stra?e 32',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.475313',
    WE_LÄNGENGRAD_OESTLICH: '9.864789',
    WE_ANZAHL_SCHUELER: 535,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989433,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2074795,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747001,
    WE_SAP_WE_ID_PKF: '7400/747001',
    WE_SAP_WE_BEZEICHNUNG: 'Heidrand 5, 21149 Hamburg, 747001',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Heidrand 5',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.464813',
    WE_LÄNGENGRAD_OESTLICH: '9.861619',
    WE_ANZAHL_SCHUELER: 923,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989420,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2076431,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747057,
    WE_SAP_WE_ID_PKF: '7400/747057',
    WE_SAP_WE_BEZEICHNUNG: 'Neumoorst?ck 1, 21147 Hamburg, 747057',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Neumoorst?ck 1',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.478136',
    WE_LÄNGENGRAD_OESTLICH: '9.866819',
    WE_ANZAHL_SCHUELER: 905,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989472,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074799,
    WE_EN_2_OBJ_ID_FKT: 5305183,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747036,
    WE_SAP_WE_ID_PKF: '7400/747036',
    WE_SAP_WE_BEZEICHNUNG: 'Neuwiedenthaler Stra?e 4, 21147 Hamburg, 747036',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Neuwiedenthaler Stra?e 4',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.476643',
    WE_LÄNGENGRAD_OESTLICH: '9.864719',
    WE_ANZAHL_SCHUELER: 77,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989453,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 5305183,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747017,
    WE_SAP_WE_ID_PKF: '7400/747017',
    WE_SAP_WE_BEZEICHNUNG: 'Ohrnsweg 52, 21149 Hamburg, 747017',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Ohrnsweg 52',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.471483',
    WE_LÄNGENGRAD_OESTLICH: '9.815589',
    WE_ANZAHL_SCHUELER: 346,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989435,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074796,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747050,
    WE_SAP_WE_ID_PKF: '7400/747050',
    WE_SAP_WE_BEZEICHNUNG: 'Quellmoor 24, 21147 Hamburg, 747050',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Quellmoor 24',
    WE_PLZ: '21147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.478913',
    WE_LÄNGENGRAD_OESTLICH: '9.870779',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989466,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074799,
    WE_EN_2_OBJ_ID_FKT: 5305183,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747018,
    WE_SAP_WE_ID_PKF: '7400/747018',
    WE_SAP_WE_BEZEICHNUNG: 'Schnuckendrift 21, 21149 Hamburg, 747018',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Schnuckendrift 21',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.466566',
    WE_LÄNGENGRAD_OESTLICH: '9.839439',
    WE_ANZAHL_SCHUELER: 359,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989436,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2074797,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747069,
    WE_SAP_WE_ID_PKF: '7400/747069',
    WE_SAP_WE_BEZEICHNUNG: 'Vogelkamp 1, 22147 Hamburg, 747069',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neugraben-Fischbek',
    WE_STRASSE_NR: 'Vogelkamp 1',
    WE_PLZ: '22147',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 5914309,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747049,
    WE_SAP_WE_ID_PKF: '7400/747049',
    WE_SAP_WE_BEZEICHNUNG: 'Neul?nder Elbdeich 241, 21079 Hamburg, 747049',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neuland',
    WE_STRASSE_NR: 'Neul?nder Elbdeich 241',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.468956',
    WE_LÄNGENGRAD_OESTLICH: '10.037199',
    WE_ANZAHL_SCHUELER: 138,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989465,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 2074778,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747070,
    WE_SAP_WE_ID_PKF: '7400/747070',
    WE_SAP_WE_BEZEICHNUNG: 'Schweenssand-Hauptdeich 5, 21075 Hamburg, 747070',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Neuland',
    WE_STRASSE_NR: 'Schweenssand-Hauptdeich 5',
    WE_PLZ: '21075',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793124,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074765,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747051,
    WE_SAP_WE_ID_PKF: '7400/747051',
    WE_SAP_WE_BEZEICHNUNG: 'Kanzlerstra?e 25, 21079 Hamburg, 747051',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'R?nneburg',
    WE_STRASSE_NR: 'Kanzlerstra?e 25',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.439086',
    WE_LÄNGENGRAD_OESTLICH: '10.007629',
    WE_ANZAHL_SCHUELER: 478,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989467,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074780,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747053,
    WE_SAP_WE_ID_PKF: '7400/747053',
    WE_SAP_WE_BEZEICHNUNG: 'Schee?eler Kehre 2, 21079 Hamburg, 747053',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Sinstorf',
    WE_STRASSE_NR: 'Schee?eler Kehre 2',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.426726',
    WE_LÄNGENGRAD_OESTLICH: '9.987309',
    WE_ANZAHL_SCHUELER: 328,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989469,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074781,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747059,
    WE_SAP_WE_ID_PKF: '7400/747059',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Kurdamm 8, 21107 Hamburg, 747059',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Kurdamm 8',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.496195',
    WE_LÄNGENGRAD_OESTLICH: '9.990852',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.17',
    WE_WE_OBJ_ID_PKT: 1989474,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 5305182,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747033,
    WE_SAP_WE_ID_PKF: '7400/747033',
    WE_SAP_WE_BEZEICHNUNG: 'Buddestra?e 25, 21109 Hamburg, 747033',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Buddestra?e 25',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.500783',
    WE_LÄNGENGRAD_OESTLICH: '10.012299',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989450,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074530,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747028,
    WE_SAP_WE_ID_PKF: '7400/747028',
    WE_SAP_WE_BEZEICHNUNG: 'Karl-Arnold-Ring 13, 21109 Hamburg, 747028',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Karl-Arnold-Ring 13',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.487403',
    WE_LÄNGENGRAD_OESTLICH: '10.023359',
    WE_ANZAHL_SCHUELER: 286,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989445,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074537,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747054,
    WE_SAP_WE_ID_PKF: '7400/747054',
    WE_SAP_WE_BEZEICHNUNG: 'St?benhofer Weg 20, 21109 Hamburg, 747054',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'St?benhofer Weg 20',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.483106',
    WE_LÄNGENGRAD_OESTLICH: '10.014609',
    WE_ANZAHL_SCHUELER: 707,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989470,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074534,
    WE_EN_2_OBJ_ID_FKT: 2074542,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747007,
    WE_SAP_WE_ID_PKF: '7400/747007',
    WE_SAP_WE_BEZEICHNUNG: 'Hanhoopsfeld 21, 21079 Hamburg, 747007',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilstorf',
    WE_STRASSE_NR: 'Hanhoopsfeld 21',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.438716',
    WE_LÄNGENGRAD_OESTLICH: '9.992020',
    WE_ANZAHL_SCHUELER: 1183,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989426,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074769,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747043,
    WE_SAP_WE_ID_PKF: '7400/747043',
    WE_SAP_WE_BEZEICHNUNG: 'Kapellenweg 63, 21077 Hamburg, 747043',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilstorf',
    WE_STRASSE_NR: 'Kapellenweg 63',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.445136',
    WE_LÄNGENGRAD_OESTLICH: '9.988559',
    WE_ANZAHL_SCHUELER: 457,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989459,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 2074775,
    WE_EN_2_OBJ_ID_FKT: 5305181,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747027,
    WE_SAP_WE_ID_PKF: '7400/747027',
    WE_SAP_WE_BEZEICHNUNG: 'R?nneburger Stra?e 50, 21079 Hamburg, 747027',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: 'Wilstorf',
    WE_STRASSE_NR: 'R?nneburger Stra?e 50',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.438723',
    WE_LÄNGENGRAD_OESTLICH: '9.993159',
    WE_ANZAHL_SCHUELER: 798,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989444,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: 2074764,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747005,
    WE_SAP_WE_ID_PKF: '7400/747005',
    WE_SAP_WE_BEZEICHNUNG: 'Elfenwiese 3, 21077 Hamburg, 747005',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Harburg',
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Elfenwiese 3',
    WE_PLZ: '21077',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.440039',
    WE_LÄNGENGRAD_OESTLICH: '9.970169',
    WE_ANZAHL_SCHUELER: 223,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989424,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7042135,
    WE_EN_1_OBJ_ID_FKT: 2074772,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749029,
    WE_SAP_WE_ID_PKF: '7400/749029',
    WE_SAP_WE_BEZEICHNUNG: 'St. Petersburger Stra?e 30, 20355 Hamburg, 749029',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'St. Pauli',
    WE_STRASSE_NR: 'St. Petersburger Stra?e 30',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.18',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6923879,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749028,
    WE_SAP_WE_ID_PKF: '7400/749028',
    WE_SAP_WE_BEZEICHNUNG: 'Grusonstra?e 3, 22113 Hamburg, 749028',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Billbrook',
    WE_STRASSE_NR: 'Grusonstra?e 3',
    WE_PLZ: '22113',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793149,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749019,
    WE_SAP_WE_ID_PKF: '7400/749019',
    WE_SAP_WE_BEZEICHNUNG: 'Eiffestra?e 398, 20537 Hamburg, 749019',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hamm',
    WE_STRASSE_NR: 'Eiffestra?e 398',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793140,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749021,
    WE_SAP_WE_ID_PKF: '7400/749021',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Frankenstra?e 16, 20097 Hamburg  beendet 12_21, 749021',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Frankenstra?e 16',
    WE_PLZ: '20097',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 6793142,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743081,
    WE_SAP_WE_ID_PKF: '7400/743081',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Wendenstra?e 162, 20537 Hamburg  beendet 12_21, 743081',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Wendenstra?e 162',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220148,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749020,
    WE_SAP_WE_ID_PKF: '7400/749020',
    WE_SAP_WE_BEZEICHNUNG: 'Friesenstra?e 22, 20097 Hamburg, 749020',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Friesenstra?e 22',
    WE_PLZ: '20097',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793141,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749016,
    WE_SAP_WE_ID_PKF: '7400/749016',
    WE_SAP_WE_BEZEICHNUNG: 'Wendenstra?e 162, 20537 Hamburg, 749016',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Hammerbrook',
    WE_STRASSE_NR: 'Wendenstra?e 162',
    WE_PLZ: '20537',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793138,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749009,
    WE_SAP_WE_ID_PKF: '7400/749009',
    WE_SAP_WE_BEZEICHNUNG: 'Holstenwall 30, 20355 Hamburg, 749009',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Neustadt',
    WE_STRASSE_NR: 'Holstenwall 30',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793133,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749014,
    WE_SAP_WE_ID_PKF: '7400/749014',
    WE_SAP_WE_BEZEICHNUNG: 'Kurdamm 8, 21107 Hamburg AGV, 749014',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Kurdamm 8',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793136,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749002,
    WE_SAP_WE_ID_PKF: '7400/749002',
    WE_SAP_WE_BEZEICHNUNG: 'Rotenh?user Stra?e 75, 21107 Hamburg, 749002',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Rotenh?user Stra?e 75',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793128,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741049,
    WE_SAP_WE_ID_PKF: '7400/741049',
    WE_SAP_WE_BEZEICHNUNG: 'Finkenwerder Landscheideweg 180, 21129 Hamburg, 741049',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Finkenwerder',
    WE_STRASSE_NR: 'Finkenwerder Landscheideweg 180',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.527836',
    WE_LÄNGENGRAD_OESTLICH: '9.862859',
    WE_ANZAHL_SCHUELER: 332,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989043,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2076312,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741067,
    WE_SAP_WE_ID_PKF: '7400/741067',
    WE_SAP_WE_BEZEICHNUNG: 'Focksweg 12, 21129 Hamburg, 741067',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Finkenwerder',
    WE_STRASSE_NR: 'Focksweg 12',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.535090',
    WE_LÄNGENGRAD_OESTLICH: '9.877377',
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989059,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074789,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741065,
    WE_SAP_WE_ID_PKF: '7400/741065',
    WE_SAP_WE_BEZEICHNUNG: 'Norderschulweg 14, 21129 Hamburg, 741065',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Finkenwerder',
    WE_STRASSE_NR: 'Norderschulweg 14',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.532113',
    WE_LÄNGENGRAD_OESTLICH: '9.875759',
    WE_ANZAHL_SCHUELER: 530,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989057,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074798,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741066,
    WE_SAP_WE_ID_PKF: '7400/741066',
    WE_SAP_WE_BEZEICHNUNG: 'Norderschulweg 18, 21129 Hamburg, 741066',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Finkenwerder',
    WE_STRASSE_NR: 'Norderschulweg 18',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.532783',
    WE_LÄNGENGRAD_OESTLICH: '9.873389',
    WE_ANZAHL_SCHUELER: 443,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989058,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074789,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741005,
    WE_SAP_WE_ID_PKF: '7400/741005',
    WE_SAP_WE_BEZEICHNUNG: 'Ostfrieslandstra?e 91, 21129 Hamburg, 741005',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Finkenwerder',
    WE_STRASSE_NR: 'Ostfrieslandstra?e 91',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.527710',
    WE_LÄNGENGRAD_OESTLICH: '9.884842',
    WE_ANZAHL_SCHUELER: 217,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989000,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074786,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 741043,
    WE_SAP_WE_ID_PKF: '7400/741043',
    WE_SAP_WE_BEZEICHNUNG: 'Slomanstieg 1-3, 20539 Hamburg, 741043',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Veddel',
    WE_STRASSE_NR: 'Slomanstieg 1-3',
    WE_PLZ: '20539',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.526848',
    WE_LÄNGENGRAD_OESTLICH: '10.019474',
    WE_ANZAHL_SCHUELER: 444,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989037,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2074540,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747035,
    WE_SAP_WE_ID_PKF: '7400/747035',
    WE_SAP_WE_BEZEICHNUNG: 'F?hrstra?e 90, 21107 Hamburg, 747035',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'F?hrstra?e 90',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.517175',
    WE_LÄNGENGRAD_OESTLICH: '9.981307',
    WE_ANZAHL_SCHUELER: 338,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989452,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2074532,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747068,
    WE_SAP_WE_ID_PKF: '7400/747068',
    WE_SAP_WE_BEZEICHNUNG: 'Krieterstra?e 2, 21109 Hamburg, 747068',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Krieterstra?e 2',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.501366',
    WE_LÄNGENGRAD_OESTLICH: '10.013279',
    WE_ANZAHL_SCHUELER: 462,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 2034793,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074530,
    WE_EN_2_OBJ_ID_FKT: 5305182,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747046,
    WE_SAP_WE_ID_PKF: '7400/747046',
    WE_SAP_WE_BEZEICHNUNG: 'Krieterstra?e 5, 21109 Hamburg, 747046',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Krieterstra?e 5',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.499834',
    WE_LÄNGENGRAD_OESTLICH: '10.013499',
    WE_ANZAHL_SCHUELER: 963,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989462,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074535,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747044,
    WE_SAP_WE_ID_PKF: '7400/747044',
    WE_SAP_WE_BEZEICHNUNG: 'Neuenfelder Stra?e 106, 21109 Hamburg, 747044',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Neuenfelder Stra?e 106',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.493169',
    WE_LÄNGENGRAD_OESTLICH: '10.013819',
    WE_ANZAHL_SCHUELER: 1067,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989460,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2074536,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747060,
    WE_SAP_WE_ID_PKF: '7400/747060',
    WE_SAP_WE_BEZEICHNUNG: 'Perlstieg 1, 21107 Hamburg, 747060',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Perlstieg 1',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.504343',
    WE_LÄNGENGRAD_OESTLICH: '9.993209',
    WE_ANZAHL_SCHUELER: 773,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989475,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2076432,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747045,
    WE_SAP_WE_ID_PKF: '7400/747045',
    WE_SAP_WE_BEZEICHNUNG: 'Prassekstra?e 5, 21109 Hamburg, 747045',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Prassekstra?e 5',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.492718',
    WE_LÄNGENGRAD_OESTLICH: '10.009545',
    WE_ANZAHL_SCHUELER: 420,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989461,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2074533,
    WE_EN_2_OBJ_ID_FKT: 2074536,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747034,
    WE_SAP_WE_ID_PKF: '7400/747034',
    WE_SAP_WE_BEZEICHNUNG: 'Rahmwerder Stra?e 3, 21109 Hamburg, 747034',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Rahmwerder Stra?e 3',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.512210',
    WE_LÄNGENGRAD_OESTLICH: '10.019719',
    WE_ANZAHL_SCHUELER: 236,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989451,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 2074530,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747052,
    WE_SAP_WE_ID_PKF: '7400/747052',
    WE_SAP_WE_BEZEICHNUNG: 'Rotenh?user Damm 45, 21107 Hamburg, 747052',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Rotenh?user Damm 45',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.511441',
    WE_LÄNGENGRAD_OESTLICH: '9.991494',
    WE_ANZAHL_SCHUELER: 305,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989468,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2074539,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747071,
    WE_SAP_WE_ID_PKF: '7400/747071',
    WE_SAP_WE_BEZEICHNUNG: 'Rotenh?user Stra?e 28, 21107 Hamburg, 747071',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Rotenh?user Stra?e 28',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793125,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2076432,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747061,
    WE_SAP_WE_ID_PKF: '7400/747061',
    WE_SAP_WE_BEZEICHNUNG: 'Rotenh?user Stra?e 67, 21107 Hamburg, 747061',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Rotenh?user Stra?e 67',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.505623',
    WE_LÄNGENGRAD_OESTLICH: '9.989969',
    WE_ANZAHL_SCHUELER: 394,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989476,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 2076432,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747020,
    WE_SAP_WE_ID_PKF: '7400/747020',
    WE_SAP_WE_BEZEICHNUNG: 'Zeidlerstra?e 50, 21107 Hamburg, 747020',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: 'Mitte',
    WE_STADTTEIL: 'Wilhelmsburg',
    WE_STRASSE_NR: 'Zeidlerstra?e 50',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: '53.510533',
    WE_LÄNGENGRAD_OESTLICH: '9.995129',
    WE_ANZAHL_SCHUELER: 75,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 1989438,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331869,
    WE_EN_1_OBJ_ID_FKT: 5305182,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749005,
    WE_SAP_WE_ID_PKF: '7400/749005',
    WE_SAP_WE_BEZEICHNUNG: 'Freiligrathstra?e 1, 22087 Hamburg, 749005',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Nord',
    WE_STADTTEIL: 'Hohenfelde',
    WE_STRASSE_NR: 'Freiligrathstra?e 1',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793130,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749003,
    WE_SAP_WE_ID_PKF: '7400/749003',
    WE_SAP_WE_BEZEICHNUNG: 'Grunewaldstra?e 74a, 22045 Hamburg, 749003',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Grunewaldstra?e 74a',
    WE_PLZ: '22045',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793129,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375689,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749057,
    WE_SAP_WE_ID_PKF: '7400/749057',
    WE_SAP_WE_BEZEICHNUNG: 'Kneesestra?e 7, 22041 Hamburg, 749057',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Mariental',
    WE_STRASSE_NR: 'Kneesestra?e 7',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: null,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7540052,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: 2074681,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749018,
    WE_SAP_WE_ID_PKF: '7400/749018',
    WE_SAP_WE_BEZEICHNUNG: 'Bargkoppelstieg 10-14, 22145 Hamburg, 749018',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Bargkoppelstieg 10-14',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793139,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749023,
    WE_SAP_WE_ID_PKF: '7400/749023',
    WE_SAP_WE_BEZEICHNUNG: 'Hellmesbergerweg 23, 22145 Hamburg, 749023',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Hellmesbergerweg 23',
    WE_PLZ: '22145',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793144,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749015,
    WE_SAP_WE_ID_PKF: '7400/749015',
    WE_SAP_WE_BEZEICHNUNG: 'Stapelfelder Stra?e 7, 22143 Hamburg, 749015',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: 'Wandsbek',
    WE_STADTTEIL: 'Rahlstedt',
    WE_STRASSE_NR: 'Stapelfelder Stra?e 7',
    WE_PLZ: '22143',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793137,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743082,
    WE_SAP_WE_ID_PKF: '7400/743082',
    WE_SAP_WE_BEZEICHNUNG: 'An der Stadthausbr?cke 1, 20355 Hamburg, 743082',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'An der Stadthausbr?cke 1',
    WE_PLZ: '20355',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7390138,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375689,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749051,
    WE_SAP_WE_ID_PKF: '7400/749051',
    WE_SAP_WE_BEZEICHNUNG: 'Heubergredder 38, 22297 Hamburg, 749051',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Heubergredder 38',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7375989,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375689,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749050,
    WE_SAP_WE_ID_PKF: '7400/749050',
    WE_SAP_WE_BEZEICHNUNG: 'Trib?nenweg 41, 22111 Hamburg, 749050',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'GMH Projekte Projektsteuerung',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Trib?nenweg 41',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7375988,
    WE_RGN_OBJ_ID_FKT: 6791231,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 7375689,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749038,
    WE_SAP_WE_ID_PKF: '7400/749038',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 17-21, 22761 Hamburg, 749038',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 17-21',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7182678,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749041,
    WE_SAP_WE_ID_PKF: '7400/749041',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 2, 22761 Hamburg, 749041',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 2',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189767,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749055,
    WE_SAP_WE_ID_PKF: '7400/749055',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 4-6, 22761 Hamburg, 749055',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 4-6',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7508675,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749042,
    WE_SAP_WE_ID_PKF: '7400/749042',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 7, 22761 Hamburg, 749042',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 7',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189768,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749040,
    WE_SAP_WE_ID_PKF: '7400/749040',
    WE_SAP_WE_BEZEICHNUNG: 'Albert-Einstein-Ring 8-10, 22761 Hamburg, 749040',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Albert-Einstein-Ring 8-10',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189766,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 7440147,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743071,
    WE_SAP_WE_ID_PKF: '7400/743071',
    WE_SAP_WE_BEZEICHNUNG: 'Am Dulsbergbad 5, 22049 Hamburg, 743071',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Am Dulsbergbad 5',
    WE_PLZ: '22049',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7138955,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749037,
    WE_SAP_WE_ID_PKF: '7400/749037',
    WE_SAP_WE_BEZEICHNUNG: 'Averhoffstra?e 38, 22085 Hamburg, 749037',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Averhoffstra?e 38',
    WE_PLZ: '22085',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7180034,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749048,
    WE_SAP_WE_ID_PKF: '7400/749048',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Allerm?her Deich 36, 21037 Hamburg  beendet 12_21, 749048',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Allerm?her Deich 36',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7252167,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743076,
    WE_SAP_WE_ID_PKF: '7400/743076',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Braamkamp 1, 22297 Hamburg  beendet 12_21, 743076',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Braamkamp 1',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220143,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743077,
    WE_SAP_WE_ID_PKF: '7400/743077',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Klotzenmoorstieg 2, 22453 Hamburg  beendet 12_21, 743077',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Klotzenmoorstieg 2',
    WE_PLZ: '22453',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220144,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743072,
    WE_SAP_WE_ID_PKF: '7400/743072',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Lupinenweg 12, 22549 Hamburg beendet 12_21, 743072',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Lupinenweg 12',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220139,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743080,
    WE_SAP_WE_ID_PKF: '7400/743080',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Mittlerer Landweg 40, 21033 Hamburg  beendet 12_21, 743080',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Mittlerer Landweg 40',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220147,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743074,
    WE_SAP_WE_ID_PKF: '7400/743074',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Moorburger Elbdeich 257 a, 21079 Hamburg, 743074',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Moorburger Elbdeich 257 a',
    WE_PLZ: '21079',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220141,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743073,
    WE_SAP_WE_ID_PKF: '7400/743073',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Sandwisch 66, 22113 Hamburg  beendet 12_21, 743073',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Sandwisch 66',
    WE_PLZ: '22113',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220140,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743078,
    WE_SAP_WE_ID_PKF: '7400/743078',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Schleidenstra?e 9-11, 22083 Hamburg  beendet 12_21, 743078',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Schleidenstra?e 9-11',
    WE_PLZ: '22083',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220145,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743075,
    WE_SAP_WE_ID_PKF: '7400/743075',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Seilerstra?e 42, 20359 Hamburg  beendet 12_21, 743075',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Seilerstra?e 42',
    WE_PLZ: '20359',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220142,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 743079,
    WE_SAP_WE_ID_PKF: '7400/743079',
    WE_SAP_WE_BEZEICHNUNG: 'BEENDET | Wandsbeker Allee 54, 22041 Hamburg  beendet 12_21, 743079',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Wandsbeker Allee 54',
    WE_PLZ: '22041',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'N',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.21',
    WE_WE_OBJ_ID_PKT: 7220146,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749056,
    WE_SAP_WE_ID_PKF: '7400/749056',
    WE_SAP_WE_BEZEICHNUNG: 'Beim Pachthof 15, 22111 Hamburg, 749056',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Beim Pachthof 15',
    WE_PLZ: '22111',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7531127,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749047,
    WE_SAP_WE_ID_PKF: '7400/749047',
    WE_SAP_WE_BEZEICHNUNG: 'Krochmannstra?e 55, 22297 Hamburg, 749047',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Krochmannstra?e 55',
    WE_PLZ: '22297',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7239236,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749052,
    WE_SAP_WE_ID_PKF: '7400/749052',
    WE_SAP_WE_BEZEICHNUNG: 'Luruper Hauptstra?e 60, 22547 Hamburg, 749052',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Luruper Hauptstra?e 60',
    WE_PLZ: '22547',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7406178,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749054,
    WE_SAP_WE_ID_PKF: '7400/749054',
    WE_SAP_WE_BEZEICHNUNG: 'Max-Born-Stra?e 2-4, 22761 Hamburg, 749054',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Max-Born-Stra?e 2-4',
    WE_PLZ: '22761',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.23',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7507036,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749033,
    WE_SAP_WE_ID_PKF: '7400/749033',
    WE_SAP_WE_BEZEICHNUNG: 'Notkestra?e 3-5, 22607 Hamburg, 749033',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Notkestra?e 3-5',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7097072,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749039,
    WE_SAP_WE_ID_PKF: '7400/749039',
    WE_SAP_WE_BEZEICHNUNG: 'Notkestra?e 7, 22607 Hamburg, 749039',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Notkestra?e 7',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189765,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749049,
    WE_SAP_WE_ID_PKF: '7400/749049',
    WE_SAP_WE_BEZEICHNUNG: 'Ohrnsweg 52 a, 21037 Hamburg, 749049',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Ohrnsweg 52 a',
    WE_PLZ: '21037',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7252168,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749044,
    WE_SAP_WE_ID_PKF: '7400/749044',
    WE_SAP_WE_BEZEICHNUNG: 'Rugenbarg 270, 22549 Hamburg, 749044',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Rugenbarg 270',
    WE_PLZ: '22549',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189770,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749046,
    WE_SAP_WE_ID_PKF: '7400/749046',
    WE_SAP_WE_BEZEICHNUNG: 'Sophienterrasse 1a, 20149 Hamburg, 749046',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Sophienterrasse 1a',
    WE_PLZ: '20149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7237463,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749043,
    WE_SAP_WE_ID_PKF: '7400/749043',
    WE_SAP_WE_BEZEICHNUNG: 'Stiefm?tterchenweg 42-46, 22607 Hamburg, 749043',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Stiefm?tterchenweg 42-46',
    WE_PLZ: '22607',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7189769,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 7440147,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749059,
    WE_SAP_WE_ID_PKF: '7400/749059',
    WE_SAP_WE_BEZEICHNUNG: 'Tangstedter Landstr. 69, 749059',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Kommunalbau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Tangstedter Landstr. 69',
    WE_PLZ: '22415',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: null,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7545077,
    WE_RGN_OBJ_ID_FKT: 5418905,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747077,
    WE_SAP_WE_ID_PKF: '7400/747077',
    WE_SAP_WE_BEZEICHNUNG: 'Cuxhavener Stra?e 379, 21149 Hamburg, 747077',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Cuxhavener Stra?e 379',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7229122,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 7263376,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747079,
    WE_SAP_WE_ID_PKF: '7400/747079',
    WE_SAP_WE_BEZEICHNUNG: 'Cuxhavener Stra?e 380, 21149 Hamburg, 747079',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Cuxhavener Stra?e 380',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7229124,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: 7263375,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747078,
    WE_SAP_WE_ID_PKF: '7400/747078',
    WE_SAP_WE_BEZEICHNUNG: 'Fischbeker Schulstra?e 0, 21149 Hamburg, 747078',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Fischbeker Schulstra?e 0',
    WE_PLZ: '21149',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7229123,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 7263371,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 748003,
    WE_SAP_WE_ID_PKF: '7400/748003',
    WE_SAP_WE_BEZEICHNUNG: 'Julius-Ludowieg-Stra?e 89, 21073 Hamburg, 748003',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Julius-Ludowieg-Stra?e 89',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7439705,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074776,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747011,
    WE_SAP_WE_ID_PKF: '7400/747011',
    WE_SAP_WE_BEZEICHNUNG: 'Karl-Arnold-Ring 11, 21109 Hamburg, 747011',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Karl-Arnold-Ring 11',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7200606,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5895163,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 748001,
    WE_SAP_WE_ID_PKF: '7400/748001',
    WE_SAP_WE_BEZEICHNUNG: 'L?neburger Tor 10, 21073 Hamburg, 748001',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'L?neburger Tor 10',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.05.20',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7117145,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 7244385,
    WE_EN_1_OBJ_ID_FKT: 5305181,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 748002,
    WE_SAP_WE_ID_PKF: '7400/748002',
    WE_SAP_WE_BEZEICHNUNG: 'Marienstr.68a&68b,Barlachstr. 16, 21073 Hamburg, 748002',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Marienstr.68a&68b',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7390139,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 2074776,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747080,
    WE_SAP_WE_ID_PKF: '7400/747080',
    WE_SAP_WE_BEZEICHNUNG: 'Neue Campusstra?e 0, 21073 Hamburg, 747080',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Neue Campusstra?e 0',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7250140,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331814,
    WE_EN_1_OBJ_ID_FKT: 7263372,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747076,
    WE_SAP_WE_ID_PKF: '7400/747076',
    WE_SAP_WE_BEZEICHNUNG: 'Neue Grundschulstra?e 0, 21073 Hamburg, 747076',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Neue Grundschulstra?e 0',
    WE_PLZ: '21073',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.03.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7229121,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 7263373,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7410,
    WE_SAP_WE_NUMMER: 747081,
    WE_SAP_WE_ID_PKF: '7400/747081',
    WE_SAP_WE_BEZEICHNUNG: 'Rahmwerder Stra?e 3, 21109 Hamburg - Mutter-Kind-M, 747081',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'S?d',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Rahmwerder Stra?e 3',
    WE_PLZ: '21109',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.21',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7371385,
    WE_RGN_OBJ_ID_FKT: 1799942,
    WE_OM_OBJ_ID_FKT: 5331870,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743088,
    WE_SAP_WE_ID_PKF: '7400/743088',
    WE_SAP_WE_BEZEICHNUNG: 'Armgartstra?e 24, 22087 Hamburg, 743088',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Armgartstra?e 24',
    WE_PLZ: '22087',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7437708,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743091,
    WE_SAP_WE_ID_PKF: '7400/743091',
    WE_SAP_WE_BEZEICHNUNG: 'Bei der Hauptfeuerwache 1, 20099 Hamburg, 743091',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Bei der Hauptfeuerwache 1',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: null,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7545215,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 7227538,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743070,
    WE_SAP_WE_ID_PKF: '7400/743070',
    WE_SAP_WE_BEZEICHNUNG: 'Berliner Tor 5, 20099 Hamburg, 743070',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Berliner Tor 5',
    WE_PLZ: '20099',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7062816,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749045,
    WE_SAP_WE_ID_PKF: '7400/749045',
    WE_SAP_WE_BEZEICHNUNG: 'Doggerbankweg 17, 21129 Hamburg, 749045',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Doggerbankweg 17',
    WE_PLZ: '21129',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7203212,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743090,
    WE_SAP_WE_ID_PKF: '7400/743090',
    WE_SAP_WE_BEZEICHNUNG: 'Grindelallee 9, 20146 Hamburg, 743090',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Grindelallee 9',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7439990,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 7227538,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749053,
    WE_SAP_WE_ID_PKF: '7400/749053',
    WE_SAP_WE_BEZEICHNUNG: 'Hagenbeckstra?e 124, 22527 Hamburg, 749053',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Hagenbeckstra?e 124',
    WE_PLZ: '22527',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.22',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7499346,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749036,
    WE_SAP_WE_ID_PKF: '7400/749036',
    WE_SAP_WE_BEZEICHNUNG: 'Holstenhofweg 85, 22043 Hamburg, 749036',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Holstenhofweg 85',
    WE_PLZ: '22043',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7142908,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749035,
    WE_SAP_WE_ID_PKF: '7400/749035',
    WE_SAP_WE_BEZEICHNUNG: 'Industriestra?e 57, 21107 Hamburg, 749035',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Industriestra?e 57',
    WE_PLZ: '21107',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7138956,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743089,
    WE_SAP_WE_ID_PKF: '7400/743089',
    WE_SAP_WE_BEZEICHNUNG: 'Lohbr?gger Kirchstra?e 65, 21033 Hamburg, 743089',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Lohbr?gger Kirchstra?e 65',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7437709,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743067,
    WE_SAP_WE_ID_PKF: '7400/743067',
    WE_SAP_WE_BEZEICHNUNG: 'Martin-Luther-King-Platz 6, 20146 Hamburg, 743067',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Martin-Luther-King-Platz 6',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6793122,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743068,
    WE_SAP_WE_ID_PKF: '7400/743068',
    WE_SAP_WE_BEZEICHNUNG: 'Neuer Jungfernstieg 21, 20354 Hamburg, 743068',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Neuer Jungfernstieg 21',
    WE_PLZ: '20354',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: '01.01.17',
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6772861,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7430,
    WE_SAP_WE_NUMMER: 749034,
    WE_SAP_WE_ID_PKF: '7400/749034',
    WE_SAP_WE_BEZEICHNUNG: 'Paul-Ehrlich-Str.2, 22763 Hamburg, 749034',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Paul-Ehrlich-Str.2',
    WE_PLZ: '22763',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7103378,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 5423111,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743087,
    WE_SAP_WE_ID_PKF: '7400/743087',
    WE_SAP_WE_BEZEICHNUNG: 'Rupertistra?e 67, 22609 Hamburg, 743087',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Rupertistra?e 67',
    WE_PLZ: '22609',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7437345,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743069,
    WE_SAP_WE_ID_PKF: '7400/743069',
    WE_SAP_WE_BEZEICHNUNG: 'Ulmenliet 20, 21033 Hamburg, 743069',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Ulmenliet 20',
    WE_PLZ: '21033',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 6960570,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: 6783127,
    WE_EN_1_OBJ_ID_FKT: 5418904,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'WE',
    WE_SAP_BUCHUNGSKREIS: 7400,
    WE_SAP_WE_GESCHAEFTSBEREICH: 7420,
    WE_SAP_WE_NUMMER: 743086,
    WE_SAP_WE_ID_PKF: '7400/743086',
    WE_SAP_WE_BEZEICHNUNG: 'Von-Melle-Park 3, 20146 Hamburg, 743086',
    WE_FM_DIENSTLEISTER: 'GMH',
    WE_REGION: 'Unibau',
    WE_BEZIRK: null,
    WE_STADTTEIL: null,
    WE_STRASSE_NR: 'Von-Melle-Park 3',
    WE_PLZ: '20146',
    WE_ORT: 'Hamburg',
    WE_BREITENGRAD_NOERDLICH: null,
    WE_LÄNGENGRAD_OESTLICH: null,
    WE_ANZAHL_SCHUELER: 1,
    WE_GUELTIG_J_N: 'J',
    WE_GUELTIG_VON: null,
    WE_GUELTIG_BIS: '31.12.99',
    WE_WE_OBJ_ID_PKT: 7437344,
    WE_RGN_OBJ_ID_FKT: 6993694,
    WE_OM_OBJ_ID_FKT: null,
    WE_EN_1_OBJ_ID_FKT: null,
    WE_EN_2_OBJ_ID_FKT: null,
    WE_EN_3_OBJ_ID_FKT: null,
  },
];

export const getWIrtschaftseinheitByID = (id: string) => {
  return C_V_STM_WE.find((value) => value.WE_SAP_WE_NUMMER === +id);
};

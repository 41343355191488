import { createSelector } from '@ngrx/store';

import { DashboardChartsData } from '@app/core/models/dashboard-charts.model';

import * as dashboardState from '@app/modules/dashboard/store';
import * as fromReducer from '@app/modules/dashboard/store/dashboard.reducer';
import { AnalyseSeriesModel } from '@core/models/analyse-series.model';
import { AnalyseNamedSeriesModel } from '@core/models/analyse-named-series.model';

const getDashboardState = createSelector(
  dashboardState.getDashboardModuleState,
  (state: dashboardState.DashboardModuleState) => state.dashboard
);

export const getDashboardData = createSelector(getDashboardState, fromReducer.getDataState);
export const getDashboardUI = createSelector(getDashboardState, fromReducer.getUiState);

export const isDashboardInitialized = createSelector(getDashboardUI, (state) => state.isDashboardInitialized);
export const getSelectedRegion = createSelector(getDashboardUI, (ui) => ui.selectedRegion.value);

export const getChartsData = createSelector(getDashboardData, getSelectedRegion, (data, region) => {
  const availableYears = ['2023', '2024', '2025'];
  let pieChartData = [];
  const barchartData = [];
  let sumMitProjekt = 0;
  let sumOhneProjekt = 0;

  if (data.chartData.investitionGesamt) {
    let filteredInvestitonGesamt: AnalyseSeriesModel[] = data.chartData.investitionGesamt;
    if (region && region !== '0')
      filteredInvestitonGesamt = filteredInvestitonGesamt.filter((value) => value.name === region);

    filteredInvestitonGesamt
      .filter((series) => series.extra[0]['value'] === 'true')
      .forEach((a) => (sumMitProjekt += +a.value));

    filteredInvestitonGesamt
      .filter((series) => series.extra[0]['value'] === 'false')
      .forEach((a) => (sumOhneProjekt += +a.value));

    pieChartData = [
      { name: 'In Projekten', value: sumMitProjekt },
      {
        name: 'Ohne Projekt',
        value: sumOhneProjekt,
      },
    ];
  }

  if (data.chartData.investitionJahr) {
    availableYears.forEach((year) => {
      const filteredInvestitionJahr: AnalyseNamedSeriesModel[] = data.chartData.investitionJahr.filter(
        (value) => value.name === year
      );

      sumMitProjekt = 0;
      sumOhneProjekt = 0;
      filteredInvestitionJahr.forEach((seriesTop) => {
        seriesTop.series
          .filter((seriesSub) => seriesSub.extra[0]['value'] === 'true')
          .forEach((a) => {
            if ((region && region !== '0' && a.name === region) || !region || region === '0') sumMitProjekt += +a.value;
          });
        seriesTop.series
          .filter((seriesSub) => seriesSub.extra[0]['value'] === 'false')
          .forEach((a) => {
            if ((region && region !== '0' && a.name === region) || !region || region === '0')
              sumOhneProjekt += +a.value;
          });

        barchartData.push({
          name: year,
          series: [
            { name: 'In Projekten', value: sumMitProjekt },
            { name: 'Ohne Projekt', value: sumOhneProjekt },
          ],
        });
      });
    });
  }

  const result: DashboardChartsData = {
    pieChart: pieChartData,
    barChart: barchartData,
    investitionGesamt: [],
    investitionJahr: [],
    referenzwerte: [],
  };

  return result;
});

export const getRegionen = createSelector(getDashboardData, ({ chartData, regionen }) => {
  //const regionIdList = [...new Set(charts.map((item) => item.regionID))];
  //const regionList = regionen.filter((r) => regionIdList.includes(r.id) || r.id === '0');

  //console.log(chartData);

  //return regionList;
  return regionen;
});

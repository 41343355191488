import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FavoritesConverter } from '../converters/favorites-converter.service';
import { FavoriteModel } from '../models/favorite.model';
import { AnwenderService, JsonPatchDocument } from '../portal-api';

@Injectable()
export class FavoritesService {
  constructor(private anwenderService: AnwenderService, private converter: FavoritesConverter) {}

  loadFavorites(userID: string): Observable<FavoriteModel[]> {
    return this.anwenderService.getAnwenderFavoriten(userID).pipe(
      map((res) => {
        const favorites = [];
        res.data.forEach((c, index) => {
          favorites[index] = this.converter.convertFromApiModel(c);
        });

        return favorites.filter(Boolean);
      })
    );
  }

  addFavorite(userID: string, favorite: FavoriteModel) {
    const tempFavorite = this.converter.convertToApiModel(favorite);
    return this.anwenderService.postAnwenderFavorit(userID, tempFavorite);
  }

  deleteFavorite(userID: string, favID: string) {
    const body: JsonPatchDocument[] = [
      {
        op: JsonPatchDocument.OpEnum.Remove,
        path: `${favID}`,
      },
    ];
    return this.anwenderService.patchAnwenderFavoriten(userID, body);
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Bildet einen Favoriten eines Anwenders ab
 */
export interface AnwenderFavorit { 
    /**
     * Eindeutige ID des Favoriten (UUID)
     */
    favoritID?: string;
    /**
     * Der Referenz Typ des Favoriten
     */
    referenzTyp: string;
    /**
     * Die Referenz Struktur mit allen relevanten Attributen (JSON)
     */
    referenzStruktur: string;
    /**
     * Die Beschreibungs-Struktur mit allen relevanten Attributen (JSON)
     */
    beschreibung: string;
    /**
     * timestamp
     */
    erstelltAm?: string;
}


import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserConfigConverter } from '@core/converters/user-config-converter.service';
import { KonfigurationService, ResponseObject } from '@core/portal-api';
import {
  DatatableConfig,
  DatatableConfigState,
  GebaeudeColumnsConfig,
  GEBAEUDE_TABLE_CONFIG_ID,
  InvestitionColumnsConfig,
  INVESTITIONEN_TABLE_CONFIG_ID,
  ProjekteColumnsConfig,
  PROJEKTE_TABLE_CONFIG_ID,
} from '@core/models/datatable-config.model';

@Injectable()
export class UserConfig {
  constructor(private configService: KonfigurationService, private converter: UserConfigConverter) {}

  getUserConfig(userID: string): Observable<DatatableConfigState> {
    return this.configService.getConfigDatatable(userID).pipe(
      map((res) => {
        const result: DatatableConfigState = {
          gebaeude: undefined,
          investition: undefined,
          projekte: undefined,
        };
        for (const d of res.data) {
          const c = this.converter.convertFromApiModel(d);
          switch (d.datatableID) {
            case INVESTITIONEN_TABLE_CONFIG_ID: {
              if (Array.isArray(c)) {
                result.investition = {
                  columns: c,
                  configID: d.configID,
                };
              } else {
                result.investition = { ...c, configID: d.configID } as InvestitionColumnsConfig;
              }
              break;
            }
            case GEBAEUDE_TABLE_CONFIG_ID: {
              if (Array.isArray(c)) {
                result.gebaeude = {
                  columns: c,
                  configID: d.configID,
                };
              } else {
                result.gebaeude = { ...c, configID: d.configID } as GebaeudeColumnsConfig;
              }
              break;
            }
            case PROJEKTE_TABLE_CONFIG_ID: {
              result.projekte = { ...c, configID: d.configID } as ProjekteColumnsConfig;
              break;
            }
            default: {
              throw new Error('Config type not found');
            }
          }
        }

        return result;
      })
    );
  }

  saveUserConfig(config: DatatableConfig): Observable<ResponseObject> {
    const t = {
      ...config.configValue,
      columns: config.configValue.columns.filter((s) => s.prop),
    };
    const result = this.converter.convertToApiModel(t);
    const tempConfig = {
      ...result,
      userID: config.userID,
      datatableID: config.datatableID,
      configID: config.configID,
    };

    if (config.configID) {
      return this.configService.putConfigDatatable(tempConfig.configID, tempConfig);
    } else {
      return this.configService.postConfigDatatable(tempConfig);
    }
  }
}

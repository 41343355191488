/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Eine Meldung, die vom Anwender initiiert wird
 */
export interface ProblemMeldung { 
    /**
     * Der Problem Level
     */
    problemLevel?: ProblemMeldung.ProblemLevelEnum;
    /**
     * Die ID des Moduls, in welchem das Problem aufgetreten ist
     */
    modulID: string;
    /**
     * Der Kontext, in welchem das Problem aufgetreten ist
     */
    meldungKontext?: ProblemMeldung.MeldungKontextEnum;
    /**
     * Die ID des Objektes, in dessen Kontext das Problem aufgetreten ist
     */
    kontextObjektID?: string;
    /**
     * Der Titel der Meldung als Base64 String
     */
    meldungTitel: string;
    /**
     * Der Text der Meldung als Base64 String
     */
    meldungText: string;
    /**
     * Der Link zur Meldung als Base64 String
     */
    meldungLink?: string;
}
export namespace ProblemMeldung {
    export type ProblemLevelEnum = 'FEHLER' | 'WARNUNG' | 'HINWEIS';
    export const ProblemLevelEnum = {
        Fehler: 'FEHLER' as ProblemLevelEnum,
        Warnung: 'WARNUNG' as ProblemLevelEnum,
        Hinweis: 'HINWEIS' as ProblemLevelEnum
    };
    export type MeldungKontextEnum = 'WIRTSCHAFTSEINHEIT' | 'GEBAEUDE' | 'INVESTITION' | 'PROJEKT' | 'DASHBOARD' | 'BERECHTIGUNG';
    export const MeldungKontextEnum = {
        Wirtschaftseinheit: 'WIRTSCHAFTSEINHEIT' as MeldungKontextEnum,
        Gebaeude: 'GEBAEUDE' as MeldungKontextEnum,
        Investition: 'INVESTITION' as MeldungKontextEnum,
        Projekt: 'PROJEKT' as MeldungKontextEnum,
        Dashboard: 'DASHBOARD' as MeldungKontextEnum,
        Berechtigung: 'BERECHTIGUNG' as MeldungKontextEnum
    };
}



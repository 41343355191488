/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * taskinfo webMethods task Object
 */
export interface TaskInfo { 
    taskID?: string;
    status?: string;
    taskBezeichnung?: string;
    /**
     * EC8DC4CB-856F-4E66-8D38-6B3552665E44 = Genehmigung erfasse, 00DC7663-1909-FF7E-DF95-1B8B4094AE91 = Projekrnummer erfassen
     */
    taskArtID?: TaskInfo.TaskArtIDEnum;
    faelligkeit?: string;
    absender?: string;
    absenderName?: string;
    empfaenger?: string;
    empfaengerName?: string;
    weId?: string;
    weBezeichnung?: string;
}
export namespace TaskInfo {
    export type TaskArtIDEnum = '00DC7663-1909-FF7E-DF95-1B8B4094AE91' | 'EC8DC4CB-856F-4E66-8D38-6B3552665E44';
    export const TaskArtIDEnum = {
        _00Dc76631909Ff7EDf951B8B4094Ae91: '00DC7663-1909-FF7E-DF95-1B8B4094AE91' as TaskArtIDEnum,
        Ec8Dc4Cb856F4E668D386B3552665E44: 'EC8DC4CB-856F-4E66-8D38-6B3552665E44' as TaskArtIDEnum
    };
}



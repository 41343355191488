<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Workflow Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-12 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Name</strong>
      </div>
      <p>{{ selectedWorkflow.kurzbeschreibung }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Status</strong>
      </div>
      <p>{{ selectedWorkflow.status }}</p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Erstellt am</strong>
      </div>
      <p>{{ selectedWorkflow.erstellungsDatum | date }}</p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="d-flex align-items-center">
        <strong>Endet am</strong>
      </div>
      <p>{{ selectedWorkflow.faelligkeitsDatum | date }}</p>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-2" *ngIf="selectedWorkflow.subtasks; else noSubtasks">
    <p class="mt-3">Dieser Workflow enthält folgende Unteraufgaben {{ getSubtaskStatus(selectedWorkflow) }}</p>
    <div *ngFor="let s of selectedWorkflow.subtasks">
      <div class="d-flex align-items-center">
        <mat-icon style="color: #0085c0">subdirectory_arrow_right</mat-icon>
        <div class="text-truncate ml-2">
          <span class="custom-subtask-header">{{ s.kurzbeschreibung }}</span>
          <p class="mb-0 text-truncate">
            <i>Fällig am: {{ s.faelligkeitsDatum | date }} / Status: {{ s.status }}</i>
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noSubtasks>
    <div class="alert alert-warning mt-4" role="alert">Dieser Workflow enthält keine Unteraufgaben</div>
  </ng-template>

  <div class="modal-footer mt-4">
    <button type="button" mat-raised-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
      Schliessen
    </button>
  </div>
</div>

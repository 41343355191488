import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '@core/store/actions/chatbot.actions';
import { ChatbotStateModel } from '@core/models/chatbot-state.model';

const initialState: ChatbotStateModel = {
  gruppen: [],
  konversationen: [],
};

const chatbotReducer = createReducer(
  initialState,
  on(fromActions.loadChatbotGruppenSuccess, (state, { gruppen }) => ({ ...state, gruppen: gruppen })),
  on(fromActions.initInitialKonversation, (state) => ({
    ...state,
    konversationen: [...state.konversationen, { id: 'initial', messages: [] }],
  })),
  on(fromActions.clearInitialKonversation, (state) => {
    return {
      ...state,
      konversationen: [{ id: 'initial', messages: [] }],
    };
  }),
  on(fromActions.addMessageToKonversation, (state, { message }) => {
    let newKonversationen = [...state.konversationen];
    let newKonversation = newKonversationen[0];
    const newMessages = newKonversation.messages;
    const newMessagesPlus = [...newMessages, message];
    newKonversation = { id: 'initial', messages: newMessagesPlus };
    newKonversationen = [newKonversation];
    return {
      ...state,
      konversationen: newKonversationen,
    };
  })
);

export function reducer(state: ChatbotStateModel | undefined, action: Action) {
  return chatbotReducer(state, action);
}

export const getChatbotGruppen = (state: ChatbotStateModel) => state.gruppen;

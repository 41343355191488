/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoorProjekt { 
    Aktion?: CoorProjekt.AktionEnum;
    TMP_Projekt_Nr?: string;
    Projektbezeichnung?: string;
    Begruendung_Ausnahme?: string;
    Projektart?: string;
    Angebotspreis_Brutto?: number;
    Angebotspreis_Netto?: number;
    Richtwert_Intern_Brutto?: number;
    Richtwert_Intern_Netto?: number;
    Richtwert_Extern_Brutto?: number;
    Richtwert_Extern_Netto?: number;
    Projektflaeche_Gesamt?: number;
    Baubeginn?: string;
    Bauende?: string;
    Projektsteuerer?: string;
    /**
     * Liste von IDs der vohaben die diesem Projekt zugeordnet werden
     */
    vohabenIdList?: Array<string>;
}
export namespace CoorProjekt {
    export type AktionEnum = 'ANLEGEN' | 'AENDERN';
    export const AktionEnum = {
        Anlegen: 'ANLEGEN' as AktionEnum,
        Aendern: 'AENDERN' as AktionEnum
    };
}



import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DokumenteService } from '@core/portal-api';
import { DokumentGruppeConverter } from '@core/converters/dokument-gruppe-converter.service';
import { DokumentGruppeModel } from '@core/models/dokument-gruppe.model';

@Injectable()
export class DokumentGruppeService {
  constructor(private dokumenteService: DokumenteService, private dokumentGruppeConverter: DokumentGruppeConverter) {}

  getAllDokumentGruppenByModule(moduleID: string): Observable<DokumentGruppeModel[]> {
    return this.dokumenteService.getDokumentgruppen(moduleID).pipe(
      map((res) => {
        const dokumentGruppeList: DokumentGruppeModel[] = [];

        res.data.forEach((serverModel) => {
          const model: DokumentGruppeModel = this.dokumentGruppeConverter.convertFromApiModel(serverModel);
          dokumentGruppeList.push(model);
        });
        return dokumentGruppeList;
      })
    );
  }
}

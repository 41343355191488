import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InvestitionTechnical } from '@core/models/investition-technical.model';
import { getWIrtschaftseinheitByID } from '@core/constants/C_V_STM_WE';
import { getGebaeudeByID } from '@core/constants/C_V_STM_GB';
import { getSchuleByID } from '@core/constants/C_V_STM_EN';
import { PredictionResponseModel } from '@core/models/prediction-response.model';
import { PredictionRequestModel } from '@core/models/prediction-request.model';
import { getRegionByID } from '@core/constants/C_V_STM_RGN';
import { getGebaeudeTypByName } from '@core/constants/C_V_STM_GTY';
import { getBezirkByName } from '@core/constants/C_V_STM_BZK';

@Injectable()
export class KostenPrognoseService {
  subscription$: Subscription = new Subscription();

  constructor(private httpService: HttpClient) {}

  getKostenprognose(dto: InvestitionTechnical, weID: string): Observable<PredictionResponseModel> {
    const predict$: Observable<PredictionResponseModel> = this.httpService.post(
      'http://78.47.4.98:8081/api/predict',
      this.mapToPredictionRequestModel(dto, weID),
      {
        responseType: 'json',
        withCredentials: false,
      }
    );

    this.subscription$.add(
      predict$.subscribe((value) => {
        console.log(value);
      })
    );

    return predict$;
  }

  mapToPredictionRequestModel(dto: InvestitionTechnical, weID: string): PredictionRequestModel {
    const wirtschaftseinheit = getWIrtschaftseinheitByID(weID);
    const region = getRegionByID(wirtschaftseinheit.WE_RGN_OBJ_ID_FKT);
    const bezirk = getBezirkByName(wirtschaftseinheit.WE_BEZIRK);
    const gebaeude = getGebaeudeByID(dto.gebaeude);
    const gebaeudeTyp = gebaeude ? getGebaeudeTypByName(gebaeude.GB_GEBAEUDETYP) : null;
    const schule = getSchuleByID(dto.schule);

    const d: PredictionRequestModel = {
      GB_NETTOGRUNDFL_NGF: gebaeude && gebaeude.GB_NETTOGRUNDFL_NGF ? gebaeude.GB_NETTOGRUNDFL_NGF : 0,
      GB_NUTZFL_NF_VON_NGF: gebaeude && gebaeude.GB_NUTZFL_NF_VON_NGF ? gebaeude.GB_NUTZFL_NF_VON_NGF : 0,
      GB_TECHNIKFL_TF_VON_NGF: gebaeude && gebaeude.GB_TECHNIKFL_TF_VON_NGF ? gebaeude.GB_TECHNIKFL_TF_VON_NGF : 0,
      GB_VERKEHRSFL_VF_VON_NGF: gebaeude && gebaeude.GB_VERKEHRSFL_VF_VON_NGF ? gebaeude.GB_VERKEHRSFL_VF_VON_NGF : 0,
      GB_GEBAEUDE_KLASSE: gebaeude ? gebaeude.GB_GEBAEUDE_KLASSE : null,
      GB_GEBAEUDE_KLASSE_ALT: gebaeude ? gebaeude.GB_GEBAEUDE_KLASSE_ALT : null,
      GB_BAUJAHR: gebaeude ? gebaeude.GB_BAUJAHR : null,
      VH_Projektart_ID: dto.projektart,
      VH_Projektflaeche_gesamt: dto.flaechenAnsatz,
      VH_Baubeginn: '' + dto.baubeginn,
      VH_Baufertigstellung: '' + dto.bauFertigstellung,
      VH_Gebaeudeklasse_Zukunft: dto.gebaeudeKlasseZukunft,
      VH_Bezirk_HH_ID: bezirk ? bezirk.BZK_NUMMER_PKF : null,
      VH_Region_ID: region.RGN_NUMMER,
      VH_Gebaeudetyp_ID: gebaeudeTyp ? gebaeudeTyp.GTY_GTY_OBJ_ID_PKF : null,
      VH_Schulart_BSB_ID: schule ? schule.EN_SCHULE_ART : null,
      VH_Schulform_BSB_ID: schule ? schule.EN_SCHULE_FORM : null,
      VH_Nutzer: dto.kunde,
      VH_UnterProgrammart_ID: dto.unterprogrammart,
      VH_Auspraegung_ID: dto.auspraegungUnterprogrammart,
      WE_FM_DIENSTLEISTER: wirtschaftseinheit.WE_FM_DIENSTLEISTER,
      WE_REGION: wirtschaftseinheit.WE_REGION,
      WE_BEZIRK: wirtschaftseinheit.WE_BEZIRK,
      WE_STADTTEIL: wirtschaftseinheit.WE_STADTTEIL,
      WE_PLZ: wirtschaftseinheit.WE_PLZ,
      WE_BREITENGRAD_NOERDLICH: +wirtschaftseinheit.WE_BREITENGRAD_NOERDLICH,
      WE_LAENGENGRAD_OESTLICH: +wirtschaftseinheit.WE_LÄNGENGRAD_OESTLICH,
      EN_SCHULE_NR_PKF: schule ? '' + schule.EN_SCHULE_NR_PKF : null,
      EN_SCHULE_BEZEICHNUNG: schule ? schule.EN_SCHULE_BEZEICHNUNG : null,
      EN_SCHULE_STRASSE_NR: schule ? schule.EN_SCHULE_STRASSE_NR : null,
      EN_SCHULE_PLZ: schule ? schule.EN_SCHULE_PLZ : null,
      EN_SCHULE_ORT: schule ? schule.EN_SCHULE_ORT : null,
      EN_SCHULE_SEPL_REGION: schule ? '' + schule.EN_SCHULE_SEPL_REGION : null,
      EN_SCHULE_BSB_KAPITEL: schule ? '' + schule.EN_SCHULE_BSB_KAPITEL : null,
      EN_SCHULE_EINRICHTUNGSART: schule ? schule.EN_SCHULE_EINRICHTUNGSART : null,
      EN_SCHULE_ART: schule ? schule.EN_SCHULE_ART : null,
      EN_SCHULE_FORM: schule ? schule.EN_SCHULE_FORM : null,
      EN_EN_OBJ_ID_PKT: schule ? '' + schule.EN_EN_OBJ_ID_PKT : null,
      EN_SCHULE_ART_OBJ_ID_FKT: schule ? '' + schule.EN_SCHULE_ART_OBJ_ID_FKT : null,
      EN_SCHULE_FORM_OBJ_ID_FKT: schule ? '' + schule.EN_SCHULE_FORM_OBJ_ID_FKT : null,
    };

    console.log(d);

    return d;
  }
}

import { createAction, props } from '@ngrx/store';

import { Wirtschaftseinheit } from '@app/core/models/wirtschaftseinheit.model';
import { Investition } from '@app/core/models/investition.model';
import { InvestitionTechnical } from '@app/core/models/investition-technical.model';
import { InvestitionPriceOffer } from '@app/core/models/project-order-price-offer.model';
import { PredictionResponseModel } from '@core/models/prediction-response.model';

export const showInvestitionAction = createAction(
  '[Investition] Show investition',
  props<{ we?: Wirtschaftseinheit; pOrder?: Investition }>()
);

export const loadInvestition = createAction(
  '[Investition] Load investition with ID',
  props<{ investitionID: string }>()
);
export const loadInvestitionSuccess = createAction(
  '[Investition] Load investition with ID success',
  props<{ po: Investition }>()
);
export const loadInvestitionFailure = createAction(
  '[Investition] Load investition with ID fail',
  props<{ error: TypeError; objektID: string }>()
);

export const loadInvestitionTechnical = createAction(
  '[Investition] Load technical investition with ID',
  props<{ investitionID: string }>()
);
export const loadInvestitionTechnicalSuccess = createAction(
  '[Investition] Load technical investition  with ID successfully',
  props<{ investition: InvestitionTechnical }>()
);
export const loadInvestitionTechnicalFailure = createAction(
  '[Investition] Load technical investition with ID failed',
  props<{ error: TypeError; objektID: string }>()
);

export const createNewInvestition = createAction(
  '[Investition] Create new investition',
  props<{ dto: InvestitionTechnical }>()
);
export const createNewInvestitionFailure = createAction('[Investition] Create new investition failed');
export const createNewInvestitionSuccess = createAction('[Investition] Create new investition successfully');

export const editInvestition = createAction('[Investition] Edit investition');
export const validateInvestition = createAction('[Investition] Validate investition');
export const saveInvestition = createAction(
  '[Investition] Save investition changes',
  props<{ dto: InvestitionTechnical }>()
);

export const saveInvestitionSuccess = createAction('[Investition] Save investition changes successfully');

export const saveInvestitionFailure = createAction(
  '[Investition] Save investition changes failed',
  props<{ err: unknown }>()
);

export const deleteInvestitionWithID = createAction(
  '[Investition] Delete investition with ID',
  props<{ id: string }>()
);
export const deleteInvestitionWithIDSuccess = createAction(
  '[Investition] Delete investition with ID success',
  props<{ weID: string }>()
);
export const deleteInvestitionWithIDFail = createAction('[Investition] Delete investition with ID fail');

export const deleteInvestition = createAction('[Investition] Delete investition');
export const deleteInvestitionSuccess = createAction(
  '[Investition] Delete investition success',
  props<{ weID: string }>()
);
export const deleteInvestitionFail = createAction('[Investition] Delete investition fail');

export const abortInvestition = createAction('[Investition] Abort investition', props<{ dto: InvestitionTechnical }>());
export const abortInvestitionSuccess = createAction(
  '[Investition] Abort investition success',
  props<{ weID: string }>()
);
export const abortInvestitionFail = createAction('[Investition] Abort investition fail');

export const calculateInvestitionPrice = createAction(
  '[Investition] Calculate investition price',
  props<{ dto: InvestitionTechnical }>()
);
export const calculateInvestitionPriceSuccess = createAction(
  '[Investition] Calculate investition price success',
  props<{ priceOffer: InvestitionPriceOffer }>()
);
export const calculateInvestitionPriceFail = createAction(
  '[Investition] Calculate investition price fail',
  props<{ error: TypeError; objektID: string }>()
);

// Workaround until refactoring
export const resetPriceOffer = createAction('[Investition] Reset price offer');
export const resetState = createAction('[Investition] Reset State');

export const getKostenPrognose = createAction(
  '[Investition] Get kosten prognose',
  props<{ dto: InvestitionTechnical }>()
);
export const getKostenPrognoseSuccess = createAction(
  '[Investition] Get kosten prognose success',
  props<{ prognose: PredictionResponseModel }>()
);
export const getKostenPrognoseFail = createAction('[Investition] Get kosten prognose fail');

import { createAction, props } from '@ngrx/store';
import { SystemKonfigurationModel } from '@core/models/system-konfiguration.model';

export const loadSystemKonfiguration = createAction('[Core/Systemkonfiguration] Load Systemkonfiguration');

export const loadSystemKonfigurationSuccess = createAction(
  '[Core/Systemkonfiguration] Load Systemkonfiguration success',
  props<{ systemKonfiguration: SystemKonfigurationModel }>()
);

export const loadSystemKonfigurationFailure = createAction(
  '[Core/Systemkonfiguration] Load Systemkonfiguration failure',
  props<{ error: unknown }>()
);

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-modal-workflow',
  templateUrl: './modal-workflow.component.html',
  styleUrls: ['./modal-workflow.component.scss'],
})
export class ModalWorkflowComponent implements OnInit, AfterViewInit {
  selectedWorkflowType: string;

  referenzbildungAktiv = true;

  dateModel: NgbDateStruct;

  minDate: NgbDateStruct;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const now: Date = new Date();
    now.setDate(now.getDate() + 1);
    this.minDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  @ViewChild('workflowDropdown') workflowDropdown: MatSelect;
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.workflowDropdown.options.length === 1) this.workflowDropdown.options.first.select();
    });
  }

  onSave({ form }) {
    if (form.valid) {
      this.activeModal.close(form.value.endDate);
    }
  }

  onCancel() {
    this.activeModal.dismiss('cancel');
  }
}

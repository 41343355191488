import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import * as fromState from '@core/store/reducers';
import * as fromUserSelectors from '@core/store/selectors/user-profile.selectors';
import { AnwenderDataService } from '@core/services/anwender-data.service';
import * as fromActions from '@app/core/store/actions/anwender-data.actions';
import * as rechtematrixActions from '@app/core/store/actions/rechtematrix.actions';

@Injectable()
export class AnwenderDataEffects {
  constructor(
    private actions$: Actions,
    private anwenderDataService: AnwenderDataService,
    private store: Store<fromState.CoreModuleState>
  ) {}

  loadAnwenderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAnwenderData),
      withLatestFrom(this.store.select(fromUserSelectors.getUser), (_, user) => user),
      switchMap((user) =>
        this.anwenderDataService.getCurrentAnwenderData(user.emailAddress).pipe(
          switchMap((anwender) => [
            fromActions.loadAnwenderDataSuccess({ anwender }),
            rechtematrixActions.loadRechtematrix(),
          ]),
          catchError((error) => of(fromActions.loadAnwenderDataFailure({ error })))
        )
      )
    )
  );
}

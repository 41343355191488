import { createAction, props } from '@ngrx/store';
import { ChoiceListItem } from '@core/models/choice-list-item.model';
import { RahmenplanDetailsData } from '@core/models/rahmenplan-details-state.model';
import { Investition } from '@core/models/investition.model';
import { Umsatz } from '@core/models/umsatz.model';
import { WizardRule } from '@core/portal-api';

export const initRahmenplanModule = createAction('[Rahmenplan] Init Rahmenplan Module');

export const initRahmenplanModuleSuccess = createAction(
  '[Rahmenplan] Init Rahmenplan Module Success',
  props<{
    wirtschaftseinheitGroupData: RahmenplanDetailsData;
    projektarten: ChoiceListItem[];
    programmarten: ChoiceListItem[];
    unterprogrammarten: ChoiceListItem[];
    auspraegungUnterprogrammarten: ChoiceListItem[];
    gebaeudeKlassenZukunft: ChoiceListItem[];
    verfuegbareJahre: ChoiceListItem[];
    projektnummern: ChoiceListItem[];
    preisarten: ChoiceListItem[];
    finanzierungsarten: ChoiceListItem[];
    kunden: ChoiceListItem[];
    wizardrules: WizardRule[];
  }>()
);

export const initRahmenplanModuleFailure = createAction(
  '[Rahmenplan] Init Rahmenplan Module Failure',
  props<{ err?: unknown }>()
);

export const initWirtschaftseinheit = createAction('[Rahmenplan] Init Wirtschaftseinheit', props<{ weID: string }>());
export const initWirtschaftseinheitSuccess = createAction('[Rahmenplan] Init Wirtschaftseinheit Success');
export const initWirtschaftseinheitFailure = createAction(
  '[Rahmenplan] Init initWirtschaftseinheit Failure',
  props<{ error: TypeError }>()
);

import { KeycloakService } from 'keycloak-angular';
import { ConfigurationService } from '@core/config/configuration.service';

export function initializer(configService: ConfigurationService, keycloak: KeycloakService): () => Promise<unknown> {
  return (): Promise<unknown> => {
    return configService.loadAuthConfig().then(
      () =>
        new Promise(async (resolve, reject) => {
          try {
            const config = configService.getAuthConfig();

            await keycloak.init({
              config: {
                url: config.url,
                realm: config.realm,
                clientId: config.clientID,
              },
              initOptions: {
                // onLoad: 'check-sso',
                onLoad: 'login-required',
                checkLoginIframe: false,
              },
            });
            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
    );
  };
}

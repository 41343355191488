import { DecimalPipe } from '@angular/common';
import { TableColumn } from '@app/core/models/table-column.model';
import { SliderModel } from '@core/models/slider.model';
import { SliderFilterModel } from '@core/models/slider-filter.model';

export function filterResult<S>(array: S[], searchParameters: Map<string, string>): S[] {
  const ret = [];
  if (searchParameters.size > 0) {
    array.forEach((item) => {
      for (const key of searchParameters.keys()) {
        const searchValue = searchParameters.get(key);
        if (JSON.stringify(item[key]).toLowerCase().includes(searchValue) && !ret.includes(item)) {
          ret.push(item);
          break;
        }
      }
    });
  }
  return ret;
}

export function extractNonRepeatableValues(objList: unknown[], key: string): string[] {
  const valueList = [];
  objList.forEach((obj) => {
    if (obj[key] && !valueList.includes(obj[key])) {
      valueList.push(obj[key]);
    }
  });
  return valueList.sort();
}

export const compare = (v1: string | number, v2: string | number) => {
  if (!isNaN(Number(v1)) && !isNaN(Number(v2))) {
    return +v1 < +v2 ? -1 : +v1 > +v2 ? 1 : 0;
  }
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

export const filterFunction = <T>(
  items: T[],
  filter: { [key: string]: string[] | string },
  sliderFilter?: SliderFilterModel[]
): T[] => {
  let filteredItems = items.filter((item) => {
    // Flag ob eine Filterbedingung mit einem einzigen Textwert erfüllt ist
    let isStringKeySatisfied = true;
    // Flab ob eine Filterbedingung mit mehreren Textwerten erfüllt. True, wenn ein Textschlüssel im Datensatz enthält
    let isArrayKeySatisfied = false;
    // Flag ob alle Filterbedingungen gleichzeitig erfüllt sind
    let isItemSatisfied = true;
    // Flag ob Filterbedingung mit multiplen Textwerten existiert
    let existsArrayKey = false;

    for (const key of Object.keys(filter)) {
      const itemValue = String(item[key]).toLowerCase();
      isStringKeySatisfied = true;
      isArrayKeySatisfied = false;
      existsArrayKey = false;
      if (typeof filter[key] === 'string') {
        const searchValue = (filter[key] as string).toLowerCase();
        if (item[key] === undefined || !itemValue.includes(searchValue)) {
          isStringKeySatisfied = false;
        }
      } else if (filter[key] && filter[key].length === 0) {
        isArrayKeySatisfied = true;
      } else {
        existsArrayKey = true;
        for (const filterValue of filter[key]) {
          const searchValue = String(filterValue).toLowerCase();
          if ((item[key] === '' && searchValue === '') || (searchValue !== '' && itemValue.includes(searchValue))) {
            isArrayKeySatisfied = true;
            break;
          }
        }
      }
      if (!existsArrayKey) {
        isArrayKeySatisfied = true;
      }
      isItemSatisfied = isItemSatisfied && isStringKeySatisfied && isArrayKeySatisfied;
    }

    return isItemSatisfied;
  });

  if (sliderFilter !== undefined && sliderFilter !== null && sliderFilter.length > 0) {
    filteredItems = filteredItems.filter((item) => {
      let isSliderSatisfied = true;
      sliderFilter.forEach((entry) => {
        if (+item[entry.name] < entry.min || +item[entry.name] > entry.max) {
          isSliderSatisfied = false;
        }
      });
      return isSliderSatisfied;
    });
  }

  return filteredItems;
};

// Set a property type to optional
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const prepareFileToDownload = (content: string, fileName: string, mimeType: string): void => {
  const blob = b64toBlob(content, mimeType);
  const url = window.URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// convert Date object to string in the form of YYYY-MM-DD hh:mm:ss.nnn
export function formatDateTimeStringWithMilliSecond(date: Date): string {
  let ret = '';
  if (date) {
    ret += convertDateToIsoString(date);
    ret += ' ' + (date.getHours() > 9 ? date.getHours() : '0' + date.getHours());
    ret += ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes());
    ret += ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds());
    ret += '.' + date.getMilliseconds();
  }
  return ret;
}

// convert Date object to string in the form of YYYY-MM-DD
export function convertDateToIsoString(date: Date): string {
  let ret = '';
  if (date) {
    ret =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());
  }
  return ret;
}

// convert Date object to string in the form of DD.MM.YYYY
export function convertDateToString(date: Date): string {
  if (date) {
    return date.toLocaleString('de-DE', {
      day: '2-digit',
      year: 'numeric',
      month: '2-digit',
    });
  } else {
    return '';
  }
}

// convert string in the form of DD.MM.YYYY to a Date object
export function convertDateStringToDate(dateString: string): Date {
  const temp = dateString.split(/[.,\/ \.]/);
  return new Date(Number(temp[2]), Number(+temp[1] - 1), Number(temp[0]));
}

// convert string in the form of YYYY-MM-DD to a Date object
export function convertIsoDateStringToDate(dateString: string): Date {
  if (dateString) {
    const temp = dateString.split(/[.,\/ \-]/);
    return new Date(Number(temp[0]), Number(+temp[1] - 1), Number(temp[2]));
  } else {
    return null;
  }
}

// compare a given date string (DD.MM.YYYY) to today and calculate the difference in days
export function calculateDifferenceToTodayInDays(dateString: string): number {
  if (dateString) {
    const date = convertDateStringToDate(dateString);
    const today = new Date();
    const todayReference = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return (date.getTime() - todayReference.getTime()) / (1000 * 60 * 60 * 24);
  } else {
    return -1000;
  }
}

export function convertNumberToFormattedString(value): string {
  let stringValue = String(value);
  // value has german format
  if (/^\d{1,3}(\.\d{3})*(\,\d+)?$/.test(stringValue)) {
    stringValue = stringValue.replace(/\./gi, '').replace(',', '.');
  } else if (/,/.test(stringValue) && /^\d+(\.\d+)*(\,\d+)?$/.test(stringValue)) {
    stringValue = stringValue.replace(/\./gi, '').replace(',', '.');
  }

  const numberValue = Number(stringValue);
  if (!isNaN(numberValue)) {
    return new DecimalPipe('de-DE').transform(numberValue, '1.2-2');
  } else {
    return value;
  }
}

export const moveColumns = (array: TableColumn[], oldIndex: number, newIndex: number) => {
  const arr = [...array];
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * This function will go through the dropdown's history (The order of selection),
 * filter the data accordingly and save the options
 *
 * @param searchFilter The filter currently used on the array
 * @param data The data array after applying filter
 * @param touchedDropdowns A list of all currently used dropdowns. It represents the click history. Which dropdown was selected first
 * @param pristineDropdowns A list of unused dropdowns
 * @param acc A variable that will store the dropdown options
 * @returns The filtered dropdown options
 *
 * TODO Generate touchedDropdowns from the searchFilter Object
 */
export const generateOptions = (
  searchFilter: { [key: string]: string[] },
  data: unknown[],
  touchedDropdowns: string[],
  pristineDropdowns: string[],
  acc: { [key: string]: string[] }
) => {
  let arr = [...data];
  const tempOptions: { [key: string]: string[] } = {};
  const key = touchedDropdowns[0];
  const newTouched = [...touchedDropdowns];

  // Remove the oldest selected dropdown from the history
  newTouched.shift();

  // If a dropdown was selected
  if (key) {
    // Extract all possible value for the dropdown
    // TODO extract in a seperate function
    arr.forEach((project) => {
      if (!tempOptions[key]) {
        tempOptions[key] = [];
      }
      if (tempOptions[key].filter((i) => i === project[key]).length < 1 && project[key] !== null) {
        tempOptions[key].push(project[key]);
      }
    });

    // Filter the data array in order to remove non-existing values
    arr = filterFunction(arr, searchFilter);
  } else if (pristineDropdowns.length > 0) {
    data.forEach((project) => {
      for (const k of pristineDropdowns) {
        if (!tempOptions[k]) {
          tempOptions[k] = [];
        }
        if (tempOptions[k].filter((i) => i === project[k]).length < 1 && project[k] !== null) {
          tempOptions[k].push(project[k]);
        }
      }
    });
  }

  acc = { ...acc, ...tempOptions };

  if (key) {
    return generateOptions(searchFilter, arr, newTouched, pristineDropdowns, acc);
  }

  Object.keys(acc).forEach((key) => {
    acc[key].sort();
  });

  return acc;
};

export const generateSliders = (
  searchFilter: { [key: string]: string[] },
  data: any[],
  columns: TableColumn[] = []
) => {
  // Filter the data array in order to remove non-existing values
  let filteredData = [...data];
  filteredData = filterFunction(filteredData, searchFilter);

  const sliderList: SliderModel[] = [];

  columns.forEach((colum, index) => {
    const columnProp = colum.prop;
    const columnName = colum.name;

    const tmpArray = filteredData.filter((i) => i[columnProp] !== null && i[columnProp] !== undefined);

    const minValue = Math.floor(Math.min(...tmpArray.map((o) => o[columnProp])));
    const maxValue = Math.ceil(Math.max(...tmpArray.map((o) => o[columnProp])));

    const slider = {
      name: columnProp,
      displayName: columnName,
      floor: minValue,
      ceil: maxValue,
      // step: 1,
      animate: false,
      noSwitching: true,
      min: minValue,
      max: maxValue,
    };

    sliderList.push(slider);
  });

  return sliderList;
};

export const mergeArrays = <T>(array1: T[], array2: T[], key: string): T[] => {
  if (array1 && array2 && key) {
    const keys = new Set(array1.map((d) => d[key]));
    const merged = [...array1, ...array2.filter((d) => !keys.has(d[key]))];
    return merged;
  }
  return array1 || array2;
};

export const filterEnumValues = (e: { [key: string]: string }, exclude: string | string[]): string[] => {
  const result = Object.values(e).filter((v) => (typeof exclude === 'string' ? v !== exclude : !exclude.includes(v)));
  return result;
};

export const groupByCategory = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

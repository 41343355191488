/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Ein Dokument Eintrag
 */
export interface DokumentEintrag { 
    /**
     * Die ID des Eintrags
     */
    id?: string;
    /**
     * Der Name des Eintrags
     */
    name?: string;
    /**
     * Der Link zum Zieldokument
     */
    link?: string;
    /**
     * Die Beschreibung des Eintrags
     */
    beschreibung?: string;
    /**
     * Der Zoom Level fuer die Anzeige des Dokuments
     */
    zoomLevel?: string;
    /**
     * Die Anzahl der Subeintraege (Prozesse)
     */
    sequenzAnzahl?: number;
    /**
     * Die Position in der Liste von Eintraegen
     */
    position?: number;
}


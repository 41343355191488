import { createAction, props } from '@ngrx/store';
import { Gebaeudeanlage } from '@core/models/gebaeudeanlage.model';

export const loadGebaeudeList = createAction('[Gebaeude] Load gebaeude list');

export const loadGebaeudeListSuccess = createAction(
  '[Gebaeude] Load gebaeude list success',
  props<{ gebaeude: Gebaeudeanlage[] }>()
);

export const loadGebaeudeListFailure = createAction(
  '[Gebaeude] Load gebaeude list failure',
  props<{ error: TypeError }>()
);

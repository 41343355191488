import { NotificationsEffects } from '@app/core/store/effects/notifications.effects';
import { UserConfigEffects } from '@app/core/store/effects/user-config.effects';
import { UserProfileEffects } from '@app/core/store/effects/user-profile.effects';
import { AnwenderDataEffects } from '@core/store/effects/anwender-data.effects';
import { RechtematrixEffects } from '@core/store/effects/rechtematrix.effects';
import { FavoritesEffects } from '@core/store/effects/favorites.effects';
import { LogEintragEffects } from '@core/store/effects/log-eintrag.effects';
import { ProblemMeldungEffects } from '@core/store/effects/problem-meldung.effects';
import { SystemKonfigurationEffects } from '@core/store/effects/system-konfiguration.effects';
import { TasksEffects } from '@core/store/effects/tasks.effects';
import { ChatbotEffects } from '@core/store/effects/chatbot.effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const coreEffects: any[] = [
  NotificationsEffects,
  UserConfigEffects,
  UserProfileEffects,
  AnwenderDataEffects,
  RechtematrixEffects,
  FavoritesEffects,
  LogEintragEffects,
  ProblemMeldungEffects,
  SystemKonfigurationEffects,
  TasksEffects,
  ChatbotEffects,
];

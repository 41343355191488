import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { WirtschaftseinheitenObject } from '@core/portal-api';
import { Wirtschaftseinheit } from '@app/core/models/wirtschaftseinheit.model';

@Injectable()
export class WirtschaftseinheitConverter implements Converter<Wirtschaftseinheit, WirtschaftseinheitenObject> {
  convertFromApiModel(w: WirtschaftseinheitenObject): Wirtschaftseinheit {
    if (w === null || w === undefined) {
      return undefined;
    }

    const we: Wirtschaftseinheit = {
      id: w.id,
      name: w.name,
      gRegion: w.region,
      gBezirk: w.bezirk,
      seplRegion: w.seplRegion ? w.seplRegion.toString() : undefined,
      we: w.id,
      sapWeID: w.sapWeID,
      weObjektID: w.weObjektID,
      regionObjektID: w.regionObjektID,
      isGueltig: w.isGueltig,
      isFavorite: false,
    };
    return we;
  }
}

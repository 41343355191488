import { ValidationErrors } from 'ngrx-forms';

export const validNumberString = <T extends number | null | undefined>(value: T): ValidationErrors => {
  if (value && (/^\d{1,3}(\.\d{3})*(\,\d+)?$/.test(String(value)) || /^\d+$/.test(String(value)))) {
    return {};
  }

  return {
    validNumberString: {
      actual: value,
    },
  };
};

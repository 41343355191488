<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Als Favorit anlegen</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #favoriteDetails="ngForm" (ngSubmit)="onSave(favoriteDetails)" id="favoriteDetails">
    <mat-form-field appearance="outline" class="d-block w-75">
      <mat-label>Name</mat-label>
      <input matInput required name="name" [ngModel]="name" />

      <mat-error>Bitte prüfen Sie Ihre Eingabe.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block w-75">
      <mat-label>Beschreibung</mat-label>
      <input matInput name="description" [ngModel]="description" required />

      <mat-error>Bitte prüfen Sie Ihre Eingabe.</mat-error>
    </mat-form-field>

    <ng-container *ngIf="canSaveFilter">
      <mat-checkbox color="primary" name="filterAndLayout" ngModel>Ansicht speichern </mat-checkbox>
      <p class="d-block info-text ml-4">Speicherung aller Filter- und Layout-Einstellungen</p>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button type="button" mat-stroked-button color="primary" class="mat-elevation-z2 mr-2" (click)="onCancel()">
    abbrechen
  </button>
  <button mat-raised-button color="primary" type="submit" form="favoriteDetails">speichern</button>
</div>

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Bildet ein Gebaeude oder eine Außenanlge ab
 */
export interface GebaeudeAnlage { 
    /**
     * Die Gebaeude ID aus SAP
     */
    gebaeudeSapID?: string;
    /**
     * Die ID PKF des Gebaeudes
     */
    gebaeudeID?: string;
    /**
     * Bezeichnung aus SAP
     */
    gebaeudeBezeichnung?: string;
    /**
     * Der Typ des Gebaeudes
     */
    gebaeudeTyp?: string;
    /**
     * Die Art des Gebaeudes
     */
    gebaeudeArt?: string;
    /**
     * Das Baujahr
     */
    baujahr?: number;
    /**
     * Der noerdliche Breitengrad
     */
    breitengradNoerdlich?: string;
    /**
     * Der oestliche Laengengrad
     */
    laengengradOestlich?: string;
    /**
     * Die Nettogrundflaeche (decimal 22,2)
     */
    nettogrundflaeche?: number;
    /**
     * Die Nutzflaeche (decimal 22,2)
     */
    nutzflaeche?: number;
    /**
     * Die Technikflaeche (decimal 22,2)
     */
    technikflaeche?: number;
    /**
     * Die Verkehrsflaeche (decimal 22,2)
     */
    verkehrsflaeche?: number;
    /**
     * Die gesamte Mietflaeche (decimal 22,2)
     */
    mietflaecheGesamt?: number;
    /**
     * Die BSB Mietflaeche (decimal 22,2)
     */
    mietflaecheBSB?: number;
    /**
     * Die sonstige Mietflaeche (decimal 22,2)
     */
    mietflaecheSonstige?: number;
    /**
     * Die Klasse des Gebaeudes
     */
    gebaeudeKlasse?: string;
    /**
     * Das Datum der letzten aenderung der Gebaeudeklasse
     */
    gebaeudeKlasseSeit?: string;
    /**
     * Die Grund fuer die letzte aenderung der Gebaeudeklasse
     */
    gebaeudeKlasseGrund?: string;
    /**
     * Die vorherige Klasse des Gebaeudes
     */
    gebaeudeKlasseAlt?: string;
    /**
     * Flag ob Gebaeude aktuell gueltig ist
     */
    gueltigJN?: string;
    /**
     * Der Beginn der Gueltigkeit
     */
    gueltigVon?: string;
    /**
     * Das Ende der Gueltigkeit
     */
    gueltigBis?: string;
    /**
     * Die Nummer des Gebaeudes
     */
    gebaeudeNummer?: number;
    /**
     * Die ID der Wirtschaftseinheit aus SAP
     */
    weSapID?: string;
    /**
     * Die ID PKF der Wirtschaftseinheit
     */
    weID?: string;
    /**
     * Die Nummer der Wirtschaftseinheit
     */
    weNummer?: number;
    /**
     * Die Bezeichnung der Wirtschaftseinheit
     */
    weBezeichnung?: string;
    /**
     * Der FM Dienstleister der Wirtschaftseinheit
     */
    weFmDienstleister?: string;
    /**
     * Die Region der Wirtschaftseinheit
     */
    weRegion?: string;
    /**
     * Die ID der Region der Wirtschaftseinheit
     */
    weRegionID?: string;
    /**
     * Der Bezirk der Wirtschaftseinheit
     */
    weBezirk?: string;
    /**
     * Der Stadtteil der Wirtschaftseinheit
     */
    weStadtteil?: string;
    /**
     * Gibt an, ob das Gebaeude gueltig ist
     */
    isGueltig?: boolean;
}


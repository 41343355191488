import { AdministrationService, ResponseObject } from '@core/portal-api';
import { CacheInvalidateConverter } from '@core/converters/cache-invalidate-converter.service';
import { Observable, of } from 'rxjs';
import { CacheInvalidateModel } from '@core/models/cache-invalidate.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CachingService {
  constructor(private administrationService: AdministrationService, private converter: CacheInvalidateConverter) {}

  saveCacheInvalidate(cacheInvalidateModel: CacheInvalidateModel): Observable<ResponseObject> {
    const cacheInvalidate = this.converter.convertToApiModel(cacheInvalidateModel);

    //return of(null);
    return this.administrationService.postCacheInvalidate(cacheInvalidate);
  }
}

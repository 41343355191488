import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {
  transform(
    items: unknown[],
    key: string,
    value: string | string[] | boolean,
    reverse = false,
    applyFilter = true
  ): any {
    if (!items || !key || !value || !applyFilter) {
      return items;
    }

    const filteredItem = items.filter((item) => {
      switch (typeof value) {
        case 'boolean':
        case 'string': {
          if (reverse ? item[key] === value : item[key] !== value) {
            return false;
          }
        }

        default: {
          return true;
        }
      }

      /*
          case 'object': {
          if (reverse ? value.includes(item[key]) : !value.includes(item[key])) {
            return false;
          }
        }
      */
    });

    return filteredItem;
  }
}

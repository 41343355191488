/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Dataobjet fuer Umsaetze pro Jahr
 */
export interface Umsaetzeobject { 
    head?: string;
    we?: number;
    regionGesamt?: number;
    regionGesamtRef?: number;
    regionZubau?: number;
    regionUmbau?: number;
    regionSanierung?: number;
    regionZuschuss?: number;
    regionAbriss?: number;
}


/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Bildet eine Konfiguration fuer eine Datatable ab
 */
export interface DatatableConfig { 
    /**
     * Eindeutige ID der Konfiguration (UUID)
     */
    configID?: string;
    /**
     * Eindeutige ID des Nutzers
     */
    userID: string;
    /**
     * Eindeutige ID der Datatable
     */
    datatableID: string;
    /**
     * Konfiguration als Base64 encoded JSON String
     */
    configValue: string;
}


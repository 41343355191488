import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { SystemKonfiguration } from '@core/portal-api';
import { SystemKonfigurationModel } from '@core/models/system-konfiguration.model';

@Injectable()
export class SystemKonfigurationConverter implements Converter<SystemKonfigurationModel, SystemKonfiguration> {
  convertFromApiModel(systemKonfiguration: SystemKonfiguration): SystemKonfigurationModel {
    if (systemKonfiguration === null || systemKonfiguration === undefined) {
      return undefined;
    }

    const systemKonfigurationModel: SystemKonfigurationModel = {
      // modul swp
      wartungArbeitenAktiv: systemKonfiguration.modulSWP.wartungArbeitenAktiv,
      wartungArbeitenText: systemKonfiguration.modulSWP.wartungArbeitenText,
      berechtigungMatrixAktiv: systemKonfiguration.modulSWP.berechtigungMatrixAktiv,
      emailBenachrichtigungAktiv: systemKonfiguration.modulSWP.emailBenachrichtigungAktiv,
      problemMeldungAktiv: systemKonfiguration.modulSWP.problemMeldungAktiv,
      taskStartenAktiv: systemKonfiguration.modulSWP.taskStartenAktiv,
      workflowStartenAktiv: systemKonfiguration.modulSWP.workflowStartenAktiv,

      // modul dashboard
      modulDashboardAktiv: systemKonfiguration.modulDashboard.modulAktiv,
      tasksAnzeigenAktiv: systemKonfiguration.modulDashboard.tasksAnzeigenAktiv,
      workflowsAnzeigenAktiv: systemKonfiguration.modulDashboard.workflowsAnzeigenAktiv,
      favoritenAnzeigenAktiv: systemKonfiguration.modulDashboard.favoritenAnzeigenAktiv,
      monitorAnzeigenAktiv: systemKonfiguration.modulDashboard.monitorAnzeigenAktiv,

      // modul rpl
      modulRplAktiv: systemKonfiguration.modulRPL.modulAktiv,
      referenzbildungAktiv: systemKonfiguration.modulRPL.referenzbildungAktiv,
      investitionAnlegenAktiv: systemKonfiguration.modulRPL.investitionAnlegenAktiv,
      investitionBearbeitenAktiv: systemKonfiguration.modulRPL.investitionBearbeitenAktiv,
      investitionLoeschenAktiv: systemKonfiguration.modulRPL.investitionLoeschenAktiv,
      projektAnlegenAktiv: systemKonfiguration.modulRPL.projektAnlegenAktiv,
      projektBearbeitenAktiv: systemKonfiguration.modulRPL.projektBearbeitenAktiv,
      projekteAnzeigenAktiv: systemKonfiguration.modulRPL.projekteAnzeigenAktiv,
      umsaetzeAnzeigenAktiv: systemKonfiguration.modulRPL.umsaetzeAnzeigenAktiv,

      // modul gbd
      modulGbdAktiv: systemKonfiguration.modulGBD.modulAktiv,
      gebaeudeDetailsAktiv: systemKonfiguration.modulGBD.gebaeudeDetailsAktiv,
    };

    return systemKonfigurationModel;
  }

  convertToApiModel(systemKonfigurationModel: SystemKonfigurationModel): SystemKonfiguration {
    if (systemKonfigurationModel === null || systemKonfigurationModel === undefined) {
      return undefined;
    }

    const systemKonfiguration: SystemKonfiguration = {
      modulSWP: {
        wartungArbeitenAktiv: systemKonfigurationModel.wartungArbeitenAktiv,
        wartungArbeitenText: systemKonfigurationModel.wartungArbeitenText,
        berechtigungMatrixAktiv: systemKonfigurationModel.berechtigungMatrixAktiv,
        emailBenachrichtigungAktiv: systemKonfigurationModel.emailBenachrichtigungAktiv,
        problemMeldungAktiv: systemKonfigurationModel.problemMeldungAktiv,
        taskStartenAktiv: systemKonfigurationModel.taskStartenAktiv,
        workflowStartenAktiv: systemKonfigurationModel.workflowStartenAktiv,
      },
      modulDashboard: {
        modulAktiv: systemKonfigurationModel.modulDashboardAktiv,
        tasksAnzeigenAktiv: systemKonfigurationModel.tasksAnzeigenAktiv,
        workflowsAnzeigenAktiv: systemKonfigurationModel.workflowsAnzeigenAktiv,
        favoritenAnzeigenAktiv: systemKonfigurationModel.favoritenAnzeigenAktiv,
        monitorAnzeigenAktiv: systemKonfigurationModel.monitorAnzeigenAktiv,
      },
      modulRPL: {
        modulAktiv: systemKonfigurationModel.modulRplAktiv,
        referenzbildungAktiv: systemKonfigurationModel.referenzbildungAktiv,
        investitionAnlegenAktiv: systemKonfigurationModel.investitionAnlegenAktiv,
        investitionBearbeitenAktiv: systemKonfigurationModel.investitionBearbeitenAktiv,
        investitionLoeschenAktiv: systemKonfigurationModel.investitionLoeschenAktiv,
        projektAnlegenAktiv: systemKonfigurationModel.projektAnlegenAktiv,
        projektBearbeitenAktiv: systemKonfigurationModel.projektBearbeitenAktiv,
        projekteAnzeigenAktiv: systemKonfigurationModel.projekteAnzeigenAktiv,
        umsaetzeAnzeigenAktiv: systemKonfigurationModel.umsaetzeAnzeigenAktiv,
      },
      modulGBD: {
        modulAktiv: systemKonfigurationModel.modulGbdAktiv,
        gebaeudeDetailsAktiv: systemKonfigurationModel.gebaeudeDetailsAktiv,
      },
    };

    return systemKonfiguration;
  }
}

export interface NotificationModel {
  id: string;
  content: string;
  type: NotificationType;
}

export type NotificationType = 'ERROR' | 'SUCCESS';
export const NotificationType = {
  SUCCESS: 'SUCCESS' as NotificationType,
  ERROR: 'ERROR' as NotificationType,
};

import { createAction, props } from '@ngrx/store';
import { ChatbotGruppeModel } from '@core/models/chatbot-gruppe.model';
import { ChatbotMessageModel } from '@core/models/chatbot-message.model';

export const loadChatbotGruppen = createAction('[Core/Chatbot] Load chatbot gruppen');

export const loadChatbotGruppenSuccess = createAction(
  '[Core/Chatbot] Load chatbot gruppen success',
  props<{ gruppen: ChatbotGruppeModel[] }>()
);

export const loadChatbotGruppenFailure = createAction(
  '[Core/Chatbot] Load chatbot gruppen failure',
  props<{ error: unknown }>()
);

export const initInitialKonversation = createAction('[Core/Chatbot] Init initial konversation');

export const addMessageToKonversation = createAction(
  '[Core/Chatbot] Add message to konversation',
  props<{ message: ChatbotMessageModel }>()
);

export const clearInitialKonversation = createAction('[Core/Chatbot] Clear initial konversation');

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Dataobjet eines Gebaeudes
 */
export interface GebaudeObject { 
    /**
     * Eindeutige gebaeude identifikation innerhalb der Wirtschaftseinheit
     */
    gebaeudeId?: number;
    /**
     * Name der Schule
     */
    schulname?: string;
    /**
     * Gebaeude nummer innerhalb der Wirtschaftseinheit
     */
    gebaeudeNummer?: number;
    /**
     * Name des Gebaeudes
     */
    gebaeudeName?: string;
    /**
     * Type des Gebaeudes
     */
    gebaeudeType?: string;
    /**
     * Bemerkung zum Gebaeudes
     */
    bemerkung?: string;
    /**
     * Flaeche des Gebaeudes
     */
    gebaeudeflaeche?: number;
    /**
     * Gebaeude ID aus SAP
     */
    sapGebaeudeId?: string;
    /**
     * true, wenn Gebaeude ein Neubau ist
     */
    isNeubau?: boolean;
    /**
     * true, wenn Gebaeude gueltig ist
     */
    isGueltig?: boolean;
}


import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDashboard from './dashboard.reducer';

export interface DashboardModuleState {
  dashboard: fromDashboard.DashboardState;
}

export const getDashboardModuleReducers: ActionReducerMap<DashboardModuleState> = {
  dashboard: fromDashboard.reducer,
};

export const getDashboardModuleState = createFeatureSelector<DashboardModuleState>('Dashboard');

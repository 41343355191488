import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LogEintragModel } from '@core/models/log-eintrag.model';
import { LogEintragService } from '@core/services/log-eintrag.service';
import * as fromActions from '@core/store/actions/log-eintrag.action';
import * as fromGebaeudeAction from '@app/modules/gebaeude/store/actions/gebaeude.actions';
import * as fromGebaeudeDetailsAction from '@app/modules/gebaeude/store/actions/gebaeude-details.actions';
import * as fromRahmenplanDetailsAction from '@app/modules/rahmenplan/store/actions/rahmenplan-details.actions';
import * as fromInvestitionAction from '@app/modules/rahmenplan/store/actions/investition.actions';

@Injectable()
export class LogEintragEffects {
  constructor(private actions$: Actions, private logEintragService: LogEintragService) {}

  saveLogEintrag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveLogEintrag),
      map((action) => action.logEintrag),
      switchMap((logEintrag: LogEintragModel) =>
        this.logEintragService.saveLogEintrag(logEintrag).pipe(
          map(() => fromActions.saveLogEintragSuccess()),
          catchError((error) => of(fromActions.saveLogEintragFailure({ error })))
        )
      )
    )
  );

  handleGlobalErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromGebaeudeAction.loadGebaeudeListFailure,
        fromGebaeudeDetailsAction.loadGebaeudeFailure,
        fromRahmenplanDetailsAction.loadInvestitionListFail,
        fromRahmenplanDetailsAction.loadWeChoicelistsFail,
        fromRahmenplanDetailsAction.loadUmsaetzeFail,
        fromRahmenplanDetailsAction.loadWeGebaeudeListFail,
        fromInvestitionAction.loadInvestitionFailure,
        fromInvestitionAction.loadInvestitionTechnicalFailure,
        fromInvestitionAction.calculateInvestitionPriceFail
      ),
      switchMap((action) =>
        this.logEintragService.logFromGlobalError(action.error, action).pipe(
          map(() => fromActions.saveLogEintragSuccess()),
          catchError((error) => of(fromActions.saveLogEintragFailure({ error })))
        )
      )
    )
  );
}

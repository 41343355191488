import { Pipe, PipeTransform } from '@angular/core';
import { AnwenderDataModel } from '@app/core/models/anwender-data.model';

@Pipe({
  name: 'anwenderListFilterPipe',
  pure: false,
})
export class AnwenderListFilterPipe implements PipeTransform {
  transform(anwenderList: AnwenderDataModel[], anwenderListFilter: string) {
    const filteredAnwenderList: AnwenderDataModel[] = [];
    anwenderList.forEach((value) => {
      if (
        !anwenderListFilter ||
        value.email.toLocaleLowerCase().includes(anwenderListFilter.toLocaleLowerCase()) ||
        value.vorname.toLocaleLowerCase().includes(anwenderListFilter.toLocaleLowerCase()) ||
        value.nachname.toLocaleLowerCase().includes(anwenderListFilter.toLocaleLowerCase())
      )
        filteredAnwenderList.push(value);
    });
    return filteredAnwenderList;
  }
}

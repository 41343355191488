import { filterEnumValues } from '@app/shared/utils';

export enum Projektart {
  Zubau = '1',
  Ersatzbau = '2',
  Sanierung = '3',
  Umbau = '4',
  Sonderbestellung = '10',
  AbrissInvest = '14',
  InvestiveInstandhaltung = '15',
}

export enum Kunden {
  BSB = '1',
  HIBB = '2',
  BWFGB = '4',
  HSB = '5',
  LIG = '6',
  FW = '7',
  BA = '8',
  BIS = '9',
  SoV = '10',
  Dritte = '3',
}

export enum Programmart {
  Ganztagsfläche = '1',
  Unterrichtsräume = '2',
  Gemeinschaftsfläche = '3',
  Lehrerbedarf = '4',
  Zusatzfläche = '5',
  Mischfläche = '6',
  Außenfläche = '7',
  Sportfläche = '8',
  ForschungUndLehre = '9',
  GBSKüchen = '13',
  GuterGTS = '15',
  Gründach7MioProg = '17',
  AbrissSondervermögen = '18',
  BaureifmachungAnkGrundst = '20',
  Tranche1Barrierefreiheit4Mio = '21',
  Digitalpakt = '22',
  KonjunkturprogGTS = '24',
  BSBUmbau20212022 = '25',
  Tranche2Barrierefreiheit = '26',
  BEGNWGNeu = '27',
  BEGNWGSan = '28',
  Klimaplan21 = '29',
  PVAnlage = '30',
  BEGEM = '31',
  AußenanlagenNeueGrundst = '32',
  MobileKlassenräume = '33',
  Abmietung = '34',
  Verkauf = '35',
  RevolvFonds = '36',
  SportimmobilienGMH = '38',
  SonderimmobilienGMH = '39',
  NichtgeförderteAnlagen = '40',
  Klimaplan22 = '41',
  Kindergarten = '42',
  Klimaplan23 = '43',
  Klimaplan24 = '44',
  BSBUmbau20232024 = '45',
  BSBBasismittelprogramm = '46',
}

export enum Unterprogrammart {
  GanztagskücheAufwärmküche = '1',
  VitalkücheMultifunktion = '2',
  Vitalküchekonventionell = '3',
  ProduktionskücheVollangebot = '4',
  EinFeldsporthalle = '5',
  ZweiFeldsporthalle = '6',
  DreiFeldsporthalle = '7',
  Gymnastikhalle = '8',
  Bewegungsfläche = '9',
  ÜberdachteAußensportfläche = '10',
}

import { Injectable } from '@angular/core';
import { BerechtigungAnwenderConverter } from '../converters/berechtigung-anwender-converter.service';
import { BerechtigungAnwenderModel } from '../models/berechtigung-anwender.model';
import { BerechtigungService } from '../portal-api';

@Injectable()
export class BerechtigungAnwenderService {
  constructor(
    private berechtigungAnwenderService: BerechtigungService,
    private berechtigungAnwenderConverter: BerechtigungAnwenderConverter
  ) {}

  updateRolesBatch(rolesBatch: BerechtigungAnwenderModel) {
    const rolesBatchDTO = this.berechtigungAnwenderConverter.convertToApiModel(rolesBatch);

    return this.berechtigungAnwenderService.postBerechtigungAnwender(rolesBatchDTO);
  }
}

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ headerText }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()" *ngIf="cancelButtonText">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <p>{{ confirmationText }}</p>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    mat-stroked-button
    color="primary"
    class="mat-elevation-z2 mr-2"
    (click)="onCancel()"
    [disabled]="showSpinner$ | async"
    *ngIf="cancelButtonText"
  >
    {{ cancelButtonText }}
  </button>
  <button mat-raised-button color="primary" (click)="onSave()" [disabled]="showSpinner$ | async">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="showSpinner$ | async"></span>
    {{ confirmButtonText }}
  </button>
</div>

import { createAction, props } from '@ngrx/store';
import { LogEintragModel } from '@core/models/log-eintrag.model';

export const saveLogEintrag = createAction(
  '[Core/LogEintrag] Save log eintrag',
  props<{ logEintrag: LogEintragModel }>()
);

export const saveLogEintragSuccess = createAction('[Core/LogEintrag] Save log eintrag success');

export const saveLogEintragFailure = createAction(
  '[Core/LogEintrag] Save log eintrag failure',
  props<{ error: unknown }>()
);

import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { Gebaeudeanlage } from '@app/core/models/gebaeudeanlage.model';

import * as fromAPI from '@core/portal-api';

@Injectable()
export class GebaeudeanlageConverter implements Converter<Gebaeudeanlage, fromAPI.GebaeudeAnlage> {
  convertFromApiModel(gebaeudeAnlage: fromAPI.GebaeudeAnlage): Gebaeudeanlage {
    if (gebaeudeAnlage == null) {
      return undefined;
    }

    const gebaeude: Gebaeudeanlage = {
      id: gebaeudeAnlage.gebaeudeID,
      gebaeudeSapID: gebaeudeAnlage.gebaeudeSapID,
      gebaeudeBezeichnung: gebaeudeAnlage.gebaeudeBezeichnung,
      gebaeudeTyp: gebaeudeAnlage.gebaeudeTyp,
      gebaeudeArt: gebaeudeAnlage.gebaeudeArt,
      baujahr: gebaeudeAnlage.baujahr,
      breitengradNoerdlich: gebaeudeAnlage.breitengradNoerdlich,
      laengengradOestlich: gebaeudeAnlage.laengengradOestlich,
      nettogrundflaeche: gebaeudeAnlage.nettogrundflaeche,
      nutzflaeche: gebaeudeAnlage.nutzflaeche,
      technikflaeche: gebaeudeAnlage.technikflaeche,
      verkehrsflaeche: gebaeudeAnlage.verkehrsflaeche,
      mietflaecheGesamt: gebaeudeAnlage.mietflaecheGesamt,
      mietflaecheBSB: gebaeudeAnlage.mietflaecheBSB,
      mietflaecheSonstige: gebaeudeAnlage.mietflaecheSonstige,
      gebaeudeKlasse: gebaeudeAnlage.gebaeudeKlasse,
      gebaeudeKlasseSeit: gebaeudeAnlage.gebaeudeKlasseSeit,
      gebaeudeKlasseGrund: gebaeudeAnlage.gebaeudeKlasseGrund,
      gebaeudeKlasseAlt: gebaeudeAnlage.gebaeudeKlasseAlt,
      gueltigJN: gebaeudeAnlage.gueltigJN,
      gueltigVon: gebaeudeAnlage.gueltigVon,
      gueltigBis: gebaeudeAnlage.gueltigBis,
      gebaeudeNummer: gebaeudeAnlage.gebaeudeNummer,
      weSapID: gebaeudeAnlage.weSapID,
      weID: gebaeudeAnlage.weID,
      weNummer: gebaeudeAnlage.weNummer,
      weBezeichnung: gebaeudeAnlage.weBezeichnung,
      weFmDienstleister: gebaeudeAnlage.weFmDienstleister,
      weRegion: gebaeudeAnlage.weRegion,
      weRegionID: gebaeudeAnlage.weRegionID,
      weBezirk: gebaeudeAnlage.weBezirk,
      weStadtteil: gebaeudeAnlage.weStadtteil,
      isGueltig: gebaeudeAnlage.isGueltig,
    };

    return gebaeude;
  }
}

import { formatDate } from '@angular/common';
import { formatDateTimeStringWithMilliSecond } from '@app/shared/utils';
import { FormGroupState } from 'ngrx-forms';

import { InvestitionTechnical } from './investition-technical.model';
import { Investition } from './investition.model';
import { WizardRule } from '@core/portal-api';
import { PredictionResponseModel } from '@core/models/prediction-response.model';

export interface InvestitionData {
  investition: Investition;
  investitionTechnical?: InvestitionTechnical;
}

export interface InvestitionUI {
  isDeleting: boolean;
  isAborting: boolean;
  isSaving: boolean;
  isCalculating: boolean;
  choiceListLoaded: boolean;
  hasEmptyChoiecList: boolean;
  form: FormGroupState<InvestitionTechnical>;
  triedSaving: boolean;
  wizardrules: WizardRule[];
  prognose: PredictionResponseModel;
}

export type OpenModeType = 'readonly' | 'new' | 'edit';

export const OpenModeTypeEnum = {
  READONLY: 'readonly' as OpenModeType,
  NEW: 'new' as OpenModeType,
  EDIT: 'edit' as OpenModeType,
};

export const initialInvestitionFormState: InvestitionTechnical = {
  id: null,
  createdOn: formatDateTimeStringWithMilliSecond(new Date()),
  createdBy: null,
  projektart: null,
  schule: null,
  programmart: null,
  unterprogrammart: null,
  auspraegungUnterprogrammart: null,
  beschreibung: null,
  baubeginn: +formatDate(new Date(), 'yyyy', 'en_EN'),
  bauFertigstellung: +formatDate(new Date(), 'yyyy', 'en_EN'),
  flaechenAnsatz: undefined,
  gebaeudeKlasseZukunft: '0',
  isBerechnungsartManuell: 'false',
  angebotspreis: null,
  berechnungsformel: null,
  sondersachverhalt: null,
  gebaeude: null,
  sapWeID: null,
  preisart: '2',
  finanzierungsart: null,
  kunde: null,
  // Mapping PUT
  projektIDBKS: null,
  bezirkHHID: null,
  archivProjekt: null,
  erfasstAm: null,
  erfasstVonBenutzer: null,
  gebaeudeflaecheZukunft: null,
  gebaeudetypID: null,
  mietflaecheBSBZukunft: null,
  mietflaecheSonstigeZukunft: null,
  processID: null,
  projektflaecheSonstige: null,
  projektsteuerungExtern: null,
  regionBSBID: null,
  regionID: null,
  richtwert: null,
  richtwertExt: null,
  schulartBSBID: null,
  schulformBSBID: null,
  subteilprojektIDBKS: null,
  teilpropjektIDBKS: null,
};

import { Injectable } from '@angular/core';

import { Converter } from '@app/core/models/converter';
import { Vorhaben } from '@core/portal-api';
import { convertDateToIsoString, convertIsoDateStringToDate } from '@shared/utils';
import { Kunden } from '../constants/wizard-rules-matrix';
import { InvestitionTechnical } from '../models/investition-technical.model';

@Injectable()
export class InvestitionTechnicalConverter implements Converter<InvestitionTechnical, Vorhaben> {
  convertFromApiModel(vorhaben: Vorhaben): InvestitionTechnical {
    if (vorhaben === null || vorhaben === undefined) {
      return undefined;
    }

    const dto: InvestitionTechnical = {
      id: vorhaben.vorhabenID,
      schule: vorhaben.schuleBSBID ? vorhaben.schuleBSBID.toString() : null,
      projektart: vorhaben.projektartID ? vorhaben.projektartID.toString() : null,
      gebaeude: vorhaben.gebaeudeID,
      programmart: vorhaben.programmartID ? vorhaben.programmartID.toString() : null,
      unterprogrammart: vorhaben.unterprogrammartID ? vorhaben.unterprogrammartID.toString() : null,
      auspraegungUnterprogrammart: vorhaben.auspraegungUnterprogrammartID
        ? vorhaben.auspraegungUnterprogrammartID.toString()
        : null,
      beschreibung: vorhaben.beschreibung,
      sondersachverhalt: vorhaben.gebaeudeBemerkung,
      flaechenAnsatz: vorhaben.projektflaecheGesamt,
      kunde: vorhaben.nutzerID ? vorhaben.nutzerID.toString() : null,
      baubeginn: convertIsoDateStringToDate(vorhaben.baubeginn).getFullYear(),
      bauFertigstellung: convertIsoDateStringToDate(vorhaben.baufertigstellung).getFullYear(),
      abrechnungsDatum: vorhaben.abrechnungsDatum ? vorhaben.abrechnungsDatum : null,
      gebaeudeKlasseZukunft: vorhaben.gebaeudeklasseZukunft ? vorhaben.gebaeudeklasseZukunft.toString() : null,
      angebotspreis: vorhaben.budgetGesamt ? vorhaben.budgetGesamt : 0,
      isBerechnungsartManuell: String(vorhaben.berechnungManuell),
      preisart: String(vorhaben.preisart) || '2',
      finanzierungsart: String(vorhaben.finanzierungsart),
      projektIDBKS: vorhaben.projektIDBKS,
      bezirkHHID: vorhaben.bezirkHHID,
      archivProjekt: vorhaben.archivProjekt,
      erfasstAm: vorhaben.erfasstAm,
      erfasstVonBenutzer: vorhaben.erfasstVonBenutzer,
      gebaeudeflaecheZukunft: vorhaben.gebaeudeflaecheZukunft,
      gebaeudetypID: vorhaben.gebaeudetypID,
      mietflaecheBSBZukunft: vorhaben.mietflaecheBSBZukunft,
      mietflaecheSonstigeZukunft: vorhaben.mietflaecheSonstigeZukunft,
      processID: vorhaben.processID,
      projektsteuerungExtern: vorhaben.projektsteuerungExtern,
      regionBSBID: vorhaben.regionBSBID,
      regionID: vorhaben.regionID,
      richtwert: vorhaben.richtwert,
      richtwertExt: vorhaben.richtwertExt,
      schulartBSBID: vorhaben.schulartBSBID,
      schulformBSBID: vorhaben.schulformBSBID,
      subteilprojektIDBKS: vorhaben.subteilprojektIDBKS,
      teilpropjektIDBKS: vorhaben.teilpropjektIDBKS,
      referenzbildungDatum: vorhaben.referenzbildungDatum,
    };

    return dto;
  }

  convertToApiModel(dto: InvestitionTechnical): Vorhaben {
    if (dto === null || dto === undefined) {
      return undefined;
    }

    const vorhaben: Vorhaben = {
      projektartID: +dto.projektart,
      programmartID: +dto.programmart,
      beschreibung: dto.beschreibung,
      baubeginn: convertDateToIsoString(new Date(+dto.baubeginn, 0, 1)),
      baufertigstellung: convertDateToIsoString(new Date(+dto.bauFertigstellung, 0, 1)),
      abrechnungsDatum: dto.abrechnungsDatum ? dto.abrechnungsDatum : null,
      nutzerID: +dto.kunde,
      projektflaecheGesamt: dto.flaechenAnsatz || 0,
      berechnungManuell: dto.isBerechnungsartManuell === 'true',
      gebaeudeklasseZukunft: dto.gebaeudeKlasseZukunft ? +dto.gebaeudeKlasseZukunft : null,
      gebaeudeBemerkung: dto.sondersachverhalt,
      gebaeudeID: dto.gebaeude,
      weID: dto.sapWeID,
      finanzierungsart: +dto.finanzierungsart,
      projektIDBKS: dto.projektIDBKS,
      bezirkHHID: dto.bezirkHHID,
      archivProjekt: dto.archivProjekt,
      erfasstAm: dto.erfasstAm,
      erfasstVonBenutzer: dto.erfasstVonBenutzer,
      gebaeudeflaecheZukunft: dto.gebaeudeflaecheZukunft,
      gebaeudetypID: dto.gebaeudetypID,
      mietflaecheBSBZukunft: dto.mietflaecheBSBZukunft,
      mietflaecheSonstigeZukunft: dto.mietflaecheSonstigeZukunft,
      processID: dto.processID,
      projektsteuerungExtern: dto.projektsteuerungExtern,
      regionBSBID: dto.regionBSBID,
      regionID: dto.regionID,
      richtwert: !isNaN(Number(dto.richtwert)) ? +dto.richtwert : null,
      richtwertExt: !isNaN(Number(dto.richtwertExt)) ? +dto.richtwertExt : null,
      schulartBSBID: dto.schulartBSBID,
      schulformBSBID: dto.schulformBSBID,
      subteilprojektIDBKS: dto.subteilprojektIDBKS,
      teilpropjektIDBKS: dto.teilpropjektIDBKS,
      referenzbildungDatum: dto.referenzbildungDatum,
      preisart: dto.preisart ? +dto.preisart : null,
    };
    if (dto.id) {
      vorhaben.vorhabenID = dto.id;
    }
    if (dto.unterprogrammart && dto.unterprogrammart !== '-1') {
      vorhaben.unterprogrammartID = +dto.unterprogrammart;
    }
    if (dto.auspraegungUnterprogrammart && dto.auspraegungUnterprogrammart !== '-1') {
      vorhaben.auspraegungUnterprogrammartID = +dto.auspraegungUnterprogrammart;
    }
    if (dto.kunde === Kunden.BSB || dto.kunde === Kunden.HIBB) {
      vorhaben.schuleBSBID = +dto.schule;
    }
    if (dto.kunde) {
      vorhaben.nutzerID = +dto.kunde;
    }
    // Set FlächeBSB or FlächeSonstige by Nutzer
    if (dto.kunde === Kunden.BSB) {
      vorhaben.projektflaecheBSB = vorhaben.projektflaecheGesamt;
    } else {
      vorhaben.projektflaecheSonstige = vorhaben.projektflaecheGesamt;
    }
    vorhaben.budgetGesamt = !isNaN(Number(dto.angebotspreis)) ? +dto.angebotspreis : null;

    return vorhaben;
  }
}

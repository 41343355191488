import { createReducer, on } from '@ngrx/store';

import { RahmenplanChoicelists } from '@app/core/models/rahmenplan-choicelists.model';

import * as fromRahmenplanActions from '../actions/rahmenplan.actions';
import * as fromRahmenplanDetailsActions from '../actions/rahmenplan-details.actions';

export interface RahmenplanState {
  isRahmenplanInitialized: boolean;
  isWeInitialized: boolean;
  choicelists: RahmenplanChoicelists;
}

const initialState: RahmenplanState = {
  isRahmenplanInitialized: false,
  isWeInitialized: false,

  choicelists: {
    schulen: [],
    projektarten: [],
    programmarten: [],
    unterprogrammarten: [],
    auspraegungUnterprogrammarten: [],
    finanzierungsarten: [],
    verfuegbareJahre: [],
    gebaeude: [],
    gebaeudeklasseNachDurchfuehrung: [],
    gebaeudeKlassenZukunft: [],
    gebaeudeForNewInvestition: [],
    preisarten: [],
    projektnummern: [],
    kunden: [],
    projektsteuerer: [],
    wizardrules: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(
    fromRahmenplanActions.initRahmenplanModuleSuccess,
    (
      state,
      {
        projektarten,
        programmarten,
        unterprogrammarten,
        auspraegungUnterprogrammarten,
        gebaeudeKlassenZukunft,
        verfuegbareJahre,
        projektnummern,
        preisarten,
        finanzierungsarten,
        kunden,
        wizardrules,
      }
    ) => ({
      ...state,
      isRahmenplanInitialized: true,
      choicelists: {
        ...state.choicelists,
        projektarten,
        programmarten,
        unterprogrammarten,
        auspraegungUnterprogrammarten,
        gebaeudeKlassenZukunft,
        verfuegbareJahre,
        projektnummern,
        preisarten,
        finanzierungsarten,
        kunden,
        wizardrules,
      },
    })
  ),
  on(
    fromRahmenplanDetailsActions.loadWeChoicelistsSuccess,
    (state, { schulen, gebaeude, gebaeudeNeu, projektnummern, projektsteuerer }) => ({
      ...state,
      isWeInitialized: true,
      choicelists: {
        ...state.choicelists,
        schulen,
        gebaeude,
        projektnummern,
        gebaeudeForNewInvestition: gebaeudeNeu,
        projektsteuerer,
      },
    })
  ),
  on(fromRahmenplanDetailsActions.loadProjektChoicelistSuccess, (state, { projektnummern }) => ({
    ...state,
    choicelists: {
      ...state.choicelists,
      projektnummern,
    },
  }))
);

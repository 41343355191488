import { Action, createReducer, on } from '@ngrx/store';

import { FavoriteModel, FavoritesType } from '@app/core/models/favorite.model';

import * as fromActions from '@app/core/store/actions/favorites.actions';

const initialState: FavoriteModel[] = [];
const favoritesReducer = createReducer(
  initialState,
  on(fromActions.loadFavoritesSuccess, (state, { favorites }) => favorites)
);

export function reducer(state: FavoriteModel[] | undefined, action: Action) {
  return favoritesReducer(state, action);
}

export const getWirtschaftseinheiten = (state: FavoriteModel[]) => {
  const wirtschaftseinheiten = state.filter((f) => f.referenceType === FavoritesType.Wirtschaftseinheiten);
  return wirtschaftseinheiten;
};
export const getGebaeude = (state: FavoriteModel[]) => {
  const gebaeude = state.filter((f) => f.referenceType === FavoritesType.Gebaeude);
  return gebaeude;
};
export const getInvestitionen = (state: FavoriteModel[]) => {
  const investitionen = state.filter((f) => f.referenceType === FavoritesType.Investitionen);
  return investitionen;
};
export const getFilter = (state: FavoriteModel[]) => {
  const filter = state.filter((f) => f.referenceType === FavoritesType.Filters);
  return filter;
};

import { ChecklistTypeEnum, ChoiceListItem } from '../models/choice-list-item.model';

export const BUILDING_CLASS_FUTURE: ChoiceListItem[] = [
  {
    id: '1',
    value: '1',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
  {
    id: '2',
    value: '2',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
  {
    id: '3',
    value: '3',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
  {
    id: '4',
    value: '4',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
  {
    id: '5',
    value: '5',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
  {
    id: '6',
    value: '6',
    type: ChecklistTypeEnum.BUILDING_CLASS_FUTURE,
  },
];

export const REGIONEN: ChoiceListItem[] = [
  { id: '0', value: 'Alle', type: ChecklistTypeEnum.REGION },
  { id: '2', value: 'Altona', type: ChecklistTypeEnum.REGION },
  { id: '8', value: 'Bergedorf', type: ChecklistTypeEnum.REGION },
  { id: '3', value: 'Eimsbüttel', type: ChecklistTypeEnum.REGION },
  { id: '13', value: 'GMH Projekte Projektsteuerung', type: ChecklistTypeEnum.REGION },
  { id: '1', value: 'HIBB', type: ChecklistTypeEnum.REGION },
  { id: '10', value: 'IFM', type: ChecklistTypeEnum.REGION },
  { id: '12', value: 'Kommunalbau', type: ChecklistTypeEnum.REGION },
  { id: '7', value: 'Mitte', type: ChecklistTypeEnum.REGION },
  { id: '4', value: 'Nord', type: ChecklistTypeEnum.REGION },
  { id: '11', value: 'Süd', type: ChecklistTypeEnum.REGION },
  { id: '9', value: 'TFM', type: ChecklistTypeEnum.REGION },
  { id: '14', value: 'Unibau', type: ChecklistTypeEnum.REGION },
  { id: '5', value: 'Wandsbek-Nord', type: ChecklistTypeEnum.REGION },
  { id: '6', value: 'Wandsbek-Süd', type: ChecklistTypeEnum.REGION },
];

import { Converter } from '@core/models/converter';
import { LogEintragModel } from '@core/models/log-eintrag.model';
import { Injectable } from '@angular/core';
import { LogEintrag } from '@core/portal-api';

@Injectable()
export class LogEintragConverter implements Converter<LogEintragModel, LogEintrag> {
  convertFromApiModel(logEintrag: LogEintrag): LogEintragModel {
    if (logEintrag === null || logEintrag === undefined) {
      return undefined;
    }

    const logEintragModel: LogEintragModel = {
      logLevel: logEintrag.logLevel,
      modulID: logEintrag.modulID,
      fehlerKontext: logEintrag.fehlerKontext,
      kontextObjektID: logEintrag.kontextObjektID,
      serviceName: logEintrag.serviceName,
      fehlerID: logEintrag.fehlerID,
      fehlerText: logEintrag.fehlerText,
      fehlerStack: logEintrag.fehlerStack,
      correlationID: logEintrag.correlationID,
    };

    return logEintragModel;
  }

  convertToApiModel(logEintragModel: LogEintragModel): LogEintrag {
    if (logEintragModel === null || logEintragModel === undefined) {
      return undefined;
    }

    const logEintrag: LogEintrag = {
      logLevel: logEintragModel.logLevel,
      modulID: logEintragModel.modulID,
      fehlerKontext: logEintragModel.fehlerKontext,
      kontextObjektID: logEintragModel.kontextObjektID,
      serviceName: logEintragModel.serviceName,
      fehlerID: logEintragModel.fehlerID,
      fehlerText: logEintragModel.fehlerText,
      fehlerStack: logEintragModel.fehlerStack,
      correlationID: logEintragModel.correlationID,
    };

    return logEintrag;
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Die Berechtigungen eines Anwenders
 */
export interface BerechtigungAnwender { 
    /**
     * Die Ebene der Berechtigung
     */
    ebeneID: BerechtigungAnwender.EbeneIDEnum;
    /**
     * Die Rolle der Berechtigung
     */
    rolleID: string;
    /**
     * Die ID des Referenzobjekts
     */
    refObjID?: string;
    emailList?: Array<string>;
    removeEmailList?: Array<string>;
}
export namespace BerechtigungAnwender {
    export type EbeneIDEnum = 'ALLE' | 'REGION' | 'WIRTSCHAFTSEINHEIT';
    export const EbeneIDEnum = {
        Alle: 'ALLE' as EbeneIDEnum,
        Region: 'REGION' as EbeneIDEnum,
        Wirtschaftseinheit: 'WIRTSCHAFTSEINHEIT' as EbeneIDEnum
    };
}



/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExportresponseObjectStatus } from './exportresponseObjectStatus';


export interface ExportresponseObject { 
    filename?: string;
    mimetype?: ExportresponseObject.MimetypeEnum;
    /**
     * datei als base64endcode string
     */
    file?: string;
    status?: ExportresponseObjectStatus;
}
export namespace ExportresponseObject {
    export type MimetypeEnum = 'application/msexcel';
    export const MimetypeEnum = {
        ApplicationMsexcel: 'application/msexcel' as MimetypeEnum
    };
}



import { Pipe, PipeTransform } from '@angular/core';
import { Investition } from '@app/core/models/investition.model';

@Pipe({
  name: 'bruttoNetto',
})
export class BruttoNettoPipe implements PipeTransform {
  transform(data: Investition[], showBrutto = true): unknown {
    if (data === null || data === undefined || data.length === 0 || showBrutto) {
      return data;
    }

    const result = [];
    data.forEach((po) => {
      result.push({
        ...po,
        angebotspreis: isNaN(Number(po.richtwertIntern))
          ? 0
          : '' + Math.round((Number(po.angebotspreis) / 1.19) * 100) / 100,
        richtwertIntern: isNaN(Number(po.richtwertIntern))
          ? 0
          : '' + Math.round((Number(po.richtwertIntern) / 1.19) * 100) / 100,
        richtwertExtern: isNaN(Number(po.richtwertIntern))
          ? 0
          : '' + Math.round((Number(po.richtwertExtern) / 1.19) * 100) / 100,
      });
    });
    return result;
  }
}

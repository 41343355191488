import { createSelector } from '@ngrx/store';

import { TableColumn } from '@app/core/models/table-column.model';

import * as coreState from '@core/store/reducers';
import {
  GEBAEUDE_FILTERS_INITIAL_VALUES,
  INVESTITIONEN_FILTERS_INITIAL_VALUES,
  PROJEKTE_FILTERS_INITIAL_VALUES,
} from '@app/core/models/filter-config.model';

const minTableConfig = (config: TableColumn[]) => {
  return config && config.length > 0
    ? config.map((col) => ({
        prop: col.prop,
        width: col.width,
        showColumn: col.showColumn,
      }))
    : [];
};

const getUserConfigState = createSelector(
  coreState.getCoreModuleState,
  (state: coreState.CoreModuleState) => state.userConfig
);

export const getTablesConfig = createSelector(getUserConfigState, (config) => {
  return config.tablesConfig;
});

export const getInvestitionTableConfig = createSelector(getTablesConfig, (config) => config.investition);
export const getGebaeudeTableConfig = createSelector(getTablesConfig, (config) => config.gebaeude);
export const getProjekteTableConfig = createSelector(getTablesConfig, (config) => config.projekte);

export const getGebaeudeTableConfigMin = createSelector(getGebaeudeTableConfig, (config) => ({
  ...config,
  columns: minTableConfig(config.columns),
}));
export const getInvestitionTableConfigMin = createSelector(getInvestitionTableConfig, (config) => ({
  ...config,
  columns: minTableConfig(config.columns),
}));
export const getProjekteTableConfigMin = createSelector(getProjekteTableConfig, (config) => ({
  ...config,
  columns: minTableConfig(config.columns),
}));

export const getTablesConfigMin = createSelector(getTablesConfig, (configs) => {
  return {
    investition: {
      ...configs.investition,
      columns: minTableConfig(configs.investition.columns),
    },
    gebaeude: {
      ...configs.gebaeude,
      columns: minTableConfig(configs.gebaeude.columns),
    },
    projekte: {
      ...configs.projekte,
      columns: minTableConfig(configs.projekte.columns),
    },
  };
});

export const getFiltersConfig = createSelector(getUserConfigState, (config) => config.filterConfig);
export const getInvestitionFilterConfig = createSelector(getFiltersConfig, (filters) => {
  return filters.investition ?? INVESTITIONEN_FILTERS_INITIAL_VALUES;
});
export const getGebaeudeFilterConfig = createSelector(getFiltersConfig, (filters) => {
  return filters?.gebaeude ?? GEBAEUDE_FILTERS_INITIAL_VALUES;
});
export const getProjekteFilterConfig = createSelector(getFiltersConfig, (filters) => {
  return filters?.projekte ?? PROJEKTE_FILTERS_INITIAL_VALUES;
});

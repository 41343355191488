/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * JSON Patch Dokument wie in RFC 6902 definiert
 */
export interface JsonPatchDocument { 
    /**
     * Die Operation die ausgefuehrt werden soll
     */
    op: JsonPatchDocument.OpEnum;
    /**
     * Ein JSON-Pointer
     */
    path: string;
    /**
     * Der Wert der in der Operation verwendet werden soll
     */
    value?: object;
    /**
     * Ein JSON-Pointer
     */
    from?: string;
}
export namespace JsonPatchDocument {
    export type OpEnum = 'add' | 'remove' | 'replace' | 'test' | 'move' | 'copy';
    export const OpEnum = {
        Add: 'add' as OpEnum,
        Remove: 'remove' as OpEnum,
        Replace: 'replace' as OpEnum,
        Test: 'test' as OpEnum,
        Move: 'move' as OpEnum,
        Copy: 'copy' as OpEnum
    };
}



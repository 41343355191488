const C_V_STM_EN = [
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5043,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Helmuth Hübener',
    EN_SCHULE_STRASSE_NR: 'Benzenbergweg 2',
    EN_SCHULE_PLZ: '22307',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 1177,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074666,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5046,
    EN_SCHULE_BEZEICHNUNG: 'Gyula Trebitsch Schule Tonndorf',
    EN_SCHULE_STRASSE_NR: 'Barenkrug 16',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 1532,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2076329,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5048,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Bergstedt',
    EN_SCHULE_STRASSE_NR: 'Volksdorfer Damm 218',
    EN_SCHULE_PLZ: '22395',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 1145,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074739,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5049,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Am Heidberg',
    EN_SCHULE_STRASSE_NR: 'Tangstedter Landstraße 300',
    EN_SCHULE_PLZ: '22417',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 978,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074679,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5052,
    EN_SCHULE_BEZEICHNUNG: 'Ilse-Löwenstein-Schule',
    EN_SCHULE_STRASSE_NR: 'Humboldtstraße 89',
    EN_SCHULE_PLZ: '22083',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 537,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 5333626,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5053,
    EN_SCHULE_BEZEICHNUNG: 'Albert-Schweitzer-Schule',
    EN_SCHULE_STRASSE_NR: 'Schluchtweg 1',
    EN_SCHULE_PLZ: '22337',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 739,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 5305171,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5054,
    EN_SCHULE_BEZEICHNUNG: 'Max-Schmeling-Stadtteilschule',
    EN_SCHULE_STRASSE_NR: 'Denksteinweg 17',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 1100,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074697,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5056,
    EN_SCHULE_BEZEICHNUNG: 'Grund- und Stadtteilschule Alter Teichweg',
    EN_SCHULE_STRASSE_NR: 'Alter Teichweg 200',
    EN_SCHULE_PLZ: '22049',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 1609,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074664,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5057,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Mümmelmannsberg',
    EN_SCHULE_STRASSE_NR: 'Mümmelmannsberg 75',
    EN_SCHULE_PLZ: '22115',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 1039,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074528,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5058,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Mümmelmannsberg',
    EN_SCHULE_STRASSE_NR: 'Mümmelmannsberg 54',
    EN_SCHULE_PLZ: '22115',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 442,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074513,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5059,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Rahewinkel',
    EN_SCHULE_STRASSE_NR: 'Rahewinkel 9',
    EN_SCHULE_PLZ: '22115',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 384,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074514,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5061,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Bergedorf',
    EN_SCHULE_STRASSE_NR: 'Ladenbeker Weg 13',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1406,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2073523,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5062,
    EN_SCHULE_BEZEICHNUNG: 'Heinrich-Hertz-Schule',
    EN_SCHULE_STRASSE_NR: 'Grasweg 72',
    EN_SCHULE_PLZ: '22303',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 1423,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074647,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5063,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Horn',
    EN_SCHULE_STRASSE_NR: 'Snitgerreihe 2',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 1115,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 5193445,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5064,
    EN_SCHULE_BEZEICHNUNG: 'Erich Kästner Schule',
    EN_SCHULE_STRASSE_NR: 'Hermelinweg 10  ',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 1341,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2076328,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5065,
    EN_SCHULE_BEZEICHNUNG: 'Fritz-Schumacher-Schule',
    EN_SCHULE_STRASSE_NR: 'Timmerloh 27/29',
    EN_SCHULE_PLZ: '22417',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 906,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074668,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5066,
    EN_SCHULE_BEZEICHNUNG: 'Geschwister-Scholl-Stadtteilschule',
    EN_SCHULE_STRASSE_NR: 'Böttcherkamp 181',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 770,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074564,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5067,
    EN_SCHULE_BEZEICHNUNG: 'Julius-Leber-Schule',
    EN_SCHULE_STRASSE_NR: 'Halstenbeker Straße 41',
    EN_SCHULE_PLZ: '22457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 1646,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074631,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5069,
    EN_SCHULE_BEZEICHNUNG: 'Irena-Sendler-Schule',
    EN_SCHULE_STRASSE_NR: 'Am Pfeilshof 20',
    EN_SCHULE_PLZ: '22393',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 1134,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074721,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5070,
    EN_SCHULE_BEZEICHNUNG: 'Goethe-Schule-Harburg',
    EN_SCHULE_STRASSE_NR: 'Eißendorfer Straße 26',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 1746,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074785,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5071,
    EN_SCHULE_BEZEICHNUNG: 'Ida Ehre Schule',
    EN_SCHULE_STRASSE_NR: 'Bogenstraße 36',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 1270,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074602,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5072,
    EN_SCHULE_BEZEICHNUNG: 'Nelson-Mandela-Stadtteilschule',
    EN_SCHULE_STRASSE_NR: 'Neuenfelder Straße 106',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1083,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074536,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5074,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Edwin-Scharff-Ring',
    EN_SCHULE_STRASSE_NR: 'Edwin-Scharff-Ring 56',
    EN_SCHULE_PLZ: '22309',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 254,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074701,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5075,
    EN_SCHULE_BEZEICHNUNG: 'Schule am See',
    EN_SCHULE_STRASSE_NR: 'Borchertring 38',
    EN_SCHULE_PLZ: '22309',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 622,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2076327,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5076,
    EN_SCHULE_BEZEICHNUNG: 'Max-Brauer-Schule',
    EN_SCHULE_STRASSE_NR: 'Bei der Paul-Gerhardt-Kirche 1',
    EN_SCHULE_PLZ: '22761',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1508,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2076314,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5077,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Blankenese',
    EN_SCHULE_STRASSE_NR: 'Frahmstraße 15',
    EN_SCHULE_PLZ: '22587',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 1109,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074585,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5079,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Poppenbüttel',
    EN_SCHULE_STRASSE_NR: 'Schulbergredder 13 u. 21',
    EN_SCHULE_PLZ: '22399',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 852,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074728,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5080,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Finkenwerder',
    EN_SCHULE_STRASSE_NR: 'Norderschulweg 14',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 542,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074798,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5081,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Bahrenfeld',
    EN_SCHULE_STRASSE_NR: 'Regerstraße 21',
    EN_SCHULE_PLZ: '22761',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 990,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074561,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5083,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Eidelstedt',
    EN_SCHULE_STRASSE_NR: 'Lohkampstraße 145',
    EN_SCHULE_PLZ: '22523',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 995,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074640,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5084,
    EN_SCHULE_BEZEICHNUNG: 'Gretel-Bergmann-Schule',
    EN_SCHULE_STRASSE_NR: 'Margit-Zinke-Straße 7-11',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1082,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2073525,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5085,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Walddörfer',
    EN_SCHULE_STRASSE_NR: 'Ahrensburger Weg 30',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 1181,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074740,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5087,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Kirchwerder',
    EN_SCHULE_STRASSE_NR: 'Kirchwerder Hausdeich 341',
    EN_SCHULE_PLZ: '21037',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1116,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2073580,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5088,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Winterhude',
    EN_SCHULE_STRASSE_NR: 'Meerweinstraße 26-28',
    EN_SCHULE_PLZ: '22303',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 1226,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074655,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5089,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Öjendorf',
    EN_SCHULE_STRASSE_NR: 'Öjendorfer Höhe 12',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 696,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074529,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5090,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Eppendorf',
    EN_SCHULE_STRASSE_NR: 'Löwenstraße 58',
    EN_SCHULE_PLZ: '20251',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 1303,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074654,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5093,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Süderelbe',
    EN_SCHULE_STRASSE_NR: 'Neumoorstück 1',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 923,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074799,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5094,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Lohbrügge',
    EN_SCHULE_STRASSE_NR: 'Binnenfeldredder 7',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1144,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2073510,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5095,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Wilhelmsburg',
    EN_SCHULE_STRASSE_NR: 'Rotenhäuser Straße 67',
    EN_SCHULE_PLZ: '21107',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1118,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2076432,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5097,
    EN_SCHULE_BEZEICHNUNG: 'Brüder-Grimm-Schule',
    EN_SCHULE_STRASSE_NR: 'Querkamp 68',
    EN_SCHULE_PLZ: '22119',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 921,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074527,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5098,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Meiendorf',
    EN_SCHULE_STRASSE_NR: 'Deepenhorn 1',
    EN_SCHULE_PLZ: '22145',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 680,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074761,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5099,
    EN_SCHULE_BEZEICHNUNG: 'Schule auf der Veddel',
    EN_SCHULE_STRASSE_NR: 'Slomanstieg 1/3',
    EN_SCHULE_PLZ: '20539',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 474,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074540,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5100,
    EN_SCHULE_BEZEICHNUNG: 'Aueschule Finkenwerder',
    EN_SCHULE_STRASSE_NR: 'Ostfrieslandstraße 91',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 226,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074786,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5101,
    EN_SCHULE_BEZEICHNUNG: 'Katharinenschule in der Hafencity',
    EN_SCHULE_STRASSE_NR: 'Am Dalmannkai 18',
    EN_SCHULE_PLZ: '20457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 347,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074501,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5102,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Eckerkoppel',
    EN_SCHULE_STRASSE_NR: 'Berner Heerweg 99',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 385,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074700,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5103,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule St. Pauli',
    EN_SCHULE_STRASSE_NR: 'Friedrichstraße 55',
    EN_SCHULE_PLZ: '20359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 200,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074495,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5104,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Lohkampstraße',
    EN_SCHULE_STRASSE_NR: 'Lohkampstraße 145',
    EN_SCHULE_PLZ: '22523',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 200,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074629,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5105,
    EN_SCHULE_BEZEICHNUNG: 'Heinrich-Wolgast-Schule',
    EN_SCHULE_STRASSE_NR: 'Greifswalder Straße 40',
    EN_SCHULE_PLZ: '20099',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 372,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074499,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5106,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hohe Landwehr',
    EN_SCHULE_STRASSE_NR: 'Hohe Landwehr 19',
    EN_SCHULE_PLZ: '20535',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 311,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074505,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5111,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Am Heidberg',
    EN_SCHULE_STRASSE_NR: 'Tangstedter Landstraße 300',
    EN_SCHULE_PLZ: '22417',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 405,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074669,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5115,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Kirchwerder',
    EN_SCHULE_STRASSE_NR: 'Kirchwerder Hausdeich 341',
    EN_SCHULE_PLZ: '21037',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074763,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5117,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Kirchdorf',
    EN_SCHULE_STRASSE_NR: 'Prassekstraße 5',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 393,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074533,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5118,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Archenholzstraße',
    EN_SCHULE_STRASSE_NR: 'Archenholzstraße 55',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 314,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074511,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5119,
    EN_SCHULE_BEZEICHNUNG: 'Westerschule Finkenwerder',
    EN_SCHULE_STRASSE_NR: 'Finkenwerder Landscheideweg 180',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 280,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076312,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5121,
    EN_SCHULE_BEZEICHNUNG: 'Schule Marckmannstraße',
    EN_SCHULE_STRASSE_NR: 'Marckmannstraße 60',
    EN_SCHULE_PLZ: '20539',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 103,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074522,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5122,
    EN_SCHULE_BEZEICHNUNG: 'Schule Zitzewitzstraße',
    EN_SCHULE_STRASSE_NR: 'Zitzewitzstraße 51',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074696,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5123,
    EN_SCHULE_BEZEICHNUNG: 'Inselschule Neuwerk',
    EN_SCHULE_STRASSE_NR: 'Neuwerk 5',
    EN_SCHULE_PLZ: '27499',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 2,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074500,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5124,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule St. Nikolai',
    EN_SCHULE_STRASSE_NR: 'Robert-Koch-Straße 15',
    EN_SCHULE_PLZ: '20249',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 241,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074644,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5127,
    EN_SCHULE_BEZEICHNUNG: 'Goldbek-Schule',
    EN_SCHULE_STRASSE_NR: 'Poßmoorweg 22',
    EN_SCHULE_PLZ: '22303',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 321,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074643,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5130,
    EN_SCHULE_BEZEICHNUNG: 'Schule Beim Pachthof',
    EN_SCHULE_STRASSE_NR: 'Beim Pachthof 15',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 330,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074518,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5134,
    EN_SCHULE_BEZEICHNUNG: 'Fritz-Köhne-Schule',
    EN_SCHULE_STRASSE_NR: 'Marckmannstraße 61',
    EN_SCHULE_PLZ: '20539',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 280,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074493,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5136,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Hasenweg',
    EN_SCHULE_STRASSE_NR: 'Hasenweg 40',
    EN_SCHULE_PLZ: '22393',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 444,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074716,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5137,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Arnkielstraße',
    EN_SCHULE_STRASSE_NR: 'Arnkielstraße 2',
    EN_SCHULE_PLZ: '22769',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 406,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074547,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5139,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Osterbrook',
    EN_SCHULE_STRASSE_NR: 'Osterbrook 17/19',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 217,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074494,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5141,
    EN_SCHULE_BEZEICHNUNG: 'Schule Pröbenweg',
    EN_SCHULE_STRASSE_NR: 'Pröbenweg 24',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074523,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5143,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Poppenbüttel',
    EN_SCHULE_STRASSE_NR: 'Schulbergredder 13 u. 21',
    EN_SCHULE_PLZ: '22399',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 341,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074717,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5145,
    EN_SCHULE_BEZEICHNUNG: 'Elbschule Bildungszentrum Hören und Kommunikation',
    EN_SCHULE_STRASSE_NR: 'Holmbrook 20',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 267,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfH',
    EN_EN_OBJ_ID_PKT: 2074531,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478708,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5146,
    EN_SCHULE_BEZEICHNUNG: 'Sprachheilschule Reinbeker Redder',
    EN_SCHULE_STRASSE_NR: 'Reinbeker Redder 274',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2073540,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5158,
    EN_SCHULE_BEZEICHNUNG: 'Elbkinder Grundschule',
    EN_SCHULE_STRASSE_NR: 'Grotefendweg 20',
    EN_SCHULE_PLZ: '22589',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 224,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074573,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5160,
    EN_SCHULE_BEZEICHNUNG: 'Schule An der Glinder Au',
    EN_SCHULE_STRASSE_NR: 'Sonnenland 27',
    EN_SCHULE_PLZ: '22115',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 277,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074517,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5163,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hauskoppelstieg',
    EN_SCHULE_STRASSE_NR: 'Hauskoppelstieg 12',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'SfL',
    EN_EN_OBJ_ID_PKT: 2074521,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478710,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5166,
    EN_SCHULE_BEZEICHNUNG: 'Schule Steinbeker Marktstraße',
    EN_SCHULE_STRASSE_NR: 'Steinbeker Marktstraße 8/10',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'SfL',
    EN_EN_OBJ_ID_PKT: 2074525,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478710,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5167,
    EN_SCHULE_BEZEICHNUNG: 'Ganztagsgrundschule Sternschanze',
    EN_SCHULE_STRASSE_NR: 'Altonaer Straße 38',
    EN_SCHULE_PLZ: '20357',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 578,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074544,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5169,
    EN_SCHULE_BEZEICHNUNG: 'Schule Speckenreye',
    EN_SCHULE_STRASSE_NR: 'Speckenreye 11',
    EN_SCHULE_PLZ: '22119',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 206,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074524,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5171,
    EN_SCHULE_BEZEICHNUNG: 'Schule Stengelestraße',
    EN_SCHULE_STRASSE_NR: 'Stengelestraße 38',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 390,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076311,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5172,
    EN_SCHULE_BEZEICHNUNG: 'Schule am Schleemer Park',
    EN_SCHULE_STRASSE_NR: 'Oberschleems 9',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 456,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074516,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5173,
    EN_SCHULE_BEZEICHNUNG: 'Schule Sterntalerstraße',
    EN_SCHULE_STRASSE_NR: 'Sterntalerstraße 42',
    EN_SCHULE_PLZ: '22119',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 297,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074526,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5174,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bonhoefferstraße',
    EN_SCHULE_STRASSE_NR: 'Bonhoefferstraße 13',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 219,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074519,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5175,
    EN_SCHULE_BEZEICHNUNG: 'Schule Fuchsbergredder',
    EN_SCHULE_STRASSE_NR: 'Dringsheide 10-12',
    EN_SCHULE_PLZ: '22119',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 329,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074520,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5202,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bahrenfelder Straße',
    EN_SCHULE_STRASSE_NR: 'Gaußstraße 171',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 418,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074554,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5204,
    EN_SCHULE_BEZEICHNUNG: 'Schule Carsten-Rehder-Straße',
    EN_SCHULE_STRASSE_NR: 'Carsten-Rehder-Straße 34',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074555,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5205,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Bramfeld',
    EN_SCHULE_STRASSE_NR: 'Hegholt 44',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 329,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074699,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5206,
    EN_SCHULE_BEZEICHNUNG: 'Louise Schroeder Schule',
    EN_SCHULE_STRASSE_NR: 'Thedestraße 100',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 534,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074553,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5208,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Karlshöhe',
    EN_SCHULE_STRASSE_NR: 'Thomas-Mann-Straße 2',
    EN_SCHULE_PLZ: '22175',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 423,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074702,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5209,
    EN_SCHULE_BEZEICHNUNG: 'Schule Klein Flottbeker Weg',
    EN_SCHULE_STRASSE_NR: 'Klein Flottbeker Weg 64',
    EN_SCHULE_PLZ: '22065',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 352,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074592,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5210,
    EN_SCHULE_BEZEICHNUNG: 'Loki-Schmidt-Schule',
    EN_SCHULE_STRASSE_NR: 'Othmarscher Kirchenweg 145',
    EN_SCHULE_PLZ: '22763',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 374,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074552,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5211,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rothestraße',
    EN_SCHULE_STRASSE_NR: 'Rothestraße 22',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 507,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074559,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5212,
    EN_SCHULE_BEZEICHNUNG: 'Ganztagsschule an der Elbe',
    EN_SCHULE_STRASSE_NR: 'Carsten-Rehder-Straße 34',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 78,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074545,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5214,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Thadenstraße',
    EN_SCHULE_STRASSE_NR: 'Thadenstraße 147',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 492,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074548,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5216,
    EN_SCHULE_BEZEICHNUNG: 'Theodor-Haubach-Schule',
    EN_SCHULE_STRASSE_NR: 'Haubachstraße 55',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 401,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074562,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5217,
    EN_SCHULE_BEZEICHNUNG: 'Schule Trenknerweg',
    EN_SCHULE_STRASSE_NR: 'Trenknerweg 136',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 440,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074560,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5218,
    EN_SCHULE_BEZEICHNUNG: 'Ganztagsschule Bernstorffstraße',
    EN_SCHULE_STRASSE_NR: 'Bernstorffstraße 147',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 2074546,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5219,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hirtenweg',
    EN_SCHULE_STRASSE_NR: 'Holmbrook 10/14',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 177,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfK',
    EN_EN_OBJ_ID_PKT: 2074556,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478709,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5220,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Marienthal',
    EN_SCHULE_STRASSE_NR: 'Schimmelmannstraße 70',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 492,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074682,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5231,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Goosacker',
    EN_SCHULE_STRASSE_NR: 'Goosacker 41',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 412,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074587,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5232,
    EN_SCHULE_BEZEICHNUNG: 'Gorch-Fock-Schule',
    EN_SCHULE_STRASSE_NR: 'Karstenstraße 22',
    EN_SCHULE_PLZ: '22587',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 458,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074574,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5233,
    EN_SCHULE_BEZEICHNUNG: 'Schule Grotefendweg',
    EN_SCHULE_STRASSE_NR: 'Grotefendweg 20',
    EN_SCHULE_PLZ: '22589',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074578,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5234,
    EN_SCHULE_BEZEICHNUNG: 'Schule Iserbarg',
    EN_SCHULE_STRASSE_NR: 'Iserbarg 2',
    EN_SCHULE_PLZ: '22559',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 387,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074579,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5235,
    EN_SCHULE_BEZEICHNUNG: 'Schule Lehmkuhlenweg',
    EN_SCHULE_STRASSE_NR: 'Lehmkuhlenweg 19/21',
    EN_SCHULE_PLZ: '22589',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 325,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074581,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5236,
    EN_SCHULE_BEZEICHNUNG: 'Schule Marschweg',
    EN_SCHULE_STRASSE_NR: 'Marschweg 10',
    EN_SCHULE_PLZ: '22559',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 344,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074582,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5237,
    EN_SCHULE_BEZEICHNUNG: 'Schule Mendelssohnstraße',
    EN_SCHULE_STRASSE_NR: 'Mendelssohnstraße 86',
    EN_SCHULE_PLZ: '22761',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 313,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074558,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5238,
    EN_SCHULE_BEZEICHNUNG: 'Schule Iserbrook',
    EN_SCHULE_STRASSE_NR: 'Musäusstraße 29',
    EN_SCHULE_PLZ: '22589',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 247,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074580,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5239,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Groß Flottbek',
    EN_SCHULE_STRASSE_NR: 'Osdorfer Weg 24',
    EN_SCHULE_PLZ: '22607',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 430,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074588,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5240,
    EN_SCHULE_BEZEICHNUNG: 'Schule Schenefelder Landstraße',
    EN_SCHULE_STRASSE_NR: 'Schenefelder Landstraße 206',
    EN_SCHULE_PLZ: '22589',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 275,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074583,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5243,
    EN_SCHULE_BEZEICHNUNG: 'Schule Schulkamp',
    EN_SCHULE_STRASSE_NR: 'Schulkamp 1/3',
    EN_SCHULE_PLZ: '22609',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 306,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074584,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5244,
    EN_SCHULE_BEZEICHNUNG: 'Schule Windmühlenweg',
    EN_SCHULE_STRASSE_NR: 'Windmühlenweg 17',
    EN_SCHULE_PLZ: '22607',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 454,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074594,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5245,
    EN_SCHULE_BEZEICHNUNG: 'Schule Kielkamp',
    EN_SCHULE_STRASSE_NR: 'Kielkamp 16',
    EN_SCHULE_PLZ: '22761',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 160,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074557,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5248,
    EN_SCHULE_BEZEICHNUNG: 'Rudolf-Roß-Grundschule',
    EN_SCHULE_STRASSE_NR: 'Kurze Straße 30',
    EN_SCHULE_PLZ: '20355',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 351,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074502,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5250,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Horn',
    EN_SCHULE_STRASSE_NR: 'Rhiemsweg 61',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 265,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074512,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5252,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Nydamer Weg',
    EN_SCHULE_STRASSE_NR: 'Nydamer Weg 44',
    EN_SCHULE_PLZ: '22145',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 361,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074745,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5263,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Franzosenkoppel',
    EN_SCHULE_STRASSE_NR: 'Franzosenkoppel 118',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 349,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074566,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5264,
    EN_SCHULE_BEZEICHNUNG: 'Fridtjof-Nansen-Schule',
    EN_SCHULE_STRASSE_NR: 'Fahrenort 76',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 678,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074563,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5265,
    EN_SCHULE_BEZEICHNUNG: 'Schule Furtweg',
    EN_SCHULE_STRASSE_NR: 'Furtweg 56',
    EN_SCHULE_PLZ: '22523',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 341,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074635,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5267,
    EN_SCHULE_BEZEICHNUNG: 'Schule Langbargheide',
    EN_SCHULE_STRASSE_NR: 'Langbargheide 40',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 269,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074571,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5270,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Luruper Hauptstraße',
    EN_SCHULE_STRASSE_NR: 'Luruper Hauptstraße 131',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 245,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074567,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5271,
    EN_SCHULE_BEZEICHNUNG: 'Max-Traeger-Schule',
    EN_SCHULE_STRASSE_NR: 'Baumacker 10',
    EN_SCHULE_PLZ: '22523',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 222,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074632,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5274,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rungwisch',
    EN_SCHULE_STRASSE_NR: 'Rungwisch 23',
    EN_SCHULE_PLZ: '22532',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 283,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074639,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5277,
    EN_SCHULE_BEZEICHNUNG: 'Schule Wesperloh',
    EN_SCHULE_STRASSE_NR: 'Wesperloh 19',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 435,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074593,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5278,
    EN_SCHULE_BEZEICHNUNG: 'Schule Kroonhorst',
    EN_SCHULE_STRASSE_NR: 'Kroonhorst 25',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 231,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074570,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5280,
    EN_SCHULE_BEZEICHNUNG: 'Schule Barlsheide',
    EN_SCHULE_STRASSE_NR: 'Bornheide 2',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 330,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074568,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5281,
    EN_SCHULE_BEZEICHNUNG: 'Schule Böttcherkamp',
    EN_SCHULE_STRASSE_NR: 'Böttcherkamp 146',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074569,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5285,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Sinstorfer Weg',
    EN_SCHULE_STRASSE_NR: 'Sinstorfer Weg 40',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 53,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 7067473,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5287,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Isestraße',
    EN_SCHULE_STRASSE_NR: 'Isestraße  144-146',
    EN_SCHULE_PLZ: '20149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 7039675,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5293,
    EN_SCHULE_BEZEICHNUNG: 'Schule Am Baakenhafen',
    EN_SCHULE_STRASSE_NR: 'Am Baakenhafen 33',
    EN_SCHULE_PLZ: '20457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 5324442,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5295,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule am Park',
    EN_SCHULE_STRASSE_NR: 'Am Soldatenfriedhof 21',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 7115639,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5301,
    EN_SCHULE_BEZEICHNUNG: 'Schule An der Isebek',
    EN_SCHULE_STRASSE_NR: 'Bismarckstraße 83/85',
    EN_SCHULE_PLZ: '20253',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 352,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074603,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5303,
    EN_SCHULE_BEZEICHNUNG: 'Astrid-Lindgren-Schule',
    EN_SCHULE_STRASSE_NR: 'Bundesstraße 94',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074596,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5304,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Hoheluft',
    EN_SCHULE_STRASSE_NR: 'Wrangelstraße 80',
    EN_SCHULE_PLZ: '20253',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 319,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074598,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5305,
    EN_SCHULE_BEZEICHNUNG: 'Schule Eduardstraße',
    EN_SCHULE_STRASSE_NR: 'Eduardstraße 28',
    EN_SCHULE_PLZ: '20257',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 199,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074610,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5308,
    EN_SCHULE_BEZEICHNUNG: 'Schule Kielortallee',
    EN_SCHULE_STRASSE_NR: 'Kielortallee 18/20',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 429,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074604,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5309,
    EN_SCHULE_BEZEICHNUNG: 'Schule Brehmweg',
    EN_SCHULE_STRASSE_NR: 'Brehmweg 62',
    EN_SCHULE_PLZ: '22527',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 274,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074609,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5310,
    EN_SCHULE_BEZEICHNUNG: 'Schule Lutterothstraße',
    EN_SCHULE_STRASSE_NR: 'Lutterothstraße 34-36',
    EN_SCHULE_PLZ: '20255',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 403,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074611,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5311,
    EN_SCHULE_BEZEICHNUNG: 'Wolfgang-Borchert-Schule',
    EN_SCHULE_STRASSE_NR: 'Schwenckestraße 91-93',
    EN_SCHULE_PLZ: '20255',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 51,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076326,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5312,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rellinger Straße',
    EN_SCHULE_STRASSE_NR: 'Rellinger Straße 13-15',
    EN_SCHULE_PLZ: '20257',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 409,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074613,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5315,
    EN_SCHULE_BEZEICHNUNG: 'Schule Tornquiststraße',
    EN_SCHULE_STRASSE_NR: 'Tornquiststraße 60',
    EN_SCHULE_PLZ: '20259',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 356,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074605,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5316,
    EN_SCHULE_BEZEICHNUNG: 'Schule Turmweg',
    EN_SCHULE_STRASSE_NR: 'Turmweg 33',
    EN_SCHULE_PLZ: '20148',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 570,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076318,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5328,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rönnkamp',
    EN_SCHULE_STRASSE_NR: 'Rönnkamp 3',
    EN_SCHULE_PLZ: '22457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 224,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074637,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5330,
    EN_SCHULE_BEZEICHNUNG: 'Schule Anna-Susanna-Stieg',
    EN_SCHULE_STRASSE_NR: 'Anna-Susanna-Stieg 3',
    EN_SCHULE_PLZ: '22457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 413,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074633,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5331,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Bindfeldweg',
    EN_SCHULE_STRASSE_NR: 'Bindfeldweg 37',
    EN_SCHULE_PLZ: '22459',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 264,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074616,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5332,
    EN_SCHULE_BEZEICHNUNG: 'Schule Burgunderweg',
    EN_SCHULE_STRASSE_NR: 'Burgunderweg 2',
    EN_SCHULE_PLZ: '22453',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 416,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074621,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5333,
    EN_SCHULE_BEZEICHNUNG: 'Schule Heidacker',
    EN_SCHULE_STRASSE_NR: 'Heidacker 13',
    EN_SCHULE_PLZ: '22523',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 360,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074636,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5334,
    EN_SCHULE_BEZEICHNUNG: 'Schule Döhrnstraße',
    EN_SCHULE_STRASSE_NR: 'Döhrnstraße 42',
    EN_SCHULE_PLZ: '22529',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 496,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074622,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5335,
    EN_SCHULE_BEZEICHNUNG: 'Schule Frohmestraße',
    EN_SCHULE_STRASSE_NR: 'Frohmestraße 42',
    EN_SCHULE_PLZ: '22457',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 392,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074634,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5336,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hinter der Lieth',
    EN_SCHULE_STRASSE_NR: 'Hinter der Lieth 61',
    EN_SCHULE_PLZ: '22529',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 359,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074623,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5338,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bindfeldweg',
    EN_SCHULE_STRASSE_NR: 'Bindfeldweg 37',
    EN_SCHULE_PLZ: '22459',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074620,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5339,
    EN_SCHULE_BEZEICHNUNG: 'Schule Molkenbuhrstraße',
    EN_SCHULE_STRASSE_NR: 'Molkenbuhrstraße 2',
    EN_SCHULE_PLZ: '22525',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 257,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074612,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5342,
    EN_SCHULE_BEZEICHNUNG: 'Schule Sethweg',
    EN_SCHULE_STRASSE_NR: 'Sethweg 56',
    EN_SCHULE_PLZ: '22455',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 345,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074626,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5344,
    EN_SCHULE_BEZEICHNUNG: 'Schule Vizelinstraße',
    EN_SCHULE_STRASSE_NR: 'Vizelinstraße 50',
    EN_SCHULE_PLZ: '22529',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 336,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074627,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5345,
    EN_SCHULE_BEZEICHNUNG: 'Schule Wegenkamp',
    EN_SCHULE_STRASSE_NR: 'Wegenkamp 9',
    EN_SCHULE_PLZ: '22527',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 237,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074614,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5346,
    EN_SCHULE_BEZEICHNUNG: 'Schule Lokstedter Damm',
    EN_SCHULE_STRASSE_NR: 'Lokstedter Damm 38',
    EN_SCHULE_PLZ: '22453',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 107,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074653,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5347,
    EN_SCHULE_BEZEICHNUNG: 'Schule Moorflagen',
    EN_SCHULE_STRASSE_NR: 'Wagrierweg 18',
    EN_SCHULE_PLZ: '22455',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 252,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074624,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5348,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Sachsenweg',
    EN_SCHULE_STRASSE_NR: 'Sachsenweg 74',
    EN_SCHULE_PLZ: '22455',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 227,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074625,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5349,
    EN_SCHULE_BEZEICHNUNG: 'Schule Röthmoorweg',
    EN_SCHULE_STRASSE_NR: 'Röthmoorweg 9',
    EN_SCHULE_PLZ: '22459',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 200,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074638,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5350,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Altona',
    EN_SCHULE_STRASSE_NR: 'Bernstorffstraße 147',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 89,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305174,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5351,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Altona-West',
    EN_SCHULE_STRASSE_NR: 'Böttcherkamp 144',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 85,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5212534,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5352,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Bergedorf',
    EN_SCHULE_STRASSE_NR: 'Leuschnerstraße 13',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 172,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305180,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5353,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Eimsbüttel',
    EN_SCHULE_STRASSE_NR: 'Bindfeldweg 37',
    EN_SCHULE_PLZ: '22459',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 155,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305175,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5354,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Süderelbe',
    EN_SCHULE_STRASSE_NR: 'Neuwiedenthaler Straße 4',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 89,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305183,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5355,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Harburg',
    EN_SCHULE_STRASSE_NR: 'Schwarzenbergstraße 72',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 228,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305181,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5356,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Billstedt',
    EN_SCHULE_STRASSE_NR: 'Steinfeldtstraße 1',
    EN_SCHULE_PLZ: '22119',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 169,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5218680,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5357,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Wilhelmsburg',
    EN_SCHULE_STRASSE_NR: 'Krieterstraße 2a',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 236,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305182,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5358,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Mitte',
    EN_SCHULE_STRASSE_NR: 'Pröbenweg 24',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 145,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305173,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5359,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Winterhude',
    EN_SCHULE_STRASSE_NR: 'Eschenweg 1',
    EN_SCHULE_PLZ: '22335',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 150,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305176,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5360,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Nord',
    EN_SCHULE_STRASSE_NR: 'Sengelmannstraße 50',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 110,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305177,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5361,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Wandsbek-Nord',
    EN_SCHULE_STRASSE_NR: 'Hohnerkamp 58',
    EN_SCHULE_PLZ: '22175',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 201,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305178,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5362,
    EN_SCHULE_BEZEICHNUNG: 'ReBBZ Wandsbek-Süd',
    EN_SCHULE_STRASSE_NR: 'Zitzewitzstraße 51',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 163,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'ReBBZ',
    EN_EN_OBJ_ID_PKT: 5305179,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478706,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5400,
    EN_SCHULE_BEZEICHNUNG: 'Schule Alsterdorfer Straße',
    EN_SCHULE_STRASSE_NR: 'Alsterdorfer Straße 39',
    EN_SCHULE_PLZ: '22299',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 316,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074650,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5404,
    EN_SCHULE_BEZEICHNUNG: 'Schule Forsmannstraße',
    EN_SCHULE_STRASSE_NR: 'Forsmannstraße 32',
    EN_SCHULE_PLZ: '22303',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 461,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076319,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5405,
    EN_SCHULE_BEZEICHNUNG: 'Schule Humboldtstraße',
    EN_SCHULE_STRASSE_NR: 'Humboldtstraße 30',
    EN_SCHULE_PLZ: '22083',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 414,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074662,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5409,
    EN_SCHULE_BEZEICHNUNG: 'Schule Knauerstraße',
    EN_SCHULE_STRASSE_NR: 'Knauerstraße 22',
    EN_SCHULE_PLZ: '20249',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 247,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074652,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5412,
    EN_SCHULE_BEZEICHNUNG: 'Robert-Koch-Schule',
    EN_SCHULE_STRASSE_NR: 'Sengelmannstraße 50  ',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074649,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5413,
    EN_SCHULE_BEZEICHNUNG: 'Marie-Beschütz-Schule',
    EN_SCHULE_STRASSE_NR: 'Erikastraße 41',
    EN_SCHULE_PLZ: '20251',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 425,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2076324,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5416,
    EN_SCHULE_BEZEICHNUNG: 'Schule auf der Uhlenhorst',
    EN_SCHULE_STRASSE_NR: 'Winterhuder Weg 126/128',
    EN_SCHULE_PLZ: '22085',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 360,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074659,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5417,
    EN_SCHULE_BEZEICHNUNG: 'Kurt-Juster-Schule',
    EN_SCHULE_STRASSE_NR: 'Alsterdorfer Straße 420',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 102,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfK',
    EN_EN_OBJ_ID_PKT: 2074648,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478709,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5418,
    EN_SCHULE_BEZEICHNUNG: 'Bildungszentrum für Blinde und Sehbehinderte',
    EN_SCHULE_STRASSE_NR: 'Borgweg 17a',
    EN_SCHULE_PLZ: '22303',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 198,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfBuS',
    EN_EN_OBJ_ID_PKT: 2074800,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478707,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5419,
    EN_SCHULE_BEZEICHNUNG: 'Schule Tegelweg',
    EN_SCHULE_STRASSE_NR: 'Tegelweg 104',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 148,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfK',
    EN_EN_OBJ_ID_PKT: 2074712,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478709,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5430,
    EN_SCHULE_BEZEICHNUNG: 'Adolph-Schönfelder-Schule',
    EN_SCHULE_STRASSE_NR: 'Zeisigstraße 3',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 577,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074656,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5437,
    EN_SCHULE_BEZEICHNUNG: 'Schule Genslerstraße',
    EN_SCHULE_STRASSE_NR: 'Genslerstraße 33',
    EN_SCHULE_PLZ: '22307',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 325,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074661,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5438,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Ballerstaedtweg',
    EN_SCHULE_STRASSE_NR: 'Ballerstaedtweg 1',
    EN_SCHULE_PLZ: '22337',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 283,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 7050948,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5439,
    EN_SCHULE_BEZEICHNUNG: 'Schule Lämmersieth',
    EN_SCHULE_STRASSE_NR: 'Lämmersieth 72a',
    EN_SCHULE_PLZ: '22305',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 160,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074663,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5447,
    EN_SCHULE_BEZEICHNUNG: 'Schule Paracelsusstraße',
    EN_SCHULE_STRASSE_NR: 'Paracelsusstraße 30',
    EN_SCHULE_PLZ: '22147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 112,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074756,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5462,
    EN_SCHULE_BEZEICHNUNG: 'Carl-Cohn-Schule',
    EN_SCHULE_STRASSE_NR: 'Carl-Cohn-Straße 2',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 544,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074651,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5463,
    EN_SCHULE_BEZEICHNUNG: 'Carl-Götze-Schule',
    EN_SCHULE_STRASSE_NR: 'Brödermannsweg 2',
    EN_SCHULE_PLZ: '22453',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 340,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074641,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5464,
    EN_SCHULE_BEZEICHNUNG: 'Schule Eberhofweg',
    EN_SCHULE_STRASSE_NR: 'Eberhofweg 63',
    EN_SCHULE_PLZ: '22415',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 311,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074672,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5466,
    EN_SCHULE_BEZEICHNUNG: 'Schule Foorthkamp',
    EN_SCHULE_STRASSE_NR: null,
    EN_SCHULE_PLZ: null,
    EN_SCHULE_ORT: null,
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2076320,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5469,
    EN_SCHULE_BEZEICHNUNG: 'Schule Krohnstieg',
    EN_SCHULE_STRASSE_NR: 'Krohnstieg 107',
    EN_SCHULE_PLZ: '22415',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 245,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074674,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5472,
    EN_SCHULE_BEZEICHNUNG: 'Schule Neubergerweg',
    EN_SCHULE_STRASSE_NR: 'Neubergerweg 2',
    EN_SCHULE_PLZ: '22419',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 412,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074675,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5473,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ohkamp',
    EN_SCHULE_STRASSE_NR: 'Ohkampring 13',
    EN_SCHULE_PLZ: '22339',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 394,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074676,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5476,
    EN_SCHULE_BEZEICHNUNG: 'Schule Stockflethweg',
    EN_SCHULE_STRASSE_NR: 'Stockflethweg 160',
    EN_SCHULE_PLZ: '22417',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 318,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074678,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5480,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ratsmühlendamm',
    EN_SCHULE_STRASSE_NR: 'Ratsmühlendamm 39',
    EN_SCHULE_PLZ: '22335',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 399,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074677,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5501,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Altrahlstedt',
    EN_SCHULE_STRASSE_NR: 'Brockdorffstraße 2',
    EN_SCHULE_PLZ: '22149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074742,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5502,
    EN_SCHULE_BEZEICHNUNG: 'Schule Am Sooren',
    EN_SCHULE_STRASSE_NR: 'Brunskamp 17',
    EN_SCHULE_PLZ: '22149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 205,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074749,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5503,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bekassinenau',
    EN_SCHULE_STRASSE_NR: 'Bekassinenau 32',
    EN_SCHULE_PLZ: '22147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 403,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074750,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5504,
    EN_SCHULE_BEZEICHNUNG: 'Schule Brockdorffstraße',
    EN_SCHULE_STRASSE_NR: 'Brockdorffstraße 64',
    EN_SCHULE_PLZ: '22149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 405,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074752,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5505,
    EN_SCHULE_BEZEICHNUNG: 'Schule Charlottenburger Straße',
    EN_SCHULE_STRASSE_NR: 'Charlottenburger Straße 84',
    EN_SCHULE_PLZ: '22045',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 261,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074691,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5506,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Großlohering',
    EN_SCHULE_STRASSE_NR: 'Großlohering 11',
    EN_SCHULE_PLZ: '22143',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 188,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074743,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5507,
    EN_SCHULE_BEZEICHNUNG: 'Schule Kamminer Straße',
    EN_SCHULE_STRASSE_NR: 'Kamminer Straße 4',
    EN_SCHULE_PLZ: '22147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 258,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074753,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5508,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Neurahlstedt',
    EN_SCHULE_STRASSE_NR: 'Rahlstedter Straße 190',
    EN_SCHULE_PLZ: '22143',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 437,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074744,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5510,
    EN_SCHULE_BEZEICHNUNG: 'Schule Potsdamer Straße',
    EN_SCHULE_STRASSE_NR: 'Potsdamer Straße 6',
    EN_SCHULE_PLZ: '22149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 261,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074695,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5513,
    EN_SCHULE_BEZEICHNUNG: 'Schule Sieker Landstraße',
    EN_SCHULE_STRASSE_NR: 'Sieker Landstraße 18',
    EN_SCHULE_PLZ: '22143',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074758,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5514,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Tonndorf',
    EN_SCHULE_STRASSE_NR: 'Rahlaukamp 1a',
    EN_SCHULE_PLZ: '22045',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 209,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074683,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5515,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bekkamp',
    EN_SCHULE_STRASSE_NR: 'Bekkamp 52',
    EN_SCHULE_PLZ: '22045',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 150,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074751,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5520,
    EN_SCHULE_BEZEICHNUNG: 'Schule Alsterredder',
    EN_SCHULE_STRASSE_NR: 'Alsterredder 28',
    EN_SCHULE_PLZ: '22395',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 483,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074722,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5522,
    EN_SCHULE_BEZEICHNUNG: 'Schule Am Walde',
    EN_SCHULE_STRASSE_NR: 'Kupferredder 12',
    EN_SCHULE_PLZ: '22397',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 196,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074732,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5523,
    EN_SCHULE_BEZEICHNUNG: 'Schule Duvenstedter Markt',
    EN_SCHULE_STRASSE_NR: 'Duvenstedter Markt 12',
    EN_SCHULE_PLZ: '22397',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 360,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074736,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5525,
    EN_SCHULE_BEZEICHNUNG: 'Schule An den Teichwiesen',
    EN_SCHULE_STRASSE_NR: 'Saseler Weg 30',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 322,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074733,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5526,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bergstedt',
    EN_SCHULE_STRASSE_NR: 'Bergstedter Alte Landstraße 12',
    EN_SCHULE_PLZ: '22395',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 355,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074734,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5529,
    EN_SCHULE_BEZEICHNUNG: 'Schule Buckhorn',
    EN_SCHULE_STRASSE_NR: 'Volksdorfer Damm 74',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 342,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074735,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5530,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hinsbleek',
    EN_SCHULE_STRASSE_NR: 'Hinsbleek 14',
    EN_SCHULE_PLZ: '22391',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 327,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074724,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5532,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ahrensburger Weg',
    EN_SCHULE_STRASSE_NR: 'Ahrensburger Weg 12',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 418,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074731,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5533,
    EN_SCHULE_BEZEICHNUNG: 'Schule Eulenkrugstraße',
    EN_SCHULE_STRASSE_NR: 'Eulenkrugstraße 166',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 326,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074737,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5534,
    EN_SCHULE_BEZEICHNUNG: 'Schule Redder',
    EN_SCHULE_STRASSE_NR: 'Redder  4',
    EN_SCHULE_PLZ: '22393',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 426,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074726,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5535,
    EN_SCHULE_BEZEICHNUNG: 'Schule Lemsahl-Mellingstedt',
    EN_SCHULE_STRASSE_NR: 'Redderbarg 46/48',
    EN_SCHULE_PLZ: '22397',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 372,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074738,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5537,
    EN_SCHULE_BEZEICHNUNG: 'Schule Müssenredder',
    EN_SCHULE_STRASSE_NR: 'Müssenredder 61',
    EN_SCHULE_PLZ: '22399',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 410,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074725,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5538,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Islandstraße',
    EN_SCHULE_STRASSE_NR: 'Islandstraße 25',
    EN_SCHULE_PLZ: '22145',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 437,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074755,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5539,
    EN_SCHULE_BEZEICHNUNG: 'Anne-Frank-Schule',
    EN_SCHULE_STRASSE_NR: 'Hohnerkamp 58',
    EN_SCHULE_PLZ: '22175',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074698,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5540,
    EN_SCHULE_BEZEICHNUNG: 'Schule Grützmühlenweg',
    EN_SCHULE_STRASSE_NR: 'Grützmühlenweg 38',
    EN_SCHULE_PLZ: '22339',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 415,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074723,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5541,
    EN_SCHULE_BEZEICHNUNG: 'Schule Strenge',
    EN_SCHULE_STRASSE_NR: 'Strenge 5',
    EN_SCHULE_PLZ: '22391',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 430,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074727,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5542,
    EN_SCHULE_BEZEICHNUNG: 'Schule Wildschwanbrook',
    EN_SCHULE_STRASSE_NR: 'Wildschwanbrook 9',
    EN_SCHULE_PLZ: '22145',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 230,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074759,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5544,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rahlstedter Höhe',
    EN_SCHULE_STRASSE_NR: 'Ahrenshooper Straße 1-3',
    EN_SCHULE_PLZ: '22147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 462,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074757,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5550,
    EN_SCHULE_BEZEICHNUNG: 'Schule Traberweg',
    EN_SCHULE_STRASSE_NR: 'Eckerkoppel 125',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 230,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074713,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5551,
    EN_SCHULE_BEZEICHNUNG: 'Schule Eenstock',
    EN_SCHULE_STRASSE_NR: 'Eenstock 15',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 303,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074709,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5557,
    EN_SCHULE_BEZEICHNUNG: 'Schule An der Seebek',
    EN_SCHULE_STRASSE_NR: 'Heinrich-Helbing-Straße 50',
    EN_SCHULE_PLZ: '22177',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 557,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074707,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5558,
    EN_SCHULE_BEZEICHNUNG: 'Schule Fahrenkrön',
    EN_SCHULE_STRASSE_NR: 'Fahrenkrön 115',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 380,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074710,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5564,
    EN_SCHULE_BEZEICHNUNG: 'Schule Appelhoff',
    EN_SCHULE_STRASSE_NR: 'Appelhoff 2',
    EN_SCHULE_PLZ: '22309',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 286,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074708,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5565,
    EN_SCHULE_BEZEICHNUNG: 'Schule Surenland',
    EN_SCHULE_STRASSE_NR: 'Bramfelder Weg 121',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 233,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074711,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5575,
    EN_SCHULE_BEZEICHNUNG: 'Schule An der Gartenstadt',
    EN_SCHULE_STRASSE_NR: 'Stephanstraße 103',
    EN_SCHULE_PLZ: '22047',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 549,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074688,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5577,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bandwirkerstraße',
    EN_SCHULE_STRASSE_NR: 'Bandwirkerstraße 56-58',
    EN_SCHULE_PLZ: '22041',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 192,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074689,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5578,
    EN_SCHULE_BEZEICHNUNG: 'Schule Bovestraße',
    EN_SCHULE_STRASSE_NR: 'Bovestraße 10-12',
    EN_SCHULE_PLZ: '22041',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 383,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074690,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5581,
    EN_SCHULE_BEZEICHNUNG: 'Schule Hasselbrook',
    EN_SCHULE_STRASSE_NR: 'Ritterstraße 44',
    EN_SCHULE_PLZ: '22089',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 320,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074504,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5584,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ifflandstraße',
    EN_SCHULE_STRASSE_NR: 'Ifflandstraße 30',
    EN_SCHULE_PLZ: '22087',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 0,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2076322,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5586,
    EN_SCHULE_BEZEICHNUNG: 'Schule Öjendorfer Damm',
    EN_SCHULE_STRASSE_NR: 'Öjendorfer Damm 8',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 263,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074693,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5587,
    EN_SCHULE_BEZEICHNUNG: 'Schule am Eichtalpark',
    EN_SCHULE_STRASSE_NR: 'Walddörferstraße 243',
    EN_SCHULE_PLZ: '22047',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 265,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074687,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5588,
    EN_SCHULE_BEZEICHNUNG: 'Schule Richardstraße',
    EN_SCHULE_STRASSE_NR: 'Richardstraße 85',
    EN_SCHULE_PLZ: '22089',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 342,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074506,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5591,
    EN_SCHULE_BEZEICHNUNG: 'Schule Wielandstraße',
    EN_SCHULE_STRASSE_NR: 'Wielandstraße 9',
    EN_SCHULE_PLZ: '22089',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 392,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074507,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5592,
    EN_SCHULE_BEZEICHNUNG: 'Schule Oppelner Straße',
    EN_SCHULE_STRASSE_NR: 'Oppelner Straße 45',
    EN_SCHULE_PLZ: '22045',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 239,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074694,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5594,
    EN_SCHULE_BEZEICHNUNG: 'Schule Jenfelder Straße',
    EN_SCHULE_STRASSE_NR: 'Jenfelder Straße 252',
    EN_SCHULE_PLZ: '22045',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 300,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074692,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5602,
    EN_SCHULE_BEZEICHNUNG: 'Schule Sander Straße',
    EN_SCHULE_STRASSE_NR: 'Sander Straße 11',
    EN_SCHULE_PLZ: '21029',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 320,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073532,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5603,
    EN_SCHULE_BEZEICHNUNG: 'Anton-Rée-Schule Allermöhe',
    EN_SCHULE_STRASSE_NR: 'Ebner-Eschenbach-Weg 43',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 499,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073512,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5604,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ernst-Henning-Straße',
    EN_SCHULE_STRASSE_NR: 'Ernst-Henning-Straße 20',
    EN_SCHULE_PLZ: '21029',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 398,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073539,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5605,
    EN_SCHULE_BEZEICHNUNG: 'Schule Leuschnerstraße',
    EN_SCHULE_STRASSE_NR: 'Leuschnerstraße 13',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 164,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073524,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5606,
    EN_SCHULE_BEZEICHNUNG: 'Schule An der Twiete',
    EN_SCHULE_STRASSE_NR: 'An der Twiete 23',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2073537,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5607,
    EN_SCHULE_BEZEICHNUNG: 'Schule Max-Eichholz-Ring',
    EN_SCHULE_STRASSE_NR: 'Max-Eichholz-Ring 25',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 430,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073526,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5610,
    EN_SCHULE_BEZEICHNUNG: 'Schule Nettelnburg',
    EN_SCHULE_STRASSE_NR: 'Fiddigshagen 11',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 439,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073515,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5611,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Mendelstraße',
    EN_SCHULE_STRASSE_NR: 'Mendelstraße 6',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 355,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073527,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5612,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Heidhorst',
    EN_SCHULE_STRASSE_NR: 'Heidhorst 16',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 275,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073518,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5613,
    EN_SCHULE_BEZEICHNUNG: 'Schule Weidemoor',
    EN_SCHULE_STRASSE_NR: 'Weidemoor 1',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 145,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2073541,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5614,
    EN_SCHULE_BEZEICHNUNG: 'Schule Friedrich-Frank-Bogen',
    EN_SCHULE_STRASSE_NR: 'Friedrich-Frank-Bogen 25',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 208,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073516,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5615,
    EN_SCHULE_BEZEICHNUNG: 'Clara-Grunwald-Schule',
    EN_SCHULE_STRASSE_NR: 'Walter-Rothenburg-Weg 37',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 351,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073534,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5616,
    EN_SCHULE_BEZEICHNUNG: 'Adolph-Diesterweg-Schule',
    EN_SCHULE_STRASSE_NR: 'Felix-Jud-Ring 29-33',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 352,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073506,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5630,
    EN_SCHULE_BEZEICHNUNG: 'Schule Mittlerer Landweg',
    EN_SCHULE_STRASSE_NR: 'Mittlerer Landweg 48',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 104,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073528,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5631,
    EN_SCHULE_BEZEICHNUNG: 'Schule Altengamme-Deich',
    EN_SCHULE_STRASSE_NR: 'Kirchenstegel 12',
    EN_SCHULE_PLZ: '21039',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 116,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073522,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5633,
    EN_SCHULE_BEZEICHNUNG: 'Schule Billwerder Straße',
    EN_SCHULE_STRASSE_NR: 'Billwerder Straße 31',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2073538,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5634,
    EN_SCHULE_BEZEICHNUNG: 'Schule Curslack-Neuengamme',
    EN_SCHULE_STRASSE_NR: 'Gramkowweg 5',
    EN_SCHULE_PLZ: '21039',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 266,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073517,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5635,
    EN_SCHULE_BEZEICHNUNG: 'Schule Fünfhausen-Warwisch',
    EN_SCHULE_STRASSE_NR: 'Durchdeich 108',
    EN_SCHULE_PLZ: '21037',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 179,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073511,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5644,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ochsenwerder',
    EN_SCHULE_STRASSE_NR: 'Elversweg 44',
    EN_SCHULE_PLZ: '21037',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 242,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073513,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5648,
    EN_SCHULE_BEZEICHNUNG: 'Schule Zollenspieker',
    EN_SCHULE_STRASSE_NR: 'Kirchenheerweg 223',
    EN_SCHULE_PLZ: '21037',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 200,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2073521,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5649,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Rissen',
    EN_SCHULE_STRASSE_NR: 'Voßhagen 15',
    EN_SCHULE_PLZ: '22559',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 694,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 5400907,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5650,
    EN_SCHULE_BEZEICHNUNG: 'Grund- und Stadtteilschule Altrahlstedt',
    EN_SCHULE_STRASSE_NR: 'Hüllenkamp 19',
    EN_SCHULE_PLZ: '22149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 1039,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074760,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5651,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Ehestorfer Weg',
    EN_SCHULE_STRASSE_NR: 'Ehestorfer Weg 14',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 745,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074784,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5654,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Altona',
    EN_SCHULE_STRASSE_NR: 'Eckernförder Str. 70',
    EN_SCHULE_PLZ: '22769',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 679,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074551,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5655,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Stellingen',
    EN_SCHULE_STRASSE_NR: 'Brehmweg 60',
    EN_SCHULE_PLZ: '22527',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 1144,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074615,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5656,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Bramfeld',
    EN_SCHULE_STRASSE_NR: 'Bramfelder Dorfplatz 5',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 642,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074714,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5657,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Richard-Linde-Weg',
    EN_SCHULE_STRASSE_NR: 'Richard-Linde-Weg 49',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1030,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2073531,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5658,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Stübenhofer Weg',
    EN_SCHULE_STRASSE_NR: 'Stübenhofer Weg 20 a',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 760,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074542,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5659,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Flottbek',
    EN_SCHULE_STRASSE_NR: 'Ohlenkamp 15 a',
    EN_SCHULE_PLZ: '22607',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 586,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074595,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5660,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Oldenfelde',
    EN_SCHULE_STRASSE_NR: 'Delingsdorfer Weg 6',
    EN_SCHULE_PLZ: '22143',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 832,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074762,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5661,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Lurup',
    EN_SCHULE_STRASSE_NR: 'Luruper Hauptstraße 131/133',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 1045,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074572,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5662,
    EN_SCHULE_BEZEICHNUNG: 'Emil Krause Schule',
    EN_SCHULE_STRASSE_NR: 'Krausestraße 53',
    EN_SCHULE_PLZ: '22049',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 818,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074665,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5663,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Hamburg-Mitte',
    EN_SCHULE_STRASSE_NR: 'Rostocker Straße 41',
    EN_SCHULE_PLZ: '20099',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 1094,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074509,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5664,
    EN_SCHULE_BEZEICHNUNG: 'Lessing-Stadtteilschule',
    EN_SCHULE_STRASSE_NR: 'Hanhoopsfeld 21',
    EN_SCHULE_PLZ: '21079',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 919,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074769,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5665,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Niendorf',
    EN_SCHULE_STRASSE_NR: 'Paul-Sorge-Straße 133/135',
    EN_SCHULE_PLZ: '22455',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 1172,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074628,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5667,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Am Hafen',
    EN_SCHULE_STRASSE_NR: 'Neustädter Straße 60',
    EN_SCHULE_PLZ: '20355',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 1023,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074508,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5668,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Langenhorn',
    EN_SCHULE_STRASSE_NR: 'Grellkamp 40',
    EN_SCHULE_PLZ: '22415',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074680,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5669,
    EN_SCHULE_BEZEICHNUNG: 'Stadtteilschule Fischbek-Falkenberg',
    EN_SCHULE_STRASSE_NR: 'Fischbeker Moor 6',
    EN_SCHULE_PLZ: '21149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 1692,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2076431,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5670,
    EN_SCHULE_BEZEICHNUNG: 'Otto-Hahn-Schule',
    EN_SCHULE_STRASSE_NR: 'Jenfelder Allee 53',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 1308,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'STS',
    EN_EN_OBJ_ID_PKT: 2074686,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478704,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5701,
    EN_SCHULE_BEZEICHNUNG: 'Schule Dempwolffstraße',
    EN_SCHULE_STRASSE_NR: 'Dempwolffstraße 7',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 235,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074771,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5703,
    EN_SCHULE_BEZEICHNUNG: 'Schule Maretstraße',
    EN_SCHULE_STRASSE_NR: 'Maretstraße 50',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 817,
    EN_SCHULE_BSB_KAPITEL: 3140,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G/STS',
    EN_EN_OBJ_ID_PKT: 2074766,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478702,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5704,
    EN_SCHULE_BEZEICHNUNG: 'Schule Grumbrechtstraße',
    EN_SCHULE_STRASSE_NR: 'Grumbrechtstraße 63',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 669,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074773,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5706,
    EN_SCHULE_BEZEICHNUNG: 'Schule In der Alten Forst',
    EN_SCHULE_STRASSE_NR: 'In der Alten Forst 1',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 637,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074774,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5707,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rönneburg',
    EN_SCHULE_STRASSE_NR: 'Kanzlerstraße 25',
    EN_SCHULE_PLZ: '21079',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 467,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074780,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5708,
    EN_SCHULE_BEZEICHNUNG: 'Schule Kapellenweg',
    EN_SCHULE_STRASSE_NR: 'Kapellenweg 63',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 418,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074775,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5710,
    EN_SCHULE_BEZEICHNUNG: 'Georg-Kerschensteiner-Grundschule',
    EN_SCHULE_STRASSE_NR: 'Baererstraße 81',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 416,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074776,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5713,
    EN_SCHULE_BEZEICHNUNG: 'Schule Marmstorf',
    EN_SCHULE_STRASSE_NR: 'Ernst-Bergeest-Weg 54',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 501,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074777,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5714,
    EN_SCHULE_BEZEICHNUNG: 'Schule Neuland',
    EN_SCHULE_STRASSE_NR: 'Neuländer Elbdeich 241',
    EN_SCHULE_PLZ: '21079',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 158,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074778,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5716,
    EN_SCHULE_BEZEICHNUNG: 'Schule Schwarzenbergstraße',
    EN_SCHULE_STRASSE_NR: 'Schwarzenbergstraße 72',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074782,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5718,
    EN_SCHULE_BEZEICHNUNG: 'Schule Scheeßeler Kehre',
    EN_SCHULE_STRASSE_NR: 'Scheeßeler Kehre 2',
    EN_SCHULE_PLZ: '21079',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 355,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074781,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5719,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule am Kiefernberg',
    EN_SCHULE_STRASSE_NR: 'Weusthoffstraße 95',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 610,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074783,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5721,
    EN_SCHULE_BEZEICHNUNG: 'Schule Baererstraße',
    EN_SCHULE_STRASSE_NR: 'Baererstraße 81',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074770,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5722,
    EN_SCHULE_BEZEICHNUNG: 'Schule Elfenwiese',
    EN_SCHULE_STRASSE_NR: 'Elfenwiese 3',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 215,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfK',
    EN_EN_OBJ_ID_PKT: 2074772,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478709,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5723,
    EN_SCHULE_BEZEICHNUNG: 'Schule Nymphenweg',
    EN_SCHULE_STRASSE_NR: 'Nymphenweg 20',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 120,
    EN_SCHULE_BSB_KAPITEL: 3110,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'F',
    EN_SCHULE_FORM: 'SfGb',
    EN_EN_OBJ_ID_PKT: 2074779,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478699,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478712,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5742,
    EN_SCHULE_BEZEICHNUNG: 'Elbinselschule',
    EN_SCHULE_STRASSE_NR: 'Krieterstraße 2b',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 582,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074530,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5743,
    EN_SCHULE_BEZEICHNUNG: 'Schule Cranz',
    EN_SCHULE_STRASSE_NR: 'Estebogen 3',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 75,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074792,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5744,
    EN_SCHULE_BEZEICHNUNG: 'Ganztagsschule Fährstraße',
    EN_SCHULE_STRASSE_NR: 'Fährstraße 90',
    EN_SCHULE_PLZ: '21107',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 332,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074532,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5747,
    EN_SCHULE_BEZEICHNUNG: 'Ganztagsgrundschule Am Johannisland',
    EN_SCHULE_STRASSE_NR: 'Am Johannisland 2-4',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 441,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074788,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5749,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule an der Haake',
    EN_SCHULE_STRASSE_NR: 'Lange Striepen 51',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 458,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074793,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5753,
    EN_SCHULE_BEZEICHNUNG: 'Schule Arp-Schnitger-Stieg',
    EN_SCHULE_STRASSE_NR: 'Arp-Schnitger-Stieg 19',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 281,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074791,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5755,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Neugraben',
    EN_SCHULE_STRASSE_NR: 'Francoper Straße 32',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 489,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074795,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5757,
    EN_SCHULE_BEZEICHNUNG: 'Frieda-Stoppenbrink-Schule',
    EN_SCHULE_STRASSE_NR: 'Neuwiedenthaler Straße 4',
    EN_SCHULE_PLZ: '21147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074787,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5761,
    EN_SCHULE_BEZEICHNUNG: 'Schule Rotenhäuser Damm',
    EN_SCHULE_STRASSE_NR: 'Rotenhäuser Damm 45',
    EN_SCHULE_PLZ: '21107',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 313,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074539,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5762,
    EN_SCHULE_BEZEICHNUNG: 'Schule Schnuckendrift',
    EN_SCHULE_STRASSE_NR: 'Schnuckendrift 21',
    EN_SCHULE_PLZ: '21149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 330,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074797,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5763,
    EN_SCHULE_BEZEICHNUNG: 'Grundschule Stübenhofer Weg',
    EN_SCHULE_STRASSE_NR: 'Stübenhofer Weg 20',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074534,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5764,
    EN_SCHULE_BEZEICHNUNG: 'Willi-Kraft-Schule',
    EN_SCHULE_STRASSE_NR: 'Zeidlerstraße 50',
    EN_SCHULE_PLZ: '21107',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074543,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5765,
    EN_SCHULE_BEZEICHNUNG: 'Sprachheilschule Wilhelmsburg',
    EN_SCHULE_STRASSE_NR: 'Kurdamm 8',
    EN_SCHULE_PLZ: '21107',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074541,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5768,
    EN_SCHULE_BEZEICHNUNG: 'Schule Ohrnsweg',
    EN_SCHULE_STRASSE_NR: 'Ohrnsweg 52',
    EN_SCHULE_PLZ: '21149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 252,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074796,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5770,
    EN_SCHULE_BEZEICHNUNG: 'Schule Karl-Arnold-Ring',
    EN_SCHULE_STRASSE_NR: 'Karl-Arnold-Ring 11',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074538,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5773,
    EN_SCHULE_BEZEICHNUNG: 'Schule an der Burgweide',
    EN_SCHULE_STRASSE_NR: 'Karl-Arnold-Ring 13',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 231,
    EN_SCHULE_BSB_KAPITEL: 3100,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'G',
    EN_EN_OBJ_ID_PKT: 2074537,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478703,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5800,
    EN_SCHULE_BEZEICHNUNG: 'Kurt-Körber-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Pergamentweg 1',
    EN_SCHULE_PLZ: '22117',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 2,
    EN_SCHULE_SCHUELER_LFD_SJ: 539,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074515,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5801,
    EN_SCHULE_BEZEICHNUNG: 'Friedrich-Ebert-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Alter Postweg 30-36',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 757,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074765,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5802,
    EN_SCHULE_BEZEICHNUNG: 'Matthias-Claudius-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Witthöfftstraße 8',
    EN_SCHULE_PLZ: '22041',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 941,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074685,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5803,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Süderelbe',
    EN_SCHULE_STRASSE_NR: 'Falkenbergsweg 5',
    EN_SCHULE_PLZ: '21149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 1010,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074790,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5805,
    EN_SCHULE_BEZEICHNUNG: 'Struensee Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Struenseestraße 20',
    EN_SCHULE_PLZ: '22767',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 448,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 5455937,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5806,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Rotherbaum',
    EN_SCHULE_STRASSE_NR: 'Bundesstraße 58',
    EN_SCHULE_PLZ: '20146',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 7223267,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5807,
    EN_SCHULE_BEZEICHNUNG: 'Helmut-Schmidt-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Krieterstraße 5',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 3,
    EN_SCHULE_SCHUELER_LFD_SJ: 892,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074535,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5809,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Rissen',
    EN_SCHULE_STRASSE_NR: 'Voßhagen 15',
    EN_SCHULE_PLZ: '22559',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 643,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074576,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5810,
    EN_SCHULE_BEZEICHNUNG: 'Albert-Schweitzer-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Struckholt 27/29',
    EN_SCHULE_PLZ: '22337',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 995,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074667,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5811,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Alstertal',
    EN_SCHULE_STRASSE_NR: 'Erdkampsweg 89',
    EN_SCHULE_PLZ: '22335',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 671,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074670,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5812,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Buckhorn',
    EN_SCHULE_STRASSE_NR: 'Im Regestall 25',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 1078,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074729,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5813,
    EN_SCHULE_BEZEICHNUNG: 'Christianeum',
    EN_SCHULE_STRASSE_NR: 'Otto-Ernst-Straße 34',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 1002,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074586,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5814,
    EN_SCHULE_BEZEICHNUNG: 'Heisenberg-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Triftstraße 43',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 845,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074767,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5816,
    EN_SCHULE_BEZEICHNUNG: 'Walddörfer-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Im Allhorn 45',
    EN_SCHULE_PLZ: '22359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 1130,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074741,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5818,
    EN_SCHULE_BEZEICHNUNG: 'Abendgymnasium mit Abendschule St. Georg',
    EN_SCHULE_STRASSE_NR: 'Rostocker Straße 41',
    EN_SCHULE_PLZ: '20099',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 553,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074491,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5819,
    EN_SCHULE_BEZEICHNUNG: 'Abendschule Vor dem Holstentor',
    EN_SCHULE_STRASSE_NR: 'Holstenglacis 6',
    EN_SCHULE_PLZ: '20355',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 598,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074492,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5820,
    EN_SCHULE_BEZEICHNUNG: 'Hansa-Kolleg',
    EN_SCHULE_STRASSE_NR: 'Von-Essen-Straße 82-84',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 155,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 2074657,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5821,
    EN_SCHULE_BEZEICHNUNG: 'Studienkolleg für ausländische Studierende',
    EN_SCHULE_STRASSE_NR: 'Holstenglacis 6',
    EN_SCHULE_PLZ: '20355',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074510,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5822,
    EN_SCHULE_BEZEICHNUNG: 'Johannes-Brahms-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Höhnkoppelort 24',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 623,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074706,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5823,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Corveystraße',
    EN_SCHULE_STRASSE_NR: 'Corveystraße 6',
    EN_SCHULE_PLZ: '22529',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 998,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074618,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5824,
    EN_SCHULE_BEZEICHNUNG: 'Goethe-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Rispenweg 28',
    EN_SCHULE_PLZ: '22547',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 5,
    EN_SCHULE_SCHUELER_LFD_SJ: 768,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074565,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5825,
    EN_SCHULE_BEZEICHNUNG: 'Lise-Meitner-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Knabeweg 3',
    EN_SCHULE_PLZ: '22549',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 896,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074591,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5829,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Ohmoor',
    EN_SCHULE_STRASSE_NR: 'Sachsenweg 76',
    EN_SCHULE_PLZ: '22455',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 1311,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074619,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5830,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Altona',
    EN_SCHULE_STRASSE_NR: 'Hohenzollernring 57',
    EN_SCHULE_PLZ: '22763',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1000,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074550,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5831,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Blankenese',
    EN_SCHULE_STRASSE_NR: 'Oesterleystraße 27',
    EN_SCHULE_PLZ: '22587',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 921,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074575,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5832,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Allermöhe',
    EN_SCHULE_STRASSE_NR: 'Walter-Rothenburg-Weg 41',
    EN_SCHULE_PLZ: '21035',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 611,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2073535,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5834,
    EN_SCHULE_BEZEICHNUNG: 'Margaretha-Rothe-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Langenfort 5',
    EN_SCHULE_PLZ: '22307',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 778,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074658,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5835,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Heidberg',
    EN_SCHULE_STRASSE_NR: 'Fritz-Schumacher-Allee 200',
    EN_SCHULE_PLZ: '22417',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 14,
    EN_SCHULE_SCHUELER_LFD_SJ: 890,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074671,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5837,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Oldenfelde',
    EN_SCHULE_STRASSE_NR: 'Birrenkovenallee 12',
    EN_SCHULE_PLZ: '22143',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 821,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074747,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5838,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Bornbrook',
    EN_SCHULE_STRASSE_NR: 'Schulenburgring 4',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 789,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2073533,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5839,
    EN_SCHULE_BEZEICHNUNG: 'Immanuel-Kant-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Am Pavillon 15',
    EN_SCHULE_PLZ: '21077',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 554,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074768,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5840,
    EN_SCHULE_BEZEICHNUNG: 'Alexander-von-Humboldt-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Rönneburger Straße 50',
    EN_SCHULE_PLZ: '21079',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 21,
    EN_SCHULE_SCHUELER_LFD_SJ: 754,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074764,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5841,
    EN_SCHULE_BEZEICHNUNG: 'Marion Dönhoff Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Willhöden 74',
    EN_SCHULE_PLZ: '22587',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 6,
    EN_SCHULE_SCHUELER_LFD_SJ: 905,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074577,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5842,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Hoheluft',
    EN_SCHULE_STRASSE_NR: 'Christian-Förster-Straße 21',
    EN_SCHULE_PLZ: '20253',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 647,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074599,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5844,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Eppendorf',
    EN_SCHULE_STRASSE_NR: 'Hegestraße 35',
    EN_SCHULE_PLZ: '20249',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 898,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074645,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5845,
    EN_SCHULE_BEZEICHNUNG: 'Hansa-Gymnasium Bergedorf',
    EN_SCHULE_STRASSE_NR: 'Hermann-Distel-Straße 25',
    EN_SCHULE_PLZ: '21029',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 789,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2073520,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5846,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Kaiser-Friedrich-Ufer',
    EN_SCHULE_STRASSE_NR: 'Kaiser-Friedrich-Ufer 6',
    EN_SCHULE_PLZ: '20259',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 993,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074600,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5847,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Lohbrügge',
    EN_SCHULE_STRASSE_NR: 'Binnenfeldredder 5',
    EN_SCHULE_PLZ: '21031',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 1013,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2073509,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5848,
    EN_SCHULE_BEZEICHNUNG: 'Luisen-Gymnasium Bergedorf',
    EN_SCHULE_STRASSE_NR: 'Reinbeker Weg 76',
    EN_SCHULE_PLZ: '21029',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 20,
    EN_SCHULE_SCHUELER_LFD_SJ: 947,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2073530,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5849,
    EN_SCHULE_BEZEICHNUNG: 'Carl-von-Ossietzky-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Müssenredder 59',
    EN_SCHULE_PLZ: '22399',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 936,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074715,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5850,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Oberalster',
    EN_SCHULE_STRASSE_NR: 'Alsterredder 26',
    EN_SCHULE_PLZ: '22395',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 851,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074719,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5853,
    EN_SCHULE_BEZEICHNUNG: 'Louise Weiss Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Ebelingplatz 8',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 571,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074497,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5856,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Finkenwerder',
    EN_SCHULE_STRASSE_NR: 'Norderschulweg 18',
    EN_SCHULE_PLZ: '21129',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 22,
    EN_SCHULE_SCHUELER_LFD_SJ: 419,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074789,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5859,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Dörpsweg',
    EN_SCHULE_STRASSE_NR: 'Dörpsweg 10',
    EN_SCHULE_PLZ: '22527',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 11,
    EN_SCHULE_SCHUELER_LFD_SJ: 850,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074630,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5860,
    EN_SCHULE_BEZEICHNUNG: 'Albrecht-Thaer-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Wegenkamp 3',
    EN_SCHULE_PLZ: '22527',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 9,
    EN_SCHULE_SCHUELER_LFD_SJ: 820,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074608,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5861,
    EN_SCHULE_BEZEICHNUNG: 'Eimsbüttler Modell, Oberstufenhaus',
    EN_SCHULE_STRASSE_NR: 'Bogenstraße 59',
    EN_SCHULE_PLZ: '20253',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2076317,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5862,
    EN_SCHULE_BEZEICHNUNG: 'Charlotte-Paulsen-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Neumann-Reichardt-Str.20',
    EN_SCHULE_PLZ: '22041',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 855,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074681,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5863,
    EN_SCHULE_BEZEICHNUNG: 'Emilie-Wüstenfeld-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Bundesstraße 78',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 1013,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074597,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5864,
    EN_SCHULE_BEZEICHNUNG: 'Helene-Lange-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Bogenstraße 32',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 986,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074601,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5866,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Klosterschule',
    EN_SCHULE_STRASSE_NR: 'Westphalensweg 7',
    EN_SCHULE_PLZ: '20099',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 1,
    EN_SCHULE_SCHUELER_LFD_SJ: 897,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074498,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5868,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Lerchenfeld',
    EN_SCHULE_STRASSE_NR: 'Lerchenfeld 10',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 13,
    EN_SCHULE_SCHUELER_LFD_SJ: 953,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2076323,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5869,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Rahlstedt',
    EN_SCHULE_STRASSE_NR: 'Scharbeutzer Straße 36',
    EN_SCHULE_PLZ: '22147',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 1018,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074748,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5870,
    EN_SCHULE_BEZEICHNUNG: 'Uhlenhorst-Barmbek',
    EN_SCHULE_STRASSE_NR: 'Osterbekstraße 107',
    EN_SCHULE_PLZ: '22083',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2076325,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5872,
    EN_SCHULE_BEZEICHNUNG: 'Wilhelm-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Klosterstieg 17',
    EN_SCHULE_PLZ: '20149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 8,
    EN_SCHULE_SCHUELER_LFD_SJ: 729,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074607,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5879,
    EN_SCHULE_BEZEICHNUNG: 'Deutsch-Französisches Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Hartsprung 23',
    EN_SCHULE_PLZ: '22529',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 405,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 7193933,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5881,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Allee',
    EN_SCHULE_STRASSE_NR: 'Max-Brauer-Allee 83-85',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1044,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074549,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5882,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Bondenwald',
    EN_SCHULE_STRASSE_NR: 'Bondenwald 14b',
    EN_SCHULE_PLZ: '22453',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 10,
    EN_SCHULE_SCHUELER_LFD_SJ: 976,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074617,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5885,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Farmsen',
    EN_SCHULE_STRASSE_NR: 'Swebenhöhe 50',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 771,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074703,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5886,
    EN_SCHULE_BEZEICHNUNG: 'Gelehrtenschule des Johanneums',
    EN_SCHULE_STRASSE_NR: 'Maria-Louisen-Straße 114',
    EN_SCHULE_PLZ: '22301',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 920,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074642,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5887,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Hochrad',
    EN_SCHULE_STRASSE_NR: 'Hochrad 2',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 1046,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074589,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5888,
    EN_SCHULE_BEZEICHNUNG: 'Heilwig-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Wilhelm-Metzger-Straße 4',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 12,
    EN_SCHULE_SCHUELER_LFD_SJ: 780,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074646,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5890,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Meiendorf',
    EN_SCHULE_STRASSE_NR: 'Schierenberg 60',
    EN_SCHULE_PLZ: '22145',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 19,
    EN_SCHULE_SCHUELER_LFD_SJ: 923,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074746,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5891,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Othmarschen',
    EN_SCHULE_STRASSE_NR: 'Walderseestraße 99',
    EN_SCHULE_PLZ: '22605',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 7,
    EN_SCHULE_SCHUELER_LFD_SJ: 962,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074590,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5894,
    EN_SCHULE_BEZEICHNUNG: 'Heinrich-Heine-Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Harksheider Straße 70',
    EN_SCHULE_PLZ: '22399',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 870,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074720,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5895,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Grootmoor',
    EN_SCHULE_STRASSE_NR: 'Am Damm 47',
    EN_SCHULE_PLZ: '22175',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 1188,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074704,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5896,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Ohlstedt',
    EN_SCHULE_STRASSE_NR: 'Sthamerstraße 55',
    EN_SCHULE_PLZ: '22397',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 18,
    EN_SCHULE_SCHUELER_LFD_SJ: 674,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074730,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5897,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Osterbek',
    EN_SCHULE_STRASSE_NR: 'Turnierstieg 24',
    EN_SCHULE_PLZ: '22179',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 16,
    EN_SCHULE_SCHUELER_LFD_SJ: 588,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074705,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5898,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Marienthal',
    EN_SCHULE_STRASSE_NR: 'Holstenhofweg 86',
    EN_SCHULE_PLZ: '22043',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 15,
    EN_SCHULE_SCHUELER_LFD_SJ: 915,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074684,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5899,
    EN_SCHULE_BEZEICHNUNG: 'Gymnasium Hummelsbüttel',
    EN_SCHULE_STRASSE_NR: 'Hummelsbüttler Hauptstraße 107',
    EN_SCHULE_PLZ: '22339',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 17,
    EN_SCHULE_SCHUELER_LFD_SJ: 720,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 2074718,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5901,
    EN_SCHULE_BEZEICHNUNG: 'BS 04',
    EN_SCHULE_STRASSE_NR: 'Angerstraße 7-11',
    EN_SCHULE_PLZ: '22087',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1244,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074803,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5902,
    EN_SCHULE_BEZEICHNUNG: 'BS 13',
    EN_SCHULE_STRASSE_NR: 'Dratelnstraße 24',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1680,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074804,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5905,
    EN_SCHULE_BEZEICHNUNG: 'G  5',
    EN_SCHULE_STRASSE_NR: 'Steinhauerdamm 4',
    EN_SCHULE_PLZ: '22087',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074806,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5906,
    EN_SCHULE_BEZEICHNUNG: 'BS 25',
    EN_SCHULE_STRASSE_NR: 'Richardstr. 1',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1232,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074807,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5907,
    EN_SCHULE_BEZEICHNUNG: 'BS 27',
    EN_SCHULE_STRASSE_NR: 'Wendenstraße 268',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1619,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074808,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5908,
    EN_SCHULE_BEZEICHNUNG: 'G  8',
    EN_SCHULE_STRASSE_NR: 'Sorbenstraße 15  ',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074809,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5909,
    EN_SCHULE_BEZEICHNUNG: 'BS 16',
    EN_SCHULE_STRASSE_NR: 'Ebelingplatz 9',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2023,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074810,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5910,
    EN_SCHULE_BEZEICHNUNG: 'BS 22',
    EN_SCHULE_STRASSE_NR: 'Museumstraße 19  ',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1800,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074811,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5911,
    EN_SCHULE_BEZEICHNUNG: 'BS 03',
    EN_SCHULE_STRASSE_NR: 'Angerstraße 4',
    EN_SCHULE_PLZ: '22087',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2315,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074812,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5912,
    EN_SCHULE_BEZEICHNUNG: 'BS 24',
    EN_SCHULE_STRASSE_NR: 'Reichsbahnstraße 53',
    EN_SCHULE_PLZ: '22525',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 766,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074813,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5913,
    EN_SCHULE_BEZEICHNUNG: 'BS 06',
    EN_SCHULE_STRASSE_NR: 'Billwerder Billdeich 614',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1259,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074814,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5915,
    EN_SCHULE_BEZEICHNUNG: 'BS 10',
    EN_SCHULE_STRASSE_NR: 'Brekelbaums Park 10  ',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1496,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074815,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5916,
    EN_SCHULE_BEZEICHNUNG: 'BS 19',
    EN_SCHULE_STRASSE_NR: 'Hermelinweg 8  ',
    EN_SCHULE_PLZ: '22159',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1777,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074816,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5917,
    EN_SCHULE_BEZEICHNUNG: 'G 17',
    EN_SCHULE_STRASSE_NR: 'Dratelnstraße 24',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074817,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5918,
    EN_SCHULE_BEZEICHNUNG: 'BS 14',
    EN_SCHULE_STRASSE_NR: 'Dratelnstraße 26',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2258,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074818,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5919,
    EN_SCHULE_BEZEICHNUNG: 'BS 08',
    EN_SCHULE_STRASSE_NR: 'Billwerder Billdeich 622',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1370,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074819,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5921,
    EN_SCHULE_BEZEICHNUNG: 'BS 01',
    EN_SCHULE_STRASSE_NR: 'Anckelmannstraße 10',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2088,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074821,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5922,
    EN_SCHULE_BEZEICHNUNG: 'BS 31',
    EN_SCHULE_STRASSE_NR: 'Wallstraße 2',
    EN_SCHULE_PLZ: '22087',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 751,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074822,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5925,
    EN_SCHULE_BEZEICHNUNG: 'H  5',
    EN_SCHULE_STRASSE_NR: 'Telemannstraße 10',
    EN_SCHULE_PLZ: '20255',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074824,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5926,
    EN_SCHULE_BEZEICHNUNG: 'BS 02',
    EN_SCHULE_STRASSE_NR: 'Anckelmannstraße 10',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1387,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074825,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5927,
    EN_SCHULE_BEZEICHNUNG: 'BS 28',
    EN_SCHULE_STRASSE_NR: 'Tessenowweg 3',
    EN_SCHULE_PLZ: '22297',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2305,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074826,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5928,
    EN_SCHULE_BEZEICHNUNG: 'BS 17',
    EN_SCHULE_STRASSE_NR: 'Eulenkamp 46',
    EN_SCHULE_PLZ: '22049',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1439,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074827,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5929,
    EN_SCHULE_BEZEICHNUNG: 'BS 32',
    EN_SCHULE_STRASSE_NR: 'Wendenstraße 166',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1164,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074828,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5930,
    EN_SCHULE_BEZEICHNUNG: 'BS 18',
    EN_SCHULE_STRASSE_NR: 'Göhlbachtal 38',
    EN_SCHULE_PLZ: '21073',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1626,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074829,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5931,
    EN_SCHULE_BEZEICHNUNG: 'H 11',
    EN_SCHULE_STRASSE_NR: 'Uferstraße 9',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074830,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5932,
    EN_SCHULE_BEZEICHNUNG: 'BS 26',
    EN_SCHULE_STRASSE_NR: 'Schlankreye 1',
    EN_SCHULE_PLZ: '20144',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1971,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074823,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5933,
    EN_SCHULE_BEZEICHNUNG: 'H 13',
    EN_SCHULE_STRASSE_NR: 'Kellinghusenstraße 11',
    EN_SCHULE_PLZ: '20249',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074832,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5934,
    EN_SCHULE_BEZEICHNUNG: 'BS 20',
    EN_SCHULE_STRASSE_NR: 'Holstenwall 14',
    EN_SCHULE_PLZ: '20355',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1345,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074833,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5935,
    EN_SCHULE_BEZEICHNUNG: 'H 15',
    EN_SCHULE_STRASSE_NR: 'Mittelweg 42a',
    EN_SCHULE_PLZ: '20148',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074834,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5936,
    EN_SCHULE_BEZEICHNUNG: 'BS 11',
    EN_SCHULE_STRASSE_NR: 'Budapester Straße 58',
    EN_SCHULE_PLZ: '20359',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1929,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074835,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5938,
    EN_SCHULE_BEZEICHNUNG: 'BS 05',
    EN_SCHULE_STRASSE_NR: 'Bei der Hauptfeuerwache 1',
    EN_SCHULE_PLZ: '20099',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 819,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074837,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5939,
    EN_SCHULE_BEZEICHNUNG: 'H 19',
    EN_SCHULE_STRASSE_NR: 'Eckernförder Straße 70',
    EN_SCHULE_PLZ: '22769',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074838,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5940,
    EN_SCHULE_BEZEICHNUNG: 'H 20',
    EN_SCHULE_STRASSE_NR: 'Gropiusring 43',
    EN_SCHULE_PLZ: '22309',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074839,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5941,
    EN_SCHULE_BEZEICHNUNG: 'BS 18 - HIBB',
    EN_SCHULE_STRASSE_NR: 'Alter Postweg 30-38',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 6946744,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5951,
    EN_SCHULE_BEZEICHNUNG: 'W  1',
    EN_SCHULE_STRASSE_NR: 'Isestraße 144',
    EN_SCHULE_PLZ: '20149',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074840,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5952,
    EN_SCHULE_BEZEICHNUNG: 'BS 29',
    EN_SCHULE_STRASSE_NR: 'Uferstraße 9-10',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 635,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074841,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5953,
    EN_SCHULE_BEZEICHNUNG: 'BS 23',
    EN_SCHULE_STRASSE_NR: 'Niendorfer Marktplatz 7a',
    EN_SCHULE_PLZ: '22459',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1259,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074842,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5954,
    EN_SCHULE_BEZEICHNUNG: 'BS 15',
    EN_SCHULE_STRASSE_NR: 'Dratelnstraße 28',
    EN_SCHULE_PLZ: '21109',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 2260,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074843,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5956,
    EN_SCHULE_BEZEICHNUNG: 'W  5',
    EN_SCHULE_STRASSE_NR: 'Alter Postweg 38',
    EN_SCHULE_PLZ: '21075',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074844,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5959,
    EN_SCHULE_BEZEICHNUNG: 'BS 12',
    EN_SCHULE_STRASSE_NR: 'Burgstraße 33-35',
    EN_SCHULE_PLZ: '20535',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1942,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074845,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5962,
    EN_SCHULE_BEZEICHNUNG: 'BS 07',
    EN_SCHULE_STRASSE_NR: 'Billwerder Billdeich 620',
    EN_SCHULE_PLZ: '21033',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1066,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074820,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5964,
    EN_SCHULE_BEZEICHNUNG: 'BS 21',
    EN_SCHULE_STRASSE_NR: 'Max-Brauer-Allee 134',
    EN_SCHULE_PLZ: '22765',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1681,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074802,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5967,
    EN_SCHULE_BEZEICHNUNG: 'BS 03 - vorher BS 09',
    EN_SCHULE_STRASSE_NR: 'Brekelbaums Park 6',
    EN_SCHULE_PLZ: '20537',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074805,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5968,
    EN_SCHULE_BEZEICHNUNG: 'BS 30',
    EN_SCHULE_STRASSE_NR: 'Wagnerstraße 60',
    EN_SCHULE_PLZ: '22081',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1432,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HEOS',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074801,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5973,
    EN_SCHULE_BEZEICHNUNG: 'H 17',
    EN_SCHULE_STRASSE_NR: 'Wentorfer Straße 13',
    EN_SCHULE_PLZ: '21029',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - HIBB',
    EN_SCHULE_ART: 'B',
    EN_SCHULE_FORM: 'BS',
    EN_EN_OBJ_ID_PKT: 2074836,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478698,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478701,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 5976,
    EN_SCHULE_BEZEICHNUNG: 'Staatliche Jugendmusikschule-Mittelweg 42',
    EN_SCHULE_STRASSE_NR: 'Mittelweg 42',
    EN_SCHULE_PLZ: '20148',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 5900,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'KS',
    EN_EN_OBJ_ID_PKT: 5254746,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478700,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 7707,
    EN_SCHULE_BEZEICHNUNG: 'Raphael-Schule',
    EN_SCHULE_STRASSE_NR: 'Quellental 21',
    EN_SCHULE_PLZ: '22609',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'SfL',
    EN_EN_OBJ_ID_PKT: 2076315,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478710,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 7915,
    EN_SCHULE_BEZEICHNUNG: 'Alsterring Gymnasium',
    EN_SCHULE_STRASSE_NR: 'Wittenkamp 15',
    EN_SCHULE_PLZ: '22307',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: 4,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 5455058,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 8202,
    EN_SCHULE_BEZEICHNUNG: 'Wichern-Schule',
    EN_SCHULE_STRASSE_NR: 'Horner Weg 164',
    EN_SCHULE_PLZ: '22111',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: 3120,
    EN_SCHULE_EINRICHTUNGSART: 'Schule - BSB',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: 'Gy',
    EN_EN_OBJ_ID_PKT: 5218686,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: 5478705,
  },
  {
    GO_TYP: 'EN',
    EN_SCHULE_NR_PKF: 9000,
    EN_SCHULE_BEZEICHNUNG: 'Hochschule (Universität Hamburg)',
    EN_SCHULE_STRASSE_NR: 'Mittelweg 177',
    EN_SCHULE_PLZ: '20148',
    EN_SCHULE_ORT: 'Hamburg',
    EN_SCHULE_SEPL_REGION: null,
    EN_SCHULE_SCHUELER_LFD_SJ: 1,
    EN_SCHULE_BSB_KAPITEL: null,
    EN_SCHULE_EINRICHTUNGSART: 'Hochschule',
    EN_SCHULE_ART: 'A',
    EN_SCHULE_FORM: null,
    EN_EN_OBJ_ID_PKT: 5418904,
    EN_SCHULE_ART_OBJ_ID_FKT: 5478697,
    EN_SCHULE_FORM_OBJ_ID_FKT: null,
  },
];

export const getSchuleByID = (id: string) => {
  return C_V_STM_EN.find((value) => value.EN_SCHULE_NR_PKF === +id);
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProblemMeldungConverter } from '@core/converters/problem-meldung-converter.service';
import { ProblemMeldungModel } from '@core/models/problem-meldung-model';
import { KommunikationService, ResponseObject } from '@core/portal-api';

@Injectable()
export class ProblemMeldungService {
  constructor(private kommunikationService: KommunikationService, private converter: ProblemMeldungConverter) {}

  saveProblemMeldung(problemMeldungModel: ProblemMeldungModel): Observable<ResponseObject> {
    const problemMeldung = this.converter.convertToApiModel(problemMeldungModel);

    return this.kommunikationService.postProblemMeldung(problemMeldung);
  }
}

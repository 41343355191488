import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import {
  DatatableConfig,
  GEBAEUDE_TABLE_CONFIG_ID,
  INVESTITIONEN_TABLE_CONFIG_ID,
  PROJEKTE_TABLE_CONFIG_ID,
} from '@app/core/models/datatable-config.model';
import { NotificationType } from '@app/core/models/notification.model';
import { initialTablesvalues } from '../reducers/user-config.reducer';
import { UserConfig } from '@app/core/services/user-config.service';

import * as fromNotificationActions from '@app/core/store/actions/notifications.actions';
import * as fromUserSelectors from '@core/store/selectors/user-profile.selectors';
import * as fromSelectors from '@core/store/selectors/user-config.selectors';
import * as fromActions from '@app/core/store/actions/user-config.actions';
import * as fromState from '@core/store/reducers';

@Injectable()
export class UserConfigEffects {
  constructor(
    private actions$: Actions,
    private configService: UserConfig,
    private store: Store<fromState.CoreModuleState>
  ) {}

  loadUserConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUserConfig),
      withLatestFrom(this.store.select(fromUserSelectors.getUser), (_, user) => user),
      switchMap((user) =>
        this.configService.getUserConfig(user.emailAddress).pipe(
          map((config) => fromActions.loadUserConfigSuccess({ config })),
          catchError((error) => of(fromActions.loadUserConfigFail({ error })))
        )
      )
    )
  );

  saveInvestitionTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveInvestitionTableConfig),
      withLatestFrom(
        this.store.select(fromSelectors.getInvestitionTableConfigMin),
        this.store.select(fromUserSelectors.getUserEmail),
        (_, config, email) => ({ config, email })
      ),
      map(({ config, email }) => {
        const result: DatatableConfig = {
          datatableID: INVESTITIONEN_TABLE_CONFIG_ID,
          configValue: config,
          userID: email,
          configID: config.configID,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.saveInvestitionTableConfigSuccess()),
          catchError((error) => of(fromActions.saveInvestitionTableConfigFail({ error })))
        )
      )
    )
  );

  saveGebaeudeTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveGebaeudeTableConfig),
      withLatestFrom(
        this.store.select(fromSelectors.getGebaeudeTableConfigMin),
        this.store.select(fromUserSelectors.getUserEmail),
        (_, config, email) => ({ config, email })
      ),
      map(({ config, email }) => {
        const result: DatatableConfig = {
          datatableID: GEBAEUDE_TABLE_CONFIG_ID,
          configValue: config,
          userID: email,
          configID: config.configID,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.saveInvestitionTableConfigSuccess()),
          catchError((error) => of(fromActions.saveInvestitionTableConfigFail({ error })))
        )
      )
    )
  );

  saveProjekteTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveProjekteTableConfig),
      withLatestFrom(
        this.store.select(fromSelectors.getProjekteTableConfigMin),
        this.store.select(fromUserSelectors.getUserEmail),
        (_, config, email) => ({ config, email })
      ),
      map(({ config, email }) => {
        const result: DatatableConfig = {
          datatableID: PROJEKTE_TABLE_CONFIG_ID,
          configValue: config,
          userID: email,
          configID: config.configID,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.saveProjekteTableConfigSuccess()),
          catchError((error) => of(fromActions.saveInvestitionTableConfigFail({ error })))
        )
      )
    )
  );

  saveUserConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.saveInvestitionTableConfigSuccess,
        fromActions.saveGebaeudeTableConfigSuccess,
        fromActions.saveProjekteTableConfigSuccess
      ),
      map(() =>
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Spaltenkonfiguration erfolgreich gespeichert', type: NotificationType.SUCCESS },
        })
      )
    )
  );

  saveUserConfigFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.saveInvestitionTableConfigFail,
        fromActions.saveGebaeudeTableConfigFail,
        fromActions.saveProjekteTableConfigFail
      ),
      map(() =>
        fromNotificationActions.addNotification({
          not: {
            id: '1',
            content: 'Spaltenkonfiguration konnten nicht gespeichert werden',
            type: NotificationType.ERROR,
          },
        })
      )
    )
  );

  resetInvestitionTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetInvestitionTableConfig),
      withLatestFrom(
        this.store.select(fromUserSelectors.getUserEmail),
        this.store.select(fromSelectors.getInvestitionTableConfig),
        (_, email, config) => ({ email, config })
      ),
      map(({ email, config }) => {
        const result: DatatableConfig = {
          configID: config?.configID,
          datatableID: INVESTITIONEN_TABLE_CONFIG_ID,
          configValue: initialTablesvalues.investition,
          userID: email,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.resetInvestitionTableConfigSuccess()),
          catchError((error) => of(fromActions.resetInvestitionTableConfigFail({ error })))
        )
      )
    )
  );

  resetGebaeudeTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetGebaeudeTableConfig),
      withLatestFrom(
        this.store.select(fromUserSelectors.getUserEmail),
        this.store.select(fromSelectors.getGebaeudeTableConfig),
        (_, email, config) => ({ email, config })
      ),
      map(({ email, config }) => {
        const result: DatatableConfig = {
          configID: config?.configID,
          datatableID: GEBAEUDE_TABLE_CONFIG_ID,
          configValue: initialTablesvalues.gebaeude,
          userID: email,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.resetGebaeudeTableConfigSuccess()),
          catchError((error) => of(fromActions.resetGebaeudeTableConfigFail({ error })))
        )
      )
    )
  );

  resetProjekteTableConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetProjekteTableConfig),
      withLatestFrom(
        this.store.select(fromUserSelectors.getUserEmail),
        this.store.select(fromSelectors.getProjekteTableConfig),
        (_, email, config) => ({ email, config })
      ),
      map(({ email, config }) => {
        const result: DatatableConfig = {
          configID: config?.configID,
          datatableID: PROJEKTE_TABLE_CONFIG_ID,
          configValue: initialTablesvalues.investition,
          userID: email,
        };
        return result;
      }),
      switchMap((config: DatatableConfig) =>
        this.configService.saveUserConfig(config).pipe(
          map(() => fromActions.resetProjekteTableConfigSuccess()),
          catchError((error) => of(fromActions.resetProjekteTableConfigFail({ error })))
        )
      )
    )
  );
}

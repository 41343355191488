import { Injectable } from '@angular/core';
import { encode } from 'js-base64';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnwenderDataConverter } from '@core/converters/anwender-data-converter.service';
import { AnwenderRolleConverter } from '@core/converters/anwender-rolle-converter.service';
import { AnwenderDataModel } from '@core/models/anwender-data.model';
import { Anwender, AnwenderService } from '@core/portal-api';

@Injectable()
export class AnwenderDataService {
  constructor(
    private anwenderService: AnwenderService,
    private anwenderDataConverterService: AnwenderDataConverter,
    private anwenderRolleConverterService: AnwenderRolleConverter
  ) {}

  getCurrentAnwenderData(userID: string): Observable<AnwenderDataModel> {
    return this.anwenderService.getAnwender(encode(userID.toLowerCase())).pipe(
      map((res) => {
        const anwenderData = this.anwenderDataConverterService.convertFromApiModel(res.data);
        if (res.data.rollen) {
          res.data.rollen.forEach((rolle) =>
            anwenderData.rollen.push(this.anwenderRolleConverterService.convertFromApiModel(rolle))
          );
        }
        return anwenderData;
      })
    );
  }

  getAnwenderList(
    ebeneID: string,
    rolleID: string,
    regionID: string,
    weID: string,
    modulID: string
  ): Observable<AnwenderDataModel[]> {
    let refObjID = null;
    if (regionID) {
      if (weID) {
        refObjID = weID;
      } else {
        refObjID = regionID;
      }
    }
    if (!rolleID) {
      rolleID = null;
    }
    if (!ebeneID) {
      ebeneID = null;
    }
    if (!modulID) {
      modulID = null;
    }
    return this.anwenderService.getAnwenderList(ebeneID, rolleID, refObjID, modulID).pipe(
      map((res) => {
        const anwenderListData = res.data.map((item: Anwender) =>
          this.anwenderDataConverterService.convertFromApiModel(item)
        );
        return anwenderListData;
      })
    );
  }

  updateAnwender(anwender: AnwenderDataModel) {
    const anwenderDTO = this.anwenderDataConverterService.convertToApiModel(anwender);
    if (anwender.rollen) {
      anwender.rollen.forEach((rolle) =>
        anwenderDTO.rollen.push(this.anwenderRolleConverterService.convertToApiModel(rolle))
      );
    }
    return this.anwenderService.putAnwender(encode(anwender.email.toLowerCase()), anwenderDTO);
  }

  createAnwender(anwender: AnwenderDataModel) {
    const anwenderDTO = this.anwenderDataConverterService.convertToApiModel(anwender);
    if (anwender.rollen) {
      anwender.rollen.forEach((rolle) =>
        anwenderDTO.rollen.push(this.anwenderRolleConverterService.convertToApiModel(rolle))
      );
    }
    return this.anwenderService.postAnwender(anwenderDTO);
  }
}

<mat-toolbar>
  <nav class="navbar navbar-expand-lg">
    <div class="corner"></div>
    <div class="navbar-brand mr-5 pl-2">
      <img src="assets/img/logo-smart-workplace.svg" alt="Zur Einkaufswagenübersicht" width="180px" />
    </div>

    <button
      mat-button
      class="navbar-toggler"
      data-toggle="collapse"
      data-target="#navbar"
      aria-controls="navbar"
      aria-expanded="false"
      aria-label="Menü zeigen"
      (click)="toggleMenu = !toggleMenu; sidenav.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <div class="collapse navbar-collapse h-100" id="navbar">
      <ul class="navbar-nav mr-auto h-100">
        <li class="nav-item cursor-pointer h-100" [routerLinkActive]="['active', 'active-route']">
          <a class="nav-link" [routerLink]="['start']">Startseite</a>
        </li>
        <li class="nav-item cursor-pointer h-100" [routerLinkActive]="['active', 'active-route']">
          <a class="nav-link" [routerLink]="['admin']" *ngIf="(berechtigungModul$ | async).hasADM">Administration</a>
        </li>
        <li
          class="nav-item cursor-pointer h-100"
          [routerLinkActive]="['active', 'active-route']"
          [ngClass]="{ active: order.isActive || projectAssignment.isActive || projectSuggestion.isActive }"
        >
          <a class="nav-link" [routerLink]="['rahmenplan']" *ngIf="(berechtigungModul$ | async).hasRPL"
            >Rahmenplan 2.0</a
          >

          <!-- TODO Remove after changing project structure-->
          <a routerLink="/projekt-zuordnen" routerLinkActive #projectAssignment="routerLinkActive" class="d-none"></a>
          <a routerLink="/projekt-anlegen" routerLinkActive #projectSuggestion="routerLinkActive" class="d-none"></a>
          <a routerLink="/investition" routerLinkActive #order="routerLinkActive" class="d-none"></a>
        </li>
        <li class="nav-item cursor-pointer h-100" [routerLinkActive]="['active', 'active-route']">
          <a class="nav-link" [routerLink]="['gebaeude']" *ngIf="(berechtigungModul$ | async).hasGBD">Gebäude</a>
        </li>
        <li class="nav-item cursor-pointer h-100" [routerLinkActive]="['active', 'active-route']">
          <a class="nav-link" [routerLink]="['handbuch']" *ngIf="(berechtigungModul$ | async).hasOGH"
            >Organisationshandbuch</a
          >
        </li>
        <li class="nav-item cursor-pointer h-100" [routerLinkActive]="['active', 'active-route']">
          <a class="nav-link" [routerLink]="['prozesse']" *ngIf="(berechtigungModul$ | async).hasPRZ">Prozesse</a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <button mat-button [matMenuTriggerFor]="menu" class="text-transformation-none">
            {{ (userProfile$ | async)?.firstName }} {{ (userProfile$ | async)?.lastName
            }}<mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <a [routerLink]="['administration']" mat-menu-item>Administration</a>-->
            <button mat-menu-item type="button" (click)="onOpenChatbot()">Chatbot Starten</button>
            <button mat-menu-item type="button" (click)="onLogout()">Abmelden</button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </nav>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <a mat-button [routerLink]="['start']">Startseite</a>
    <a mat-button [routerLink]="['admin']" *ngIf="(berechtigungModul$ | async).hasADM">Administration</a>
    <a mat-button [routerLink]="['rahmenplan']" *ngIf="(berechtigungModul$ | async).hasRPL">Rahmenplan 2.0</a>
    <a mat-button [routerLink]="['gebaeude']" *ngIf="(berechtigungModul$ | async).hasGBD">Gebäude</a>
    <a mat-button [routerLink]="['handbuch']" *ngIf="(berechtigungModul$ | async).hasOGH">Organisationshandbuch</a>
    <a mat-button [routerLink]="['prozesse']" *ngIf="(berechtigungModul$ | async).hasPRZ">Prozesse</a>

    <button mat-button [matMenuTriggerFor]="menu" class="text-transformation-none navigation-dropdown">
      {{ (userProfile$ | async)?.firstName }} {{ (userProfile$ | async)?.lastName }}<mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <a [routerLink]="['administration']" mat-menu-item>Administration</a>-->
      <button mat-menu-item type="button" (click)="onOpenChatbot()">Chatbot Starten</button>
      <button mat-menu-item type="button" (click)="onLogout()">Abmelden</button>
    </mat-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="d-flex justify-content-center align-items-center h-100 w-100" *ngIf="isLoading; else showContent">
      <mat-spinner></mat-spinner>
    </div>
    <ng-template #showContent>
      <router-outlet></router-outlet>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>

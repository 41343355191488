import { Injectable } from '@angular/core';
import { BerechtigungModulBatchModel } from '../models/berechtigung-modul-batch.model';
import { Converter } from '../models/converter';
import { BerechtigungModul } from '../portal-api';

@Injectable()
export class BerechtigungModulConverter implements Converter<BerechtigungModulBatchModel, BerechtigungModul> {
  convertToApiModel(item: BerechtigungModulBatchModel): BerechtigungModul {
    if (item === null || item === undefined) {
      return undefined;
    }
    const berechtigungModul: BerechtigungModul = {
      modulID: item.modulID,
      emailList: item.emailList,
      removeEmailList: item.removeEmailList,
    };
    return berechtigungModul;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from '@core/store/actions/problem-meldung.actions';
import { ProblemMeldungService } from '@core/services/problem-meldung.service';
import { ProblemMeldungModel } from '@core/models/problem-meldung-model';
import * as fromNotificationActions from '@core/store/actions/notifications.actions';
import { NotificationType } from '@core/models/notification.model';

@Injectable()
export class ProblemMeldungEffects {
  constructor(private actions$: Actions, private problemMeldungService: ProblemMeldungService) {}

  saveProblemMeldung$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveProblemMeldung),
      map((action) => action.problemMeldung),
      switchMap((problemMeldung: ProblemMeldungModel) =>
        this.problemMeldungService.saveProblemMeldung(problemMeldung).pipe(
          map(() => fromActions.saveProblemMeldungSuccess()),
          catchError((error) => of(fromActions.saveProblemMeldungFailure({ error })))
        )
      )
    )
  );

  saveProblemMeldungSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveProblemMeldungSuccess),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Problemmeldung wurde erfolgreich angelegt', type: NotificationType.SUCCESS },
        }),
      ])
    )
  );

  saveProblemMeldungFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.saveProblemMeldungFailure),
      switchMap(() => [
        fromNotificationActions.addNotification({
          not: { id: '1', content: 'Problemmeldung konnte nicht angelegt werden', type: NotificationType.ERROR },
        }),
      ])
    )
  );
}

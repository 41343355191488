import { createSelector } from '@ngrx/store';

import * as coreState from '@core/store/reducers';
import { BerechtigungModulModel } from '@core/models/berechtigung-modul.model';

export const selectAnwenderData = createSelector(
  coreState.getCoreModuleState,
  (state: coreState.CoreModuleState) => state.anwenderData
);

export const selectBerechtigungModul = createSelector(selectAnwenderData, (anwenderData) => {
  const berechtigungModul: BerechtigungModulModel = {
    hasRPL: anwenderData.module.includes('RPL'),
    hasGBD: anwenderData.module.includes('GBD'),
    hasADM: anwenderData.module.includes('ADM'),
    hasOGH: anwenderData.module.includes('OGH'),
    hasPRZ: anwenderData.module.includes('PRZ'),
  };

  return berechtigungModul;
});

import { Injectable } from '@angular/core';
import { encode } from 'js-base64';
import { Converter } from '@core/models/converter';
import { ProblemMeldungModel } from '@core/models/problem-meldung-model';
import { ProblemMeldung } from '@core/portal-api';

@Injectable()
export class ProblemMeldungConverter implements Converter<ProblemMeldungModel, ProblemMeldung> {
  convertFromApiModel(problemMeldung: ProblemMeldung): ProblemMeldungModel {
    if (problemMeldung === null || problemMeldung === undefined) {
      return undefined;
    }

    const problemMeldungModel: ProblemMeldungModel = {
      problemLevel: problemMeldung.problemLevel,
      modulID: problemMeldung.modulID,
      meldungKontext: problemMeldung.meldungKontext,
      kontextObjektID: problemMeldung.kontextObjektID,
      meldungTitel: problemMeldung.meldungTitel,
      meldungText: problemMeldung.meldungText,
      meldungLink: problemMeldung.meldungLink,
    };

    return problemMeldungModel;
  }

  convertToApiModel(problemMeldungModel: ProblemMeldungModel): ProblemMeldung {
    if (problemMeldungModel === null || problemMeldungModel === undefined) {
      return undefined;
    }

    const problemMeldung: ProblemMeldung = {
      problemLevel: problemMeldungModel.problemLevel,
      modulID: problemMeldungModel.modulID,
      meldungKontext: problemMeldungModel.meldungKontext,
      kontextObjektID: problemMeldungModel.kontextObjektID,
      meldungTitel: encode(problemMeldungModel.meldungTitel),
      meldungText: encode(problemMeldungModel.meldungText),
      meldungLink: encode(problemMeldungModel.meldungLink),
    };

    return problemMeldung;
  }
}

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRahmenplanDetails from './rahmenplan-details.reducer';
import * as fromInvestition from './investition.reducer';
import * as fromChoiceLists from './rahmenplan.reducer';
export interface RahmenplanModuleState {
  rahmenplanDetails: fromRahmenplanDetails.RahmenplanDetailsState;
  investition: fromInvestition.InvestitionState;
  rahmenplan: fromChoiceLists.RahmenplanState;
}

export const rahmenplanModuleReducers: ActionReducerMap<RahmenplanModuleState> = {
  rahmenplanDetails: fromRahmenplanDetails.reducer,
  investition: fromInvestition.reducer,
  rahmenplan: fromChoiceLists.reducer,
};

export const getRahmenplanModuleState = createFeatureSelector<RahmenplanModuleState>('Rahmenplan');

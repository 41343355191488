import { PipeTransform } from '@angular/core';

export interface TableColumn {
  name?: string;
  prop: string;
  width?: number;
  showColumn?: boolean;
  type?: ColumnType;
  order?: number;
  cellClass?: string;
  hasFilter?: boolean;
  hasPipe?: boolean;
  hasComparator?: boolean;
  pipe?: PipeTransform;
  comparator?: (A, B, rowA, rowB, direction) => 1 | 0 | -1;
  hasSlider?: boolean;
  summaryFunc?: any;
}

export type ColumnType = 'NUMBER' | 'STRING';
export const ColumnType = {
  NUMBER: 'NUMBER' as ColumnType,
  STRING: 'STRING' as ColumnType,
  DATE: 'DATE' as ColumnType,
};

import { createAction, props } from '@ngrx/store';
import { ProblemMeldungModel } from '@core/models/problem-meldung-model';

export const saveProblemMeldung = createAction(
  '[Core/ProblemMeldung] Save problem meldung',
  props<{ problemMeldung: ProblemMeldungModel }>()
);

export const saveProblemMeldungSuccess = createAction('[Core/ProblemMeldung] Save problem meldung success');

export const saveProblemMeldungFailure = createAction(
  '[Core/ProblemMeldung] Save problem meldung failure',
  props<{ error: unknown }>()
);

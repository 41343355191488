import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationType } from '@app/core/models/notification.model';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import * as fromActions from '@app/core/store/actions/notifications.actions';

@Injectable()
export class NotificationsEffects {
  NOTIFICATION_TIMEOUT = 5000;

  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {}

  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNotification),
        map((action) => action.not),
        tap((not) => {
          const config: MatSnackBarConfig = {
            panelClass: not.type === NotificationType.ERROR ? 'notification-error' : 'notification-success',
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: this.NOTIFICATION_TIMEOUT,
          };
          this.matSnackBar.open(not.content, 'X', config);
        })
      ),
    { dispatch: false }
  );
}

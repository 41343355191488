import { Injectable } from '@angular/core';
import { UserProfileService } from '@core/services/user-profile.service';
import { AppConfig, ConfigurationService } from '@core/config/configuration.service';

export function roleInitializer(configService: ConfigurationService, roleService: RoleService): () => Promise<unknown> {
  return (): Promise<unknown> => {
    return configService.loadAppConfig().then(
      () =>
        new Promise(async (resolve, reject) => {
          try {
            roleService.appConfig = configService.getAppConfig();
            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
    );
  };
}

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  appConfig: AppConfig;

  constructor(private userProfile: UserProfileService, private configService: ConfigurationService) {}

  canRead(): boolean {
    let ret;
    if (this.appConfig.roleEnabled) {
      if (
        this.appConfig.readRoleName.filter((roleName) => {
          if (this.userProfile.getCurrentUsersProfile().userRoles.includes(roleName)) {
            return roleName;
          }
        }).length > 0
      ) {
        ret = true;
      } else {
        ret = false;
      }
    } else {
      ret = true;
    }
    return ret;
  }

  canWrite(): boolean {
    let ret;
    if (this.appConfig.roleEnabled) {
      if (
        this.appConfig.writeRoleName.filter((roleName) => {
          if (this.userProfile.getCurrentUsersProfile().userRoles.includes(roleName)) {
            return roleName;
          }
        }).length > 0
      ) {
        ret = true;
      } else {
        ret = false;
      }
    } else {
      ret = true;
    }
    return ret;
  }

  canApprove(): boolean {
    let ret;
    if (this.appConfig.roleEnabled) {
      if (
        this.appConfig.approveRoleName.filter((roleName) => {
          if (this.userProfile.getCurrentUsersProfile().userRoles.includes(roleName)) {
            return roleName;
          }
        }).length > 0
      ) {
        ret = true;
      } else {
        ret = false;
      }
    } else {
      ret = true;
    }
    return ret;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { RechtematrixService } from '@core/services/rechtematrix.service';

import * as fromActions from '@app/core/store/actions/rechtematrix.actions';
import * as fromCoreActions from '@core/store/actions/rechtematrix.actions';
import * as fromAnwenderDataSelectors from '@core/store/selectors/anwender-data.selectors';
import * as fromRechtematrixSelectors from '@core/store/selectors/rechtematrix.selectors';
import * as fromUserProfileSelectors from '@core/store/selectors/user-profile.selectors';
import { Store } from '@ngrx/store';
import * as coreState from '@core/store/reducers';

@Injectable()
export class RechtematrixEffects {
  constructor(
    private actions$: Actions,
    private coreStore: Store<coreState.CoreModuleState>,
    private rechtematrixService: RechtematrixService
  ) {}

  loadRechtematrix$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRechtematrix),
      switchMap(() =>
        this.rechtematrixService.getRechtematrix().pipe(
          switchMap((rechtematrix) => [
            fromActions.loadRechtematrixSuccess({ rechtematrix }),
            fromActions.updateRechtematrix(),
          ]),
          catchError((error) => of(fromActions.loadRechtematrixFailure({ error })))
        )
      )
    )
  );

  updateRechtematrix$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCoreActions.updateRechtematrix),
      withLatestFrom(
        this.coreStore.select(fromAnwenderDataSelectors.selectAnwenderData),
        this.coreStore.select(fromRechtematrixSelectors.selectRechtematrixEntries),
        this.coreStore.select(fromUserProfileSelectors.getUser)
      ),
      switchMap(([, anwenderData, rechteMatrixEntries, userProfile]) =>
        this.rechtematrixService.updateRechtematrixPermittance(rechteMatrixEntries, anwenderData, userProfile).pipe(
          map((rechtematrix) => fromCoreActions.updateRechtematrixSuccess({ rechtematrix })),
          catchError((error) => of(fromCoreActions.updateRechtematrixFailure({ error })))
        )
      )
    )
  );
}

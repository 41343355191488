import { Injectable } from '@angular/core';
import { Converter } from '@core/models/converter';
import { TaskModel } from '@core/models/task.model';
import { TaskGenericObject } from '@core/portal-api';

@Injectable()
export class TasksConverter implements Converter<TaskModel, TaskGenericObject> {
  convertFromApiModel(task: TaskGenericObject): TaskModel {
    if (task === null || task === undefined) {
      return undefined;
    }

    const taskModel: TaskModel = {
      taskID: task.taskMetaData.taskID,
      taskType: task.taskMetaData.taskType,
      processID: task.taskMetaData.processID,
      status: task.taskMetaData.status,
      kurzbeschreibung: task.taskMetaData.kurzbeschreibung,
      beschreibung: task.taskMetaData.beschreibung,
      absender: task.taskMetaData.absender,
      empfaenger: task.taskMetaData.empfaenger,
      faelligkeitsDatum: task.taskMetaData.faelligkeitsDatum,
      erstellungsDatum: task.taskMetaData.erstellungsDatum,
      aenderungsDatum: task.taskMetaData.aenderungsDatum,
      subtasks: task.subtask ? [] : null,
    };

    return taskModel;
  }
}

import { ValidationErrors } from 'ngrx-forms';

export const validDate = <T extends string | null | undefined>(value: T): ValidationErrors => {
  return !!value && isValidDate(value)
    ? {}
    : {
        invalidDate: {
          actual: value,
        },
      };
};

const isValidDate = (dateString: string): boolean => {
  // First check for the pattern
  const dateFormat = /^\d{1,2}.\d{1,2}.\d{4}$/;

  if (!dateFormat.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export function lessThanDate(actualValue: string): <T extends string | null | undefined>(value: T) => ValidationErrors {
  return <T extends string | null | undefined>(value: T): ValidationErrors => {
    if (!value || !actualValue || typeof value !== 'string') {
      return {};
    }
    const valueArr = value.split(/[.,\/ \.]/);
    const actualValueArr = actualValue.split(/[.,\/ \.]/);
    const valueAsDate = new Date(Number(valueArr[2]), Number(+valueArr[1] - 1), Number(valueArr[0]));
    const actualValueAsDate = new Date(
      Number(actualValueArr[2]),
      Number(+actualValueArr[1] - 1),
      Number(actualValueArr[0])
    );
    if (valueAsDate > actualValueAsDate) {
      return {};
    }

    return {
      lessThanDate: {
        actual: value as string,
      },
    };
  };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'greaterThan',
})
export class GreaterThanPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(items: any[], key: string, value: number, applyFilter: boolean): any {
    if (!items || !key || applyFilter || !value) {
      return items;
    }

    const filteredItem = items.filter((item) => item[key] >= value);

    return filteredItem;
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Bildet einen Eintrag in einer Auswahlliste ab
 */
export interface ChoiceListItem { 
    /**
     * Eindeutige ID des Eintrags
     */
    id: string;
    /**
     * Der value des Eintrags
     */
    value: string;
    /**
     * Eindeutige ID des Parents eines Eintrags
     */
    parentID?: string;
    /**
     * Datum ab wann das Item Gueltig ist
     */
    gueltigVon?: string;
    /**
     * Datum bis wann das Item Gueltig ist
     */
    gueltigBis?: string;
}


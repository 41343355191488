import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromState from '@core/store/reducers';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SystemKonfigurationService } from '@core/services/system-konfiguration.service';
import * as fromActions from '@core/store/actions/system-konfiguration.actions';

@Injectable()
export class SystemKonfigurationEffects {
  constructor(
    private actions$: Actions,
    private systemKonfigurationService: SystemKonfigurationService,
    private store: Store<fromState.CoreModuleState>
  ) {}

  loadSystemKonfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSystemKonfiguration),
      switchMap(() =>
        this.systemKonfigurationService.getSystemKonfiguration().pipe(
          map((systemKonfiguration) => fromActions.loadSystemKonfigurationSuccess({ systemKonfiguration })),
          catchError((error) => of(fromActions.loadSystemKonfigurationFailure({ error })))
        )
      )
    )
  );
}

import { createFormControlState, FormControlState, onNgrxForms } from 'ngrx-forms';
import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { ChoiceListItem } from '@app/core/models/choice-list-item.model';
import { DashboardChartsData } from '@app/core/models/dashboard-charts.model';

import * as fromActions from '@app/modules/dashboard/store/dashboard.actions';

interface DashboardData {
  regionen: ChoiceListItem[];
  chartData: DashboardChartsData;
}
export interface DashboardUI {
  isDashboardInitialized: boolean;
  selectedRegion: FormControlState<string>;
}

export interface DashboardState {
  data: DashboardData;
  ui: DashboardUI;
}

const initialDataState: DashboardData = {
  regionen: [],
  chartData: {
    barChart: [],
    pieChart: [],
    investitionGesamt: [],
    investitionJahr: [],
    referenzwerte: [],
  },
};

const initialUiState: DashboardUI = {
  isDashboardInitialized: false,
  selectedRegion: createFormControlState<string>('selectedRegion', ''),
};

const dashboardDataReducer = createReducer(
  initialDataState,
  on(fromActions.loadDashboardChoicelistsSuccess, (state, { regionen }) => ({ ...state, regionen })),
  on(
    fromActions.loadChartDataSuccess,
    fromActions.refreshChartDataSuccess,
    (state, { investitionGesamt, investitionJahr, referenzwerte }) => ({
      ...state,
      chartData: {
        ...state.chartData,
        investitionGesamt,
        investitionJahr,
        referenzwerte,
      },
    })
  )
);

const dashboardUiReducer = createReducer(
  initialUiState,
  onNgrxForms(),
  on(fromActions.initDashboardFail, fromActions.initDashboard, (state) => ({
    ...state,
    isDashboardInitialized: false,
  })),
  on(fromActions.initDashboardSuccess, (state) => ({ ...state, isDashboardInitialized: true }))
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers = combineReducers<DashboardState, any>({
  data: dashboardDataReducer,
  ui: dashboardUiReducer,
});
export function reducer(state: DashboardState | undefined, action: Action) {
  return reducers(state, action);
}

export const getDataState = (state: DashboardState) => state.data;
export const getUiState = (state: DashboardState) => state.ui;

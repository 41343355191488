import {
  JsonPatchDocument,
  ResponseObject,
  Vorhaben,
  VorhabenService,
  VorhabenSingleResponse,
} from '@app/core/portal-api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Investition } from '@app/core/models/investition.model';
import { SbhTaskService } from '@core/services/sbh-task.service';
import { InvestitionTechnicalConverter } from '@app/core/converters/investition-technical-converter.service';
import { InvestitionConverter } from '@app/core/converters/investition-converter.service';
import { UserProfileService } from '@core/services/user-profile.service';
import { InvestitionTechnical } from '../models/investition-technical.model';
import { InvestitionPriceOffer } from '../models/project-order-price-offer.model';

@Injectable()
export class InvestitionService {
  constructor(
    private vorhabenService: VorhabenService,
    private taskService: SbhTaskService,
    private vorhabenConverter: InvestitionTechnicalConverter,
    private projectConverter: InvestitionConverter,
    private profilService: UserProfileService
  ) {}

  patchInvestition(body: JsonPatchDocument[]) {
    return this.vorhabenService.patchVorhaben(body);
  }

  createNewInvestition(dto: InvestitionTechnical): Observable<Investition> {
    const serverModel: Vorhaben = this.vorhabenConverter.convertToApiModel(dto);
    serverModel.erfasstAm = dto.createdOn;
    serverModel.erfasstVonBenutzer = this.profilService.getCurrentUsersProfile().emailAddress;
    return this.vorhabenService.postVorhaben(serverModel).pipe(
      map((res) => {
        const ret: Investition = this.projectConverter.convertFromApiModel(res.data);
        return ret;
      })
    );
  }

  saveInvestition(dto: InvestitionTechnical): Observable<Investition> {
    const serverModel: Vorhaben = this.vorhabenConverter.convertToApiModel(dto);
    serverModel.letzteAenderungAm = dto.createdOn;
    serverModel.letzteAenderungVonBenutzer = this.profilService.getCurrentUsersProfile().emailAddress;
    return this.vorhabenService.putVorhaben(serverModel.vorhabenID, serverModel).pipe(
      map((res) => {
        const ret: Investition = this.projectConverter.convertFromApiModel(res.data);
        return ret;
      })
    );
  }

  deleteInvestition(dto: InvestitionTechnical): Observable<VorhabenSingleResponse> {
    const serverModel: Vorhaben = this.vorhabenConverter.convertToApiModel(dto);
    serverModel.letzteAenderungAm = new Date().toISOString().replace('Z', '').replace('T', ' ');
    serverModel.letzteAenderungVonBenutzer = this.profilService.getCurrentUsersProfile().emailAddress;
    serverModel.archivProjekt = true;
    return this.vorhabenService.putVorhaben(serverModel.vorhabenID, serverModel);
  }

  abortInvestition(dto: InvestitionTechnical): Observable<ResponseObject> {
    return this.taskService.createInvestitionCancelTask(dto);
  }

  priceOfferCalculation(projectOrder: InvestitionTechnical): Observable<InvestitionPriceOffer> {
    const body = this.vorhabenConverter.convertToApiModel(projectOrder);
    return this.vorhabenService.postVorhabenPreisberechnung(body).pipe(
      map((result) => {
        const data = result.data;
        if (data) {
          return {
            price: data.preis,
            calculationType: data.art,
            formula: data.formel,
          };
        } else {
          return {
            price: 0,
            calculationType: 'MANUELL',
            formula: '-',
          };
        }
      })
    );
  }

  getTechnicalInvestitionByID(id: string): Observable<InvestitionTechnical> {
    return this.vorhabenService.getTechnicalVorhaben(id).pipe(
      map((res) => {
        return this.vorhabenConverter.convertFromApiModel(res.data);
      })
    );
  }

  getInvestitionByID(id: string) {
    return this.vorhabenService
      .getSpecificVorhaben(id)
      .pipe(map((res) => this.projectConverter.convertFromApiModel(res.data)));
  }
}

/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Die Berechtigungsmatrix fuer Zugriffsregeln innerhalb der Anwendung
 */
export interface BerechtigungMatrix { 
    /**
     * Die ID des Moduls
     */
    modulID?: string;
    /**
     * Der Fokus in welchem die Funktion ausgefuehrt wird
     */
    fokus?: string;
    /**
     * Eine Funktion innerhalb der Anwendung
     */
    funktion?: string;
    rollenIntern?: Array<string>;
    rollenIAM?: Array<string>;
    /**
     * Ist eine Zustaendigkeit fuer die Funktion erforderlich?
     */
    zustaendig?: boolean;
    /**
     * Ist die Funktion aktiviert?
     */
    aktiviert?: boolean;
}


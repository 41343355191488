import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdministrationService } from '@core/portal-api';
import { SystemKonfigurationConverter } from '@core/converters/system-konfiguration-converter.service';
import { SystemKonfigurationModel } from '@core/models/system-konfiguration.model';

@Injectable()
export class SystemKonfigurationService {
  constructor(private administrationService: AdministrationService, private converter: SystemKonfigurationConverter) {}

  getSystemKonfiguration(): Observable<SystemKonfigurationModel> {
    return this.administrationService.getSystemKonfiguration().pipe(
      map((res) => {
        return this.converter.convertFromApiModel(res.data);
      })
    );
  }
}

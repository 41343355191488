import { ColumnType, TableColumn } from '../models/table-column.model';

export const PROJEKTE_TABLE_CONFIG: TableColumn[] = [
  { name: 'Adresse', prop: 'adresse', order: 0, showColumn: true, width: 160 },
  {
    name: 'Gebäudenummer',
    prop: 'gebaeudeNummer',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasComparator: true,
    width: 70,
  },
  { name: 'Gebäudename', prop: 'gebaeudeName', width: 180, showColumn: true },
  { name: 'Schule / Institution', prop: 'schulName', width: 210, showColumn: true },
  { name: 'Projektart', prop: 'projektArt', hasFilter: true, showColumn: true, width: 120 },
  { name: 'Programmart', prop: 'programmArt', hasFilter: true, width: 180, showColumn: true },
  { name: 'Unterprogrammart', prop: 'unterProgrammArt', width: 170, showColumn: true },
  { name: 'Ausprägung Unterprogrammart', prop: 'auspraegungUnterProgrammArt', width: 170, showColumn: true },
  { name: 'Preisart', prop: 'preisart', showColumn: true, width: 130 },
  { name: 'Beschreibung', prop: 'beschreibung', showColumn: true, width: 160 },
  { name: 'Projektnummer', prop: 'projektNummer', hasFilter: true, showColumn: true, width: 160 },
  {
    name: 'Investitionsfläche in ㎡',
    prop: 'flaechenAnsatz',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasPipe: true,
    width: 120,
  },
  { name: 'Kunde', prop: 'nutzer', hasFilter: true, showColumn: false, width: 70 },
  {
    name: 'Baubeginn',
    prop: 'bauBeginn',
    cellClass: 'text-right',
    hasFilter: true,
    showColumn: true,
    width: 95,
  },
  {
    name: 'Übergabe',
    prop: 'bauFertigstellung',
    cellClass: 'text-right',
    hasFilter: true,
    showColumn: true,
    width: 95,
  },
  {
    name: 'Angebotspreis in € (Brutto)',
    prop: 'angebotspreis',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: true,
    hasPipe: true,
    width: 130,
  },
  { name: 'Status', prop: 'auftragStatus', hasFilter: true, showColumn: false, width: 160 },
  { name: 'Bemerkung', prop: 'bemerkung', showColumn: false, width: 160 },
  {
    name: 'Richtwert Intern in € (Brutto)',
    prop: 'richtwertIntern',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: false,
    hasPipe: true,
    width: 140,
  },
  {
    name: 'Richtwert Extern in € (Brutto)',
    prop: 'richtwertExtern',
    cellClass: 'text-right',
    type: ColumnType.NUMBER,
    showColumn: false,
    hasPipe: true,
    width: 140,
  },
  {
    name: 'Letzte Änderung',
    prop: 'letzteAenderungungDatum',
    cellClass: 'text-right',
    type: ColumnType.DATE,
    showColumn: false,
    width: 160,
  },
  {
    name: 'Projektnummer alt',
    prop: 'subteilprojektnummer',
    showColumn: false,
    width: 160,
  },
];

import { createAction, props } from '@ngrx/store';

import { Umsatz } from '@app/core/models/umsatz.model';
import { RahmenplanDetailsData } from '@app/core/models/rahmenplan-details-state.model';
import { Investition } from '@app/core/models/investition.model';
import { ChoiceListItem } from '@app/core/models/choice-list-item.model';
import { Gebaeudeanlage } from '@core/models/gebaeudeanlage.model';

export const loadWirtschaftseinheitGroup = createAction('[Rahmenplan] load Wirtschaftseinheit Groups');
export const loadWirtschaftseinheitGroupSuccess = createAction(
  '[Rahmenplan] loaded Wirtschaftseinheit Groups success',
  props<{ wirtschaftseinheitGroupData: RahmenplanDetailsData }>()
);
export const loadWirtschaftseinheitGroupFail = createAction('[Rahmenplan] loaded Wirtschaftseinheit Groups failed');
export const selectWirtschaftseinheit = createAction(
  '[Rahmenplan] Select wirtschaftseinheit',
  props<{ weID: string }>()
);

export const loadInvestitionList = createAction('[Rahmenplan] load investition list', props<{ weID: string }>());
export const loadInvestitionListSuccess = createAction(
  '[Rahmenplan] loaded investition list success',
  props<{ investitionList: Investition[] }>()
);
export const loadInvestitionListFail = createAction(
  '[Rahmenplan] loaded investition list failed',
  props<{ error: TypeError; objektID: string }>()
);

export const loadInvestitionListAndNavigate = createAction(
  '[Rahmenplan] load investition list and navigate',
  props<{ weID: string }>()
);

export const loadWeChoicelists = createAction('[Rahmenplan] Load we choicelists', props<{ weID: string }>());
export const loadWeChoicelistsSuccess = createAction(
  '[Rahmenplan] Load we choicelists success',
  props<{
    schulen: ChoiceListItem[];
    gebaeude: ChoiceListItem[];
    gebaeudeNeu: ChoiceListItem[];
    projektnummern: ChoiceListItem[];
    projektsteuerer: ChoiceListItem[];
  }>()
);
export const loadWeChoicelistsFail = createAction(
  '[Rahmenplan] Load we choicelists fail',
  props<{ error: TypeError }>()
);

export const loadProjektChoicelist = createAction('[Rahmenplan] Load projekt choicelist', props<{ weID: string }>());

export const loadProjektChoicelistSuccess = createAction(
  '[Rahmenplan] Load projekt choicelist success',
  props<{ projektnummern: ChoiceListItem[] }>()
);

export const loadProjektChoicelistFail = createAction(
  '[Rahmenplan] Load projekt choicelist fail',
  props<{ error: TypeError; objektID: string }>()
);

export const setSelectedWeID = createAction(
  '[Rahmenplan] Set selected Wirtschaftseinheit ID',
  props<{ weID: string }>()
);

export const loadUmsaetze = createAction('[Rahmenplan] load Umsatz list', props<{ weID: string }>());
export const loadUmsaetzeFail = createAction(
  '[Rahmenplan] load Umsatz list failed',
  props<{ error: TypeError; objektID: string }>()
);
export const loadUmsaetzeSuccess = createAction(
  '[Rahmenplan] load Umsatz list success',
  props<{ umsatzList: Umsatz[] }>()
);

export const loadWeGebaeudeList = createAction('[Rahmenplan] Load we gebaeude list', props<{ weID: string }>());

export const loadWeGebaeudeListSuccess = createAction(
  '[Rahmenplan] Load we gebaeude list success',
  props<{ gebaeudeList: Gebaeudeanlage[] }>()
);

export const loadWeGebaeudeListFail = createAction(
  '[Rahmenplan] Load we gebaeude list failure',
  props<{ error: TypeError; objektID: string }>()
);

export const exportVorhaben = createAction('[Rahmenplan] Export all Vorhaben', props<{ weID: string }>());
export const exportVorhabenFail = createAction('[Rahmenplan] Export all Vorhaben fail', props<{ error: unknown }>());

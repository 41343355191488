/**
 * SBH Rahmenplan API
 * SBH Rahmenplan API
 *
 * The version of the OpenAPI document: 0.0.5
 * Contact: christopher.brost@nterra.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Meta Informationen fuer einen Task
 */
export interface TaskGenericMetaData { 
    /**
     * Eindeutiger Identifier des Tasks (UUID)
     */
    taskID?: string;
    /**
     * Der Typ des Tasks
     */
    taskType?: TaskGenericMetaData.TaskTypeEnum;
    /**
     * status des Tasks
     */
    status?: TaskGenericMetaData.StatusEnum;
    /**
     * Eindeutiger Identifier des BPM Prozesses
     */
    processID?: string;
    /**
     * Kurzbeschreibung des Tasks
     */
    kurzbeschreibung?: string;
    /**
     * Beschreibung des Tasks
     */
    beschreibung?: string;
    /**
     * Absender des Tasks
     */
    absender?: string;
    /**
     * Empfaenger des Tasks
     */
    empfaenger?: string;
    /**
     * Faelligkeitsdatum des Tasks (YYYY-MM-DD)
     */
    faelligkeitsDatum?: string;
    /**
     * Erstellungsdatum des Tasks (YYYY-MM-DD)
     */
    erstellungsDatum?: string;
    /**
     * letztes Aenderungsdatum des Tasks (YYYY-MM-DD)
     */
    aenderungsDatum?: string;
}
export namespace TaskGenericMetaData {
    export type TaskTypeEnum = 'PROJEKTVORSCHLAG_PRUEFEN' | 'PROJEKTVORSCHLAG_ANLEGEN' | 'PROJEKTZUORDNUNG_PRUEFEN' | 'PROJEKTZUORDNUNG_ABSCHLIESSEN' | 'AUFTRAGSABBRUCH_PRUEFEN' | 'AUFTRAGSABBRUCH_ABSCHLIESSEN' | 'WORKFLOW_REFERENZBILDUNG_STARTEN' | 'REFERENZBILDUNG_SPERREN';
    export const TaskTypeEnum = {
        ProjektvorschlagPruefen: 'PROJEKTVORSCHLAG_PRUEFEN' as TaskTypeEnum,
        ProjektvorschlagAnlegen: 'PROJEKTVORSCHLAG_ANLEGEN' as TaskTypeEnum,
        ProjektzuordnungPruefen: 'PROJEKTZUORDNUNG_PRUEFEN' as TaskTypeEnum,
        ProjektzuordnungAbschliessen: 'PROJEKTZUORDNUNG_ABSCHLIESSEN' as TaskTypeEnum,
        AuftragsabbruchPruefen: 'AUFTRAGSABBRUCH_PRUEFEN' as TaskTypeEnum,
        AuftragsabbruchAbschliessen: 'AUFTRAGSABBRUCH_ABSCHLIESSEN' as TaskTypeEnum,
        WorkflowReferenzbildungStarten: 'WORKFLOW_REFERENZBILDUNG_STARTEN' as TaskTypeEnum,
        ReferenzbildungSperren: 'REFERENZBILDUNG_SPERREN' as TaskTypeEnum
    };
    export type StatusEnum = 'offen' | 'geschlossen';
    export const StatusEnum = {
        Offen: 'offen' as StatusEnum,
        Geschlossen: 'geschlossen' as StatusEnum
    };
}



const C_V_STM_GB = [
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702014',
    GB_SAP_GB_ID_PKF: '7000/702014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 353.28,
    GB_NUTZFL_NF_VON_NGF: 277.98,
    GB_TECHNIKFL_TF_VON_NGF: 11.27,
    GB_VERKEHRSFL_VF_VON_NGF: 64.03,
    GB_MIETFL_GESAMT: 353.28,
    GB_MIETFL_1_BSB: 342.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990031,
    GB_WE_OBJ_ID_FKT: 1989082,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702014',
    GB_SAP_GB_ID_PKF: '7000/702014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 191.53,
    GB_NUTZFL_NF_VON_NGF: 152.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.18,
    GB_MIETFL_GESAMT: 191.53,
    GB_MIETFL_1_BSB: 191.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990032,
    GB_WE_OBJ_ID_FKT: 1989082,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702014',
    GB_SAP_GB_ID_PKF: '7000/702014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2653.42,
    GB_NUTZFL_NF_VON_NGF: 1995.25,
    GB_TECHNIKFL_TF_VON_NGF: 91.29,
    GB_VERKEHRSFL_VF_VON_NGF: 566.88,
    GB_MIETFL_GESAMT: 2653.42,
    GB_MIETFL_1_BSB: 2376.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990033,
    GB_WE_OBJ_ID_FKT: 1989082,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702014',
    GB_SAP_GB_ID_PKF: '7000/702014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.559294',
    GB_LAENGENGRAD_OESTLICH: '9.957519',
    GB_NETTOGRUNDFL_NGF: 330.28,
    GB_NUTZFL_NF_VON_NGF: 312.69,
    GB_TECHNIKFL_TF_VON_NGF: 9.2,
    GB_VERKEHRSFL_VF_VON_NGF: 8.39,
    GB_MIETFL_GESAMT: 330.28,
    GB_MIETFL_1_BSB: 330.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990034,
    GB_WE_OBJ_ID_FKT: 1989082,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702022',
    GB_SAP_GB_ID_PKF: '7000/702022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.545783',
    GB_LAENGENGRAD_OESTLICH: '9.949029',
    GB_NETTOGRUNDFL_NGF: 4709.42,
    GB_NUTZFL_NF_VON_NGF: 3669.43,
    GB_TECHNIKFL_TF_VON_NGF: 139.15,
    GB_VERKEHRSFL_VF_VON_NGF: 900.84,
    GB_MIETFL_GESAMT: 4709.42,
    GB_MIETFL_1_BSB: 4623.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990079,
    GB_WE_OBJ_ID_FKT: 1989090,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702022',
    GB_SAP_GB_ID_PKF: '7000/702022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: '53.546042',
    GB_LAENGENGRAD_OESTLICH: '9.949093',
    GB_NETTOGRUNDFL_NGF: 301.1,
    GB_NUTZFL_NF_VON_NGF: 294.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.89,
    GB_MIETFL_GESAMT: 301.1,
    GB_MIETFL_1_BSB: 301.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990080,
    GB_WE_OBJ_ID_FKT: 1989090,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702022',
    GB_SAP_GB_ID_PKF: '7000/702022/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 450.86,
    GB_NUTZFL_NF_VON_NGF: 350.25,
    GB_TECHNIKFL_TF_VON_NGF: 3.46,
    GB_VERKEHRSFL_VF_VON_NGF: 97.15,
    GB_MIETFL_GESAMT: 450.86,
    GB_MIETFL_1_BSB: 450.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990081,
    GB_WE_OBJ_ID_FKT: 1989090,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702022,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702022',
    GB_SAP_GB_ID_PKF: '7000/702022/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.98,
    GB_NUTZFL_NF_VON_NGF: 122.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: 137.98,
    GB_MIETFL_1_BSB: 137.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6894217,
    GB_WE_OBJ_ID_FKT: 1989090,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1217.58,
    GB_NUTZFL_NF_VON_NGF: 996.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 221.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453337,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1643.26,
    GB_NUTZFL_NF_VON_NGF: 1408.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 234.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453338,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1449.76,
    GB_NUTZFL_NF_VON_NGF: 1170.67,
    GB_TECHNIKFL_TF_VON_NGF: 37.09,
    GB_VERKEHRSFL_VF_VON_NGF: 242.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453339,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.548250',
    GB_LAENGENGRAD_OESTLICH: '9.949098',
    GB_NETTOGRUNDFL_NGF: 624.92,
    GB_NUTZFL_NF_VON_NGF: 598.89,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 19.34,
    GB_MIETFL_GESAMT: 624.92,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5453340,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3335.25,
    GB_NUTZFL_NF_VON_NGF: 2949.38,
    GB_TECHNIKFL_TF_VON_NGF: 3.06,
    GB_VERKEHRSFL_VF_VON_NGF: 382.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453341,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1957.57,
    GB_NUTZFL_NF_VON_NGF: 1928.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.25,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453342,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.5,
    GB_NUTZFL_NF_VON_NGF: 109.26,
    GB_TECHNIKFL_TF_VON_NGF: 9.13,
    GB_VERKEHRSFL_VF_VON_NGF: 21.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '24.06.20',
    GB_GB_OBJ_ID_PKT: 5453343,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.96,
    GB_NUTZFL_NF_VON_NGF: 15.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.96,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5453344,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1619.99,
    GB_NUTZFL_NF_VON_NGF: 1296.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 323.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '08.05.19',
    GB_GB_OBJ_ID_PKT: 5453345,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 967.32,
    GB_NUTZFL_NF_VON_NGF: 646.19,
    GB_TECHNIKFL_TF_VON_NGF: 2.2,
    GB_VERKEHRSFL_VF_VON_NGF: 318.93,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '08.05.19',
    GB_GB_OBJ_ID_PKT: 5453346,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 788.5,
    GB_NUTZFL_NF_VON_NGF: 467.68,
    GB_TECHNIKFL_TF_VON_NGF: 3.25,
    GB_VERKEHRSFL_VF_VON_NGF: 317.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '08.05.19',
    GB_GB_OBJ_ID_PKT: 5453347,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/15',
    GB_SAP_GB_BEZEICHNUNG: 'Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.0,
    GB_NUTZFL_NF_VON_NGF: 135.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 135.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5453350,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/16',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5453351,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.548191',
    GB_LAENGENGRAD_OESTLICH: '9.944520',
    GB_NETTOGRUNDFL_NGF: 530.74,
    GB_NUTZFL_NF_VON_NGF: 507.47,
    GB_TECHNIKFL_TF_VON_NGF: 17.41,
    GB_VERKEHRSFL_VF_VON_NGF: 5.86,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '08.05.19',
    GB_GB_OBJ_ID_PKT: 5453348,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.547685',
    GB_LAENGENGRAD_OESTLICH: '9.946116',
    GB_NETTOGRUNDFL_NGF: 1107.07,
    GB_NUTZFL_NF_VON_NGF: 839.96,
    GB_TECHNIKFL_TF_VON_NGF: 47.0,
    GB_VERKEHRSFL_VF_VON_NGF: 220.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '08.06.20',
    GB_GB_OBJ_ID_PKT: 5453349,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/17',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5453352,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/18',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5912362,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/19',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5912363,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/20',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6894218,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702075,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/702075',
    GB_SAP_GB_ID_PKF: '7000/702075/21',
    GB_SAP_GB_BEZEICHNUNG: 'Einfeldsporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Einfeldsporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6901217,
    GB_WE_OBJ_ID_FKT: 5453335,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1875,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2461.57,
    GB_NUTZFL_NF_VON_NGF: 1854.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 607.3,
    GB_MIETFL_GESAMT: 2461.57,
    GB_MIETFL_1_BSB: 1839.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990352,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1771.32,
    GB_NUTZFL_NF_VON_NGF: 1422.57,
    GB_TECHNIKFL_TF_VON_NGF: 16.45,
    GB_VERKEHRSFL_VF_VON_NGF: 332.3,
    GB_MIETFL_GESAMT: 1771.32,
    GB_MIETFL_1_BSB: 1401.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990353,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.556826',
    GB_LAENGENGRAD_OESTLICH: '9.956352',
    GB_NETTOGRUNDFL_NGF: 618.34,
    GB_NUTZFL_NF_VON_NGF: 589.49,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 22.16,
    GB_MIETFL_GESAMT: 618.34,
    GB_MIETFL_1_BSB: 618.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990354,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.14',
    GB_GB_OBJ_ID_PKT: 1990355,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 698.48,
    GB_NUTZFL_NF_VON_NGF: 559.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 138.78,
    GB_MIETFL_GESAMT: 698.48,
    GB_MIETFL_1_BSB: 698.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990356,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 766.09,
    GB_NUTZFL_NF_VON_NGF: 577.36,
    GB_TECHNIKFL_TF_VON_NGF: 34.11,
    GB_VERKEHRSFL_VF_VON_NGF: 154.62,
    GB_MIETFL_GESAMT: 766.09,
    GB_MIETFL_1_BSB: 699.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990357,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.556162',
    GB_LAENGENGRAD_OESTLICH: '9.955816',
    GB_NETTOGRUNDFL_NGF: 665.63,
    GB_NUTZFL_NF_VON_NGF: 636.85,
    GB_TECHNIKFL_TF_VON_NGF: 0.7,
    GB_VERKEHRSFL_VF_VON_NGF: 28.08,
    GB_MIETFL_GESAMT: 665.63,
    GB_MIETFL_1_BSB: 665.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990358,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702056,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702056',
    GB_SAP_GB_ID_PKF: '7000/702056/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 771.22,
    GB_NUTZFL_NF_VON_NGF: 675.82,
    GB_TECHNIKFL_TF_VON_NGF: 12.39,
    GB_VERKEHRSFL_VF_VON_NGF: 83.01,
    GB_MIETFL_GESAMT: 771.22,
    GB_MIETFL_1_BSB: 525.38,
    GB_MIETFL_2_DRITTE: 245.84,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '10.07.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400820,
    GB_WE_OBJ_ID_FKT: 1989124,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702042',
    GB_SAP_GB_ID_PKF: '7000/702042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.554108',
    GB_LAENGENGRAD_OESTLICH: '9.950277',
    GB_NETTOGRUNDFL_NGF: 7835.15,
    GB_NUTZFL_NF_VON_NGF: 6039.72,
    GB_TECHNIKFL_TF_VON_NGF: 122.24,
    GB_VERKEHRSFL_VF_VON_NGF: 1673.19,
    GB_MIETFL_GESAMT: 7835.15,
    GB_MIETFL_1_BSB: 7487.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.09',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990245,
    GB_WE_OBJ_ID_FKT: 1989110,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1091.5,
    GB_NUTZFL_NF_VON_NGF: 700.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 391.05,
    GB_MIETFL_GESAMT: 1091.5,
    GB_MIETFL_1_BSB: 851.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989992,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 372.52,
    GB_NUTZFL_NF_VON_NGF: 249.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 123.18,
    GB_MIETFL_GESAMT: 372.52,
    GB_MIETFL_1_BSB: 220.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989993,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.564119',
    GB_LAENGENGRAD_OESTLICH: '9.953798',
    GB_NETTOGRUNDFL_NGF: 622.52,
    GB_NUTZFL_NF_VON_NGF: 582.01,
    GB_TECHNIKFL_TF_VON_NGF: 21.42,
    GB_VERKEHRSFL_VF_VON_NGF: 19.09,
    GB_MIETFL_GESAMT: 622.52,
    GB_MIETFL_1_BSB: 622.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989994,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.08.17',
    GB_GB_OBJ_ID_PKT: 1989995,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1255.78,
    GB_NUTZFL_NF_VON_NGF: 1065.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 190.0,
    GB_MIETFL_GESAMT: 1255.78,
    GB_MIETFL_1_BSB: 1255.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989996,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1279.05,
    GB_NUTZFL_NF_VON_NGF: 1036.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 242.06,
    GB_MIETFL_GESAMT: 1279.05,
    GB_MIETFL_1_BSB: 1279.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989997,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 337.68,
    GB_NUTZFL_NF_VON_NGF: 304.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.32,
    GB_MIETFL_GESAMT: 337.68,
    GB_MIETFL_1_BSB: 337.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989998,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 736.79,
    GB_NUTZFL_NF_VON_NGF: 532.77,
    GB_TECHNIKFL_TF_VON_NGF: 0.56,
    GB_VERKEHRSFL_VF_VON_NGF: 203.46,
    GB_MIETFL_GESAMT: 736.79,
    GB_MIETFL_1_BSB: 736.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989999,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3087.68,
    GB_NUTZFL_NF_VON_NGF: 2323.45,
    GB_TECHNIKFL_TF_VON_NGF: 154.18,
    GB_VERKEHRSFL_VF_VON_NGF: 610.05,
    GB_MIETFL_GESAMT: 3087.68,
    GB_MIETFL_1_BSB: 2065.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990000,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702009,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702009',
    GB_SAP_GB_ID_PKF: '7000/702009/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.564034',
    GB_LAENGENGRAD_OESTLICH: '9.955884',
    GB_NETTOGRUNDFL_NGF: 531.56,
    GB_NUTZFL_NF_VON_NGF: 495.86,
    GB_TECHNIKFL_TF_VON_NGF: 15.97,
    GB_VERKEHRSFL_VF_VON_NGF: 19.73,
    GB_MIETFL_GESAMT: 531.56,
    GB_MIETFL_1_BSB: 531.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.08.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5193723,
    GB_WE_OBJ_ID_FKT: 1989077,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702057',
    GB_SAP_GB_ID_PKF: '7000/702057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6819.38,
    GB_NUTZFL_NF_VON_NGF: 4766.34,
    GB_TECHNIKFL_TF_VON_NGF: 65.58,
    GB_VERKEHRSFL_VF_VON_NGF: 1987.46,
    GB_MIETFL_GESAMT: 6819.38,
    GB_MIETFL_1_BSB: 6054.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990359,
    GB_WE_OBJ_ID_FKT: 1989125,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702057',
    GB_SAP_GB_ID_PKF: '7000/702057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.559577',
    GB_LAENGENGRAD_OESTLICH: '9.940454',
    GB_NETTOGRUNDFL_NGF: 625.77,
    GB_NUTZFL_NF_VON_NGF: 595.69,
    GB_TECHNIKFL_TF_VON_NGF: 7.87,
    GB_VERKEHRSFL_VF_VON_NGF: 22.21,
    GB_MIETFL_GESAMT: 625.77,
    GB_MIETFL_1_BSB: 625.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990360,
    GB_WE_OBJ_ID_FKT: 1989125,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702057',
    GB_SAP_GB_ID_PKF: '7000/702057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6996605,
    GB_WE_OBJ_ID_FKT: 1989125,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702070',
    GB_SAP_GB_ID_PKF: '7000/702070/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: '53.559247',
    GB_LAENGENGRAD_OESTLICH: '9.942179',
    GB_NETTOGRUNDFL_NGF: 869.04,
    GB_NUTZFL_NF_VON_NGF: 655.45,
    GB_TECHNIKFL_TF_VON_NGF: 89.33,
    GB_VERKEHRSFL_VF_VON_NGF: 124.26,
    GB_MIETFL_GESAMT: 869.04,
    GB_MIETFL_1_BSB: 779.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990420,
    GB_WE_OBJ_ID_FKT: 1989136,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702072,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702072',
    GB_SAP_GB_ID_PKF: '7000/702072/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990421,
    GB_WE_OBJ_ID_FKT: 1989137,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13543.13,
    GB_NUTZFL_NF_VON_NGF: 9777.44,
    GB_TECHNIKFL_TF_VON_NGF: 144.19,
    GB_VERKEHRSFL_VF_VON_NGF: 3621.5,
    GB_MIETFL_GESAMT: 13543.13,
    GB_MIETFL_1_BSB: 10160.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989962,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.566739',
    GB_LAENGENGRAD_OESTLICH: '9.947327',
    GB_NETTOGRUNDFL_NGF: 1617.14,
    GB_NUTZFL_NF_VON_NGF: 1526.92,
    GB_TECHNIKFL_TF_VON_NGF: 31.6,
    GB_VERKEHRSFL_VF_VON_NGF: 58.62,
    GB_MIETFL_GESAMT: 1617.14,
    GB_MIETFL_1_BSB: 1617.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989963,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 251.71,
    GB_NUTZFL_NF_VON_NGF: 193.07,
    GB_TECHNIKFL_TF_VON_NGF: 13.52,
    GB_VERKEHRSFL_VF_VON_NGF: 45.12,
    GB_MIETFL_GESAMT: 251.71,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989964,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138239,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7096361,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702002,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702002',
    GB_SAP_GB_ID_PKF: '7000/702002/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 994.89,
    GB_NUTZFL_NF_VON_NGF: 869.39,
    GB_TECHNIKFL_TF_VON_NGF: 1.81,
    GB_VERKEHRSFL_VF_VON_NGF: 123.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.15',
    GB_GB_OBJ_ID_PKT: 4352712,
    GB_WE_OBJ_ID_FKT: 1989070,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702001',
    GB_SAP_GB_ID_PKF: '7000/702001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.554912',
    GB_LAENGENGRAD_OESTLICH: '9.940758',
    GB_NETTOGRUNDFL_NGF: 5829.0,
    GB_NUTZFL_NF_VON_NGF: 4098.44,
    GB_TECHNIKFL_TF_VON_NGF: 111.36,
    GB_VERKEHRSFL_VF_VON_NGF: 1619.2,
    GB_MIETFL_GESAMT: 5829.0,
    GB_MIETFL_1_BSB: 4930.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989960,
    GB_WE_OBJ_ID_FKT: 1989069,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702001',
    GB_SAP_GB_ID_PKF: '7000/702001/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2105.28,
    GB_NUTZFL_NF_VON_NGF: 1691.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 413.61,
    GB_MIETFL_GESAMT: 2105.28,
    GB_MIETFL_1_BSB: 2093.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989961,
    GB_WE_OBJ_ID_FKT: 1989069,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702001,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702001',
    GB_SAP_GB_ID_PKF: '7000/702001/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3666.55,
    GB_NUTZFL_NF_VON_NGF: 2591.5,
    GB_TECHNIKFL_TF_VON_NGF: 120.26,
    GB_VERKEHRSFL_VF_VON_NGF: 954.79,
    GB_MIETFL_GESAMT: 3666.55,
    GB_MIETFL_1_BSB: 3647.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5933300,
    GB_WE_OBJ_ID_FKT: 1989069,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702001,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702001',
    GB_SAP_GB_ID_PKF: '7000/702001/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.21,
    GB_NUTZFL_NF_VON_NGF: 80.65,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.16',
    GB_GB_OBJ_ID_PKT: 5306669,
    GB_WE_OBJ_ID_FKT: 1989069,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702078',
    GB_SAP_GB_ID_PKF: '7000/702078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5489781,
    GB_WE_OBJ_ID_FKT: 5489779,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2713.72,
    GB_NUTZFL_NF_VON_NGF: 2041.26,
    GB_TECHNIKFL_TF_VON_NGF: 83.05,
    GB_VERKEHRSFL_VF_VON_NGF: 589.41,
    GB_MIETFL_GESAMT: 2713.72,
    GB_MIETFL_1_BSB: 2519.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990269,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 822.94,
    GB_NUTZFL_NF_VON_NGF: 729.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.49,
    GB_MIETFL_GESAMT: 822.94,
    GB_MIETFL_1_BSB: 822.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990270,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1099.05,
    GB_NUTZFL_NF_VON_NGF: 834.42,
    GB_TECHNIKFL_TF_VON_NGF: 53.54,
    GB_VERKEHRSFL_VF_VON_NGF: 211.09,
    GB_MIETFL_GESAMT: 1099.05,
    GB_MIETFL_1_BSB: 1045.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990271,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 797.26,
    GB_NUTZFL_NF_VON_NGF: 688.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 109.16,
    GB_MIETFL_GESAMT: 797.26,
    GB_MIETFL_1_BSB: 797.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990272,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.560750',
    GB_LAENGENGRAD_OESTLICH: '9.922976',
    GB_NETTOGRUNDFL_NGF: 665.19,
    GB_NUTZFL_NF_VON_NGF: 621.23,
    GB_TECHNIKFL_TF_VON_NGF: 16.26,
    GB_VERKEHRSFL_VF_VON_NGF: 27.7,
    GB_MIETFL_GESAMT: 665.19,
    GB_MIETFL_1_BSB: 665.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990273,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 930.9,
    GB_NUTZFL_NF_VON_NGF: 774.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 156.13,
    GB_MIETFL_GESAMT: 930.9,
    GB_MIETFL_1_BSB: 930.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990274,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 938.06,
    GB_NUTZFL_NF_VON_NGF: 775.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 162.44,
    GB_MIETFL_GESAMT: 938.06,
    GB_MIETFL_1_BSB: 938.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990275,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.560118',
    GB_LAENGENGRAD_OESTLICH: '9.922785',
    GB_NETTOGRUNDFL_NGF: 617.45,
    GB_NUTZFL_NF_VON_NGF: 596.74,
    GB_TECHNIKFL_TF_VON_NGF: 6.34,
    GB_VERKEHRSFL_VF_VON_NGF: 14.37,
    GB_MIETFL_GESAMT: 617.45,
    GB_MIETFL_1_BSB: 617.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990276,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 368.89,
    GB_NUTZFL_NF_VON_NGF: 271.61,
    GB_TECHNIKFL_TF_VON_NGF: 4.29,
    GB_VERKEHRSFL_VF_VON_NGF: 92.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.16',
    GB_GB_OBJ_ID_PKT: 1990277,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 417.26,
    GB_NUTZFL_NF_VON_NGF: 396.39,
    GB_TECHNIKFL_TF_VON_NGF: 4.42,
    GB_VERKEHRSFL_VF_VON_NGF: 16.45,
    GB_MIETFL_GESAMT: 417.26,
    GB_MIETFL_1_BSB: 417.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990278,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.9,
    GB_NUTZFL_NF_VON_NGF: 16.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.9,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990279,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/12',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.37,
    GB_NUTZFL_NF_VON_NGF: 9.57,
    GB_TECHNIKFL_TF_VON_NGF: 4.8,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.37,
    GB_MIETFL_1_BSB: 14.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990280,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1604.79,
    GB_NUTZFL_NF_VON_NGF: 1184.94,
    GB_TECHNIKFL_TF_VON_NGF: 14.35,
    GB_VERKEHRSFL_VF_VON_NGF: 405.5,
    GB_MIETFL_GESAMT: 1604.79,
    GB_MIETFL_1_BSB: 1601.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400492,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1776.28,
    GB_NUTZFL_NF_VON_NGF: 1669.8,
    GB_TECHNIKFL_TF_VON_NGF: 24.33,
    GB_VERKEHRSFL_VF_VON_NGF: 82.15,
    GB_MIETFL_GESAMT: 1776.28,
    GB_MIETFL_1_BSB: 1776.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.03.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400493,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6985652,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 314.14,
    GB_NUTZFL_NF_VON_NGF: 289.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.43,
    GB_MIETFL_GESAMT: 314.14,
    GB_MIETFL_1_BSB: 314.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352717,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702045,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702045',
    GB_SAP_GB_ID_PKF: '7000/702045/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 259.78,
    GB_NUTZFL_NF_VON_NGF: 223.22,
    GB_TECHNIKFL_TF_VON_NGF: 3.37,
    GB_VERKEHRSFL_VF_VON_NGF: 33.19,
    GB_MIETFL_GESAMT: 259.78,
    GB_MIETFL_1_BSB: 259.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '27.08.15',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7127479,
    GB_WE_OBJ_ID_FKT: 1989113,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702036',
    GB_SAP_GB_ID_PKF: '7000/702036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.573507',
    GB_LAENGENGRAD_OESTLICH: '9.899155',
    GB_NETTOGRUNDFL_NGF: 3752.44,
    GB_NUTZFL_NF_VON_NGF: 2702.75,
    GB_TECHNIKFL_TF_VON_NGF: 92.13,
    GB_VERKEHRSFL_VF_VON_NGF: 957.56,
    GB_MIETFL_GESAMT: 3752.44,
    GB_MIETFL_1_BSB: 3651.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990201,
    GB_WE_OBJ_ID_FKT: 1989104,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702036,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702036',
    GB_SAP_GB_ID_PKF: '7000/702036/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 462.0,
    GB_NUTZFL_NF_VON_NGF: 317.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 144.66,
    GB_MIETFL_GESAMT: 462.0,
    GB_MIETFL_1_BSB: 462.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990202,
    GB_WE_OBJ_ID_FKT: 1989104,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702036,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702036',
    GB_SAP_GB_ID_PKF: '7000/702036/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.07.22',
    GB_GB_OBJ_ID_PKT: 1990203,
    GB_WE_OBJ_ID_FKT: 1989104,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702036,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702036',
    GB_SAP_GB_ID_PKF: '7000/702036/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 348.55,
    GB_NUTZFL_NF_VON_NGF: 296.0,
    GB_TECHNIKFL_TF_VON_NGF: 5.25,
    GB_VERKEHRSFL_VF_VON_NGF: 47.3,
    GB_MIETFL_GESAMT: 348.55,
    GB_MIETFL_1_BSB: 348.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6950696,
    GB_WE_OBJ_ID_FKT: 1989104,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 713.12,
    GB_NUTZFL_NF_VON_NGF: 284.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 428.9,
    GB_MIETFL_GESAMT: 713.12,
    GB_MIETFL_1_BSB: 713.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990285,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 197.52,
    GB_NUTZFL_NF_VON_NGF: 86.33,
    GB_TECHNIKFL_TF_VON_NGF: 2.8,
    GB_VERKEHRSFL_VF_VON_NGF: 108.39,
    GB_MIETFL_GESAMT: 197.52,
    GB_MIETFL_1_BSB: 169.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990286,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1105.55,
    GB_NUTZFL_NF_VON_NGF: 891.83,
    GB_TECHNIKFL_TF_VON_NGF: 58.01,
    GB_VERKEHRSFL_VF_VON_NGF: 155.71,
    GB_MIETFL_GESAMT: 1105.55,
    GB_MIETFL_1_BSB: 1014.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990287,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 309.21,
    GB_NUTZFL_NF_VON_NGF: 274.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.58,
    GB_MIETFL_GESAMT: 309.21,
    GB_MIETFL_1_BSB: 309.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '03.04.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990288,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.08,
    GB_NUTZFL_NF_VON_NGF: 125.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.75,
    GB_MIETFL_GESAMT: 137.08,
    GB_MIETFL_1_BSB: 137.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990289,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/6',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 830.77,
    GB_NUTZFL_NF_VON_NGF: 684.22,
    GB_TECHNIKFL_TF_VON_NGF: 39.89,
    GB_VERKEHRSFL_VF_VON_NGF: 106.66,
    GB_MIETFL_GESAMT: 830.77,
    GB_MIETFL_1_BSB: 830.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990290,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1234.5,
    GB_NUTZFL_NF_VON_NGF: 1049.7,
    GB_TECHNIKFL_TF_VON_NGF: 96.68,
    GB_VERKEHRSFL_VF_VON_NGF: 88.12,
    GB_MIETFL_GESAMT: 1234.5,
    GB_MIETFL_1_BSB: 826.06,
    GB_MIETFL_2_DRITTE: 270.17,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990291,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.564583',
    GB_LAENGENGRAD_OESTLICH: '9.906778',
    GB_NETTOGRUNDFL_NGF: 587.97,
    GB_NUTZFL_NF_VON_NGF: 556.6,
    GB_TECHNIKFL_TF_VON_NGF: 21.94,
    GB_VERKEHRSFL_VF_VON_NGF: 9.43,
    GB_MIETFL_GESAMT: 587.97,
    GB_MIETFL_1_BSB: 587.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990292,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 48.27,
    GB_NUTZFL_NF_VON_NGF: 48.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.11.20',
    GB_GB_OBJ_ID_PKT: 1990293,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 376.78,
    GB_NUTZFL_NF_VON_NGF: 337.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.2,
    GB_MIETFL_GESAMT: 376.78,
    GB_MIETFL_1_BSB: 376.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990294,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/11',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 56.76,
    GB_NUTZFL_NF_VON_NGF: 56.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 56.76,
    GB_MIETFL_1_BSB: 56.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990295,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702047,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702047',
    GB_SAP_GB_ID_PKF: '7000/702047/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.564166',
    GB_LAENGENGRAD_OESTLICH: '9.904890',
    GB_NETTOGRUNDFL_NGF: 1277.08,
    GB_NUTZFL_NF_VON_NGF: 1015.21,
    GB_TECHNIKFL_TF_VON_NGF: 23.79,
    GB_VERKEHRSFL_VF_VON_NGF: 238.08,
    GB_MIETFL_GESAMT: 1277.08,
    GB_MIETFL_1_BSB: 1255.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400050,
    GB_WE_OBJ_ID_FKT: 1989115,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1610.54,
    GB_NUTZFL_NF_VON_NGF: 1008.13,
    GB_TECHNIKFL_TF_VON_NGF: 81.56,
    GB_VERKEHRSFL_VF_VON_NGF: 520.85,
    GB_MIETFL_GESAMT: 1610.54,
    GB_MIETFL_1_BSB: 1256.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.07.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990150,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 61.1,
    GB_NUTZFL_NF_VON_NGF: 61.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 61.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990151,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1756.82,
    GB_NUTZFL_NF_VON_NGF: 1278.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 478.26,
    GB_MIETFL_GESAMT: 1756.82,
    GB_MIETFL_1_BSB: 1591.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990152,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 478.24,
    GB_NUTZFL_NF_VON_NGF: 376.86,
    GB_TECHNIKFL_TF_VON_NGF: 4.31,
    GB_VERKEHRSFL_VF_VON_NGF: 97.07,
    GB_MIETFL_GESAMT: 478.24,
    GB_MIETFL_1_BSB: 478.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '02.09.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990153,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.568246',
    GB_LAENGENGRAD_OESTLICH: '9.893729',
    GB_NETTOGRUNDFL_NGF: 591.04,
    GB_NUTZFL_NF_VON_NGF: 571.66,
    GB_TECHNIKFL_TF_VON_NGF: 10.14,
    GB_VERKEHRSFL_VF_VON_NGF: 9.24,
    GB_MIETFL_GESAMT: 591.04,
    GB_MIETFL_1_BSB: 591.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990154,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 652.84,
    GB_NUTZFL_NF_VON_NGF: 458.36,
    GB_TECHNIKFL_TF_VON_NGF: 1.07,
    GB_VERKEHRSFL_VF_VON_NGF: 193.41,
    GB_MIETFL_GESAMT: 652.84,
    GB_MIETFL_1_BSB: 652.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990155,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 547.49,
    GB_NUTZFL_NF_VON_NGF: 359.94,
    GB_TECHNIKFL_TF_VON_NGF: 10.15,
    GB_VERKEHRSFL_VF_VON_NGF: 177.4,
    GB_MIETFL_GESAMT: 547.49,
    GB_MIETFL_1_BSB: 547.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990156,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 698.31,
    GB_NUTZFL_NF_VON_NGF: 415.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 282.51,
    GB_MIETFL_GESAMT: 698.31,
    GB_MIETFL_1_BSB: 698.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990157,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 661.7,
    GB_NUTZFL_NF_VON_NGF: 625.75,
    GB_TECHNIKFL_TF_VON_NGF: 15.99,
    GB_VERKEHRSFL_VF_VON_NGF: 19.96,
    GB_MIETFL_GESAMT: 661.7,
    GB_MIETFL_1_BSB: 661.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '10.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5426170,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702031,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702031',
    GB_SAP_GB_ID_PKF: '7000/702031/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 166.22,
    GB_NUTZFL_NF_VON_NGF: 148.64,
    GB_TECHNIKFL_TF_VON_NGF: 3.4,
    GB_VERKEHRSFL_VF_VON_NGF: 14.18,
    GB_MIETFL_GESAMT: 166.22,
    GB_MIETFL_1_BSB: 166.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352716,
    GB_WE_OBJ_ID_FKT: 1989099,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3245.29,
    GB_NUTZFL_NF_VON_NGF: 2845.04,
    GB_TECHNIKFL_TF_VON_NGF: 13.51,
    GB_VERKEHRSFL_VF_VON_NGF: 386.74,
    GB_MIETFL_GESAMT: 3245.29,
    GB_MIETFL_1_BSB: 2453.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990001,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 157.47,
    GB_NUTZFL_NF_VON_NGF: 141.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.14,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990002,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1892,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1073.45,
    GB_NUTZFL_NF_VON_NGF: 677.66,
    GB_TECHNIKFL_TF_VON_NGF: 61.75,
    GB_VERKEHRSFL_VF_VON_NGF: 334.04,
    GB_MIETFL_GESAMT: 1073.45,
    GB_MIETFL_1_BSB: 673.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.03.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990003,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.569745',
    GB_LAENGENGRAD_OESTLICH: '9.907349',
    GB_NETTOGRUNDFL_NGF: 1119.07,
    GB_NUTZFL_NF_VON_NGF: 1057.77,
    GB_TECHNIKFL_TF_VON_NGF: 14.08,
    GB_VERKEHRSFL_VF_VON_NGF: 47.22,
    GB_MIETFL_GESAMT: 1119.07,
    GB_MIETFL_1_BSB: 1119.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990004,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2131.34,
    GB_NUTZFL_NF_VON_NGF: 1681.05,
    GB_TECHNIKFL_TF_VON_NGF: 8.86,
    GB_VERKEHRSFL_VF_VON_NGF: 441.43,
    GB_MIETFL_GESAMT: 2131.34,
    GB_MIETFL_1_BSB: 2125.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990005,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.569297',
    GB_LAENGENGRAD_OESTLICH: '9.908455',
    GB_NETTOGRUNDFL_NGF: 624.94,
    GB_NUTZFL_NF_VON_NGF: 583.96,
    GB_TECHNIKFL_TF_VON_NGF: 22.24,
    GB_VERKEHRSFL_VF_VON_NGF: 18.74,
    GB_MIETFL_GESAMT: 624.94,
    GB_MIETFL_1_BSB: 624.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990006,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 953.16,
    GB_NUTZFL_NF_VON_NGF: 793.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 159.71,
    GB_MIETFL_GESAMT: 953.16,
    GB_MIETFL_1_BSB: 953.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990007,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1892,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1497.65,
    GB_NUTZFL_NF_VON_NGF: 1086.26,
    GB_TECHNIKFL_TF_VON_NGF: 15.29,
    GB_VERKEHRSFL_VF_VON_NGF: 396.1,
    GB_MIETFL_GESAMT: 1497.65,
    GB_MIETFL_1_BSB: 1302.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990008,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 436.93,
    GB_NUTZFL_NF_VON_NGF: 267.8,
    GB_TECHNIKFL_TF_VON_NGF: 66.98,
    GB_VERKEHRSFL_VF_VON_NGF: 102.15,
    GB_MIETFL_GESAMT: 436.93,
    GB_MIETFL_1_BSB: 292.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990009,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 412.61,
    GB_NUTZFL_NF_VON_NGF: 412.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 412.61,
    GB_MIETFL_1_BSB: 412.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990010,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2015.57,
    GB_NUTZFL_NF_VON_NGF: 1580.55,
    GB_TECHNIKFL_TF_VON_NGF: 28.31,
    GB_VERKEHRSFL_VF_VON_NGF: 406.71,
    GB_MIETFL_GESAMT: 2015.57,
    GB_MIETFL_1_BSB: 2009.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '27.06.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401382,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/12',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 348.08,
    GB_NUTZFL_NF_VON_NGF: 332.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.53,
    GB_MIETFL_GESAMT: 348.08,
    GB_MIETFL_1_BSB: 348.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.02.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418553,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702010,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702010',
    GB_SAP_GB_ID_PKF: '7000/702010/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.19',
    GB_GB_OBJ_ID_PKT: 4352713,
    GB_WE_OBJ_ID_FKT: 1989078,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1221.03,
    GB_NUTZFL_NF_VON_NGF: 859.26,
    GB_TECHNIKFL_TF_VON_NGF: 59.28,
    GB_VERKEHRSFL_VF_VON_NGF: 302.49,
    GB_MIETFL_GESAMT: 1221.03,
    GB_MIETFL_1_BSB: 1158.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990039,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2695.72,
    GB_NUTZFL_NF_VON_NGF: 1783.16,
    GB_TECHNIKFL_TF_VON_NGF: 183.23,
    GB_VERKEHRSFL_VF_VON_NGF: 729.33,
    GB_MIETFL_GESAMT: 2695.72,
    GB_MIETFL_1_BSB: 2581.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990040,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: '53.564919',
    GB_LAENGENGRAD_OESTLICH: '9.822581',
    GB_NETTOGRUNDFL_NGF: 3300.1,
    GB_NUTZFL_NF_VON_NGF: 2548.35,
    GB_TECHNIKFL_TF_VON_NGF: 68.25,
    GB_VERKEHRSFL_VF_VON_NGF: 683.5,
    GB_MIETFL_GESAMT: 3300.1,
    GB_MIETFL_1_BSB: 3198.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990041,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 624.65,
    GB_NUTZFL_NF_VON_NGF: 595.88,
    GB_TECHNIKFL_TF_VON_NGF: 6.6,
    GB_VERKEHRSFL_VF_VON_NGF: 22.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.15',
    GB_GB_OBJ_ID_PKT: 1990042,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 951.43,
    GB_NUTZFL_NF_VON_NGF: 730.28,
    GB_TECHNIKFL_TF_VON_NGF: 31.13,
    GB_VERKEHRSFL_VF_VON_NGF: 190.02,
    GB_MIETFL_GESAMT: 951.43,
    GB_MIETFL_1_BSB: 916.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990043,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.14,
    GB_NUTZFL_NF_VON_NGF: 362.84,
    GB_TECHNIKFL_TF_VON_NGF: 2.14,
    GB_VERKEHRSFL_VF_VON_NGF: 87.16,
    GB_MIETFL_GESAMT: 452.14,
    GB_MIETFL_1_BSB: 452.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990044,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: '53.565197',
    GB_LAENGENGRAD_OESTLICH: '9.824539',
    GB_NETTOGRUNDFL_NGF: 529.13,
    GB_NUTZFL_NF_VON_NGF: 505.44,
    GB_TECHNIKFL_TF_VON_NGF: 14.94,
    GB_VERKEHRSFL_VF_VON_NGF: 8.75,
    GB_MIETFL_GESAMT: 529.13,
    GB_MIETFL_1_BSB: 529.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990045,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 133.22,
    GB_NUTZFL_NF_VON_NGF: 98.22,
    GB_TECHNIKFL_TF_VON_NGF: 7.74,
    GB_VERKEHRSFL_VF_VON_NGF: 27.26,
    GB_MIETFL_GESAMT: 133.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990046,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.0,
    GB_NUTZFL_NF_VON_NGF: 12.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990047,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 166.0,
    GB_NUTZFL_NF_VON_NGF: 166.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990048,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990049,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990050,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.11,
    GB_NUTZFL_NF_VON_NGF: 394.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 81.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990051,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 133.23,
    GB_NUTZFL_NF_VON_NGF: 70.14,
    GB_TECHNIKFL_TF_VON_NGF: 49.74,
    GB_VERKEHRSFL_VF_VON_NGF: 13.35,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 1990052,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3240.09,
    GB_NUTZFL_NF_VON_NGF: 2517.11,
    GB_TECHNIKFL_TF_VON_NGF: 20.68,
    GB_VERKEHRSFL_VF_VON_NGF: 702.3,
    GB_MIETFL_GESAMT: 3240.09,
    GB_MIETFL_1_BSB: 3228.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.05.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400486,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/16',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.565352',
    GB_LAENGENGRAD_OESTLICH: '9.822905',
    GB_NETTOGRUNDFL_NGF: 831.52,
    GB_NUTZFL_NF_VON_NGF: 668.97,
    GB_TECHNIKFL_TF_VON_NGF: 30.5,
    GB_VERKEHRSFL_VF_VON_NGF: 132.05,
    GB_MIETFL_GESAMT: 831.52,
    GB_MIETFL_1_BSB: 831.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '26.08.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401383,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/17',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7015703,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1133.53,
    GB_NUTZFL_NF_VON_NGF: 801.6,
    GB_TECHNIKFL_TF_VON_NGF: 11.55,
    GB_VERKEHRSFL_VF_VON_NGF: 320.38,
    GB_MIETFL_GESAMT: 1133.53,
    GB_MIETFL_1_BSB: 1124.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.10.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7068144,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.16',
    GB_GB_OBJ_ID_PKT: 5306671,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 996.4,
    GB_NUTZFL_NF_VON_NGF: 878.77,
    GB_TECHNIKFL_TF_VON_NGF: 8.11,
    GB_VERKEHRSFL_VF_VON_NGF: 109.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.16',
    GB_GB_OBJ_ID_PKT: 5400487,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702016,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/702016',
    GB_SAP_GB_ID_PKF: '7000/702016/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 510.88,
    GB_NUTZFL_NF_VON_NGF: 439.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.64,
    GB_MIETFL_GESAMT: 78.05,
    GB_MIETFL_1_BSB: 78.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6936735,
    GB_WE_OBJ_ID_FKT: 1989084,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702020',
    GB_SAP_GB_ID_PKF: '7000/702020/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 306.38,
    GB_NUTZFL_NF_VON_NGF: 275.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.23',
    GB_GB_OBJ_ID_PKT: 1990069,
    GB_WE_OBJ_ID_FKT: 1989088,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702020',
    GB_SAP_GB_ID_PKF: '7000/702020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 523.78,
    GB_NUTZFL_NF_VON_NGF: 383.39,
    GB_TECHNIKFL_TF_VON_NGF: 15.43,
    GB_VERKEHRSFL_VF_VON_NGF: 124.96,
    GB_MIETFL_GESAMT: 523.78,
    GB_MIETFL_1_BSB: 523.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.06.18',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990070,
    GB_WE_OBJ_ID_FKT: 1989088,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702020',
    GB_SAP_GB_ID_PKF: '7000/702020/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 80.4,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 71.63,
    GB_VERKEHRSFL_VF_VON_NGF: 8.77,
    GB_MIETFL_GESAMT: 80.4,
    GB_MIETFL_1_BSB: 71.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.06.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5447296,
    GB_WE_OBJ_ID_FKT: 1989088,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702020,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702020',
    GB_SAP_GB_ID_PKF: '7000/702020/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7544162,
    GB_WE_OBJ_ID_FKT: 1989088,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702019',
    GB_SAP_GB_ID_PKF: '7000/702019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1875,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1198.56,
    GB_NUTZFL_NF_VON_NGF: 970.88,
    GB_TECHNIKFL_TF_VON_NGF: 32.13,
    GB_VERKEHRSFL_VF_VON_NGF: 195.55,
    GB_MIETFL_GESAMT: 1198.56,
    GB_MIETFL_1_BSB: 1039.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990067,
    GB_WE_OBJ_ID_FKT: 1989087,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702019',
    GB_SAP_GB_ID_PKF: '7000/702019/2',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1941,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 77.25,
    GB_NUTZFL_NF_VON_NGF: 77.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 77.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990068,
    GB_WE_OBJ_ID_FKT: 1989087,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2218.69,
    GB_NUTZFL_NF_VON_NGF: 1419.77,
    GB_TECHNIKFL_TF_VON_NGF: 70.76,
    GB_VERKEHRSFL_VF_VON_NGF: 728.16,
    GB_MIETFL_GESAMT: 2218.69,
    GB_MIETFL_1_BSB: 1830.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990144,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.564327',
    GB_LAENGENGRAD_OESTLICH: '9.800213',
    GB_NETTOGRUNDFL_NGF: 652.03,
    GB_NUTZFL_NF_VON_NGF: 612.74,
    GB_TECHNIKFL_TF_VON_NGF: 20.0,
    GB_VERKEHRSFL_VF_VON_NGF: 19.29,
    GB_MIETFL_GESAMT: 652.03,
    GB_MIETFL_1_BSB: 623.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.11.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990145,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 55.96,
    GB_NUTZFL_NF_VON_NGF: 55.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 55.96,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990146,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1621.07,
    GB_NUTZFL_NF_VON_NGF: 1111.44,
    GB_TECHNIKFL_TF_VON_NGF: 2.67,
    GB_VERKEHRSFL_VF_VON_NGF: 506.96,
    GB_MIETFL_GESAMT: 1621.07,
    GB_MIETFL_1_BSB: 1621.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.11.09',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990147,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 329.74,
    GB_NUTZFL_NF_VON_NGF: 294.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.03.19',
    GB_GB_OBJ_ID_PKT: 1990148,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.03.19',
    GB_GB_OBJ_ID_PKT: 1990149,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2013.29,
    GB_NUTZFL_NF_VON_NGF: 1720.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 292.61,
    GB_MIETFL_GESAMT: 2013.29,
    GB_MIETFL_1_BSB: 1647.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400488,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 589.79,
    GB_NUTZFL_NF_VON_NGF: 449.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 140.09,
    GB_MIETFL_GESAMT: 589.79,
    GB_MIETFL_1_BSB: 586.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.06.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400489,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702030,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702030',
    GB_SAP_GB_ID_PKF: '7000/702030/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552742,
    GB_WE_OBJ_ID_FKT: 1989098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 1894,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7025.63,
    GB_NUTZFL_NF_VON_NGF: 5265.1,
    GB_TECHNIKFL_TF_VON_NGF: 233.38,
    GB_VERKEHRSFL_VF_VON_NGF: 1527.15,
    GB_MIETFL_GESAMT: 7025.63,
    GB_MIETFL_1_BSB: 6011.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990062,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.54,
    GB_NUTZFL_NF_VON_NGF: 84.86,
    GB_TECHNIKFL_TF_VON_NGF: 6.82,
    GB_VERKEHRSFL_VF_VON_NGF: 28.86,
    GB_MIETFL_GESAMT: 120.54,
    GB_MIETFL_1_BSB: 120.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990063,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 496.24,
    GB_NUTZFL_NF_VON_NGF: 445.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.26,
    GB_MIETFL_GESAMT: 496.24,
    GB_MIETFL_1_BSB: 496.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.04.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990064,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.562995',
    GB_LAENGENGRAD_OESTLICH: '9.806931',
    GB_NETTOGRUNDFL_NGF: 625.09,
    GB_NUTZFL_NF_VON_NGF: 597.48,
    GB_TECHNIKFL_TF_VON_NGF: 6.25,
    GB_VERKEHRSFL_VF_VON_NGF: 21.36,
    GB_MIETFL_GESAMT: 625.09,
    GB_MIETFL_1_BSB: 625.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990065,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: '53.563387',
    GB_LAENGENGRAD_OESTLICH: '9.806895',
    GB_NETTOGRUNDFL_NGF: 738.48,
    GB_NUTZFL_NF_VON_NGF: 504.75,
    GB_TECHNIKFL_TF_VON_NGF: 17.55,
    GB_VERKEHRSFL_VF_VON_NGF: 216.18,
    GB_MIETFL_GESAMT: 738.48,
    GB_MIETFL_1_BSB: 537.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.11.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990066,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702018',
    GB_SAP_GB_ID_PKF: '7000/702018/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 390.13,
    GB_NUTZFL_NF_VON_NGF: 315.33,
    GB_TECHNIKFL_TF_VON_NGF: 8.65,
    GB_VERKEHRSFL_VF_VON_NGF: 66.15,
    GB_MIETFL_GESAMT: 390.13,
    GB_MIETFL_1_BSB: 390.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455624,
    GB_WE_OBJ_ID_FKT: 1989086,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1482.33,
    GB_NUTZFL_NF_VON_NGF: 798.18,
    GB_TECHNIKFL_TF_VON_NGF: 62.22,
    GB_VERKEHRSFL_VF_VON_NGF: 621.93,
    GB_MIETFL_GESAMT: 1482.33,
    GB_MIETFL_1_BSB: 1382.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990246,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1042.85,
    GB_NUTZFL_NF_VON_NGF: 859.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 183.42,
    GB_MIETFL_GESAMT: 1042.85,
    GB_MIETFL_1_BSB: 1023.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990247,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.63,
    GB_NUTZFL_NF_VON_NGF: 268.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 168.92,
    GB_MIETFL_GESAMT: 437.63,
    GB_MIETFL_1_BSB: 437.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990248,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1494.2,
    GB_NUTZFL_NF_VON_NGF: 1031.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 462.64,
    GB_MIETFL_GESAMT: 1494.2,
    GB_MIETFL_1_BSB: 1441.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990249,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 123.05,
    GB_NUTZFL_NF_VON_NGF: 104.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.23,
    GB_MIETFL_GESAMT: 123.05,
    GB_MIETFL_1_BSB: 123.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990250,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 80.38,
    GB_NUTZFL_NF_VON_NGF: 80.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 80.38,
    GB_MIETFL_1_BSB: 80.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990251,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 971.54,
    GB_NUTZFL_NF_VON_NGF: 636.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 335.19,
    GB_MIETFL_GESAMT: 971.54,
    GB_MIETFL_1_BSB: 971.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990252,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 536.62,
    GB_NUTZFL_NF_VON_NGF: 402.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 134.15,
    GB_MIETFL_GESAMT: 536.62,
    GB_MIETFL_1_BSB: 536.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990253,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 129.13,
    GB_NUTZFL_NF_VON_NGF: 129.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 129.13,
    GB_MIETFL_1_BSB: 129.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990254,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 480.33,
    GB_NUTZFL_NF_VON_NGF: 317.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 162.34,
    GB_MIETFL_GESAMT: 480.33,
    GB_MIETFL_1_BSB: 480.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990255,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 195.05,
    GB_NUTZFL_NF_VON_NGF: 176.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.03,
    GB_MIETFL_GESAMT: 195.05,
    GB_MIETFL_1_BSB: 195.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990256,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.570834',
    GB_LAENGENGRAD_OESTLICH: '9.806001',
    GB_NETTOGRUNDFL_NGF: 1971.77,
    GB_NUTZFL_NF_VON_NGF: 1895.66,
    GB_TECHNIKFL_TF_VON_NGF: 20.77,
    GB_VERKEHRSFL_VF_VON_NGF: 55.34,
    GB_MIETFL_GESAMT: 1971.77,
    GB_MIETFL_1_BSB: 1971.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990257,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/13',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 947.61,
    GB_NUTZFL_NF_VON_NGF: 668.57,
    GB_TECHNIKFL_TF_VON_NGF: 39.48,
    GB_VERKEHRSFL_VF_VON_NGF: 239.56,
    GB_MIETFL_GESAMT: 947.61,
    GB_MIETFL_1_BSB: 945.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990258,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 126.67,
    GB_NUTZFL_NF_VON_NGF: 114.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.61,
    GB_MIETFL_GESAMT: 126.67,
    GB_MIETFL_1_BSB: 126.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990259,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 142.95,
    GB_NUTZFL_NF_VON_NGF: 140.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 2.77,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.18',
    GB_GB_OBJ_ID_PKT: 1990260,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/16',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 87.07,
    GB_NUTZFL_NF_VON_NGF: 73.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.54,
    GB_MIETFL_GESAMT: 87.07,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990261,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2053.08,
    GB_NUTZFL_NF_VON_NGF: 1879.15,
    GB_TECHNIKFL_TF_VON_NGF: 19.15,
    GB_VERKEHRSFL_VF_VON_NGF: 154.78,
    GB_MIETFL_GESAMT: 2053.08,
    GB_MIETFL_1_BSB: 2048.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.04.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7007980,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/18',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.571626',
    GB_LAENGENGRAD_OESTLICH: '9.804299',
    GB_NETTOGRUNDFL_NGF: 637.78,
    GB_NUTZFL_NF_VON_NGF: 601.58,
    GB_TECHNIKFL_TF_VON_NGF: 15.86,
    GB_VERKEHRSFL_VF_VON_NGF: 20.34,
    GB_MIETFL_GESAMT: 637.78,
    GB_MIETFL_1_BSB: 637.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7057220,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702043,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702043',
    GB_SAP_GB_ID_PKF: '7000/702043/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.09.20',
    GB_GB_OBJ_ID_PKT: 6928622,
    GB_WE_OBJ_ID_FKT: 1989111,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1809.87,
    GB_NUTZFL_NF_VON_NGF: 1442.44,
    GB_TECHNIKFL_TF_VON_NGF: 51.37,
    GB_VERKEHRSFL_VF_VON_NGF: 316.06,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990396,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 85.08,
    GB_NUTZFL_NF_VON_NGF: 74.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990397,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Aula',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 568.41,
    GB_NUTZFL_NF_VON_NGF: 505.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990398,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990399,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990400,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990401,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990402,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702062,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702062',
    GB_SAP_GB_ID_PKF: '7000/702062/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 38.88,
    GB_NUTZFL_NF_VON_NGF: 35.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.08,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.16',
    GB_GB_OBJ_ID_PKT: 1990403,
    GB_WE_OBJ_ID_FKT: 1989130,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2405.61,
    GB_NUTZFL_NF_VON_NGF: 1605.42,
    GB_TECHNIKFL_TF_VON_NGF: 95.67,
    GB_VERKEHRSFL_VF_VON_NGF: 704.52,
    GB_MIETFL_GESAMT: 2405.61,
    GB_MIETFL_1_BSB: 2305.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776341,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.35,
    GB_NUTZFL_NF_VON_NGF: 116.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.47,
    GB_MIETFL_GESAMT: 143.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776342,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 870.63,
    GB_NUTZFL_NF_VON_NGF: 467.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 403.06,
    GB_MIETFL_GESAMT: 870.63,
    GB_MIETFL_1_BSB: 870.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776343,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 627.14,
    GB_NUTZFL_NF_VON_NGF: 586.37,
    GB_TECHNIKFL_TF_VON_NGF: 21.96,
    GB_VERKEHRSFL_VF_VON_NGF: 18.81,
    GB_MIETFL_GESAMT: 627.14,
    GB_MIETFL_1_BSB: 627.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776344,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 438.29,
    GB_NUTZFL_NF_VON_NGF: 349.4,
    GB_TECHNIKFL_TF_VON_NGF: 3.71,
    GB_VERKEHRSFL_VF_VON_NGF: 85.18,
    GB_MIETFL_GESAMT: 438.29,
    GB_MIETFL_1_BSB: 438.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776345,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 464.91,
    GB_NUTZFL_NF_VON_NGF: 375.13,
    GB_TECHNIKFL_TF_VON_NGF: 3.78,
    GB_VERKEHRSFL_VF_VON_NGF: 86.0,
    GB_MIETFL_GESAMT: 464.91,
    GB_MIETFL_1_BSB: 461.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776346,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/7',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.48,
    GB_NUTZFL_NF_VON_NGF: 34.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 34.48,
    GB_MIETFL_1_BSB: 34.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776347,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 792.47,
    GB_NUTZFL_NF_VON_NGF: 632.03,
    GB_TECHNIKFL_TF_VON_NGF: 7.88,
    GB_VERKEHRSFL_VF_VON_NGF: 152.56,
    GB_MIETFL_GESAMT: 792.47,
    GB_MIETFL_1_BSB: 792.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776348,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 964.99,
    GB_NUTZFL_NF_VON_NGF: 836.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 128.45,
    GB_MIETFL_GESAMT: 964.99,
    GB_MIETFL_1_BSB: 964.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401514,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1271.6,
    GB_NUTZFL_NF_VON_NGF: 1086.99,
    GB_TECHNIKFL_TF_VON_NGF: 7.98,
    GB_VERKEHRSFL_VF_VON_NGF: 176.63,
    GB_MIETFL_GESAMT: 1271.6,
    GB_MIETFL_1_BSB: 1266.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401515,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 637.15,
    GB_NUTZFL_NF_VON_NGF: 604.31,
    GB_TECHNIKFL_TF_VON_NGF: 12.67,
    GB_VERKEHRSFL_VF_VON_NGF: 20.17,
    GB_MIETFL_GESAMT: 637.15,
    GB_MIETFL_1_BSB: 637.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '28.10.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401516,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7057222,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702074,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702074',
    GB_SAP_GB_ID_PKF: '7000/702074/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 286.78,
    GB_NUTZFL_NF_VON_NGF: 224.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 62.35,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '27.10.17',
    GB_GB_OBJ_ID_PKT: 6776349,
    GB_WE_OBJ_ID_FKT: 5290579,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 641.94,
    GB_NUTZFL_NF_VON_NGF: 504.62,
    GB_TECHNIKFL_TF_VON_NGF: 96.11,
    GB_VERKEHRSFL_VF_VON_NGF: 41.21,
    GB_MIETFL_GESAMT: 641.94,
    GB_MIETFL_1_BSB: 545.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.10.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990387,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 128.87,
    GB_NUTZFL_NF_VON_NGF: 106.87,
    GB_TECHNIKFL_TF_VON_NGF: 3.24,
    GB_VERKEHRSFL_VF_VON_NGF: 18.76,
    GB_MIETFL_GESAMT: 128.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990388,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 127.78,
    GB_NUTZFL_NF_VON_NGF: 116.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.16,
    GB_MIETFL_GESAMT: 127.78,
    GB_MIETFL_1_BSB: 127.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990389,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1103.76,
    GB_NUTZFL_NF_VON_NGF: 991.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 112.21,
    GB_MIETFL_GESAMT: 1103.76,
    GB_MIETFL_1_BSB: 1041.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.03.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990390,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: '53.567715',
    GB_LAENGENGRAD_OESTLICH: '9.869923',
    GB_NETTOGRUNDFL_NGF: 520.65,
    GB_NUTZFL_NF_VON_NGF: 476.88,
    GB_TECHNIKFL_TF_VON_NGF: 10.12,
    GB_VERKEHRSFL_VF_VON_NGF: 33.65,
    GB_MIETFL_GESAMT: 520.65,
    GB_MIETFL_1_BSB: 520.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.08',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990391,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.567196',
    GB_LAENGENGRAD_OESTLICH: '9.869042',
    GB_NETTOGRUNDFL_NGF: 626.38,
    GB_NUTZFL_NF_VON_NGF: 597.43,
    GB_TECHNIKFL_TF_VON_NGF: 6.71,
    GB_VERKEHRSFL_VF_VON_NGF: 22.24,
    GB_MIETFL_GESAMT: 626.38,
    GB_MIETFL_1_BSB: 626.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990392,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1473.48,
    GB_NUTZFL_NF_VON_NGF: 1182.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 291.17,
    GB_MIETFL_GESAMT: 1473.48,
    GB_MIETFL_1_BSB: 1473.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990393,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.3,
    GB_NUTZFL_NF_VON_NGF: 165.54,
    GB_TECHNIKFL_TF_VON_NGF: 7.9,
    GB_VERKEHRSFL_VF_VON_NGF: 18.86,
    GB_MIETFL_GESAMT: 192.3,
    GB_MIETFL_1_BSB: 192.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990394,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1990395,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 980.7,
    GB_NUTZFL_NF_VON_NGF: 940.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.49,
    GB_MIETFL_GESAMT: 980.7,
    GB_MIETFL_1_BSB: 980.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400822,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.72,
    GB_NUTZFL_NF_VON_NGF: 144.93,
    GB_TECHNIKFL_TF_VON_NGF: 2.75,
    GB_VERKEHRSFL_VF_VON_NGF: 9.04,
    GB_MIETFL_GESAMT: 156.72,
    GB_MIETFL_1_BSB: 156.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307032,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702061,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702061',
    GB_SAP_GB_ID_PKF: '7000/702061/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.07.19',
    GB_GUELTIG_BIS: '31.08.20',
    GB_GB_OBJ_ID_PKT: 7124885,
    GB_WE_OBJ_ID_FKT: 1989129,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 381.35,
    GB_NUTZFL_NF_VON_NGF: 357.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.19,
    GB_MIETFL_GESAMT: 381.35,
    GB_MIETFL_1_BSB: 238.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990053,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 211.87,
    GB_NUTZFL_NF_VON_NGF: 198.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.54,
    GB_MIETFL_GESAMT: 211.87,
    GB_MIETFL_1_BSB: 211.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990054,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 211.94,
    GB_NUTZFL_NF_VON_NGF: 198.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.28,
    GB_MIETFL_GESAMT: 211.94,
    GB_MIETFL_1_BSB: 211.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990055,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1548.81,
    GB_NUTZFL_NF_VON_NGF: 1124.29,
    GB_TECHNIKFL_TF_VON_NGF: 72.86,
    GB_VERKEHRSFL_VF_VON_NGF: 351.66,
    GB_MIETFL_GESAMT: 1548.81,
    GB_MIETFL_1_BSB: 1387.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990056,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 308.8,
    GB_NUTZFL_NF_VON_NGF: 294.29,
    GB_TECHNIKFL_TF_VON_NGF: 0.33,
    GB_VERKEHRSFL_VF_VON_NGF: 14.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1990057,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 307.23,
    GB_NUTZFL_NF_VON_NGF: 235.04,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 65.53,
    GB_MIETFL_GESAMT: 307.23,
    GB_MIETFL_1_BSB: 307.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990058,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1858.79,
    GB_NUTZFL_NF_VON_NGF: 1498.24,
    GB_TECHNIKFL_TF_VON_NGF: 0.93,
    GB_VERKEHRSFL_VF_VON_NGF: 359.62,
    GB_MIETFL_GESAMT: 1858.79,
    GB_MIETFL_1_BSB: 1801.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990059,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/8',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 28.69,
    GB_NUTZFL_NF_VON_NGF: 28.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 28.69,
    GB_MIETFL_1_BSB: 28.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990060,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 72.88,
    GB_NUTZFL_NF_VON_NGF: 72.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 72.88,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990061,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400818,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702017,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702017',
    GB_SAP_GB_ID_PKF: '7000/702017/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 668.64,
    GB_NUTZFL_NF_VON_NGF: 638.64,
    GB_TECHNIKFL_TF_VON_NGF: 13.03,
    GB_VERKEHRSFL_VF_VON_NGF: 16.97,
    GB_MIETFL_GESAMT: 668.64,
    GB_MIETFL_1_BSB: 668.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400819,
    GB_WE_OBJ_ID_FKT: 1989085,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1949,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 427.11,
    GB_NUTZFL_NF_VON_NGF: 351.51,
    GB_TECHNIKFL_TF_VON_NGF: 13.77,
    GB_VERKEHRSFL_VF_VON_NGF: 61.83,
    GB_MIETFL_GESAMT: 427.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990183,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.580466',
    GB_LAENGENGRAD_OESTLICH: '9.811639',
    GB_NETTOGRUNDFL_NGF: 302.82,
    GB_NUTZFL_NF_VON_NGF: 288.36,
    GB_TECHNIKFL_TF_VON_NGF: 6.01,
    GB_VERKEHRSFL_VF_VON_NGF: 8.45,
    GB_MIETFL_GESAMT: 302.82,
    GB_MIETFL_1_BSB: 302.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990184,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.580437',
    GB_LAENGENGRAD_OESTLICH: '9.811079',
    GB_NETTOGRUNDFL_NGF: 579.65,
    GB_NUTZFL_NF_VON_NGF: 530.75,
    GB_TECHNIKFL_TF_VON_NGF: 39.2,
    GB_VERKEHRSFL_VF_VON_NGF: 9.7,
    GB_MIETFL_GESAMT: 579.65,
    GB_MIETFL_1_BSB: 579.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990185,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 834.07,
    GB_NUTZFL_NF_VON_NGF: 706.14,
    GB_TECHNIKFL_TF_VON_NGF: 34.72,
    GB_VERKEHRSFL_VF_VON_NGF: 93.21,
    GB_MIETFL_GESAMT: 834.07,
    GB_MIETFL_1_BSB: 834.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990186,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 85.52,
    GB_NUTZFL_NF_VON_NGF: 9.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.96,
    GB_MIETFL_GESAMT: 85.52,
    GB_MIETFL_1_BSB: 85.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990187,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.27,
    GB_NUTZFL_NF_VON_NGF: 225.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 225.27,
    GB_MIETFL_1_BSB: 225.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990188,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 305.84,
    GB_NUTZFL_NF_VON_NGF: 285.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.12,
    GB_MIETFL_GESAMT: 305.84,
    GB_MIETFL_1_BSB: 305.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990189,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 189.81,
    GB_NUTZFL_NF_VON_NGF: 174.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.73,
    GB_MIETFL_GESAMT: 189.81,
    GB_MIETFL_1_BSB: 189.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990190,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.26,
    GB_NUTZFL_NF_VON_NGF: 137.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.42,
    GB_MIETFL_GESAMT: 173.26,
    GB_MIETFL_1_BSB: 173.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990191,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 227.78,
    GB_NUTZFL_NF_VON_NGF: 202.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.74,
    GB_MIETFL_GESAMT: 227.78,
    GB_MIETFL_1_BSB: 227.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990192,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 427.18,
    GB_NUTZFL_NF_VON_NGF: 318.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.83,
    GB_MIETFL_GESAMT: 427.18,
    GB_MIETFL_1_BSB: 427.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990193,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/12',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 60.8,
    GB_NUTZFL_NF_VON_NGF: 60.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 60.8,
    GB_MIETFL_1_BSB: 60.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990194,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 426.37,
    GB_NUTZFL_NF_VON_NGF: 287.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 139.06,
    GB_MIETFL_GESAMT: 426.37,
    GB_MIETFL_1_BSB: 426.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990195,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/14',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 82.87,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.87,
    GB_MIETFL_GESAMT: 82.87,
    GB_MIETFL_1_BSB: 82.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990196,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/15',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 644.87,
    GB_NUTZFL_NF_VON_NGF: 442.31,
    GB_TECHNIKFL_TF_VON_NGF: 25.56,
    GB_VERKEHRSFL_VF_VON_NGF: 177.0,
    GB_MIETFL_GESAMT: 644.87,
    GB_MIETFL_1_BSB: 619.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990197,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/16',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.37,
    GB_NUTZFL_NF_VON_NGF: 81.09,
    GB_TECHNIKFL_TF_VON_NGF: 5.76,
    GB_VERKEHRSFL_VF_VON_NGF: 5.52,
    GB_MIETFL_GESAMT: 92.37,
    GB_MIETFL_1_BSB: 92.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990198,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/17',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 122.6,
    GB_NUTZFL_NF_VON_NGF: 112.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.44,
    GB_MIETFL_GESAMT: 122.6,
    GB_MIETFL_1_BSB: 122.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990199,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702035,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/702035',
    GB_SAP_GB_ID_PKF: '7000/702035/18',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 851.05,
    GB_NUTZFL_NF_VON_NGF: 610.5,
    GB_TECHNIKFL_TF_VON_NGF: 97.89,
    GB_VERKEHRSFL_VF_VON_NGF: 142.66,
    GB_MIETFL_GESAMT: 851.05,
    GB_MIETFL_1_BSB: 441.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990200,
    GB_WE_OBJ_ID_FKT: 1989103,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 581.05,
    GB_NUTZFL_NF_VON_NGF: 503.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 77.79,
    GB_MIETFL_GESAMT: 581.05,
    GB_MIETFL_1_BSB: 581.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990333,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.89,
    GB_NUTZFL_NF_VON_NGF: 14.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.89,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990334,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9.97,
    GB_NUTZFL_NF_VON_NGF: 9.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 9.97,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990335,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 149.35,
    GB_NUTZFL_NF_VON_NGF: 122.04,
    GB_TECHNIKFL_TF_VON_NGF: 6.83,
    GB_VERKEHRSFL_VF_VON_NGF: 20.48,
    GB_MIETFL_GESAMT: 149.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990336,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 287.95,
    GB_NUTZFL_NF_VON_NGF: 287.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 287.95,
    GB_MIETFL_1_BSB: 287.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990337,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1568.62,
    GB_NUTZFL_NF_VON_NGF: 1149.5,
    GB_TECHNIKFL_TF_VON_NGF: 110.24,
    GB_VERKEHRSFL_VF_VON_NGF: 308.88,
    GB_MIETFL_GESAMT: 1568.62,
    GB_MIETFL_1_BSB: 1452.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990338,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.85,
    GB_NUTZFL_NF_VON_NGF: 385.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 21.56,
    GB_MIETFL_GESAMT: 406.85,
    GB_MIETFL_1_BSB: 406.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990339,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.4,
    GB_NUTZFL_NF_VON_NGF: 385.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 21.14,
    GB_MIETFL_GESAMT: 406.4,
    GB_MIETFL_1_BSB: 406.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990340,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702053,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702053',
    GB_SAP_GB_ID_PKF: '7000/702053/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.577357',
    GB_LAENGENGRAD_OESTLICH: '9.826545',
    GB_NETTOGRUNDFL_NGF: 622.52,
    GB_NUTZFL_NF_VON_NGF: 589.81,
    GB_TECHNIKFL_TF_VON_NGF: 10.44,
    GB_VERKEHRSFL_VF_VON_NGF: 22.27,
    GB_MIETFL_GESAMT: 622.52,
    GB_MIETFL_1_BSB: 622.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990341,
    GB_WE_OBJ_ID_FKT: 1989121,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702015',
    GB_SAP_GB_ID_PKF: '7000/702015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1652.06,
    GB_NUTZFL_NF_VON_NGF: 1198.02,
    GB_TECHNIKFL_TF_VON_NGF: 67.61,
    GB_VERKEHRSFL_VF_VON_NGF: 386.43,
    GB_MIETFL_GESAMT: 1652.06,
    GB_MIETFL_1_BSB: 1551.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990035,
    GB_WE_OBJ_ID_FKT: 1989083,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702015',
    GB_SAP_GB_ID_PKF: '7000/702015/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 524.75,
    GB_NUTZFL_NF_VON_NGF: 356.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 168.16,
    GB_MIETFL_GESAMT: 524.75,
    GB_MIETFL_1_BSB: 524.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990036,
    GB_WE_OBJ_ID_FKT: 1989083,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702015',
    GB_SAP_GB_ID_PKF: '7000/702015/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 542.58,
    GB_NUTZFL_NF_VON_NGF: 441.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.36,
    GB_MIETFL_GESAMT: 542.58,
    GB_MIETFL_1_BSB: 542.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990037,
    GB_WE_OBJ_ID_FKT: 1989083,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702015',
    GB_SAP_GB_ID_PKF: '7000/702015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.592014',
    GB_LAENGENGRAD_OESTLICH: '9.860217',
    GB_NETTOGRUNDFL_NGF: 628.24,
    GB_NUTZFL_NF_VON_NGF: 593.12,
    GB_TECHNIKFL_TF_VON_NGF: 12.75,
    GB_VERKEHRSFL_VF_VON_NGF: 22.37,
    GB_MIETFL_GESAMT: 628.24,
    GB_MIETFL_1_BSB: 628.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990038,
    GB_WE_OBJ_ID_FKT: 1989083,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 403.51,
    GB_NUTZFL_NF_VON_NGF: 344.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.92,
    GB_MIETFL_GESAMT: 403.51,
    GB_MIETFL_1_BSB: 403.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990071,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 655.61,
    GB_NUTZFL_NF_VON_NGF: 567.53,
    GB_TECHNIKFL_TF_VON_NGF: 15.64,
    GB_VERKEHRSFL_VF_VON_NGF: 72.44,
    GB_MIETFL_GESAMT: 655.61,
    GB_MIETFL_1_BSB: 619.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990072,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 653.71,
    GB_NUTZFL_NF_VON_NGF: 525.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 128.26,
    GB_MIETFL_GESAMT: 653.71,
    GB_MIETFL_1_BSB: 653.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990073,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 316.34,
    GB_NUTZFL_NF_VON_NGF: 285.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.22,
    GB_MIETFL_GESAMT: 316.34,
    GB_MIETFL_1_BSB: 316.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990074,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.592758',
    GB_LAENGENGRAD_OESTLICH: '9.859278',
    GB_NETTOGRUNDFL_NGF: 343.3,
    GB_NUTZFL_NF_VON_NGF: 325.26,
    GB_TECHNIKFL_TF_VON_NGF: 9.48,
    GB_VERKEHRSFL_VF_VON_NGF: 8.56,
    GB_MIETFL_GESAMT: 343.3,
    GB_MIETFL_1_BSB: 343.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990075,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 523.26,
    GB_NUTZFL_NF_VON_NGF: 429.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.69,
    GB_MIETFL_GESAMT: 523.26,
    GB_MIETFL_1_BSB: 523.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990076,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.41,
    GB_NUTZFL_NF_VON_NGF: 130.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.54,
    GB_MIETFL_GESAMT: 140.41,
    GB_MIETFL_1_BSB: 140.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990077,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 210.99,
    GB_NUTZFL_NF_VON_NGF: 181.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.86,
    GB_MIETFL_GESAMT: 210.99,
    GB_MIETFL_1_BSB: 210.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990078,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7023158,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7023159,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7023160,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702021,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702021',
    GB_SAP_GB_ID_PKF: '7000/702021/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7023161,
    GB_WE_OBJ_ID_FKT: 1989089,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1220.16,
    GB_NUTZFL_NF_VON_NGF: 734.65,
    GB_TECHNIKFL_TF_VON_NGF: 123.51,
    GB_VERKEHRSFL_VF_VON_NGF: 362.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.22',
    GB_GB_OBJ_ID_PKT: 1990108,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.22',
    GB_GB_OBJ_ID_PKT: 1990109,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.22',
    GB_GB_OBJ_ID_PKT: 1990110,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 49.89,
    GB_NUTZFL_NF_VON_NGF: 49.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.22',
    GB_GB_OBJ_ID_PKT: 1990112,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.38,
    GB_NUTZFL_NF_VON_NGF: 117.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990113,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990114,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.591678',
    GB_LAENGENGRAD_OESTLICH: '9.856404',
    GB_NETTOGRUNDFL_NGF: 1644.56,
    GB_NUTZFL_NF_VON_NGF: 1553.49,
    GB_TECHNIKFL_TF_VON_NGF: 34.44,
    GB_VERKEHRSFL_VF_VON_NGF: 56.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.22',
    GB_GB_OBJ_ID_PKT: 1990111,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990115,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990116,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990117,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.86,
    GB_NUTZFL_NF_VON_NGF: 178.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 61.41,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990118,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702027,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702027',
    GB_SAP_GB_ID_PKF: '7000/702027/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990119,
    GB_WE_OBJ_ID_FKT: 1989095,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.91,
    GB_NUTZFL_NF_VON_NGF: 350.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 83.25,
    GB_MIETFL_GESAMT: 433.91,
    GB_MIETFL_1_BSB: 315.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990092,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1278.85,
    GB_NUTZFL_NF_VON_NGF: 914.78,
    GB_TECHNIKFL_TF_VON_NGF: 97.89,
    GB_VERKEHRSFL_VF_VON_NGF: 266.18,
    GB_MIETFL_GESAMT: 1278.85,
    GB_MIETFL_1_BSB: 989.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990093,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 688.17,
    GB_NUTZFL_NF_VON_NGF: 644.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.15,
    GB_MIETFL_GESAMT: 688.17,
    GB_MIETFL_1_BSB: 688.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990094,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.89,
    GB_NUTZFL_NF_VON_NGF: 423.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.32,
    GB_MIETFL_GESAMT: 497.89,
    GB_MIETFL_1_BSB: 497.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990095,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1273.29,
    GB_NUTZFL_NF_VON_NGF: 1059.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 214.26,
    GB_MIETFL_GESAMT: 1273.29,
    GB_MIETFL_1_BSB: 1273.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990096,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 419.93,
    GB_NUTZFL_NF_VON_NGF: 376.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.9,
    GB_MIETFL_GESAMT: 419.93,
    GB_MIETFL_1_BSB: 419.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990097,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.601168',
    GB_LAENGENGRAD_OESTLICH: '9.869644',
    GB_NETTOGRUNDFL_NGF: 623.75,
    GB_NUTZFL_NF_VON_NGF: 594.96,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.13,
    GB_MIETFL_GESAMT: 623.75,
    GB_MIETFL_1_BSB: 623.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990098,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.89,
    GB_NUTZFL_NF_VON_NGF: 295.95,
    GB_TECHNIKFL_TF_VON_NGF: 11.04,
    GB_VERKEHRSFL_VF_VON_NGF: 4.9,
    GB_MIETFL_GESAMT: 311.89,
    GB_MIETFL_1_BSB: 311.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.01.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5333624,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702025,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702025',
    GB_SAP_GB_ID_PKF: '7000/702025/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 697.29,
    GB_NUTZFL_NF_VON_NGF: 666.86,
    GB_TECHNIKFL_TF_VON_NGF: 13.65,
    GB_VERKEHRSFL_VF_VON_NGF: 16.78,
    GB_MIETFL_GESAMT: 697.29,
    GB_MIETFL_1_BSB: 697.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5481320,
    GB_WE_OBJ_ID_FKT: 1989093,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702077',
    GB_SAP_GB_ID_PKF: '7000/702077/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12707.23,
    GB_NUTZFL_NF_VON_NGF: 9310.13,
    GB_TECHNIKFL_TF_VON_NGF: 207.82,
    GB_VERKEHRSFL_VF_VON_NGF: 3189.28,
    GB_MIETFL_GESAMT: 12707.23,
    GB_MIETFL_1_BSB: 12289.25,
    GB_MIETFL_2_DRITTE: 341.28,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479589,
    GB_WE_OBJ_ID_FKT: 5479587,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 853.88,
    GB_NUTZFL_NF_VON_NGF: 629.27,
    GB_TECHNIKFL_TF_VON_NGF: 69.35,
    GB_VERKEHRSFL_VF_VON_NGF: 155.26,
    GB_MIETFL_GESAMT: 853.88,
    GB_MIETFL_1_BSB: 763.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990083,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 111.48,
    GB_NUTZFL_NF_VON_NGF: 97.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.24,
    GB_MIETFL_GESAMT: 111.48,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990084,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 299.36,
    GB_NUTZFL_NF_VON_NGF: 289.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 299.36,
    GB_MIETFL_1_BSB: 299.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990085,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 472.51,
    GB_NUTZFL_NF_VON_NGF: 424.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.69,
    GB_MIETFL_GESAMT: 472.51,
    GB_MIETFL_1_BSB: 472.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990086,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.9,
    GB_NUTZFL_NF_VON_NGF: 423.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.03,
    GB_MIETFL_GESAMT: 471.9,
    GB_MIETFL_1_BSB: 471.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990087,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.69,
    GB_NUTZFL_NF_VON_NGF: 384.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.27,
    GB_MIETFL_GESAMT: 439.69,
    GB_MIETFL_1_BSB: 439.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990088,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 442.9,
    GB_NUTZFL_NF_VON_NGF: 387.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.27,
    GB_MIETFL_GESAMT: 442.9,
    GB_MIETFL_1_BSB: 442.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990089,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 442.89,
    GB_NUTZFL_NF_VON_NGF: 387.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.27,
    GB_MIETFL_GESAMT: 442.89,
    GB_MIETFL_1_BSB: 442.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990090,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.608474',
    GB_LAENGENGRAD_OESTLICH: '9.873853',
    GB_NETTOGRUNDFL_NGF: 624.66,
    GB_NUTZFL_NF_VON_NGF: 589.32,
    GB_TECHNIKFL_TF_VON_NGF: 13.29,
    GB_VERKEHRSFL_VF_VON_NGF: 22.05,
    GB_MIETFL_GESAMT: 624.66,
    GB_MIETFL_1_BSB: 624.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990091,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400048,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7430555,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/12',
    GB_SAP_GB_BEZEICHNUNG: 'Freilufthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Freilufthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7430556,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 95.91,
    GB_NUTZFL_NF_VON_NGF: 78.43,
    GB_TECHNIKFL_TF_VON_NGF: 2.74,
    GB_VERKEHRSFL_VF_VON_NGF: 14.74,
    GB_MIETFL_GESAMT: 95.91,
    GB_MIETFL_1_BSB: 95.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5496750,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702024,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702024',
    GB_SAP_GB_ID_PKF: '7000/702024/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 89.34,
    GB_NUTZFL_NF_VON_NGF: 79.18,
    GB_TECHNIKFL_TF_VON_NGF: 3.18,
    GB_VERKEHRSFL_VF_VON_NGF: 6.98,
    GB_MIETFL_GESAMT: 89.34,
    GB_MIETFL_1_BSB: 89.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462186,
    GB_WE_OBJ_ID_FKT: 1989092,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 436.45,
    GB_NUTZFL_NF_VON_NGF: 400.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.58,
    GB_MIETFL_GESAMT: 436.45,
    GB_MIETFL_1_BSB: 436.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990224,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.596826',
    GB_LAENGENGRAD_OESTLICH: '9.890032',
    GB_NETTOGRUNDFL_NGF: 619.78,
    GB_NUTZFL_NF_VON_NGF: 597.78,
    GB_TECHNIKFL_TF_VON_NGF: 2.91,
    GB_VERKEHRSFL_VF_VON_NGF: 19.09,
    GB_MIETFL_GESAMT: 619.78,
    GB_MIETFL_1_BSB: 619.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990225,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2115.43,
    GB_NUTZFL_NF_VON_NGF: 1634.48,
    GB_TECHNIKFL_TF_VON_NGF: 32.32,
    GB_VERKEHRSFL_VF_VON_NGF: 448.63,
    GB_MIETFL_GESAMT: 2115.43,
    GB_MIETFL_1_BSB: 2115.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990226,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.9,
    GB_NUTZFL_NF_VON_NGF: 169.17,
    GB_TECHNIKFL_TF_VON_NGF: 4.83,
    GB_VERKEHRSFL_VF_VON_NGF: 24.9,
    GB_MIETFL_GESAMT: 198.9,
    GB_MIETFL_1_BSB: 198.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990227,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 470.1,
    GB_NUTZFL_NF_VON_NGF: 355.06,
    GB_TECHNIKFL_TF_VON_NGF: 2.96,
    GB_VERKEHRSFL_VF_VON_NGF: 112.08,
    GB_MIETFL_GESAMT: 470.1,
    GB_MIETFL_1_BSB: 470.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990228,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 345.62,
    GB_NUTZFL_NF_VON_NGF: 299.8,
    GB_TECHNIKFL_TF_VON_NGF: 10.8,
    GB_VERKEHRSFL_VF_VON_NGF: 35.02,
    GB_MIETFL_GESAMT: 345.62,
    GB_MIETFL_1_BSB: 345.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990229,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1573.61,
    GB_NUTZFL_NF_VON_NGF: 1125.96,
    GB_TECHNIKFL_TF_VON_NGF: 94.54,
    GB_VERKEHRSFL_VF_VON_NGF: 353.11,
    GB_MIETFL_GESAMT: 1573.61,
    GB_MIETFL_1_BSB: 1479.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990230,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/8',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.24,
    GB_NUTZFL_NF_VON_NGF: 122.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.69,
    GB_MIETFL_GESAMT: 143.24,
    GB_MIETFL_1_BSB: 143.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990231,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702039,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702039',
    GB_SAP_GB_ID_PKF: '7000/702039/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7531744,
    GB_WE_OBJ_ID_FKT: 1989107,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1720.95,
    GB_NUTZFL_NF_VON_NGF: 1337.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 383.12,
    GB_MIETFL_GESAMT: 1720.95,
    GB_MIETFL_1_BSB: 1591.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.09.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989977,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1143.35,
    GB_NUTZFL_NF_VON_NGF: 674.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 469.31,
    GB_MIETFL_GESAMT: 1143.35,
    GB_MIETFL_1_BSB: 1135.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989978,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1598.09,
    GB_NUTZFL_NF_VON_NGF: 1240.24,
    GB_TECHNIKFL_TF_VON_NGF: 79.56,
    GB_VERKEHRSFL_VF_VON_NGF: 278.29,
    GB_MIETFL_GESAMT: 1598.09,
    GB_MIETFL_1_BSB: 1433.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989979,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 133.91,
    GB_NUTZFL_NF_VON_NGF: 84.04,
    GB_TECHNIKFL_TF_VON_NGF: 11.29,
    GB_VERKEHRSFL_VF_VON_NGF: 38.58,
    GB_MIETFL_GESAMT: 133.91,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989980,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 729.56,
    GB_NUTZFL_NF_VON_NGF: 618.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 111.24,
    GB_MIETFL_GESAMT: 729.56,
    GB_MIETFL_1_BSB: 729.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989981,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.590515',
    GB_LAENGENGRAD_OESTLICH: '9.871728',
    GB_NETTOGRUNDFL_NGF: 589.97,
    GB_NUTZFL_NF_VON_NGF: 548.93,
    GB_TECHNIKFL_TF_VON_NGF: 22.21,
    GB_VERKEHRSFL_VF_VON_NGF: 18.83,
    GB_MIETFL_GESAMT: 589.97,
    GB_MIETFL_1_BSB: 589.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989982,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.74,
    GB_NUTZFL_NF_VON_NGF: 14.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989983,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 388.25,
    GB_NUTZFL_NF_VON_NGF: 326.98,
    GB_TECHNIKFL_TF_VON_NGF: 3.07,
    GB_VERKEHRSFL_VF_VON_NGF: 58.2,
    GB_MIETFL_GESAMT: 388.25,
    GB_MIETFL_1_BSB: 386.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5338329,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702007',
    GB_SAP_GB_ID_PKF: '7000/702007/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5424404,
    GB_WE_OBJ_ID_FKT: 1989075,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1600.08,
    GB_NUTZFL_NF_VON_NGF: 1348.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 251.74,
    GB_MIETFL_GESAMT: 1600.08,
    GB_MIETFL_1_BSB: 1523.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.03.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990120,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 183.32,
    GB_NUTZFL_NF_VON_NGF: 151.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.09.18',
    GB_GB_OBJ_ID_PKT: 1990121,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 861.65,
    GB_NUTZFL_NF_VON_NGF: 637.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 224.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.09.18',
    GB_GB_OBJ_ID_PKT: 1990122,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 495.29,
    GB_NUTZFL_NF_VON_NGF: 384.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 111.04,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.09.18',
    GB_GB_OBJ_ID_PKT: 1990123,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2660.95,
    GB_NUTZFL_NF_VON_NGF: 2250.05,
    GB_TECHNIKFL_TF_VON_NGF: 8.75,
    GB_VERKEHRSFL_VF_VON_NGF: 402.15,
    GB_MIETFL_GESAMT: 2660.95,
    GB_MIETFL_1_BSB: 2397.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990124,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.24,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 32.24,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.24,
    GB_MIETFL_1_BSB: 32.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990125,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2845.03,
    GB_NUTZFL_NF_VON_NGF: 2286.66,
    GB_TECHNIKFL_TF_VON_NGF: 122.17,
    GB_VERKEHRSFL_VF_VON_NGF: 436.2,
    GB_MIETFL_GESAMT: 2845.03,
    GB_MIETFL_1_BSB: 2116.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990126,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.597013',
    GB_LAENGENGRAD_OESTLICH: '9.893593',
    GB_NETTOGRUNDFL_NGF: 694.86,
    GB_NUTZFL_NF_VON_NGF: 662.66,
    GB_TECHNIKFL_TF_VON_NGF: 6.71,
    GB_VERKEHRSFL_VF_VON_NGF: 25.49,
    GB_MIETFL_GESAMT: 694.86,
    GB_MIETFL_1_BSB: 694.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990127,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.596952',
    GB_LAENGENGRAD_OESTLICH: '9.894224',
    GB_NETTOGRUNDFL_NGF: 1396.29,
    GB_NUTZFL_NF_VON_NGF: 1366.13,
    GB_TECHNIKFL_TF_VON_NGF: 14.24,
    GB_VERKEHRSFL_VF_VON_NGF: 15.92,
    GB_MIETFL_GESAMT: 1396.29,
    GB_MIETFL_1_BSB: 1396.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990128,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 792.3,
    GB_NUTZFL_NF_VON_NGF: 604.88,
    GB_TECHNIKFL_TF_VON_NGF: 94.63,
    GB_VERKEHRSFL_VF_VON_NGF: 92.79,
    GB_MIETFL_GESAMT: 792.3,
    GB_MIETFL_1_BSB: 792.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.09.07',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990129,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.99,
    GB_NUTZFL_NF_VON_NGF: 34.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 34.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990130,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 333.44,
    GB_NUTZFL_NF_VON_NGF: 333.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.12',
    GB_GB_OBJ_ID_PKT: 1990131,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702028,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702028',
    GB_SAP_GB_ID_PKF: '7000/702028/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 949.52,
    GB_NUTZFL_NF_VON_NGF: 841.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.38,
    GB_MIETFL_GESAMT: 949.52,
    GB_MIETFL_1_BSB: 947.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5419842,
    GB_WE_OBJ_ID_FKT: 1989096,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3568.47,
    GB_NUTZFL_NF_VON_NGF: 2542.0,
    GB_TECHNIKFL_TF_VON_NGF: 78.7,
    GB_VERKEHRSFL_VF_VON_NGF: 947.77,
    GB_MIETFL_GESAMT: 3568.47,
    GB_MIETFL_1_BSB: 3402.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.03.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990099,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 822.27,
    GB_NUTZFL_NF_VON_NGF: 604.17,
    GB_TECHNIKFL_TF_VON_NGF: 117.28,
    GB_VERKEHRSFL_VF_VON_NGF: 100.82,
    GB_MIETFL_GESAMT: 822.27,
    GB_MIETFL_1_BSB: 822.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990100,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 121.96,
    GB_NUTZFL_NF_VON_NGF: 112.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.18,
    GB_MIETFL_GESAMT: 121.96,
    GB_MIETFL_1_BSB: 121.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990101,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1990102,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.599819',
    GB_LAENGENGRAD_OESTLICH: '9.858745',
    GB_NETTOGRUNDFL_NGF: 623.73,
    GB_NUTZFL_NF_VON_NGF: 596.02,
    GB_TECHNIKFL_TF_VON_NGF: 12.49,
    GB_VERKEHRSFL_VF_VON_NGF: 15.22,
    GB_MIETFL_GESAMT: 623.73,
    GB_MIETFL_1_BSB: 623.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990103,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 38.56,
    GB_NUTZFL_NF_VON_NGF: 38.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 38.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990104,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.86,
    GB_NUTZFL_NF_VON_NGF: 14.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.86,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990105,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.44,
    GB_NUTZFL_NF_VON_NGF: 120.7,
    GB_TECHNIKFL_TF_VON_NGF: 11.03,
    GB_VERKEHRSFL_VF_VON_NGF: 7.71,
    GB_MIETFL_GESAMT: 139.44,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990106,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702026,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702026',
    GB_SAP_GB_ID_PKF: '7000/702026/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1990107,
    GB_WE_OBJ_ID_FKT: 1989094,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 613.15,
    GB_NUTZFL_NF_VON_NGF: 545.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.66,
    GB_MIETFL_GESAMT: 613.15,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 613.15,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990368,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.84,
    GB_NUTZFL_NF_VON_NGF: 107.67,
    GB_TECHNIKFL_TF_VON_NGF: 7.84,
    GB_VERKEHRSFL_VF_VON_NGF: 22.33,
    GB_MIETFL_GESAMT: 137.84,
    GB_MIETFL_1_BSB: 81.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990369,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.24,
    GB_NUTZFL_NF_VON_NGF: 178.27,
    GB_TECHNIKFL_TF_VON_NGF: 1.73,
    GB_VERKEHRSFL_VF_VON_NGF: 34.24,
    GB_MIETFL_GESAMT: 214.24,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 214.24,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990370,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 859.02,
    GB_NUTZFL_NF_VON_NGF: 745.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 113.09,
    GB_MIETFL_GESAMT: 859.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 859.02,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990371,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1558.57,
    GB_NUTZFL_NF_VON_NGF: 1142.61,
    GB_TECHNIKFL_TF_VON_NGF: 105.28,
    GB_VERKEHRSFL_VF_VON_NGF: 310.68,
    GB_MIETFL_GESAMT: 1558.57,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1558.57,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990372,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.99,
    GB_NUTZFL_NF_VON_NGF: 441.58,
    GB_TECHNIKFL_TF_VON_NGF: 35.77,
    GB_VERKEHRSFL_VF_VON_NGF: 20.64,
    GB_MIETFL_GESAMT: 497.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 497.99,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990373,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 89.76,
    GB_NUTZFL_NF_VON_NGF: 89.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 89.76,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990374,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.85,
    GB_NUTZFL_NF_VON_NGF: 340.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 92.98,
    GB_MIETFL_GESAMT: 433.85,
    GB_MIETFL_1_BSB: 433.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990375,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.607472',
    GB_LAENGENGRAD_OESTLICH: '9.874723',
    GB_NETTOGRUNDFL_NGF: 623.08,
    GB_NUTZFL_NF_VON_NGF: 594.11,
    GB_TECHNIKFL_TF_VON_NGF: 6.67,
    GB_VERKEHRSFL_VF_VON_NGF: 22.3,
    GB_MIETFL_GESAMT: 623.08,
    GB_MIETFL_1_BSB: 623.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990376,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 28.3,
    GB_NUTZFL_NF_VON_NGF: 28.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 28.3,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990377,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702059,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702059',
    GB_SAP_GB_ID_PKF: '7000/702059/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.26,
    GB_NUTZFL_NF_VON_NGF: 72.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.05,
    GB_MIETFL_GESAMT: 83.26,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 83.26,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990378,
    GB_WE_OBJ_ID_FKT: 1989127,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 689.56,
    GB_NUTZFL_NF_VON_NGF: 576.86,
    GB_TECHNIKFL_TF_VON_NGF: 8.02,
    GB_VERKEHRSFL_VF_VON_NGF: 104.68,
    GB_MIETFL_GESAMT: 689.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989984,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.591251',
    GB_LAENGENGRAD_OESTLICH: '9.885038',
    GB_NETTOGRUNDFL_NGF: 658.74,
    GB_NUTZFL_NF_VON_NGF: 612.79,
    GB_TECHNIKFL_TF_VON_NGF: 17.24,
    GB_VERKEHRSFL_VF_VON_NGF: 28.71,
    GB_MIETFL_GESAMT: 658.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989985,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 458.59,
    GB_NUTZFL_NF_VON_NGF: 398.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.89,
    GB_MIETFL_GESAMT: 458.59,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989986,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 459.05,
    GB_NUTZFL_NF_VON_NGF: 399.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.04,
    GB_MIETFL_GESAMT: 459.05,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989987,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 561.27,
    GB_NUTZFL_NF_VON_NGF: 425.81,
    GB_TECHNIKFL_TF_VON_NGF: 73.13,
    GB_VERKEHRSFL_VF_VON_NGF: 62.33,
    GB_MIETFL_GESAMT: 561.27,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989988,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 458.93,
    GB_NUTZFL_NF_VON_NGF: 398.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.73,
    GB_MIETFL_GESAMT: 458.93,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989989,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.04,
    GB_NUTZFL_NF_VON_NGF: 377.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.99,
    GB_MIETFL_GESAMT: 454.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989990,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 98.7,
    GB_NUTZFL_NF_VON_NGF: 82.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.17,
    GB_MIETFL_GESAMT: 98.7,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989991,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702008,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702008',
    GB_SAP_GB_ID_PKF: '7000/702008/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.20',
    GB_GB_OBJ_ID_PKT: 5892620,
    GB_WE_OBJ_ID_FKT: 1989076,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702050',
    GB_SAP_GB_ID_PKF: '7000/702050/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.551532',
    GB_LAENGENGRAD_OESTLICH: '9.861119',
    GB_NETTOGRUNDFL_NGF: 658.76,
    GB_NUTZFL_NF_VON_NGF: 631.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.51,
    GB_MIETFL_GESAMT: 658.76,
    GB_MIETFL_1_BSB: 658.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990316,
    GB_WE_OBJ_ID_FKT: 1989118,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702050',
    GB_SAP_GB_ID_PKF: '7000/702050/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990317,
    GB_WE_OBJ_ID_FKT: 1989118,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702050,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702050',
    GB_SAP_GB_ID_PKF: '7000/702050/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990318,
    GB_WE_OBJ_ID_FKT: 1989118,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1881,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 808.12,
    GB_NUTZFL_NF_VON_NGF: 697.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 110.22,
    GB_MIETFL_GESAMT: 808.12,
    GB_MIETFL_1_BSB: 737.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990346,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1904.7,
    GB_NUTZFL_NF_VON_NGF: 1524.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 380.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.15',
    GB_GB_OBJ_ID_PKT: 1990347,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: '53.552383',
    GB_LAENGENGRAD_OESTLICH: '9.847092',
    GB_NETTOGRUNDFL_NGF: 410.06,
    GB_NUTZFL_NF_VON_NGF: 335.46,
    GB_TECHNIKFL_TF_VON_NGF: 25.84,
    GB_VERKEHRSFL_VF_VON_NGF: 48.76,
    GB_MIETFL_GESAMT: 410.06,
    GB_MIETFL_1_BSB: 382.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.10.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990348,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 352.79,
    GB_NUTZFL_NF_VON_NGF: 287.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.77,
    GB_MIETFL_GESAMT: 352.79,
    GB_MIETFL_1_BSB: 352.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990349,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 182.18,
    GB_NUTZFL_NF_VON_NGF: 153.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.32,
    GB_MIETFL_GESAMT: 182.18,
    GB_MIETFL_1_BSB: 182.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990350,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 802.14,
    GB_NUTZFL_NF_VON_NGF: 786.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.97,
    GB_MIETFL_GESAMT: 802.14,
    GB_MIETFL_1_BSB: 802.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.10.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990351,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1640.9,
    GB_NUTZFL_NF_VON_NGF: 1226.78,
    GB_TECHNIKFL_TF_VON_NGF: 105.69,
    GB_VERKEHRSFL_VF_VON_NGF: 308.43,
    GB_MIETFL_GESAMT: 1640.9,
    GB_MIETFL_1_BSB: 1435.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401385,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702055,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702055',
    GB_SAP_GB_ID_PKF: '7000/702055/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 4352721,
    GB_WE_OBJ_ID_FKT: 1989123,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702013',
    GB_SAP_GB_ID_PKF: '7000/702013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.583298',
    GB_LAENGENGRAD_OESTLICH: '9.857279',
    GB_NETTOGRUNDFL_NGF: 8698.36,
    GB_NUTZFL_NF_VON_NGF: 6463.29,
    GB_TECHNIKFL_TF_VON_NGF: 277.78,
    GB_VERKEHRSFL_VF_VON_NGF: 1957.29,
    GB_MIETFL_GESAMT: 8698.36,
    GB_MIETFL_1_BSB: 8574.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990029,
    GB_WE_OBJ_ID_FKT: 1989081,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702013',
    GB_SAP_GB_ID_PKF: '7000/702013/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.73,
    GB_NUTZFL_NF_VON_NGF: 33.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.73,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990030,
    GB_WE_OBJ_ID_FKT: 1989081,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702076,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702076',
    GB_SAP_GB_ID_PKF: '7000/702076/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.590737',
    GB_LAENGENGRAD_OESTLICH: '9.857423',
    GB_NETTOGRUNDFL_NGF: 1904.95,
    GB_NUTZFL_NF_VON_NGF: 1730.91,
    GB_TECHNIKFL_TF_VON_NGF: 35.41,
    GB_VERKEHRSFL_VF_VON_NGF: 138.63,
    GB_MIETFL_GESAMT: 1904.95,
    GB_MIETFL_1_BSB: 1904.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5461860,
    GB_WE_OBJ_ID_FKT: 5461810,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 463.26,
    GB_NUTZFL_NF_VON_NGF: 372.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 91.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990018,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702076,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702076',
    GB_SAP_GB_ID_PKF: '7000/702076/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.590137017',
    GB_LAENGENGRAD_OESTLICH: '9.8565952004',
    GB_NETTOGRUNDFL_NGF: 10039.44,
    GB_NUTZFL_NF_VON_NGF: 8197.86,
    GB_TECHNIKFL_TF_VON_NGF: 302.08,
    GB_VERKEHRSFL_VF_VON_NGF: 1539.5,
    GB_MIETFL_GESAMT: 10039.44,
    GB_MIETFL_1_BSB: 9265.6,
    GB_MIETFL_2_DRITTE: 421.12,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5461859,
    GB_WE_OBJ_ID_FKT: 5461810,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990019,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.97,
    GB_NUTZFL_NF_VON_NGF: 344.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 78.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990020,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 669.54,
    GB_NUTZFL_NF_VON_NGF: 550.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 118.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990021,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.01,
    GB_NUTZFL_NF_VON_NGF: 431.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990022,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 242.58,
    GB_NUTZFL_NF_VON_NGF: 214.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.75,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990023,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.588542',
    GB_LAENGENGRAD_OESTLICH: '9.855551',
    GB_NETTOGRUNDFL_NGF: 658.36,
    GB_NUTZFL_NF_VON_NGF: 618.59,
    GB_TECHNIKFL_TF_VON_NGF: 11.15,
    GB_VERKEHRSFL_VF_VON_NGF: 28.62,
    GB_MIETFL_GESAMT: 658.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 658.36,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990024,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990025,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 643.2,
    GB_NUTZFL_NF_VON_NGF: 585.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 57.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990026,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 583.37,
    GB_NUTZFL_NF_VON_NGF: 466.76,
    GB_TECHNIKFL_TF_VON_NGF: 5.0,
    GB_VERKEHRSFL_VF_VON_NGF: 111.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990027,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702012,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702012',
    GB_SAP_GB_ID_PKF: '7000/702012/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990028,
    GB_WE_OBJ_ID_FKT: 1989080,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 627.75,
    GB_NUTZFL_NF_VON_NGF: 511.89,
    GB_TECHNIKFL_TF_VON_NGF: 24.07,
    GB_VERKEHRSFL_VF_VON_NGF: 91.79,
    GB_MIETFL_GESAMT: 627.75,
    GB_MIETFL_1_BSB: 627.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990132,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990133,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.570747',
    GB_LAENGENGRAD_OESTLICH: '9.848503',
    GB_NETTOGRUNDFL_NGF: 623.17,
    GB_NUTZFL_NF_VON_NGF: 581.85,
    GB_TECHNIKFL_TF_VON_NGF: 22.24,
    GB_VERKEHRSFL_VF_VON_NGF: 19.08,
    GB_MIETFL_GESAMT: 623.17,
    GB_MIETFL_1_BSB: 623.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990134,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 347.69,
    GB_NUTZFL_NF_VON_NGF: 284.46,
    GB_TECHNIKFL_TF_VON_NGF: 10.17,
    GB_VERKEHRSFL_VF_VON_NGF: 53.06,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.18',
    GB_GB_OBJ_ID_PKT: 1990135,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1341.86,
    GB_NUTZFL_NF_VON_NGF: 1103.07,
    GB_TECHNIKFL_TF_VON_NGF: 6.3,
    GB_VERKEHRSFL_VF_VON_NGF: 232.49,
    GB_MIETFL_GESAMT: 1341.86,
    GB_MIETFL_1_BSB: 1341.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990136,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1558.42,
    GB_NUTZFL_NF_VON_NGF: 1227.33,
    GB_TECHNIKFL_TF_VON_NGF: 97.85,
    GB_VERKEHRSFL_VF_VON_NGF: 233.24,
    GB_MIETFL_GESAMT: 1558.42,
    GB_MIETFL_1_BSB: 1504.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.09.17',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990137,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 420.15,
    GB_NUTZFL_NF_VON_NGF: 358.93,
    GB_TECHNIKFL_TF_VON_NGF: 4.4,
    GB_VERKEHRSFL_VF_VON_NGF: 56.82,
    GB_MIETFL_GESAMT: 420.15,
    GB_MIETFL_1_BSB: 420.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990138,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 146.55,
    GB_NUTZFL_NF_VON_NGF: 120.4,
    GB_TECHNIKFL_TF_VON_NGF: 1.69,
    GB_VERKEHRSFL_VF_VON_NGF: 24.46,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.08.18',
    GB_GB_OBJ_ID_PKT: 1990139,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 419.43,
    GB_NUTZFL_NF_VON_NGF: 358.47,
    GB_TECHNIKFL_TF_VON_NGF: 4.02,
    GB_VERKEHRSFL_VF_VON_NGF: 56.94,
    GB_MIETFL_GESAMT: 419.43,
    GB_MIETFL_1_BSB: 419.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990140,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/10',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.7,
    GB_NUTZFL_NF_VON_NGF: 13.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.7,
    GB_MIETFL_1_BSB: 13.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990141,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.571205',
    GB_LAENGENGRAD_OESTLICH: '9.849364',
    GB_NETTOGRUNDFL_NGF: 320.9,
    GB_NUTZFL_NF_VON_NGF: 302.28,
    GB_TECHNIKFL_TF_VON_NGF: 7.65,
    GB_VERKEHRSFL_VF_VON_NGF: 10.97,
    GB_MIETFL_GESAMT: 320.9,
    GB_MIETFL_1_BSB: 320.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990142,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/12',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 157.74,
    GB_NUTZFL_NF_VON_NGF: 120.58,
    GB_TECHNIKFL_TF_VON_NGF: 13.97,
    GB_VERKEHRSFL_VF_VON_NGF: 23.19,
    GB_MIETFL_GESAMT: 157.74,
    GB_MIETFL_1_BSB: 108.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990143,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/13',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 480.49,
    GB_NUTZFL_NF_VON_NGF: 463.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.5,
    GB_MIETFL_GESAMT: 480.49,
    GB_MIETFL_1_BSB: 480.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.08',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5334508,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 4352714,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702029,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702029',
    GB_SAP_GB_ID_PKF: '7000/702029/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 4352715,
    GB_WE_OBJ_ID_FKT: 1989097,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1264.81,
    GB_NUTZFL_NF_VON_NGF: 1009.94,
    GB_TECHNIKFL_TF_VON_NGF: 22.14,
    GB_VERKEHRSFL_VF_VON_NGF: 232.73,
    GB_MIETFL_GESAMT: 1264.81,
    GB_MIETFL_1_BSB: 1264.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990238,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 116.99,
    GB_NUTZFL_NF_VON_NGF: 79.45,
    GB_TECHNIKFL_TF_VON_NGF: 17.25,
    GB_VERKEHRSFL_VF_VON_NGF: 20.29,
    GB_MIETFL_GESAMT: 116.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990239,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 258.08,
    GB_NUTZFL_NF_VON_NGF: 205.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.48,
    GB_MIETFL_GESAMT: 258.08,
    GB_MIETFL_1_BSB: 258.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990240,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.573344',
    GB_LAENGENGRAD_OESTLICH: '9.855479',
    GB_NETTOGRUNDFL_NGF: 1719.1,
    GB_NUTZFL_NF_VON_NGF: 1628.48,
    GB_TECHNIKFL_TF_VON_NGF: 33.77,
    GB_VERKEHRSFL_VF_VON_NGF: 56.85,
    GB_MIETFL_GESAMT: 1719.1,
    GB_MIETFL_1_BSB: 1719.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990241,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3141.32,
    GB_NUTZFL_NF_VON_NGF: 2296.49,
    GB_TECHNIKFL_TF_VON_NGF: 72.53,
    GB_VERKEHRSFL_VF_VON_NGF: 772.3,
    GB_MIETFL_GESAMT: 3141.32,
    GB_MIETFL_1_BSB: 2983.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990242,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2882.5,
    GB_NUTZFL_NF_VON_NGF: 2225.45,
    GB_TECHNIKFL_TF_VON_NGF: 178.22,
    GB_VERKEHRSFL_VF_VON_NGF: 478.83,
    GB_MIETFL_GESAMT: 2882.5,
    GB_MIETFL_1_BSB: 2532.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990243,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702041,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702041',
    GB_SAP_GB_ID_PKF: '7000/702041/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1143.97,
    GB_NUTZFL_NF_VON_NGF: 922.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 221.43,
    GB_MIETFL_GESAMT: 1143.97,
    GB_MIETFL_1_BSB: 1143.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990244,
    GB_WE_OBJ_ID_FKT: 1989109,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 918.89,
    GB_NUTZFL_NF_VON_NGF: 674.3,
    GB_TECHNIKFL_TF_VON_NGF: 86.51,
    GB_VERKEHRSFL_VF_VON_NGF: 158.08,
    GB_MIETFL_GESAMT: 918.89,
    GB_MIETFL_1_BSB: 787.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990210,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 385.01,
    GB_NUTZFL_NF_VON_NGF: 47.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 337.57,
    GB_MIETFL_GESAMT: 385.01,
    GB_MIETFL_1_BSB: 385.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990211,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1053.02,
    GB_NUTZFL_NF_VON_NGF: 928.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 124.51,
    GB_MIETFL_GESAMT: 1053.02,
    GB_MIETFL_1_BSB: 1053.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990212,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.54,
    GB_NUTZFL_NF_VON_NGF: 137.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.41,
    GB_MIETFL_GESAMT: 143.54,
    GB_MIETFL_1_BSB: 143.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990213,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.28,
    GB_NUTZFL_NF_VON_NGF: 192.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.18,
    GB_MIETFL_GESAMT: 221.28,
    GB_MIETFL_1_BSB: 221.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990214,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.56,
    GB_NUTZFL_NF_VON_NGF: 192.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.18,
    GB_MIETFL_GESAMT: 221.56,
    GB_MIETFL_1_BSB: 221.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990215,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.95,
    GB_NUTZFL_NF_VON_NGF: 192.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.18,
    GB_MIETFL_GESAMT: 221.95,
    GB_MIETFL_1_BSB: 221.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990216,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.91,
    GB_NUTZFL_NF_VON_NGF: 192.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.23,
    GB_MIETFL_GESAMT: 221.91,
    GB_MIETFL_1_BSB: 221.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990217,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.28,
    GB_NUTZFL_NF_VON_NGF: 387.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.22,
    GB_MIETFL_GESAMT: 469.28,
    GB_MIETFL_1_BSB: 469.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990218,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.23,
    GB_NUTZFL_NF_VON_NGF: 389.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.22,
    GB_MIETFL_GESAMT: 471.23,
    GB_MIETFL_1_BSB: 471.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990219,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.3,
    GB_NUTZFL_NF_VON_NGF: 389.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.22,
    GB_MIETFL_GESAMT: 471.3,
    GB_MIETFL_1_BSB: 471.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990220,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.588361',
    GB_LAENGENGRAD_OESTLICH: '9.845314',
    GB_NETTOGRUNDFL_NGF: 1108.01,
    GB_NUTZFL_NF_VON_NGF: 964.24,
    GB_TECHNIKFL_TF_VON_NGF: 55.88,
    GB_VERKEHRSFL_VF_VON_NGF: 87.89,
    GB_MIETFL_GESAMT: 1108.01,
    GB_MIETFL_1_BSB: 1108.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990221,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/13',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 121.88,
    GB_NUTZFL_NF_VON_NGF: 98.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.92,
    GB_MIETFL_GESAMT: 121.88,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990222,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702038,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702038',
    GB_SAP_GB_ID_PKF: '7000/702038/14',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 204.25,
    GB_NUTZFL_NF_VON_NGF: 169.57,
    GB_TECHNIKFL_TF_VON_NGF: 5.61,
    GB_VERKEHRSFL_VF_VON_NGF: 29.07,
    GB_MIETFL_GESAMT: 204.25,
    GB_MIETFL_1_BSB: 204.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990223,
    GB_WE_OBJ_ID_FKT: 1989106,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 777.63,
    GB_NUTZFL_NF_VON_NGF: 702.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.99,
    GB_MIETFL_GESAMT: 777.63,
    GB_MIETFL_1_BSB: 777.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990379,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.69,
    GB_NUTZFL_NF_VON_NGF: 83.56,
    GB_TECHNIKFL_TF_VON_NGF: 40.25,
    GB_VERKEHRSFL_VF_VON_NGF: 49.88,
    GB_MIETFL_GESAMT: 173.69,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990380,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 128.93,
    GB_NUTZFL_NF_VON_NGF: 118.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.32,
    GB_MIETFL_GESAMT: 128.93,
    GB_MIETFL_1_BSB: 128.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990381,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1683.11,
    GB_NUTZFL_NF_VON_NGF: 1268.75,
    GB_TECHNIKFL_TF_VON_NGF: 66.52,
    GB_VERKEHRSFL_VF_VON_NGF: 347.84,
    GB_MIETFL_GESAMT: 1683.11,
    GB_MIETFL_1_BSB: 1478.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990382,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.12.15',
    GB_GB_OBJ_ID_PKT: 1990383,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 817.09,
    GB_NUTZFL_NF_VON_NGF: 709.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 107.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.12.15',
    GB_GB_OBJ_ID_PKT: 1990384,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.575862',
    GB_LAENGENGRAD_OESTLICH: '9.860867',
    GB_NETTOGRUNDFL_NGF: 624.06,
    GB_NUTZFL_NF_VON_NGF: 588.88,
    GB_TECHNIKFL_TF_VON_NGF: 12.84,
    GB_VERKEHRSFL_VF_VON_NGF: 22.34,
    GB_MIETFL_GESAMT: 624.06,
    GB_MIETFL_1_BSB: 624.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.10.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990385,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.59,
    GB_NUTZFL_NF_VON_NGF: 16.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.59,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990386,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 373.05,
    GB_NUTZFL_NF_VON_NGF: 328.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.05,
    GB_MIETFL_GESAMT: 373.05,
    GB_MIETFL_1_BSB: 373.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5372664,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1536.22,
    GB_NUTZFL_NF_VON_NGF: 1212.17,
    GB_TECHNIKFL_TF_VON_NGF: 17.93,
    GB_VERKEHRSFL_VF_VON_NGF: 306.12,
    GB_MIETFL_GESAMT: 1536.22,
    GB_MIETFL_1_BSB: 1530.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400051,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.575905',
    GB_LAENGENGRAD_OESTLICH: '9.860242',
    GB_NETTOGRUNDFL_NGF: 659.46,
    GB_NUTZFL_NF_VON_NGF: 611.27,
    GB_TECHNIKFL_TF_VON_NGF: 3.92,
    GB_VERKEHRSFL_VF_VON_NGF: 44.27,
    GB_MIETFL_GESAMT: 659.46,
    GB_MIETFL_1_BSB: 659.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400052,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.575866',
    GB_LAENGENGRAD_OESTLICH: '9.860269',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400053,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 322.49,
    GB_NUTZFL_NF_VON_NGF: 297.37,
    GB_TECHNIKFL_TF_VON_NGF: 6.02,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.06.17',
    GB_GB_OBJ_ID_PKT: 4352722,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702060,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702060',
    GB_SAP_GB_ID_PKF: '7000/702060/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.2,
    GB_NUTZFL_NF_VON_NGF: 78.6,
    GB_TECHNIKFL_TF_VON_NGF: 3.1,
    GB_VERKEHRSFL_VF_VON_NGF: 8.5,
    GB_MIETFL_GESAMT: 90.2,
    GB_MIETFL_1_BSB: 90.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '15.07.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7371386,
    GB_WE_OBJ_ID_FKT: 1989128,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 841.55,
    GB_NUTZFL_NF_VON_NGF: 609.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 232.01,
    GB_MIETFL_GESAMT: 841.55,
    GB_MIETFL_1_BSB: 841.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990161,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1560.4,
    GB_NUTZFL_NF_VON_NGF: 956.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 603.43,
    GB_MIETFL_GESAMT: 1560.4,
    GB_MIETFL_1_BSB: 1560.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990162,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 907.0,
    GB_NUTZFL_NF_VON_NGF: 653.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 253.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.07.20',
    GB_GB_OBJ_ID_PKT: 1990163,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 258.74,
    GB_NUTZFL_NF_VON_NGF: 176.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.54,
    GB_MIETFL_GESAMT: 258.74,
    GB_MIETFL_1_BSB: 258.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990164,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1376.64,
    GB_NUTZFL_NF_VON_NGF: 1071.5,
    GB_TECHNIKFL_TF_VON_NGF: 70.44,
    GB_VERKEHRSFL_VF_VON_NGF: 234.7,
    GB_MIETFL_GESAMT: 1376.64,
    GB_MIETFL_1_BSB: 1113.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.11.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990165,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1047.92,
    GB_NUTZFL_NF_VON_NGF: 896.91,
    GB_TECHNIKFL_TF_VON_NGF: 58.2,
    GB_VERKEHRSFL_VF_VON_NGF: 92.81,
    GB_MIETFL_GESAMT: 1047.92,
    GB_MIETFL_1_BSB: 968.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990166,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 434.87,
    GB_NUTZFL_NF_VON_NGF: 338.99,
    GB_TECHNIKFL_TF_VON_NGF: 2.92,
    GB_VERKEHRSFL_VF_VON_NGF: 92.96,
    GB_MIETFL_GESAMT: 434.87,
    GB_MIETFL_1_BSB: 434.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990167,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.554620',
    GB_LAENGENGRAD_OESTLICH: '9.875232',
    GB_NETTOGRUNDFL_NGF: 1528.51,
    GB_NUTZFL_NF_VON_NGF: 1430.38,
    GB_TECHNIKFL_TF_VON_NGF: 9.77,
    GB_VERKEHRSFL_VF_VON_NGF: 88.36,
    GB_MIETFL_GESAMT: 1528.51,
    GB_MIETFL_1_BSB: 1518.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990168,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554740',
    GB_LAENGENGRAD_OESTLICH: '9.876044',
    GB_NETTOGRUNDFL_NGF: 629.52,
    GB_NUTZFL_NF_VON_NGF: 588.57,
    GB_TECHNIKFL_TF_VON_NGF: 22.11,
    GB_VERKEHRSFL_VF_VON_NGF: 18.84,
    GB_MIETFL_GESAMT: 629.52,
    GB_MIETFL_1_BSB: 629.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990169,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.554935',
    GB_LAENGENGRAD_OESTLICH: '9.876524',
    GB_NETTOGRUNDFL_NGF: 551.7,
    GB_NUTZFL_NF_VON_NGF: 522.25,
    GB_TECHNIKFL_TF_VON_NGF: 20.78,
    GB_VERKEHRSFL_VF_VON_NGF: 8.67,
    GB_MIETFL_GESAMT: 551.7,
    GB_MIETFL_1_BSB: 302.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990170,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1760.31,
    GB_NUTZFL_NF_VON_NGF: 1321.18,
    GB_TECHNIKFL_TF_VON_NGF: 12.12,
    GB_VERKEHRSFL_VF_VON_NGF: 427.01,
    GB_MIETFL_GESAMT: 1760.31,
    GB_MIETFL_1_BSB: 1750.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.04.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401384,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/12',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 171.06,
    GB_NUTZFL_NF_VON_NGF: 90.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.47,
    GB_MIETFL_GESAMT: 171.06,
    GB_MIETFL_1_BSB: 171.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '20.11.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7064664,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7148619,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702033,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702033',
    GB_SAP_GB_ID_PKF: '7000/702033/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7385385,
    GB_WE_OBJ_ID_FKT: 1989101,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1795.18,
    GB_NUTZFL_NF_VON_NGF: 1454.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 340.54,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.16',
    GB_GB_OBJ_ID_PKT: 1990158,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1888,
    GB_BREITENGRAD_NOERDLICH: '53.551899',
    GB_LAENGENGRAD_OESTLICH: '9.900998',
    GB_NETTOGRUNDFL_NGF: 3234.84,
    GB_NUTZFL_NF_VON_NGF: 2233.68,
    GB_TECHNIKFL_TF_VON_NGF: 171.81,
    GB_VERKEHRSFL_VF_VON_NGF: 829.35,
    GB_MIETFL_GESAMT: 3234.84,
    GB_MIETFL_1_BSB: 3079.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.07.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990159,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2862.36,
    GB_NUTZFL_NF_VON_NGF: 1698.45,
    GB_TECHNIKFL_TF_VON_NGF: 90.79,
    GB_VERKEHRSFL_VF_VON_NGF: 1073.12,
    GB_MIETFL_GESAMT: 2862.36,
    GB_MIETFL_1_BSB: 2745.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990160,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.16',
    GB_GB_OBJ_ID_PKT: 2138306,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1130.06,
    GB_NUTZFL_NF_VON_NGF: 824.97,
    GB_TECHNIKFL_TF_VON_NGF: 33.5,
    GB_VERKEHRSFL_VF_VON_NGF: 271.59,
    GB_MIETFL_GESAMT: 1130.06,
    GB_MIETFL_1_BSB: 1130.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400490,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1027.56,
    GB_NUTZFL_NF_VON_NGF: 769.38,
    GB_TECHNIKFL_TF_VON_NGF: 7.26,
    GB_VERKEHRSFL_VF_VON_NGF: 250.92,
    GB_MIETFL_GESAMT: 1027.56,
    GB_MIETFL_1_BSB: 942.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5916741,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 780.0,
    GB_NUTZFL_NF_VON_NGF: 780.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.07.18',
    GB_GB_OBJ_ID_PKT: 6936736,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702032,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702032',
    GB_SAP_GB_ID_PKF: '7000/702032/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 112.84,
    GB_NUTZFL_NF_VON_NGF: 95.61,
    GB_TECHNIKFL_TF_VON_NGF: 2.97,
    GB_VERKEHRSFL_VF_VON_NGF: 14.26,
    GB_MIETFL_GESAMT: 112.84,
    GB_MIETFL_1_BSB: 112.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7388282,
    GB_WE_OBJ_ID_FKT: 1989100,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702054',
    GB_SAP_GB_ID_PKF: '7000/702054/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5888.04,
    GB_NUTZFL_NF_VON_NGF: 4360.54,
    GB_TECHNIKFL_TF_VON_NGF: 204.35,
    GB_VERKEHRSFL_VF_VON_NGF: 1323.15,
    GB_MIETFL_GESAMT: 5888.04,
    GB_MIETFL_1_BSB: 5492.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990342,
    GB_WE_OBJ_ID_FKT: 1989122,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702054,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702054',
    GB_SAP_GB_ID_PKF: '7000/702054/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 65.76,
    GB_NUTZFL_NF_VON_NGF: 56.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.0,
    GB_MIETFL_GESAMT: 65.76,
    GB_MIETFL_1_BSB: 65.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990343,
    GB_WE_OBJ_ID_FKT: 1989122,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702054,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702054',
    GB_SAP_GB_ID_PKF: '7000/702054/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.552987',
    GB_LAENGENGRAD_OESTLICH: '9.899752',
    GB_NETTOGRUNDFL_NGF: 1381.99,
    GB_NUTZFL_NF_VON_NGF: 1179.36,
    GB_TECHNIKFL_TF_VON_NGF: 17.13,
    GB_VERKEHRSFL_VF_VON_NGF: 185.5,
    GB_MIETFL_GESAMT: 1381.99,
    GB_MIETFL_1_BSB: 1381.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990344,
    GB_WE_OBJ_ID_FKT: 1989122,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702054,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702054',
    GB_SAP_GB_ID_PKF: '7000/702054/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2661.71,
    GB_NUTZFL_NF_VON_NGF: 1894.78,
    GB_TECHNIKFL_TF_VON_NGF: 21.43,
    GB_VERKEHRSFL_VF_VON_NGF: 745.5,
    GB_MIETFL_GESAMT: 2661.71,
    GB_MIETFL_1_BSB: 2651.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990345,
    GB_WE_OBJ_ID_FKT: 1989122,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702054,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702054',
    GB_SAP_GB_ID_PKF: '7000/702054/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Musikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Musikgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.13',
    GB_GB_OBJ_ID_PKT: 5309262,
    GB_WE_OBJ_ID_FKT: 1989122,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7061458,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.553142',
    GB_LAENGENGRAD_OESTLICH: '9.883207',
    GB_NETTOGRUNDFL_NGF: 6216.79,
    GB_NUTZFL_NF_VON_NGF: 4576.11,
    GB_TECHNIKFL_TF_VON_NGF: 101.84,
    GB_VERKEHRSFL_VF_VON_NGF: 1538.84,
    GB_MIETFL_GESAMT: 6216.79,
    GB_MIETFL_1_BSB: 6123.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990204,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 576.94,
    GB_NUTZFL_NF_VON_NGF: 342.85,
    GB_TECHNIKFL_TF_VON_NGF: 103.91,
    GB_VERKEHRSFL_VF_VON_NGF: 130.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990205,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 119.1,
    GB_NUTZFL_NF_VON_NGF: 83.81,
    GB_TECHNIKFL_TF_VON_NGF: 12.18,
    GB_VERKEHRSFL_VF_VON_NGF: 23.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990206,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990207,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990209,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702037,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702037',
    GB_SAP_GB_ID_PKF: '7000/702037/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet,',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 260.0,
    GB_NUTZFL_NF_VON_NGF: 260.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 5171087,
    GB_WE_OBJ_ID_FKT: 1989105,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1309.03,
    GB_NUTZFL_NF_VON_NGF: 990.41,
    GB_TECHNIKFL_TF_VON_NGF: 30.5,
    GB_VERKEHRSFL_VF_VON_NGF: 288.12,
    GB_MIETFL_GESAMT: 1309.03,
    GB_MIETFL_1_BSB: 1265.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990304,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.552189',
    GB_LAENGENGRAD_OESTLICH: '9.903139',
    GB_NETTOGRUNDFL_NGF: 595.25,
    GB_NUTZFL_NF_VON_NGF: 564.3,
    GB_TECHNIKFL_TF_VON_NGF: 21.64,
    GB_VERKEHRSFL_VF_VON_NGF: 9.31,
    GB_MIETFL_GESAMT: 595.25,
    GB_MIETFL_1_BSB: 595.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990305,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.56,
    GB_NUTZFL_NF_VON_NGF: 175.43,
    GB_TECHNIKFL_TF_VON_NGF: 7.53,
    GB_VERKEHRSFL_VF_VON_NGF: 32.6,
    GB_MIETFL_GESAMT: 215.56,
    GB_MIETFL_1_BSB: 215.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990306,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 130.58,
    GB_NUTZFL_NF_VON_NGF: 118.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.67,
    GB_MIETFL_GESAMT: 130.58,
    GB_MIETFL_1_BSB: 130.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990307,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 464.45,
    GB_NUTZFL_NF_VON_NGF: 435.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.89,
    GB_MIETFL_GESAMT: 464.45,
    GB_MIETFL_1_BSB: 464.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990308,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1990309,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1219.98,
    GB_NUTZFL_NF_VON_NGF: 981.04,
    GB_TECHNIKFL_TF_VON_NGF: 63.59,
    GB_VERKEHRSFL_VF_VON_NGF: 175.35,
    GB_MIETFL_GESAMT: 1219.98,
    GB_MIETFL_1_BSB: 997.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990310,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 283.17,
    GB_NUTZFL_NF_VON_NGF: 253.72,
    GB_TECHNIKFL_TF_VON_NGF: 1.34,
    GB_VERKEHRSFL_VF_VON_NGF: 28.11,
    GB_MIETFL_GESAMT: 283.17,
    GB_MIETFL_1_BSB: 176.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990311,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 317.29,
    GB_NUTZFL_NF_VON_NGF: 283.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.43,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.10.20',
    GB_GB_OBJ_ID_PKT: 1990312,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 285.06,
    GB_NUTZFL_NF_VON_NGF: 247.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.65,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.08.14',
    GB_GB_OBJ_ID_PKT: 1990314,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990315,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle mit Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle mit Sporthalle',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.552744',
    GB_LAENGENGRAD_OESTLICH: '9.902321',
    GB_NETTOGRUNDFL_NGF: 622.52,
    GB_NUTZFL_NF_VON_NGF: 581.3,
    GB_TECHNIKFL_TF_VON_NGF: 24.31,
    GB_VERKEHRSFL_VF_VON_NGF: 16.91,
    GB_MIETFL_GESAMT: 622.52,
    GB_MIETFL_1_BSB: 622.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990313,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.552258082',
    GB_LAENGENGRAD_OESTLICH: '9.9025777485',
    GB_NETTOGRUNDFL_NGF: 1812.07,
    GB_NUTZFL_NF_VON_NGF: 1288.75,
    GB_TECHNIKFL_TF_VON_NGF: 44.63,
    GB_VERKEHRSFL_VF_VON_NGF: 478.69,
    GB_MIETFL_GESAMT: 1812.07,
    GB_MIETFL_1_BSB: 1807.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.11.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5891495,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702049,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702049',
    GB_SAP_GB_ID_PKF: '7000/702049/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 691.86,
    GB_NUTZFL_NF_VON_NGF: 428.34,
    GB_TECHNIKFL_TF_VON_NGF: 4.02,
    GB_VERKEHRSFL_VF_VON_NGF: 259.5,
    GB_MIETFL_GESAMT: 691.86,
    GB_MIETFL_1_BSB: 691.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7028580,
    GB_WE_OBJ_ID_FKT: 1989117,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702023',
    GB_SAP_GB_ID_PKF: '7000/702023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.557852',
    GB_LAENGENGRAD_OESTLICH: '9.872439',
    GB_NETTOGRUNDFL_NGF: 14727.55,
    GB_NUTZFL_NF_VON_NGF: 11118.93,
    GB_TECHNIKFL_TF_VON_NGF: 495.1,
    GB_VERKEHRSFL_VF_VON_NGF: 3113.52,
    GB_MIETFL_GESAMT: 14727.55,
    GB_MIETFL_1_BSB: 13599.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990082,
    GB_WE_OBJ_ID_FKT: 1989091,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800478,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702079',
    GB_SAP_GB_ID_PKF: '7000/702079/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6996164,
    GB_WE_OBJ_ID_FKT: 6996162,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702079,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702079',
    GB_SAP_GB_ID_PKF: '7000/702079/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6996165,
    GB_WE_OBJ_ID_FKT: 6996162,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702079,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702079',
    GB_SAP_GB_ID_PKF: '7000/702079/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7248652,
    GB_WE_OBJ_ID_FKT: 6996162,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702079,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702079',
    GB_SAP_GB_ID_PKF: '7000/702079/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7248653,
    GB_WE_OBJ_ID_FKT: 6996162,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702079,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702079',
    GB_SAP_GB_ID_PKF: '7000/702079/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7371671,
    GB_WE_OBJ_ID_FKT: 6996162,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 781.82,
    GB_NUTZFL_NF_VON_NGF: 633.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 148.32,
    GB_MIETFL_GESAMT: 781.82,
    GB_MIETFL_1_BSB: 781.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990361,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 129.25,
    GB_NUTZFL_NF_VON_NGF: 98.16,
    GB_TECHNIKFL_TF_VON_NGF: 8.04,
    GB_VERKEHRSFL_VF_VON_NGF: 23.05,
    GB_MIETFL_GESAMT: 129.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990362,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.547666',
    GB_LAENGENGRAD_OESTLICH: '9.908753',
    GB_NETTOGRUNDFL_NGF: 628.73,
    GB_NUTZFL_NF_VON_NGF: 603.8,
    GB_TECHNIKFL_TF_VON_NGF: 5.82,
    GB_VERKEHRSFL_VF_VON_NGF: 19.11,
    GB_MIETFL_GESAMT: 628.73,
    GB_MIETFL_1_BSB: 628.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990363,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 967.2,
    GB_NUTZFL_NF_VON_NGF: 844.38,
    GB_TECHNIKFL_TF_VON_NGF: 5.31,
    GB_VERKEHRSFL_VF_VON_NGF: 117.51,
    GB_MIETFL_GESAMT: 967.2,
    GB_MIETFL_1_BSB: 967.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990364,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1430.59,
    GB_NUTZFL_NF_VON_NGF: 1210.6,
    GB_TECHNIKFL_TF_VON_NGF: 63.67,
    GB_VERKEHRSFL_VF_VON_NGF: 156.32,
    GB_MIETFL_GESAMT: 1430.59,
    GB_MIETFL_1_BSB: 975.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990365,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 479.04,
    GB_NUTZFL_NF_VON_NGF: 479.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 479.04,
    GB_MIETFL_1_BSB: 479.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990366,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/7',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.547764',
    GB_LAENGENGRAD_OESTLICH: '9.909027',
    GB_NETTOGRUNDFL_NGF: 1773.31,
    GB_NUTZFL_NF_VON_NGF: 1549.03,
    GB_TECHNIKFL_TF_VON_NGF: 8.5,
    GB_VERKEHRSFL_VF_VON_NGF: 215.78,
    GB_MIETFL_GESAMT: 1773.31,
    GB_MIETFL_1_BSB: 1771.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400821,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702058,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702058',
    GB_SAP_GB_ID_PKF: '7000/702058/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7057221,
    GB_WE_OBJ_ID_FKT: 1989126,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.553783',
    GB_LAENGENGRAD_OESTLICH: '9.884240',
    GB_NETTOGRUNDFL_NGF: 3837.78,
    GB_NUTZFL_NF_VON_NGF: 2737.1,
    GB_TECHNIKFL_TF_VON_NGF: 83.39,
    GB_VERKEHRSFL_VF_VON_NGF: 1017.29,
    GB_MIETFL_GESAMT: 3837.78,
    GB_MIETFL_1_BSB: 3505.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990296,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.553789',
    GB_LAENGENGRAD_OESTLICH: '9.883317',
    GB_NETTOGRUNDFL_NGF: 929.94,
    GB_NUTZFL_NF_VON_NGF: 835.46,
    GB_TECHNIKFL_TF_VON_NGF: 35.96,
    GB_VERKEHRSFL_VF_VON_NGF: 58.52,
    GB_MIETFL_GESAMT: 929.94,
    GB_MIETFL_1_BSB: 887.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990297,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.554029',
    GB_LAENGENGRAD_OESTLICH: '9.882834',
    GB_NETTOGRUNDFL_NGF: 1438.41,
    GB_NUTZFL_NF_VON_NGF: 1137.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 301.31,
    GB_MIETFL_GESAMT: 1438.41,
    GB_MIETFL_1_BSB: 1438.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990298,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.558088',
    GB_LAENGENGRAD_OESTLICH: '9.927654',
    GB_NETTOGRUNDFL_NGF: 593.66,
    GB_NUTZFL_NF_VON_NGF: 562.04,
    GB_TECHNIKFL_TF_VON_NGF: 22.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.61,
    GB_MIETFL_GESAMT: 593.66,
    GB_MIETFL_1_BSB: 593.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990015,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.554313',
    GB_LAENGENGRAD_OESTLICH: '9.882641',
    GB_NETTOGRUNDFL_NGF: 362.75,
    GB_NUTZFL_NF_VON_NGF: 304.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.44,
    GB_MIETFL_GESAMT: 362.75,
    GB_MIETFL_1_BSB: 362.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.05.23',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990299,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.554316',
    GB_LAENGENGRAD_OESTLICH: '9.883176',
    GB_NETTOGRUNDFL_NGF: 369.19,
    GB_NUTZFL_NF_VON_NGF: 310.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.24,
    GB_MIETFL_GESAMT: 369.19,
    GB_MIETFL_1_BSB: 369.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990300,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.554498316',
    GB_LAENGENGRAD_OESTLICH: '9.8833479324',
    GB_NETTOGRUNDFL_NGF: 425.69,
    GB_NUTZFL_NF_VON_NGF: 345.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.02,
    GB_MIETFL_GESAMT: 425.69,
    GB_MIETFL_1_BSB: 425.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990301,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.554275',
    GB_LAENGENGRAD_OESTLICH: '9.883865',
    GB_NETTOGRUNDFL_NGF: 659.55,
    GB_NUTZFL_NF_VON_NGF: 613.67,
    GB_TECHNIKFL_TF_VON_NGF: 16.47,
    GB_VERKEHRSFL_VF_VON_NGF: 29.41,
    GB_MIETFL_GESAMT: 659.55,
    GB_MIETFL_1_BSB: 659.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990302,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.554309',
    GB_LAENGENGRAD_OESTLICH: '9.884433',
    GB_NETTOGRUNDFL_NGF: 824.94,
    GB_NUTZFL_NF_VON_NGF: 604.08,
    GB_TECHNIKFL_TF_VON_NGF: 5.89,
    GB_VERKEHRSFL_VF_VON_NGF: 214.97,
    GB_MIETFL_GESAMT: 824.94,
    GB_MIETFL_1_BSB: 824.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990303,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702048,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702048',
    GB_SAP_GB_ID_PKF: '7000/702048/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.553611',
    GB_LAENGENGRAD_OESTLICH: '9.883828',
    GB_NETTOGRUNDFL_NGF: 401.15,
    GB_NUTZFL_NF_VON_NGF: 307.04,
    GB_TECHNIKFL_TF_VON_NGF: 8.55,
    GB_VERKEHRSFL_VF_VON_NGF: 85.56,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '25.04.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '11.05.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462933,
    GB_WE_OBJ_ID_FKT: 1989116,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702063',
    GB_SAP_GB_ID_PKF: '7000/702063/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1960.19,
    GB_NUTZFL_NF_VON_NGF: 1534.93,
    GB_TECHNIKFL_TF_VON_NGF: 52.61,
    GB_VERKEHRSFL_VF_VON_NGF: 372.65,
    GB_MIETFL_GESAMT: 1960.19,
    GB_MIETFL_1_BSB: 1806.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.06.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990404,
    GB_WE_OBJ_ID_FKT: 1989131,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702063,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702063',
    GB_SAP_GB_ID_PKF: '7000/702063/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1896,
    GB_BREITENGRAD_NOERDLICH: '53.550120',
    GB_LAENGENGRAD_OESTLICH: '9.922028',
    GB_NETTOGRUNDFL_NGF: 368.08,
    GB_NUTZFL_NF_VON_NGF: 341.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.28,
    GB_MIETFL_GESAMT: 368.08,
    GB_MIETFL_1_BSB: 368.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990405,
    GB_WE_OBJ_ID_FKT: 1989131,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702063,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702063',
    GB_SAP_GB_ID_PKF: '7000/702063/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.86,
    GB_NUTZFL_NF_VON_NGF: 14.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.86,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990406,
    GB_WE_OBJ_ID_FKT: 1989131,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4860.16,
    GB_NUTZFL_NF_VON_NGF: 3235.73,
    GB_TECHNIKFL_TF_VON_NGF: 38.17,
    GB_VERKEHRSFL_VF_VON_NGF: 1586.26,
    GB_MIETFL_GESAMT: 4860.16,
    GB_MIETFL_1_BSB: 4482.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990281,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.559373',
    GB_LAENGENGRAD_OESTLICH: '9.921429',
    GB_NETTOGRUNDFL_NGF: 1493.57,
    GB_NUTZFL_NF_VON_NGF: 1261.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 232.24,
    GB_MIETFL_GESAMT: 1493.57,
    GB_MIETFL_1_BSB: 1493.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990282,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 376.54,
    GB_NUTZFL_NF_VON_NGF: 276.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 100.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.08.18',
    GB_GB_OBJ_ID_PKT: 1990283,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: '53.558769',
    GB_LAENGENGRAD_OESTLICH: '9.921808',
    GB_NETTOGRUNDFL_NGF: 772.6,
    GB_NUTZFL_NF_VON_NGF: 697.1,
    GB_TECHNIKFL_TF_VON_NGF: 11.16,
    GB_VERKEHRSFL_VF_VON_NGF: 64.34,
    GB_MIETFL_GESAMT: 772.6,
    GB_MIETFL_1_BSB: 657.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990284,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.7,
    GB_NUTZFL_NF_VON_NGF: 15.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.7,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352719,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1025.74,
    GB_NUTZFL_NF_VON_NGF: 681.23,
    GB_TECHNIKFL_TF_VON_NGF: 20.38,
    GB_VERKEHRSFL_VF_VON_NGF: 324.13,
    GB_MIETFL_GESAMT: 1025.74,
    GB_MIETFL_1_BSB: 1022.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.10.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400494,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702046,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702046',
    GB_SAP_GB_ID_PKF: '7000/702046/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.18',
    GB_GB_OBJ_ID_PKT: 5068082,
    GB_WE_OBJ_ID_FKT: 1989114,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4125.06,
    GB_NUTZFL_NF_VON_NGF: 2924.78,
    GB_TECHNIKFL_TF_VON_NGF: 84.08,
    GB_VERKEHRSFL_VF_VON_NGF: 1116.2,
    GB_MIETFL_GESAMT: 4125.06,
    GB_MIETFL_1_BSB: 4051.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990011,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 182.8,
    GB_NUTZFL_NF_VON_NGF: 135.83,
    GB_TECHNIKFL_TF_VON_NGF: 18.88,
    GB_VERKEHRSFL_VF_VON_NGF: 28.09,
    GB_MIETFL_GESAMT: 182.8,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990012,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 356.62,
    GB_NUTZFL_NF_VON_NGF: 326.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.19,
    GB_MIETFL_GESAMT: 356.62,
    GB_MIETFL_1_BSB: 356.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.05.14',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990013,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.14,
    GB_NUTZFL_NF_VON_NGF: 695.74,
    GB_TECHNIKFL_TF_VON_NGF: 44.59,
    GB_VERKEHRSFL_VF_VON_NGF: 90.81,
    GB_MIETFL_GESAMT: 831.14,
    GB_MIETFL_1_BSB: 831.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '08.02.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990014,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 51.84,
    GB_NUTZFL_NF_VON_NGF: 51.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 51.84,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990016,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990017,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7057219,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702011,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702011',
    GB_SAP_GB_ID_PKF: '7000/702011/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.47,
    GB_NUTZFL_NF_VON_NGF: 77.56,
    GB_TECHNIKFL_TF_VON_NGF: 12.17,
    GB_VERKEHRSFL_VF_VON_NGF: 14.74,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '10.04.21',
    GB_GB_OBJ_ID_PKT: 7036056,
    GB_WE_OBJ_ID_FKT: 1989079,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1939,
    GB_BREITENGRAD_NOERDLICH: '53.552092',
    GB_LAENGENGRAD_OESTLICH: '9.916355',
    GB_NETTOGRUNDFL_NGF: 6989.36,
    GB_NUTZFL_NF_VON_NGF: 4876.18,
    GB_TECHNIKFL_TF_VON_NGF: 73.2,
    GB_VERKEHRSFL_VF_VON_NGF: 2039.98,
    GB_MIETFL_GESAMT: 6989.36,
    GB_MIETFL_1_BSB: 6647.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989969,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.551936',
    GB_LAENGENGRAD_OESTLICH: '9.915863',
    GB_NETTOGRUNDFL_NGF: 660.35,
    GB_NUTZFL_NF_VON_NGF: 621.18,
    GB_TECHNIKFL_TF_VON_NGF: 10.59,
    GB_VERKEHRSFL_VF_VON_NGF: 28.58,
    GB_MIETFL_GESAMT: 660.35,
    GB_MIETFL_1_BSB: 660.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989970,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.7,
    GB_NUTZFL_NF_VON_NGF: 13.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.20',
    GB_GB_OBJ_ID_PKT: 1989971,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1501.89,
    GB_NUTZFL_NF_VON_NGF: 1099.25,
    GB_TECHNIKFL_TF_VON_NGF: 101.3,
    GB_VERKEHRSFL_VF_VON_NGF: 301.34,
    GB_MIETFL_GESAMT: 1501.89,
    GB_MIETFL_1_BSB: 1493.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989972,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.551998',
    GB_LAENGENGRAD_OESTLICH: '9.915588',
    GB_NETTOGRUNDFL_NGF: 983.9,
    GB_NUTZFL_NF_VON_NGF: 752.11,
    GB_TECHNIKFL_TF_VON_NGF: 23.77,
    GB_VERKEHRSFL_VF_VON_NGF: 208.02,
    GB_MIETFL_GESAMT: 983.9,
    GB_MIETFL_1_BSB: 982.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7496448,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.21',
    GB_GB_OBJ_ID_PKT: 5306670,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702004,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702004',
    GB_SAP_GB_ID_PKF: '7000/702004/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 252.97,
    GB_NUTZFL_NF_VON_NGF: 211.53,
    GB_TECHNIKFL_TF_VON_NGF: 1.81,
    GB_VERKEHRSFL_VF_VON_NGF: 39.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.21',
    GB_GB_OBJ_ID_PKT: 5399541,
    GB_WE_OBJ_ID_FKT: 1989072,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702052',
    GB_SAP_GB_ID_PKF: '7000/702052/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4201.59,
    GB_NUTZFL_NF_VON_NGF: 3065.82,
    GB_TECHNIKFL_TF_VON_NGF: 68.36,
    GB_VERKEHRSFL_VF_VON_NGF: 1067.41,
    GB_MIETFL_GESAMT: 4201.59,
    GB_MIETFL_1_BSB: 4007.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990328,
    GB_WE_OBJ_ID_FKT: 1989120,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702052',
    GB_SAP_GB_ID_PKF: '7000/702052/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1876,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2591.81,
    GB_NUTZFL_NF_VON_NGF: 2019.08,
    GB_TECHNIKFL_TF_VON_NGF: 21.14,
    GB_VERKEHRSFL_VF_VON_NGF: 551.59,
    GB_MIETFL_GESAMT: 2591.81,
    GB_MIETFL_1_BSB: 1985.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990329,
    GB_WE_OBJ_ID_FKT: 1989120,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702052',
    GB_SAP_GB_ID_PKF: '7000/702052/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.548774',
    GB_LAENGENGRAD_OESTLICH: '9.930404',
    GB_NETTOGRUNDFL_NGF: 458.49,
    GB_NUTZFL_NF_VON_NGF: 414.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.91,
    GB_MIETFL_GESAMT: 458.49,
    GB_MIETFL_1_BSB: 458.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990330,
    GB_WE_OBJ_ID_FKT: 1989120,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702052,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702052',
    GB_SAP_GB_ID_PKF: '7000/702052/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.549178',
    GB_LAENGENGRAD_OESTLICH: '9.930478',
    GB_NETTOGRUNDFL_NGF: 615.69,
    GB_NUTZFL_NF_VON_NGF: 573.58,
    GB_TECHNIKFL_TF_VON_NGF: 17.18,
    GB_VERKEHRSFL_VF_VON_NGF: 24.93,
    GB_MIETFL_GESAMT: 615.69,
    GB_MIETFL_1_BSB: 615.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990331,
    GB_WE_OBJ_ID_FKT: 1989120,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702052,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702052',
    GB_SAP_GB_ID_PKF: '7000/702052/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.48,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.48,
    GB_MIETFL_GESAMT: 32.48,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990332,
    GB_WE_OBJ_ID_FKT: 1989120,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 387.57,
    GB_NUTZFL_NF_VON_NGF: 335.13,
    GB_TECHNIKFL_TF_VON_NGF: 5.23,
    GB_VERKEHRSFL_VF_VON_NGF: 47.21,
    GB_MIETFL_GESAMT: 387.57,
    GB_MIETFL_1_BSB: 387.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990171,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 850.3,
    GB_NUTZFL_NF_VON_NGF: 695.82,
    GB_TECHNIKFL_TF_VON_NGF: 34.07,
    GB_VERKEHRSFL_VF_VON_NGF: 120.41,
    GB_MIETFL_GESAMT: 850.3,
    GB_MIETFL_1_BSB: 848.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990172,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 166.07,
    GB_NUTZFL_NF_VON_NGF: 136.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.78,
    GB_MIETFL_GESAMT: 166.07,
    GB_MIETFL_1_BSB: 166.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990173,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 731.91,
    GB_NUTZFL_NF_VON_NGF: 600.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 131.69,
    GB_MIETFL_GESAMT: 731.91,
    GB_MIETFL_1_BSB: 731.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990174,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 536.66,
    GB_NUTZFL_NF_VON_NGF: 478.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 57.99,
    GB_MIETFL_GESAMT: 536.66,
    GB_MIETFL_1_BSB: 536.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990175,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.62,
    GB_NUTZFL_NF_VON_NGF: 144.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 144.62,
    GB_MIETFL_1_BSB: 144.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990176,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1010.12,
    GB_NUTZFL_NF_VON_NGF: 818.07,
    GB_TECHNIKFL_TF_VON_NGF: 49.55,
    GB_VERKEHRSFL_VF_VON_NGF: 142.5,
    GB_MIETFL_GESAMT: 1010.12,
    GB_MIETFL_1_BSB: 960.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990177,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 989.77,
    GB_NUTZFL_NF_VON_NGF: 880.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 109.51,
    GB_MIETFL_GESAMT: 989.77,
    GB_MIETFL_1_BSB: 853.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990178,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.576108',
    GB_LAENGENGRAD_OESTLICH: '9.761984',
    GB_NETTOGRUNDFL_NGF: 589.95,
    GB_NUTZFL_NF_VON_NGF: 559.14,
    GB_TECHNIKFL_TF_VON_NGF: 21.54,
    GB_VERKEHRSFL_VF_VON_NGF: 9.27,
    GB_MIETFL_GESAMT: 589.95,
    GB_MIETFL_1_BSB: 589.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990179,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.94,
    GB_NUTZFL_NF_VON_NGF: 168.93,
    GB_TECHNIKFL_TF_VON_NGF: 6.27,
    GB_VERKEHRSFL_VF_VON_NGF: 30.74,
    GB_MIETFL_GESAMT: 205.94,
    GB_MIETFL_1_BSB: 205.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990180,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/11',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 101.4,
    GB_NUTZFL_NF_VON_NGF: 91.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.79,
    GB_MIETFL_GESAMT: 101.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990181,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.77,
    GB_NUTZFL_NF_VON_NGF: 314.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 92.41,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990182,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.574440',
    GB_LAENGENGRAD_OESTLICH: '9.761610',
    GB_NETTOGRUNDFL_NGF: 657.76,
    GB_NUTZFL_NF_VON_NGF: 627.65,
    GB_TECHNIKFL_TF_VON_NGF: 13.12,
    GB_VERKEHRSFL_VF_VON_NGF: 16.99,
    GB_MIETFL_GESAMT: 657.76,
    GB_MIETFL_1_BSB: 657.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400491,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7007979,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702034,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/702034',
    GB_SAP_GB_ID_PKF: '7000/702034/15',
    GB_SAP_GB_BEZEICHNUNG: 'Freilufthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Freilufthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7232774,
    GB_WE_OBJ_ID_FKT: 1989102,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7030261,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1882,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1137.36,
    GB_NUTZFL_NF_VON_NGF: 880.22,
    GB_TECHNIKFL_TF_VON_NGF: 9.02,
    GB_VERKEHRSFL_VF_VON_NGF: 248.12,
    GB_MIETFL_GESAMT: 1137.36,
    GB_MIETFL_1_BSB: 1013.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990232,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 535.8,
    GB_NUTZFL_NF_VON_NGF: 460.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.59,
    GB_MIETFL_GESAMT: 535.8,
    GB_MIETFL_1_BSB: 415.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990233,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 44.28,
    GB_NUTZFL_NF_VON_NGF: 44.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 44.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990234,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 765.37,
    GB_NUTZFL_NF_VON_NGF: 595.86,
    GB_TECHNIKFL_TF_VON_NGF: 1.21,
    GB_VERKEHRSFL_VF_VON_NGF: 168.3,
    GB_MIETFL_GESAMT: 765.37,
    GB_MIETFL_1_BSB: 765.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990235,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 816.45,
    GB_NUTZFL_NF_VON_NGF: 646.19,
    GB_TECHNIKFL_TF_VON_NGF: 1.18,
    GB_VERKEHRSFL_VF_VON_NGF: 169.08,
    GB_MIETFL_GESAMT: 816.45,
    GB_MIETFL_1_BSB: 816.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990236,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: '53.583870',
    GB_LAENGENGRAD_OESTLICH: '9.789338',
    GB_NETTOGRUNDFL_NGF: 766.58,
    GB_NUTZFL_NF_VON_NGF: 711.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.93,
    GB_MIETFL_GESAMT: 766.58,
    GB_MIETFL_1_BSB: 766.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.08',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990237,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1359.33,
    GB_NUTZFL_NF_VON_NGF: 1165.15,
    GB_TECHNIKFL_TF_VON_NGF: 23.03,
    GB_VERKEHRSFL_VF_VON_NGF: 171.15,
    GB_MIETFL_GESAMT: 1359.33,
    GB_MIETFL_1_BSB: 1359.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5414316,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702040,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702040',
    GB_SAP_GB_ID_PKF: '7000/702040/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1309.57,
    GB_NUTZFL_NF_VON_NGF: 1230.81,
    GB_TECHNIKFL_TF_VON_NGF: 6.16,
    GB_VERKEHRSFL_VF_VON_NGF: 72.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.07.18',
    GB_GB_OBJ_ID_PKT: 5365086,
    GB_WE_OBJ_ID_FKT: 1989108,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5177034,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 605.81,
    GB_NUTZFL_NF_VON_NGF: 478.06,
    GB_TECHNIKFL_TF_VON_NGF: 58.42,
    GB_VERKEHRSFL_VF_VON_NGF: 69.33,
    GB_MIETFL_GESAMT: 605.81,
    GB_MIETFL_1_BSB: 522.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990262,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 540.44,
    GB_NUTZFL_NF_VON_NGF: 398.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 142.44,
    GB_MIETFL_GESAMT: 540.44,
    GB_MIETFL_1_BSB: 540.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990263,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 861.11,
    GB_NUTZFL_NF_VON_NGF: 633.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 227.5,
    GB_MIETFL_GESAMT: 861.11,
    GB_MIETFL_1_BSB: 861.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990264,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 506.27,
    GB_NUTZFL_NF_VON_NGF: 455.27,
    GB_TECHNIKFL_TF_VON_NGF: 1.47,
    GB_VERKEHRSFL_VF_VON_NGF: 49.53,
    GB_MIETFL_GESAMT: 506.27,
    GB_MIETFL_1_BSB: 506.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.07',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990265,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1302.16,
    GB_NUTZFL_NF_VON_NGF: 1154.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 147.59,
    GB_MIETFL_GESAMT: 1302.16,
    GB_MIETFL_1_BSB: 1302.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990266,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.584688',
    GB_LAENGENGRAD_OESTLICH: '9.762746',
    GB_NETTOGRUNDFL_NGF: 654.26,
    GB_NUTZFL_NF_VON_NGF: 609.24,
    GB_TECHNIKFL_TF_VON_NGF: 16.41,
    GB_VERKEHRSFL_VF_VON_NGF: 28.61,
    GB_MIETFL_GESAMT: 654.26,
    GB_MIETFL_1_BSB: 654.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990267,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 149.28,
    GB_NUTZFL_NF_VON_NGF: 125.17,
    GB_TECHNIKFL_TF_VON_NGF: 6.52,
    GB_VERKEHRSFL_VF_VON_NGF: 17.59,
    GB_MIETFL_GESAMT: 149.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990268,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 344.61,
    GB_NUTZFL_NF_VON_NGF: 316.52,
    GB_TECHNIKFL_TF_VON_NGF: 11.76,
    GB_VERKEHRSFL_VF_VON_NGF: 16.33,
    GB_MIETFL_GESAMT: 344.61,
    GB_MIETFL_1_BSB: 344.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400049,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7534579,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702044,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702044',
    GB_SAP_GB_ID_PKF: '7000/702044/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552743,
    GB_WE_OBJ_ID_FKT: 1989112,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7198585,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 833.42,
    GB_NUTZFL_NF_VON_NGF: 619.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 214.24,
    GB_MIETFL_GESAMT: 833.42,
    GB_MIETFL_1_BSB: 833.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990319,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 410.41,
    GB_NUTZFL_NF_VON_NGF: 338.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.71,
    GB_MIETFL_GESAMT: 410.41,
    GB_MIETFL_1_BSB: 410.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990320,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 123.9,
    GB_NUTZFL_NF_VON_NGF: 91.21,
    GB_TECHNIKFL_TF_VON_NGF: 7.96,
    GB_VERKEHRSFL_VF_VON_NGF: 24.73,
    GB_MIETFL_GESAMT: 123.9,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990321,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 268.68,
    GB_NUTZFL_NF_VON_NGF: 205.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.25,
    GB_MIETFL_GESAMT: 268.68,
    GB_MIETFL_1_BSB: 268.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990322,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3143.45,
    GB_NUTZFL_NF_VON_NGF: 2379.63,
    GB_TECHNIKFL_TF_VON_NGF: 74.9,
    GB_VERKEHRSFL_VF_VON_NGF: 688.92,
    GB_MIETFL_GESAMT: 3143.45,
    GB_MIETFL_1_BSB: 2863.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990323,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990324,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.579297',
    GB_LAENGENGRAD_OESTLICH: '9.751312',
    GB_NETTOGRUNDFL_NGF: 1722.0,
    GB_NUTZFL_NF_VON_NGF: 1641.46,
    GB_TECHNIKFL_TF_VON_NGF: 22.46,
    GB_VERKEHRSFL_VF_VON_NGF: 58.08,
    GB_MIETFL_GESAMT: 1722.0,
    GB_MIETFL_1_BSB: 1722.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990325,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1157.45,
    GB_NUTZFL_NF_VON_NGF: 937.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 219.68,
    GB_MIETFL_GESAMT: 1157.45,
    GB_MIETFL_1_BSB: 1157.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990326,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2295.03,
    GB_NUTZFL_NF_VON_NGF: 1709.54,
    GB_TECHNIKFL_TF_VON_NGF: 91.28,
    GB_VERKEHRSFL_VF_VON_NGF: 494.21,
    GB_MIETFL_GESAMT: 2295.03,
    GB_MIETFL_1_BSB: 2022.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990327,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 869.08,
    GB_NUTZFL_NF_VON_NGF: 721.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 148.04,
    GB_MIETFL_GESAMT: 869.08,
    GB_MIETFL_1_BSB: 854.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400495,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3913.66,
    GB_NUTZFL_NF_VON_NGF: 2975.02,
    GB_TECHNIKFL_TF_VON_NGF: 14.43,
    GB_VERKEHRSFL_VF_VON_NGF: 924.21,
    GB_MIETFL_GESAMT: 3913.66,
    GB_MIETFL_1_BSB: 3833.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400496,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7177581,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352720,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.63,
    GB_NUTZFL_NF_VON_NGF: 220.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.16,
    GB_MIETFL_GESAMT: 236.63,
    GB_MIETFL_1_BSB: 236.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306672,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702051,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/702051',
    GB_SAP_GB_ID_PKF: '7000/702051/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 5400497,
    GB_WE_OBJ_ID_FKT: 1989119,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 7085591,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702003',
    GB_SAP_GB_ID_PKF: '7000/702003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1891,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4361.67,
    GB_NUTZFL_NF_VON_NGF: 3361.0,
    GB_TECHNIKFL_TF_VON_NGF: 52.53,
    GB_VERKEHRSFL_VF_VON_NGF: 948.14,
    GB_MIETFL_GESAMT: 4361.67,
    GB_MIETFL_1_BSB: 3693.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989965,
    GB_WE_OBJ_ID_FKT: 1989071,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702003',
    GB_SAP_GB_ID_PKF: '7000/702003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: '53.563852',
    GB_LAENGENGRAD_OESTLICH: '9.964607',
    GB_NETTOGRUNDFL_NGF: 400.6,
    GB_NUTZFL_NF_VON_NGF: 385.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.27,
    GB_MIETFL_GESAMT: 400.6,
    GB_MIETFL_1_BSB: 400.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989966,
    GB_WE_OBJ_ID_FKT: 1989071,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702003',
    GB_SAP_GB_ID_PKF: '7000/702003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1741.91,
    GB_NUTZFL_NF_VON_NGF: 1317.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 424.77,
    GB_MIETFL_GESAMT: 1741.91,
    GB_MIETFL_1_BSB: 1741.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989967,
    GB_WE_OBJ_ID_FKT: 1989071,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702003',
    GB_SAP_GB_ID_PKF: '7000/702003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 548.97,
    GB_NUTZFL_NF_VON_NGF: 447.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.66,
    GB_MIETFL_GESAMT: 548.97,
    GB_MIETFL_1_BSB: 548.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989968,
    GB_WE_OBJ_ID_FKT: 1989071,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702003',
    GB_SAP_GB_ID_PKF: '7000/702003/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.564067',
    GB_LAENGENGRAD_OESTLICH: '9.961971',
    GB_NETTOGRUNDFL_NGF: 540.57,
    GB_NUTZFL_NF_VON_NGF: 507.41,
    GB_TECHNIKFL_TF_VON_NGF: 12.96,
    GB_VERKEHRSFL_VF_VON_NGF: 20.2,
    GB_MIETFL_GESAMT: 540.57,
    GB_MIETFL_1_BSB: 540.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2136207,
    GB_WE_OBJ_ID_FKT: 1989071,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702081,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702081',
    GB_SAP_GB_ID_PKF: '7000/702081/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7101937,
    GB_WE_OBJ_ID_FKT: 7101935,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702081,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702081',
    GB_SAP_GB_ID_PKF: '7000/702081/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7101938,
    GB_WE_OBJ_ID_FKT: 7101935,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 5452933,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702006',
    GB_SAP_GB_ID_PKF: '7000/702006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: '53.559508',
    GB_LAENGENGRAD_OESTLICH: '9.965303',
    GB_NETTOGRUNDFL_NGF: 5013.16,
    GB_NUTZFL_NF_VON_NGF: 3365.94,
    GB_TECHNIKFL_TF_VON_NGF: 45.08,
    GB_VERKEHRSFL_VF_VON_NGF: 1602.14,
    GB_MIETFL_GESAMT: 5013.16,
    GB_MIETFL_1_BSB: 4503.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989976,
    GB_WE_OBJ_ID_FKT: 1989074,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 1800543,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702068',
    GB_SAP_GB_ID_PKF: '7000/702068/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12598.86,
    GB_NUTZFL_NF_VON_NGF: 8229.26,
    GB_TECHNIKFL_TF_VON_NGF: 263.08,
    GB_VERKEHRSFL_VF_VON_NGF: 4106.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1990419,
    GB_WE_OBJ_ID_FKT: 1989135,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702068,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702068',
    GB_SAP_GB_ID_PKF: '7000/702068/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1572.84,
    GB_NUTZFL_NF_VON_NGF: 1516.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 56.59,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 2078228,
    GB_WE_OBJ_ID_FKT: 1989135,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702066',
    GB_SAP_GB_ID_PKF: '7000/702066/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 220.12,
    GB_NUTZFL_NF_VON_NGF: 183.43,
    GB_TECHNIKFL_TF_VON_NGF: 3.77,
    GB_VERKEHRSFL_VF_VON_NGF: 32.92,
    GB_MIETFL_GESAMT: 220.12,
    GB_MIETFL_1_BSB: 220.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990410,
    GB_WE_OBJ_ID_FKT: 1989133,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702066,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702066',
    GB_SAP_GB_ID_PKF: '7000/702066/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 220.49,
    GB_NUTZFL_NF_VON_NGF: 196.48,
    GB_TECHNIKFL_TF_VON_NGF: 3.77,
    GB_VERKEHRSFL_VF_VON_NGF: 20.24,
    GB_MIETFL_GESAMT: 220.49,
    GB_MIETFL_1_BSB: 220.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990411,
    GB_WE_OBJ_ID_FKT: 1989133,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702064',
    GB_SAP_GB_ID_PKF: '7000/702064/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6123.49,
    GB_NUTZFL_NF_VON_NGF: 4552.04,
    GB_TECHNIKFL_TF_VON_NGF: 51.34,
    GB_VERKEHRSFL_VF_VON_NGF: 1520.11,
    GB_MIETFL_GESAMT: 6123.49,
    GB_MIETFL_1_BSB: 5113.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.09.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990407,
    GB_WE_OBJ_ID_FKT: 1989132,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702064,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702064',
    GB_SAP_GB_ID_PKF: '7000/702064/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3426.67,
    GB_NUTZFL_NF_VON_NGF: 2778.54,
    GB_TECHNIKFL_TF_VON_NGF: 46.36,
    GB_VERKEHRSFL_VF_VON_NGF: 601.77,
    GB_MIETFL_GESAMT: 3426.67,
    GB_MIETFL_1_BSB: 2791.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990408,
    GB_WE_OBJ_ID_FKT: 1989132,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702064,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702064',
    GB_SAP_GB_ID_PKF: '7000/702064/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2027.12,
    GB_NUTZFL_NF_VON_NGF: 1451.13,
    GB_TECHNIKFL_TF_VON_NGF: 49.08,
    GB_VERKEHRSFL_VF_VON_NGF: 526.91,
    GB_MIETFL_GESAMT: 2027.12,
    GB_MIETFL_1_BSB: 1820.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990409,
    GB_WE_OBJ_ID_FKT: 1989132,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702064,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702064',
    GB_SAP_GB_ID_PKF: '7000/702064/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 687.76,
    GB_NUTZFL_NF_VON_NGF: 525.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 162.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 5399918,
    GB_WE_OBJ_ID_FKT: 1989132,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990412,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990413,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990414,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990416,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.567741',
    GB_LAENGENGRAD_OESTLICH: '9.886510',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990415,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990417,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | WC Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1990418,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 5327468,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 5450526,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 5450527,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702067,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702067',
    GB_SAP_GB_ID_PKF: '7000/702067/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 5334410,
    GB_WE_OBJ_ID_FKT: 1989134,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708005',
    GB_SAP_GB_ID_PKF: '7000/708005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1894,
    GB_BREITENGRAD_NOERDLICH: '53.559245770',
    GB_LAENGENGRAD_OESTLICH: '9.8808150812',
    GB_NETTOGRUNDFL_NGF: 4034.59,
    GB_NUTZFL_NF_VON_NGF: 2758.18,
    GB_TECHNIKFL_TF_VON_NGF: 83.78,
    GB_VERKEHRSFL_VF_VON_NGF: 1192.63,
    GB_MIETFL_GESAMT: 682.03,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236669,
    GB_WE_OBJ_ID_FKT: 7236645,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.0,
    GB_NUTZFL_NF_VON_NGF: 400.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.05.22',
    GB_GB_OBJ_ID_PKT: 7236674,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.0,
    GB_NUTZFL_NF_VON_NGF: 400.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.05.22',
    GB_GB_OBJ_ID_PKT: 7236675,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.0,
    GB_NUTZFL_NF_VON_NGF: 400.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.05.22',
    GB_GB_OBJ_ID_PKT: 7236676,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.0,
    GB_NUTZFL_NF_VON_NGF: 400.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.05.22',
    GB_GB_OBJ_ID_PKT: 7236677,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.565237',
    GB_LAENGENGRAD_OESTLICH: '9.855403',
    GB_NETTOGRUNDFL_NGF: 127.85,
    GB_NUTZFL_NF_VON_NGF: 100.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.42,
    GB_MIETFL_GESAMT: 27.42,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236678,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.564941',
    GB_LAENGENGRAD_OESTLICH: '9.855785',
    GB_NETTOGRUNDFL_NGF: 55.46,
    GB_NUTZFL_NF_VON_NGF: 55.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236679,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 87.05,
    GB_NUTZFL_NF_VON_NGF: 43.33,
    GB_TECHNIKFL_TF_VON_NGF: 43.72,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 30.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236680,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.564645',
    GB_LAENGENGRAD_OESTLICH: '9.855517',
    GB_NETTOGRUNDFL_NGF: 117.64,
    GB_NUTZFL_NF_VON_NGF: 117.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236681,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708009,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/708009',
    GB_SAP_GB_ID_PKF: '7000/708009/9',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7262858,
    GB_WE_OBJ_ID_FKT: 7236649,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.5682008',
    GB_LAENGENGRAD_OESTLICH: '9.7564566',
    GB_NETTOGRUNDFL_NGF: 809.13,
    GB_NUTZFL_NF_VON_NGF: 590.08,
    GB_TECHNIKFL_TF_VON_NGF: 35.4,
    GB_VERKEHRSFL_VF_VON_NGF: 183.65,
    GB_MIETFL_GESAMT: 84.01,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236716,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/2',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1949,
    GB_BREITENGRAD_NOERDLICH: '53.568305132',
    GB_LAENGENGRAD_OESTLICH: '9.7561920671',
    GB_NETTOGRUNDFL_NGF: 70.81,
    GB_NUTZFL_NF_VON_NGF: 70.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236717,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.568025052',
    GB_LAENGENGRAD_OESTLICH: '9.7560991956',
    GB_NETTOGRUNDFL_NGF: 158.89,
    GB_NUTZFL_NF_VON_NGF: 140.93,
    GB_TECHNIKFL_TF_VON_NGF: 1.58,
    GB_VERKEHRSFL_VF_VON_NGF: 16.38,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236718,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.568055051',
    GB_LAENGENGRAD_OESTLICH: '9.7558132051',
    GB_NETTOGRUNDFL_NGF: 203.24,
    GB_NUTZFL_NF_VON_NGF: 185.38,
    GB_TECHNIKFL_TF_VON_NGF: 1.59,
    GB_VERKEHRSFL_VF_VON_NGF: 16.27,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236719,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/5',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: 1942,
    GB_BREITENGRAD_NOERDLICH: '53.567813199',
    GB_LAENGENGRAD_OESTLICH: '9.7560207410',
    GB_NETTOGRUNDFL_NGF: 40.99,
    GB_NUTZFL_NF_VON_NGF: 19.15,
    GB_TECHNIKFL_TF_VON_NGF: 21.84,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236720,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1921,
    GB_BREITENGRAD_NOERDLICH: '53.567605327',
    GB_LAENGENGRAD_OESTLICH: '9.7564357015',
    GB_NETTOGRUNDFL_NGF: 363.37,
    GB_NUTZFL_NF_VON_NGF: 265.97,
    GB_TECHNIKFL_TF_VON_NGF: 11.58,
    GB_VERKEHRSFL_VF_VON_NGF: 85.82,
    GB_MIETFL_GESAMT: 11.58,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236721,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708022,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/708022',
    GB_SAP_GB_ID_PKF: '7000/708022/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.567546124',
    GB_LAENGENGRAD_OESTLICH: '9.7557563208',
    GB_NETTOGRUNDFL_NGF: 102.7,
    GB_NUTZFL_NF_VON_NGF: 91.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.65,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236722,
    GB_WE_OBJ_ID_FKT: 7236662,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 101.58,
    GB_NUTZFL_NF_VON_NGF: 95.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.26,
    GB_MIETFL_GESAMT: 101.58,
    GB_MIETFL_1_BSB: 101.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423850,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 69.17,
    GB_NUTZFL_NF_VON_NGF: 69.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 69.17,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423851,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 59.52,
    GB_NUTZFL_NF_VON_NGF: 59.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 59.52,
    GB_MIETFL_1_BSB: 59.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423852,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/4',
    GB_SAP_GB_BEZEICHNUNG: 'Lehrerumkleide/Werkstatt',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lehrerumkleide/Werkstatt',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 27.95,
    GB_NUTZFL_NF_VON_NGF: 27.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 27.95,
    GB_MIETFL_1_BSB: 27.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423853,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/5',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 115.51,
    GB_NUTZFL_NF_VON_NGF: 115.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 115.51,
    GB_MIETFL_1_BSB: 115.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423854,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706047',
    GB_SAP_GB_ID_PKF: '7000/706047/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6776679,
    GB_WE_OBJ_ID_FKT: 5395772,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706004',
    GB_SAP_GB_ID_PKF: '7000/706004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1415.57,
    GB_NUTZFL_NF_VON_NGF: 1020.8,
    GB_TECHNIKFL_TF_VON_NGF: 25.15,
    GB_VERKEHRSFL_VF_VON_NGF: 369.62,
    GB_MIETFL_GESAMT: 1415.57,
    GB_MIETFL_1_BSB: 1093.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992047,
    GB_WE_OBJ_ID_FKT: 1989380,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706004',
    GB_SAP_GB_ID_PKF: '7000/706004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.428899',
    GB_LAENGENGRAD_OESTLICH: '10.270797',
    GB_NETTOGRUNDFL_NGF: 405.45,
    GB_NUTZFL_NF_VON_NGF: 405.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 405.45,
    GB_MIETFL_1_BSB: 405.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992048,
    GB_WE_OBJ_ID_FKT: 1989380,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706004',
    GB_SAP_GB_ID_PKF: '7000/706004/3',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.428947',
    GB_LAENGENGRAD_OESTLICH: '10.270532',
    GB_NETTOGRUNDFL_NGF: 444.58,
    GB_NUTZFL_NF_VON_NGF: 382.87,
    GB_TECHNIKFL_TF_VON_NGF: 7.51,
    GB_VERKEHRSFL_VF_VON_NGF: 54.2,
    GB_MIETFL_GESAMT: 444.58,
    GB_MIETFL_1_BSB: 444.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992049,
    GB_WE_OBJ_ID_FKT: 1989380,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706004,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706004',
    GB_SAP_GB_ID_PKF: '7000/706004/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.13,
    GB_NUTZFL_NF_VON_NGF: 13.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352772,
    GB_WE_OBJ_ID_FKT: 1989380,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706004,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706004',
    GB_SAP_GB_ID_PKF: '7000/706004/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 316.96,
    GB_NUTZFL_NF_VON_NGF: 250.83,
    GB_TECHNIKFL_TF_VON_NGF: 6.31,
    GB_VERKEHRSFL_VF_VON_NGF: 59.82,
    GB_MIETFL_GESAMT: 316.96,
    GB_MIETFL_1_BSB: 316.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '08.04.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5348841,
    GB_WE_OBJ_ID_FKT: 1989380,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3497.9,
    GB_NUTZFL_NF_VON_NGF: 2519.17,
    GB_TECHNIKFL_TF_VON_NGF: 80.51,
    GB_VERKEHRSFL_VF_VON_NGF: 898.22,
    GB_MIETFL_GESAMT: 3497.9,
    GB_MIETFL_1_BSB: 2714.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992105,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3779.24,
    GB_NUTZFL_NF_VON_NGF: 2809.87,
    GB_TECHNIKFL_TF_VON_NGF: 5.16,
    GB_VERKEHRSFL_VF_VON_NGF: 964.21,
    GB_MIETFL_GESAMT: 3779.24,
    GB_MIETFL_1_BSB: 3228.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992106,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1938,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 163.37,
    GB_NUTZFL_NF_VON_NGF: 137.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.78,
    GB_MIETFL_GESAMT: 163.37,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992107,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: '53.483326',
    GB_LAENGENGRAD_OESTLICH: '10.226123',
    GB_NETTOGRUNDFL_NGF: 627.22,
    GB_NUTZFL_NF_VON_NGF: 586.59,
    GB_TECHNIKFL_TF_VON_NGF: 14.87,
    GB_VERKEHRSFL_VF_VON_NGF: 25.76,
    GB_MIETFL_GESAMT: 627.22,
    GB_MIETFL_1_BSB: 627.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.06',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992108,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: '53.483518',
    GB_LAENGENGRAD_OESTLICH: '10.225456',
    GB_NETTOGRUNDFL_NGF: 629.66,
    GB_NUTZFL_NF_VON_NGF: 608.79,
    GB_TECHNIKFL_TF_VON_NGF: 6.55,
    GB_VERKEHRSFL_VF_VON_NGF: 14.32,
    GB_MIETFL_GESAMT: 629.66,
    GB_MIETFL_1_BSB: 629.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992109,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1938,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 163.84,
    GB_NUTZFL_NF_VON_NGF: 125.18,
    GB_TECHNIKFL_TF_VON_NGF: 14.47,
    GB_VERKEHRSFL_VF_VON_NGF: 24.19,
    GB_MIETFL_GESAMT: 163.84,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 149.37,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992110,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.25,
    GB_NUTZFL_NF_VON_NGF: 33.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138242,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.53,
    GB_NUTZFL_NF_VON_NGF: 16.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138243,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7012600,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706012,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706012',
    GB_SAP_GB_ID_PKF: '7000/706012/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7012601,
    GB_WE_OBJ_ID_FKT: 1989388,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2280.75,
    GB_NUTZFL_NF_VON_NGF: 1606.87,
    GB_TECHNIKFL_TF_VON_NGF: 47.82,
    GB_VERKEHRSFL_VF_VON_NGF: 626.06,
    GB_MIETFL_GESAMT: 2280.75,
    GB_MIETFL_1_BSB: 1940.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992230,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 401.18,
    GB_NUTZFL_NF_VON_NGF: 285.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 115.68,
    GB_MIETFL_GESAMT: 401.18,
    GB_MIETFL_1_BSB: 401.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992231,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 101.96,
    GB_NUTZFL_NF_VON_NGF: 90.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.06.20',
    GB_GB_OBJ_ID_PKT: 1992232,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.11.19',
    GB_GB_OBJ_ID_PKT: 1992233,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.38,
    GB_NUTZFL_NF_VON_NGF: 361.38,
    GB_TECHNIKFL_TF_VON_NGF: 2.4,
    GB_VERKEHRSFL_VF_VON_NGF: 91.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1992234,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.82,
    GB_NUTZFL_NF_VON_NGF: 14.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992235,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.29,
    GB_NUTZFL_NF_VON_NGF: 338.94,
    GB_TECHNIKFL_TF_VON_NGF: 2.94,
    GB_VERKEHRSFL_VF_VON_NGF: 91.41,
    GB_MIETFL_GESAMT: 433.29,
    GB_MIETFL_1_BSB: 433.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992236,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.480008',
    GB_LAENGENGRAD_OESTLICH: '10.194102',
    GB_NETTOGRUNDFL_NGF: 624.22,
    GB_NUTZFL_NF_VON_NGF: 589.62,
    GB_TECHNIKFL_TF_VON_NGF: 15.61,
    GB_VERKEHRSFL_VF_VON_NGF: 18.99,
    GB_MIETFL_GESAMT: 624.22,
    GB_MIETFL_1_BSB: 624.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992237,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.95,
    GB_NUTZFL_NF_VON_NGF: 187.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.84,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1992238,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 216.25,
    GB_NUTZFL_NF_VON_NGF: 187.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1992239,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.59,
    GB_NUTZFL_NF_VON_NGF: 205.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1992240,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2030.12,
    GB_NUTZFL_NF_VON_NGF: 1303.55,
    GB_TECHNIKFL_TF_VON_NGF: 56.43,
    GB_VERKEHRSFL_VF_VON_NGF: 670.14,
    GB_MIETFL_GESAMT: 2030.12,
    GB_MIETFL_1_BSB: 2028.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402909,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.480067',
    GB_LAENGENGRAD_OESTLICH: '10.194935',
    GB_NETTOGRUNDFL_NGF: 1272.19,
    GB_NUTZFL_NF_VON_NGF: 1142.43,
    GB_TECHNIKFL_TF_VON_NGF: 57.79,
    GB_VERKEHRSFL_VF_VON_NGF: 71.97,
    GB_MIETFL_GESAMT: 1272.19,
    GB_MIETFL_1_BSB: 1272.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402910,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/14',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 642.61,
    GB_NUTZFL_NF_VON_NGF: 434.12,
    GB_TECHNIKFL_TF_VON_NGF: 4.46,
    GB_VERKEHRSFL_VF_VON_NGF: 204.03,
    GB_MIETFL_GESAMT: 642.61,
    GB_MIETFL_1_BSB: 642.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6890353,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7147226,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5928879,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 762.04,
    GB_NUTZFL_NF_VON_NGF: 592.49,
    GB_TECHNIKFL_TF_VON_NGF: 35.54,
    GB_VERKEHRSFL_VF_VON_NGF: 134.01,
    GB_MIETFL_GESAMT: 762.04,
    GB_MIETFL_1_BSB: 580.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992113,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 363.54,
    GB_NUTZFL_NF_VON_NGF: 344.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.72,
    GB_MIETFL_GESAMT: 363.54,
    GB_MIETFL_1_BSB: 363.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992114,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706026,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706026',
    GB_SAP_GB_ID_PKF: '7000/706026/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.19',
    GB_GB_OBJ_ID_PKT: 5422499,
    GB_WE_OBJ_ID_FKT: 1989402,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.34,
    GB_NUTZFL_NF_VON_NGF: 13.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.34,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992115,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.85,
    GB_NUTZFL_NF_VON_NGF: 17.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.85,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992116,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.52,
    GB_NUTZFL_NF_VON_NGF: 14.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992117,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 146.33,
    GB_NUTZFL_NF_VON_NGF: 117.19,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.14,
    GB_MIETFL_GESAMT: 146.33,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992118,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.52,
    GB_NUTZFL_NF_VON_NGF: 14.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992119,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 474.2,
    GB_NUTZFL_NF_VON_NGF: 384.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.71,
    GB_MIETFL_GESAMT: 474.2,
    GB_MIETFL_1_BSB: 474.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992120,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 474.92,
    GB_NUTZFL_NF_VON_NGF: 385.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.79,
    GB_MIETFL_GESAMT: 474.92,
    GB_MIETFL_1_BSB: 474.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992121,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.39,
    GB_NUTZFL_NF_VON_NGF: 385.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.71,
    GB_MIETFL_GESAMT: 475.39,
    GB_MIETFL_1_BSB: 475.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992122,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.39,
    GB_NUTZFL_NF_VON_NGF: 385.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.71,
    GB_MIETFL_GESAMT: 475.39,
    GB_MIETFL_1_BSB: 475.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992123,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.39,
    GB_NUTZFL_NF_VON_NGF: 385.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.71,
    GB_MIETFL_GESAMT: 475.39,
    GB_MIETFL_1_BSB: 475.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992124,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.493471',
    GB_LAENGENGRAD_OESTLICH: '10.178869',
    GB_NETTOGRUNDFL_NGF: 620.09,
    GB_NUTZFL_NF_VON_NGF: 587.6,
    GB_TECHNIKFL_TF_VON_NGF: 13.51,
    GB_VERKEHRSFL_VF_VON_NGF: 18.98,
    GB_MIETFL_GESAMT: 620.09,
    GB_MIETFL_1_BSB: 620.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992125,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/14',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.53,
    GB_NUTZFL_NF_VON_NGF: 15.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992126,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.85,
    GB_NUTZFL_NF_VON_NGF: 181.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.07.20',
    GB_GB_OBJ_ID_PKT: 1992127,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.96,
    GB_NUTZFL_NF_VON_NGF: 147.7,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 23.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.07.20',
    GB_GB_OBJ_ID_PKT: 1992128,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.99,
    GB_NUTZFL_NF_VON_NGF: 147.73,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 23.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.07.20',
    GB_GB_OBJ_ID_PKT: 1992129,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.96,
    GB_NUTZFL_NF_VON_NGF: 147.7,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 23.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.07.20',
    GB_GB_OBJ_ID_PKT: 1992130,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706014,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/706014',
    GB_SAP_GB_ID_PKF: '7000/706014/20',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 374.99,
    GB_NUTZFL_NF_VON_NGF: 348.51,
    GB_TECHNIKFL_TF_VON_NGF: 1.37,
    GB_VERKEHRSFL_VF_VON_NGF: 25.11,
    GB_MIETFL_GESAMT: 374.99,
    GB_MIETFL_1_BSB: 374.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992131,
    GB_WE_OBJ_ID_FKT: 1989390,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6371.57,
    GB_NUTZFL_NF_VON_NGF: 4300.26,
    GB_TECHNIKFL_TF_VON_NGF: 367.59,
    GB_VERKEHRSFL_VF_VON_NGF: 1703.72,
    GB_MIETFL_GESAMT: 6371.57,
    GB_MIETFL_1_BSB: 4943.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992140,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3621.9,
    GB_NUTZFL_NF_VON_NGF: 2706.11,
    GB_TECHNIKFL_TF_VON_NGF: 51.48,
    GB_VERKEHRSFL_VF_VON_NGF: 864.31,
    GB_MIETFL_GESAMT: 3621.9,
    GB_MIETFL_1_BSB: 3552.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992141,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.494238',
    GB_LAENGENGRAD_OESTLICH: '10.220729',
    GB_NETTOGRUNDFL_NGF: 625.75,
    GB_NUTZFL_NF_VON_NGF: 600.19,
    GB_TECHNIKFL_TF_VON_NGF: 6.77,
    GB_VERKEHRSFL_VF_VON_NGF: 18.79,
    GB_MIETFL_GESAMT: 625.75,
    GB_MIETFL_1_BSB: 625.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992142,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: '53.493500',
    GB_LAENGENGRAD_OESTLICH: '10.220530',
    GB_NETTOGRUNDFL_NGF: 553.07,
    GB_NUTZFL_NF_VON_NGF: 509.29,
    GB_TECHNIKFL_TF_VON_NGF: 17.19,
    GB_VERKEHRSFL_VF_VON_NGF: 26.59,
    GB_MIETFL_GESAMT: 553.07,
    GB_MIETFL_1_BSB: 553.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992143,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.38,
    GB_NUTZFL_NF_VON_NGF: 13.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.38,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992144,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 169.07,
    GB_NUTZFL_NF_VON_NGF: 141.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.95,
    GB_MIETFL_GESAMT: 169.07,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992145,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.07,
    GB_NUTZFL_NF_VON_NGF: 17.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.07,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138313,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706017',
    GB_SAP_GB_ID_PKF: '7000/706017/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.29,
    GB_NUTZFL_NF_VON_NGF: 17.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.29,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138314,
    GB_WE_OBJ_ID_FKT: 1989393,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706022',
    GB_SAP_GB_ID_PKF: '7000/706022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1890,
    GB_BREITENGRAD_NOERDLICH: '53.491898',
    GB_LAENGENGRAD_OESTLICH: '10.227914',
    GB_NETTOGRUNDFL_NGF: 5771.68,
    GB_NUTZFL_NF_VON_NGF: 4099.26,
    GB_TECHNIKFL_TF_VON_NGF: 68.99,
    GB_VERKEHRSFL_VF_VON_NGF: 1603.43,
    GB_MIETFL_GESAMT: 5771.68,
    GB_MIETFL_1_BSB: 5771.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992200,
    GB_WE_OBJ_ID_FKT: 1989398,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706022',
    GB_SAP_GB_ID_PKF: '7000/706022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1369.94,
    GB_NUTZFL_NF_VON_NGF: 1063.7,
    GB_TECHNIKFL_TF_VON_NGF: 14.21,
    GB_VERKEHRSFL_VF_VON_NGF: 292.03,
    GB_MIETFL_GESAMT: 1369.94,
    GB_MIETFL_1_BSB: 1355.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992201,
    GB_WE_OBJ_ID_FKT: 1989398,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706022',
    GB_SAP_GB_ID_PKF: '7000/706022/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 235.12,
    GB_NUTZFL_NF_VON_NGF: 222.7,
    GB_TECHNIKFL_TF_VON_NGF: 2.31,
    GB_VERKEHRSFL_VF_VON_NGF: 10.11,
    GB_MIETFL_GESAMT: 235.12,
    GB_MIETFL_1_BSB: 235.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992202,
    GB_WE_OBJ_ID_FKT: 1989398,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706022,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706022',
    GB_SAP_GB_ID_PKF: '7000/706022/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1848.26,
    GB_NUTZFL_NF_VON_NGF: 1357.46,
    GB_TECHNIKFL_TF_VON_NGF: 42.1,
    GB_VERKEHRSFL_VF_VON_NGF: 448.7,
    GB_MIETFL_GESAMT: 1848.26,
    GB_MIETFL_1_BSB: 1845.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402905,
    GB_WE_OBJ_ID_FKT: 1989398,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706022,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706022',
    GB_SAP_GB_ID_PKF: '7000/706022/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.15,
    GB_NUTZFL_NF_VON_NGF: 137.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.12.19',
    GB_GB_OBJ_ID_PKT: 4352774,
    GB_WE_OBJ_ID_FKT: 1989398,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 130.38,
    GB_NUTZFL_NF_VON_NGF: 118.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '05.04.22',
    GB_GB_OBJ_ID_PKT: 1992260,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.91,
    GB_NUTZFL_NF_VON_NGF: 83.69,
    GB_TECHNIKFL_TF_VON_NGF: 8.75,
    GB_VERKEHRSFL_VF_VON_NGF: 12.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.06.20',
    GB_GB_OBJ_ID_PKT: 1992261,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.06.20',
    GB_GB_OBJ_ID_PKT: 1992262,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.98,
    GB_NUTZFL_NF_VON_NGF: 155.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.66,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.12.22',
    GB_GB_OBJ_ID_PKT: 1992263,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.98,
    GB_NUTZFL_NF_VON_NGF: 155.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.12.22',
    GB_GB_OBJ_ID_PKT: 1992264,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.9,
    GB_NUTZFL_NF_VON_NGF: 189.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.12.22',
    GB_GB_OBJ_ID_PKT: 1992265,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1992266,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 606.59,
    GB_NUTZFL_NF_VON_NGF: 420.69,
    GB_TECHNIKFL_TF_VON_NGF: 24.48,
    GB_VERKEHRSFL_VF_VON_NGF: 161.42,
    GB_MIETFL_GESAMT: 606.59,
    GB_MIETFL_1_BSB: 606.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992267,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1551.76,
    GB_NUTZFL_NF_VON_NGF: 1136.14,
    GB_TECHNIKFL_TF_VON_NGF: 106.09,
    GB_VERKEHRSFL_VF_VON_NGF: 309.53,
    GB_MIETFL_GESAMT: 1547.95,
    GB_MIETFL_1_BSB: 1437.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992269,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/12',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1729.99,
    GB_NUTZFL_NF_VON_NGF: 1364.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 365.0,
    GB_MIETFL_GESAMT: 1729.99,
    GB_MIETFL_1_BSB: 1729.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455810,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/13',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7382801,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.495678',
    GB_LAENGENGRAD_OESTLICH: '10.218978',
    GB_NETTOGRUNDFL_NGF: 628.96,
    GB_NUTZFL_NF_VON_NGF: 603.8,
    GB_TECHNIKFL_TF_VON_NGF: 6.56,
    GB_VERKEHRSFL_VF_VON_NGF: 18.6,
    GB_MIETFL_GESAMT: 628.96,
    GB_MIETFL_1_BSB: 628.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992268,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706030,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706030',
    GB_SAP_GB_ID_PKF: '7000/706030/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.496341',
    GB_LAENGENGRAD_OESTLICH: '10.219111',
    GB_NETTOGRUNDFL_NGF: 667.84,
    GB_NUTZFL_NF_VON_NGF: 619.36,
    GB_TECHNIKFL_TF_VON_NGF: 13.15,
    GB_VERKEHRSFL_VF_VON_NGF: 35.33,
    GB_MIETFL_GESAMT: 667.84,
    GB_MIETFL_1_BSB: 661.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.05.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5352221,
    GB_WE_OBJ_ID_FKT: 1989406,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706040',
    GB_SAP_GB_ID_PKF: '7000/706040/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 638.19,
    GB_NUTZFL_NF_VON_NGF: 590.67,
    GB_TECHNIKFL_TF_VON_NGF: 17.63,
    GB_VERKEHRSFL_VF_VON_NGF: 29.89,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.12.15',
    GB_GB_OBJ_ID_PKT: 1992296,
    GB_WE_OBJ_ID_FKT: 1989415,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5455812,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 210.62,
    GB_NUTZFL_NF_VON_NGF: 175.92,
    GB_TECHNIKFL_TF_VON_NGF: 1.2,
    GB_VERKEHRSFL_VF_VON_NGF: 33.5,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.04.20',
    GB_GB_OBJ_ID_PKT: 1992222,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.04.20',
    GB_GB_OBJ_ID_PKT: 1992223,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.04.20',
    GB_GB_OBJ_ID_PKT: 1992224,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.01,
    GB_NUTZFL_NF_VON_NGF: 186.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.44,
    GB_MIETFL_GESAMT: 214.01,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 214.01,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992225,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.09,
    GB_NUTZFL_NF_VON_NGF: 362.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 90.07,
    GB_MIETFL_GESAMT: 452.09,
    GB_MIETFL_1_BSB: 452.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992226,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 540.96,
    GB_NUTZFL_NF_VON_NGF: 360.75,
    GB_TECHNIKFL_TF_VON_NGF: 81.05,
    GB_VERKEHRSFL_VF_VON_NGF: 99.16,
    GB_MIETFL_GESAMT: 540.96,
    GB_MIETFL_1_BSB: 447.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992227,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.17',
    GB_GB_OBJ_ID_PKT: 1992228,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 125.65,
    GB_NUTZFL_NF_VON_NGF: 106.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.96,
    GB_MIETFL_GESAMT: 125.65,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992229,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 627.59,
    GB_NUTZFL_NF_VON_NGF: 448.03,
    GB_TECHNIKFL_TF_VON_NGF: 7.27,
    GB_VERKEHRSFL_VF_VON_NGF: 172.29,
    GB_MIETFL_GESAMT: 627.59,
    GB_MIETFL_1_BSB: 626.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.04.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402907,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.499256',
    GB_LAENGENGRAD_OESTLICH: '10.133695',
    GB_NETTOGRUNDFL_NGF: 381.33,
    GB_NUTZFL_NF_VON_NGF: 363.53,
    GB_TECHNIKFL_TF_VON_NGF: 5.66,
    GB_VERKEHRSFL_VF_VON_NGF: 12.14,
    GB_MIETFL_GESAMT: 381.33,
    GB_MIETFL_1_BSB: 381.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402908,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706025,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706025',
    GB_SAP_GB_ID_PKF: '7000/706025/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7063957,
    GB_WE_OBJ_ID_FKT: 1989401,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1427.52,
    GB_NUTZFL_NF_VON_NGF: 1083.97,
    GB_TECHNIKFL_TF_VON_NGF: 64.94,
    GB_VERKEHRSFL_VF_VON_NGF: 278.61,
    GB_MIETFL_GESAMT: 1427.52,
    GB_MIETFL_1_BSB: 1323.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992090,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.448959',
    GB_LAENGENGRAD_OESTLICH: '10.219298',
    GB_NETTOGRUNDFL_NGF: 616.16,
    GB_NUTZFL_NF_VON_NGF: 577.45,
    GB_TECHNIKFL_TF_VON_NGF: 19.54,
    GB_VERKEHRSFL_VF_VON_NGF: 19.17,
    GB_MIETFL_GESAMT: 616.16,
    GB_MIETFL_1_BSB: 616.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992091,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.53,
    GB_NUTZFL_NF_VON_NGF: 166.98,
    GB_TECHNIKFL_TF_VON_NGF: 4.34,
    GB_VERKEHRSFL_VF_VON_NGF: 27.21,
    GB_MIETFL_GESAMT: 198.53,
    GB_MIETFL_1_BSB: 198.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992092,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.42,
    GB_NUTZFL_NF_VON_NGF: 14.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.42,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992093,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: '53.448837',
    GB_LAENGENGRAD_OESTLICH: '10.218535',
    GB_NETTOGRUNDFL_NGF: 915.49,
    GB_NUTZFL_NF_VON_NGF: 845.0,
    GB_TECHNIKFL_TF_VON_NGF: 21.75,
    GB_VERKEHRSFL_VF_VON_NGF: 48.74,
    GB_MIETFL_GESAMT: 531.03,
    GB_MIETFL_1_BSB: 531.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992094,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 309.74,
    GB_NUTZFL_NF_VON_NGF: 219.48,
    GB_TECHNIKFL_TF_VON_NGF: 4.66,
    GB_VERKEHRSFL_VF_VON_NGF: 85.6,
    GB_MIETFL_GESAMT: 309.74,
    GB_MIETFL_1_BSB: 309.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992095,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 201.26,
    GB_NUTZFL_NF_VON_NGF: 191.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.62,
    GB_MIETFL_GESAMT: 201.26,
    GB_MIETFL_1_BSB: 201.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992096,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 344.05,
    GB_NUTZFL_NF_VON_NGF: 302.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.18,
    GB_MIETFL_GESAMT: 344.05,
    GB_MIETFL_1_BSB: 344.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992097,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 332.24,
    GB_NUTZFL_NF_VON_NGF: 286.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.85,
    GB_MIETFL_GESAMT: 332.24,
    GB_MIETFL_1_BSB: 332.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992098,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 440.71,
    GB_NUTZFL_NF_VON_NGF: 398.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.69,
    GB_MIETFL_GESAMT: 440.71,
    GB_MIETFL_1_BSB: 440.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992099,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 889.77,
    GB_NUTZFL_NF_VON_NGF: 750.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 139.08,
    GB_MIETFL_GESAMT: 889.77,
    GB_MIETFL_1_BSB: 889.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992100,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 208.26,
    GB_NUTZFL_NF_VON_NGF: 175.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.31,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.11.19',
    GB_GB_OBJ_ID_PKT: 1992101,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 309.7,
    GB_NUTZFL_NF_VON_NGF: 254.17,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 53.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.11.19',
    GB_GB_OBJ_ID_PKT: 1992102,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.32,
    GB_NUTZFL_NF_VON_NGF: 142.21,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 23.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.11.19',
    GB_GB_OBJ_ID_PKT: 1992103,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.32,
    GB_NUTZFL_NF_VON_NGF: 142.21,
    GB_TECHNIKFL_TF_VON_NGF: 1.77,
    GB_VERKEHRSFL_VF_VON_NGF: 23.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.11.19',
    GB_GB_OBJ_ID_PKT: 1992104,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7032939,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 79.47,
    GB_NUTZFL_NF_VON_NGF: 70.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.14,
    GB_MIETFL_GESAMT: 79.47,
    GB_MIETFL_1_BSB: 79.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5480646,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '21.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7060627,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '21.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7060628,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 166.58,
    GB_NUTZFL_NF_VON_NGF: 152.89,
    GB_TECHNIKFL_TF_VON_NGF: 3.12,
    GB_VERKEHRSFL_VF_VON_NGF: 10.57,
    GB_MIETFL_GESAMT: 166.58,
    GB_MIETFL_1_BSB: 166.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7123803,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.2,
    GB_NUTZFL_NF_VON_NGF: 78.6,
    GB_TECHNIKFL_TF_VON_NGF: 3.1,
    GB_VERKEHRSFL_VF_VON_NGF: 8.5,
    GB_MIETFL_GESAMT: 90.2,
    GB_MIETFL_1_BSB: 90.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7359078,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706011,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/706011',
    GB_SAP_GB_ID_PKF: '7000/706011/45',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 91.4,
    GB_NUTZFL_NF_VON_NGF: 79.56,
    GB_TECHNIKFL_TF_VON_NGF: 7.52,
    GB_VERKEHRSFL_VF_VON_NGF: 4.32,
    GB_MIETFL_GESAMT: 91.4,
    GB_MIETFL_1_BSB: 91.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '18.08.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7435573,
    GB_WE_OBJ_ID_FKT: 1989387,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706015',
    GB_SAP_GB_ID_PKF: '7000/706015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2035.5,
    GB_NUTZFL_NF_VON_NGF: 1481.88,
    GB_TECHNIKFL_TF_VON_NGF: 55.61,
    GB_VERKEHRSFL_VF_VON_NGF: 498.01,
    GB_MIETFL_GESAMT: 2035.5,
    GB_MIETFL_1_BSB: 1974.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992132,
    GB_WE_OBJ_ID_FKT: 1989391,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706015',
    GB_SAP_GB_ID_PKF: '7000/706015/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.11.14',
    GB_GB_OBJ_ID_PKT: 1992133,
    GB_WE_OBJ_ID_FKT: 1989391,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706015',
    GB_SAP_GB_ID_PKF: '7000/706015/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.87,
    GB_NUTZFL_NF_VON_NGF: 122.38,
    GB_TECHNIKFL_TF_VON_NGF: 12.2,
    GB_VERKEHRSFL_VF_VON_NGF: 28.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.11.22',
    GB_GB_OBJ_ID_PKT: 1992134,
    GB_WE_OBJ_ID_FKT: 1989391,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706015',
    GB_SAP_GB_ID_PKF: '7000/706015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 917.69,
    GB_NUTZFL_NF_VON_NGF: 681.48,
    GB_TECHNIKFL_TF_VON_NGF: 13.96,
    GB_VERKEHRSFL_VF_VON_NGF: 222.25,
    GB_MIETFL_GESAMT: 917.69,
    GB_MIETFL_1_BSB: 915.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7012602,
    GB_WE_OBJ_ID_FKT: 1989391,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706013',
    GB_SAP_GB_ID_PKF: '7000/706013/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1909,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 788.15,
    GB_NUTZFL_NF_VON_NGF: 644.25,
    GB_TECHNIKFL_TF_VON_NGF: 34.81,
    GB_VERKEHRSFL_VF_VON_NGF: 109.09,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1992111,
    GB_WE_OBJ_ID_FKT: 1989389,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706013',
    GB_SAP_GB_ID_PKF: '7000/706013/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1882,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 76.2,
    GB_NUTZFL_NF_VON_NGF: 76.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1992112,
    GB_WE_OBJ_ID_FKT: 1989389,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1172.06,
    GB_NUTZFL_NF_VON_NGF: 828.96,
    GB_TECHNIKFL_TF_VON_NGF: 17.74,
    GB_VERKEHRSFL_VF_VON_NGF: 325.36,
    GB_MIETFL_GESAMT: 1172.06,
    GB_MIETFL_1_BSB: 994.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992278,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 313.97,
    GB_NUTZFL_NF_VON_NGF: 210.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.3,
    GB_MIETFL_GESAMT: 313.97,
    GB_MIETFL_1_BSB: 248.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992279,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.09.15',
    GB_GB_OBJ_ID_PKT: 1992280,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: '53.401135',
    GB_LAENGENGRAD_OESTLICH: '10.185299',
    GB_NETTOGRUNDFL_NGF: 616.25,
    GB_NUTZFL_NF_VON_NGF: 549.63,
    GB_TECHNIKFL_TF_VON_NGF: 9.92,
    GB_VERKEHRSFL_VF_VON_NGF: 56.7,
    GB_MIETFL_GESAMT: 616.25,
    GB_MIETFL_1_BSB: 616.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992281,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 19.38,
    GB_NUTZFL_NF_VON_NGF: 19.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 19.38,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992282,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1327.01,
    GB_NUTZFL_NF_VON_NGF: 1009.78,
    GB_TECHNIKFL_TF_VON_NGF: 5.21,
    GB_VERKEHRSFL_VF_VON_NGF: 312.02,
    GB_MIETFL_GESAMT: 1327.01,
    GB_MIETFL_1_BSB: 1323.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402913,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 328.26,
    GB_NUTZFL_NF_VON_NGF: 300.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.17',
    GB_GB_OBJ_ID_PKT: 4352778,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706032,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706032',
    GB_SAP_GB_ID_PKF: '7000/706032/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 82.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.17',
    GB_GB_OBJ_ID_PKT: 4352779,
    GB_WE_OBJ_ID_FKT: 1989408,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706046',
    GB_SAP_GB_ID_PKF: '7000/706046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5361610,
    GB_WE_OBJ_ID_FKT: 5361605,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706046',
    GB_SAP_GB_ID_PKF: '7000/706046/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5361611,
    GB_WE_OBJ_ID_FKT: 5361605,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706046',
    GB_SAP_GB_ID_PKF: '7000/706046/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6785019,
    GB_WE_OBJ_ID_FKT: 5361605,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1482.67,
    GB_NUTZFL_NF_VON_NGF: 1137.87,
    GB_TECHNIKFL_TF_VON_NGF: 81.79,
    GB_VERKEHRSFL_VF_VON_NGF: 263.01,
    GB_MIETFL_GESAMT: 1482.67,
    GB_MIETFL_1_BSB: 1482.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992303,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1088.86,
    GB_NUTZFL_NF_VON_NGF: 937.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 151.8,
    GB_MIETFL_GESAMT: 1088.86,
    GB_MIETFL_1_BSB: 1088.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992304,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.11,
    GB_NUTZFL_NF_VON_NGF: 392.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.55,
    GB_MIETFL_GESAMT: 497.11,
    GB_MIETFL_1_BSB: 497.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992305,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.11,
    GB_NUTZFL_NF_VON_NGF: 392.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.55,
    GB_MIETFL_GESAMT: 497.11,
    GB_MIETFL_1_BSB: 497.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992306,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 490.17,
    GB_NUTZFL_NF_VON_NGF: 385.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.55,
    GB_MIETFL_GESAMT: 490.17,
    GB_MIETFL_1_BSB: 490.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992307,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 490.17,
    GB_NUTZFL_NF_VON_NGF: 385.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.55,
    GB_MIETFL_GESAMT: 490.17,
    GB_MIETFL_1_BSB: 490.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992308,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.421640',
    GB_LAENGENGRAD_OESTLICH: '10.203881',
    GB_NETTOGRUNDFL_NGF: 617.62,
    GB_NUTZFL_NF_VON_NGF: 585.9,
    GB_TECHNIKFL_TF_VON_NGF: 12.96,
    GB_VERKEHRSFL_VF_VON_NGF: 18.76,
    GB_MIETFL_GESAMT: 617.62,
    GB_MIETFL_1_BSB: 617.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992309,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.421200',
    GB_LAENGENGRAD_OESTLICH: '10.203849',
    GB_NETTOGRUNDFL_NGF: 320.64,
    GB_NUTZFL_NF_VON_NGF: 302.6,
    GB_TECHNIKFL_TF_VON_NGF: 7.2,
    GB_VERKEHRSFL_VF_VON_NGF: 10.84,
    GB_MIETFL_GESAMT: 320.64,
    GB_MIETFL_1_BSB: 320.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992310,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.04.21',
    GB_GB_OBJ_ID_PKT: 1992311,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1596.67,
    GB_NUTZFL_NF_VON_NGF: 1073.05,
    GB_TECHNIKFL_TF_VON_NGF: 26.21,
    GB_VERKEHRSFL_VF_VON_NGF: 497.41,
    GB_MIETFL_GESAMT: 1596.67,
    GB_MIETFL_1_BSB: 1262.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992312,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/11',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 238.87,
    GB_NUTZFL_NF_VON_NGF: 192.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.81,
    GB_MIETFL_GESAMT: 238.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992313,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.13,
    GB_NUTZFL_NF_VON_NGF: 13.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138315,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402914,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402915,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 301.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352782,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.25,
    GB_NUTZFL_NF_VON_NGF: 151.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 161.25,
    GB_MIETFL_1_BSB: 161.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352783,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306682,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 79.97,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 92.53,
    GB_MIETFL_1_BSB: 92.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306683,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 489.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306684,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/45',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 177.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 177.64,
    GB_MIETFL_1_BSB: 177.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.18',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6940205,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 46,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/46',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 176.0,
    GB_NUTZFL_NF_VON_NGF: 176.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 176.0,
    GB_MIETFL_1_BSB: 176.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6969755,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 47,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/47',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 108.21,
    GB_NUTZFL_NF_VON_NGF: 83.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.18,
    GB_MIETFL_GESAMT: 108.21,
    GB_MIETFL_1_BSB: 108.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7216617,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706042,
    GB_SAP_GB_NUMMER: 48,
    GB_SAP_WE_ID_FKF: '7000/706042',
    GB_SAP_GB_ID_PKF: '7000/706042/48',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552762,
    GB_WE_OBJ_ID_FKT: 1989417,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706044',
    GB_SAP_GB_ID_PKF: '7000/706044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.449492',
    GB_LAENGENGRAD_OESTLICH: '10.135720',
    GB_NETTOGRUNDFL_NGF: 1919.15,
    GB_NUTZFL_NF_VON_NGF: 1780.96,
    GB_TECHNIKFL_TF_VON_NGF: 20.78,
    GB_VERKEHRSFL_VF_VON_NGF: 117.41,
    GB_MIETFL_GESAMT: 1919.15,
    GB_MIETFL_1_BSB: 1919.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992319,
    GB_WE_OBJ_ID_FKT: 1989419,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1525.8,
    GB_NUTZFL_NF_VON_NGF: 1309.08,
    GB_TECHNIKFL_TF_VON_NGF: 64.78,
    GB_VERKEHRSFL_VF_VON_NGF: 151.94,
    GB_MIETFL_GESAMT: 1525.8,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1525.8,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992050,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 35.61,
    GB_NUTZFL_NF_VON_NGF: 35.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 35.61,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992051,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.1,
    GB_NUTZFL_NF_VON_NGF: 104.32,
    GB_TECHNIKFL_TF_VON_NGF: 12.74,
    GB_VERKEHRSFL_VF_VON_NGF: 33.04,
    GB_MIETFL_GESAMT: 150.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 150.1,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992052,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.99,
    GB_NUTZFL_NF_VON_NGF: 32.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992053,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.45,
    GB_NUTZFL_NF_VON_NGF: 137.91,
    GB_TECHNIKFL_TF_VON_NGF: 1.66,
    GB_VERKEHRSFL_VF_VON_NGF: 27.88,
    GB_MIETFL_GESAMT: 167.45,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 167.45,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992054,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/6',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.502763',
    GB_LAENGENGRAD_OESTLICH: '10.201276',
    GB_NETTOGRUNDFL_NGF: 305.44,
    GB_NUTZFL_NF_VON_NGF: 289.89,
    GB_TECHNIKFL_TF_VON_NGF: 7.4,
    GB_VERKEHRSFL_VF_VON_NGF: 8.15,
    GB_MIETFL_GESAMT: 305.44,
    GB_MIETFL_1_BSB: 305.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992055,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1204.51,
    GB_NUTZFL_NF_VON_NGF: 1023.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 180.63,
    GB_MIETFL_GESAMT: 1204.51,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1204.51,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992056,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706005,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706005',
    GB_SAP_GB_ID_PKF: '7000/706005/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 5336730,
    GB_WE_OBJ_ID_FKT: 1989381,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1432.31,
    GB_NUTZFL_NF_VON_NGF: 1000.97,
    GB_TECHNIKFL_TF_VON_NGF: 79.07,
    GB_VERKEHRSFL_VF_VON_NGF: 352.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992071,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 630.96,
    GB_NUTZFL_NF_VON_NGF: 455.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 175.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992072,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.34,
    GB_NUTZFL_NF_VON_NGF: 168.8,
    GB_TECHNIKFL_TF_VON_NGF: 5.94,
    GB_VERKEHRSFL_VF_VON_NGF: 30.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992073,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 630.96,
    GB_NUTZFL_NF_VON_NGF: 455.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 175.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992074,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 385.77,
    GB_NUTZFL_NF_VON_NGF: 365.71,
    GB_TECHNIKFL_TF_VON_NGF: 9.51,
    GB_VERKEHRSFL_VF_VON_NGF: 10.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992075,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.39,
    GB_NUTZFL_NF_VON_NGF: 329.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 70.95,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992076,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.82,
    GB_NUTZFL_NF_VON_NGF: 14.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992077,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.97,
    GB_NUTZFL_NF_VON_NGF: 33.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992078,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 20.38,
    GB_NUTZFL_NF_VON_NGF: 20.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1992079,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6996623,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7007981,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706008',
    GB_SAP_GB_ID_PKF: '7000/706008/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7364527,
    GB_WE_OBJ_ID_FKT: 1989384,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2812.51,
    GB_NUTZFL_NF_VON_NGF: 2095.82,
    GB_TECHNIKFL_TF_VON_NGF: 52.99,
    GB_VERKEHRSFL_VF_VON_NGF: 663.7,
    GB_MIETFL_GESAMT: 2812.51,
    GB_MIETFL_1_BSB: 2759.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.11.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992177,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 650.48,
    GB_NUTZFL_NF_VON_NGF: 565.3,
    GB_TECHNIKFL_TF_VON_NGF: 1.07,
    GB_VERKEHRSFL_VF_VON_NGF: 84.11,
    GB_MIETFL_GESAMT: 650.48,
    GB_MIETFL_1_BSB: 650.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992178,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1992179,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 403.61,
    GB_NUTZFL_NF_VON_NGF: 331.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1992180,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1992181,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.39,
    GB_NUTZFL_NF_VON_NGF: 389.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 98.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.07.16',
    GB_GB_OBJ_ID_PKT: 1992182,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 339.66,
    GB_NUTZFL_NF_VON_NGF: 268.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 70.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.07.16',
    GB_GB_OBJ_ID_PKT: 1992183,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 635.4,
    GB_NUTZFL_NF_VON_NGF: 537.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 98.09,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.07.16',
    GB_GB_OBJ_ID_PKT: 1992184,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: '53.504306',
    GB_LAENGENGRAD_OESTLICH: '10.207799',
    GB_NETTOGRUNDFL_NGF: 1733.19,
    GB_NUTZFL_NF_VON_NGF: 1607.1,
    GB_TECHNIKFL_TF_VON_NGF: 35.5,
    GB_VERKEHRSFL_VF_VON_NGF: 90.59,
    GB_MIETFL_GESAMT: 1733.19,
    GB_MIETFL_1_BSB: 1733.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992185,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5167.68,
    GB_NUTZFL_NF_VON_NGF: 3313.4,
    GB_TECHNIKFL_TF_VON_NGF: 343.23,
    GB_VERKEHRSFL_VF_VON_NGF: 1511.05,
    GB_MIETFL_GESAMT: 5167.68,
    GB_MIETFL_1_BSB: 4799.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402902,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706020,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706020',
    GB_SAP_GB_ID_PKF: '7000/706020/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2956.86,
    GB_NUTZFL_NF_VON_NGF: 2362.9,
    GB_TECHNIKFL_TF_VON_NGF: 49.8,
    GB_VERKEHRSFL_VF_VON_NGF: 544.16,
    GB_MIETFL_GESAMT: 2956.86,
    GB_MIETFL_1_BSB: 2955.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402903,
    GB_WE_OBJ_ID_FKT: 1989396,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4597.94,
    GB_NUTZFL_NF_VON_NGF: 3315.03,
    GB_TECHNIKFL_TF_VON_NGF: 69.76,
    GB_VERKEHRSFL_VF_VON_NGF: 1213.15,
    GB_MIETFL_GESAMT: 4597.94,
    GB_MIETFL_1_BSB: 4246.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992186,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2230.17,
    GB_NUTZFL_NF_VON_NGF: 1931.7,
    GB_TECHNIKFL_TF_VON_NGF: 37.74,
    GB_VERKEHRSFL_VF_VON_NGF: 260.73,
    GB_MIETFL_GESAMT: 2230.17,
    GB_MIETFL_1_BSB: 2132.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992187,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1017.78,
    GB_NUTZFL_NF_VON_NGF: 833.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 184.5,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992188,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.06,
    GB_NUTZFL_NF_VON_NGF: 356.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992189,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.22,
    GB_NUTZFL_NF_VON_NGF: 356.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992190,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992191,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.8,
    GB_NUTZFL_NF_VON_NGF: 190.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.25,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992192,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.79,
    GB_NUTZFL_NF_VON_NGF: 190.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992193,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.98,
    GB_NUTZFL_NF_VON_NGF: 190.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.25,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992194,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.05,
    GB_NUTZFL_NF_VON_NGF: 174.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992195,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.08,
    GB_NUTZFL_NF_VON_NGF: 174.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992196,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.11,
    GB_NUTZFL_NF_VON_NGF: 174.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992197,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.07.22',
    GB_GB_OBJ_ID_PKT: 1992198,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.506576',
    GB_LAENGENGRAD_OESTLICH: '10.207258',
    GB_NETTOGRUNDFL_NGF: 624.51,
    GB_NUTZFL_NF_VON_NGF: 585.28,
    GB_TECHNIKFL_TF_VON_NGF: 19.75,
    GB_VERKEHRSFL_VF_VON_NGF: 19.48,
    GB_MIETFL_GESAMT: 624.51,
    GB_MIETFL_1_BSB: 624.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992199,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706021,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/706021',
    GB_SAP_GB_ID_PKF: '7000/706021/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6483.49,
    GB_NUTZFL_NF_VON_NGF: 4993.01,
    GB_TECHNIKFL_TF_VON_NGF: 37.77,
    GB_VERKEHRSFL_VF_VON_NGF: 1452.71,
    GB_MIETFL_GESAMT: 6483.49,
    GB_MIETFL_1_BSB: 6472.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.11.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402904,
    GB_WE_OBJ_ID_FKT: 1989397,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706018',
    GB_SAP_GB_ID_PKF: '7000/706018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2557.79,
    GB_NUTZFL_NF_VON_NGF: 1997.12,
    GB_TECHNIKFL_TF_VON_NGF: 33.7,
    GB_VERKEHRSFL_VF_VON_NGF: 526.97,
    GB_MIETFL_GESAMT: 2557.79,
    GB_MIETFL_1_BSB: 2457.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992146,
    GB_WE_OBJ_ID_FKT: 1989394,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706018',
    GB_SAP_GB_ID_PKF: '7000/706018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.516434',
    GB_LAENGENGRAD_OESTLICH: '10.168819',
    GB_NETTOGRUNDFL_NGF: 702.4,
    GB_NUTZFL_NF_VON_NGF: 656.61,
    GB_TECHNIKFL_TF_VON_NGF: 14.01,
    GB_VERKEHRSFL_VF_VON_NGF: 31.78,
    GB_MIETFL_GESAMT: 702.4,
    GB_MIETFL_1_BSB: 702.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992147,
    GB_WE_OBJ_ID_FKT: 1989394,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706018',
    GB_SAP_GB_ID_PKF: '7000/706018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 648.98,
    GB_NUTZFL_NF_VON_NGF: 500.79,
    GB_TECHNIKFL_TF_VON_NGF: 6.3,
    GB_VERKEHRSFL_VF_VON_NGF: 141.89,
    GB_MIETFL_GESAMT: 648.98,
    GB_MIETFL_1_BSB: 648.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992148,
    GB_WE_OBJ_ID_FKT: 1989394,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706018',
    GB_SAP_GB_ID_PKF: '7000/706018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Freilufthalle',
    GB_GEBAEUDETYP: 'Freianlage',
    GB_GEBAEUDEART: 'Freilufthalle',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 464.3,
    GB_NUTZFL_NF_VON_NGF: 452.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Mietfrei',
    GB_GEBAEUDE_KLASSE_SEIT: '19.10.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7379454,
    GB_WE_OBJ_ID_FKT: 1989394,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1960.86,
    GB_NUTZFL_NF_VON_NGF: 1602.02,
    GB_TECHNIKFL_TF_VON_NGF: 16.29,
    GB_VERKEHRSFL_VF_VON_NGF: 342.55,
    GB_MIETFL_GESAMT: 1960.86,
    GB_MIETFL_1_BSB: 1948.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992060,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1285.45,
    GB_NUTZFL_NF_VON_NGF: 1010.43,
    GB_TECHNIKFL_TF_VON_NGF: 66.02,
    GB_VERKEHRSFL_VF_VON_NGF: 209.0,
    GB_MIETFL_GESAMT: 1285.45,
    GB_MIETFL_1_BSB: 1283.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992061,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 494.07,
    GB_NUTZFL_NF_VON_NGF: 412.83,
    GB_TECHNIKFL_TF_VON_NGF: 20.2,
    GB_VERKEHRSFL_VF_VON_NGF: 61.04,
    GB_MIETFL_GESAMT: 494.07,
    GB_MIETFL_1_BSB: 494.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992062,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.36,
    GB_NUTZFL_NF_VON_NGF: 87.53,
    GB_TECHNIKFL_TF_VON_NGF: 4.06,
    GB_VERKEHRSFL_VF_VON_NGF: 11.77,
    GB_MIETFL_GESAMT: 103.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992063,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.74,
    GB_NUTZFL_NF_VON_NGF: 14.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992064,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.64,
    GB_NUTZFL_NF_VON_NGF: 15.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.64,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992065,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3622.32,
    GB_NUTZFL_NF_VON_NGF: 2763.16,
    GB_TECHNIKFL_TF_VON_NGF: 35.97,
    GB_VERKEHRSFL_VF_VON_NGF: 823.19,
    GB_MIETFL_GESAMT: 3622.32,
    GB_MIETFL_1_BSB: 2864.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992066,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2319.84,
    GB_NUTZFL_NF_VON_NGF: 1768.08,
    GB_TECHNIKFL_TF_VON_NGF: 85.35,
    GB_VERKEHRSFL_VF_VON_NGF: 466.41,
    GB_MIETFL_GESAMT: 2319.84,
    GB_MIETFL_1_BSB: 2017.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.06.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992067,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1159.13,
    GB_NUTZFL_NF_VON_NGF: 935.92,
    GB_TECHNIKFL_TF_VON_NGF: 6.8,
    GB_VERKEHRSFL_VF_VON_NGF: 216.41,
    GB_MIETFL_GESAMT: 1159.13,
    GB_MIETFL_1_BSB: 1159.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.05.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992068,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3127.71,
    GB_NUTZFL_NF_VON_NGF: 2197.4,
    GB_TECHNIKFL_TF_VON_NGF: 97.99,
    GB_VERKEHRSFL_VF_VON_NGF: 832.32,
    GB_MIETFL_GESAMT: 3127.71,
    GB_MIETFL_1_BSB: 3020.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992069,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 145.36,
    GB_NUTZFL_NF_VON_NGF: 145.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 145.36,
    GB_MIETFL_1_BSB: 145.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992070,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.494786',
    GB_LAENGENGRAD_OESTLICH: '10.186318',
    GB_NETTOGRUNDFL_NGF: 2689.2,
    GB_NUTZFL_NF_VON_NGF: 1965.57,
    GB_TECHNIKFL_TF_VON_NGF: 83.35,
    GB_VERKEHRSFL_VF_VON_NGF: 640.28,
    GB_MIETFL_GESAMT: 2689.2,
    GB_MIETFL_1_BSB: 2689.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.10.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078346,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3862.34,
    GB_NUTZFL_NF_VON_NGF: 3048.85,
    GB_TECHNIKFL_TF_VON_NGF: 61.88,
    GB_VERKEHRSFL_VF_VON_NGF: 751.61,
    GB_MIETFL_GESAMT: 3862.34,
    GB_MIETFL_1_BSB: 3862.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401746,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706007,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706007',
    GB_SAP_GB_ID_PKF: '7000/706007/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ?ude Baubeginn 2013',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5402901,
    GB_WE_OBJ_ID_FKT: 1989383,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4479.89,
    GB_NUTZFL_NF_VON_NGF: 3295.69,
    GB_TECHNIKFL_TF_VON_NGF: 99.58,
    GB_VERKEHRSFL_VF_VON_NGF: 1084.62,
    GB_MIETFL_GESAMT: 4479.89,
    GB_MIETFL_1_BSB: 4226.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992149,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.2,
    GB_NUTZFL_NF_VON_NGF: 16.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.2,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992150,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.2,
    GB_NUTZFL_NF_VON_NGF: 16.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.2,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992172,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.01.20',
    GB_GB_OBJ_ID_PKT: 1992174,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.01.20',
    GB_GB_OBJ_ID_PKT: 1992175,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachklassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 606.52,
    GB_NUTZFL_NF_VON_NGF: 464.73,
    GB_TECHNIKFL_TF_VON_NGF: 6.98,
    GB_VERKEHRSFL_VF_VON_NGF: 134.81,
    GB_MIETFL_GESAMT: 606.52,
    GB_MIETFL_1_BSB: 601.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '13.11.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455806,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.496501',
    GB_LAENGENGRAD_OESTLICH: '10.205430',
    GB_NETTOGRUNDFL_NGF: 616.11,
    GB_NUTZFL_NF_VON_NGF: 591.05,
    GB_TECHNIKFL_TF_VON_NGF: 6.26,
    GB_VERKEHRSFL_VF_VON_NGF: 18.8,
    GB_MIETFL_GESAMT: 616.11,
    GB_MIETFL_1_BSB: 616.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992173,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: '53.496773',
    GB_LAENGENGRAD_OESTLICH: '10.206718',
    GB_NETTOGRUNDFL_NGF: 624.15,
    GB_NUTZFL_NF_VON_NGF: 571.93,
    GB_TECHNIKFL_TF_VON_NGF: 21.01,
    GB_VERKEHRSFL_VF_VON_NGF: 31.21,
    GB_MIETFL_GESAMT: 624.15,
    GB_MIETFL_1_BSB: 513.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.07.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992176,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/9',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455807,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706019,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706019',
    GB_SAP_GB_ID_PKF: '7000/706019/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7140425,
    GB_WE_OBJ_ID_FKT: 1989395,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.45,
    GB_NUTZFL_NF_VON_NGF: 168.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.09,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.04.22',
    GB_GB_OBJ_ID_PKT: 1992297,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.71,
    GB_NUTZFL_NF_VON_NGF: 147.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.04.22',
    GB_GB_OBJ_ID_PKT: 1992298,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1992299,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1992300,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1992301,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1992302,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5456170,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7012604,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.04.22',
    GB_GB_OBJ_ID_PKT: 7373710,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 876.05,
    GB_NUTZFL_NF_VON_NGF: 711.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 164.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 6776675,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 586.23,
    GB_NUTZFL_NF_VON_NGF: 463.32,
    GB_TECHNIKFL_TF_VON_NGF: 3.75,
    GB_VERKEHRSFL_VF_VON_NGF: 119.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 6776676,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 392.29,
    GB_NUTZFL_NF_VON_NGF: 322.92,
    GB_TECHNIKFL_TF_VON_NGF: 3.75,
    GB_VERKEHRSFL_VF_VON_NGF: 65.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 6776677,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706041,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/706041',
    GB_SAP_GB_ID_PKF: '7000/706041/43',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 290.82,
    GB_NUTZFL_NF_VON_NGF: 259.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.73,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 6776678,
    GB_WE_OBJ_ID_FKT: 1989416,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 554.84,
    GB_NUTZFL_NF_VON_NGF: 417.05,
    GB_TECHNIKFL_TF_VON_NGF: 31.98,
    GB_VERKEHRSFL_VF_VON_NGF: 105.81,
    GB_MIETFL_GESAMT: 554.84,
    GB_MIETFL_1_BSB: 524.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.07.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992203,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.72,
    GB_NUTZFL_NF_VON_NGF: 376.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 57.06,
    GB_MIETFL_GESAMT: 433.72,
    GB_MIETFL_1_BSB: 433.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992204,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.01,
    GB_NUTZFL_NF_VON_NGF: 359.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 73.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '25.03.22',
    GB_GB_OBJ_ID_PKT: 1992205,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.18,
    GB_NUTZFL_NF_VON_NGF: 190.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.89,
    GB_MIETFL_GESAMT: 217.18,
    GB_MIETFL_1_BSB: 217.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992206,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 216.19,
    GB_NUTZFL_NF_VON_NGF: 185.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.3,
    GB_MIETFL_GESAMT: 216.19,
    GB_MIETFL_1_BSB: 216.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992207,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.91,
    GB_NUTZFL_NF_VON_NGF: 190.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.28,
    GB_MIETFL_GESAMT: 217.91,
    GB_MIETFL_1_BSB: 217.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992208,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 218.82,
    GB_NUTZFL_NF_VON_NGF: 180.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.84,
    GB_MIETFL_GESAMT: 218.82,
    GB_MIETFL_1_BSB: 218.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992209,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.506810',
    GB_LAENGENGRAD_OESTLICH: '10.217857',
    GB_NETTOGRUNDFL_NGF: 303.94,
    GB_NUTZFL_NF_VON_NGF: 280.87,
    GB_TECHNIKFL_TF_VON_NGF: 14.68,
    GB_VERKEHRSFL_VF_VON_NGF: 8.39,
    GB_MIETFL_GESAMT: 303.94,
    GB_MIETFL_1_BSB: 303.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992210,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 149.72,
    GB_NUTZFL_NF_VON_NGF: 107.17,
    GB_TECHNIKFL_TF_VON_NGF: 6.54,
    GB_VERKEHRSFL_VF_VON_NGF: 36.01,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.08.17',
    GB_GB_OBJ_ID_PKT: 1992211,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 676.68,
    GB_NUTZFL_NF_VON_NGF: 592.96,
    GB_TECHNIKFL_TF_VON_NGF: 35.6,
    GB_VERKEHRSFL_VF_VON_NGF: 48.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.15',
    GB_GB_OBJ_ID_PKT: 1992212,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 29.63,
    GB_NUTZFL_NF_VON_NGF: 29.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 29.63,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992213,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.506805',
    GB_LAENGENGRAD_OESTLICH: '10.215937',
    GB_NETTOGRUNDFL_NGF: 660.5,
    GB_NUTZFL_NF_VON_NGF: 614.7,
    GB_TECHNIKFL_TF_VON_NGF: 16.98,
    GB_VERKEHRSFL_VF_VON_NGF: 28.82,
    GB_MIETFL_GESAMT: 660.5,
    GB_MIETFL_1_BSB: 660.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992214,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1363.54,
    GB_NUTZFL_NF_VON_NGF: 990.47,
    GB_TECHNIKFL_TF_VON_NGF: 18.57,
    GB_VERKEHRSFL_VF_VON_NGF: 354.5,
    GB_MIETFL_GESAMT: 1363.54,
    GB_MIETFL_1_BSB: 1357.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402906,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 850.45,
    GB_NUTZFL_NF_VON_NGF: 644.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 206.39,
    GB_MIETFL_GESAMT: 850.45,
    GB_MIETFL_1_BSB: 848.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.09.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7012603,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1340.91,
    GB_NUTZFL_NF_VON_NGF: 1110.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 230.5,
    GB_MIETFL_GESAMT: 1340.91,
    GB_MIETFL_1_BSB: 1338.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.07.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7111364,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706023,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/706023',
    GB_SAP_GB_ID_PKF: '7000/706023/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1335.64,
    GB_NUTZFL_NF_VON_NGF: 1149.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 186.31,
    GB_MIETFL_GESAMT: 1335.64,
    GB_MIETFL_1_BSB: 1333.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7234505,
    GB_WE_OBJ_ID_FKT: 1989399,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 686.51,
    GB_NUTZFL_NF_VON_NGF: 583.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.69,
    GB_MIETFL_GESAMT: 686.51,
    GB_MIETFL_1_BSB: 525.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992215,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1559.26,
    GB_NUTZFL_NF_VON_NGF: 1072.28,
    GB_TECHNIKFL_TF_VON_NGF: 105.92,
    GB_VERKEHRSFL_VF_VON_NGF: 381.06,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.12.22',
    GB_GB_OBJ_ID_PKT: 1992216,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 766.23,
    GB_NUTZFL_NF_VON_NGF: 533.68,
    GB_TECHNIKFL_TF_VON_NGF: 94.93,
    GB_VERKEHRSFL_VF_VON_NGF: 137.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.14',
    GB_GB_OBJ_ID_PKT: 1992217,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 231.02,
    GB_NUTZFL_NF_VON_NGF: 171.89,
    GB_TECHNIKFL_TF_VON_NGF: 5.08,
    GB_VERKEHRSFL_VF_VON_NGF: 54.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.14',
    GB_GB_OBJ_ID_PKT: 1992218,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.506475',
    GB_LAENGENGRAD_OESTLICH: '10.193975',
    GB_NETTOGRUNDFL_NGF: 615.76,
    GB_NUTZFL_NF_VON_NGF: 590.39,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 18.71,
    GB_MIETFL_GESAMT: 615.76,
    GB_MIETFL_1_BSB: 615.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992219,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 434.81,
    GB_NUTZFL_NF_VON_NGF: 380.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.37,
    GB_MIETFL_GESAMT: 434.81,
    GB_MIETFL_1_BSB: 434.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992220,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 435.1,
    GB_NUTZFL_NF_VON_NGF: 380.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.45,
    GB_MIETFL_GESAMT: 435.1,
    GB_MIETFL_1_BSB: 435.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992221,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 739.4,
    GB_NUTZFL_NF_VON_NGF: 650.01,
    GB_TECHNIKFL_TF_VON_NGF: 12.33,
    GB_VERKEHRSFL_VF_VON_NGF: 77.06,
    GB_MIETFL_GESAMT: 739.4,
    GB_MIETFL_1_BSB: 636.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455808,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1341.98,
    GB_NUTZFL_NF_VON_NGF: 1266.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.53,
    GB_MIETFL_GESAMT: 1341.98,
    GB_MIETFL_1_BSB: 1339.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.06.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7094474,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.04.21',
    GB_GB_OBJ_ID_PKT: 7114885,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7114886,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706024,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706024',
    GB_SAP_GB_ID_PKF: '7000/706024/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.20',
    GB_GB_OBJ_ID_PKT: 7114887,
    GB_WE_OBJ_ID_FKT: 1989400,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 989.59,
    GB_NUTZFL_NF_VON_NGF: 833.85,
    GB_TECHNIKFL_TF_VON_NGF: 41.62,
    GB_VERKEHRSFL_VF_VON_NGF: 114.12,
    GB_MIETFL_GESAMT: 989.59,
    GB_MIETFL_1_BSB: 947.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992248,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 966.21,
    GB_NUTZFL_NF_VON_NGF: 857.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.71,
    GB_MIETFL_GESAMT: 966.21,
    GB_MIETFL_1_BSB: 966.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992249,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: '53.512052',
    GB_LAENGENGRAD_OESTLICH: '10.205551',
    GB_NETTOGRUNDFL_NGF: 415.64,
    GB_NUTZFL_NF_VON_NGF: 382.89,
    GB_TECHNIKFL_TF_VON_NGF: 6.7,
    GB_VERKEHRSFL_VF_VON_NGF: 26.05,
    GB_MIETFL_GESAMT: 415.64,
    GB_MIETFL_1_BSB: 415.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992250,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5931019,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6994915,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '25.08.21',
    GB_GB_OBJ_ID_PKT: 6994916,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706028,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706028',
    GB_SAP_GB_ID_PKF: '7000/706028/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '27.04.20',
    GB_GUELTIG_BIS: '31.01.22',
    GB_GB_OBJ_ID_PKT: 7111254,
    GB_WE_OBJ_ID_FKT: 1989404,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1410.59,
    GB_NUTZFL_NF_VON_NGF: 1099.48,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 311.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.21',
    GB_GB_OBJ_ID_PKT: 1992251,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 647.13,
    GB_NUTZFL_NF_VON_NGF: 488.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 158.56,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.21',
    GB_GB_OBJ_ID_PKT: 1992252,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 854.7,
    GB_NUTZFL_NF_VON_NGF: 571.51,
    GB_TECHNIKFL_TF_VON_NGF: 4.94,
    GB_VERKEHRSFL_VF_VON_NGF: 278.25,
    GB_MIETFL_GESAMT: 854.7,
    GB_MIETFL_1_BSB: 847.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992253,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.8,
    GB_NUTZFL_NF_VON_NGF: 148.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.21',
    GB_GB_OBJ_ID_PKT: 1992254,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.07,
    GB_NUTZFL_NF_VON_NGF: 177.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.21',
    GB_GB_OBJ_ID_PKT: 1992255,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.96,
    GB_NUTZFL_NF_VON_NGF: 177.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.21',
    GB_GB_OBJ_ID_PKT: 1992256,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.500684',
    GB_LAENGENGRAD_OESTLICH: '10.190053',
    GB_NETTOGRUNDFL_NGF: 625.37,
    GB_NUTZFL_NF_VON_NGF: 580.14,
    GB_TECHNIKFL_TF_VON_NGF: 26.07,
    GB_VERKEHRSFL_VF_VON_NGF: 19.16,
    GB_MIETFL_GESAMT: 625.37,
    GB_MIETFL_1_BSB: 625.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992257,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 96.44,
    GB_NUTZFL_NF_VON_NGF: 79.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.17',
    GB_GB_OBJ_ID_PKT: 1992258,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: '53.500573',
    GB_LAENGENGRAD_OESTLICH: '10.191098',
    GB_NETTOGRUNDFL_NGF: 558.22,
    GB_NUTZFL_NF_VON_NGF: 526.14,
    GB_TECHNIKFL_TF_VON_NGF: 9.52,
    GB_VERKEHRSFL_VF_VON_NGF: 22.56,
    GB_MIETFL_GESAMT: 558.22,
    GB_MIETFL_1_BSB: 558.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992259,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2410.36,
    GB_NUTZFL_NF_VON_NGF: 1820.07,
    GB_TECHNIKFL_TF_VON_NGF: 67.36,
    GB_VERKEHRSFL_VF_VON_NGF: 522.93,
    GB_MIETFL_GESAMT: 2410.36,
    GB_MIETFL_1_BSB: 2337.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5401747,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/11',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1905.4,
    GB_NUTZFL_NF_VON_NGF: 1495.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 409.49,
    GB_MIETFL_GESAMT: 1905.4,
    GB_MIETFL_1_BSB: 1905.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402912,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3062.25,
    GB_NUTZFL_NF_VON_NGF: 2146.03,
    GB_TECHNIKFL_TF_VON_NGF: 17.83,
    GB_VERKEHRSFL_VF_VON_NGF: 898.39,
    GB_MIETFL_GESAMT: 3062.25,
    GB_MIETFL_1_BSB: 3054.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.04.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5420362,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455809,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7098070,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 301.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352775,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.88,
    GB_NUTZFL_NF_VON_NGF: 301.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.88,
    GB_MIETFL_1_BSB: 338.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352776,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 82.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.22',
    GB_GB_OBJ_ID_PKT: 4352777,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/43',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 250.06,
    GB_NUTZFL_NF_VON_NGF: 223.49,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 23.56,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.22',
    GB_GB_OBJ_ID_PKT: 5921331,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/44',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 39.0,
    GB_NUTZFL_NF_VON_NGF: 39.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.22',
    GB_GB_OBJ_ID_PKT: 6785818,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/45',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 170.0,
    GB_NUTZFL_NF_VON_NGF: 170.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 170.0,
    GB_MIETFL_1_BSB: 170.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6936738,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706029,
    GB_SAP_GB_NUMMER: 46,
    GB_SAP_WE_ID_FKF: '7000/706029',
    GB_SAP_GB_ID_PKF: '7000/706029/46',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.61,
    GB_NUTZFL_NF_VON_NGF: 156.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 156.61,
    GB_MIETFL_1_BSB: 156.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036061,
    GB_WE_OBJ_ID_FKT: 1989405,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5419278,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8911.76,
    GB_NUTZFL_NF_VON_NGF: 6387.68,
    GB_TECHNIKFL_TF_VON_NGF: 200.25,
    GB_VERKEHRSFL_VF_VON_NGF: 2323.83,
    GB_MIETFL_GESAMT: 8911.76,
    GB_MIETFL_1_BSB: 7724.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992080,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 402.73,
    GB_NUTZFL_NF_VON_NGF: 331.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.48,
    GB_MIETFL_GESAMT: 402.73,
    GB_MIETFL_1_BSB: 402.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992081,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 402.73,
    GB_NUTZFL_NF_VON_NGF: 331.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.48,
    GB_MIETFL_GESAMT: 402.73,
    GB_MIETFL_1_BSB: 402.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992082,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 402.73,
    GB_NUTZFL_NF_VON_NGF: 331.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.48,
    GB_MIETFL_GESAMT: 402.73,
    GB_MIETFL_1_BSB: 402.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992083,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 693.76,
    GB_NUTZFL_NF_VON_NGF: 526.56,
    GB_TECHNIKFL_TF_VON_NGF: 20.46,
    GB_VERKEHRSFL_VF_VON_NGF: 146.74,
    GB_MIETFL_GESAMT: 693.76,
    GB_MIETFL_1_BSB: 505.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992084,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 472.21,
    GB_NUTZFL_NF_VON_NGF: 380.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 91.22,
    GB_MIETFL_GESAMT: 472.21,
    GB_MIETFL_1_BSB: 472.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992085,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 695.64,
    GB_NUTZFL_NF_VON_NGF: 549.0,
    GB_TECHNIKFL_TF_VON_NGF: 20.43,
    GB_VERKEHRSFL_VF_VON_NGF: 126.21,
    GB_MIETFL_GESAMT: 695.64,
    GB_MIETFL_1_BSB: 473.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992086,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.507059',
    GB_LAENGENGRAD_OESTLICH: '10.195859',
    GB_NETTOGRUNDFL_NGF: 1720.47,
    GB_NUTZFL_NF_VON_NGF: 1640.9,
    GB_TECHNIKFL_TF_VON_NGF: 21.01,
    GB_VERKEHRSFL_VF_VON_NGF: 58.56,
    GB_MIETFL_GESAMT: 1720.47,
    GB_MIETFL_1_BSB: 1720.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992087,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1387.51,
    GB_NUTZFL_NF_VON_NGF: 1274.6,
    GB_TECHNIKFL_TF_VON_NGF: 6.84,
    GB_VERKEHRSFL_VF_VON_NGF: 106.07,
    GB_MIETFL_GESAMT: 1387.51,
    GB_MIETFL_1_BSB: 1385.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7063956,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 851.87,
    GB_NUTZFL_NF_VON_NGF: 742.86,
    GB_TECHNIKFL_TF_VON_NGF: 8.13,
    GB_VERKEHRSFL_VF_VON_NGF: 100.88,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.17',
    GB_GUELTIG_BIS: '31.07.21',
    GB_GB_OBJ_ID_PKT: 7124366,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706009,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706009',
    GB_SAP_GB_ID_PKF: '7000/706009/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.02.18',
    GB_GUELTIG_BIS: '31.07.21',
    GB_GB_OBJ_ID_PKT: 7124367,
    GB_WE_OBJ_ID_FKT: 1989385,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 947.49,
    GB_NUTZFL_NF_VON_NGF: 720.4,
    GB_TECHNIKFL_TF_VON_NGF: 45.84,
    GB_VERKEHRSFL_VF_VON_NGF: 181.25,
    GB_MIETFL_GESAMT: 947.49,
    GB_MIETFL_1_BSB: 701.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992270,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 567.83,
    GB_NUTZFL_NF_VON_NGF: 358.85,
    GB_TECHNIKFL_TF_VON_NGF: 37.75,
    GB_VERKEHRSFL_VF_VON_NGF: 171.23,
    GB_MIETFL_GESAMT: 567.83,
    GB_MIETFL_1_BSB: 476.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992271,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 286.2,
    GB_NUTZFL_NF_VON_NGF: 233.68,
    GB_TECHNIKFL_TF_VON_NGF: 33.22,
    GB_VERKEHRSFL_VF_VON_NGF: 19.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.11.22',
    GB_GB_OBJ_ID_PKT: 1992272,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 616.52,
    GB_NUTZFL_NF_VON_NGF: 429.26,
    GB_TECHNIKFL_TF_VON_NGF: 69.07,
    GB_VERKEHRSFL_VF_VON_NGF: 118.19,
    GB_MIETFL_GESAMT: 616.52,
    GB_MIETFL_1_BSB: 456.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992273,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 615.33,
    GB_NUTZFL_NF_VON_NGF: 491.94,
    GB_TECHNIKFL_TF_VON_NGF: 4.97,
    GB_VERKEHRSFL_VF_VON_NGF: 118.42,
    GB_MIETFL_GESAMT: 615.33,
    GB_MIETFL_1_BSB: 518.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992274,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.41,
    GB_NUTZFL_NF_VON_NGF: 369.05,
    GB_TECHNIKFL_TF_VON_NGF: 10.37,
    GB_VERKEHRSFL_VF_VON_NGF: 107.99,
    GB_MIETFL_GESAMT: 487.41,
    GB_MIETFL_1_BSB: 415.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992275,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.512946',
    GB_LAENGENGRAD_OESTLICH: '10.159092',
    GB_NETTOGRUNDFL_NGF: 546.37,
    GB_NUTZFL_NF_VON_NGF: 472.8,
    GB_TECHNIKFL_TF_VON_NGF: 11.92,
    GB_VERKEHRSFL_VF_VON_NGF: 61.65,
    GB_MIETFL_GESAMT: 546.37,
    GB_MIETFL_1_BSB: 538.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992276,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 334.99,
    GB_NUTZFL_NF_VON_NGF: 248.25,
    GB_TECHNIKFL_TF_VON_NGF: 9.41,
    GB_VERKEHRSFL_VF_VON_NGF: 77.33,
    GB_MIETFL_GESAMT: 334.99,
    GB_MIETFL_1_BSB: 334.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992277,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 18.0,
    GB_NUTZFL_NF_VON_NGF: 18.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 18.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5422558,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7150749,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706031,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706031',
    GB_SAP_GB_ID_PKF: '7000/706031/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.96,
    GB_NUTZFL_NF_VON_NGF: 76.03,
    GB_TECHNIKFL_TF_VON_NGF: 4.38,
    GB_VERKEHRSFL_VF_VON_NGF: 13.55,
    GB_MIETFL_GESAMT: 93.96,
    GB_MIETFL_1_BSB: 93.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036062,
    GB_WE_OBJ_ID_FKT: 1989407,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 357.64,
    GB_NUTZFL_NF_VON_NGF: 303.04,
    GB_TECHNIKFL_TF_VON_NGF: 4.23,
    GB_VERKEHRSFL_VF_VON_NGF: 50.37,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992035,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 685.4,
    GB_NUTZFL_NF_VON_NGF: 541.32,
    GB_TECHNIKFL_TF_VON_NGF: 6.42,
    GB_VERKEHRSFL_VF_VON_NGF: 137.66,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992036,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992037,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 340.56,
    GB_NUTZFL_NF_VON_NGF: 269.5,
    GB_TECHNIKFL_TF_VON_NGF: 2.37,
    GB_VERKEHRSFL_VF_VON_NGF: 68.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992038,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 231.02,
    GB_NUTZFL_NF_VON_NGF: 171.89,
    GB_TECHNIKFL_TF_VON_NGF: 5.08,
    GB_VERKEHRSFL_VF_VON_NGF: 54.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992039,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 488.72,
    GB_NUTZFL_NF_VON_NGF: 373.05,
    GB_TECHNIKFL_TF_VON_NGF: 6.27,
    GB_VERKEHRSFL_VF_VON_NGF: 109.4,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992040,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 641.55,
    GB_NUTZFL_NF_VON_NGF: 491.41,
    GB_TECHNIKFL_TF_VON_NGF: 8.1,
    GB_VERKEHRSFL_VF_VON_NGF: 142.04,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992041,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706002,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706002',
    GB_SAP_GB_ID_PKF: '7000/706002/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 31.63,
    GB_NUTZFL_NF_VON_NGF: 15.92,
    GB_TECHNIKFL_TF_VON_NGF: 15.71,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.16',
    GB_GB_OBJ_ID_PKT: 1992042,
    GB_WE_OBJ_ID_FKT: 1989378,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706006',
    GB_SAP_GB_ID_PKF: '7000/706006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3649.51,
    GB_NUTZFL_NF_VON_NGF: 2713.38,
    GB_TECHNIKFL_TF_VON_NGF: 74.33,
    GB_VERKEHRSFL_VF_VON_NGF: 861.8,
    GB_MIETFL_GESAMT: 3649.51,
    GB_MIETFL_1_BSB: 3553.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992057,
    GB_WE_OBJ_ID_FKT: 1989382,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706006',
    GB_SAP_GB_ID_PKF: '7000/706006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.481909',
    GB_LAENGENGRAD_OESTLICH: '10.174240',
    GB_NETTOGRUNDFL_NGF: 1237.94,
    GB_NUTZFL_NF_VON_NGF: 1135.28,
    GB_TECHNIKFL_TF_VON_NGF: 35.72,
    GB_VERKEHRSFL_VF_VON_NGF: 66.94,
    GB_MIETFL_GESAMT: 1237.94,
    GB_MIETFL_1_BSB: 1237.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992058,
    GB_WE_OBJ_ID_FKT: 1989382,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706006,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706006',
    GB_SAP_GB_ID_PKF: '7000/706006/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1047.24,
    GB_NUTZFL_NF_VON_NGF: 815.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 232.01,
    GB_MIETFL_GESAMT: 1047.24,
    GB_MIETFL_1_BSB: 1047.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992059,
    GB_WE_OBJ_ID_FKT: 1989382,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706006,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706006',
    GB_SAP_GB_ID_PKF: '7000/706006/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1421.46,
    GB_NUTZFL_NF_VON_NGF: 1095.32,
    GB_TECHNIKFL_TF_VON_NGF: 7.03,
    GB_VERKEHRSFL_VF_VON_NGF: 319.11,
    GB_MIETFL_GESAMT: 1421.46,
    GB_MIETFL_1_BSB: 1419.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402900,
    GB_WE_OBJ_ID_FKT: 1989382,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7061061,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706001',
    GB_SAP_GB_ID_PKF: '7000/706001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4667.58,
    GB_NUTZFL_NF_VON_NGF: 3423.4,
    GB_TECHNIKFL_TF_VON_NGF: 19.61,
    GB_VERKEHRSFL_VF_VON_NGF: 1224.57,
    GB_MIETFL_GESAMT: 4667.58,
    GB_MIETFL_1_BSB: 4627.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992033,
    GB_WE_OBJ_ID_FKT: 1989377,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706001',
    GB_SAP_GB_ID_PKF: '7000/706001/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.486862',
    GB_LAENGENGRAD_OESTLICH: '10.147363',
    GB_NETTOGRUNDFL_NGF: 1448.71,
    GB_NUTZFL_NF_VON_NGF: 1242.04,
    GB_TECHNIKFL_TF_VON_NGF: 49.85,
    GB_VERKEHRSFL_VF_VON_NGF: 156.82,
    GB_MIETFL_GESAMT: 1448.71,
    GB_MIETFL_1_BSB: 1333.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992034,
    GB_WE_OBJ_ID_FKT: 1989377,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706001,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706001',
    GB_SAP_GB_ID_PKF: '7000/706001/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552761,
    GB_WE_OBJ_ID_FKT: 1989377,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10387.83,
    GB_NUTZFL_NF_VON_NGF: 7332.8,
    GB_TECHNIKFL_TF_VON_NGF: 719.45,
    GB_VERKEHRSFL_VF_VON_NGF: 2335.58,
    GB_MIETFL_GESAMT: 10387.83,
    GB_MIETFL_1_BSB: 9697.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992135,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.18,
    GB_NUTZFL_NF_VON_NGF: 131.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.29,
    GB_MIETFL_GESAMT: 139.18,
    GB_MIETFL_1_BSB: 19.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992136,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.28,
    GB_NUTZFL_NF_VON_NGF: 32.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992137,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.487242',
    GB_LAENGENGRAD_OESTLICH: '10.167525',
    GB_NETTOGRUNDFL_NGF: 696.35,
    GB_NUTZFL_NF_VON_NGF: 629.37,
    GB_TECHNIKFL_TF_VON_NGF: 16.92,
    GB_VERKEHRSFL_VF_VON_NGF: 50.06,
    GB_MIETFL_GESAMT: 696.35,
    GB_MIETFL_1_BSB: 696.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992138,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.486959',
    GB_LAENGENGRAD_OESTLICH: '10.168119',
    GB_NETTOGRUNDFL_NGF: 1521.44,
    GB_NUTZFL_NF_VON_NGF: 1121.68,
    GB_TECHNIKFL_TF_VON_NGF: 32.86,
    GB_VERKEHRSFL_VF_VON_NGF: 366.9,
    GB_MIETFL_GESAMT: 1521.44,
    GB_MIETFL_1_BSB: 1521.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992139,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706016,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706016',
    GB_SAP_GB_ID_PKF: '7000/706016/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | bile Klassenr?ume. beendet;"',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 256.78,
    GB_NUTZFL_NF_VON_NGF: 227.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.78,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352773,
    GB_WE_OBJ_ID_FKT: 1989392,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706010',
    GB_SAP_GB_ID_PKF: '7000/706010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4630.32,
    GB_NUTZFL_NF_VON_NGF: 3376.05,
    GB_TECHNIKFL_TF_VON_NGF: 23.23,
    GB_VERKEHRSFL_VF_VON_NGF: 1231.04,
    GB_MIETFL_GESAMT: 4630.32,
    GB_MIETFL_1_BSB: 4586.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992088,
    GB_WE_OBJ_ID_FKT: 1989386,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706010',
    GB_SAP_GB_ID_PKF: '7000/706010/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.486591',
    GB_LAENGENGRAD_OESTLICH: '10.161327',
    GB_NETTOGRUNDFL_NGF: 1437.31,
    GB_NUTZFL_NF_VON_NGF: 1256.86,
    GB_TECHNIKFL_TF_VON_NGF: 52.96,
    GB_VERKEHRSFL_VF_VON_NGF: 127.49,
    GB_MIETFL_GESAMT: 1437.31,
    GB_MIETFL_1_BSB: 1341.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992089,
    GB_WE_OBJ_ID_FKT: 1989386,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5456938,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706043',
    GB_SAP_GB_ID_PKF: '7000/706043/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992314,
    GB_WE_OBJ_ID_FKT: 1989418,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706043',
    GB_SAP_GB_ID_PKF: '7000/706043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.486002',
    GB_LAENGENGRAD_OESTLICH: '10.152196',
    GB_NETTOGRUNDFL_NGF: 7176.86,
    GB_NUTZFL_NF_VON_NGF: 5013.54,
    GB_TECHNIKFL_TF_VON_NGF: 184.73,
    GB_VERKEHRSFL_VF_VON_NGF: 1978.59,
    GB_MIETFL_GESAMT: 7176.86,
    GB_MIETFL_1_BSB: 7119.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992315,
    GB_WE_OBJ_ID_FKT: 1989418,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706043',
    GB_SAP_GB_ID_PKF: '7000/706043/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1394.47,
    GB_NUTZFL_NF_VON_NGF: 1033.67,
    GB_TECHNIKFL_TF_VON_NGF: 19.47,
    GB_VERKEHRSFL_VF_VON_NGF: 341.33,
    GB_MIETFL_GESAMT: 1394.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1384.27,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992316,
    GB_WE_OBJ_ID_FKT: 1989418,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706043',
    GB_SAP_GB_ID_PKF: '7000/706043/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.8,
    GB_NUTZFL_NF_VON_NGF: 33.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.8,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992317,
    GB_WE_OBJ_ID_FKT: 1989418,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706043',
    GB_SAP_GB_ID_PKF: '7000/706043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.485383',
    GB_LAENGENGRAD_OESTLICH: '10.152194',
    GB_NETTOGRUNDFL_NGF: 1757.26,
    GB_NUTZFL_NF_VON_NGF: 1602.04,
    GB_TECHNIKFL_TF_VON_NGF: 35.22,
    GB_VERKEHRSFL_VF_VON_NGF: 120.0,
    GB_MIETFL_GESAMT: 1757.26,
    GB_MIETFL_1_BSB: 1757.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992318,
    GB_WE_OBJ_ID_FKT: 1989418,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706003',
    GB_SAP_GB_ID_PKF: '7000/706003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachklassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5043.2,
    GB_NUTZFL_NF_VON_NGF: 3631.3,
    GB_TECHNIKFL_TF_VON_NGF: 67.45,
    GB_VERKEHRSFL_VF_VON_NGF: 1344.45,
    GB_MIETFL_GESAMT: 5043.2,
    GB_MIETFL_1_BSB: 4763.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992043,
    GB_WE_OBJ_ID_FKT: 1989379,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706003',
    GB_SAP_GB_ID_PKF: '7000/706003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 888.23,
    GB_NUTZFL_NF_VON_NGF: 811.81,
    GB_TECHNIKFL_TF_VON_NGF: 1.93,
    GB_VERKEHRSFL_VF_VON_NGF: 74.49,
    GB_MIETFL_GESAMT: 888.23,
    GB_MIETFL_1_BSB: 886.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992044,
    GB_WE_OBJ_ID_FKT: 1989379,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706003',
    GB_SAP_GB_ID_PKF: '7000/706003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5092.9,
    GB_NUTZFL_NF_VON_NGF: 3558.85,
    GB_TECHNIKFL_TF_VON_NGF: 225.1,
    GB_VERKEHRSFL_VF_VON_NGF: 1308.95,
    GB_MIETFL_GESAMT: 5092.9,
    GB_MIETFL_1_BSB: 4879.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992045,
    GB_WE_OBJ_ID_FKT: 1989379,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706003',
    GB_SAP_GB_ID_PKF: '7000/706003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.484935',
    GB_LAENGENGRAD_OESTLICH: '10.156281',
    GB_NETTOGRUNDFL_NGF: 1863.13,
    GB_NUTZFL_NF_VON_NGF: 1668.26,
    GB_TECHNIKFL_TF_VON_NGF: 50.36,
    GB_VERKEHRSFL_VF_VON_NGF: 144.51,
    GB_MIETFL_GESAMT: 1863.13,
    GB_MIETFL_1_BSB: 1863.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992046,
    GB_WE_OBJ_ID_FKT: 1989379,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7139260,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1657.02,
    GB_NUTZFL_NF_VON_NGF: 1332.36,
    GB_TECHNIKFL_TF_VON_NGF: 19.83,
    GB_VERKEHRSFL_VF_VON_NGF: 304.83,
    GB_MIETFL_GESAMT: 1657.02,
    GB_MIETFL_1_BSB: 1654.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992241,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 248.39,
    GB_NUTZFL_NF_VON_NGF: 208.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 1992242,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.08,
    GB_NUTZFL_NF_VON_NGF: 392.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 1992243,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 1992244,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 533.81,
    GB_NUTZFL_NF_VON_NGF: 462.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 1992245,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.472353',
    GB_LAENGENGRAD_OESTLICH: '10.074202',
    GB_NETTOGRUNDFL_NGF: 622.91,
    GB_NUTZFL_NF_VON_NGF: 593.92,
    GB_TECHNIKFL_TF_VON_NGF: 6.71,
    GB_VERKEHRSFL_VF_VON_NGF: 22.28,
    GB_MIETFL_GESAMT: 622.91,
    GB_MIETFL_1_BSB: 622.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992246,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 218.21,
    GB_NUTZFL_NF_VON_NGF: 111.67,
    GB_TECHNIKFL_TF_VON_NGF: 85.19,
    GB_VERKEHRSFL_VF_VON_NGF: 21.35,
    GB_MIETFL_GESAMT: 218.21,
    GB_MIETFL_1_BSB: 122.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992247,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1464.06,
    GB_NUTZFL_NF_VON_NGF: 1086.26,
    GB_TECHNIKFL_TF_VON_NGF: 35.48,
    GB_VERKEHRSFL_VF_VON_NGF: 342.32,
    GB_MIETFL_GESAMT: 1464.06,
    GB_MIETFL_1_BSB: 1461.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402911,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706027,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/706027',
    GB_SAP_GB_ID_PKF: '7000/706027/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7406022,
    GB_WE_OBJ_ID_FKT: 1989403,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706045',
    GB_SAP_GB_ID_PKF: '7000/706045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1934,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 303.36,
    GB_NUTZFL_NF_VON_NGF: 253.78,
    GB_TECHNIKFL_TF_VON_NGF: 11.87,
    GB_VERKEHRSFL_VF_VON_NGF: 37.71,
    GB_MIETFL_GESAMT: 303.36,
    GB_MIETFL_1_BSB: 303.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2033548,
    GB_WE_OBJ_ID_FKT: 2033547,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706045',
    GB_SAP_GB_ID_PKF: '7000/706045/2',
    GB_SAP_GB_BEZEICHNUNG: 'Schuppen',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Schuppen',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.57,
    GB_NUTZFL_NF_VON_NGF: 137.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 137.57,
    GB_MIETFL_1_BSB: 137.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2033549,
    GB_WE_OBJ_ID_FKT: 2033547,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706045',
    GB_SAP_GB_ID_PKF: '7000/706045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 1934,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 45.88,
    GB_NUTZFL_NF_VON_NGF: 45.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 45.88,
    GB_MIETFL_1_BSB: 45.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2033550,
    GB_WE_OBJ_ID_FKT: 2033547,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5487027,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706037',
    GB_SAP_GB_ID_PKF: '7000/706037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1888,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2135.5,
    GB_NUTZFL_NF_VON_NGF: 1591.48,
    GB_TECHNIKFL_TF_VON_NGF: 18.54,
    GB_VERKEHRSFL_VF_VON_NGF: 525.48,
    GB_MIETFL_GESAMT: 2135.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992294,
    GB_WE_OBJ_ID_FKT: 1989413,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706037',
    GB_SAP_GB_ID_PKF: '7000/706037/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352780,
    GB_WE_OBJ_ID_FKT: 1989413,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706037',
    GB_SAP_GB_ID_PKF: '7000/706037/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.36,
    GB_NUTZFL_NF_VON_NGF: 15.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352781,
    GB_WE_OBJ_ID_FKT: 1989413,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706038',
    GB_SAP_GB_ID_PKF: '7000/706038/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 339.16,
    GB_NUTZFL_NF_VON_NGF: 287.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.16',
    GB_GB_OBJ_ID_PKT: 1992295,
    GB_WE_OBJ_ID_FKT: 1989414,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706034',
    GB_SAP_GB_ID_PKF: '7000/706034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15117.17,
    GB_NUTZFL_NF_VON_NGF: 9591.81,
    GB_TECHNIKFL_TF_VON_NGF: 2479.33,
    GB_VERKEHRSFL_VF_VON_NGF: 3046.03,
    GB_MIETFL_GESAMT: 15117.17,
    GB_MIETFL_1_BSB: 15048.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992288,
    GB_WE_OBJ_ID_FKT: 1989410,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706034',
    GB_SAP_GB_ID_PKF: '7000/706034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 44.04,
    GB_NUTZFL_NF_VON_NGF: 44.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 44.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992289,
    GB_WE_OBJ_ID_FKT: 1989410,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706034',
    GB_SAP_GB_ID_PKF: '7000/706034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.34,
    GB_NUTZFL_NF_VON_NGF: 16.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.34,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992290,
    GB_WE_OBJ_ID_FKT: 1989410,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706034',
    GB_SAP_GB_ID_PKF: '7000/706034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 543.0,
    GB_NUTZFL_NF_VON_NGF: 542.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 0.95,
    GB_MIETFL_GESAMT: 543.0,
    GB_MIETFL_1_BSB: 543.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992291,
    GB_WE_OBJ_ID_FKT: 1989410,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706035',
    GB_SAP_GB_ID_PKF: '7000/706035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12687.21,
    GB_NUTZFL_NF_VON_NGF: 9770.0,
    GB_TECHNIKFL_TF_VON_NGF: 751.07,
    GB_VERKEHRSFL_VF_VON_NGF: 2166.14,
    GB_MIETFL_GESAMT: 12687.21,
    GB_MIETFL_1_BSB: 12677.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992292,
    GB_WE_OBJ_ID_FKT: 1989411,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706035,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/706035',
    GB_SAP_GB_ID_PKF: '7000/706035/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 306.1,
    GB_NUTZFL_NF_VON_NGF: 285.56,
    GB_TECHNIKFL_TF_VON_NGF: 5.43,
    GB_VERKEHRSFL_VF_VON_NGF: 15.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '06.10.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '06.10.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7223166,
    GB_WE_OBJ_ID_FKT: 1989411,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706035,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/706035',
    GB_SAP_GB_ID_PKF: '7000/706035/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 232.94,
    GB_NUTZFL_NF_VON_NGF: 216.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7223167,
    GB_WE_OBJ_ID_FKT: 1989411,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706033',
    GB_SAP_GB_ID_PKF: '7000/706033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14749.03,
    GB_NUTZFL_NF_VON_NGF: 11013.12,
    GB_TECHNIKFL_TF_VON_NGF: 617.45,
    GB_VERKEHRSFL_VF_VON_NGF: 3118.46,
    GB_MIETFL_GESAMT: 14749.03,
    GB_MIETFL_1_BSB: 14318.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992283,
    GB_WE_OBJ_ID_FKT: 1989409,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706033',
    GB_SAP_GB_ID_PKF: '7000/706033/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: '53.492440',
    GB_LAENGENGRAD_OESTLICH: '10.183533',
    GB_NETTOGRUNDFL_NGF: 1387.17,
    GB_NUTZFL_NF_VON_NGF: 1350.31,
    GB_TECHNIKFL_TF_VON_NGF: 17.05,
    GB_VERKEHRSFL_VF_VON_NGF: 19.81,
    GB_MIETFL_GESAMT: 1387.17,
    GB_MIETFL_1_BSB: 1387.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992284,
    GB_WE_OBJ_ID_FKT: 1989409,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/706033',
    GB_SAP_GB_ID_PKF: '7000/706033/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.492912',
    GB_LAENGENGRAD_OESTLICH: '10.183061',
    GB_NETTOGRUNDFL_NGF: 1623.52,
    GB_NUTZFL_NF_VON_NGF: 1542.77,
    GB_TECHNIKFL_TF_VON_NGF: 32.02,
    GB_VERKEHRSFL_VF_VON_NGF: 48.73,
    GB_MIETFL_GESAMT: 1623.52,
    GB_MIETFL_1_BSB: 1623.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992285,
    GB_WE_OBJ_ID_FKT: 1989409,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/706033',
    GB_SAP_GB_ID_PKF: '7000/706033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 608.6,
    GB_NUTZFL_NF_VON_NGF: 458.17,
    GB_TECHNIKFL_TF_VON_NGF: 9.69,
    GB_VERKEHRSFL_VF_VON_NGF: 140.74,
    GB_MIETFL_GESAMT: 608.6,
    GB_MIETFL_1_BSB: 608.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992286,
    GB_WE_OBJ_ID_FKT: 1989409,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/706033',
    GB_SAP_GB_ID_PKF: '7000/706033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 203.13,
    GB_NUTZFL_NF_VON_NGF: 166.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.69,
    GB_MIETFL_GESAMT: 203.13,
    GB_MIETFL_1_BSB: 203.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992287,
    GB_WE_OBJ_ID_FKT: 1989409,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706036',
    GB_SAP_GB_ID_PKF: '7000/706036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1886,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3627.07,
    GB_NUTZFL_NF_VON_NGF: 2804.0,
    GB_TECHNIKFL_TF_VON_NGF: 52.08,
    GB_VERKEHRSFL_VF_VON_NGF: 770.99,
    GB_MIETFL_GESAMT: 3627.07,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 3627.07,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992293,
    GB_WE_OBJ_ID_FKT: 1989412,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708007',
    GB_SAP_GB_ID_PKF: '7000/708007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: '53.497551402',
    GB_LAENGENGRAD_OESTLICH: '10.207718599',
    GB_NETTOGRUNDFL_NGF: 1259.08,
    GB_NUTZFL_NF_VON_NGF: 882.71,
    GB_TECHNIKFL_TF_VON_NGF: 55.98,
    GB_VERKEHRSFL_VF_VON_NGF: 320.39,
    GB_MIETFL_GESAMT: 60.37,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236671,
    GB_WE_OBJ_ID_FKT: 7236647,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708007',
    GB_SAP_GB_ID_PKF: '7000/708007/2',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.497707',
    GB_LAENGENGRAD_OESTLICH: '10.207392',
    GB_NETTOGRUNDFL_NGF: 131.27,
    GB_NUTZFL_NF_VON_NGF: 112.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236672,
    GB_WE_OBJ_ID_FKT: 7236647,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1224.61,
    GB_NUTZFL_NF_VON_NGF: 922.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 301.68,
    GB_MIETFL_GESAMT: 1224.61,
    GB_MIETFL_1_BSB: 1224.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990529,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2398.73,
    GB_NUTZFL_NF_VON_NGF: 1695.11,
    GB_TECHNIKFL_TF_VON_NGF: 63.6,
    GB_VERKEHRSFL_VF_VON_NGF: 640.02,
    GB_MIETFL_GESAMT: 2398.73,
    GB_MIETFL_1_BSB: 2283.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990530,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1982.65,
    GB_NUTZFL_NF_VON_NGF: 1478.81,
    GB_TECHNIKFL_TF_VON_NGF: 15.31,
    GB_VERKEHRSFL_VF_VON_NGF: 488.53,
    GB_MIETFL_GESAMT: 1982.65,
    GB_MIETFL_1_BSB: 1816.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990531,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.608831',
    GB_LAENGENGRAD_OESTLICH: '9.914942',
    GB_NETTOGRUNDFL_NGF: 1719.51,
    GB_NUTZFL_NF_VON_NGF: 1636.91,
    GB_TECHNIKFL_TF_VON_NGF: 21.24,
    GB_VERKEHRSFL_VF_VON_NGF: 61.36,
    GB_MIETFL_GESAMT: 1719.51,
    GB_MIETFL_1_BSB: 1719.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990532,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3119.62,
    GB_NUTZFL_NF_VON_NGF: 2158.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 961.08,
    GB_MIETFL_GESAMT: 3119.62,
    GB_MIETFL_1_BSB: 3008.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990533,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 781.42,
    GB_NUTZFL_NF_VON_NGF: 627.54,
    GB_TECHNIKFL_TF_VON_NGF: 83.43,
    GB_VERKEHRSFL_VF_VON_NGF: 70.45,
    GB_MIETFL_GESAMT: 781.42,
    GB_MIETFL_1_BSB: 695.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990534,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 122.74,
    GB_NUTZFL_NF_VON_NGF: 97.43,
    GB_TECHNIKFL_TF_VON_NGF: 12.14,
    GB_VERKEHRSFL_VF_VON_NGF: 13.17,
    GB_MIETFL_GESAMT: 122.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990535,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703011,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703011',
    GB_SAP_GB_ID_PKF: '7000/703011/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7238606,
    GB_WE_OBJ_ID_FKT: 1989148,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703061,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703061',
    GB_SAP_GB_ID_PKF: '7000/703061/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.607405',
    GB_LAENGENGRAD_OESTLICH: '9.900953',
    GB_NETTOGRUNDFL_NGF: 622.74,
    GB_NUTZFL_NF_VON_NGF: 581.99,
    GB_TECHNIKFL_TF_VON_NGF: 21.37,
    GB_VERKEHRSFL_VF_VON_NGF: 19.38,
    GB_MIETFL_GESAMT: 622.74,
    GB_MIETFL_1_BSB: 622.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990802,
    GB_WE_OBJ_ID_FKT: 1989196,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1766.02,
    GB_NUTZFL_NF_VON_NGF: 1069.67,
    GB_TECHNIKFL_TF_VON_NGF: 33.46,
    GB_VERKEHRSFL_VF_VON_NGF: 662.89,
    GB_MIETFL_GESAMT: 1766.02,
    GB_MIETFL_1_BSB: 1566.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990568,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 669.22,
    GB_NUTZFL_NF_VON_NGF: 603.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.47,
    GB_MIETFL_GESAMT: 669.22,
    GB_MIETFL_1_BSB: 577.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990569,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2062.52,
    GB_NUTZFL_NF_VON_NGF: 1455.82,
    GB_TECHNIKFL_TF_VON_NGF: 94.45,
    GB_VERKEHRSFL_VF_VON_NGF: 512.25,
    GB_MIETFL_GESAMT: 2062.52,
    GB_MIETFL_1_BSB: 1821.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990570,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 383.28,
    GB_NUTZFL_NF_VON_NGF: 342.5,
    GB_TECHNIKFL_TF_VON_NGF: 1.67,
    GB_VERKEHRSFL_VF_VON_NGF: 39.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.08.13',
    GB_GB_OBJ_ID_PKT: 1990571,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.605326',
    GB_LAENGENGRAD_OESTLICH: '9.901617',
    GB_NETTOGRUNDFL_NGF: 661.03,
    GB_NUTZFL_NF_VON_NGF: 613.82,
    GB_TECHNIKFL_TF_VON_NGF: 15.76,
    GB_VERKEHRSFL_VF_VON_NGF: 31.45,
    GB_MIETFL_GESAMT: 661.03,
    GB_MIETFL_1_BSB: 661.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990572,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 653.78,
    GB_NUTZFL_NF_VON_NGF: 541.93,
    GB_TECHNIKFL_TF_VON_NGF: 14.97,
    GB_VERKEHRSFL_VF_VON_NGF: 96.88,
    GB_MIETFL_GESAMT: 653.78,
    GB_MIETFL_1_BSB: 653.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5358588,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5420780,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703017,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703017',
    GB_SAP_GB_ID_PKF: '7000/703017/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552745,
    GB_WE_OBJ_ID_FKT: 1989154,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 451.38,
    GB_NUTZFL_NF_VON_NGF: 375.66,
    GB_TECHNIKFL_TF_VON_NGF: 4.75,
    GB_VERKEHRSFL_VF_VON_NGF: 70.97,
    GB_MIETFL_GESAMT: 451.38,
    GB_MIETFL_1_BSB: 451.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.02.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990573,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.66,
    GB_NUTZFL_NF_VON_NGF: 374.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.28,
    GB_MIETFL_GESAMT: 453.66,
    GB_MIETFL_1_BSB: 453.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '09.11.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990574,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 448.85,
    GB_NUTZFL_NF_VON_NGF: 370.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 78.58,
    GB_MIETFL_GESAMT: 448.85,
    GB_MIETFL_1_BSB: 448.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990575,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.12,
    GB_NUTZFL_NF_VON_NGF: 375.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.79,
    GB_MIETFL_GESAMT: 455.12,
    GB_MIETFL_1_BSB: 455.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990576,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.66,
    GB_NUTZFL_NF_VON_NGF: 373.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.9,
    GB_MIETFL_GESAMT: 453.66,
    GB_MIETFL_1_BSB: 453.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.02.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990577,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.620197',
    GB_LAENGENGRAD_OESTLICH: '9.888987',
    GB_NETTOGRUNDFL_NGF: 658.43,
    GB_NUTZFL_NF_VON_NGF: 618.01,
    GB_TECHNIKFL_TF_VON_NGF: 11.64,
    GB_VERKEHRSFL_VF_VON_NGF: 28.78,
    GB_MIETFL_GESAMT: 658.43,
    GB_MIETFL_1_BSB: 658.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990578,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/7',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 503.1,
    GB_NUTZFL_NF_VON_NGF: 432.45,
    GB_TECHNIKFL_TF_VON_NGF: 43.51,
    GB_VERKEHRSFL_VF_VON_NGF: 27.14,
    GB_MIETFL_GESAMT: 503.1,
    GB_MIETFL_1_BSB: 503.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990579,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 891.8,
    GB_NUTZFL_NF_VON_NGF: 691.23,
    GB_TECHNIKFL_TF_VON_NGF: 17.92,
    GB_VERKEHRSFL_VF_VON_NGF: 182.65,
    GB_MIETFL_GESAMT: 891.8,
    GB_MIETFL_1_BSB: 844.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '24.07.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.15',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5419211,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7049785,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7049786,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '11.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '24.03.16',
    GB_GB_OBJ_ID_PKT: 4352724,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703018,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703018',
    GB_SAP_GB_ID_PKF: '7000/703018/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 180.12,
    GB_NUTZFL_NF_VON_NGF: 159.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.8,
    GB_MIETFL_GESAMT: 180.12,
    GB_MIETFL_1_BSB: 180.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '11.08.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7510188,
    GB_WE_OBJ_ID_FKT: 1989155,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1087.26,
    GB_NUTZFL_NF_VON_NGF: 824.1,
    GB_TECHNIKFL_TF_VON_NGF: 60.51,
    GB_VERKEHRSFL_VF_VON_NGF: 202.65,
    GB_MIETFL_GESAMT: 1087.26,
    GB_MIETFL_1_BSB: 895.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990540,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.96,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.96,
    GB_MIETFL_GESAMT: 93.96,
    GB_MIETFL_1_BSB: 93.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990541,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2454.64,
    GB_NUTZFL_NF_VON_NGF: 1880.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 574.34,
    GB_MIETFL_GESAMT: 1822.77,
    GB_MIETFL_1_BSB: 1822.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990542,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.72,
    GB_NUTZFL_NF_VON_NGF: 79.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.75,
    GB_MIETFL_GESAMT: 90.72,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990543,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 285.46,
    GB_NUTZFL_NF_VON_NGF: 253.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.31,
    GB_MIETFL_GESAMT: 285.46,
    GB_MIETFL_1_BSB: 285.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990544,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 155.19,
    GB_NUTZFL_NF_VON_NGF: 142.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.93,
    GB_MIETFL_GESAMT: 155.19,
    GB_MIETFL_1_BSB: 155.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990545,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 176.61,
    GB_NUTZFL_NF_VON_NGF: 155.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 21.5,
    GB_MIETFL_GESAMT: 176.61,
    GB_MIETFL_1_BSB: 176.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990546,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1085.96,
    GB_NUTZFL_NF_VON_NGF: 928.36,
    GB_TECHNIKFL_TF_VON_NGF: 4.41,
    GB_VERKEHRSFL_VF_VON_NGF: 153.19,
    GB_MIETFL_GESAMT: 1085.96,
    GB_MIETFL_1_BSB: 1080.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990547,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 149.64,
    GB_NUTZFL_NF_VON_NGF: 132.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.33,
    GB_MIETFL_GESAMT: 149.64,
    GB_MIETFL_1_BSB: 149.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990548,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 363.09,
    GB_NUTZFL_NF_VON_NGF: 301.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 58.15,
    GB_MIETFL_GESAMT: 363.09,
    GB_MIETFL_1_BSB: 363.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990549,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 440.62,
    GB_NUTZFL_NF_VON_NGF: 355.27,
    GB_TECHNIKFL_TF_VON_NGF: 3.62,
    GB_VERKEHRSFL_VF_VON_NGF: 81.73,
    GB_MIETFL_GESAMT: 440.62,
    GB_MIETFL_1_BSB: 440.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990550,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lehrschwimmbecken',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lehrschwimmbecken',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990551,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.41,
    GB_NUTZFL_NF_VON_NGF: 156.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.36,
    GB_MIETFL_GESAMT: 164.41,
    GB_MIETFL_1_BSB: 164.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990552,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.612830',
    GB_LAENGENGRAD_OESTLICH: '9.887000',
    GB_NETTOGRUNDFL_NGF: 588.25,
    GB_NUTZFL_NF_VON_NGF: 562.11,
    GB_TECHNIKFL_TF_VON_NGF: 16.63,
    GB_VERKEHRSFL_VF_VON_NGF: 9.51,
    GB_MIETFL_GESAMT: 588.25,
    GB_MIETFL_1_BSB: 588.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990553,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/15',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.613159',
    GB_LAENGENGRAD_OESTLICH: '9.886831',
    GB_NETTOGRUNDFL_NGF: 1119.53,
    GB_NUTZFL_NF_VON_NGF: 1086.96,
    GB_TECHNIKFL_TF_VON_NGF: 10.45,
    GB_VERKEHRSFL_VF_VON_NGF: 22.12,
    GB_MIETFL_GESAMT: 1119.53,
    GB_MIETFL_1_BSB: 1119.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990554,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/16',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3147.9,
    GB_NUTZFL_NF_VON_NGF: 2541.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 606.79,
    GB_MIETFL_GESAMT: 3147.9,
    GB_MIETFL_1_BSB: 3144.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990555,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/17',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1039.23,
    GB_NUTZFL_NF_VON_NGF: 874.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 164.4,
    GB_MIETFL_GESAMT: 1039.23,
    GB_MIETFL_1_BSB: 681.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990556,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/18',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.612146',
    GB_LAENGENGRAD_OESTLICH: '9.887907',
    GB_NETTOGRUNDFL_NGF: 606.14,
    GB_NUTZFL_NF_VON_NGF: 551.84,
    GB_TECHNIKFL_TF_VON_NGF: 18.14,
    GB_VERKEHRSFL_VF_VON_NGF: 36.16,
    GB_MIETFL_GESAMT: 606.14,
    GB_MIETFL_1_BSB: 606.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '28.04.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406742,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703013,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703013',
    GB_SAP_GB_ID_PKF: '7000/703013/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552744,
    GB_WE_OBJ_ID_FKT: 1989150,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1581.78,
    GB_NUTZFL_NF_VON_NGF: 1259.36,
    GB_TECHNIKFL_TF_VON_NGF: 111.52,
    GB_VERKEHRSFL_VF_VON_NGF: 210.9,
    GB_MIETFL_GESAMT: 1581.78,
    GB_MIETFL_1_BSB: 1200.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990797,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 483.61,
    GB_NUTZFL_NF_VON_NGF: 380.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.48,
    GB_MIETFL_GESAMT: 483.61,
    GB_MIETFL_1_BSB: 483.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990798,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 964.3,
    GB_NUTZFL_NF_VON_NGF: 781.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 182.51,
    GB_MIETFL_GESAMT: 964.3,
    GB_MIETFL_1_BSB: 964.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990799,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 965.39,
    GB_NUTZFL_NF_VON_NGF: 763.77,
    GB_TECHNIKFL_TF_VON_NGF: 4.11,
    GB_VERKEHRSFL_VF_VON_NGF: 197.51,
    GB_MIETFL_GESAMT: 965.39,
    GB_MIETFL_1_BSB: 965.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990800,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/5',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.38,
    GB_NUTZFL_NF_VON_NGF: 33.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.38,
    GB_MIETFL_1_BSB: 33.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990801,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Sporthalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.608971595',
    GB_LAENGENGRAD_OESTLICH: '9.8952151023',
    GB_NETTOGRUNDFL_NGF: 1766.03,
    GB_NUTZFL_NF_VON_NGF: 1662.62,
    GB_TECHNIKFL_TF_VON_NGF: 38.18,
    GB_VERKEHRSFL_VF_VON_NGF: 65.23,
    GB_MIETFL_GESAMT: 1766.03,
    GB_MIETFL_1_BSB: 1766.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '13.06.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6979083,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703060,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703060',
    GB_SAP_GB_ID_PKF: '7000/703060/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552748,
    GB_WE_OBJ_ID_FKT: 1989195,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/1',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 523.59,
    GB_NUTZFL_NF_VON_NGF: 486.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.84,
    GB_MIETFL_GESAMT: 523.59,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 406.89,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990716,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.8,
    GB_NUTZFL_NF_VON_NGF: 119.51,
    GB_TECHNIKFL_TF_VON_NGF: 22.0,
    GB_VERKEHRSFL_VF_VON_NGF: 21.29,
    GB_MIETFL_GESAMT: 162.8,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990717,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1553.61,
    GB_NUTZFL_NF_VON_NGF: 1004.71,
    GB_TECHNIKFL_TF_VON_NGF: 106.36,
    GB_VERKEHRSFL_VF_VON_NGF: 442.54,
    GB_MIETFL_GESAMT: 1553.61,
    GB_MIETFL_1_BSB: 1439.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990718,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.612072',
    GB_LAENGENGRAD_OESTLICH: '9.911424',
    GB_NETTOGRUNDFL_NGF: 624.57,
    GB_NUTZFL_NF_VON_NGF: 595.91,
    GB_TECHNIKFL_TF_VON_NGF: 8.49,
    GB_VERKEHRSFL_VF_VON_NGF: 20.17,
    GB_MIETFL_GESAMT: 624.57,
    GB_MIETFL_1_BSB: 624.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990719,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1248.93,
    GB_NUTZFL_NF_VON_NGF: 912.05,
    GB_TECHNIKFL_TF_VON_NGF: 19.58,
    GB_VERKEHRSFL_VF_VON_NGF: 317.3,
    GB_MIETFL_GESAMT: 1248.93,
    GB_MIETFL_1_BSB: 1248.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.02.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990720,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703040',
    GB_SAP_GB_ID_PKF: '7000/703040/6',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 869.43,
    GB_NUTZFL_NF_VON_NGF: 726.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 142.45,
    GB_MIETFL_GESAMT: 869.43,
    GB_MIETFL_1_BSB: 866.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.03.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5419014,
    GB_WE_OBJ_ID_FKT: 1989176,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703053',
    GB_SAP_GB_ID_PKF: '7000/703053/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990788,
    GB_WE_OBJ_ID_FKT: 1989189,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703002',
    GB_SAP_GB_ID_PKF: '7000/703002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1902,
    GB_BREITENGRAD_NOERDLICH: '53.575561',
    GB_LAENGENGRAD_OESTLICH: '9.968075',
    GB_NETTOGRUNDFL_NGF: 4782.91,
    GB_NUTZFL_NF_VON_NGF: 3670.77,
    GB_TECHNIKFL_TF_VON_NGF: 64.13,
    GB_VERKEHRSFL_VF_VON_NGF: 1048.01,
    GB_MIETFL_GESAMT: 4782.91,
    GB_MIETFL_1_BSB: 3777.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990430,
    GB_WE_OBJ_ID_FKT: 1989139,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703002,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703002',
    GB_SAP_GB_ID_PKF: '7000/703002/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 79.97,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.12.21',
    GB_GB_OBJ_ID_PKT: 5325173,
    GB_WE_OBJ_ID_FKT: 1989139,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703024',
    GB_SAP_GB_ID_PKF: '7000/703024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1934,
    GB_BREITENGRAD_NOERDLICH: '53.574543',
    GB_LAENGENGRAD_OESTLICH: '9.974042',
    GB_NETTOGRUNDFL_NGF: 7460.33,
    GB_NUTZFL_NF_VON_NGF: 5011.25,
    GB_TECHNIKFL_TF_VON_NGF: 151.53,
    GB_VERKEHRSFL_VF_VON_NGF: 2297.55,
    GB_MIETFL_GESAMT: 7460.33,
    GB_MIETFL_1_BSB: 7284.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990602,
    GB_WE_OBJ_ID_FKT: 1989161,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703024',
    GB_SAP_GB_ID_PKF: '7000/703024/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2017.91,
    GB_NUTZFL_NF_VON_NGF: 1622.13,
    GB_TECHNIKFL_TF_VON_NGF: 14.63,
    GB_VERKEHRSFL_VF_VON_NGF: 381.15,
    GB_MIETFL_GESAMT: 2017.91,
    GB_MIETFL_1_BSB: 2011.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990603,
    GB_WE_OBJ_ID_FKT: 1989161,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703024',
    GB_SAP_GB_ID_PKF: '7000/703024/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3315.85,
    GB_NUTZFL_NF_VON_NGF: 2515.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 800.6,
    GB_MIETFL_GESAMT: 3315.85,
    GB_MIETFL_1_BSB: 2984.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990604,
    GB_WE_OBJ_ID_FKT: 1989161,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703020',
    GB_SAP_GB_ID_PKF: '7000/703020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5527.76,
    GB_NUTZFL_NF_VON_NGF: 3889.02,
    GB_TECHNIKFL_TF_VON_NGF: 93.16,
    GB_VERKEHRSFL_VF_VON_NGF: 1545.58,
    GB_MIETFL_GESAMT: 5527.76,
    GB_MIETFL_1_BSB: 4454.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990584,
    GB_WE_OBJ_ID_FKT: 1989157,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703020',
    GB_SAP_GB_ID_PKF: '7000/703020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: '53.575293',
    GB_LAENGENGRAD_OESTLICH: '9.971092',
    GB_NETTOGRUNDFL_NGF: 766.62,
    GB_NUTZFL_NF_VON_NGF: 698.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.56,
    GB_MIETFL_GESAMT: 766.62,
    GB_MIETFL_1_BSB: 708.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990585,
    GB_WE_OBJ_ID_FKT: 1989157,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703014',
    GB_SAP_GB_ID_PKF: '7000/703014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1923,
    GB_BREITENGRAD_NOERDLICH: '53.571601',
    GB_LAENGENGRAD_OESTLICH: '9.972500',
    GB_NETTOGRUNDFL_NGF: 7080.95,
    GB_NUTZFL_NF_VON_NGF: 4640.41,
    GB_TECHNIKFL_TF_VON_NGF: 386.4,
    GB_VERKEHRSFL_VF_VON_NGF: 2054.14,
    GB_MIETFL_GESAMT: 7080.95,
    GB_MIETFL_1_BSB: 5694.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990557,
    GB_WE_OBJ_ID_FKT: 1989151,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703014',
    GB_SAP_GB_ID_PKF: '7000/703014/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1990558,
    GB_WE_OBJ_ID_FKT: 1989151,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703014,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703014',
    GB_SAP_GB_ID_PKF: '7000/703014/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 180.32,
    GB_NUTZFL_NF_VON_NGF: 163.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.24,
    GB_MIETFL_GESAMT: 180.32,
    GB_MIETFL_1_BSB: 180.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352723,
    GB_WE_OBJ_ID_FKT: 1989151,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703004',
    GB_SAP_GB_ID_PKF: '7000/703004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1424.22,
    GB_NUTZFL_NF_VON_NGF: 1071.91,
    GB_TECHNIKFL_TF_VON_NGF: 37.13,
    GB_VERKEHRSFL_VF_VON_NGF: 315.18,
    GB_MIETFL_GESAMT: 1424.22,
    GB_MIETFL_1_BSB: 949.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990452,
    GB_WE_OBJ_ID_FKT: 1989141,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703004',
    GB_SAP_GB_ID_PKF: '7000/703004/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1746.94,
    GB_NUTZFL_NF_VON_NGF: 1127.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 619.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.01.19',
    GB_GB_OBJ_ID_PKT: 1990453,
    GB_WE_OBJ_ID_FKT: 1989141,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703004',
    GB_SAP_GB_ID_PKF: '7000/703004/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2537.5,
    GB_NUTZFL_NF_VON_NGF: 1968.03,
    GB_TECHNIKFL_TF_VON_NGF: 31.01,
    GB_VERKEHRSFL_VF_VON_NGF: 538.46,
    GB_MIETFL_GESAMT: 2537.5,
    GB_MIETFL_1_BSB: 2528.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.10.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406987,
    GB_WE_OBJ_ID_FKT: 1989141,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703012',
    GB_SAP_GB_ID_PKF: '7000/703012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1905,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4009.14,
    GB_NUTZFL_NF_VON_NGF: 2756.84,
    GB_TECHNIKFL_TF_VON_NGF: 102.74,
    GB_VERKEHRSFL_VF_VON_NGF: 1149.56,
    GB_MIETFL_GESAMT: 4009.14,
    GB_MIETFL_1_BSB: 3394.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990536,
    GB_WE_OBJ_ID_FKT: 1989149,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703012',
    GB_SAP_GB_ID_PKF: '7000/703012/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 896.93,
    GB_NUTZFL_NF_VON_NGF: 765.25,
    GB_TECHNIKFL_TF_VON_NGF: 39.48,
    GB_VERKEHRSFL_VF_VON_NGF: 92.2,
    GB_MIETFL_GESAMT: 896.93,
    GB_MIETFL_1_BSB: 896.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990537,
    GB_WE_OBJ_ID_FKT: 1989149,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703012',
    GB_SAP_GB_ID_PKF: '7000/703012/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.571600',
    GB_LAENGENGRAD_OESTLICH: '9.946585',
    GB_NETTOGRUNDFL_NGF: 594.62,
    GB_NUTZFL_NF_VON_NGF: 563.75,
    GB_TECHNIKFL_TF_VON_NGF: 11.93,
    GB_VERKEHRSFL_VF_VON_NGF: 18.94,
    GB_MIETFL_GESAMT: 594.62,
    GB_MIETFL_1_BSB: 594.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990538,
    GB_WE_OBJ_ID_FKT: 1989149,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703012',
    GB_SAP_GB_ID_PKF: '7000/703012/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 176.61,
    GB_NUTZFL_NF_VON_NGF: 141.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.56,
    GB_MIETFL_GESAMT: 176.61,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990539,
    GB_WE_OBJ_ID_FKT: 1989149,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 306.21,
    GB_NUTZFL_NF_VON_NGF: 271.47,
    GB_TECHNIKFL_TF_VON_NGF: 7.02,
    GB_VERKEHRSFL_VF_VON_NGF: 27.72,
    GB_MIETFL_GESAMT: 306.21,
    GB_MIETFL_1_BSB: 306.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990559,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1990560,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: '53.573464',
    GB_LAENGENGRAD_OESTLICH: '9.973496',
    GB_NETTOGRUNDFL_NGF: 1390.87,
    GB_NUTZFL_NF_VON_NGF: 1359.2,
    GB_TECHNIKFL_TF_VON_NGF: 21.37,
    GB_VERKEHRSFL_VF_VON_NGF: 10.3,
    GB_MIETFL_GESAMT: 1390.87,
    GB_MIETFL_1_BSB: 1390.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990561,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 553.32,
    GB_NUTZFL_NF_VON_NGF: 473.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.19,
    GB_MIETFL_GESAMT: 553.32,
    GB_MIETFL_1_BSB: 553.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306674,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 448.79,
    GB_NUTZFL_NF_VON_NGF: 349.7,
    GB_TECHNIKFL_TF_VON_NGF: 3.5,
    GB_VERKEHRSFL_VF_VON_NGF: 95.59,
    GB_MIETFL_GESAMT: 448.79,
    GB_MIETFL_1_BSB: 448.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306675,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1489.48,
    GB_NUTZFL_NF_VON_NGF: 1085.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 404.21,
    GB_MIETFL_GESAMT: 1489.48,
    GB_MIETFL_1_BSB: 1479.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.03.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418088,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703015,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703015',
    GB_SAP_GB_ID_PKF: '7000/703015/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.17',
    GB_GB_OBJ_ID_PKT: 5306408,
    GB_WE_OBJ_ID_FKT: 1989152,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703069,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703069',
    GB_SAP_GB_ID_PKF: '7000/703069/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.573088',
    GB_LAENGENGRAD_OESTLICH: '9.974373',
    GB_NETTOGRUNDFL_NGF: 1331.81,
    GB_NUTZFL_NF_VON_NGF: 1155.33,
    GB_TECHNIKFL_TF_VON_NGF: 30.05,
    GB_VERKEHRSFL_VF_VON_NGF: 146.43,
    GB_MIETFL_GESAMT: 1331.81,
    GB_MIETFL_1_BSB: 1331.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6830386,
    GB_WE_OBJ_ID_FKT: 6830384,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703027',
    GB_SAP_GB_ID_PKF: '7000/703027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7561.88,
    GB_NUTZFL_NF_VON_NGF: 5145.04,
    GB_TECHNIKFL_TF_VON_NGF: 552.05,
    GB_VERKEHRSFL_VF_VON_NGF: 1864.79,
    GB_MIETFL_GESAMT: 7561.88,
    GB_MIETFL_1_BSB: 5639.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.06.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990623,
    GB_WE_OBJ_ID_FKT: 1989164,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703027',
    GB_SAP_GB_ID_PKF: '7000/703027/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 541.96,
    GB_NUTZFL_NF_VON_NGF: 373.02,
    GB_TECHNIKFL_TF_VON_NGF: 9.69,
    GB_VERKEHRSFL_VF_VON_NGF: 159.25,
    GB_MIETFL_GESAMT: 541.96,
    GB_MIETFL_1_BSB: 541.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990624,
    GB_WE_OBJ_ID_FKT: 1989164,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703027',
    GB_SAP_GB_ID_PKF: '7000/703027/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 264.5,
    GB_NUTZFL_NF_VON_NGF: 252.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.67,
    GB_MIETFL_GESAMT: 264.5,
    GB_MIETFL_1_BSB: 264.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '02.08.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306676,
    GB_WE_OBJ_ID_FKT: 1989164,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1905,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4500.36,
    GB_NUTZFL_NF_VON_NGF: 2778.02,
    GB_TECHNIKFL_TF_VON_NGF: 104.86,
    GB_VERKEHRSFL_VF_VON_NGF: 1617.48,
    GB_MIETFL_GESAMT: 4500.36,
    GB_MIETFL_1_BSB: 3868.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990625,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1000.44,
    GB_NUTZFL_NF_VON_NGF: 847.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 152.98,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.04.15',
    GB_GB_OBJ_ID_PKT: 1990626,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.573088',
    GB_LAENGENGRAD_OESTLICH: '9.974373',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.18',
    GB_GB_OBJ_ID_PKT: 5325174,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ssengeb?ude Baubeginn 08/15',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5419942,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.572037',
    GB_LAENGENGRAD_OESTLICH: '9.974181',
    GB_NETTOGRUNDFL_NGF: 2157.57,
    GB_NUTZFL_NF_VON_NGF: 1767.26,
    GB_TECHNIKFL_TF_VON_NGF: 63.99,
    GB_VERKEHRSFL_VF_VON_NGF: 326.32,
    GB_MIETFL_GESAMT: 2157.57,
    GB_MIETFL_1_BSB: 2107.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406743,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703028,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703028',
    GB_SAP_GB_ID_PKF: '7000/703028/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.01.18',
    GB_GB_OBJ_ID_PKT: 5920808,
    GB_WE_OBJ_ID_FKT: 1989165,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703029',
    GB_SAP_GB_ID_PKF: '7000/703029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1908,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4672.17,
    GB_NUTZFL_NF_VON_NGF: 3200.8,
    GB_TECHNIKFL_TF_VON_NGF: 112.38,
    GB_VERKEHRSFL_VF_VON_NGF: 1358.99,
    GB_MIETFL_GESAMT: 4672.17,
    GB_MIETFL_1_BSB: 3587.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990627,
    GB_WE_OBJ_ID_FKT: 1989166,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703029',
    GB_SAP_GB_ID_PKF: '7000/703029/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1908,
    GB_BREITENGRAD_NOERDLICH: '53.581237',
    GB_LAENGENGRAD_OESTLICH: '9.953698',
    GB_NETTOGRUNDFL_NGF: 633.15,
    GB_NUTZFL_NF_VON_NGF: 560.19,
    GB_TECHNIKFL_TF_VON_NGF: 50.77,
    GB_VERKEHRSFL_VF_VON_NGF: 22.19,
    GB_MIETFL_GESAMT: 633.15,
    GB_MIETFL_1_BSB: 482.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.05.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990628,
    GB_WE_OBJ_ID_FKT: 1989166,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703029',
    GB_SAP_GB_ID_PKF: '7000/703029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 777.56,
    GB_NUTZFL_NF_VON_NGF: 632.93,
    GB_TECHNIKFL_TF_VON_NGF: 37.88,
    GB_VERKEHRSFL_VF_VON_NGF: 106.75,
    GB_MIETFL_GESAMT: 777.56,
    GB_MIETFL_1_BSB: 777.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990629,
    GB_WE_OBJ_ID_FKT: 1989166,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703029',
    GB_SAP_GB_ID_PKF: '7000/703029/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 492.43,
    GB_NUTZFL_NF_VON_NGF: 430.33,
    GB_TECHNIKFL_TF_VON_NGF: 7.69,
    GB_VERKEHRSFL_VF_VON_NGF: 54.41,
    GB_MIETFL_GESAMT: 492.43,
    GB_MIETFL_1_BSB: 492.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990630,
    GB_WE_OBJ_ID_FKT: 1989166,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703029,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703029',
    GB_SAP_GB_ID_PKF: '7000/703029/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.46,
    GB_NUTZFL_NF_VON_NGF: 222.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.73,
    GB_MIETFL_GESAMT: 239.46,
    GB_MIETFL_1_BSB: 239.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4731304,
    GB_WE_OBJ_ID_FKT: 1989166,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3636.38,
    GB_NUTZFL_NF_VON_NGF: 2579.88,
    GB_TECHNIKFL_TF_VON_NGF: 43.72,
    GB_VERKEHRSFL_VF_VON_NGF: 1012.78,
    GB_MIETFL_GESAMT: 3636.38,
    GB_MIETFL_1_BSB: 3590.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990698,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 59.23,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.23,
    GB_MIETFL_GESAMT: 59.23,
    GB_MIETFL_1_BSB: 59.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.03.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990699,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 294.69,
    GB_NUTZFL_NF_VON_NGF: 294.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 294.69,
    GB_MIETFL_1_BSB: 294.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.03.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990700,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 998.12,
    GB_NUTZFL_NF_VON_NGF: 698.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 299.89,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1990701,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 390.04,
    GB_NUTZFL_NF_VON_NGF: 259.85,
    GB_TECHNIKFL_TF_VON_NGF: 34.48,
    GB_VERKEHRSFL_VF_VON_NGF: 95.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '26.06.17',
    GB_GB_OBJ_ID_PKT: 1990702,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.573285',
    GB_LAENGENGRAD_OESTLICH: '9.941771',
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.03.17',
    GB_GB_OBJ_ID_PKT: 1990703,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2219.3,
    GB_NUTZFL_NF_VON_NGF: 1846.44,
    GB_TECHNIKFL_TF_VON_NGF: 104.6,
    GB_VERKEHRSFL_VF_VON_NGF: 268.26,
    GB_MIETFL_GESAMT: 2219.3,
    GB_MIETFL_1_BSB: 2219.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.06.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406989,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.06.18',
    GB_GB_OBJ_ID_PKT: 4352731,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 185.06,
    GB_NUTZFL_NF_VON_NGF: 159.94,
    GB_TECHNIKFL_TF_VON_NGF: 6.02,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.05.23',
    GB_GB_OBJ_ID_PKT: 5325175,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703037,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703037',
    GB_SAP_GB_ID_PKF: '7000/703037/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 283.11,
    GB_NUTZFL_NF_VON_NGF: 252.22,
    GB_TECHNIKFL_TF_VON_NGF: 6.02,
    GB_VERKEHRSFL_VF_VON_NGF: 24.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 5325176,
    GB_WE_OBJ_ID_FKT: 1989173,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703070',
    GB_SAP_GB_ID_PKF: '7000/703070/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3253.95,
    GB_NUTZFL_NF_VON_NGF: 2586.16,
    GB_TECHNIKFL_TF_VON_NGF: 94.55,
    GB_VERKEHRSFL_VF_VON_NGF: 573.24,
    GB_MIETFL_GESAMT: 3253.95,
    GB_MIETFL_1_BSB: 3159.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '03.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6950697,
    GB_WE_OBJ_ID_FKT: 6950694,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 711.86,
    GB_NUTZFL_NF_VON_NGF: 585.81,
    GB_TECHNIKFL_TF_VON_NGF: 27.49,
    GB_VERKEHRSFL_VF_VON_NGF: 98.56,
    GB_MIETFL_GESAMT: 711.86,
    GB_MIETFL_1_BSB: 682.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990765,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 576.29,
    GB_NUTZFL_NF_VON_NGF: 323.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 253.07,
    GB_MIETFL_GESAMT: 576.29,
    GB_MIETFL_1_BSB: 576.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990766,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1737.71,
    GB_NUTZFL_NF_VON_NGF: 1203.82,
    GB_TECHNIKFL_TF_VON_NGF: 63.47,
    GB_VERKEHRSFL_VF_VON_NGF: 470.42,
    GB_MIETFL_GESAMT: 1737.71,
    GB_MIETFL_1_BSB: 1642.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990767,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703070,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703070',
    GB_SAP_GB_ID_PKF: '7000/703070/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.579724',
    GB_LAENGENGRAD_OESTLICH: '9.950192',
    GB_NETTOGRUNDFL_NGF: 503.11,
    GB_NUTZFL_NF_VON_NGF: 436.37,
    GB_TECHNIKFL_TF_VON_NGF: 14.12,
    GB_VERKEHRSFL_VF_VON_NGF: 52.62,
    GB_MIETFL_GESAMT: 503.11,
    GB_MIETFL_1_BSB: 503.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '03.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6950698,
    GB_WE_OBJ_ID_FKT: 6950694,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703071',
    GB_SAP_GB_ID_PKF: '7000/703071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5749.96,
    GB_NUTZFL_NF_VON_NGF: 3890.39,
    GB_TECHNIKFL_TF_VON_NGF: 70.65,
    GB_VERKEHRSFL_VF_VON_NGF: 1788.92,
    GB_MIETFL_GESAMT: 5749.96,
    GB_MIETFL_1_BSB: 4505.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6960571,
    GB_WE_OBJ_ID_FKT: 6960568,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703071,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703071',
    GB_SAP_GB_ID_PKF: '7000/703071/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 131.16,
    GB_NUTZFL_NF_VON_NGF: 107.22,
    GB_TECHNIKFL_TF_VON_NGF: 3.34,
    GB_VERKEHRSFL_VF_VON_NGF: 20.6,
    GB_MIETFL_GESAMT: 131.16,
    GB_MIETFL_1_BSB: 74.96,
    GB_MIETFL_2_DRITTE: 56.2,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6960572,
    GB_WE_OBJ_ID_FKT: 6960568,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703071,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703071',
    GB_SAP_GB_ID_PKF: '7000/703071/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 876.08,
    GB_NUTZFL_NF_VON_NGF: 801.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.34,
    GB_MIETFL_GESAMT: 876.08,
    GB_MIETFL_1_BSB: 753.1,
    GB_MIETFL_2_DRITTE: 122.98,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6960573,
    GB_WE_OBJ_ID_FKT: 6960568,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.23,
    GB_NUTZFL_NF_VON_NGF: 335.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.03,
    GB_MIETFL_GESAMT: 437.23,
    GB_MIETFL_1_BSB: 437.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990736,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1968.56,
    GB_NUTZFL_NF_VON_NGF: 1591.14,
    GB_TECHNIKFL_TF_VON_NGF: 97.14,
    GB_VERKEHRSFL_VF_VON_NGF: 280.28,
    GB_MIETFL_GESAMT: 1968.56,
    GB_MIETFL_1_BSB: 1510.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990737,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 717.1,
    GB_NUTZFL_NF_VON_NGF: 547.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 169.47,
    GB_MIETFL_GESAMT: 717.1,
    GB_MIETFL_1_BSB: 717.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990738,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 718.51,
    GB_NUTZFL_NF_VON_NGF: 555.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 162.78,
    GB_MIETFL_GESAMT: 718.51,
    GB_MIETFL_1_BSB: 718.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990739,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 708.02,
    GB_NUTZFL_NF_VON_NGF: 552.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 155.4,
    GB_MIETFL_GESAMT: 708.02,
    GB_MIETFL_1_BSB: 708.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990740,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 121.89,
    GB_NUTZFL_NF_VON_NGF: 81.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.51,
    GB_MIETFL_GESAMT: 121.89,
    GB_MIETFL_1_BSB: 121.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990741,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.98,
    GB_NUTZFL_NF_VON_NGF: 95.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.54,
    GB_MIETFL_GESAMT: 120.98,
    GB_MIETFL_1_BSB: 120.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990742,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.573741',
    GB_LAENGENGRAD_OESTLICH: '9.952768',
    GB_NETTOGRUNDFL_NGF: 619.26,
    GB_NUTZFL_NF_VON_NGF: 577.68,
    GB_TECHNIKFL_TF_VON_NGF: 21.71,
    GB_VERKEHRSFL_VF_VON_NGF: 19.87,
    GB_MIETFL_GESAMT: 619.26,
    GB_MIETFL_1_BSB: 597.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990743,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Bunker',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990744,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703043,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703043',
    GB_SAP_GB_ID_PKF: '7000/703043/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Bunker',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990745,
    GB_WE_OBJ_ID_FKT: 1989179,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703019',
    GB_SAP_GB_ID_PKF: '7000/703019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7326.16,
    GB_NUTZFL_NF_VON_NGF: 5360.69,
    GB_TECHNIKFL_TF_VON_NGF: 72.04,
    GB_VERKEHRSFL_VF_VON_NGF: 1893.43,
    GB_MIETFL_GESAMT: 7326.16,
    GB_MIETFL_1_BSB: 6344.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990580,
    GB_WE_OBJ_ID_FKT: 1989156,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703019',
    GB_SAP_GB_ID_PKF: '7000/703019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1909,
    GB_BREITENGRAD_NOERDLICH: '53.573735',
    GB_LAENGENGRAD_OESTLICH: '9.975452',
    GB_NETTOGRUNDFL_NGF: 454.5,
    GB_NUTZFL_NF_VON_NGF: 411.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.63,
    GB_MIETFL_GESAMT: 454.5,
    GB_MIETFL_1_BSB: 454.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990581,
    GB_WE_OBJ_ID_FKT: 1989156,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703019',
    GB_SAP_GB_ID_PKF: '7000/703019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 184.03,
    GB_NUTZFL_NF_VON_NGF: 125.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.58,
    GB_MIETFL_GESAMT: 184.03,
    GB_MIETFL_1_BSB: 184.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990582,
    GB_WE_OBJ_ID_FKT: 1989156,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703019',
    GB_SAP_GB_ID_PKF: '7000/703019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 284.01,
    GB_NUTZFL_NF_VON_NGF: 254.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.46,
    GB_MIETFL_GESAMT: 284.01,
    GB_MIETFL_1_BSB: 284.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990583,
    GB_WE_OBJ_ID_FKT: 1989156,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703072,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703072',
    GB_SAP_GB_ID_PKF: '7000/703072/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7412140,
    GB_WE_OBJ_ID_FKT: 7238604,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703072,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703072',
    GB_SAP_GB_ID_PKF: '7000/703072/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7412141,
    GB_WE_OBJ_ID_FKT: 7238604,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703072,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703072',
    GB_SAP_GB_ID_PKF: '7000/703072/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7238607,
    GB_WE_OBJ_ID_FKT: 7238604,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 195.09,
    GB_NUTZFL_NF_VON_NGF: 141.72,
    GB_TECHNIKFL_TF_VON_NGF: 17.16,
    GB_VERKEHRSFL_VF_VON_NGF: 36.21,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.10.17',
    GB_GB_OBJ_ID_PKT: 1990768,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.574459',
    GB_LAENGENGRAD_OESTLICH: '9.995306',
    GB_NETTOGRUNDFL_NGF: 1030.21,
    GB_NUTZFL_NF_VON_NGF: 926.75,
    GB_TECHNIKFL_TF_VON_NGF: 38.78,
    GB_VERKEHRSFL_VF_VON_NGF: 64.68,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.10.17',
    GB_GB_OBJ_ID_PKT: 1990769,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1562.41,
    GB_NUTZFL_NF_VON_NGF: 1381.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 181.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.06.18',
    GB_GB_OBJ_ID_PKT: 1990770,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990771,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 359.82,
    GB_NUTZFL_NF_VON_NGF: 310.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.61,
    GB_MIETFL_GESAMT: 359.82,
    GB_MIETFL_1_BSB: 359.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990772,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 259.75,
    GB_NUTZFL_NF_VON_NGF: 252.58,
    GB_TECHNIKFL_TF_VON_NGF: 0.67,
    GB_VERKEHRSFL_VF_VON_NGF: 6.5,
    GB_MIETFL_GESAMT: 259.75,
    GB_MIETFL_1_BSB: 259.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990773,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4225.76,
    GB_NUTZFL_NF_VON_NGF: 3208.53,
    GB_TECHNIKFL_TF_VON_NGF: 110.52,
    GB_VERKEHRSFL_VF_VON_NGF: 906.71,
    GB_MIETFL_GESAMT: 4225.76,
    GB_MIETFL_1_BSB: 4215.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5415065,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.02,
    GB_NUTZFL_NF_VON_NGF: 151.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.22',
    GB_GB_OBJ_ID_PKT: 4352737,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.02,
    GB_NUTZFL_NF_VON_NGF: 148.69,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '15.01.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.22',
    GB_GB_OBJ_ID_PKT: 7143490,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703047,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703047',
    GB_SAP_GB_ID_PKF: '7000/703047/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 607.42,
    GB_NUTZFL_NF_VON_NGF: 569.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7143491,
    GB_WE_OBJ_ID_FKT: 1989183,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1898,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1826.26,
    GB_NUTZFL_NF_VON_NGF: 1297.29,
    GB_TECHNIKFL_TF_VON_NGF: 54.58,
    GB_VERKEHRSFL_VF_VON_NGF: 474.39,
    GB_MIETFL_GESAMT: 1826.26,
    GB_MIETFL_1_BSB: 1735.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990605,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1319.25,
    GB_NUTZFL_NF_VON_NGF: 855.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 463.58,
    GB_MIETFL_GESAMT: 1319.25,
    GB_MIETFL_1_BSB: 1319.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990606,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/3',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.13,
    GB_NUTZFL_NF_VON_NGF: 15.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.13,
    GB_MIETFL_1_BSB: 15.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990607,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.580461',
    GB_LAENGENGRAD_OESTLICH: '9.976812',
    GB_NETTOGRUNDFL_NGF: 458.09,
    GB_NUTZFL_NF_VON_NGF: 400.16,
    GB_TECHNIKFL_TF_VON_NGF: 10.77,
    GB_VERKEHRSFL_VF_VON_NGF: 47.16,
    GB_MIETFL_GESAMT: 458.09,
    GB_MIETFL_1_BSB: 458.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990608,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.29,
    GB_NUTZFL_NF_VON_NGF: 12.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.29,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990609,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703025,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703025',
    GB_SAP_GB_ID_PKF: '7000/703025/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1898,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 193.09,
    GB_NUTZFL_NF_VON_NGF: 167.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.04,
    GB_MIETFL_GESAMT: 193.09,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990610,
    GB_WE_OBJ_ID_FKT: 1989162,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703023',
    GB_SAP_GB_ID_PKF: '7000/703023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4258.63,
    GB_NUTZFL_NF_VON_NGF: 2987.36,
    GB_TECHNIKFL_TF_VON_NGF: 63.91,
    GB_VERKEHRSFL_VF_VON_NGF: 1207.36,
    GB_MIETFL_GESAMT: 4258.63,
    GB_MIETFL_1_BSB: 3059.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '23.04.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990599,
    GB_WE_OBJ_ID_FKT: 1989160,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703023',
    GB_SAP_GB_ID_PKF: '7000/703023/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1990600,
    GB_WE_OBJ_ID_FKT: 1989160,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703023',
    GB_SAP_GB_ID_PKF: '7000/703023/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1990601,
    GB_WE_OBJ_ID_FKT: 1989160,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703023,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703023',
    GB_SAP_GB_ID_PKF: '7000/703023/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1073.03,
    GB_NUTZFL_NF_VON_NGF: 962.61,
    GB_TECHNIKFL_TF_VON_NGF: 10.62,
    GB_VERKEHRSFL_VF_VON_NGF: 99.8,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.15',
    GB_GB_OBJ_ID_PKT: 5307181,
    GB_WE_OBJ_ID_FKT: 1989160,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1684.19,
    GB_NUTZFL_NF_VON_NGF: 1224.97,
    GB_TECHNIKFL_TF_VON_NGF: 62.34,
    GB_VERKEHRSFL_VF_VON_NGF: 396.88,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.16',
    GB_GB_OBJ_ID_PKT: 1990595,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703023,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703023',
    GB_SAP_GB_ID_PKF: '7000/703023/4',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.583662',
    GB_LAENGENGRAD_OESTLICH: '9.962169',
    GB_NETTOGRUNDFL_NGF: 7359.68,
    GB_NUTZFL_NF_VON_NGF: 5747.24,
    GB_TECHNIKFL_TF_VON_NGF: 128.46,
    GB_VERKEHRSFL_VF_VON_NGF: 1483.98,
    GB_MIETFL_GESAMT: 7359.68,
    GB_MIETFL_1_BSB: 7258.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406988,
    GB_WE_OBJ_ID_FKT: 1989160,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 414.26,
    GB_NUTZFL_NF_VON_NGF: 318.15,
    GB_TECHNIKFL_TF_VON_NGF: 2.86,
    GB_VERKEHRSFL_VF_VON_NGF: 93.25,
    GB_MIETFL_GESAMT: 414.26,
    GB_MIETFL_1_BSB: 414.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990596,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 19.04,
    GB_NUTZFL_NF_VON_NGF: 19.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.06.18',
    GB_GB_OBJ_ID_PKT: 1990597,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.16',
    GB_GB_OBJ_ID_PKT: 1990598,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3670.89,
    GB_NUTZFL_NF_VON_NGF: 2683.47,
    GB_TECHNIKFL_TF_VON_NGF: 226.99,
    GB_VERKEHRSFL_VF_VON_NGF: 760.43,
    GB_MIETFL_GESAMT: 3670.89,
    GB_MIETFL_1_BSB: 3463.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.03.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5379246,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703022,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703022',
    GB_SAP_GB_ID_PKF: '7000/703022/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.02.16',
    GB_GB_OBJ_ID_PKT: 4352725,
    GB_WE_OBJ_ID_FKT: 1989159,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 777.1,
    GB_NUTZFL_NF_VON_NGF: 677.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 99.26,
    GB_MIETFL_GESAMT: 777.1,
    GB_MIETFL_1_BSB: 719.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990505,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.44,
    GB_NUTZFL_NF_VON_NGF: 84.24,
    GB_TECHNIKFL_TF_VON_NGF: 22.47,
    GB_VERKEHRSFL_VF_VON_NGF: 13.73,
    GB_MIETFL_GESAMT: 120.44,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990506,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 328.66,
    GB_NUTZFL_NF_VON_NGF: 308.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.21,
    GB_MIETFL_GESAMT: 328.66,
    GB_MIETFL_1_BSB: 328.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990507,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 871.25,
    GB_NUTZFL_NF_VON_NGF: 795.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.42,
    GB_MIETFL_GESAMT: 871.25,
    GB_MIETFL_1_BSB: 871.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990508,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1343.04,
    GB_NUTZFL_NF_VON_NGF: 1068.14,
    GB_TECHNIKFL_TF_VON_NGF: 15.07,
    GB_VERKEHRSFL_VF_VON_NGF: 259.83,
    GB_MIETFL_GESAMT: 1343.04,
    GB_MIETFL_1_BSB: 1343.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990509,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.32,
    GB_NUTZFL_NF_VON_NGF: 146.66,
    GB_TECHNIKFL_TF_VON_NGF: 22.02,
    GB_VERKEHRSFL_VF_VON_NGF: 37.64,
    GB_MIETFL_GESAMT: 206.32,
    GB_MIETFL_1_BSB: 206.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990510,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.596686',
    GB_LAENGENGRAD_OESTLICH: '9.972801',
    GB_NETTOGRUNDFL_NGF: 1216.78,
    GB_NUTZFL_NF_VON_NGF: 1216.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 1216.78,
    GB_MIETFL_1_BSB: 1216.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990511,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 254.13,
    GB_NUTZFL_NF_VON_NGF: 192.95,
    GB_TECHNIKFL_TF_VON_NGF: 16.23,
    GB_VERKEHRSFL_VF_VON_NGF: 44.95,
    GB_MIETFL_GESAMT: 254.13,
    GB_MIETFL_1_BSB: 254.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990512,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1270.35,
    GB_NUTZFL_NF_VON_NGF: 1037.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 232.4,
    GB_MIETFL_GESAMT: 1270.35,
    GB_MIETFL_1_BSB: 1270.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.12',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990513,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 687.03,
    GB_NUTZFL_NF_VON_NGF: 573.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 113.08,
    GB_MIETFL_GESAMT: 687.03,
    GB_MIETFL_1_BSB: 687.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990514,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1543.92,
    GB_NUTZFL_NF_VON_NGF: 1018.79,
    GB_TECHNIKFL_TF_VON_NGF: 105.78,
    GB_VERKEHRSFL_VF_VON_NGF: 419.35,
    GB_MIETFL_GESAMT: 1543.92,
    GB_MIETFL_1_BSB: 1433.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.03.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990515,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/12',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 318.17,
    GB_NUTZFL_NF_VON_NGF: 318.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 318.17,
    GB_MIETFL_1_BSB: 318.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990516,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 340.51,
    GB_NUTZFL_NF_VON_NGF: 340.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 340.51,
    GB_MIETFL_1_BSB: 340.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990517,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 138.12,
    GB_NUTZFL_NF_VON_NGF: 105.81,
    GB_TECHNIKFL_TF_VON_NGF: 21.61,
    GB_VERKEHRSFL_VF_VON_NGF: 10.7,
    GB_MIETFL_GESAMT: 138.12,
    GB_MIETFL_1_BSB: 138.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990518,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703009,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703009',
    GB_SAP_GB_ID_PKF: '7000/703009/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.88,
    GB_NUTZFL_NF_VON_NGF: 298.3,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.88,
    GB_MIETFL_1_BSB: 338.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '15.07.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7359075,
    GB_WE_OBJ_ID_FKT: 1989146,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 1884,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 992.33,
    GB_NUTZFL_NF_VON_NGF: 757.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 235.2,
    GB_MIETFL_GESAMT: 992.33,
    GB_MIETFL_1_BSB: 915.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990519,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1884,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 651.9,
    GB_NUTZFL_NF_VON_NGF: 466.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 185.49,
    GB_MIETFL_GESAMT: 651.9,
    GB_MIETFL_1_BSB: 543.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990520,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 69.87,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 69.87,
    GB_MIETFL_GESAMT: 69.87,
    GB_MIETFL_1_BSB: 69.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990521,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1918.13,
    GB_NUTZFL_NF_VON_NGF: 1242.0,
    GB_TECHNIKFL_TF_VON_NGF: 2.7,
    GB_VERKEHRSFL_VF_VON_NGF: 673.43,
    GB_MIETFL_GESAMT: 1918.13,
    GB_MIETFL_1_BSB: 1441.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990522,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1023.51,
    GB_NUTZFL_NF_VON_NGF: 878.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 145.24,
    GB_MIETFL_GESAMT: 1023.51,
    GB_MIETFL_1_BSB: 785.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990523,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990524,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1064.41,
    GB_NUTZFL_NF_VON_NGF: 784.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 280.1,
    GB_MIETFL_GESAMT: 1064.41,
    GB_MIETFL_1_BSB: 1024.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990525,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.595723',
    GB_LAENGENGRAD_OESTLICH: '9.954267',
    GB_NETTOGRUNDFL_NGF: 367.98,
    GB_NUTZFL_NF_VON_NGF: 367.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 367.98,
    GB_MIETFL_1_BSB: 367.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990526,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/9',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.58,
    GB_NUTZFL_NF_VON_NGF: 203.67,
    GB_TECHNIKFL_TF_VON_NGF: 12.55,
    GB_VERKEHRSFL_VF_VON_NGF: 9.36,
    GB_MIETFL_GESAMT: 225.58,
    GB_MIETFL_1_BSB: 225.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990527,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.69,
    GB_NUTZFL_NF_VON_NGF: 120.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.11.21',
    GB_GB_OBJ_ID_PKT: 1990528,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800176,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703010,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703010',
    GB_SAP_GB_ID_PKF: '7000/703010/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 79.47,
    GB_NUTZFL_NF_VON_NGF: 70.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.14,
    GB_MIETFL_GESAMT: 79.47,
    GB_MIETFL_1_BSB: 79.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '26.06.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5925375,
    GB_WE_OBJ_ID_FKT: 1989147,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 749.36,
    GB_NUTZFL_NF_VON_NGF: 508.8,
    GB_TECHNIKFL_TF_VON_NGF: 83.24,
    GB_VERKEHRSFL_VF_VON_NGF: 157.32,
    GB_MIETFL_GESAMT: 749.36,
    GB_MIETFL_1_BSB: 362.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990586,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.601926',
    GB_LAENGENGRAD_OESTLICH: '9.945472',
    GB_NETTOGRUNDFL_NGF: 614.68,
    GB_NUTZFL_NF_VON_NGF: 560.07,
    GB_TECHNIKFL_TF_VON_NGF: 15.49,
    GB_VERKEHRSFL_VF_VON_NGF: 39.12,
    GB_MIETFL_GESAMT: 614.68,
    GB_MIETFL_1_BSB: 614.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990587,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.4,
    GB_NUTZFL_NF_VON_NGF: 180.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.06,
    GB_MIETFL_GESAMT: 198.4,
    GB_MIETFL_1_BSB: 198.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990588,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 131.08,
    GB_NUTZFL_NF_VON_NGF: 114.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.75,
    GB_MIETFL_GESAMT: 131.08,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990589,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1305.43,
    GB_NUTZFL_NF_VON_NGF: 914.68,
    GB_TECHNIKFL_TF_VON_NGF: 11.3,
    GB_VERKEHRSFL_VF_VON_NGF: 379.45,
    GB_MIETFL_GESAMT: 1305.43,
    GB_MIETFL_1_BSB: 1305.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990590,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 274.16,
    GB_NUTZFL_NF_VON_NGF: 179.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.91,
    GB_MIETFL_GESAMT: 274.16,
    GB_MIETFL_1_BSB: 274.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990591,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 672.09,
    GB_NUTZFL_NF_VON_NGF: 515.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 157.02,
    GB_MIETFL_GESAMT: 672.09,
    GB_MIETFL_1_BSB: 672.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990592,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 748.58,
    GB_NUTZFL_NF_VON_NGF: 590.08,
    GB_TECHNIKFL_TF_VON_NGF: 8.59,
    GB_VERKEHRSFL_VF_VON_NGF: 149.91,
    GB_MIETFL_GESAMT: 748.58,
    GB_MIETFL_1_BSB: 748.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990593,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 495.78,
    GB_NUTZFL_NF_VON_NGF: 441.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.18,
    GB_MIETFL_GESAMT: 495.78,
    GB_MIETFL_1_BSB: 495.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990594,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/10',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.8,
    GB_NUTZFL_NF_VON_NGF: 22.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.8,
    GB_MIETFL_1_BSB: 22.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307249,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/11',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 21.34,
    GB_NUTZFL_NF_VON_NGF: 21.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 21.34,
    GB_MIETFL_1_BSB: 21.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307250,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703021,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703021',
    GB_SAP_GB_ID_PKF: '7000/703021/12',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.64,
    GB_NUTZFL_NF_VON_NGF: 22.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.64,
    GB_MIETFL_1_BSB: 22.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307251,
    GB_WE_OBJ_ID_FKT: 1989158,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 524.99,
    GB_NUTZFL_NF_VON_NGF: 459.44,
    GB_TECHNIKFL_TF_VON_NGF: 4.4,
    GB_VERKEHRSFL_VF_VON_NGF: 61.15,
    GB_MIETFL_GESAMT: 524.99,
    GB_MIETFL_1_BSB: 524.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990754,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.33,
    GB_NUTZFL_NF_VON_NGF: 364.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1990755,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 159.91,
    GB_NUTZFL_NF_VON_NGF: 119.14,
    GB_TECHNIKFL_TF_VON_NGF: 17.8,
    GB_VERKEHRSFL_VF_VON_NGF: 22.97,
    GB_MIETFL_GESAMT: 159.91,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990756,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.67,
    GB_NUTZFL_NF_VON_NGF: 192.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.82,
    GB_MIETFL_GESAMT: 215.67,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 215.67,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990757,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 431.51,
    GB_NUTZFL_NF_VON_NGF: 357.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.43,
    GB_MIETFL_GESAMT: 431.51,
    GB_MIETFL_1_BSB: 431.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990758,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.589714',
    GB_LAENGENGRAD_OESTLICH: '9.949804',
    GB_NETTOGRUNDFL_NGF: 661.07,
    GB_NUTZFL_NF_VON_NGF: 614.75,
    GB_TECHNIKFL_TF_VON_NGF: 17.47,
    GB_VERKEHRSFL_VF_VON_NGF: 28.85,
    GB_MIETFL_GESAMT: 661.07,
    GB_MIETFL_1_BSB: 661.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990759,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 942.45,
    GB_NUTZFL_NF_VON_NGF: 739.29,
    GB_TECHNIKFL_TF_VON_NGF: 4.6,
    GB_VERKEHRSFL_VF_VON_NGF: 198.56,
    GB_MIETFL_GESAMT: 942.45,
    GB_MIETFL_1_BSB: 942.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352734,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 942.45,
    GB_NUTZFL_NF_VON_NGF: 739.29,
    GB_TECHNIKFL_TF_VON_NGF: 4.6,
    GB_VERKEHRSFL_VF_VON_NGF: 198.56,
    GB_MIETFL_GESAMT: 942.45,
    GB_MIETFL_1_BSB: 942.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352735,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.33,
    GB_NUTZFL_NF_VON_NGF: 364.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.85,
    GB_MIETFL_GESAMT: 441.33,
    GB_MIETFL_1_BSB: 441.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '02.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5454439,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 910.58,
    GB_NUTZFL_NF_VON_NGF: 651.16,
    GB_TECHNIKFL_TF_VON_NGF: 9.69,
    GB_VERKEHRSFL_VF_VON_NGF: 249.73,
    GB_MIETFL_GESAMT: 910.58,
    GB_MIETFL_1_BSB: 908.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7025058,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.589982',
    GB_LAENGENGRAD_OESTLICH: '9.949815',
    GB_NETTOGRUNDFL_NGF: 361.53,
    GB_NUTZFL_NF_VON_NGF: 333.24,
    GB_TECHNIKFL_TF_VON_NGF: 12.83,
    GB_VERKEHRSFL_VF_VON_NGF: 15.46,
    GB_MIETFL_GESAMT: 361.53,
    GB_MIETFL_1_BSB: 361.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035243,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 82.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 92.53,
    GB_MIETFL_1_BSB: 92.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5447297,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703045,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703045',
    GB_SAP_GB_ID_PKF: '7000/703045/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.37,
    GB_NUTZFL_NF_VON_NGF: 83.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.34,
    GB_MIETFL_GESAMT: 92.37,
    GB_MIETFL_1_BSB: 92.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036057,
    GB_WE_OBJ_ID_FKT: 1989181,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 277.61,
    GB_NUTZFL_NF_VON_NGF: 226.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.39,
    GB_MIETFL_GESAMT: 277.61,
    GB_MIETFL_1_BSB: 277.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990454,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 134.93,
    GB_NUTZFL_NF_VON_NGF: 116.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.44,
    GB_MIETFL_GESAMT: 134.93,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990455,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.91,
    GB_NUTZFL_NF_VON_NGF: 171.15,
    GB_TECHNIKFL_TF_VON_NGF: 4.36,
    GB_VERKEHRSFL_VF_VON_NGF: 25.4,
    GB_MIETFL_GESAMT: 200.91,
    GB_MIETFL_1_BSB: 200.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990456,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 199.09,
    GB_NUTZFL_NF_VON_NGF: 169.25,
    GB_TECHNIKFL_TF_VON_NGF: 4.44,
    GB_VERKEHRSFL_VF_VON_NGF: 25.4,
    GB_MIETFL_GESAMT: 199.09,
    GB_MIETFL_1_BSB: 199.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990457,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 727.45,
    GB_NUTZFL_NF_VON_NGF: 539.15,
    GB_TECHNIKFL_TF_VON_NGF: 3.39,
    GB_VERKEHRSFL_VF_VON_NGF: 184.91,
    GB_MIETFL_GESAMT: 727.45,
    GB_MIETFL_1_BSB: 727.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990458,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.623270',
    GB_LAENGENGRAD_OESTLICH: '9.946116',
    GB_NETTOGRUNDFL_NGF: 594.88,
    GB_NUTZFL_NF_VON_NGF: 571.96,
    GB_TECHNIKFL_TF_VON_NGF: 12.2,
    GB_VERKEHRSFL_VF_VON_NGF: 10.72,
    GB_MIETFL_GESAMT: 594.88,
    GB_MIETFL_1_BSB: 594.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '03.05.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990459,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/7',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.623566',
    GB_LAENGENGRAD_OESTLICH: '9.946137',
    GB_NETTOGRUNDFL_NGF: 476.07,
    GB_NUTZFL_NF_VON_NGF: 416.87,
    GB_TECHNIKFL_TF_VON_NGF: 10.44,
    GB_VERKEHRSFL_VF_VON_NGF: 48.76,
    GB_MIETFL_GESAMT: 476.07,
    GB_MIETFL_1_BSB: 476.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990460,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.82,
    GB_NUTZFL_NF_VON_NGF: 32.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990461,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 175.89,
    GB_NUTZFL_NF_VON_NGF: 169.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.03,
    GB_MIETFL_GESAMT: 175.89,
    GB_MIETFL_1_BSB: 175.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990462,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 128.14,
    GB_NUTZFL_NF_VON_NGF: 109.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.24,
    GB_MIETFL_GESAMT: 128.14,
    GB_MIETFL_1_BSB: 128.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990463,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 163.83,
    GB_NUTZFL_NF_VON_NGF: 150.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.67,
    GB_MIETFL_GESAMT: 163.83,
    GB_MIETFL_1_BSB: 163.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990464,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/12',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.59,
    GB_NUTZFL_NF_VON_NGF: 195.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.11,
    GB_MIETFL_GESAMT: 221.59,
    GB_MIETFL_1_BSB: 221.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990465,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 326.27,
    GB_NUTZFL_NF_VON_NGF: 296.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.21,
    GB_MIETFL_GESAMT: 326.27,
    GB_MIETFL_1_BSB: 326.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990466,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.65,
    GB_NUTZFL_NF_VON_NGF: 83.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.65,
    GB_MIETFL_1_BSB: 83.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990467,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/15',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.65,
    GB_NUTZFL_NF_VON_NGF: 129.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.07,
    GB_MIETFL_GESAMT: 139.65,
    GB_MIETFL_1_BSB: 139.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990468,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/16',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.42,
    GB_NUTZFL_NF_VON_NGF: 381.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.05,
    GB_MIETFL_GESAMT: 422.42,
    GB_MIETFL_1_BSB: 422.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990469,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/17',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 259.81,
    GB_NUTZFL_NF_VON_NGF: 239.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.15,
    GB_MIETFL_GESAMT: 259.81,
    GB_MIETFL_1_BSB: 259.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990470,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/18',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.6,
    GB_NUTZFL_NF_VON_NGF: 134.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.46,
    GB_MIETFL_GESAMT: 140.6,
    GB_MIETFL_1_BSB: 140.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990471,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 183.09,
    GB_NUTZFL_NF_VON_NGF: 168.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990472,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/20',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1143.46,
    GB_NUTZFL_NF_VON_NGF: 947.33,
    GB_TECHNIKFL_TF_VON_NGF: 57.72,
    GB_VERKEHRSFL_VF_VON_NGF: 138.41,
    GB_MIETFL_GESAMT: 1143.46,
    GB_MIETFL_1_BSB: 942.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990473,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/21',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 321.51,
    GB_NUTZFL_NF_VON_NGF: 269.69,
    GB_TECHNIKFL_TF_VON_NGF: 2.29,
    GB_VERKEHRSFL_VF_VON_NGF: 49.53,
    GB_MIETFL_GESAMT: 321.51,
    GB_MIETFL_1_BSB: 321.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5341177,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/22',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7391605,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 184.34,
    GB_NUTZFL_NF_VON_NGF: 131.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.6,
    GB_MIETFL_GESAMT: 184.34,
    GB_MIETFL_1_BSB: 184.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373709,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703005,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703005',
    GB_SAP_GB_ID_PKF: '7000/703005/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 335.36,
    GB_NUTZFL_NF_VON_NGF: 310.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.8,
    GB_MIETFL_GESAMT: 156.68,
    GB_MIETFL_1_BSB: 156.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '11.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7458027,
    GB_WE_OBJ_ID_FKT: 1989142,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 618.23,
    GB_NUTZFL_NF_VON_NGF: 445.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 172.48,
    GB_MIETFL_GESAMT: 618.23,
    GB_MIETFL_1_BSB: 618.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990474,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.89,
    GB_NUTZFL_NF_VON_NGF: 129.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.89,
    GB_MIETFL_GESAMT: 139.89,
    GB_MIETFL_1_BSB: 139.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990475,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 462.16,
    GB_NUTZFL_NF_VON_NGF: 298.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 163.53,
    GB_MIETFL_GESAMT: 462.16,
    GB_MIETFL_1_BSB: 462.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990476,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 949.81,
    GB_NUTZFL_NF_VON_NGF: 834.79,
    GB_TECHNIKFL_TF_VON_NGF: 36.67,
    GB_VERKEHRSFL_VF_VON_NGF: 78.35,
    GB_MIETFL_GESAMT: 949.81,
    GB_MIETFL_1_BSB: 949.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990477,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 320.25,
    GB_NUTZFL_NF_VON_NGF: 277.65,
    GB_TECHNIKFL_TF_VON_NGF: 10.26,
    GB_VERKEHRSFL_VF_VON_NGF: 32.34,
    GB_MIETFL_GESAMT: 320.25,
    GB_MIETFL_1_BSB: 320.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990478,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 142.16,
    GB_NUTZFL_NF_VON_NGF: 114.68,
    GB_TECHNIKFL_TF_VON_NGF: 7.64,
    GB_VERKEHRSFL_VF_VON_NGF: 19.84,
    GB_MIETFL_GESAMT: 142.16,
    GB_MIETFL_1_BSB: 73.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990479,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1541.96,
    GB_NUTZFL_NF_VON_NGF: 1019.87,
    GB_TECHNIKFL_TF_VON_NGF: 97.43,
    GB_VERKEHRSFL_VF_VON_NGF: 424.66,
    GB_MIETFL_GESAMT: 1541.96,
    GB_MIETFL_1_BSB: 1437.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990480,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.623416',
    GB_LAENGENGRAD_OESTLICH: '9.937976',
    GB_NETTOGRUNDFL_NGF: 622.16,
    GB_NUTZFL_NF_VON_NGF: 593.94,
    GB_TECHNIKFL_TF_VON_NGF: 12.72,
    GB_VERKEHRSFL_VF_VON_NGF: 15.5,
    GB_MIETFL_GESAMT: 622.16,
    GB_MIETFL_1_BSB: 622.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990481,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 374.92,
    GB_NUTZFL_NF_VON_NGF: 262.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 112.77,
    GB_MIETFL_GESAMT: 374.92,
    GB_MIETFL_1_BSB: 374.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990482,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 123.28,
    GB_NUTZFL_NF_VON_NGF: 110.04,
    GB_TECHNIKFL_TF_VON_NGF: 4.85,
    GB_VERKEHRSFL_VF_VON_NGF: 8.39,
    GB_MIETFL_GESAMT: 123.28,
    GB_MIETFL_1_BSB: 123.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990483,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.623331',
    GB_LAENGENGRAD_OESTLICH: '9.936683',
    GB_NETTOGRUNDFL_NGF: 178.93,
    GB_NUTZFL_NF_VON_NGF: 178.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 178.93,
    GB_MIETFL_1_BSB: 178.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990484,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.33,
    GB_NUTZFL_NF_VON_NGF: 124.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.52,
    GB_MIETFL_GESAMT: 200.33,
    GB_MIETFL_1_BSB: 200.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990485,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 313.88,
    GB_NUTZFL_NF_VON_NGF: 228.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 85.14,
    GB_MIETFL_GESAMT: 313.88,
    GB_MIETFL_1_BSB: 313.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990486,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 447.07,
    GB_NUTZFL_NF_VON_NGF: 308.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 138.31,
    GB_MIETFL_GESAMT: 447.07,
    GB_MIETFL_1_BSB: 447.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990487,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 695.48,
    GB_NUTZFL_NF_VON_NGF: 392.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 302.73,
    GB_MIETFL_GESAMT: 695.48,
    GB_MIETFL_1_BSB: 695.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990488,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 466.75,
    GB_NUTZFL_NF_VON_NGF: 235.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 231.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.11.18',
    GB_GB_OBJ_ID_PKT: 1990489,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 503.69,
    GB_NUTZFL_NF_VON_NGF: 265.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 238.44,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.11.18',
    GB_GB_OBJ_ID_PKT: 1990490,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 412.37,
    GB_NUTZFL_NF_VON_NGF: 333.44,
    GB_TECHNIKFL_TF_VON_NGF: 4.64,
    GB_VERKEHRSFL_VF_VON_NGF: 74.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.11.18',
    GB_GB_OBJ_ID_PKT: 1990491,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2928.3,
    GB_NUTZFL_NF_VON_NGF: 2209.72,
    GB_TECHNIKFL_TF_VON_NGF: 7.15,
    GB_VERKEHRSFL_VF_VON_NGF: 711.43,
    GB_MIETFL_GESAMT: 2928.3,
    GB_MIETFL_1_BSB: 2923.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5505032,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703006,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/703006',
    GB_SAP_GB_ID_PKF: '7000/703006/20',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6907920,
    GB_WE_OBJ_ID_FKT: 1989143,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.39,
    GB_NUTZFL_NF_VON_NGF: 411.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.57,
    GB_MIETFL_GESAMT: 455.39,
    GB_MIETFL_1_BSB: 455.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990494,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 106.14,
    GB_NUTZFL_NF_VON_NGF: 87.13,
    GB_TECHNIKFL_TF_VON_NGF: 12.0,
    GB_VERKEHRSFL_VF_VON_NGF: 7.01,
    GB_MIETFL_GESAMT: 106.14,
    GB_MIETFL_1_BSB: 72.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990495,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.39,
    GB_NUTZFL_NF_VON_NGF: 411.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.57,
    GB_MIETFL_GESAMT: 455.39,
    GB_MIETFL_1_BSB: 455.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990496,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.42,
    GB_NUTZFL_NF_VON_NGF: 422.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.08,
    GB_MIETFL_GESAMT: 469.42,
    GB_MIETFL_1_BSB: 469.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990497,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.42,
    GB_NUTZFL_NF_VON_NGF: 422.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.08,
    GB_MIETFL_GESAMT: 469.42,
    GB_MIETFL_1_BSB: 469.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990498,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 468.62,
    GB_NUTZFL_NF_VON_NGF: 421.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.06,
    GB_MIETFL_GESAMT: 468.62,
    GB_MIETFL_1_BSB: 468.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990499,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/7',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1605.73,
    GB_NUTZFL_NF_VON_NGF: 1242.3,
    GB_TECHNIKFL_TF_VON_NGF: 147.69,
    GB_VERKEHRSFL_VF_VON_NGF: 215.74,
    GB_MIETFL_GESAMT: 1605.73,
    GB_MIETFL_1_BSB: 1324.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990500,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 209.96,
    GB_NUTZFL_NF_VON_NGF: 101.83,
    GB_TECHNIKFL_TF_VON_NGF: 2.71,
    GB_VERKEHRSFL_VF_VON_NGF: 105.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.14',
    GB_GB_OBJ_ID_PKT: 1990501,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 738.45,
    GB_NUTZFL_NF_VON_NGF: 528.1,
    GB_TECHNIKFL_TF_VON_NGF: 92.92,
    GB_VERKEHRSFL_VF_VON_NGF: 117.43,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.14',
    GB_GB_OBJ_ID_PKT: 1990502,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 134.36,
    GB_NUTZFL_NF_VON_NGF: 99.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.92,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.14',
    GB_GB_OBJ_ID_PKT: 1990503,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.628324',
    GB_LAENGENGRAD_OESTLICH: '9.962494',
    GB_NETTOGRUNDFL_NGF: 619.44,
    GB_NUTZFL_NF_VON_NGF: 553.72,
    GB_TECHNIKFL_TF_VON_NGF: 13.0,
    GB_VERKEHRSFL_VF_VON_NGF: 52.72,
    GB_MIETFL_GESAMT: 619.44,
    GB_MIETFL_1_BSB: 619.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990504,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/12',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 305.01,
    GB_NUTZFL_NF_VON_NGF: 270.86,
    GB_TECHNIKFL_TF_VON_NGF: 13.38,
    GB_VERKEHRSFL_VF_VON_NGF: 20.77,
    GB_MIETFL_GESAMT: 305.01,
    GB_MIETFL_1_BSB: 305.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '10.07.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5351047,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703008,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703008',
    GB_SAP_GB_ID_PKF: '7000/703008/13',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7486628,
    GB_WE_OBJ_ID_FKT: 1989145,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1699.17,
    GB_NUTZFL_NF_VON_NGF: 1090.12,
    GB_TECHNIKFL_TF_VON_NGF: 87.15,
    GB_VERKEHRSFL_VF_VON_NGF: 521.9,
    GB_MIETFL_GESAMT: 1699.17,
    GB_MIETFL_1_BSB: 1612.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990656,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 197.6,
    GB_NUTZFL_NF_VON_NGF: 164.59,
    GB_TECHNIKFL_TF_VON_NGF: 6.99,
    GB_VERKEHRSFL_VF_VON_NGF: 26.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.03.19',
    GB_GB_OBJ_ID_PKT: 1990657,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1945.56,
    GB_NUTZFL_NF_VON_NGF: 1494.69,
    GB_TECHNIKFL_TF_VON_NGF: 32.42,
    GB_VERKEHRSFL_VF_VON_NGF: 418.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.11.15',
    GB_GB_OBJ_ID_PKT: 1990658,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 910.51,
    GB_NUTZFL_NF_VON_NGF: 620.64,
    GB_TECHNIKFL_TF_VON_NGF: 5.27,
    GB_VERKEHRSFL_VF_VON_NGF: 284.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.11.15',
    GB_GB_OBJ_ID_PKT: 1990659,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 238.92,
    GB_NUTZFL_NF_VON_NGF: 235.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.66,
    GB_MIETFL_GESAMT: 238.92,
    GB_MIETFL_1_BSB: 238.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990660,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.13',
    GB_GB_OBJ_ID_PKT: 1990661,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1131.11,
    GB_NUTZFL_NF_VON_NGF: 918.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 212.26,
    GB_MIETFL_GESAMT: 1131.11,
    GB_MIETFL_1_BSB: 1131.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990662,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 856.47,
    GB_NUTZFL_NF_VON_NGF: 662.24,
    GB_TECHNIKFL_TF_VON_NGF: 17.64,
    GB_VERKEHRSFL_VF_VON_NGF: 176.59,
    GB_MIETFL_GESAMT: 856.47,
    GB_MIETFL_1_BSB: 852.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990663,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: '53.634065',
    GB_LAENGENGRAD_OESTLICH: '9.949843',
    GB_NETTOGRUNDFL_NGF: 533.74,
    GB_NUTZFL_NF_VON_NGF: 518.73,
    GB_TECHNIKFL_TF_VON_NGF: 8.14,
    GB_VERKEHRSFL_VF_VON_NGF: 6.87,
    GB_MIETFL_GESAMT: 533.74,
    GB_MIETFL_1_BSB: 533.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990664,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.634519',
    GB_LAENGENGRAD_OESTLICH: '9.950073',
    GB_NETTOGRUNDFL_NGF: 589.68,
    GB_NUTZFL_NF_VON_NGF: 558.83,
    GB_TECHNIKFL_TF_VON_NGF: 21.68,
    GB_VERKEHRSFL_VF_VON_NGF: 9.17,
    GB_MIETFL_GESAMT: 589.68,
    GB_MIETFL_1_BSB: 589.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990665,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lehrschwimmbecken',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lehrschwimmbecken',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990666,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1243.2,
    GB_NUTZFL_NF_VON_NGF: 1003.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 239.5,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.11.19',
    GB_GB_OBJ_ID_PKT: 1990667,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6145.47,
    GB_NUTZFL_NF_VON_NGF: 4493.95,
    GB_TECHNIKFL_TF_VON_NGF: 175.21,
    GB_VERKEHRSFL_VF_VON_NGF: 1476.31,
    GB_MIETFL_GESAMT: 6145.47,
    GB_MIETFL_1_BSB: 5837.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406744,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6772659,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.19',
    GB_GB_OBJ_ID_PKT: 4352727,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.16',
    GB_GB_OBJ_ID_PKT: 4352728,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.19',
    GB_GB_OBJ_ID_PKT: 5358589,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703033,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/703033',
    GB_SAP_GB_ID_PKF: '7000/703033/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552747,
    GB_WE_OBJ_ID_FKT: 1989170,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 423.13,
    GB_NUTZFL_NF_VON_NGF: 333.65,
    GB_TECHNIKFL_TF_VON_NGF: 6.37,
    GB_VERKEHRSFL_VF_VON_NGF: 83.11,
    GB_MIETFL_GESAMT: 423.13,
    GB_MIETFL_1_BSB: 423.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990668,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.96,
    GB_NUTZFL_NF_VON_NGF: 114.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.84,
    GB_MIETFL_GESAMT: 139.96,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990669,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 259.36,
    GB_NUTZFL_NF_VON_NGF: 206.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.6,
    GB_MIETFL_GESAMT: 259.36,
    GB_MIETFL_1_BSB: 259.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990670,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.6,
    GB_NUTZFL_NF_VON_NGF: 32.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.6,
    GB_MIETFL_1_BSB: 32.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990671,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 728.69,
    GB_NUTZFL_NF_VON_NGF: 618.7,
    GB_TECHNIKFL_TF_VON_NGF: 4.22,
    GB_VERKEHRSFL_VF_VON_NGF: 105.77,
    GB_MIETFL_GESAMT: 728.69,
    GB_MIETFL_1_BSB: 728.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990672,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.47,
    GB_NUTZFL_NF_VON_NGF: 65.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 38.12,
    GB_MIETFL_GESAMT: 103.47,
    GB_MIETFL_1_BSB: 103.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990673,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3124.44,
    GB_NUTZFL_NF_VON_NGF: 2312.85,
    GB_TECHNIKFL_TF_VON_NGF: 72.12,
    GB_VERKEHRSFL_VF_VON_NGF: 739.47,
    GB_MIETFL_GESAMT: 3124.44,
    GB_MIETFL_1_BSB: 2956.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990674,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.641901',
    GB_LAENGENGRAD_OESTLICH: '9.962386',
    GB_NETTOGRUNDFL_NGF: 1219.28,
    GB_NUTZFL_NF_VON_NGF: 1219.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 1219.28,
    GB_MIETFL_1_BSB: 1219.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990675,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/9',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 265.76,
    GB_NUTZFL_NF_VON_NGF: 218.65,
    GB_TECHNIKFL_TF_VON_NGF: 17.31,
    GB_VERKEHRSFL_VF_VON_NGF: 29.8,
    GB_MIETFL_GESAMT: 265.76,
    GB_MIETFL_1_BSB: 265.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990676,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/10',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 265.76,
    GB_NUTZFL_NF_VON_NGF: 218.65,
    GB_TECHNIKFL_TF_VON_NGF: 17.31,
    GB_VERKEHRSFL_VF_VON_NGF: 29.8,
    GB_MIETFL_GESAMT: 265.76,
    GB_MIETFL_1_BSB: 265.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990677,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.16',
    GB_GB_OBJ_ID_PKT: 1990678,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: '53.642872',
    GB_LAENGENGRAD_OESTLICH: '9.959662',
    GB_NETTOGRUNDFL_NGF: 2736.07,
    GB_NUTZFL_NF_VON_NGF: 2553.67,
    GB_TECHNIKFL_TF_VON_NGF: 26.22,
    GB_VERKEHRSFL_VF_VON_NGF: 156.18,
    GB_MIETFL_GESAMT: 2736.07,
    GB_MIETFL_1_BSB: 2736.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990679,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1158.53,
    GB_NUTZFL_NF_VON_NGF: 939.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 219.07,
    GB_MIETFL_GESAMT: 1158.53,
    GB_MIETFL_1_BSB: 1158.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990680,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2389.17,
    GB_NUTZFL_NF_VON_NGF: 1878.27,
    GB_TECHNIKFL_TF_VON_NGF: 108.18,
    GB_VERKEHRSFL_VF_VON_NGF: 402.72,
    GB_MIETFL_GESAMT: 2389.17,
    GB_MIETFL_1_BSB: 2008.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990681,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/15',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1278.03,
    GB_NUTZFL_NF_VON_NGF: 1021.18,
    GB_TECHNIKFL_TF_VON_NGF: 65.69,
    GB_VERKEHRSFL_VF_VON_NGF: 191.16,
    GB_MIETFL_GESAMT: 1278.03,
    GB_MIETFL_1_BSB: 1278.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990682,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3952.46,
    GB_NUTZFL_NF_VON_NGF: 2979.34,
    GB_TECHNIKFL_TF_VON_NGF: 31.68,
    GB_VERKEHRSFL_VF_VON_NGF: 941.44,
    GB_MIETFL_GESAMT: 3952.46,
    GB_MIETFL_1_BSB: 3621.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990683,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/17',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2269.52,
    GB_NUTZFL_NF_VON_NGF: 1766.36,
    GB_TECHNIKFL_TF_VON_NGF: 46.52,
    GB_VERKEHRSFL_VF_VON_NGF: 456.64,
    GB_MIETFL_GESAMT: 2269.52,
    GB_MIETFL_1_BSB: 2261.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990684,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/18',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2011.87,
    GB_NUTZFL_NF_VON_NGF: 1622.15,
    GB_TECHNIKFL_TF_VON_NGF: 52.13,
    GB_VERKEHRSFL_VF_VON_NGF: 337.59,
    GB_MIETFL_GESAMT: 2011.87,
    GB_MIETFL_1_BSB: 2008.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406745,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352729,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 420.88,
    GB_NUTZFL_NF_VON_NGF: 365.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.18',
    GB_GB_OBJ_ID_PKT: 4352730,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703034,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703034',
    GB_SAP_GB_ID_PKF: '7000/703034/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 483.18,
    GB_NUTZFL_NF_VON_NGF: 426.76,
    GB_TECHNIKFL_TF_VON_NGF: 3.4,
    GB_VERKEHRSFL_VF_VON_NGF: 53.02,
    GB_MIETFL_GESAMT: 467.67,
    GB_MIETFL_1_BSB: 467.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '18.01.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7414713,
    GB_WE_OBJ_ID_FKT: 1989171,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 803.92,
    GB_NUTZFL_NF_VON_NGF: 583.62,
    GB_TECHNIKFL_TF_VON_NGF: 62.97,
    GB_VERKEHRSFL_VF_VON_NGF: 157.33,
    GB_MIETFL_GESAMT: 803.92,
    GB_MIETFL_1_BSB: 697.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990685,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 109.31,
    GB_NUTZFL_NF_VON_NGF: 81.54,
    GB_TECHNIKFL_TF_VON_NGF: 8.2,
    GB_VERKEHRSFL_VF_VON_NGF: 19.57,
    GB_MIETFL_GESAMT: 109.31,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990686,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 504.08,
    GB_NUTZFL_NF_VON_NGF: 478.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.61,
    GB_MIETFL_GESAMT: 504.08,
    GB_MIETFL_1_BSB: 504.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990687,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.52,
    GB_NUTZFL_NF_VON_NGF: 370.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.11,
    GB_MIETFL_GESAMT: 452.52,
    GB_MIETFL_1_BSB: 452.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990688,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.52,
    GB_NUTZFL_NF_VON_NGF: 370.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.11,
    GB_MIETFL_GESAMT: 452.52,
    GB_MIETFL_1_BSB: 452.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990689,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.22,
    GB_NUTZFL_NF_VON_NGF: 371.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.11,
    GB_MIETFL_GESAMT: 453.22,
    GB_MIETFL_1_BSB: 453.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990690,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.66,
    GB_NUTZFL_NF_VON_NGF: 144.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.55,
    GB_MIETFL_GESAMT: 161.66,
    GB_MIETFL_1_BSB: 161.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990691,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 428.66,
    GB_NUTZFL_NF_VON_NGF: 380.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.58,
    GB_MIETFL_GESAMT: 428.66,
    GB_MIETFL_1_BSB: 428.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990692,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 428.66,
    GB_NUTZFL_NF_VON_NGF: 380.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.58,
    GB_MIETFL_GESAMT: 428.66,
    GB_MIETFL_1_BSB: 428.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990693,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 317.68,
    GB_NUTZFL_NF_VON_NGF: 281.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.89,
    GB_MIETFL_GESAMT: 317.68,
    GB_MIETFL_1_BSB: 317.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990694,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/12',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 593.77,
    GB_NUTZFL_NF_VON_NGF: 524.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 69.42,
    GB_MIETFL_GESAMT: 593.77,
    GB_MIETFL_1_BSB: 593.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.11.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990695,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.633326',
    GB_LAENGENGRAD_OESTLICH: '9.943653',
    GB_NETTOGRUNDFL_NGF: 626.25,
    GB_NUTZFL_NF_VON_NGF: 597.38,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.21,
    GB_MIETFL_GESAMT: 626.25,
    GB_MIETFL_1_BSB: 626.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990696,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 193.26,
    GB_NUTZFL_NF_VON_NGF: 164.39,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.21,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.15',
    GB_GB_OBJ_ID_PKT: 1990697,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703035,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703035',
    GB_SAP_GB_ID_PKF: '7000/703035/15',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa mit Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa mit Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7487843,
    GB_WE_OBJ_ID_FKT: 1989172,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800466,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 863.12,
    GB_NUTZFL_NF_VON_NGF: 618.45,
    GB_TECHNIKFL_TF_VON_NGF: 99.75,
    GB_VERKEHRSFL_VF_VON_NGF: 144.92,
    GB_MIETFL_GESAMT: 863.12,
    GB_MIETFL_1_BSB: 671.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990646,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.53,
    GB_NUTZFL_NF_VON_NGF: 125.23,
    GB_TECHNIKFL_TF_VON_NGF: 5.96,
    GB_VERKEHRSFL_VF_VON_NGF: 33.34,
    GB_MIETFL_GESAMT: 164.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990647,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 889.69,
    GB_NUTZFL_NF_VON_NGF: 766.44,
    GB_TECHNIKFL_TF_VON_NGF: 25.01,
    GB_VERKEHRSFL_VF_VON_NGF: 98.24,
    GB_MIETFL_GESAMT: 889.69,
    GB_MIETFL_1_BSB: 820.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990648,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 477.27,
    GB_NUTZFL_NF_VON_NGF: 422.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.83,
    GB_MIETFL_GESAMT: 477.27,
    GB_MIETFL_1_BSB: 477.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.01.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990649,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 477.38,
    GB_NUTZFL_NF_VON_NGF: 422.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.83,
    GB_MIETFL_GESAMT: 477.38,
    GB_MIETFL_1_BSB: 477.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990650,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 476.11,
    GB_NUTZFL_NF_VON_NGF: 421.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.83,
    GB_MIETFL_GESAMT: 476.11,
    GB_MIETFL_1_BSB: 476.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.10.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990651,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.642942',
    GB_LAENGENGRAD_OESTLICH: '9.944390',
    GB_NETTOGRUNDFL_NGF: 404.31,
    GB_NUTZFL_NF_VON_NGF: 404.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 404.31,
    GB_MIETFL_1_BSB: 404.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.06.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990652,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/8',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 263.35,
    GB_NUTZFL_NF_VON_NGF: 203.56,
    GB_TECHNIKFL_TF_VON_NGF: 12.34,
    GB_VERKEHRSFL_VF_VON_NGF: 47.45,
    GB_MIETFL_GESAMT: 263.35,
    GB_MIETFL_1_BSB: 263.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.06.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990653,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 476.95,
    GB_NUTZFL_NF_VON_NGF: 422.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.83,
    GB_MIETFL_GESAMT: 476.95,
    GB_MIETFL_1_BSB: 476.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '24.02.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990654,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703032,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703032',
    GB_SAP_GB_ID_PKF: '7000/703032/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 477.0,
    GB_NUTZFL_NF_VON_NGF: 422.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.83,
    GB_MIETFL_GESAMT: 477.0,
    GB_MIETFL_1_BSB: 477.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990655,
    GB_WE_OBJ_ID_FKT: 1989169,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703067',
    GB_SAP_GB_ID_PKF: '7000/703067/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 5503987,
    GB_WE_OBJ_ID_FKT: 5503984,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703067',
    GB_SAP_GB_ID_PKF: '7000/703067/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 5503988,
    GB_WE_OBJ_ID_FKT: 5503984,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703067,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703067',
    GB_SAP_GB_ID_PKF: '7000/703067/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 5503989,
    GB_WE_OBJ_ID_FKT: 5503984,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703076,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703076',
    GB_SAP_GB_ID_PKF: '7000/703076/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1892,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1322.05,
    GB_NUTZFL_NF_VON_NGF: 1067.65,
    GB_TECHNIKFL_TF_VON_NGF: 24.44,
    GB_VERKEHRSFL_VF_VON_NGF: 229.96,
    GB_MIETFL_GESAMT: 1322.05,
    GB_MIETFL_1_BSB: 1020.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.04.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7234838,
    GB_WE_OBJ_ID_FKT: 7234836,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703073,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703073',
    GB_SAP_GB_ID_PKF: '7000/703073/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7393.94,
    GB_NUTZFL_NF_VON_NGF: 4920.23,
    GB_TECHNIKFL_TF_VON_NGF: 106.02,
    GB_VERKEHRSFL_VF_VON_NGF: 2367.69,
    GB_MIETFL_GESAMT: 7393.94,
    GB_MIETFL_1_BSB: 6122.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '29.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7083733,
    GB_WE_OBJ_ID_FKT: 7083731,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703073,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703073',
    GB_SAP_GB_ID_PKF: '7000/703073/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 902.82,
    GB_NUTZFL_NF_VON_NGF: 866.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.83,
    GB_MIETFL_GESAMT: 902.82,
    GB_MIETFL_1_BSB: 640.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '29.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7083734,
    GB_WE_OBJ_ID_FKT: 7083731,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703073,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703073',
    GB_SAP_GB_ID_PKF: '7000/703073/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 35.1,
    GB_NUTZFL_NF_VON_NGF: 35.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 35.1,
    GB_MIETFL_1_BSB: 35.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7083735,
    GB_WE_OBJ_ID_FKT: 7083731,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703073,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703073',
    GB_SAP_GB_ID_PKF: '7000/703073/4',
    GB_SAP_GB_BEZEICHNUNG: 'Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 134.56,
    GB_NUTZFL_NF_VON_NGF: 93.93,
    GB_TECHNIKFL_TF_VON_NGF: 8.5,
    GB_VERKEHRSFL_VF_VON_NGF: 32.13,
    GB_MIETFL_GESAMT: 134.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7083736,
    GB_WE_OBJ_ID_FKT: 7083731,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 577.75,
    GB_NUTZFL_NF_VON_NGF: 509.96,
    GB_TECHNIKFL_TF_VON_NGF: 5.4,
    GB_VERKEHRSFL_VF_VON_NGF: 62.39,
    GB_MIETFL_GESAMT: 577.75,
    GB_MIETFL_1_BSB: 577.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990746,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.61,
    GB_NUTZFL_NF_VON_NGF: 187.56,
    GB_TECHNIKFL_TF_VON_NGF: 20.05,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 207.61,
    GB_MIETFL_1_BSB: 174.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990747,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 434.98,
    GB_NUTZFL_NF_VON_NGF: 364.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 70.96,
    GB_MIETFL_GESAMT: 434.98,
    GB_MIETFL_1_BSB: 434.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990748,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 131.13,
    GB_NUTZFL_NF_VON_NGF: 91.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.06,
    GB_MIETFL_GESAMT: 131.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990749,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.572172',
    GB_LAENGENGRAD_OESTLICH: '9.991269',
    GB_NETTOGRUNDFL_NGF: 1819.57,
    GB_NUTZFL_NF_VON_NGF: 1515.91,
    GB_TECHNIKFL_TF_VON_NGF: 148.05,
    GB_VERKEHRSFL_VF_VON_NGF: 155.61,
    GB_MIETFL_GESAMT: 1819.57,
    GB_MIETFL_1_BSB: 1318.93,
    GB_MIETFL_2_DRITTE: 402.39,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990750,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1573.56,
    GB_NUTZFL_NF_VON_NGF: 1045.21,
    GB_TECHNIKFL_TF_VON_NGF: 103.12,
    GB_VERKEHRSFL_VF_VON_NGF: 425.23,
    GB_MIETFL_GESAMT: 1573.56,
    GB_MIETFL_1_BSB: 1462.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990751,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 216.51,
    GB_NUTZFL_NF_VON_NGF: 181.13,
    GB_TECHNIKFL_TF_VON_NGF: 4.42,
    GB_VERKEHRSFL_VF_VON_NGF: 30.96,
    GB_MIETFL_GESAMT: 216.51,
    GB_MIETFL_1_BSB: 216.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990752,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 404.8,
    GB_NUTZFL_NF_VON_NGF: 359.0,
    GB_TECHNIKFL_TF_VON_NGF: 10.78,
    GB_VERKEHRSFL_VF_VON_NGF: 35.02,
    GB_MIETFL_GESAMT: 404.8,
    GB_MIETFL_1_BSB: 404.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990753,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 480.85,
    GB_NUTZFL_NF_VON_NGF: 420.29,
    GB_TECHNIKFL_TF_VON_NGF: 7.58,
    GB_VERKEHRSFL_VF_VON_NGF: 52.98,
    GB_MIETFL_GESAMT: 480.85,
    GB_MIETFL_1_BSB: 480.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.02.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5427884,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1690.79,
    GB_NUTZFL_NF_VON_NGF: 1199.2,
    GB_TECHNIKFL_TF_VON_NGF: 21.76,
    GB_VERKEHRSFL_VF_VON_NGF: 469.83,
    GB_MIETFL_GESAMT: 1690.79,
    GB_MIETFL_1_BSB: 1690.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5432735,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703044,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703044',
    GB_SAP_GB_ID_PKF: '7000/703044/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '25.08.17',
    GB_GB_OBJ_ID_PKT: 4352733,
    GB_WE_OBJ_ID_FKT: 1989180,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 296.63,
    GB_NUTZFL_NF_VON_NGF: 263.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.42,
    GB_MIETFL_GESAMT: 296.63,
    GB_MIETFL_1_BSB: 296.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.05.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990431,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 100.4,
    GB_NUTZFL_NF_VON_NGF: 79.61,
    GB_TECHNIKFL_TF_VON_NGF: 3.55,
    GB_VERKEHRSFL_VF_VON_NGF: 17.24,
    GB_MIETFL_GESAMT: 100.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990432,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1141.18,
    GB_NUTZFL_NF_VON_NGF: 998.8,
    GB_TECHNIKFL_TF_VON_NGF: 21.06,
    GB_VERKEHRSFL_VF_VON_NGF: 121.32,
    GB_MIETFL_GESAMT: 1141.18,
    GB_MIETFL_1_BSB: 1139.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990433,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1344.48,
    GB_NUTZFL_NF_VON_NGF: 1056.12,
    GB_TECHNIKFL_TF_VON_NGF: 47.29,
    GB_VERKEHRSFL_VF_VON_NGF: 241.07,
    GB_MIETFL_GESAMT: 1344.48,
    GB_MIETFL_1_BSB: 1168.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990434,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 361.78,
    GB_NUTZFL_NF_VON_NGF: 334.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.43,
    GB_MIETFL_GESAMT: 361.78,
    GB_MIETFL_1_BSB: 361.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.03.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990435,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 425.2,
    GB_NUTZFL_NF_VON_NGF: 425.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 425.2,
    GB_MIETFL_1_BSB: 425.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990436,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.641451',
    GB_LAENGENGRAD_OESTLICH: '9.919649',
    GB_NETTOGRUNDFL_NGF: 531.56,
    GB_NUTZFL_NF_VON_NGF: 514.51,
    GB_TECHNIKFL_TF_VON_NGF: 9.18,
    GB_VERKEHRSFL_VF_VON_NGF: 7.87,
    GB_MIETFL_GESAMT: 531.56,
    GB_MIETFL_1_BSB: 531.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990437,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.641046',
    GB_LAENGENGRAD_OESTLICH: '9.919569',
    GB_NETTOGRUNDFL_NGF: 624.65,
    GB_NUTZFL_NF_VON_NGF: 587.41,
    GB_TECHNIKFL_TF_VON_NGF: 21.83,
    GB_VERKEHRSFL_VF_VON_NGF: 15.41,
    GB_MIETFL_GESAMT: 624.65,
    GB_MIETFL_1_BSB: 624.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990438,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 914.22,
    GB_NUTZFL_NF_VON_NGF: 732.56,
    GB_TECHNIKFL_TF_VON_NGF: 9.76,
    GB_VERKEHRSFL_VF_VON_NGF: 171.9,
    GB_MIETFL_GESAMT: 914.22,
    GB_MIETFL_1_BSB: 914.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990439,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.14',
    GB_GB_OBJ_ID_PKT: 1990440,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990441,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990442,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990443,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990444,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990445,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990446,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990447,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990448,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990449,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/20',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990450,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/21',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990451,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703003,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7000/703003',
    GB_SAP_GB_ID_PKF: '7000/703003/22',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7049784,
    GB_WE_OBJ_ID_FKT: 1989140,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1223.19,
    GB_NUTZFL_NF_VON_NGF: 1098.6,
    GB_TECHNIKFL_TF_VON_NGF: 10.87,
    GB_VERKEHRSFL_VF_VON_NGF: 113.72,
    GB_MIETFL_GESAMT: 1223.19,
    GB_MIETFL_1_BSB: 925.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990562,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1729.45,
    GB_NUTZFL_NF_VON_NGF: 1117.45,
    GB_TECHNIKFL_TF_VON_NGF: 33.56,
    GB_VERKEHRSFL_VF_VON_NGF: 578.44,
    GB_MIETFL_GESAMT: 1729.45,
    GB_MIETFL_1_BSB: 1501.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990563,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 891.77,
    GB_NUTZFL_NF_VON_NGF: 738.64,
    GB_TECHNIKFL_TF_VON_NGF: 3.2,
    GB_VERKEHRSFL_VF_VON_NGF: 149.93,
    GB_MIETFL_GESAMT: 891.77,
    GB_MIETFL_1_BSB: 891.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990564,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 488.68,
    GB_NUTZFL_NF_VON_NGF: 389.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 99.13,
    GB_MIETFL_GESAMT: 488.68,
    GB_MIETFL_1_BSB: 488.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990565,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.633007',
    GB_LAENGENGRAD_OESTLICH: '9.919004',
    GB_NETTOGRUNDFL_NGF: 594.04,
    GB_NUTZFL_NF_VON_NGF: 565.95,
    GB_TECHNIKFL_TF_VON_NGF: 18.7,
    GB_VERKEHRSFL_VF_VON_NGF: 9.39,
    GB_MIETFL_GESAMT: 594.04,
    GB_MIETFL_1_BSB: 594.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990566,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 261.7,
    GB_NUTZFL_NF_VON_NGF: 238.09,
    GB_TECHNIKFL_TF_VON_NGF: 4.6,
    GB_VERKEHRSFL_VF_VON_NGF: 19.01,
    GB_MIETFL_GESAMT: 261.7,
    GB_MIETFL_1_BSB: 261.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.05.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990567,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.633048',
    GB_LAENGENGRAD_OESTLICH: '9.918434',
    GB_NETTOGRUNDFL_NGF: 916.42,
    GB_NUTZFL_NF_VON_NGF: 622.11,
    GB_TECHNIKFL_TF_VON_NGF: 10.84,
    GB_VERKEHRSFL_VF_VON_NGF: 283.47,
    GB_MIETFL_GESAMT: 916.42,
    GB_MIETFL_1_BSB: 914.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7204581,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703016,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703016',
    GB_SAP_GB_ID_PKF: '7000/703016/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7204582,
    GB_WE_OBJ_ID_FKT: 1989153,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2614.4,
    GB_NUTZFL_NF_VON_NGF: 1219.92,
    GB_TECHNIKFL_TF_VON_NGF: 261.41,
    GB_VERKEHRSFL_VF_VON_NGF: 1133.07,
    GB_MIETFL_GESAMT: 2614.4,
    GB_MIETFL_1_BSB: 2614.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990611,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 549.47,
    GB_NUTZFL_NF_VON_NGF: 392.09,
    GB_TECHNIKFL_TF_VON_NGF: 13.19,
    GB_VERKEHRSFL_VF_VON_NGF: 144.19,
    GB_MIETFL_GESAMT: 549.47,
    GB_MIETFL_1_BSB: 549.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990612,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 116.06,
    GB_NUTZFL_NF_VON_NGF: 96.06,
    GB_TECHNIKFL_TF_VON_NGF: 5.09,
    GB_VERKEHRSFL_VF_VON_NGF: 14.91,
    GB_MIETFL_GESAMT: 116.06,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990613,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 269.98,
    GB_NUTZFL_NF_VON_NGF: 205.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 64.34,
    GB_MIETFL_GESAMT: 269.98,
    GB_MIETFL_1_BSB: 269.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990614,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 686.52,
    GB_NUTZFL_NF_VON_NGF: 606.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.01,
    GB_MIETFL_GESAMT: 686.52,
    GB_MIETFL_1_BSB: 686.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990615,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 738.12,
    GB_NUTZFL_NF_VON_NGF: 537.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 200.68,
    GB_MIETFL_GESAMT: 738.12,
    GB_MIETFL_1_BSB: 738.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990616,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3345.77,
    GB_NUTZFL_NF_VON_NGF: 2496.5,
    GB_TECHNIKFL_TF_VON_NGF: 75.07,
    GB_VERKEHRSFL_VF_VON_NGF: 774.2,
    GB_MIETFL_GESAMT: 3345.77,
    GB_MIETFL_1_BSB: 3265.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990617,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2364.16,
    GB_NUTZFL_NF_VON_NGF: 1745.65,
    GB_TECHNIKFL_TF_VON_NGF: 108.16,
    GB_VERKEHRSFL_VF_VON_NGF: 510.35,
    GB_MIETFL_GESAMT: 2364.16,
    GB_MIETFL_1_BSB: 2230.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990618,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5226.98,
    GB_NUTZFL_NF_VON_NGF: 3915.62,
    GB_TECHNIKFL_TF_VON_NGF: 359.53,
    GB_VERKEHRSFL_VF_VON_NGF: 951.83,
    GB_MIETFL_GESAMT: 5226.98,
    GB_MIETFL_1_BSB: 5145.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990619,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.623895',
    GB_LAENGENGRAD_OESTLICH: '9.904288',
    GB_NETTOGRUNDFL_NGF: 624.57,
    GB_NUTZFL_NF_VON_NGF: 595.88,
    GB_TECHNIKFL_TF_VON_NGF: 6.59,
    GB_VERKEHRSFL_VF_VON_NGF: 22.1,
    GB_MIETFL_GESAMT: 624.57,
    GB_MIETFL_1_BSB: 624.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990620,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.624149',
    GB_LAENGENGRAD_OESTLICH: '9.904884',
    GB_NETTOGRUNDFL_NGF: 1387.02,
    GB_NUTZFL_NF_VON_NGF: 1362.04,
    GB_TECHNIKFL_TF_VON_NGF: 8.91,
    GB_VERKEHRSFL_VF_VON_NGF: 16.07,
    GB_MIETFL_GESAMT: 1387.02,
    GB_MIETFL_1_BSB: 1387.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990621,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/12',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6.18,
    GB_NUTZFL_NF_VON_NGF: 6.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 6.18,
    GB_MIETFL_1_BSB: 6.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990622,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1407.48,
    GB_NUTZFL_NF_VON_NGF: 1268.73,
    GB_TECHNIKFL_TF_VON_NGF: 15.83,
    GB_VERKEHRSFL_VF_VON_NGF: 122.92,
    GB_MIETFL_GESAMT: 1407.48,
    GB_MIETFL_1_BSB: 1403.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '02.03.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7183788,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 252.97,
    GB_NUTZFL_NF_VON_NGF: 213.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.22',
    GB_GB_OBJ_ID_PKT: 4352726,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 674.47,
    GB_NUTZFL_NF_VON_NGF: 613.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 61.46,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '05.07.19',
    GB_GUELTIG_BIS: '28.02.23',
    GB_GB_OBJ_ID_PKT: 7058643,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 350.18,
    GB_NUTZFL_NF_VON_NGF: 305.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '05.07.19',
    GB_GUELTIG_BIS: '28.02.23',
    GB_GB_OBJ_ID_PKT: 7058644,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/43',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 350.17,
    GB_NUTZFL_NF_VON_NGF: 305.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.95,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '05.07.19',
    GB_GUELTIG_BIS: '31.03.22',
    GB_GB_OBJ_ID_PKT: 7058645,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/44',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 348.81,
    GB_NUTZFL_NF_VON_NGF: 303.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '05.07.19',
    GB_GUELTIG_BIS: '31.03.22',
    GB_GB_OBJ_ID_PKT: 7058646,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703026,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/703026',
    GB_SAP_GB_ID_PKF: '7000/703026/45',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 510.88,
    GB_NUTZFL_NF_VON_NGF: 439.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.64,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '05.07.19',
    GB_GUELTIG_BIS: '31.03.22',
    GB_GB_OBJ_ID_PKT: 7058647,
    GB_WE_OBJ_ID_FKT: 1989163,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703038',
    GB_SAP_GB_ID_PKF: '7000/703038/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.646955',
    GB_LAENGENGRAD_OESTLICH: '9.919795',
    GB_NETTOGRUNDFL_NGF: 3577.25,
    GB_NUTZFL_NF_VON_NGF: 2688.76,
    GB_TECHNIKFL_TF_VON_NGF: 74.13,
    GB_VERKEHRSFL_VF_VON_NGF: 814.36,
    GB_MIETFL_GESAMT: 3577.25,
    GB_MIETFL_1_BSB: 3495.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990704,
    GB_WE_OBJ_ID_FKT: 1989174,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1257.05,
    GB_NUTZFL_NF_VON_NGF: 836.29,
    GB_TECHNIKFL_TF_VON_NGF: 121.56,
    GB_VERKEHRSFL_VF_VON_NGF: 299.2,
    GB_MIETFL_GESAMT: 1257.05,
    GB_MIETFL_1_BSB: 1065.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990705,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.28,
    GB_NUTZFL_NF_VON_NGF: 179.18,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 32.47,
    GB_MIETFL_GESAMT: 215.28,
    GB_MIETFL_1_BSB: 215.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990706,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.11,
    GB_NUTZFL_NF_VON_NGF: 168.79,
    GB_TECHNIKFL_TF_VON_NGF: 6.21,
    GB_VERKEHRSFL_VF_VON_NGF: 31.11,
    GB_MIETFL_GESAMT: 206.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 206.11,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990707,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.628038',
    GB_LAENGENGRAD_OESTLICH: '9.924635',
    GB_NETTOGRUNDFL_NGF: 322.69,
    GB_NUTZFL_NF_VON_NGF: 300.65,
    GB_TECHNIKFL_TF_VON_NGF: 7.65,
    GB_VERKEHRSFL_VF_VON_NGF: 14.39,
    GB_MIETFL_GESAMT: 322.69,
    GB_MIETFL_1_BSB: 322.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990708,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1315.88,
    GB_NUTZFL_NF_VON_NGF: 1060.4,
    GB_TECHNIKFL_TF_VON_NGF: 17.79,
    GB_VERKEHRSFL_VF_VON_NGF: 237.69,
    GB_MIETFL_GESAMT: 1315.88,
    GB_MIETFL_1_BSB: 1315.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990710,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 456.25,
    GB_NUTZFL_NF_VON_NGF: 376.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.84,
    GB_MIETFL_GESAMT: 456.25,
    GB_MIETFL_1_BSB: 456.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990711,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.628027',
    GB_LAENGENGRAD_OESTLICH: '9.925069',
    GB_NETTOGRUNDFL_NGF: 659.25,
    GB_NUTZFL_NF_VON_NGF: 612.47,
    GB_TECHNIKFL_TF_VON_NGF: 17.4,
    GB_VERKEHRSFL_VF_VON_NGF: 29.38,
    GB_MIETFL_GESAMT: 659.25,
    GB_MIETFL_1_BSB: 659.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990709,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 914.56,
    GB_NUTZFL_NF_VON_NGF: 755.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 159.35,
    GB_MIETFL_GESAMT: 914.56,
    GB_MIETFL_1_BSB: 914.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990712,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 914.14,
    GB_NUTZFL_NF_VON_NGF: 755.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 158.73,
    GB_MIETFL_GESAMT: 914.14,
    GB_MIETFL_1_BSB: 914.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990713,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.45,
    GB_NUTZFL_NF_VON_NGF: 88.47,
    GB_TECHNIKFL_TF_VON_NGF: 4.1,
    GB_VERKEHRSFL_VF_VON_NGF: 11.88,
    GB_MIETFL_GESAMT: 104.45,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990714,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.04,
    GB_NUTZFL_NF_VON_NGF: 136.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.12.13',
    GB_GB_OBJ_ID_PKT: 1990715,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/12',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7486629,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703039,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703039',
    GB_SAP_GB_ID_PKF: '7000/703039/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.14',
    GB_GB_OBJ_ID_PKT: 4352732,
    GB_WE_OBJ_ID_FKT: 1989175,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 0.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.08.22',
    GB_GB_OBJ_ID_PKT: 1990721,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.31,
    GB_NUTZFL_NF_VON_NGF: 80.25,
    GB_TECHNIKFL_TF_VON_NGF: 4.73,
    GB_VERKEHRSFL_VF_VON_NGF: 17.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.17',
    GB_GB_OBJ_ID_PKT: 1990722,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 138.03,
    GB_NUTZFL_NF_VON_NGF: 116.24,
    GB_TECHNIKFL_TF_VON_NGF: 10.96,
    GB_VERKEHRSFL_VF_VON_NGF: 10.83,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990723,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 773.51,
    GB_NUTZFL_NF_VON_NGF: 654.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 119.37,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990724,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2180.76,
    GB_NUTZFL_NF_VON_NGF: 1696.14,
    GB_TECHNIKFL_TF_VON_NGF: 123.04,
    GB_VERKEHRSFL_VF_VON_NGF: 361.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990725,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 557.67,
    GB_NUTZFL_NF_VON_NGF: 512.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.41,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990726,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 932.67,
    GB_NUTZFL_NF_VON_NGF: 753.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 179.01,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990727,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703041,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703041',
    GB_SAP_GB_ID_PKF: '7000/703041/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.594375',
    GB_LAENGENGRAD_OESTLICH: '9.931023',
    GB_NETTOGRUNDFL_NGF: 627.09,
    GB_NUTZFL_NF_VON_NGF: 589.43,
    GB_TECHNIKFL_TF_VON_NGF: 12.55,
    GB_VERKEHRSFL_VF_VON_NGF: 25.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990728,
    GB_WE_OBJ_ID_FKT: 1989177,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1548.94,
    GB_NUTZFL_NF_VON_NGF: 1311.06,
    GB_TECHNIKFL_TF_VON_NGF: 12.84,
    GB_VERKEHRSFL_VF_VON_NGF: 225.04,
    GB_MIETFL_GESAMT: 1548.94,
    GB_MIETFL_1_BSB: 1548.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990729,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2313.62,
    GB_NUTZFL_NF_VON_NGF: 1779.72,
    GB_TECHNIKFL_TF_VON_NGF: 100.49,
    GB_VERKEHRSFL_VF_VON_NGF: 433.41,
    GB_MIETFL_GESAMT: 2313.62,
    GB_MIETFL_1_BSB: 2131.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990730,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3829.96,
    GB_NUTZFL_NF_VON_NGF: 2570.61,
    GB_TECHNIKFL_TF_VON_NGF: 97.29,
    GB_VERKEHRSFL_VF_VON_NGF: 1162.06,
    GB_MIETFL_GESAMT: 3829.96,
    GB_MIETFL_1_BSB: 3701.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990731,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.585982',
    GB_LAENGENGRAD_OESTLICH: '9.941620',
    GB_NETTOGRUNDFL_NGF: 1727.92,
    GB_NUTZFL_NF_VON_NGF: 1636.93,
    GB_TECHNIKFL_TF_VON_NGF: 34.73,
    GB_VERKEHRSFL_VF_VON_NGF: 56.26,
    GB_MIETFL_GESAMT: 1727.92,
    GB_MIETFL_1_BSB: 1727.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990732,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1641.55,
    GB_NUTZFL_NF_VON_NGF: 1259.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 382.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1990733,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 879.55,
    GB_NUTZFL_NF_VON_NGF: 711.64,
    GB_TECHNIKFL_TF_VON_NGF: 7.62,
    GB_VERKEHRSFL_VF_VON_NGF: 160.29,
    GB_MIETFL_GESAMT: 879.55,
    GB_MIETFL_1_BSB: 879.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990734,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.68,
    GB_NUTZFL_NF_VON_NGF: 355.82,
    GB_TECHNIKFL_TF_VON_NGF: 3.81,
    GB_VERKEHRSFL_VF_VON_NGF: 80.05,
    GB_MIETFL_GESAMT: 439.68,
    GB_MIETFL_1_BSB: 439.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990735,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 117.45,
    GB_NUTZFL_NF_VON_NGF: 87.57,
    GB_TECHNIKFL_TF_VON_NGF: 8.1,
    GB_VERKEHRSFL_VF_VON_NGF: 21.78,
    GB_MIETFL_GESAMT: 117.45,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138307,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1293.52,
    GB_NUTZFL_NF_VON_NGF: 1031.43,
    GB_TECHNIKFL_TF_VON_NGF: 70.45,
    GB_VERKEHRSFL_VF_VON_NGF: 191.64,
    GB_MIETFL_GESAMT: 1293.52,
    GB_MIETFL_1_BSB: 1293.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138308,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.586348',
    GB_LAENGENGRAD_OESTLICH: '9.941689',
    GB_NETTOGRUNDFL_NGF: 1262.26,
    GB_NUTZFL_NF_VON_NGF: 1233.55,
    GB_TECHNIKFL_TF_VON_NGF: 6.56,
    GB_VERKEHRSFL_VF_VON_NGF: 22.15,
    GB_MIETFL_GESAMT: 1262.26,
    GB_MIETFL_1_BSB: 1262.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.10.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407549,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 723.99,
    GB_NUTZFL_NF_VON_NGF: 653.8,
    GB_TECHNIKFL_TF_VON_NGF: 5.48,
    GB_VERKEHRSFL_VF_VON_NGF: 64.71,
    GB_MIETFL_GESAMT: 723.99,
    GB_MIETFL_1_BSB: 723.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.09.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407550,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703042,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/703042',
    GB_SAP_GB_ID_PKF: '7000/703042/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2166.98,
    GB_NUTZFL_NF_VON_NGF: 1674.03,
    GB_TECHNIKFL_TF_VON_NGF: 19.72,
    GB_VERKEHRSFL_VF_VON_NGF: 473.23,
    GB_MIETFL_GESAMT: 2166.98,
    GB_MIETFL_1_BSB: 2160.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.06.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5445849,
    GB_WE_OBJ_ID_FKT: 1989178,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703007',
    GB_SAP_GB_ID_PKF: '7000/703007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.585008',
    GB_LAENGENGRAD_OESTLICH: '9.939348',
    GB_NETTOGRUNDFL_NGF: 3998.76,
    GB_NUTZFL_NF_VON_NGF: 3117.17,
    GB_TECHNIKFL_TF_VON_NGF: 28.85,
    GB_VERKEHRSFL_VF_VON_NGF: 852.74,
    GB_MIETFL_GESAMT: 3626.85,
    GB_MIETFL_1_BSB: 2965.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990492,
    GB_WE_OBJ_ID_FKT: 1989144,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703007',
    GB_SAP_GB_ID_PKF: '7000/703007/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990493,
    GB_WE_OBJ_ID_FKT: 1989144,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703007',
    GB_SAP_GB_ID_PKF: '7000/703007/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7051690,
    GB_WE_OBJ_ID_FKT: 1989144,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703007',
    GB_SAP_GB_ID_PKF: '7000/703007/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7051691,
    GB_WE_OBJ_ID_FKT: 1989144,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7020907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 896.51,
    GB_NUTZFL_NF_VON_NGF: 765.92,
    GB_TECHNIKFL_TF_VON_NGF: 14.38,
    GB_VERKEHRSFL_VF_VON_NGF: 116.21,
    GB_MIETFL_GESAMT: 896.51,
    GB_MIETFL_1_BSB: 670.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990639,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.5,
    GB_NUTZFL_NF_VON_NGF: 178.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.72,
    GB_MIETFL_GESAMT: 192.5,
    GB_MIETFL_1_BSB: 192.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990640,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.57,
    GB_NUTZFL_NF_VON_NGF: 453.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.43,
    GB_MIETFL_GESAMT: 489.57,
    GB_MIETFL_1_BSB: 297.32,
    GB_MIETFL_2_DRITTE: 192.25,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990641,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 851.91,
    GB_NUTZFL_NF_VON_NGF: 653.03,
    GB_TECHNIKFL_TF_VON_NGF: 1.64,
    GB_VERKEHRSFL_VF_VON_NGF: 197.24,
    GB_MIETFL_GESAMT: 851.91,
    GB_MIETFL_1_BSB: 851.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990642,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 175.06,
    GB_NUTZFL_NF_VON_NGF: 151.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 23.25,
    GB_MIETFL_GESAMT: 175.06,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 175.06,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990643,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1457.54,
    GB_NUTZFL_NF_VON_NGF: 1161.6,
    GB_TECHNIKFL_TF_VON_NGF: 81.85,
    GB_VERKEHRSFL_VF_VON_NGF: 214.09,
    GB_MIETFL_GESAMT: 1457.54,
    GB_MIETFL_1_BSB: 1305.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.07.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990644,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703031',
    GB_SAP_GB_ID_PKF: '7000/703031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.588830',
    GB_LAENGENGRAD_OESTLICH: '9.925532',
    GB_NETTOGRUNDFL_NGF: 609.95,
    GB_NUTZFL_NF_VON_NGF: 577.95,
    GB_TECHNIKFL_TF_VON_NGF: 16.29,
    GB_VERKEHRSFL_VF_VON_NGF: 15.71,
    GB_MIETFL_GESAMT: 609.95,
    GB_MIETFL_1_BSB: 609.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990645,
    GB_WE_OBJ_ID_FKT: 1989168,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800360,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3187.28,
    GB_NUTZFL_NF_VON_NGF: 2167.53,
    GB_TECHNIKFL_TF_VON_NGF: 97.71,
    GB_VERKEHRSFL_VF_VON_NGF: 922.04,
    GB_MIETFL_GESAMT: 3187.28,
    GB_MIETFL_1_BSB: 3024.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990422,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 972.37,
    GB_NUTZFL_NF_VON_NGF: 785.42,
    GB_TECHNIKFL_TF_VON_NGF: 24.38,
    GB_VERKEHRSFL_VF_VON_NGF: 162.57,
    GB_MIETFL_GESAMT: 972.37,
    GB_MIETFL_1_BSB: 946.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990423,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 363.73,
    GB_NUTZFL_NF_VON_NGF: 295.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.16,
    GB_MIETFL_GESAMT: 363.73,
    GB_MIETFL_1_BSB: 363.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990424,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 622.79,
    GB_NUTZFL_NF_VON_NGF: 478.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 144.68,
    GB_MIETFL_GESAMT: 622.79,
    GB_MIETFL_1_BSB: 622.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990425,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 623.11,
    GB_NUTZFL_NF_VON_NGF: 478.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 145.0,
    GB_MIETFL_GESAMT: 623.11,
    GB_MIETFL_1_BSB: 623.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990426,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1238.12,
    GB_NUTZFL_NF_VON_NGF: 978.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 259.35,
    GB_MIETFL_GESAMT: 1238.12,
    GB_MIETFL_1_BSB: 1210.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990427,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.601759',
    GB_LAENGENGRAD_OESTLICH: '9.924143',
    GB_NETTOGRUNDFL_NGF: 2043.87,
    GB_NUTZFL_NF_VON_NGF: 1799.05,
    GB_TECHNIKFL_TF_VON_NGF: 97.1,
    GB_VERKEHRSFL_VF_VON_NGF: 147.72,
    GB_MIETFL_GESAMT: 2043.87,
    GB_MIETFL_1_BSB: 2003.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990428,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 142.28,
    GB_NUTZFL_NF_VON_NGF: 98.47,
    GB_TECHNIKFL_TF_VON_NGF: 19.86,
    GB_VERKEHRSFL_VF_VON_NGF: 23.95,
    GB_MIETFL_GESAMT: 142.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990429,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7407695,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703001,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/703001',
    GB_SAP_GB_ID_PKF: '7000/703001/10',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7479802,
    GB_WE_OBJ_ID_FKT: 1989138,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703046',
    GB_SAP_GB_ID_PKF: '7000/703046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1707.91,
    GB_NUTZFL_NF_VON_NGF: 1297.08,
    GB_TECHNIKFL_TF_VON_NGF: 144.81,
    GB_VERKEHRSFL_VF_VON_NGF: 266.02,
    GB_MIETFL_GESAMT: 1707.91,
    GB_MIETFL_1_BSB: 1399.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990760,
    GB_WE_OBJ_ID_FKT: 1989182,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703046',
    GB_SAP_GB_ID_PKF: '7000/703046/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.602315',
    GB_LAENGENGRAD_OESTLICH: '9.922866',
    GB_NETTOGRUNDFL_NGF: 621.54,
    GB_NUTZFL_NF_VON_NGF: 580.25,
    GB_TECHNIKFL_TF_VON_NGF: 14.99,
    GB_VERKEHRSFL_VF_VON_NGF: 26.3,
    GB_MIETFL_GESAMT: 621.54,
    GB_MIETFL_1_BSB: 621.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.11.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990761,
    GB_WE_OBJ_ID_FKT: 1989182,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703046',
    GB_SAP_GB_ID_PKF: '7000/703046/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 420.3,
    GB_NUTZFL_NF_VON_NGF: 390.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.0,
    GB_MIETFL_GESAMT: 420.3,
    GB_MIETFL_1_BSB: 329.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990762,
    GB_WE_OBJ_ID_FKT: 1989182,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703046,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703046',
    GB_SAP_GB_ID_PKF: '7000/703046/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1378.17,
    GB_NUTZFL_NF_VON_NGF: 1166.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 211.54,
    GB_MIETFL_GESAMT: 1378.17,
    GB_MIETFL_1_BSB: 940.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990763,
    GB_WE_OBJ_ID_FKT: 1989182,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703046',
    GB_SAP_GB_ID_PKF: '7000/703046/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1175.81,
    GB_NUTZFL_NF_VON_NGF: 965.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 210.42,
    GB_MIETFL_GESAMT: 1175.81,
    GB_MIETFL_1_BSB: 953.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.10.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990764,
    GB_WE_OBJ_ID_FKT: 1989182,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 7035707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 523.98,
    GB_NUTZFL_NF_VON_NGF: 455.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.93,
    GB_MIETFL_GESAMT: 523.98,
    GB_MIETFL_1_BSB: 523.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990631,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1586.27,
    GB_NUTZFL_NF_VON_NGF: 1035.58,
    GB_TECHNIKFL_TF_VON_NGF: 106.19,
    GB_VERKEHRSFL_VF_VON_NGF: 444.5,
    GB_MIETFL_GESAMT: 1586.27,
    GB_MIETFL_1_BSB: 1472.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990632,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 514.8,
    GB_NUTZFL_NF_VON_NGF: 436.34,
    GB_TECHNIKFL_TF_VON_NGF: 18.1,
    GB_VERKEHRSFL_VF_VON_NGF: 60.36,
    GB_MIETFL_GESAMT: 514.8,
    GB_MIETFL_1_BSB: 387.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990633,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.54,
    GB_NUTZFL_NF_VON_NGF: 382.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 70.21,
    GB_MIETFL_GESAMT: 452.54,
    GB_MIETFL_1_BSB: 452.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '09.11.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990634,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.69,
    GB_NUTZFL_NF_VON_NGF: 373.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.17,
    GB_MIETFL_GESAMT: 452.69,
    GB_MIETFL_1_BSB: 452.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990635,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.616225',
    GB_LAENGENGRAD_OESTLICH: '9.899542',
    GB_NETTOGRUNDFL_NGF: 622.25,
    GB_NUTZFL_NF_VON_NGF: 593.25,
    GB_TECHNIKFL_TF_VON_NGF: 6.79,
    GB_VERKEHRSFL_VF_VON_NGF: 22.21,
    GB_MIETFL_GESAMT: 622.25,
    GB_MIETFL_1_BSB: 622.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990636,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 151.11,
    GB_NUTZFL_NF_VON_NGF: 78.66,
    GB_TECHNIKFL_TF_VON_NGF: 38.83,
    GB_VERKEHRSFL_VF_VON_NGF: 33.62,
    GB_MIETFL_GESAMT: 151.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990637,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.52,
    GB_NUTZFL_NF_VON_NGF: 14.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990638,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7421253,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 186.89,
    GB_NUTZFL_NF_VON_NGF: 153.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.08,
    GB_MIETFL_GESAMT: 186.89,
    GB_MIETFL_1_BSB: 186.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '08.07.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7359076,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703030,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/703030',
    GB_SAP_GB_ID_PKF: '7000/703030/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552746,
    GB_WE_OBJ_ID_FKT: 1989167,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2055931,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703064',
    GB_SAP_GB_ID_PKF: '7000/703064/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2298.58,
    GB_NUTZFL_NF_VON_NGF: 1917.18,
    GB_TECHNIKFL_TF_VON_NGF: 49.37,
    GB_VERKEHRSFL_VF_VON_NGF: 332.03,
    GB_MIETFL_GESAMT: 2298.58,
    GB_MIETFL_1_BSB: 2297.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.10.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399921,
    GB_WE_OBJ_ID_FKT: 2862423,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703055',
    GB_SAP_GB_ID_PKF: '7000/703055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: '53.582032',
    GB_LAENGENGRAD_OESTLICH: '9.946416',
    GB_NETTOGRUNDFL_NGF: 4862.74,
    GB_NUTZFL_NF_VON_NGF: 3377.92,
    GB_TECHNIKFL_TF_VON_NGF: 84.48,
    GB_VERKEHRSFL_VF_VON_NGF: 1400.34,
    GB_MIETFL_GESAMT: 4862.74,
    GB_MIETFL_1_BSB: 3845.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990790,
    GB_WE_OBJ_ID_FKT: 1989191,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703055',
    GB_SAP_GB_ID_PKF: '7000/703055/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1931.27,
    GB_NUTZFL_NF_VON_NGF: 1409.13,
    GB_TECHNIKFL_TF_VON_NGF: 118.92,
    GB_VERKEHRSFL_VF_VON_NGF: 403.22,
    GB_MIETFL_GESAMT: 1931.27,
    GB_MIETFL_1_BSB: 1920.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399920,
    GB_WE_OBJ_ID_FKT: 1989191,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703054',
    GB_SAP_GB_ID_PKF: '7000/703054/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.572400',
    GB_LAENGENGRAD_OESTLICH: '9.970066',
    GB_NETTOGRUNDFL_NGF: 7114.24,
    GB_NUTZFL_NF_VON_NGF: 4533.84,
    GB_TECHNIKFL_TF_VON_NGF: 210.35,
    GB_VERKEHRSFL_VF_VON_NGF: 2370.05,
    GB_MIETFL_GESAMT: 7114.24,
    GB_MIETFL_1_BSB: 6892.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990789,
    GB_WE_OBJ_ID_FKT: 1989190,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703058',
    GB_SAP_GB_ID_PKF: '7000/703058/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1990794,
    GB_WE_OBJ_ID_FKT: 1989193,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703058',
    GB_SAP_GB_ID_PKF: '7000/703058/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.579724',
    GB_LAENGENGRAD_OESTLICH: '9.950192',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1990795,
    GB_WE_OBJ_ID_FKT: 1989193,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703050',
    GB_SAP_GB_ID_PKF: '7000/703050/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.583529',
    GB_LAENGENGRAD_OESTLICH: '9.992017',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 1990782,
    GB_WE_OBJ_ID_FKT: 1989186,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703050',
    GB_SAP_GB_ID_PKF: '7000/703050/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 1990783,
    GB_WE_OBJ_ID_FKT: 1989186,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3862.21,
    GB_NUTZFL_NF_VON_NGF: 2910.41,
    GB_TECHNIKFL_TF_VON_NGF: 86.52,
    GB_VERKEHRSFL_VF_VON_NGF: 865.28,
    GB_MIETFL_GESAMT: 3862.21,
    GB_MIETFL_1_BSB: 3859.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990774,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1774.19,
    GB_NUTZFL_NF_VON_NGF: 1157.28,
    GB_TECHNIKFL_TF_VON_NGF: 88.87,
    GB_VERKEHRSFL_VF_VON_NGF: 528.04,
    GB_MIETFL_GESAMT: 1774.19,
    GB_MIETFL_1_BSB: 1716.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990775,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 286.19,
    GB_NUTZFL_NF_VON_NGF: 71.02,
    GB_TECHNIKFL_TF_VON_NGF: 10.68,
    GB_VERKEHRSFL_VF_VON_NGF: 204.49,
    GB_MIETFL_GESAMT: 286.19,
    GB_MIETFL_1_BSB: 286.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990776,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1069.85,
    GB_NUTZFL_NF_VON_NGF: 852.2,
    GB_TECHNIKFL_TF_VON_NGF: 3.05,
    GB_VERKEHRSFL_VF_VON_NGF: 214.6,
    GB_MIETFL_GESAMT: 1069.85,
    GB_MIETFL_1_BSB: 1069.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990777,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1145.55,
    GB_NUTZFL_NF_VON_NGF: 907.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 238.02,
    GB_MIETFL_GESAMT: 1145.55,
    GB_MIETFL_1_BSB: 1145.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990778,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 585.23,
    GB_NUTZFL_NF_VON_NGF: 548.28,
    GB_TECHNIKFL_TF_VON_NGF: 19.6,
    GB_VERKEHRSFL_VF_VON_NGF: 17.35,
    GB_MIETFL_GESAMT: 585.23,
    GB_MIETFL_1_BSB: 585.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990779,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.618082',
    GB_LAENGENGRAD_OESTLICH: '9.947102',
    GB_NETTOGRUNDFL_NGF: 619.9,
    GB_NUTZFL_NF_VON_NGF: 590.15,
    GB_TECHNIKFL_TF_VON_NGF: 9.27,
    GB_VERKEHRSFL_VF_VON_NGF: 20.48,
    GB_MIETFL_GESAMT: 619.9,
    GB_MIETFL_1_BSB: 619.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990780,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703048,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/703048',
    GB_SAP_GB_ID_PKF: '7000/703048/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | enutzt',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.15',
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5399919,
    GB_WE_OBJ_ID_FKT: 1989184,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703052',
    GB_SAP_GB_ID_PKF: '7000/703052/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1892,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.07.21',
    GB_GB_OBJ_ID_PKT: 1990786,
    GB_WE_OBJ_ID_FKT: 1989188,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703052',
    GB_SAP_GB_ID_PKF: '7000/703052/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 255.0,
    GB_NUTZFL_NF_VON_NGF: 255.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990787,
    GB_WE_OBJ_ID_FKT: 1989188,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703051',
    GB_SAP_GB_ID_PKF: '7000/703051/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990784,
    GB_WE_OBJ_ID_FKT: 1989187,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 6842810,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703051',
    GB_SAP_GB_ID_PKF: '7000/703051/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 1990785,
    GB_WE_OBJ_ID_FKT: 1989187,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 6842810,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703051',
    GB_SAP_GB_ID_PKF: '7000/703051/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 2138240,
    GB_WE_OBJ_ID_FKT: 1989187,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 6842810,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703051',
    GB_SAP_GB_ID_PKF: '7000/703051/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 4352738,
    GB_WE_OBJ_ID_FKT: 1989187,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 6842810,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703057',
    GB_SAP_GB_ID_PKF: '7000/703057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990791,
    GB_WE_OBJ_ID_FKT: 1989192,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800433,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703057',
    GB_SAP_GB_ID_PKF: '7000/703057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990792,
    GB_WE_OBJ_ID_FKT: 1989192,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800433,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703057',
    GB_SAP_GB_ID_PKF: '7000/703057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: '53.577988',
    GB_LAENGENGRAD_OESTLICH: '9.952609',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990793,
    GB_WE_OBJ_ID_FKT: 1989192,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800433,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703059',
    GB_SAP_GB_ID_PKF: '7000/703059/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990796,
    GB_WE_OBJ_ID_FKT: 1989194,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708011',
    GB_SAP_GB_ID_PKF: '7000/708011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: '53.570566226',
    GB_LAENGENGRAD_OESTLICH: '9.9669596005',
    GB_NETTOGRUNDFL_NGF: 2750.0,
    GB_NUTZFL_NF_VON_NGF: 2750.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236685,
    GB_WE_OBJ_ID_FKT: 7236651,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708014',
    GB_SAP_GB_ID_PKF: '7000/708014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: '53.569838940',
    GB_LAENGENGRAD_OESTLICH: '9.9664080196',
    GB_NETTOGRUNDFL_NGF: 1483.13,
    GB_NUTZFL_NF_VON_NGF: 1140.85,
    GB_TECHNIKFL_TF_VON_NGF: 15.13,
    GB_VERKEHRSFL_VF_VON_NGF: 327.15,
    GB_MIETFL_GESAMT: 9.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236688,
    GB_WE_OBJ_ID_FKT: 7236654,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708015',
    GB_SAP_GB_ID_PKF: '7000/708015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.570225830',
    GB_LAENGENGRAD_OESTLICH: '9.9669001363',
    GB_NETTOGRUNDFL_NGF: 1358.34,
    GB_NUTZFL_NF_VON_NGF: 1164.16,
    GB_TECHNIKFL_TF_VON_NGF: 16.93,
    GB_VERKEHRSFL_VF_VON_NGF: 177.25,
    GB_MIETFL_GESAMT: 46.95,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236689,
    GB_WE_OBJ_ID_FKT: 7236655,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1894,
    GB_BREITENGRAD_NOERDLICH: '53.570088144',
    GB_LAENGENGRAD_OESTLICH: '9.9630900982',
    GB_NETTOGRUNDFL_NGF: 4300.0,
    GB_NUTZFL_NF_VON_NGF: 4300.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236682,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/2',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.569941',
    GB_LAENGENGRAD_OESTLICH: '9.963557',
    GB_NETTOGRUNDFL_NGF: 420.0,
    GB_NUTZFL_NF_VON_NGF: 420.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236683,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/3',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.570460',
    GB_LAENGENGRAD_OESTLICH: '9.963148',
    GB_NETTOGRUNDFL_NGF: 1280.0,
    GB_NUTZFL_NF_VON_NGF: 1280.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236684,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/4',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7453197,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7486649,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708010,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/708010',
    GB_SAP_GB_ID_PKF: '7000/708010/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7486650,
    GB_WE_OBJ_ID_FKT: 7236650,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708013',
    GB_SAP_GB_ID_PKF: '7000/708013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.570432564',
    GB_LAENGENGRAD_OESTLICH: '9.9654206597',
    GB_NETTOGRUNDFL_NGF: 650.3,
    GB_NUTZFL_NF_VON_NGF: 520.81,
    GB_TECHNIKFL_TF_VON_NGF: 4.32,
    GB_VERKEHRSFL_VF_VON_NGF: 125.17,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236687,
    GB_WE_OBJ_ID_FKT: 7236653,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708012',
    GB_SAP_GB_ID_PKF: '7000/708012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1914,
    GB_BREITENGRAD_NOERDLICH: '53.570769988',
    GB_LAENGENGRAD_OESTLICH: '9.9660316238',
    GB_NETTOGRUNDFL_NGF: 4980.0,
    GB_NUTZFL_NF_VON_NGF: 4980.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236686,
    GB_WE_OBJ_ID_FKT: 7236652,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708002',
    GB_SAP_GB_ID_PKF: '7000/708002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.604425121',
    GB_LAENGENGRAD_OESTLICH: '9.9579459204',
    GB_NETTOGRUNDFL_NGF: 2113.0,
    GB_NUTZFL_NF_VON_NGF: 2113.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236663,
    GB_WE_OBJ_ID_FKT: 7148617,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708002',
    GB_SAP_GB_ID_PKF: '7000/708002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.604446871',
    GB_LAENGENGRAD_OESTLICH: '9.9590977059',
    GB_NETTOGRUNDFL_NGF: 455.0,
    GB_NUTZFL_NF_VON_NGF: 455.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236665,
    GB_WE_OBJ_ID_FKT: 7148617,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708002',
    GB_SAP_GB_ID_PKF: '7000/708002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.605457200',
    GB_LAENGENGRAD_OESTLICH: '9.9577388317',
    GB_NETTOGRUNDFL_NGF: 600.0,
    GB_NUTZFL_NF_VON_NGF: 600.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236666,
    GB_WE_OBJ_ID_FKT: 7148617,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708002',
    GB_SAP_GB_ID_PKF: '7000/708002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.604169285',
    GB_LAENGENGRAD_OESTLICH: '9.9587290694',
    GB_NETTOGRUNDFL_NGF: 2347.0,
    GB_NUTZFL_NF_VON_NGF: 2347.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7148620,
    GB_WE_OBJ_ID_FKT: 7148617,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708002',
    GB_SAP_GB_ID_PKF: '7000/708002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.605011056',
    GB_LAENGENGRAD_OESTLICH: '9.9577028452',
    GB_NETTOGRUNDFL_NGF: 1680.0,
    GB_NUTZFL_NF_VON_NGF: 1680.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236664,
    GB_WE_OBJ_ID_FKT: 7148617,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7531677,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708023',
    GB_SAP_GB_ID_PKF: '7000/708023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: '53.571305581',
    GB_LAENGENGRAD_OESTLICH: '9.9941033424',
    GB_NETTOGRUNDFL_NGF: 2239.78,
    GB_NUTZFL_NF_VON_NGF: 1608.31,
    GB_TECHNIKFL_TF_VON_NGF: 68.06,
    GB_VERKEHRSFL_VF_VON_NGF: 563.41,
    GB_MIETFL_GESAMT: 9.46,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7267088,
    GB_WE_OBJ_ID_FKT: 7267087,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708023',
    GB_SAP_GB_ID_PKF: '7000/708023/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: '53.571712321',
    GB_LAENGENGRAD_OESTLICH: '9.9938041807',
    GB_NETTOGRUNDFL_NGF: 1830.03,
    GB_NUTZFL_NF_VON_NGF: 1191.82,
    GB_TECHNIKFL_TF_VON_NGF: 54.7,
    GB_VERKEHRSFL_VF_VON_NGF: 583.51,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7267089,
    GB_WE_OBJ_ID_FKT: 7267087,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708023',
    GB_SAP_GB_ID_PKF: '7000/708023/3',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: '53.571551418',
    GB_LAENGENGRAD_OESTLICH: '9.9943428099',
    GB_NETTOGRUNDFL_NGF: 243.52,
    GB_NUTZFL_NF_VON_NGF: 183.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.29,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7267090,
    GB_WE_OBJ_ID_FKT: 7267087,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708017',
    GB_SAP_GB_ID_PKF: '7000/708017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2683.0,
    GB_NUTZFL_NF_VON_NGF: 2683.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236691,
    GB_WE_OBJ_ID_FKT: 7236657,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708017',
    GB_SAP_GB_ID_PKF: '7000/708017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2000.0,
    GB_NUTZFL_NF_VON_NGF: 2000.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236692,
    GB_WE_OBJ_ID_FKT: 7236657,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703062,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703062',
    GB_SAP_GB_ID_PKF: '7000/703062/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1990803,
    GB_WE_OBJ_ID_FKT: 1989197,
    GB_RGN_OBJ_ID_FKT: 5490070,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703062,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/703062',
    GB_SAP_GB_ID_PKF: '7000/703062/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1990804,
    GB_WE_OBJ_ID_FKT: 1989197,
    GB_RGN_OBJ_ID_FKT: 5490070,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703062,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/703062',
    GB_SAP_GB_ID_PKF: '7000/703062/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1990805,
    GB_WE_OBJ_ID_FKT: 1989197,
    GB_RGN_OBJ_ID_FKT: 5490070,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703062,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/703062',
    GB_SAP_GB_ID_PKF: '7000/703062/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 767.61,
    GB_NUTZFL_NF_VON_NGF: 629.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 137.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.08.15',
    GB_GB_OBJ_ID_PKT: 5177249,
    GB_WE_OBJ_ID_FKT: 1989197,
    GB_RGN_OBJ_ID_FKT: 5490070,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708001',
    GB_SAP_GB_ID_PKF: '7000/708001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1091.0,
    GB_NUTZFL_NF_VON_NGF: 1091.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Mietfrei',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.17',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5913912,
    GB_WE_OBJ_ID_FKT: 5913911,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1511.98,
    GB_NUTZFL_NF_VON_NGF: 1284.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 227.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992487,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 117.56,
    GB_NUTZFL_NF_VON_NGF: 97.03,
    GB_TECHNIKFL_TF_VON_NGF: 5.39,
    GB_VERKEHRSFL_VF_VON_NGF: 15.14,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992488,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4779.4,
    GB_NUTZFL_NF_VON_NGF: 3922.91,
    GB_TECHNIKFL_TF_VON_NGF: 43.44,
    GB_VERKEHRSFL_VF_VON_NGF: 813.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992489,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992490,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3151.02,
    GB_NUTZFL_NF_VON_NGF: 2386.5,
    GB_TECHNIKFL_TF_VON_NGF: 76.83,
    GB_VERKEHRSFL_VF_VON_NGF: 687.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992491,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2333.32,
    GB_NUTZFL_NF_VON_NGF: 1730.14,
    GB_TECHNIKFL_TF_VON_NGF: 102.98,
    GB_VERKEHRSFL_VF_VON_NGF: 500.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992492,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707024,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/707024',
    GB_SAP_GB_ID_PKF: '7000/707024/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 655.84,
    GB_NUTZFL_NF_VON_NGF: 610.48,
    GB_TECHNIKFL_TF_VON_NGF: 16.43,
    GB_VERKEHRSFL_VF_VON_NGF: 28.93,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992493,
    GB_WE_OBJ_ID_FKT: 1989442,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989734,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989735,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707066',
    GB_SAP_GB_ID_PKF: '7000/707066/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.465616',
    GB_LAENGENGRAD_OESTLICH: '9.959755',
    GB_NETTOGRUNDFL_NGF: 1122.31,
    GB_NUTZFL_NF_VON_NGF: 1037.87,
    GB_TECHNIKFL_TF_VON_NGF: 17.83,
    GB_VERKEHRSFL_VF_VON_NGF: 66.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 1992831,
    GB_WE_OBJ_ID_FKT: 1989480,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452753',
    GB_LAENGENGRAD_OESTLICH: '9.847736',
    GB_NETTOGRUNDFL_NGF: 210.83,
    GB_NUTZFL_NF_VON_NGF: 191.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: 38.29,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236702,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.452634',
    GB_LAENGENGRAD_OESTLICH: '9.847865',
    GB_NETTOGRUNDFL_NGF: 193.4,
    GB_NUTZFL_NF_VON_NGF: 154.01,
    GB_TECHNIKFL_TF_VON_NGF: 23.69,
    GB_VERKEHRSFL_VF_VON_NGF: 15.7,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236703,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/3',
    GB_SAP_GB_BEZEICHNUNG: 'Bettenhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bettenhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452432',
    GB_LAENGENGRAD_OESTLICH: '9.847966',
    GB_NETTOGRUNDFL_NGF: 228.09,
    GB_NUTZFL_NF_VON_NGF: 149.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 78.94,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236704,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/4',
    GB_SAP_GB_BEZEICHNUNG: 'Bettenhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bettenhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452209',
    GB_LAENGENGRAD_OESTLICH: '9.848094',
    GB_NETTOGRUNDFL_NGF: 306.13,
    GB_NUTZFL_NF_VON_NGF: 232.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 73.48,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236705,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/5',
    GB_SAP_GB_BEZEICHNUNG: 'Bettenhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bettenhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452834',
    GB_LAENGENGRAD_OESTLICH: '9.848614',
    GB_NETTOGRUNDFL_NGF: 364.95,
    GB_NUTZFL_NF_VON_NGF: 213.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 151.82,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236706,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/6',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452522',
    GB_LAENGENGRAD_OESTLICH: '9.848624',
    GB_NETTOGRUNDFL_NGF: 167.47,
    GB_NUTZFL_NF_VON_NGF: 110.59,
    GB_TECHNIKFL_TF_VON_NGF: 27.35,
    GB_VERKEHRSFL_VF_VON_NGF: 29.53,
    GB_MIETFL_GESAMT: 33.06,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236707,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.453250',
    GB_LAENGENGRAD_OESTLICH: '9.848263',
    GB_NETTOGRUNDFL_NGF: 222.44,
    GB_NUTZFL_NF_VON_NGF: 195.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.06,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236708,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.453200102',
    GB_LAENGENGRAD_OESTLICH: '9.8479018275',
    GB_NETTOGRUNDFL_NGF: 278.7,
    GB_NUTZFL_NF_VON_NGF: 254.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.41,
    GB_MIETFL_GESAMT: 157.49,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236709,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/9',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452800',
    GB_LAENGENGRAD_OESTLICH: '9.848041',
    GB_NETTOGRUNDFL_NGF: 31.01,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 27.91,
    GB_VERKEHRSFL_VF_VON_NGF: 3.1,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236710,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.452623',
    GB_LAENGENGRAD_OESTLICH: '9.847312',
    GB_NETTOGRUNDFL_NGF: 118.52,
    GB_NUTZFL_NF_VON_NGF: 115.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.05,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236711,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 31.4,
    GB_NUTZFL_NF_VON_NGF: 31.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236712,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708020,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/708020',
    GB_SAP_GB_ID_PKF: '7000/708020/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 27.56,
    GB_NUTZFL_NF_VON_NGF: 27.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 27.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7454919,
    GB_WE_OBJ_ID_FKT: 7236660,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707065,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707065',
    GB_SAP_GB_ID_PKF: '7000/707065/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992830,
    GB_WE_OBJ_ID_FKT: 1989479,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702080',
    GB_SAP_GB_ID_PKF: '7000/702080/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1879,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2879.48,
    GB_NUTZFL_NF_VON_NGF: 2187.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 691.97,
    GB_MIETFL_GESAMT: 2879.48,
    GB_MIETFL_1_BSB: 2611.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7020100,
    GB_WE_OBJ_ID_FKT: 7020098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702080,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/702080',
    GB_SAP_GB_ID_PKF: '7000/702080/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 364.27,
    GB_NUTZFL_NF_VON_NGF: 345.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.31,
    GB_MIETFL_GESAMT: 364.27,
    GB_MIETFL_1_BSB: 364.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7020101,
    GB_WE_OBJ_ID_FKT: 7020098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702080,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/702080',
    GB_SAP_GB_ID_PKF: '7000/702080/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 187.98,
    GB_NUTZFL_NF_VON_NGF: 187.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.04.19',
    GB_GUELTIG_BIS: '01.04.19',
    GB_GB_OBJ_ID_PKT: 7020102,
    GB_WE_OBJ_ID_FKT: 7020098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702080,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/702080',
    GB_SAP_GB_ID_PKF: '7000/702080/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1879,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3306.35,
    GB_NUTZFL_NF_VON_NGF: 2602.02,
    GB_TECHNIKFL_TF_VON_NGF: 59.99,
    GB_VERKEHRSFL_VF_VON_NGF: 644.34,
    GB_MIETFL_GESAMT: 3306.35,
    GB_MIETFL_1_BSB: 3239.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7020103,
    GB_WE_OBJ_ID_FKT: 7020098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989731,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989732,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989733,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702080,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/702080',
    GB_SAP_GB_ID_PKF: '7000/702080/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 231.08,
    GB_NUTZFL_NF_VON_NGF: 224.62,
    GB_TECHNIKFL_TF_VON_NGF: 3.6,
    GB_VERKEHRSFL_VF_VON_NGF: 2.86,
    GB_MIETFL_GESAMT: 231.08,
    GB_MIETFL_1_BSB: 231.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '26.07.19',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.04.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7039821,
    GB_WE_OBJ_ID_FKT: 7020098,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989736,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/7',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989737,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989738,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989739,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989740,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989741,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989742,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701038,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701038',
    GB_SAP_GB_ID_PKF: '7000/701038/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989743,
    GB_WE_OBJ_ID_FKT: 1989032,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701046',
    GB_SAP_GB_ID_PKF: '7000/701046/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 816.99,
    GB_NUTZFL_NF_VON_NGF: 623.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 193.48,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.12.16',
    GB_GB_OBJ_ID_PKT: 1989804,
    GB_WE_OBJ_ID_FKT: 1989040,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701046',
    GB_SAP_GB_ID_PKF: '7000/701046/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 778.03,
    GB_NUTZFL_NF_VON_NGF: 640.08,
    GB_TECHNIKFL_TF_VON_NGF: 14.04,
    GB_VERKEHRSFL_VF_VON_NGF: 123.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.12.16',
    GB_GB_OBJ_ID_PKT: 1989805,
    GB_WE_OBJ_ID_FKT: 1989040,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701046',
    GB_SAP_GB_ID_PKF: '7000/701046/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 891.56,
    GB_NUTZFL_NF_VON_NGF: 510.76,
    GB_TECHNIKFL_TF_VON_NGF: 237.5,
    GB_VERKEHRSFL_VF_VON_NGF: 143.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.12.16',
    GB_GB_OBJ_ID_PKT: 1989806,
    GB_WE_OBJ_ID_FKT: 1989040,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701046,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701046',
    GB_SAP_GB_ID_PKF: '7000/701046/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.12.16',
    GB_GB_OBJ_ID_PKT: 1989807,
    GB_WE_OBJ_ID_FKT: 1989040,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701046',
    GB_SAP_GB_ID_PKF: '7000/701046/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.531183',
    GB_LAENGENGRAD_OESTLICH: '10.129225',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.12.16',
    GB_GB_OBJ_ID_PKT: 1989808,
    GB_WE_OBJ_ID_FKT: 1989040,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701025',
    GB_SAP_GB_ID_PKF: '7000/701025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6494.17,
    GB_NUTZFL_NF_VON_NGF: 5341.18,
    GB_TECHNIKFL_TF_VON_NGF: 69.71,
    GB_VERKEHRSFL_VF_VON_NGF: 1083.28,
    GB_MIETFL_GESAMT: 6494.17,
    GB_MIETFL_1_BSB: 6474.2,
    GB_MIETFL_2_DRITTE: 19.97,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989646,
    GB_WE_OBJ_ID_FKT: 1989020,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 6766014,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701025',
    GB_SAP_GB_ID_PKF: '7000/701025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3000.0,
    GB_NUTZFL_NF_VON_NGF: 3000.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 3000.0,
    GB_MIETFL_1_BSB: 3000.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989647,
    GB_WE_OBJ_ID_FKT: 1989020,
    GB_RGN_OBJ_ID_FKT: 5446962,
    GB_OM_OBJ_ID_FKT: 6766014,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989888,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1581.01,
    GB_NUTZFL_NF_VON_NGF: 1130.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 450.39,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989889,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2598.11,
    GB_NUTZFL_NF_VON_NGF: 2019.04,
    GB_TECHNIKFL_TF_VON_NGF: 74.76,
    GB_VERKEHRSFL_VF_VON_NGF: 504.31,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989890,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2251.36,
    GB_NUTZFL_NF_VON_NGF: 1862.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 388.73,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.13',
    GB_GB_OBJ_ID_PKT: 1989891,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1466.88,
    GB_NUTZFL_NF_VON_NGF: 970.94,
    GB_TECHNIKFL_TF_VON_NGF: 247.15,
    GB_VERKEHRSFL_VF_VON_NGF: 248.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.13',
    GB_GB_OBJ_ID_PKT: 1989892,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1694.51,
    GB_NUTZFL_NF_VON_NGF: 1398.46,
    GB_TECHNIKFL_TF_VON_NGF: 12.59,
    GB_VERKEHRSFL_VF_VON_NGF: 283.46,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989893,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701056,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701056',
    GB_SAP_GB_ID_PKF: '7000/701056/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2058.59,
    GB_NUTZFL_NF_VON_NGF: 1658.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 400.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989894,
    GB_WE_OBJ_ID_FKT: 1989050,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701052',
    GB_SAP_GB_ID_PKF: '7000/701052/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10981.04,
    GB_NUTZFL_NF_VON_NGF: 7973.33,
    GB_TECHNIKFL_TF_VON_NGF: 41.59,
    GB_VERKEHRSFL_VF_VON_NGF: 2966.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989863,
    GB_WE_OBJ_ID_FKT: 1989046,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701052',
    GB_SAP_GB_ID_PKF: '7000/701052/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3044.02,
    GB_NUTZFL_NF_VON_NGF: 2733.23,
    GB_TECHNIKFL_TF_VON_NGF: 50.94,
    GB_VERKEHRSFL_VF_VON_NGF: 259.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989864,
    GB_WE_OBJ_ID_FKT: 1989046,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701052',
    GB_SAP_GB_ID_PKF: '7000/701052/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989865,
    GB_WE_OBJ_ID_FKT: 1989046,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701072,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701072',
    GB_SAP_GB_ID_PKF: '7000/701072/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5359.16,
    GB_NUTZFL_NF_VON_NGF: 3959.94,
    GB_TECHNIKFL_TF_VON_NGF: 362.44,
    GB_VERKEHRSFL_VF_VON_NGF: 1036.78,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989943,
    GB_WE_OBJ_ID_FKT: 1989064,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701072,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701072',
    GB_SAP_GB_ID_PKF: '7000/701072/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1812.34,
    GB_NUTZFL_NF_VON_NGF: 1384.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 428.01,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989944,
    GB_WE_OBJ_ID_FKT: 1989064,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701072,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701072',
    GB_SAP_GB_ID_PKF: '7000/701072/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 520.16,
    GB_NUTZFL_NF_VON_NGF: 342.26,
    GB_TECHNIKFL_TF_VON_NGF: 93.72,
    GB_VERKEHRSFL_VF_VON_NGF: 84.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989945,
    GB_WE_OBJ_ID_FKT: 1989064,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701072,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701072',
    GB_SAP_GB_ID_PKF: '7000/701072/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989946,
    GB_WE_OBJ_ID_FKT: 1989064,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989853,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1351.67,
    GB_NUTZFL_NF_VON_NGF: 1056.06,
    GB_TECHNIKFL_TF_VON_NGF: 14.92,
    GB_VERKEHRSFL_VF_VON_NGF: 280.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989854,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6331.45,
    GB_NUTZFL_NF_VON_NGF: 4905.38,
    GB_TECHNIKFL_TF_VON_NGF: 353.38,
    GB_VERKEHRSFL_VF_VON_NGF: 1072.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989855,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.16',
    GB_GB_OBJ_ID_PKT: 1989856,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: '53.550480',
    GB_LAENGENGRAD_OESTLICH: '10.039827',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989857,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.550551',
    GB_LAENGENGRAD_OESTLICH: '10.040458',
    GB_NETTOGRUNDFL_NGF: 575.83,
    GB_NUTZFL_NF_VON_NGF: 543.35,
    GB_TECHNIKFL_TF_VON_NGF: 19.31,
    GB_VERKEHRSFL_VF_VON_NGF: 13.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.17',
    GB_GB_OBJ_ID_PKT: 1989858,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 49.96,
    GB_NUTZFL_NF_VON_NGF: 49.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989859,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1933.25,
    GB_NUTZFL_NF_VON_NGF: 1356.28,
    GB_TECHNIKFL_TF_VON_NGF: 178.55,
    GB_VERKEHRSFL_VF_VON_NGF: 398.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989860,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3138.65,
    GB_NUTZFL_NF_VON_NGF: 2115.8,
    GB_TECHNIKFL_TF_VON_NGF: 87.48,
    GB_VERKEHRSFL_VF_VON_NGF: 935.37,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989861,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 356.44,
    GB_NUTZFL_NF_VON_NGF: 356.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989862,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701051,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701051',
    GB_SAP_GB_ID_PKF: '7000/701051/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Fachklassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 7027054,
    GB_WE_OBJ_ID_FKT: 1989045,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800288,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701064',
    GB_SAP_GB_ID_PKF: '7000/701064/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989908,
    GB_WE_OBJ_ID_FKT: 1989056,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701064,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701064',
    GB_SAP_GB_ID_PKF: '7000/701064/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989909,
    GB_WE_OBJ_ID_FKT: 1989056,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701064,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701064',
    GB_SAP_GB_ID_PKF: '7000/701064/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1296.99,
    GB_NUTZFL_NF_VON_NGF: 1168.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 128.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1989910,
    GB_WE_OBJ_ID_FKT: 1989056,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701054',
    GB_SAP_GB_ID_PKF: '7000/701054/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.553037',
    GB_LAENGENGRAD_OESTLICH: '10.035646',
    GB_NETTOGRUNDFL_NGF: 656.13,
    GB_NUTZFL_NF_VON_NGF: 611.41,
    GB_TECHNIKFL_TF_VON_NGF: 15.82,
    GB_VERKEHRSFL_VF_VON_NGF: 28.9,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.12.22',
    GB_GB_OBJ_ID_PKT: 1989869,
    GB_WE_OBJ_ID_FKT: 1989048,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701054,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701054',
    GB_SAP_GB_ID_PKF: '7000/701054/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14243.35,
    GB_NUTZFL_NF_VON_NGF: 11536.57,
    GB_TECHNIKFL_TF_VON_NGF: 768.13,
    GB_VERKEHRSFL_VF_VON_NGF: 1938.65,
    GB_MIETFL_GESAMT: 14243.35,
    GB_MIETFL_1_BSB: 13400.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989870,
    GB_WE_OBJ_ID_FKT: 1989048,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701054,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701054',
    GB_SAP_GB_ID_PKF: '7000/701054/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 116.87,
    GB_NUTZFL_NF_VON_NGF: 100.86,
    GB_TECHNIKFL_TF_VON_NGF: 3.84,
    GB_VERKEHRSFL_VF_VON_NGF: 12.17,
    GB_MIETFL_GESAMT: 116.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989871,
    GB_WE_OBJ_ID_FKT: 1989048,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701054,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701054',
    GB_SAP_GB_ID_PKF: '7000/701054/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3061.65,
    GB_NUTZFL_NF_VON_NGF: 2488.43,
    GB_TECHNIKFL_TF_VON_NGF: 61.64,
    GB_VERKEHRSFL_VF_VON_NGF: 511.58,
    GB_MIETFL_GESAMT: 3061.65,
    GB_MIETFL_1_BSB: 3015.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989872,
    GB_WE_OBJ_ID_FKT: 1989048,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1756.92,
    GB_NUTZFL_NF_VON_NGF: 721.91,
    GB_TECHNIKFL_TF_VON_NGF: 44.72,
    GB_VERKEHRSFL_VF_VON_NGF: 990.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.12.22',
    GB_GB_OBJ_ID_PKT: 1989866,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2766.76,
    GB_NUTZFL_NF_VON_NGF: 1964.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 802.59,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.12.22',
    GB_GB_OBJ_ID_PKT: 1989867,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4230.32,
    GB_NUTZFL_NF_VON_NGF: 2988.68,
    GB_TECHNIKFL_TF_VON_NGF: 145.61,
    GB_VERKEHRSFL_VF_VON_NGF: 1096.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '14.12.22',
    GB_GB_OBJ_ID_PKT: 1989868,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035902,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035903,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035904,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035905,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701053,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701053',
    GB_SAP_GB_ID_PKF: '7000/701053/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035906,
    GB_WE_OBJ_ID_FKT: 1989047,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1909,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2774.13,
    GB_NUTZFL_NF_VON_NGF: 1904.13,
    GB_TECHNIKFL_TF_VON_NGF: 54.82,
    GB_VERKEHRSFL_VF_VON_NGF: 815.18,
    GB_MIETFL_GESAMT: 2774.13,
    GB_MIETFL_1_BSB: 1952.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '26.05.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989873,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5585.86,
    GB_NUTZFL_NF_VON_NGF: 2236.67,
    GB_TECHNIKFL_TF_VON_NGF: 2841.18,
    GB_VERKEHRSFL_VF_VON_NGF: 508.01,
    GB_MIETFL_GESAMT: 5585.86,
    GB_MIETFL_1_BSB: 2741.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989874,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 114.74,
    GB_NUTZFL_NF_VON_NGF: 103.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.13,
    GB_MIETFL_GESAMT: 114.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989875,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.4,
    GB_NUTZFL_NF_VON_NGF: 12.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989876,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.4,
    GB_NUTZFL_NF_VON_NGF: 12.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989877,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.4,
    GB_NUTZFL_NF_VON_NGF: 12.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989878,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.4,
    GB_NUTZFL_NF_VON_NGF: 12.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989879,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.4,
    GB_NUTZFL_NF_VON_NGF: 12.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989880,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 49.6,
    GB_NUTZFL_NF_VON_NGF: 49.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 49.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989881,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 187.68,
    GB_NUTZFL_NF_VON_NGF: 183.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 4.3,
    GB_MIETFL_GESAMT: 187.68,
    GB_MIETFL_1_BSB: 187.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989882,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 387.97,
    GB_NUTZFL_NF_VON_NGF: 309.45,
    GB_TECHNIKFL_TF_VON_NGF: 50.49,
    GB_VERKEHRSFL_VF_VON_NGF: 28.03,
    GB_MIETFL_GESAMT: 387.97,
    GB_MIETFL_1_BSB: 387.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989883,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.553420',
    GB_LAENGENGRAD_OESTLICH: '10.048998',
    GB_NETTOGRUNDFL_NGF: 1561.17,
    GB_NUTZFL_NF_VON_NGF: 1338.52,
    GB_TECHNIKFL_TF_VON_NGF: 17.07,
    GB_VERKEHRSFL_VF_VON_NGF: 205.58,
    GB_MIETFL_GESAMT: 1561.17,
    GB_MIETFL_1_BSB: 1561.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989884,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1916.04,
    GB_NUTZFL_NF_VON_NGF: 1514.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 402.04,
    GB_MIETFL_GESAMT: 1916.04,
    GB_MIETFL_1_BSB: 1916.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.03.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989885,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1475.23,
    GB_NUTZFL_NF_VON_NGF: 1134.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 340.85,
    GB_MIETFL_GESAMT: 1475.23,
    GB_MIETFL_1_BSB: 1475.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.03.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989886,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/15',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2341.67,
    GB_NUTZFL_NF_VON_NGF: 1436.96,
    GB_TECHNIKFL_TF_VON_NGF: 180.23,
    GB_VERKEHRSFL_VF_VON_NGF: 724.48,
    GB_MIETFL_GESAMT: 2341.67,
    GB_MIETFL_1_BSB: 2161.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989887,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701055,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/701055',
    GB_SAP_GB_ID_PKF: '7000/701055/16',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 375.08,
    GB_NUTZFL_NF_VON_NGF: 351.46,
    GB_TECHNIKFL_TF_VON_NGF: 1.73,
    GB_VERKEHRSFL_VF_VON_NGF: 21.89,
    GB_MIETFL_GESAMT: 375.08,
    GB_MIETFL_1_BSB: 375.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399917,
    GB_WE_OBJ_ID_FKT: 1989049,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701060',
    GB_SAP_GB_ID_PKF: '7000/701060/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.549240',
    GB_LAENGENGRAD_OESTLICH: '10.034448',
    GB_NETTOGRUNDFL_NGF: 7118.94,
    GB_NUTZFL_NF_VON_NGF: 4777.61,
    GB_TECHNIKFL_TF_VON_NGF: 165.79,
    GB_VERKEHRSFL_VF_VON_NGF: 2175.54,
    GB_MIETFL_GESAMT: 7118.94,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 6960.63,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989904,
    GB_WE_OBJ_ID_FKT: 1989054,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701060',
    GB_SAP_GB_ID_PKF: '7000/701060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.548133',
    GB_LAENGENGRAD_OESTLICH: '10.035392',
    GB_NETTOGRUNDFL_NGF: 1400.82,
    GB_NUTZFL_NF_VON_NGF: 1376.31,
    GB_TECHNIKFL_TF_VON_NGF: 14.29,
    GB_VERKEHRSFL_VF_VON_NGF: 10.22,
    GB_MIETFL_GESAMT: 1400.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989905,
    GB_WE_OBJ_ID_FKT: 1989054,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701058',
    GB_SAP_GB_ID_PKF: '7000/701058/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1902,
    GB_BREITENGRAD_NOERDLICH: '53.552275',
    GB_LAENGENGRAD_OESTLICH: '9.976109',
    GB_NETTOGRUNDFL_NGF: 4951.89,
    GB_NUTZFL_NF_VON_NGF: 3586.42,
    GB_TECHNIKFL_TF_VON_NGF: 57.9,
    GB_VERKEHRSFL_VF_VON_NGF: 1307.57,
    GB_MIETFL_GESAMT: 4951.89,
    GB_MIETFL_1_BSB: 4895.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989901,
    GB_WE_OBJ_ID_FKT: 1989052,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701058',
    GB_SAP_GB_ID_PKF: '7000/701058/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.34,
    GB_NUTZFL_NF_VON_NGF: 149.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 23.24,
    GB_MIETFL_GESAMT: 172.34,
    GB_MIETFL_1_BSB: 172.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989902,
    GB_WE_OBJ_ID_FKT: 1989052,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701058,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701058',
    GB_SAP_GB_ID_PKF: '7000/701058/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1906,
    GB_BREITENGRAD_NOERDLICH: '53.550980',
    GB_LAENGENGRAD_OESTLICH: '9.974825',
    GB_NETTOGRUNDFL_NGF: 4193.36,
    GB_NUTZFL_NF_VON_NGF: 3165.73,
    GB_TECHNIKFL_TF_VON_NGF: 51.47,
    GB_VERKEHRSFL_VF_VON_NGF: 976.16,
    GB_MIETFL_GESAMT: 4193.36,
    GB_MIETFL_1_BSB: 4141.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5177248,
    GB_WE_OBJ_ID_FKT: 1989052,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701058,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701058',
    GB_SAP_GB_ID_PKF: '7000/701058/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.95,
    GB_NUTZFL_NF_VON_NGF: 14.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.95,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5304882,
    GB_WE_OBJ_ID_FKT: 1989052,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701058,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701058',
    GB_SAP_GB_ID_PKF: '7000/701058/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.97,
    GB_NUTZFL_NF_VON_NGF: 12.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.97,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5304883,
    GB_WE_OBJ_ID_FKT: 1989052,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701071',
    GB_SAP_GB_ID_PKF: '7000/701071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1915,
    GB_BREITENGRAD_NOERDLICH: '53.548689',
    GB_LAENGENGRAD_OESTLICH: '9.973760',
    GB_NETTOGRUNDFL_NGF: 4725.36,
    GB_NUTZFL_NF_VON_NGF: 3372.89,
    GB_TECHNIKFL_TF_VON_NGF: 52.59,
    GB_VERKEHRSFL_VF_VON_NGF: 1299.88,
    GB_MIETFL_GESAMT: 4725.36,
    GB_MIETFL_1_BSB: 4058.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989942,
    GB_WE_OBJ_ID_FKT: 1989063,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701063',
    GB_SAP_GB_ID_PKF: '7000/701063/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1873,
    GB_BREITENGRAD_NOERDLICH: '53.556393',
    GB_LAENGENGRAD_OESTLICH: '10.006045',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989906,
    GB_WE_OBJ_ID_FKT: 1989055,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701063,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701063',
    GB_SAP_GB_ID_PKF: '7000/701063/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1873,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989907,
    GB_WE_OBJ_ID_FKT: 1989055,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2071474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701059',
    GB_SAP_GB_ID_PKF: '7000/701059/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6501.84,
    GB_NUTZFL_NF_VON_NGF: 4337.38,
    GB_TECHNIKFL_TF_VON_NGF: 229.14,
    GB_VERKEHRSFL_VF_VON_NGF: 1935.32,
    GB_MIETFL_GESAMT: 6501.84,
    GB_MIETFL_1_BSB: 6173.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989903,
    GB_WE_OBJ_ID_FKT: 1989053,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 590.18,
    GB_NUTZFL_NF_VON_NGF: 446.44,
    GB_TECHNIKFL_TF_VON_NGF: 57.72,
    GB_VERKEHRSFL_VF_VON_NGF: 86.02,
    GB_MIETFL_GESAMT: 590.18,
    GB_MIETFL_1_BSB: 590.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.09.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989895,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 267.72,
    GB_NUTZFL_NF_VON_NGF: 267.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 267.72,
    GB_MIETFL_1_BSB: 267.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989896,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2449.1,
    GB_NUTZFL_NF_VON_NGF: 1488.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 960.49,
    GB_MIETFL_GESAMT: 2449.1,
    GB_MIETFL_1_BSB: 2449.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989897,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 815.63,
    GB_NUTZFL_NF_VON_NGF: 665.28,
    GB_TECHNIKFL_TF_VON_NGF: 11.55,
    GB_VERKEHRSFL_VF_VON_NGF: 138.8,
    GB_MIETFL_GESAMT: 815.63,
    GB_MIETFL_1_BSB: 815.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989898,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 669.71,
    GB_NUTZFL_NF_VON_NGF: 669.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 669.71,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 669.71,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989899,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701057,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701057',
    GB_SAP_GB_ID_PKF: '7000/701057/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 785.3,
    GB_NUTZFL_NF_VON_NGF: 661.87,
    GB_TECHNIKFL_TF_VON_NGF: 3.89,
    GB_VERKEHRSFL_VF_VON_NGF: 119.54,
    GB_MIETFL_GESAMT: 785.3,
    GB_MIETFL_1_BSB: 785.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989900,
    GB_WE_OBJ_ID_FKT: 1989051,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 5194947,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13473.67,
    GB_NUTZFL_NF_VON_NGF: 9793.42,
    GB_TECHNIKFL_TF_VON_NGF: 609.12,
    GB_VERKEHRSFL_VF_VON_NGF: 3071.13,
    GB_MIETFL_GESAMT: 13473.67,
    GB_MIETFL_1_BSB: 12864.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992479,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 804.2,
    GB_NUTZFL_NF_VON_NGF: 717.18,
    GB_TECHNIKFL_TF_VON_NGF: 15.46,
    GB_VERKEHRSFL_VF_VON_NGF: 71.56,
    GB_MIETFL_GESAMT: 804.2,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992480,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992481,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992482,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992483,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2578.38,
    GB_NUTZFL_NF_VON_NGF: 1989.81,
    GB_TECHNIKFL_TF_VON_NGF: 86.96,
    GB_VERKEHRSFL_VF_VON_NGF: 501.61,
    GB_MIETFL_GESAMT: 2578.38,
    GB_MIETFL_1_BSB: 2562.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '26.10.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399924,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707022,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/707022',
    GB_SAP_GB_ID_PKF: '7000/707022/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.5,
    GB_NUTZFL_NF_VON_NGF: 143.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 143.5,
    GB_MIETFL_1_BSB: 143.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '26.10.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6982815,
    GB_WE_OBJ_ID_FKT: 1989440,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707021',
    GB_SAP_GB_ID_PKF: '7000/707021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6065.62,
    GB_NUTZFL_NF_VON_NGF: 4181.76,
    GB_TECHNIKFL_TF_VON_NGF: 324.31,
    GB_VERKEHRSFL_VF_VON_NGF: 1559.55,
    GB_MIETFL_GESAMT: 6065.62,
    GB_MIETFL_1_BSB: 5829.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992475,
    GB_WE_OBJ_ID_FKT: 1989439,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/707021',
    GB_SAP_GB_ID_PKF: '7000/707021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5991.51,
    GB_NUTZFL_NF_VON_NGF: 4818.27,
    GB_TECHNIKFL_TF_VON_NGF: 59.81,
    GB_VERKEHRSFL_VF_VON_NGF: 1113.43,
    GB_MIETFL_GESAMT: 5991.51,
    GB_MIETFL_1_BSB: 5987.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992476,
    GB_WE_OBJ_ID_FKT: 1989439,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/707021',
    GB_SAP_GB_ID_PKF: '7000/707021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.499933',
    GB_LAENGENGRAD_OESTLICH: '10.001632',
    GB_NETTOGRUNDFL_NGF: 1601.96,
    GB_NUTZFL_NF_VON_NGF: 1545.16,
    GB_TECHNIKFL_TF_VON_NGF: 30.81,
    GB_VERKEHRSFL_VF_VON_NGF: 25.99,
    GB_MIETFL_GESAMT: 1601.96,
    GB_MIETFL_1_BSB: 1601.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992477,
    GB_WE_OBJ_ID_FKT: 1989439,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/707021',
    GB_SAP_GB_ID_PKF: '7000/707021/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.499479',
    GB_LAENGENGRAD_OESTLICH: '10.001323',
    GB_NETTOGRUNDFL_NGF: 1532.77,
    GB_NUTZFL_NF_VON_NGF: 1491.44,
    GB_TECHNIKFL_TF_VON_NGF: 17.12,
    GB_VERKEHRSFL_VF_VON_NGF: 24.21,
    GB_MIETFL_GESAMT: 1532.77,
    GB_MIETFL_1_BSB: 1532.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992478,
    GB_WE_OBJ_ID_FKT: 1989439,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707023',
    GB_SAP_GB_ID_PKF: '7000/707023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1052.22,
    GB_NUTZFL_NF_VON_NGF: 843.28,
    GB_TECHNIKFL_TF_VON_NGF: 31.18,
    GB_VERKEHRSFL_VF_VON_NGF: 177.76,
    GB_MIETFL_GESAMT: 1052.22,
    GB_MIETFL_1_BSB: 925.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.07.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992484,
    GB_WE_OBJ_ID_FKT: 1989441,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/707023',
    GB_SAP_GB_ID_PKF: '7000/707023/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 463.23,
    GB_NUTZFL_NF_VON_NGF: 315.41,
    GB_TECHNIKFL_TF_VON_NGF: 5.85,
    GB_VERKEHRSFL_VF_VON_NGF: 141.97,
    GB_MIETFL_GESAMT: 463.23,
    GB_MIETFL_1_BSB: 457.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.07.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992485,
    GB_WE_OBJ_ID_FKT: 1989441,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/707023',
    GB_SAP_GB_ID_PKF: '7000/707023/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9993.79,
    GB_NUTZFL_NF_VON_NGF: 7514.39,
    GB_TECHNIKFL_TF_VON_NGF: 173.59,
    GB_VERKEHRSFL_VF_VON_NGF: 2305.81,
    GB_MIETFL_GESAMT: 9993.79,
    GB_MIETFL_1_BSB: 9724.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.07.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992486,
    GB_WE_OBJ_ID_FKT: 1989441,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 1800488,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701068',
    GB_SAP_GB_ID_PKF: '7000/701068/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1879,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989930,
    GB_WE_OBJ_ID_FKT: 1989060,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701068,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701068',
    GB_SAP_GB_ID_PKF: '7000/701068/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: '53.555307',
    GB_LAENGENGRAD_OESTLICH: '9.963490',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989931,
    GB_WE_OBJ_ID_FKT: 1989060,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701068,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701068',
    GB_SAP_GB_ID_PKF: '7000/701068/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.03.19',
    GB_GB_OBJ_ID_PKT: 1989932,
    GB_WE_OBJ_ID_FKT: 1989060,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701068,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701068',
    GB_SAP_GB_ID_PKF: '7000/701068/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1879,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989933,
    GB_WE_OBJ_ID_FKT: 1989060,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 693.1,
    GB_NUTZFL_NF_VON_NGF: 644.29,
    GB_TECHNIKFL_TF_VON_NGF: 14.2,
    GB_VERKEHRSFL_VF_VON_NGF: 34.61,
    GB_MIETFL_GESAMT: 693.1,
    GB_MIETFL_1_BSB: 587.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989690,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.532711',
    GB_LAENGENGRAD_OESTLICH: '10.107673',
    GB_NETTOGRUNDFL_NGF: 603.72,
    GB_NUTZFL_NF_VON_NGF: 594.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.52,
    GB_MIETFL_GESAMT: 603.72,
    GB_MIETFL_1_BSB: 603.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989691,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 701.92,
    GB_NUTZFL_NF_VON_NGF: 597.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.68,
    GB_MIETFL_GESAMT: 701.92,
    GB_MIETFL_1_BSB: 701.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989692,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.21,
    GB_NUTZFL_NF_VON_NGF: 156.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.32,
    GB_MIETFL_GESAMT: 177.21,
    GB_MIETFL_1_BSB: 177.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989693,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.532109',
    GB_LAENGENGRAD_OESTLICH: '10.108398',
    GB_NETTOGRUNDFL_NGF: 296.3,
    GB_NUTZFL_NF_VON_NGF: 281.5,
    GB_TECHNIKFL_TF_VON_NGF: 6.84,
    GB_VERKEHRSFL_VF_VON_NGF: 7.96,
    GB_MIETFL_GESAMT: 296.3,
    GB_MIETFL_1_BSB: 296.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989694,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 284.02,
    GB_NUTZFL_NF_VON_NGF: 284.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 284.02,
    GB_MIETFL_1_BSB: 284.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989695,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.39,
    GB_NUTZFL_NF_VON_NGF: 141.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 21.14,
    GB_MIETFL_GESAMT: 162.39,
    GB_MIETFL_1_BSB: 162.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989696,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1092.34,
    GB_NUTZFL_NF_VON_NGF: 916.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 175.78,
    GB_MIETFL_GESAMT: 1092.34,
    GB_MIETFL_1_BSB: 1092.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989697,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 118.54,
    GB_NUTZFL_NF_VON_NGF: 101.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.27,
    GB_MIETFL_GESAMT: 118.54,
    GB_MIETFL_1_BSB: 118.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989698,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1275.71,
    GB_NUTZFL_NF_VON_NGF: 820.31,
    GB_TECHNIKFL_TF_VON_NGF: 165.67,
    GB_VERKEHRSFL_VF_VON_NGF: 289.73,
    GB_MIETFL_GESAMT: 1275.71,
    GB_MIETFL_1_BSB: 920.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989699,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 30.4,
    GB_NUTZFL_NF_VON_NGF: 30.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 30.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989700,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701032,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701032',
    GB_SAP_GB_ID_PKF: '7000/701032/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 432.08,
    GB_NUTZFL_NF_VON_NGF: 366.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.22,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 5306668,
    GB_WE_OBJ_ID_FKT: 1989026,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 745.91,
    GB_NUTZFL_NF_VON_NGF: 541.59,
    GB_TECHNIKFL_TF_VON_NGF: 77.38,
    GB_VERKEHRSFL_VF_VON_NGF: 126.94,
    GB_MIETFL_GESAMT: 745.91,
    GB_MIETFL_1_BSB: 668.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989716,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.64,
    GB_NUTZFL_NF_VON_NGF: 118.53,
    GB_TECHNIKFL_TF_VON_NGF: 5.17,
    GB_VERKEHRSFL_VF_VON_NGF: 20.94,
    GB_MIETFL_GESAMT: 144.64,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989717,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 293.44,
    GB_NUTZFL_NF_VON_NGF: 293.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.11.21',
    GB_GB_OBJ_ID_PKT: 1989718,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.48,
    GB_NUTZFL_NF_VON_NGF: 12.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.48,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989719,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.11.22',
    GB_GB_OBJ_ID_PKT: 1989720,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.51,
    GB_NUTZFL_NF_VON_NGF: 383.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.11.22',
    GB_GB_OBJ_ID_PKT: 1989721,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 905.09,
    GB_NUTZFL_NF_VON_NGF: 803.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.98,
    GB_MIETFL_GESAMT: 905.09,
    GB_MIETFL_1_BSB: 905.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989722,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989724,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.544695',
    GB_LAENGENGRAD_OESTLICH: '10.119154',
    GB_NETTOGRUNDFL_NGF: 464.0,
    GB_NUTZFL_NF_VON_NGF: 464.0,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 0.0,
    GB_MIETFL_GESAMT: 464.0,
    GB_MIETFL_1_BSB: 464.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989723,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 219.02,
    GB_NUTZFL_NF_VON_NGF: 191.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.14,
    GB_MIETFL_GESAMT: 219.02,
    GB_MIETFL_1_BSB: 219.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989725,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 219.18,
    GB_NUTZFL_NF_VON_NGF: 191.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.39,
    GB_MIETFL_GESAMT: 219.18,
    GB_MIETFL_1_BSB: 219.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989726,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.52,
    GB_NUTZFL_NF_VON_NGF: 383.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.26,
    GB_MIETFL_GESAMT: 437.52,
    GB_MIETFL_1_BSB: 437.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989727,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/13',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 271.14,
    GB_NUTZFL_NF_VON_NGF: 207.96,
    GB_TECHNIKFL_TF_VON_NGF: 18.14,
    GB_VERKEHRSFL_VF_VON_NGF: 45.04,
    GB_MIETFL_GESAMT: 271.14,
    GB_MIETFL_1_BSB: 271.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423848,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1631.74,
    GB_NUTZFL_NF_VON_NGF: 1244.49,
    GB_TECHNIKFL_TF_VON_NGF: 1.59,
    GB_VERKEHRSFL_VF_VON_NGF: 385.66,
    GB_MIETFL_GESAMT: 1631.74,
    GB_MIETFL_1_BSB: 1623.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.05.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423849,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/15',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.49,
    GB_NUTZFL_NF_VON_NGF: 487.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 487.49,
    GB_MIETFL_1_BSB: 487.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5498530,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701036,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/701036',
    GB_SAP_GB_ID_PKF: '7000/701036/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7237464,
    GB_WE_OBJ_ID_FKT: 1989030,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1715.69,
    GB_NUTZFL_NF_VON_NGF: 1493.73,
    GB_TECHNIKFL_TF_VON_NGF: 17.96,
    GB_VERKEHRSFL_VF_VON_NGF: 204.0,
    GB_MIETFL_GESAMT: 1715.69,
    GB_MIETFL_1_BSB: 1487.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989518,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 30.25,
    GB_NUTZFL_NF_VON_NGF: 30.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 30.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989519,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 680.54,
    GB_NUTZFL_NF_VON_NGF: 630.0,
    GB_TECHNIKFL_TF_VON_NGF: 31.27,
    GB_VERKEHRSFL_VF_VON_NGF: 19.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989520,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 573.54,
    GB_NUTZFL_NF_VON_NGF: 480.75,
    GB_TECHNIKFL_TF_VON_NGF: 17.86,
    GB_VERKEHRSFL_VF_VON_NGF: 74.93,
    GB_MIETFL_GESAMT: 573.54,
    GB_MIETFL_1_BSB: 462.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.03.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989521,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 465.14,
    GB_NUTZFL_NF_VON_NGF: 421.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.72,
    GB_MIETFL_GESAMT: 465.14,
    GB_MIETFL_1_BSB: 465.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989522,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 465.75,
    GB_NUTZFL_NF_VON_NGF: 422.19,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.56,
    GB_MIETFL_GESAMT: 465.75,
    GB_MIETFL_1_BSB: 465.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989523,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 223.9,
    GB_NUTZFL_NF_VON_NGF: 213.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.44,
    GB_MIETFL_GESAMT: 223.9,
    GB_MIETFL_1_BSB: 223.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989524,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 224.03,
    GB_NUTZFL_NF_VON_NGF: 213.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.34,
    GB_MIETFL_GESAMT: 224.03,
    GB_MIETFL_1_BSB: 224.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989525,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 223.82,
    GB_NUTZFL_NF_VON_NGF: 213.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.39,
    GB_MIETFL_GESAMT: 223.82,
    GB_MIETFL_1_BSB: 223.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989526,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 223.99,
    GB_NUTZFL_NF_VON_NGF: 213.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.34,
    GB_MIETFL_GESAMT: 223.99,
    GB_MIETFL_1_BSB: 223.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989527,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.528054',
    GB_LAENGENGRAD_OESTLICH: '10.140984',
    GB_NETTOGRUNDFL_NGF: 903.21,
    GB_NUTZFL_NF_VON_NGF: 791.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 111.92,
    GB_MIETFL_GESAMT: 903.21,
    GB_MIETFL_1_BSB: 903.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989706,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.528046',
    GB_LAENGENGRAD_OESTLICH: '10.140610',
    GB_NETTOGRUNDFL_NGF: 903.19,
    GB_NUTZFL_NF_VON_NGF: 791.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 111.45,
    GB_MIETFL_GESAMT: 903.19,
    GB_MIETFL_1_BSB: 903.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989707,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: '53.527723',
    GB_LAENGENGRAD_OESTLICH: '10.140118',
    GB_NETTOGRUNDFL_NGF: 172.87,
    GB_NUTZFL_NF_VON_NGF: 149.91,
    GB_TECHNIKFL_TF_VON_NGF: 5.39,
    GB_VERKEHRSFL_VF_VON_NGF: 17.57,
    GB_MIETFL_GESAMT: 172.87,
    GB_MIETFL_1_BSB: 172.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989708,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701007,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701007',
    GB_SAP_GB_ID_PKF: '7000/701007/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.545252',
    GB_LAENGENGRAD_OESTLICH: '10.135627',
    GB_NETTOGRUNDFL_NGF: 663.54,
    GB_NUTZFL_NF_VON_NGF: 621.49,
    GB_TECHNIKFL_TF_VON_NGF: 12.82,
    GB_VERKEHRSFL_VF_VON_NGF: 29.23,
    GB_MIETFL_GESAMT: 663.54,
    GB_MIETFL_1_BSB: 663.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5320791,
    GB_WE_OBJ_ID_FKT: 1989002,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2786.47,
    GB_NUTZFL_NF_VON_NGF: 2134.9,
    GB_TECHNIKFL_TF_VON_NGF: 74.86,
    GB_VERKEHRSFL_VF_VON_NGF: 576.71,
    GB_MIETFL_GESAMT: 2786.47,
    GB_MIETFL_1_BSB: 2628.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989538,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1360.48,
    GB_NUTZFL_NF_VON_NGF: 1091.56,
    GB_TECHNIKFL_TF_VON_NGF: 59.18,
    GB_VERKEHRSFL_VF_VON_NGF: 209.74,
    GB_MIETFL_GESAMT: 1360.48,
    GB_MIETFL_1_BSB: 1266.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989539,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1282.82,
    GB_NUTZFL_NF_VON_NGF: 1027.17,
    GB_TECHNIKFL_TF_VON_NGF: 62.71,
    GB_VERKEHRSFL_VF_VON_NGF: 192.94,
    GB_MIETFL_GESAMT: 1282.82,
    GB_MIETFL_1_BSB: 1282.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989540,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 99.45,
    GB_NUTZFL_NF_VON_NGF: 86.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.63,
    GB_MIETFL_GESAMT: 99.45,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989541,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.560745',
    GB_LAENGENGRAD_OESTLICH: '10.120951',
    GB_NETTOGRUNDFL_NGF: 659.77,
    GB_NUTZFL_NF_VON_NGF: 614.67,
    GB_TECHNIKFL_TF_VON_NGF: 16.89,
    GB_VERKEHRSFL_VF_VON_NGF: 28.21,
    GB_MIETFL_GESAMT: 659.77,
    GB_MIETFL_1_BSB: 659.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989542,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.77,
    GB_NUTZFL_NF_VON_NGF: 13.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.77,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989543,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701009',
    GB_SAP_GB_ID_PKF: '7000/701009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.87,
    GB_NUTZFL_NF_VON_NGF: 13.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989544,
    GB_WE_OBJ_ID_FKT: 1989004,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1128.6,
    GB_NUTZFL_NF_VON_NGF: 702.27,
    GB_TECHNIKFL_TF_VON_NGF: 60.62,
    GB_VERKEHRSFL_VF_VON_NGF: 365.71,
    GB_MIETFL_GESAMT: 1128.6,
    GB_MIETFL_1_BSB: 1019.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.08',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989573,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 255.45,
    GB_NUTZFL_NF_VON_NGF: 255.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 255.45,
    GB_MIETFL_1_BSB: 255.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989574,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.47,
    GB_NUTZFL_NF_VON_NGF: 160.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 64.63,
    GB_MIETFL_GESAMT: 225.47,
    GB_MIETFL_1_BSB: 225.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989575,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 251.98,
    GB_NUTZFL_NF_VON_NGF: 251.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 251.98,
    GB_MIETFL_1_BSB: 251.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.08',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989576,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 438.98,
    GB_NUTZFL_NF_VON_NGF: 346.3,
    GB_TECHNIKFL_TF_VON_NGF: 4.0,
    GB_VERKEHRSFL_VF_VON_NGF: 88.68,
    GB_MIETFL_GESAMT: 438.98,
    GB_MIETFL_1_BSB: 438.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989577,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 711.87,
    GB_NUTZFL_NF_VON_NGF: 506.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 205.05,
    GB_MIETFL_GESAMT: 711.87,
    GB_MIETFL_1_BSB: 711.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989578,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/7',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.541653',
    GB_LAENGENGRAD_OESTLICH: '10.102960',
    GB_NETTOGRUNDFL_NGF: 327.01,
    GB_NUTZFL_NF_VON_NGF: 313.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.4,
    GB_MIETFL_GESAMT: 327.01,
    GB_MIETFL_1_BSB: 327.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989579,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701015,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701015',
    GB_SAP_GB_ID_PKF: '7000/701015/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 97.42,
    GB_NUTZFL_NF_VON_NGF: 84.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.27,
    GB_MIETFL_GESAMT: 97.42,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989580,
    GB_WE_OBJ_ID_FKT: 1989010,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.529393',
    GB_LAENGENGRAD_OESTLICH: '10.140409',
    GB_NETTOGRUNDFL_NGF: 1482.8,
    GB_NUTZFL_NF_VON_NGF: 1139.67,
    GB_TECHNIKFL_TF_VON_NGF: 55.46,
    GB_VERKEHRSFL_VF_VON_NGF: 287.67,
    GB_MIETFL_GESAMT: 1482.8,
    GB_MIETFL_1_BSB: 1172.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989701,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.529010057',
    GB_LAENGENGRAD_OESTLICH: '10.139954550',
    GB_NETTOGRUNDFL_NGF: 2722.53,
    GB_NUTZFL_NF_VON_NGF: 2284.19,
    GB_TECHNIKFL_TF_VON_NGF: 73.17,
    GB_VERKEHRSFL_VF_VON_NGF: 365.17,
    GB_MIETFL_GESAMT: 2722.53,
    GB_MIETFL_1_BSB: 2533.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989702,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.528745',
    GB_LAENGENGRAD_OESTLICH: '10.140638',
    GB_NETTOGRUNDFL_NGF: 661.05,
    GB_NUTZFL_NF_VON_NGF: 615.41,
    GB_TECHNIKFL_TF_VON_NGF: 16.74,
    GB_VERKEHRSFL_VF_VON_NGF: 28.9,
    GB_MIETFL_GESAMT: 661.05,
    GB_MIETFL_1_BSB: 661.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989703,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.528406',
    GB_LAENGENGRAD_OESTLICH: '10.140649',
    GB_NETTOGRUNDFL_NGF: 454.05,
    GB_NUTZFL_NF_VON_NGF: 398.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.85,
    GB_MIETFL_GESAMT: 454.05,
    GB_MIETFL_1_BSB: 454.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989704,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.528431',
    GB_LAENGENGRAD_OESTLICH: '10.141804',
    GB_NETTOGRUNDFL_NGF: 531.46,
    GB_NUTZFL_NF_VON_NGF: 523.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.78,
    GB_MIETFL_GESAMT: 531.46,
    GB_MIETFL_1_BSB: 531.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989705,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.11.18',
    GB_GB_OBJ_ID_PKT: 1989709,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: '53.527993',
    GB_LAENGENGRAD_OESTLICH: '10.139792',
    GB_NETTOGRUNDFL_NGF: 173.02,
    GB_NUTZFL_NF_VON_NGF: 150.01,
    GB_TECHNIKFL_TF_VON_NGF: 5.45,
    GB_VERKEHRSFL_VF_VON_NGF: 17.56,
    GB_MIETFL_GESAMT: 173.02,
    GB_MIETFL_1_BSB: 173.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989710,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701033,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701033',
    GB_SAP_GB_ID_PKF: '7000/701033/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.529430',
    GB_LAENGENGRAD_OESTLICH: '10.140991',
    GB_NETTOGRUNDFL_NGF: 488.53,
    GB_NUTZFL_NF_VON_NGF: 437.01,
    GB_TECHNIKFL_TF_VON_NGF: 17.3,
    GB_VERKEHRSFL_VF_VON_NGF: 34.22,
    GB_MIETFL_GESAMT: 488.53,
    GB_MIETFL_1_BSB: 488.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5331391,
    GB_WE_OBJ_ID_FKT: 1989027,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701034',
    GB_SAP_GB_ID_PKF: '7000/701034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.532501',
    GB_LAENGENGRAD_OESTLICH: '10.148044',
    GB_NETTOGRUNDFL_NGF: 37080.68,
    GB_NUTZFL_NF_VON_NGF: 24281.42,
    GB_TECHNIKFL_TF_VON_NGF: 3778.79,
    GB_VERKEHRSFL_VF_VON_NGF: 9020.47,
    GB_MIETFL_GESAMT: 37080.68,
    GB_MIETFL_1_BSB: 31155.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989711,
    GB_WE_OBJ_ID_FKT: 1989028,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701034',
    GB_SAP_GB_ID_PKF: '7000/701034/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989712,
    GB_WE_OBJ_ID_FKT: 1989028,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701034',
    GB_SAP_GB_ID_PKF: '7000/701034/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 872.31,
    GB_NUTZFL_NF_VON_NGF: 694.16,
    GB_TECHNIKFL_TF_VON_NGF: 2.92,
    GB_VERKEHRSFL_VF_VON_NGF: 175.23,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989713,
    GB_WE_OBJ_ID_FKT: 1989028,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1013.42,
    GB_NUTZFL_NF_VON_NGF: 756.99,
    GB_TECHNIKFL_TF_VON_NGF: 73.88,
    GB_VERKEHRSFL_VF_VON_NGF: 182.55,
    GB_MIETFL_GESAMT: 1013.42,
    GB_MIETFL_1_BSB: 727.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989677,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 361.01,
    GB_NUTZFL_NF_VON_NGF: 334.98,
    GB_TECHNIKFL_TF_VON_NGF: 23.7,
    GB_VERKEHRSFL_VF_VON_NGF: 2.33,
    GB_MIETFL_GESAMT: 361.01,
    GB_MIETFL_1_BSB: 361.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989678,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.34,
    GB_NUTZFL_NF_VON_NGF: 131.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.9,
    GB_MIETFL_GESAMT: 144.34,
    GB_MIETFL_1_BSB: 144.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989679,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 37.94,
    GB_NUTZFL_NF_VON_NGF: 37.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 37.94,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989680,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 409.89,
    GB_NUTZFL_NF_VON_NGF: 344.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.37,
    GB_MIETFL_GESAMT: 409.89,
    GB_MIETFL_1_BSB: 409.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989681,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 409.89,
    GB_NUTZFL_NF_VON_NGF: 344.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.37,
    GB_MIETFL_GESAMT: 409.89,
    GB_MIETFL_1_BSB: 409.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989682,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 409.89,
    GB_NUTZFL_NF_VON_NGF: 344.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.37,
    GB_MIETFL_GESAMT: 409.89,
    GB_MIETFL_1_BSB: 409.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989683,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 711.05,
    GB_NUTZFL_NF_VON_NGF: 613.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 97.51,
    GB_MIETFL_GESAMT: 711.05,
    GB_MIETFL_1_BSB: 602.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989684,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.536744',
    GB_LAENGENGRAD_OESTLICH: '10.120425',
    GB_NETTOGRUNDFL_NGF: 666.5,
    GB_NUTZFL_NF_VON_NGF: 618.36,
    GB_TECHNIKFL_TF_VON_NGF: 18.98,
    GB_VERKEHRSFL_VF_VON_NGF: 29.16,
    GB_MIETFL_GESAMT: 666.5,
    GB_MIETFL_1_BSB: 666.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989685,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1185.98,
    GB_NUTZFL_NF_VON_NGF: 903.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 282.35,
    GB_MIETFL_GESAMT: 1185.98,
    GB_MIETFL_1_BSB: 1185.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989686,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.96,
    GB_NUTZFL_NF_VON_NGF: 183.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.11,
    GB_MIETFL_GESAMT: 214.96,
    GB_MIETFL_1_BSB: 214.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989687,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/12',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.537318',
    GB_LAENGENGRAD_OESTLICH: '10.121278',
    GB_NETTOGRUNDFL_NGF: 338.42,
    GB_NUTZFL_NF_VON_NGF: 318.29,
    GB_TECHNIKFL_TF_VON_NGF: 14.87,
    GB_VERKEHRSFL_VF_VON_NGF: 5.26,
    GB_MIETFL_GESAMT: 338.42,
    GB_MIETFL_1_BSB: 338.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989688,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 289.91,
    GB_NUTZFL_NF_VON_NGF: 273.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.33,
    GB_MIETFL_GESAMT: 289.91,
    GB_MIETFL_1_BSB: 289.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989689,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7226788,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/15',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7226789,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701031,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/701031',
    GB_SAP_GB_ID_PKF: '7000/701031/16',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7508241,
    GB_WE_OBJ_ID_FKT: 1989025,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 188.48,
    GB_NUTZFL_NF_VON_NGF: 168.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.12.21',
    GB_GB_OBJ_ID_PKT: 1989657,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 412.65,
    GB_NUTZFL_NF_VON_NGF: 412.65,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 0.0,
    GB_MIETFL_GESAMT: 412.65,
    GB_MIETFL_1_BSB: 412.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989658,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1531.17,
    GB_NUTZFL_NF_VON_NGF: 1112.52,
    GB_TECHNIKFL_TF_VON_NGF: 66.85,
    GB_VERKEHRSFL_VF_VON_NGF: 351.8,
    GB_MIETFL_GESAMT: 1531.17,
    GB_MIETFL_1_BSB: 1428.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.10.18',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989659,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989660,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1989661,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.541330',
    GB_LAENGENGRAD_OESTLICH: '10.128934',
    GB_NETTOGRUNDFL_NGF: 628.96,
    GB_NUTZFL_NF_VON_NGF: 610.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.94,
    GB_MIETFL_GESAMT: 628.96,
    GB_MIETFL_1_BSB: 628.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989662,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/7',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 534.38,
    GB_NUTZFL_NF_VON_NGF: 380.46,
    GB_TECHNIKFL_TF_VON_NGF: 93.14,
    GB_VERKEHRSFL_VF_VON_NGF: 60.78,
    GB_MIETFL_GESAMT: 534.38,
    GB_MIETFL_1_BSB: 407.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989663,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 163.56,
    GB_NUTZFL_NF_VON_NGF: 105.72,
    GB_TECHNIKFL_TF_VON_NGF: 7.85,
    GB_VERKEHRSFL_VF_VON_NGF: 49.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '18.12.18',
    GB_GB_OBJ_ID_PKT: 1989664,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.15',
    GB_GB_OBJ_ID_PKT: 1989665,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3424.38,
    GB_NUTZFL_NF_VON_NGF: 2548.14,
    GB_TECHNIKFL_TF_VON_NGF: 77.53,
    GB_VERKEHRSFL_VF_VON_NGF: 798.71,
    GB_MIETFL_GESAMT: 3424.38,
    GB_MIETFL_1_BSB: 3306.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.08.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5334625,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1757.8,
    GB_NUTZFL_NF_VON_NGF: 1328.39,
    GB_TECHNIKFL_TF_VON_NGF: 57.25,
    GB_VERKEHRSFL_VF_VON_NGF: 372.16,
    GB_MIETFL_GESAMT: 1757.8,
    GB_MIETFL_1_BSB: 1754.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406908,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5906772,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.08.21',
    GB_GB_OBJ_ID_PKT: 4352711,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701028,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701028',
    GB_SAP_GB_ID_PKF: '7000/701028/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 237.66,
    GB_NUTZFL_NF_VON_NGF: 218.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 5307031,
    GB_WE_OBJ_ID_FKT: 1989023,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8222.48,
    GB_NUTZFL_NF_VON_NGF: 6106.09,
    GB_TECHNIKFL_TF_VON_NGF: 185.21,
    GB_VERKEHRSFL_VF_VON_NGF: 1931.18,
    GB_MIETFL_GESAMT: 8222.48,
    GB_MIETFL_1_BSB: 7327.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989652,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1413.01,
    GB_NUTZFL_NF_VON_NGF: 1064.26,
    GB_TECHNIKFL_TF_VON_NGF: 72.14,
    GB_VERKEHRSFL_VF_VON_NGF: 276.61,
    GB_MIETFL_GESAMT: 1413.01,
    GB_MIETFL_1_BSB: 1340.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989653,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.67,
    GB_NUTZFL_NF_VON_NGF: 114.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.6,
    GB_MIETFL_GESAMT: 139.67,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989654,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.13,
    GB_NUTZFL_NF_VON_NGF: 26.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989655,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5277.74,
    GB_NUTZFL_NF_VON_NGF: 3770.93,
    GB_TECHNIKFL_TF_VON_NGF: 47.57,
    GB_VERKEHRSFL_VF_VON_NGF: 1459.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.12.18',
    GB_GB_OBJ_ID_PKT: 1989755,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 376.54,
    GB_NUTZFL_NF_VON_NGF: 351.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989757,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.541457',
    GB_LAENGENGRAD_OESTLICH: '10.121873',
    GB_NETTOGRUNDFL_NGF: 1712.52,
    GB_NUTZFL_NF_VON_NGF: 1645.81,
    GB_TECHNIKFL_TF_VON_NGF: 11.16,
    GB_VERKEHRSFL_VF_VON_NGF: 55.55,
    GB_MIETFL_GESAMT: 1712.52,
    GB_MIETFL_1_BSB: 1712.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989656,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.17',
    GB_GB_OBJ_ID_PKT: 4352709,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701027,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701027',
    GB_SAP_GB_ID_PKF: '7000/701027/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.56,
    GB_NUTZFL_NF_VON_NGF: 151.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.18',
    GB_GB_OBJ_ID_PKT: 4352710,
    GB_WE_OBJ_ID_FKT: 1989022,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.527530',
    GB_LAENGENGRAD_OESTLICH: '10.155263',
    GB_NETTOGRUNDFL_NGF: 1200.21,
    GB_NUTZFL_NF_VON_NGF: 1131.55,
    GB_TECHNIKFL_TF_VON_NGF: 7.64,
    GB_VERKEHRSFL_VF_VON_NGF: 61.02,
    GB_MIETFL_GESAMT: 1200.21,
    GB_MIETFL_1_BSB: 1200.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.10.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989756,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: '53.527935',
    GB_LAENGENGRAD_OESTLICH: '10.154822',
    GB_NETTOGRUNDFL_NGF: 139.22,
    GB_NUTZFL_NF_VON_NGF: 118.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.74,
    GB_MIETFL_GESAMT: 139.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989758,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989759,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989760,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 333.63,
    GB_NUTZFL_NF_VON_NGF: 264.81,
    GB_TECHNIKFL_TF_VON_NGF: 5.35,
    GB_VERKEHRSFL_VF_VON_NGF: 63.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989761,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.01,
    GB_NUTZFL_NF_VON_NGF: 150.09,
    GB_TECHNIKFL_TF_VON_NGF: 5.29,
    GB_VERKEHRSFL_VF_VON_NGF: 17.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989762,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 554.56,
    GB_NUTZFL_NF_VON_NGF: 463.48,
    GB_TECHNIKFL_TF_VON_NGF: 10.71,
    GB_VERKEHRSFL_VF_VON_NGF: 80.37,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.08.16',
    GB_GB_OBJ_ID_PKT: 1989763,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/10',
    GB_SAP_GB_BEZEICHNUNG: 'Unterrichtsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Unterrichtsgeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.527879',
    GB_LAENGENGRAD_OESTLICH: '10.153816',
    GB_NETTOGRUNDFL_NGF: 5389.92,
    GB_NUTZFL_NF_VON_NGF: 4077.87,
    GB_TECHNIKFL_TF_VON_NGF: 178.17,
    GB_VERKEHRSFL_VF_VON_NGF: 1133.88,
    GB_MIETFL_GESAMT: 5389.92,
    GB_MIETFL_1_BSB: 5362.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5408251,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701040,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701040',
    GB_SAP_GB_ID_PKF: '7000/701040/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 336.3,
    GB_NUTZFL_NF_VON_NGF: 309.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.18',
    GB_GB_OBJ_ID_PKT: 5482447,
    GB_WE_OBJ_ID_FKT: 1989034,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3265.67,
    GB_NUTZFL_NF_VON_NGF: 2053.42,
    GB_TECHNIKFL_TF_VON_NGF: 93.38,
    GB_VERKEHRSFL_VF_VON_NGF: 1118.87,
    GB_MIETFL_GESAMT: 3265.67,
    GB_MIETFL_1_BSB: 3097.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989950,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 255.69,
    GB_NUTZFL_NF_VON_NGF: 192.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.05,
    GB_MIETFL_GESAMT: 255.69,
    GB_MIETFL_1_BSB: 255.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989951,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 256.17,
    GB_NUTZFL_NF_VON_NGF: 193.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.05,
    GB_MIETFL_GESAMT: 256.17,
    GB_MIETFL_1_BSB: 256.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989952,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 122.85,
    GB_NUTZFL_NF_VON_NGF: 103.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.74,
    GB_MIETFL_GESAMT: 122.85,
    GB_MIETFL_1_BSB: 122.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989953,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 123.7,
    GB_NUTZFL_NF_VON_NGF: 103.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.92,
    GB_MIETFL_GESAMT: 123.7,
    GB_MIETFL_1_BSB: 123.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989954,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.9,
    GB_NUTZFL_NF_VON_NGF: 676.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 155.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.20',
    GB_GB_OBJ_ID_PKT: 1989955,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 392.56,
    GB_NUTZFL_NF_VON_NGF: 283.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 109.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.20',
    GB_GB_OBJ_ID_PKT: 1989956,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.533122',
    GB_LAENGENGRAD_OESTLICH: '10.130191',
    GB_NETTOGRUNDFL_NGF: 1226.1,
    GB_NUTZFL_NF_VON_NGF: 979.69,
    GB_TECHNIKFL_TF_VON_NGF: 71.18,
    GB_VERKEHRSFL_VF_VON_NGF: 175.23,
    GB_MIETFL_GESAMT: 1226.1,
    GB_MIETFL_1_BSB: 1226.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989957,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 154.73,
    GB_NUTZFL_NF_VON_NGF: 140.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.84,
    GB_MIETFL_GESAMT: 154.73,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989958,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 60.71,
    GB_NUTZFL_NF_VON_NGF: 60.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 60.71,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989959,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 387.75,
    GB_NUTZFL_NF_VON_NGF: 365.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.53,
    GB_MIETFL_GESAMT: 387.75,
    GB_MIETFL_1_BSB: 387.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '15.11.14',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5372168,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701080,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701080',
    GB_SAP_GB_ID_PKF: '7000/701080/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7544161,
    GB_WE_OBJ_ID_FKT: 1989068,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3865.65,
    GB_NUTZFL_NF_VON_NGF: 3247.62,
    GB_TECHNIKFL_TF_VON_NGF: 3.53,
    GB_VERKEHRSFL_VF_VON_NGF: 614.5,
    GB_MIETFL_GESAMT: 3865.65,
    GB_MIETFL_1_BSB: 2046.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989792,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1160.99,
    GB_NUTZFL_NF_VON_NGF: 841.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 319.31,
    GB_MIETFL_GESAMT: 1160.99,
    GB_MIETFL_1_BSB: 1160.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989793,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 830.22,
    GB_NUTZFL_NF_VON_NGF: 679.77,
    GB_TECHNIKFL_TF_VON_NGF: 30.29,
    GB_VERKEHRSFL_VF_VON_NGF: 120.16,
    GB_MIETFL_GESAMT: 830.22,
    GB_MIETFL_1_BSB: 830.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989794,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 478.72,
    GB_NUTZFL_NF_VON_NGF: 385.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 1989795,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 189.17,
    GB_NUTZFL_NF_VON_NGF: 178.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 1989796,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.46,
    GB_NUTZFL_NF_VON_NGF: 131.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.34,
    GB_MIETFL_GESAMT: 140.46,
    GB_MIETFL_1_BSB: 140.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989797,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.53,
    GB_NUTZFL_NF_VON_NGF: 670.83,
    GB_TECHNIKFL_TF_VON_NGF: 52.13,
    GB_VERKEHRSFL_VF_VON_NGF: 108.57,
    GB_MIETFL_GESAMT: 831.53,
    GB_MIETFL_1_BSB: 636.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989798,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.41,
    GB_NUTZFL_NF_VON_NGF: 163.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.03,
    GB_MIETFL_GESAMT: 172.41,
    GB_MIETFL_1_BSB: 172.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989799,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.552608',
    GB_LAENGENGRAD_OESTLICH: '10.107098',
    GB_NETTOGRUNDFL_NGF: 591.56,
    GB_NUTZFL_NF_VON_NGF: 567.87,
    GB_TECHNIKFL_TF_VON_NGF: 4.8,
    GB_VERKEHRSFL_VF_VON_NGF: 18.89,
    GB_MIETFL_GESAMT: 591.56,
    GB_MIETFL_1_BSB: 591.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.04.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989800,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: '53.552621',
    GB_LAENGENGRAD_OESTLICH: '10.107721',
    GB_NETTOGRUNDFL_NGF: 316.08,
    GB_NUTZFL_NF_VON_NGF: 294.46,
    GB_TECHNIKFL_TF_VON_NGF: 7.92,
    GB_VERKEHRSFL_VF_VON_NGF: 13.7,
    GB_MIETFL_GESAMT: 316.08,
    GB_MIETFL_1_BSB: 316.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989801,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.32,
    GB_NUTZFL_NF_VON_NGF: 26.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.32,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989802,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lehrschwimmbecken',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lehrschwimmbecken',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989803,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1571.54,
    GB_NUTZFL_NF_VON_NGF: 1188.75,
    GB_TECHNIKFL_TF_VON_NGF: 143.84,
    GB_VERKEHRSFL_VF_VON_NGF: 238.95,
    GB_MIETFL_GESAMT: 1571.54,
    GB_MIETFL_1_BSB: 1432.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5409166,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 5409167,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701045,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701045',
    GB_SAP_GB_ID_PKF: '7000/701045/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 248.08,
    GB_NUTZFL_NF_VON_NGF: 215.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 29.76,
    GB_MIETFL_GESAMT: 248.08,
    GB_MIETFL_1_BSB: 248.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7121549,
    GB_WE_OBJ_ID_FKT: 1989039,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701082,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701082',
    GB_SAP_GB_ID_PKF: '7000/701082/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 675.57,
    GB_NUTZFL_NF_VON_NGF: 539.85,
    GB_TECHNIKFL_TF_VON_NGF: 47.42,
    GB_VERKEHRSFL_VF_VON_NGF: 88.3,
    GB_MIETFL_GESAMT: 675.57,
    GB_MIETFL_1_BSB: 473.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2137305,
    GB_WE_OBJ_ID_FKT: 2137304,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701082,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701082',
    GB_SAP_GB_ID_PKF: '7000/701082/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1765.42,
    GB_NUTZFL_NF_VON_NGF: 1290.05,
    GB_TECHNIKFL_TF_VON_NGF: 67.92,
    GB_VERKEHRSFL_VF_VON_NGF: 407.45,
    GB_MIETFL_GESAMT: 1765.42,
    GB_MIETFL_1_BSB: 1443.69,
    GB_MIETFL_2_DRITTE: 95.23,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.12',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2137307,
    GB_WE_OBJ_ID_FKT: 2137304,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701082,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701082',
    GB_SAP_GB_ID_PKF: '7000/701082/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 996.93,
    GB_NUTZFL_NF_VON_NGF: 710.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 286.83,
    GB_MIETFL_GESAMT: 996.93,
    GB_MIETFL_1_BSB: 996.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.12',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2137308,
    GB_WE_OBJ_ID_FKT: 2137304,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701082,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701082',
    GB_SAP_GB_ID_PKF: '7000/701082/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.547721',
    GB_LAENGENGRAD_OESTLICH: '10.100426',
    GB_NETTOGRUNDFL_NGF: 656.89,
    GB_NUTZFL_NF_VON_NGF: 611.93,
    GB_TECHNIKFL_TF_VON_NGF: 16.63,
    GB_VERKEHRSFL_VF_VON_NGF: 28.33,
    GB_MIETFL_GESAMT: 656.89,
    GB_MIETFL_1_BSB: 656.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '10.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2137306,
    GB_WE_OBJ_ID_FKT: 2137304,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701082,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701082',
    GB_SAP_GB_ID_PKF: '7000/701082/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5192035,
    GB_WE_OBJ_ID_FKT: 2137304,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1676.32,
    GB_NUTZFL_NF_VON_NGF: 1382.73,
    GB_TECHNIKFL_TF_VON_NGF: 66.38,
    GB_VERKEHRSFL_VF_VON_NGF: 227.21,
    GB_MIETFL_GESAMT: 1676.32,
    GB_MIETFL_1_BSB: 1369.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989831,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.5,
    GB_NUTZFL_NF_VON_NGF: 115.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.5,
    GB_MIETFL_GESAMT: 150.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989832,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.559682',
    GB_LAENGENGRAD_OESTLICH: '10.112006',
    GB_NETTOGRUNDFL_NGF: 636.2,
    GB_NUTZFL_NF_VON_NGF: 594.17,
    GB_TECHNIKFL_TF_VON_NGF: 21.84,
    GB_VERKEHRSFL_VF_VON_NGF: 20.19,
    GB_MIETFL_GESAMT: 636.2,
    GB_MIETFL_1_BSB: 636.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989833,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 479.89,
    GB_NUTZFL_NF_VON_NGF: 396.08,
    GB_TECHNIKFL_TF_VON_NGF: 29.72,
    GB_VERKEHRSFL_VF_VON_NGF: 54.09,
    GB_MIETFL_GESAMT: 479.89,
    GB_MIETFL_1_BSB: 479.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989834,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 470.8,
    GB_NUTZFL_NF_VON_NGF: 395.12,
    GB_TECHNIKFL_TF_VON_NGF: 29.84,
    GB_VERKEHRSFL_VF_VON_NGF: 45.84,
    GB_MIETFL_GESAMT: 470.8,
    GB_MIETFL_1_BSB: 470.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989835,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.47,
    GB_NUTZFL_NF_VON_NGF: 392.04,
    GB_TECHNIKFL_TF_VON_NGF: 33.55,
    GB_VERKEHRSFL_VF_VON_NGF: 45.88,
    GB_MIETFL_GESAMT: 471.47,
    GB_MIETFL_1_BSB: 471.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989836,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.08,
    GB_NUTZFL_NF_VON_NGF: 391.88,
    GB_TECHNIKFL_TF_VON_NGF: 33.28,
    GB_VERKEHRSFL_VF_VON_NGF: 45.92,
    GB_MIETFL_GESAMT: 471.08,
    GB_MIETFL_1_BSB: 471.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989837,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.38,
    GB_NUTZFL_NF_VON_NGF: 391.69,
    GB_TECHNIKFL_TF_VON_NGF: 33.77,
    GB_VERKEHRSFL_VF_VON_NGF: 45.92,
    GB_MIETFL_GESAMT: 471.38,
    GB_MIETFL_1_BSB: 471.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989838,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/9',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.36,
    GB_NUTZFL_NF_VON_NGF: 14.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.36,
    GB_MIETFL_1_BSB: 14.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989839,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701048,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701048',
    GB_SAP_GB_ID_PKF: '7000/701048/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 39.3,
    GB_NUTZFL_NF_VON_NGF: 39.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 39.3,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989840,
    GB_WE_OBJ_ID_FKT: 1989042,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 270.98,
    GB_NUTZFL_NF_VON_NGF: 244.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.10.18',
    GB_GB_OBJ_ID_PKT: 1989764,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.568534',
    GB_LAENGENGRAD_OESTLICH: '10.039399',
    GB_NETTOGRUNDFL_NGF: 623.95,
    GB_NUTZFL_NF_VON_NGF: 581.4,
    GB_TECHNIKFL_TF_VON_NGF: 23.53,
    GB_VERKEHRSFL_VF_VON_NGF: 19.02,
    GB_MIETFL_GESAMT: 623.95,
    GB_MIETFL_1_BSB: 623.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989765,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1956.5,
    GB_NUTZFL_NF_VON_NGF: 1586.71,
    GB_TECHNIKFL_TF_VON_NGF: 7.53,
    GB_VERKEHRSFL_VF_VON_NGF: 362.26,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.10.18',
    GB_GB_OBJ_ID_PKT: 1989766,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 284.87,
    GB_NUTZFL_NF_VON_NGF: 251.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.10.18',
    GB_GB_OBJ_ID_PKT: 1989767,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 277.87,
    GB_NUTZFL_NF_VON_NGF: 232.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.14,
    GB_MIETFL_GESAMT: 277.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989768,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 316.93,
    GB_NUTZFL_NF_VON_NGF: 266.29,
    GB_TECHNIKFL_TF_VON_NGF: 5.35,
    GB_VERKEHRSFL_VF_VON_NGF: 45.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 1989769,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/7',
    GB_SAP_GB_BEZEICHNUNG: 'Unterrichtsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Unterrichtsgeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3559.32,
    GB_NUTZFL_NF_VON_NGF: 2917.83,
    GB_TECHNIKFL_TF_VON_NGF: 36.93,
    GB_VERKEHRSFL_VF_VON_NGF: 604.56,
    GB_MIETFL_GESAMT: 3559.32,
    GB_MIETFL_1_BSB: 3556.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407548,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701041,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701041',
    GB_SAP_GB_ID_PKF: '7000/701041/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7082701,
    GB_WE_OBJ_ID_FKT: 1989035,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: '53.564177',
    GB_LAENGENGRAD_OESTLICH: '10.044810',
    GB_NETTOGRUNDFL_NGF: 4269.39,
    GB_NUTZFL_NF_VON_NGF: 3083.84,
    GB_TECHNIKFL_TF_VON_NGF: 114.0,
    GB_VERKEHRSFL_VF_VON_NGF: 1071.55,
    GB_MIETFL_GESAMT: 4269.39,
    GB_MIETFL_1_BSB: 3934.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989565,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 321.86,
    GB_NUTZFL_NF_VON_NGF: 280.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.66,
    GB_MIETFL_GESAMT: 321.86,
    GB_MIETFL_1_BSB: 321.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989566,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 356.27,
    GB_NUTZFL_NF_VON_NGF: 346.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.1,
    GB_MIETFL_GESAMT: 356.27,
    GB_MIETFL_1_BSB: 174.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989567,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.564142',
    GB_LAENGENGRAD_OESTLICH: '10.044076',
    GB_NETTOGRUNDFL_NGF: 625.47,
    GB_NUTZFL_NF_VON_NGF: 599.54,
    GB_TECHNIKFL_TF_VON_NGF: 6.58,
    GB_VERKEHRSFL_VF_VON_NGF: 19.35,
    GB_MIETFL_GESAMT: 625.47,
    GB_MIETFL_1_BSB: 625.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989568,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/5',
    GB_SAP_GB_BEZEICHNUNG: 'Bunker',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8.7,
    GB_NUTZFL_NF_VON_NGF: 8.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 8.7,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989569,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.63,
    GB_NUTZFL_NF_VON_NGF: 16.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.63,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989570,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.68,
    GB_NUTZFL_NF_VON_NGF: 370.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.24,
    GB_MIETFL_GESAMT: 452.68,
    GB_MIETFL_1_BSB: 452.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989571,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.6,
    GB_NUTZFL_NF_VON_NGF: 380.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 72.49,
    GB_MIETFL_GESAMT: 452.6,
    GB_MIETFL_1_BSB: 452.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989572,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7098068,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 89.38,
    GB_NUTZFL_NF_VON_NGF: 80.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.66,
    GB_MIETFL_GESAMT: 89.38,
    GB_MIETFL_1_BSB: 89.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.02.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7409979,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701014,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701014',
    GB_SAP_GB_ID_PKF: '7000/701014/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 241.49,
    GB_NUTZFL_NF_VON_NGF: 226.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.14,
    GB_MIETFL_GESAMT: 241.49,
    GB_MIETFL_1_BSB: 241.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462185,
    GB_WE_OBJ_ID_FKT: 1989009,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 629.19,
    GB_NUTZFL_NF_VON_NGF: 482.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 146.31,
    GB_MIETFL_GESAMT: 629.19,
    GB_MIETFL_1_BSB: 431.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989847,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausenhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989848,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1200.17,
    GB_NUTZFL_NF_VON_NGF: 899.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 300.97,
    GB_MIETFL_GESAMT: 1200.17,
    GB_MIETFL_1_BSB: 1200.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989849,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 889.37,
    GB_NUTZFL_NF_VON_NGF: 691.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 197.68,
    GB_MIETFL_GESAMT: 889.37,
    GB_MIETFL_1_BSB: 889.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989850,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1846.98,
    GB_NUTZFL_NF_VON_NGF: 1391.59,
    GB_TECHNIKFL_TF_VON_NGF: 112.17,
    GB_VERKEHRSFL_VF_VON_NGF: 343.22,
    GB_MIETFL_GESAMT: 1846.98,
    GB_MIETFL_1_BSB: 1685.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989851,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.570297',
    GB_LAENGENGRAD_OESTLICH: '10.051590',
    GB_NETTOGRUNDFL_NGF: 621.82,
    GB_NUTZFL_NF_VON_NGF: 572.11,
    GB_TECHNIKFL_TF_VON_NGF: 30.63,
    GB_VERKEHRSFL_VF_VON_NGF: 19.08,
    GB_MIETFL_GESAMT: 621.82,
    GB_MIETFL_1_BSB: 621.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989852,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701050,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701050',
    GB_SAP_GB_ID_PKF: '7000/701050/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 418.44,
    GB_NUTZFL_NF_VON_NGF: 393.25,
    GB_TECHNIKFL_TF_VON_NGF: 8.16,
    GB_VERKEHRSFL_VF_VON_NGF: 17.03,
    GB_MIETFL_GESAMT: 418.44,
    GB_MIETFL_1_BSB: 418.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '26.09.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5339435,
    GB_WE_OBJ_ID_FKT: 1989044,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701084,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701084',
    GB_SAP_GB_ID_PKF: '7000/701084/1',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.537846',
    GB_LAENGENGRAD_OESTLICH: '10.013416',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5402105,
    GB_WE_OBJ_ID_FKT: 5307765,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5491330,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701074',
    GB_SAP_GB_ID_PKF: '7000/701074/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 585.06,
    GB_NUTZFL_NF_VON_NGF: 544.17,
    GB_TECHNIKFL_TF_VON_NGF: 21.99,
    GB_VERKEHRSFL_VF_VON_NGF: 18.9,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989947,
    GB_WE_OBJ_ID_FKT: 1989065,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5051.86,
    GB_NUTZFL_NF_VON_NGF: 3762.18,
    GB_TECHNIKFL_TF_VON_NGF: 22.5,
    GB_VERKEHRSFL_VF_VON_NGF: 1267.18,
    GB_MIETFL_GESAMT: 5051.86,
    GB_MIETFL_1_BSB: 5023.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989557,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.541402',
    GB_LAENGENGRAD_OESTLICH: '10.007710',
    GB_NETTOGRUNDFL_NGF: 333.7,
    GB_NUTZFL_NF_VON_NGF: 291.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.49,
    GB_MIETFL_GESAMT: 333.7,
    GB_MIETFL_1_BSB: 333.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373704,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.541593',
    GB_LAENGENGRAD_OESTLICH: '10.007319',
    GB_NETTOGRUNDFL_NGF: 334.54,
    GB_NUTZFL_NF_VON_NGF: 262.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 72.04,
    GB_MIETFL_GESAMT: 334.54,
    GB_MIETFL_1_BSB: 334.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373705,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.541588',
    GB_LAENGENGRAD_OESTLICH: '10.006957',
    GB_NETTOGRUNDFL_NGF: 529.38,
    GB_NUTZFL_NF_VON_NGF: 487.77,
    GB_TECHNIKFL_TF_VON_NGF: 2.92,
    GB_VERKEHRSFL_VF_VON_NGF: 38.69,
    GB_MIETFL_GESAMT: 529.38,
    GB_MIETFL_1_BSB: 529.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373706,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.541218',
    GB_LAENGENGRAD_OESTLICH: '10.007063',
    GB_NETTOGRUNDFL_NGF: 639.01,
    GB_NUTZFL_NF_VON_NGF: 608.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.83,
    GB_MIETFL_GESAMT: 639.01,
    GB_MIETFL_1_BSB: 639.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373707,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701087,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/701087',
    GB_SAP_GB_ID_PKF: '7000/701087/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.541090',
    GB_LAENGENGRAD_OESTLICH: '10.007469',
    GB_NETTOGRUNDFL_NGF: 218.53,
    GB_NUTZFL_NF_VON_NGF: 203.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.09,
    GB_MIETFL_GESAMT: 218.53,
    GB_MIETFL_1_BSB: 218.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373708,
    GB_WE_OBJ_ID_FKT: 5491328,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3444.82,
    GB_NUTZFL_NF_VON_NGF: 2342.64,
    GB_TECHNIKFL_TF_VON_NGF: 196.37,
    GB_VERKEHRSFL_VF_VON_NGF: 905.81,
    GB_MIETFL_GESAMT: 3444.82,
    GB_MIETFL_1_BSB: 3065.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.04.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989558,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 232.24,
    GB_NUTZFL_NF_VON_NGF: 203.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.23,
    GB_MIETFL_GESAMT: 232.24,
    GB_MIETFL_1_BSB: 232.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989559,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.553817',
    GB_LAENGENGRAD_OESTLICH: '10.046176',
    GB_NETTOGRUNDFL_NGF: 1719.88,
    GB_NUTZFL_NF_VON_NGF: 1627.89,
    GB_TECHNIKFL_TF_VON_NGF: 35.02,
    GB_VERKEHRSFL_VF_VON_NGF: 56.97,
    GB_MIETFL_GESAMT: 1719.88,
    GB_MIETFL_1_BSB: 1719.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989560,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 629.24,
    GB_NUTZFL_NF_VON_NGF: 588.35,
    GB_TECHNIKFL_TF_VON_NGF: 21.99,
    GB_VERKEHRSFL_VF_VON_NGF: 18.9,
    GB_MIETFL_GESAMT: 629.24,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 629.24,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989561,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 81.81,
    GB_NUTZFL_NF_VON_NGF: 62.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.88,
    GB_MIETFL_GESAMT: 81.81,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989562,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 24.6,
    GB_NUTZFL_NF_VON_NGF: 24.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 24.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989563,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701013,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701013',
    GB_SAP_GB_ID_PKF: '7000/701013/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.5,
    GB_NUTZFL_NF_VON_NGF: 15.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989564,
    GB_WE_OBJ_ID_FKT: 1989008,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5522.57,
    GB_NUTZFL_NF_VON_NGF: 3570.04,
    GB_TECHNIKFL_TF_VON_NGF: 84.99,
    GB_VERKEHRSFL_VF_VON_NGF: 1867.54,
    GB_MIETFL_GESAMT: 5522.57,
    GB_MIETFL_1_BSB: 5145.1,
    GB_MIETFL_2_DRITTE: 87.92,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989551,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.91,
    GB_NUTZFL_NF_VON_NGF: 173.24,
    GB_TECHNIKFL_TF_VON_NGF: 4.12,
    GB_VERKEHRSFL_VF_VON_NGF: 28.55,
    GB_MIETFL_GESAMT: 205.91,
    GB_MIETFL_1_BSB: 205.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989552,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.01,
    GB_NUTZFL_NF_VON_NGF: 168.68,
    GB_TECHNIKFL_TF_VON_NGF: 6.41,
    GB_VERKEHRSFL_VF_VON_NGF: 30.92,
    GB_MIETFL_GESAMT: 206.01,
    GB_MIETFL_1_BSB: 206.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989553,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.564660',
    GB_LAENGENGRAD_OESTLICH: '10.061669',
    GB_NETTOGRUNDFL_NGF: 590.91,
    GB_NUTZFL_NF_VON_NGF: 560.13,
    GB_TECHNIKFL_TF_VON_NGF: 21.32,
    GB_VERKEHRSFL_VF_VON_NGF: 9.46,
    GB_MIETFL_GESAMT: 590.91,
    GB_MIETFL_1_BSB: 590.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989554,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.565550',
    GB_LAENGENGRAD_OESTLICH: '10.061395',
    GB_NETTOGRUNDFL_NGF: 609.49,
    GB_NUTZFL_NF_VON_NGF: 574.8,
    GB_TECHNIKFL_TF_VON_NGF: 16.39,
    GB_VERKEHRSFL_VF_VON_NGF: 18.3,
    GB_MIETFL_GESAMT: 609.49,
    GB_MIETFL_1_BSB: 609.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989555,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.56,
    GB_NUTZFL_NF_VON_NGF: 14.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989556,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/7',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 531.59,
    GB_NUTZFL_NF_VON_NGF: 338.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 192.75,
    GB_MIETFL_GESAMT: 531.59,
    GB_MIETFL_1_BSB: 358.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.08.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5365139,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.07.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6943332,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701012,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701012',
    GB_SAP_GB_ID_PKF: '7000/701012/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Sporthalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7507252,
    GB_WE_OBJ_ID_FKT: 1989007,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 589.44,
    GB_NUTZFL_NF_VON_NGF: 467.99,
    GB_TECHNIKFL_TF_VON_NGF: 28.98,
    GB_VERKEHRSFL_VF_VON_NGF: 92.47,
    GB_MIETFL_GESAMT: 589.44,
    GB_MIETFL_1_BSB: 589.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989593,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 777.4,
    GB_NUTZFL_NF_VON_NGF: 544.79,
    GB_TECHNIKFL_TF_VON_NGF: 68.06,
    GB_VERKEHRSFL_VF_VON_NGF: 164.55,
    GB_MIETFL_GESAMT: 777.4,
    GB_MIETFL_1_BSB: 777.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989594,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 995.84,
    GB_NUTZFL_NF_VON_NGF: 751.64,
    GB_TECHNIKFL_TF_VON_NGF: 26.58,
    GB_VERKEHRSFL_VF_VON_NGF: 217.62,
    GB_MIETFL_GESAMT: 995.84,
    GB_MIETFL_1_BSB: 856.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989595,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1552.25,
    GB_NUTZFL_NF_VON_NGF: 1114.44,
    GB_TECHNIKFL_TF_VON_NGF: 97.71,
    GB_VERKEHRSFL_VF_VON_NGF: 340.1,
    GB_MIETFL_GESAMT: 1552.25,
    GB_MIETFL_1_BSB: 1447.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989596,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1349.47,
    GB_NUTZFL_NF_VON_NGF: 1115.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 233.97,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.12.19',
    GB_GB_OBJ_ID_PKT: 1989597,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 132.26,
    GB_NUTZFL_NF_VON_NGF: 122.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.25,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.17',
    GB_GB_OBJ_ID_PKT: 1989598,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.81,
    GB_NUTZFL_NF_VON_NGF: 311.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.17',
    GB_GB_OBJ_ID_PKT: 1989599,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 111.21,
    GB_NUTZFL_NF_VON_NGF: 83.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.08,
    GB_MIETFL_GESAMT: 111.21,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989600,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Physiomotorikgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Physiomotorikgeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 518.23,
    GB_NUTZFL_NF_VON_NGF: 461.14,
    GB_TECHNIKFL_TF_VON_NGF: 12.64,
    GB_VERKEHRSFL_VF_VON_NGF: 44.45,
    GB_MIETFL_GESAMT: 518.23,
    GB_MIETFL_1_BSB: 518.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5424484,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701018,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701018',
    GB_SAP_GB_ID_PKF: '7000/701018/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1534.64,
    GB_NUTZFL_NF_VON_NGF: 1452.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.63,
    GB_MIETFL_GESAMT: 1534.64,
    GB_MIETFL_1_BSB: 1530.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.04.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6990164,
    GB_WE_OBJ_ID_FKT: 1989013,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701037',
    GB_SAP_GB_ID_PKF: '7000/701037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: '53.548529',
    GB_LAENGENGRAD_OESTLICH: '10.056299',
    GB_NETTOGRUNDFL_NGF: 7257.26,
    GB_NUTZFL_NF_VON_NGF: 4998.05,
    GB_TECHNIKFL_TF_VON_NGF: 96.61,
    GB_VERKEHRSFL_VF_VON_NGF: 2162.6,
    GB_MIETFL_GESAMT: 7257.26,
    GB_MIETFL_1_BSB: 7059.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989728,
    GB_WE_OBJ_ID_FKT: 1989031,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701037',
    GB_SAP_GB_ID_PKF: '7000/701037/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 210.99,
    GB_NUTZFL_NF_VON_NGF: 166.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989729,
    GB_WE_OBJ_ID_FKT: 1989031,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701037',
    GB_SAP_GB_ID_PKF: '7000/701037/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989730,
    GB_WE_OBJ_ID_FKT: 1989031,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 850.79,
    GB_NUTZFL_NF_VON_NGF: 657.54,
    GB_TECHNIKFL_TF_VON_NGF: 40.23,
    GB_VERKEHRSFL_VF_VON_NGF: 153.02,
    GB_MIETFL_GESAMT: 850.79,
    GB_MIETFL_1_BSB: 639.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989744,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 275.93,
    GB_NUTZFL_NF_VON_NGF: 275.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 275.93,
    GB_MIETFL_1_BSB: 275.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989745,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.42,
    GB_NUTZFL_NF_VON_NGF: 125.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.15,
    GB_MIETFL_GESAMT: 140.42,
    GB_MIETFL_1_BSB: 140.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989746,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.85,
    GB_NUTZFL_NF_VON_NGF: 133.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.71,
    GB_MIETFL_GESAMT: 147.85,
    GB_MIETFL_1_BSB: 147.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989747,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 151.87,
    GB_NUTZFL_NF_VON_NGF: 137.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.61,
    GB_MIETFL_GESAMT: 151.87,
    GB_MIETFL_1_BSB: 151.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989748,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 153.63,
    GB_NUTZFL_NF_VON_NGF: 138.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.2,
    GB_MIETFL_GESAMT: 153.63,
    GB_MIETFL_1_BSB: 153.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989749,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 153.78,
    GB_NUTZFL_NF_VON_NGF: 139.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.69,
    GB_MIETFL_GESAMT: 153.78,
    GB_MIETFL_1_BSB: 153.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989750,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.24,
    GB_NUTZFL_NF_VON_NGF: 139.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.79,
    GB_MIETFL_GESAMT: 156.24,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989751,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.553487',
    GB_LAENGENGRAD_OESTLICH: '10.050116',
    GB_NETTOGRUNDFL_NGF: 668.14,
    GB_NUTZFL_NF_VON_NGF: 628.56,
    GB_TECHNIKFL_TF_VON_NGF: 10.31,
    GB_VERKEHRSFL_VF_VON_NGF: 29.27,
    GB_MIETFL_GESAMT: 668.14,
    GB_MIETFL_1_BSB: 668.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.09',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989752,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 417.98,
    GB_NUTZFL_NF_VON_NGF: 327.26,
    GB_TECHNIKFL_TF_VON_NGF: 10.47,
    GB_VERKEHRSFL_VF_VON_NGF: 80.25,
    GB_MIETFL_GESAMT: 417.98,
    GB_MIETFL_1_BSB: 417.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989753,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.29,
    GB_NUTZFL_NF_VON_NGF: 177.79,
    GB_TECHNIKFL_TF_VON_NGF: 3.54,
    GB_VERKEHRSFL_VF_VON_NGF: 33.96,
    GB_MIETFL_GESAMT: 215.29,
    GB_MIETFL_1_BSB: 215.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989754,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7226790,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7226791,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701039,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701039',
    GB_SAP_GB_ID_PKF: '7000/701039/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7226792,
    GB_WE_OBJ_ID_FKT: 1989033,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701086,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701086',
    GB_SAP_GB_ID_PKF: '7000/701086/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.558214',
    GB_LAENGENGRAD_OESTLICH: '10.026596',
    GB_NETTOGRUNDFL_NGF: 1782.03,
    GB_NUTZFL_NF_VON_NGF: 1683.11,
    GB_TECHNIKFL_TF_VON_NGF: 40.88,
    GB_VERKEHRSFL_VF_VON_NGF: 58.04,
    GB_MIETFL_GESAMT: 1782.03,
    GB_MIETFL_1_BSB: 1782.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5482181,
    GB_WE_OBJ_ID_FKT: 5482179,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701086,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701086',
    GB_SAP_GB_ID_PKF: '7000/701086/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.557533',
    GB_LAENGENGRAD_OESTLICH: '10.026798',
    GB_NETTOGRUNDFL_NGF: 1381.45,
    GB_NUTZFL_NF_VON_NGF: 1343.41,
    GB_TECHNIKFL_TF_VON_NGF: 22.18,
    GB_VERKEHRSFL_VF_VON_NGF: 15.86,
    GB_MIETFL_GESAMT: 1381.45,
    GB_MIETFL_1_BSB: 1381.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.07.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5482182,
    GB_WE_OBJ_ID_FKT: 5482179,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701088,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701088',
    GB_SAP_GB_ID_PKF: '7000/701088/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5930658,
    GB_WE_OBJ_ID_FKT: 5925373,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800537,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701016',
    GB_SAP_GB_ID_PKF: '7000/701016/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2878.06,
    GB_NUTZFL_NF_VON_NGF: 2230.95,
    GB_TECHNIKFL_TF_VON_NGF: 58.5,
    GB_VERKEHRSFL_VF_VON_NGF: 588.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1989581,
    GB_WE_OBJ_ID_FKT: 1989011,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701016',
    GB_SAP_GB_ID_PKF: '7000/701016/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989582,
    GB_WE_OBJ_ID_FKT: 1989011,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701016',
    GB_SAP_GB_ID_PKF: '7000/701016/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 95.88,
    GB_NUTZFL_NF_VON_NGF: 86.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.09.15',
    GB_GB_OBJ_ID_PKT: 1989583,
    GB_WE_OBJ_ID_FKT: 1989011,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701006',
    GB_SAP_GB_ID_PKF: '7000/701006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: '53.551743',
    GB_LAENGENGRAD_OESTLICH: '10.083285',
    GB_NETTOGRUNDFL_NGF: 8483.81,
    GB_NUTZFL_NF_VON_NGF: 5853.28,
    GB_TECHNIKFL_TF_VON_NGF: 59.61,
    GB_VERKEHRSFL_VF_VON_NGF: 2570.92,
    GB_MIETFL_GESAMT: 8483.81,
    GB_MIETFL_1_BSB: 8014.31,
    GB_MIETFL_2_DRITTE: 180.79,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989515,
    GB_WE_OBJ_ID_FKT: 1989001,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701006',
    GB_SAP_GB_ID_PKF: '7000/701006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.552680',
    GB_LAENGENGRAD_OESTLICH: '10.082660',
    GB_NETTOGRUNDFL_NGF: 659.52,
    GB_NUTZFL_NF_VON_NGF: 618.71,
    GB_TECHNIKFL_TF_VON_NGF: 11.92,
    GB_VERKEHRSFL_VF_VON_NGF: 28.89,
    GB_MIETFL_GESAMT: 659.52,
    GB_MIETFL_1_BSB: 659.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989516,
    GB_WE_OBJ_ID_FKT: 1989001,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701006,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701006',
    GB_SAP_GB_ID_PKF: '7000/701006/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 465.84,
    GB_NUTZFL_NF_VON_NGF: 363.13,
    GB_TECHNIKFL_TF_VON_NGF: 4.84,
    GB_VERKEHRSFL_VF_VON_NGF: 97.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '25.10.21',
    GB_GB_OBJ_ID_PKT: 1989517,
    GB_WE_OBJ_ID_FKT: 1989001,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.555408740',
    GB_LAENGENGRAD_OESTLICH: '10.070161325',
    GB_NETTOGRUNDFL_NGF: 454.96,
    GB_NUTZFL_NF_VON_NGF: 398.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 56.59,
    GB_MIETFL_GESAMT: 454.96,
    GB_MIETFL_1_BSB: 353.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989614,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.20',
    GB_GB_OBJ_ID_PKT: 1989615,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.555644644',
    GB_LAENGENGRAD_OESTLICH: '10.070587054',
    GB_NETTOGRUNDFL_NGF: 1550.17,
    GB_NUTZFL_NF_VON_NGF: 1145.9,
    GB_TECHNIKFL_TF_VON_NGF: 101.22,
    GB_VERKEHRSFL_VF_VON_NGF: 303.05,
    GB_MIETFL_GESAMT: 1550.17,
    GB_MIETFL_1_BSB: 1440.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '08.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989616,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 293.47,
    GB_NUTZFL_NF_VON_NGF: 293.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.17',
    GB_GB_OBJ_ID_PKT: 1989617,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 587.48,
    GB_NUTZFL_NF_VON_NGF: 448.83,
    GB_TECHNIKFL_TF_VON_NGF: 4.51,
    GB_VERKEHRSFL_VF_VON_NGF: 134.14,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.17',
    GB_GB_OBJ_ID_PKT: 1989618,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 221.43,
    GB_NUTZFL_NF_VON_NGF: 170.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.09.17',
    GB_GB_OBJ_ID_PKT: 1989620,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.556443',
    GB_LAENGENGRAD_OESTLICH: '10.070780',
    GB_NETTOGRUNDFL_NGF: 618.9,
    GB_NUTZFL_NF_VON_NGF: 589.32,
    GB_TECHNIKFL_TF_VON_NGF: 6.77,
    GB_VERKEHRSFL_VF_VON_NGF: 22.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.07.19',
    GB_GB_OBJ_ID_PKT: 1989619,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.96,
    GB_NUTZFL_NF_VON_NGF: 13.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.07.19',
    GB_GB_OBJ_ID_PKT: 1989621,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.556269',
    GB_LAENGENGRAD_OESTLICH: '10.069909',
    GB_NETTOGRUNDFL_NGF: 655.08,
    GB_NUTZFL_NF_VON_NGF: 613.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.22,
    GB_MIETFL_GESAMT: 655.08,
    GB_MIETFL_1_BSB: 655.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5400485,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: '53.555762',
    GB_LAENGENGRAD_OESTLICH: '10.069445',
    GB_NETTOGRUNDFL_NGF: 2052.92,
    GB_NUTZFL_NF_VON_NGF: 1880.51,
    GB_TECHNIKFL_TF_VON_NGF: 35.38,
    GB_VERKEHRSFL_VF_VON_NGF: 137.03,
    GB_MIETFL_GESAMT: 2052.92,
    GB_MIETFL_1_BSB: 2052.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.07.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5419941,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701021,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701021',
    GB_SAP_GB_ID_PKF: '7000/701021/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: '53.556499550',
    GB_LAENGENGRAD_OESTLICH: '10.071034764',
    GB_NETTOGRUNDFL_NGF: 1207.25,
    GB_NUTZFL_NF_VON_NGF: 850.96,
    GB_TECHNIKFL_TF_VON_NGF: 9.61,
    GB_VERKEHRSFL_VF_VON_NGF: 346.68,
    GB_MIETFL_GESAMT: 1207.25,
    GB_MIETFL_1_BSB: 1202.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.09.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5430942,
    GB_WE_OBJ_ID_FKT: 1989016,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1053.6,
    GB_NUTZFL_NF_VON_NGF: 783.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 269.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989625,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 372.95,
    GB_NUTZFL_NF_VON_NGF: 235.7,
    GB_TECHNIKFL_TF_VON_NGF: 80.2,
    GB_VERKEHRSFL_VF_VON_NGF: 57.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.18',
    GB_GB_OBJ_ID_PKT: 1989626,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 99.59,
    GB_NUTZFL_NF_VON_NGF: 88.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989627,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 160.39,
    GB_NUTZFL_NF_VON_NGF: 100.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989628,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.37,
    GB_NUTZFL_NF_VON_NGF: 378.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.84,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989629,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 389.58,
    GB_NUTZFL_NF_VON_NGF: 294.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.79,
    GB_MIETFL_GESAMT: 389.58,
    GB_MIETFL_1_BSB: 389.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989630,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 389.33,
    GB_NUTZFL_NF_VON_NGF: 294.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.59,
    GB_MIETFL_GESAMT: 389.33,
    GB_MIETFL_1_BSB: 389.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989631,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 389.45,
    GB_NUTZFL_NF_VON_NGF: 294.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.64,
    GB_MIETFL_GESAMT: 389.45,
    GB_MIETFL_1_BSB: 389.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989632,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 226.64,
    GB_NUTZFL_NF_VON_NGF: 203.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989633,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 302.24,
    GB_NUTZFL_NF_VON_NGF: 271.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.09,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989634,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 141.82,
    GB_NUTZFL_NF_VON_NGF: 130.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.12.18',
    GB_GB_OBJ_ID_PKT: 1989635,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.61,
    GB_NUTZFL_NF_VON_NGF: 204.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1989636,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.16,
    GB_NUTZFL_NF_VON_NGF: 200.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.12.18',
    GB_GB_OBJ_ID_PKT: 1989637,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 347.46,
    GB_NUTZFL_NF_VON_NGF: 253.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.73,
    GB_MIETFL_GESAMT: 347.46,
    GB_MIETFL_1_BSB: 347.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989638,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 347.6,
    GB_NUTZFL_NF_VON_NGF: 253.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.8,
    GB_MIETFL_GESAMT: 347.6,
    GB_MIETFL_1_BSB: 347.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989639,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 389.57,
    GB_NUTZFL_NF_VON_NGF: 294.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 95.13,
    GB_MIETFL_GESAMT: 389.57,
    GB_MIETFL_1_BSB: 389.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989640,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 387.64,
    GB_NUTZFL_NF_VON_NGF: 314.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 72.93,
    GB_MIETFL_GESAMT: 387.64,
    GB_MIETFL_1_BSB: 387.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989641,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.555995',
    GB_LAENGENGRAD_OESTLICH: '10.102681',
    GB_NETTOGRUNDFL_NGF: 626.14,
    GB_NUTZFL_NF_VON_NGF: 590.58,
    GB_TECHNIKFL_TF_VON_NGF: 13.41,
    GB_VERKEHRSFL_VF_VON_NGF: 22.15,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.11.20',
    GB_GB_OBJ_ID_PKT: 1989642,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989643,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/20',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 310.83,
    GB_NUTZFL_NF_VON_NGF: 298.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.32,
    GB_MIETFL_GESAMT: 310.83,
    GB_MIETFL_1_BSB: 310.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5382561,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/21',
    GB_SAP_GB_BEZEICHNUNG: 'Unterrichtsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Unterrichtsgeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3060.33,
    GB_NUTZFL_NF_VON_NGF: 2190.6,
    GB_TECHNIKFL_TF_VON_NGF: 127.9,
    GB_VERKEHRSFL_VF_VON_NGF: 741.83,
    GB_MIETFL_GESAMT: 3060.33,
    GB_MIETFL_1_BSB: 2751.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5409164,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/22',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1181.67,
    GB_NUTZFL_NF_VON_NGF: 1083.36,
    GB_TECHNIKFL_TF_VON_NGF: 11.94,
    GB_VERKEHRSFL_VF_VON_NGF: 86.37,
    GB_MIETFL_GESAMT: 1181.67,
    GB_MIETFL_1_BSB: 1181.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5409165,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701023,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701023',
    GB_SAP_GB_ID_PKF: '7000/701023/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 435.26,
    GB_NUTZFL_NF_VON_NGF: 407.38,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 24.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.19',
    GB_GB_OBJ_ID_PKT: 5445432,
    GB_WE_OBJ_ID_FKT: 1989018,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800372,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701022',
    GB_SAP_GB_ID_PKF: '7000/701022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: '53.554577743',
    GB_LAENGENGRAD_OESTLICH: '10.075060732',
    GB_NETTOGRUNDFL_NGF: 5545.81,
    GB_NUTZFL_NF_VON_NGF: 3701.26,
    GB_TECHNIKFL_TF_VON_NGF: 106.18,
    GB_VERKEHRSFL_VF_VON_NGF: 1738.37,
    GB_MIETFL_GESAMT: 5545.81,
    GB_MIETFL_1_BSB: 4027.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989622,
    GB_WE_OBJ_ID_FKT: 1989017,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701022',
    GB_SAP_GB_ID_PKF: '7000/701022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.554356380',
    GB_LAENGENGRAD_OESTLICH: '10.074771834',
    GB_NETTOGRUNDFL_NGF: 1105.32,
    GB_NUTZFL_NF_VON_NGF: 910.99,
    GB_TECHNIKFL_TF_VON_NGF: 32.85,
    GB_VERKEHRSFL_VF_VON_NGF: 161.48,
    GB_MIETFL_GESAMT: 1105.32,
    GB_MIETFL_1_BSB: 994.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989623,
    GB_WE_OBJ_ID_FKT: 1989017,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701022',
    GB_SAP_GB_ID_PKF: '7000/701022/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.554033',
    GB_LAENGENGRAD_OESTLICH: '10.074151',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '24.09.19',
    GB_GB_OBJ_ID_PKT: 1989624,
    GB_WE_OBJ_ID_FKT: 1989017,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 391.42,
    GB_NUTZFL_NF_VON_NGF: 360.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.58,
    GB_MIETFL_GESAMT: 391.42,
    GB_MIETFL_1_BSB: 391.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989601,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 158.12,
    GB_NUTZFL_NF_VON_NGF: 107.03,
    GB_TECHNIKFL_TF_VON_NGF: 10.68,
    GB_VERKEHRSFL_VF_VON_NGF: 40.41,
    GB_MIETFL_GESAMT: 158.12,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989602,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 825.71,
    GB_NUTZFL_NF_VON_NGF: 653.56,
    GB_TECHNIKFL_TF_VON_NGF: 30.29,
    GB_VERKEHRSFL_VF_VON_NGF: 141.86,
    GB_MIETFL_GESAMT: 825.71,
    GB_MIETFL_1_BSB: 825.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989603,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 279.98,
    GB_NUTZFL_NF_VON_NGF: 279.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 279.98,
    GB_MIETFL_1_BSB: 279.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989604,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1540.02,
    GB_NUTZFL_NF_VON_NGF: 1146.22,
    GB_TECHNIKFL_TF_VON_NGF: 45.33,
    GB_VERKEHRSFL_VF_VON_NGF: 348.47,
    GB_MIETFL_GESAMT: 1540.02,
    GB_MIETFL_1_BSB: 1437.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989605,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 847.36,
    GB_NUTZFL_NF_VON_NGF: 743.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.69,
    GB_MIETFL_GESAMT: 847.36,
    GB_MIETFL_1_BSB: 847.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989606,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/7',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.74,
    GB_NUTZFL_NF_VON_NGF: 26.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.74,
    GB_MIETFL_1_BSB: 26.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989607,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.84,
    GB_NUTZFL_NF_VON_NGF: 161.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.56,
    GB_MIETFL_GESAMT: 173.84,
    GB_MIETFL_1_BSB: 173.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989608,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.559836',
    GB_LAENGENGRAD_OESTLICH: '10.075435',
    GB_NETTOGRUNDFL_NGF: 301.68,
    GB_NUTZFL_NF_VON_NGF: 276.58,
    GB_TECHNIKFL_TF_VON_NGF: 16.7,
    GB_VERKEHRSFL_VF_VON_NGF: 8.4,
    GB_MIETFL_GESAMT: 301.68,
    GB_MIETFL_1_BSB: 301.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989609,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.66,
    GB_NUTZFL_NF_VON_NGF: 13.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.66,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989611,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.49,
    GB_NUTZFL_NF_VON_NGF: 81.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.73,
    GB_MIETFL_GESAMT: 90.49,
    GB_MIETFL_1_BSB: 90.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7364228,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.559422',
    GB_LAENGENGRAD_OESTLICH: '10.075707',
    GB_NETTOGRUNDFL_NGF: 619.87,
    GB_NUTZFL_NF_VON_NGF: 566.43,
    GB_TECHNIKFL_TF_VON_NGF: 33.98,
    GB_VERKEHRSFL_VF_VON_NGF: 19.46,
    GB_MIETFL_GESAMT: 619.87,
    GB_MIETFL_1_BSB: 619.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.11.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989610,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701019,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701019',
    GB_SAP_GB_ID_PKF: '7000/701019/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552741,
    GB_WE_OBJ_ID_FKT: 1989014,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701020',
    GB_SAP_GB_ID_PKF: '7000/701020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.557242187',
    GB_LAENGENGRAD_OESTLICH: '10.073147142',
    GB_NETTOGRUNDFL_NGF: 16587.14,
    GB_NUTZFL_NF_VON_NGF: 9477.24,
    GB_TECHNIKFL_TF_VON_NGF: 3398.56,
    GB_VERKEHRSFL_VF_VON_NGF: 3711.34,
    GB_MIETFL_GESAMT: 16587.14,
    GB_MIETFL_1_BSB: 11313.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.07.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989612,
    GB_WE_OBJ_ID_FKT: 1989015,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701020',
    GB_SAP_GB_ID_PKF: '7000/701020/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.09,
    GB_NUTZFL_NF_VON_NGF: 138.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.11.14',
    GB_GB_OBJ_ID_PKT: 1989613,
    GB_WE_OBJ_ID_FKT: 1989015,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554643155',
    GB_LAENGENGRAD_OESTLICH: '10.095636252',
    GB_NETTOGRUNDFL_NGF: 1069.6,
    GB_NUTZFL_NF_VON_NGF: 889.75,
    GB_TECHNIKFL_TF_VON_NGF: 29.97,
    GB_VERKEHRSFL_VF_VON_NGF: 149.88,
    GB_MIETFL_GESAMT: 1069.6,
    GB_MIETFL_1_BSB: 796.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989781,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554793',
    GB_LAENGENGRAD_OESTLICH: '10.095032',
    GB_NETTOGRUNDFL_NGF: 514.14,
    GB_NUTZFL_NF_VON_NGF: 446.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.87,
    GB_MIETFL_GESAMT: 514.14,
    GB_MIETFL_1_BSB: 514.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989782,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.555011898',
    GB_LAENGENGRAD_OESTLICH: '10.094683871',
    GB_NETTOGRUNDFL_NGF: 511.23,
    GB_NUTZFL_NF_VON_NGF: 443.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.69,
    GB_MIETFL_GESAMT: 511.23,
    GB_MIETFL_1_BSB: 511.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989783,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.555244163',
    GB_LAENGENGRAD_OESTLICH: '10.094462471',
    GB_NETTOGRUNDFL_NGF: 510.52,
    GB_NUTZFL_NF_VON_NGF: 420.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.85,
    GB_MIETFL_GESAMT: 510.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 510.52,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989784,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.555440',
    GB_LAENGENGRAD_OESTLICH: '10.094604',
    GB_NETTOGRUNDFL_NGF: 660.23,
    GB_NUTZFL_NF_VON_NGF: 565.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.76,
    GB_MIETFL_GESAMT: 660.23,
    GB_MIETFL_1_BSB: 660.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989785,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.555126',
    GB_LAENGENGRAD_OESTLICH: '10.095621',
    GB_NETTOGRUNDFL_NGF: 408.86,
    GB_NUTZFL_NF_VON_NGF: 359.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.75,
    GB_MIETFL_GESAMT: 408.86,
    GB_MIETFL_1_BSB: 408.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989786,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554898',
    GB_LAENGENGRAD_OESTLICH: '10.096857',
    GB_NETTOGRUNDFL_NGF: 237.99,
    GB_NUTZFL_NF_VON_NGF: 213.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.29,
    GB_MIETFL_GESAMT: 237.99,
    GB_MIETFL_1_BSB: 237.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989787,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554590',
    GB_LAENGENGRAD_OESTLICH: '10.096747',
    GB_NETTOGRUNDFL_NGF: 234.8,
    GB_NUTZFL_NF_VON_NGF: 209.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.93,
    GB_MIETFL_GESAMT: 234.8,
    GB_MIETFL_1_BSB: 234.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989788,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554277',
    GB_LAENGENGRAD_OESTLICH: '10.096465',
    GB_NETTOGRUNDFL_NGF: 239.17,
    GB_NUTZFL_NF_VON_NGF: 214.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.86,
    GB_MIETFL_GESAMT: 239.17,
    GB_MIETFL_1_BSB: 239.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989789,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.554248',
    GB_LAENGENGRAD_OESTLICH: '10.096111',
    GB_NETTOGRUNDFL_NGF: 238.48,
    GB_NUTZFL_NF_VON_NGF: 213.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.88,
    GB_MIETFL_GESAMT: 238.48,
    GB_MIETFL_1_BSB: 238.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989790,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701044,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701044',
    GB_SAP_GB_ID_PKF: '7000/701044/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.555479',
    GB_LAENGENGRAD_OESTLICH: '10.095149',
    GB_NETTOGRUNDFL_NGF: 624.51,
    GB_NUTZFL_NF_VON_NGF: 595.42,
    GB_TECHNIKFL_TF_VON_NGF: 6.72,
    GB_VERKEHRSFL_VF_VON_NGF: 22.37,
    GB_MIETFL_GESAMT: 624.51,
    GB_MIETFL_1_BSB: 624.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989791,
    GB_WE_OBJ_ID_FKT: 1989038,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.36,
    GB_NUTZFL_NF_VON_NGF: 419.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.24,
    GB_MIETFL_GESAMT: 455.36,
    GB_MIETFL_1_BSB: 262.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989809,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 843.8,
    GB_NUTZFL_NF_VON_NGF: 633.04,
    GB_TECHNIKFL_TF_VON_NGF: 45.53,
    GB_VERKEHRSFL_VF_VON_NGF: 165.23,
    GB_MIETFL_GESAMT: 843.8,
    GB_MIETFL_1_BSB: 837.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.02.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989810,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.4,
    GB_NUTZFL_NF_VON_NGF: 189.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.07,
    GB_MIETFL_GESAMT: 192.4,
    GB_MIETFL_1_BSB: 192.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989811,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 80.9,
    GB_NUTZFL_NF_VON_NGF: 80.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 80.9,
    GB_MIETFL_1_BSB: 80.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989812,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.18,
    GB_NUTZFL_NF_VON_NGF: 133.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.68,
    GB_MIETFL_GESAMT: 144.18,
    GB_MIETFL_1_BSB: 144.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989813,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.549480',
    GB_LAENGENGRAD_OESTLICH: '10.094984',
    GB_NETTOGRUNDFL_NGF: 622.34,
    GB_NUTZFL_NF_VON_NGF: 576.79,
    GB_TECHNIKFL_TF_VON_NGF: 26.34,
    GB_VERKEHRSFL_VF_VON_NGF: 19.21,
    GB_MIETFL_GESAMT: 622.34,
    GB_MIETFL_1_BSB: 622.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.01.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989814,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1596.36,
    GB_NUTZFL_NF_VON_NGF: 1193.32,
    GB_TECHNIKFL_TF_VON_NGF: 66.77,
    GB_VERKEHRSFL_VF_VON_NGF: 336.27,
    GB_MIETFL_GESAMT: 1596.36,
    GB_MIETFL_1_BSB: 1492.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989815,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 132.29,
    GB_NUTZFL_NF_VON_NGF: 120.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.86,
    GB_MIETFL_GESAMT: 132.29,
    GB_MIETFL_1_BSB: 132.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989816,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 152.28,
    GB_NUTZFL_NF_VON_NGF: 152.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 152.28,
    GB_MIETFL_1_BSB: 152.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989817,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1140.3,
    GB_NUTZFL_NF_VON_NGF: 1030.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 109.79,
    GB_MIETFL_GESAMT: 1140.3,
    GB_MIETFL_1_BSB: 850.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989818,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.548865',
    GB_LAENGENGRAD_OESTLICH: '10.093613',
    GB_NETTOGRUNDFL_NGF: 347.91,
    GB_NUTZFL_NF_VON_NGF: 330.01,
    GB_TECHNIKFL_TF_VON_NGF: 9.53,
    GB_VERKEHRSFL_VF_VON_NGF: 8.37,
    GB_MIETFL_GESAMT: 347.91,
    GB_MIETFL_1_BSB: 304.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989819,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.548624',
    GB_LAENGENGRAD_OESTLICH: '10.093967',
    GB_NETTOGRUNDFL_NGF: 624.81,
    GB_NUTZFL_NF_VON_NGF: 590.29,
    GB_TECHNIKFL_TF_VON_NGF: 15.66,
    GB_VERKEHRSFL_VF_VON_NGF: 18.86,
    GB_MIETFL_GESAMT: 624.81,
    GB_MIETFL_1_BSB: 624.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989820,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1582.82,
    GB_NUTZFL_NF_VON_NGF: 1166.86,
    GB_TECHNIKFL_TF_VON_NGF: 59.96,
    GB_VERKEHRSFL_VF_VON_NGF: 356.0,
    GB_MIETFL_GESAMT: 1582.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1113.41,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989821,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/14',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 176.83,
    GB_NUTZFL_NF_VON_NGF: 161.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.46,
    GB_MIETFL_GESAMT: 176.83,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 176.83,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989822,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/15',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 107.76,
    GB_NUTZFL_NF_VON_NGF: 97.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.6,
    GB_MIETFL_GESAMT: 107.76,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 107.76,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989823,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 471.03,
    GB_NUTZFL_NF_VON_NGF: 433.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.31,
    GB_MIETFL_GESAMT: 471.03,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 471.03,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989824,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/17',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: '53.550018',
    GB_LAENGENGRAD_OESTLICH: '10.096189',
    GB_NETTOGRUNDFL_NGF: 299.94,
    GB_NUTZFL_NF_VON_NGF: 278.13,
    GB_TECHNIKFL_TF_VON_NGF: 13.54,
    GB_VERKEHRSFL_VF_VON_NGF: 8.27,
    GB_MIETFL_GESAMT: 299.94,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989825,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/18',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1954,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1709.46,
    GB_NUTZFL_NF_VON_NGF: 1617.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 91.61,
    GB_MIETFL_GESAMT: 1709.46,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 847.59,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989826,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 345.56,
    GB_NUTZFL_NF_VON_NGF: 310.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.05.21',
    GB_GB_OBJ_ID_PKT: 1989827,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/20',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.53,
    GB_NUTZFL_NF_VON_NGF: 119.39,
    GB_TECHNIKFL_TF_VON_NGF: 3.32,
    GB_VERKEHRSFL_VF_VON_NGF: 27.82,
    GB_MIETFL_GESAMT: 150.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989828,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/21',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.0,
    GB_NUTZFL_NF_VON_NGF: 22.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989829,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/22',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 118.49,
    GB_NUTZFL_NF_VON_NGF: 95.83,
    GB_TECHNIKFL_TF_VON_NGF: 2.04,
    GB_VERKEHRSFL_VF_VON_NGF: 20.62,
    GB_MIETFL_GESAMT: 118.49,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989830,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 23,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/23',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2073.5,
    GB_NUTZFL_NF_VON_NGF: 1799.39,
    GB_TECHNIKFL_TF_VON_NGF: 8.66,
    GB_VERKEHRSFL_VF_VON_NGF: 265.45,
    GB_MIETFL_GESAMT: 2073.5,
    GB_MIETFL_1_BSB: 2070.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.04.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7044998,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701047,
    GB_SAP_GB_NUMMER: 24,
    GB_SAP_WE_ID_FKF: '7000/701047',
    GB_SAP_GB_ID_PKF: '7000/701047/24',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7148618,
    GB_WE_OBJ_ID_FKT: 1989041,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 315.52,
    GB_NUTZFL_NF_VON_NGF: 315.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 315.52,
    GB_MIETFL_1_BSB: 315.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989770,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.551875',
    GB_LAENGENGRAD_OESTLICH: '9.978363',
    GB_NETTOGRUNDFL_NGF: 625.73,
    GB_NUTZFL_NF_VON_NGF: 565.37,
    GB_TECHNIKFL_TF_VON_NGF: 8.54,
    GB_VERKEHRSFL_VF_VON_NGF: 51.82,
    GB_MIETFL_GESAMT: 625.73,
    GB_MIETFL_1_BSB: 625.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989771,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1192.04,
    GB_NUTZFL_NF_VON_NGF: 1090.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.65,
    GB_MIETFL_GESAMT: 1192.04,
    GB_MIETFL_1_BSB: 1192.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989772,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 882.14,
    GB_NUTZFL_NF_VON_NGF: 779.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.01,
    GB_MIETFL_GESAMT: 882.14,
    GB_MIETFL_1_BSB: 882.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989773,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 574.46,
    GB_NUTZFL_NF_VON_NGF: 500.87,
    GB_TECHNIKFL_TF_VON_NGF: 3.85,
    GB_VERKEHRSFL_VF_VON_NGF: 69.74,
    GB_MIETFL_GESAMT: 574.46,
    GB_MIETFL_1_BSB: 574.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989774,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1679.55,
    GB_NUTZFL_NF_VON_NGF: 1265.38,
    GB_TECHNIKFL_TF_VON_NGF: 79.76,
    GB_VERKEHRSFL_VF_VON_NGF: 334.41,
    GB_MIETFL_GESAMT: 1679.55,
    GB_MIETFL_1_BSB: 1576.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989775,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 256.54,
    GB_NUTZFL_NF_VON_NGF: 198.65,
    GB_TECHNIKFL_TF_VON_NGF: 29.13,
    GB_VERKEHRSFL_VF_VON_NGF: 28.76,
    GB_MIETFL_GESAMT: 256.54,
    GB_MIETFL_1_BSB: 209.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989776,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989777,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/9',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.03,
    GB_NUTZFL_NF_VON_NGF: 135.33,
    GB_TECHNIKFL_TF_VON_NGF: 8.26,
    GB_VERKEHRSFL_VF_VON_NGF: 81.44,
    GB_MIETFL_GESAMT: 225.03,
    GB_MIETFL_1_BSB: 216.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989778,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701042,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701042',
    GB_SAP_GB_ID_PKF: '7000/701042/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989779,
    GB_WE_OBJ_ID_FKT: 1989036,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701002',
    GB_SAP_GB_ID_PKF: '7000/701002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1893,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2936.76,
    GB_NUTZFL_NF_VON_NGF: 2158.37,
    GB_TECHNIKFL_TF_VON_NGF: 52.72,
    GB_VERKEHRSFL_VF_VON_NGF: 725.67,
    GB_MIETFL_GESAMT: 2936.76,
    GB_MIETFL_1_BSB: 2271.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989482,
    GB_WE_OBJ_ID_FKT: 1988997,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701002',
    GB_SAP_GB_ID_PKF: '7000/701002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1866.58,
    GB_NUTZFL_NF_VON_NGF: 1310.08,
    GB_TECHNIKFL_TF_VON_NGF: 37.82,
    GB_VERKEHRSFL_VF_VON_NGF: 518.68,
    GB_MIETFL_GESAMT: 1866.58,
    GB_MIETFL_1_BSB: 1682.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989483,
    GB_WE_OBJ_ID_FKT: 1988997,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701002',
    GB_SAP_GB_ID_PKF: '7000/701002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.553683',
    GB_LAENGENGRAD_OESTLICH: '9.981585',
    GB_NETTOGRUNDFL_NGF: 1599.17,
    GB_NUTZFL_NF_VON_NGF: 1217.3,
    GB_TECHNIKFL_TF_VON_NGF: 29.2,
    GB_VERKEHRSFL_VF_VON_NGF: 352.67,
    GB_MIETFL_GESAMT: 1599.17,
    GB_MIETFL_1_BSB: 1582.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989484,
    GB_WE_OBJ_ID_FKT: 1988997,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701002',
    GB_SAP_GB_ID_PKF: '7000/701002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2449.9,
    GB_NUTZFL_NF_VON_NGF: 1741.98,
    GB_TECHNIKFL_TF_VON_NGF: 7.6,
    GB_VERKEHRSFL_VF_VON_NGF: 700.32,
    GB_MIETFL_GESAMT: 2449.9,
    GB_MIETFL_1_BSB: 2154.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989485,
    GB_WE_OBJ_ID_FKT: 1988997,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701002',
    GB_SAP_GB_ID_PKF: '7000/701002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1972.91,
    GB_NUTZFL_NF_VON_NGF: 1408.79,
    GB_TECHNIKFL_TF_VON_NGF: 103.36,
    GB_VERKEHRSFL_VF_VON_NGF: 460.76,
    GB_MIETFL_GESAMT: 1972.91,
    GB_MIETFL_1_BSB: 1858.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989486,
    GB_WE_OBJ_ID_FKT: 1988997,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701035',
    GB_SAP_GB_ID_PKF: '7000/701035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 253.0,
    GB_NUTZFL_NF_VON_NGF: 253.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 253.0,
    GB_MIETFL_1_BSB: 253.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989714,
    GB_WE_OBJ_ID_FKT: 1989029,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701035,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701035',
    GB_SAP_GB_ID_PKF: '7000/701035/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 1989715,
    GB_WE_OBJ_ID_FKT: 1989029,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 212.51,
    GB_NUTZFL_NF_VON_NGF: 164.16,
    GB_TECHNIKFL_TF_VON_NGF: 7.58,
    GB_VERKEHRSFL_VF_VON_NGF: 40.77,
    GB_MIETFL_GESAMT: 212.51,
    GB_MIETFL_1_BSB: 212.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989666,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.45,
    GB_NUTZFL_NF_VON_NGF: 308.14,
    GB_TECHNIKFL_TF_VON_NGF: 39.14,
    GB_VERKEHRSFL_VF_VON_NGF: 108.17,
    GB_MIETFL_GESAMT: 455.45,
    GB_MIETFL_1_BSB: 353.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989667,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 252.72,
    GB_NUTZFL_NF_VON_NGF: 233.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.82,
    GB_MIETFL_GESAMT: 252.72,
    GB_MIETFL_1_BSB: 252.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989668,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.07,
    GB_NUTZFL_NF_VON_NGF: 107.49,
    GB_TECHNIKFL_TF_VON_NGF: 24.96,
    GB_VERKEHRSFL_VF_VON_NGF: 23.62,
    GB_MIETFL_GESAMT: 156.07,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989669,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 588.07,
    GB_NUTZFL_NF_VON_NGF: 485.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.76,
    GB_MIETFL_GESAMT: 588.07,
    GB_MIETFL_1_BSB: 588.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989670,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/6',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.535604',
    GB_LAENGENGRAD_OESTLICH: '10.038414',
    GB_NETTOGRUNDFL_NGF: 309.02,
    GB_NUTZFL_NF_VON_NGF: 300.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.29,
    GB_MIETFL_GESAMT: 309.02,
    GB_MIETFL_1_BSB: 309.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989671,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 350.83,
    GB_NUTZFL_NF_VON_NGF: 274.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.21,
    GB_MIETFL_GESAMT: 350.83,
    GB_MIETFL_1_BSB: 350.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989672,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 871.08,
    GB_NUTZFL_NF_VON_NGF: 701.62,
    GB_TECHNIKFL_TF_VON_NGF: 3.82,
    GB_VERKEHRSFL_VF_VON_NGF: 165.64,
    GB_MIETFL_GESAMT: 871.08,
    GB_MIETFL_1_BSB: 865.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989673,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.19,
    GB_NUTZFL_NF_VON_NGF: 13.19,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.19,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989674,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.12,
    GB_NUTZFL_NF_VON_NGF: 13.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.12,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989675,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.53,
    GB_NUTZFL_NF_VON_NGF: 26.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989676,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7072218,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701030,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701030',
    GB_SAP_GB_ID_PKF: '7000/701030/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.26,
    GB_NUTZFL_NF_VON_NGF: 145.43,
    GB_TECHNIKFL_TF_VON_NGF: 7.12,
    GB_VERKEHRSFL_VF_VON_NGF: 19.71,
    GB_MIETFL_GESAMT: 172.26,
    GB_MIETFL_1_BSB: 172.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036055,
    GB_WE_OBJ_ID_FKT: 1989024,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1049.32,
    GB_NUTZFL_NF_VON_NGF: 874.34,
    GB_TECHNIKFL_TF_VON_NGF: 5.04,
    GB_VERKEHRSFL_VF_VON_NGF: 169.94,
    GB_MIETFL_GESAMT: 1049.32,
    GB_MIETFL_1_BSB: 965.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989528,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 128.4,
    GB_NUTZFL_NF_VON_NGF: 117.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.45,
    GB_MIETFL_GESAMT: 128.4,
    GB_MIETFL_1_BSB: 128.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989529,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.96,
    GB_NUTZFL_NF_VON_NGF: 636.81,
    GB_TECHNIKFL_TF_VON_NGF: 30.43,
    GB_VERKEHRSFL_VF_VON_NGF: 164.72,
    GB_MIETFL_GESAMT: 831.96,
    GB_MIETFL_1_BSB: 831.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989530,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.535787',
    GB_LAENGENGRAD_OESTLICH: '10.041223',
    GB_NETTOGRUNDFL_NGF: 634.15,
    GB_NUTZFL_NF_VON_NGF: 607.85,
    GB_TECHNIKFL_TF_VON_NGF: 7.06,
    GB_VERKEHRSFL_VF_VON_NGF: 19.24,
    GB_MIETFL_GESAMT: 634.15,
    GB_MIETFL_1_BSB: 634.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989531,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 887.08,
    GB_NUTZFL_NF_VON_NGF: 735.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 151.24,
    GB_MIETFL_GESAMT: 887.08,
    GB_MIETFL_1_BSB: 887.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989532,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 940.39,
    GB_NUTZFL_NF_VON_NGF: 804.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 136.18,
    GB_MIETFL_GESAMT: 940.39,
    GB_MIETFL_1_BSB: 940.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989533,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1505.38,
    GB_NUTZFL_NF_VON_NGF: 1051.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 454.27,
    GB_MIETFL_GESAMT: 1505.38,
    GB_MIETFL_1_BSB: 1505.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989534,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 463.26,
    GB_NUTZFL_NF_VON_NGF: 350.82,
    GB_TECHNIKFL_TF_VON_NGF: 5.74,
    GB_VERKEHRSFL_VF_VON_NGF: 106.7,
    GB_MIETFL_GESAMT: 463.26,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 463.26,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989535,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.22,
    GB_NUTZFL_NF_VON_NGF: 26.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989536,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989537,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7096914,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/701008',
    GB_SAP_GB_ID_PKF: '7000/701008/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7096915,
    GB_WE_OBJ_ID_FKT: 1989003,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 2053707,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1102.93,
    GB_NUTZFL_NF_VON_NGF: 776.67,
    GB_TECHNIKFL_TF_VON_NGF: 12.14,
    GB_VERKEHRSFL_VF_VON_NGF: 314.12,
    GB_MIETFL_GESAMT: 1102.93,
    GB_MIETFL_1_BSB: 1018.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989584,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1744.42,
    GB_NUTZFL_NF_VON_NGF: 1399.34,
    GB_TECHNIKFL_TF_VON_NGF: 20.57,
    GB_VERKEHRSFL_VF_VON_NGF: 324.51,
    GB_MIETFL_GESAMT: 1744.42,
    GB_MIETFL_1_BSB: 1430.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989586,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 483.86,
    GB_NUTZFL_NF_VON_NGF: 378.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 105.54,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.10.22',
    GB_GB_OBJ_ID_PKT: 1989587,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.556137',
    GB_LAENGENGRAD_OESTLICH: '10.010858',
    GB_NETTOGRUNDFL_NGF: 619.39,
    GB_NUTZFL_NF_VON_NGF: 578.94,
    GB_TECHNIKFL_TF_VON_NGF: 21.58,
    GB_VERKEHRSFL_VF_VON_NGF: 18.87,
    GB_MIETFL_GESAMT: 619.39,
    GB_MIETFL_1_BSB: 619.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '09.09.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989585,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.10.22',
    GB_GB_OBJ_ID_PKT: 1989588,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1595.6,
    GB_NUTZFL_NF_VON_NGF: 1113.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 482.0,
    GB_MIETFL_GESAMT: 1595.6,
    GB_MIETFL_1_BSB: 1353.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989589,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.98,
    GB_NUTZFL_NF_VON_NGF: 358.93,
    GB_TECHNIKFL_TF_VON_NGF: 28.34,
    GB_VERKEHRSFL_VF_VON_NGF: 46.71,
    GB_MIETFL_GESAMT: 433.98,
    GB_MIETFL_1_BSB: 411.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989590,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.12,
    GB_NUTZFL_NF_VON_NGF: 143.48,
    GB_TECHNIKFL_TF_VON_NGF: 4.4,
    GB_VERKEHRSFL_VF_VON_NGF: 17.24,
    GB_MIETFL_GESAMT: 165.12,
    GB_MIETFL_1_BSB: 165.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989591,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.71,
    GB_NUTZFL_NF_VON_NGF: 14.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.71,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989592,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7096360,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7386453,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5068080,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701017,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/701017',
    GB_SAP_GB_ID_PKF: '7000/701017/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 153.8,
    GB_NUTZFL_NF_VON_NGF: 117.59,
    GB_TECHNIKFL_TF_VON_NGF: 3.62,
    GB_VERKEHRSFL_VF_VON_NGF: 32.59,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '14.02.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '14.02.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7496165,
    GB_WE_OBJ_ID_FKT: 1989012,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6736.67,
    GB_NUTZFL_NF_VON_NGF: 4964.03,
    GB_TECHNIKFL_TF_VON_NGF: 87.05,
    GB_VERKEHRSFL_VF_VON_NGF: 1685.59,
    GB_MIETFL_GESAMT: 6736.67,
    GB_MIETFL_1_BSB: 5589.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989546,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 447.43,
    GB_NUTZFL_NF_VON_NGF: 347.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 99.83,
    GB_MIETFL_GESAMT: 447.43,
    GB_MIETFL_1_BSB: 447.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989547,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.557924',
    GB_LAENGENGRAD_OESTLICH: '10.016574',
    GB_NETTOGRUNDFL_NGF: 730.82,
    GB_NUTZFL_NF_VON_NGF: 681.37,
    GB_TECHNIKFL_TF_VON_NGF: 17.41,
    GB_VERKEHRSFL_VF_VON_NGF: 32.04,
    GB_MIETFL_GESAMT: 730.82,
    GB_MIETFL_1_BSB: 730.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989548,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.557687',
    GB_LAENGENGRAD_OESTLICH: '10.015944',
    GB_NETTOGRUNDFL_NGF: 624.34,
    GB_NUTZFL_NF_VON_NGF: 583.92,
    GB_TECHNIKFL_TF_VON_NGF: 21.67,
    GB_VERKEHRSFL_VF_VON_NGF: 18.75,
    GB_MIETFL_GESAMT: 624.34,
    GB_MIETFL_1_BSB: 624.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989549,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 144.73,
    GB_NUTZFL_NF_VON_NGF: 108.89,
    GB_TECHNIKFL_TF_VON_NGF: 13.08,
    GB_VERKEHRSFL_VF_VON_NGF: 22.76,
    GB_MIETFL_GESAMT: 144.73,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989550,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 935.63,
    GB_NUTZFL_NF_VON_NGF: 715.15,
    GB_TECHNIKFL_TF_VON_NGF: 47.03,
    GB_VERKEHRSFL_VF_VON_NGF: 173.45,
    GB_MIETFL_GESAMT: 935.63,
    GB_MIETFL_1_BSB: 928.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5405363,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701011,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701011',
    GB_SAP_GB_ID_PKF: '7000/701011/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 116.81,
    GB_NUTZFL_NF_VON_NGF: 107.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352707,
    GB_WE_OBJ_ID_FKT: 1989006,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 7033443,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1919,
    GB_BREITENGRAD_NOERDLICH: '53.555624',
    GB_LAENGENGRAD_OESTLICH: '10.024662',
    GB_NETTOGRUNDFL_NGF: 6346.74,
    GB_NUTZFL_NF_VON_NGF: 4196.08,
    GB_TECHNIKFL_TF_VON_NGF: 422.8,
    GB_VERKEHRSFL_VF_VON_NGF: 1727.86,
    GB_MIETFL_GESAMT: 6346.74,
    GB_MIETFL_1_BSB: 5635.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989648,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2145.7,
    GB_NUTZFL_NF_VON_NGF: 1643.4,
    GB_TECHNIKFL_TF_VON_NGF: 36.3,
    GB_VERKEHRSFL_VF_VON_NGF: 466.0,
    GB_MIETFL_GESAMT: 2145.7,
    GB_MIETFL_1_BSB: 2145.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989649,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.15',
    GB_GB_OBJ_ID_PKT: 1989650,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 184.23,
    GB_NUTZFL_NF_VON_NGF: 148.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.92,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.15',
    GB_GB_OBJ_ID_PKT: 1989651,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 759.93,
    GB_NUTZFL_NF_VON_NGF: 640.98,
    GB_TECHNIKFL_TF_VON_NGF: 4.72,
    GB_VERKEHRSFL_VF_VON_NGF: 114.23,
    GB_MIETFL_GESAMT: 759.93,
    GB_MIETFL_1_BSB: 759.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5405364,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701026,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701026',
    GB_SAP_GB_ID_PKF: '7000/701026/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 175.17,
    GB_NUTZFL_NF_VON_NGF: 149.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.21',
    GB_GB_OBJ_ID_PKT: 5210885,
    GB_WE_OBJ_ID_FKT: 1989021,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5325079,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701078',
    GB_SAP_GB_ID_PKF: '7000/701078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1735.47,
    GB_NUTZFL_NF_VON_NGF: 1091.0,
    GB_TECHNIKFL_TF_VON_NGF: 37.79,
    GB_VERKEHRSFL_VF_VON_NGF: 606.68,
    GB_MIETFL_GESAMT: 1735.47,
    GB_MIETFL_1_BSB: 1674.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989948,
    GB_WE_OBJ_ID_FKT: 1989066,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1962.86,
    GB_NUTZFL_NF_VON_NGF: 1353.61,
    GB_TECHNIKFL_TF_VON_NGF: 70.27,
    GB_VERKEHRSFL_VF_VON_NGF: 538.98,
    GB_MIETFL_GESAMT: 1962.86,
    GB_MIETFL_1_BSB: 1654.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989487,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 351.24,
    GB_NUTZFL_NF_VON_NGF: 319.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.47,
    GB_MIETFL_GESAMT: 351.24,
    GB_MIETFL_1_BSB: 351.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989488,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1093.67,
    GB_NUTZFL_NF_VON_NGF: 921.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 171.83,
    GB_MIETFL_GESAMT: 1093.67,
    GB_MIETFL_1_BSB: 1093.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989489,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.16',
    GB_GB_OBJ_ID_PKT: 1989490,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1096.23,
    GB_NUTZFL_NF_VON_NGF: 924.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 171.86,
    GB_MIETFL_GESAMT: 1096.23,
    GB_MIETFL_1_BSB: 1096.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989491,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.547100',
    GB_LAENGENGRAD_OESTLICH: '9.958222',
    GB_NETTOGRUNDFL_NGF: 623.61,
    GB_NUTZFL_NF_VON_NGF: 588.41,
    GB_TECHNIKFL_TF_VON_NGF: 12.84,
    GB_VERKEHRSFL_VF_VON_NGF: 22.36,
    GB_MIETFL_GESAMT: 623.61,
    GB_MIETFL_1_BSB: 623.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989492,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 827.36,
    GB_NUTZFL_NF_VON_NGF: 654.48,
    GB_TECHNIKFL_TF_VON_NGF: 22.88,
    GB_VERKEHRSFL_VF_VON_NGF: 150.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.12.20',
    GB_GB_OBJ_ID_PKT: 1989493,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.68,
    GB_NUTZFL_NF_VON_NGF: 13.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '18.05.21',
    GB_GB_OBJ_ID_PKT: 1989494,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/9',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.546842559',
    GB_LAENGENGRAD_OESTLICH: '9.9580834211',
    GB_NETTOGRUNDFL_NGF: 1627.12,
    GB_NUTZFL_NF_VON_NGF: 1235.86,
    GB_TECHNIKFL_TF_VON_NGF: 7.93,
    GB_VERKEHRSFL_VF_VON_NGF: 383.33,
    GB_MIETFL_GESAMT: 1627.12,
    GB_MIETFL_1_BSB: 1616.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '03.03.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5922012,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701003,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/701003',
    GB_SAP_GB_ID_PKF: '7000/701003/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 322.16,
    GB_NUTZFL_NF_VON_NGF: 274.23,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 44.92,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7169935,
    GB_WE_OBJ_ID_FKT: 1988998,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701001',
    GB_SAP_GB_ID_PKF: '7000/701001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1878,
    GB_BREITENGRAD_NOERDLICH: '53.558216',
    GB_LAENGENGRAD_OESTLICH: '9.977081',
    GB_NETTOGRUNDFL_NGF: 6038.21,
    GB_NUTZFL_NF_VON_NGF: 3970.6,
    GB_TECHNIKFL_TF_VON_NGF: 72.09,
    GB_VERKEHRSFL_VF_VON_NGF: 1995.52,
    GB_MIETFL_GESAMT: 6038.21,
    GB_MIETFL_1_BSB: 5966.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989481,
    GB_WE_OBJ_ID_FKT: 1988996,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800307,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701085,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701085',
    GB_SAP_GB_ID_PKF: '7000/701085/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2009.62,
    GB_NUTZFL_NF_VON_NGF: 1413.61,
    GB_TECHNIKFL_TF_VON_NGF: 72.07,
    GB_VERKEHRSFL_VF_VON_NGF: 523.94,
    GB_MIETFL_GESAMT: 2009.62,
    GB_MIETFL_1_BSB: 1412.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5398770,
    GB_WE_OBJ_ID_FKT: 5398768,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701085,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701085',
    GB_SAP_GB_ID_PKF: '7000/701085/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1884,
    GB_BREITENGRAD_NOERDLICH: '53.559062',
    GB_LAENGENGRAD_OESTLICH: '9.970060',
    GB_NETTOGRUNDFL_NGF: 329.86,
    GB_NUTZFL_NF_VON_NGF: 300.34,
    GB_TECHNIKFL_TF_VON_NGF: 25.36,
    GB_VERKEHRSFL_VF_VON_NGF: 4.16,
    GB_MIETFL_GESAMT: 329.86,
    GB_MIETFL_1_BSB: 329.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5398771,
    GB_WE_OBJ_ID_FKT: 5398768,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701085,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701085',
    GB_SAP_GB_ID_PKF: '7000/701085/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 5398772,
    GB_WE_OBJ_ID_FKT: 5398768,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701085,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/701085',
    GB_SAP_GB_ID_PKF: '7000/701085/4',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 95.51,
    GB_NUTZFL_NF_VON_NGF: 74.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 21.46,
    GB_MIETFL_GESAMT: 95.51,
    GB_MIETFL_1_BSB: 95.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5459457,
    GB_WE_OBJ_ID_FKT: 5398768,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701079',
    GB_SAP_GB_ID_PKF: '7000/701079/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.546105',
    GB_LAENGENGRAD_OESTLICH: '9.956955',
    GB_NETTOGRUNDFL_NGF: 1342.66,
    GB_NUTZFL_NF_VON_NGF: 935.77,
    GB_TECHNIKFL_TF_VON_NGF: 81.56,
    GB_VERKEHRSFL_VF_VON_NGF: 325.33,
    GB_MIETFL_GESAMT: 1342.66,
    GB_MIETFL_1_BSB: 1342.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989949,
    GB_WE_OBJ_ID_FKT: 1989067,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 5891836,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701091,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701091',
    GB_SAP_GB_ID_PKF: '7000/701091/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7431590,
    GB_WE_OBJ_ID_FKT: 7430553,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701090,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701090',
    GB_SAP_GB_ID_PKF: '7000/701090/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7054149,
    GB_WE_OBJ_ID_FKT: 7054147,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.553864548',
    GB_LAENGENGRAD_OESTLICH: '10.043914401',
    GB_NETTOGRUNDFL_NGF: 580.95,
    GB_NUTZFL_NF_VON_NGF: 496.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 84.1,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236693,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.554103027',
    GB_LAENGENGRAD_OESTLICH: '10.044687101',
    GB_NETTOGRUNDFL_NGF: 880.76,
    GB_NUTZFL_NF_VON_NGF: 647.26,
    GB_TECHNIKFL_TF_VON_NGF: 3.92,
    GB_VERKEHRSFL_VF_VON_NGF: 229.58,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236694,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.553511340',
    GB_LAENGENGRAD_OESTLICH: '10.044579365',
    GB_NETTOGRUNDFL_NGF: 1531.19,
    GB_NUTZFL_NF_VON_NGF: 888.57,
    GB_TECHNIKFL_TF_VON_NGF: 80.61,
    GB_VERKEHRSFL_VF_VON_NGF: 562.01,
    GB_MIETFL_GESAMT: 74.63,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236695,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.553081644',
    GB_LAENGENGRAD_OESTLICH: '10.044300191',
    GB_NETTOGRUNDFL_NGF: 849.64,
    GB_NUTZFL_NF_VON_NGF: 781.27,
    GB_TECHNIKFL_TF_VON_NGF: 10.87,
    GB_VERKEHRSFL_VF_VON_NGF: 57.5,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236696,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.553852066',
    GB_LAENGENGRAD_OESTLICH: '10.044393734',
    GB_NETTOGRUNDFL_NGF: 309.99,
    GB_NUTZFL_NF_VON_NGF: 222.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.95,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236697,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/6',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.553332611',
    GB_LAENGENGRAD_OESTLICH: '10.043935523',
    GB_NETTOGRUNDFL_NGF: 171.49,
    GB_NUTZFL_NF_VON_NGF: 124.02,
    GB_TECHNIKFL_TF_VON_NGF: 34.02,
    GB_VERKEHRSFL_VF_VON_NGF: 13.45,
    GB_MIETFL_GESAMT: 35.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236698,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/7',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.553549848',
    GB_LAENGENGRAD_OESTLICH: '10.043892831',
    GB_NETTOGRUNDFL_NGF: 149.52,
    GB_NUTZFL_NF_VON_NGF: 74.02,
    GB_TECHNIKFL_TF_VON_NGF: 38.89,
    GB_VERKEHRSFL_VF_VON_NGF: 36.61,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236699,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/708018',
    GB_SAP_GB_ID_PKF: '7000/708018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236700,
    GB_WE_OBJ_ID_FKT: 7236658,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708003',
    GB_SAP_GB_ID_PKF: '7000/708003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1882,
    GB_BREITENGRAD_NOERDLICH: '53.560283514',
    GB_LAENGENGRAD_OESTLICH: '9.9753285315',
    GB_NETTOGRUNDFL_NGF: 1543.29,
    GB_NUTZFL_NF_VON_NGF: 1166.24,
    GB_TECHNIKFL_TF_VON_NGF: 62.74,
    GB_VERKEHRSFL_VF_VON_NGF: 314.31,
    GB_MIETFL_GESAMT: 292.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236667,
    GB_WE_OBJ_ID_FKT: 7236643,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 5448333,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708008',
    GB_SAP_GB_ID_PKF: '7000/708008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: '53.550648883',
    GB_LAENGENGRAD_OESTLICH: '9.9633154711',
    GB_NETTOGRUNDFL_NGF: 1781.0,
    GB_NUTZFL_NF_VON_NGF: 1781.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236673,
    GB_WE_OBJ_ID_FKT: 7236648,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708021',
    GB_SAP_GB_ID_PKF: '7000/708021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: '53.461457178',
    GB_LAENGENGRAD_OESTLICH: '10.060626457',
    GB_NETTOGRUNDFL_NGF: 2675.84,
    GB_NUTZFL_NF_VON_NGF: 2356.42,
    GB_TECHNIKFL_TF_VON_NGF: 29.05,
    GB_VERKEHRSFL_VF_VON_NGF: 290.37,
    GB_MIETFL_GESAMT: 837.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236713,
    GB_WE_OBJ_ID_FKT: 7236661,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708021',
    GB_SAP_GB_ID_PKF: '7000/708021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.461661',
    GB_LAENGENGRAD_OESTLICH: '10.060110',
    GB_NETTOGRUNDFL_NGF: 193.0,
    GB_NUTZFL_NF_VON_NGF: 185.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.83,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236714,
    GB_WE_OBJ_ID_FKT: 7236661,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708021',
    GB_SAP_GB_ID_PKF: '7000/708021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Lehrschwimmbecken',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lehrschwimmbecken',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: '53.462039117',
    GB_LAENGENGRAD_OESTLICH: '10.060882106',
    GB_NETTOGRUNDFL_NGF: 595.79,
    GB_NUTZFL_NF_VON_NGF: 537.73,
    GB_TECHNIKFL_TF_VON_NGF: 21.93,
    GB_VERKEHRSFL_VF_VON_NGF: 36.13,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236715,
    GB_WE_OBJ_ID_FKT: 7236661,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703068',
    GB_SAP_GB_ID_PKF: '7000/703068/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.560892',
    GB_LAENGENGRAD_OESTLICH: '10.022666',
    GB_NETTOGRUNDFL_NGF: 624.39,
    GB_NUTZFL_NF_VON_NGF: 583.91,
    GB_TECHNIKFL_TF_VON_NGF: 21.66,
    GB_VERKEHRSFL_VF_VON_NGF: 18.82,
    GB_MIETFL_GESAMT: 624.39,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 624.39,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6780852,
    GB_WE_OBJ_ID_FKT: 6780850,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 2141633,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4435.95,
    GB_NUTZFL_NF_VON_NGF: 3306.33,
    GB_TECHNIKFL_TF_VON_NGF: 2.04,
    GB_VERKEHRSFL_VF_VON_NGF: 1127.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991195,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991196,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 665.57,
    GB_NUTZFL_NF_VON_NGF: 625.4,
    GB_TECHNIKFL_TF_VON_NGF: 12.1,
    GB_VERKEHRSFL_VF_VON_NGF: 28.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991197,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1717.18,
    GB_NUTZFL_NF_VON_NGF: 1326.24,
    GB_TECHNIKFL_TF_VON_NGF: 37.77,
    GB_VERKEHRSFL_VF_VON_NGF: 353.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991198,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991199,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 48.18,
    GB_NUTZFL_NF_VON_NGF: 39.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.8,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991200,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1235.47,
    GB_NUTZFL_NF_VON_NGF: 913.08,
    GB_TECHNIKFL_TF_VON_NGF: 85.51,
    GB_VERKEHRSFL_VF_VON_NGF: 236.88,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991201,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1715.52,
    GB_NUTZFL_NF_VON_NGF: 1303.52,
    GB_TECHNIKFL_TF_VON_NGF: 2.74,
    GB_VERKEHRSFL_VF_VON_NGF: 409.26,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991202,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704063,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704063',
    GB_SAP_GB_ID_PKF: '7000/704063/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1576.73,
    GB_NUTZFL_NF_VON_NGF: 1418.14,
    GB_TECHNIKFL_TF_VON_NGF: 38.68,
    GB_VERKEHRSFL_VF_VON_NGF: 119.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991203,
    GB_WE_OBJ_ID_FKT: 1989260,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991212,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 270.78,
    GB_NUTZFL_NF_VON_NGF: 266.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 4.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991213,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991214,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.47,
    GB_NUTZFL_NF_VON_NGF: 161.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991215,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 180.02,
    GB_NUTZFL_NF_VON_NGF: 161.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.38,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991216,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.59,
    GB_NUTZFL_NF_VON_NGF: 161.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.41,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991217,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704068,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704068',
    GB_SAP_GB_ID_PKF: '7000/704068/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991218,
    GB_WE_OBJ_ID_FKT: 1989264,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704070',
    GB_SAP_GB_ID_PKF: '7000/704070/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10715.79,
    GB_NUTZFL_NF_VON_NGF: 6035.4,
    GB_TECHNIKFL_TF_VON_NGF: 165.92,
    GB_VERKEHRSFL_VF_VON_NGF: 4514.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991224,
    GB_WE_OBJ_ID_FKT: 1989266,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704069,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704069',
    GB_SAP_GB_ID_PKF: '7000/704069/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1988.64,
    GB_NUTZFL_NF_VON_NGF: 1515.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 472.78,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991219,
    GB_WE_OBJ_ID_FKT: 1989265,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704069,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704069',
    GB_SAP_GB_ID_PKF: '7000/704069/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.04,
    GB_NUTZFL_NF_VON_NGF: 407.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991220,
    GB_WE_OBJ_ID_FKT: 1989265,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704069,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704069',
    GB_SAP_GB_ID_PKF: '7000/704069/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991221,
    GB_WE_OBJ_ID_FKT: 1989265,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704069,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704069',
    GB_SAP_GB_ID_PKF: '7000/704069/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 625.68,
    GB_NUTZFL_NF_VON_NGF: 596.88,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 22.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.12',
    GB_GB_OBJ_ID_PKT: 1991222,
    GB_WE_OBJ_ID_FKT: 1989265,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704069,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704069',
    GB_SAP_GB_ID_PKF: '7000/704069/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2095.96,
    GB_NUTZFL_NF_VON_NGF: 1923.18,
    GB_TECHNIKFL_TF_VON_NGF: 9.18,
    GB_VERKEHRSFL_VF_VON_NGF: 163.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.12',
    GB_GB_OBJ_ID_PKT: 1991223,
    GB_WE_OBJ_ID_FKT: 1989265,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704077',
    GB_SAP_GB_ID_PKF: '7000/704077/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2921.49,
    GB_NUTZFL_NF_VON_NGF: 2062.39,
    GB_TECHNIKFL_TF_VON_NGF: 190.92,
    GB_VERKEHRSFL_VF_VON_NGF: 668.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991237,
    GB_WE_OBJ_ID_FKT: 1989272,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2612.43,
    GB_NUTZFL_NF_VON_NGF: 1857.75,
    GB_TECHNIKFL_TF_VON_NGF: 87.26,
    GB_VERKEHRSFL_VF_VON_NGF: 667.42,
    GB_MIETFL_GESAMT: 2612.43,
    GB_MIETFL_1_BSB: 2430.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991225,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 472.09,
    GB_NUTZFL_NF_VON_NGF: 437.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.4,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.11.16',
    GB_GB_OBJ_ID_PKT: 1991226,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.23',
    GB_GB_OBJ_ID_PKT: 1991227,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4389.47,
    GB_NUTZFL_NF_VON_NGF: 3452.5,
    GB_TECHNIKFL_TF_VON_NGF: 57.99,
    GB_VERKEHRSFL_VF_VON_NGF: 878.98,
    GB_MIETFL_GESAMT: 4389.47,
    GB_MIETFL_1_BSB: 4369.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991228,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6865.41,
    GB_NUTZFL_NF_VON_NGF: 5323.71,
    GB_TECHNIKFL_TF_VON_NGF: 537.7,
    GB_VERKEHRSFL_VF_VON_NGF: 1004.0,
    GB_MIETFL_GESAMT: 6865.41,
    GB_MIETFL_1_BSB: 6439.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991229,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704071,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704071',
    GB_SAP_GB_ID_PKF: '7000/704071/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3031.29,
    GB_NUTZFL_NF_VON_NGF: 2390.32,
    GB_TECHNIKFL_TF_VON_NGF: 158.34,
    GB_VERKEHRSFL_VF_VON_NGF: 482.63,
    GB_MIETFL_GESAMT: 3031.29,
    GB_MIETFL_1_BSB: 2993.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '04.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399923,
    GB_WE_OBJ_ID_FKT: 1989267,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704079',
    GB_SAP_GB_ID_PKF: '7000/704079/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1908,
    GB_BREITENGRAD_NOERDLICH: '53.572902',
    GB_LAENGENGRAD_OESTLICH: '10.049128',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.22',
    GB_GB_OBJ_ID_PKT: 1991240,
    GB_WE_OBJ_ID_FKT: 1989274,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704066',
    GB_SAP_GB_ID_PKF: '7000/704066/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1991206,
    GB_WE_OBJ_ID_FKT: 1989262,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704066,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704066',
    GB_SAP_GB_ID_PKF: '7000/704066/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1991207,
    GB_WE_OBJ_ID_FKT: 1989262,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704064',
    GB_SAP_GB_ID_PKF: '7000/704064/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7980.53,
    GB_NUTZFL_NF_VON_NGF: 5485.72,
    GB_TECHNIKFL_TF_VON_NGF: 308.49,
    GB_VERKEHRSFL_VF_VON_NGF: 2186.32,
    GB_MIETFL_GESAMT: 7980.53,
    GB_MIETFL_1_BSB: 7514.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991204,
    GB_WE_OBJ_ID_FKT: 1989261,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704064,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704064',
    GB_SAP_GB_ID_PKF: '7000/704064/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7169.84,
    GB_NUTZFL_NF_VON_NGF: 5362.41,
    GB_TECHNIKFL_TF_VON_NGF: 195.51,
    GB_VERKEHRSFL_VF_VON_NGF: 1611.92,
    GB_MIETFL_GESAMT: 7169.84,
    GB_MIETFL_1_BSB: 6731.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991205,
    GB_WE_OBJ_ID_FKT: 1989261,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 915.53,
    GB_NUTZFL_NF_VON_NGF: 763.28,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 152.25,
    GB_MIETFL_GESAMT: 915.53,
    GB_MIETFL_1_BSB: 738.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991208,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4373.03,
    GB_NUTZFL_NF_VON_NGF: 2861.77,
    GB_TECHNIKFL_TF_VON_NGF: 178.17,
    GB_VERKEHRSFL_VF_VON_NGF: 1333.09,
    GB_MIETFL_GESAMT: 4373.03,
    GB_MIETFL_1_BSB: 4057.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991209,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.609148',
    GB_LAENGENGRAD_OESTLICH: '10.026679',
    GB_NETTOGRUNDFL_NGF: 1725.06,
    GB_NUTZFL_NF_VON_NGF: 1630.85,
    GB_TECHNIKFL_TF_VON_NGF: 27.61,
    GB_VERKEHRSFL_VF_VON_NGF: 66.6,
    GB_MIETFL_GESAMT: 1725.06,
    GB_MIETFL_1_BSB: 1725.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991210,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.53,
    GB_NUTZFL_NF_VON_NGF: 16.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991211,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9546.0,
    GB_NUTZFL_NF_VON_NGF: 6259.94,
    GB_TECHNIKFL_TF_VON_NGF: 164.32,
    GB_VERKEHRSFL_VF_VON_NGF: 3121.74,
    GB_MIETFL_GESAMT: 9546.0,
    GB_MIETFL_1_BSB: 9505.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.04.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399922,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704067,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704067',
    GB_SAP_GB_ID_PKF: '7000/704067/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachklassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.01,
    GB_NUTZFL_NF_VON_NGF: 162.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 162.01,
    GB_MIETFL_1_BSB: 162.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6975783,
    GB_WE_OBJ_ID_FKT: 1989263,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 7258370,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1483.8,
    GB_NUTZFL_NF_VON_NGF: 1144.22,
    GB_TECHNIKFL_TF_VON_NGF: 30.29,
    GB_VERKEHRSFL_VF_VON_NGF: 309.29,
    GB_MIETFL_GESAMT: 1483.8,
    GB_MIETFL_1_BSB: 1470.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991045,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: '53.614120',
    GB_LAENGENGRAD_OESTLICH: '10.021056',
    GB_NETTOGRUNDFL_NGF: 1806.17,
    GB_NUTZFL_NF_VON_NGF: 904.43,
    GB_TECHNIKFL_TF_VON_NGF: 467.0,
    GB_VERKEHRSFL_VF_VON_NGF: 434.74,
    GB_MIETFL_GESAMT: 1806.17,
    GB_MIETFL_1_BSB: 1455.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991046,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 331.43,
    GB_NUTZFL_NF_VON_NGF: 251.84,
    GB_TECHNIKFL_TF_VON_NGF: 7.44,
    GB_VERKEHRSFL_VF_VON_NGF: 72.15,
    GB_MIETFL_GESAMT: 331.43,
    GB_MIETFL_1_BSB: 173.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991047,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 507.96,
    GB_NUTZFL_NF_VON_NGF: 361.11,
    GB_TECHNIKFL_TF_VON_NGF: 56.52,
    GB_VERKEHRSFL_VF_VON_NGF: 90.33,
    GB_MIETFL_GESAMT: 507.96,
    GB_MIETFL_1_BSB: 433.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991048,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.85,
    GB_NUTZFL_NF_VON_NGF: 366.69,
    GB_TECHNIKFL_TF_VON_NGF: 19.71,
    GB_VERKEHRSFL_VF_VON_NGF: 68.45,
    GB_MIETFL_GESAMT: 454.85,
    GB_MIETFL_1_BSB: 437.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991049,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 455.12,
    GB_NUTZFL_NF_VON_NGF: 368.89,
    GB_TECHNIKFL_TF_VON_NGF: 19.75,
    GB_VERKEHRSFL_VF_VON_NGF: 66.48,
    GB_MIETFL_GESAMT: 455.12,
    GB_MIETFL_1_BSB: 437.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991050,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 218.03,
    GB_NUTZFL_NF_VON_NGF: 177.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.29,
    GB_MIETFL_GESAMT: 218.03,
    GB_MIETFL_1_BSB: 218.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991051,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704041,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704041',
    GB_SAP_GB_ID_PKF: '7000/704041/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991052,
    GB_WE_OBJ_ID_FKT: 1989238,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 618.17,
    GB_NUTZFL_NF_VON_NGF: 462.94,
    GB_TECHNIKFL_TF_VON_NGF: 15.6,
    GB_VERKEHRSFL_VF_VON_NGF: 139.63,
    GB_MIETFL_GESAMT: 618.17,
    GB_MIETFL_1_BSB: 587.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991131,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1909.36,
    GB_NUTZFL_NF_VON_NGF: 1429.38,
    GB_TECHNIKFL_TF_VON_NGF: 95.27,
    GB_VERKEHRSFL_VF_VON_NGF: 384.71,
    GB_MIETFL_GESAMT: 1909.36,
    GB_MIETFL_1_BSB: 1566.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991132,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2081.4,
    GB_NUTZFL_NF_VON_NGF: 1636.35,
    GB_TECHNIKFL_TF_VON_NGF: 45.93,
    GB_VERKEHRSFL_VF_VON_NGF: 399.12,
    GB_MIETFL_GESAMT: 2081.4,
    GB_MIETFL_1_BSB: 2041.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991133,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.615069',
    GB_LAENGENGRAD_OESTLICH: '10.019904',
    GB_NETTOGRUNDFL_NGF: 595.16,
    GB_NUTZFL_NF_VON_NGF: 552.03,
    GB_TECHNIKFL_TF_VON_NGF: 33.68,
    GB_VERKEHRSFL_VF_VON_NGF: 9.45,
    GB_MIETFL_GESAMT: 595.16,
    GB_MIETFL_1_BSB: 595.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991134,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/5',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 443.45,
    GB_NUTZFL_NF_VON_NGF: 426.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.57,
    GB_MIETFL_GESAMT: 443.45,
    GB_MIETFL_1_BSB: 443.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991135,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 388.36,
    GB_NUTZFL_NF_VON_NGF: 378.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.91,
    GB_MIETFL_GESAMT: 388.36,
    GB_MIETFL_1_BSB: 388.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991136,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 50.98,
    GB_NUTZFL_NF_VON_NGF: 50.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 50.98,
    GB_MIETFL_1_BSB: 50.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991137,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704052,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704052',
    GB_SAP_GB_ID_PKF: '7000/704052/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 38.69,
    GB_NUTZFL_NF_VON_NGF: 36.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 1.98,
    GB_MIETFL_GESAMT: 38.69,
    GB_MIETFL_1_BSB: 38.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991138,
    GB_WE_OBJ_ID_FKT: 1989249,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1640.16,
    GB_NUTZFL_NF_VON_NGF: 1139.94,
    GB_TECHNIKFL_TF_VON_NGF: 172.62,
    GB_VERKEHRSFL_VF_VON_NGF: 327.6,
    GB_MIETFL_GESAMT: 1640.16,
    GB_MIETFL_1_BSB: 1379.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991006,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 534.74,
    GB_NUTZFL_NF_VON_NGF: 515.63,
    GB_TECHNIKFL_TF_VON_NGF: 5.62,
    GB_VERKEHRSFL_VF_VON_NGF: 13.49,
    GB_MIETFL_GESAMT: 534.74,
    GB_MIETFL_1_BSB: 534.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991007,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 857.39,
    GB_NUTZFL_NF_VON_NGF: 816.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.08,
    GB_MIETFL_GESAMT: 857.39,
    GB_MIETFL_1_BSB: 855.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991008,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.606365',
    GB_LAENGENGRAD_OESTLICH: '9.999597',
    GB_NETTOGRUNDFL_NGF: 1157.59,
    GB_NUTZFL_NF_VON_NGF: 1003.07,
    GB_TECHNIKFL_TF_VON_NGF: 23.41,
    GB_VERKEHRSFL_VF_VON_NGF: 131.11,
    GB_MIETFL_GESAMT: 1157.59,
    GB_MIETFL_1_BSB: 1080.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.01.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991009,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 633.22,
    GB_NUTZFL_NF_VON_NGF: 451.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 181.91,
    GB_MIETFL_GESAMT: 633.22,
    GB_MIETFL_1_BSB: 633.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991010,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 767.72,
    GB_NUTZFL_NF_VON_NGF: 572.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 194.86,
    GB_MIETFL_GESAMT: 767.72,
    GB_MIETFL_1_BSB: 767.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991011,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 793.5,
    GB_NUTZFL_NF_VON_NGF: 598.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 194.51,
    GB_MIETFL_GESAMT: 793.5,
    GB_MIETFL_1_BSB: 793.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991012,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/9',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 927.83,
    GB_NUTZFL_NF_VON_NGF: 709.21,
    GB_TECHNIKFL_TF_VON_NGF: 98.52,
    GB_VERKEHRSFL_VF_VON_NGF: 120.1,
    GB_MIETFL_GESAMT: 927.83,
    GB_MIETFL_1_BSB: 927.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991013,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 626.22,
    GB_NUTZFL_NF_VON_NGF: 507.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 118.83,
    GB_MIETFL_GESAMT: 626.22,
    GB_MIETFL_1_BSB: 626.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991014,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.02,
    GB_NUTZFL_NF_VON_NGF: 14.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991015,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1092.44,
    GB_NUTZFL_NF_VON_NGF: 946.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 145.69,
    GB_MIETFL_GESAMT: 1092.44,
    GB_MIETFL_1_BSB: 1075.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '26.05.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407001,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407002,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704034,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704034',
    GB_SAP_GB_ID_PKF: '7000/704034/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.04.18',
    GB_GB_OBJ_ID_PKT: 5356818,
    GB_WE_OBJ_ID_FKT: 1989231,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 2077131,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 392.99,
    GB_NUTZFL_NF_VON_NGF: 298.36,
    GB_TECHNIKFL_TF_VON_NGF: 35.68,
    GB_VERKEHRSFL_VF_VON_NGF: 58.95,
    GB_MIETFL_GESAMT: 392.99,
    GB_MIETFL_1_BSB: 290.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990878,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.601406',
    GB_LAENGENGRAD_OESTLICH: '10.047877',
    GB_NETTOGRUNDFL_NGF: 553.01,
    GB_NUTZFL_NF_VON_NGF: 529.52,
    GB_TECHNIKFL_TF_VON_NGF: 15.56,
    GB_VERKEHRSFL_VF_VON_NGF: 7.93,
    GB_MIETFL_GESAMT: 553.01,
    GB_MIETFL_1_BSB: 553.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990879,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 556.51,
    GB_NUTZFL_NF_VON_NGF: 459.63,
    GB_TECHNIKFL_TF_VON_NGF: 10.97,
    GB_VERKEHRSFL_VF_VON_NGF: 85.91,
    GB_MIETFL_GESAMT: 556.51,
    GB_MIETFL_1_BSB: 556.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990880,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 329.51,
    GB_NUTZFL_NF_VON_NGF: 307.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.31,
    GB_MIETFL_GESAMT: 329.51,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 329.51,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990881,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 247.33,
    GB_NUTZFL_NF_VON_NGF: 204.83,
    GB_TECHNIKFL_TF_VON_NGF: 8.88,
    GB_VERKEHRSFL_VF_VON_NGF: 33.62,
    GB_MIETFL_GESAMT: 247.33,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 247.33,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990882,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2043.8,
    GB_NUTZFL_NF_VON_NGF: 1366.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 677.44,
    GB_MIETFL_GESAMT: 2043.8,
    GB_MIETFL_1_BSB: 2043.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990883,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.602068',
    GB_LAENGENGRAD_OESTLICH: '10.046069',
    GB_NETTOGRUNDFL_NGF: 626.33,
    GB_NUTZFL_NF_VON_NGF: 586.38,
    GB_TECHNIKFL_TF_VON_NGF: 21.08,
    GB_VERKEHRSFL_VF_VON_NGF: 18.87,
    GB_MIETFL_GESAMT: 626.33,
    GB_MIETFL_1_BSB: 626.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990884,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.79,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 239.79,
    GB_MIETFL_GESAMT: 239.79,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 239.79,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990885,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 171.99,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 171.99,
    GB_MIETFL_GESAMT: 171.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 171.99,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990886,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2183.44,
    GB_NUTZFL_NF_VON_NGF: 1615.28,
    GB_TECHNIKFL_TF_VON_NGF: 31.21,
    GB_VERKEHRSFL_VF_VON_NGF: 536.95,
    GB_MIETFL_GESAMT: 2183.44,
    GB_MIETFL_1_BSB: 2115.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990887,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 256.41,
    GB_NUTZFL_NF_VON_NGF: 135.53,
    GB_TECHNIKFL_TF_VON_NGF: 8.21,
    GB_VERKEHRSFL_VF_VON_NGF: 112.67,
    GB_MIETFL_GESAMT: 256.41,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 256.41,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990888,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990889,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1539.12,
    GB_NUTZFL_NF_VON_NGF: 1120.71,
    GB_TECHNIKFL_TF_VON_NGF: 101.85,
    GB_VERKEHRSFL_VF_VON_NGF: 316.56,
    GB_MIETFL_GESAMT: 1539.12,
    GB_MIETFL_1_BSB: 1430.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.09.18',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990890,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704012,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704012',
    GB_SAP_GB_ID_PKF: '7000/704012/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990891,
    GB_WE_OBJ_ID_FKT: 1989209,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704081,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704081',
    GB_SAP_GB_ID_PKF: '7000/704081/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 580.0,
    GB_NUTZFL_NF_VON_NGF: 521.67,
    GB_TECHNIKFL_TF_VON_NGF: 4.41,
    GB_VERKEHRSFL_VF_VON_NGF: 53.92,
    GB_MIETFL_GESAMT: 580.0,
    GB_MIETFL_1_BSB: 561.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.04.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5324671,
    GB_WE_OBJ_ID_FKT: 5324670,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704010',
    GB_SAP_GB_ID_PKF: '7000/704010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: '53.598708',
    GB_LAENGENGRAD_OESTLICH: '10.037121',
    GB_NETTOGRUNDFL_NGF: 7375.71,
    GB_NUTZFL_NF_VON_NGF: 5169.36,
    GB_TECHNIKFL_TF_VON_NGF: 105.06,
    GB_VERKEHRSFL_VF_VON_NGF: 2101.29,
    GB_MIETFL_GESAMT: 7375.71,
    GB_MIETFL_1_BSB: 7197.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990875,
    GB_WE_OBJ_ID_FKT: 1989207,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704030',
    GB_SAP_GB_ID_PKF: '7000/704030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1913,
    GB_BREITENGRAD_NOERDLICH: '53.593491',
    GB_LAENGENGRAD_OESTLICH: '10.038914',
    GB_NETTOGRUNDFL_NGF: 6408.3,
    GB_NUTZFL_NF_VON_NGF: 4519.74,
    GB_TECHNIKFL_TF_VON_NGF: 173.09,
    GB_VERKEHRSFL_VF_VON_NGF: 1715.47,
    GB_MIETFL_GESAMT: 6408.3,
    GB_MIETFL_1_BSB: 4934.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990985,
    GB_WE_OBJ_ID_FKT: 1989227,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704042',
    GB_SAP_GB_ID_PKF: '7000/704042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.587506',
    GB_LAENGENGRAD_OESTLICH: '10.060451',
    GB_NETTOGRUNDFL_NGF: 6681.8,
    GB_NUTZFL_NF_VON_NGF: 4353.66,
    GB_TECHNIKFL_TF_VON_NGF: 107.84,
    GB_VERKEHRSFL_VF_VON_NGF: 2220.3,
    GB_MIETFL_GESAMT: 6681.8,
    GB_MIETFL_1_BSB: 6331.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.03.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991053,
    GB_WE_OBJ_ID_FKT: 1989239,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704042',
    GB_SAP_GB_ID_PKF: '7000/704042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 119.18,
    GB_NUTZFL_NF_VON_NGF: 105.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.72,
    GB_MIETFL_GESAMT: 119.18,
    GB_MIETFL_1_BSB: 119.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991054,
    GB_WE_OBJ_ID_FKT: 1989239,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704042',
    GB_SAP_GB_ID_PKF: '7000/704042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 73.12,
    GB_NUTZFL_NF_VON_NGF: 62.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.07,
    GB_MIETFL_GESAMT: 73.12,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991055,
    GB_WE_OBJ_ID_FKT: 1989239,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704042',
    GB_SAP_GB_ID_PKF: '7000/704042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1357.19,
    GB_NUTZFL_NF_VON_NGF: 1110.45,
    GB_TECHNIKFL_TF_VON_NGF: 6.19,
    GB_VERKEHRSFL_VF_VON_NGF: 240.55,
    GB_MIETFL_GESAMT: 1357.19,
    GB_MIETFL_1_BSB: 1357.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991056,
    GB_WE_OBJ_ID_FKT: 1989239,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 594.42,
    GB_NUTZFL_NF_VON_NGF: 524.04,
    GB_TECHNIKFL_TF_VON_NGF: 10.07,
    GB_VERKEHRSFL_VF_VON_NGF: 60.31,
    GB_MIETFL_GESAMT: 594.42,
    GB_MIETFL_1_BSB: 594.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991091,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704013',
    GB_SAP_GB_ID_PKF: '7000/704013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1929,
    GB_BREITENGRAD_NOERDLICH: '53.599235',
    GB_LAENGENGRAD_OESTLICH: '10.048765',
    GB_NETTOGRUNDFL_NGF: 6489.06,
    GB_NUTZFL_NF_VON_NGF: 4633.18,
    GB_TECHNIKFL_TF_VON_NGF: 102.53,
    GB_VERKEHRSFL_VF_VON_NGF: 1753.35,
    GB_MIETFL_GESAMT: 6489.06,
    GB_MIETFL_1_BSB: 5892.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990892,
    GB_WE_OBJ_ID_FKT: 1989210,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704013',
    GB_SAP_GB_ID_PKF: '7000/704013/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.598883',
    GB_LAENGENGRAD_OESTLICH: '10.047831',
    GB_NETTOGRUNDFL_NGF: 302.61,
    GB_NUTZFL_NF_VON_NGF: 285.15,
    GB_TECHNIKFL_TF_VON_NGF: 9.13,
    GB_VERKEHRSFL_VF_VON_NGF: 8.33,
    GB_MIETFL_GESAMT: 302.61,
    GB_MIETFL_1_BSB: 302.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990893,
    GB_WE_OBJ_ID_FKT: 1989210,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3157.43,
    GB_NUTZFL_NF_VON_NGF: 2194.52,
    GB_TECHNIKFL_TF_VON_NGF: 111.58,
    GB_VERKEHRSFL_VF_VON_NGF: 851.33,
    GB_MIETFL_GESAMT: 3157.43,
    GB_MIETFL_1_BSB: 3052.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991092,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1154.35,
    GB_NUTZFL_NF_VON_NGF: 930.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 223.7,
    GB_MIETFL_GESAMT: 1154.35,
    GB_MIETFL_1_BSB: 1154.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991093,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2318.76,
    GB_NUTZFL_NF_VON_NGF: 1735.02,
    GB_TECHNIKFL_TF_VON_NGF: 106.02,
    GB_VERKEHRSFL_VF_VON_NGF: 477.72,
    GB_MIETFL_GESAMT: 2318.76,
    GB_MIETFL_1_BSB: 2145.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991094,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: '53.600248',
    GB_LAENGENGRAD_OESTLICH: '10.043446',
    GB_NETTOGRUNDFL_NGF: 1851.32,
    GB_NUTZFL_NF_VON_NGF: 1774.13,
    GB_TECHNIKFL_TF_VON_NGF: 21.32,
    GB_VERKEHRSFL_VF_VON_NGF: 55.87,
    GB_MIETFL_GESAMT: 1851.32,
    GB_MIETFL_1_BSB: 1851.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991095,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.25,
    GB_NUTZFL_NF_VON_NGF: 87.73,
    GB_TECHNIKFL_TF_VON_NGF: 4.15,
    GB_VERKEHRSFL_VF_VON_NGF: 11.37,
    GB_MIETFL_GESAMT: 103.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991096,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.53,
    GB_NUTZFL_NF_VON_NGF: 15.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991097,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.53,
    GB_NUTZFL_NF_VON_NGF: 15.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991098,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.65,
    GB_NUTZFL_NF_VON_NGF: 14.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.65,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991099,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704047',
    GB_SAP_GB_ID_PKF: '7000/704047/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2077.6,
    GB_NUTZFL_NF_VON_NGF: 1728.19,
    GB_TECHNIKFL_TF_VON_NGF: 78.51,
    GB_VERKEHRSFL_VF_VON_NGF: 270.9,
    GB_MIETFL_GESAMT: 2077.6,
    GB_MIETFL_1_BSB: 2073.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '28.10.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407009,
    GB_WE_OBJ_ID_FKT: 1989244,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704011',
    GB_SAP_GB_ID_PKF: '7000/704011/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1914,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5759.45,
    GB_NUTZFL_NF_VON_NGF: 3979.71,
    GB_TECHNIKFL_TF_VON_NGF: 71.06,
    GB_VERKEHRSFL_VF_VON_NGF: 1708.68,
    GB_MIETFL_GESAMT: 5759.45,
    GB_MIETFL_1_BSB: 4811.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990876,
    GB_WE_OBJ_ID_FKT: 1989208,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704011',
    GB_SAP_GB_ID_PKF: '7000/704011/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1914,
    GB_BREITENGRAD_NOERDLICH: '53.592467',
    GB_LAENGENGRAD_OESTLICH: '10.051874',
    GB_NETTOGRUNDFL_NGF: 524.49,
    GB_NUTZFL_NF_VON_NGF: 462.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 61.72,
    GB_MIETFL_GESAMT: 524.49,
    GB_MIETFL_1_BSB: 524.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990877,
    GB_WE_OBJ_ID_FKT: 1989208,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 958.59,
    GB_NUTZFL_NF_VON_NGF: 786.33,
    GB_TECHNIKFL_TF_VON_NGF: 42.31,
    GB_VERKEHRSFL_VF_VON_NGF: 129.95,
    GB_MIETFL_GESAMT: 958.59,
    GB_MIETFL_1_BSB: 636.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990911,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 246.73,
    GB_NUTZFL_NF_VON_NGF: 66.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 180.58,
    GB_MIETFL_GESAMT: 246.73,
    GB_MIETFL_1_BSB: 246.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990912,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 697.22,
    GB_NUTZFL_NF_VON_NGF: 570.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 126.34,
    GB_MIETFL_GESAMT: 697.22,
    GB_MIETFL_1_BSB: 697.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990913,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.597535',
    GB_LAENGENGRAD_OESTLICH: '10.048627',
    GB_NETTOGRUNDFL_NGF: 301.95,
    GB_NUTZFL_NF_VON_NGF: 284.5,
    GB_TECHNIKFL_TF_VON_NGF: 9.01,
    GB_VERKEHRSFL_VF_VON_NGF: 8.44,
    GB_MIETFL_GESAMT: 301.95,
    GB_MIETFL_1_BSB: 301.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990914,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.24,
    GB_NUTZFL_NF_VON_NGF: 107.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.13,
    GB_MIETFL_GESAMT: 137.24,
    GB_MIETFL_1_BSB: 137.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990915,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990916,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704016,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704016',
    GB_SAP_GB_ID_PKF: '7000/704016/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990917,
    GB_WE_OBJ_ID_FKT: 1989213,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 843.36,
    GB_NUTZFL_NF_VON_NGF: 673.09,
    GB_TECHNIKFL_TF_VON_NGF: 46.08,
    GB_VERKEHRSFL_VF_VON_NGF: 124.19,
    GB_MIETFL_GESAMT: 843.36,
    GB_MIETFL_1_BSB: 796.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990813,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 720.57,
    GB_NUTZFL_NF_VON_NGF: 509.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 211.31,
    GB_MIETFL_GESAMT: 720.57,
    GB_MIETFL_1_BSB: 697.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990814,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.67,
    GB_NUTZFL_NF_VON_NGF: 124.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.9,
    GB_MIETFL_GESAMT: 137.67,
    GB_MIETFL_1_BSB: 137.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990815,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1523.49,
    GB_NUTZFL_NF_VON_NGF: 1215.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 308.09,
    GB_MIETFL_GESAMT: 1523.49,
    GB_MIETFL_1_BSB: 1429.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.03.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990816,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 863.53,
    GB_NUTZFL_NF_VON_NGF: 764.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 98.78,
    GB_MIETFL_GESAMT: 863.53,
    GB_MIETFL_1_BSB: 863.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.02.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990817,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.582363',
    GB_LAENGENGRAD_OESTLICH: '10.038900',
    GB_NETTOGRUNDFL_NGF: 617.2,
    GB_NUTZFL_NF_VON_NGF: 581.31,
    GB_TECHNIKFL_TF_VON_NGF: 13.48,
    GB_VERKEHRSFL_VF_VON_NGF: 22.41,
    GB_MIETFL_GESAMT: 617.2,
    GB_MIETFL_1_BSB: 617.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990818,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 129.19,
    GB_NUTZFL_NF_VON_NGF: 96.82,
    GB_TECHNIKFL_TF_VON_NGF: 14.33,
    GB_VERKEHRSFL_VF_VON_NGF: 18.04,
    GB_MIETFL_GESAMT: 129.19,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990819,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704002,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704002',
    GB_SAP_GB_ID_PKF: '7000/704002/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406990,
    GB_WE_OBJ_ID_FKT: 1989199,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704015',
    GB_SAP_GB_ID_PKF: '7000/704015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1788.19,
    GB_NUTZFL_NF_VON_NGF: 1394.31,
    GB_TECHNIKFL_TF_VON_NGF: 11.5,
    GB_VERKEHRSFL_VF_VON_NGF: 382.38,
    GB_MIETFL_GESAMT: 1788.19,
    GB_MIETFL_1_BSB: 1728.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990908,
    GB_WE_OBJ_ID_FKT: 1989212,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704015',
    GB_SAP_GB_ID_PKF: '7000/704015/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.582329',
    GB_LAENGENGRAD_OESTLICH: '10.038936',
    GB_NETTOGRUNDFL_NGF: 490.17,
    GB_NUTZFL_NF_VON_NGF: 458.05,
    GB_TECHNIKFL_TF_VON_NGF: 22.87,
    GB_VERKEHRSFL_VF_VON_NGF: 9.25,
    GB_MIETFL_GESAMT: 490.17,
    GB_MIETFL_1_BSB: 490.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990909,
    GB_WE_OBJ_ID_FKT: 1989212,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704015',
    GB_SAP_GB_ID_PKF: '7000/704015/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.7,
    GB_NUTZFL_NF_VON_NGF: 107.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.95,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1990910,
    GB_WE_OBJ_ID_FKT: 1989212,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 707.19,
    GB_NUTZFL_NF_VON_NGF: 574.33,
    GB_TECHNIKFL_TF_VON_NGF: 50.36,
    GB_VERKEHRSFL_VF_VON_NGF: 82.5,
    GB_MIETFL_GESAMT: 707.19,
    GB_MIETFL_1_BSB: 645.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991029,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1541.21,
    GB_NUTZFL_NF_VON_NGF: 1235.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 305.45,
    GB_MIETFL_GESAMT: 1541.21,
    GB_MIETFL_1_BSB: 1433.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991030,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 878.88,
    GB_NUTZFL_NF_VON_NGF: 770.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.6,
    GB_MIETFL_GESAMT: 878.88,
    GB_MIETFL_1_BSB: 878.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991031,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.573562',
    GB_LAENGENGRAD_OESTLICH: '10.026314',
    GB_NETTOGRUNDFL_NGF: 624.61,
    GB_NUTZFL_NF_VON_NGF: 598.51,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 19.41,
    GB_MIETFL_GESAMT: 624.61,
    GB_MIETFL_1_BSB: 624.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991032,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.33,
    GB_NUTZFL_NF_VON_NGF: 121.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.24,
    GB_MIETFL_GESAMT: 135.33,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991033,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704037,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704037',
    GB_SAP_GB_ID_PKF: '7000/704037/6',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.574090',
    GB_LAENGENGRAD_OESTLICH: '10.027428',
    GB_NETTOGRUNDFL_NGF: 2945.74,
    GB_NUTZFL_NF_VON_NGF: 2501.07,
    GB_TECHNIKFL_TF_VON_NGF: 4.39,
    GB_VERKEHRSFL_VF_VON_NGF: 440.28,
    GB_MIETFL_GESAMT: 2945.74,
    GB_MIETFL_1_BSB: 2936.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407005,
    GB_WE_OBJ_ID_FKT: 1989234,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704058',
    GB_SAP_GB_ID_PKF: '7000/704058/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1903,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4652.04,
    GB_NUTZFL_NF_VON_NGF: 3314.37,
    GB_TECHNIKFL_TF_VON_NGF: 65.23,
    GB_VERKEHRSFL_VF_VON_NGF: 1272.44,
    GB_MIETFL_GESAMT: 4652.04,
    GB_MIETFL_1_BSB: 3623.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991174,
    GB_WE_OBJ_ID_FKT: 1989255,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704058',
    GB_SAP_GB_ID_PKF: '7000/704058/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.91,
    GB_NUTZFL_NF_VON_NGF: 581.03,
    GB_TECHNIKFL_TF_VON_NGF: 30.0,
    GB_VERKEHRSFL_VF_VON_NGF: 220.88,
    GB_MIETFL_GESAMT: 831.91,
    GB_MIETFL_1_BSB: 831.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991175,
    GB_WE_OBJ_ID_FKT: 1989255,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704058,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704058',
    GB_SAP_GB_ID_PKF: '7000/704058/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.575984',
    GB_LAENGENGRAD_OESTLICH: '10.022013',
    GB_NETTOGRUNDFL_NGF: 383.5,
    GB_NUTZFL_NF_VON_NGF: 370.09,
    GB_TECHNIKFL_TF_VON_NGF: 6.55,
    GB_VERKEHRSFL_VF_VON_NGF: 6.86,
    GB_MIETFL_GESAMT: 383.5,
    GB_MIETFL_1_BSB: 383.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991176,
    GB_WE_OBJ_ID_FKT: 1989255,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704058,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704058',
    GB_SAP_GB_ID_PKF: '7000/704058/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7093588,
    GB_WE_OBJ_ID_FKT: 1989255,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704058,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704058',
    GB_SAP_GB_ID_PKF: '7000/704058/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 240.22,
    GB_NUTZFL_NF_VON_NGF: 222.84,
    GB_TECHNIKFL_TF_VON_NGF: 4.14,
    GB_VERKEHRSFL_VF_VON_NGF: 13.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.04.18',
    GB_GB_OBJ_ID_PKT: 5356820,
    GB_WE_OBJ_ID_FKT: 1989255,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704060',
    GB_SAP_GB_ID_PKF: '7000/704060/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1906,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4197.29,
    GB_NUTZFL_NF_VON_NGF: 3025.46,
    GB_TECHNIKFL_TF_VON_NGF: 91.02,
    GB_VERKEHRSFL_VF_VON_NGF: 1080.81,
    GB_MIETFL_GESAMT: 4197.29,
    GB_MIETFL_1_BSB: 2994.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.06.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991180,
    GB_WE_OBJ_ID_FKT: 1989257,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704060',
    GB_SAP_GB_ID_PKF: '7000/704060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.579669',
    GB_LAENGENGRAD_OESTLICH: '10.028431',
    GB_NETTOGRUNDFL_NGF: 1273.0,
    GB_NUTZFL_NF_VON_NGF: 976.04,
    GB_TECHNIKFL_TF_VON_NGF: 86.38,
    GB_VERKEHRSFL_VF_VON_NGF: 210.58,
    GB_MIETFL_GESAMT: 1273.0,
    GB_MIETFL_1_BSB: 1271.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991181,
    GB_WE_OBJ_ID_FKT: 1989257,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704060,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704060',
    GB_SAP_GB_ID_PKF: '7000/704060/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991182,
    GB_WE_OBJ_ID_FKT: 1989257,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704060,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704060',
    GB_SAP_GB_ID_PKF: '7000/704060/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 5445850,
    GB_WE_OBJ_ID_FKT: 1989257,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704032',
    GB_SAP_GB_ID_PKF: '7000/704032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5980.76,
    GB_NUTZFL_NF_VON_NGF: 4098.54,
    GB_TECHNIKFL_TF_VON_NGF: 113.74,
    GB_VERKEHRSFL_VF_VON_NGF: 1768.48,
    GB_MIETFL_GESAMT: 5980.76,
    GB_MIETFL_1_BSB: 5638.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990998,
    GB_WE_OBJ_ID_FKT: 1989229,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704032',
    GB_SAP_GB_ID_PKF: '7000/704032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.576065',
    GB_LAENGENGRAD_OESTLICH: '10.046677',
    GB_NETTOGRUNDFL_NGF: 660.0,
    GB_NUTZFL_NF_VON_NGF: 599.82,
    GB_TECHNIKFL_TF_VON_NGF: 15.53,
    GB_VERKEHRSFL_VF_VON_NGF: 44.65,
    GB_MIETFL_GESAMT: 660.0,
    GB_MIETFL_1_BSB: 660.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990999,
    GB_WE_OBJ_ID_FKT: 1989229,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704032',
    GB_SAP_GB_ID_PKF: '7000/704032/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042794,
    GB_WE_OBJ_ID_FKT: 1989229,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 384.78,
    GB_NUTZFL_NF_VON_NGF: 356.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.89,
    GB_MIETFL_GESAMT: 384.78,
    GB_MIETFL_1_BSB: 384.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990806,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.578427',
    GB_LAENGENGRAD_OESTLICH: '10.047101',
    GB_NETTOGRUNDFL_NGF: 670.29,
    GB_NUTZFL_NF_VON_NGF: 623.28,
    GB_TECHNIKFL_TF_VON_NGF: 17.16,
    GB_VERKEHRSFL_VF_VON_NGF: 29.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.06.23',
    GB_GB_OBJ_ID_PKT: 1990807,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1473.48,
    GB_NUTZFL_NF_VON_NGF: 1173.89,
    GB_TECHNIKFL_TF_VON_NGF: 79.35,
    GB_VERKEHRSFL_VF_VON_NGF: 220.24,
    GB_MIETFL_GESAMT: 1473.48,
    GB_MIETFL_1_BSB: 988.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990808,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 901.02,
    GB_NUTZFL_NF_VON_NGF: 738.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 162.61,
    GB_MIETFL_GESAMT: 901.02,
    GB_MIETFL_1_BSB: 901.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990809,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.28,
    GB_NUTZFL_NF_VON_NGF: 15.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990810,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 401.61,
    GB_NUTZFL_NF_VON_NGF: 379.31,
    GB_TECHNIKFL_TF_VON_NGF: 14.39,
    GB_VERKEHRSFL_VF_VON_NGF: 7.91,
    GB_MIETFL_GESAMT: 401.61,
    GB_MIETFL_1_BSB: 383.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990811,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.28,
    GB_NUTZFL_NF_VON_NGF: 15.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990812,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 288.73,
    GB_NUTZFL_NF_VON_NGF: 258.29,
    GB_TECHNIKFL_TF_VON_NGF: 7.13,
    GB_VERKEHRSFL_VF_VON_NGF: 23.31,
    GB_MIETFL_GESAMT: 288.73,
    GB_MIETFL_1_BSB: 288.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307973,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704001,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704001',
    GB_SAP_GB_ID_PKF: '7000/704001/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042789,
    GB_WE_OBJ_ID_FKT: 1989198,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: '53.587145',
    GB_LAENGENGRAD_OESTLICH: '10.069190',
    GB_NETTOGRUNDFL_NGF: 7342.93,
    GB_NUTZFL_NF_VON_NGF: 4858.96,
    GB_TECHNIKFL_TF_VON_NGF: 129.55,
    GB_VERKEHRSFL_VF_VON_NGF: 2354.42,
    GB_MIETFL_GESAMT: 7342.93,
    GB_MIETFL_1_BSB: 7135.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990845,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12456.93,
    GB_NUTZFL_NF_VON_NGF: 7833.96,
    GB_TECHNIKFL_TF_VON_NGF: 725.36,
    GB_VERKEHRSFL_VF_VON_NGF: 3897.61,
    GB_MIETFL_GESAMT: 12456.93,
    GB_MIETFL_1_BSB: 11784.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990846,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.586875',
    GB_LAENGENGRAD_OESTLICH: '10.068151',
    GB_NETTOGRUNDFL_NGF: 4869.63,
    GB_NUTZFL_NF_VON_NGF: 4478.94,
    GB_TECHNIKFL_TF_VON_NGF: 51.86,
    GB_VERKEHRSFL_VF_VON_NGF: 338.83,
    GB_MIETFL_GESAMT: 4869.63,
    GB_MIETFL_1_BSB: 4869.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990847,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.37,
    GB_NUTZFL_NF_VON_NGF: 166.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.36,
    GB_MIETFL_GESAMT: 207.37,
    GB_MIETFL_1_BSB: 181.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990848,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 838.99,
    GB_NUTZFL_NF_VON_NGF: 527.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 311.81,
    GB_MIETFL_GESAMT: 838.99,
    GB_MIETFL_1_BSB: 838.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990849,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 398.58,
    GB_NUTZFL_NF_VON_NGF: 368.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.69,
    GB_MIETFL_GESAMT: 398.58,
    GB_MIETFL_1_BSB: 398.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990850,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/7',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.586685',
    GB_LAENGENGRAD_OESTLICH: '10.070506',
    GB_NETTOGRUNDFL_NGF: 304.14,
    GB_NUTZFL_NF_VON_NGF: 286.63,
    GB_TECHNIKFL_TF_VON_NGF: 8.84,
    GB_VERKEHRSFL_VF_VON_NGF: 8.67,
    GB_MIETFL_GESAMT: 304.14,
    GB_MIETFL_1_BSB: 304.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990851,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.5,
    GB_NUTZFL_NF_VON_NGF: 334.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.7,
    GB_MIETFL_GESAMT: 406.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 270.69,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990852,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 180.52,
    GB_NUTZFL_NF_VON_NGF: 161.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.65,
    GB_MIETFL_GESAMT: 180.52,
    GB_MIETFL_1_BSB: 180.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990853,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 364.62,
    GB_NUTZFL_NF_VON_NGF: 364.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 364.62,
    GB_MIETFL_1_BSB: 364.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990854,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.49,
    GB_NUTZFL_NF_VON_NGF: 130.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.67,
    GB_MIETFL_GESAMT: 156.49,
    GB_MIETFL_1_BSB: 156.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990855,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 29.04,
    GB_NUTZFL_NF_VON_NGF: 29.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 29.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990856,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6890795,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704007,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704007',
    GB_SAP_GB_ID_PKF: '7000/704007/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 98.8,
    GB_NUTZFL_NF_VON_NGF: 78.73,
    GB_TECHNIKFL_TF_VON_NGF: 5.38,
    GB_VERKEHRSFL_VF_VON_NGF: 14.69,
    GB_MIETFL_GESAMT: 98.8,
    GB_MIETFL_1_BSB: 98.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462187,
    GB_WE_OBJ_ID_FKT: 1989204,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704009',
    GB_SAP_GB_ID_PKF: '7000/704009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1923,
    GB_BREITENGRAD_NOERDLICH: '53.580776',
    GB_LAENGENGRAD_OESTLICH: '10.057617',
    GB_NETTOGRUNDFL_NGF: 5324.69,
    GB_NUTZFL_NF_VON_NGF: 3738.72,
    GB_TECHNIKFL_TF_VON_NGF: 316.92,
    GB_VERKEHRSFL_VF_VON_NGF: 1269.05,
    GB_MIETFL_GESAMT: 5324.69,
    GB_MIETFL_1_BSB: 4915.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990870,
    GB_WE_OBJ_ID_FKT: 1989206,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704009',
    GB_SAP_GB_ID_PKF: '7000/704009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 491.94,
    GB_NUTZFL_NF_VON_NGF: 320.84,
    GB_TECHNIKFL_TF_VON_NGF: 2.47,
    GB_VERKEHRSFL_VF_VON_NGF: 168.63,
    GB_MIETFL_GESAMT: 491.94,
    GB_MIETFL_1_BSB: 491.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990871,
    GB_WE_OBJ_ID_FKT: 1989206,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704009',
    GB_SAP_GB_ID_PKF: '7000/704009/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1315.59,
    GB_NUTZFL_NF_VON_NGF: 995.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 320.48,
    GB_MIETFL_GESAMT: 1315.59,
    GB_MIETFL_1_BSB: 1085.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990872,
    GB_WE_OBJ_ID_FKT: 1989206,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704009',
    GB_SAP_GB_ID_PKF: '7000/704009/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990873,
    GB_WE_OBJ_ID_FKT: 1989206,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704009',
    GB_SAP_GB_ID_PKF: '7000/704009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.3,
    GB_NUTZFL_NF_VON_NGF: 16.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.3,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990874,
    GB_WE_OBJ_ID_FKT: 1989206,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704078',
    GB_SAP_GB_ID_PKF: '7000/704078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.582233',
    GB_LAENGENGRAD_OESTLICH: '10.059951',
    GB_NETTOGRUNDFL_NGF: 710.49,
    GB_NUTZFL_NF_VON_NGF: 674.29,
    GB_TECHNIKFL_TF_VON_NGF: 9.07,
    GB_VERKEHRSFL_VF_VON_NGF: 27.13,
    GB_MIETFL_GESAMT: 710.49,
    GB_MIETFL_1_BSB: 702.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991238,
    GB_WE_OBJ_ID_FKT: 1989273,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704078,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704078',
    GB_SAP_GB_ID_PKF: '7000/704078/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 24.22,
    GB_NUTZFL_NF_VON_NGF: 24.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 24.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991239,
    GB_WE_OBJ_ID_FKT: 1989273,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704078,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704078',
    GB_SAP_GB_ID_PKF: '7000/704078/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7032362,
    GB_WE_OBJ_ID_FKT: 1989273,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704059',
    GB_SAP_GB_ID_PKF: '7000/704059/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1899,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6353.38,
    GB_NUTZFL_NF_VON_NGF: 4445.98,
    GB_TECHNIKFL_TF_VON_NGF: 108.42,
    GB_VERKEHRSFL_VF_VON_NGF: 1798.98,
    GB_MIETFL_GESAMT: 6353.38,
    GB_MIETFL_1_BSB: 5175.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991177,
    GB_WE_OBJ_ID_FKT: 1989256,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704059,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704059',
    GB_SAP_GB_ID_PKF: '7000/704059/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 659.22,
    GB_NUTZFL_NF_VON_NGF: 614.6,
    GB_TECHNIKFL_TF_VON_NGF: 16.17,
    GB_VERKEHRSFL_VF_VON_NGF: 28.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991178,
    GB_WE_OBJ_ID_FKT: 1989256,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704059,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704059',
    GB_SAP_GB_ID_PKF: '7000/704059/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 47.34,
    GB_NUTZFL_NF_VON_NGF: 47.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 47.34,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991179,
    GB_WE_OBJ_ID_FKT: 1989256,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704059,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704059',
    GB_SAP_GB_ID_PKF: '7000/704059/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2080.23,
    GB_NUTZFL_NF_VON_NGF: 1730.56,
    GB_TECHNIKFL_TF_VON_NGF: 87.93,
    GB_VERKEHRSFL_VF_VON_NGF: 261.74,
    GB_MIETFL_GESAMT: 2080.23,
    GB_MIETFL_1_BSB: 2080.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407019,
    GB_WE_OBJ_ID_FKT: 1989256,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704020',
    GB_SAP_GB_ID_PKF: '7000/704020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: '53.585043',
    GB_LAENGENGRAD_OESTLICH: '9.984570',
    GB_NETTOGRUNDFL_NGF: 7165.58,
    GB_NUTZFL_NF_VON_NGF: 5126.32,
    GB_TECHNIKFL_TF_VON_NGF: 100.97,
    GB_VERKEHRSFL_VF_VON_NGF: 1938.29,
    GB_MIETFL_GESAMT: 7165.58,
    GB_MIETFL_1_BSB: 6955.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990938,
    GB_WE_OBJ_ID_FKT: 1989217,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704020',
    GB_SAP_GB_ID_PKF: '7000/704020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 484.76,
    GB_NUTZFL_NF_VON_NGF: 336.95,
    GB_TECHNIKFL_TF_VON_NGF: 3.86,
    GB_VERKEHRSFL_VF_VON_NGF: 143.95,
    GB_MIETFL_GESAMT: 484.76,
    GB_MIETFL_1_BSB: 484.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.02',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990939,
    GB_WE_OBJ_ID_FKT: 1989217,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704020',
    GB_SAP_GB_ID_PKF: '7000/704020/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 801.66,
    GB_NUTZFL_NF_VON_NGF: 493.69,
    GB_TECHNIKFL_TF_VON_NGF: 6.88,
    GB_VERKEHRSFL_VF_VON_NGF: 301.09,
    GB_MIETFL_GESAMT: 801.66,
    GB_MIETFL_1_BSB: 799.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406993,
    GB_WE_OBJ_ID_FKT: 1989217,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704020,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704020',
    GB_SAP_GB_ID_PKF: '7000/704020/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet _11_16',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.0,
    GB_NUTZFL_NF_VON_NGF: 167.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352743,
    GB_WE_OBJ_ID_FKT: 1989217,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704039',
    GB_SAP_GB_ID_PKF: '7000/704039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1600.55,
    GB_NUTZFL_NF_VON_NGF: 1213.22,
    GB_TECHNIKFL_TF_VON_NGF: 27.56,
    GB_VERKEHRSFL_VF_VON_NGF: 359.77,
    GB_MIETFL_GESAMT: 1600.55,
    GB_MIETFL_1_BSB: 1187.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991035,
    GB_WE_OBJ_ID_FKT: 1989236,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704039',
    GB_SAP_GB_ID_PKF: '7000/704039/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.590327',
    GB_LAENGENGRAD_OESTLICH: '9.991033',
    GB_NETTOGRUNDFL_NGF: 322.05,
    GB_NUTZFL_NF_VON_NGF: 322.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 322.05,
    GB_MIETFL_1_BSB: 322.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991036,
    GB_WE_OBJ_ID_FKT: 1989236,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704039',
    GB_SAP_GB_ID_PKF: '7000/704039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1667.07,
    GB_NUTZFL_NF_VON_NGF: 1120.09,
    GB_TECHNIKFL_TF_VON_NGF: 85.35,
    GB_VERKEHRSFL_VF_VON_NGF: 461.63,
    GB_MIETFL_GESAMT: 1667.07,
    GB_MIETFL_1_BSB: 1570.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2073502,
    GB_WE_OBJ_ID_FKT: 1989236,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704039',
    GB_SAP_GB_ID_PKF: '7000/704039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3257.98,
    GB_NUTZFL_NF_VON_NGF: 2365.64,
    GB_TECHNIKFL_TF_VON_NGF: 92.25,
    GB_VERKEHRSFL_VF_VON_NGF: 800.09,
    GB_MIETFL_GESAMT: 3257.98,
    GB_MIETFL_1_BSB: 3158.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407006,
    GB_WE_OBJ_ID_FKT: 1989236,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704039',
    GB_SAP_GB_ID_PKF: '7000/704039/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 346.2,
    GB_NUTZFL_NF_VON_NGF: 299.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.8,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.18',
    GB_GUELTIG_BIS: '28.08.18',
    GB_GB_OBJ_ID_PKT: 5510099,
    GB_WE_OBJ_ID_FKT: 1989236,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704075,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704075',
    GB_SAP_GB_ID_PKF: '7000/704075/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.587680',
    GB_LAENGENGRAD_OESTLICH: '9.990043',
    GB_NETTOGRUNDFL_NGF: 2391.99,
    GB_NUTZFL_NF_VON_NGF: 1876.61,
    GB_TECHNIKFL_TF_VON_NGF: 38.28,
    GB_VERKEHRSFL_VF_VON_NGF: 477.1,
    GB_MIETFL_GESAMT: 2391.99,
    GB_MIETFL_1_BSB: 2390.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991236,
    GB_WE_OBJ_ID_FKT: 1989270,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1326.58,
    GB_NUTZFL_NF_VON_NGF: 1091.14,
    GB_TECHNIKFL_TF_VON_NGF: 43.0,
    GB_VERKEHRSFL_VF_VON_NGF: 192.44,
    GB_MIETFL_GESAMT: 1326.58,
    GB_MIETFL_1_BSB: 1177.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991139,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 780.3,
    GB_NUTZFL_NF_VON_NGF: 671.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.82,
    GB_MIETFL_GESAMT: 780.3,
    GB_MIETFL_1_BSB: 780.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991140,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 355.05,
    GB_NUTZFL_NF_VON_NGF: 355.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 355.05,
    GB_MIETFL_1_BSB: 355.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991141,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.588157',
    GB_LAENGENGRAD_OESTLICH: '9.983367',
    GB_NETTOGRUNDFL_NGF: 376.1,
    GB_NUTZFL_NF_VON_NGF: 314.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 61.35,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '05.07.18',
    GB_GB_OBJ_ID_PKT: 1991142,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407013,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mischgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mischgeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2859.64,
    GB_NUTZFL_NF_VON_NGF: 2266.6,
    GB_TECHNIKFL_TF_VON_NGF: 75.15,
    GB_VERKEHRSFL_VF_VON_NGF: 517.89,
    GB_MIETFL_GESAMT: 2859.64,
    GB_MIETFL_1_BSB: 2849.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '25.06.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407014,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704053,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704053',
    GB_SAP_GB_ID_PKF: '7000/704053/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 252.88,
    GB_NUTZFL_NF_VON_NGF: 228.53,
    GB_TECHNIKFL_TF_VON_NGF: 3.2,
    GB_VERKEHRSFL_VF_VON_NGF: 21.15,
    GB_MIETFL_GESAMT: 252.88,
    GB_MIETFL_1_BSB: 252.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5356819,
    GB_WE_OBJ_ID_FKT: 1989250,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704048',
    GB_SAP_GB_ID_PKF: '7000/704048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5191.2,
    GB_NUTZFL_NF_VON_NGF: 3422.34,
    GB_TECHNIKFL_TF_VON_NGF: 134.24,
    GB_VERKEHRSFL_VF_VON_NGF: 1634.62,
    GB_MIETFL_GESAMT: 5191.2,
    GB_MIETFL_1_BSB: 5024.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991100,
    GB_WE_OBJ_ID_FKT: 1989245,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5067.75,
    GB_NUTZFL_NF_VON_NGF: 3824.6,
    GB_TECHNIKFL_TF_VON_NGF: 79.48,
    GB_VERKEHRSFL_VF_VON_NGF: 1163.67,
    GB_MIETFL_GESAMT: 5067.75,
    GB_MIETFL_1_BSB: 3284.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990945,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 187.92,
    GB_NUTZFL_NF_VON_NGF: 167.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.74,
    GB_MIETFL_GESAMT: 187.92,
    GB_MIETFL_1_BSB: 187.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990946,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.625436',
    GB_LAENGENGRAD_OESTLICH: '10.015193',
    GB_NETTOGRUNDFL_NGF: 618.83,
    GB_NUTZFL_NF_VON_NGF: 587.76,
    GB_TECHNIKFL_TF_VON_NGF: 21.51,
    GB_VERKEHRSFL_VF_VON_NGF: 9.56,
    GB_MIETFL_GESAMT: 618.83,
    GB_MIETFL_1_BSB: 618.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990947,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704048',
    GB_SAP_GB_ID_PKF: '7000/704048/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.592111',
    GB_LAENGENGRAD_OESTLICH: '9.984879',
    GB_NETTOGRUNDFL_NGF: 508.07,
    GB_NUTZFL_NF_VON_NGF: 474.77,
    GB_TECHNIKFL_TF_VON_NGF: 18.71,
    GB_VERKEHRSFL_VF_VON_NGF: 14.59,
    GB_MIETFL_GESAMT: 508.07,
    GB_MIETFL_1_BSB: 508.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.10.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991101,
    GB_WE_OBJ_ID_FKT: 1989245,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704006',
    GB_SAP_GB_ID_PKF: '7000/704006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.632290',
    GB_LAENGENGRAD_OESTLICH: '10.018948',
    GB_NETTOGRUNDFL_NGF: 6348.88,
    GB_NUTZFL_NF_VON_NGF: 4326.36,
    GB_TECHNIKFL_TF_VON_NGF: 130.33,
    GB_VERKEHRSFL_VF_VON_NGF: 1892.19,
    GB_MIETFL_GESAMT: 6348.88,
    GB_MIETFL_1_BSB: 6223.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.06.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990844,
    GB_WE_OBJ_ID_FKT: 1989203,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704006',
    GB_SAP_GB_ID_PKF: '7000/704006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.632848',
    GB_LAENGENGRAD_OESTLICH: '10.017162',
    GB_NETTOGRUNDFL_NGF: 1772.25,
    GB_NUTZFL_NF_VON_NGF: 1465.04,
    GB_TECHNIKFL_TF_VON_NGF: 29.08,
    GB_VERKEHRSFL_VF_VON_NGF: 278.13,
    GB_MIETFL_GESAMT: 1772.25,
    GB_MIETFL_1_BSB: 1766.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.02.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5379554,
    GB_WE_OBJ_ID_FKT: 1989203,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.92,
    GB_NUTZFL_NF_VON_NGF: 10.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.92,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990948,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.92,
    GB_NUTZFL_NF_VON_NGF: 10.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.92,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990949,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406994,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704023,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704023',
    GB_SAP_GB_ID_PKF: '7000/704023/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042792,
    GB_WE_OBJ_ID_FKT: 1989220,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 709.83,
    GB_NUTZFL_NF_VON_NGF: 535.33,
    GB_TECHNIKFL_TF_VON_NGF: 113.48,
    GB_VERKEHRSFL_VF_VON_NGF: 61.02,
    GB_MIETFL_GESAMT: 709.83,
    GB_MIETFL_1_BSB: 453.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991117,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 850.12,
    GB_NUTZFL_NF_VON_NGF: 743.77,
    GB_TECHNIKFL_TF_VON_NGF: 41.61,
    GB_VERKEHRSFL_VF_VON_NGF: 64.74,
    GB_MIETFL_GESAMT: 850.12,
    GB_MIETFL_1_BSB: 850.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991118,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.4,
    GB_NUTZFL_NF_VON_NGF: 181.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.77,
    GB_MIETFL_GESAMT: 192.4,
    GB_MIETFL_1_BSB: 192.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991119,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 986.12,
    GB_NUTZFL_NF_VON_NGF: 889.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 96.64,
    GB_MIETFL_GESAMT: 986.12,
    GB_MIETFL_1_BSB: 986.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991120,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 273.16,
    GB_NUTZFL_NF_VON_NGF: 262.96,
    GB_TECHNIKFL_TF_VON_NGF: 1.78,
    GB_VERKEHRSFL_VF_VON_NGF: 8.42,
    GB_MIETFL_GESAMT: 273.16,
    GB_MIETFL_1_BSB: 273.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991121,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1514.81,
    GB_NUTZFL_NF_VON_NGF: 1113.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 401.69,
    GB_MIETFL_GESAMT: 1514.81,
    GB_MIETFL_1_BSB: 1514.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991122,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.636084',
    GB_LAENGENGRAD_OESTLICH: '10.021961',
    GB_NETTOGRUNDFL_NGF: 625.0,
    GB_NUTZFL_NF_VON_NGF: 581.23,
    GB_TECHNIKFL_TF_VON_NGF: 24.11,
    GB_VERKEHRSFL_VF_VON_NGF: 19.66,
    GB_MIETFL_GESAMT: 625.0,
    GB_MIETFL_1_BSB: 625.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991123,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.86,
    GB_NUTZFL_NF_VON_NGF: 13.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.86,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991124,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 671.54,
    GB_NUTZFL_NF_VON_NGF: 629.4,
    GB_TECHNIKFL_TF_VON_NGF: 12.36,
    GB_VERKEHRSFL_VF_VON_NGF: 29.78,
    GB_MIETFL_GESAMT: 671.54,
    GB_MIETFL_1_BSB: 671.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5408253,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6912834,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 158.38,
    GB_NUTZFL_NF_VON_NGF: 146.61,
    GB_TECHNIKFL_TF_VON_NGF: 2.67,
    GB_VERKEHRSFL_VF_VON_NGF: 9.1,
    GB_MIETFL_GESAMT: 158.38,
    GB_MIETFL_1_BSB: 158.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6912835,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704050,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704050',
    GB_SAP_GB_ID_PKF: '7000/704050/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.11.18',
    GB_GB_OBJ_ID_PKT: 6979929,
    GB_WE_OBJ_ID_FKT: 1989247,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 474.17,
    GB_NUTZFL_NF_VON_NGF: 343.48,
    GB_TECHNIKFL_TF_VON_NGF: 53.16,
    GB_VERKEHRSFL_VF_VON_NGF: 77.53,
    GB_MIETFL_GESAMT: 474.17,
    GB_MIETFL_1_BSB: 343.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990925,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1273.75,
    GB_NUTZFL_NF_VON_NGF: 1042.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 231.2,
    GB_MIETFL_GESAMT: 1273.75,
    GB_MIETFL_1_BSB: 1273.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990926,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 781.74,
    GB_NUTZFL_NF_VON_NGF: 609.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 172.06,
    GB_MIETFL_GESAMT: 781.74,
    GB_MIETFL_1_BSB: 777.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990927,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 303.12,
    GB_NUTZFL_NF_VON_NGF: 257.72,
    GB_TECHNIKFL_TF_VON_NGF: 13.88,
    GB_VERKEHRSFL_VF_VON_NGF: 31.52,
    GB_MIETFL_GESAMT: 303.12,
    GB_MIETFL_1_BSB: 303.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990928,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.605731',
    GB_LAENGENGRAD_OESTLICH: '9.976387',
    GB_NETTOGRUNDFL_NGF: 625.5,
    GB_NUTZFL_NF_VON_NGF: 584.19,
    GB_TECHNIKFL_TF_VON_NGF: 22.38,
    GB_VERKEHRSFL_VF_VON_NGF: 18.93,
    GB_MIETFL_GESAMT: 625.5,
    GB_MIETFL_1_BSB: 625.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990929,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 280.73,
    GB_NUTZFL_NF_VON_NGF: 31.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 249.0,
    GB_MIETFL_GESAMT: 280.73,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990930,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 870.31,
    GB_NUTZFL_NF_VON_NGF: 701.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 168.99,
    GB_MIETFL_GESAMT: 870.31,
    GB_MIETFL_1_BSB: 870.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2073501,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5457433,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7030302,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042790,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704018,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704018',
    GB_SAP_GB_ID_PKF: '7000/704018/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 185.97,
    GB_NUTZFL_NF_VON_NGF: 146.81,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 35.53,
    GB_MIETFL_GESAMT: 160.77,
    GB_MIETFL_1_BSB: 160.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365063,
    GB_WE_OBJ_ID_FKT: 1989215,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1898,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 696.65,
    GB_NUTZFL_NF_VON_NGF: 577.66,
    GB_TECHNIKFL_TF_VON_NGF: 6.77,
    GB_VERKEHRSFL_VF_VON_NGF: 112.22,
    GB_MIETFL_GESAMT: 696.65,
    GB_MIETFL_1_BSB: 694.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991082,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991083,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1037.53,
    GB_NUTZFL_NF_VON_NGF: 777.77,
    GB_TECHNIKFL_TF_VON_NGF: 55.95,
    GB_VERKEHRSFL_VF_VON_NGF: 203.81,
    GB_MIETFL_GESAMT: 1037.53,
    GB_MIETFL_1_BSB: 1035.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991084,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 806.23,
    GB_NUTZFL_NF_VON_NGF: 597.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 208.4,
    GB_MIETFL_GESAMT: 806.23,
    GB_MIETFL_1_BSB: 803.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.05.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991085,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.607466',
    GB_LAENGENGRAD_OESTLICH: '9.971537',
    GB_NETTOGRUNDFL_NGF: 527.4,
    GB_NUTZFL_NF_VON_NGF: 449.75,
    GB_TECHNIKFL_TF_VON_NGF: 18.64,
    GB_VERKEHRSFL_VF_VON_NGF: 59.01,
    GB_MIETFL_GESAMT: 527.4,
    GB_MIETFL_1_BSB: 527.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.05.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991086,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.02,
    GB_NUTZFL_NF_VON_NGF: 87.79,
    GB_TECHNIKFL_TF_VON_NGF: 3.95,
    GB_VERKEHRSFL_VF_VON_NGF: 11.28,
    GB_MIETFL_GESAMT: 103.02,
    GB_MIETFL_1_BSB: 103.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.04.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991087,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991088,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.87,
    GB_NUTZFL_NF_VON_NGF: 32.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 32.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991089,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.79,
    GB_NUTZFL_NF_VON_NGF: 16.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.18',
    GB_GB_OBJ_ID_PKT: 1991090,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.89,
    GB_NUTZFL_NF_VON_NGF: 17.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.89,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138309,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/11',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 58.1,
    GB_NUTZFL_NF_VON_NGF: 58.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 58.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138310,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 613.29,
    GB_NUTZFL_NF_VON_NGF: 445.3,
    GB_TECHNIKFL_TF_VON_NGF: 18.17,
    GB_VERKEHRSFL_VF_VON_NGF: 149.82,
    GB_MIETFL_GESAMT: 613.29,
    GB_MIETFL_1_BSB: 610.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5408252,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704046,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704046',
    GB_SAP_GB_ID_PKF: '7000/704046/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.79,
    GB_NUTZFL_NF_VON_NGF: 155.25,
    GB_TECHNIKFL_TF_VON_NGF: 4.65,
    GB_VERKEHRSFL_VF_VON_NGF: 19.89,
    GB_MIETFL_GESAMT: 179.79,
    GB_MIETFL_1_BSB: 179.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462188,
    GB_WE_OBJ_ID_FKT: 1989243,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704022',
    GB_SAP_GB_ID_PKF: '7000/704022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.587503',
    GB_LAENGENGRAD_OESTLICH: '9.976018',
    GB_NETTOGRUNDFL_NGF: 6707.54,
    GB_NUTZFL_NF_VON_NGF: 4582.35,
    GB_TECHNIKFL_TF_VON_NGF: 98.51,
    GB_VERKEHRSFL_VF_VON_NGF: 2026.68,
    GB_MIETFL_GESAMT: 6707.54,
    GB_MIETFL_1_BSB: 5506.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990944,
    GB_WE_OBJ_ID_FKT: 1989219,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1907,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7477.05,
    GB_NUTZFL_NF_VON_NGF: 5024.93,
    GB_TECHNIKFL_TF_VON_NGF: 105.95,
    GB_VERKEHRSFL_VF_VON_NGF: 2346.17,
    GB_MIETFL_GESAMT: 7477.05,
    GB_MIETFL_1_BSB: 6469.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990940,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990941,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 354.88,
    GB_NUTZFL_NF_VON_NGF: 300.48,
    GB_TECHNIKFL_TF_VON_NGF: 12.56,
    GB_VERKEHRSFL_VF_VON_NGF: 41.84,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1990942,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.587425',
    GB_LAENGENGRAD_OESTLICH: '9.973698',
    GB_NETTOGRUNDFL_NGF: 988.81,
    GB_NUTZFL_NF_VON_NGF: 955.43,
    GB_TECHNIKFL_TF_VON_NGF: 22.69,
    GB_VERKEHRSFL_VF_VON_NGF: 10.69,
    GB_MIETFL_GESAMT: 988.81,
    GB_MIETFL_1_BSB: 555.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990943,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 720.48,
    GB_NUTZFL_NF_VON_NGF: 632.08,
    GB_TECHNIKFL_TF_VON_NGF: 28.74,
    GB_VERKEHRSFL_VF_VON_NGF: 59.66,
    GB_MIETFL_GESAMT: 720.48,
    GB_MIETFL_1_BSB: 720.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.14',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5374002,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.88,
    GB_NUTZFL_NF_VON_NGF: 301.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.20',
    GB_GB_OBJ_ID_PKT: 4352744,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704021,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704021',
    GB_SAP_GB_ID_PKF: '7000/704021/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.20',
    GB_GB_OBJ_ID_PKT: 4352745,
    GB_WE_OBJ_ID_FKT: 1989218,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704024',
    GB_SAP_GB_ID_PKF: '7000/704024/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1512.98,
    GB_NUTZFL_NF_VON_NGF: 1176.92,
    GB_TECHNIKFL_TF_VON_NGF: 57.85,
    GB_VERKEHRSFL_VF_VON_NGF: 278.21,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.19',
    GB_GB_OBJ_ID_PKT: 1990950,
    GB_WE_OBJ_ID_FKT: 1989221,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704024',
    GB_SAP_GB_ID_PKF: '7000/704024/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2684.12,
    GB_NUTZFL_NF_VON_NGF: 2298.4,
    GB_TECHNIKFL_TF_VON_NGF: 77.76,
    GB_VERKEHRSFL_VF_VON_NGF: 307.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.19',
    GB_GB_OBJ_ID_PKT: 1990951,
    GB_WE_OBJ_ID_FKT: 1989221,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704024',
    GB_SAP_GB_ID_PKF: '7000/704024/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.641610',
    GB_LAENGENGRAD_OESTLICH: '10.022772',
    GB_NETTOGRUNDFL_NGF: 656.18,
    GB_NUTZFL_NF_VON_NGF: 611.3,
    GB_TECHNIKFL_TF_VON_NGF: 16.54,
    GB_VERKEHRSFL_VF_VON_NGF: 28.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.19',
    GB_GB_OBJ_ID_PKT: 1990952,
    GB_WE_OBJ_ID_FKT: 1989221,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704024,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704024',
    GB_SAP_GB_ID_PKF: '7000/704024/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1990953,
    GB_WE_OBJ_ID_FKT: 1989221,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 756.77,
    GB_NUTZFL_NF_VON_NGF: 606.01,
    GB_TECHNIKFL_TF_VON_NGF: 37.44,
    GB_VERKEHRSFL_VF_VON_NGF: 113.32,
    GB_MIETFL_GESAMT: 756.77,
    GB_MIETFL_1_BSB: 579.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990931,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 612.6,
    GB_NUTZFL_NF_VON_NGF: 505.78,
    GB_TECHNIKFL_TF_VON_NGF: 71.49,
    GB_VERKEHRSFL_VF_VON_NGF: 35.33,
    GB_MIETFL_GESAMT: 612.6,
    GB_MIETFL_1_BSB: 552.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990932,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1527.09,
    GB_NUTZFL_NF_VON_NGF: 1107.06,
    GB_TECHNIKFL_TF_VON_NGF: 104.39,
    GB_VERKEHRSFL_VF_VON_NGF: 315.64,
    GB_MIETFL_GESAMT: 1527.09,
    GB_MIETFL_1_BSB: 1416.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990933,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.68,
    GB_NUTZFL_NF_VON_NGF: 201.32,
    GB_TECHNIKFL_TF_VON_NGF: 6.36,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 207.68,
    GB_MIETFL_1_BSB: 207.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990934,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lehrschwimmbecken',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lehrschwimmbecken',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1990935,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.651508',
    GB_LAENGENGRAD_OESTLICH: '10.013973',
    GB_NETTOGRUNDFL_NGF: 623.28,
    GB_NUTZFL_NF_VON_NGF: 587.73,
    GB_TECHNIKFL_TF_VON_NGF: 15.8,
    GB_VERKEHRSFL_VF_VON_NGF: 19.75,
    GB_MIETFL_GESAMT: 623.28,
    GB_MIETFL_1_BSB: 623.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990936,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 929.74,
    GB_NUTZFL_NF_VON_NGF: 819.34,
    GB_TECHNIKFL_TF_VON_NGF: 14.42,
    GB_VERKEHRSFL_VF_VON_NGF: 95.98,
    GB_MIETFL_GESAMT: 929.74,
    GB_MIETFL_1_BSB: 929.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990937,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6890796,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704019,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704019',
    GB_SAP_GB_ID_PKF: '7000/704019/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042791,
    GB_WE_OBJ_ID_FKT: 1989216,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1146.51,
    GB_NUTZFL_NF_VON_NGF: 997.87,
    GB_TECHNIKFL_TF_VON_NGF: 32.62,
    GB_VERKEHRSFL_VF_VON_NGF: 116.02,
    GB_MIETFL_GESAMT: 1146.51,
    GB_MIETFL_1_BSB: 974.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.10.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990975,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 996.35,
    GB_NUTZFL_NF_VON_NGF: 772.63,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 218.93,
    GB_MIETFL_GESAMT: 996.35,
    GB_MIETFL_1_BSB: 804.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990976,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 551.76,
    GB_NUTZFL_NF_VON_NGF: 411.68,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 135.29,
    GB_MIETFL_GESAMT: 551.76,
    GB_MIETFL_1_BSB: 359.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990977,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 360.28,
    GB_NUTZFL_NF_VON_NGF: 256.12,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 99.37,
    GB_MIETFL_GESAMT: 360.28,
    GB_MIETFL_1_BSB: 360.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990978,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 551.59,
    GB_NUTZFL_NF_VON_NGF: 401.05,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 145.75,
    GB_MIETFL_GESAMT: 551.59,
    GB_MIETFL_1_BSB: 359.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990979,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.658317',
    GB_LAENGENGRAD_OESTLICH: '10.012177',
    GB_NETTOGRUNDFL_NGF: 623.84,
    GB_NUTZFL_NF_VON_NGF: 588.62,
    GB_TECHNIKFL_TF_VON_NGF: 13.11,
    GB_VERKEHRSFL_VF_VON_NGF: 22.11,
    GB_MIETFL_GESAMT: 623.84,
    GB_MIETFL_1_BSB: 623.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990980,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2047.91,
    GB_NUTZFL_NF_VON_NGF: 1490.75,
    GB_TECHNIKFL_TF_VON_NGF: 90.9,
    GB_VERKEHRSFL_VF_VON_NGF: 466.26,
    GB_MIETFL_GESAMT: 2047.91,
    GB_MIETFL_1_BSB: 1887.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '06.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990981,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 994.0,
    GB_NUTZFL_NF_VON_NGF: 786.74,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 202.47,
    GB_MIETFL_GESAMT: 994.0,
    GB_MIETFL_1_BSB: 804.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990982,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 247.53,
    GB_NUTZFL_NF_VON_NGF: 241.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.96,
    GB_MIETFL_GESAMT: 247.53,
    GB_MIETFL_1_BSB: 247.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406998,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704028,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704028',
    GB_SAP_GB_ID_PKF: '7000/704028/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042793,
    GB_WE_OBJ_ID_FKT: 1989225,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1009.78,
    GB_NUTZFL_NF_VON_NGF: 604.99,
    GB_TECHNIKFL_TF_VON_NGF: 142.34,
    GB_VERKEHRSFL_VF_VON_NGF: 262.45,
    GB_MIETFL_GESAMT: 1009.78,
    GB_MIETFL_1_BSB: 881.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990954,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 725.48,
    GB_NUTZFL_NF_VON_NGF: 511.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 213.56,
    GB_MIETFL_GESAMT: 725.48,
    GB_MIETFL_1_BSB: 725.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990955,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/3',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.659821',
    GB_LAENGENGRAD_OESTLICH: '10.012008',
    GB_NETTOGRUNDFL_NGF: 357.56,
    GB_NUTZFL_NF_VON_NGF: 357.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 357.56,
    GB_MIETFL_1_BSB: 357.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990956,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 83.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990957,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 83.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990958,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 83.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990959,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 83.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990960,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 84.5,
    GB_NUTZFL_NF_VON_NGF: 84.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 84.5,
    GB_MIETFL_1_BSB: 84.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990961,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 82.94,
    GB_NUTZFL_NF_VON_NGF: 82.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 82.94,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 82.94,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990962,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 82.94,
    GB_NUTZFL_NF_VON_NGF: 82.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 82.94,
    GB_MIETFL_1_BSB: 82.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990963,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 84.4,
    GB_NUTZFL_NF_VON_NGF: 84.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 84.4,
    GB_MIETFL_1_BSB: 84.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990964,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/12',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.03,
    GB_NUTZFL_NF_VON_NGF: 83.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.03,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 83.03,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990965,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/13',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 83.04,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990966,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/14',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.04,
    GB_NUTZFL_NF_VON_NGF: 83.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 83.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 83.04,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990967,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/15',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 84.5,
    GB_NUTZFL_NF_VON_NGF: 84.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 84.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 84.5,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990968,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704025,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/704025',
    GB_SAP_GB_ID_PKF: '7000/704025/16',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 204.76,
    GB_NUTZFL_NF_VON_NGF: 159.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.93,
    GB_MIETFL_GESAMT: 204.76,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990969,
    GB_WE_OBJ_ID_FKT: 1989222,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2553.77,
    GB_NUTZFL_NF_VON_NGF: 1926.96,
    GB_TECHNIKFL_TF_VON_NGF: 113.0,
    GB_VERKEHRSFL_VF_VON_NGF: 513.81,
    GB_MIETFL_GESAMT: 2553.77,
    GB_MIETFL_1_BSB: 2340.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991000,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1154.16,
    GB_NUTZFL_NF_VON_NGF: 944.18,
    GB_TECHNIKFL_TF_VON_NGF: 5.17,
    GB_VERKEHRSFL_VF_VON_NGF: 204.81,
    GB_MIETFL_GESAMT: 1154.16,
    GB_MIETFL_1_BSB: 1154.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991001,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3804.1,
    GB_NUTZFL_NF_VON_NGF: 2465.38,
    GB_TECHNIKFL_TF_VON_NGF: 221.21,
    GB_VERKEHRSFL_VF_VON_NGF: 1117.51,
    GB_MIETFL_GESAMT: 3804.1,
    GB_MIETFL_1_BSB: 3600.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991002,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3165.7,
    GB_NUTZFL_NF_VON_NGF: 2665.67,
    GB_TECHNIKFL_TF_VON_NGF: 56.07,
    GB_VERKEHRSFL_VF_VON_NGF: 443.96,
    GB_MIETFL_GESAMT: 3165.7,
    GB_MIETFL_1_BSB: 3110.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991003,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.670002',
    GB_LAENGENGRAD_OESTLICH: '10.033182',
    GB_NETTOGRUNDFL_NGF: 1628.25,
    GB_NUTZFL_NF_VON_NGF: 1542.03,
    GB_TECHNIKFL_TF_VON_NGF: 38.3,
    GB_VERKEHRSFL_VF_VON_NGF: 47.92,
    GB_MIETFL_GESAMT: 1628.25,
    GB_MIETFL_1_BSB: 1628.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991004,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 451.61,
    GB_NUTZFL_NF_VON_NGF: 367.39,
    GB_TECHNIKFL_TF_VON_NGF: 4.84,
    GB_VERKEHRSFL_VF_VON_NGF: 79.38,
    GB_MIETFL_GESAMT: 451.61,
    GB_MIETFL_1_BSB: 451.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991005,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.71,
    GB_NUTZFL_NF_VON_NGF: 14.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.71,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2073742,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/8',
    GB_SAP_GB_BEZEICHNUNG: 'Doppelgarage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Doppelgarage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.27,
    GB_NUTZFL_NF_VON_NGF: 33.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.27,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2073743,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042795,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704033,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704033',
    GB_SAP_GB_ID_PKF: '7000/704033/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.02,
    GB_NUTZFL_NF_VON_NGF: 148.69,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: 167.02,
    GB_MIETFL_1_BSB: 68.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5921148,
    GB_WE_OBJ_ID_FKT: 1989230,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 715.52,
    GB_NUTZFL_NF_VON_NGF: 567.65,
    GB_TECHNIKFL_TF_VON_NGF: 81.19,
    GB_VERKEHRSFL_VF_VON_NGF: 66.68,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991057,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 837.1,
    GB_NUTZFL_NF_VON_NGF: 733.27,
    GB_TECHNIKFL_TF_VON_NGF: 38.8,
    GB_VERKEHRSFL_VF_VON_NGF: 65.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991058,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 958.32,
    GB_NUTZFL_NF_VON_NGF: 744.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 213.39,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991059,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 52.51,
    GB_NUTZFL_NF_VON_NGF: 52.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991060,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 443.82,
    GB_NUTZFL_NF_VON_NGF: 443.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 443.82,
    GB_MIETFL_1_BSB: 443.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991061,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 851.52,
    GB_NUTZFL_NF_VON_NGF: 779.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991062,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.652525',
    GB_LAENGENGRAD_OESTLICH: '9.998581',
    GB_NETTOGRUNDFL_NGF: 627.7,
    GB_NUTZFL_NF_VON_NGF: 574.55,
    GB_TECHNIKFL_TF_VON_NGF: 33.7,
    GB_VERKEHRSFL_VF_VON_NGF: 19.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991063,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.651627',
    GB_LAENGENGRAD_OESTLICH: '9.998870',
    GB_NETTOGRUNDFL_NGF: 302.31,
    GB_NUTZFL_NF_VON_NGF: 279.32,
    GB_TECHNIKFL_TF_VON_NGF: 14.59,
    GB_VERKEHRSFL_VF_VON_NGF: 8.4,
    GB_MIETFL_GESAMT: 302.31,
    GB_MIETFL_1_BSB: 302.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991064,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 137.51,
    GB_NUTZFL_NF_VON_NGF: 126.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.57,
    GB_MIETFL_GESAMT: 137.51,
    GB_MIETFL_1_BSB: 137.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991065,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 405.05,
    GB_NUTZFL_NF_VON_NGF: 325.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.64,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991066,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 805.84,
    GB_NUTZFL_NF_VON_NGF: 678.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 127.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.20',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 5174590,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.85,
    GB_NUTZFL_NF_VON_NGF: 10.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991067,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.7,
    GB_NUTZFL_NF_VON_NGF: 34.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991068,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.35,
    GB_NUTZFL_NF_VON_NGF: 17.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.08.22',
    GB_GB_OBJ_ID_PKT: 1991069,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/15',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042796,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042797,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704043,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/704043',
    GB_SAP_GB_ID_PKF: '7000/704043/17',
    GB_SAP_GB_BEZEICHNUNG: 'Mischgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mischgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7175632,
    GB_WE_OBJ_ID_FKT: 1989240,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1630.01,
    GB_NUTZFL_NF_VON_NGF: 1273.0,
    GB_TECHNIKFL_TF_VON_NGF: 21.72,
    GB_VERKEHRSFL_VF_VON_NGF: 335.29,
    GB_MIETFL_GESAMT: 1630.01,
    GB_MIETFL_1_BSB: 1554.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991037,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.05,
    GB_NUTZFL_NF_VON_NGF: 111.66,
    GB_TECHNIKFL_TF_VON_NGF: 18.5,
    GB_VERKEHRSFL_VF_VON_NGF: 19.89,
    GB_MIETFL_GESAMT: 150.05,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991038,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1568.95,
    GB_NUTZFL_NF_VON_NGF: 1149.81,
    GB_TECHNIKFL_TF_VON_NGF: 104.18,
    GB_VERKEHRSFL_VF_VON_NGF: 314.96,
    GB_MIETFL_GESAMT: 1568.95,
    GB_MIETFL_1_BSB: 1449.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991039,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.646943',
    GB_LAENGENGRAD_OESTLICH: '10.000841',
    GB_NETTOGRUNDFL_NGF: 660.96,
    GB_NUTZFL_NF_VON_NGF: 614.85,
    GB_TECHNIKFL_TF_VON_NGF: 17.04,
    GB_VERKEHRSFL_VF_VON_NGF: 29.07,
    GB_MIETFL_GESAMT: 660.96,
    GB_MIETFL_1_BSB: 660.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991040,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 520.22,
    GB_NUTZFL_NF_VON_NGF: 422.73,
    GB_TECHNIKFL_TF_VON_NGF: 39.56,
    GB_VERKEHRSFL_VF_VON_NGF: 57.93,
    GB_MIETFL_GESAMT: 520.22,
    GB_MIETFL_1_BSB: 479.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.07.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991041,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 518.89,
    GB_NUTZFL_NF_VON_NGF: 421.8,
    GB_TECHNIKFL_TF_VON_NGF: 39.25,
    GB_VERKEHRSFL_VF_VON_NGF: 57.84,
    GB_MIETFL_GESAMT: 518.89,
    GB_MIETFL_1_BSB: 478.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.03.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991042,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 38.64,
    GB_NUTZFL_NF_VON_NGF: 38.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 38.64,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991043,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704040,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704040',
    GB_SAP_GB_ID_PKF: '7000/704040/8',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 27.72,
    GB_NUTZFL_NF_VON_NGF: 27.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 27.72,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991044,
    GB_WE_OBJ_ID_FKT: 1989237,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1523.0,
    GB_NUTZFL_NF_VON_NGF: 1164.85,
    GB_TECHNIKFL_TF_VON_NGF: 84.89,
    GB_VERKEHRSFL_VF_VON_NGF: 273.26,
    GB_MIETFL_GESAMT: 1523.0,
    GB_MIETFL_1_BSB: 1389.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.05.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991102,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.24,
    GB_NUTZFL_NF_VON_NGF: 71.21,
    GB_TECHNIKFL_TF_VON_NGF: 8.51,
    GB_VERKEHRSFL_VF_VON_NGF: 10.52,
    GB_MIETFL_GESAMT: 90.24,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991103,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 160.42,
    GB_NUTZFL_NF_VON_NGF: 127.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.17,
    GB_MIETFL_GESAMT: 160.42,
    GB_MIETFL_1_BSB: 160.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991104,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 260.25,
    GB_NUTZFL_NF_VON_NGF: 211.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.92,
    GB_MIETFL_GESAMT: 260.25,
    GB_MIETFL_1_BSB: 260.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991105,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.15,
    GB_NUTZFL_NF_VON_NGF: 168.25,
    GB_TECHNIKFL_TF_VON_NGF: 112.98,
    GB_VERKEHRSFL_VF_VON_NGF: 56.92,
    GB_MIETFL_GESAMT: 338.15,
    GB_MIETFL_1_BSB: 157.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991106,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 260.58,
    GB_NUTZFL_NF_VON_NGF: 210.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.5,
    GB_MIETFL_GESAMT: 260.58,
    GB_MIETFL_1_BSB: 260.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991107,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.08,
    GB_NUTZFL_NF_VON_NGF: 174.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.62,
    GB_MIETFL_GESAMT: 205.08,
    GB_MIETFL_1_BSB: 205.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991108,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.667709',
    GB_LAENGENGRAD_OESTLICH: '10.004697',
    GB_NETTOGRUNDFL_NGF: 590.16,
    GB_NUTZFL_NF_VON_NGF: 559.12,
    GB_TECHNIKFL_TF_VON_NGF: 22.0,
    GB_VERKEHRSFL_VF_VON_NGF: 9.04,
    GB_MIETFL_GESAMT: 590.16,
    GB_MIETFL_1_BSB: 590.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991109,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.667638',
    GB_LAENGENGRAD_OESTLICH: '10.005117',
    GB_NETTOGRUNDFL_NGF: 326.7,
    GB_NUTZFL_NF_VON_NGF: 307.38,
    GB_TECHNIKFL_TF_VON_NGF: 8.35,
    GB_VERKEHRSFL_VF_VON_NGF: 10.97,
    GB_MIETFL_GESAMT: 326.7,
    GB_MIETFL_1_BSB: 326.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991110,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 511.07,
    GB_NUTZFL_NF_VON_NGF: 417.07,
    GB_TECHNIKFL_TF_VON_NGF: 6.48,
    GB_VERKEHRSFL_VF_VON_NGF: 87.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1991111,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1440.68,
    GB_NUTZFL_NF_VON_NGF: 1141.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 298.9,
    GB_MIETFL_GESAMT: 1440.68,
    GB_MIETFL_1_BSB: 1440.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991112,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1070.11,
    GB_NUTZFL_NF_VON_NGF: 880.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 189.63,
    GB_MIETFL_GESAMT: 1070.11,
    GB_MIETFL_1_BSB: 1070.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991113,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/13',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.44,
    GB_NUTZFL_NF_VON_NGF: 16.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.44,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991114,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/14',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.02,
    GB_NUTZFL_NF_VON_NGF: 16.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991115,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/15',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 11.02,
    GB_NUTZFL_NF_VON_NGF: 11.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 11.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991116,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | sengang beendet zu 31.08.2013',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 2073764,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/17',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.91,
    GB_NUTZFL_NF_VON_NGF: 10.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.91,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5174591,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/18',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407010,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407011,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704049,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704049',
    GB_SAP_GB_ID_PKF: '7000/704049/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552750,
    GB_WE_OBJ_ID_FKT: 1989246,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 445.01,
    GB_NUTZFL_NF_VON_NGF: 355.43,
    GB_TECHNIKFL_TF_VON_NGF: 3.88,
    GB_VERKEHRSFL_VF_VON_NGF: 85.7,
    GB_MIETFL_GESAMT: 445.01,
    GB_MIETFL_1_BSB: 292.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991143,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 68.92,
    GB_NUTZFL_NF_VON_NGF: 68.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 68.92,
    GB_MIETFL_1_BSB: 68.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991144,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1550.86,
    GB_NUTZFL_NF_VON_NGF: 1150.77,
    GB_TECHNIKFL_TF_VON_NGF: 103.27,
    GB_VERKEHRSFL_VF_VON_NGF: 296.82,
    GB_MIETFL_GESAMT: 1550.86,
    GB_MIETFL_1_BSB: 1439.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991145,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.03,
    GB_NUTZFL_NF_VON_NGF: 173.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 173.03,
    GB_MIETFL_1_BSB: 173.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991146,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.1,
    GB_NUTZFL_NF_VON_NGF: 155.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.49,
    GB_MIETFL_GESAMT: 173.1,
    GB_MIETFL_1_BSB: 173.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991147,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.37,
    GB_NUTZFL_NF_VON_NGF: 169.07,
    GB_TECHNIKFL_TF_VON_NGF: 4.5,
    GB_VERKEHRSFL_VF_VON_NGF: 24.8,
    GB_MIETFL_GESAMT: 198.37,
    GB_MIETFL_1_BSB: 198.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991148,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991149,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.678688',
    GB_LAENGENGRAD_OESTLICH: '10.018084',
    GB_NETTOGRUNDFL_NGF: 623.84,
    GB_NUTZFL_NF_VON_NGF: 583.55,
    GB_TECHNIKFL_TF_VON_NGF: 21.34,
    GB_VERKEHRSFL_VF_VON_NGF: 18.95,
    GB_MIETFL_GESAMT: 623.84,
    GB_MIETFL_1_BSB: 623.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991150,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 855.96,
    GB_NUTZFL_NF_VON_NGF: 760.12,
    GB_TECHNIKFL_TF_VON_NGF: 31.58,
    GB_VERKEHRSFL_VF_VON_NGF: 64.26,
    GB_MIETFL_GESAMT: 855.96,
    GB_MIETFL_1_BSB: 855.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991151,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.42,
    GB_NUTZFL_NF_VON_NGF: 22.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.42,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991152,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 334.8,
    GB_NUTZFL_NF_VON_NGF: 287.34,
    GB_TECHNIKFL_TF_VON_NGF: 15.08,
    GB_VERKEHRSFL_VF_VON_NGF: 32.38,
    GB_MIETFL_GESAMT: 334.8,
    GB_MIETFL_1_BSB: 334.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.01.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5328103,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407015,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407016,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704054,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704054',
    GB_SAP_GB_ID_PKF: '7000/704054/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552751,
    GB_WE_OBJ_ID_FKT: 1989251,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2065.52,
    GB_NUTZFL_NF_VON_NGF: 1426.7,
    GB_TECHNIKFL_TF_VON_NGF: 111.86,
    GB_VERKEHRSFL_VF_VON_NGF: 526.96,
    GB_MIETFL_GESAMT: 2065.52,
    GB_MIETFL_1_BSB: 1860.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990857,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 853.35,
    GB_NUTZFL_NF_VON_NGF: 771.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.1,
    GB_MIETFL_GESAMT: 853.35,
    GB_MIETFL_1_BSB: 853.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990858,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.29,
    GB_NUTZFL_NF_VON_NGF: 377.63,
    GB_TECHNIKFL_TF_VON_NGF: 3.75,
    GB_VERKEHRSFL_VF_VON_NGF: 59.91,
    GB_MIETFL_GESAMT: 441.29,
    GB_MIETFL_1_BSB: 441.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990859,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.54,
    GB_NUTZFL_NF_VON_NGF: 376.0,
    GB_TECHNIKFL_TF_VON_NGF: 3.78,
    GB_VERKEHRSFL_VF_VON_NGF: 59.76,
    GB_MIETFL_GESAMT: 439.54,
    GB_MIETFL_1_BSB: 439.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990860,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2027.65,
    GB_NUTZFL_NF_VON_NGF: 1356.72,
    GB_TECHNIKFL_TF_VON_NGF: 101.07,
    GB_VERKEHRSFL_VF_VON_NGF: 569.86,
    GB_MIETFL_GESAMT: 2027.65,
    GB_MIETFL_1_BSB: 1895.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990861,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1990862,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.76,
    GB_NUTZFL_NF_VON_NGF: 26.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1990863,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1990864,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.03.17',
    GB_GB_OBJ_ID_PKT: 1990865,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1643.34,
    GB_NUTZFL_NF_VON_NGF: 1340.2,
    GB_TECHNIKFL_TF_VON_NGF: 107.79,
    GB_VERKEHRSFL_VF_VON_NGF: 195.35,
    GB_MIETFL_GESAMT: 1643.34,
    GB_MIETFL_1_BSB: 1535.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990866,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 423.09,
    GB_NUTZFL_NF_VON_NGF: 358.77,
    GB_TECHNIKFL_TF_VON_NGF: 13.28,
    GB_VERKEHRSFL_VF_VON_NGF: 51.04,
    GB_MIETFL_GESAMT: 423.09,
    GB_MIETFL_1_BSB: 423.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990867,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.670984',
    GB_LAENGENGRAD_OESTLICH: '10.027087',
    GB_NETTOGRUNDFL_NGF: 1640.56,
    GB_NUTZFL_NF_VON_NGF: 1373.98,
    GB_TECHNIKFL_TF_VON_NGF: 17.21,
    GB_VERKEHRSFL_VF_VON_NGF: 249.37,
    GB_MIETFL_GESAMT: 1640.56,
    GB_MIETFL_1_BSB: 1640.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990868,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.671041',
    GB_LAENGENGRAD_OESTLICH: '10.028035',
    GB_NETTOGRUNDFL_NGF: 586.15,
    GB_NUTZFL_NF_VON_NGF: 547.19,
    GB_TECHNIKFL_TF_VON_NGF: 32.86,
    GB_VERKEHRSFL_VF_VON_NGF: 6.1,
    GB_MIETFL_GESAMT: 586.15,
    GB_MIETFL_1_BSB: 586.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990869,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/14',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1318.76,
    GB_NUTZFL_NF_VON_NGF: 1047.58,
    GB_TECHNIKFL_TF_VON_NGF: 47.64,
    GB_VERKEHRSFL_VF_VON_NGF: 223.54,
    GB_MIETFL_GESAMT: 1318.76,
    GB_MIETFL_1_BSB: 1315.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406847,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5491169,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 4352742,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 329.29,
    GB_NUTZFL_NF_VON_NGF: 289.51,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 36.77,
    GB_MIETFL_GESAMT: 329.29,
    GB_MIETFL_1_BSB: 329.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5455418,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704008,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/704008',
    GB_SAP_GB_ID_PKF: '7000/704008/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 75.0,
    GB_NUTZFL_NF_VON_NGF: 75.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 75.0,
    GB_MIETFL_1_BSB: 75.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6940204,
    GB_WE_OBJ_ID_FKT: 1989205,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704027',
    GB_SAP_GB_ID_PKF: '7000/704027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9531.84,
    GB_NUTZFL_NF_VON_NGF: 7030.88,
    GB_TECHNIKFL_TF_VON_NGF: 292.33,
    GB_VERKEHRSFL_VF_VON_NGF: 2208.63,
    GB_MIETFL_GESAMT: 9531.84,
    GB_MIETFL_1_BSB: 8364.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990972,
    GB_WE_OBJ_ID_FKT: 1989224,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704027',
    GB_SAP_GB_ID_PKF: '7000/704027/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.63,
    GB_NUTZFL_NF_VON_NGF: 94.6,
    GB_TECHNIKFL_TF_VON_NGF: 14.17,
    GB_VERKEHRSFL_VF_VON_NGF: 11.86,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.21',
    GB_GB_OBJ_ID_PKT: 1990973,
    GB_WE_OBJ_ID_FKT: 1989224,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704027',
    GB_SAP_GB_ID_PKF: '7000/704027/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.658343',
    GB_LAENGENGRAD_OESTLICH: '10.022730',
    GB_NETTOGRUNDFL_NGF: 1241.09,
    GB_NUTZFL_NF_VON_NGF: 1145.57,
    GB_TECHNIKFL_TF_VON_NGF: 45.06,
    GB_VERKEHRSFL_VF_VON_NGF: 50.46,
    GB_MIETFL_GESAMT: 1241.09,
    GB_MIETFL_1_BSB: 1241.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990974,
    GB_WE_OBJ_ID_FKT: 1989224,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704027',
    GB_SAP_GB_ID_PKF: '7000/704027/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406997,
    GB_WE_OBJ_ID_FKT: 1989224,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 319.69,
    GB_NUTZFL_NF_VON_NGF: 272.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.96,
    GB_MIETFL_GESAMT: 319.69,
    GB_MIETFL_1_BSB: 319.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990986,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1212.52,
    GB_NUTZFL_NF_VON_NGF: 955.97,
    GB_TECHNIKFL_TF_VON_NGF: 66.94,
    GB_VERKEHRSFL_VF_VON_NGF: 189.61,
    GB_MIETFL_GESAMT: 1212.52,
    GB_MIETFL_1_BSB: 1145.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990987,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/3',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 251.4,
    GB_NUTZFL_NF_VON_NGF: 112.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 139.3,
    GB_MIETFL_GESAMT: 251.4,
    GB_MIETFL_1_BSB: 251.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990988,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1309.14,
    GB_NUTZFL_NF_VON_NGF: 1134.01,
    GB_TECHNIKFL_TF_VON_NGF: 9.35,
    GB_VERKEHRSFL_VF_VON_NGF: 165.78,
    GB_MIETFL_GESAMT: 1309.14,
    GB_MIETFL_1_BSB: 1309.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990989,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 530.81,
    GB_NUTZFL_NF_VON_NGF: 420.3,
    GB_TECHNIKFL_TF_VON_NGF: 0.88,
    GB_VERKEHRSFL_VF_VON_NGF: 109.63,
    GB_MIETFL_GESAMT: 530.81,
    GB_MIETFL_1_BSB: 466.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990990,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 158.24,
    GB_NUTZFL_NF_VON_NGF: 143.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.68,
    GB_MIETFL_GESAMT: 158.24,
    GB_MIETFL_1_BSB: 158.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990991,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 459.84,
    GB_NUTZFL_NF_VON_NGF: 309.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 150.63,
    GB_MIETFL_GESAMT: 459.84,
    GB_MIETFL_1_BSB: 459.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990992,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/8',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 81.9,
    GB_NUTZFL_NF_VON_NGF: 81.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 81.9,
    GB_MIETFL_1_BSB: 81.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990993,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 355.64,
    GB_NUTZFL_NF_VON_NGF: 355.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 355.64,
    GB_MIETFL_1_BSB: 355.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990994,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 111.29,
    GB_NUTZFL_NF_VON_NGF: 59.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.28,
    GB_MIETFL_GESAMT: 111.29,
    GB_MIETFL_1_BSB: 111.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990995,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 69.67,
    GB_NUTZFL_NF_VON_NGF: 69.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 69.67,
    GB_MIETFL_1_BSB: 69.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990996,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704031,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704031',
    GB_SAP_GB_ID_PKF: '7000/704031/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.612519',
    GB_LAENGENGRAD_OESTLICH: '10.033506',
    GB_NETTOGRUNDFL_NGF: 622.25,
    GB_NUTZFL_NF_VON_NGF: 573.41,
    GB_TECHNIKFL_TF_VON_NGF: 34.11,
    GB_VERKEHRSFL_VF_VON_NGF: 14.73,
    GB_MIETFL_GESAMT: 622.25,
    GB_MIETFL_1_BSB: 622.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990997,
    GB_WE_OBJ_ID_FKT: 1989228,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1891,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2556.35,
    GB_NUTZFL_NF_VON_NGF: 1818.8,
    GB_TECHNIKFL_TF_VON_NGF: 123.56,
    GB_VERKEHRSFL_VF_VON_NGF: 613.99,
    GB_MIETFL_GESAMT: 2556.35,
    GB_MIETFL_1_BSB: 2342.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991125,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1912,
    GB_BREITENGRAD_NOERDLICH: '53.626467',
    GB_LAENGENGRAD_OESTLICH: '10.020464',
    GB_NETTOGRUNDFL_NGF: 478.29,
    GB_NUTZFL_NF_VON_NGF: 453.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.03,
    GB_MIETFL_GESAMT: 478.29,
    GB_MIETFL_1_BSB: 478.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991126,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991127,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1891,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1281.33,
    GB_NUTZFL_NF_VON_NGF: 941.36,
    GB_TECHNIKFL_TF_VON_NGF: 140.19,
    GB_VERKEHRSFL_VF_VON_NGF: 199.78,
    GB_MIETFL_GESAMT: 1281.33,
    GB_MIETFL_1_BSB: 941.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '08.05.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991128,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 26.3,
    GB_NUTZFL_NF_VON_NGF: 26.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 26.3,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991129,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.43,
    GB_NUTZFL_NF_VON_NGF: 16.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.43,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991130,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704051,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704051',
    GB_SAP_GB_ID_PKF: '7000/704051/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1568.33,
    GB_NUTZFL_NF_VON_NGF: 1129.46,
    GB_TECHNIKFL_TF_VON_NGF: 139.05,
    GB_VERKEHRSFL_VF_VON_NGF: 299.82,
    GB_MIETFL_GESAMT: 1568.33,
    GB_MIETFL_1_BSB: 1445.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407012,
    GB_WE_OBJ_ID_FKT: 1989248,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800386,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1748.13,
    GB_NUTZFL_NF_VON_NGF: 1205.31,
    GB_TECHNIKFL_TF_VON_NGF: 73.76,
    GB_VERKEHRSFL_VF_VON_NGF: 469.06,
    GB_MIETFL_GESAMT: 1748.13,
    GB_MIETFL_1_BSB: 1490.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990833,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1001.05,
    GB_NUTZFL_NF_VON_NGF: 809.13,
    GB_TECHNIKFL_TF_VON_NGF: 68.5,
    GB_VERKEHRSFL_VF_VON_NGF: 123.42,
    GB_MIETFL_GESAMT: 1001.05,
    GB_MIETFL_1_BSB: 932.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990834,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1201.89,
    GB_NUTZFL_NF_VON_NGF: 1005.04,
    GB_TECHNIKFL_TF_VON_NGF: 18.23,
    GB_VERKEHRSFL_VF_VON_NGF: 178.62,
    GB_MIETFL_GESAMT: 1201.89,
    GB_MIETFL_1_BSB: 1165.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990835,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1580.95,
    GB_NUTZFL_NF_VON_NGF: 1143.14,
    GB_TECHNIKFL_TF_VON_NGF: 77.08,
    GB_VERKEHRSFL_VF_VON_NGF: 360.73,
    GB_MIETFL_GESAMT: 1580.95,
    GB_MIETFL_1_BSB: 1503.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990836,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.632618',
    GB_LAENGENGRAD_OESTLICH: '10.049238',
    GB_NETTOGRUNDFL_NGF: 622.95,
    GB_NUTZFL_NF_VON_NGF: 576.77,
    GB_TECHNIKFL_TF_VON_NGF: 23.93,
    GB_VERKEHRSFL_VF_VON_NGF: 22.25,
    GB_MIETFL_GESAMT: 622.95,
    GB_MIETFL_1_BSB: 622.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990837,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.632412',
    GB_LAENGENGRAD_OESTLICH: '10.049311',
    GB_NETTOGRUNDFL_NGF: 311.48,
    GB_NUTZFL_NF_VON_NGF: 288.69,
    GB_TECHNIKFL_TF_VON_NGF: 11.32,
    GB_VERKEHRSFL_VF_VON_NGF: 11.47,
    GB_MIETFL_GESAMT: 311.48,
    GB_MIETFL_1_BSB: 311.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990838,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1044.08,
    GB_NUTZFL_NF_VON_NGF: 852.52,
    GB_TECHNIKFL_TF_VON_NGF: 4.45,
    GB_VERKEHRSFL_VF_VON_NGF: 187.11,
    GB_MIETFL_GESAMT: 1044.08,
    GB_MIETFL_1_BSB: 1044.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990839,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/12',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 190.82,
    GB_NUTZFL_NF_VON_NGF: 141.58,
    GB_TECHNIKFL_TF_VON_NGF: 12.47,
    GB_VERKEHRSFL_VF_VON_NGF: 36.77,
    GB_MIETFL_GESAMT: 190.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990840,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1500.42,
    GB_NUTZFL_NF_VON_NGF: 1165.44,
    GB_TECHNIKFL_TF_VON_NGF: 15.49,
    GB_VERKEHRSFL_VF_VON_NGF: 319.49,
    GB_MIETFL_GESAMT: 1500.42,
    GB_MIETFL_1_BSB: 1494.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.04.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406845,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/14',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.31,
    GB_NUTZFL_NF_VON_NGF: 366.69,
    GB_TECHNIKFL_TF_VON_NGF: 5.22,
    GB_VERKEHRSFL_VF_VON_NGF: 28.4,
    GB_MIETFL_GESAMT: 400.31,
    GB_MIETFL_1_BSB: 400.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406846,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/15',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5900442,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704004,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704004',
    GB_SAP_GB_ID_PKF: '7000/704004/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 846.43,
    GB_NUTZFL_NF_VON_NGF: 771.64,
    GB_TECHNIKFL_TF_VON_NGF: 8.75,
    GB_VERKEHRSFL_VF_VON_NGF: 66.04,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.07.18',
    GB_GB_OBJ_ID_PKT: 4352740,
    GB_WE_OBJ_ID_FKT: 1989201,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2611.64,
    GB_NUTZFL_NF_VON_NGF: 1704.5,
    GB_TECHNIKFL_TF_VON_NGF: 23.17,
    GB_VERKEHRSFL_VF_VON_NGF: 883.97,
    GB_MIETFL_GESAMT: 2611.64,
    GB_MIETFL_1_BSB: 2130.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990820,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1012.78,
    GB_NUTZFL_NF_VON_NGF: 949.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.27,
    GB_MIETFL_GESAMT: 1012.78,
    GB_MIETFL_1_BSB: 1012.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990821,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.35,
    GB_NUTZFL_NF_VON_NGF: 66.42,
    GB_TECHNIKFL_TF_VON_NGF: 9.59,
    GB_VERKEHRSFL_VF_VON_NGF: 27.34,
    GB_MIETFL_GESAMT: 103.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990822,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1513.29,
    GB_NUTZFL_NF_VON_NGF: 1070.99,
    GB_TECHNIKFL_TF_VON_NGF: 110.21,
    GB_VERKEHRSFL_VF_VON_NGF: 332.09,
    GB_MIETFL_GESAMT: 1513.29,
    GB_MIETFL_1_BSB: 1132.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990823,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 328.87,
    GB_NUTZFL_NF_VON_NGF: 328.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 328.87,
    GB_MIETFL_1_BSB: 328.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990824,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 801.75,
    GB_NUTZFL_NF_VON_NGF: 610.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 190.89,
    GB_MIETFL_GESAMT: 801.75,
    GB_MIETFL_1_BSB: 610.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990825,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 647.88,
    GB_NUTZFL_NF_VON_NGF: 499.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 148.2,
    GB_MIETFL_GESAMT: 647.88,
    GB_MIETFL_1_BSB: 647.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990826,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.628276',
    GB_LAENGENGRAD_OESTLICH: '10.030128',
    GB_NETTOGRUNDFL_NGF: 655.7,
    GB_NUTZFL_NF_VON_NGF: 631.42,
    GB_TECHNIKFL_TF_VON_NGF: 9.79,
    GB_VERKEHRSFL_VF_VON_NGF: 14.49,
    GB_MIETFL_GESAMT: 655.7,
    GB_MIETFL_1_BSB: 655.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990827,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.628033',
    GB_LAENGENGRAD_OESTLICH: '10.029876',
    GB_NETTOGRUNDFL_NGF: 367.71,
    GB_NUTZFL_NF_VON_NGF: 333.3,
    GB_TECHNIKFL_TF_VON_NGF: 12.75,
    GB_VERKEHRSFL_VF_VON_NGF: 21.66,
    GB_MIETFL_GESAMT: 367.71,
    GB_MIETFL_1_BSB: 367.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990828,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.627993',
    GB_LAENGENGRAD_OESTLICH: '10.030329',
    GB_NETTOGRUNDFL_NGF: 515.55,
    GB_NUTZFL_NF_VON_NGF: 499.47,
    GB_TECHNIKFL_TF_VON_NGF: 9.42,
    GB_VERKEHRSFL_VF_VON_NGF: 6.66,
    GB_MIETFL_GESAMT: 515.55,
    GB_MIETFL_1_BSB: 515.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990829,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2135.83,
    GB_NUTZFL_NF_VON_NGF: 1560.82,
    GB_TECHNIKFL_TF_VON_NGF: 71.92,
    GB_VERKEHRSFL_VF_VON_NGF: 503.09,
    GB_MIETFL_GESAMT: 2135.83,
    GB_MIETFL_1_BSB: 1802.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990830,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 23.04,
    GB_NUTZFL_NF_VON_NGF: 23.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 23.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990831,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/13',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.63,
    GB_NUTZFL_NF_VON_NGF: 14.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.63,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990832,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406991,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704003,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704003',
    GB_SAP_GB_ID_PKF: '7000/704003/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 191.78,
    GB_NUTZFL_NF_VON_NGF: 172.67,
    GB_TECHNIKFL_TF_VON_NGF: 3.27,
    GB_VERKEHRSFL_VF_VON_NGF: 15.84,
    GB_MIETFL_GESAMT: 191.78,
    GB_MIETFL_1_BSB: 191.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352739,
    GB_WE_OBJ_ID_FKT: 1989200,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704045',
    GB_SAP_GB_ID_PKF: '7000/704045/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1913,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1437.99,
    GB_NUTZFL_NF_VON_NGF: 998.04,
    GB_TECHNIKFL_TF_VON_NGF: 61.59,
    GB_VERKEHRSFL_VF_VON_NGF: 378.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '26.09.17',
    GB_GB_OBJ_ID_PKT: 1991081,
    GB_WE_OBJ_ID_FKT: 1989242,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4566.23,
    GB_NUTZFL_NF_VON_NGF: 3513.86,
    GB_TECHNIKFL_TF_VON_NGF: 81.1,
    GB_VERKEHRSFL_VF_VON_NGF: 971.27,
    GB_MIETFL_GESAMT: 4566.23,
    GB_MIETFL_1_BSB: 3506.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991070,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 767.6,
    GB_NUTZFL_NF_VON_NGF: 633.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 134.54,
    GB_MIETFL_GESAMT: 767.6,
    GB_MIETFL_1_BSB: 767.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991071,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 767.6,
    GB_NUTZFL_NF_VON_NGF: 633.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 134.54,
    GB_MIETFL_GESAMT: 767.6,
    GB_MIETFL_1_BSB: 767.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991072,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 456.6,
    GB_NUTZFL_NF_VON_NGF: 361.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 95.06,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991073,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.51,
    GB_NUTZFL_NF_VON_NGF: 126.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.89,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991074,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.568699',
    GB_LAENGENGRAD_OESTLICH: '10.030807',
    GB_NETTOGRUNDFL_NGF: 606.77,
    GB_NUTZFL_NF_VON_NGF: 538.15,
    GB_TECHNIKFL_TF_VON_NGF: 12.12,
    GB_VERKEHRSFL_VF_VON_NGF: 56.5,
    GB_MIETFL_GESAMT: 606.77,
    GB_MIETFL_1_BSB: 606.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991075,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.568660',
    GB_LAENGENGRAD_OESTLICH: '10.030367',
    GB_NETTOGRUNDFL_NGF: 715.33,
    GB_NUTZFL_NF_VON_NGF: 674.58,
    GB_TECHNIKFL_TF_VON_NGF: 16.16,
    GB_VERKEHRSFL_VF_VON_NGF: 24.59,
    GB_MIETFL_GESAMT: 715.33,
    GB_MIETFL_1_BSB: 715.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991076,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 269.31,
    GB_NUTZFL_NF_VON_NGF: 269.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991077,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 80.84,
    GB_NUTZFL_NF_VON_NGF: 71.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.77,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991078,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 31.03,
    GB_NUTZFL_NF_VON_NGF: 31.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991079,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.47,
    GB_NUTZFL_NF_VON_NGF: 12.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.21',
    GB_GB_OBJ_ID_PKT: 1991080,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407007,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407008,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 352.16,
    GB_MIETFL_1_BSB: 352.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.06.15',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5420694,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704044,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704044',
    GB_SAP_GB_ID_PKF: '7000/704044/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 433.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7366375,
    GB_WE_OBJ_ID_FKT: 1989241,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1902,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4134.12,
    GB_NUTZFL_NF_VON_NGF: 2701.03,
    GB_TECHNIKFL_TF_VON_NGF: 86.64,
    GB_VERKEHRSFL_VF_VON_NGF: 1346.45,
    GB_MIETFL_GESAMT: 4134.12,
    GB_MIETFL_1_BSB: 3888.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '09.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991170,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.578770',
    GB_LAENGENGRAD_OESTLICH: '10.017370',
    GB_NETTOGRUNDFL_NGF: 621.48,
    GB_NUTZFL_NF_VON_NGF: 595.04,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 19.75,
    GB_MIETFL_GESAMT: 621.48,
    GB_MIETFL_1_BSB: 621.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991171,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 650.0,
    GB_NUTZFL_NF_VON_NGF: 650.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 650.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991172,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 25.62,
    GB_NUTZFL_NF_VON_NGF: 25.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 25.62,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991173,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1042.56,
    GB_NUTZFL_NF_VON_NGF: 716.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 325.8,
    GB_MIETFL_GESAMT: 1042.56,
    GB_MIETFL_1_BSB: 964.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '07.03.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407018,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.08.18',
    GB_GB_OBJ_ID_PKT: 5306677,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704057,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704057',
    GB_SAP_GB_ID_PKF: '7000/704057/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 253.77,
    GB_NUTZFL_NF_VON_NGF: 231.66,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.08.18',
    GB_GB_OBJ_ID_PKT: 5306678,
    GB_WE_OBJ_ID_FKT: 1989254,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 7086002,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1906,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4679.0,
    GB_NUTZFL_NF_VON_NGF: 3414.99,
    GB_TECHNIKFL_TF_VON_NGF: 43.27,
    GB_VERKEHRSFL_VF_VON_NGF: 1220.74,
    GB_MIETFL_GESAMT: 4679.0,
    GB_MIETFL_1_BSB: 3412.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990841,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.596061',
    GB_LAENGENGRAD_OESTLICH: '9.996710',
    GB_NETTOGRUNDFL_NGF: 464.74,
    GB_NUTZFL_NF_VON_NGF: 440.99,
    GB_TECHNIKFL_TF_VON_NGF: 10.0,
    GB_VERKEHRSFL_VF_VON_NGF: 13.75,
    GB_MIETFL_GESAMT: 464.74,
    GB_MIETFL_1_BSB: 464.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990842,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.68,
    GB_NUTZFL_NF_VON_NGF: 22.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.68,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990843,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406992,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 324.92,
    GB_NUTZFL_NF_VON_NGF: 294.65,
    GB_TECHNIKFL_TF_VON_NGF: 5.76,
    GB_VERKEHRSFL_VF_VON_NGF: 24.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.10.20',
    GB_GB_OBJ_ID_PKT: 4352741,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704005,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704005',
    GB_SAP_GB_ID_PKF: '7000/704005/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 505.43,
    GB_NUTZFL_NF_VON_NGF: 467.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.06.20',
    GB_GB_OBJ_ID_PKT: 5453087,
    GB_WE_OBJ_ID_FKT: 1989202,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800319,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704082,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704082',
    GB_SAP_GB_ID_PKF: '7000/704082/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1901,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4056.8,
    GB_NUTZFL_NF_VON_NGF: 2893.44,
    GB_TECHNIKFL_TF_VON_NGF: 85.31,
    GB_VERKEHRSFL_VF_VON_NGF: 1078.05,
    GB_MIETFL_GESAMT: 4056.8,
    GB_MIETFL_1_BSB: 3947.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5434152,
    GB_WE_OBJ_ID_FKT: 5434100,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704082,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704082',
    GB_SAP_GB_ID_PKF: '7000/704082/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 458.7,
    GB_NUTZFL_NF_VON_NGF: 428.08,
    GB_TECHNIKFL_TF_VON_NGF: 17.97,
    GB_VERKEHRSFL_VF_VON_NGF: 12.65,
    GB_MIETFL_GESAMT: 458.7,
    GB_MIETFL_1_BSB: 458.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.16',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5434154,
    GB_WE_OBJ_ID_FKT: 5434100,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704082,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704082',
    GB_SAP_GB_ID_PKF: '7000/704082/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.19',
    GB_GB_OBJ_ID_PKT: 5913265,
    GB_WE_OBJ_ID_FKT: 5434100,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1602.34,
    GB_NUTZFL_NF_VON_NGF: 1094.34,
    GB_TECHNIKFL_TF_VON_NGF: 57.77,
    GB_VERKEHRSFL_VF_VON_NGF: 450.23,
    GB_MIETFL_GESAMT: 1602.34,
    GB_MIETFL_1_BSB: 1377.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990894,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.63,
    GB_NUTZFL_NF_VON_NGF: 146.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.29,
    GB_MIETFL_GESAMT: 165.63,
    GB_MIETFL_1_BSB: 165.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990895,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.63,
    GB_NUTZFL_NF_VON_NGF: 146.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.29,
    GB_MIETFL_GESAMT: 165.63,
    GB_MIETFL_1_BSB: 165.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990896,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 403.25,
    GB_NUTZFL_NF_VON_NGF: 299.51,
    GB_TECHNIKFL_TF_VON_NGF: 5.2,
    GB_VERKEHRSFL_VF_VON_NGF: 98.54,
    GB_MIETFL_GESAMT: 403.25,
    GB_MIETFL_1_BSB: 399.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990897,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 157.59,
    GB_NUTZFL_NF_VON_NGF: 131.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.18,
    GB_MIETFL_GESAMT: 157.59,
    GB_MIETFL_1_BSB: 157.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990898,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 542.77,
    GB_NUTZFL_NF_VON_NGF: 421.98,
    GB_TECHNIKFL_TF_VON_NGF: 5.16,
    GB_VERKEHRSFL_VF_VON_NGF: 115.63,
    GB_MIETFL_GESAMT: 542.77,
    GB_MIETFL_1_BSB: 513.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990899,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.06.19',
    GB_GB_OBJ_ID_PKT: 1990900,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.591613',
    GB_LAENGENGRAD_OESTLICH: '10.012399',
    GB_NETTOGRUNDFL_NGF: 1113.32,
    GB_NUTZFL_NF_VON_NGF: 930.38,
    GB_TECHNIKFL_TF_VON_NGF: 128.8,
    GB_VERKEHRSFL_VF_VON_NGF: 54.14,
    GB_MIETFL_GESAMT: 1113.32,
    GB_MIETFL_1_BSB: 992.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990901,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1145.07,
    GB_NUTZFL_NF_VON_NGF: 893.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 251.82,
    GB_MIETFL_GESAMT: 1145.07,
    GB_MIETFL_1_BSB: 1145.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990902,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 442.24,
    GB_NUTZFL_NF_VON_NGF: 335.78,
    GB_TECHNIKFL_TF_VON_NGF: 4.09,
    GB_VERKEHRSFL_VF_VON_NGF: 102.37,
    GB_MIETFL_GESAMT: 442.24,
    GB_MIETFL_1_BSB: 431.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990903,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 696.33,
    GB_NUTZFL_NF_VON_NGF: 528.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 167.81,
    GB_MIETFL_GESAMT: 696.33,
    GB_MIETFL_1_BSB: 693.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990904,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 30.1,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.1,
    GB_MIETFL_GESAMT: 30.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990905,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.32,
    GB_NUTZFL_NF_VON_NGF: 147.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.01,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '9',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 1990906,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/14',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 927.51,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 927.51,
    GB_MIETFL_GESAMT: 927.51,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990907,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/15',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9.61,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.61,
    GB_MIETFL_GESAMT: 9.61,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077016,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/16',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.4,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.4,
    GB_MIETFL_GESAMT: 15.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077017,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/17',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.4,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.4,
    GB_MIETFL_GESAMT: 15.4,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077018,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/18',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8.22,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.22,
    GB_MIETFL_GESAMT: 8.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077019,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704014,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/704014',
    GB_SAP_GB_ID_PKF: '7000/704014/19',
    GB_SAP_GB_BEZEICHNUNG: 'Musikgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Musikgeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 113.01,
    GB_NUTZFL_NF_VON_NGF: 95.67,
    GB_TECHNIKFL_TF_VON_NGF: 8.67,
    GB_VERKEHRSFL_VF_VON_NGF: 8.67,
    GB_MIETFL_GESAMT: 113.01,
    GB_MIETFL_1_BSB: 113.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5916610,
    GB_WE_OBJ_ID_FKT: 1989211,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 5366015,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.601829',
    GB_LAENGENGRAD_OESTLICH: '10.011432',
    GB_NETTOGRUNDFL_NGF: 1132.1,
    GB_NUTZFL_NF_VON_NGF: 896.98,
    GB_TECHNIKFL_TF_VON_NGF: 58.81,
    GB_VERKEHRSFL_VF_VON_NGF: 176.31,
    GB_MIETFL_GESAMT: 1132.1,
    GB_MIETFL_1_BSB: 1073.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990918,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.601260528',
    GB_LAENGENGRAD_OESTLICH: '10.011583692',
    GB_NETTOGRUNDFL_NGF: 133.19,
    GB_NUTZFL_NF_VON_NGF: 84.94,
    GB_TECHNIKFL_TF_VON_NGF: 23.05,
    GB_VERKEHRSFL_VF_VON_NGF: 25.2,
    GB_MIETFL_GESAMT: 133.19,
    GB_MIETFL_1_BSB: 72.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990919,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.601985',
    GB_LAENGENGRAD_OESTLICH: '10.011029',
    GB_NETTOGRUNDFL_NGF: 681.46,
    GB_NUTZFL_NF_VON_NGF: 587.91,
    GB_TECHNIKFL_TF_VON_NGF: 58.92,
    GB_VERKEHRSFL_VF_VON_NGF: 34.63,
    GB_MIETFL_GESAMT: 681.46,
    GB_MIETFL_1_BSB: 622.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990920,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.601011',
    GB_LAENGENGRAD_OESTLICH: '10.012716',
    GB_NETTOGRUNDFL_NGF: 619.4,
    GB_NUTZFL_NF_VON_NGF: 578.7,
    GB_TECHNIKFL_TF_VON_NGF: 21.83,
    GB_VERKEHRSFL_VF_VON_NGF: 18.87,
    GB_MIETFL_GESAMT: 619.4,
    GB_MIETFL_1_BSB: 619.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990921,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.601638589',
    GB_LAENGENGRAD_OESTLICH: '10.011199427',
    GB_NETTOGRUNDFL_NGF: 311.64,
    GB_NUTZFL_NF_VON_NGF: 248.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 62.81,
    GB_MIETFL_GESAMT: 311.64,
    GB_MIETFL_1_BSB: 311.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990922,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.601474199',
    GB_LAENGENGRAD_OESTLICH: '10.011982554',
    GB_NETTOGRUNDFL_NGF: 1357.19,
    GB_NUTZFL_NF_VON_NGF: 951.99,
    GB_TECHNIKFL_TF_VON_NGF: 1.71,
    GB_VERKEHRSFL_VF_VON_NGF: 403.49,
    GB_MIETFL_GESAMT: 1357.19,
    GB_MIETFL_1_BSB: 1357.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990923,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.602131',
    GB_LAENGENGRAD_OESTLICH: '10.012467',
    GB_NETTOGRUNDFL_NGF: 1322.78,
    GB_NUTZFL_NF_VON_NGF: 1102.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 219.88,
    GB_MIETFL_GESAMT: 1322.78,
    GB_MIETFL_1_BSB: 1312.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990924,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/8',
    GB_SAP_GB_BEZEICHNUNG: 'GBS mit Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'GBS mit Sporthalle',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.601358',
    GB_LAENGENGRAD_OESTLICH: '10.012822',
    GB_NETTOGRUNDFL_NGF: 1602.99,
    GB_NUTZFL_NF_VON_NGF: 1306.83,
    GB_TECHNIKFL_TF_VON_NGF: 39.13,
    GB_VERKEHRSFL_VF_VON_NGF: 257.03,
    GB_MIETFL_GESAMT: 1602.99,
    GB_MIETFL_1_BSB: 1596.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '09.05.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5457432,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704017,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704017',
    GB_SAP_GB_ID_PKF: '7000/704017/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 331.38,
    GB_NUTZFL_NF_VON_NGF: 292.27,
    GB_TECHNIKFL_TF_VON_NGF: 2.67,
    GB_VERKEHRSFL_VF_VON_NGF: 36.44,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.04.20',
    GB_GB_OBJ_ID_PKT: 5483117,
    GB_WE_OBJ_ID_FKT: 1989214,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704026',
    GB_SAP_GB_ID_PKF: '7000/704026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4586.12,
    GB_NUTZFL_NF_VON_NGF: 3272.85,
    GB_TECHNIKFL_TF_VON_NGF: 84.53,
    GB_VERKEHRSFL_VF_VON_NGF: 1228.74,
    GB_MIETFL_GESAMT: 4586.12,
    GB_MIETFL_1_BSB: 3692.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990970,
    GB_WE_OBJ_ID_FKT: 1989223,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704026',
    GB_SAP_GB_ID_PKF: '7000/704026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.584990',
    GB_LAENGENGRAD_OESTLICH: '10.014122',
    GB_NETTOGRUNDFL_NGF: 500.64,
    GB_NUTZFL_NF_VON_NGF: 463.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.81,
    GB_MIETFL_GESAMT: 500.64,
    GB_MIETFL_1_BSB: 471.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.07.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990971,
    GB_WE_OBJ_ID_FKT: 1989223,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704026',
    GB_SAP_GB_ID_PKF: '7000/704026/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406995,
    GB_WE_OBJ_ID_FKT: 1989223,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704026',
    GB_SAP_GB_ID_PKF: '7000/704026/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406996,
    GB_WE_OBJ_ID_FKT: 1989223,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1925,
    GB_BREITENGRAD_NOERDLICH: '53.592635',
    GB_LAENGENGRAD_OESTLICH: '10.010520',
    GB_NETTOGRUNDFL_NGF: 5609.45,
    GB_NUTZFL_NF_VON_NGF: 4132.2,
    GB_TECHNIKFL_TF_VON_NGF: 165.12,
    GB_VERKEHRSFL_VF_VON_NGF: 1312.13,
    GB_MIETFL_GESAMT: 5609.45,
    GB_MIETFL_1_BSB: 5092.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991016,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.593164',
    GB_LAENGENGRAD_OESTLICH: '10.011198',
    GB_NETTOGRUNDFL_NGF: 625.34,
    GB_NUTZFL_NF_VON_NGF: 596.83,
    GB_TECHNIKFL_TF_VON_NGF: 11.34,
    GB_VERKEHRSFL_VF_VON_NGF: 17.17,
    GB_MIETFL_GESAMT: 625.34,
    GB_MIETFL_1_BSB: 625.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991017,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1671.71,
    GB_NUTZFL_NF_VON_NGF: 1352.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 319.32,
    GB_MIETFL_GESAMT: 1671.71,
    GB_MIETFL_1_BSB: 1671.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991018,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 655.89,
    GB_NUTZFL_NF_VON_NGF: 524.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 131.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.04.17',
    GB_GB_OBJ_ID_PKT: 1991020,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1044.99,
    GB_NUTZFL_NF_VON_NGF: 836.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 208.65,
    GB_MIETFL_GESAMT: 1044.99,
    GB_MIETFL_1_BSB: 1044.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991021,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/4',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1886,
    GB_BREITENGRAD_NOERDLICH: '53.591991',
    GB_LAENGENGRAD_OESTLICH: '10.010550',
    GB_NETTOGRUNDFL_NGF: 5734.83,
    GB_NUTZFL_NF_VON_NGF: 4506.76,
    GB_TECHNIKFL_TF_VON_NGF: 73.87,
    GB_VERKEHRSFL_VF_VON_NGF: 1154.2,
    GB_MIETFL_GESAMT: 5734.83,
    GB_MIETFL_1_BSB: 5529.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991019,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1828.99,
    GB_NUTZFL_NF_VON_NGF: 1310.1,
    GB_TECHNIKFL_TF_VON_NGF: 27.68,
    GB_VERKEHRSFL_VF_VON_NGF: 491.21,
    GB_MIETFL_GESAMT: 1828.99,
    GB_MIETFL_1_BSB: 1825.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407003,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704035,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704035',
    GB_SAP_GB_ID_PKF: '7000/704035/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mischgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mischgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7224918,
    GB_WE_OBJ_ID_FKT: 1989232,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1914,
    GB_BREITENGRAD_NOERDLICH: '53.589813',
    GB_LAENGENGRAD_OESTLICH: '10.006929',
    GB_NETTOGRUNDFL_NGF: 8342.27,
    GB_NUTZFL_NF_VON_NGF: 6346.8,
    GB_TECHNIKFL_TF_VON_NGF: 88.48,
    GB_VERKEHRSFL_VF_VON_NGF: 1906.99,
    GB_MIETFL_GESAMT: 8342.27,
    GB_MIETFL_1_BSB: 6700.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990983,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: '53.589936',
    GB_LAENGENGRAD_OESTLICH: '10.007876',
    GB_NETTOGRUNDFL_NGF: 1881.11,
    GB_NUTZFL_NF_VON_NGF: 1522.81,
    GB_TECHNIKFL_TF_VON_NGF: 17.83,
    GB_VERKEHRSFL_VF_VON_NGF: 340.47,
    GB_MIETFL_GESAMT: 1881.11,
    GB_MIETFL_1_BSB: 1881.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.07',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1990984,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406999,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1212.94,
    GB_NUTZFL_NF_VON_NGF: 939.5,
    GB_TECHNIKFL_TF_VON_NGF: 10.58,
    GB_VERKEHRSFL_VF_VON_NGF: 262.86,
    GB_MIETFL_GESAMT: 1212.94,
    GB_MIETFL_1_BSB: 1207.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407000,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.44,
    GB_NUTZFL_NF_VON_NGF: 728.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.17',
    GB_GB_OBJ_ID_PKT: 4352746,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704029,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704029',
    GB_SAP_GB_ID_PKF: '7000/704029/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 149.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.17',
    GB_GB_OBJ_ID_PKT: 4352747,
    GB_WE_OBJ_ID_FKT: 1989226,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.587119',
    GB_LAENGENGRAD_OESTLICH: '10.027435',
    GB_NETTOGRUNDFL_NGF: 6289.85,
    GB_NUTZFL_NF_VON_NGF: 4362.77,
    GB_TECHNIKFL_TF_VON_NGF: 134.76,
    GB_VERKEHRSFL_VF_VON_NGF: 1792.32,
    GB_MIETFL_GESAMT: 6289.85,
    GB_MIETFL_1_BSB: 6135.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991156,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991157,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.27,
    GB_NUTZFL_NF_VON_NGF: 371.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 117.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991158,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 875.3,
    GB_NUTZFL_NF_VON_NGF: 697.34,
    GB_TECHNIKFL_TF_VON_NGF: 8.81,
    GB_VERKEHRSFL_VF_VON_NGF: 169.15,
    GB_MIETFL_GESAMT: 875.3,
    GB_MIETFL_1_BSB: 875.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991159,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1943.1,
    GB_NUTZFL_NF_VON_NGF: 1631.54,
    GB_TECHNIKFL_TF_VON_NGF: 51.86,
    GB_VERKEHRSFL_VF_VON_NGF: 259.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.04.14',
    GB_GB_OBJ_ID_PKT: 1991160,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1400.95,
    GB_NUTZFL_NF_VON_NGF: 1007.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 393.66,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991161,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.97,
    GB_NUTZFL_NF_VON_NGF: 236.83,
    GB_TECHNIKFL_TF_VON_NGF: 1.75,
    GB_VERKEHRSFL_VF_VON_NGF: 73.39,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991162,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1369.23,
    GB_NUTZFL_NF_VON_NGF: 978.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 391.18,
    GB_MIETFL_GESAMT: 1369.23,
    GB_MIETFL_1_BSB: 1369.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991163,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/9',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 879.25,
    GB_NUTZFL_NF_VON_NGF: 647.07,
    GB_TECHNIKFL_TF_VON_NGF: 23.13,
    GB_VERKEHRSFL_VF_VON_NGF: 209.05,
    GB_MIETFL_GESAMT: 879.25,
    GB_MIETFL_1_BSB: 879.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991164,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 331.28,
    GB_NUTZFL_NF_VON_NGF: 282.93,
    GB_TECHNIKFL_TF_VON_NGF: 2.5,
    GB_VERKEHRSFL_VF_VON_NGF: 45.85,
    GB_MIETFL_GESAMT: 331.28,
    GB_MIETFL_1_BSB: 331.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991165,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991166,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengang',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengang',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991167,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/13',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.11,
    GB_NUTZFL_NF_VON_NGF: 10.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991168,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/14',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.11,
    GB_NUTZFL_NF_VON_NGF: 10.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991169,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/15',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3532.58,
    GB_NUTZFL_NF_VON_NGF: 2738.67,
    GB_TECHNIKFL_TF_VON_NGF: 87.02,
    GB_VERKEHRSFL_VF_VON_NGF: 706.89,
    GB_MIETFL_GESAMT: 3532.58,
    GB_MIETFL_1_BSB: 3499.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.06.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5406848,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/16',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.587854',
    GB_LAENGENGRAD_OESTLICH: '10.027907',
    GB_NETTOGRUNDFL_NGF: 1943.1,
    GB_NUTZFL_NF_VON_NGF: 1631.54,
    GB_TECHNIKFL_TF_VON_NGF: 51.86,
    GB_VERKEHRSFL_VF_VON_NGF: 259.7,
    GB_MIETFL_GESAMT: 1943.1,
    GB_MIETFL_1_BSB: 1943.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407017,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/17',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7238608,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 317.51,
    GB_NUTZFL_NF_VON_NGF: 289.74,
    GB_TECHNIKFL_TF_VON_NGF: 2.81,
    GB_VERKEHRSFL_VF_VON_NGF: 24.96,
    GB_MIETFL_GESAMT: 317.51,
    GB_MIETFL_1_BSB: 317.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5913367,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704056,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704056',
    GB_SAP_GB_ID_PKF: '7000/704056/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 495.88,
    GB_NUTZFL_NF_VON_NGF: 426.55,
    GB_TECHNIKFL_TF_VON_NGF: 5.1,
    GB_VERKEHRSFL_VF_VON_NGF: 64.23,
    GB_MIETFL_GESAMT: 475.1,
    GB_MIETFL_1_BSB: 475.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.07.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7238609,
    GB_WE_OBJ_ID_FKT: 1989253,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 6785771,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 339.46,
    GB_NUTZFL_NF_VON_NGF: 269.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 69.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991022,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 20.9,
    GB_NUTZFL_NF_VON_NGF: 18.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 2.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991023,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 202.51,
    GB_NUTZFL_NF_VON_NGF: 162.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.72,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991024,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 342.77,
    GB_NUTZFL_NF_VON_NGF: 271.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.67,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991025,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 706.18,
    GB_NUTZFL_NF_VON_NGF: 610.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 95.98,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991026,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 321.71,
    GB_NUTZFL_NF_VON_NGF: 277.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991027,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: '53.586417',
    GB_LAENGENGRAD_OESTLICH: '10.010927',
    GB_NETTOGRUNDFL_NGF: 417.64,
    GB_NUTZFL_NF_VON_NGF: 374.72,
    GB_TECHNIKFL_TF_VON_NGF: 6.97,
    GB_VERKEHRSFL_VF_VON_NGF: 35.95,
    GB_MIETFL_GESAMT: 417.64,
    GB_MIETFL_1_BSB: 417.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991028,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/8',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4349.89,
    GB_NUTZFL_NF_VON_NGF: 3099.8,
    GB_TECHNIKFL_TF_VON_NGF: 169.99,
    GB_VERKEHRSFL_VF_VON_NGF: 1080.1,
    GB_MIETFL_GESAMT: 4349.89,
    GB_MIETFL_1_BSB: 4343.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5407004,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 4352748,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704036,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/704036',
    GB_SAP_GB_ID_PKF: '7000/704036/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552749,
    GB_WE_OBJ_ID_FKT: 1989233,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800451,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704080',
    GB_SAP_GB_ID_PKF: '7000/704080/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1716.91,
    GB_NUTZFL_NF_VON_NGF: 1636.25,
    GB_TECHNIKFL_TF_VON_NGF: 23.85,
    GB_VERKEHRSFL_VF_VON_NGF: 56.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.16',
    GB_GB_OBJ_ID_PKT: 2078147,
    GB_WE_OBJ_ID_FKT: 2078144,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704080,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704080',
    GB_SAP_GB_ID_PKF: '7000/704080/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1386.57,
    GB_NUTZFL_NF_VON_NGF: 1353.34,
    GB_TECHNIKFL_TF_VON_NGF: 17.18,
    GB_VERKEHRSFL_VF_VON_NGF: 16.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.16',
    GB_GB_OBJ_ID_PKT: 2078148,
    GB_WE_OBJ_ID_FKT: 2078144,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704073,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704073',
    GB_SAP_GB_ID_PKF: '7000/704073/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1991230,
    GB_WE_OBJ_ID_FKT: 1989268,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704073,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704073',
    GB_SAP_GB_ID_PKF: '7000/704073/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1991231,
    GB_WE_OBJ_ID_FKT: 1989268,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704055',
    GB_SAP_GB_ID_PKF: '7000/704055/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991153,
    GB_WE_OBJ_ID_FKT: 1989252,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704055',
    GB_SAP_GB_ID_PKF: '7000/704055/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991154,
    GB_WE_OBJ_ID_FKT: 1989252,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704055,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704055',
    GB_SAP_GB_ID_PKF: '7000/704055/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991155,
    GB_WE_OBJ_ID_FKT: 1989252,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704074',
    GB_SAP_GB_ID_PKF: '7000/704074/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.23,
    GB_NUTZFL_NF_VON_NGF: 136.45,
    GB_TECHNIKFL_TF_VON_NGF: 22.51,
    GB_VERKEHRSFL_VF_VON_NGF: 6.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1991232,
    GB_WE_OBJ_ID_FKT: 1989269,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704074,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704074',
    GB_SAP_GB_ID_PKF: '7000/704074/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.78,
    GB_NUTZFL_NF_VON_NGF: 183.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1991233,
    GB_WE_OBJ_ID_FKT: 1989269,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704074,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704074',
    GB_SAP_GB_ID_PKF: '7000/704074/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.59,
    GB_NUTZFL_NF_VON_NGF: 188.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1991234,
    GB_WE_OBJ_ID_FKT: 1989269,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704074,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704074',
    GB_SAP_GB_ID_PKF: '7000/704074/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 216.02,
    GB_NUTZFL_NF_VON_NGF: 189.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1991235,
    GB_WE_OBJ_ID_FKT: 1989269,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704083,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704083',
    GB_SAP_GB_ID_PKF: '7000/704083/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1908,
    GB_BREITENGRAD_NOERDLICH: '53.5730142',
    GB_LAENGENGRAD_OESTLICH: '10.0489969',
    GB_NETTOGRUNDFL_NGF: 5171.5,
    GB_NUTZFL_NF_VON_NGF: 3621.81,
    GB_TECHNIKFL_TF_VON_NGF: 42.41,
    GB_VERKEHRSFL_VF_VON_NGF: 1507.28,
    GB_MIETFL_GESAMT: 5171.5,
    GB_MIETFL_1_BSB: 4852.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225404,
    GB_WE_OBJ_ID_FKT: 7225401,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704083,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704083',
    GB_SAP_GB_ID_PKF: '7000/704083/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225405,
    GB_WE_OBJ_ID_FKT: 7225401,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 3622167,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708016',
    GB_SAP_GB_ID_PKF: '7000/708016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: '53.575718997',
    GB_LAENGENGRAD_OESTLICH: '10.006530220',
    GB_NETTOGRUNDFL_NGF: 719.52,
    GB_NUTZFL_NF_VON_NGF: 628.19,
    GB_TECHNIKFL_TF_VON_NGF: 9.76,
    GB_VERKEHRSFL_VF_VON_NGF: 81.57,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236690,
    GB_WE_OBJ_ID_FKT: 7236656,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8273.08,
    GB_NUTZFL_NF_VON_NGF: 5775.02,
    GB_TECHNIKFL_TF_VON_NGF: 110.01,
    GB_VERKEHRSFL_VF_VON_NGF: 2388.05,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991188,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 814.43,
    GB_NUTZFL_NF_VON_NGF: 593.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 220.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991189,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991190,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991191,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991192,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991193,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704062,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/704062',
    GB_SAP_GB_ID_PKF: '7000/704062/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1991194,
    GB_WE_OBJ_ID_FKT: 1989259,
    GB_RGN_OBJ_ID_FKT: 5445906,
    GB_OM_OBJ_ID_FKT: 1800539,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705096,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705096',
    GB_SAP_GB_ID_PKF: '7000/705096/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14772.71,
    GB_NUTZFL_NF_VON_NGF: 10834.0,
    GB_TECHNIKFL_TF_VON_NGF: 228.0,
    GB_VERKEHRSFL_VF_VON_NGF: 3710.71,
    GB_MIETFL_GESAMT: 14772.71,
    GB_MIETFL_1_BSB: 14659.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992014,
    GB_WE_OBJ_ID_FKT: 1989370,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705096,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705096',
    GB_SAP_GB_ID_PKF: '7000/705096/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.09.21',
    GB_GB_OBJ_ID_PKT: 5171088,
    GB_WE_OBJ_ID_FKT: 1989370,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705096,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705096',
    GB_SAP_GB_ID_PKF: '7000/705096/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.613750',
    GB_LAENGENGRAD_OESTLICH: '10.120528',
    GB_NETTOGRUNDFL_NGF: 1513.61,
    GB_NUTZFL_NF_VON_NGF: 1468.24,
    GB_TECHNIKFL_TF_VON_NGF: 16.97,
    GB_VERKEHRSFL_VF_VON_NGF: 28.4,
    GB_MIETFL_GESAMT: 1513.61,
    GB_MIETFL_1_BSB: 1513.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5171089,
    GB_WE_OBJ_ID_FKT: 1989370,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705096,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705096',
    GB_SAP_GB_ID_PKF: '7000/705096/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.03.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7127494,
    GB_WE_OBJ_ID_FKT: 1989370,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: 2049891,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705097,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705097',
    GB_SAP_GB_ID_PKF: '7000/705097/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.06.16',
    GB_GB_OBJ_ID_PKT: 1992015,
    GB_WE_OBJ_ID_FKT: 1989371,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705097,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705097',
    GB_SAP_GB_ID_PKF: '7000/705097/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.06.16',
    GB_GB_OBJ_ID_PKT: 1992016,
    GB_WE_OBJ_ID_FKT: 1989371,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705097,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705097',
    GB_SAP_GB_ID_PKF: '7000/705097/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1261.25,
    GB_NUTZFL_NF_VON_NGF: 1032.08,
    GB_TECHNIKFL_TF_VON_NGF: 12.63,
    GB_VERKEHRSFL_VF_VON_NGF: 216.54,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1992017,
    GB_WE_OBJ_ID_FKT: 1989371,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705097,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705097',
    GB_SAP_GB_ID_PKF: '7000/705097/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 323.17,
    GB_NUTZFL_NF_VON_NGF: 317.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.73,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '16.06.16',
    GB_GB_OBJ_ID_PKT: 1992018,
    GB_WE_OBJ_ID_FKT: 1989371,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705098,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705098',
    GB_SAP_GB_ID_PKF: '7000/705098/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1702.6,
    GB_NUTZFL_NF_VON_NGF: 1416.06,
    GB_TECHNIKFL_TF_VON_NGF: 54.94,
    GB_VERKEHRSFL_VF_VON_NGF: 231.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.06.16',
    GB_GB_OBJ_ID_PKT: 1992019,
    GB_WE_OBJ_ID_FKT: 1989372,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705098,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705098',
    GB_SAP_GB_ID_PKF: '7000/705098/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1522.47,
    GB_NUTZFL_NF_VON_NGF: 1171.44,
    GB_TECHNIKFL_TF_VON_NGF: 98.84,
    GB_VERKEHRSFL_VF_VON_NGF: 252.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.06.16',
    GB_GB_OBJ_ID_PKT: 1992020,
    GB_WE_OBJ_ID_FKT: 1989372,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705098,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705098',
    GB_SAP_GB_ID_PKF: '7000/705098/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.06.16',
    GB_GB_OBJ_ID_PKT: 1992021,
    GB_WE_OBJ_ID_FKT: 1989372,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705095,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705095',
    GB_SAP_GB_ID_PKF: '7000/705095/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 29558.05,
    GB_NUTZFL_NF_VON_NGF: 17925.51,
    GB_TECHNIKFL_TF_VON_NGF: 1464.19,
    GB_VERKEHRSFL_VF_VON_NGF: 10168.35,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.16',
    GB_GB_OBJ_ID_PKT: 1992011,
    GB_WE_OBJ_ID_FKT: 1989369,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705095,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705095',
    GB_SAP_GB_ID_PKF: '7000/705095/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.16',
    GB_GB_OBJ_ID_PKT: 1992012,
    GB_WE_OBJ_ID_FKT: 1989369,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705095,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705095',
    GB_SAP_GB_ID_PKF: '7000/705095/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6089.76,
    GB_NUTZFL_NF_VON_NGF: 4866.67,
    GB_TECHNIKFL_TF_VON_NGF: 449.74,
    GB_VERKEHRSFL_VF_VON_NGF: 773.35,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.16',
    GB_GB_OBJ_ID_PKT: 1992013,
    GB_WE_OBJ_ID_FKT: 1989369,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705095,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705095',
    GB_SAP_GB_ID_PKF: '7000/705095/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | bau Campus Steilshoop Beginn 2016',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5400634,
    GB_WE_OBJ_ID_FKT: 1989369,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708004',
    GB_SAP_GB_ID_PKF: '7000/708004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.607461606',
    GB_LAENGENGRAD_OESTLICH: '10.120061222',
    GB_NETTOGRUNDFL_NGF: 3008.0,
    GB_NUTZFL_NF_VON_NGF: 3008.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236668,
    GB_WE_OBJ_ID_FKT: 7236644,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708004',
    GB_SAP_GB_ID_PKF: '7000/708004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Einfeldsporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Einfeldsporthalle',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.608198',
    GB_LAENGENGRAD_OESTLICH: '10.119783',
    GB_NETTOGRUNDFL_NGF: 625.23,
    GB_NUTZFL_NF_VON_NGF: 529.25,
    GB_TECHNIKFL_TF_VON_NGF: 34.74,
    GB_VERKEHRSFL_VF_VON_NGF: 61.24,
    GB_MIETFL_GESAMT: 25.88,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365771,
    GB_WE_OBJ_ID_FKT: 7236644,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7486811,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708006',
    GB_SAP_GB_ID_PKF: '7000/708006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schulungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Schulungsgeb?ude',
    GB_BAUJAHR: 1915,
    GB_BREITENGRAD_NOERDLICH: '53.640699512',
    GB_LAENGENGRAD_OESTLICH: '10.081300761',
    GB_NETTOGRUNDFL_NGF: 405.6,
    GB_NUTZFL_NF_VON_NGF: 356.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.52,
    GB_MIETFL_GESAMT: 47.85,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236670,
    GB_WE_OBJ_ID_FKT: 7236646,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 5448333,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708019',
    GB_SAP_GB_ID_PKF: '7000/708019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Schullandheim',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Schullandheim',
    GB_BAUJAHR: 1884,
    GB_BREITENGRAD_NOERDLICH: '53.697911398',
    GB_LAENGENGRAD_OESTLICH: '10.127730911',
    GB_NETTOGRUNDFL_NGF: 2350.7,
    GB_NUTZFL_NF_VON_NGF: 1882.08,
    GB_TECHNIKFL_TF_VON_NGF: 39.11,
    GB_VERKEHRSFL_VF_VON_NGF: 429.51,
    GB_MIETFL_GESAMT: 535.05,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7236701,
    GB_WE_OBJ_ID_FKT: 7236659,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708019',
    GB_SAP_GB_ID_PKF: '7000/708019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7446780,
    GB_WE_OBJ_ID_FKT: 7236659,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7371755,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 585.61,
    GB_NUTZFL_NF_VON_NGF: 454.45,
    GB_TECHNIKFL_TF_VON_NGF: 12.97,
    GB_VERKEHRSFL_VF_VON_NGF: 118.19,
    GB_MIETFL_GESAMT: 585.61,
    GB_MIETFL_1_BSB: 466.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991398,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 254.52,
    GB_NUTZFL_NF_VON_NGF: 209.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.16',
    GB_GB_OBJ_ID_PKT: 1991399,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 386.48,
    GB_NUTZFL_NF_VON_NGF: 332.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.97,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.16',
    GB_GB_OBJ_ID_PKT: 1991400,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.16',
    GB_GB_OBJ_ID_PKT: 1991401,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.87,
    GB_NUTZFL_NF_VON_NGF: 140.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.11.16',
    GB_GB_OBJ_ID_PKT: 1991402,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 770.88,
    GB_NUTZFL_NF_VON_NGF: 576.55,
    GB_TECHNIKFL_TF_VON_NGF: 91.21,
    GB_VERKEHRSFL_VF_VON_NGF: 103.12,
    GB_MIETFL_GESAMT: 760.23,
    GB_MIETFL_1_BSB: 555.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.06.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991403,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 872.81,
    GB_NUTZFL_NF_VON_NGF: 742.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 130.67,
    GB_MIETFL_GESAMT: 872.81,
    GB_MIETFL_1_BSB: 872.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.06.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991404,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1040.41,
    GB_NUTZFL_NF_VON_NGF: 855.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 184.78,
    GB_MIETFL_GESAMT: 1040.41,
    GB_MIETFL_1_BSB: 1040.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.06.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991405,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 416.04,
    GB_NUTZFL_NF_VON_NGF: 388.34,
    GB_TECHNIKFL_TF_VON_NGF: 7.61,
    GB_VERKEHRSFL_VF_VON_NGF: 20.09,
    GB_MIETFL_GESAMT: 416.04,
    GB_MIETFL_1_BSB: 416.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.06.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991406,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.671030',
    GB_LAENGENGRAD_OESTLICH: '10.131389',
    GB_NETTOGRUNDFL_NGF: 624.79,
    GB_NUTZFL_NF_VON_NGF: 581.3,
    GB_TECHNIKFL_TF_VON_NGF: 24.57,
    GB_VERKEHRSFL_VF_VON_NGF: 18.92,
    GB_MIETFL_GESAMT: 624.79,
    GB_MIETFL_1_BSB: 624.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.03.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991407,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 457.53,
    GB_NUTZFL_NF_VON_NGF: 366.12,
    GB_TECHNIKFL_TF_VON_NGF: 4.9,
    GB_VERKEHRSFL_VF_VON_NGF: 86.51,
    GB_MIETFL_GESAMT: 457.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 225.21,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991408,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 467.63,
    GB_NUTZFL_NF_VON_NGF: 467.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.12.16',
    GB_GB_OBJ_ID_PKT: 1991409,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705020,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705020',
    GB_SAP_GB_ID_PKF: '7000/705020/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1192.34,
    GB_NUTZFL_NF_VON_NGF: 778.56,
    GB_TECHNIKFL_TF_VON_NGF: 24.67,
    GB_VERKEHRSFL_VF_VON_NGF: 389.11,
    GB_MIETFL_GESAMT: 1192.34,
    GB_MIETFL_1_BSB: 952.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.04.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399653,
    GB_WE_OBJ_ID_FKT: 1989294,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.672070203',
    GB_LAENGENGRAD_OESTLICH: '10.137006978',
    GB_NETTOGRUNDFL_NGF: 6858.4,
    GB_NUTZFL_NF_VON_NGF: 5196.94,
    GB_TECHNIKFL_TF_VON_NGF: 50.63,
    GB_VERKEHRSFL_VF_VON_NGF: 1610.83,
    GB_MIETFL_GESAMT: 6858.4,
    GB_MIETFL_1_BSB: 6852.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991410,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.671234',
    GB_LAENGENGRAD_OESTLICH: '10.130674',
    GB_NETTOGRUNDFL_NGF: 1674.08,
    GB_NUTZFL_NF_VON_NGF: 1510.22,
    GB_TECHNIKFL_TF_VON_NGF: 24.65,
    GB_VERKEHRSFL_VF_VON_NGF: 139.21,
    GB_MIETFL_GESAMT: 1674.08,
    GB_MIETFL_1_BSB: 1674.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991411,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/3',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.672252',
    GB_LAENGENGRAD_OESTLICH: '10.136948',
    GB_NETTOGRUNDFL_NGF: 23.52,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 23.52,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 23.52,
    GB_MIETFL_1_BSB: 23.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991412,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.672226',
    GB_LAENGENGRAD_OESTLICH: '10.137318',
    GB_NETTOGRUNDFL_NGF: 84.93,
    GB_NUTZFL_NF_VON_NGF: 70.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.58,
    GB_MIETFL_GESAMT: 84.93,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991413,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.671823',
    GB_LAENGENGRAD_OESTLICH: '10.137981',
    GB_NETTOGRUNDFL_NGF: 3548.42,
    GB_NUTZFL_NF_VON_NGF: 2518.04,
    GB_TECHNIKFL_TF_VON_NGF: 21.09,
    GB_VERKEHRSFL_VF_VON_NGF: 1009.29,
    GB_MIETFL_GESAMT: 3548.42,
    GB_MIETFL_1_BSB: 3425.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399654,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.670240',
    GB_LAENGENGRAD_OESTLICH: '10.137509',
    GB_NETTOGRUNDFL_NGF: 638.34,
    GB_NUTZFL_NF_VON_NGF: 602.19,
    GB_TECHNIKFL_TF_VON_NGF: 15.7,
    GB_VERKEHRSFL_VF_VON_NGF: 20.45,
    GB_MIETFL_GESAMT: 638.34,
    GB_MIETFL_1_BSB: 638.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479153,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705021,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705021',
    GB_SAP_GB_ID_PKF: '7000/705021/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.19',
    GB_GB_OBJ_ID_PKT: 5399220,
    GB_WE_OBJ_ID_FKT: 1989295,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 604.44,
    GB_NUTZFL_NF_VON_NGF: 431.35,
    GB_TECHNIKFL_TF_VON_NGF: 83.02,
    GB_VERKEHRSFL_VF_VON_NGF: 90.07,
    GB_MIETFL_GESAMT: 604.44,
    GB_MIETFL_1_BSB: 515.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991460,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 149.52,
    GB_NUTZFL_NF_VON_NGF: 100.47,
    GB_TECHNIKFL_TF_VON_NGF: 19.62,
    GB_VERKEHRSFL_VF_VON_NGF: 29.43,
    GB_MIETFL_GESAMT: 149.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991461,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991462,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 146.97,
    GB_NUTZFL_NF_VON_NGF: 132.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.31,
    GB_MIETFL_GESAMT: 146.97,
    GB_MIETFL_1_BSB: 146.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991463,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 428.81,
    GB_NUTZFL_NF_VON_NGF: 348.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.03,
    GB_MIETFL_GESAMT: 428.81,
    GB_MIETFL_1_BSB: 428.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991464,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 468.24,
    GB_NUTZFL_NF_VON_NGF: 389.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 78.51,
    GB_MIETFL_GESAMT: 468.24,
    GB_MIETFL_1_BSB: 468.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991465,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.666010',
    GB_LAENGENGRAD_OESTLICH: '10.149778',
    GB_NETTOGRUNDFL_NGF: 624.87,
    GB_NUTZFL_NF_VON_NGF: 596.33,
    GB_TECHNIKFL_TF_VON_NGF: 8.7,
    GB_VERKEHRSFL_VF_VON_NGF: 19.84,
    GB_MIETFL_GESAMT: 624.87,
    GB_MIETFL_1_BSB: 624.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991466,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.5,
    GB_NUTZFL_NF_VON_NGF: 382.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.23,
    GB_MIETFL_GESAMT: 437.5,
    GB_MIETFL_1_BSB: 437.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991467,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 440.02,
    GB_NUTZFL_NF_VON_NGF: 359.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.15,
    GB_MIETFL_GESAMT: 440.02,
    GB_MIETFL_1_BSB: 440.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991468,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705027,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705027',
    GB_SAP_GB_ID_PKF: '7000/705027/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.665336',
    GB_LAENGENGRAD_OESTLICH: '10.148393',
    GB_NETTOGRUNDFL_NGF: 614.86,
    GB_NUTZFL_NF_VON_NGF: 571.29,
    GB_TECHNIKFL_TF_VON_NGF: 3.23,
    GB_VERKEHRSFL_VF_VON_NGF: 40.34,
    GB_MIETFL_GESAMT: 614.86,
    GB_MIETFL_1_BSB: 614.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5340410,
    GB_WE_OBJ_ID_FKT: 1989301,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1597.93,
    GB_NUTZFL_NF_VON_NGF: 1296.42,
    GB_TECHNIKFL_TF_VON_NGF: 47.1,
    GB_VERKEHRSFL_VF_VON_NGF: 254.41,
    GB_MIETFL_GESAMT: 1597.93,
    GB_MIETFL_1_BSB: 1378.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.06.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991489,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 424.36,
    GB_NUTZFL_NF_VON_NGF: 354.63,
    GB_TECHNIKFL_TF_VON_NGF: 1.89,
    GB_VERKEHRSFL_VF_VON_NGF: 67.84,
    GB_MIETFL_GESAMT: 424.36,
    GB_MIETFL_1_BSB: 424.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991490,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 938.71,
    GB_NUTZFL_NF_VON_NGF: 719.77,
    GB_TECHNIKFL_TF_VON_NGF: 7.89,
    GB_VERKEHRSFL_VF_VON_NGF: 211.05,
    GB_MIETFL_GESAMT: 938.71,
    GB_MIETFL_1_BSB: 938.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991491,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.704028',
    GB_LAENGENGRAD_OESTLICH: '10.108510',
    GB_NETTOGRUNDFL_NGF: 662.97,
    GB_NUTZFL_NF_VON_NGF: 624.54,
    GB_TECHNIKFL_TF_VON_NGF: 11.35,
    GB_VERKEHRSFL_VF_VON_NGF: 27.08,
    GB_MIETFL_GESAMT: 662.97,
    GB_MIETFL_1_BSB: 662.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991492,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.46,
    GB_NUTZFL_NF_VON_NGF: 173.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.89,
    GB_MIETFL_GESAMT: 192.46,
    GB_MIETFL_1_BSB: 192.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991493,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.68,
    GB_NUTZFL_NF_VON_NGF: 112.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.42,
    GB_VERKEHRSFL_VF_VON_NGF: 31.95,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.05.14',
    GB_GB_OBJ_ID_PKT: 1991494,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.9,
    GB_NUTZFL_NF_VON_NGF: 258.94,
    GB_TECHNIKFL_TF_VON_NGF: 3.74,
    GB_VERKEHRSFL_VF_VON_NGF: 49.22,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.08.14',
    GB_GB_OBJ_ID_PKT: 1991495,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.704036',
    GB_LAENGENGRAD_OESTLICH: '10.107754',
    GB_NETTOGRUNDFL_NGF: 311.97,
    GB_NUTZFL_NF_VON_NGF: 294.68,
    GB_TECHNIKFL_TF_VON_NGF: 9.27,
    GB_VERKEHRSFL_VF_VON_NGF: 8.02,
    GB_MIETFL_GESAMT: 311.97,
    GB_MIETFL_1_BSB: 311.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2052948,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.703625',
    GB_LAENGENGRAD_OESTLICH: '10.107493',
    GB_NETTOGRUNDFL_NGF: 1059.66,
    GB_NUTZFL_NF_VON_NGF: 793.32,
    GB_TECHNIKFL_TF_VON_NGF: 46.07,
    GB_VERKEHRSFL_VF_VON_NGF: 220.27,
    GB_MIETFL_GESAMT: 1059.66,
    GB_MIETFL_1_BSB: 1056.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399499,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705031,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705031',
    GB_SAP_GB_ID_PKF: '7000/705031/10',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399663,
    GB_WE_OBJ_ID_FKT: 1989305,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1617.99,
    GB_NUTZFL_NF_VON_NGF: 959.45,
    GB_TECHNIKFL_TF_VON_NGF: 35.68,
    GB_VERKEHRSFL_VF_VON_NGF: 622.86,
    GB_MIETFL_GESAMT: 1617.99,
    GB_MIETFL_1_BSB: 1617.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.09.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991582,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 421.47,
    GB_NUTZFL_NF_VON_NGF: 372.26,
    GB_TECHNIKFL_TF_VON_NGF: 7.73,
    GB_VERKEHRSFL_VF_VON_NGF: 41.48,
    GB_MIETFL_GESAMT: 421.47,
    GB_MIETFL_1_BSB: 421.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991583,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: '53.647374',
    GB_LAENGENGRAD_OESTLICH: '10.038369',
    GB_NETTOGRUNDFL_NGF: 663.77,
    GB_NUTZFL_NF_VON_NGF: 616.96,
    GB_TECHNIKFL_TF_VON_NGF: 17.45,
    GB_VERKEHRSFL_VF_VON_NGF: 29.36,
    GB_MIETFL_GESAMT: 663.77,
    GB_MIETFL_1_BSB: 663.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991584,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3647.39,
    GB_NUTZFL_NF_VON_NGF: 2744.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 902.61,
    GB_MIETFL_GESAMT: 3647.39,
    GB_MIETFL_1_BSB: 3647.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991585,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.84,
    GB_NUTZFL_NF_VON_NGF: 87.1,
    GB_TECHNIKFL_TF_VON_NGF: 4.07,
    GB_VERKEHRSFL_VF_VON_NGF: 11.67,
    GB_MIETFL_GESAMT: 102.84,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991586,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7371672,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705042,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705042',
    GB_SAP_GB_ID_PKF: '7000/705042/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7371673,
    GB_WE_OBJ_ID_FKT: 1989316,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.647326',
    GB_LAENGENGRAD_OESTLICH: '10.039754',
    GB_NETTOGRUNDFL_NGF: 3172.49,
    GB_NUTZFL_NF_VON_NGF: 2292.58,
    GB_TECHNIKFL_TF_VON_NGF: 120.41,
    GB_VERKEHRSFL_VF_VON_NGF: 759.5,
    GB_MIETFL_GESAMT: 3172.49,
    GB_MIETFL_1_BSB: 3062.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991653,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2819.8,
    GB_NUTZFL_NF_VON_NGF: 2320.43,
    GB_TECHNIKFL_TF_VON_NGF: 66.33,
    GB_VERKEHRSFL_VF_VON_NGF: 433.04,
    GB_MIETFL_GESAMT: 2819.8,
    GB_MIETFL_1_BSB: 2750.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991654,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.646635',
    GB_LAENGENGRAD_OESTLICH: '10.038449',
    GB_NETTOGRUNDFL_NGF: 1767.73,
    GB_NUTZFL_NF_VON_NGF: 1630.42,
    GB_TECHNIKFL_TF_VON_NGF: 35.1,
    GB_VERKEHRSFL_VF_VON_NGF: 102.21,
    GB_MIETFL_GESAMT: 1767.73,
    GB_MIETFL_1_BSB: 1767.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.04.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991655,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1154.5,
    GB_NUTZFL_NF_VON_NGF: 925.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 229.21,
    GB_MIETFL_GESAMT: 1154.5,
    GB_MIETFL_1_BSB: 1154.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991656,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 98.4,
    GB_NUTZFL_NF_VON_NGF: 86.7,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 11.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '04.11.19',
    GB_GB_OBJ_ID_PKT: 1991657,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705051,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705051',
    GB_SAP_GB_ID_PKF: '7000/705051/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7371674,
    GB_WE_OBJ_ID_FKT: 1989325,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1849.97,
    GB_NUTZFL_NF_VON_NGF: 1403.65,
    GB_TECHNIKFL_TF_VON_NGF: 78.58,
    GB_VERKEHRSFL_VF_VON_NGF: 367.74,
    GB_MIETFL_GESAMT: 1849.97,
    GB_MIETFL_1_BSB: 1160.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991879,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 126.69,
    GB_NUTZFL_NF_VON_NGF: 116.0,
    GB_TECHNIKFL_TF_VON_NGF: 10.69,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 126.69,
    GB_MIETFL_1_BSB: 126.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991880,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 226.05,
    GB_NUTZFL_NF_VON_NGF: 191.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.28,
    GB_MIETFL_GESAMT: 226.05,
    GB_MIETFL_1_BSB: 226.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991881,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 227.37,
    GB_NUTZFL_NF_VON_NGF: 193.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.21,
    GB_MIETFL_GESAMT: 227.37,
    GB_MIETFL_1_BSB: 227.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991882,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 227.47,
    GB_NUTZFL_NF_VON_NGF: 193.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.41,
    GB_MIETFL_GESAMT: 227.47,
    GB_MIETFL_1_BSB: 227.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991883,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 227.81,
    GB_NUTZFL_NF_VON_NGF: 193.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.8,
    GB_MIETFL_GESAMT: 227.81,
    GB_MIETFL_1_BSB: 227.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991884,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.646325',
    GB_LAENGENGRAD_OESTLICH: '10.051542',
    GB_NETTOGRUNDFL_NGF: 617.9,
    GB_NUTZFL_NF_VON_NGF: 588.97,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.27,
    GB_MIETFL_GESAMT: 617.9,
    GB_MIETFL_1_BSB: 617.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991885,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.646341',
    GB_LAENGENGRAD_OESTLICH: '10.052035',
    GB_NETTOGRUNDFL_NGF: 322.18,
    GB_NUTZFL_NF_VON_NGF: 303.51,
    GB_TECHNIKFL_TF_VON_NGF: 7.64,
    GB_VERKEHRSFL_VF_VON_NGF: 11.03,
    GB_MIETFL_GESAMT: 322.18,
    GB_MIETFL_1_BSB: 322.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991886,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 617.97,
    GB_NUTZFL_NF_VON_NGF: 525.03,
    GB_TECHNIKFL_TF_VON_NGF: 5.34,
    GB_VERKEHRSFL_VF_VON_NGF: 87.6,
    GB_MIETFL_GESAMT: 617.97,
    GB_MIETFL_1_BSB: 615.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991887,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 397.11,
    GB_NUTZFL_NF_VON_NGF: 379.08,
    GB_TECHNIKFL_TF_VON_NGF: 1.27,
    GB_VERKEHRSFL_VF_VON_NGF: 16.76,
    GB_MIETFL_GESAMT: 397.11,
    GB_MIETFL_1_BSB: 397.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991888,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705077,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705077',
    GB_SAP_GB_ID_PKF: '7000/705077/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 854.75,
    GB_NUTZFL_NF_VON_NGF: 740.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 114.43,
    GB_MIETFL_GESAMT: 854.75,
    GB_MIETFL_1_BSB: 854.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991889,
    GB_WE_OBJ_ID_FKT: 1989351,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 688.23,
    GB_NUTZFL_NF_VON_NGF: 547.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 140.6,
    GB_MIETFL_GESAMT: 688.23,
    GB_MIETFL_1_BSB: 688.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991710,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.01,
    GB_NUTZFL_NF_VON_NGF: 183.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.97,
    GB_MIETFL_GESAMT: 236.01,
    GB_MIETFL_1_BSB: 236.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991711,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.95,
    GB_NUTZFL_NF_VON_NGF: 210.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.05,
    GB_MIETFL_GESAMT: 213.95,
    GB_MIETFL_1_BSB: 213.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991712,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 313.14,
    GB_NUTZFL_NF_VON_NGF: 207.32,
    GB_TECHNIKFL_TF_VON_NGF: 49.34,
    GB_VERKEHRSFL_VF_VON_NGF: 56.48,
    GB_MIETFL_GESAMT: 313.14,
    GB_MIETFL_1_BSB: 236.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991713,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 516.98,
    GB_NUTZFL_NF_VON_NGF: 418.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 98.84,
    GB_MIETFL_GESAMT: 516.98,
    GB_MIETFL_1_BSB: 316.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991714,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.680954',
    GB_LAENGENGRAD_OESTLICH: '10.104769',
    GB_NETTOGRUNDFL_NGF: 625.73,
    GB_NUTZFL_NF_VON_NGF: 596.96,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.11,
    GB_MIETFL_GESAMT: 625.73,
    GB_MIETFL_1_BSB: 625.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991715,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 598.05,
    GB_NUTZFL_NF_VON_NGF: 481.89,
    GB_TECHNIKFL_TF_VON_NGF: 0.77,
    GB_VERKEHRSFL_VF_VON_NGF: 115.39,
    GB_MIETFL_GESAMT: 598.05,
    GB_MIETFL_1_BSB: 598.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991716,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 217.2,
    GB_NUTZFL_NF_VON_NGF: 181.83,
    GB_TECHNIKFL_TF_VON_NGF: 4.45,
    GB_VERKEHRSFL_VF_VON_NGF: 30.92,
    GB_MIETFL_GESAMT: 217.2,
    GB_MIETFL_1_BSB: 217.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991717,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.13,
    GB_NUTZFL_NF_VON_NGF: 155.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.66,
    GB_MIETFL_GESAMT: 173.13,
    GB_MIETFL_1_BSB: 173.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991718,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.95,
    GB_NUTZFL_NF_VON_NGF: 175.82,
    GB_TECHNIKFL_TF_VON_NGF: 7.53,
    GB_VERKEHRSFL_VF_VON_NGF: 32.6,
    GB_MIETFL_GESAMT: 215.95,
    GB_MIETFL_1_BSB: 215.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991719,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.95,
    GB_NUTZFL_NF_VON_NGF: 175.82,
    GB_TECHNIKFL_TF_VON_NGF: 7.53,
    GB_VERKEHRSFL_VF_VON_NGF: 32.6,
    GB_MIETFL_GESAMT: 215.95,
    GB_MIETFL_1_BSB: 215.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991720,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/12',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 72.86,
    GB_NUTZFL_NF_VON_NGF: 64.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.21,
    GB_MIETFL_GESAMT: 72.86,
    GB_MIETFL_1_BSB: 72.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991721,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 203.85,
    GB_NUTZFL_NF_VON_NGF: 191.03,
    GB_TECHNIKFL_TF_VON_NGF: 5.76,
    GB_VERKEHRSFL_VF_VON_NGF: 7.06,
    GB_MIETFL_GESAMT: 203.85,
    GB_MIETFL_1_BSB: 203.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991722,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1931,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 342.22,
    GB_NUTZFL_NF_VON_NGF: 258.19,
    GB_TECHNIKFL_TF_VON_NGF: 18.71,
    GB_VERKEHRSFL_VF_VON_NGF: 65.32,
    GB_MIETFL_GESAMT: 342.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 271.59,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991723,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705057,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705057',
    GB_SAP_GB_ID_PKF: '7000/705057/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552755,
    GB_WE_OBJ_ID_FKT: 1989331,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.665558942',
    GB_LAENGENGRAD_OESTLICH: '10.074547548',
    GB_NETTOGRUNDFL_NGF: 1746.91,
    GB_NUTZFL_NF_VON_NGF: 1373.67,
    GB_TECHNIKFL_TF_VON_NGF: 40.16,
    GB_VERKEHRSFL_VF_VON_NGF: 333.08,
    GB_MIETFL_GESAMT: 1746.91,
    GB_MIETFL_1_BSB: 1746.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991603,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.664859',
    GB_LAENGENGRAD_OESTLICH: '10.076000',
    GB_NETTOGRUNDFL_NGF: 664.44,
    GB_NUTZFL_NF_VON_NGF: 618.22,
    GB_TECHNIKFL_TF_VON_NGF: 16.9,
    GB_VERKEHRSFL_VF_VON_NGF: 29.32,
    GB_MIETFL_GESAMT: 664.44,
    GB_MIETFL_1_BSB: 664.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991604,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.664460',
    GB_LAENGENGRAD_OESTLICH: '10.075546',
    GB_NETTOGRUNDFL_NGF: 532.41,
    GB_NUTZFL_NF_VON_NGF: 508.77,
    GB_TECHNIKFL_TF_VON_NGF: 15.58,
    GB_VERKEHRSFL_VF_VON_NGF: 8.06,
    GB_MIETFL_GESAMT: 532.41,
    GB_MIETFL_1_BSB: 532.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991605,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: '53.664581280',
    GB_LAENGENGRAD_OESTLICH: '10.074839626',
    GB_NETTOGRUNDFL_NGF: 904.37,
    GB_NUTZFL_NF_VON_NGF: 691.49,
    GB_TECHNIKFL_TF_VON_NGF: 6.93,
    GB_VERKEHRSFL_VF_VON_NGF: 205.95,
    GB_MIETFL_GESAMT: 904.37,
    GB_MIETFL_1_BSB: 904.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991606,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.665179117',
    GB_LAENGENGRAD_OESTLICH: '10.074528582',
    GB_NETTOGRUNDFL_NGF: 3501.76,
    GB_NUTZFL_NF_VON_NGF: 2683.73,
    GB_TECHNIKFL_TF_VON_NGF: 57.4,
    GB_VERKEHRSFL_VF_VON_NGF: 760.63,
    GB_MIETFL_GESAMT: 3501.76,
    GB_MIETFL_1_BSB: 3444.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991607,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.665286781',
    GB_LAENGENGRAD_OESTLICH: '10.073607544',
    GB_NETTOGRUNDFL_NGF: 102.38,
    GB_NUTZFL_NF_VON_NGF: 86.67,
    GB_TECHNIKFL_TF_VON_NGF: 4.04,
    GB_VERKEHRSFL_VF_VON_NGF: 11.67,
    GB_MIETFL_GESAMT: 102.38,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991608,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.665370854',
    GB_LAENGENGRAD_OESTLICH: '10.073980260',
    GB_NETTOGRUNDFL_NGF: 606.28,
    GB_NUTZFL_NF_VON_NGF: 476.5,
    GB_TECHNIKFL_TF_VON_NGF: 29.04,
    GB_VERKEHRSFL_VF_VON_NGF: 100.74,
    GB_MIETFL_GESAMT: 606.28,
    GB_MIETFL_1_BSB: 606.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991609,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705045,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705045',
    GB_SAP_GB_ID_PKF: '7000/705045/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5458916,
    GB_WE_OBJ_ID_FKT: 1989319,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 645.59,
    GB_NUTZFL_NF_VON_NGF: 581.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 64.54,
    GB_MIETFL_GESAMT: 645.59,
    GB_MIETFL_1_BSB: 517.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991610,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 107.31,
    GB_NUTZFL_NF_VON_NGF: 89.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.54,
    GB_MIETFL_GESAMT: 107.31,
    GB_MIETFL_1_BSB: 107.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991611,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.22',
    GB_GB_OBJ_ID_PKT: 1991612,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.59,
    GB_NUTZFL_NF_VON_NGF: 155.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.54,
    GB_MIETFL_GESAMT: 172.59,
    GB_MIETFL_1_BSB: 172.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991613,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.650799',
    GB_LAENGENGRAD_OESTLICH: '10.073846',
    GB_NETTOGRUNDFL_NGF: 1583.35,
    GB_NUTZFL_NF_VON_NGF: 1155.27,
    GB_TECHNIKFL_TF_VON_NGF: 106.15,
    GB_VERKEHRSFL_VF_VON_NGF: 321.93,
    GB_MIETFL_GESAMT: 1583.35,
    GB_MIETFL_1_BSB: 1465.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '03.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991614,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.651270',
    GB_LAENGENGRAD_OESTLICH: '10.073571',
    GB_NETTOGRUNDFL_NGF: 622.9,
    GB_NUTZFL_NF_VON_NGF: 587.73,
    GB_TECHNIKFL_TF_VON_NGF: 12.91,
    GB_VERKEHRSFL_VF_VON_NGF: 22.26,
    GB_MIETFL_GESAMT: 622.9,
    GB_MIETFL_1_BSB: 622.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991615,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.79,
    GB_NUTZFL_NF_VON_NGF: 44.46,
    GB_TECHNIKFL_TF_VON_NGF: 17.36,
    GB_VERKEHRSFL_VF_VON_NGF: 31.97,
    GB_MIETFL_GESAMT: 93.79,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991616,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 247.98,
    GB_NUTZFL_NF_VON_NGF: 247.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 247.98,
    GB_MIETFL_1_BSB: 247.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5379555,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.650722',
    GB_LAENGENGRAD_OESTLICH: '10.073379',
    GB_NETTOGRUNDFL_NGF: 300.65,
    GB_NUTZFL_NF_VON_NGF: 247.0,
    GB_TECHNIKFL_TF_VON_NGF: 4.49,
    GB_VERKEHRSFL_VF_VON_NGF: 49.16,
    GB_MIETFL_GESAMT: 300.65,
    GB_MIETFL_1_BSB: 300.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5379556,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479155,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705046,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705046',
    GB_SAP_GB_ID_PKF: '7000/705046/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479156,
    GB_WE_OBJ_ID_FKT: 1989320,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1627.61,
    GB_NUTZFL_NF_VON_NGF: 1297.97,
    GB_TECHNIKFL_TF_VON_NGF: 35.28,
    GB_VERKEHRSFL_VF_VON_NGF: 294.36,
    GB_MIETFL_GESAMT: 1627.61,
    GB_MIETFL_1_BSB: 1509.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991469,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3347.62,
    GB_NUTZFL_NF_VON_NGF: 2496.75,
    GB_TECHNIKFL_TF_VON_NGF: 72.02,
    GB_VERKEHRSFL_VF_VON_NGF: 778.85,
    GB_MIETFL_GESAMT: 3347.62,
    GB_MIETFL_1_BSB: 3062.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991470,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1152.45,
    GB_NUTZFL_NF_VON_NGF: 923.13,
    GB_TECHNIKFL_TF_VON_NGF: 3.2,
    GB_VERKEHRSFL_VF_VON_NGF: 226.12,
    GB_MIETFL_GESAMT: 1152.45,
    GB_MIETFL_1_BSB: 1152.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991471,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.654145',
    GB_LAENGENGRAD_OESTLICH: '10.069227',
    GB_NETTOGRUNDFL_NGF: 2041.92,
    GB_NUTZFL_NF_VON_NGF: 1683.99,
    GB_TECHNIKFL_TF_VON_NGF: 89.06,
    GB_VERKEHRSFL_VF_VON_NGF: 268.87,
    GB_MIETFL_GESAMT: 2041.92,
    GB_MIETFL_1_BSB: 2001.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991472,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.654131',
    GB_LAENGENGRAD_OESTLICH: '10.068095',
    GB_NETTOGRUNDFL_NGF: 1529.49,
    GB_NUTZFL_NF_VON_NGF: 1180.35,
    GB_TECHNIKFL_TF_VON_NGF: 26.3,
    GB_VERKEHRSFL_VF_VON_NGF: 322.84,
    GB_MIETFL_GESAMT: 1529.49,
    GB_MIETFL_1_BSB: 1529.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991473,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2840.11,
    GB_NUTZFL_NF_VON_NGF: 2377.42,
    GB_TECHNIKFL_TF_VON_NGF: 42.27,
    GB_VERKEHRSFL_VF_VON_NGF: 420.42,
    GB_MIETFL_GESAMT: 2840.11,
    GB_MIETFL_1_BSB: 1920.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991474,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7118968,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705028,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705028',
    GB_SAP_GB_ID_PKF: '7000/705028/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7373908,
    GB_WE_OBJ_ID_FKT: 1989302,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.654940',
    GB_LAENGENGRAD_OESTLICH: '10.067266',
    GB_NETTOGRUNDFL_NGF: 1920.16,
    GB_NUTZFL_NF_VON_NGF: 1560.46,
    GB_TECHNIKFL_TF_VON_NGF: 42.37,
    GB_VERKEHRSFL_VF_VON_NGF: 317.33,
    GB_MIETFL_GESAMT: 1920.16,
    GB_MIETFL_1_BSB: 1920.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991763,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3123.3,
    GB_NUTZFL_NF_VON_NGF: 2279.7,
    GB_TECHNIKFL_TF_VON_NGF: 60.32,
    GB_VERKEHRSFL_VF_VON_NGF: 783.28,
    GB_MIETFL_GESAMT: 3123.3,
    GB_MIETFL_1_BSB: 2848.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991764,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.36,
    GB_NUTZFL_NF_VON_NGF: 90.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.57,
    GB_MIETFL_GESAMT: 102.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991765,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.655132',
    GB_LAENGENGRAD_OESTLICH: '10067174',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6774147,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet,',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 500.0,
    GB_NUTZFL_NF_VON_NGF: 500.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352762,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705063,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705063',
    GB_SAP_GB_ID_PKF: '7000/705063/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume. beendet,',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352763,
    GB_WE_OBJ_ID_FKT: 1989337,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1167.69,
    GB_NUTZFL_NF_VON_NGF: 776.87,
    GB_TECHNIKFL_TF_VON_NGF: 78.46,
    GB_VERKEHRSFL_VF_VON_NGF: 312.36,
    GB_MIETFL_GESAMT: 1167.69,
    GB_MIETFL_1_BSB: 1093.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991866,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 533.84,
    GB_NUTZFL_NF_VON_NGF: 365.28,
    GB_TECHNIKFL_TF_VON_NGF: 20.02,
    GB_VERKEHRSFL_VF_VON_NGF: 148.54,
    GB_MIETFL_GESAMT: 533.84,
    GB_MIETFL_1_BSB: 380.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991867,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1950,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 652.21,
    GB_NUTZFL_NF_VON_NGF: 518.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 133.66,
    GB_MIETFL_GESAMT: 652.21,
    GB_MIETFL_1_BSB: 649.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991868,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 594.35,
    GB_NUTZFL_NF_VON_NGF: 534.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.77,
    GB_MIETFL_GESAMT: 594.35,
    GB_MIETFL_1_BSB: 594.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991869,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 457.88,
    GB_NUTZFL_NF_VON_NGF: 419.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 38.55,
    GB_MIETFL_GESAMT: 457.88,
    GB_MIETFL_1_BSB: 457.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991870,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 128.36,
    GB_NUTZFL_NF_VON_NGF: 120.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.67,
    GB_MIETFL_GESAMT: 128.36,
    GB_MIETFL_1_BSB: 128.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991871,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 158.61,
    GB_NUTZFL_NF_VON_NGF: 139.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.15,
    GB_MIETFL_GESAMT: 158.61,
    GB_MIETFL_1_BSB: 158.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991872,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5366.51,
    GB_NUTZFL_NF_VON_NGF: 3450.45,
    GB_TECHNIKFL_TF_VON_NGF: 257.64,
    GB_VERKEHRSFL_VF_VON_NGF: 1658.42,
    GB_MIETFL_GESAMT: 5366.51,
    GB_MIETFL_1_BSB: 5030.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991873,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/9',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 2004,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 148.29,
    GB_NUTZFL_NF_VON_NGF: 148.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 148.29,
    GB_MIETFL_1_BSB: 148.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991874,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.659714',
    GB_LAENGENGRAD_OESTLICH: '10.079456',
    GB_NETTOGRUNDFL_NGF: 530.76,
    GB_NUTZFL_NF_VON_NGF: 507.9,
    GB_TECHNIKFL_TF_VON_NGF: 15.16,
    GB_VERKEHRSFL_VF_VON_NGF: 7.7,
    GB_MIETFL_GESAMT: 530.76,
    GB_MIETFL_1_BSB: 530.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991875,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.659551',
    GB_LAENGENGRAD_OESTLICH: '10.080220',
    GB_NETTOGRUNDFL_NGF: 623.31,
    GB_NUTZFL_NF_VON_NGF: 604.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.99,
    GB_MIETFL_GESAMT: 623.31,
    GB_MIETFL_1_BSB: 623.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991876,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/12',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1926,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2552.38,
    GB_NUTZFL_NF_VON_NGF: 1884.59,
    GB_TECHNIKFL_TF_VON_NGF: 69.93,
    GB_VERKEHRSFL_VF_VON_NGF: 597.86,
    GB_MIETFL_GESAMT: 2552.38,
    GB_MIETFL_1_BSB: 1691.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991877,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705076,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705076',
    GB_SAP_GB_ID_PKF: '7000/705076/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.657085',
    GB_LAENGENGRAD_OESTLICH: '10.081346',
    GB_NETTOGRUNDFL_NGF: 666.6,
    GB_NUTZFL_NF_VON_NGF: 624.76,
    GB_TECHNIKFL_TF_VON_NGF: 16.02,
    GB_VERKEHRSFL_VF_VON_NGF: 25.82,
    GB_MIETFL_GESAMT: 666.6,
    GB_MIETFL_1_BSB: 666.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991878,
    GB_WE_OBJ_ID_FKT: 1989350,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3486.89,
    GB_NUTZFL_NF_VON_NGF: 2738.33,
    GB_TECHNIKFL_TF_VON_NGF: 38.48,
    GB_VERKEHRSFL_VF_VON_NGF: 710.08,
    GB_MIETFL_GESAMT: 3486.89,
    GB_MIETFL_1_BSB: 3344.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991916,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.600142',
    GB_LAENGENGRAD_OESTLICH: '10.130576',
    GB_NETTOGRUNDFL_NGF: 728.02,
    GB_NUTZFL_NF_VON_NGF: 673.6,
    GB_TECHNIKFL_TF_VON_NGF: 12.1,
    GB_VERKEHRSFL_VF_VON_NGF: 42.32,
    GB_MIETFL_GESAMT: 728.02,
    GB_MIETFL_1_BSB: 728.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991917,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.92,
    GB_NUTZFL_NF_VON_NGF: 171.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.9,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.07.22',
    GB_GB_OBJ_ID_PKT: 1991918,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.97,
    GB_NUTZFL_NF_VON_NGF: 171.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.95,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '21.07.22',
    GB_GB_OBJ_ID_PKT: 1991919,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 345.32,
    GB_NUTZFL_NF_VON_NGF: 314.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.29,
    GB_MIETFL_GESAMT: 345.32,
    GB_MIETFL_1_BSB: 345.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.01.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5422190,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.600659',
    GB_LAENGENGRAD_OESTLICH: '10.129796',
    GB_NETTOGRUNDFL_NGF: 953.19,
    GB_NUTZFL_NF_VON_NGF: 848.29,
    GB_TECHNIKFL_TF_VON_NGF: 13.48,
    GB_VERKEHRSFL_VF_VON_NGF: 91.42,
    GB_MIETFL_GESAMT: 953.19,
    GB_MIETFL_1_BSB: 950.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '03.05.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365770,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705080,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705080',
    GB_SAP_GB_ID_PKF: '7000/705080/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 360.53,
    GB_NUTZFL_NF_VON_NGF: 284.84,
    GB_TECHNIKFL_TF_VON_NGF: 6.4,
    GB_VERKEHRSFL_VF_VON_NGF: 69.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '15.03.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '15.03.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7486648,
    GB_WE_OBJ_ID_FKT: 1989354,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.612014131',
    GB_LAENGENGRAD_OESTLICH: '10.136981473',
    GB_NETTOGRUNDFL_NGF: 752.33,
    GB_NUTZFL_NF_VON_NGF: 514.14,
    GB_TECHNIKFL_TF_VON_NGF: 173.26,
    GB_VERKEHRSFL_VF_VON_NGF: 64.93,
    GB_MIETFL_GESAMT: 752.33,
    GB_MIETFL_1_BSB: 441.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991382,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991383,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.612877',
    GB_LAENGENGRAD_OESTLICH: '10.136761',
    GB_NETTOGRUNDFL_NGF: 623.7,
    GB_NUTZFL_NF_VON_NGF: 572.25,
    GB_TECHNIKFL_TF_VON_NGF: 34.85,
    GB_VERKEHRSFL_VF_VON_NGF: 16.6,
    GB_MIETFL_GESAMT: 623.7,
    GB_MIETFL_1_BSB: 623.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.12',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991384,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.612884586',
    GB_LAENGENGRAD_OESTLICH: '10.137385286',
    GB_NETTOGRUNDFL_NGF: 455.02,
    GB_NUTZFL_NF_VON_NGF: 362.89,
    GB_TECHNIKFL_TF_VON_NGF: 4.9,
    GB_VERKEHRSFL_VF_VON_NGF: 87.23,
    GB_MIETFL_GESAMT: 455.02,
    GB_MIETFL_1_BSB: 455.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991385,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.612628466',
    GB_LAENGENGRAD_OESTLICH: '10.137922191',
    GB_NETTOGRUNDFL_NGF: 1019.13,
    GB_NUTZFL_NF_VON_NGF: 903.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 115.58,
    GB_MIETFL_GESAMT: 1019.13,
    GB_MIETFL_1_BSB: 882.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991386,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1991387,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.612074890',
    GB_LAENGENGRAD_OESTLICH: '10.137857706',
    GB_NETTOGRUNDFL_NGF: 1443.16,
    GB_NUTZFL_NF_VON_NGF: 1142.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 300.7,
    GB_MIETFL_GESAMT: 1443.16,
    GB_MIETFL_1_BSB: 1443.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991388,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.612097393',
    GB_LAENGENGRAD_OESTLICH: '10.137284931',
    GB_NETTOGRUNDFL_NGF: 309.72,
    GB_NUTZFL_NF_VON_NGF: 309.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 309.72,
    GB_MIETFL_1_BSB: 309.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991389,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423271,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.612515',
    GB_LAENGENGRAD_OESTLICH: '10.137408',
    GB_NETTOGRUNDFL_NGF: 144.51,
    GB_NUTZFL_NF_VON_NGF: 129.87,
    GB_TECHNIKFL_TF_VON_NGF: 5.41,
    GB_VERKEHRSFL_VF_VON_NGF: 9.23,
    GB_MIETFL_GESAMT: 144.51,
    GB_MIETFL_1_BSB: 144.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.07.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5492107,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7050330,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705018,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705018',
    GB_SAP_GB_ID_PKF: '7000/705018/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 91.4,
    GB_NUTZFL_NF_VON_NGF: 77.98,
    GB_TECHNIKFL_TF_VON_NGF: 4.47,
    GB_VERKEHRSFL_VF_VON_NGF: 8.95,
    GB_MIETFL_GESAMT: 91.4,
    GB_MIETFL_1_BSB: 91.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462189,
    GB_WE_OBJ_ID_FKT: 1989292,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705069,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705069',
    GB_SAP_GB_ID_PKF: '7000/705069/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 8488.42,
    GB_NUTZFL_NF_VON_NGF: 5892.68,
    GB_TECHNIKFL_TF_VON_NGF: 185.71,
    GB_VERKEHRSFL_VF_VON_NGF: 2410.03,
    GB_MIETFL_GESAMT: 8488.42,
    GB_MIETFL_1_BSB: 8068.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991805,
    GB_WE_OBJ_ID_FKT: 1989343,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705069,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705069',
    GB_SAP_GB_ID_PKF: '7000/705069/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.612003',
    GB_LAENGENGRAD_OESTLICH: '10.159271',
    GB_NETTOGRUNDFL_NGF: 1682.64,
    GB_NUTZFL_NF_VON_NGF: 1578.94,
    GB_TECHNIKFL_TF_VON_NGF: 37.04,
    GB_VERKEHRSFL_VF_VON_NGF: 66.66,
    GB_MIETFL_GESAMT: 1682.64,
    GB_MIETFL_1_BSB: 1682.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991806,
    GB_WE_OBJ_ID_FKT: 1989343,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705069,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705069',
    GB_SAP_GB_ID_PKF: '7000/705069/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 175.02,
    GB_NUTZFL_NF_VON_NGF: 130.2,
    GB_TECHNIKFL_TF_VON_NGF: 9.0,
    GB_VERKEHRSFL_VF_VON_NGF: 35.82,
    GB_MIETFL_GESAMT: 175.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991807,
    GB_WE_OBJ_ID_FKT: 1989343,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705004',
    GB_SAP_GB_ID_PKF: '7000/705004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 780.13,
    GB_NUTZFL_NF_VON_NGF: 584.54,
    GB_TECHNIKFL_TF_VON_NGF: 38.17,
    GB_VERKEHRSFL_VF_VON_NGF: 157.42,
    GB_MIETFL_GESAMT: 780.13,
    GB_MIETFL_1_BSB: 726.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991256,
    GB_WE_OBJ_ID_FKT: 1989278,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705004',
    GB_SAP_GB_ID_PKF: '7000/705004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.57,
    GB_NUTZFL_NF_VON_NGF: 177.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.78,
    GB_MIETFL_GESAMT: 206.57,
    GB_MIETFL_1_BSB: 206.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991257,
    GB_WE_OBJ_ID_FKT: 1989278,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705004',
    GB_SAP_GB_ID_PKF: '7000/705004/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.596102',
    GB_LAENGENGRAD_OESTLICH: '10.152535',
    GB_NETTOGRUNDFL_NGF: 161.95,
    GB_NUTZFL_NF_VON_NGF: 141.17,
    GB_TECHNIKFL_TF_VON_NGF: 6.43,
    GB_VERKEHRSFL_VF_VON_NGF: 14.35,
    GB_MIETFL_GESAMT: 161.95,
    GB_MIETFL_1_BSB: 161.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5331847,
    GB_WE_OBJ_ID_FKT: 1989278,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.595686',
    GB_LAENGENGRAD_OESTLICH: '10.163658',
    GB_NETTOGRUNDFL_NGF: 630.3,
    GB_NUTZFL_NF_VON_NGF: 435.07,
    GB_TECHNIKFL_TF_VON_NGF: 86.41,
    GB_VERKEHRSFL_VF_VON_NGF: 108.82,
    GB_MIETFL_GESAMT: 630.3,
    GB_MIETFL_1_BSB: 454.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991441,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.595383',
    GB_LAENGENGRAD_OESTLICH: '10.163720',
    GB_NETTOGRUNDFL_NGF: 286.72,
    GB_NUTZFL_NF_VON_NGF: 279.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.58,
    GB_MIETFL_GESAMT: 286.72,
    GB_MIETFL_1_BSB: 286.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991442,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.595439',
    GB_LAENGENGRAD_OESTLICH: '10.163352',
    GB_NETTOGRUNDFL_NGF: 844.55,
    GB_NUTZFL_NF_VON_NGF: 679.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 164.62,
    GB_MIETFL_GESAMT: 844.55,
    GB_MIETFL_1_BSB: 844.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.02.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991443,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.595899',
    GB_LAENGENGRAD_OESTLICH: '10.162827',
    GB_NETTOGRUNDFL_NGF: 1230.65,
    GB_NUTZFL_NF_VON_NGF: 961.33,
    GB_TECHNIKFL_TF_VON_NGF: 0.58,
    GB_VERKEHRSFL_VF_VON_NGF: 268.74,
    GB_MIETFL_GESAMT: 1230.65,
    GB_MIETFL_1_BSB: 1227.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991444,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 585.01,
    GB_NUTZFL_NF_VON_NGF: 429.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 155.42,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '27.08.18',
    GB_GB_OBJ_ID_PKT: 1991445,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.594569',
    GB_LAENGENGRAD_OESTLICH: '10.163256',
    GB_NETTOGRUNDFL_NGF: 630.16,
    GB_NUTZFL_NF_VON_NGF: 576.46,
    GB_TECHNIKFL_TF_VON_NGF: 34.84,
    GB_VERKEHRSFL_VF_VON_NGF: 18.86,
    GB_MIETFL_GESAMT: 630.16,
    GB_MIETFL_1_BSB: 630.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991446,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.595629',
    GB_LAENGENGRAD_OESTLICH: '10.163198',
    GB_NETTOGRUNDFL_NGF: 952.45,
    GB_NUTZFL_NF_VON_NGF: 370.58,
    GB_TECHNIKFL_TF_VON_NGF: 5.43,
    GB_VERKEHRSFL_VF_VON_NGF: 576.44,
    GB_MIETFL_GESAMT: 952.45,
    GB_MIETFL_1_BSB: 952.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.14',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5364378,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/8',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.595',
    GB_LAENGENGRAD_OESTLICH: '10.163207',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399659,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.595071',
    GB_LAENGENGRAD_OESTLICH: '10.163729',
    GB_NETTOGRUNDFL_NGF: 1335.04,
    GB_NUTZFL_NF_VON_NGF: 1032.91,
    GB_TECHNIKFL_TF_VON_NGF: 22.93,
    GB_VERKEHRSFL_VF_VON_NGF: 279.2,
    GB_MIETFL_GESAMT: 1335.04,
    GB_MIETFL_1_BSB: 1335.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5422187,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352754,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705025,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705025',
    GB_SAP_GB_ID_PKF: '7000/705025/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.02,
    GB_NUTZFL_NF_VON_NGF: 148.69,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: 167.02,
    GB_MIETFL_1_BSB: 167.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7121550,
    GB_WE_OBJ_ID_FKT: 1989299,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 671.29,
    GB_NUTZFL_NF_VON_NGF: 598.7,
    GB_TECHNIKFL_TF_VON_NGF: 4.18,
    GB_VERKEHRSFL_VF_VON_NGF: 68.41,
    GB_MIETFL_GESAMT: 671.29,
    GB_MIETFL_1_BSB: 671.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991285,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 121.98,
    GB_NUTZFL_NF_VON_NGF: 112.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.83,
    GB_MIETFL_GESAMT: 121.98,
    GB_MIETFL_1_BSB: 121.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991286,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.57,
    GB_NUTZFL_NF_VON_NGF: 181.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.01,
    GB_MIETFL_GESAMT: 236.57,
    GB_MIETFL_1_BSB: 236.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991287,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 820.64,
    GB_NUTZFL_NF_VON_NGF: 688.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 132.58,
    GB_MIETFL_GESAMT: 820.64,
    GB_MIETFL_1_BSB: 820.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991288,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 635.79,
    GB_NUTZFL_NF_VON_NGF: 558.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 77.77,
    GB_MIETFL_GESAMT: 635.79,
    GB_MIETFL_1_BSB: 635.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991289,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 500.05,
    GB_NUTZFL_NF_VON_NGF: 412.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.66,
    GB_MIETFL_GESAMT: 500.05,
    GB_MIETFL_1_BSB: 500.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991290,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 501.53,
    GB_NUTZFL_NF_VON_NGF: 413.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.73,
    GB_MIETFL_GESAMT: 501.53,
    GB_MIETFL_1_BSB: 501.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991291,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 499.83,
    GB_NUTZFL_NF_VON_NGF: 412.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.2,
    GB_MIETFL_GESAMT: 499.83,
    GB_MIETFL_1_BSB: 499.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991292,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.592654',
    GB_LAENGENGRAD_OESTLICH: '10.181048',
    GB_NETTOGRUNDFL_NGF: 625.96,
    GB_NUTZFL_NF_VON_NGF: 597.18,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.12,
    GB_MIETFL_GESAMT: 625.96,
    GB_MIETFL_1_BSB: 625.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991293,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.49,
    GB_NUTZFL_NF_VON_NGF: 92.21,
    GB_TECHNIKFL_TF_VON_NGF: 35.39,
    GB_VERKEHRSFL_VF_VON_NGF: 11.89,
    GB_MIETFL_GESAMT: 139.49,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991294,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 138.78,
    GB_NUTZFL_NF_VON_NGF: 128.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.21,
    GB_MIETFL_GESAMT: 138.78,
    GB_MIETFL_1_BSB: 138.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991295,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991296,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705008,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705008',
    GB_SAP_GB_ID_PKF: '7000/705008/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991297,
    GB_WE_OBJ_ID_FKT: 1989282,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1867.57,
    GB_NUTZFL_NF_VON_NGF: 1370.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 496.96,
    GB_MIETFL_GESAMT: 1867.57,
    GB_MIETFL_1_BSB: 1867.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991741,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1616.31,
    GB_NUTZFL_NF_VON_NGF: 1164.96,
    GB_TECHNIKFL_TF_VON_NGF: 109.49,
    GB_VERKEHRSFL_VF_VON_NGF: 341.86,
    GB_MIETFL_GESAMT: 1616.31,
    GB_MIETFL_1_BSB: 1258.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991742,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 445.58,
    GB_NUTZFL_NF_VON_NGF: 343.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.94,
    GB_MIETFL_GESAMT: 445.58,
    GB_MIETFL_1_BSB: 445.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991743,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.628982',
    GB_LAENGENGRAD_OESTLICH: '10.163339',
    GB_NETTOGRUNDFL_NGF: 626.65,
    GB_NUTZFL_NF_VON_NGF: 585.58,
    GB_TECHNIKFL_TF_VON_NGF: 21.9,
    GB_VERKEHRSFL_VF_VON_NGF: 19.17,
    GB_MIETFL_GESAMT: 626.65,
    GB_MIETFL_1_BSB: 626.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991744,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1034.46,
    GB_NUTZFL_NF_VON_NGF: 863.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 170.62,
    GB_MIETFL_GESAMT: 1034.46,
    GB_MIETFL_1_BSB: 1034.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991745,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.628962',
    GB_LAENGENGRAD_OESTLICH: '10.163818',
    GB_NETTOGRUNDFL_NGF: 407.96,
    GB_NUTZFL_NF_VON_NGF: 351.95,
    GB_TECHNIKFL_TF_VON_NGF: 24.09,
    GB_VERKEHRSFL_VF_VON_NGF: 31.92,
    GB_MIETFL_GESAMT: 407.96,
    GB_MIETFL_1_BSB: 407.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.09.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5361560,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705060,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705060',
    GB_SAP_GB_ID_PKF: '7000/705060/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1897.17,
    GB_NUTZFL_NF_VON_NGF: 1556.68,
    GB_TECHNIKFL_TF_VON_NGF: 48.1,
    GB_VERKEHRSFL_VF_VON_NGF: 292.39,
    GB_MIETFL_GESAMT: 1897.17,
    GB_MIETFL_1_BSB: 1890.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399686,
    GB_WE_OBJ_ID_FKT: 1989334,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 833.94,
    GB_NUTZFL_NF_VON_NGF: 676.08,
    GB_TECHNIKFL_TF_VON_NGF: 35.28,
    GB_VERKEHRSFL_VF_VON_NGF: 122.58,
    GB_MIETFL_GESAMT: 833.94,
    GB_MIETFL_1_BSB: 751.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991808,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.612196',
    GB_LAENGENGRAD_OESTLICH: '10.158074',
    GB_NETTOGRUNDFL_NGF: 1218.88,
    GB_NUTZFL_NF_VON_NGF: 1019.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 199.38,
    GB_MIETFL_GESAMT: 1218.88,
    GB_MIETFL_1_BSB: 1218.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991809,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 859.14,
    GB_NUTZFL_NF_VON_NGF: 738.69,
    GB_TECHNIKFL_TF_VON_NGF: 6.13,
    GB_VERKEHRSFL_VF_VON_NGF: 114.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.03.19',
    GB_GB_OBJ_ID_PKT: 1991810,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.62,
    GB_NUTZFL_NF_VON_NGF: 177.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.03.19',
    GB_GB_OBJ_ID_PKT: 1991811,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.03,
    GB_NUTZFL_NF_VON_NGF: 175.56,
    GB_TECHNIKFL_TF_VON_NGF: 4.62,
    GB_VERKEHRSFL_VF_VON_NGF: 25.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.03.19',
    GB_GB_OBJ_ID_PKT: 1991812,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.612103',
    GB_LAENGENGRAD_OESTLICH: '10.157000',
    GB_NETTOGRUNDFL_NGF: 635.4,
    GB_NUTZFL_NF_VON_NGF: 588.01,
    GB_TECHNIKFL_TF_VON_NGF: 28.26,
    GB_VERKEHRSFL_VF_VON_NGF: 19.13,
    GB_MIETFL_GESAMT: 635.4,
    GB_MIETFL_1_BSB: 635.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991813,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1321.37,
    GB_NUTZFL_NF_VON_NGF: 1079.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 241.46,
    GB_MIETFL_GESAMT: 1321.37,
    GB_MIETFL_1_BSB: 1277.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991814,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/8',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 819.83,
    GB_NUTZFL_NF_VON_NGF: 581.74,
    GB_TECHNIKFL_TF_VON_NGF: 106.88,
    GB_VERKEHRSFL_VF_VON_NGF: 131.21,
    GB_MIETFL_GESAMT: 819.83,
    GB_MIETFL_1_BSB: 630.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.06.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991815,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1943.39,
    GB_NUTZFL_NF_VON_NGF: 1471.36,
    GB_TECHNIKFL_TF_VON_NGF: 8.5,
    GB_VERKEHRSFL_VF_VON_NGF: 463.53,
    GB_MIETFL_GESAMT: 1943.39,
    GB_MIETFL_1_BSB: 1936.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.06.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399688,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479161,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.611436',
    GB_LAENGENGRAD_OESTLICH: '10.159440',
    GB_NETTOGRUNDFL_NGF: 1699.69,
    GB_NUTZFL_NF_VON_NGF: 1315.52,
    GB_TECHNIKFL_TF_VON_NGF: 80.38,
    GB_VERKEHRSFL_VF_VON_NGF: 303.79,
    GB_MIETFL_GESAMT: 1699.69,
    GB_MIETFL_1_BSB: 1697.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399689,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7118969,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 149.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 4352764,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 328.27,
    GB_NUTZFL_NF_VON_NGF: 297.38,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 24.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.17',
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 5922193,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6936737,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 286.13,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 49.75,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 338.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7124365,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.65,
    GB_NUTZFL_NF_VON_NGF: 151.05,
    GB_TECHNIKFL_TF_VON_NGF: 3.8,
    GB_VERKEHRSFL_VF_VON_NGF: 9.8,
    GB_MIETFL_GESAMT: 164.65,
    GB_MIETFL_1_BSB: 164.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462193,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/45',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552756,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 46,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/46',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552757,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705070,
    GB_SAP_GB_NUMMER: 47,
    GB_SAP_WE_ID_FKF: '7000/705070',
    GB_SAP_GB_ID_PKF: '7000/705070/47',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552758,
    GB_WE_OBJ_ID_FKT: 1989344,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 669.79,
    GB_NUTZFL_NF_VON_NGF: 532.27,
    GB_TECHNIKFL_TF_VON_NGF: 20.7,
    GB_VERKEHRSFL_VF_VON_NGF: 116.82,
    GB_MIETFL_GESAMT: 669.79,
    GB_MIETFL_1_BSB: 601.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991576,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 288.2,
    GB_NUTZFL_NF_VON_NGF: 268.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.07,
    GB_MIETFL_GESAMT: 288.2,
    GB_MIETFL_1_BSB: 288.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991577,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.600824',
    GB_LAENGENGRAD_OESTLICH: '10.178194',
    GB_NETTOGRUNDFL_NGF: 1587.55,
    GB_NUTZFL_NF_VON_NGF: 1178.19,
    GB_TECHNIKFL_TF_VON_NGF: 101.64,
    GB_VERKEHRSFL_VF_VON_NGF: 307.72,
    GB_MIETFL_GESAMT: 1587.55,
    GB_MIETFL_1_BSB: 1479.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.04.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991578,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.601078',
    GB_LAENGENGRAD_OESTLICH: '10.178665',
    GB_NETTOGRUNDFL_NGF: 622.91,
    GB_NUTZFL_NF_VON_NGF: 577.12,
    GB_TECHNIKFL_TF_VON_NGF: 23.55,
    GB_VERKEHRSFL_VF_VON_NGF: 22.24,
    GB_MIETFL_GESAMT: 622.91,
    GB_MIETFL_1_BSB: 622.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.11.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991579,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 852.73,
    GB_NUTZFL_NF_VON_NGF: 766.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 86.58,
    GB_MIETFL_GESAMT: 852.73,
    GB_MIETFL_1_BSB: 852.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991580,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705041',
    GB_SAP_GB_ID_PKF: '7000/705041/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 275.6,
    GB_NUTZFL_NF_VON_NGF: 262.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.32,
    GB_MIETFL_GESAMT: 275.6,
    GB_MIETFL_1_BSB: 275.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.05.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991581,
    GB_WE_OBJ_ID_FKT: 1989315,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 224.66,
    GB_NUTZFL_NF_VON_NGF: 190.28,
    GB_TECHNIKFL_TF_VON_NGF: 3.38,
    GB_VERKEHRSFL_VF_VON_NGF: 31.0,
    GB_MIETFL_GESAMT: 224.66,
    GB_MIETFL_1_BSB: 224.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991249,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.19,
    GB_NUTZFL_NF_VON_NGF: 363.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.31,
    GB_MIETFL_GESAMT: 422.19,
    GB_MIETFL_1_BSB: 422.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991250,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 647.23,
    GB_NUTZFL_NF_VON_NGF: 565.46,
    GB_TECHNIKFL_TF_VON_NGF: 6.35,
    GB_VERKEHRSFL_VF_VON_NGF: 75.42,
    GB_MIETFL_GESAMT: 647.23,
    GB_MIETFL_1_BSB: 647.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991251,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 639.66,
    GB_NUTZFL_NF_VON_NGF: 547.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 92.63,
    GB_MIETFL_GESAMT: 639.66,
    GB_MIETFL_1_BSB: 639.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991252,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 638.85,
    GB_NUTZFL_NF_VON_NGF: 407.27,
    GB_TECHNIKFL_TF_VON_NGF: 62.85,
    GB_VERKEHRSFL_VF_VON_NGF: 168.73,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1991253,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 491.55,
    GB_NUTZFL_NF_VON_NGF: 385.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 105.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.16',
    GB_GB_OBJ_ID_PKT: 1991254,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/7',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.594100',
    GB_LAENGENGRAD_OESTLICH: '10.152538',
    GB_NETTOGRUNDFL_NGF: 347.01,
    GB_NUTZFL_NF_VON_NGF: 325.97,
    GB_TECHNIKFL_TF_VON_NGF: 6.0,
    GB_VERKEHRSFL_VF_VON_NGF: 15.04,
    GB_MIETFL_GESAMT: 347.01,
    GB_MIETFL_1_BSB: 326.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991255,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.593757795',
    GB_LAENGENGRAD_OESTLICH: '10.152696886',
    GB_NETTOGRUNDFL_NGF: 2171.32,
    GB_NUTZFL_NF_VON_NGF: 1703.76,
    GB_TECHNIKFL_TF_VON_NGF: 24.18,
    GB_VERKEHRSFL_VF_VON_NGF: 443.38,
    GB_MIETFL_GESAMT: 2171.32,
    GB_MIETFL_1_BSB: 2166.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399649,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/9',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.593777519',
    GB_LAENGENGRAD_OESTLICH: '10.153003198',
    GB_NETTOGRUNDFL_NGF: 35.0,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 35.0,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 35.0,
    GB_MIETFL_1_BSB: 35.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399650,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705003,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705003',
    GB_SAP_GB_ID_PKF: '7000/705003/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.586198',
    GB_LAENGENGRAD_OESTLICH: '10.169583',
    GB_NETTOGRUNDFL_NGF: 1734.9,
    GB_NUTZFL_NF_VON_NGF: 1640.83,
    GB_TECHNIKFL_TF_VON_NGF: 35.9,
    GB_VERKEHRSFL_VF_VON_NGF: 58.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.18',
    GB_GB_OBJ_ID_PKT: 5420002,
    GB_WE_OBJ_ID_FKT: 1989277,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.629131735',
    GB_LAENGENGRAD_OESTLICH: '10.159854818',
    GB_NETTOGRUNDFL_NGF: 1527.78,
    GB_NUTZFL_NF_VON_NGF: 1095.82,
    GB_TECHNIKFL_TF_VON_NGF: 40.75,
    GB_VERKEHRSFL_VF_VON_NGF: 391.21,
    GB_MIETFL_GESAMT: 1527.78,
    GB_MIETFL_1_BSB: 1212.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991746,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.629274886',
    GB_LAENGENGRAD_OESTLICH: '10.158824850',
    GB_NETTOGRUNDFL_NGF: 2787.03,
    GB_NUTZFL_NF_VON_NGF: 2130.82,
    GB_TECHNIKFL_TF_VON_NGF: 71.02,
    GB_VERKEHRSFL_VF_VON_NGF: 585.19,
    GB_MIETFL_GESAMT: 2787.03,
    GB_MIETFL_1_BSB: 2497.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991747,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.628529',
    GB_LAENGENGRAD_OESTLICH: '10.158865',
    GB_NETTOGRUNDFL_NGF: 660.01,
    GB_NUTZFL_NF_VON_NGF: 619.75,
    GB_TECHNIKFL_TF_VON_NGF: 11.96,
    GB_VERKEHRSFL_VF_VON_NGF: 28.3,
    GB_MIETFL_GESAMT: 660.01,
    GB_MIETFL_1_BSB: 660.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991748,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.628808847',
    GB_LAENGENGRAD_OESTLICH: '10.158583451',
    GB_NETTOGRUNDFL_NGF: 344.19,
    GB_NUTZFL_NF_VON_NGF: 328.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.41,
    GB_MIETFL_GESAMT: 344.19,
    GB_MIETFL_1_BSB: 344.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5422189,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062817,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: '53.629632763',
    GB_LAENGENGRAD_OESTLICH: '10.159363974',
    GB_NETTOGRUNDFL_NGF: 909.51,
    GB_NUTZFL_NF_VON_NGF: 676.97,
    GB_TECHNIKFL_TF_VON_NGF: 9.77,
    GB_VERKEHRSFL_VF_VON_NGF: 222.77,
    GB_MIETFL_GESAMT: 909.51,
    GB_MIETFL_1_BSB: 907.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7068496,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.25,
    GB_NUTZFL_NF_VON_NGF: 148.69,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036060,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705061,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705061',
    GB_SAP_GB_ID_PKF: '7000/705061/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 149.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7131740,
    GB_WE_OBJ_ID_FKT: 1989335,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 713.22,
    GB_NUTZFL_NF_VON_NGF: 573.79,
    GB_TECHNIKFL_TF_VON_NGF: 26.88,
    GB_VERKEHRSFL_VF_VON_NGF: 112.55,
    GB_MIETFL_GESAMT: 713.22,
    GB_MIETFL_1_BSB: 535.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991692,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 659.91,
    GB_NUTZFL_NF_VON_NGF: 578.92,
    GB_TECHNIKFL_TF_VON_NGF: 21.47,
    GB_VERKEHRSFL_VF_VON_NGF: 59.52,
    GB_MIETFL_GESAMT: 659.91,
    GB_MIETFL_1_BSB: 638.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991693,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.619015',
    GB_LAENGENGRAD_OESTLICH: '10.141360',
    GB_NETTOGRUNDFL_NGF: 620.09,
    GB_NUTZFL_NF_VON_NGF: 584.8,
    GB_TECHNIKFL_TF_VON_NGF: 12.94,
    GB_VERKEHRSFL_VF_VON_NGF: 22.35,
    GB_MIETFL_GESAMT: 620.09,
    GB_MIETFL_1_BSB: 620.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991694,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 277.69,
    GB_NUTZFL_NF_VON_NGF: 261.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.07,
    GB_MIETFL_GESAMT: 277.69,
    GB_MIETFL_1_BSB: 277.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991695,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 296.28,
    GB_NUTZFL_NF_VON_NGF: 268.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.77,
    GB_MIETFL_GESAMT: 296.28,
    GB_MIETFL_1_BSB: 296.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991696,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 278.1,
    GB_NUTZFL_NF_VON_NGF: 262.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.09,
    GB_MIETFL_GESAMT: 278.1,
    GB_MIETFL_1_BSB: 278.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991697,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.54,
    GB_NUTZFL_NF_VON_NGF: 387.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.72,
    GB_MIETFL_GESAMT: 437.54,
    GB_MIETFL_1_BSB: 437.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991698,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 438.0,
    GB_NUTZFL_NF_VON_NGF: 388.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.72,
    GB_MIETFL_GESAMT: 438.0,
    GB_MIETFL_1_BSB: 438.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991699,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 437.58,
    GB_NUTZFL_NF_VON_NGF: 387.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.72,
    GB_MIETFL_GESAMT: 437.58,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991700,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.88,
    GB_NUTZFL_NF_VON_NGF: 384.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.28,
    GB_MIETFL_GESAMT: 439.88,
    GB_MIETFL_1_BSB: 439.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991701,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 440.09,
    GB_NUTZFL_NF_VON_NGF: 384.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.37,
    GB_MIETFL_GESAMT: 440.09,
    GB_MIETFL_1_BSB: 440.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991702,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479157,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705055,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705055',
    GB_SAP_GB_ID_PKF: '7000/705055/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479158,
    GB_WE_OBJ_ID_FKT: 1989329,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3052.62,
    GB_NUTZFL_NF_VON_NGF: 2192.94,
    GB_TECHNIKFL_TF_VON_NGF: 73.28,
    GB_VERKEHRSFL_VF_VON_NGF: 786.4,
    GB_MIETFL_GESAMT: 3052.62,
    GB_MIETFL_1_BSB: 2979.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991703,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.587103',
    GB_LAENGENGRAD_OESTLICH: '10.170560',
    GB_NETTOGRUNDFL_NGF: 3039.83,
    GB_NUTZFL_NF_VON_NGF: 2247.76,
    GB_TECHNIKFL_TF_VON_NGF: 0.53,
    GB_VERKEHRSFL_VF_VON_NGF: 791.54,
    GB_MIETFL_GESAMT: 3039.83,
    GB_MIETFL_1_BSB: 3039.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.06.13',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991704,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.586429',
    GB_LAENGENGRAD_OESTLICH: '10.170889',
    GB_NETTOGRUNDFL_NGF: 659.71,
    GB_NUTZFL_NF_VON_NGF: 614.53,
    GB_TECHNIKFL_TF_VON_NGF: 17.24,
    GB_VERKEHRSFL_VF_VON_NGF: 27.94,
    GB_MIETFL_GESAMT: 659.71,
    GB_MIETFL_1_BSB: 659.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991705,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.1,
    GB_NUTZFL_NF_VON_NGF: 81.4,
    GB_TECHNIKFL_TF_VON_NGF: 4.1,
    GB_VERKEHRSFL_VF_VON_NGF: 16.6,
    GB_MIETFL_GESAMT: 102.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991706,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.586245',
    GB_LAENGENGRAD_OESTLICH: '10.169576',
    GB_NETTOGRUNDFL_NGF: 1734.9,
    GB_NUTZFL_NF_VON_NGF: 1640.83,
    GB_TECHNIKFL_TF_VON_NGF: 35.9,
    GB_VERKEHRSFL_VF_VON_NGF: 58.17,
    GB_MIETFL_GESAMT: 1734.9,
    GB_MIETFL_1_BSB: 1734.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991707,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.29,
    GB_NUTZFL_NF_VON_NGF: 81.21,
    GB_TECHNIKFL_TF_VON_NGF: 4.12,
    GB_VERKEHRSFL_VF_VON_NGF: 16.96,
    GB_MIETFL_GESAMT: 102.29,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991708,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705056,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705056',
    GB_SAP_GB_ID_PKF: '7000/705056/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991709,
    GB_WE_OBJ_ID_FKT: 1989330,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1758.8,
    GB_NUTZFL_NF_VON_NGF: 1438.67,
    GB_TECHNIKFL_TF_VON_NGF: 50.39,
    GB_VERKEHRSFL_VF_VON_NGF: 269.74,
    GB_MIETFL_GESAMT: 1758.8,
    GB_MIETFL_1_BSB: 1399.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991777,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1681.53,
    GB_NUTZFL_NF_VON_NGF: 1208.1,
    GB_TECHNIKFL_TF_VON_NGF: 62.02,
    GB_VERKEHRSFL_VF_VON_NGF: 411.41,
    GB_MIETFL_GESAMT: 1681.53,
    GB_MIETFL_1_BSB: 1611.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '02.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991778,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.617796',
    GB_LAENGENGRAD_OESTLICH: '10.155029',
    GB_NETTOGRUNDFL_NGF: 535.02,
    GB_NUTZFL_NF_VON_NGF: 511.23,
    GB_TECHNIKFL_TF_VON_NGF: 15.48,
    GB_VERKEHRSFL_VF_VON_NGF: 8.31,
    GB_MIETFL_GESAMT: 535.02,
    GB_MIETFL_1_BSB: 535.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991779,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.671148',
    GB_LAENGENGRAD_OESTLICH: '10.154078',
    GB_NETTOGRUNDFL_NGF: 1134.35,
    GB_NUTZFL_NF_VON_NGF: 849.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 284.61,
    GB_MIETFL_GESAMT: 1134.35,
    GB_MIETFL_1_BSB: 1127.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '02.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5327469,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 345.24,
    GB_NUTZFL_NF_VON_NGF: 317.9,
    GB_TECHNIKFL_TF_VON_NGF: 11.84,
    GB_VERKEHRSFL_VF_VON_NGF: 15.5,
    GB_MIETFL_GESAMT: 345.24,
    GB_MIETFL_1_BSB: 345.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '24.06.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479159,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.0,
    GB_NUTZFL_NF_VON_NGF: 167.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '01.09.22',
    GB_GB_OBJ_ID_PKT: 5174594,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705065,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705065',
    GB_SAP_GB_ID_PKF: '7000/705065/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.65,
    GB_NUTZFL_NF_VON_NGF: 151.05,
    GB_TECHNIKFL_TF_VON_NGF: 3.8,
    GB_VERKEHRSFL_VF_VON_NGF: 9.8,
    GB_MIETFL_GESAMT: 164.65,
    GB_MIETFL_1_BSB: 164.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7501953,
    GB_WE_OBJ_ID_FKT: 1989339,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1415.52,
    GB_NUTZFL_NF_VON_NGF: 1155.04,
    GB_TECHNIKFL_TF_VON_NGF: 32.28,
    GB_VERKEHRSFL_VF_VON_NGF: 228.2,
    GB_MIETFL_GESAMT: 1415.52,
    GB_MIETFL_1_BSB: 1246.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991857,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 689.07,
    GB_NUTZFL_NF_VON_NGF: 566.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 122.33,
    GB_MIETFL_GESAMT: 689.07,
    GB_MIETFL_1_BSB: 489.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991858,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 735.9,
    GB_NUTZFL_NF_VON_NGF: 595.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 140.69,
    GB_MIETFL_GESAMT: 735.9,
    GB_MIETFL_1_BSB: 506.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991859,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 701.24,
    GB_NUTZFL_NF_VON_NGF: 592.2,
    GB_TECHNIKFL_TF_VON_NGF: 1.08,
    GB_VERKEHRSFL_VF_VON_NGF: 107.96,
    GB_MIETFL_GESAMT: 701.24,
    GB_MIETFL_1_BSB: 499.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991860,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 432.45,
    GB_NUTZFL_NF_VON_NGF: 340.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 92.45,
    GB_MIETFL_GESAMT: 432.45,
    GB_MIETFL_1_BSB: 432.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991861,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.597247',
    GB_LAENGENGRAD_OESTLICH: '10.137261',
    GB_NETTOGRUNDFL_NGF: 661.4,
    GB_NUTZFL_NF_VON_NGF: 616.82,
    GB_TECHNIKFL_TF_VON_NGF: 16.48,
    GB_VERKEHRSFL_VF_VON_NGF: 28.1,
    GB_MIETFL_GESAMT: 661.4,
    GB_MIETFL_1_BSB: 661.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991862,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.49,
    GB_NUTZFL_NF_VON_NGF: 378.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.87,
    GB_MIETFL_GESAMT: 487.49,
    GB_MIETFL_1_BSB: 487.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991863,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 655.73,
    GB_NUTZFL_NF_VON_NGF: 457.56,
    GB_TECHNIKFL_TF_VON_NGF: 63.61,
    GB_VERKEHRSFL_VF_VON_NGF: 134.56,
    GB_MIETFL_GESAMT: 655.73,
    GB_MIETFL_1_BSB: 503.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991864,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705075,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705075',
    GB_SAP_GB_ID_PKF: '7000/705075/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.41,
    GB_NUTZFL_NF_VON_NGF: 124.13,
    GB_TECHNIKFL_TF_VON_NGF: 3.92,
    GB_VERKEHRSFL_VF_VON_NGF: 22.36,
    GB_MIETFL_GESAMT: 150.41,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991865,
    GB_WE_OBJ_ID_FKT: 1989349,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.604679996',
    GB_LAENGENGRAD_OESTLICH: '10.166305040',
    GB_NETTOGRUNDFL_NGF: 949.45,
    GB_NUTZFL_NF_VON_NGF: 690.74,
    GB_TECHNIKFL_TF_VON_NGF: 122.01,
    GB_VERKEHRSFL_VF_VON_NGF: 136.7,
    GB_MIETFL_GESAMT: 949.45,
    GB_MIETFL_1_BSB: 539.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991766,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.604740766',
    GB_LAENGENGRAD_OESTLICH: '10.166691948',
    GB_NETTOGRUNDFL_NGF: 559.34,
    GB_NUTZFL_NF_VON_NGF: 522.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.73,
    GB_MIETFL_GESAMT: 559.34,
    GB_MIETFL_1_BSB: 559.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991767,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.604587717',
    GB_LAENGENGRAD_OESTLICH: '10.167488523',
    GB_NETTOGRUNDFL_NGF: 624.02,
    GB_NUTZFL_NF_VON_NGF: 595.03,
    GB_TECHNIKFL_TF_VON_NGF: 6.75,
    GB_VERKEHRSFL_VF_VON_NGF: 22.24,
    GB_MIETFL_GESAMT: 624.02,
    GB_MIETFL_1_BSB: 624.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.03.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991768,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.604102281',
    GB_LAENGENGRAD_OESTLICH: '10.167412247',
    GB_NETTOGRUNDFL_NGF: 853.24,
    GB_NUTZFL_NF_VON_NGF: 778.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.6,
    GB_MIETFL_GESAMT: 853.24,
    GB_MIETFL_1_BSB: 853.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.06.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991769,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.604121017',
    GB_LAENGENGRAD_OESTLICH: '10.168138505',
    GB_NETTOGRUNDFL_NGF: 534.77,
    GB_NUTZFL_NF_VON_NGF: 510.94,
    GB_TECHNIKFL_TF_VON_NGF: 15.45,
    GB_VERKEHRSFL_VF_VON_NGF: 8.38,
    GB_MIETFL_GESAMT: 534.77,
    GB_MIETFL_1_BSB: 534.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991770,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.603521454',
    GB_LAENGENGRAD_OESTLICH: '10.167777631',
    GB_NETTOGRUNDFL_NGF: 436.3,
    GB_NUTZFL_NF_VON_NGF: 334.39,
    GB_TECHNIKFL_TF_VON_NGF: 7.55,
    GB_VERKEHRSFL_VF_VON_NGF: 94.36,
    GB_MIETFL_GESAMT: 436.3,
    GB_MIETFL_1_BSB: 436.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991771,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.603483981',
    GB_LAENGENGRAD_OESTLICH: '10.167227299',
    GB_NETTOGRUNDFL_NGF: 441.05,
    GB_NUTZFL_NF_VON_NGF: 385.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.28,
    GB_MIETFL_GESAMT: 441.05,
    GB_MIETFL_1_BSB: 441.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991772,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.603633872',
    GB_LAENGENGRAD_OESTLICH: '10.166843871',
    GB_NETTOGRUNDFL_NGF: 441.33,
    GB_NUTZFL_NF_VON_NGF: 386.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.28,
    GB_MIETFL_GESAMT: 441.33,
    GB_MIETFL_1_BSB: 441.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991773,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.603952391',
    GB_LAENGENGRAD_OESTLICH: '10.166658923',
    GB_NETTOGRUNDFL_NGF: 438.4,
    GB_NUTZFL_NF_VON_NGF: 390.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.08,
    GB_MIETFL_GESAMT: 438.4,
    GB_MIETFL_1_BSB: 438.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991774,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1529.76,
    GB_NUTZFL_NF_VON_NGF: 997.87,
    GB_TECHNIKFL_TF_VON_NGF: 102.61,
    GB_VERKEHRSFL_VF_VON_NGF: 429.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.06.18',
    GB_GB_OBJ_ID_PKT: 1991900,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 366.62,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 366.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991901,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.06,
    GB_NUTZFL_NF_VON_NGF: 387.74,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 53.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991902,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.604163843',
    GB_LAENGENGRAD_OESTLICH: '10.166573216',
    GB_NETTOGRUNDFL_NGF: 438.09,
    GB_NUTZFL_NF_VON_NGF: 389.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.12,
    GB_MIETFL_GESAMT: 438.09,
    GB_MIETFL_1_BSB: 438.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991775,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705064,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705064',
    GB_SAP_GB_ID_PKF: '7000/705064/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.604426149',
    GB_LAENGENGRAD_OESTLICH: '10.166541639',
    GB_NETTOGRUNDFL_NGF: 438.35,
    GB_NUTZFL_NF_VON_NGF: 390.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.01,
    GB_MIETFL_GESAMT: 438.35,
    GB_MIETFL_1_BSB: 438.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991776,
    GB_WE_OBJ_ID_FKT: 1989338,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1050.82,
    GB_NUTZFL_NF_VON_NGF: 629.93,
    GB_TECHNIKFL_TF_VON_NGF: 33.49,
    GB_VERKEHRSFL_VF_VON_NGF: 387.4,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991903,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 302.0,
    GB_NUTZFL_NF_VON_NGF: 296.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991904,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 35.75,
    GB_NUTZFL_NF_VON_NGF: 35.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991905,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 413.62,
    GB_NUTZFL_NF_VON_NGF: 386.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.29,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991906,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 203.21,
    GB_NUTZFL_NF_VON_NGF: 196.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991907,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 218.16,
    GB_NUTZFL_NF_VON_NGF: 211.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991908,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991909,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991910,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 95.84,
    GB_NUTZFL_NF_VON_NGF: 86.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991911,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1991912,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 297.57,
    GB_NUTZFL_NF_VON_NGF: 289.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1991913,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/15',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.601818',
    GB_LAENGENGRAD_OESTLICH: '10.147600',
    GB_NETTOGRUNDFL_NGF: 5334.01,
    GB_NUTZFL_NF_VON_NGF: 3218.49,
    GB_TECHNIKFL_TF_VON_NGF: 318.0,
    GB_VERKEHRSFL_VF_VON_NGF: 1797.52,
    GB_MIETFL_GESAMT: 5334.01,
    GB_MIETFL_1_BSB: 5163.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991914,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 476.5,
    GB_NUTZFL_NF_VON_NGF: 332.68,
    GB_TECHNIKFL_TF_VON_NGF: 2.74,
    GB_VERKEHRSFL_VF_VON_NGF: 141.08,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991915,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/17',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.600785',
    GB_LAENGENGRAD_OESTLICH: '10.145191',
    GB_NETTOGRUNDFL_NGF: 3269.4,
    GB_NUTZFL_NF_VON_NGF: 2187.32,
    GB_TECHNIKFL_TF_VON_NGF: 273.37,
    GB_VERKEHRSFL_VF_VON_NGF: 808.71,
    GB_MIETFL_GESAMT: 3269.4,
    GB_MIETFL_1_BSB: 3266.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5174595,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.601739',
    GB_LAENGENGRAD_OESTLICH: '10.145730',
    GB_NETTOGRUNDFL_NGF: 3403.35,
    GB_NUTZFL_NF_VON_NGF: 2253.77,
    GB_TECHNIKFL_TF_VON_NGF: 42.16,
    GB_VERKEHRSFL_VF_VON_NGF: 1107.42,
    GB_MIETFL_GESAMT: 3403.35,
    GB_MIETFL_1_BSB: 3403.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.01.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5382563,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/19',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7118970,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705079,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/705079',
    GB_SAP_GB_ID_PKF: '7000/705079/20',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7118971,
    GB_WE_OBJ_ID_FKT: 1989353,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705101,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705101',
    GB_SAP_GB_ID_PKF: '7000/705101/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 285.15,
    GB_NUTZFL_NF_VON_NGF: 285.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 285.15,
    GB_MIETFL_1_BSB: 285.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992027,
    GB_WE_OBJ_ID_FKT: 1989375,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705101,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705101',
    GB_SAP_GB_ID_PKF: '7000/705101/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.14',
    GB_GB_OBJ_ID_PKT: 1992028,
    GB_WE_OBJ_ID_FKT: 1989375,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705101,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705101',
    GB_SAP_GB_ID_PKF: '7000/705101/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.623579',
    GB_LAENGENGRAD_OESTLICH: '10.148.836',
    GB_NETTOGRUNDFL_NGF: 1539.21,
    GB_NUTZFL_NF_VON_NGF: 1150.8,
    GB_TECHNIKFL_TF_VON_NGF: 87.79,
    GB_VERKEHRSFL_VF_VON_NGF: 300.62,
    GB_MIETFL_GESAMT: 1539.21,
    GB_MIETFL_1_BSB: 1447.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.07.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992029,
    GB_WE_OBJ_ID_FKT: 1989375,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705101,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705101',
    GB_SAP_GB_ID_PKF: '7000/705101/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.624130',
    GB_LAENGENGRAD_OESTLICH: '10.147314',
    GB_NETTOGRUNDFL_NGF: 625.88,
    GB_NUTZFL_NF_VON_NGF: 585.19,
    GB_TECHNIKFL_TF_VON_NGF: 18.39,
    GB_VERKEHRSFL_VF_VON_NGF: 22.3,
    GB_MIETFL_GESAMT: 625.88,
    GB_MIETFL_1_BSB: 625.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992030,
    GB_WE_OBJ_ID_FKT: 1989375,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705101,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705101',
    GB_SAP_GB_ID_PKF: '7000/705101/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.623750',
    GB_LAENGENGRAD_OESTLICH: '10.147483',
    GB_NETTOGRUNDFL_NGF: 1403.99,
    GB_NUTZFL_NF_VON_NGF: 1251.49,
    GB_TECHNIKFL_TF_VON_NGF: 8.55,
    GB_VERKEHRSFL_VF_VON_NGF: 143.95,
    GB_MIETFL_GESAMT: 1403.99,
    GB_MIETFL_1_BSB: 1400.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '25.04.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7237465,
    GB_WE_OBJ_ID_FKT: 1989375,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2824.54,
    GB_NUTZFL_NF_VON_NGF: 2117.63,
    GB_TECHNIKFL_TF_VON_NGF: 111.22,
    GB_VERKEHRSFL_VF_VON_NGF: 595.69,
    GB_MIETFL_GESAMT: 2824.54,
    GB_MIETFL_1_BSB: 2413.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991749,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 516.34,
    GB_NUTZFL_NF_VON_NGF: 383.54,
    GB_TECHNIKFL_TF_VON_NGF: 61.71,
    GB_VERKEHRSFL_VF_VON_NGF: 71.09,
    GB_MIETFL_GESAMT: 516.34,
    GB_MIETFL_1_BSB: 404.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991750,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 409.15,
    GB_NUTZFL_NF_VON_NGF: 349.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.86,
    GB_MIETFL_GESAMT: 409.15,
    GB_MIETFL_1_BSB: 409.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991751,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 408.84,
    GB_NUTZFL_NF_VON_NGF: 349.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.71,
    GB_MIETFL_GESAMT: 408.84,
    GB_MIETFL_1_BSB: 408.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991752,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 408.86,
    GB_NUTZFL_NF_VON_NGF: 349.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.45,
    GB_MIETFL_GESAMT: 408.86,
    GB_MIETFL_1_BSB: 408.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991753,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 227.69,
    GB_NUTZFL_NF_VON_NGF: 208.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.4,
    GB_MIETFL_GESAMT: 227.69,
    GB_MIETFL_1_BSB: 227.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991754,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 231.27,
    GB_NUTZFL_NF_VON_NGF: 223.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.06,
    GB_MIETFL_GESAMT: 231.27,
    GB_MIETFL_1_BSB: 231.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991755,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 438.69,
    GB_NUTZFL_NF_VON_NGF: 418.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.53,
    GB_MIETFL_GESAMT: 438.69,
    GB_MIETFL_1_BSB: 438.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991756,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 413.3,
    GB_NUTZFL_NF_VON_NGF: 363.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 49.37,
    GB_MIETFL_GESAMT: 413.3,
    GB_MIETFL_1_BSB: 358.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991757,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 229.08,
    GB_NUTZFL_NF_VON_NGF: 212.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.77,
    GB_MIETFL_GESAMT: 229.08,
    GB_MIETFL_1_BSB: 229.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991758,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 365.58,
    GB_NUTZFL_NF_VON_NGF: 305.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.13,
    GB_MIETFL_GESAMT: 365.58,
    GB_MIETFL_1_BSB: 365.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991759,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 365.69,
    GB_NUTZFL_NF_VON_NGF: 305.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.36,
    GB_MIETFL_GESAMT: 365.69,
    GB_MIETFL_1_BSB: 365.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991760,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 364.85,
    GB_NUTZFL_NF_VON_NGF: 304.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.94,
    GB_MIETFL_GESAMT: 364.85,
    GB_MIETFL_1_BSB: 364.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991761,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: '53.624245',
    GB_LAENGENGRAD_OESTLICH: '10.149689',
    GB_NETTOGRUNDFL_NGF: 1723.37,
    GB_NUTZFL_NF_VON_NGF: 1595.11,
    GB_TECHNIKFL_TF_VON_NGF: 32.59,
    GB_VERKEHRSFL_VF_VON_NGF: 95.67,
    GB_MIETFL_GESAMT: 1723.37,
    GB_MIETFL_1_BSB: 1723.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991762,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.24,
    GB_NUTZFL_NF_VON_NGF: 151.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '30.04.15',
    GB_GB_OBJ_ID_PKT: 5176393,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705062,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705062',
    GB_SAP_GB_ID_PKF: '7000/705062/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 149.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '30.04.15',
    GB_GB_OBJ_ID_PKT: 5176394,
    GB_WE_OBJ_ID_FKT: 1989336,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.606519',
    GB_LAENGENGRAD_OESTLICH: '10.171291',
    GB_NETTOGRUNDFL_NGF: 199.35,
    GB_NUTZFL_NF_VON_NGF: 173.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.51,
    GB_MIETFL_GESAMT: 199.35,
    GB_MIETFL_1_BSB: 199.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991931,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.606732897',
    GB_LAENGENGRAD_OESTLICH: '10.170716863',
    GB_NETTOGRUNDFL_NGF: 307.75,
    GB_NUTZFL_NF_VON_NGF: 265.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.72,
    GB_MIETFL_GESAMT: 307.75,
    GB_MIETFL_1_BSB: 191.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991929,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.606765070',
    GB_LAENGENGRAD_OESTLICH: '10.171051782',
    GB_NETTOGRUNDFL_NGF: 703.51,
    GB_NUTZFL_NF_VON_NGF: 582.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.57,
    GB_MIETFL_GESAMT: 703.51,
    GB_MIETFL_1_BSB: 703.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991930,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.605821',
    GB_LAENGENGRAD_OESTLICH: '10.171559',
    GB_NETTOGRUNDFL_NGF: 305.33,
    GB_NUTZFL_NF_VON_NGF: 283.09,
    GB_TECHNIKFL_TF_VON_NGF: 14.19,
    GB_VERKEHRSFL_VF_VON_NGF: 8.05,
    GB_MIETFL_GESAMT: 305.33,
    GB_MIETFL_1_BSB: 305.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991932,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.605853',
    GB_LAENGENGRAD_OESTLICH: '10.170386',
    GB_NETTOGRUNDFL_NGF: 386.26,
    GB_NUTZFL_NF_VON_NGF: 327.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.8,
    GB_MIETFL_GESAMT: 386.26,
    GB_MIETFL_1_BSB: 386.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991933,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.606054',
    GB_LAENGENGRAD_OESTLICH: '10.170379',
    GB_NETTOGRUNDFL_NGF: 385.45,
    GB_NUTZFL_NF_VON_NGF: 326.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.09,
    GB_MIETFL_GESAMT: 385.45,
    GB_MIETFL_1_BSB: 385.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991934,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 572.03,
    GB_NUTZFL_NF_VON_NGF: 426.95,
    GB_TECHNIKFL_TF_VON_NGF: 61.18,
    GB_VERKEHRSFL_VF_VON_NGF: 83.9,
    GB_MIETFL_GESAMT: 572.03,
    GB_MIETFL_1_BSB: 381.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991935,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.606397',
    GB_LAENGENGRAD_OESTLICH: '10.170363',
    GB_NETTOGRUNDFL_NGF: 384.71,
    GB_NUTZFL_NF_VON_NGF: 325.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.13,
    GB_MIETFL_GESAMT: 384.71,
    GB_MIETFL_1_BSB: 384.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991936,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705082,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705082',
    GB_SAP_GB_ID_PKF: '7000/705082/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7232775,
    GB_WE_OBJ_ID_FKT: 1989356,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705103,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705103',
    GB_SAP_GB_ID_PKF: '7000/705103/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 624.25,
    GB_NUTZFL_NF_VON_NGF: 435.87,
    GB_TECHNIKFL_TF_VON_NGF: 74.69,
    GB_VERKEHRSFL_VF_VON_NGF: 113.69,
    GB_MIETFL_GESAMT: 624.25,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 624.25,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992031,
    GB_WE_OBJ_ID_FKT: 1989376,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705103,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705103',
    GB_SAP_GB_ID_PKF: '7000/705103/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.605900525',
    GB_LAENGENGRAD_OESTLICH: '10.168841956',
    GB_NETTOGRUNDFL_NGF: 673.35,
    GB_NUTZFL_NF_VON_NGF: 485.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 187.7,
    GB_MIETFL_GESAMT: 673.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 673.35,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992032,
    GB_WE_OBJ_ID_FKT: 1989376,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800545,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1426.7,
    GB_NUTZFL_NF_VON_NGF: 944.88,
    GB_TECHNIKFL_TF_VON_NGF: 35.4,
    GB_VERKEHRSFL_VF_VON_NGF: 446.42,
    GB_MIETFL_GESAMT: 1426.7,
    GB_MIETFL_1_BSB: 1182.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991991,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 434.23,
    GB_NUTZFL_NF_VON_NGF: 370.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.65,
    GB_MIETFL_GESAMT: 434.23,
    GB_MIETFL_1_BSB: 434.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991992,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 435.35,
    GB_NUTZFL_NF_VON_NGF: 381.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.84,
    GB_MIETFL_GESAMT: 435.35,
    GB_MIETFL_1_BSB: 435.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991993,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 430.15,
    GB_NUTZFL_NF_VON_NGF: 338.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 91.39,
    GB_MIETFL_GESAMT: 430.15,
    GB_MIETFL_1_BSB: 430.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991994,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.628702',
    GB_LAENGENGRAD_OESTLICH: '10.170416',
    GB_NETTOGRUNDFL_NGF: 629.07,
    GB_NUTZFL_NF_VON_NGF: 600.1,
    GB_TECHNIKFL_TF_VON_NGF: 6.64,
    GB_VERKEHRSFL_VF_VON_NGF: 22.33,
    GB_MIETFL_GESAMT: 629.07,
    GB_MIETFL_1_BSB: 629.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991995,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 303.26,
    GB_NUTZFL_NF_VON_NGF: 268.34,
    GB_TECHNIKFL_TF_VON_NGF: 3.18,
    GB_VERKEHRSFL_VF_VON_NGF: 31.74,
    GB_MIETFL_GESAMT: 303.26,
    GB_MIETFL_1_BSB: 303.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991996,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 304.68,
    GB_NUTZFL_NF_VON_NGF: 287.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.05,
    GB_MIETFL_GESAMT: 304.68,
    GB_MIETFL_1_BSB: 304.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991997,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 290.41,
    GB_NUTZFL_NF_VON_NGF: 242.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.66,
    GB_MIETFL_GESAMT: 290.41,
    GB_MIETFL_1_BSB: 290.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991998,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 417.02,
    GB_NUTZFL_NF_VON_NGF: 363.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.4,
    GB_MIETFL_GESAMT: 417.02,
    GB_MIETFL_1_BSB: 417.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991999,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 416.77,
    GB_NUTZFL_NF_VON_NGF: 363.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.14,
    GB_MIETFL_GESAMT: 416.77,
    GB_MIETFL_1_BSB: 416.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992000,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 174.44,
    GB_NUTZFL_NF_VON_NGF: 154.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.73,
    GB_MIETFL_GESAMT: 174.44,
    GB_MIETFL_1_BSB: 174.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992001,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705093,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705093',
    GB_SAP_GB_ID_PKF: '7000/705093/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7255695,
    GB_WE_OBJ_ID_FKT: 1989367,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800471,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3650.36,
    GB_NUTZFL_NF_VON_NGF: 2617.64,
    GB_TECHNIKFL_TF_VON_NGF: 73.32,
    GB_VERKEHRSFL_VF_VON_NGF: 959.4,
    GB_MIETFL_GESAMT: 3650.36,
    GB_MIETFL_1_BSB: 3577.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991780,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.01.23',
    GB_GB_OBJ_ID_PKT: 1991781,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 326.78,
    GB_NUTZFL_NF_VON_NGF: 292.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.33,
    GB_MIETFL_GESAMT: 326.78,
    GB_MIETFL_1_BSB: 326.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991782,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 722.76,
    GB_NUTZFL_NF_VON_NGF: 523.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 198.78,
    GB_MIETFL_GESAMT: 722.76,
    GB_MIETFL_1_BSB: 722.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991783,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 641.05,
    GB_NUTZFL_NF_VON_NGF: 576.33,
    GB_TECHNIKFL_TF_VON_NGF: 18.23,
    GB_VERKEHRSFL_VF_VON_NGF: 46.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.04.15',
    GB_GB_OBJ_ID_PKT: 1991784,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.659826',
    GB_LAENGENGRAD_OESTLICH: '10.100657',
    GB_NETTOGRUNDFL_NGF: 591.1,
    GB_NUTZFL_NF_VON_NGF: 560.54,
    GB_TECHNIKFL_TF_VON_NGF: 21.29,
    GB_VERKEHRSFL_VF_VON_NGF: 9.27,
    GB_MIETFL_GESAMT: 591.1,
    GB_MIETFL_1_BSB: 591.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991785,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 643.29,
    GB_NUTZFL_NF_VON_NGF: 515.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 127.32,
    GB_MIETFL_GESAMT: 643.29,
    GB_MIETFL_1_BSB: 643.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991786,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 536.28,
    GB_NUTZFL_NF_VON_NGF: 460.88,
    GB_TECHNIKFL_TF_VON_NGF: 31.72,
    GB_VERKEHRSFL_VF_VON_NGF: 43.68,
    GB_MIETFL_GESAMT: 536.28,
    GB_MIETFL_1_BSB: 504.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991787,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 355.88,
    GB_NUTZFL_NF_VON_NGF: 355.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 355.88,
    GB_MIETFL_1_BSB: 355.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991788,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 98.68,
    GB_NUTZFL_NF_VON_NGF: 98.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 98.68,
    GB_MIETFL_1_BSB: 98.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991789,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 707.48,
    GB_NUTZFL_NF_VON_NGF: 664.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.16,
    GB_MIETFL_GESAMT: 707.48,
    GB_MIETFL_1_BSB: 707.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991790,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/12',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 764.47,
    GB_NUTZFL_NF_VON_NGF: 612.51,
    GB_TECHNIKFL_TF_VON_NGF: 17.36,
    GB_VERKEHRSFL_VF_VON_NGF: 134.6,
    GB_MIETFL_GESAMT: 764.47,
    GB_MIETFL_1_BSB: 764.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991791,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/13',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.660084',
    GB_LAENGENGRAD_OESTLICH: '10.099737',
    GB_NETTOGRUNDFL_NGF: 2236.53,
    GB_NUTZFL_NF_VON_NGF: 1693.49,
    GB_TECHNIKFL_TF_VON_NGF: 343.31,
    GB_VERKEHRSFL_VF_VON_NGF: 199.73,
    GB_MIETFL_GESAMT: 2236.53,
    GB_MIETFL_1_BSB: 1895.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '07.07.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399779,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/14',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 164.33,
    GB_NUTZFL_NF_VON_NGF: 146.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.46,
    GB_MIETFL_GESAMT: 164.33,
    GB_MIETFL_1_BSB: 164.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7042087,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705066,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705066',
    GB_SAP_GB_ID_PKF: '7000/705066/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.6,
    GB_NUTZFL_NF_VON_NGF: 145.2,
    GB_TECHNIKFL_TF_VON_NGF: 3.1,
    GB_VERKEHRSFL_VF_VON_NGF: 14.3,
    GB_MIETFL_GESAMT: 162.6,
    GB_MIETFL_1_BSB: 162.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7359077,
    GB_WE_OBJ_ID_FKT: 1989340,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 581.21,
    GB_NUTZFL_NF_VON_NGF: 491.7,
    GB_TECHNIKFL_TF_VON_NGF: 27.58,
    GB_VERKEHRSFL_VF_VON_NGF: 61.93,
    GB_MIETFL_GESAMT: 581.21,
    GB_MIETFL_1_BSB: 542.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991242,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 133.2,
    GB_NUTZFL_NF_VON_NGF: 108.84,
    GB_TECHNIKFL_TF_VON_NGF: 7.84,
    GB_VERKEHRSFL_VF_VON_NGF: 16.52,
    GB_MIETFL_GESAMT: 133.2,
    GB_MIETFL_1_BSB: 66.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991243,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 957.55,
    GB_NUTZFL_NF_VON_NGF: 795.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 161.96,
    GB_MIETFL_GESAMT: 957.55,
    GB_MIETFL_1_BSB: 957.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991244,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.659458',
    GB_LAENGENGRAD_OESTLICH: '10.099000',
    GB_NETTOGRUNDFL_NGF: 624.46,
    GB_NUTZFL_NF_VON_NGF: 583.55,
    GB_TECHNIKFL_TF_VON_NGF: 21.86,
    GB_VERKEHRSFL_VF_VON_NGF: 19.05,
    GB_MIETFL_GESAMT: 624.46,
    GB_MIETFL_1_BSB: 624.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991245,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.01.23',
    GB_GB_OBJ_ID_PKT: 1991246,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1538.3,
    GB_NUTZFL_NF_VON_NGF: 1002.39,
    GB_TECHNIKFL_TF_VON_NGF: 103.13,
    GB_VERKEHRSFL_VF_VON_NGF: 432.78,
    GB_MIETFL_GESAMT: 1538.3,
    GB_MIETFL_1_BSB: 1427.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991247,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.18,
    GB_NUTZFL_NF_VON_NGF: 23.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.49,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.04.15',
    GB_GB_OBJ_ID_PKT: 1991248,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1330.23,
    GB_NUTZFL_NF_VON_NGF: 1037.59,
    GB_TECHNIKFL_TF_VON_NGF: 6.92,
    GB_VERKEHRSFL_VF_VON_NGF: 285.72,
    GB_MIETFL_GESAMT: 1330.23,
    GB_MIETFL_1_BSB: 1327.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399648,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.53,
    GB_NUTZFL_NF_VON_NGF: 178.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.21,
    GB_MIETFL_GESAMT: 206.53,
    GB_MIETFL_1_BSB: 206.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7507037,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705002,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705002',
    GB_SAP_GB_ID_PKF: '7000/705002/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 909.58,
    GB_NUTZFL_NF_VON_NGF: 826.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.64,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.18',
    GB_GB_OBJ_ID_PKT: 4352750,
    GB_WE_OBJ_ID_FKT: 1989276,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.638327',
    GB_LAENGENGRAD_OESTLICH: '10.118686',
    GB_NETTOGRUNDFL_NGF: 435.3,
    GB_NUTZFL_NF_VON_NGF: 376.88,
    GB_TECHNIKFL_TF_VON_NGF: 3.11,
    GB_VERKEHRSFL_VF_VON_NGF: 55.31,
    GB_MIETFL_GESAMT: 435.3,
    GB_MIETFL_1_BSB: 435.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991587,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.638521',
    GB_LAENGENGRAD_OESTLICH: '10.118647',
    GB_NETTOGRUNDFL_NGF: 436.84,
    GB_NUTZFL_NF_VON_NGF: 373.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.82,
    GB_MIETFL_GESAMT: 436.84,
    GB_MIETFL_1_BSB: 436.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991588,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.638732',
    GB_LAENGENGRAD_OESTLICH: '10.118581',
    GB_NETTOGRUNDFL_NGF: 519.68,
    GB_NUTZFL_NF_VON_NGF: 369.16,
    GB_TECHNIKFL_TF_VON_NGF: 82.52,
    GB_VERKEHRSFL_VF_VON_NGF: 68.0,
    GB_MIETFL_GESAMT: 519.68,
    GB_MIETFL_1_BSB: 432.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991589,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/4',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 254.32,
    GB_NUTZFL_NF_VON_NGF: 246.63,
    GB_TECHNIKFL_TF_VON_NGF: 3.4,
    GB_VERKEHRSFL_VF_VON_NGF: 4.29,
    GB_MIETFL_GESAMT: 254.32,
    GB_MIETFL_1_BSB: 254.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991590,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.639142',
    GB_LAENGENGRAD_OESTLICH: '10.119573',
    GB_NETTOGRUNDFL_NGF: 965.13,
    GB_NUTZFL_NF_VON_NGF: 871.07,
    GB_TECHNIKFL_TF_VON_NGF: 11.88,
    GB_VERKEHRSFL_VF_VON_NGF: 82.18,
    GB_MIETFL_GESAMT: 965.13,
    GB_MIETFL_1_BSB: 965.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.08.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991591,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 484.31,
    GB_NUTZFL_NF_VON_NGF: 356.42,
    GB_TECHNIKFL_TF_VON_NGF: 3.67,
    GB_VERKEHRSFL_VF_VON_NGF: 124.22,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.16',
    GB_GB_OBJ_ID_PKT: 1991592,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991593,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.52,
    GB_NUTZFL_NF_VON_NGF: 79.72,
    GB_TECHNIKFL_TF_VON_NGF: 1.58,
    GB_VERKEHRSFL_VF_VON_NGF: 23.22,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.16',
    GB_GB_OBJ_ID_PKT: 1991594,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.1,
    GB_NUTZFL_NF_VON_NGF: 86.53,
    GB_TECHNIKFL_TF_VON_NGF: 4.1,
    GB_VERKEHRSFL_VF_VON_NGF: 11.47,
    GB_MIETFL_GESAMT: 102.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5306679,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.638653',
    GB_LAENGENGRAD_OESTLICH: '10.119115',
    GB_NETTOGRUNDFL_NGF: 1967.74,
    GB_NUTZFL_NF_VON_NGF: 1620.22,
    GB_TECHNIKFL_TF_VON_NGF: 7.36,
    GB_VERKEHRSFL_VF_VON_NGF: 340.16,
    GB_MIETFL_GESAMT: 1967.74,
    GB_MIETFL_1_BSB: 1671.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '08.04.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399673,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 906.96,
    GB_NUTZFL_NF_VON_NGF: 686.84,
    GB_TECHNIKFL_TF_VON_NGF: 9.77,
    GB_VERKEHRSFL_VF_VON_NGF: 210.35,
    GB_MIETFL_GESAMT: 906.96,
    GB_MIETFL_1_BSB: 904.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399674,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705043,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705043',
    GB_SAP_GB_ID_PKF: '7000/705043/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '22.06.20',
    GB_GB_OBJ_ID_PKT: 4352758,
    GB_WE_OBJ_ID_FKT: 1989317,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1622.17,
    GB_NUTZFL_NF_VON_NGF: 1279.94,
    GB_TECHNIKFL_TF_VON_NGF: 43.74,
    GB_VERKEHRSFL_VF_VON_NGF: 298.49,
    GB_MIETFL_GESAMT: 1622.17,
    GB_MIETFL_1_BSB: 1622.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991920,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 276.27,
    GB_NUTZFL_NF_VON_NGF: 231.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.77,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 5322799,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 902.84,
    GB_NUTZFL_NF_VON_NGF: 736.97,
    GB_TECHNIKFL_TF_VON_NGF: 1.32,
    GB_VERKEHRSFL_VF_VON_NGF: 164.55,
    GB_MIETFL_GESAMT: 902.84,
    GB_MIETFL_1_BSB: 902.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991922,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: '53.649849',
    GB_LAENGENGRAD_OESTLICH: '10.109765',
    GB_NETTOGRUNDFL_NGF: 667.34,
    GB_NUTZFL_NF_VON_NGF: 625.81,
    GB_TECHNIKFL_TF_VON_NGF: 15.76,
    GB_VERKEHRSFL_VF_VON_NGF: 25.77,
    GB_MIETFL_GESAMT: 667.34,
    GB_MIETFL_1_BSB: 667.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991924,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.650154',
    GB_LAENGENGRAD_OESTLICH: '10.110292',
    GB_NETTOGRUNDFL_NGF: 620.97,
    GB_NUTZFL_NF_VON_NGF: 592.11,
    GB_TECHNIKFL_TF_VON_NGF: 6.74,
    GB_VERKEHRSFL_VF_VON_NGF: 22.12,
    GB_MIETFL_GESAMT: 620.97,
    GB_MIETFL_1_BSB: 620.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '14.03.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991923,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2852.75,
    GB_NUTZFL_NF_VON_NGF: 2067.71,
    GB_TECHNIKFL_TF_VON_NGF: 96.72,
    GB_VERKEHRSFL_VF_VON_NGF: 688.32,
    GB_MIETFL_GESAMT: 2852.75,
    GB_MIETFL_1_BSB: 2852.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991925,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.03.19',
    GB_GB_OBJ_ID_PKT: 1991926,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 457.47,
    GB_NUTZFL_NF_VON_NGF: 362.01,
    GB_TECHNIKFL_TF_VON_NGF: 4.78,
    GB_VERKEHRSFL_VF_VON_NGF: 90.68,
    GB_MIETFL_GESAMT: 457.47,
    GB_MIETFL_1_BSB: 457.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991927,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.91,
    GB_NUTZFL_NF_VON_NGF: 92.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.79,
    GB_MIETFL_GESAMT: 103.91,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991928,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.14,
    GB_NUTZFL_NF_VON_NGF: 207.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 0.0,
    GB_MIETFL_GESAMT: 207.14,
    GB_MIETFL_1_BSB: 207.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5332268,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 980.03,
    GB_NUTZFL_NF_VON_NGF: 881.2,
    GB_TECHNIKFL_TF_VON_NGF: 12.45,
    GB_VERKEHRSFL_VF_VON_NGF: 86.38,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 4352766,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705081,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705081',
    GB_SAP_GB_ID_PKF: '7000/705081/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.19',
    GB_GB_OBJ_ID_PKT: 5325223,
    GB_WE_OBJ_ID_FKT: 1989355,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705001',
    GB_SAP_GB_ID_PKF: '7000/705001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.652069109',
    GB_LAENGENGRAD_OESTLICH: '10.170292196',
    GB_NETTOGRUNDFL_NGF: 3765.27,
    GB_NUTZFL_NF_VON_NGF: 2527.82,
    GB_TECHNIKFL_TF_VON_NGF: 44.91,
    GB_VERKEHRSFL_VF_VON_NGF: 1192.54,
    GB_MIETFL_GESAMT: 3765.27,
    GB_MIETFL_1_BSB: 3753.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991241,
    GB_WE_OBJ_ID_FKT: 1989275,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705001',
    GB_SAP_GB_ID_PKF: '7000/705001/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.652432',
    GB_LAENGENGRAD_OESTLICH: '10.170756',
    GB_NETTOGRUNDFL_NGF: 1131.1,
    GB_NUTZFL_NF_VON_NGF: 802.91,
    GB_TECHNIKFL_TF_VON_NGF: 14.1,
    GB_VERKEHRSFL_VF_VON_NGF: 314.09,
    GB_MIETFL_GESAMT: 1131.1,
    GB_MIETFL_1_BSB: 1124.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.08.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5382562,
    GB_WE_OBJ_ID_FKT: 1989275,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705001,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705001',
    GB_SAP_GB_ID_PKF: '7000/705001/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude (Zubau)',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.15',
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5399647,
    GB_WE_OBJ_ID_FKT: 1989275,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705001,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705001',
    GB_SAP_GB_ID_PKF: '7000/705001/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.652493',
    GB_LAENGENGRAD_OESTLICH: '10.170011',
    GB_NETTOGRUNDFL_NGF: 161.25,
    GB_NUTZFL_NF_VON_NGF: 151.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Mietfrei',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352749,
    GB_WE_OBJ_ID_FKT: 1989275,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5347032,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.653166',
    GB_LAENGENGRAD_OESTLICH: '10.174772',
    GB_NETTOGRUNDFL_NGF: 2390.29,
    GB_NUTZFL_NF_VON_NGF: 1918.98,
    GB_TECHNIKFL_TF_VON_NGF: 29.47,
    GB_VERKEHRSFL_VF_VON_NGF: 441.84,
    GB_MIETFL_GESAMT: 2390.29,
    GB_MIETFL_1_BSB: 2061.7,
    GB_MIETFL_2_DRITTE: 79.68,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991975,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.16',
    GB_GB_OBJ_ID_PKT: 1991976,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.653198',
    GB_LAENGENGRAD_OESTLICH: '10.172966',
    GB_NETTOGRUNDFL_NGF: 1655.75,
    GB_NUTZFL_NF_VON_NGF: 1374.13,
    GB_TECHNIKFL_TF_VON_NGF: 15.69,
    GB_VERKEHRSFL_VF_VON_NGF: 265.93,
    GB_MIETFL_GESAMT: 1655.75,
    GB_MIETFL_1_BSB: 1655.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991977,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.653309066',
    GB_LAENGENGRAD_OESTLICH: '10.173881276',
    GB_NETTOGRUNDFL_NGF: 3907.0,
    GB_NUTZFL_NF_VON_NGF: 2857.86,
    GB_TECHNIKFL_TF_VON_NGF: 89.01,
    GB_VERKEHRSFL_VF_VON_NGF: 960.13,
    GB_MIETFL_GESAMT: 3907.0,
    GB_MIETFL_1_BSB: 3627.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991978,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.653623',
    GB_LAENGENGRAD_OESTLICH: '10.174614',
    GB_NETTOGRUNDFL_NGF: 786.68,
    GB_NUTZFL_NF_VON_NGF: 604.64,
    GB_TECHNIKFL_TF_VON_NGF: 10.6,
    GB_VERKEHRSFL_VF_VON_NGF: 171.44,
    GB_MIETFL_GESAMT: 786.68,
    GB_MIETFL_1_BSB: 784.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991979,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 833.57,
    GB_NUTZFL_NF_VON_NGF: 695.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 138.24,
    GB_MIETFL_GESAMT: 833.57,
    GB_MIETFL_1_BSB: 833.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991447,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/2',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 369.66,
    GB_NUTZFL_NF_VON_NGF: 333.15,
    GB_TECHNIKFL_TF_VON_NGF: 16.46,
    GB_VERKEHRSFL_VF_VON_NGF: 20.05,
    GB_MIETFL_GESAMT: 369.66,
    GB_MIETFL_1_BSB: 369.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991448,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 684.57,
    GB_NUTZFL_NF_VON_NGF: 498.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 186.18,
    GB_MIETFL_GESAMT: 684.57,
    GB_MIETFL_1_BSB: 684.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991449,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 578.42,
    GB_NUTZFL_NF_VON_NGF: 416.79,
    GB_TECHNIKFL_TF_VON_NGF: 22.75,
    GB_VERKEHRSFL_VF_VON_NGF: 138.88,
    GB_MIETFL_GESAMT: 578.42,
    GB_MIETFL_1_BSB: 564.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991450,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.652900',
    GB_LAENGENGRAD_OESTLICH: '10.174049',
    GB_NETTOGRUNDFL_NGF: 1398.71,
    GB_NUTZFL_NF_VON_NGF: 1054.8,
    GB_TECHNIKFL_TF_VON_NGF: 30.82,
    GB_VERKEHRSFL_VF_VON_NGF: 313.09,
    GB_MIETFL_GESAMT: 1398.71,
    GB_MIETFL_1_BSB: 1396.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399699,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705090,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705090',
    GB_SAP_GB_ID_PKF: '7000/705090/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552760,
    GB_WE_OBJ_ID_FKT: 1989364,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.643603',
    GB_LAENGENGRAD_OESTLICH: '10.183245',
    GB_NETTOGRUNDFL_NGF: 443.5,
    GB_NUTZFL_NF_VON_NGF: 323.41,
    GB_TECHNIKFL_TF_VON_NGF: 3.7,
    GB_VERKEHRSFL_VF_VON_NGF: 116.39,
    GB_MIETFL_GESAMT: 443.5,
    GB_MIETFL_1_BSB: 443.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991537,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.643899',
    GB_LAENGENGRAD_OESTLICH: '10.183414',
    GB_NETTOGRUNDFL_NGF: 449.46,
    GB_NUTZFL_NF_VON_NGF: 350.11,
    GB_TECHNIKFL_TF_VON_NGF: 3.48,
    GB_VERKEHRSFL_VF_VON_NGF: 95.87,
    GB_MIETFL_GESAMT: 449.46,
    GB_MIETFL_1_BSB: 449.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991538,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.644320',
    GB_LAENGENGRAD_OESTLICH: '10.183706',
    GB_NETTOGRUNDFL_NGF: 621.83,
    GB_NUTZFL_NF_VON_NGF: 579.97,
    GB_TECHNIKFL_TF_VON_NGF: 12.46,
    GB_VERKEHRSFL_VF_VON_NGF: 29.4,
    GB_MIETFL_GESAMT: 621.83,
    GB_MIETFL_1_BSB: 621.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991539,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/4',
    GB_SAP_GB_BEZEICHNUNG: 'Umkleidegeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Umkleidegeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.644160',
    GB_LAENGENGRAD_OESTLICH: '10.183916',
    GB_NETTOGRUNDFL_NGF: 136.61,
    GB_NUTZFL_NF_VON_NGF: 98.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.99,
    GB_MIETFL_GESAMT: 136.61,
    GB_MIETFL_1_BSB: 136.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991540,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.644007',
    GB_LAENGENGRAD_OESTLICH: '10.184187',
    GB_NETTOGRUNDFL_NGF: 495.14,
    GB_NUTZFL_NF_VON_NGF: 368.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 126.87,
    GB_MIETFL_GESAMT: 495.14,
    GB_MIETFL_1_BSB: 495.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991541,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.643735',
    GB_LAENGENGRAD_OESTLICH: '10.184206',
    GB_NETTOGRUNDFL_NGF: 421.35,
    GB_NUTZFL_NF_VON_NGF: 332.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 88.77,
    GB_MIETFL_GESAMT: 421.35,
    GB_MIETFL_1_BSB: 421.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991542,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/7',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.643797',
    GB_LAENGENGRAD_OESTLICH: '10.184501',
    GB_NETTOGRUNDFL_NGF: 41.34,
    GB_NUTZFL_NF_VON_NGF: 41.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 41.34,
    GB_MIETFL_1_BSB: 41.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991543,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.643535',
    GB_LAENGENGRAD_OESTLICH: '10.183621',
    GB_NETTOGRUNDFL_NGF: 463.91,
    GB_NUTZFL_NF_VON_NGF: 372.61,
    GB_TECHNIKFL_TF_VON_NGF: 5.08,
    GB_VERKEHRSFL_VF_VON_NGF: 86.22,
    GB_MIETFL_GESAMT: 463.91,
    GB_MIETFL_1_BSB: 463.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991544,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.644899760',
    GB_LAENGENGRAD_OESTLICH: '10.183732274',
    GB_NETTOGRUNDFL_NGF: 100.96,
    GB_NUTZFL_NF_VON_NGF: 87.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.5,
    GB_MIETFL_GESAMT: 100.96,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991545,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/10',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.644065902',
    GB_LAENGENGRAD_OESTLICH: '10.183416494',
    GB_NETTOGRUNDFL_NGF: 21.97,
    GB_NUTZFL_NF_VON_NGF: 16.01,
    GB_TECHNIKFL_TF_VON_NGF: 5.96,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 21.97,
    GB_MIETFL_1_BSB: 21.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138241,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/11',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.644442',
    GB_LAENGENGRAD_OESTLICH: '10.184087',
    GB_NETTOGRUNDFL_NGF: 808.62,
    GB_NUTZFL_NF_VON_NGF: 681.21,
    GB_TECHNIKFL_TF_VON_NGF: 9.22,
    GB_VERKEHRSFL_VF_VON_NGF: 118.19,
    GB_MIETFL_GESAMT: 808.62,
    GB_MIETFL_1_BSB: 804.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.04.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399669,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/12',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: '53.644374498',
    GB_LAENGENGRAD_OESTLICH: '10.184485811',
    GB_NETTOGRUNDFL_NGF: 344.43,
    GB_NUTZFL_NF_VON_NGF: 328.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.48,
    GB_MIETFL_GESAMT: 344.43,
    GB_MIETFL_1_BSB: 344.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.02.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5422188,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705037,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705037',
    GB_SAP_GB_ID_PKF: '7000/705037/13',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479154,
    GB_WE_OBJ_ID_FKT: 1989311,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5361199,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.651920201',
    GB_LAENGENGRAD_OESTLICH: '10.174704671',
    GB_NETTOGRUNDFL_NGF: 4642.16,
    GB_NUTZFL_NF_VON_NGF: 2607.03,
    GB_TECHNIKFL_TF_VON_NGF: 260.25,
    GB_VERKEHRSFL_VF_VON_NGF: 1774.88,
    GB_MIETFL_GESAMT: 4642.16,
    GB_MIETFL_1_BSB: 3989.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991985,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.652599152',
    GB_LAENGENGRAD_OESTLICH: '10.174492250',
    GB_NETTOGRUNDFL_NGF: 4843.51,
    GB_NUTZFL_NF_VON_NGF: 2954.22,
    GB_TECHNIKFL_TF_VON_NGF: 139.17,
    GB_VERKEHRSFL_VF_VON_NGF: 1750.12,
    GB_MIETFL_GESAMT: 4843.51,
    GB_MIETFL_1_BSB: 4319.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991986,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.652307',
    GB_LAENGENGRAD_OESTLICH: '10.175334',
    GB_NETTOGRUNDFL_NGF: 870.64,
    GB_NUTZFL_NF_VON_NGF: 661.54,
    GB_TECHNIKFL_TF_VON_NGF: 19.98,
    GB_VERKEHRSFL_VF_VON_NGF: 189.12,
    GB_MIETFL_GESAMT: 870.64,
    GB_MIETFL_1_BSB: 870.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991987,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.652250',
    GB_LAENGENGRAD_OESTLICH: '10.174967',
    GB_NETTOGRUNDFL_NGF: 1934.92,
    GB_NUTZFL_NF_VON_NGF: 1421.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 512.99,
    GB_MIETFL_GESAMT: 1934.92,
    GB_MIETFL_1_BSB: 1934.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991988,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.652680',
    GB_LAENGENGRAD_OESTLICH: '10.172420',
    GB_NETTOGRUNDFL_NGF: 2334.6,
    GB_NUTZFL_NF_VON_NGF: 1505.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 829.35,
    GB_MIETFL_GESAMT: 2334.6,
    GB_MIETFL_1_BSB: 1766.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991990,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: '53.652061320',
    GB_LAENGENGRAD_OESTLICH: '10.173829668',
    GB_NETTOGRUNDFL_NGF: 661.7,
    GB_NUTZFL_NF_VON_NGF: 373.03,
    GB_TECHNIKFL_TF_VON_NGF: 68.93,
    GB_VERKEHRSFL_VF_VON_NGF: 219.74,
    GB_MIETFL_GESAMT: 661.7,
    GB_MIETFL_1_BSB: 495.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991989,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.652024',
    GB_LAENGENGRAD_OESTLICH: '10.173151',
    GB_NETTOGRUNDFL_NGF: 1893.42,
    GB_NUTZFL_NF_VON_NGF: 1645.12,
    GB_TECHNIKFL_TF_VON_NGF: 67.12,
    GB_VERKEHRSFL_VF_VON_NGF: 181.18,
    GB_MIETFL_GESAMT: 1893.42,
    GB_MIETFL_1_BSB: 1893.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.03.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138312,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.652466',
    GB_LAENGENGRAD_OESTLICH: '10.173132',
    GB_NETTOGRUNDFL_NGF: 218.98,
    GB_NUTZFL_NF_VON_NGF: 168.75,
    GB_TECHNIKFL_TF_VON_NGF: 3.97,
    GB_VERKEHRSFL_VF_VON_NGF: 46.26,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7376500,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705092,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705092',
    GB_SAP_GB_ID_PKF: '7000/705092/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.651525',
    GB_LAENGENGRAD_OESTLICH: '10.173619',
    GB_NETTOGRUNDFL_NGF: 611.97,
    GB_NUTZFL_NF_VON_NGF: 569.29,
    GB_TECHNIKFL_TF_VON_NGF: 4.1,
    GB_VERKEHRSFL_VF_VON_NGF: 38.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7376501,
    GB_WE_OBJ_ID_FKT: 1989366,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 808.69,
    GB_NUTZFL_NF_VON_NGF: 622.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 186.45,
    GB_MIETFL_GESAMT: 808.69,
    GB_MIETFL_1_BSB: 808.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991451,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 242.74,
    GB_NUTZFL_NF_VON_NGF: 141.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.43,
    GB_MIETFL_GESAMT: 242.74,
    GB_MIETFL_1_BSB: 242.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991452,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1016.13,
    GB_NUTZFL_NF_VON_NGF: 773.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 242.52,
    GB_MIETFL_GESAMT: 1016.13,
    GB_MIETFL_1_BSB: 899.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991453,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.92,
    GB_NUTZFL_NF_VON_NGF: 361.99,
    GB_TECHNIKFL_TF_VON_NGF: 4.78,
    GB_VERKEHRSFL_VF_VON_NGF: 86.15,
    GB_MIETFL_GESAMT: 452.92,
    GB_MIETFL_1_BSB: 452.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991454,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.63,
    GB_NUTZFL_NF_VON_NGF: 386.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 83.03,
    GB_MIETFL_GESAMT: 469.63,
    GB_MIETFL_1_BSB: 469.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991455,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 468.51,
    GB_NUTZFL_NF_VON_NGF: 385.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 83.16,
    GB_MIETFL_GESAMT: 468.51,
    GB_MIETFL_1_BSB: 468.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991456,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.665845',
    GB_LAENGENGRAD_OESTLICH: '10.148690',
    GB_NETTOGRUNDFL_NGF: 1756.27,
    GB_NUTZFL_NF_VON_NGF: 1634.34,
    GB_TECHNIKFL_TF_VON_NGF: 16.6,
    GB_VERKEHRSFL_VF_VON_NGF: 105.33,
    GB_MIETFL_GESAMT: 1756.27,
    GB_MIETFL_1_BSB: 1756.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.03',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991457,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2254.01,
    GB_NUTZFL_NF_VON_NGF: 1698.87,
    GB_TECHNIKFL_TF_VON_NGF: 28.68,
    GB_VERKEHRSFL_VF_VON_NGF: 526.46,
    GB_MIETFL_GESAMT: 2254.01,
    GB_MIETFL_1_BSB: 2234.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991458,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/13',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.45,
    GB_NUTZFL_NF_VON_NGF: 122.9,
    GB_TECHNIKFL_TF_VON_NGF: 4.55,
    GB_VERKEHRSFL_VF_VON_NGF: 20.0,
    GB_MIETFL_GESAMT: 147.45,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991459,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705026,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705026',
    GB_SAP_GB_ID_PKF: '7000/705026/14',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 848.51,
    GB_NUTZFL_NF_VON_NGF: 540.93,
    GB_TECHNIKFL_TF_VON_NGF: 88.01,
    GB_VERKEHRSFL_VF_VON_NGF: 219.57,
    GB_MIETFL_GESAMT: 848.51,
    GB_MIETFL_1_BSB: 744.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352756,
    GB_WE_OBJ_ID_FKT: 1989300,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 963.58,
    GB_NUTZFL_NF_VON_NGF: 777.46,
    GB_TECHNIKFL_TF_VON_NGF: 50.07,
    GB_VERKEHRSFL_VF_VON_NGF: 136.05,
    GB_MIETFL_GESAMT: 963.58,
    GB_MIETFL_1_BSB: 520.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '08.09.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991305,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.649727',
    GB_LAENGENGRAD_OESTLICH: '10.148037',
    GB_NETTOGRUNDFL_NGF: 440.7,
    GB_NUTZFL_NF_VON_NGF: 382.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 57.74,
    GB_MIETFL_GESAMT: 440.7,
    GB_MIETFL_1_BSB: 440.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.04.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991306,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.650117',
    GB_LAENGENGRAD_OESTLICH: '10.147892',
    GB_NETTOGRUNDFL_NGF: 627.01,
    GB_NUTZFL_NF_VON_NGF: 589.39,
    GB_TECHNIKFL_TF_VON_NGF: 15.51,
    GB_VERKEHRSFL_VF_VON_NGF: 22.11,
    GB_MIETFL_GESAMT: 627.01,
    GB_MIETFL_1_BSB: 627.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.04.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991307,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 474.43,
    GB_NUTZFL_NF_VON_NGF: 394.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.43,
    GB_MIETFL_GESAMT: 474.43,
    GB_MIETFL_1_BSB: 474.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991308,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 112.9,
    GB_NUTZFL_NF_VON_NGF: 95.4,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.5,
    GB_MIETFL_GESAMT: 112.9,
    GB_MIETFL_1_BSB: 112.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991309,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 485.65,
    GB_NUTZFL_NF_VON_NGF: 434.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.58,
    GB_MIETFL_GESAMT: 485.65,
    GB_MIETFL_1_BSB: 485.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991310,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 114.23,
    GB_NUTZFL_NF_VON_NGF: 96.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.44,
    GB_MIETFL_GESAMT: 114.23,
    GB_MIETFL_1_BSB: 114.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991311,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 484.76,
    GB_NUTZFL_NF_VON_NGF: 432.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.85,
    GB_MIETFL_GESAMT: 484.76,
    GB_MIETFL_1_BSB: 484.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.06.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991312,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 114.97,
    GB_NUTZFL_NF_VON_NGF: 97.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.59,
    GB_MIETFL_GESAMT: 114.97,
    GB_MIETFL_1_BSB: 114.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '04.06.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991313,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 573.13,
    GB_NUTZFL_NF_VON_NGF: 519.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.16,
    GB_MIETFL_GESAMT: 573.13,
    GB_MIETFL_1_BSB: 480.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.11.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991314,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 114.13,
    GB_NUTZFL_NF_VON_NGF: 96.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.41,
    GB_MIETFL_GESAMT: 114.13,
    GB_MIETFL_1_BSB: 114.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.11.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991315,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/12',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.649445',
    GB_LAENGENGRAD_OESTLICH: '10.148116',
    GB_NETTOGRUNDFL_NGF: 520.59,
    GB_NUTZFL_NF_VON_NGF: 435.14,
    GB_TECHNIKFL_TF_VON_NGF: 53.64,
    GB_VERKEHRSFL_VF_VON_NGF: 31.81,
    GB_MIETFL_GESAMT: 520.59,
    GB_MIETFL_1_BSB: 459.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.09.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.14',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5366751,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705010,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705010',
    GB_SAP_GB_ID_PKF: '7000/705010/13',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '53.649831',
    GB_LAENGENGRAD_OESTLICH: '10.147964',
    GB_NETTOGRUNDFL_NGF: 320.66,
    GB_NUTZFL_NF_VON_NGF: 299.47,
    GB_TECHNIKFL_TF_VON_NGF: 12.76,
    GB_VERKEHRSFL_VF_VON_NGF: 8.43,
    GB_MIETFL_GESAMT: 320.66,
    GB_MIETFL_1_BSB: 320.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '11.04.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399651,
    GB_WE_OBJ_ID_FKT: 1989284,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.652144',
    GB_LAENGENGRAD_OESTLICH: '10.166664',
    GB_NETTOGRUNDFL_NGF: 279.98,
    GB_NUTZFL_NF_VON_NGF: 251.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.15,
    GB_MIETFL_GESAMT: 279.98,
    GB_MIETFL_1_BSB: 214.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991980,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.652295020',
    GB_LAENGENGRAD_OESTLICH: '10.166594262',
    GB_NETTOGRUNDFL_NGF: 167.53,
    GB_NUTZFL_NF_VON_NGF: 139.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.89,
    GB_MIETFL_GESAMT: 167.53,
    GB_MIETFL_1_BSB: 167.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991981,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.652570',
    GB_LAENGENGRAD_OESTLICH: '10.166798',
    GB_NETTOGRUNDFL_NGF: 367.56,
    GB_NUTZFL_NF_VON_NGF: 292.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.55,
    GB_MIETFL_GESAMT: 367.56,
    GB_MIETFL_1_BSB: 367.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991982,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.652395',
    GB_LAENGENGRAD_OESTLICH: '10.167305',
    GB_NETTOGRUNDFL_NGF: 888.29,
    GB_NUTZFL_NF_VON_NGF: 693.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 194.31,
    GB_MIETFL_GESAMT: 888.29,
    GB_MIETFL_1_BSB: 867.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991983,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.652230',
    GB_LAENGENGRAD_OESTLICH: '10.167463',
    GB_NETTOGRUNDFL_NGF: 827.76,
    GB_NUTZFL_NF_VON_NGF: 648.49,
    GB_TECHNIKFL_TF_VON_NGF: 59.84,
    GB_VERKEHRSFL_VF_VON_NGF: 119.43,
    GB_MIETFL_GESAMT: 827.76,
    GB_MIETFL_1_BSB: 583.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991984,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705091,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705091',
    GB_SAP_GB_ID_PKF: '7000/705091/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.652079',
    GB_LAENGENGRAD_OESTLICH: '10.167374',
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 82.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 92.53,
    GB_MIETFL_1_BSB: 92.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352771,
    GB_WE_OBJ_ID_FKT: 1989365,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7198474,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 452.87,
    GB_NUTZFL_NF_VON_NGF: 324.45,
    GB_TECHNIKFL_TF_VON_NGF: 8.56,
    GB_VERKEHRSFL_VF_VON_NGF: 119.86,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.07.18',
    GB_GB_OBJ_ID_PKT: 1991658,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 250.33,
    GB_NUTZFL_NF_VON_NGF: 250.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.03.19',
    GB_GB_OBJ_ID_PKT: 1991659,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 404.28,
    GB_NUTZFL_NF_VON_NGF: 337.55,
    GB_TECHNIKFL_TF_VON_NGF: 31.7,
    GB_VERKEHRSFL_VF_VON_NGF: 35.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.15',
    GB_GB_OBJ_ID_PKT: 1991660,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 249.77,
    GB_NUTZFL_NF_VON_NGF: 242.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.18',
    GB_GB_OBJ_ID_PKT: 1991661,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.18',
    GB_GB_OBJ_ID_PKT: 1991662,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 866.93,
    GB_NUTZFL_NF_VON_NGF: 738.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 128.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.15',
    GB_GB_OBJ_ID_PKT: 1991663,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 369.24,
    GB_NUTZFL_NF_VON_NGF: 310.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.15',
    GB_GB_OBJ_ID_PKT: 1991664,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991667,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.638295',
    GB_LAENGENGRAD_OESTLICH: '10.104482',
    GB_NETTOGRUNDFL_NGF: 1535.49,
    GB_NUTZFL_NF_VON_NGF: 1440.96,
    GB_TECHNIKFL_TF_VON_NGF: 26.16,
    GB_VERKEHRSFL_VF_VON_NGF: 68.37,
    GB_MIETFL_GESAMT: 1535.49,
    GB_MIETFL_1_BSB: 1535.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991665,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.638359',
    GB_LAENGENGRAD_OESTLICH: '10.103553',
    GB_NETTOGRUNDFL_NGF: 606.8,
    GB_NUTZFL_NF_VON_NGF: 577.64,
    GB_TECHNIKFL_TF_VON_NGF: 6.99,
    GB_VERKEHRSFL_VF_VON_NGF: 22.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '06.08.18',
    GB_GB_OBJ_ID_PKT: 1991666,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 952.33,
    GB_NUTZFL_NF_VON_NGF: 774.41,
    GB_TECHNIKFL_TF_VON_NGF: 9.62,
    GB_VERKEHRSFL_VF_VON_NGF: 168.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991668,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 913.43,
    GB_NUTZFL_NF_VON_NGF: 724.72,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 188.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.15',
    GB_GB_OBJ_ID_PKT: 1991669,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 418.9,
    GB_NUTZFL_NF_VON_NGF: 384.37,
    GB_TECHNIKFL_TF_VON_NGF: 9.21,
    GB_VERKEHRSFL_VF_VON_NGF: 25.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1991670,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 350.91,
    GB_NUTZFL_NF_VON_NGF: 316.07,
    GB_TECHNIKFL_TF_VON_NGF: 15.98,
    GB_VERKEHRSFL_VF_VON_NGF: 18.86,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.18',
    GB_GB_OBJ_ID_PKT: 1991671,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 67.45,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.18',
    GB_GB_OBJ_ID_PKT: 1991672,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 265.66,
    GB_NUTZFL_NF_VON_NGF: 77.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 187.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.03.19',
    GB_GB_OBJ_ID_PKT: 1991673,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6555.55,
    GB_NUTZFL_NF_VON_NGF: 4502.46,
    GB_TECHNIKFL_TF_VON_NGF: 258.01,
    GB_VERKEHRSFL_VF_VON_NGF: 1795.08,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.03.19',
    GB_GB_OBJ_ID_PKT: 1991674,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 858.92,
    GB_NUTZFL_NF_VON_NGF: 156.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 702.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '26.11.18',
    GB_GB_OBJ_ID_PKT: 1991675,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 807.95,
    GB_NUTZFL_NF_VON_NGF: 542.56,
    GB_TECHNIKFL_TF_VON_NGF: 61.22,
    GB_VERKEHRSFL_VF_VON_NGF: 204.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '26.11.18',
    GB_GB_OBJ_ID_PKT: 1991676,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/20',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.4,
    GB_NUTZFL_NF_VON_NGF: 223.06,
    GB_TECHNIKFL_TF_VON_NGF: 7.01,
    GB_VERKEHRSFL_VF_VON_NGF: 81.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.12.18',
    GB_GB_OBJ_ID_PKT: 1991677,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/21',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.639094177',
    GB_LAENGENGRAD_OESTLICH: '10.103132316',
    GB_NETTOGRUNDFL_NGF: 6277.66,
    GB_NUTZFL_NF_VON_NGF: 4895.43,
    GB_TECHNIKFL_TF_VON_NGF: 61.22,
    GB_VERKEHRSFL_VF_VON_NGF: 1321.01,
    GB_MIETFL_GESAMT: 6277.66,
    GB_MIETFL_1_BSB: 6267.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399678,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/22',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.639071484',
    GB_LAENGENGRAD_OESTLICH: '10.104446473',
    GB_NETTOGRUNDFL_NGF: 5862.77,
    GB_NUTZFL_NF_VON_NGF: 4465.99,
    GB_TECHNIKFL_TF_VON_NGF: 181.14,
    GB_VERKEHRSFL_VF_VON_NGF: 1215.64,
    GB_MIETFL_GESAMT: 5862.77,
    GB_MIETFL_1_BSB: 5710.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399679,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.77,
    GB_NUTZFL_NF_VON_NGF: 77.74,
    GB_TECHNIKFL_TF_VON_NGF: 4.62,
    GB_VERKEHRSFL_VF_VON_NGF: 8.41,
    GB_MIETFL_GESAMT: 90.77,
    GB_MIETFL_1_BSB: 90.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462192,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705052,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705052',
    GB_SAP_GB_ID_PKF: '7000/705052/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552754,
    GB_WE_OBJ_ID_FKT: 1989326,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1934,
    GB_BREITENGRAD_NOERDLICH: '53.637064158',
    GB_LAENGENGRAD_OESTLICH: '10.078565397',
    GB_NETTOGRUNDFL_NGF: 3959.7,
    GB_NUTZFL_NF_VON_NGF: 3092.11,
    GB_TECHNIKFL_TF_VON_NGF: 42.65,
    GB_VERKEHRSFL_VF_VON_NGF: 824.94,
    GB_MIETFL_GESAMT: 3959.7,
    GB_MIETFL_1_BSB: 2929.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991937,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.637384150',
    GB_LAENGENGRAD_OESTLICH: '10.078851053',
    GB_NETTOGRUNDFL_NGF: 131.1,
    GB_NUTZFL_NF_VON_NGF: 93.93,
    GB_TECHNIKFL_TF_VON_NGF: 6.49,
    GB_VERKEHRSFL_VF_VON_NGF: 30.68,
    GB_MIETFL_GESAMT: 131.1,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991938,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.636405',
    GB_LAENGENGRAD_OESTLICH: '10.078450',
    GB_NETTOGRUNDFL_NGF: 662.12,
    GB_NUTZFL_NF_VON_NGF: 615.69,
    GB_TECHNIKFL_TF_VON_NGF: 17.54,
    GB_VERKEHRSFL_VF_VON_NGF: 28.89,
    GB_MIETFL_GESAMT: 662.12,
    GB_MIETFL_1_BSB: 662.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991939,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1155.27,
    GB_NUTZFL_NF_VON_NGF: 931.99,
    GB_TECHNIKFL_TF_VON_NGF: 1.8,
    GB_VERKEHRSFL_VF_VON_NGF: 221.48,
    GB_MIETFL_GESAMT: 1155.27,
    GB_MIETFL_1_BSB: 1155.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991573,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.637022',
    GB_LAENGENGRAD_OESTLICH: '10.079740',
    GB_NETTOGRUNDFL_NGF: 763.28,
    GB_NUTZFL_NF_VON_NGF: 644.21,
    GB_TECHNIKFL_TF_VON_NGF: 3.53,
    GB_VERKEHRSFL_VF_VON_NGF: 115.54,
    GB_MIETFL_GESAMT: 763.28,
    GB_MIETFL_1_BSB: 760.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '28.08.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399694,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.637007',
    GB_LAENGENGRAD_OESTLICH: '10.079411',
    GB_NETTOGRUNDFL_NGF: 312.39,
    GB_NUTZFL_NF_VON_NGF: 236.94,
    GB_TECHNIKFL_TF_VON_NGF: 8.14,
    GB_VERKEHRSFL_VF_VON_NGF: 67.31,
    GB_MIETFL_GESAMT: 312.39,
    GB_MIETFL_1_BSB: 312.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.11.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399695,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7023403,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705083,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705083',
    GB_SAP_GB_ID_PKF: '7000/705083/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.636415223',
    GB_LAENGENGRAD_OESTLICH: '10.078915825',
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 82.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 92.53,
    GB_MIETFL_1_BSB: 92.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352767,
    GB_WE_OBJ_ID_FKT: 1989357,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 5924350,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.697268',
    GB_LAENGENGRAD_OESTLICH: '10.136273',
    GB_NETTOGRUNDFL_NGF: 3423.93,
    GB_NUTZFL_NF_VON_NGF: 2594.55,
    GB_TECHNIKFL_TF_VON_NGF: 49.78,
    GB_VERKEHRSFL_VF_VON_NGF: 779.6,
    GB_MIETFL_GESAMT: 3423.93,
    GB_MIETFL_1_BSB: 3263.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991298,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.696949',
    GB_LAENGENGRAD_OESTLICH: '10.136256',
    GB_NETTOGRUNDFL_NGF: 624.92,
    GB_NUTZFL_NF_VON_NGF: 595.96,
    GB_TECHNIKFL_TF_VON_NGF: 6.7,
    GB_VERKEHRSFL_VF_VON_NGF: 22.26,
    GB_MIETFL_GESAMT: 624.92,
    GB_MIETFL_1_BSB: 624.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.04.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991299,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.10.15',
    GB_GB_OBJ_ID_PKT: 1991300,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 751.82,
    GB_NUTZFL_NF_VON_NGF: 641.29,
    GB_TECHNIKFL_TF_VON_NGF: 3.28,
    GB_VERKEHRSFL_VF_VON_NGF: 107.25,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.10.15',
    GB_GB_OBJ_ID_PKT: 1991301,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 435.67,
    GB_NUTZFL_NF_VON_NGF: 339.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 96.13,
    GB_MIETFL_GESAMT: 435.67,
    GB_MIETFL_1_BSB: 435.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991302,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 436.35,
    GB_NUTZFL_NF_VON_NGF: 343.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.08,
    GB_MIETFL_GESAMT: 436.35,
    GB_MIETFL_1_BSB: 436.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991303,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705009,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705009',
    GB_SAP_GB_ID_PKF: '7000/705009/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.18,
    GB_NUTZFL_NF_VON_NGF: 324.09,
    GB_TECHNIKFL_TF_VON_NGF: 13.32,
    GB_VERKEHRSFL_VF_VON_NGF: 95.77,
    GB_MIETFL_GESAMT: 433.18,
    GB_MIETFL_1_BSB: 433.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991304,
    GB_WE_OBJ_ID_FKT: 1989283,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 7360729,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1933.6,
    GB_NUTZFL_NF_VON_NGF: 1530.06,
    GB_TECHNIKFL_TF_VON_NGF: 42.42,
    GB_VERKEHRSFL_VF_VON_NGF: 361.12,
    GB_MIETFL_GESAMT: 1933.6,
    GB_MIETFL_1_BSB: 1933.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991792,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3141.91,
    GB_NUTZFL_NF_VON_NGF: 2309.59,
    GB_TECHNIKFL_TF_VON_NGF: 71.53,
    GB_VERKEHRSFL_VF_VON_NGF: 760.79,
    GB_MIETFL_GESAMT: 3141.91,
    GB_MIETFL_1_BSB: 2852.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991793,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 636.03,
    GB_NUTZFL_NF_VON_NGF: 607.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.98,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.10.14',
    GB_GB_OBJ_ID_PKT: 1991794,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2336.02,
    GB_NUTZFL_NF_VON_NGF: 1802.26,
    GB_TECHNIKFL_TF_VON_NGF: 95.36,
    GB_VERKEHRSFL_VF_VON_NGF: 438.4,
    GB_MIETFL_GESAMT: 2336.02,
    GB_MIETFL_1_BSB: 1912.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991795,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.2,
    GB_NUTZFL_NF_VON_NGF: 92.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.77,
    GB_MIETFL_GESAMT: 104.2,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991796,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.693789',
    GB_LAENGENGRAD_OESTLICH: '10.142679',
    GB_NETTOGRUNDFL_NGF: 1891.24,
    GB_NUTZFL_NF_VON_NGF: 1681.95,
    GB_TECHNIKFL_TF_VON_NGF: 29.28,
    GB_VERKEHRSFL_VF_VON_NGF: 180.01,
    GB_MIETFL_GESAMT: 1891.24,
    GB_MIETFL_1_BSB: 1891.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.09.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5362900,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705067,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705067',
    GB_SAP_GB_ID_PKF: '7000/705067/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 926.38,
    GB_NUTZFL_NF_VON_NGF: 853.47,
    GB_TECHNIKFL_TF_VON_NGF: 15.21,
    GB_VERKEHRSFL_VF_VON_NGF: 57.7,
    GB_MIETFL_GESAMT: 926.38,
    GB_MIETFL_1_BSB: 924.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5479160,
    GB_WE_OBJ_ID_FKT: 1989341,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1945.05,
    GB_NUTZFL_NF_VON_NGF: 1617.24,
    GB_TECHNIKFL_TF_VON_NGF: 8.91,
    GB_VERKEHRSFL_VF_VON_NGF: 318.9,
    GB_MIETFL_GESAMT: 1945.05,
    GB_MIETFL_1_BSB: 1826.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991570,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2347.67,
    GB_NUTZFL_NF_VON_NGF: 1685.65,
    GB_TECHNIKFL_TF_VON_NGF: 95.09,
    GB_VERKEHRSFL_VF_VON_NGF: 566.93,
    GB_MIETFL_GESAMT: 2347.67,
    GB_MIETFL_1_BSB: 2228.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991571,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3596.69,
    GB_NUTZFL_NF_VON_NGF: 2701.67,
    GB_TECHNIKFL_TF_VON_NGF: 72.83,
    GB_VERKEHRSFL_VF_VON_NGF: 822.19,
    GB_MIETFL_GESAMT: 3596.69,
    GB_MIETFL_1_BSB: 3521.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991572,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.630964',
    GB_LAENGENGRAD_OESTLICH: '10.097718',
    GB_NETTOGRUNDFL_NGF: 1773.03,
    GB_NUTZFL_NF_VON_NGF: 1664.83,
    GB_TECHNIKFL_TF_VON_NGF: 33.5,
    GB_VERKEHRSFL_VF_VON_NGF: 74.7,
    GB_MIETFL_GESAMT: 1773.03,
    GB_MIETFL_1_BSB: 1773.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991574,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991575,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/7',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 602.47,
    GB_NUTZFL_NF_VON_NGF: 453.94,
    GB_TECHNIKFL_TF_VON_NGF: 16.44,
    GB_VERKEHRSFL_VF_VON_NGF: 132.09,
    GB_MIETFL_GESAMT: 602.47,
    GB_MIETFL_1_BSB: 602.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399672,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705040,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705040',
    GB_SAP_GB_ID_PKF: '7000/705040/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088387,
    GB_WE_OBJ_ID_FKT: 1989314,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1743.12,
    GB_NUTZFL_NF_VON_NGF: 1228.13,
    GB_TECHNIKFL_TF_VON_NGF: 61.04,
    GB_VERKEHRSFL_VF_VON_NGF: 453.95,
    GB_MIETFL_GESAMT: 1743.12,
    GB_MIETFL_1_BSB: 1695.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.08.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991415,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 405.77,
    GB_NUTZFL_NF_VON_NGF: 397.84,
    GB_TECHNIKFL_TF_VON_NGF: 0.0,
    GB_VERKEHRSFL_VF_VON_NGF: 7.93,
    GB_MIETFL_GESAMT: 405.77,
    GB_MIETFL_1_BSB: 405.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991416,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.22,
    GB_NUTZFL_NF_VON_NGF: 77.27,
    GB_TECHNIKFL_TF_VON_NGF: 4.12,
    GB_VERKEHRSFL_VF_VON_NGF: 11.83,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.03.20',
    GB_GB_OBJ_ID_PKT: 1991417,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3048.79,
    GB_NUTZFL_NF_VON_NGF: 1671.85,
    GB_TECHNIKFL_TF_VON_NGF: 91.24,
    GB_VERKEHRSFL_VF_VON_NGF: 1285.7,
    GB_MIETFL_GESAMT: 3048.79,
    GB_MIETFL_1_BSB: 2100.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991418,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991419,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 551.22,
    GB_NUTZFL_NF_VON_NGF: 470.28,
    GB_TECHNIKFL_TF_VON_NGF: 3.12,
    GB_VERKEHRSFL_VF_VON_NGF: 77.82,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 1991420,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 174.72,
    GB_NUTZFL_NF_VON_NGF: 157.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.59,
    GB_MIETFL_GESAMT: 174.72,
    GB_MIETFL_1_BSB: 174.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991421,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.13,
    GB_NUTZFL_NF_VON_NGF: 182.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.0,
    GB_MIETFL_GESAMT: 213.13,
    GB_MIETFL_1_BSB: 213.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991422,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 810.73,
    GB_NUTZFL_NF_VON_NGF: 650.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 159.88,
    GB_MIETFL_GESAMT: 810.73,
    GB_MIETFL_1_BSB: 810.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991423,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.615238',
    GB_LAENGENGRAD_OESTLICH: '10.079595',
    GB_NETTOGRUNDFL_NGF: 2434.68,
    GB_NUTZFL_NF_VON_NGF: 2022.12,
    GB_TECHNIKFL_TF_VON_NGF: 37.86,
    GB_VERKEHRSFL_VF_VON_NGF: 374.7,
    GB_MIETFL_GESAMT: 2434.68,
    GB_MIETFL_1_BSB: 2431.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.07.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399657,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.614055',
    GB_LAENGENGRAD_OESTLICH: '10.077641',
    GB_NETTOGRUNDFL_NGF: 508.35,
    GB_NUTZFL_NF_VON_NGF: 439.92,
    GB_TECHNIKFL_TF_VON_NGF: 3.51,
    GB_VERKEHRSFL_VF_VON_NGF: 64.92,
    GB_MIETFL_GESAMT: 508.35,
    GB_MIETFL_1_BSB: 508.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7035758,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705023,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705023',
    GB_SAP_GB_ID_PKF: '7000/705023/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 165.54,
    GB_NUTZFL_NF_VON_NGF: 153.01,
    GB_TECHNIKFL_TF_VON_NGF: 3.32,
    GB_VERKEHRSFL_VF_VON_NGF: 9.21,
    GB_MIETFL_GESAMT: 165.54,
    GB_MIETFL_1_BSB: 165.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352753,
    GB_WE_OBJ_ID_FKT: 1989297,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1702.6,
    GB_NUTZFL_NF_VON_NGF: 1307.58,
    GB_TECHNIKFL_TF_VON_NGF: 101.15,
    GB_VERKEHRSFL_VF_VON_NGF: 293.87,
    GB_MIETFL_GESAMT: 1702.6,
    GB_MIETFL_1_BSB: 1536.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991512,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.97,
    GB_NUTZFL_NF_VON_NGF: 109.52,
    GB_TECHNIKFL_TF_VON_NGF: 3.87,
    GB_VERKEHRSFL_VF_VON_NGF: 34.58,
    GB_MIETFL_GESAMT: 147.97,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991513,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.8,
    GB_NUTZFL_NF_VON_NGF: 421.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.34,
    GB_MIETFL_GESAMT: 475.8,
    GB_MIETFL_1_BSB: 475.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991515,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.8,
    GB_NUTZFL_NF_VON_NGF: 421.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.34,
    GB_MIETFL_GESAMT: 475.8,
    GB_MIETFL_1_BSB: 475.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991516,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.7,
    GB_NUTZFL_NF_VON_NGF: 420.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.12,
    GB_MIETFL_GESAMT: 475.7,
    GB_MIETFL_1_BSB: 237.82,
    GB_MIETFL_2_DRITTE: 237.88,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991517,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.604797',
    GB_LAENGENGRAD_OESTLICH: '10.092640',
    GB_NETTOGRUNDFL_NGF: 625.09,
    GB_NUTZFL_NF_VON_NGF: 599.31,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 19.09,
    GB_MIETFL_GESAMT: 625.09,
    GB_MIETFL_1_BSB: 625.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.10.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991514,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.8,
    GB_NUTZFL_NF_VON_NGF: 421.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.34,
    GB_MIETFL_GESAMT: 475.8,
    GB_MIETFL_1_BSB: 475.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991518,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705034,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705034',
    GB_SAP_GB_ID_PKF: '7000/705034/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 475.8,
    GB_NUTZFL_NF_VON_NGF: 421.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.34,
    GB_MIETFL_GESAMT: 475.8,
    GB_MIETFL_1_BSB: 475.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991519,
    GB_WE_OBJ_ID_FKT: 1989308,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 407.3,
    GB_NUTZFL_NF_VON_NGF: 360.53,
    GB_TECHNIKFL_TF_VON_NGF: 2.94,
    GB_VERKEHRSFL_VF_VON_NGF: 43.83,
    GB_MIETFL_GESAMT: 407.3,
    GB_MIETFL_1_BSB: 407.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991334,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 836.01,
    GB_NUTZFL_NF_VON_NGF: 710.83,
    GB_TECHNIKFL_TF_VON_NGF: 30.46,
    GB_VERKEHRSFL_VF_VON_NGF: 94.72,
    GB_MIETFL_GESAMT: 836.01,
    GB_MIETFL_1_BSB: 805.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991335,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.02,
    GB_NUTZFL_NF_VON_NGF: 124.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.21,
    GB_MIETFL_GESAMT: 135.02,
    GB_MIETFL_1_BSB: 135.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991336,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.605465',
    GB_LAENGENGRAD_OESTLICH: '10.068211',
    GB_NETTOGRUNDFL_NGF: 628.76,
    GB_NUTZFL_NF_VON_NGF: 588.08,
    GB_TECHNIKFL_TF_VON_NGF: 21.68,
    GB_VERKEHRSFL_VF_VON_NGF: 19.0,
    GB_MIETFL_GESAMT: 628.76,
    GB_MIETFL_1_BSB: 628.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991337,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 80.63,
    GB_NUTZFL_NF_VON_NGF: 76.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 4.36,
    GB_MIETFL_GESAMT: 80.63,
    GB_MIETFL_1_BSB: 80.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991338,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/6',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.605328',
    GB_LAENGENGRAD_OESTLICH: '10.068748',
    GB_NETTOGRUNDFL_NGF: 302.48,
    GB_NUTZFL_NF_VON_NGF: 284.93,
    GB_TECHNIKFL_TF_VON_NGF: 9.22,
    GB_VERKEHRSFL_VF_VON_NGF: 8.33,
    GB_MIETFL_GESAMT: 302.48,
    GB_MIETFL_1_BSB: 302.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991339,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 389.18,
    GB_NUTZFL_NF_VON_NGF: 340.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.52,
    GB_MIETFL_GESAMT: 389.18,
    GB_MIETFL_1_BSB: 389.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991340,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 345.52,
    GB_NUTZFL_NF_VON_NGF: 310.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.4,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.03.21',
    GB_GB_OBJ_ID_PKT: 1991341,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1545.81,
    GB_NUTZFL_NF_VON_NGF: 1118.74,
    GB_TECHNIKFL_TF_VON_NGF: 92.66,
    GB_VERKEHRSFL_VF_VON_NGF: 334.41,
    GB_MIETFL_GESAMT: 1545.81,
    GB_MIETFL_1_BSB: 1445.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991342,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 302.62,
    GB_NUTZFL_NF_VON_NGF: 282.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.29,
    GB_MIETFL_GESAMT: 302.62,
    GB_MIETFL_1_BSB: 302.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991343,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/11',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 273.42,
    GB_NUTZFL_NF_VON_NGF: 208.19,
    GB_TECHNIKFL_TF_VON_NGF: 10.29,
    GB_VERKEHRSFL_VF_VON_NGF: 54.94,
    GB_MIETFL_GESAMT: 273.42,
    GB_MIETFL_1_BSB: 101.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991344,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/12',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 901.14,
    GB_NUTZFL_NF_VON_NGF: 838.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.06,
    GB_MIETFL_GESAMT: 901.14,
    GB_MIETFL_1_BSB: 901.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991345,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1423.06,
    GB_NUTZFL_NF_VON_NGF: 967.77,
    GB_TECHNIKFL_TF_VON_NGF: 18.62,
    GB_VERKEHRSFL_VF_VON_NGF: 436.67,
    GB_MIETFL_GESAMT: 1423.06,
    GB_MIETFL_1_BSB: 1418.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088377,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705013,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705013',
    GB_SAP_GB_ID_PKF: '7000/705013/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7517505,
    GB_WE_OBJ_ID_FKT: 1989287,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1551.73,
    GB_NUTZFL_NF_VON_NGF: 1027.53,
    GB_TECHNIKFL_TF_VON_NGF: 90.29,
    GB_VERKEHRSFL_VF_VON_NGF: 433.91,
    GB_MIETFL_GESAMT: 1551.73,
    GB_MIETFL_1_BSB: 1461.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991546,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1366.81,
    GB_NUTZFL_NF_VON_NGF: 1193.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 173.76,
    GB_MIETFL_GESAMT: 1366.81,
    GB_MIETFL_1_BSB: 1366.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.11.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991547,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/3',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 32.74,
    GB_NUTZFL_NF_VON_NGF: 30.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 2.24,
    GB_MIETFL_GESAMT: 32.74,
    GB_MIETFL_1_BSB: 32.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991548,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 495.77,
    GB_NUTZFL_NF_VON_NGF: 371.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 124.09,
    GB_MIETFL_GESAMT: 495.77,
    GB_MIETFL_1_BSB: 495.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991436,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.616131',
    GB_LAENGENGRAD_OESTLICH: '10.095577',
    GB_NETTOGRUNDFL_NGF: 624.4,
    GB_NUTZFL_NF_VON_NGF: 578.87,
    GB_TECHNIKFL_TF_VON_NGF: 23.25,
    GB_VERKEHRSFL_VF_VON_NGF: 22.28,
    GB_MIETFL_GESAMT: 624.4,
    GB_MIETFL_1_BSB: 624.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '26.04.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991549,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 430.98,
    GB_NUTZFL_NF_VON_NGF: 379.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.91,
    GB_MIETFL_GESAMT: 430.98,
    GB_MIETFL_1_BSB: 430.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991550,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/6',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 158.59,
    GB_NUTZFL_NF_VON_NGF: 112.23,
    GB_TECHNIKFL_TF_VON_NGF: 8.12,
    GB_VERKEHRSFL_VF_VON_NGF: 38.24,
    GB_MIETFL_GESAMT: 158.59,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991551,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 188.43,
    GB_NUTZFL_NF_VON_NGF: 170.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.4,
    GB_MIETFL_GESAMT: 188.43,
    GB_MIETFL_1_BSB: 188.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991552,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/8',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088384,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/9',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088385,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/10',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088386,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 92.53,
    GB_NUTZFL_NF_VON_NGF: 79.97,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 92.53,
    GB_MIETFL_1_BSB: 92.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036059,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 369.66,
    GB_NUTZFL_NF_VON_NGF: 296.19,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 70.46,
    GB_MIETFL_GESAMT: 332.69,
    GB_MIETFL_1_BSB: 332.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.07.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7130253,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705038,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/705038',
    GB_SAP_GB_ID_PKF: '7000/705038/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462190,
    GB_WE_OBJ_ID_FKT: 1989312,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 858.83,
    GB_NUTZFL_NF_VON_NGF: 615.48,
    GB_TECHNIKFL_TF_VON_NGF: 44.96,
    GB_VERKEHRSFL_VF_VON_NGF: 198.39,
    GB_MIETFL_GESAMT: 858.83,
    GB_MIETFL_1_BSB: 813.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991424,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 123.41,
    GB_NUTZFL_NF_VON_NGF: 116.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.93,
    GB_MIETFL_GESAMT: 123.41,
    GB_MIETFL_1_BSB: 123.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991425,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 317.38,
    GB_NUTZFL_NF_VON_NGF: 256.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 60.61,
    GB_MIETFL_GESAMT: 317.38,
    GB_MIETFL_1_BSB: 282.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991426,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 856.53,
    GB_NUTZFL_NF_VON_NGF: 610.83,
    GB_TECHNIKFL_TF_VON_NGF: 51.33,
    GB_VERKEHRSFL_VF_VON_NGF: 194.37,
    GB_MIETFL_GESAMT: 856.53,
    GB_MIETFL_1_BSB: 856.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991427,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.52,
    GB_NUTZFL_NF_VON_NGF: 366.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.44,
    GB_MIETFL_GESAMT: 406.52,
    GB_MIETFL_1_BSB: 406.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991428,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.26,
    GB_NUTZFL_NF_VON_NGF: 365.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 40.53,
    GB_MIETFL_GESAMT: 406.26,
    GB_MIETFL_1_BSB: 406.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991429,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 898.24,
    GB_NUTZFL_NF_VON_NGF: 778.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.04,
    GB_MIETFL_GESAMT: 898.24,
    GB_MIETFL_1_BSB: 898.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991430,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.53,
    GB_NUTZFL_NF_VON_NGF: 86.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.21,
    GB_MIETFL_GESAMT: 104.53,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991431,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.16,
    GB_NUTZFL_NF_VON_NGF: 34.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 34.16,
    GB_MIETFL_1_BSB: 34.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991432,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.51,
    GB_NUTZFL_NF_VON_NGF: 373.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 124.01,
    GB_MIETFL_GESAMT: 497.51,
    GB_MIETFL_1_BSB: 497.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991433,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.55,
    GB_NUTZFL_NF_VON_NGF: 361.97,
    GB_TECHNIKFL_TF_VON_NGF: 4.84,
    GB_VERKEHRSFL_VF_VON_NGF: 86.74,
    GB_MIETFL_GESAMT: 453.55,
    GB_MIETFL_1_BSB: 453.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991434,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 141.79,
    GB_NUTZFL_NF_VON_NGF: 129.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.13,
    GB_MIETFL_GESAMT: 141.79,
    GB_MIETFL_1_BSB: 141.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991435,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.604964',
    GB_LAENGENGRAD_OESTLICH: '10.082710',
    GB_NETTOGRUNDFL_NGF: 624.68,
    GB_NUTZFL_NF_VON_NGF: 577.07,
    GB_TECHNIKFL_TF_VON_NGF: 25.43,
    GB_VERKEHRSFL_VF_VON_NGF: 22.18,
    GB_MIETFL_GESAMT: 624.68,
    GB_MIETFL_1_BSB: 624.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991437,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/15',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.605040',
    GB_LAENGENGRAD_OESTLICH: '10.081722',
    GB_NETTOGRUNDFL_NGF: 304.13,
    GB_NUTZFL_NF_VON_NGF: 290.02,
    GB_TECHNIKFL_TF_VON_NGF: 5.67,
    GB_VERKEHRSFL_VF_VON_NGF: 8.44,
    GB_MIETFL_GESAMT: 304.13,
    GB_MIETFL_1_BSB: 304.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991438,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 495.79,
    GB_NUTZFL_NF_VON_NGF: 371.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 124.09,
    GB_MIETFL_GESAMT: 495.79,
    GB_MIETFL_1_BSB: 495.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991439,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705024,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705024',
    GB_SAP_GB_ID_PKF: '7000/705024/17',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 456.14,
    GB_NUTZFL_NF_VON_NGF: 362.11,
    GB_TECHNIKFL_TF_VON_NGF: 4.81,
    GB_VERKEHRSFL_VF_VON_NGF: 89.22,
    GB_MIETFL_GESAMT: 456.14,
    GB_MIETFL_1_BSB: 456.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991440,
    GB_WE_OBJ_ID_FKT: 1989298,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1008.59,
    GB_NUTZFL_NF_VON_NGF: 794.45,
    GB_TECHNIKFL_TF_VON_NGF: 71.73,
    GB_VERKEHRSFL_VF_VON_NGF: 142.41,
    GB_MIETFL_GESAMT: 1008.59,
    GB_MIETFL_1_BSB: 827.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991595,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 204.23,
    GB_NUTZFL_NF_VON_NGF: 171.43,
    GB_TECHNIKFL_TF_VON_NGF: 4.35,
    GB_VERKEHRSFL_VF_VON_NGF: 28.45,
    GB_MIETFL_GESAMT: 204.23,
    GB_MIETFL_1_BSB: 204.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991596,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 521.03,
    GB_NUTZFL_NF_VON_NGF: 400.13,
    GB_TECHNIKFL_TF_VON_NGF: 6.56,
    GB_VERKEHRSFL_VF_VON_NGF: 114.34,
    GB_MIETFL_GESAMT: 521.03,
    GB_MIETFL_1_BSB: 521.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991597,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 456.31,
    GB_NUTZFL_NF_VON_NGF: 364.98,
    GB_TECHNIKFL_TF_VON_NGF: 4.69,
    GB_VERKEHRSFL_VF_VON_NGF: 86.64,
    GB_MIETFL_GESAMT: 456.31,
    GB_MIETFL_1_BSB: 456.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991598,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 322.87,
    GB_NUTZFL_NF_VON_NGF: 279.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.98,
    GB_MIETFL_GESAMT: 322.87,
    GB_MIETFL_1_BSB: 322.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991599,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 323.24,
    GB_NUTZFL_NF_VON_NGF: 279.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 43.35,
    GB_MIETFL_GESAMT: 323.24,
    GB_MIETFL_1_BSB: 323.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991600,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 322.87,
    GB_NUTZFL_NF_VON_NGF: 279.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.98,
    GB_MIETFL_GESAMT: 322.87,
    GB_MIETFL_1_BSB: 322.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991601,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.615554',
    GB_LAENGENGRAD_OESTLICH: '10.092362',
    GB_NETTOGRUNDFL_NGF: 653.28,
    GB_NUTZFL_NF_VON_NGF: 600.06,
    GB_TECHNIKFL_TF_VON_NGF: 10.62,
    GB_VERKEHRSFL_VF_VON_NGF: 42.6,
    GB_MIETFL_GESAMT: 653.28,
    GB_MIETFL_1_BSB: 653.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '27.10.11',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991602,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705044',
    GB_SAP_GB_ID_PKF: '7000/705044/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7435572,
    GB_WE_OBJ_ID_FKT: 1989318,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 567.96,
    GB_NUTZFL_NF_VON_NGF: 464.64,
    GB_TECHNIKFL_TF_VON_NGF: 52.21,
    GB_VERKEHRSFL_VF_VON_NGF: 51.11,
    GB_MIETFL_GESAMT: 567.96,
    GB_MIETFL_1_BSB: 422.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.09.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991346,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1758.96,
    GB_NUTZFL_NF_VON_NGF: 1325.41,
    GB_TECHNIKFL_TF_VON_NGF: 105.07,
    GB_VERKEHRSFL_VF_VON_NGF: 328.48,
    GB_MIETFL_GESAMT: 1758.96,
    GB_MIETFL_1_BSB: 1645.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.03.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991347,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 980.84,
    GB_NUTZFL_NF_VON_NGF: 890.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 90.23,
    GB_MIETFL_GESAMT: 980.84,
    GB_MIETFL_1_BSB: 980.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.07.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991348,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 240.01,
    GB_NUTZFL_NF_VON_NGF: 220.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.94,
    GB_MIETFL_GESAMT: 240.01,
    GB_MIETFL_1_BSB: 240.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991349,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.600249',
    GB_LAENGENGRAD_OESTLICH: '10.055924',
    GB_NETTOGRUNDFL_NGF: 622.57,
    GB_NUTZFL_NF_VON_NGF: 593.58,
    GB_TECHNIKFL_TF_VON_NGF: 6.67,
    GB_VERKEHRSFL_VF_VON_NGF: 22.32,
    GB_MIETFL_GESAMT: 622.57,
    GB_MIETFL_1_BSB: 622.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.02.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991350,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.24,
    GB_NUTZFL_NF_VON_NGF: 174.32,
    GB_TECHNIKFL_TF_VON_NGF: 4.43,
    GB_VERKEHRSFL_VF_VON_NGF: 28.49,
    GB_MIETFL_GESAMT: 207.24,
    GB_MIETFL_1_BSB: 207.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991351,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 924.1,
    GB_NUTZFL_NF_VON_NGF: 610.83,
    GB_TECHNIKFL_TF_VON_NGF: 15.12,
    GB_VERKEHRSFL_VF_VON_NGF: 298.15,
    GB_MIETFL_GESAMT: 924.1,
    GB_MIETFL_1_BSB: 921.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7032211,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705014,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705014',
    GB_SAP_GB_ID_PKF: '7000/705014/8',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 344.02,
    GB_NUTZFL_NF_VON_NGF: 316.76,
    GB_TECHNIKFL_TF_VON_NGF: 11.8,
    GB_VERKEHRSFL_VF_VON_NGF: 15.46,
    GB_MIETFL_GESAMT: 344.02,
    GB_MIETFL_1_BSB: 344.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088378,
    GB_WE_OBJ_ID_FKT: 1989288,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1949,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2603.98,
    GB_NUTZFL_NF_VON_NGF: 1910.51,
    GB_TECHNIKFL_TF_VON_NGF: 64.83,
    GB_VERKEHRSFL_VF_VON_NGF: 628.64,
    GB_MIETFL_GESAMT: 2603.98,
    GB_MIETFL_1_BSB: 2124.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991352,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 110.91,
    GB_NUTZFL_NF_VON_NGF: 98.24,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.67,
    GB_MIETFL_GESAMT: 110.91,
    GB_MIETFL_1_BSB: 79.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991353,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/3',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 190.62,
    GB_NUTZFL_NF_VON_NGF: 173.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.52,
    GB_MIETFL_GESAMT: 190.62,
    GB_MIETFL_1_BSB: 190.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991354,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 791.86,
    GB_NUTZFL_NF_VON_NGF: 563.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 228.21,
    GB_MIETFL_GESAMT: 791.86,
    GB_MIETFL_1_BSB: 791.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991355,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 19.5,
    GB_NUTZFL_NF_VON_NGF: 19.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 19.5,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5174592,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/6',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.624397',
    GB_LAENGENGRAD_OESTLICH: '10.092804',
    GB_NETTOGRUNDFL_NGF: 302.86,
    GB_NUTZFL_NF_VON_NGF: 285.27,
    GB_TECHNIKFL_TF_VON_NGF: 9.23,
    GB_VERKEHRSFL_VF_VON_NGF: 8.36,
    GB_MIETFL_GESAMT: 302.86,
    GB_MIETFL_1_BSB: 302.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991356,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 339.48,
    GB_NUTZFL_NF_VON_NGF: 279.39,
    GB_TECHNIKFL_TF_VON_NGF: 2.63,
    GB_VERKEHRSFL_VF_VON_NGF: 57.46,
    GB_MIETFL_GESAMT: 339.48,
    GB_MIETFL_1_BSB: 339.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991357,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.624439',
    GB_LAENGENGRAD_OESTLICH: '10.094348',
    GB_NETTOGRUNDFL_NGF: 589.69,
    GB_NUTZFL_NF_VON_NGF: 536.46,
    GB_TECHNIKFL_TF_VON_NGF: 33.47,
    GB_VERKEHRSFL_VF_VON_NGF: 19.76,
    GB_MIETFL_GESAMT: 589.69,
    GB_MIETFL_1_BSB: 589.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991358,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 122.77,
    GB_NUTZFL_NF_VON_NGF: 111.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.89,
    GB_MIETFL_GESAMT: 122.77,
    GB_MIETFL_1_BSB: 122.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '24.08.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991359,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1197.58,
    GB_NUTZFL_NF_VON_NGF: 1033.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 164.07,
    GB_MIETFL_GESAMT: 1197.58,
    GB_MIETFL_1_BSB: 1197.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991360,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705015,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705015',
    GB_SAP_GB_ID_PKF: '7000/705015/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.0,
    GB_NUTZFL_NF_VON_NGF: 17.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991361,
    GB_WE_OBJ_ID_FKT: 1989289,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1772.72,
    GB_NUTZFL_NF_VON_NGF: 1345.78,
    GB_TECHNIKFL_TF_VON_NGF: 16.67,
    GB_VERKEHRSFL_VF_VON_NGF: 410.27,
    GB_MIETFL_GESAMT: 1772.72,
    GB_MIETFL_1_BSB: 1751.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991687,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.613298',
    GB_LAENGENGRAD_OESTLICH: '10.081737',
    GB_NETTOGRUNDFL_NGF: 1730.32,
    GB_NUTZFL_NF_VON_NGF: 1648.14,
    GB_TECHNIKFL_TF_VON_NGF: 24.61,
    GB_VERKEHRSFL_VF_VON_NGF: 57.57,
    GB_MIETFL_GESAMT: 1730.32,
    GB_MIETFL_1_BSB: 1730.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991688,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3127.46,
    GB_NUTZFL_NF_VON_NGF: 2303.88,
    GB_TECHNIKFL_TF_VON_NGF: 71.21,
    GB_VERKEHRSFL_VF_VON_NGF: 752.37,
    GB_MIETFL_GESAMT: 3127.46,
    GB_MIETFL_1_BSB: 3056.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991689,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1719.68,
    GB_NUTZFL_NF_VON_NGF: 1404.49,
    GB_TECHNIKFL_TF_VON_NGF: 16.08,
    GB_VERKEHRSFL_VF_VON_NGF: 299.11,
    GB_MIETFL_GESAMT: 1719.68,
    GB_MIETFL_1_BSB: 1488.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991690,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2364.66,
    GB_NUTZFL_NF_VON_NGF: 1709.84,
    GB_TECHNIKFL_TF_VON_NGF: 186.92,
    GB_VERKEHRSFL_VF_VON_NGF: 467.9,
    GB_MIETFL_GESAMT: 2364.66,
    GB_MIETFL_1_BSB: 2179.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991691,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705054,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705054',
    GB_SAP_GB_ID_PKF: '7000/705054/6',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399681,
    GB_WE_OBJ_ID_FKT: 1989328,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 434.57,
    GB_NUTZFL_NF_VON_NGF: 383.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.78,
    GB_MIETFL_GESAMT: 434.57,
    GB_MIETFL_1_BSB: 393.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991617,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 134.68,
    GB_NUTZFL_NF_VON_NGF: 124.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.98,
    GB_MIETFL_GESAMT: 134.68,
    GB_MIETFL_1_BSB: 134.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991618,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 138.13,
    GB_NUTZFL_NF_VON_NGF: 113.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.88,
    GB_MIETFL_GESAMT: 138.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991619,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 305.09,
    GB_NUTZFL_NF_VON_NGF: 305.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 305.09,
    GB_MIETFL_1_BSB: 305.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991620,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.627434',
    GB_LAENGENGRAD_OESTLICH: '10.111437',
    GB_NETTOGRUNDFL_NGF: 623.06,
    GB_NUTZFL_NF_VON_NGF: 594.1,
    GB_TECHNIKFL_TF_VON_NGF: 6.71,
    GB_VERKEHRSFL_VF_VON_NGF: 22.25,
    GB_MIETFL_GESAMT: 623.06,
    GB_MIETFL_1_BSB: 623.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991621,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991622,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1195.87,
    GB_NUTZFL_NF_VON_NGF: 984.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 211.75,
    GB_MIETFL_GESAMT: 1195.87,
    GB_MIETFL_1_BSB: 1195.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991623,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/8',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 117.37,
    GB_NUTZFL_NF_VON_NGF: 107.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.45,
    GB_MIETFL_GESAMT: 117.37,
    GB_MIETFL_1_BSB: 117.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991624,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1665.4,
    GB_NUTZFL_NF_VON_NGF: 1160.31,
    GB_TECHNIKFL_TF_VON_NGF: 196.67,
    GB_VERKEHRSFL_VF_VON_NGF: 308.42,
    GB_MIETFL_GESAMT: 1665.4,
    GB_MIETFL_1_BSB: 1430.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991625,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705047',
    GB_SAP_GB_ID_PKF: '7000/705047/10',
    GB_SAP_GB_BEZEICHNUNG: 'Doppelgarage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Doppelgarage',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.12.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189771,
    GB_WE_OBJ_ID_FKT: 1989321,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1639.87,
    GB_NUTZFL_NF_VON_NGF: 1320.3,
    GB_TECHNIKFL_TF_VON_NGF: 14.92,
    GB_VERKEHRSFL_VF_VON_NGF: 304.65,
    GB_MIETFL_GESAMT: 1639.87,
    GB_MIETFL_1_BSB: 1520.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991824,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3139.58,
    GB_NUTZFL_NF_VON_NGF: 2339.1,
    GB_TECHNIKFL_TF_VON_NGF: 72.43,
    GB_VERKEHRSFL_VF_VON_NGF: 728.05,
    GB_MIETFL_GESAMT: 3139.58,
    GB_MIETFL_1_BSB: 2983.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991825,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.601598',
    GB_LAENGENGRAD_OESTLICH: '10.093465',
    GB_NETTOGRUNDFL_NGF: 657.18,
    GB_NUTZFL_NF_VON_NGF: 611.53,
    GB_TECHNIKFL_TF_VON_NGF: 17.1,
    GB_VERKEHRSFL_VF_VON_NGF: 28.55,
    GB_MIETFL_GESAMT: 657.18,
    GB_MIETFL_1_BSB: 657.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991826,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.602417',
    GB_LAENGENGRAD_OESTLICH: '10.093766',
    GB_NETTOGRUNDFL_NGF: 1499.32,
    GB_NUTZFL_NF_VON_NGF: 1422.51,
    GB_TECHNIKFL_TF_VON_NGF: 27.9,
    GB_VERKEHRSFL_VF_VON_NGF: 48.91,
    GB_MIETFL_GESAMT: 1499.32,
    GB_MIETFL_1_BSB: 1499.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991827,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2329.85,
    GB_NUTZFL_NF_VON_NGF: 1769.01,
    GB_TECHNIKFL_TF_VON_NGF: 120.77,
    GB_VERKEHRSFL_VF_VON_NGF: 440.07,
    GB_MIETFL_GESAMT: 2329.85,
    GB_MIETFL_1_BSB: 2117.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991828,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2839.5,
    GB_NUTZFL_NF_VON_NGF: 2168.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 670.86,
    GB_MIETFL_GESAMT: 2839.5,
    GB_MIETFL_1_BSB: 2839.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991829,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705072,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705072',
    GB_SAP_GB_ID_PKF: '7000/705072/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7539889,
    GB_WE_OBJ_ID_FKT: 1989346,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 692.99,
    GB_NUTZFL_NF_VON_NGF: 613.57,
    GB_TECHNIKFL_TF_VON_NGF: 27.99,
    GB_VERKEHRSFL_VF_VON_NGF: 51.43,
    GB_MIETFL_GESAMT: 692.99,
    GB_MIETFL_1_BSB: 575.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991520,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 947.24,
    GB_NUTZFL_NF_VON_NGF: 691.21,
    GB_TECHNIKFL_TF_VON_NGF: 124.12,
    GB_VERKEHRSFL_VF_VON_NGF: 131.91,
    GB_MIETFL_GESAMT: 947.24,
    GB_MIETFL_1_BSB: 825.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.01.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991521,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 18.08,
    GB_NUTZFL_NF_VON_NGF: 18.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.15',
    GB_GB_OBJ_ID_PKT: 1991522,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1540.41,
    GB_NUTZFL_NF_VON_NGF: 1146.46,
    GB_TECHNIKFL_TF_VON_NGF: 88.45,
    GB_VERKEHRSFL_VF_VON_NGF: 305.5,
    GB_MIETFL_GESAMT: 1540.41,
    GB_MIETFL_1_BSB: 1432.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991523,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.620055',
    GB_LAENGENGRAD_OESTLICH: '10.121279',
    GB_NETTOGRUNDFL_NGF: 628.45,
    GB_NUTZFL_NF_VON_NGF: 574.97,
    GB_TECHNIKFL_TF_VON_NGF: 34.44,
    GB_VERKEHRSFL_VF_VON_NGF: 19.04,
    GB_MIETFL_GESAMT: 628.45,
    GB_MIETFL_1_BSB: 628.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.08.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991524,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 777.23,
    GB_NUTZFL_NF_VON_NGF: 587.62,
    GB_TECHNIKFL_TF_VON_NGF: 6.82,
    GB_VERKEHRSFL_VF_VON_NGF: 182.79,
    GB_MIETFL_GESAMT: 777.23,
    GB_MIETFL_1_BSB: 777.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991525,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 501.56,
    GB_NUTZFL_NF_VON_NGF: 351.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 149.99,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.03.23',
    GB_GB_OBJ_ID_PKT: 1991526,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 539.93,
    GB_NUTZFL_NF_VON_NGF: 410.85,
    GB_TECHNIKFL_TF_VON_NGF: 16.84,
    GB_VERKEHRSFL_VF_VON_NGF: 112.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.03.23',
    GB_GB_OBJ_ID_PKT: 1991527,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.619116',
    GB_LAENGENGRAD_OESTLICH: '10.121769',
    GB_NETTOGRUNDFL_NGF: 338.43,
    GB_NUTZFL_NF_VON_NGF: 315.35,
    GB_TECHNIKFL_TF_VON_NGF: 16.54,
    GB_VERKEHRSFL_VF_VON_NGF: 6.54,
    GB_MIETFL_GESAMT: 338.43,
    GB_MIETFL_1_BSB: 338.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.01.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991528,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 458.3,
    GB_NUTZFL_NF_VON_NGF: 428.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.10.20',
    GB_GB_OBJ_ID_PKT: 1991529,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 339.66,
    GB_NUTZFL_NF_VON_NGF: 304.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.15',
    GB_GB_OBJ_ID_PKT: 1991530,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/12',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.18,
    GB_NUTZFL_NF_VON_NGF: 366.28,
    GB_TECHNIKFL_TF_VON_NGF: 4.67,
    GB_VERKEHRSFL_VF_VON_NGF: 118.23,
    GB_MIETFL_GESAMT: 489.18,
    GB_MIETFL_1_BSB: 489.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399666,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1527.02,
    GB_NUTZFL_NF_VON_NGF: 1126.32,
    GB_TECHNIKFL_TF_VON_NGF: 65.24,
    GB_VERKEHRSFL_VF_VON_NGF: 335.46,
    GB_MIETFL_GESAMT: 1527.02,
    GB_MIETFL_1_BSB: 1462.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '27.11.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7067422,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705035,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705035',
    GB_SAP_GB_ID_PKF: '7000/705035/14',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088383,
    GB_WE_OBJ_ID_FKT: 1989309,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705100,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705100',
    GB_SAP_GB_ID_PKF: '7000/705100/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.608198',
    GB_LAENGENGRAD_OESTLICH: '10.119783',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.21',
    GB_GB_OBJ_ID_PKT: 1992026,
    GB_WE_OBJ_ID_FKT: 1989374,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 5423275,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13875.7,
    GB_NUTZFL_NF_VON_NGF: 9494.03,
    GB_TECHNIKFL_TF_VON_NGF: 544.43,
    GB_VERKEHRSFL_VF_VON_NGF: 3837.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.11.18',
    GB_GB_OBJ_ID_PKT: 1991496,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.95,
    GB_NUTZFL_NF_VON_NGF: 92.64,
    GB_TECHNIKFL_TF_VON_NGF: 19.5,
    GB_VERKEHRSFL_VF_VON_NGF: 28.81,
    GB_MIETFL_GESAMT: 140.95,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '26.01.16',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991497,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.61,
    GB_NUTZFL_NF_VON_NGF: 392.5,
    GB_TECHNIKFL_TF_VON_NGF: 13.47,
    GB_VERKEHRSFL_VF_VON_NGF: 91.64,
    GB_MIETFL_GESAMT: 497.61,
    GB_MIETFL_1_BSB: 497.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991498,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1991499,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.598381',
    GB_LAENGENGRAD_OESTLICH: '10.114061',
    GB_NETTOGRUNDFL_NGF: 629.01,
    GB_NUTZFL_NF_VON_NGF: 600.11,
    GB_TECHNIKFL_TF_VON_NGF: 6.76,
    GB_VERKEHRSFL_VF_VON_NGF: 22.14,
    GB_MIETFL_GESAMT: 629.01,
    GB_MIETFL_1_BSB: 629.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991500,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 518.85,
    GB_NUTZFL_NF_VON_NGF: 482.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.62,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.03.15',
    GB_GB_OBJ_ID_PKT: 1991501,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 231.57,
    GB_NUTZFL_NF_VON_NGF: 181.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.09,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991502,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 1991503,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 28.41,
    GB_NUTZFL_NF_VON_NGF: 28.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.21',
    GB_GB_OBJ_ID_PKT: 1991504,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 11.43,
    GB_NUTZFL_NF_VON_NGF: 11.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 11.43,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991505,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.19,
    GB_NUTZFL_NF_VON_NGF: 16.19,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.19,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991506,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5323086,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1772.32,
    GB_NUTZFL_NF_VON_NGF: 1313.09,
    GB_TECHNIKFL_TF_VON_NGF: 116.68,
    GB_VERKEHRSFL_VF_VON_NGF: 342.55,
    GB_MIETFL_GESAMT: 1772.32,
    GB_MIETFL_1_BSB: 1769.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399664,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1361.49,
    GB_NUTZFL_NF_VON_NGF: 1041.12,
    GB_TECHNIKFL_TF_VON_NGF: 12.97,
    GB_VERKEHRSFL_VF_VON_NGF: 307.4,
    GB_MIETFL_GESAMT: 1361.49,
    GB_MIETFL_1_BSB: 1359.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6901218,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 5323087,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 305.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.12.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7021211,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705032,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/705032',
    GB_SAP_GB_ID_PKF: '7000/705032/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.12.18',
    GB_GUELTIG_BIS: '30.09.20',
    GB_GB_OBJ_ID_PKT: 7021212,
    GB_WE_OBJ_ID_FKT: 1989306,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1210.2,
    GB_NUTZFL_NF_VON_NGF: 881.06,
    GB_TECHNIKFL_TF_VON_NGF: 89.63,
    GB_VERKEHRSFL_VF_VON_NGF: 239.51,
    GB_MIETFL_GESAMT: 1210.2,
    GB_MIETFL_1_BSB: 1120.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991940,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 785.1,
    GB_NUTZFL_NF_VON_NGF: 766.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.58,
    GB_MIETFL_GESAMT: 785.1,
    GB_MIETFL_1_BSB: 785.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991941,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 156.43,
    GB_NUTZFL_NF_VON_NGF: 151.84,
    GB_TECHNIKFL_TF_VON_NGF: 4.59,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 156.43,
    GB_MIETFL_1_BSB: 156.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991942,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.56,
    GB_NUTZFL_NF_VON_NGF: 131.16,
    GB_TECHNIKFL_TF_VON_NGF: 6.48,
    GB_VERKEHRSFL_VF_VON_NGF: 29.92,
    GB_MIETFL_GESAMT: 167.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991943,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1454.74,
    GB_NUTZFL_NF_VON_NGF: 1154.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 299.97,
    GB_MIETFL_GESAMT: 1454.74,
    GB_MIETFL_1_BSB: 1454.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991944,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1126.31,
    GB_NUTZFL_NF_VON_NGF: 875.87,
    GB_TECHNIKFL_TF_VON_NGF: 17.73,
    GB_VERKEHRSFL_VF_VON_NGF: 232.71,
    GB_MIETFL_GESAMT: 1126.31,
    GB_MIETFL_1_BSB: 1102.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991945,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 405.5,
    GB_NUTZFL_NF_VON_NGF: 338.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.38,
    GB_MIETFL_GESAMT: 405.5,
    GB_MIETFL_1_BSB: 405.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991946,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.44,
    GB_NUTZFL_NF_VON_NGF: 128.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.51,
    GB_MIETFL_GESAMT: 139.44,
    GB_MIETFL_1_BSB: 139.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991947,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.69,
    GB_NUTZFL_NF_VON_NGF: 177.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 29.15,
    GB_MIETFL_GESAMT: 206.69,
    GB_MIETFL_1_BSB: 206.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991948,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 855.02,
    GB_NUTZFL_NF_VON_NGF: 596.43,
    GB_TECHNIKFL_TF_VON_NGF: 39.57,
    GB_VERKEHRSFL_VF_VON_NGF: 219.02,
    GB_MIETFL_GESAMT: 855.02,
    GB_MIETFL_1_BSB: 846.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991949,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.34,
    GB_NUTZFL_NF_VON_NGF: 14.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.34,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991950,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.613635',
    GB_LAENGENGRAD_OESTLICH: '10.107840',
    GB_NETTOGRUNDFL_NGF: 618.19,
    GB_NUTZFL_NF_VON_NGF: 570.81,
    GB_TECHNIKFL_TF_VON_NGF: 34.1,
    GB_VERKEHRSFL_VF_VON_NGF: 13.28,
    GB_MIETFL_GESAMT: 618.19,
    GB_MIETFL_1_BSB: 618.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.10.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991951,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705084,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705084',
    GB_SAP_GB_ID_PKF: '7000/705084/13',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '05.12.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399696,
    GB_WE_OBJ_ID_FKT: 1989358,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705089,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705089',
    GB_SAP_GB_ID_PKF: '7000/705089/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2892.87,
    GB_NUTZFL_NF_VON_NGF: 2044.66,
    GB_TECHNIKFL_TF_VON_NGF: 28.63,
    GB_VERKEHRSFL_VF_VON_NGF: 819.58,
    GB_MIETFL_GESAMT: 2892.87,
    GB_MIETFL_1_BSB: 2775.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991973,
    GB_WE_OBJ_ID_FKT: 1989363,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705089,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705089',
    GB_SAP_GB_ID_PKF: '7000/705089/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.596965',
    GB_LAENGENGRAD_OESTLICH: '10.100566',
    GB_NETTOGRUNDFL_NGF: 827.69,
    GB_NUTZFL_NF_VON_NGF: 698.47,
    GB_TECHNIKFL_TF_VON_NGF: 15.68,
    GB_VERKEHRSFL_VF_VON_NGF: 113.54,
    GB_MIETFL_GESAMT: 827.69,
    GB_MIETFL_1_BSB: 827.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991974,
    GB_WE_OBJ_ID_FKT: 1989363,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705089,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705089',
    GB_SAP_GB_ID_PKF: '7000/705089/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552759,
    GB_WE_OBJ_ID_FKT: 1989363,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093623,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3826.27,
    GB_NUTZFL_NF_VON_NGF: 2797.62,
    GB_TECHNIKFL_TF_VON_NGF: 91.45,
    GB_VERKEHRSFL_VF_VON_NGF: 937.2,
    GB_MIETFL_GESAMT: 3826.27,
    GB_MIETFL_1_BSB: 3726.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991531,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/3',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1981,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 33.0,
    GB_NUTZFL_NF_VON_NGF: 33.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 33.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991533,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.613474',
    GB_LAENGENGRAD_OESTLICH: '10.119357',
    GB_NETTOGRUNDFL_NGF: 653.53,
    GB_NUTZFL_NF_VON_NGF: 607.96,
    GB_TECHNIKFL_TF_VON_NGF: 16.56,
    GB_VERKEHRSFL_VF_VON_NGF: 29.01,
    GB_MIETFL_GESAMT: 653.53,
    GB_MIETFL_1_BSB: 653.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991532,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 443.93,
    GB_NUTZFL_NF_VON_NGF: 347.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 96.52,
    GB_MIETFL_GESAMT: 443.93,
    GB_MIETFL_1_BSB: 443.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991534,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6875.35,
    GB_NUTZFL_NF_VON_NGF: 4726.23,
    GB_TECHNIKFL_TF_VON_NGF: 126.33,
    GB_VERKEHRSFL_VF_VON_NGF: 2022.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '18.11.22',
    GB_GB_OBJ_ID_PKT: 1991535,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991536,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4511.75,
    GB_NUTZFL_NF_VON_NGF: 3389.02,
    GB_TECHNIKFL_TF_VON_NGF: 97.79,
    GB_VERKEHRSFL_VF_VON_NGF: 1024.94,
    GB_MIETFL_GESAMT: 4511.75,
    GB_MIETFL_1_BSB: 4491.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399668,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705036,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705036',
    GB_SAP_GB_ID_PKF: '7000/705036/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 255.78,
    GB_NUTZFL_NF_VON_NGF: 201.66,
    GB_TECHNIKFL_TF_VON_NGF: 5.86,
    GB_VERKEHRSFL_VF_VON_NGF: 48.26,
    GB_MIETFL_GESAMT: 255.78,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7379453,
    GB_WE_OBJ_ID_FKT: 1989310,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705048',
    GB_SAP_GB_ID_PKF: '7000/705048/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2604.28,
    GB_NUTZFL_NF_VON_NGF: 1929.33,
    GB_TECHNIKFL_TF_VON_NGF: 9.61,
    GB_VERKEHRSFL_VF_VON_NGF: 665.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.09.16',
    GB_GB_OBJ_ID_PKT: 1991626,
    GB_WE_OBJ_ID_FKT: 1989322,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705048',
    GB_SAP_GB_ID_PKF: '7000/705048/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 894.09,
    GB_NUTZFL_NF_VON_NGF: 780.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 113.23,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.09.16',
    GB_GB_OBJ_ID_PKT: 1991627,
    GB_WE_OBJ_ID_FKT: 1989322,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705048,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705048',
    GB_SAP_GB_ID_PKF: '7000/705048/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.75,
    GB_NUTZFL_NF_VON_NGF: 177.92,
    GB_TECHNIKFL_TF_VON_NGF: 3.65,
    GB_VERKEHRSFL_VF_VON_NGF: 32.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.18',
    GB_GB_OBJ_ID_PKT: 1991628,
    GB_WE_OBJ_ID_FKT: 1989322,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705048,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705048',
    GB_SAP_GB_ID_PKF: '7000/705048/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.64,
    GB_NUTZFL_NF_VON_NGF: 178.81,
    GB_TECHNIKFL_TF_VON_NGF: 3.65,
    GB_VERKEHRSFL_VF_VON_NGF: 32.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.18',
    GB_GB_OBJ_ID_PKT: 1991629,
    GB_WE_OBJ_ID_FKT: 1989322,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705048,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705048',
    GB_SAP_GB_ID_PKF: '7000/705048/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 204.91,
    GB_NUTZFL_NF_VON_NGF: 172.67,
    GB_TECHNIKFL_TF_VON_NGF: 4.61,
    GB_VERKEHRSFL_VF_VON_NGF: 27.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.18',
    GB_GB_OBJ_ID_PKT: 1991630,
    GB_WE_OBJ_ID_FKT: 1989322,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 878.01,
    GB_NUTZFL_NF_VON_NGF: 631.81,
    GB_TECHNIKFL_TF_VON_NGF: 96.48,
    GB_VERKEHRSFL_VF_VON_NGF: 149.72,
    GB_MIETFL_GESAMT: 878.01,
    GB_MIETFL_1_BSB: 664.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991553,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 492.6,
    GB_NUTZFL_NF_VON_NGF: 390.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.23,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.07.15',
    GB_GB_OBJ_ID_PKT: 1991554,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1996,
    GB_BREITENGRAD_NOERDLICH: '53.612381',
    GB_LAENGENGRAD_OESTLICH: '10.108943',
    GB_NETTOGRUNDFL_NGF: 536.13,
    GB_NUTZFL_NF_VON_NGF: 512.65,
    GB_TECHNIKFL_TF_VON_NGF: 14.54,
    GB_VERKEHRSFL_VF_VON_NGF: 8.94,
    GB_MIETFL_GESAMT: 536.13,
    GB_MIETFL_1_BSB: 536.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991555,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.612774',
    GB_LAENGENGRAD_OESTLICH: '10.108358',
    GB_NETTOGRUNDFL_NGF: 627.4,
    GB_NUTZFL_NF_VON_NGF: 584.96,
    GB_TECHNIKFL_TF_VON_NGF: 21.8,
    GB_VERKEHRSFL_VF_VON_NGF: 20.64,
    GB_MIETFL_GESAMT: 627.4,
    GB_MIETFL_1_BSB: 627.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991556,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 127.83,
    GB_NUTZFL_NF_VON_NGF: 115.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991557,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991558,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 9.49,
    GB_NUTZFL_NF_VON_NGF: 6.17,
    GB_TECHNIKFL_TF_VON_NGF: 3.32,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991559,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.35,
    GB_NUTZFL_NF_VON_NGF: 34.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.07.15',
    GB_GB_OBJ_ID_PKT: 1991560,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 318.67,
    GB_NUTZFL_NF_VON_NGF: 296.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991561,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 219.0,
    GB_NUTZFL_NF_VON_NGF: 209.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.24,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991562,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.79,
    GB_NUTZFL_NF_VON_NGF: 26.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 1991563,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 447.76,
    GB_NUTZFL_NF_VON_NGF: 389.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.07.15',
    GB_GB_OBJ_ID_PKT: 1991564,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 424.53,
    GB_NUTZFL_NF_VON_NGF: 396.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.06,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991565,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 466.28,
    GB_NUTZFL_NF_VON_NGF: 377.19,
    GB_TECHNIKFL_TF_VON_NGF: 8.18,
    GB_VERKEHRSFL_VF_VON_NGF: 80.91,
    GB_MIETFL_GESAMT: 466.28,
    GB_MIETFL_1_BSB: 466.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991566,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 346.92,
    GB_NUTZFL_NF_VON_NGF: 300.08,
    GB_TECHNIKFL_TF_VON_NGF: 10.9,
    GB_VERKEHRSFL_VF_VON_NGF: 35.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991567,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 939.38,
    GB_NUTZFL_NF_VON_NGF: 697.31,
    GB_TECHNIKFL_TF_VON_NGF: 5.75,
    GB_VERKEHRSFL_VF_VON_NGF: 236.32,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.07.15',
    GB_GB_OBJ_ID_PKT: 1991568,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.56,
    GB_NUTZFL_NF_VON_NGF: 384.5,
    GB_TECHNIKFL_TF_VON_NGF: 16.26,
    GB_VERKEHRSFL_VF_VON_NGF: 52.8,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.17',
    GB_GB_OBJ_ID_PKT: 1991569,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 354.38,
    GB_NUTZFL_NF_VON_NGF: 306.78,
    GB_TECHNIKFL_TF_VON_NGF: 3.12,
    GB_VERKEHRSFL_VF_VON_NGF: 44.48,
    GB_MIETFL_GESAMT: 354.38,
    GB_MIETFL_1_BSB: 354.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.06.13',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5366752,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5321.19,
    GB_NUTZFL_NF_VON_NGF: 4094.41,
    GB_TECHNIKFL_TF_VON_NGF: 29.89,
    GB_VERKEHRSFL_VF_VON_NGF: 1196.89,
    GB_MIETFL_GESAMT: 5321.19,
    GB_MIETFL_1_BSB: 5146.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399670,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705039,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705039',
    GB_SAP_GB_ID_PKF: '7000/705039/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552752,
    GB_WE_OBJ_ID_FKT: 1989313,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4570.16,
    GB_NUTZFL_NF_VON_NGF: 3234.62,
    GB_TECHNIKFL_TF_VON_NGF: 254.37,
    GB_VERKEHRSFL_VF_VON_NGF: 1081.17,
    GB_MIETFL_GESAMT: 4570.16,
    GB_MIETFL_1_BSB: 4198.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991952,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 194.69,
    GB_NUTZFL_NF_VON_NGF: 152.13,
    GB_TECHNIKFL_TF_VON_NGF: 5.5,
    GB_VERKEHRSFL_VF_VON_NGF: 37.06,
    GB_MIETFL_GESAMT: 194.69,
    GB_MIETFL_1_BSB: 189.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991953,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/3',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.13,
    GB_NUTZFL_NF_VON_NGF: 16.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.13,
    GB_MIETFL_1_BSB: 16.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991954,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991955,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 235.41,
    GB_NUTZFL_NF_VON_NGF: 191.01,
    GB_TECHNIKFL_TF_VON_NGF: 6.25,
    GB_VERKEHRSFL_VF_VON_NGF: 38.15,
    GB_MIETFL_GESAMT: 235.41,
    GB_MIETFL_1_BSB: 235.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991956,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/6',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 558.79,
    GB_NUTZFL_NF_VON_NGF: 522.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.23,
    GB_MIETFL_GESAMT: 558.79,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 558.79,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991957,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 190.88,
    GB_NUTZFL_NF_VON_NGF: 148.03,
    GB_TECHNIKFL_TF_VON_NGF: 12.05,
    GB_VERKEHRSFL_VF_VON_NGF: 30.8,
    GB_MIETFL_GESAMT: 190.88,
    GB_MIETFL_1_BSB: 190.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7381736,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705085,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705085',
    GB_SAP_GB_ID_PKF: '7000/705085/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462194,
    GB_WE_OBJ_ID_FKT: 1989359,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.574745',
    GB_LAENGENGRAD_OESTLICH: '10.142921',
    GB_NETTOGRUNDFL_NGF: 4074.2,
    GB_NUTZFL_NF_VON_NGF: 2698.04,
    GB_TECHNIKFL_TF_VON_NGF: 203.05,
    GB_VERKEHRSFL_VF_VON_NGF: 1173.11,
    GB_MIETFL_GESAMT: 4074.2,
    GB_MIETFL_1_BSB: 3836.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991390,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 30.16,
    GB_NUTZFL_NF_VON_NGF: 30.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 30.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991391,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 173.57,
    GB_NUTZFL_NF_VON_NGF: 130.06,
    GB_TECHNIKFL_TF_VON_NGF: 6.9,
    GB_VERKEHRSFL_VF_VON_NGF: 36.61,
    GB_MIETFL_GESAMT: 173.57,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991392,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.6,
    GB_NUTZFL_NF_VON_NGF: 175.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.85,
    GB_MIETFL_GESAMT: 206.6,
    GB_MIETFL_1_BSB: 206.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991393,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 479.22,
    GB_NUTZFL_NF_VON_NGF: 388.98,
    GB_TECHNIKFL_TF_VON_NGF: 9.4,
    GB_VERKEHRSFL_VF_VON_NGF: 80.84,
    GB_MIETFL_GESAMT: 479.22,
    GB_MIETFL_1_BSB: 476.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991394,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 208.41,
    GB_NUTZFL_NF_VON_NGF: 183.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.85,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.03.23',
    GB_GB_OBJ_ID_PKT: 1991395,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.09,
    GB_NUTZFL_NF_VON_NGF: 14.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.09,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991396,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/8',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 573.87,
    GB_NUTZFL_NF_VON_NGF: 440.64,
    GB_TECHNIKFL_TF_VON_NGF: 2.47,
    GB_VERKEHRSFL_VF_VON_NGF: 130.76,
    GB_MIETFL_GESAMT: 573.87,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 573.87,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991397,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 905.42,
    GB_NUTZFL_NF_VON_NGF: 659.02,
    GB_TECHNIKFL_TF_VON_NGF: 18.86,
    GB_VERKEHRSFL_VF_VON_NGF: 227.54,
    GB_MIETFL_GESAMT: 905.42,
    GB_MIETFL_1_BSB: 900.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.11.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088379,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705019,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705019',
    GB_SAP_GB_ID_PKF: '7000/705019/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 178.42,
    GB_NUTZFL_NF_VON_NGF: 156.95,
    GB_TECHNIKFL_TF_VON_NGF: 2.64,
    GB_VERKEHRSFL_VF_VON_NGF: 18.83,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '10.10.22',
    GB_GB_OBJ_ID_PKT: 7036058,
    GB_WE_OBJ_ID_FKT: 1989293,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3653.07,
    GB_NUTZFL_NF_VON_NGF: 2968.93,
    GB_TECHNIKFL_TF_VON_NGF: 83.36,
    GB_VERKEHRSFL_VF_VON_NGF: 600.78,
    GB_MIETFL_GESAMT: 3653.07,
    GB_MIETFL_1_BSB: 3294.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991475,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.587497',
    GB_LAENGENGRAD_OESTLICH: '10.142329',
    GB_NETTOGRUNDFL_NGF: 619.28,
    GB_NUTZFL_NF_VON_NGF: 587.43,
    GB_TECHNIKFL_TF_VON_NGF: 13.13,
    GB_VERKEHRSFL_VF_VON_NGF: 18.72,
    GB_MIETFL_GESAMT: 619.28,
    GB_MIETFL_1_BSB: 619.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '24.01.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991476,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1390.02,
    GB_NUTZFL_NF_VON_NGF: 1168.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 221.19,
    GB_MIETFL_GESAMT: 1390.02,
    GB_MIETFL_1_BSB: 1390.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991477,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1991478,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 240.25,
    GB_NUTZFL_NF_VON_NGF: 205.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.74,
    GB_MIETFL_GESAMT: 240.25,
    GB_MIETFL_1_BSB: 240.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991479,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1991480,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 168.48,
    GB_NUTZFL_NF_VON_NGF: 123.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.23,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 1991481,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 240.53,
    GB_NUTZFL_NF_VON_NGF: 195.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.42,
    GB_MIETFL_GESAMT: 240.53,
    GB_MIETFL_1_BSB: 240.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991482,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399660,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705029,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705029',
    GB_SAP_GB_ID_PKF: '7000/705029/10',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399661,
    GB_WE_OBJ_ID_FKT: 1989303,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: 1929,
    GB_BREITENGRAD_NOERDLICH: '53.572438',
    GB_LAENGENGRAD_OESTLICH: '10.131996',
    GB_NETTOGRUNDFL_NGF: 1264.75,
    GB_NUTZFL_NF_VON_NGF: 971.77,
    GB_TECHNIKFL_TF_VON_NGF: 20.43,
    GB_VERKEHRSFL_VF_VON_NGF: 272.55,
    GB_MIETFL_GESAMT: 1264.75,
    GB_MIETFL_1_BSB: 1202.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991848,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1929,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 138.71,
    GB_NUTZFL_NF_VON_NGF: 119.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.12,
    GB_MIETFL_GESAMT: 138.71,
    GB_MIETFL_1_BSB: 138.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991849,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 357.35,
    GB_NUTZFL_NF_VON_NGF: 260.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 97.14,
    GB_MIETFL_GESAMT: 357.35,
    GB_MIETFL_1_BSB: 357.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991850,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 603.2,
    GB_NUTZFL_NF_VON_NGF: 541.04,
    GB_TECHNIKFL_TF_VON_NGF: 8.07,
    GB_VERKEHRSFL_VF_VON_NGF: 54.09,
    GB_MIETFL_GESAMT: 603.2,
    GB_MIETFL_1_BSB: 603.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991851,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.571456',
    GB_LAENGENGRAD_OESTLICH: '10.132899',
    GB_NETTOGRUNDFL_NGF: 625.51,
    GB_NUTZFL_NF_VON_NGF: 596.8,
    GB_TECHNIKFL_TF_VON_NGF: 6.66,
    GB_VERKEHRSFL_VF_VON_NGF: 22.05,
    GB_MIETFL_GESAMT: 625.51,
    GB_MIETFL_1_BSB: 625.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991852,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 29.26,
    GB_NUTZFL_NF_VON_NGF: 29.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 29.26,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991853,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1834.99,
    GB_NUTZFL_NF_VON_NGF: 1039.76,
    GB_TECHNIKFL_TF_VON_NGF: 71.96,
    GB_VERKEHRSFL_VF_VON_NGF: 723.27,
    GB_MIETFL_GESAMT: 1834.99,
    GB_MIETFL_1_BSB: 1738.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991854,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 907.71,
    GB_NUTZFL_NF_VON_NGF: 766.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 140.74,
    GB_MIETFL_GESAMT: 907.71,
    GB_MIETFL_1_BSB: 907.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991855,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 117.42,
    GB_NUTZFL_NF_VON_NGF: 91.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.89,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.15',
    GB_GB_OBJ_ID_PKT: 1991856,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 821.77,
    GB_NUTZFL_NF_VON_NGF: 586.04,
    GB_TECHNIKFL_TF_VON_NGF: 7.39,
    GB_VERKEHRSFL_VF_VON_NGF: 228.34,
    GB_MIETFL_GESAMT: 821.77,
    GB_MIETFL_1_BSB: 790.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '27.11.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399692,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399693,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705074,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705074',
    GB_SAP_GB_ID_PKF: '7000/705074/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7377312,
    GB_WE_OBJ_ID_FKT: 1989348,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3223.41,
    GB_NUTZFL_NF_VON_NGF: 2492.42,
    GB_TECHNIKFL_TF_VON_NGF: 75.02,
    GB_VERKEHRSFL_VF_VON_NGF: 655.97,
    GB_MIETFL_GESAMT: 3223.41,
    GB_MIETFL_1_BSB: 3215.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991830,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 364.19,
    GB_NUTZFL_NF_VON_NGF: 308.17,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 56.02,
    GB_MIETFL_GESAMT: 364.19,
    GB_MIETFL_1_BSB: 364.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991831,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1588.5,
    GB_NUTZFL_NF_VON_NGF: 1254.18,
    GB_TECHNIKFL_TF_VON_NGF: 7.36,
    GB_VERKEHRSFL_VF_VON_NGF: 326.96,
    GB_MIETFL_GESAMT: 1588.5,
    GB_MIETFL_1_BSB: 1588.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991832,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1592.97,
    GB_NUTZFL_NF_VON_NGF: 1268.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 324.38,
    GB_MIETFL_GESAMT: 1592.97,
    GB_MIETFL_1_BSB: 1592.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991833,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.578316',
    GB_LAENGENGRAD_OESTLICH: '10.126132',
    GB_NETTOGRUNDFL_NGF: 1624.89,
    GB_NUTZFL_NF_VON_NGF: 1537.28,
    GB_TECHNIKFL_TF_VON_NGF: 31.8,
    GB_VERKEHRSFL_VF_VON_NGF: 55.81,
    GB_MIETFL_GESAMT: 1624.89,
    GB_MIETFL_1_BSB: 1624.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991834,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 755.51,
    GB_NUTZFL_NF_VON_NGF: 652.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.17,
    GB_MIETFL_GESAMT: 755.51,
    GB_MIETFL_1_BSB: 755.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991835,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4198.32,
    GB_NUTZFL_NF_VON_NGF: 2877.87,
    GB_TECHNIKFL_TF_VON_NGF: 390.84,
    GB_VERKEHRSFL_VF_VON_NGF: 929.61,
    GB_MIETFL_GESAMT: 4198.32,
    GB_MIETFL_1_BSB: 3746.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991836,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.579176',
    GB_LAENGENGRAD_OESTLICH: '10.125609',
    GB_NETTOGRUNDFL_NGF: 665.82,
    GB_NUTZFL_NF_VON_NGF: 613.83,
    GB_TECHNIKFL_TF_VON_NGF: 22.31,
    GB_VERKEHRSFL_VF_VON_NGF: 29.68,
    GB_MIETFL_GESAMT: 665.82,
    GB_MIETFL_1_BSB: 665.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.10.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991837,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 443.48,
    GB_NUTZFL_NF_VON_NGF: 368.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.97,
    GB_MIETFL_GESAMT: 443.48,
    GB_MIETFL_1_BSB: 443.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991838,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 58.45,
    GB_NUTZFL_NF_VON_NGF: 58.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 58.45,
    GB_MIETFL_1_BSB: 58.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991839,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 448.03,
    GB_NUTZFL_NF_VON_NGF: 346.83,
    GB_TECHNIKFL_TF_VON_NGF: 4.76,
    GB_VERKEHRSFL_VF_VON_NGF: 96.44,
    GB_MIETFL_GESAMT: 448.03,
    GB_MIETFL_1_BSB: 448.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '29.04.15',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991840,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 588.97,
    GB_NUTZFL_NF_VON_NGF: 382.05,
    GB_TECHNIKFL_TF_VON_NGF: 69.15,
    GB_VERKEHRSFL_VF_VON_NGF: 137.77,
    GB_MIETFL_GESAMT: 588.97,
    GB_MIETFL_1_BSB: 506.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991841,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 470.12,
    GB_NUTZFL_NF_VON_NGF: 380.19,
    GB_TECHNIKFL_TF_VON_NGF: 1.34,
    GB_VERKEHRSFL_VF_VON_NGF: 88.59,
    GB_MIETFL_GESAMT: 470.12,
    GB_MIETFL_1_BSB: 456.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991842,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 461.24,
    GB_NUTZFL_NF_VON_NGF: 392.43,
    GB_TECHNIKFL_TF_VON_NGF: 1.15,
    GB_VERKEHRSFL_VF_VON_NGF: 67.66,
    GB_MIETFL_GESAMT: 461.24,
    GB_MIETFL_1_BSB: 461.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.03.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991843,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 461.95,
    GB_NUTZFL_NF_VON_NGF: 392.67,
    GB_TECHNIKFL_TF_VON_NGF: 1.66,
    GB_VERKEHRSFL_VF_VON_NGF: 67.62,
    GB_MIETFL_GESAMT: 461.95,
    GB_MIETFL_1_BSB: 461.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.03.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991844,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 107.38,
    GB_NUTZFL_NF_VON_NGF: 86.4,
    GB_TECHNIKFL_TF_VON_NGF: 2.86,
    GB_VERKEHRSFL_VF_VON_NGF: 18.12,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 1991845,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.16',
    GB_GB_OBJ_ID_PKT: 1991846,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/18',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.86,
    GB_NUTZFL_NF_VON_NGF: 13.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.86,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991847,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | au Ganztagsfl?che Beginn 2015',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5399691,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705073,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705073',
    GB_SAP_GB_ID_PKF: '7000/705073/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.17',
    GB_GB_OBJ_ID_PKT: 4352765,
    GB_WE_OBJ_ID_FKT: 1989347,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1985.75,
    GB_NUTZFL_NF_VON_NGF: 1742.76,
    GB_TECHNIKFL_TF_VON_NGF: 6.73,
    GB_VERKEHRSFL_VF_VON_NGF: 236.26,
    GB_MIETFL_GESAMT: 1985.75,
    GB_MIETFL_1_BSB: 1411.61,
    GB_MIETFL_2_DRITTE: 572.42,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991678,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 728.06,
    GB_NUTZFL_NF_VON_NGF: 575.72,
    GB_TECHNIKFL_TF_VON_NGF: 70.61,
    GB_VERKEHRSFL_VF_VON_NGF: 81.73,
    GB_MIETFL_GESAMT: 728.06,
    GB_MIETFL_1_BSB: 637.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991679,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 479.35,
    GB_NUTZFL_NF_VON_NGF: 424.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.76,
    GB_MIETFL_GESAMT: 479.35,
    GB_MIETFL_1_BSB: 479.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991680,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 478.76,
    GB_NUTZFL_NF_VON_NGF: 424.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.76,
    GB_MIETFL_GESAMT: 478.76,
    GB_MIETFL_1_BSB: 478.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991681,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 478.76,
    GB_NUTZFL_NF_VON_NGF: 424.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.76,
    GB_MIETFL_GESAMT: 478.76,
    GB_MIETFL_1_BSB: 478.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991682,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 477.82,
    GB_NUTZFL_NF_VON_NGF: 423.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.76,
    GB_MIETFL_GESAMT: 477.82,
    GB_MIETFL_1_BSB: 477.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991683,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.576960',
    GB_LAENGENGRAD_OESTLICH: '10.131607',
    GB_NETTOGRUNDFL_NGF: 656.94,
    GB_NUTZFL_NF_VON_NGF: 599.44,
    GB_TECHNIKFL_TF_VON_NGF: 11.61,
    GB_VERKEHRSFL_VF_VON_NGF: 45.89,
    GB_MIETFL_GESAMT: 656.94,
    GB_MIETFL_1_BSB: 656.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991684,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 153.61,
    GB_NUTZFL_NF_VON_NGF: 111.34,
    GB_TECHNIKFL_TF_VON_NGF: 15.21,
    GB_VERKEHRSFL_VF_VON_NGF: 27.06,
    GB_MIETFL_GESAMT: 153.61,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991685,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.79,
    GB_NUTZFL_NF_VON_NGF: 10.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.79,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991686,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 213.48,
    GB_NUTZFL_NF_VON_NGF: 186.03,
    GB_TECHNIKFL_TF_VON_NGF: 12.57,
    GB_VERKEHRSFL_VF_VON_NGF: 14.88,
    GB_MIETFL_GESAMT: 213.48,
    GB_MIETFL_1_BSB: 213.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '30.09.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5325220,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckhalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 617.45,
    GB_NUTZFL_NF_VON_NGF: 510.87,
    GB_TECHNIKFL_TF_VON_NGF: 8.95,
    GB_VERKEHRSFL_VF_VON_NGF: 97.63,
    GB_MIETFL_GESAMT: 617.45,
    GB_MIETFL_1_BSB: 617.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.11.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399680,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Zubau',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.05.21',
    GB_GB_OBJ_ID_PKT: 7032213,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705053,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705053',
    GB_SAP_GB_ID_PKF: '7000/705053/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1454.9,
    GB_NUTZFL_NF_VON_NGF: 1345.0,
    GB_TECHNIKFL_TF_VON_NGF: 15.09,
    GB_VERKEHRSFL_VF_VON_NGF: 94.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.01.13',
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 5174593,
    GB_WE_OBJ_ID_FKT: 1989327,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1730.85,
    GB_NUTZFL_NF_VON_NGF: 1217.04,
    GB_TECHNIKFL_TF_VON_NGF: 162.5,
    GB_VERKEHRSFL_VF_VON_NGF: 351.31,
    GB_MIETFL_GESAMT: 1730.85,
    GB_MIETFL_1_BSB: 1427.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991797,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.567749',
    GB_LAENGENGRAD_OESTLICH: '10.134702',
    GB_NETTOGRUNDFL_NGF: 624.22,
    GB_NUTZFL_NF_VON_NGF: 588.63,
    GB_TECHNIKFL_TF_VON_NGF: 13.46,
    GB_VERKEHRSFL_VF_VON_NGF: 22.13,
    GB_MIETFL_GESAMT: 624.22,
    GB_MIETFL_1_BSB: 624.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991798,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 402.04,
    GB_NUTZFL_NF_VON_NGF: 333.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.20',
    GB_GB_OBJ_ID_PKT: 1991799,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.2,
    GB_NUTZFL_NF_VON_NGF: 422.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.02,
    GB_MIETFL_GESAMT: 469.2,
    GB_MIETFL_1_BSB: 469.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991800,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.4,
    GB_NUTZFL_NF_VON_NGF: 422.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.02,
    GB_MIETFL_GESAMT: 469.4,
    GB_MIETFL_1_BSB: 469.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991801,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.34,
    GB_NUTZFL_NF_VON_NGF: 422.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.02,
    GB_MIETFL_GESAMT: 469.34,
    GB_MIETFL_1_BSB: 469.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991802,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 148.93,
    GB_NUTZFL_NF_VON_NGF: 134.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.25,
    GB_MIETFL_GESAMT: 148.93,
    GB_MIETFL_1_BSB: 148.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991803,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.16,
    GB_NUTZFL_NF_VON_NGF: 16.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991804,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 426.65,
    GB_NUTZFL_NF_VON_NGF: 377.63,
    GB_TECHNIKFL_TF_VON_NGF: 7.62,
    GB_VERKEHRSFL_VF_VON_NGF: 41.4,
    GB_MIETFL_GESAMT: 426.65,
    GB_MIETFL_1_BSB: 426.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '29.08.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5325221,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1362.69,
    GB_NUTZFL_NF_VON_NGF: 1047.86,
    GB_TECHNIKFL_TF_VON_NGF: 9.77,
    GB_VERKEHRSFL_VF_VON_NGF: 305.06,
    GB_MIETFL_GESAMT: 1362.69,
    GB_MIETFL_1_BSB: 1358.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399687,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705068,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705068',
    GB_SAP_GB_ID_PKF: '7000/705068/11',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088392,
    GB_WE_OBJ_ID_FKT: 1989342,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1732.71,
    GB_NUTZFL_NF_VON_NGF: 1255.23,
    GB_TECHNIKFL_TF_VON_NGF: 197.15,
    GB_VERKEHRSFL_VF_VON_NGF: 280.33,
    GB_MIETFL_GESAMT: 1732.71,
    GB_MIETFL_1_BSB: 1518.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.03.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991816,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.15',
    GB_GB_OBJ_ID_PKT: 1991817,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 490.52,
    GB_NUTZFL_NF_VON_NGF: 381.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 109.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.15',
    GB_GB_OBJ_ID_PKT: 1991818,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 731.76,
    GB_NUTZFL_NF_VON_NGF: 676.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991819,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.4,
    GB_NUTZFL_NF_VON_NGF: 414.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991820,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 710.07,
    GB_NUTZFL_NF_VON_NGF: 609.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 100.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991821,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1014.82,
    GB_NUTZFL_NF_VON_NGF: 843.15,
    GB_TECHNIKFL_TF_VON_NGF: 2.44,
    GB_VERKEHRSFL_VF_VON_NGF: 169.23,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.17',
    GB_GB_OBJ_ID_PKT: 1991822,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.573182',
    GB_LAENGENGRAD_OESTLICH: '10.145193',
    GB_NETTOGRUNDFL_NGF: 658.03,
    GB_NUTZFL_NF_VON_NGF: 611.99,
    GB_TECHNIKFL_TF_VON_NGF: 17.25,
    GB_VERKEHRSFL_VF_VON_NGF: 28.79,
    GB_MIETFL_GESAMT: 658.03,
    GB_MIETFL_1_BSB: 658.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '02.09.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991823,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2301.85,
    GB_NUTZFL_NF_VON_NGF: 1891.91,
    GB_TECHNIKFL_TF_VON_NGF: 5.66,
    GB_VERKEHRSFL_VF_VON_NGF: 404.28,
    GB_MIETFL_GESAMT: 2301.85,
    GB_MIETFL_1_BSB: 2195.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399690,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705071,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705071',
    GB_SAP_GB_ID_PKF: '7000/705071/10',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 344.37,
    GB_NUTZFL_NF_VON_NGF: 317.24,
    GB_TECHNIKFL_TF_VON_NGF: 11.7,
    GB_VERKEHRSFL_VF_VON_NGF: 15.43,
    GB_MIETFL_GESAMT: 344.37,
    GB_MIETFL_1_BSB: 344.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.02.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7087710,
    GB_WE_OBJ_ID_FKT: 1989345,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2642.16,
    GB_NUTZFL_NF_VON_NGF: 1802.49,
    GB_TECHNIKFL_TF_VON_NGF: 133.4,
    GB_VERKEHRSFL_VF_VON_NGF: 706.27,
    GB_MIETFL_GESAMT: 2642.16,
    GB_MIETFL_1_BSB: 2397.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992002,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 396.34,
    GB_NUTZFL_NF_VON_NGF: 372.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.2,
    GB_MIETFL_GESAMT: 396.34,
    GB_MIETFL_1_BSB: 396.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992003,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.58,
    GB_NUTZFL_NF_VON_NGF: 123.19,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.39,
    GB_MIETFL_GESAMT: 147.58,
    GB_MIETFL_1_BSB: 147.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992004,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.9,
    GB_NUTZFL_NF_VON_NGF: 254.35,
    GB_TECHNIKFL_TF_VON_NGF: 1.58,
    GB_VERKEHRSFL_VF_VON_NGF: 55.97,
    GB_MIETFL_GESAMT: 311.9,
    GB_MIETFL_1_BSB: 311.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992005,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.563845',
    GB_LAENGENGRAD_OESTLICH: '10.064471',
    GB_NETTOGRUNDFL_NGF: 303.13,
    GB_NUTZFL_NF_VON_NGF: 285.56,
    GB_TECHNIKFL_TF_VON_NGF: 9.04,
    GB_VERKEHRSFL_VF_VON_NGF: 8.53,
    GB_MIETFL_GESAMT: 303.13,
    GB_MIETFL_1_BSB: 303.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992006,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/6',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 649.31,
    GB_NUTZFL_NF_VON_NGF: 471.21,
    GB_TECHNIKFL_TF_VON_NGF: 5.51,
    GB_VERKEHRSFL_VF_VON_NGF: 172.59,
    GB_MIETFL_GESAMT: 649.31,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 649.31,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992007,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 203.23,
    GB_NUTZFL_NF_VON_NGF: 170.82,
    GB_TECHNIKFL_TF_VON_NGF: 3.08,
    GB_VERKEHRSFL_VF_VON_NGF: 29.33,
    GB_MIETFL_GESAMT: 203.23,
    GB_MIETFL_1_BSB: 203.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992008,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 728.97,
    GB_NUTZFL_NF_VON_NGF: 492.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 236.27,
    GB_MIETFL_GESAMT: 728.97,
    GB_MIETFL_1_BSB: 728.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992009,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.64,
    GB_NUTZFL_NF_VON_NGF: 127.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.57,
    GB_MIETFL_GESAMT: 135.64,
    GB_MIETFL_1_BSB: 135.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992010,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7093937,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7093938,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705094,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705094',
    GB_SAP_GB_ID_PKF: '7000/705094/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7093939,
    GB_WE_OBJ_ID_FKT: 1989368,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1497.84,
    GB_NUTZFL_NF_VON_NGF: 1129.77,
    GB_TECHNIKFL_TF_VON_NGF: 9.08,
    GB_VERKEHRSFL_VF_VON_NGF: 358.99,
    GB_MIETFL_GESAMT: 1497.84,
    GB_MIETFL_1_BSB: 1494.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991724,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2680.38,
    GB_NUTZFL_NF_VON_NGF: 1741.42,
    GB_TECHNIKFL_TF_VON_NGF: 92.9,
    GB_VERKEHRSFL_VF_VON_NGF: 846.06,
    GB_MIETFL_GESAMT: 2680.38,
    GB_MIETFL_1_BSB: 2574.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991725,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 513.14,
    GB_NUTZFL_NF_VON_NGF: 415.86,
    GB_TECHNIKFL_TF_VON_NGF: 27.53,
    GB_VERKEHRSFL_VF_VON_NGF: 69.75,
    GB_MIETFL_GESAMT: 513.14,
    GB_MIETFL_1_BSB: 487.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991726,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3719.2,
    GB_NUTZFL_NF_VON_NGF: 2761.75,
    GB_TECHNIKFL_TF_VON_NGF: 146.37,
    GB_VERKEHRSFL_VF_VON_NGF: 811.08,
    GB_MIETFL_GESAMT: 3719.2,
    GB_MIETFL_1_BSB: 3572.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991727,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.8,
    GB_NUTZFL_NF_VON_NGF: 82.4,
    GB_TECHNIKFL_TF_VON_NGF: 6.1,
    GB_VERKEHRSFL_VF_VON_NGF: 32.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.01.20',
    GB_GB_OBJ_ID_PKT: 1991728,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 43.08,
    GB_NUTZFL_NF_VON_NGF: 43.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 43.08,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991729,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: '53.568792',
    GB_LAENGENGRAD_OESTLICH: '10.105229',
    GB_NETTOGRUNDFL_NGF: 638.71,
    GB_NUTZFL_NF_VON_NGF: 592.82,
    GB_TECHNIKFL_TF_VON_NGF: 17.02,
    GB_VERKEHRSFL_VF_VON_NGF: 28.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.18',
    GB_GB_OBJ_ID_PKT: 1991730,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/10',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 832.84,
    GB_NUTZFL_NF_VON_NGF: 770.39,
    GB_TECHNIKFL_TF_VON_NGF: 4.79,
    GB_VERKEHRSFL_VF_VON_NGF: 57.66,
    GB_MIETFL_GESAMT: 832.84,
    GB_MIETFL_1_BSB: 832.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399682,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | au Unterrichtsr?ume Beginn 2014',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.12.16',
    GB_GB_OBJ_ID_PKT: 5399683,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399684,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.05,
    GB_NUTZFL_NF_VON_NGF: 187.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.16,
    GB_MIETFL_GESAMT: 198.05,
    GB_MIETFL_1_BSB: 198.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991631,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.565750',
    GB_LAENGENGRAD_OESTLICH: '10.103901',
    GB_NETTOGRUNDFL_NGF: 1531.15,
    GB_NUTZFL_NF_VON_NGF: 1190.41,
    GB_TECHNIKFL_TF_VON_NGF: 26.25,
    GB_VERKEHRSFL_VF_VON_NGF: 314.49,
    GB_MIETFL_GESAMT: 1531.15,
    GB_MIETFL_1_BSB: 1531.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '21.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5395405,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705058,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705058',
    GB_SAP_GB_ID_PKF: '7000/705058/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.565812',
    GB_LAENGENGRAD_OESTLICH: '10.103216',
    GB_NETTOGRUNDFL_NGF: 663.13,
    GB_NUTZFL_NF_VON_NGF: 586.48,
    GB_TECHNIKFL_TF_VON_NGF: 20.84,
    GB_VERKEHRSFL_VF_VON_NGF: 55.81,
    GB_MIETFL_GESAMT: 663.13,
    GB_MIETFL_1_BSB: 663.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5395406,
    GB_WE_OBJ_ID_FKT: 1989332,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 850.22,
    GB_NUTZFL_NF_VON_NGF: 753.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 96.38,
    GB_MIETFL_GESAMT: 850.22,
    GB_MIETFL_1_BSB: 850.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991632,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1449.06,
    GB_NUTZFL_NF_VON_NGF: 1120.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 328.46,
    GB_MIETFL_GESAMT: 1449.06,
    GB_MIETFL_1_BSB: 1449.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991633,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.74,
    GB_NUTZFL_NF_VON_NGF: 211.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.22,
    GB_MIETFL_GESAMT: 236.74,
    GB_MIETFL_1_BSB: 236.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991634,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.566050',
    GB_LAENGENGRAD_OESTLICH: '10.104208',
    GB_NETTOGRUNDFL_NGF: 626.13,
    GB_NUTZFL_NF_VON_NGF: 585.39,
    GB_TECHNIKFL_TF_VON_NGF: 21.5,
    GB_VERKEHRSFL_VF_VON_NGF: 19.24,
    GB_MIETFL_GESAMT: 626.13,
    GB_MIETFL_1_BSB: 626.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991635,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 290.04,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 26.25,
    GB_VERKEHRSFL_VF_VON_NGF: 263.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 1991636,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 230.53,
    GB_NUTZFL_NF_VON_NGF: 171.56,
    GB_TECHNIKFL_TF_VON_NGF: 4.99,
    GB_VERKEHRSFL_VF_VON_NGF: 53.98,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '11.10.17',
    GB_GB_OBJ_ID_PKT: 1991637,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/8',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.565724',
    GB_LAENGENGRAD_OESTLICH: '10.103912',
    GB_NETTOGRUNDFL_NGF: 601.88,
    GB_NUTZFL_NF_VON_NGF: 459.15,
    GB_TECHNIKFL_TF_VON_NGF: 69.64,
    GB_VERKEHRSFL_VF_VON_NGF: 73.09,
    GB_MIETFL_GESAMT: 601.88,
    GB_MIETFL_1_BSB: 413.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991638,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991639,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 12.95,
    GB_NUTZFL_NF_VON_NGF: 12.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 12.95,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991640,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 11.33,
    GB_NUTZFL_NF_VON_NGF: 11.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 11.33,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991641,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/12',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.34,
    GB_NUTZFL_NF_VON_NGF: 14.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.34,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991642,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 652.98,
    GB_NUTZFL_NF_VON_NGF: 576.33,
    GB_TECHNIKFL_TF_VON_NGF: 20.84,
    GB_VERKEHRSFL_VF_VON_NGF: 55.81,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 5325219,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2228.39,
    GB_NUTZFL_NF_VON_NGF: 1698.73,
    GB_TECHNIKFL_TF_VON_NGF: 49.13,
    GB_VERKEHRSFL_VF_VON_NGF: 480.53,
    GB_MIETFL_GESAMT: 2228.39,
    GB_MIETFL_1_BSB: 2154.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399675,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/15',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088388,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/16',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7377310,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7377311,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705049,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705049',
    GB_SAP_GB_ID_PKF: '7000/705049/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552753,
    GB_WE_OBJ_ID_FKT: 1989323,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 1991643,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.54,
    GB_NUTZFL_NF_VON_NGF: 111.32,
    GB_TECHNIKFL_TF_VON_NGF: 7.74,
    GB_VERKEHRSFL_VF_VON_NGF: 16.48,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 1991644,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.571654',
    GB_LAENGENGRAD_OESTLICH: '10.103564',
    GB_NETTOGRUNDFL_NGF: 623.64,
    GB_NUTZFL_NF_VON_NGF: 588.07,
    GB_TECHNIKFL_TF_VON_NGF: 13.68,
    GB_VERKEHRSFL_VF_VON_NGF: 21.89,
    GB_MIETFL_GESAMT: 623.64,
    GB_MIETFL_1_BSB: 623.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991645,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 307.49,
    GB_NUTZFL_NF_VON_NGF: 262.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 45.43,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 1991646,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 101.63,
    GB_NUTZFL_NF_VON_NGF: 84.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 1991647,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.17',
    GB_GB_OBJ_ID_PKT: 1991648,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 162.49,
    GB_NUTZFL_NF_VON_NGF: 138.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 23.87,
    GB_MIETFL_GESAMT: 162.49,
    GB_MIETFL_1_BSB: 162.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991649,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1540.93,
    GB_NUTZFL_NF_VON_NGF: 1104.84,
    GB_TECHNIKFL_TF_VON_NGF: 106.25,
    GB_VERKEHRSFL_VF_VON_NGF: 329.84,
    GB_MIETFL_GESAMT: 1540.93,
    GB_MIETFL_1_BSB: 1427.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991650,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.75,
    GB_NUTZFL_NF_VON_NGF: 10.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.75,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991651,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 17.0,
    GB_NUTZFL_NF_VON_NGF: 17.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 17.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991652,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2841.43,
    GB_NUTZFL_NF_VON_NGF: 2233.37,
    GB_TECHNIKFL_TF_VON_NGF: 32.26,
    GB_VERKEHRSFL_VF_VON_NGF: 575.8,
    GB_MIETFL_GESAMT: 2841.43,
    GB_MIETFL_1_BSB: 2838.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399676,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/12',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 303.19,
    GB_NUTZFL_NF_VON_NGF: 280.64,
    GB_TECHNIKFL_TF_VON_NGF: 6.43,
    GB_VERKEHRSFL_VF_VON_NGF: 16.12,
    GB_MIETFL_GESAMT: 303.19,
    GB_MIETFL_1_BSB: 303.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '14.11.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399677,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/13',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7032212,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/14',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088389,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.04.16',
    GB_GB_OBJ_ID_PKT: 4352759,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705050,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705050',
    GB_SAP_GB_ID_PKF: '7000/705050/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.77,
    GB_NUTZFL_NF_VON_NGF: 79.43,
    GB_TECHNIKFL_TF_VON_NGF: 2.93,
    GB_VERKEHRSFL_VF_VON_NGF: 8.41,
    GB_MIETFL_GESAMT: 90.77,
    GB_MIETFL_1_BSB: 90.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462191,
    GB_WE_OBJ_ID_FKT: 1989324,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705099,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705099',
    GB_SAP_GB_ID_PKF: '7000/705099/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4920.24,
    GB_NUTZFL_NF_VON_NGF: 3522.21,
    GB_TECHNIKFL_TF_VON_NGF: 157.6,
    GB_VERKEHRSFL_VF_VON_NGF: 1240.43,
    GB_MIETFL_GESAMT: 4920.24,
    GB_MIETFL_1_BSB: 4751.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992022,
    GB_WE_OBJ_ID_FKT: 1989373,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705099,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705099',
    GB_SAP_GB_ID_PKF: '7000/705099/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 117.28,
    GB_NUTZFL_NF_VON_NGF: 92.2,
    GB_TECHNIKFL_TF_VON_NGF: 5.97,
    GB_VERKEHRSFL_VF_VON_NGF: 19.11,
    GB_MIETFL_GESAMT: 117.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992023,
    GB_WE_OBJ_ID_FKT: 1989373,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705099,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705099',
    GB_SAP_GB_ID_PKF: '7000/705099/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.562477',
    GB_LAENGENGRAD_OESTLICH: '10.075001',
    GB_NETTOGRUNDFL_NGF: 592.0,
    GB_NUTZFL_NF_VON_NGF: 545.99,
    GB_TECHNIKFL_TF_VON_NGF: 21.98,
    GB_VERKEHRSFL_VF_VON_NGF: 24.03,
    GB_MIETFL_GESAMT: 592.0,
    GB_MIETFL_1_BSB: 592.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992024,
    GB_WE_OBJ_ID_FKT: 1989373,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705099,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705099',
    GB_SAP_GB_ID_PKF: '7000/705099/4',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 30.82,
    GB_NUTZFL_NF_VON_NGF: 30.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 30.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992025,
    GB_WE_OBJ_ID_FKT: 1989373,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 306.83,
    GB_NUTZFL_NF_VON_NGF: 231.42,
    GB_TECHNIKFL_TF_VON_NGF: 18.96,
    GB_VERKEHRSFL_VF_VON_NGF: 56.45,
    GB_MIETFL_GESAMT: 306.83,
    GB_MIETFL_1_BSB: 138.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991890,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1558.13,
    GB_NUTZFL_NF_VON_NGF: 1151.33,
    GB_TECHNIKFL_TF_VON_NGF: 105.03,
    GB_VERKEHRSFL_VF_VON_NGF: 301.77,
    GB_MIETFL_GESAMT: 1558.13,
    GB_MIETFL_1_BSB: 1447.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991891,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.583411',
    GB_LAENGENGRAD_OESTLICH: '10.149883',
    GB_NETTOGRUNDFL_NGF: 629.65,
    GB_NUTZFL_NF_VON_NGF: 588.81,
    GB_TECHNIKFL_TF_VON_NGF: 21.85,
    GB_VERKEHRSFL_VF_VON_NGF: 18.99,
    GB_MIETFL_GESAMT: 629.65,
    GB_MIETFL_1_BSB: 629.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991892,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 901.21,
    GB_NUTZFL_NF_VON_NGF: 843.06,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.15,
    GB_MIETFL_GESAMT: 901.21,
    GB_MIETFL_1_BSB: 901.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991893,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 411.15,
    GB_NUTZFL_NF_VON_NGF: 386.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.26,
    GB_MIETFL_GESAMT: 411.15,
    GB_MIETFL_1_BSB: 411.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991894,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 649.38,
    GB_NUTZFL_NF_VON_NGF: 558.53,
    GB_TECHNIKFL_TF_VON_NGF: 7.67,
    GB_VERKEHRSFL_VF_VON_NGF: 83.18,
    GB_MIETFL_GESAMT: 649.38,
    GB_MIETFL_1_BSB: 649.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991895,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 183.4,
    GB_NUTZFL_NF_VON_NGF: 155.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.91,
    GB_MIETFL_GESAMT: 183.4,
    GB_MIETFL_1_BSB: 183.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991896,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991897,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.8,
    GB_NUTZFL_NF_VON_NGF: 14.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.8,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991898,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/10',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991899,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/11',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 583.97,
    GB_NUTZFL_NF_VON_NGF: 455.76,
    GB_TECHNIKFL_TF_VON_NGF: 3.53,
    GB_VERKEHRSFL_VF_VON_NGF: 124.68,
    GB_MIETFL_GESAMT: 583.97,
    GB_MIETFL_1_BSB: 581.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.15',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5325222,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705078,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705078',
    GB_SAP_GB_ID_PKF: '7000/705078/12',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088393,
    GB_WE_OBJ_ID_FKT: 1989352,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1073.84,
    GB_NUTZFL_NF_VON_NGF: 887.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 186.13,
    GB_MIETFL_GESAMT: 1073.84,
    GB_MIETFL_1_BSB: 1073.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991362,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/2',
    GB_SAP_GB_BEZEICHNUNG: 'K?chengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'K?chengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 148.16,
    GB_NUTZFL_NF_VON_NGF: 87.43,
    GB_TECHNIKFL_TF_VON_NGF: 10.94,
    GB_VERKEHRSFL_VF_VON_NGF: 49.79,
    GB_MIETFL_GESAMT: 148.16,
    GB_MIETFL_1_BSB: 148.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991363,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 143.93,
    GB_NUTZFL_NF_VON_NGF: 120.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 23.62,
    GB_MIETFL_GESAMT: 143.93,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991364,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 325.44,
    GB_NUTZFL_NF_VON_NGF: 287.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.82,
    GB_MIETFL_GESAMT: 325.44,
    GB_MIETFL_1_BSB: 325.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991365,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 245.35,
    GB_NUTZFL_NF_VON_NGF: 241.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.37,
    GB_MIETFL_GESAMT: 245.35,
    GB_MIETFL_1_BSB: 245.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991366,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 494.39,
    GB_NUTZFL_NF_VON_NGF: 396.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 97.6,
    GB_MIETFL_GESAMT: 494.39,
    GB_MIETFL_1_BSB: 494.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991367,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 186.44,
    GB_NUTZFL_NF_VON_NGF: 178.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.35,
    GB_MIETFL_GESAMT: 186.44,
    GB_MIETFL_1_BSB: 186.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991368,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 51.27,
    GB_NUTZFL_NF_VON_NGF: 40.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.04,
    GB_MIETFL_GESAMT: 51.27,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991369,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 41.88,
    GB_NUTZFL_NF_VON_NGF: 41.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 41.88,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991370,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1106.84,
    GB_NUTZFL_NF_VON_NGF: 769.05,
    GB_TECHNIKFL_TF_VON_NGF: 100.74,
    GB_VERKEHRSFL_VF_VON_NGF: 237.05,
    GB_MIETFL_GESAMT: 1106.84,
    GB_MIETFL_1_BSB: 983.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991372,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/12',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 140.52,
    GB_NUTZFL_NF_VON_NGF: 109.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.63,
    GB_MIETFL_GESAMT: 140.52,
    GB_MIETFL_1_BSB: 140.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991373,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/13',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 67.91,
    GB_NUTZFL_NF_VON_NGF: 67.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 67.91,
    GB_MIETFL_1_BSB: 67.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991374,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/14',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 260.73,
    GB_NUTZFL_NF_VON_NGF: 252.23,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.5,
    GB_MIETFL_GESAMT: 260.73,
    GB_MIETFL_1_BSB: 260.73,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5342631,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.606197',
    GB_LAENGENGRAD_OESTLICH: '10.058257',
    GB_NETTOGRUNDFL_NGF: 592.79,
    GB_NUTZFL_NF_VON_NGF: 561.64,
    GB_TECHNIKFL_TF_VON_NGF: 21.84,
    GB_VERKEHRSFL_VF_VON_NGF: 9.31,
    GB_MIETFL_GESAMT: 592.79,
    GB_MIETFL_1_BSB: 592.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991371,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705016,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705016',
    GB_SAP_GB_ID_PKF: '7000/705016/15',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399652,
    GB_WE_OBJ_ID_FKT: 1989290,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 719.51,
    GB_NUTZFL_NF_VON_NGF: 452.71,
    GB_TECHNIKFL_TF_VON_NGF: 64.22,
    GB_VERKEHRSFL_VF_VON_NGF: 202.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991275,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 119.79,
    GB_NUTZFL_NF_VON_NGF: 119.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991276,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 130.03,
    GB_NUTZFL_NF_VON_NGF: 117.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 13.03,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991277,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 61.65,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 7.48,
    GB_VERKEHRSFL_VF_VON_NGF: 54.17,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991278,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 381.46,
    GB_NUTZFL_NF_VON_NGF: 325.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.76,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991279,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 496.86,
    GB_NUTZFL_NF_VON_NGF: 370.31,
    GB_TECHNIKFL_TF_VON_NGF: 5.87,
    GB_VERKEHRSFL_VF_VON_NGF: 120.68,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991280,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2928.03,
    GB_NUTZFL_NF_VON_NGF: 2198.34,
    GB_TECHNIKFL_TF_VON_NGF: 68.62,
    GB_VERKEHRSFL_VF_VON_NGF: 661.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991281,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 101.88,
    GB_NUTZFL_NF_VON_NGF: 84.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1991282,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.617962',
    GB_LAENGENGRAD_OESTLICH: '10.062967',
    GB_NETTOGRUNDFL_NGF: 659.25,
    GB_NUTZFL_NF_VON_NGF: 614.13,
    GB_TECHNIKFL_TF_VON_NGF: 16.52,
    GB_VERKEHRSFL_VF_VON_NGF: 28.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991283,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 1991284,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705007,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705007',
    GB_SAP_GB_ID_PKF: '7000/705007/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 5306409,
    GB_WE_OBJ_ID_FKT: 1989281,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1532.6,
    GB_NUTZFL_NF_VON_NGF: 1082.52,
    GB_TECHNIKFL_TF_VON_NGF: 50.24,
    GB_VERKEHRSFL_VF_VON_NGF: 399.84,
    GB_MIETFL_GESAMT: 1532.6,
    GB_MIETFL_1_BSB: 1376.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991507,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.13',
    GB_GB_OBJ_ID_PKT: 1991508,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2794.94,
    GB_NUTZFL_NF_VON_NGF: 2169.66,
    GB_TECHNIKFL_TF_VON_NGF: 50.48,
    GB_VERKEHRSFL_VF_VON_NGF: 574.8,
    GB_MIETFL_GESAMT: 2794.94,
    GB_MIETFL_1_BSB: 2744.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991509,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 381.77,
    GB_NUTZFL_NF_VON_NGF: 324.81,
    GB_TECHNIKFL_TF_VON_NGF: 1.3,
    GB_VERKEHRSFL_VF_VON_NGF: 55.66,
    GB_MIETFL_GESAMT: 381.77,
    GB_MIETFL_1_BSB: 381.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991510,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.615252',
    GB_LAENGENGRAD_OESTLICH: '10.049158',
    GB_NETTOGRUNDFL_NGF: 657.85,
    GB_NUTZFL_NF_VON_NGF: 612.4,
    GB_TECHNIKFL_TF_VON_NGF: 16.57,
    GB_VERKEHRSFL_VF_VON_NGF: 28.88,
    GB_MIETFL_GESAMT: 657.85,
    GB_MIETFL_1_BSB: 657.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991511,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 187.94,
    GB_NUTZFL_NF_VON_NGF: 160.27,
    GB_TECHNIKFL_TF_VON_NGF: 12.08,
    GB_VERKEHRSFL_VF_VON_NGF: 15.59,
    GB_MIETFL_GESAMT: 187.94,
    GB_MIETFL_1_BSB: 187.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5327082,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705033',
    GB_SAP_GB_ID_PKF: '7000/705033/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.15',
    GB_GB_OBJ_ID_PKT: 5399665,
    GB_WE_OBJ_ID_FKT: 1989307,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 610.11,
    GB_NUTZFL_NF_VON_NGF: 313.79,
    GB_TECHNIKFL_TF_VON_NGF: 191.99,
    GB_VERKEHRSFL_VF_VON_NGF: 104.33,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.19',
    GB_GB_OBJ_ID_PKT: 5400904,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 251.85,
    GB_NUTZFL_NF_VON_NGF: 248.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 3.3,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.05.23',
    GB_GB_OBJ_ID_PKT: 5484107,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.614769',
    GB_LAENGENGRAD_OESTLICH: '10.058414',
    GB_NETTOGRUNDFL_NGF: 6089.76,
    GB_NUTZFL_NF_VON_NGF: 4866.67,
    GB_TECHNIKFL_TF_VON_NGF: 449.74,
    GB_VERKEHRSFL_VF_VON_NGF: 773.35,
    GB_MIETFL_GESAMT: 6089.76,
    GB_MIETFL_1_BSB: 5256.31,
    GB_MIETFL_2_DRITTE: 569.06,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5484108,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/4',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10670.91,
    GB_NUTZFL_NF_VON_NGF: 7599.84,
    GB_TECHNIKFL_TF_VON_NGF: 184.36,
    GB_VERKEHRSFL_VF_VON_NGF: 2886.71,
    GB_MIETFL_GESAMT: 10670.91,
    GB_MIETFL_1_BSB: 10136.64,
    GB_MIETFL_2_DRITTE: 380.93,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418554,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2076.37,
    GB_NUTZFL_NF_VON_NGF: 1634.69,
    GB_TECHNIKFL_TF_VON_NGF: 53.76,
    GB_VERKEHRSFL_VF_VON_NGF: 387.92,
    GB_MIETFL_GESAMT: 2076.37,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 2025.98,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418796,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/6',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 25.0,
    GB_NUTZFL_NF_VON_NGF: 25.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 25.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7118972,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705104,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705104',
    GB_SAP_GB_ID_PKF: '7000/705104/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7517506,
    GB_WE_OBJ_ID_FKT: 2862424,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800410,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3642.79,
    GB_NUTZFL_NF_VON_NGF: 2385.68,
    GB_TECHNIKFL_TF_VON_NGF: 231.21,
    GB_VERKEHRSFL_VF_VON_NGF: 1025.9,
    GB_MIETFL_GESAMT: 3642.79,
    GB_MIETFL_1_BSB: 3315.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '25.08.15',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991958,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3135.28,
    GB_NUTZFL_NF_VON_NGF: 2303.84,
    GB_TECHNIKFL_TF_VON_NGF: 71.31,
    GB_VERKEHRSFL_VF_VON_NGF: 760.13,
    GB_MIETFL_GESAMT: 3135.28,
    GB_MIETFL_1_BSB: 3039.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991959,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.594530',
    GB_LAENGENGRAD_OESTLICH: '10.123878',
    GB_NETTOGRUNDFL_NGF: 1728.32,
    GB_NUTZFL_NF_VON_NGF: 1637.84,
    GB_TECHNIKFL_TF_VON_NGF: 33.7,
    GB_VERKEHRSFL_VF_VON_NGF: 56.78,
    GB_MIETFL_GESAMT: 1728.32,
    GB_MIETFL_1_BSB: 1728.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991960,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.81,
    GB_NUTZFL_NF_VON_NGF: 87.07,
    GB_TECHNIKFL_TF_VON_NGF: 4.09,
    GB_VERKEHRSFL_VF_VON_NGF: 11.65,
    GB_MIETFL_GESAMT: 102.81,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991961,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/5',
    GB_SAP_GB_BEZEICHNUNG: 'Eingangszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Eingangszentrum',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1937.11,
    GB_NUTZFL_NF_VON_NGF: 1564.27,
    GB_TECHNIKFL_TF_VON_NGF: 47.85,
    GB_VERKEHRSFL_VF_VON_NGF: 324.99,
    GB_MIETFL_GESAMT: 1937.11,
    GB_MIETFL_1_BSB: 1937.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991962,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705086,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705086',
    GB_SAP_GB_ID_PKF: '7000/705086/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3802.02,
    GB_NUTZFL_NF_VON_NGF: 3210.1,
    GB_TECHNIKFL_TF_VON_NGF: 27.05,
    GB_VERKEHRSFL_VF_VON_NGF: 564.87,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399697,
    GB_WE_OBJ_ID_FKT: 1989360,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705088,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705088',
    GB_SAP_GB_ID_PKF: '7000/705088/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1896,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1905.45,
    GB_NUTZFL_NF_VON_NGF: 1529.91,
    GB_TECHNIKFL_TF_VON_NGF: 38.97,
    GB_VERKEHRSFL_VF_VON_NGF: 336.57,
    GB_MIETFL_GESAMT: 1905.45,
    GB_MIETFL_1_BSB: 1005.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991969,
    GB_WE_OBJ_ID_FKT: 1989362,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705088,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705088',
    GB_SAP_GB_ID_PKF: '7000/705088/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 335.07,
    GB_NUTZFL_NF_VON_NGF: 320.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.14,
    GB_MIETFL_GESAMT: 335.07,
    GB_MIETFL_1_BSB: 335.07,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991970,
    GB_WE_OBJ_ID_FKT: 1989362,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705088,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705088',
    GB_SAP_GB_ID_PKF: '7000/705088/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1550.13,
    GB_NUTZFL_NF_VON_NGF: 1142.22,
    GB_TECHNIKFL_TF_VON_NGF: 105.47,
    GB_VERKEHRSFL_VF_VON_NGF: 302.44,
    GB_MIETFL_GESAMT: 1550.13,
    GB_MIETFL_1_BSB: 1437.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '21.12.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991971,
    GB_WE_OBJ_ID_FKT: 1989362,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705088,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705088',
    GB_SAP_GB_ID_PKF: '7000/705088/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.587311',
    GB_LAENGENGRAD_OESTLICH: '10.131055',
    GB_NETTOGRUNDFL_NGF: 623.64,
    GB_NUTZFL_NF_VON_NGF: 583.19,
    GB_TECHNIKFL_TF_VON_NGF: 21.35,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: 623.64,
    GB_MIETFL_1_BSB: 623.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '27.11.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991972,
    GB_WE_OBJ_ID_FKT: 1989362,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705088,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705088',
    GB_SAP_GB_ID_PKF: '7000/705088/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.2,
    GB_NUTZFL_NF_VON_NGF: 172.12,
    GB_TECHNIKFL_TF_VON_NGF: 13.75,
    GB_VERKEHRSFL_VF_VON_NGF: 14.33,
    GB_MIETFL_GESAMT: 200.2,
    GB_MIETFL_1_BSB: 200.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.05.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5343392,
    GB_WE_OBJ_ID_FKT: 1989362,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3812.34,
    GB_NUTZFL_NF_VON_NGF: 2964.74,
    GB_TECHNIKFL_TF_VON_NGF: 47.1,
    GB_VERKEHRSFL_VF_VON_NGF: 800.5,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.16',
    GB_GB_OBJ_ID_PKT: 1991963,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.16',
    GB_GB_OBJ_ID_PKT: 1991964,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1605.8,
    GB_NUTZFL_NF_VON_NGF: 1349.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 256.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.16',
    GB_GB_OBJ_ID_PKT: 1991965,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1346.22,
    GB_NUTZFL_NF_VON_NGF: 1064.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 282.17,
    GB_MIETFL_GESAMT: 1346.22,
    GB_MIETFL_1_BSB: 1346.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991966,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 83.11,
    GB_NUTZFL_NF_VON_NGF: 59.25,
    GB_TECHNIKFL_TF_VON_NGF: 9.86,
    GB_VERKEHRSFL_VF_VON_NGF: 14.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.02.16',
    GB_GB_OBJ_ID_PKT: 1991967,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.594215',
    GB_LAENGENGRAD_OESTLICH: '10.122275',
    GB_NETTOGRUNDFL_NGF: 591.94,
    GB_NUTZFL_NF_VON_NGF: 563.76,
    GB_TECHNIKFL_TF_VON_NGF: 18.62,
    GB_VERKEHRSFL_VF_VON_NGF: 9.56,
    GB_MIETFL_GESAMT: 591.94,
    GB_MIETFL_1_BSB: 591.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.06.21',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991968,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4308.47,
    GB_NUTZFL_NF_VON_NGF: 3290.7,
    GB_TECHNIKFL_TF_VON_NGF: 29.56,
    GB_VERKEHRSFL_VF_VON_NGF: 988.21,
    GB_MIETFL_GESAMT: 4308.47,
    GB_MIETFL_1_BSB: 4305.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '11.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399986,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 688.4,
    GB_NUTZFL_NF_VON_NGF: 577.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 110.75,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 4352768,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 420.88,
    GB_NUTZFL_NF_VON_NGF: 365.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 4352769,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 420.88,
    GB_NUTZFL_NF_VON_NGF: 365.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 4352770,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.89,
    GB_NUTZFL_NF_VON_NGF: 298.31,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 37.57,
    GB_MIETFL_GESAMT: 338.89,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5462184,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5462185,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 45,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/45',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5462186,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 46,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/46',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.07.17',
    GB_GB_OBJ_ID_PKT: 5462187,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705087,
    GB_SAP_GB_NUMMER: 47,
    GB_SAP_WE_ID_FKF: '7000/705087',
    GB_SAP_GB_ID_PKF: '7000/705087/47',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 185.06,
    GB_NUTZFL_NF_VON_NGF: 172.5,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 185.06,
    GB_MIETFL_1_BSB: 185.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5462188,
    GB_WE_OBJ_ID_FKT: 1989361,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6796417,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 981.56,
    GB_NUTZFL_NF_VON_NGF: 732.53,
    GB_TECHNIKFL_TF_VON_NGF: 27.28,
    GB_VERKEHRSFL_VF_VON_NGF: 221.75,
    GB_MIETFL_GESAMT: 981.56,
    GB_MIETFL_1_BSB: 795.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991375,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 682.33,
    GB_NUTZFL_NF_VON_NGF: 642.13,
    GB_TECHNIKFL_TF_VON_NGF: 4.95,
    GB_VERKEHRSFL_VF_VON_NGF: 35.25,
    GB_MIETFL_GESAMT: 682.33,
    GB_MIETFL_1_BSB: 670.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991376,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1518.72,
    GB_NUTZFL_NF_VON_NGF: 1004.14,
    GB_TECHNIKFL_TF_VON_NGF: 97.6,
    GB_VERKEHRSFL_VF_VON_NGF: 416.98,
    GB_MIETFL_GESAMT: 1518.72,
    GB_MIETFL_1_BSB: 1416.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.03.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991377,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 846.66,
    GB_NUTZFL_NF_VON_NGF: 731.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 115.64,
    GB_MIETFL_GESAMT: 846.66,
    GB_MIETFL_1_BSB: 846.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991378,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 53.34,
    GB_NUTZFL_NF_VON_NGF: 53.34,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 53.34,
    GB_MIETFL_1_BSB: 53.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991379,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.576394',
    GB_LAENGENGRAD_OESTLICH: '10.066221',
    GB_NETTOGRUNDFL_NGF: 674.3,
    GB_NUTZFL_NF_VON_NGF: 626.55,
    GB_TECHNIKFL_TF_VON_NGF: 17.61,
    GB_VERKEHRSFL_VF_VON_NGF: 30.14,
    GB_MIETFL_GESAMT: 674.3,
    GB_MIETFL_1_BSB: 674.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.08.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991380,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705017',
    GB_SAP_GB_ID_PKF: '7000/705017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 22.62,
    GB_NUTZFL_NF_VON_NGF: 22.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 22.62,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991381,
    GB_WE_OBJ_ID_FKT: 1989291,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705022',
    GB_SAP_GB_ID_PKF: '7000/705022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1928,
    GB_BREITENGRAD_NOERDLICH: '53.571913',
    GB_LAENGENGRAD_OESTLICH: '10.080318',
    GB_NETTOGRUNDFL_NGF: 5447.78,
    GB_NUTZFL_NF_VON_NGF: 3693.05,
    GB_TECHNIKFL_TF_VON_NGF: 76.06,
    GB_VERKEHRSFL_VF_VON_NGF: 1678.67,
    GB_MIETFL_GESAMT: 5447.78,
    GB_MIETFL_1_BSB: 5058.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '13.04.18',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991414,
    GB_WE_OBJ_ID_FKT: 1989296,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705022,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705022',
    GB_SAP_GB_ID_PKF: '7000/705022/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 279.62,
    GB_NUTZFL_NF_VON_NGF: 250.12,
    GB_TECHNIKFL_TF_VON_NGF: 14.81,
    GB_VERKEHRSFL_VF_VON_NGF: 14.69,
    GB_MIETFL_GESAMT: 279.62,
    GB_MIETFL_1_BSB: 279.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5327081,
    GB_WE_OBJ_ID_FKT: 1989296,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705022,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705022',
    GB_SAP_GB_ID_PKF: '7000/705022/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399655,
    GB_WE_OBJ_ID_FKT: 1989296,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705022,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705022',
    GB_SAP_GB_ID_PKF: '7000/705022/4',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399656,
    GB_WE_OBJ_ID_FKT: 1989296,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705022,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705022',
    GB_SAP_GB_ID_PKF: '7000/705022/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352752,
    GB_WE_OBJ_ID_FKT: 1989296,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1914,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3294.32,
    GB_NUTZFL_NF_VON_NGF: 2143.87,
    GB_TECHNIKFL_TF_VON_NGF: 42.93,
    GB_VERKEHRSFL_VF_VON_NGF: 1107.52,
    GB_MIETFL_GESAMT: 3294.32,
    GB_MIETFL_1_BSB: 3130.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991483,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/2',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 11.33,
    GB_NUTZFL_NF_VON_NGF: 11.33,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 11.33,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991484,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 555.13,
    GB_NUTZFL_NF_VON_NGF: 441.77,
    GB_TECHNIKFL_TF_VON_NGF: 10.1,
    GB_VERKEHRSFL_VF_VON_NGF: 103.26,
    GB_MIETFL_GESAMT: 555.13,
    GB_MIETFL_1_BSB: 555.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991485,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3045.37,
    GB_NUTZFL_NF_VON_NGF: 2519.71,
    GB_TECHNIKFL_TF_VON_NGF: 1.15,
    GB_VERKEHRSFL_VF_VON_NGF: 524.51,
    GB_MIETFL_GESAMT: 3045.37,
    GB_MIETFL_1_BSB: 2763.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991486,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1916,
    GB_BREITENGRAD_NOERDLICH: '53.571636',
    GB_LAENGENGRAD_OESTLICH: '10.078452',
    GB_NETTOGRUNDFL_NGF: 538.93,
    GB_NUTZFL_NF_VON_NGF: 467.39,
    GB_TECHNIKFL_TF_VON_NGF: 21.49,
    GB_VERKEHRSFL_VF_VON_NGF: 50.05,
    GB_MIETFL_GESAMT: 538.93,
    GB_MIETFL_1_BSB: 517.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991487,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1241.11,
    GB_NUTZFL_NF_VON_NGF: 1190.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.7,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1991488,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1353.72,
    GB_NUTZFL_NF_VON_NGF: 1031.1,
    GB_TECHNIKFL_TF_VON_NGF: 14.51,
    GB_VERKEHRSFL_VF_VON_NGF: 308.11,
    GB_MIETFL_GESAMT: 1353.72,
    GB_MIETFL_1_BSB: 1224.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399662,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088380,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/9',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088381,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/10',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088382,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705030,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705030',
    GB_SAP_GB_ID_PKF: '7000/705030/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.16',
    GB_GB_OBJ_ID_PKT: 4352757,
    GB_WE_OBJ_ID_FKT: 1989304,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7085574,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 927.18,
    GB_NUTZFL_NF_VON_NGF: 616.28,
    GB_TECHNIKFL_TF_VON_NGF: 188.01,
    GB_VERKEHRSFL_VF_VON_NGF: 122.89,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.19',
    GB_GB_OBJ_ID_PKT: 1991324,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 449.48,
    GB_NUTZFL_NF_VON_NGF: 373.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.06,
    GB_MIETFL_GESAMT: 449.48,
    GB_MIETFL_1_BSB: 449.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991325,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 449.48,
    GB_NUTZFL_NF_VON_NGF: 373.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.06,
    GB_MIETFL_GESAMT: 449.48,
    GB_MIETFL_1_BSB: 449.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991326,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.589200',
    GB_LAENGENGRAD_OESTLICH: '10.086801',
    GB_NETTOGRUNDFL_NGF: 303.44,
    GB_NUTZFL_NF_VON_NGF: 281.14,
    GB_TECHNIKFL_TF_VON_NGF: 14.31,
    GB_VERKEHRSFL_VF_VON_NGF: 7.99,
    GB_MIETFL_GESAMT: 303.44,
    GB_MIETFL_1_BSB: 303.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.22',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991327,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.588936',
    GB_LAENGENGRAD_OESTLICH: '10.086370',
    GB_NETTOGRUNDFL_NGF: 624.77,
    GB_NUTZFL_NF_VON_NGF: 579.25,
    GB_TECHNIKFL_TF_VON_NGF: 23.38,
    GB_VERKEHRSFL_VF_VON_NGF: 22.14,
    GB_MIETFL_GESAMT: 624.77,
    GB_MIETFL_1_BSB: 624.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991328,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.87,
    GB_NUTZFL_NF_VON_NGF: 144.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.46,
    GB_MIETFL_GESAMT: 161.87,
    GB_MIETFL_1_BSB: 161.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991329,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.74,
    GB_NUTZFL_NF_VON_NGF: 90.56,
    GB_TECHNIKFL_TF_VON_NGF: 19.47,
    GB_VERKEHRSFL_VF_VON_NGF: 37.71,
    GB_MIETFL_GESAMT: 147.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991330,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 6.91,
    GB_NUTZFL_NF_VON_NGF: 6.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 6.91,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991331,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mehrzweckhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1191.56,
    GB_NUTZFL_NF_VON_NGF: 937.98,
    GB_TECHNIKFL_TF_VON_NGF: 23.64,
    GB_VERKEHRSFL_VF_VON_NGF: 229.94,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.09.19',
    GB_GB_OBJ_ID_PKT: 1991332,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 436.83,
    GB_NUTZFL_NF_VON_NGF: 402.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 33.97,
    GB_MIETFL_GESAMT: 436.83,
    GB_MIETFL_1_BSB: 436.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991333,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 912.37,
    GB_NUTZFL_NF_VON_NGF: 638.6,
    GB_TECHNIKFL_TF_VON_NGF: 10.01,
    GB_VERKEHRSFL_VF_VON_NGF: 263.76,
    GB_MIETFL_GESAMT: 912.37,
    GB_MIETFL_1_BSB: 909.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7027055,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7027056,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/13',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1141.37,
    GB_NUTZFL_NF_VON_NGF: 903.63,
    GB_TECHNIKFL_TF_VON_NGF: 22.24,
    GB_VERKEHRSFL_VF_VON_NGF: 215.5,
    GB_MIETFL_GESAMT: 1141.37,
    GB_MIETFL_1_BSB: 963.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056305,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1347.7,
    GB_NUTZFL_NF_VON_NGF: 1123.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 223.78,
    GB_MIETFL_GESAMT: 1347.7,
    GB_MIETFL_1_BSB: 1347.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056306,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/15',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 766.88,
    GB_NUTZFL_NF_VON_NGF: 543.06,
    GB_TECHNIKFL_TF_VON_NGF: 23.64,
    GB_VERKEHRSFL_VF_VON_NGF: 200.18,
    GB_MIETFL_GESAMT: 766.88,
    GB_MIETFL_1_BSB: 743.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056307,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705012,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7000/705012',
    GB_SAP_GB_ID_PKF: '7000/705012/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 432.33,
    GB_NUTZFL_NF_VON_NGF: 397.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.08,
    GB_MIETFL_GESAMT: 432.33,
    GB_MIETFL_1_BSB: 432.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '12.04.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.10.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056308,
    GB_WE_OBJ_ID_FKT: 1989286,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1768.47,
    GB_NUTZFL_NF_VON_NGF: 1344.8,
    GB_TECHNIKFL_TF_VON_NGF: 69.18,
    GB_VERKEHRSFL_VF_VON_NGF: 354.49,
    GB_MIETFL_GESAMT: 1768.47,
    GB_MIETFL_1_BSB: 1410.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991266,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 399.8,
    GB_NUTZFL_NF_VON_NGF: 399.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 399.8,
    GB_MIETFL_1_BSB: 199.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991267,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 458.88,
    GB_NUTZFL_NF_VON_NGF: 377.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.91,
    GB_MIETFL_GESAMT: 458.88,
    GB_MIETFL_1_BSB: 458.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991268,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.581527',
    GB_LAENGENGRAD_OESTLICH: '10.097156',
    GB_NETTOGRUNDFL_NGF: 320.23,
    GB_NUTZFL_NF_VON_NGF: 301.71,
    GB_TECHNIKFL_TF_VON_NGF: 7.6,
    GB_VERKEHRSFL_VF_VON_NGF: 10.92,
    GB_MIETFL_GESAMT: 320.23,
    GB_MIETFL_1_BSB: 320.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991269,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.47,
    GB_NUTZFL_NF_VON_NGF: 183.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.42,
    GB_MIETFL_GESAMT: 215.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 215.47,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991270,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1551.66,
    GB_NUTZFL_NF_VON_NGF: 1142.26,
    GB_TECHNIKFL_TF_VON_NGF: 103.78,
    GB_VERKEHRSFL_VF_VON_NGF: 305.62,
    GB_MIETFL_GESAMT: 1551.66,
    GB_MIETFL_1_BSB: 1439.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.07.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991271,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.581118',
    GB_LAENGENGRAD_OESTLICH: '10.095667',
    GB_NETTOGRUNDFL_NGF: 625.63,
    GB_NUTZFL_NF_VON_NGF: 596.8,
    GB_TECHNIKFL_TF_VON_NGF: 6.69,
    GB_VERKEHRSFL_VF_VON_NGF: 22.14,
    GB_MIETFL_GESAMT: 625.63,
    GB_MIETFL_1_BSB: 625.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991272,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 470.41,
    GB_NUTZFL_NF_VON_NGF: 385.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 84.71,
    GB_MIETFL_GESAMT: 470.41,
    GB_MIETFL_1_BSB: 470.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991273,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 518.32,
    GB_NUTZFL_NF_VON_NGF: 442.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 75.72,
    GB_MIETFL_GESAMT: 518.32,
    GB_MIETFL_1_BSB: 518.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991274,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705006,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705006',
    GB_SAP_GB_ID_PKF: '7000/705006/10',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 18.13,
    GB_NUTZFL_NF_VON_NGF: 18.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 18.13,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138311,
    GB_WE_OBJ_ID_FKT: 1989280,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2845.19,
    GB_NUTZFL_NF_VON_NGF: 2044.1,
    GB_TECHNIKFL_TF_VON_NGF: 124.86,
    GB_VERKEHRSFL_VF_VON_NGF: 676.23,
    GB_MIETFL_GESAMT: 2845.19,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 2845.19,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991258,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.578835',
    GB_LAENGENGRAD_OESTLICH: '10.077466',
    GB_NETTOGRUNDFL_NGF: 511.48,
    GB_NUTZFL_NF_VON_NGF: 417.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.21,
    GB_MIETFL_GESAMT: 511.48,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 511.48,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991259,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 766.49,
    GB_NUTZFL_NF_VON_NGF: 662.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.75,
    GB_MIETFL_GESAMT: 766.49,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 766.49,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991260,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 99.28,
    GB_NUTZFL_NF_VON_NGF: 81.02,
    GB_TECHNIKFL_TF_VON_NGF: 5.54,
    GB_VERKEHRSFL_VF_VON_NGF: 12.72,
    GB_MIETFL_GESAMT: 99.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991261,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 839.42,
    GB_NUTZFL_NF_VON_NGF: 678.01,
    GB_TECHNIKFL_TF_VON_NGF: 69.83,
    GB_VERKEHRSFL_VF_VON_NGF: 91.58,
    GB_MIETFL_GESAMT: 839.42,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 839.42,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991262,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 215.29,
    GB_NUTZFL_NF_VON_NGF: 9.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 205.93,
    GB_MIETFL_GESAMT: 215.29,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 215.29,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991263,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 981.69,
    GB_NUTZFL_NF_VON_NGF: 755.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 226.37,
    GB_MIETFL_GESAMT: 981.69,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 981.69,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991264,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705005,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705005',
    GB_SAP_GB_ID_PKF: '7000/705005/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2000,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 198.24,
    GB_NUTZFL_NF_VON_NGF: 173.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.74,
    GB_MIETFL_GESAMT: 198.24,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 198.24,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991265,
    GB_WE_OBJ_ID_FKT: 1989279,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3731.18,
    GB_NUTZFL_NF_VON_NGF: 2486.89,
    GB_TECHNIKFL_TF_VON_NGF: 335.41,
    GB_VERKEHRSFL_VF_VON_NGF: 908.88,
    GB_MIETFL_GESAMT: 3731.18,
    GB_MIETFL_1_BSB: 2695.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991731,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1231.03,
    GB_NUTZFL_NF_VON_NGF: 1028.88,
    GB_TECHNIKFL_TF_VON_NGF: 12.87,
    GB_VERKEHRSFL_VF_VON_NGF: 189.28,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 1991732,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 557.15,
    GB_NUTZFL_NF_VON_NGF: 454.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 102.88,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.14',
    GB_GB_OBJ_ID_PKT: 1991733,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 440.59,
    GB_NUTZFL_NF_VON_NGF: 343.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 97.16,
    GB_MIETFL_GESAMT: 440.59,
    GB_MIETFL_1_BSB: 440.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991734,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 580.51,
    GB_NUTZFL_NF_VON_NGF: 451.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 129.33,
    GB_MIETFL_GESAMT: 580.51,
    GB_MIETFL_1_BSB: 580.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991735,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 497.78,
    GB_NUTZFL_NF_VON_NGF: 387.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 110.26,
    GB_MIETFL_GESAMT: 497.78,
    GB_MIETFL_1_BSB: 497.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991736,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 11.35,
    GB_NUTZFL_NF_VON_NGF: 11.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 11.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991737,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 647.2,
    GB_NUTZFL_NF_VON_NGF: 486.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 160.38,
    GB_MIETFL_GESAMT: 647.2,
    GB_MIETFL_1_BSB: 647.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991738,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.573675',
    GB_LAENGENGRAD_OESTLICH: '10.074179',
    GB_NETTOGRUNDFL_NGF: 608.5,
    GB_NUTZFL_NF_VON_NGF: 567.2,
    GB_TECHNIKFL_TF_VON_NGF: 13.29,
    GB_VERKEHRSFL_VF_VON_NGF: 28.01,
    GB_MIETFL_GESAMT: 608.5,
    GB_MIETFL_1_BSB: 608.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991739,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.573882',
    GB_LAENGENGRAD_OESTLICH: '10.074647',
    GB_NETTOGRUNDFL_NGF: 3309.32,
    GB_NUTZFL_NF_VON_NGF: 2525.81,
    GB_TECHNIKFL_TF_VON_NGF: 77.26,
    GB_VERKEHRSFL_VF_VON_NGF: 706.25,
    GB_MIETFL_GESAMT: 3309.32,
    GB_MIETFL_1_BSB: 3228.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1991740,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2192.76,
    GB_NUTZFL_NF_VON_NGF: 1608.8,
    GB_TECHNIKFL_TF_VON_NGF: 26.85,
    GB_VERKEHRSFL_VF_VON_NGF: 557.11,
    GB_MIETFL_GESAMT: 2192.76,
    GB_MIETFL_1_BSB: 2186.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '09.03.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.15',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5399685,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088390,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/13',
    GB_SAP_GB_BEZEICHNUNG: 'Zubau',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Zubau',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088391,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume beendet 01.08.2015',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 464.06,
    GB_NUTZFL_NF_VON_NGF: 441.95,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 19.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 4352760,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume beendet 01.08.2015',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 4352761,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705059,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/705059',
    GB_SAP_GB_ID_PKF: '7000/705059/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ile Klassenr?ume beendet 01.08.2015',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.08.15',
    GB_GB_OBJ_ID_PKT: 5306681,
    GB_WE_OBJ_ID_FKT: 1989333,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 7093624,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702083,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702083',
    GB_SAP_GB_ID_PKF: '7000/702083/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7413395,
    GB_WE_OBJ_ID_FKT: 7413392,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702084,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702084',
    GB_SAP_GB_ID_PKF: '7000/702084/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.559386109',
    GB_LAENGENGRAD_OESTLICH: '9.9559335466',
    GB_NETTOGRUNDFL_NGF: 2975.96,
    GB_NUTZFL_NF_VON_NGF: 2173.74,
    GB_TECHNIKFL_TF_VON_NGF: 87.64,
    GB_VERKEHRSFL_VF_VON_NGF: 676.36,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7454918,
    GB_WE_OBJ_ID_FKT: 7454916,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: 6988878,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 702082,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/702082',
    GB_SAP_GB_ID_PKF: '7000/702082/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7413394,
    GB_WE_OBJ_ID_FKT: 7413391,
    GB_RGN_OBJ_ID_FKT: 1799936,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706052',
    GB_SAP_GB_ID_PKF: '7000/706052/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062820,
    GB_WE_OBJ_ID_FKT: 7062813,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5448392,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706052',
    GB_SAP_GB_ID_PKF: '7000/706052/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062821,
    GB_WE_OBJ_ID_FKT: 7062813,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5448392,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706053',
    GB_SAP_GB_ID_PKF: '7000/706053/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062822,
    GB_WE_OBJ_ID_FKT: 7062814,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5455812,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706053',
    GB_SAP_GB_ID_PKF: '7000/706053/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062823,
    GB_WE_OBJ_ID_FKT: 7062814,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 5455812,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706048',
    GB_SAP_GB_ID_PKF: '7000/706048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7058648,
    GB_WE_OBJ_ID_FKT: 7058639,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 1800347,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706049',
    GB_SAP_GB_ID_PKF: '7000/706049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7058649,
    GB_WE_OBJ_ID_FKT: 7058640,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706050',
    GB_SAP_GB_ID_PKF: '7000/706050/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7058650,
    GB_WE_OBJ_ID_FKT: 7058641,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/706051',
    GB_SAP_GB_ID_PKF: '7000/706051/1',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062818,
    GB_WE_OBJ_ID_FKT: 7062812,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7085672,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 706051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/706051',
    GB_SAP_GB_ID_PKF: '7000/706051/2',
    GB_SAP_GB_BEZEICHNUNG: 'Einfeldsporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Einfeldsporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062819,
    GB_WE_OBJ_ID_FKT: 7062812,
    GB_RGN_OBJ_ID_FKT: 1799937,
    GB_OM_OBJ_ID_FKT: 7085672,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701000,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701000',
    GB_SAP_GB_ID_PKF: '7000/701000/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2070722,
    GB_WE_OBJ_ID_FKT: 5461213,
    GB_RGN_OBJ_ID_FKT: 6981843,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703074',
    GB_SAP_GB_ID_PKF: '7000/703074/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225196,
    GB_WE_OBJ_ID_FKT: 7223672,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800285,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703075,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703075',
    GB_SAP_GB_ID_PKF: '7000/703075/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225197,
    GB_WE_OBJ_ID_FKT: 7223673,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 1800508,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 703077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/703077',
    GB_SAP_GB_ID_PKF: '7000/703077/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7426515,
    GB_WE_OBJ_ID_FKT: 7426509,
    GB_RGN_OBJ_ID_FKT: 1799938,
    GB_OM_OBJ_ID_FKT: 6925907,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701069,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701069',
    GB_SAP_GB_ID_PKF: '7000/701069/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2812.31,
    GB_NUTZFL_NF_VON_NGF: 1987.19,
    GB_TECHNIKFL_TF_VON_NGF: 73.11,
    GB_VERKEHRSFL_VF_VON_NGF: 752.01,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.08.13',
    GB_GB_OBJ_ID_PKT: 1989934,
    GB_WE_OBJ_ID_FKT: 1989061,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701069,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701069',
    GB_SAP_GB_ID_PKF: '7000/701069/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989935,
    GB_WE_OBJ_ID_FKT: 1989061,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701069,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/701069',
    GB_SAP_GB_ID_PKF: '7000/701069/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989936,
    GB_WE_OBJ_ID_FKT: 1989061,
    GB_RGN_OBJ_ID_FKT: 1799939,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/701024',
    GB_SAP_GB_ID_PKF: '7000/701024/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989644,
    GB_WE_OBJ_ID_FKT: 1989019,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800490,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 701024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/701024',
    GB_SAP_GB_ID_PKF: '7000/701024/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.12',
    GB_GB_OBJ_ID_PKT: 1989645,
    GB_WE_OBJ_ID_FKT: 1989019,
    GB_RGN_OBJ_ID_FKT: 1799940,
    GB_OM_OBJ_ID_FKT: 1800490,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704084,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/704084',
    GB_SAP_GB_ID_PKF: '7000/704084/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5104.0,
    GB_NUTZFL_NF_VON_NGF: 5104.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 5104.0,
    GB_MIETFL_1_BSB: 5104.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225406,
    GB_WE_OBJ_ID_FKT: 7225402,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704084,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/704084',
    GB_SAP_GB_ID_PKF: '7000/704084/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5556.0,
    GB_NUTZFL_NF_VON_NGF: 5556.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 5556.0,
    GB_MIETFL_1_BSB: 5556.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225407,
    GB_WE_OBJ_ID_FKT: 7225402,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704084,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/704084',
    GB_SAP_GB_ID_PKF: '7000/704084/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 5157.0,
    GB_NUTZFL_NF_VON_NGF: 5157.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 5157.0,
    GB_MIETFL_1_BSB: 5157.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225408,
    GB_WE_OBJ_ID_FKT: 7225402,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704084,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/704084',
    GB_SAP_GB_ID_PKF: '7000/704084/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7225409,
    GB_WE_OBJ_ID_FKT: 7225402,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 704084,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/704084',
    GB_SAP_GB_ID_PKF: '7000/704084/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2900.0,
    GB_NUTZFL_NF_VON_NGF: 2900.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 2900.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7395519,
    GB_WE_OBJ_ID_FKT: 7225402,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: 1800546,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708026',
    GB_SAP_GB_ID_PKF: '7000/708026/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: 1908,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7403571,
    GB_WE_OBJ_ID_FKT: 7403566,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708025',
    GB_SAP_GB_ID_PKF: '7000/708025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1873,
    GB_BREITENGRAD_NOERDLICH: '53.556393',
    GB_LAENGENGRAD_OESTLICH: '10.006045',
    GB_NETTOGRUNDFL_NGF: 6553.42,
    GB_NUTZFL_NF_VON_NGF: 4554.53,
    GB_TECHNIKFL_TF_VON_NGF: 121.45,
    GB_VERKEHRSFL_VF_VON_NGF: 1877.44,
    GB_MIETFL_GESAMT: 6553.42,
    GB_MIETFL_1_BSB: 6435.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7403569,
    GB_WE_OBJ_ID_FKT: 7403565,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7401209,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708025,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708025',
    GB_SAP_GB_ID_PKF: '7000/708025/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1873,
    GB_BREITENGRAD_NOERDLICH: '53.556722655',
    GB_LAENGENGRAD_OESTLICH: '10.006168598',
    GB_NETTOGRUNDFL_NGF: 442.64,
    GB_NUTZFL_NF_VON_NGF: 312.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 129.86,
    GB_MIETFL_GESAMT: 442.64,
    GB_MIETFL_1_BSB: 442.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7403570,
    GB_WE_OBJ_ID_FKT: 7403565,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7401209,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708024',
    GB_SAP_GB_ID_PKF: '7000/708024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.583529',
    GB_LAENGENGRAD_OESTLICH: '9.992017',
    GB_NETTOGRUNDFL_NGF: 2962.9,
    GB_NUTZFL_NF_VON_NGF: 2315.33,
    GB_TECHNIKFL_TF_VON_NGF: 68.7,
    GB_VERKEHRSFL_VF_VON_NGF: 578.87,
    GB_MIETFL_GESAMT: 81.15,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7403568,
    GB_WE_OBJ_ID_FKT: 7403564,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708024,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/708024',
    GB_SAP_GB_ID_PKF: '7000/708024/2',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: '53.583663415',
    GB_LAENGENGRAD_OESTLICH: '9.9924919752',
    GB_NETTOGRUNDFL_NGF: 1478.45,
    GB_NUTZFL_NF_VON_NGF: 1158.64,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 319.81,
    GB_MIETFL_GESAMT: 114.97,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7405173,
    GB_WE_OBJ_ID_FKT: 7403564,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708027',
    GB_SAP_GB_ID_PKF: '7000/708027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7403572,
    GB_WE_OBJ_ID_FKT: 7403567,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 1800371,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/708028',
    GB_SAP_GB_ID_PKF: '7000/708028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '15.03.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7531271,
    GB_WE_OBJ_ID_FKT: 7531270,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7433109,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 708028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/708028',
    GB_SAP_GB_ID_PKF: '7000/708028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb. Gewerbeschule',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.574176667',
    GB_LAENGENGRAD_OESTLICH: '10.034831382',
    GB_NETTOGRUNDFL_NGF: 986.84,
    GB_NUTZFL_NF_VON_NGF: 768.92,
    GB_TECHNIKFL_TF_VON_NGF: 30.71,
    GB_VERKEHRSFL_VF_VON_NGF: 187.21,
    GB_MIETFL_GESAMT: 0.0,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7531401,
    GB_WE_OBJ_ID_FKT: 7531270,
    GB_RGN_OBJ_ID_FKT: 7245319,
    GB_OM_OBJ_ID_FKT: 7433109,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705106,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705106',
    GB_SAP_GB_ID_PKF: '7000/705106/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7396824,
    GB_WE_OBJ_ID_FKT: 7396822,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800510,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705111,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705111',
    GB_SAP_GB_ID_PKF: '7000/705111/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7426519,
    GB_WE_OBJ_ID_FKT: 7426513,
    GB_RGN_OBJ_ID_FKT: 1799943,
    GB_OM_OBJ_ID_FKT: 1800432,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705105,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705105',
    GB_SAP_GB_ID_PKF: '7000/705105/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7255696,
    GB_WE_OBJ_ID_FKT: 7255693,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 6771788,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705107,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705107',
    GB_SAP_GB_ID_PKF: '7000/705107/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7426516,
    GB_WE_OBJ_ID_FKT: 7426510,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800459,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705108,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705108',
    GB_SAP_GB_ID_PKF: '7000/705108/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7426517,
    GB_WE_OBJ_ID_FKT: 7426511,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800459,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 705110,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/705110',
    GB_SAP_GB_ID_PKF: '7000/705110/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7426518,
    GB_WE_OBJ_ID_FKT: 7426512,
    GB_RGN_OBJ_ID_FKT: 1799944,
    GB_OM_OBJ_ID_FKT: 1800459,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707063',
    GB_SAP_GB_ID_PKF: '7000/707063/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.11',
    GB_GB_OBJ_ID_PKT: 6793070,
    GB_WE_OBJ_ID_FKT: 6792487,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793042,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793043,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793044,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793045,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793046,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793047,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793048,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793049,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1992806,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1992807,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 210.0,
    GB_NUTZFL_NF_VON_NGF: 210.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.13',
    GB_GB_OBJ_ID_PKT: 1992808,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793050,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793051,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7000,
    GB_SAP_WE_NUMMER: 707059,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7000/707059',
    GB_SAP_GB_ID_PKF: '7000/707059/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | ',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793052,
    GB_WE_OBJ_ID_FKT: 6792484,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749026,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749026',
    GB_SAP_GB_ID_PKF: '7400/749026/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798796,
    GB_WE_OBJ_ID_FKT: 6793147,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749027',
    GB_SAP_GB_ID_PKF: '7400/749027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800182,
    GB_WE_OBJ_ID_FKT: 6793148,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749030',
    GB_SAP_GB_ID_PKF: '7400/749030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7022864,
    GB_WE_OBJ_ID_FKT: 7022861,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749025,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749025',
    GB_SAP_GB_ID_PKF: '7400/749025/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798795,
    GB_WE_OBJ_ID_FKT: 6793146,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749024,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749024',
    GB_SAP_GB_ID_PKF: '7400/749024/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798794,
    GB_WE_OBJ_ID_FKT: 6793145,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749032',
    GB_SAP_GB_ID_PKF: '7400/749032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1980,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7022866,
    GB_WE_OBJ_ID_FKT: 7022863,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743065,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743065',
    GB_SAP_GB_ID_PKF: '7400/743065/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418963,
    GB_WE_OBJ_ID_FKT: 5418960,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743065,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743065',
    GB_SAP_GB_ID_PKF: '7400/743065/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418964,
    GB_WE_OBJ_ID_FKT: 5418960,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743066',
    GB_SAP_GB_ID_PKF: '7400/743066/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418965,
    GB_WE_OBJ_ID_FKT: 5418961,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743066,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743066',
    GB_SAP_GB_ID_PKF: '7400/743066/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5418966,
    GB_WE_OBJ_ID_FKT: 5418961,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747075,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747075',
    GB_SAP_GB_ID_PKF: '7400/747075/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.514556924',
    GB_LAENGENGRAD_OESTLICH: '9.9986505687',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6971955,
    GB_WE_OBJ_ID_FKT: 6971954,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749001,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/749001',
    GB_SAP_GB_ID_PKF: '7400/749001/13',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 876.39,
    GB_NUTZFL_NF_VON_NGF: 652.22,
    GB_TECHNIKFL_TF_VON_NGF: 26.39,
    GB_VERKEHRSFL_VF_VON_NGF: 197.78,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.11.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6801182,
    GB_WE_OBJ_ID_FKT: 6793127,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749013,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749013',
    GB_SAP_GB_ID_PKF: '7400/749013/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800177,
    GB_WE_OBJ_ID_FKT: 6793135,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749031',
    GB_SAP_GB_ID_PKF: '7400/749031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7022865,
    GB_WE_OBJ_ID_FKT: 7022862,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749022,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749022',
    GB_SAP_GB_ID_PKF: '7400/749022/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798792,
    GB_WE_OBJ_ID_FKT: 6793143,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992415,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992416,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992417,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992418,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.487765',
    GB_LAENGENGRAD_OESTLICH: '10.022015',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992419,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992420,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992421,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992422,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749017,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/749017',
    GB_SAP_GB_ID_PKF: '7400/749017/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078237,
    GB_WE_OBJ_ID_FKT: 1989430,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 208.47,
    GB_NUTZFL_NF_VON_NGF: 177.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.21,
    GB_MIETFL_GESAMT: 208.47,
    GB_MIETFL_1_BSB: 208.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992531,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 296.63,
    GB_NUTZFL_NF_VON_NGF: 233.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 62.87,
    GB_MIETFL_GESAMT: 296.63,
    GB_MIETFL_1_BSB: 296.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992532,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.529808',
    GB_LAENGENGRAD_OESTLICH: '9.774680',
    GB_NETTOGRUNDFL_NGF: 622.3,
    GB_NUTZFL_NF_VON_NGF: 599.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.32,
    GB_MIETFL_GESAMT: 622.3,
    GB_MIETFL_1_BSB: 622.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992533,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/4',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 412.37,
    GB_NUTZFL_NF_VON_NGF: 321.33,
    GB_TECHNIKFL_TF_VON_NGF: 28.42,
    GB_VERKEHRSFL_VF_VON_NGF: 62.62,
    GB_MIETFL_GESAMT: 412.37,
    GB_MIETFL_1_BSB: 352.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992534,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.79,
    GB_NUTZFL_NF_VON_NGF: 399.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.84,
    GB_MIETFL_GESAMT: 454.79,
    GB_MIETFL_1_BSB: 454.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992535,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.79,
    GB_NUTZFL_NF_VON_NGF: 417.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.64,
    GB_MIETFL_GESAMT: 454.79,
    GB_MIETFL_1_BSB: 265.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992536,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 453.09,
    GB_NUTZFL_NF_VON_NGF: 415.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.64,
    GB_MIETFL_GESAMT: 453.09,
    GB_MIETFL_1_BSB: 227.21,
    GB_MIETFL_2_DRITTE: 225.88,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992537,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 187.83,
    GB_NUTZFL_NF_VON_NGF: 178.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 9.4,
    GB_MIETFL_GESAMT: 187.83,
    GB_MIETFL_1_BSB: 187.83,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992538,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.2,
    GB_NUTZFL_NF_VON_NGF: 220.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.94,
    GB_MIETFL_GESAMT: 239.2,
    GB_MIETFL_1_BSB: 239.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992539,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.12,
    GB_NUTZFL_NF_VON_NGF: 202.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.34,
    GB_MIETFL_GESAMT: 214.12,
    GB_MIETFL_1_BSB: 214.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992540,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/11',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 95.51,
    GB_NUTZFL_NF_VON_NGF: 84.39,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 7.49,
    GB_MIETFL_GESAMT: 95.51,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992541,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/12',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.39,
    GB_NUTZFL_NF_VON_NGF: 88.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 4.59,
    GB_MIETFL_GESAMT: 93.39,
    GB_MIETFL_1_BSB: 93.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992542,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 70.55,
    GB_NUTZFL_NF_VON_NGF: 19.1,
    GB_TECHNIKFL_TF_VON_NGF: 43.04,
    GB_VERKEHRSFL_VF_VON_NGF: 8.41,
    GB_MIETFL_GESAMT: 70.55,
    GB_MIETFL_1_BSB: 70.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992543,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/14',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 34.38,
    GB_NUTZFL_NF_VON_NGF: 34.38,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 34.38,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992544,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747030,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747030',
    GB_SAP_GB_ID_PKF: '7400/747030/15',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 20.0,
    GB_NUTZFL_NF_VON_NGF: 20.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 20.0,
    GB_MIETFL_1_BSB: 20.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: '9',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5351501,
    GB_WE_OBJ_ID_FKT: 1989447,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.454162919',
    GB_LAENGENGRAD_OESTLICH: '9.9740747677',
    GB_NETTOGRUNDFL_NGF: 1070.41,
    GB_NUTZFL_NF_VON_NGF: 623.87,
    GB_TECHNIKFL_TF_VON_NGF: 95.42,
    GB_VERKEHRSFL_VF_VON_NGF: 351.12,
    GB_MIETFL_GESAMT: 1070.41,
    GB_MIETFL_1_BSB: 818.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992346,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.453924',
    GB_LAENGENGRAD_OESTLICH: '9.973991',
    GB_NETTOGRUNDFL_NGF: 241.89,
    GB_NUTZFL_NF_VON_NGF: 184.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 57.27,
    GB_MIETFL_GESAMT: 241.89,
    GB_MIETFL_1_BSB: 241.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992347,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.453825',
    GB_LAENGENGRAD_OESTLICH: '9.973596',
    GB_NETTOGRUNDFL_NGF: 362.21,
    GB_NUTZFL_NF_VON_NGF: 283.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.0,
    GB_MIETFL_GESAMT: 362.21,
    GB_MIETFL_1_BSB: 362.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992348,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.453401',
    GB_LAENGENGRAD_OESTLICH: '9.972799',
    GB_NETTOGRUNDFL_NGF: 881.29,
    GB_NUTZFL_NF_VON_NGF: 695.87,
    GB_TECHNIKFL_TF_VON_NGF: 19.16,
    GB_VERKEHRSFL_VF_VON_NGF: 166.26,
    GB_MIETFL_GESAMT: 881.29,
    GB_MIETFL_1_BSB: 881.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992349,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/5',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.453660',
    GB_LAENGENGRAD_OESTLICH: '9.973115',
    GB_NETTOGRUNDFL_NGF: 398.08,
    GB_NUTZFL_NF_VON_NGF: 333.05,
    GB_TECHNIKFL_TF_VON_NGF: 18.6,
    GB_VERKEHRSFL_VF_VON_NGF: 46.43,
    GB_MIETFL_GESAMT: 398.08,
    GB_MIETFL_1_BSB: 398.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992350,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.04.22',
    GB_GB_OBJ_ID_PKT: 1992351,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.454008',
    GB_LAENGENGRAD_OESTLICH: '9.973402',
    GB_NETTOGRUNDFL_NGF: 361.52,
    GB_NUTZFL_NF_VON_NGF: 282.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 79.01,
    GB_MIETFL_GESAMT: 361.52,
    GB_MIETFL_1_BSB: 361.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992352,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.454334',
    GB_LAENGENGRAD_OESTLICH: '9.973506',
    GB_NETTOGRUNDFL_NGF: 362.24,
    GB_NUTZFL_NF_VON_NGF: 283.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 78.62,
    GB_MIETFL_GESAMT: 362.24,
    GB_MIETFL_1_BSB: 362.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992353,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.454320',
    GB_LAENGENGRAD_OESTLICH: '9.973863',
    GB_NETTOGRUNDFL_NGF: 351.97,
    GB_NUTZFL_NF_VON_NGF: 277.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 74.83,
    GB_MIETFL_GESAMT: 351.97,
    GB_MIETFL_1_BSB: 351.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992354,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7033164,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045108,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747003,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747003',
    GB_SAP_GB_ID_PKF: '7400/747003/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 182.7,
    GB_NUTZFL_NF_VON_NGF: 162.2,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 17.49,
    GB_MIETFL_GESAMT: 182.7,
    GB_MIETFL_1_BSB: 182.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036064,
    GB_WE_OBJ_ID_FKT: 1989422,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747004,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747004',
    GB_SAP_GB_ID_PKF: '7400/747004/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: '53.458846194',
    GB_LAENGENGRAD_OESTLICH: '9.9649093325',
    GB_NETTOGRUNDFL_NGF: 3303.01,
    GB_NUTZFL_NF_VON_NGF: 2361.06,
    GB_TECHNIKFL_TF_VON_NGF: 52.51,
    GB_VERKEHRSFL_VF_VON_NGF: 889.44,
    GB_MIETFL_GESAMT: 3303.01,
    GB_MIETFL_1_BSB: 2793.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992355,
    GB_WE_OBJ_ID_FKT: 1989423,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747004,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747004',
    GB_SAP_GB_ID_PKF: '7400/747004/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.459079400',
    GB_LAENGENGRAD_OESTLICH: '9.9647354402',
    GB_NETTOGRUNDFL_NGF: 255.14,
    GB_NUTZFL_NF_VON_NGF: 246.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.32,
    GB_MIETFL_GESAMT: 255.14,
    GB_MIETFL_1_BSB: 255.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992356,
    GB_WE_OBJ_ID_FKT: 1989423,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747004,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747004',
    GB_SAP_GB_ID_PKF: '7400/747004/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.458989',
    GB_LAENGENGRAD_OESTLICH: '9.964208',
    GB_NETTOGRUNDFL_NGF: 596.55,
    GB_NUTZFL_NF_VON_NGF: 565.88,
    GB_TECHNIKFL_TF_VON_NGF: 21.49,
    GB_VERKEHRSFL_VF_VON_NGF: 9.18,
    GB_MIETFL_GESAMT: 596.55,
    GB_MIETFL_1_BSB: 596.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992357,
    GB_WE_OBJ_ID_FKT: 1989423,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747004,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747004',
    GB_SAP_GB_ID_PKF: '7400/747004/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.0,
    GB_NUTZFL_NF_VON_NGF: 90.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552764,
    GB_WE_OBJ_ID_FKT: 1989423,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 577.29,
    GB_NUTZFL_NF_VON_NGF: 475.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 101.72,
    GB_MIETFL_GESAMT: 577.29,
    GB_MIETFL_1_BSB: 545.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992545,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 405.88,
    GB_NUTZFL_NF_VON_NGF: 405.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 405.88,
    GB_MIETFL_1_BSB: 405.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992546,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1957,
    GB_BREITENGRAD_NOERDLICH: '53.455609',
    GB_LAENGENGRAD_OESTLICH: '9.950673',
    GB_NETTOGRUNDFL_NGF: 594.43,
    GB_NUTZFL_NF_VON_NGF: 569.98,
    GB_TECHNIKFL_TF_VON_NGF: 14.91,
    GB_VERKEHRSFL_VF_VON_NGF: 9.54,
    GB_MIETFL_GESAMT: 594.43,
    GB_MIETFL_1_BSB: 594.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992547,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/4',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.456831',
    GB_LAENGENGRAD_OESTLICH: '9.951949',
    GB_NETTOGRUNDFL_NGF: 303.69,
    GB_NUTZFL_NF_VON_NGF: 285.84,
    GB_TECHNIKFL_TF_VON_NGF: 9.34,
    GB_VERKEHRSFL_VF_VON_NGF: 8.51,
    GB_MIETFL_GESAMT: 303.69,
    GB_MIETFL_1_BSB: 303.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992548,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1078.26,
    GB_NUTZFL_NF_VON_NGF: 788.74,
    GB_TECHNIKFL_TF_VON_NGF: 65.57,
    GB_VERKEHRSFL_VF_VON_NGF: 223.95,
    GB_MIETFL_GESAMT: 1078.26,
    GB_MIETFL_1_BSB: 931.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992549,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 319.61,
    GB_NUTZFL_NF_VON_NGF: 265.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 54.03,
    GB_MIETFL_GESAMT: 319.61,
    GB_MIETFL_1_BSB: 319.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992550,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 365.96,
    GB_NUTZFL_NF_VON_NGF: 365.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 365.96,
    GB_MIETFL_1_BSB: 365.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992551,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 363.77,
    GB_NUTZFL_NF_VON_NGF: 363.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 363.77,
    GB_MIETFL_1_BSB: 363.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992552,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793186,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1091.83,
    GB_NUTZFL_NF_VON_NGF: 950.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 140.86,
    GB_MIETFL_GESAMT: 1091.83,
    GB_MIETFL_1_BSB: 887.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992554,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793187,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/12',
    GB_SAP_GB_BEZEICHNUNG: 'WC Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'WC Geb?ude',
    GB_BAUJAHR: 1953,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.9,
    GB_NUTZFL_NF_VON_NGF: 73.88,
    GB_TECHNIKFL_TF_VON_NGF: 5.02,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 78.9,
    GB_MIETFL_1_BSB: 78.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992556,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1238.96,
    GB_NUTZFL_NF_VON_NGF: 944.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 294.5,
    GB_MIETFL_GESAMT: 1238.96,
    GB_MIETFL_1_BSB: 1231.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2032266,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 429.87,
    GB_NUTZFL_NF_VON_NGF: 335.35,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 94.52,
    GB_MIETFL_GESAMT: 429.87,
    GB_MIETFL_1_BSB: 429.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077065,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/15',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 139.56,
    GB_NUTZFL_NF_VON_NGF: 119.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.2,
    GB_MIETFL_GESAMT: 139.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077066,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7088394,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.53,
    GB_NUTZFL_NF_VON_NGF: 424.48,
    GB_TECHNIKFL_TF_VON_NGF: 4.82,
    GB_VERKEHRSFL_VF_VON_NGF: 40.23,
    GB_MIETFL_GESAMT: 469.53,
    GB_MIETFL_1_BSB: 469.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5490139,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 469.15,
    GB_NUTZFL_NF_VON_NGF: 424.48,
    GB_TECHNIKFL_TF_VON_NGF: 4.82,
    GB_VERKEHRSFL_VF_VON_NGF: 39.85,
    GB_MIETFL_GESAMT: 469.15,
    GB_MIETFL_1_BSB: 469.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7185164,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552768,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '22.09.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552769,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747031,
    GB_SAP_GB_NUMMER: 44,
    GB_SAP_WE_ID_FKF: '7400/747031',
    GB_SAP_GB_ID_PKF: '7400/747031/44',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '20.10.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552770,
    GB_WE_OBJ_ID_FKT: 1989448,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.448753812',
    GB_LAENGENGRAD_OESTLICH: '9.9358235971',
    GB_NETTOGRUNDFL_NGF: 803.42,
    GB_NUTZFL_NF_VON_NGF: 655.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 147.55,
    GB_MIETFL_GESAMT: 803.42,
    GB_MIETFL_1_BSB: 535.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992628,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.449099695',
    GB_LAENGENGRAD_OESTLICH: '9.9354235289',
    GB_NETTOGRUNDFL_NGF: 148.35,
    GB_NUTZFL_NF_VON_NGF: 114.56,
    GB_TECHNIKFL_TF_VON_NGF: 6.8,
    GB_VERKEHRSFL_VF_VON_NGF: 26.99,
    GB_MIETFL_GESAMT: 148.35,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992629,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.448510313',
    GB_LAENGENGRAD_OESTLICH: '9.9365849641',
    GB_NETTOGRUNDFL_NGF: 220.11,
    GB_NUTZFL_NF_VON_NGF: 192.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.17,
    GB_MIETFL_GESAMT: 220.11,
    GB_MIETFL_1_BSB: 220.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992630,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.448494042',
    GB_LAENGENGRAD_OESTLICH: '9.9371579818',
    GB_NETTOGRUNDFL_NGF: 219.02,
    GB_NUTZFL_NF_VON_NGF: 191.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 27.18,
    GB_MIETFL_GESAMT: 219.02,
    GB_MIETFL_1_BSB: 219.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992631,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.448279598',
    GB_LAENGENGRAD_OESTLICH: '9.9365305523',
    GB_NETTOGRUNDFL_NGF: 217.89,
    GB_NUTZFL_NF_VON_NGF: 180.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.64,
    GB_MIETFL_GESAMT: 217.89,
    GB_MIETFL_1_BSB: 217.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992632,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.448248574',
    GB_LAENGENGRAD_OESTLICH: '9.9370872499',
    GB_NETTOGRUNDFL_NGF: 217.12,
    GB_NUTZFL_NF_VON_NGF: 181.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.13,
    GB_MIETFL_GESAMT: 217.12,
    GB_MIETFL_1_BSB: 217.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992633,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.448805567',
    GB_LAENGENGRAD_OESTLICH: '9.9363727411',
    GB_NETTOGRUNDFL_NGF: 435.61,
    GB_NUTZFL_NF_VON_NGF: 381.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.99,
    GB_MIETFL_GESAMT: 435.61,
    GB_MIETFL_1_BSB: 435.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992634,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/8',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: '53.448972',
    GB_LAENGENGRAD_OESTLICH: '9.937109',
    GB_NETTOGRUNDFL_NGF: 623.81,
    GB_NUTZFL_NF_VON_NGF: 601.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.01,
    GB_MIETFL_GESAMT: 623.81,
    GB_MIETFL_1_BSB: 623.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992635,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.448778229',
    GB_LAENGENGRAD_OESTLICH: '9.9367530734',
    GB_NETTOGRUNDFL_NGF: 435.61,
    GB_NUTZFL_NF_VON_NGF: 381.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.99,
    GB_MIETFL_GESAMT: 435.61,
    GB_MIETFL_1_BSB: 435.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992636,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.448726489',
    GB_LAENGENGRAD_OESTLICH: '9.9371541469',
    GB_NETTOGRUNDFL_NGF: 435.58,
    GB_NUTZFL_NF_VON_NGF: 381.59,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 53.99,
    GB_MIETFL_GESAMT: 435.58,
    GB_MIETFL_1_BSB: 435.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992637,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 419.85,
    GB_NUTZFL_NF_VON_NGF: 368.37,
    GB_TECHNIKFL_TF_VON_NGF: 7.47,
    GB_VERKEHRSFL_VF_VON_NGF: 44.01,
    GB_MIETFL_GESAMT: 419.85,
    GB_MIETFL_1_BSB: 419.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.08',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992638,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/12',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.448282',
    GB_LAENGENGRAD_OESTLICH: '9.938245',
    GB_NETTOGRUNDFL_NGF: 3255.4,
    GB_NUTZFL_NF_VON_NGF: 2512.98,
    GB_TECHNIKFL_TF_VON_NGF: 51.49,
    GB_VERKEHRSFL_VF_VON_NGF: 690.93,
    GB_MIETFL_GESAMT: 3255.4,
    GB_MIETFL_1_BSB: 3249.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.11.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992639,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.01.17',
    GB_GB_OBJ_ID_PKT: 2032267,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7046161,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 177.64,
    GB_NUTZFL_NF_VON_NGF: 149.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.02,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 4352791,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747042,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7400/747042',
    GB_SAP_GB_ID_PKF: '7400/747042/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 94.02,
    GB_NUTZFL_NF_VON_NGF: 81.8,
    GB_TECHNIKFL_TF_VON_NGF: 2.69,
    GB_VERKEHRSFL_VF_VON_NGF: 9.53,
    GB_MIETFL_GESAMT: 94.02,
    GB_MIETFL_1_BSB: 94.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365934,
    GB_WE_OBJ_ID_FKT: 1989458,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747032,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747032',
    GB_SAP_GB_ID_PKF: '7400/747032/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1901,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1402.15,
    GB_NUTZFL_NF_VON_NGF: 1001.81,
    GB_TECHNIKFL_TF_VON_NGF: 31.08,
    GB_VERKEHRSFL_VF_VON_NGF: 369.26,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992557,
    GB_WE_OBJ_ID_FKT: 1989449,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747032,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747032',
    GB_SAP_GB_ID_PKF: '7400/747032/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 67.74,
    GB_NUTZFL_NF_VON_NGF: 67.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992558,
    GB_WE_OBJ_ID_FKT: 1989449,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 411.77,
    GB_NUTZFL_NF_VON_NGF: 342.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 69.59,
    GB_MIETFL_GESAMT: 411.77,
    GB_MIETFL_1_BSB: 411.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992615,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 271.47,
    GB_NUTZFL_NF_VON_NGF: 207.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 64.38,
    GB_MIETFL_GESAMT: 271.47,
    GB_MIETFL_1_BSB: 271.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992619,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456962043',
    GB_LAENGENGRAD_OESTLICH: '9.9440621659',
    GB_NETTOGRUNDFL_NGF: 830.3,
    GB_NUTZFL_NF_VON_NGF: 693.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 137.27,
    GB_MIETFL_GESAMT: 830.3,
    GB_MIETFL_1_BSB: 830.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992614,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456441',
    GB_LAENGENGRAD_OESTLICH: '9.944946',
    GB_NETTOGRUNDFL_NGF: 529.33,
    GB_NUTZFL_NF_VON_NGF: 514.95,
    GB_TECHNIKFL_TF_VON_NGF: 6.46,
    GB_VERKEHRSFL_VF_VON_NGF: 7.92,
    GB_MIETFL_GESAMT: 529.33,
    GB_MIETFL_1_BSB: 529.33,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992616,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456128',
    GB_LAENGENGRAD_OESTLICH: '9.945138',
    GB_NETTOGRUNDFL_NGF: 656.1,
    GB_NUTZFL_NF_VON_NGF: 609.84,
    GB_TECHNIKFL_TF_VON_NGF: 17.16,
    GB_VERKEHRSFL_VF_VON_NGF: 29.1,
    GB_MIETFL_GESAMT: 656.1,
    GB_MIETFL_1_BSB: 656.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992617,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456435',
    GB_LAENGENGRAD_OESTLICH: '9.944114',
    GB_NETTOGRUNDFL_NGF: 3127.34,
    GB_NUTZFL_NF_VON_NGF: 2302.2,
    GB_TECHNIKFL_TF_VON_NGF: 59.76,
    GB_VERKEHRSFL_VF_VON_NGF: 765.38,
    GB_MIETFL_GESAMT: 3127.34,
    GB_MIETFL_1_BSB: 2853.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992618,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456718',
    GB_LAENGENGRAD_OESTLICH: '9.943281',
    GB_NETTOGRUNDFL_NGF: 1165.44,
    GB_NUTZFL_NF_VON_NGF: 943.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 221.92,
    GB_MIETFL_GESAMT: 1165.44,
    GB_MIETFL_1_BSB: 1165.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992620,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.456346',
    GB_LAENGENGRAD_OESTLICH: '9.943473',
    GB_NETTOGRUNDFL_NGF: 2311.71,
    GB_NUTZFL_NF_VON_NGF: 1685.57,
    GB_TECHNIKFL_TF_VON_NGF: 80.13,
    GB_VERKEHRSFL_VF_VON_NGF: 546.01,
    GB_MIETFL_GESAMT: 2311.71,
    GB_MIETFL_1_BSB: 1853.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992621,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 146.85,
    GB_NUTZFL_NF_VON_NGF: 95.07,
    GB_TECHNIKFL_TF_VON_NGF: 21.22,
    GB_VERKEHRSFL_VF_VON_NGF: 30.56,
    GB_MIETFL_GESAMT: 146.85,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992622,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747040,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747040',
    GB_SAP_GB_ID_PKF: '7400/747040/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045111,
    GB_WE_OBJ_ID_FKT: 1989456,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747063,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747063',
    GB_SAP_GB_ID_PKF: '7400/747063/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.11',
    GB_GB_OBJ_ID_PKT: 1992826,
    GB_WE_OBJ_ID_FKT: 1989477,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747063,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747063',
    GB_SAP_GB_ID_PKF: '7400/747063/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.452773',
    GB_LAENGENGRAD_OESTLICH: '9.980701',
    GB_NETTOGRUNDFL_NGF: 1935.44,
    GB_NUTZFL_NF_VON_NGF: 1733.41,
    GB_TECHNIKFL_TF_VON_NGF: 17.03,
    GB_VERKEHRSFL_VF_VON_NGF: 185.0,
    GB_MIETFL_GESAMT: 1935.44,
    GB_MIETFL_1_BSB: 1935.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793229,
    GB_WE_OBJ_ID_FKT: 1989477,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747064,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747064',
    GB_SAP_GB_ID_PKF: '7400/747064/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1920,
    GB_BREITENGRAD_NOERDLICH: '53.456266',
    GB_LAENGENGRAD_OESTLICH: '9.983104',
    GB_NETTOGRUNDFL_NGF: 552.41,
    GB_NUTZFL_NF_VON_NGF: 450.24,
    GB_TECHNIKFL_TF_VON_NGF: 83.19,
    GB_VERKEHRSFL_VF_VON_NGF: 18.98,
    GB_MIETFL_GESAMT: 552.41,
    GB_MIETFL_1_BSB: 479.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992827,
    GB_WE_OBJ_ID_FKT: 1989478,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747064,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747064',
    GB_SAP_GB_ID_PKF: '7400/747064/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1575.93,
    GB_NUTZFL_NF_VON_NGF: 1235.55,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 340.38,
    GB_MIETFL_GESAMT: 1575.93,
    GB_MIETFL_1_BSB: 1102.37,
    GB_MIETFL_2_DRITTE: 67.68,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992828,
    GB_WE_OBJ_ID_FKT: 1989478,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747064,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747064',
    GB_SAP_GB_ID_PKF: '7400/747064/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1904,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4827.81,
    GB_NUTZFL_NF_VON_NGF: 3541.96,
    GB_TECHNIKFL_TF_VON_NGF: 59.14,
    GB_VERKEHRSFL_VF_VON_NGF: 1226.71,
    GB_MIETFL_GESAMT: 4827.81,
    GB_MIETFL_1_BSB: 3820.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992829,
    GB_WE_OBJ_ID_FKT: 1989478,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.460476484',
    GB_LAENGENGRAD_OESTLICH: '9.9744127558',
    GB_NETTOGRUNDFL_NGF: 1539.39,
    GB_NUTZFL_NF_VON_NGF: 1327.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 212.23,
    GB_MIETFL_GESAMT: 1539.39,
    GB_MIETFL_1_BSB: 1539.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992606,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.460365381',
    GB_LAENGENGRAD_OESTLICH: '9.9742707161',
    GB_NETTOGRUNDFL_NGF: 214.71,
    GB_NUTZFL_NF_VON_NGF: 106.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 108.0,
    GB_MIETFL_GESAMT: 214.71,
    GB_MIETFL_1_BSB: 214.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992607,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.460969',
    GB_LAENGENGRAD_OESTLICH: '9.973630',
    GB_NETTOGRUNDFL_NGF: 1717.93,
    GB_NUTZFL_NF_VON_NGF: 1392.06,
    GB_TECHNIKFL_TF_VON_NGF: 50.78,
    GB_VERKEHRSFL_VF_VON_NGF: 275.09,
    GB_MIETFL_GESAMT: 1717.93,
    GB_MIETFL_1_BSB: 1358.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992608,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: '53.460678',
    GB_LAENGENGRAD_OESTLICH: '9.973707',
    GB_NETTOGRUNDFL_NGF: 1304.05,
    GB_NUTZFL_NF_VON_NGF: 1023.86,
    GB_TECHNIKFL_TF_VON_NGF: 42.9,
    GB_VERKEHRSFL_VF_VON_NGF: 237.29,
    GB_MIETFL_GESAMT: 1304.05,
    GB_MIETFL_1_BSB: 1211.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992609,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7101622,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 395.65,
    GB_NUTZFL_NF_VON_NGF: 327.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.43,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337412,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 338.02,
    GB_NUTZFL_NF_VON_NGF: 292.23,
    GB_TECHNIKFL_TF_VON_NGF: 4.78,
    GB_VERKEHRSFL_VF_VON_NGF: 41.01,
    GB_MIETFL_GESAMT: 319.62,
    GB_MIETFL_1_BSB: 319.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462196,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.460560720',
    GB_LAENGENGRAD_OESTLICH: '9.9749188687',
    GB_NETTOGRUNDFL_NGF: 1520.95,
    GB_NUTZFL_NF_VON_NGF: 1204.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 316.86,
    GB_MIETFL_GESAMT: 1520.95,
    GB_MIETFL_1_BSB: 1477.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992610,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.460317474',
    GB_LAENGENGRAD_OESTLICH: '9.9738146142',
    GB_NETTOGRUNDFL_NGF: 3892.44,
    GB_NUTZFL_NF_VON_NGF: 2772.55,
    GB_TECHNIKFL_TF_VON_NGF: 98.74,
    GB_VERKEHRSFL_VF_VON_NGF: 1021.15,
    GB_MIETFL_GESAMT: 3892.44,
    GB_MIETFL_1_BSB: 3469.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992611,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1894,
    GB_BREITENGRAD_NOERDLICH: '53.461233405',
    GB_LAENGENGRAD_OESTLICH: '9.9747105878',
    GB_NETTOGRUNDFL_NGF: 5898.85,
    GB_NUTZFL_NF_VON_NGF: 4386.18,
    GB_TECHNIKFL_TF_VON_NGF: 125.22,
    GB_VERKEHRSFL_VF_VON_NGF: 1387.45,
    GB_MIETFL_GESAMT: 5898.85,
    GB_MIETFL_1_BSB: 5182.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992612,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.461065',
    GB_LAENGENGRAD_OESTLICH: '9.974121',
    GB_NETTOGRUNDFL_NGF: 1660.2,
    GB_NUTZFL_NF_VON_NGF: 1104.08,
    GB_TECHNIKFL_TF_VON_NGF: 52.72,
    GB_VERKEHRSFL_VF_VON_NGF: 503.4,
    GB_MIETFL_GESAMT: 1660.2,
    GB_MIETFL_1_BSB: 1556.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992613,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5425082,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1895,
    GB_BREITENGRAD_NOERDLICH: '53.461810583',
    GB_LAENGENGRAD_OESTLICH: '9.9736984108',
    GB_NETTOGRUNDFL_NGF: 2851.78,
    GB_NUTZFL_NF_VON_NGF: 2043.13,
    GB_TECHNIKFL_TF_VON_NGF: 89.06,
    GB_VERKEHRSFL_VF_VON_NGF: 719.59,
    GB_MIETFL_GESAMT: 2851.78,
    GB_MIETFL_1_BSB: 2035.48,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '02.08.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5488377,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 6793189,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.17',
    GB_GB_OBJ_ID_PKT: 6793190,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747039,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747039',
    GB_SAP_GB_ID_PKF: '7400/747039/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: '53.461585993',
    GB_LAENGENGRAD_OESTLICH: '9.9733215515',
    GB_NETTOGRUNDFL_NGF: 1718.6,
    GB_NUTZFL_NF_VON_NGF: 1327.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 391.15,
    GB_MIETFL_GESAMT: 1718.6,
    GB_MIETFL_1_BSB: 1393.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '02.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793191,
    GB_WE_OBJ_ID_FKT: 1989455,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747012,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747012',
    GB_SAP_GB_ID_PKF: '7400/747012/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992423,
    GB_WE_OBJ_ID_FKT: 1989431,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747074,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747074',
    GB_SAP_GB_ID_PKF: '7400/747074/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6877868,
    GB_WE_OBJ_ID_FKT: 6844734,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 7458.12,
    GB_NUTZFL_NF_VON_NGF: 5496.94,
    GB_TECHNIKFL_TF_VON_NGF: 162.69,
    GB_VERKEHRSFL_VF_VON_NGF: 1798.49,
    GB_MIETFL_GESAMT: 7458.12,
    GB_MIETFL_1_BSB: 6759.85,
    GB_MIETFL_2_DRITTE: 307.96,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992692,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.452773',
    GB_LAENGENGRAD_OESTLICH: '9.980701',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992693,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 842.39,
    GB_NUTZFL_NF_VON_NGF: 616.71,
    GB_TECHNIKFL_TF_VON_NGF: 15.51,
    GB_VERKEHRSFL_VF_VON_NGF: 210.17,
    GB_MIETFL_GESAMT: 842.39,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 842.39,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992694,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 504.62,
    GB_NUTZFL_NF_VON_NGF: 366.87,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 137.75,
    GB_MIETFL_GESAMT: 504.62,
    GB_MIETFL_1_BSB: 504.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992695,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 508.77,
    GB_NUTZFL_NF_VON_NGF: 387.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 121.51,
    GB_MIETFL_GESAMT: 508.77,
    GB_MIETFL_1_BSB: 508.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992696,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 664.53,
    GB_NUTZFL_NF_VON_NGF: 461.53,
    GB_TECHNIKFL_TF_VON_NGF: 57.62,
    GB_VERKEHRSFL_VF_VON_NGF: 145.38,
    GB_MIETFL_GESAMT: 664.53,
    GB_MIETFL_1_BSB: 467.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992697,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793201,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7033165,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1992,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.17,
    GB_NUTZFL_NF_VON_NGF: 351.22,
    GB_TECHNIKFL_TF_VON_NGF: 3.57,
    GB_VERKEHRSFL_VF_VON_NGF: 99.38,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '27.08.21',
    GB_GB_OBJ_ID_PKT: 1992404,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Bunker',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 1945,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 70.77,
    GB_NUTZFL_NF_VON_NGF: 70.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.12.21',
    GB_GB_OBJ_ID_PKT: 4352786,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747048,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747048',
    GB_SAP_GB_ID_PKF: '7400/747048/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.88,
    GB_NUTZFL_NF_VON_NGF: 74.26,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 16.61,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.11.18',
    GB_GB_OBJ_ID_PKT: 5490623,
    GB_WE_OBJ_ID_FKT: 1989464,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747009,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747009',
    GB_SAP_GB_ID_PKF: '7400/747009/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1902,
    GB_BREITENGRAD_NOERDLICH: '53.462676',
    GB_LAENGENGRAD_OESTLICH: '9.976132',
    GB_NETTOGRUNDFL_NGF: 5561.88,
    GB_NUTZFL_NF_VON_NGF: 4139.22,
    GB_TECHNIKFL_TF_VON_NGF: 305.83,
    GB_VERKEHRSFL_VF_VON_NGF: 1116.83,
    GB_MIETFL_GESAMT: 5561.88,
    GB_MIETFL_1_BSB: 4271.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.07.23',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992403,
    GB_WE_OBJ_ID_FKT: 1989428,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1990,
    GB_BREITENGRAD_NOERDLICH: '53.463507',
    GB_LAENGENGRAD_OESTLICH: '9.974424',
    GB_NETTOGRUNDFL_NGF: 1498.74,
    GB_NUTZFL_NF_VON_NGF: 1041.19,
    GB_TECHNIKFL_TF_VON_NGF: 59.53,
    GB_VERKEHRSFL_VF_VON_NGF: 398.02,
    GB_MIETFL_GESAMT: 1498.74,
    GB_MIETFL_1_BSB: 1458.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992456,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.463624',
    GB_LAENGENGRAD_OESTLICH: '9.974774',
    GB_NETTOGRUNDFL_NGF: 164.28,
    GB_NUTZFL_NF_VON_NGF: 142.01,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 22.27,
    GB_MIETFL_GESAMT: 164.28,
    GB_MIETFL_1_BSB: 164.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992457,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/3',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: '53.463424',
    GB_LAENGENGRAD_OESTLICH: '9.975116',
    GB_NETTOGRUNDFL_NGF: 250.03,
    GB_NUTZFL_NF_VON_NGF: 226.87,
    GB_TECHNIKFL_TF_VON_NGF: 5.63,
    GB_VERKEHRSFL_VF_VON_NGF: 17.53,
    GB_MIETFL_GESAMT: 250.03,
    GB_MIETFL_1_BSB: 250.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992458,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.463205',
    GB_LAENGENGRAD_OESTLICH: '9.975371',
    GB_NETTOGRUNDFL_NGF: 863.59,
    GB_NUTZFL_NF_VON_NGF: 647.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 216.2,
    GB_MIETFL_GESAMT: 863.59,
    GB_MIETFL_1_BSB: 659.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '28.09.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992459,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: '53.463028',
    GB_LAENGENGRAD_OESTLICH: '9.974797',
    GB_NETTOGRUNDFL_NGF: 1476.55,
    GB_NUTZFL_NF_VON_NGF: 1099.43,
    GB_TECHNIKFL_TF_VON_NGF: 34.08,
    GB_VERKEHRSFL_VF_VON_NGF: 343.04,
    GB_MIETFL_GESAMT: 1476.55,
    GB_MIETFL_1_BSB: 1395.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.05',
    GB_GEBAEUDE_KLASSE_ALT: '1',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992460,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793175,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793176,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: 'Garage / Lager / Bunker',
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 14.47,
    GB_NUTZFL_NF_VON_NGF: 14.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 14.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992463,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747019,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747019',
    GB_SAP_GB_ID_PKF: '7400/747019/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: '9.9742582853',
    GB_LAENGENGRAD_OESTLICH: '53.463267064',
    GB_NETTOGRUNDFL_NGF: 765.14,
    GB_NUTZFL_NF_VON_NGF: 594.08,
    GB_TECHNIKFL_TF_VON_NGF: 3.04,
    GB_VERKEHRSFL_VF_VON_NGF: 168.02,
    GB_MIETFL_GESAMT: 765.14,
    GB_MIETFL_1_BSB: 762.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5892621,
    GB_WE_OBJ_ID_FKT: 1989437,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1911,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 968.04,
    GB_NUTZFL_NF_VON_NGF: 740.38,
    GB_TECHNIKFL_TF_VON_NGF: 47.79,
    GB_VERKEHRSFL_VF_VON_NGF: 179.87,
    GB_MIETFL_GESAMT: 968.04,
    GB_MIETFL_1_BSB: 560.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992405,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.474634',
    GB_LAENGENGRAD_OESTLICH: '9.893243',
    GB_NETTOGRUNDFL_NGF: 622.87,
    GB_NUTZFL_NF_VON_NGF: 587.92,
    GB_TECHNIKFL_TF_VON_NGF: 12.8,
    GB_VERKEHRSFL_VF_VON_NGF: 22.15,
    GB_MIETFL_GESAMT: 622.87,
    GB_MIETFL_1_BSB: 622.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992406,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 477.64,
    GB_NUTZFL_NF_VON_NGF: 412.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.38,
    GB_MIETFL_GESAMT: 477.64,
    GB_MIETFL_1_BSB: 477.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992407,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 192.35,
    GB_NUTZFL_NF_VON_NGF: 175.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.37,
    GB_MIETFL_GESAMT: 192.35,
    GB_MIETFL_1_BSB: 192.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992408,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 483.22,
    GB_NUTZFL_NF_VON_NGF: 425.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.09,
    GB_MIETFL_GESAMT: 483.22,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992409,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 462.87,
    GB_NUTZFL_NF_VON_NGF: 360.4,
    GB_TECHNIKFL_TF_VON_NGF: 4.62,
    GB_VERKEHRSFL_VF_VON_NGF: 97.85,
    GB_MIETFL_GESAMT: 462.87,
    GB_MIETFL_1_BSB: 462.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992410,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 203.26,
    GB_NUTZFL_NF_VON_NGF: 165.7,
    GB_TECHNIKFL_TF_VON_NGF: 4.4,
    GB_VERKEHRSFL_VF_VON_NGF: 33.16,
    GB_MIETFL_GESAMT: 203.26,
    GB_MIETFL_1_BSB: 203.26,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992411,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 487.21,
    GB_NUTZFL_NF_VON_NGF: 398.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.08,
    GB_MIETFL_GESAMT: 487.21,
    GB_MIETFL_1_BSB: 484.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992412,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: '53.473800',
    GB_LAENGENGRAD_OESTLICH: '9.893717',
    GB_NETTOGRUNDFL_NGF: 634.37,
    GB_NUTZFL_NF_VON_NGF: 572.05,
    GB_TECHNIKFL_TF_VON_NGF: 7.92,
    GB_VERKEHRSFL_VF_VON_NGF: 54.4,
    GB_MIETFL_GESAMT: 634.37,
    GB_MIETFL_1_BSB: 423.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992413,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/10',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 27.2,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: 27.2,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 27.2,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992414,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 483.04,
    GB_NUTZFL_NF_VON_NGF: 424.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.09,
    GB_MIETFL_GESAMT: 483.04,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078236,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/12',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.49,
    GB_NUTZFL_NF_VON_NGF: 13.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.49,
    GB_MIETFL_1_BSB: 13.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138317,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/13',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 10.56,
    GB_NUTZFL_NF_VON_NGF: 10.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 10.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138318,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/14',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 15.52,
    GB_NUTZFL_NF_VON_NGF: 15.52,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 15.52,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138319,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7379455,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/16',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7525638,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747010,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747010',
    GB_SAP_GB_ID_PKF: '7400/747010/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 383.72,
    GB_NUTZFL_NF_VON_NGF: 298.38,
    GB_TECHNIKFL_TF_VON_NGF: 6.64,
    GB_VERKEHRSFL_VF_VON_NGF: 78.7,
    GB_MIETFL_GESAMT: 383.72,
    GB_MIETFL_1_BSB: 383.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462197,
    GB_WE_OBJ_ID_FKT: 1989429,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 815.03,
    GB_NUTZFL_NF_VON_NGF: 649.97,
    GB_TECHNIKFL_TF_VON_NGF: 39.15,
    GB_VERKEHRSFL_VF_VON_NGF: 125.91,
    GB_MIETFL_GESAMT: 815.03,
    GB_MIETFL_1_BSB: 712.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.07.21',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992679,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 150.31,
    GB_NUTZFL_NF_VON_NGF: 127.85,
    GB_TECHNIKFL_TF_VON_NGF: 6.51,
    GB_VERKEHRSFL_VF_VON_NGF: 15.95,
    GB_MIETFL_GESAMT: 150.31,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992680,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793196,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793197,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793198,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793199,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.49,
    GB_NUTZFL_NF_VON_NGF: 392.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.51,
    GB_MIETFL_GESAMT: 439.49,
    GB_MIETFL_1_BSB: 439.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992685,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.476256',
    GB_LAENGENGRAD_OESTLICH: '9.885643',
    GB_NETTOGRUNDFL_NGF: 625.27,
    GB_NUTZFL_NF_VON_NGF: 596.33,
    GB_TECHNIKFL_TF_VON_NGF: 6.68,
    GB_VERKEHRSFL_VF_VON_NGF: 22.26,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '07.02.20',
    GB_GB_OBJ_ID_PKT: 1992686,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 216.34,
    GB_NUTZFL_NF_VON_NGF: 183.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.39,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.18',
    GB_GB_OBJ_ID_PKT: 1992687,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793200,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.12,
    GB_NUTZFL_NF_VON_NGF: 393.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.32,
    GB_MIETFL_GESAMT: 441.12,
    GB_MIETFL_1_BSB: 441.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992689,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 439.71,
    GB_NUTZFL_NF_VON_NGF: 392.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 47.08,
    GB_MIETFL_GESAMT: 439.71,
    GB_MIETFL_1_BSB: 439.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.08.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992690,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 174.13,
    GB_NUTZFL_NF_VON_NGF: 147.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.18',
    GB_GB_OBJ_ID_PKT: 1992691,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/15',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2569.28,
    GB_NUTZFL_NF_VON_NGF: 1614.26,
    GB_TECHNIKFL_TF_VON_NGF: 156.07,
    GB_VERKEHRSFL_VF_VON_NGF: 798.95,
    GB_MIETFL_GESAMT: 2569.28,
    GB_MIETFL_1_BSB: 2566.65,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5423855,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: '53.476335',
    GB_LAENGENGRAD_OESTLICH: '9.884567',
    GB_NETTOGRUNDFL_NGF: 1313.72,
    GB_NUTZFL_NF_VON_NGF: 1262.27,
    GB_TECHNIKFL_TF_VON_NGF: 14.97,
    GB_VERKEHRSFL_VF_VON_NGF: 36.48,
    GB_MIETFL_GESAMT: 1313.72,
    GB_MIETFL_1_BSB: 1313.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.06.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078150,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.476253',
    GB_LAENGENGRAD_OESTLICH: '9.885702',
    GB_NETTOGRUNDFL_NGF: 2374.0,
    GB_NUTZFL_NF_VON_NGF: 2089.96,
    GB_TECHNIKFL_TF_VON_NGF: 60.92,
    GB_VERKEHRSFL_VF_VON_NGF: 223.12,
    GB_MIETFL_GESAMT: 2374.0,
    GB_MIETFL_1_BSB: 2370.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5507182,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/17',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045113,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747047,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747047',
    GB_SAP_GB_ID_PKF: '7400/747047/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1036.58,
    GB_NUTZFL_NF_VON_NGF: 870.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 166.26,
    GB_MIETFL_GESAMT: 1036.58,
    GB_MIETFL_1_BSB: 1036.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5351504,
    GB_WE_OBJ_ID_FKT: 1989463,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.466208715',
    GB_LAENGENGRAD_OESTLICH: '9.9647916857',
    GB_NETTOGRUNDFL_NGF: 1121.91,
    GB_NUTZFL_NF_VON_NGF: 703.17,
    GB_TECHNIKFL_TF_VON_NGF: 137.06,
    GB_VERKEHRSFL_VF_VON_NGF: 281.68,
    GB_MIETFL_GESAMT: 1121.91,
    GB_MIETFL_1_BSB: 984.85,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '05.11.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992597,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.465603',
    GB_LAENGENGRAD_OESTLICH: '9.966063',
    GB_NETTOGRUNDFL_NGF: 660.56,
    GB_NUTZFL_NF_VON_NGF: 604.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.67,
    GB_MIETFL_GESAMT: 660.56,
    GB_MIETFL_1_BSB: 660.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992598,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.465792569',
    GB_LAENGENGRAD_OESTLICH: '9.9652586530',
    GB_NETTOGRUNDFL_NGF: 4728.33,
    GB_NUTZFL_NF_VON_NGF: 3271.01,
    GB_TECHNIKFL_TF_VON_NGF: 265.65,
    GB_VERKEHRSFL_VF_VON_NGF: 1191.67,
    GB_MIETFL_GESAMT: 4728.33,
    GB_MIETFL_1_BSB: 3923.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992599,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.465804248',
    GB_LAENGENGRAD_OESTLICH: '9.9645025637',
    GB_NETTOGRUNDFL_NGF: 5937.42,
    GB_NUTZFL_NF_VON_NGF: 4150.73,
    GB_TECHNIKFL_TF_VON_NGF: 74.77,
    GB_VERKEHRSFL_VF_VON_NGF: 1711.92,
    GB_MIETFL_GESAMT: 5937.42,
    GB_MIETFL_1_BSB: 5777.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '30.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992600,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.465315243',
    GB_LAENGENGRAD_OESTLICH: '9.9654471926',
    GB_NETTOGRUNDFL_NGF: 5997.67,
    GB_NUTZFL_NF_VON_NGF: 4154.98,
    GB_TECHNIKFL_TF_VON_NGF: 83.84,
    GB_VERKEHRSFL_VF_VON_NGF: 1758.85,
    GB_MIETFL_GESAMT: 5997.67,
    GB_MIETFL_1_BSB: 5630.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992601,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/6',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992602,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.466278813',
    GB_LAENGENGRAD_OESTLICH: '9.9662580268',
    GB_NETTOGRUNDFL_NGF: 451.56,
    GB_NUTZFL_NF_VON_NGF: 351.8,
    GB_TECHNIKFL_TF_VON_NGF: 3.95,
    GB_VERKEHRSFL_VF_VON_NGF: 95.81,
    GB_MIETFL_GESAMT: 451.56,
    GB_MIETFL_1_BSB: 451.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992603,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: '53.466072',
    GB_LAENGENGRAD_OESTLICH: '9.966558',
    GB_NETTOGRUNDFL_NGF: 651.69,
    GB_NUTZFL_NF_VON_NGF: 503.89,
    GB_TECHNIKFL_TF_VON_NGF: 7.74,
    GB_VERKEHRSFL_VF_VON_NGF: 140.06,
    GB_MIETFL_GESAMT: 651.69,
    GB_MIETFL_1_BSB: 651.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992604,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.465433',
    GB_LAENGENGRAD_OESTLICH: '9.963828',
    GB_NETTOGRUNDFL_NGF: 551.58,
    GB_NUTZFL_NF_VON_NGF: 473.63,
    GB_TECHNIKFL_TF_VON_NGF: 12.85,
    GB_VERKEHRSFL_VF_VON_NGF: 65.1,
    GB_MIETFL_GESAMT: 551.58,
    GB_MIETFL_1_BSB: 403.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992605,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747037,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747037',
    GB_SAP_GB_ID_PKF: '7400/747037/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: '53.465752',
    GB_LAENGENGRAD_OESTLICH: '9.966952',
    GB_NETTOGRUNDFL_NGF: 659.93,
    GB_NUTZFL_NF_VON_NGF: 629.67,
    GB_TECHNIKFL_TF_VON_NGF: 17.27,
    GB_VERKEHRSFL_VF_VON_NGF: 12.99,
    GB_MIETFL_GESAMT: 659.93,
    GB_MIETFL_1_BSB: 659.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.11.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5427886,
    GB_WE_OBJ_ID_FKT: 1989454,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747038,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747038',
    GB_SAP_GB_ID_PKF: '7400/747038/11',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6879407,
    GB_WE_OBJ_ID_FKT: 6793123,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747073,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747073',
    GB_SAP_GB_ID_PKF: '7400/747073/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6877867,
    GB_WE_OBJ_ID_FKT: 6844733,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.476236585',
    GB_LAENGENGRAD_OESTLICH: '9.8543171669',
    GB_NETTOGRUNDFL_NGF: 5664.61,
    GB_NUTZFL_NF_VON_NGF: 3940.06,
    GB_TECHNIKFL_TF_VON_NGF: 100.71,
    GB_VERKEHRSFL_VF_VON_NGF: 1623.84,
    GB_MIETFL_GESAMT: 5664.61,
    GB_MIETFL_1_BSB: 5655.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2057893,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/2',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.476179117',
    GB_LAENGENGRAD_OESTLICH: '9.8555509831',
    GB_NETTOGRUNDFL_NGF: 1403.05,
    GB_NUTZFL_NF_VON_NGF: 1064.13,
    GB_TECHNIKFL_TF_VON_NGF: 5.53,
    GB_VERKEHRSFL_VF_VON_NGF: 333.39,
    GB_MIETFL_GESAMT: 1403.05,
    GB_MIETFL_1_BSB: 84.16,
    GB_MIETFL_2_DRITTE: 1288.94,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2057894,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.475250',
    GB_LAENGENGRAD_OESTLICH: '9.855129',
    GB_NETTOGRUNDFL_NGF: 4396.24,
    GB_NUTZFL_NF_VON_NGF: 3176.04,
    GB_TECHNIKFL_TF_VON_NGF: 244.86,
    GB_VERKEHRSFL_VF_VON_NGF: 975.34,
    GB_MIETFL_GESAMT: 4396.24,
    GB_MIETFL_1_BSB: 4391.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2057895,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.28,
    GB_NUTZFL_NF_VON_NGF: 218.44,
    GB_TECHNIKFL_TF_VON_NGF: 1.68,
    GB_VERKEHRSFL_VF_VON_NGF: 16.16,
    GB_MIETFL_GESAMT: 236.28,
    GB_MIETFL_1_BSB: 236.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7127495,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/4',
    GB_SAP_GB_BEZEICHNUNG: 'Bildungszentrum',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Bildungszentrum',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.475253233',
    GB_LAENGENGRAD_OESTLICH: '9.8550359990',
    GB_NETTOGRUNDFL_NGF: 2869.47,
    GB_NUTZFL_NF_VON_NGF: 2478.15,
    GB_TECHNIKFL_TF_VON_NGF: 30.44,
    GB_VERKEHRSFL_VF_VON_NGF: 360.88,
    GB_MIETFL_GESAMT: 2869.47,
    GB_MIETFL_1_BSB: 501.15,
    GB_MIETFL_2_DRITTE: 2286.61,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2057896,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: '53.475264',
    GB_LAENGENGRAD_OESTLICH: '9.856175',
    GB_NETTOGRUNDFL_NGF: 1408.26,
    GB_NUTZFL_NF_VON_NGF: 1275.51,
    GB_TECHNIKFL_TF_VON_NGF: 8.47,
    GB_VERKEHRSFL_VF_VON_NGF: 124.28,
    GB_MIETFL_GESAMT: 1408.26,
    GB_MIETFL_1_BSB: 1404.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '20.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7379673,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747067,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747067',
    GB_SAP_GB_ID_PKF: '7400/747067/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.475468',
    GB_LAENGENGRAD_OESTLICH: '9.856104',
    GB_NETTOGRUNDFL_NGF: 279.04,
    GB_NUTZFL_NF_VON_NGF: 240.89,
    GB_TECHNIKFL_TF_VON_NGF: 3.76,
    GB_VERKEHRSFL_VF_VON_NGF: 34.39,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7121553,
    GB_WE_OBJ_ID_FKT: 2032474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.46,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 236.46,
    GB_MIETFL_GESAMT: 236.46,
    GB_MIETFL_1_BSB: 236.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992364,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 131.16,
    GB_NUTZFL_NF_VON_NGF: 105.54,
    GB_TECHNIKFL_TF_VON_NGF: 4.06,
    GB_VERKEHRSFL_VF_VON_NGF: 21.56,
    GB_MIETFL_GESAMT: 131.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992365,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.468796',
    GB_LAENGENGRAD_OESTLICH: '9.955042',
    GB_NETTOGRUNDFL_NGF: 623.91,
    GB_NUTZFL_NF_VON_NGF: 589.63,
    GB_TECHNIKFL_TF_VON_NGF: 12.45,
    GB_VERKEHRSFL_VF_VON_NGF: 21.83,
    GB_MIETFL_GESAMT: 623.91,
    GB_MIETFL_1_BSB: 623.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992366,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 430.83,
    GB_NUTZFL_NF_VON_NGF: 398.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.39,
    GB_MIETFL_GESAMT: 430.83,
    GB_MIETFL_1_BSB: 214.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992367,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 432.24,
    GB_NUTZFL_NF_VON_NGF: 400.43,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.81,
    GB_MIETFL_GESAMT: 432.24,
    GB_MIETFL_1_BSB: 214.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992368,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 431.04,
    GB_NUTZFL_NF_VON_NGF: 399.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.3,
    GB_MIETFL_GESAMT: 431.04,
    GB_MIETFL_1_BSB: 212.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992369,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 218.02,
    GB_NUTZFL_NF_VON_NGF: 182.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.66,
    GB_MIETFL_GESAMT: 218.02,
    GB_MIETFL_1_BSB: 218.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992370,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 441.76,
    GB_NUTZFL_NF_VON_NGF: 375.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.26,
    GB_MIETFL_GESAMT: 441.76,
    GB_MIETFL_1_BSB: 441.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992371,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/9',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 372.95,
    GB_NUTZFL_NF_VON_NGF: 340.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.01,
    GB_MIETFL_GESAMT: 372.95,
    GB_MIETFL_1_BSB: 372.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992372,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1233.28,
    GB_NUTZFL_NF_VON_NGF: 982.34,
    GB_TECHNIKFL_TF_VON_NGF: 77.24,
    GB_VERKEHRSFL_VF_VON_NGF: 173.7,
    GB_MIETFL_GESAMT: 1233.28,
    GB_MIETFL_1_BSB: 984.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992373,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.22,
    GB_NUTZFL_NF_VON_NGF: 155.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.04,
    GB_MIETFL_GESAMT: 172.22,
    GB_MIETFL_1_BSB: 172.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992374,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 438.64,
    GB_NUTZFL_NF_VON_NGF: 372.21,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.43,
    GB_MIETFL_GESAMT: 438.64,
    GB_MIETFL_1_BSB: 438.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078230,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 59.52,
    GB_NUTZFL_NF_VON_NGF: 51.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.22,
    GB_MIETFL_GESAMT: 59.52,
    GB_MIETFL_1_BSB: 59.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992375,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 435.76,
    GB_NUTZFL_NF_VON_NGF: 368.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 67.14,
    GB_MIETFL_GESAMT: 435.76,
    GB_MIETFL_1_BSB: 435.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078231,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/15',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2913.85,
    GB_NUTZFL_NF_VON_NGF: 2211.84,
    GB_TECHNIKFL_TF_VON_NGF: 289.59,
    GB_VERKEHRSFL_VF_VON_NGF: 412.42,
    GB_MIETFL_GESAMT: 2913.85,
    GB_MIETFL_1_BSB: 2732.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '08.05.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5420003,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7149204,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 516.75,
    GB_NUTZFL_NF_VON_NGF: 433.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 82.81,
    GB_MIETFL_GESAMT: 516.75,
    GB_MIETFL_1_BSB: 516.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5068083,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747006,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747006',
    GB_SAP_GB_ID_PKF: '7400/747006/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.7,
    GB_NUTZFL_NF_VON_NGF: 203.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.26,
    GB_MIETFL_GESAMT: 239.7,
    GB_MIETFL_1_BSB: 239.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337411,
    GB_WE_OBJ_ID_FKT: 1989425,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.21',
    GB_GB_OBJ_ID_PKT: 1992779,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.460767',
    GB_LAENGENGRAD_OESTLICH: '9.954397',
    GB_NETTOGRUNDFL_NGF: 623.32,
    GB_NUTZFL_NF_VON_NGF: 583.86,
    GB_TECHNIKFL_TF_VON_NGF: 20.0,
    GB_VERKEHRSFL_VF_VON_NGF: 19.46,
    GB_MIETFL_GESAMT: 623.32,
    GB_MIETFL_1_BSB: 623.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992780,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.461050946',
    GB_LAENGENGRAD_OESTLICH: '9.9571548654',
    GB_NETTOGRUNDFL_NGF: 837.36,
    GB_NUTZFL_NF_VON_NGF: 562.72,
    GB_TECHNIKFL_TF_VON_NGF: 51.53,
    GB_VERKEHRSFL_VF_VON_NGF: 223.11,
    GB_MIETFL_GESAMT: 837.36,
    GB_MIETFL_1_BSB: 754.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992781,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 346.77,
    GB_NUTZFL_NF_VON_NGF: 317.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.93,
    GB_MIETFL_GESAMT: 346.77,
    GB_MIETFL_1_BSB: 346.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992782,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 302.54,
    GB_NUTZFL_NF_VON_NGF: 271.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.15,
    GB_MIETFL_GESAMT: 302.54,
    GB_MIETFL_1_BSB: 302.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992783,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 494.22,
    GB_NUTZFL_NF_VON_NGF: 375.53,
    GB_TECHNIKFL_TF_VON_NGF: 16.06,
    GB_VERKEHRSFL_VF_VON_NGF: 102.63,
    GB_MIETFL_GESAMT: 494.22,
    GB_MIETFL_1_BSB: 311.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992784,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 360.64,
    GB_NUTZFL_NF_VON_NGF: 343.6,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.04,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.21',
    GB_GB_OBJ_ID_PKT: 1992785,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793216,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793217,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793218,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 416.35,
    GB_NUTZFL_NF_VON_NGF: 379.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.66,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '26.06.20',
    GB_GB_OBJ_ID_PKT: 1992789,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793219,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793220,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793221,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/15',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.461159099',
    GB_LAENGENGRAD_OESTLICH: '9.9564416406',
    GB_NETTOGRUNDFL_NGF: 700.49,
    GB_NUTZFL_NF_VON_NGF: 449.48,
    GB_TECHNIKFL_TF_VON_NGF: 9.94,
    GB_VERKEHRSFL_VF_VON_NGF: 241.07,
    GB_MIETFL_GESAMT: 700.49,
    GB_MIETFL_1_BSB: 700.49,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992793,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/16',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltung und Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltung und Fachgeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.461114081',
    GB_LAENGENGRAD_OESTLICH: '9.9558577847',
    GB_NETTOGRUNDFL_NGF: 513.19,
    GB_NUTZFL_NF_VON_NGF: 402.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 111.12,
    GB_MIETFL_GESAMT: 513.19,
    GB_MIETFL_1_BSB: 513.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992794,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/17',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: '53.461318898',
    GB_LAENGENGRAD_OESTLICH: '9.9572980494',
    GB_NETTOGRUNDFL_NGF: 138.16,
    GB_NUTZFL_NF_VON_NGF: 112.14,
    GB_TECHNIKFL_TF_VON_NGF: 6.2,
    GB_VERKEHRSFL_VF_VON_NGF: 19.82,
    GB_MIETFL_GESAMT: 138.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992795,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.460841427',
    GB_LAENGENGRAD_OESTLICH: '9.9549024572',
    GB_NETTOGRUNDFL_NGF: 214.02,
    GB_NUTZFL_NF_VON_NGF: 181.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.03,
    GB_MIETFL_GESAMT: 214.02,
    GB_MIETFL_1_BSB: 214.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992796,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/20',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5912364,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/22',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7050332,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 23,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/23',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7050333,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.460573037',
    GB_LAENGENGRAD_OESTLICH: '9.9552370993',
    GB_NETTOGRUNDFL_NGF: 463.9,
    GB_NUTZFL_NF_VON_NGF: 370.32,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.58,
    GB_MIETFL_GESAMT: 463.9,
    GB_MIETFL_1_BSB: 463.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992797,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/21',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: '53.460231',
    GB_LAENGENGRAD_OESTLICH: '9.955591',
    GB_NETTOGRUNDFL_NGF: 1763.05,
    GB_NUTZFL_NF_VON_NGF: 1323.19,
    GB_TECHNIKFL_TF_VON_NGF: 12.4,
    GB_VERKEHRSFL_VF_VON_NGF: 427.46,
    GB_MIETFL_GESAMT: 1763.05,
    GB_MIETFL_1_BSB: 1761.1,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7050331,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 433.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 489.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352794,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 66.61,
    GB_NUTZFL_NF_VON_NGF: 66.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 66.61,
    GB_MIETFL_1_BSB: 66.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036067,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747058,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747058',
    GB_SAP_GB_ID_PKF: '7400/747058/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 671.03,
    GB_NUTZFL_NF_VON_NGF: 597.93,
    GB_TECHNIKFL_TF_VON_NGF: 8.03,
    GB_VERKEHRSFL_VF_VON_NGF: 65.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.05.20',
    GB_GUELTIG_BIS: '31.03.21',
    GB_GB_OBJ_ID_PKT: 7114888,
    GB_WE_OBJ_ID_FKT: 1989473,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.431492',
    GB_LAENGENGRAD_OESTLICH: '9.967079',
    GB_NETTOGRUNDFL_NGF: 3507.79,
    GB_NUTZFL_NF_VON_NGF: 2561.53,
    GB_TECHNIKFL_TF_VON_NGF: 90.34,
    GB_VERKEHRSFL_VF_VON_NGF: 855.92,
    GB_MIETFL_GESAMT: 3507.79,
    GB_MIETFL_1_BSB: 3507.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992623,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.431823',
    GB_LAENGENGRAD_OESTLICH: '9.968185',
    GB_NETTOGRUNDFL_NGF: 1594.47,
    GB_NUTZFL_NF_VON_NGF: 1547.1,
    GB_TECHNIKFL_TF_VON_NGF: 13.03,
    GB_VERKEHRSFL_VF_VON_NGF: 34.34,
    GB_MIETFL_GESAMT: 1594.47,
    GB_MIETFL_1_BSB: 1594.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992624,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.430842248',
    GB_LAENGENGRAD_OESTLICH: '9.9670805887',
    GB_NETTOGRUNDFL_NGF: 3254.37,
    GB_NUTZFL_NF_VON_NGF: 2574.93,
    GB_TECHNIKFL_TF_VON_NGF: 15.27,
    GB_VERKEHRSFL_VF_VON_NGF: 664.17,
    GB_MIETFL_GESAMT: 3254.37,
    GB_MIETFL_1_BSB: 2981.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992625,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793192,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.431045270',
    GB_LAENGENGRAD_OESTLICH: '9.9680796621',
    GB_NETTOGRUNDFL_NGF: 917.97,
    GB_NUTZFL_NF_VON_NGF: 742.07,
    GB_TECHNIKFL_TF_VON_NGF: 8.5,
    GB_VERKEHRSFL_VF_VON_NGF: 167.4,
    GB_MIETFL_GESAMT: 917.97,
    GB_MIETFL_1_BSB: 917.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.09',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992627,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747041,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747041',
    GB_SAP_GB_ID_PKF: '7400/747041/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045112,
    GB_WE_OBJ_ID_FKT: 1989457,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1938,
    GB_BREITENGRAD_NOERDLICH: '53.438413426',
    GB_LAENGENGRAD_OESTLICH: '9.9634869707',
    GB_NETTOGRUNDFL_NGF: 1026.34,
    GB_NUTZFL_NF_VON_NGF: 700.33,
    GB_TECHNIKFL_TF_VON_NGF: 64.44,
    GB_VERKEHRSFL_VF_VON_NGF: 261.57,
    GB_MIETFL_GESAMT: 1026.34,
    GB_MIETFL_1_BSB: 636.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992426,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.438532837',
    GB_LAENGENGRAD_OESTLICH: '9.9639523346',
    GB_NETTOGRUNDFL_NGF: 488.93,
    GB_NUTZFL_NF_VON_NGF: 368.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.66,
    GB_MIETFL_GESAMT: 488.93,
    GB_MIETFL_1_BSB: 488.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992427,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.438434701',
    GB_LAENGENGRAD_OESTLICH: '9.9647621864',
    GB_NETTOGRUNDFL_NGF: 488.99,
    GB_NUTZFL_NF_VON_NGF: 368.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.51,
    GB_MIETFL_GESAMT: 488.99,
    GB_MIETFL_1_BSB: 488.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992428,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.438779356',
    GB_LAENGENGRAD_OESTLICH: '9.9647922863',
    GB_NETTOGRUNDFL_NGF: 488.99,
    GB_NUTZFL_NF_VON_NGF: 368.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.51,
    GB_MIETFL_GESAMT: 488.99,
    GB_MIETFL_1_BSB: 488.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992429,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2005,
    GB_BREITENGRAD_NOERDLICH: '53.438702750',
    GB_LAENGENGRAD_OESTLICH: '9.9644603082',
    GB_NETTOGRUNDFL_NGF: 742.48,
    GB_NUTZFL_NF_VON_NGF: 647.53,
    GB_TECHNIKFL_TF_VON_NGF: 18.55,
    GB_VERKEHRSFL_VF_VON_NGF: 76.4,
    GB_MIETFL_GESAMT: 742.48,
    GB_MIETFL_1_BSB: 690.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992430,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/6',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.438961',
    GB_LAENGENGRAD_OESTLICH: '9.964083',
    GB_NETTOGRUNDFL_NGF: 624.01,
    GB_NUTZFL_NF_VON_NGF: 591.32,
    GB_TECHNIKFL_TF_VON_NGF: 10.64,
    GB_VERKEHRSFL_VF_VON_NGF: 22.05,
    GB_MIETFL_GESAMT: 624.01,
    GB_MIETFL_1_BSB: 624.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992431,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.438581713',
    GB_LAENGENGRAD_OESTLICH: '9.9629927822',
    GB_NETTOGRUNDFL_NGF: 164.56,
    GB_NUTZFL_NF_VON_NGF: 130.77,
    GB_TECHNIKFL_TF_VON_NGF: 6.48,
    GB_VERKEHRSFL_VF_VON_NGF: 27.31,
    GB_MIETFL_GESAMT: 164.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992432,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/8',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 103.99,
    GB_NUTZFL_NF_VON_NGF: 103.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 103.99,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992433,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.1,
    GB_NUTZFL_NF_VON_NGF: 84.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.6,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992396,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.438527312',
    GB_LAENGENGRAD_OESTLICH: '9.9643672555',
    GB_NETTOGRUNDFL_NGF: 488.78,
    GB_NUTZFL_NF_VON_NGF: 368.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.51,
    GB_MIETFL_GESAMT: 488.78,
    GB_MIETFL_1_BSB: 488.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078238,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1938,
    GB_BREITENGRAD_NOERDLICH: '53.438210147',
    GB_LAENGENGRAD_OESTLICH: '9.9632747058',
    GB_NETTOGRUNDFL_NGF: 546.64,
    GB_NUTZFL_NF_VON_NGF: 423.76,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 122.88,
    GB_MIETFL_GESAMT: 546.64,
    GB_MIETFL_1_BSB: 546.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078239,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6769412,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.438353189',
    GB_LAENGENGRAD_OESTLICH: '9.9658691444',
    GB_NETTOGRUNDFL_NGF: 1384.13,
    GB_NUTZFL_NF_VON_NGF: 1044.65,
    GB_TECHNIKFL_TF_VON_NGF: 7.83,
    GB_VERKEHRSFL_VF_VON_NGF: 331.65,
    GB_MIETFL_GESAMT: 1384.13,
    GB_MIETFL_1_BSB: 1381.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6908728,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7098643,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.438118356',
    GB_LAENGENGRAD_OESTLICH: '9.9653351607',
    GB_NETTOGRUNDFL_NGF: 923.75,
    GB_NUTZFL_NF_VON_NGF: 681.08,
    GB_TECHNIKFL_TF_VON_NGF: 20.27,
    GB_VERKEHRSFL_VF_VON_NGF: 222.4,
    GB_MIETFL_GESAMT: 923.75,
    GB_MIETFL_1_BSB: 921.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7098644,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.03.22',
    GB_GB_OBJ_ID_PKT: 5926829,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.05.22',
    GB_GB_OBJ_ID_PKT: 6940206,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747014,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747014',
    GB_SAP_GB_ID_PKF: '7400/747014/42',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.05.22',
    GB_GB_OBJ_ID_PKT: 7036065,
    GB_WE_OBJ_ID_FKT: 1989432,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747016',
    GB_SAP_GB_ID_PKF: '7400/747016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.440220',
    GB_LAENGENGRAD_OESTLICH: '9.970896',
    GB_NETTOGRUNDFL_NGF: 1496.31,
    GB_NUTZFL_NF_VON_NGF: 1112.09,
    GB_TECHNIKFL_TF_VON_NGF: 58.88,
    GB_VERKEHRSFL_VF_VON_NGF: 325.34,
    GB_MIETFL_GESAMT: 1496.31,
    GB_MIETFL_1_BSB: 1344.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992444,
    GB_WE_OBJ_ID_FKT: 1989434,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747016,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747016',
    GB_SAP_GB_ID_PKF: '7400/747016/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2034.5,
    GB_NUTZFL_NF_VON_NGF: 1464.75,
    GB_TECHNIKFL_TF_VON_NGF: 4.75,
    GB_VERKEHRSFL_VF_VON_NGF: 565.0,
    GB_MIETFL_GESAMT: 2034.5,
    GB_MIETFL_1_BSB: 1981.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992445,
    GB_WE_OBJ_ID_FKT: 1989434,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747016,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747016',
    GB_SAP_GB_ID_PKF: '7400/747016/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 422.22,
    GB_NUTZFL_NF_VON_NGF: 321.61,
    GB_TECHNIKFL_TF_VON_NGF: 13.29,
    GB_VERKEHRSFL_VF_VON_NGF: 87.32,
    GB_MIETFL_GESAMT: 422.22,
    GB_MIETFL_1_BSB: 419.52,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992446,
    GB_WE_OBJ_ID_FKT: 1989434,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 705.58,
    GB_NUTZFL_NF_VON_NGF: 554.26,
    GB_TECHNIKFL_TF_VON_NGF: 9.75,
    GB_VERKEHRSFL_VF_VON_NGF: 141.57,
    GB_MIETFL_GESAMT: 705.58,
    GB_MIETFL_1_BSB: 533.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992387,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.430853',
    GB_LAENGENGRAD_OESTLICH: '9.969387',
    GB_NETTOGRUNDFL_NGF: 615.7,
    GB_NUTZFL_NF_VON_NGF: 573.69,
    GB_TECHNIKFL_TF_VON_NGF: 19.69,
    GB_VERKEHRSFL_VF_VON_NGF: 22.32,
    GB_MIETFL_GESAMT: 615.7,
    GB_MIETFL_1_BSB: 615.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992388,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 350.84,
    GB_NUTZFL_NF_VON_NGF: 333.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 17.27,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.12.22',
    GB_GB_OBJ_ID_PKT: 1992389,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.86,
    GB_NUTZFL_NF_VON_NGF: 164.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.5,
    GB_MIETFL_GESAMT: 179.86,
    GB_MIETFL_1_BSB: 179.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992390,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 902.43,
    GB_NUTZFL_NF_VON_NGF: 622.11,
    GB_TECHNIKFL_TF_VON_NGF: 70.41,
    GB_VERKEHRSFL_VF_VON_NGF: 209.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.12.22',
    GB_GB_OBJ_ID_PKT: 1992391,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 558.58,
    GB_NUTZFL_NF_VON_NGF: 558.58,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992393,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 239.77,
    GB_NUTZFL_NF_VON_NGF: 215.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 23.79,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992394,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 116.44,
    GB_NUTZFL_NF_VON_NGF: 107.88,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 8.56,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992395,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.430003',
    GB_LAENGENGRAD_OESTLICH: '9.968939',
    GB_NETTOGRUNDFL_NGF: 304.84,
    GB_NUTZFL_NF_VON_NGF: 282.68,
    GB_TECHNIKFL_TF_VON_NGF: 13.63,
    GB_VERKEHRSFL_VF_VON_NGF: 8.53,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.12.20',
    GB_GB_OBJ_ID_PKT: 1992392,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992397,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992398,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 242.11,
    GB_NUTZFL_NF_VON_NGF: 218.03,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.08,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.12.20',
    GB_GB_OBJ_ID_PKT: 1992399,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 108.24,
    GB_NUTZFL_NF_VON_NGF: 94.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '09.12.20',
    GB_GB_OBJ_ID_PKT: 1992400,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 108.24,
    GB_NUTZFL_NF_VON_NGF: 94.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992401,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 108.24,
    GB_NUTZFL_NF_VON_NGF: 94.14,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.1,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 2078233,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 40.3,
    GB_NUTZFL_NF_VON_NGF: 40.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '28.02.20',
    GB_GB_OBJ_ID_PKT: 1992402,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 68.49,
    GB_NUTZFL_NF_VON_NGF: 58.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.12.22',
    GB_GB_OBJ_ID_PKT: 2078234,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1909.86,
    GB_NUTZFL_NF_VON_NGF: 1460.83,
    GB_TECHNIKFL_TF_VON_NGF: 71.64,
    GB_VERKEHRSFL_VF_VON_NGF: 377.39,
    GB_MIETFL_GESAMT: 1909.86,
    GB_MIETFL_1_BSB: 1907.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793174,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/20',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7037347,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/21',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7067302,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747008,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747008',
    GB_SAP_GB_ID_PKF: '7400/747008/22',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 553.16,
    GB_NUTZFL_NF_VON_NGF: 441.55,
    GB_TECHNIKFL_TF_VON_NGF: 31.08,
    GB_VERKEHRSFL_VF_VON_NGF: 80.53,
    GB_MIETFL_GESAMT: 553.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 551.75,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7148621,
    GB_WE_OBJ_ID_FKT: 1989427,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1917,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 245.15,
    GB_NUTZFL_NF_VON_NGF: 245.15,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 245.15,
    GB_MIETFL_1_BSB: 245.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992524,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.516322',
    GB_LAENGENGRAD_OESTLICH: '9.809613',
    GB_NETTOGRUNDFL_NGF: 619.29,
    GB_NUTZFL_NF_VON_NGF: 590.53,
    GB_TECHNIKFL_TF_VON_NGF: 6.62,
    GB_VERKEHRSFL_VF_VON_NGF: 22.14,
    GB_MIETFL_GESAMT: 619.29,
    GB_MIETFL_1_BSB: 619.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992525,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/3',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 186.14,
    GB_NUTZFL_NF_VON_NGF: 158.39,
    GB_TECHNIKFL_TF_VON_NGF: 15.89,
    GB_VERKEHRSFL_VF_VON_NGF: 11.86,
    GB_MIETFL_GESAMT: 186.14,
    GB_MIETFL_1_BSB: 186.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992526,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 506.08,
    GB_NUTZFL_NF_VON_NGF: 437.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 68.59,
    GB_MIETFL_GESAMT: 506.08,
    GB_MIETFL_1_BSB: 506.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992527,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 482.77,
    GB_NUTZFL_NF_VON_NGF: 417.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.0,
    GB_MIETFL_GESAMT: 482.77,
    GB_MIETFL_1_BSB: 482.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992528,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/22',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1652.43,
    GB_NUTZFL_NF_VON_NGF: 1208.44,
    GB_TECHNIKFL_TF_VON_NGF: 31.23,
    GB_VERKEHRSFL_VF_VON_NGF: 412.76,
    GB_MIETFL_GESAMT: 1652.43,
    GB_MIETFL_1_BSB: 1648.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5383940,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 23,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/23',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1176.06,
    GB_NUTZFL_NF_VON_NGF: 1138.39,
    GB_TECHNIKFL_TF_VON_NGF: 14.24,
    GB_VERKEHRSFL_VF_VON_NGF: 23.43,
    GB_MIETFL_GESAMT: 1176.06,
    GB_MIETFL_1_BSB: 1176.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '25.07.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5383941,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1586.71,
    GB_NUTZFL_NF_VON_NGF: 1475.83,
    GB_TECHNIKFL_TF_VON_NGF: 7.97,
    GB_VERKEHRSFL_VF_VON_NGF: 102.91,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.10.18',
    GB_GB_OBJ_ID_PKT: 4352785,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/6',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1917,
    GB_BREITENGRAD_NOERDLICH: '53.516230',
    GB_LAENGENGRAD_OESTLICH: '9.810914',
    GB_NETTOGRUNDFL_NGF: 1653.87,
    GB_NUTZFL_NF_VON_NGF: 1200.86,
    GB_TECHNIKFL_TF_VON_NGF: 53.53,
    GB_VERKEHRSFL_VF_VON_NGF: 399.48,
    GB_MIETFL_GESAMT: 1653.87,
    GB_MIETFL_1_BSB: 1252.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992529,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747029,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747029',
    GB_SAP_GB_ID_PKF: '7400/747029/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 488.4,
    GB_NUTZFL_NF_VON_NGF: 422.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.33,
    GB_MIETFL_GESAMT: 488.4,
    GB_MIETFL_1_BSB: 488.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.05.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992530,
    GB_WE_OBJ_ID_FKT: 1989446,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747072,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747072',
    GB_SAP_GB_ID_PKF: '7400/747072/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471326067',
    GB_LAENGENGRAD_OESTLICH: '9.8687749223',
    GB_NETTOGRUNDFL_NGF: 622.32,
    GB_NUTZFL_NF_VON_NGF: 593.44,
    GB_TECHNIKFL_TF_VON_NGF: 6.71,
    GB_VERKEHRSFL_VF_VON_NGF: 22.17,
    GB_MIETFL_GESAMT: 622.32,
    GB_MIETFL_1_BSB: 615.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793232,
    GB_WE_OBJ_ID_FKT: 6793126,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1508.28,
    GB_NUTZFL_NF_VON_NGF: 1164.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 343.92,
    GB_MIETFL_GESAMT: 1508.28,
    GB_MIETFL_1_BSB: 1387.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992752,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.470992',
    GB_LAENGENGRAD_OESTLICH: '9.869678',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992753,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793213,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.06,
    GB_NUTZFL_NF_VON_NGF: 146.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.55,
    GB_MIETFL_GESAMT: 161.06,
    GB_MIETFL_1_BSB: 161.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992755,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.06,
    GB_NUTZFL_NF_VON_NGF: 146.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.55,
    GB_MIETFL_GESAMT: 161.06,
    GB_MIETFL_1_BSB: 161.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992756,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 298.6,
    GB_NUTZFL_NF_VON_NGF: 176.95,
    GB_TECHNIKFL_TF_VON_NGF: 88.45,
    GB_VERKEHRSFL_VF_VON_NGF: 33.2,
    GB_MIETFL_GESAMT: 298.6,
    GB_MIETFL_1_BSB: 197.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992757,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2663.4,
    GB_NUTZFL_NF_VON_NGF: 2236.68,
    GB_TECHNIKFL_TF_VON_NGF: 45.57,
    GB_VERKEHRSFL_VF_VON_NGF: 381.15,
    GB_MIETFL_GESAMT: 2663.4,
    GB_MIETFL_1_BSB: 2533.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992758,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 277.75,
    GB_NUTZFL_NF_VON_NGF: 189.77,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.98,
    GB_MIETFL_GESAMT: 277.75,
    GB_MIETFL_1_BSB: 277.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992759,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1527.46,
    GB_NUTZFL_NF_VON_NGF: 1313.1,
    GB_TECHNIKFL_TF_VON_NGF: 39.66,
    GB_VERKEHRSFL_VF_VON_NGF: 174.7,
    GB_MIETFL_GESAMT: 1527.46,
    GB_MIETFL_1_BSB: 1354.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992760,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 267.04,
    GB_NUTZFL_NF_VON_NGF: 235.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.31,
    GB_MIETFL_GESAMT: 267.04,
    GB_MIETFL_1_BSB: 267.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992761,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.06,
    GB_NUTZFL_NF_VON_NGF: 146.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.55,
    GB_MIETFL_GESAMT: 161.06,
    GB_MIETFL_1_BSB: 161.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992762,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 161.06,
    GB_NUTZFL_NF_VON_NGF: 146.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.55,
    GB_MIETFL_GESAMT: 161.06,
    GB_MIETFL_1_BSB: 161.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992763,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 211.62,
    GB_NUTZFL_NF_VON_NGF: 197.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.55,
    GB_MIETFL_GESAMT: 211.62,
    GB_MIETFL_1_BSB: 211.62,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992764,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/14',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 372.0,
    GB_NUTZFL_NF_VON_NGF: 340.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.31,
    GB_MIETFL_GESAMT: 372.0,
    GB_MIETFL_1_BSB: 329.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992765,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793214,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/16',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 166.79,
    GB_NUTZFL_NF_VON_NGF: 128.26,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 38.53,
    GB_MIETFL_GESAMT: 166.79,
    GB_MIETFL_1_BSB: 166.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992767,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 770.92,
    GB_NUTZFL_NF_VON_NGF: 589.52,
    GB_TECHNIKFL_TF_VON_NGF: 6.99,
    GB_VERKEHRSFL_VF_VON_NGF: 174.41,
    GB_MIETFL_GESAMT: 770.92,
    GB_MIETFL_1_BSB: 770.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992768,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/19',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793215,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747055,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747055',
    GB_SAP_GB_ID_PKF: '7400/747055/18',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.470636',
    GB_LAENGENGRAD_OESTLICH: '9.865455',
    GB_NETTOGRUNDFL_NGF: 1353.12,
    GB_NUTZFL_NF_VON_NGF: 1241.95,
    GB_TECHNIKFL_TF_VON_NGF: 26.83,
    GB_VERKEHRSFL_VF_VON_NGF: 84.34,
    GB_MIETFL_GESAMT: 1353.12,
    GB_MIETFL_1_BSB: 1317.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '16.04.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2077021,
    GB_WE_OBJ_ID_FKT: 1989471,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 831.4,
    GB_NUTZFL_NF_VON_NGF: 572.44,
    GB_TECHNIKFL_TF_VON_NGF: 75.24,
    GB_VERKEHRSFL_VF_VON_NGF: 183.72,
    GB_MIETFL_GESAMT: 831.4,
    GB_MIETFL_1_BSB: 548.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992329,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793153,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.474228',
    GB_LAENGENGRAD_OESTLICH: '9.837772',
    GB_NETTOGRUNDFL_NGF: 614.82,
    GB_NUTZFL_NF_VON_NGF: 581.25,
    GB_TECHNIKFL_TF_VON_NGF: 11.12,
    GB_VERKEHRSFL_VF_VON_NGF: 22.45,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992331,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.474232',
    GB_LAENGENGRAD_OESTLICH: '9.838138',
    GB_NETTOGRUNDFL_NGF: 547.72,
    GB_NUTZFL_NF_VON_NGF: 516.54,
    GB_TECHNIKFL_TF_VON_NGF: 12.65,
    GB_VERKEHRSFL_VF_VON_NGF: 18.53,
    GB_MIETFL_GESAMT: 547.72,
    GB_MIETFL_1_BSB: 547.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992332,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.53,
    GB_NUTZFL_NF_VON_NGF: 361.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.74,
    GB_MIETFL_GESAMT: 433.53,
    GB_MIETFL_1_BSB: 433.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992333,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 443.82,
    GB_NUTZFL_NF_VON_NGF: 372.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.57,
    GB_MIETFL_GESAMT: 443.82,
    GB_MIETFL_1_BSB: 443.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992334,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793154,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793155,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793156,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793157,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/11',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1726.08,
    GB_NUTZFL_NF_VON_NGF: 1407.99,
    GB_TECHNIKFL_TF_VON_NGF: 8.57,
    GB_VERKEHRSFL_VF_VON_NGF: 309.52,
    GB_MIETFL_GESAMT: 1726.08,
    GB_MIETFL_1_BSB: 1726.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992339,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793158,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/13',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 147.79,
    GB_NUTZFL_NF_VON_NGF: 112.96,
    GB_TECHNIKFL_TF_VON_NGF: 6.79,
    GB_VERKEHRSFL_VF_VON_NGF: 28.04,
    GB_MIETFL_GESAMT: 147.79,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992341,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 433.53,
    GB_NUTZFL_NF_VON_NGF: 361.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.69,
    GB_MIETFL_GESAMT: 433.53,
    GB_MIETFL_1_BSB: 433.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992342,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793159,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793160,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793161,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793162,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/20',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2791.63,
    GB_NUTZFL_NF_VON_NGF: 2217.65,
    GB_TECHNIKFL_TF_VON_NGF: 43.41,
    GB_VERKEHRSFL_VF_VON_NGF: 530.57,
    GB_MIETFL_GESAMT: 2791.63,
    GB_MIETFL_1_BSB: 2788.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '23.08.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5383938,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747002,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747002',
    GB_SAP_GB_ID_PKF: '7400/747002/21',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 378.93,
    GB_NUTZFL_NF_VON_NGF: 319.52,
    GB_TECHNIKFL_TF_VON_NGF: 5.67,
    GB_VERKEHRSFL_VF_VON_NGF: 53.74,
    GB_MIETFL_GESAMT: 378.93,
    GB_MIETFL_1_BSB: 378.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.12.16',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5383939,
    GB_WE_OBJ_ID_FKT: 1989421,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 735.93,
    GB_NUTZFL_NF_VON_NGF: 637.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 97.99,
    GB_MIETFL_GESAMT: 735.93,
    GB_MIETFL_1_BSB: 699.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992434,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/2',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 152.55,
    GB_NUTZFL_NF_VON_NGF: 137.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.61,
    GB_MIETFL_GESAMT: 152.55,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992435,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 574.78,
    GB_NUTZFL_NF_VON_NGF: 470.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 104.27,
    GB_MIETFL_GESAMT: 574.78,
    GB_MIETFL_1_BSB: 574.78,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992436,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1653.28,
    GB_NUTZFL_NF_VON_NGF: 1248.02,
    GB_TECHNIKFL_TF_VON_NGF: 69.08,
    GB_VERKEHRSFL_VF_VON_NGF: 336.18,
    GB_MIETFL_GESAMT: 1653.28,
    GB_MIETFL_1_BSB: 1541.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992437,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 791.9,
    GB_NUTZFL_NF_VON_NGF: 740.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.53,
    GB_MIETFL_GESAMT: 791.9,
    GB_MIETFL_1_BSB: 791.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992438,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.76,
    GB_NUTZFL_NF_VON_NGF: 149.77,
    GB_TECHNIKFL_TF_VON_NGF: 5.39,
    GB_VERKEHRSFL_VF_VON_NGF: 17.6,
    GB_MIETFL_GESAMT: 172.76,
    GB_MIETFL_1_BSB: 172.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992439,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.61,
    GB_NUTZFL_NF_VON_NGF: 149.64,
    GB_TECHNIKFL_TF_VON_NGF: 5.37,
    GB_VERKEHRSFL_VF_VON_NGF: 17.6,
    GB_MIETFL_GESAMT: 172.61,
    GB_MIETFL_1_BSB: 172.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992440,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 179.01,
    GB_NUTZFL_NF_VON_NGF: 160.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 18.5,
    GB_MIETFL_GESAMT: 179.01,
    GB_MIETFL_1_BSB: 179.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992441,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 460.68,
    GB_NUTZFL_NF_VON_NGF: 373.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.37,
    GB_MIETFL_GESAMT: 460.68,
    GB_MIETFL_1_BSB: 460.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992442,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/10',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.475344',
    GB_LAENGENGRAD_OESTLICH: '9.864866',
    GB_NETTOGRUNDFL_NGF: 624.97,
    GB_NUTZFL_NF_VON_NGF: 589.3,
    GB_TECHNIKFL_TF_VON_NGF: 13.39,
    GB_VERKEHRSFL_VF_VON_NGF: 22.28,
    GB_MIETFL_GESAMT: 624.97,
    GB_MIETFL_1_BSB: 624.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992443,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/11',
    GB_SAP_GB_BEZEICHNUNG: 'Nebengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Nebengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 19.49,
    GB_NUTZFL_NF_VON_NGF: 19.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 19.49,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078240,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.71,
    GB_NUTZFL_NF_VON_NGF: 149.75,
    GB_TECHNIKFL_TF_VON_NGF: 5.39,
    GB_VERKEHRSFL_VF_VON_NGF: 17.57,
    GB_MIETFL_GESAMT: 172.71,
    GB_MIETFL_1_BSB: 172.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078241,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 171.95,
    GB_NUTZFL_NF_VON_NGF: 149.8,
    GB_TECHNIKFL_TF_VON_NGF: 4.64,
    GB_VERKEHRSFL_VF_VON_NGF: 17.51,
    GB_MIETFL_GESAMT: 171.95,
    GB_MIETFL_1_BSB: 171.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078242,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045109,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/15',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045110,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 135.66,
    GB_NUTZFL_NF_VON_NGF: 135.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 135.66,
    GB_MIETFL_1_BSB: 135.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6940207,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 88.92,
    GB_NUTZFL_NF_VON_NGF: 77.65,
    GB_TECHNIKFL_TF_VON_NGF: 2.63,
    GB_VERKEHRSFL_VF_VON_NGF: 8.64,
    GB_MIETFL_GESAMT: 88.92,
    GB_MIETFL_1_BSB: 88.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036066,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 167.02,
    GB_NUTZFL_NF_VON_NGF: 148.69,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 15.32,
    GB_MIETFL_GESAMT: 167.02,
    GB_MIETFL_1_BSB: 167.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7127496,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747015,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7400/747015',
    GB_SAP_GB_ID_PKF: '7400/747015/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 133.99,
    GB_NUTZFL_NF_VON_NGF: 74.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 59.02,
    GB_MIETFL_GESAMT: 133.99,
    GB_MIETFL_1_BSB: 133.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365772,
    GB_WE_OBJ_ID_FKT: 1989433,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2618.0,
    GB_NUTZFL_NF_VON_NGF: 2227.62,
    GB_TECHNIKFL_TF_VON_NGF: 60.85,
    GB_VERKEHRSFL_VF_VON_NGF: 329.53,
    GB_MIETFL_GESAMT: 2618.0,
    GB_MIETFL_1_BSB: 1063.15,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992320,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 881.38,
    GB_NUTZFL_NF_VON_NGF: 695.58,
    GB_TECHNIKFL_TF_VON_NGF: 52.85,
    GB_VERKEHRSFL_VF_VON_NGF: 132.95,
    GB_MIETFL_GESAMT: 881.38,
    GB_MIETFL_1_BSB: 787.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992321,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 621.54,
    GB_NUTZFL_NF_VON_NGF: 577.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 44.45,
    GB_MIETFL_GESAMT: 621.54,
    GB_MIETFL_1_BSB: 621.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992322,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2007,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1505.7,
    GB_NUTZFL_NF_VON_NGF: 1196.15,
    GB_TECHNIKFL_TF_VON_NGF: 8.45,
    GB_VERKEHRSFL_VF_VON_NGF: 301.1,
    GB_MIETFL_GESAMT: 1505.7,
    GB_MIETFL_1_BSB: 1505.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992323,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 897.91,
    GB_NUTZFL_NF_VON_NGF: 689.26,
    GB_TECHNIKFL_TF_VON_NGF: 4.48,
    GB_VERKEHRSFL_VF_VON_NGF: 204.17,
    GB_MIETFL_GESAMT: 897.91,
    GB_MIETFL_1_BSB: 897.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992324,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793152,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 408.47,
    GB_NUTZFL_NF_VON_NGF: 342.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 65.56,
    GB_MIETFL_GESAMT: 408.47,
    GB_MIETFL_1_BSB: 408.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992326,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2006,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 626.81,
    GB_NUTZFL_NF_VON_NGF: 485.97,
    GB_TECHNIKFL_TF_VON_NGF: 6.03,
    GB_VERKEHRSFL_VF_VON_NGF: 134.81,
    GB_MIETFL_GESAMT: 626.81,
    GB_MIETFL_1_BSB: 626.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992327,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/9',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 87.81,
    GB_NUTZFL_NF_VON_NGF: 87.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 87.81,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992328,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/10',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1951,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 89.29,
    GB_NUTZFL_NF_VON_NGF: 75.07,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 14.22,
    GB_MIETFL_GESAMT: 89.29,
    GB_MIETFL_1_BSB: 89.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078229,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.466455',
    GB_LAENGENGRAD_OESTLICH: '9.862542',
    GB_NETTOGRUNDFL_NGF: 1614.01,
    GB_NUTZFL_NF_VON_NGF: 1512.51,
    GB_TECHNIKFL_TF_VON_NGF: 27.11,
    GB_VERKEHRSFL_VF_VON_NGF: 74.39,
    GB_MIETFL_GESAMT: 1614.01,
    GB_MIETFL_1_BSB: 1614.01,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '19.12.14',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337410,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5461605,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045107,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 489.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.19',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7036063,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 428.85,
    GB_TECHNIKFL_TF_VON_NGF: 4.96,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Mietfrei',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '17.04.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7113563,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 488.77,
    GB_NUTZFL_NF_VON_NGF: 432.02,
    GB_TECHNIKFL_TF_VON_NGF: 5.0,
    GB_VERKEHRSFL_VF_VON_NGF: 51.75,
    GB_MIETFL_GESAMT: 488.77,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Mietfrei',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '17.04.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7113564,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747001,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7400/747001',
    GB_SAP_GB_ID_PKF: '7400/747001/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 240.0,
    GB_NUTZFL_NF_VON_NGF: 240.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552763,
    GB_WE_OBJ_ID_FKT: 1989420,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 701.99,
    GB_NUTZFL_NF_VON_NGF: 591.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 110.46,
    GB_MIETFL_GESAMT: 701.99,
    GB_MIETFL_1_BSB: 701.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992769,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 550.87,
    GB_NUTZFL_NF_VON_NGF: 437.19,
    GB_TECHNIKFL_TF_VON_NGF: 6.68,
    GB_VERKEHRSFL_VF_VON_NGF: 107.0,
    GB_MIETFL_GESAMT: 550.87,
    GB_MIETFL_1_BSB: 550.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992770,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.479436',
    GB_LAENGENGRAD_OESTLICH: '9.865508',
    GB_NETTOGRUNDFL_NGF: 2079.08,
    GB_NUTZFL_NF_VON_NGF: 1839.57,
    GB_TECHNIKFL_TF_VON_NGF: 47.05,
    GB_VERKEHRSFL_VF_VON_NGF: 192.46,
    GB_MIETFL_GESAMT: 2079.08,
    GB_MIETFL_1_BSB: 2079.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992771,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3589.97,
    GB_NUTZFL_NF_VON_NGF: 2342.2,
    GB_TECHNIKFL_TF_VON_NGF: 340.9,
    GB_VERKEHRSFL_VF_VON_NGF: 906.87,
    GB_MIETFL_GESAMT: 3589.97,
    GB_MIETFL_1_BSB: 3065.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.20',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992772,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3111.69,
    GB_NUTZFL_NF_VON_NGF: 2284.68,
    GB_TECHNIKFL_TF_VON_NGF: 86.12,
    GB_VERKEHRSFL_VF_VON_NGF: 740.89,
    GB_MIETFL_GESAMT: 3111.69,
    GB_MIETFL_1_BSB: 3006.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.19',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992773,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 259.41,
    GB_NUTZFL_NF_VON_NGF: 207.09,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 52.32,
    GB_MIETFL_GESAMT: 259.41,
    GB_MIETFL_1_BSB: 259.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992774,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1283.3,
    GB_NUTZFL_NF_VON_NGF: 1025.96,
    GB_TECHNIKFL_TF_VON_NGF: 65.21,
    GB_VERKEHRSFL_VF_VON_NGF: 192.13,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.10.22',
    GB_GB_OBJ_ID_PKT: 1992775,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2802.01,
    GB_NUTZFL_NF_VON_NGF: 2086.52,
    GB_TECHNIKFL_TF_VON_NGF: 172.9,
    GB_VERKEHRSFL_VF_VON_NGF: 542.59,
    GB_MIETFL_GESAMT: 2802.01,
    GB_MIETFL_1_BSB: 2574.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '18.10.19',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992776,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 620.3,
    GB_NUTZFL_NF_VON_NGF: 575.95,
    GB_TECHNIKFL_TF_VON_NGF: 2.3,
    GB_VERKEHRSFL_VF_VON_NGF: 42.05,
    GB_MIETFL_GESAMT: 620.3,
    GB_MIETFL_1_BSB: 620.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992777,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 120.74,
    GB_NUTZFL_NF_VON_NGF: 90.5,
    GB_TECHNIKFL_TF_VON_NGF: 9.35,
    GB_VERKEHRSFL_VF_VON_NGF: 20.89,
    GB_MIETFL_GESAMT: 120.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992778,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 364.76,
    GB_NUTZFL_NF_VON_NGF: 293.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 71.26,
    GB_MIETFL_GESAMT: 364.76,
    GB_MIETFL_1_BSB: 364.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078160,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/14',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5383392,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747057,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747057',
    GB_SAP_GB_ID_PKF: '7400/747057/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5909124,
    GB_WE_OBJ_ID_FKT: 1989472,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 376.88,
    GB_NUTZFL_NF_VON_NGF: 336.56,
    GB_TECHNIKFL_TF_VON_NGF: 20.06,
    GB_VERKEHRSFL_VF_VON_NGF: 20.26,
    GB_MIETFL_GESAMT: 376.88,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992588,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/2',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: '53.475945',
    GB_LAENGENGRAD_OESTLICH: '9.865850',
    GB_NETTOGRUNDFL_NGF: 298.42,
    GB_NUTZFL_NF_VON_NGF: 281.95,
    GB_TECHNIKFL_TF_VON_NGF: 9.14,
    GB_VERKEHRSFL_VF_VON_NGF: 7.33,
    GB_MIETFL_GESAMT: 298.42,
    GB_MIETFL_1_BSB: 298.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992589,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/3',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 808.74,
    GB_NUTZFL_NF_VON_NGF: 598.45,
    GB_TECHNIKFL_TF_VON_NGF: 53.77,
    GB_VERKEHRSFL_VF_VON_NGF: 156.52,
    GB_MIETFL_GESAMT: 808.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992590,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 484.11,
    GB_NUTZFL_NF_VON_NGF: 421.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 63.0,
    GB_MIETFL_GESAMT: 484.11,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992591,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1949,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 224.56,
    GB_NUTZFL_NF_VON_NGF: 173.49,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 51.07,
    GB_MIETFL_GESAMT: 224.56,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992592,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1880,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 400.61,
    GB_NUTZFL_NF_VON_NGF: 363.89,
    GB_TECHNIKFL_TF_VON_NGF: 2.21,
    GB_VERKEHRSFL_VF_VON_NGF: 34.51,
    GB_MIETFL_GESAMT: 400.61,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992593,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 392.74,
    GB_NUTZFL_NF_VON_NGF: 301.7,
    GB_TECHNIKFL_TF_VON_NGF: 10.54,
    GB_VERKEHRSFL_VF_VON_NGF: 80.5,
    GB_MIETFL_GESAMT: 392.74,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992594,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 395.41,
    GB_NUTZFL_NF_VON_NGF: 296.38,
    GB_TECHNIKFL_TF_VON_NGF: 3.62,
    GB_VERKEHRSFL_VF_VON_NGF: 95.41,
    GB_MIETFL_GESAMT: 395.41,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992595,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 406.15,
    GB_NUTZFL_NF_VON_NGF: 307.13,
    GB_TECHNIKFL_TF_VON_NGF: 3.82,
    GB_VERKEHRSFL_VF_VON_NGF: 95.2,
    GB_MIETFL_GESAMT: 406.15,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992596,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747036,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747036',
    GB_SAP_GB_ID_PKF: '7400/747036/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.17',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6950192,
    GB_WE_OBJ_ID_FKT: 1989453,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1583.9,
    GB_NUTZFL_NF_VON_NGF: 1190.66,
    GB_TECHNIKFL_TF_VON_NGF: 12.37,
    GB_VERKEHRSFL_VF_VON_NGF: 380.87,
    GB_MIETFL_GESAMT: 1583.9,
    GB_MIETFL_1_BSB: 1580.82,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992447,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 767.95,
    GB_NUTZFL_NF_VON_NGF: 606.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 161.47,
    GB_MIETFL_GESAMT: 767.95,
    GB_MIETFL_1_BSB: 767.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992448,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 994.62,
    GB_NUTZFL_NF_VON_NGF: 682.34,
    GB_TECHNIKFL_TF_VON_NGF: 77.43,
    GB_VERKEHRSFL_VF_VON_NGF: 234.85,
    GB_MIETFL_GESAMT: 994.62,
    GB_MIETFL_1_BSB: 767.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078243,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 313.75,
    GB_NUTZFL_NF_VON_NGF: 262.1,
    GB_TECHNIKFL_TF_VON_NGF: 1.07,
    GB_VERKEHRSFL_VF_VON_NGF: 50.58,
    GB_MIETFL_GESAMT: 313.75,
    GB_MIETFL_1_BSB: 201.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078244,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 322.9,
    GB_NUTZFL_NF_VON_NGF: 302.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.48,
    GB_MIETFL_GESAMT: 322.9,
    GB_MIETFL_1_BSB: 322.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078245,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1982,
    GB_BREITENGRAD_NOERDLICH: '53.470759',
    GB_LAENGENGRAD_OESTLICH: '9.817279',
    GB_NETTOGRUNDFL_NGF: 658.36,
    GB_NUTZFL_NF_VON_NGF: 612.57,
    GB_TECHNIKFL_TF_VON_NGF: 16.72,
    GB_VERKEHRSFL_VF_VON_NGF: 29.07,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '03.05.23',
    GB_GB_OBJ_ID_PKT: 1992449,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/7',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.47,
    GB_NUTZFL_NF_VON_NGF: 16.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.47,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138320,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6910394,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/9',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6910395,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 225.21,
    GB_NUTZFL_NF_VON_NGF: 205.21,
    GB_TECHNIKFL_TF_VON_NGF: 2.99,
    GB_VERKEHRSFL_VF_VON_NGF: 17.01,
    GB_MIETFL_GESAMT: 225.21,
    GB_MIETFL_1_BSB: 225.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7121551,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 181.69,
    GB_NUTZFL_NF_VON_NGF: 143.25,
    GB_TECHNIKFL_TF_VON_NGF: 1.5,
    GB_VERKEHRSFL_VF_VON_NGF: 36.94,
    GB_MIETFL_GESAMT: 181.69,
    GB_MIETFL_1_BSB: 181.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '09.08.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7365933,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747017,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747017',
    GB_SAP_GB_ID_PKF: '7400/747017/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 170.0,
    GB_NUTZFL_NF_VON_NGF: 170.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552766,
    GB_WE_OBJ_ID_FKT: 1989435,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.477986',
    GB_LAENGENGRAD_OESTLICH: '9.868672',
    GB_NETTOGRUNDFL_NGF: 662.45,
    GB_NUTZFL_NF_VON_NGF: 621.83,
    GB_TECHNIKFL_TF_VON_NGF: 12.04,
    GB_VERKEHRSFL_VF_VON_NGF: 28.58,
    GB_MIETFL_GESAMT: 662.45,
    GB_MIETFL_1_BSB: 662.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992708,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.479495',
    GB_LAENGENGRAD_OESTLICH: '9.869631',
    GB_NETTOGRUNDFL_NGF: 1675.16,
    GB_NUTZFL_NF_VON_NGF: 1580.02,
    GB_TECHNIKFL_TF_VON_NGF: 32.05,
    GB_VERKEHRSFL_VF_VON_NGF: 63.09,
    GB_MIETFL_GESAMT: 1675.16,
    GB_MIETFL_1_BSB: 1675.16,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992709,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1626.75,
    GB_NUTZFL_NF_VON_NGF: 1242.89,
    GB_TECHNIKFL_TF_VON_NGF: 63.94,
    GB_VERKEHRSFL_VF_VON_NGF: 319.92,
    GB_MIETFL_GESAMT: 1626.75,
    GB_MIETFL_1_BSB: 1626.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992710,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793202,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2008.23,
    GB_NUTZFL_NF_VON_NGF: 1439.16,
    GB_TECHNIKFL_TF_VON_NGF: 72.27,
    GB_VERKEHRSFL_VF_VON_NGF: 496.8,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '17.12.19',
    GB_GB_OBJ_ID_PKT: 1992712,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 360.51,
    GB_NUTZFL_NF_VON_NGF: 293.91,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 66.6,
    GB_MIETFL_GESAMT: 360.51,
    GB_MIETFL_1_BSB: 360.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992713,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 102.64,
    GB_NUTZFL_NF_VON_NGF: 86.54,
    GB_TECHNIKFL_TF_VON_NGF: 4.08,
    GB_VERKEHRSFL_VF_VON_NGF: 12.02,
    GB_MIETFL_GESAMT: 102.64,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992714,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1398.78,
    GB_NUTZFL_NF_VON_NGF: 973.25,
    GB_TECHNIKFL_TF_VON_NGF: 20.28,
    GB_VERKEHRSFL_VF_VON_NGF: 405.25,
    GB_MIETFL_GESAMT: 1398.78,
    GB_MIETFL_1_BSB: 1390.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5432903,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 721.02,
    GB_NUTZFL_NF_VON_NGF: 589.46,
    GB_TECHNIKFL_TF_VON_NGF: 6.2,
    GB_VERKEHRSFL_VF_VON_NGF: 125.36,
    GB_MIETFL_GESAMT: 721.02,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 721.02,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5928494,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747050,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747050',
    GB_SAP_GB_ID_PKF: '7400/747050/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.479482582',
    GB_LAENGENGRAD_OESTLICH: '9.8685266103',
    GB_NETTOGRUNDFL_NGF: 1261.96,
    GB_NUTZFL_NF_VON_NGF: 936.07,
    GB_TECHNIKFL_TF_VON_NGF: 23.42,
    GB_VERKEHRSFL_VF_VON_NGF: 302.47,
    GB_MIETFL_GESAMT: 1261.96,
    GB_MIETFL_1_BSB: 1261.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.11.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6929609,
    GB_WE_OBJ_ID_FKT: 1989466,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 839.78,
    GB_NUTZFL_NF_VON_NGF: 570.51,
    GB_TECHNIKFL_TF_VON_NGF: 43.21,
    GB_VERKEHRSFL_VF_VON_NGF: 226.06,
    GB_MIETFL_GESAMT: 839.78,
    GB_MIETFL_1_BSB: 719.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992450,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 732.1,
    GB_NUTZFL_NF_VON_NGF: 610.16,
    GB_TECHNIKFL_TF_VON_NGF: 0.6,
    GB_VERKEHRSFL_VF_VON_NGF: 121.34,
    GB_MIETFL_GESAMT: 732.1,
    GB_MIETFL_1_BSB: 499.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992451,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 201.74,
    GB_NUTZFL_NF_VON_NGF: 171.61,
    GB_TECHNIKFL_TF_VON_NGF: 4.42,
    GB_VERKEHRSFL_VF_VON_NGF: 25.71,
    GB_MIETFL_GESAMT: 201.74,
    GB_MIETFL_1_BSB: 201.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992452,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.466601',
    GB_LAENGENGRAD_OESTLICH: '9.839817',
    GB_NETTOGRUNDFL_NGF: 664.47,
    GB_NUTZFL_NF_VON_NGF: 602.16,
    GB_TECHNIKFL_TF_VON_NGF: 16.58,
    GB_VERKEHRSFL_VF_VON_NGF: 45.73,
    GB_MIETFL_GESAMT: 664.47,
    GB_MIETFL_1_BSB: 664.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992453,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/5',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 13.36,
    GB_NUTZFL_NF_VON_NGF: 13.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 13.36,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992454,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/6',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.41,
    GB_NUTZFL_NF_VON_NGF: 16.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.41,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992455,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 996.81,
    GB_NUTZFL_NF_VON_NGF: 848.23,
    GB_TECHNIKFL_TF_VON_NGF: 0.88,
    GB_VERKEHRSFL_VF_VON_NGF: 147.7,
    GB_MIETFL_GESAMT: 996.81,
    GB_MIETFL_1_BSB: 754.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078246,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 987.27,
    GB_NUTZFL_NF_VON_NGF: 719.04,
    GB_TECHNIKFL_TF_VON_NGF: 109.9,
    GB_VERKEHRSFL_VF_VON_NGF: 158.33,
    GB_MIETFL_GESAMT: 987.27,
    GB_MIETFL_1_BSB: 749.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078247,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 980.83,
    GB_NUTZFL_NF_VON_NGF: 802.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 178.39,
    GB_MIETFL_GESAMT: 980.83,
    GB_MIETFL_1_BSB: 749.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078248,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747018,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747018',
    GB_SAP_GB_ID_PKF: '7400/747018/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552767,
    GB_WE_OBJ_ID_FKT: 1989436,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 100.04,
    GB_NUTZFL_NF_VON_NGF: 61.04,
    GB_TECHNIKFL_TF_VON_NGF: 19.6,
    GB_VERKEHRSFL_VF_VON_NGF: 19.4,
    GB_MIETFL_GESAMT: 100.04,
    GB_MIETFL_1_BSB: 100.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992699,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 141.75,
    GB_NUTZFL_NF_VON_NGF: 141.75,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 0.0,
    GB_MIETFL_GESAMT: 141.75,
    GB_MIETFL_1_BSB: 141.75,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992700,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.468908',
    GB_LAENGENGRAD_OESTLICH: '10.036895',
    GB_NETTOGRUNDFL_NGF: 434.86,
    GB_NUTZFL_NF_VON_NGF: 395.82,
    GB_TECHNIKFL_TF_VON_NGF: 7.27,
    GB_VERKEHRSFL_VF_VON_NGF: 31.77,
    GB_MIETFL_GESAMT: 434.86,
    GB_MIETFL_1_BSB: 434.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992701,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 868.64,
    GB_NUTZFL_NF_VON_NGF: 696.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 171.65,
    GB_MIETFL_GESAMT: 868.64,
    GB_MIETFL_1_BSB: 868.64,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992702,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 98.21,
    GB_NUTZFL_NF_VON_NGF: 92.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.52,
    GB_MIETFL_GESAMT: 98.21,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992703,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 207.39,
    GB_NUTZFL_NF_VON_NGF: 153.76,
    GB_TECHNIKFL_TF_VON_NGF: 35.08,
    GB_VERKEHRSFL_VF_VON_NGF: 18.55,
    GB_MIETFL_GESAMT: 207.39,
    GB_MIETFL_1_BSB: 207.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '22.12.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992704,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '05.09.22',
    GB_GB_OBJ_ID_PKT: 1992705,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '10.06.22',
    GB_GB_OBJ_ID_PKT: 1992706,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '05.09.22',
    GB_GB_OBJ_ID_PKT: 1992707,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2022,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 942.94,
    GB_NUTZFL_NF_VON_NGF: 858.69,
    GB_TECHNIKFL_TF_VON_NGF: 7.92,
    GB_VERKEHRSFL_VF_VON_NGF: 76.33,
    GB_MIETFL_GESAMT: 942.94,
    GB_MIETFL_1_BSB: 940.68,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '10.06.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5431017,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056611,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747049,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747049',
    GB_SAP_GB_ID_PKF: '7400/747049/12',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056612,
    GB_WE_OBJ_ID_FKT: 1989465,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.438949',
    GB_LAENGENGRAD_OESTLICH: '10.007777',
    GB_NETTOGRUNDFL_NGF: 244.6,
    GB_NUTZFL_NF_VON_NGF: 228.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.1,
    GB_MIETFL_GESAMT: 244.6,
    GB_MIETFL_1_BSB: 244.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992716,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 224.53,
    GB_NUTZFL_NF_VON_NGF: 199.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 24.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '25.05.21',
    GB_GB_OBJ_ID_PKT: 1992721,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.438881',
    GB_LAENGENGRAD_OESTLICH: '10.008731',
    GB_NETTOGRUNDFL_NGF: 215.36,
    GB_NUTZFL_NF_VON_NGF: 215.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 215.36,
    GB_MIETFL_1_BSB: 215.36,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992722,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747070',
    GB_SAP_GB_ID_PKF: '7400/747070/1',
    GB_SAP_GB_BEZEICHNUNG: 'Bootshaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bootshaus',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: '53.470796174',
    GB_LAENGENGRAD_OESTLICH: '10.002334646',
    GB_NETTOGRUNDFL_NGF: 643.89,
    GB_NUTZFL_NF_VON_NGF: 556.49,
    GB_TECHNIKFL_TF_VON_NGF: 8.82,
    GB_VERKEHRSFL_VF_VON_NGF: 78.58,
    GB_MIETFL_GESAMT: 643.89,
    GB_MIETFL_1_BSB: 643.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '30.05.13',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793230,
    GB_WE_OBJ_ID_FKT: 6793124,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.439141089',
    GB_LAENGENGRAD_OESTLICH: '10.007865395',
    GB_NETTOGRUNDFL_NGF: 867.69,
    GB_NUTZFL_NF_VON_NGF: 657.92,
    GB_TECHNIKFL_TF_VON_NGF: 76.03,
    GB_VERKEHRSFL_VF_VON_NGF: 133.74,
    GB_MIETFL_GESAMT: 867.69,
    GB_MIETFL_1_BSB: 579.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992715,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.438983',
    GB_LAENGENGRAD_OESTLICH: '10.009687',
    GB_NETTOGRUNDFL_NGF: 664.08,
    GB_NUTZFL_NF_VON_NGF: 618.73,
    GB_TECHNIKFL_TF_VON_NGF: 16.45,
    GB_VERKEHRSFL_VF_VON_NGF: 28.9,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.09.22',
    GB_GB_OBJ_ID_PKT: 1992717,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.438766',
    GB_LAENGENGRAD_OESTLICH: '10.008324',
    GB_NETTOGRUNDFL_NGF: 465.4,
    GB_NUTZFL_NF_VON_NGF: 417.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.01,
    GB_MIETFL_GESAMT: 465.4,
    GB_MIETFL_1_BSB: 465.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992718,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.438549',
    GB_LAENGENGRAD_OESTLICH: '10.008463',
    GB_NETTOGRUNDFL_NGF: 464.3,
    GB_NUTZFL_NF_VON_NGF: 416.29,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.01,
    GB_MIETFL_GESAMT: 464.3,
    GB_MIETFL_1_BSB: 464.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992719,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.438440',
    GB_LAENGENGRAD_OESTLICH: '10.008742',
    GB_NETTOGRUNDFL_NGF: 465.69,
    GB_NUTZFL_NF_VON_NGF: 417.68,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 48.01,
    GB_MIETFL_GESAMT: 465.69,
    GB_MIETFL_1_BSB: 465.69,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '11.11.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992720,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.438792',
    GB_LAENGENGRAD_OESTLICH: '10.009064',
    GB_NETTOGRUNDFL_NGF: 214.18,
    GB_NUTZFL_NF_VON_NGF: 214.18,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 214.18,
    GB_MIETFL_1_BSB: 214.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '6',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992723,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.438683',
    GB_LAENGENGRAD_OESTLICH: '10.009225',
    GB_NETTOGRUNDFL_NGF: 0.0,
    GB_NUTZFL_NF_VON_NGF: 0.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.09.22',
    GB_GB_OBJ_ID_PKT: 1992724,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.438951',
    GB_LAENGENGRAD_OESTLICH: '10.008098',
    GB_NETTOGRUNDFL_NGF: 144.17,
    GB_NUTZFL_NF_VON_NGF: 131.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 12.27,
    GB_MIETFL_GESAMT: 144.17,
    GB_MIETFL_1_BSB: 144.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992725,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.439558',
    GB_LAENGENGRAD_OESTLICH: '10.007637',
    GB_NETTOGRUNDFL_NGF: 455.03,
    GB_NUTZFL_NF_VON_NGF: 363.19,
    GB_TECHNIKFL_TF_VON_NGF: 4.88,
    GB_VERKEHRSFL_VF_VON_NGF: 86.96,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '23.09.22',
    GB_GB_OBJ_ID_PKT: 1992726,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/13',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1967,
    GB_BREITENGRAD_NOERDLICH: '53.439286',
    GB_LAENGENGRAD_OESTLICH: '10.007964',
    GB_NETTOGRUNDFL_NGF: 166.84,
    GB_NUTZFL_NF_VON_NGF: 112.86,
    GB_TECHNIKFL_TF_VON_NGF: 4.76,
    GB_VERKEHRSFL_VF_VON_NGF: 49.22,
    GB_MIETFL_GESAMT: 166.84,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992727,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/14',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.438772',
    GB_LAENGENGRAD_OESTLICH: '10.007748',
    GB_NETTOGRUNDFL_NGF: 1086.54,
    GB_NUTZFL_NF_VON_NGF: 864.36,
    GB_TECHNIKFL_TF_VON_NGF: 37.72,
    GB_VERKEHRSFL_VF_VON_NGF: 184.46,
    GB_MIETFL_GESAMT: 1086.54,
    GB_MIETFL_1_BSB: 1081.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793203,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: '53.439127',
    GB_LAENGENGRAD_OESTLICH: '10.008413',
    GB_NETTOGRUNDFL_NGF: 1360.18,
    GB_NUTZFL_NF_VON_NGF: 1109.49,
    GB_TECHNIKFL_TF_VON_NGF: 3.37,
    GB_VERKEHRSFL_VF_VON_NGF: 247.32,
    GB_MIETFL_GESAMT: 1360.18,
    GB_MIETFL_1_BSB: 1358.11,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '10.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5434613,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/16',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7097073,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747051,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747051',
    GB_SAP_GB_ID_PKF: '7400/747051/17',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Sporthalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: '53.439003089',
    GB_LAENGENGRAD_OESTLICH: '10.009735145',
    GB_NETTOGRUNDFL_NGF: 1148.29,
    GB_NUTZFL_NF_VON_NGF: 1131.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.48,
    GB_MIETFL_GESAMT: 1148.29,
    GB_MIETFL_1_BSB: 1148.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '12.07.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7106734,
    GB_WE_OBJ_ID_FKT: 1989467,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.427174',
    GB_LAENGENGRAD_OESTLICH: '9.987348',
    GB_NETTOGRUNDFL_NGF: 663.95,
    GB_NUTZFL_NF_VON_NGF: 621.24,
    GB_TECHNIKFL_TF_VON_NGF: 11.71,
    GB_VERKEHRSFL_VF_VON_NGF: 31.0,
    GB_MIETFL_GESAMT: 663.95,
    GB_MIETFL_1_BSB: 663.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992733,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3176.99,
    GB_NUTZFL_NF_VON_NGF: 2334.52,
    GB_TECHNIKFL_TF_VON_NGF: 108.82,
    GB_VERKEHRSFL_VF_VON_NGF: 733.65,
    GB_MIETFL_GESAMT: 3176.99,
    GB_MIETFL_1_BSB: 2767.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992734,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.75,
    GB_NUTZFL_NF_VON_NGF: 178.56,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.19,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.22',
    GB_GB_OBJ_ID_PKT: 1992735,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '01.07.22',
    GB_GB_OBJ_ID_PKT: 1992736,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/5',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2008,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 931.67,
    GB_NUTZFL_NF_VON_NGF: 795.8,
    GB_TECHNIKFL_TF_VON_NGF: 21.0,
    GB_VERKEHRSFL_VF_VON_NGF: 114.87,
    GB_MIETFL_GESAMT: 931.67,
    GB_MIETFL_1_BSB: 931.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.08',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992737,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2058.82,
    GB_NUTZFL_NF_VON_NGF: 1537.03,
    GB_TECHNIKFL_TF_VON_NGF: 12.68,
    GB_VERKEHRSFL_VF_VON_NGF: 509.11,
    GB_MIETFL_GESAMT: 2058.82,
    GB_MIETFL_1_BSB: 2054.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '15.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7033166,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/7',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Sporthalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.427209377',
    GB_LAENGENGRAD_OESTLICH: '9.9867250516',
    GB_NETTOGRUNDFL_NGF: 639.94,
    GB_NUTZFL_NF_VON_NGF: 619.83,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 20.11,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045114,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747053,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747053',
    GB_SAP_GB_ID_PKF: '7400/747053/8',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045115,
    GB_WE_OBJ_ID_FKT: 1989469,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 3127.9,
    GB_NUTZFL_NF_VON_NGF: 3127.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992798,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992799,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992800,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992801,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992802,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992803,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992804,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/8',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1905,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992805,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337415,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337416,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747059,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747059',
    GB_SAP_GB_ID_PKF: '7400/747059/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5337417,
    GB_WE_OBJ_ID_FKT: 1989474,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992559,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992560,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992561,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/4',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992562,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992563,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992564,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992565,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992566,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992567,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747033,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747033',
    GB_SAP_GB_ID_PKF: '7400/747033/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '9',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992568,
    GB_WE_OBJ_ID_FKT: 1989450,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 584.29,
    GB_NUTZFL_NF_VON_NGF: 489.63,
    GB_TECHNIKFL_TF_VON_NGF: 2.03,
    GB_VERKEHRSFL_VF_VON_NGF: 92.63,
    GB_MIETFL_GESAMT: 584.29,
    GB_MIETFL_1_BSB: 584.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992514,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 609.44,
    GB_NUTZFL_NF_VON_NGF: 571.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 37.82,
    GB_MIETFL_GESAMT: 609.44,
    GB_MIETFL_1_BSB: 609.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992515,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1978,
    GB_BREITENGRAD_NOERDLICH: '53.487480',
    GB_LAENGENGRAD_OESTLICH: '10.025020',
    GB_NETTOGRUNDFL_NGF: 661.98,
    GB_NUTZFL_NF_VON_NGF: 615.26,
    GB_TECHNIKFL_TF_VON_NGF: 17.67,
    GB_VERKEHRSFL_VF_VON_NGF: 29.05,
    GB_MIETFL_GESAMT: 661.98,
    GB_MIETFL_1_BSB: 661.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992516,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 708.08,
    GB_NUTZFL_NF_VON_NGF: 604.11,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.97,
    GB_MIETFL_GESAMT: 708.08,
    GB_MIETFL_1_BSB: 708.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992517,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 714.22,
    GB_NUTZFL_NF_VON_NGF: 610.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.83,
    GB_MIETFL_GESAMT: 714.22,
    GB_MIETFL_1_BSB: 714.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992518,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 710.67,
    GB_NUTZFL_NF_VON_NGF: 561.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 149.06,
    GB_MIETFL_GESAMT: 710.67,
    GB_MIETFL_1_BSB: 710.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992519,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 945.19,
    GB_NUTZFL_NF_VON_NGF: 682.65,
    GB_TECHNIKFL_TF_VON_NGF: 70.6,
    GB_VERKEHRSFL_VF_VON_NGF: 191.94,
    GB_MIETFL_GESAMT: 945.19,
    GB_MIETFL_1_BSB: 706.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992520,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 510.17,
    GB_NUTZFL_NF_VON_NGF: 453.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 56.92,
    GB_MIETFL_GESAMT: 510.17,
    GB_MIETFL_1_BSB: 510.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992521,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/9',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 189.51,
    GB_NUTZFL_NF_VON_NGF: 129.51,
    GB_TECHNIKFL_TF_VON_NGF: 31.66,
    GB_VERKEHRSFL_VF_VON_NGF: 28.34,
    GB_MIETFL_GESAMT: 189.51,
    GB_MIETFL_1_BSB: 189.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992522,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 110.28,
    GB_NUTZFL_NF_VON_NGF: 93.36,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.92,
    GB_MIETFL_GESAMT: 110.28,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992523,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747028,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747028',
    GB_SAP_GB_ID_PKF: '7400/747028/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.11.15',
    GB_GEBAEUDE_KLASSE_ALT: '9',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.10.20',
    GB_GB_OBJ_ID_PKT: 2078149,
    GB_WE_OBJ_ID_FKT: 1989445,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Lager',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 121.22,
    GB_NUTZFL_NF_VON_NGF: 121.22,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.06.23',
    GB_GB_OBJ_ID_PKT: 1992738,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 412.25,
    GB_NUTZFL_NF_VON_NGF: 307.73,
    GB_TECHNIKFL_TF_VON_NGF: 48.65,
    GB_VERKEHRSFL_VF_VON_NGF: 55.87,
    GB_MIETFL_GESAMT: 412.25,
    GB_MIETFL_1_BSB: 412.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992739,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 152.53,
    GB_NUTZFL_NF_VON_NGF: 138.09,
    GB_TECHNIKFL_TF_VON_NGF: 0.9,
    GB_VERKEHRSFL_VF_VON_NGF: 13.54,
    GB_MIETFL_GESAMT: 152.53,
    GB_MIETFL_1_BSB: 152.53,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992740,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 505.39,
    GB_NUTZFL_NF_VON_NGF: 437.94,
    GB_TECHNIKFL_TF_VON_NGF: 14.71,
    GB_VERKEHRSFL_VF_VON_NGF: 52.74,
    GB_MIETFL_GESAMT: 505.39,
    GB_MIETFL_1_BSB: 505.39,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.09.16',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992741,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/6',
    GB_SAP_GB_BEZEICHNUNG: 'Lager',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Lager',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 16.08,
    GB_NUTZFL_NF_VON_NGF: 16.08,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 16.08,
    GB_MIETFL_1_BSB: 16.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992742,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.02,
    GB_NUTZFL_NF_VON_NGF: 360.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 93.48,
    GB_MIETFL_GESAMT: 454.02,
    GB_MIETFL_1_BSB: 454.02,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992743,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 454.4,
    GB_NUTZFL_NF_VON_NGF: 351.84,
    GB_TECHNIKFL_TF_VON_NGF: 8.95,
    GB_VERKEHRSFL_VF_VON_NGF: 93.61,
    GB_MIETFL_GESAMT: 454.4,
    GB_MIETFL_1_BSB: 454.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992744,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 446.18,
    GB_NUTZFL_NF_VON_NGF: 342.25,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 103.93,
    GB_MIETFL_GESAMT: 446.18,
    GB_MIETFL_1_BSB: 446.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992745,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 742.18,
    GB_NUTZFL_NF_VON_NGF: 277.96,
    GB_TECHNIKFL_TF_VON_NGF: 6.73,
    GB_VERKEHRSFL_VF_VON_NGF: 457.49,
    GB_MIETFL_GESAMT: 742.18,
    GB_MIETFL_1_BSB: 742.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992746,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.483601',
    GB_LAENGENGRAD_OESTLICH: '10.016685',
    GB_NETTOGRUNDFL_NGF: 1385.93,
    GB_NUTZFL_NF_VON_NGF: 1335.47,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 50.46,
    GB_MIETFL_GESAMT: 1385.93,
    GB_MIETFL_1_BSB: 1385.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992747,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/12',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 4387.95,
    GB_NUTZFL_NF_VON_NGF: 2493.52,
    GB_TECHNIKFL_TF_VON_NGF: 56.73,
    GB_VERKEHRSFL_VF_VON_NGF: 1837.7,
    GB_MIETFL_GESAMT: 4387.95,
    GB_MIETFL_1_BSB: 4378.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992748,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/13',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 2003,
    GB_BREITENGRAD_NOERDLICH: '53.482774',
    GB_LAENGENGRAD_OESTLICH: '10.016637',
    GB_NETTOGRUNDFL_NGF: 1835.68,
    GB_NUTZFL_NF_VON_NGF: 1382.87,
    GB_TECHNIKFL_TF_VON_NGF: 111.86,
    GB_VERKEHRSFL_VF_VON_NGF: 340.95,
    GB_MIETFL_GESAMT: 1835.68,
    GB_MIETFL_1_BSB: 1831.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992749,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793206,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793207,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793208,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/17',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793209,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/18',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793210,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/19',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793211,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/20',
    GB_SAP_GB_BEZEICHNUNG: 'Sport- und Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sport- und Mehrzweckgeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 2987.03,
    GB_NUTZFL_NF_VON_NGF: 2165.64,
    GB_TECHNIKFL_TF_VON_NGF: 30.99,
    GB_VERKEHRSFL_VF_VON_NGF: 790.4,
    GB_MIETFL_GESAMT: 2987.03,
    GB_MIETFL_1_BSB: 2980.29,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5461606,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/21',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 481.8,
    GB_NUTZFL_NF_VON_NGF: 362.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 119.52,
    GB_MIETFL_GESAMT: 481.8,
    GB_MIETFL_1_BSB: 481.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '17.12.21',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5921873,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/22',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5921874,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 23,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/23',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5921875,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 29,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/29',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.57,
    GB_NUTZFL_NF_VON_NGF: 182.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.06.23',
    GB_GB_OBJ_ID_PKT: 5336970,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 30,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/30',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793212,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 31,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/31',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 223.81,
    GB_NUTZFL_NF_VON_NGF: 188.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.19',
    GB_GB_OBJ_ID_PKT: 2078151,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 32,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/32',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 229.31,
    GB_NUTZFL_NF_VON_NGF: 194.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.19',
    GB_GB_OBJ_ID_PKT: 2078152,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 33,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/33',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 223.81,
    GB_NUTZFL_NF_VON_NGF: 188.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.18,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.19',
    GB_GB_OBJ_ID_PKT: 2078153,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 34,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/34',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 229.31,
    GB_NUTZFL_NF_VON_NGF: 194.13,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.18,
    GB_MIETFL_GESAMT: 229.31,
    GB_MIETFL_1_BSB: 229.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078154,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 35,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/35',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 160.46,
    GB_NUTZFL_NF_VON_NGF: 121.27,
    GB_TECHNIKFL_TF_VON_NGF: 11.5,
    GB_VERKEHRSFL_VF_VON_NGF: 27.69,
    GB_MIETFL_GESAMT: 160.46,
    GB_MIETFL_1_BSB: 160.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '15.09.16',
    GB_GEBAEUDE_KLASSE_ALT: '6',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078155,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 37,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/37',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 931.3,
    GB_NUTZFL_NF_VON_NGF: 835.79,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 95.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '12.08.19',
    GB_GB_OBJ_ID_PKT: 2078156,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 39,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/39',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 214.57,
    GB_NUTZFL_NF_VON_NGF: 182.37,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.06.23',
    GB_GB_OBJ_ID_PKT: 2078157,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '27.06.22',
    GB_GB_OBJ_ID_PKT: 5450619,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747054,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747054',
    GB_SAP_GB_ID_PKF: '7400/747054/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '27.06.22',
    GB_GB_OBJ_ID_PKT: 6994917,
    GB_WE_OBJ_ID_FKT: 1989470,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793163,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793164,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793165,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793166,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793167,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793168,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793169,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793170,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793171,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793172,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793173,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/21',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.438007515',
    GB_LAENGENGRAD_OESTLICH: '9.9924467243',
    GB_NETTOGRUNDFL_NGF: 3020.58,
    GB_NUTZFL_NF_VON_NGF: 2301.82,
    GB_TECHNIKFL_TF_VON_NGF: 69.77,
    GB_VERKEHRSFL_VF_VON_NGF: 648.99,
    GB_MIETFL_GESAMT: 3020.58,
    GB_MIETFL_1_BSB: 3014.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6987036,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/22',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045863,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 27,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/27',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450905,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 28,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/28',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450906,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 29,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/29',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450907,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 36,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/36',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5434612,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 37,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/37',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450612,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 30,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/30',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.437571524',
    GB_LAENGENGRAD_OESTLICH: '9.9928224884',
    GB_NETTOGRUNDFL_NGF: 1799.23,
    GB_NUTZFL_NF_VON_NGF: 1389.32,
    GB_TECHNIKFL_TF_VON_NGF: 25.09,
    GB_VERKEHRSFL_VF_VON_NGF: 384.82,
    GB_MIETFL_GESAMT: 1799.23,
    GB_MIETFL_1_BSB: 1793.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335715,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 31,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/31',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.437451921',
    GB_LAENGENGRAD_OESTLICH: '9.9919771225',
    GB_NETTOGRUNDFL_NGF: 3074.96,
    GB_NUTZFL_NF_VON_NGF: 2476.2,
    GB_TECHNIKFL_TF_VON_NGF: 26.32,
    GB_VERKEHRSFL_VF_VON_NGF: 572.44,
    GB_MIETFL_GESAMT: 3074.96,
    GB_MIETFL_1_BSB: 2581.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335716,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 32,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/32',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.437871403',
    GB_LAENGENGRAD_OESTLICH: '9.9917165309',
    GB_NETTOGRUNDFL_NGF: 1775.1,
    GB_NUTZFL_NF_VON_NGF: 1416.39,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 358.71,
    GB_MIETFL_GESAMT: 1775.1,
    GB_MIETFL_1_BSB: 1769.08,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.11.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335717,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 33,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/33',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.438233419',
    GB_LAENGENGRAD_OESTLICH: '9.9911275641',
    GB_NETTOGRUNDFL_NGF: 1519.98,
    GB_NUTZFL_NF_VON_NGF: 1068.36,
    GB_TECHNIKFL_TF_VON_NGF: 74.81,
    GB_VERKEHRSFL_VF_VON_NGF: 376.81,
    GB_MIETFL_GESAMT: 1519.98,
    GB_MIETFL_1_BSB: 1457.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335718,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 34,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/34',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.437887363',
    GB_LAENGENGRAD_OESTLICH: '9.9909920578',
    GB_NETTOGRUNDFL_NGF: 2385.44,
    GB_NUTZFL_NF_VON_NGF: 2179.91,
    GB_TECHNIKFL_TF_VON_NGF: 83.36,
    GB_VERKEHRSFL_VF_VON_NGF: 122.17,
    GB_MIETFL_GESAMT: 2385.44,
    GB_MIETFL_1_BSB: 2385.44,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.12.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335719,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 38,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/38',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450613,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 39,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/39',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5450614,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747007,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747007',
    GB_SAP_GB_ID_PKF: '7400/747007/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 170.0,
    GB_NUTZFL_NF_VON_NGF: 170.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552765,
    GB_WE_OBJ_ID_FKT: 1989426,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.445810',
    GB_LAENGENGRAD_OESTLICH: '9.988875',
    GB_NETTOGRUNDFL_NGF: 321.5,
    GB_NUTZFL_NF_VON_NGF: 285.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 35.57,
    GB_MIETFL_GESAMT: 321.5,
    GB_MIETFL_1_BSB: 321.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992640,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.445783',
    GB_LAENGENGRAD_OESTLICH: '.988584',
    GB_NETTOGRUNDFL_NGF: 357.13,
    GB_NUTZFL_NF_VON_NGF: 315.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 41.48,
    GB_MIETFL_GESAMT: 357.13,
    GB_MIETFL_1_BSB: 357.13,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992641,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: '53.445405',
    GB_LAENGENGRAD_OESTLICH: '9.988439',
    GB_NETTOGRUNDFL_NGF: 595.72,
    GB_NUTZFL_NF_VON_NGF: 571.03,
    GB_TECHNIKFL_TF_VON_NGF: 15.25,
    GB_VERKEHRSFL_VF_VON_NGF: 9.44,
    GB_MIETFL_GESAMT: 595.72,
    GB_MIETFL_1_BSB: 595.72,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992642,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.445627',
    GB_LAENGENGRAD_OESTLICH: '9.989305',
    GB_NETTOGRUNDFL_NGF: 477.05,
    GB_NUTZFL_NF_VON_NGF: 414.23,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 59.19,
    GB_MIETFL_GESAMT: 477.05,
    GB_MIETFL_1_BSB: 477.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992643,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.445276',
    GB_LAENGENGRAD_OESTLICH: '9.989412',
    GB_NETTOGRUNDFL_NGF: 477.05,
    GB_NUTZFL_NF_VON_NGF: 414.23,
    GB_TECHNIKFL_TF_VON_NGF: 3.63,
    GB_VERKEHRSFL_VF_VON_NGF: 59.19,
    GB_MIETFL_GESAMT: 477.05,
    GB_MIETFL_1_BSB: 477.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992644,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.445051936',
    GB_LAENGENGRAD_OESTLICH: '9.9885462074',
    GB_NETTOGRUNDFL_NGF: 663.03,
    GB_NUTZFL_NF_VON_NGF: 501.66,
    GB_TECHNIKFL_TF_VON_NGF: 17.86,
    GB_VERKEHRSFL_VF_VON_NGF: 143.51,
    GB_MIETFL_GESAMT: 663.03,
    GB_MIETFL_1_BSB: 443.97,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992645,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.445162448',
    GB_LAENGENGRAD_OESTLICH: '9.9888749350',
    GB_NETTOGRUNDFL_NGF: 836.88,
    GB_NUTZFL_NF_VON_NGF: 552.57,
    GB_TECHNIKFL_TF_VON_NGF: 2.87,
    GB_VERKEHRSFL_VF_VON_NGF: 281.44,
    GB_MIETFL_GESAMT: 836.88,
    GB_MIETFL_1_BSB: 719.88,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992646,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1915,
    GB_BREITENGRAD_NOERDLICH: '53.445972347',
    GB_LAENGENGRAD_OESTLICH: '9.9882378922',
    GB_NETTOGRUNDFL_NGF: 2722.99,
    GB_NUTZFL_NF_VON_NGF: 1966.37,
    GB_TECHNIKFL_TF_VON_NGF: 41.62,
    GB_VERKEHRSFL_VF_VON_NGF: 715.0,
    GB_MIETFL_GESAMT: 2722.99,
    GB_MIETFL_1_BSB: 1686.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992647,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1107.71,
    GB_NUTZFL_NF_VON_NGF: 641.59,
    GB_TECHNIKFL_TF_VON_NGF: 2.18,
    GB_VERKEHRSFL_VF_VON_NGF: 463.94,
    GB_MIETFL_GESAMT: 1107.71,
    GB_MIETFL_1_BSB: 1107.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2138322,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749014,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749014',
    GB_SAP_GB_ID_PKF: '7400/749014/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800178,
    GB_WE_OBJ_ID_FKT: 6793136,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795265,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795266,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/9',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.445564962',
    GB_LAENGENGRAD_OESTLICH: '9.9881594912',
    GB_NETTOGRUNDFL_NGF: 149.93,
    GB_NUTZFL_NF_VON_NGF: 121.16,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 28.77,
    GB_MIETFL_GESAMT: 149.93,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992648,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793193,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2010,
    GB_BREITENGRAD_NOERDLICH: '53.446198',
    GB_LAENGENGRAD_OESTLICH: '9.988568',
    GB_NETTOGRUNDFL_NGF: 919.67,
    GB_NUTZFL_NF_VON_NGF: 736.64,
    GB_TECHNIKFL_TF_VON_NGF: 13.51,
    GB_VERKEHRSFL_VF_VON_NGF: 169.52,
    GB_MIETFL_GESAMT: 919.67,
    GB_MIETFL_1_BSB: 901.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992650,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747043,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747043',
    GB_SAP_GB_ID_PKF: '7400/747043/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '19.11.21',
    GB_GB_OBJ_ID_PKT: 6940208,
    GB_WE_OBJ_ID_FKT: 1989459,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1373.28,
    GB_NUTZFL_NF_VON_NGF: 868.76,
    GB_TECHNIKFL_TF_VON_NGF: 32.22,
    GB_VERKEHRSFL_VF_VON_NGF: 472.3,
    GB_MIETFL_GESAMT: 1373.28,
    GB_MIETFL_1_BSB: 1103.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992497,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.438641',
    GB_LAENGENGRAD_OESTLICH: '9.992140',
    GB_NETTOGRUNDFL_NGF: 614.93,
    GB_NUTZFL_NF_VON_NGF: 587.12,
    GB_TECHNIKFL_TF_VON_NGF: 9.01,
    GB_VERKEHRSFL_VF_VON_NGF: 18.8,
    GB_MIETFL_GESAMT: 614.93,
    GB_MIETFL_1_BSB: 614.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992498,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/3',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.437527',
    GB_LAENGENGRAD_OESTLICH: '9.993294',
    GB_NETTOGRUNDFL_NGF: 295.89,
    GB_NUTZFL_NF_VON_NGF: 288.96,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 6.93,
    GB_MIETFL_GESAMT: 295.89,
    GB_MIETFL_1_BSB: 295.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992499,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793177,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 848.09,
    GB_NUTZFL_NF_VON_NGF: 699.98,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 148.11,
    GB_MIETFL_GESAMT: 848.09,
    GB_MIETFL_1_BSB: 848.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992501,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 806.99,
    GB_NUTZFL_NF_VON_NGF: 600.15,
    GB_TECHNIKFL_TF_VON_NGF: 64.43,
    GB_VERKEHRSFL_VF_VON_NGF: 142.41,
    GB_MIETFL_GESAMT: 806.99,
    GB_MIETFL_1_BSB: 691.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.14',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992502,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793178,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/8',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 108.46,
    GB_NUTZFL_NF_VON_NGF: 87.15,
    GB_TECHNIKFL_TF_VON_NGF: 8.53,
    GB_VERKEHRSFL_VF_VON_NGF: 12.78,
    GB_MIETFL_GESAMT: 108.46,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992504,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.24,
    GB_NUTZFL_NF_VON_NGF: 169.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992505,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 431.57,
    GB_NUTZFL_NF_VON_NGF: 342.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.07,
    GB_MIETFL_GESAMT: 431.57,
    GB_MIETFL_1_BSB: 431.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992506,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793179,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793180,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793181,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/14',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.24,
    GB_NUTZFL_NF_VON_NGF: 169.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992510,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/15',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.26,
    GB_NUTZFL_NF_VON_NGF: 169.54,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.72,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 1992511,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/16',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 200.24,
    GB_NUTZFL_NF_VON_NGF: 169.53,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 30.71,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '20.05.19',
    GB_GB_OBJ_ID_PKT: 1992512,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 613.08,
    GB_NUTZFL_NF_VON_NGF: 457.86,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 155.22,
    GB_MIETFL_GESAMT: 613.08,
    GB_MIETFL_1_BSB: 567.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992513,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/19',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.438568',
    GB_LAENGENGRAD_OESTLICH: '9.991214',
    GB_NETTOGRUNDFL_NGF: 623.25,
    GB_NUTZFL_NF_VON_NGF: 587.83,
    GB_TECHNIKFL_TF_VON_NGF: 16.59,
    GB_VERKEHRSFL_VF_VON_NGF: 18.83,
    GB_MIETFL_GESAMT: 623.25,
    GB_MIETFL_1_BSB: 623.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '07.10.15',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335720,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/20',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2017,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1564.47,
    GB_NUTZFL_NF_VON_NGF: 1148.28,
    GB_TECHNIKFL_TF_VON_NGF: 37.47,
    GB_VERKEHRSFL_VF_VON_NGF: 378.72,
    GB_MIETFL_GESAMT: 1564.47,
    GB_MIETFL_1_BSB: 1558.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.17',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5335721,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 21,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/21',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045864,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 22,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/22',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7435292,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 36,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/36',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793182,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 37,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/37',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793183,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 38,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/38',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793184,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747027,
    GB_SAP_GB_NUMMER: 39,
    GB_SAP_WE_ID_FKF: '7400/747027',
    GB_SAP_GB_ID_PKF: '7400/747027/39',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793185,
    GB_WE_OBJ_ID_FKT: 1989444,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.440044',
    GB_LAENGENGRAD_OESTLICH: '9.970847',
    GB_NETTOGRUNDFL_NGF: 2056.13,
    GB_NUTZFL_NF_VON_NGF: 1072.49,
    GB_TECHNIKFL_TF_VON_NGF: 345.83,
    GB_VERKEHRSFL_VF_VON_NGF: 637.81,
    GB_MIETFL_GESAMT: 2056.13,
    GB_MIETFL_1_BSB: 1331.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992358,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 202.19,
    GB_NUTZFL_NF_VON_NGF: 169.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 32.27,
    GB_MIETFL_GESAMT: 202.19,
    GB_MIETFL_1_BSB: 202.19,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992359,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1426.37,
    GB_NUTZFL_NF_VON_NGF: 1053.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 372.96,
    GB_MIETFL_GESAMT: 1426.37,
    GB_MIETFL_1_BSB: 1426.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992360,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Kita',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Kita',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1502.16,
    GB_NUTZFL_NF_VON_NGF: 1081.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 420.17,
    GB_MIETFL_GESAMT: 1502.16,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 1502.16,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992361,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/5',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 73.58,
    GB_NUTZFL_NF_VON_NGF: 65.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.93,
    GB_MIETFL_GESAMT: 73.58,
    GB_MIETFL_1_BSB: 73.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992362,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1566.42,
    GB_NUTZFL_NF_VON_NGF: 837.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 728.43,
    GB_MIETFL_GESAMT: 1566.42,
    GB_MIETFL_1_BSB: 1566.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992363,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747005,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747005',
    GB_SAP_GB_ID_PKF: '7400/747005/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2016,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 191.86,
    GB_NUTZFL_NF_VON_NGF: 152.51,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 39.35,
    GB_MIETFL_GESAMT: 191.86,
    GB_MIETFL_1_BSB: 191.86,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5490622,
    GB_WE_OBJ_ID_FKT: 1989424,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749029,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749029',
    GB_SAP_GB_ID_PKF: '7400/749029/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6923880,
    GB_WE_OBJ_ID_FKT: 6923879,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749028,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749028',
    GB_SAP_GB_ID_PKF: '7400/749028/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800183,
    GB_WE_OBJ_ID_FKT: 6793149,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749019,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749019',
    GB_SAP_GB_ID_PKF: '7400/749019/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798790,
    GB_WE_OBJ_ID_FKT: 6793140,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749021,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749021',
    GB_SAP_GB_ID_PKF: '7400/749021/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800181,
    GB_WE_OBJ_ID_FKT: 6793142,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743081,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743081',
    GB_SAP_GB_ID_PKF: '7400/743081/1',
    GB_SAP_GB_BEZEICHNUNG: 'Dreifeldsporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Dreifeldsporthalle',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220157,
    GB_WE_OBJ_ID_FKT: 7220148,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749020',
    GB_SAP_GB_ID_PKF: '7400/749020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798791,
    GB_WE_OBJ_ID_FKT: 6793141,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749016,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749016',
    GB_SAP_GB_ID_PKF: '7400/749016/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800180,
    GB_WE_OBJ_ID_FKT: 6793138,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795267,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795268,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795269,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749002,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/749002',
    GB_SAP_GB_ID_PKF: '7400/749002/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6795270,
    GB_WE_OBJ_ID_FKT: 6793128,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.528012219',
    GB_LAENGENGRAD_OESTLICH: '9.8631144006',
    GB_NETTOGRUNDFL_NGF: 1621.55,
    GB_NUTZFL_NF_VON_NGF: 1302.48,
    GB_TECHNIKFL_TF_VON_NGF: 16.91,
    GB_VERKEHRSFL_VF_VON_NGF: 302.16,
    GB_MIETFL_GESAMT: 1621.55,
    GB_MIETFL_1_BSB: 852.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989841,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.528310',
    GB_LAENGENGRAD_OESTLICH: '9.862882',
    GB_NETTOGRUNDFL_NGF: 1608.11,
    GB_NUTZFL_NF_VON_NGF: 1167.17,
    GB_TECHNIKFL_TF_VON_NGF: 90.21,
    GB_VERKEHRSFL_VF_VON_NGF: 350.73,
    GB_MIETFL_GESAMT: 1608.11,
    GB_MIETFL_1_BSB: 1501.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989842,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.528259',
    GB_LAENGENGRAD_OESTLICH: '9.863324',
    GB_NETTOGRUNDFL_NGF: 238.66,
    GB_NUTZFL_NF_VON_NGF: 218.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.77,
    GB_MIETFL_GESAMT: 238.66,
    GB_MIETFL_1_BSB: 238.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989843,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.528513',
    GB_LAENGENGRAD_OESTLICH: '9.863322',
    GB_NETTOGRUNDFL_NGF: 238.6,
    GB_NUTZFL_NF_VON_NGF: 218.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.75,
    GB_MIETFL_GESAMT: 238.6,
    GB_MIETFL_1_BSB: 238.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989844,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.528749',
    GB_LAENGENGRAD_OESTLICH: '9.862702',
    GB_NETTOGRUNDFL_NGF: 626.67,
    GB_NUTZFL_NF_VON_NGF: 592.02,
    GB_TECHNIKFL_TF_VON_NGF: 15.71,
    GB_VERKEHRSFL_VF_VON_NGF: 18.94,
    GB_MIETFL_GESAMT: 626.67,
    GB_MIETFL_1_BSB: 626.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989845,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.528602',
    GB_LAENGENGRAD_OESTLICH: '9.862751',
    GB_NETTOGRUNDFL_NGF: 605.76,
    GB_NUTZFL_NF_VON_NGF: 476.04,
    GB_TECHNIKFL_TF_VON_NGF: 4.17,
    GB_VERKEHRSFL_VF_VON_NGF: 125.55,
    GB_MIETFL_GESAMT: 605.76,
    GB_MIETFL_1_BSB: 605.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989846,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.527989',
    GB_LAENGENGRAD_OESTLICH: '9.863446',
    GB_NETTOGRUNDFL_NGF: 37.27,
    GB_NUTZFL_NF_VON_NGF: 37.27,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 37.27,
    GB_MIETFL_1_BSB: 37.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078224,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.528289',
    GB_LAENGENGRAD_OESTLICH: '9.863830',
    GB_NETTOGRUNDFL_NGF: 238.67,
    GB_NUTZFL_NF_VON_NGF: 218.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.77,
    GB_MIETFL_GESAMT: 238.67,
    GB_MIETFL_1_BSB: 238.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078225,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741049,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/741049',
    GB_SAP_GB_ID_PKF: '7400/741049/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.528517',
    GB_LAENGENGRAD_OESTLICH: '9.863798',
    GB_NETTOGRUNDFL_NGF: 238.67,
    GB_NUTZFL_NF_VON_NGF: 218.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.77,
    GB_MIETFL_GESAMT: 238.67,
    GB_MIETFL_1_BSB: 238.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078226,
    GB_WE_OBJ_ID_FKT: 1989043,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741067',
    GB_SAP_GB_ID_PKF: '7400/741067/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793151,
    GB_WE_OBJ_ID_FKT: 1989059,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741067',
    GB_SAP_GB_ID_PKF: '7400/741067/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1929,
    GB_BREITENGRAD_NOERDLICH: '53.534922',
    GB_LAENGENGRAD_OESTLICH: '9.877927',
    GB_NETTOGRUNDFL_NGF: 836.56,
    GB_NUTZFL_NF_VON_NGF: 679.23,
    GB_TECHNIKFL_TF_VON_NGF: 53.52,
    GB_VERKEHRSFL_VF_VON_NGF: 103.81,
    GB_MIETFL_GESAMT: 836.56,
    GB_MIETFL_1_BSB: 719.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078146,
    GB_WE_OBJ_ID_FKT: 1989059,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/1',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532173626',
    GB_LAENGENGRAD_OESTLICH: '9.8760885372',
    GB_NETTOGRUNDFL_NGF: 332.57,
    GB_NUTZFL_NF_VON_NGF: 301.41,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.16,
    GB_MIETFL_GESAMT: 332.57,
    GB_MIETFL_1_BSB: 332.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989911,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1998,
    GB_BREITENGRAD_NOERDLICH: '53.532023',
    GB_LAENGENGRAD_OESTLICH: '9.874344',
    GB_NETTOGRUNDFL_NGF: 1483.65,
    GB_NUTZFL_NF_VON_NGF: 1431.79,
    GB_TECHNIKFL_TF_VON_NGF: 20.28,
    GB_VERKEHRSFL_VF_VON_NGF: 31.58,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '15.02.23',
    GB_GB_OBJ_ID_PKT: 1989912,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532038',
    GB_LAENGENGRAD_OESTLICH: '9.876226',
    GB_NETTOGRUNDFL_NGF: 25.28,
    GB_NUTZFL_NF_VON_NGF: 25.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 25.28,
    GB_MIETFL_1_BSB: 25.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989920,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: '53.531745',
    GB_LAENGENGRAD_OESTLICH: '9.874925',
    GB_NETTOGRUNDFL_NGF: 453.25,
    GB_NUTZFL_NF_VON_NGF: 351.59,
    GB_TECHNIKFL_TF_VON_NGF: 3.53,
    GB_VERKEHRSFL_VF_VON_NGF: 98.13,
    GB_MIETFL_GESAMT: 453.25,
    GB_MIETFL_1_BSB: 453.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989921,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/12',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.531981',
    GB_LAENGENGRAD_OESTLICH: '9.876031',
    GB_NETTOGRUNDFL_NGF: 160.31,
    GB_NUTZFL_NF_VON_NGF: 125.95,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 34.36,
    GB_MIETFL_GESAMT: 160.31,
    GB_MIETFL_1_BSB: 73.43,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989922,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.532672',
    GB_LAENGENGRAD_OESTLICH: '9.874505',
    GB_NETTOGRUNDFL_NGF: 519.66,
    GB_NUTZFL_NF_VON_NGF: 427.69,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 91.97,
    GB_MIETFL_GESAMT: 519.66,
    GB_MIETFL_1_BSB: 519.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989913,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532682',
    GB_LAENGENGRAD_OESTLICH: '9.875341',
    GB_NETTOGRUNDFL_NGF: 497.84,
    GB_NUTZFL_NF_VON_NGF: 409.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 88.18,
    GB_MIETFL_GESAMT: 497.84,
    GB_MIETFL_1_BSB: 497.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989914,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532965235',
    GB_LAENGENGRAD_OESTLICH: '9.8754838792',
    GB_NETTOGRUNDFL_NGF: 497.84,
    GB_NUTZFL_NF_VON_NGF: 409.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 88.18,
    GB_MIETFL_GESAMT: 497.84,
    GB_MIETFL_1_BSB: 497.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989915,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.532878',
    GB_LAENGENGRAD_OESTLICH: '9.875880',
    GB_NETTOGRUNDFL_NGF: 1303.34,
    GB_NUTZFL_NF_VON_NGF: 1074.56,
    GB_TECHNIKFL_TF_VON_NGF: 1.71,
    GB_VERKEHRSFL_VF_VON_NGF: 227.07,
    GB_MIETFL_GESAMT: 1303.34,
    GB_MIETFL_1_BSB: 1303.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989916,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.532435',
    GB_LAENGENGRAD_OESTLICH: '9.875642',
    GB_NETTOGRUNDFL_NGF: 134.98,
    GB_NUTZFL_NF_VON_NGF: 124.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 10.14,
    GB_MIETFL_GESAMT: 134.98,
    GB_MIETFL_1_BSB: 134.98,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989917,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/8',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1999,
    GB_BREITENGRAD_NOERDLICH: '53.531757',
    GB_LAENGENGRAD_OESTLICH: '9.875804',
    GB_NETTOGRUNDFL_NGF: 837.5,
    GB_NUTZFL_NF_VON_NGF: 627.57,
    GB_TECHNIKFL_TF_VON_NGF: 25.6,
    GB_VERKEHRSFL_VF_VON_NGF: 184.33,
    GB_MIETFL_GESAMT: 837.5,
    GB_MIETFL_1_BSB: 797.05,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989918,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532139931',
    GB_LAENGENGRAD_OESTLICH: '9.8755530568',
    GB_NETTOGRUNDFL_NGF: 1513.84,
    GB_NUTZFL_NF_VON_NGF: 1234.31,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 279.53,
    GB_MIETFL_GESAMT: 724.47,
    GB_MIETFL_1_BSB: 724.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989919,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/13',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.532384',
    GB_LAENGENGRAD_OESTLICH: '9.875982',
    GB_NETTOGRUNDFL_NGF: 1027.29,
    GB_NUTZFL_NF_VON_NGF: 688.35,
    GB_TECHNIKFL_TF_VON_NGF: 113.27,
    GB_VERKEHRSFL_VF_VON_NGF: 225.67,
    GB_MIETFL_GESAMT: 1027.29,
    GB_MIETFL_1_BSB: 963.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989923,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Sporthalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7537683,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/15',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7537684,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741065,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/741065',
    GB_SAP_GB_ID_PKF: '7400/741065/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 303.98,
    GB_NUTZFL_NF_VON_NGF: 264.26,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 36.71,
    GB_MIETFL_GESAMT: 288.14,
    GB_MIETFL_1_BSB: 288.14,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462195,
    GB_WE_OBJ_ID_FKT: 1989057,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1983,
    GB_BREITENGRAD_NOERDLICH: '53.532282',
    GB_LAENGENGRAD_OESTLICH: '9.874324',
    GB_NETTOGRUNDFL_NGF: 671.38,
    GB_NUTZFL_NF_VON_NGF: 629.53,
    GB_TECHNIKFL_TF_VON_NGF: 12.67,
    GB_VERKEHRSFL_VF_VON_NGF: 29.18,
    GB_MIETFL_GESAMT: 671.38,
    GB_MIETFL_1_BSB: 671.38,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989924,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793150,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: '53.532013',
    GB_LAENGENGRAD_OESTLICH: '9.873471',
    GB_NETTOGRUNDFL_NGF: 1157.59,
    GB_NUTZFL_NF_VON_NGF: 902.28,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 255.31,
    GB_MIETFL_GESAMT: 1157.59,
    GB_MIETFL_1_BSB: 1157.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989926,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1976,
    GB_BREITENGRAD_NOERDLICH: '53.532906',
    GB_LAENGENGRAD_OESTLICH: '9.874447',
    GB_NETTOGRUNDFL_NGF: 520.27,
    GB_NUTZFL_NF_VON_NGF: 427.8,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 92.47,
    GB_MIETFL_GESAMT: 520.27,
    GB_MIETFL_1_BSB: 520.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989927,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.532701921',
    GB_LAENGENGRAD_OESTLICH: '9.8740500307',
    GB_NETTOGRUNDFL_NGF: 3948.24,
    GB_NUTZFL_NF_VON_NGF: 3039.38,
    GB_TECHNIKFL_TF_VON_NGF: 93.97,
    GB_VERKEHRSFL_VF_VON_NGF: 814.89,
    GB_MIETFL_GESAMT: 3948.24,
    GB_MIETFL_1_BSB: 3630.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989928,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.531796',
    GB_LAENGENGRAD_OESTLICH: '9.875348',
    GB_NETTOGRUNDFL_NGF: 1229.85,
    GB_NUTZFL_NF_VON_NGF: 935.37,
    GB_TECHNIKFL_TF_VON_NGF: 20.19,
    GB_VERKEHRSFL_VF_VON_NGF: 274.29,
    GB_MIETFL_GESAMT: 1229.85,
    GB_MIETFL_1_BSB: 1226.55,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078145,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741066,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/741066',
    GB_SAP_GB_ID_PKF: '7400/741066/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045862,
    GB_WE_OBJ_ID_FKT: 1989058,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: '53.527235',
    GB_LAENGENGRAD_OESTLICH: '9.884656',
    GB_NETTOGRUNDFL_NGF: 2596.34,
    GB_NUTZFL_NF_VON_NGF: 2160.41,
    GB_TECHNIKFL_TF_VON_NGF: 73.98,
    GB_VERKEHRSFL_VF_VON_NGF: 361.95,
    GB_MIETFL_GESAMT: 2596.34,
    GB_MIETFL_1_BSB: 1059.34,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989509,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1952,
    GB_BREITENGRAD_NOERDLICH: '53.527684',
    GB_LAENGENGRAD_OESTLICH: '9.885441',
    GB_NETTOGRUNDFL_NGF: 1564.36,
    GB_NUTZFL_NF_VON_NGF: 1038.57,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 525.79,
    GB_MIETFL_GESAMT: 1564.36,
    GB_MIETFL_1_BSB: 1205.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989510,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1882,
    GB_BREITENGRAD_NOERDLICH: '53.528108',
    GB_LAENGENGRAD_OESTLICH: '9.885266',
    GB_NETTOGRUNDFL_NGF: 1702.65,
    GB_NUTZFL_NF_VON_NGF: 1443.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 259.45,
    GB_MIETFL_GESAMT: 1702.65,
    GB_MIETFL_1_BSB: 973.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989511,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1958,
    GB_BREITENGRAD_NOERDLICH: '53.528355',
    GB_LAENGENGRAD_OESTLICH: '9.884415',
    GB_NETTOGRUNDFL_NGF: 628.5,
    GB_NUTZFL_NF_VON_NGF: 594.15,
    GB_TECHNIKFL_TF_VON_NGF: 15.35,
    GB_VERKEHRSFL_VF_VON_NGF: 19.0,
    GB_MIETFL_GESAMT: 628.5,
    GB_MIETFL_1_BSB: 628.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989512,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/5',
    GB_SAP_GB_BEZEICHNUNG: 'Mehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mehrzweckhalle',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.527805',
    GB_LAENGENGRAD_OESTLICH: '9.884622',
    GB_NETTOGRUNDFL_NGF: 687.28,
    GB_NUTZFL_NF_VON_NGF: 556.51,
    GB_TECHNIKFL_TF_VON_NGF: 26.33,
    GB_VERKEHRSFL_VF_VON_NGF: 104.44,
    GB_MIETFL_GESAMT: 687.28,
    GB_MIETFL_1_BSB: 687.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.11',
    GB_GEBAEUDE_KLASSE_ALT: '2',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989513,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741005,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/741005',
    GB_SAP_GB_ID_PKF: '7400/741005/6',
    GB_SAP_GB_BEZEICHNUNG: 'Bunker',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Bunker',
    GB_BAUJAHR: 1942,
    GB_BREITENGRAD_NOERDLICH: '53.527454',
    GB_LAENGENGRAD_OESTLICH: '9.885668',
    GB_NETTOGRUNDFL_NGF: 21.62,
    GB_NUTZFL_NF_VON_NGF: 21.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 21.62,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989514,
    GB_WE_OBJ_ID_FKT: 1989000,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/741043',
    GB_SAP_GB_ID_PKF: '7400/741043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1930,
    GB_BREITENGRAD_NOERDLICH: '53.526872',
    GB_LAENGENGRAD_OESTLICH: '10.019040',
    GB_NETTOGRUNDFL_NGF: 8512.71,
    GB_NUTZFL_NF_VON_NGF: 5880.9,
    GB_TECHNIKFL_TF_VON_NGF: 94.11,
    GB_VERKEHRSFL_VF_VON_NGF: 2537.7,
    GB_MIETFL_GESAMT: 8512.71,
    GB_MIETFL_1_BSB: 8218.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1989780,
    GB_WE_OBJ_ID_FKT: 1989037,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 741043,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/741043',
    GB_SAP_GB_ID_PKF: '7400/741043/2',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7123570,
    GB_WE_OBJ_ID_FKT: 1989037,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: '53.516135',
    GB_LAENGENGRAD_OESTLICH: '9.980144',
    GB_NETTOGRUNDFL_NGF: 622.45,
    GB_NUTZFL_NF_VON_NGF: 588.12,
    GB_TECHNIKFL_TF_VON_NGF: 15.33,
    GB_VERKEHRSFL_VF_VON_NGF: 19.0,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '02.05.23',
    GB_GB_OBJ_ID_PKT: 1992578,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/2',
    GB_SAP_GB_BEZEICHNUNG: 'Aula',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Aula',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.517019',
    GB_LAENGENGRAD_OESTLICH: '9.980821',
    GB_NETTOGRUNDFL_NGF: 926.12,
    GB_NUTZFL_NF_VON_NGF: 799.64,
    GB_TECHNIKFL_TF_VON_NGF: 38.71,
    GB_VERKEHRSFL_VF_VON_NGF: 87.77,
    GB_MIETFL_GESAMT: 926.12,
    GB_MIETFL_1_BSB: 798.93,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992579,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1899,
    GB_BREITENGRAD_NOERDLICH: '53.517146',
    GB_LAENGENGRAD_OESTLICH: '9.981391',
    GB_NETTOGRUNDFL_NGF: 1695.73,
    GB_NUTZFL_NF_VON_NGF: 1332.78,
    GB_TECHNIKFL_TF_VON_NGF: 69.83,
    GB_VERKEHRSFL_VF_VON_NGF: 293.12,
    GB_MIETFL_GESAMT: 1695.73,
    GB_MIETFL_1_BSB: 1077.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992580,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.516989706',
    GB_LAENGENGRAD_OESTLICH: '9.9815787520',
    GB_NETTOGRUNDFL_NGF: 222.91,
    GB_NUTZFL_NF_VON_NGF: 137.93,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 84.98,
    GB_MIETFL_GESAMT: 222.91,
    GB_MIETFL_1_BSB: 222.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992581,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1962,
    GB_BREITENGRAD_NOERDLICH: '53.516342',
    GB_LAENGENGRAD_OESTLICH: '9.981504',
    GB_NETTOGRUNDFL_NGF: 1447.51,
    GB_NUTZFL_NF_VON_NGF: 1135.48,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 312.03,
    GB_MIETFL_GESAMT: 1447.51,
    GB_MIETFL_1_BSB: 1447.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '23.11.17',
    GB_GEBAEUDE_KLASSE_ALT: '5',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992582,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1970,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.38,
    GB_NUTZFL_NF_VON_NGF: 147.01,
    GB_TECHNIKFL_TF_VON_NGF: 1.8,
    GB_VERKEHRSFL_VF_VON_NGF: 23.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.10.20',
    GB_GB_OBJ_ID_PKT: 1992583,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 172.37,
    GB_NUTZFL_NF_VON_NGF: 147.0,
    GB_TECHNIKFL_TF_VON_NGF: 1.8,
    GB_VERKEHRSFL_VF_VON_NGF: 23.57,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.10.20',
    GB_GB_OBJ_ID_PKT: 1992584,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.516036',
    GB_LAENGENGRAD_OESTLICH: '9.980978',
    GB_NETTOGRUNDFL_NGF: 443.3,
    GB_NUTZFL_NF_VON_NGF: 345.84,
    GB_TECHNIKFL_TF_VON_NGF: 3.72,
    GB_VERKEHRSFL_VF_VON_NGF: 93.74,
    GB_MIETFL_GESAMT: 443.3,
    GB_MIETFL_1_BSB: 443.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992585,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1988,
    GB_BREITENGRAD_NOERDLICH: '53.516101',
    GB_LAENGENGRAD_OESTLICH: '9.980698',
    GB_NETTOGRUNDFL_NGF: 443.3,
    GB_NUTZFL_NF_VON_NGF: 345.84,
    GB_TECHNIKFL_TF_VON_NGF: 3.72,
    GB_VERKEHRSFL_VF_VON_NGF: 93.74,
    GB_MIETFL_GESAMT: 443.3,
    GB_MIETFL_1_BSB: 443.3,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992586,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793188,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/11',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5445433,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/12',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7045865,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/13',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056609,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/14',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7056610,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.501445',
    GB_LAENGENGRAD_OESTLICH: '10.012674',
    GB_NETTOGRUNDFL_NGF: 4236.67,
    GB_NUTZFL_NF_VON_NGF: 2825.34,
    GB_TECHNIKFL_TF_VON_NGF: 188.4,
    GB_VERKEHRSFL_VF_VON_NGF: 1222.93,
    GB_MIETFL_GESAMT: 4236.67,
    GB_MIETFL_1_BSB: 4228.09,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307974,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747035,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747035',
    GB_SAP_GB_ID_PKF: '7400/747035/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: '53.516859',
    GB_LAENGENGRAD_OESTLICH: '9.981210',
    GB_NETTOGRUNDFL_NGF: 161.24,
    GB_NUTZFL_NF_VON_NGF: 148.68,
    GB_TECHNIKFL_TF_VON_NGF: 3.01,
    GB_VERKEHRSFL_VF_VON_NGF: 9.55,
    GB_MIETFL_GESAMT: 161.24,
    GB_MIETFL_1_BSB: 161.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.08.20',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7121552,
    GB_WE_OBJ_ID_FKT: 1989452,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.500754',
    GB_LAENGENGRAD_OESTLICH: '10.012930',
    GB_NETTOGRUNDFL_NGF: 4637.89,
    GB_NUTZFL_NF_VON_NGF: 3036.65,
    GB_TECHNIKFL_TF_VON_NGF: 216.93,
    GB_VERKEHRSFL_VF_VON_NGF: 1384.31,
    GB_MIETFL_GESAMT: 4637.89,
    GB_MIETFL_1_BSB: 4629.31,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307975,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.500796',
    GB_LAENGENGRAD_OESTLICH: '10.011779',
    GB_NETTOGRUNDFL_NGF: 5136.78,
    GB_NUTZFL_NF_VON_NGF: 3639.95,
    GB_TECHNIKFL_TF_VON_NGF: 174.98,
    GB_VERKEHRSFL_VF_VON_NGF: 1321.85,
    GB_MIETFL_GESAMT: 5136.78,
    GB_MIETFL_1_BSB: 5123.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307976,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.500031',
    GB_LAENGENGRAD_OESTLICH: '10.012232',
    GB_NETTOGRUNDFL_NGF: 4755.68,
    GB_NUTZFL_NF_VON_NGF: 3468.18,
    GB_TECHNIKFL_TF_VON_NGF: 261.39,
    GB_VERKEHRSFL_VF_VON_NGF: 1026.11,
    GB_MIETFL_GESAMT: 4755.68,
    GB_MIETFL_1_BSB: 3970.58,
    GB_MIETFL_2_DRITTE: 658.55,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307977,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.500473',
    GB_LAENGENGRAD_OESTLICH: '10.012407',
    GB_NETTOGRUNDFL_NGF: 667.87,
    GB_NUTZFL_NF_VON_NGF: 631.04,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 36.83,
    GB_MIETFL_GESAMT: 667.87,
    GB_MIETFL_1_BSB: 667.87,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307978,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747068,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747068',
    GB_SAP_GB_ID_PKF: '7400/747068/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: '53.500217637',
    GB_LAENGENGRAD_OESTLICH: '10.013745151',
    GB_NETTOGRUNDFL_NGF: 592.28,
    GB_NUTZFL_NF_VON_NGF: 306.56,
    GB_TECHNIKFL_TF_VON_NGF: 97.91,
    GB_VERKEHRSFL_VF_VON_NGF: 187.81,
    GB_MIETFL_GESAMT: 592.28,
    GB_MIETFL_1_BSB: 421.28,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '22.04.13',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5307979,
    GB_WE_OBJ_ID_FKT: 2034793,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.499844782',
    GB_LAENGENGRAD_OESTLICH: '10.014324927',
    GB_NETTOGRUNDFL_NGF: 695.76,
    GB_NUTZFL_NF_VON_NGF: 582.89,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 112.87,
    GB_MIETFL_GESAMT: 695.76,
    GB_MIETFL_1_BSB: 695.76,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992671,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.499775486',
    GB_LAENGENGRAD_OESTLICH: '10.013580624',
    GB_NETTOGRUNDFL_NGF: 551.32,
    GB_NUTZFL_NF_VON_NGF: 436.71,
    GB_TECHNIKFL_TF_VON_NGF: 19.43,
    GB_VERKEHRSFL_VF_VON_NGF: 95.18,
    GB_MIETFL_GESAMT: 551.32,
    GB_MIETFL_1_BSB: 551.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992672,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.498642',
    GB_LAENGENGRAD_OESTLICH: '10.014584',
    GB_NETTOGRUNDFL_NGF: 1733.12,
    GB_NUTZFL_NF_VON_NGF: 1654.34,
    GB_TECHNIKFL_TF_VON_NGF: 22.46,
    GB_VERKEHRSFL_VF_VON_NGF: 56.32,
    GB_MIETFL_GESAMT: 1733.12,
    GB_MIETFL_1_BSB: 1733.12,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992673,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1974,
    GB_BREITENGRAD_NOERDLICH: '53.499787035',
    GB_LAENGENGRAD_OESTLICH: '10.014952730',
    GB_NETTOGRUNDFL_NGF: 1157.54,
    GB_NUTZFL_NF_VON_NGF: 936.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 221.54,
    GB_MIETFL_GESAMT: 1157.54,
    GB_MIETFL_1_BSB: 1155.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992674,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1972,
    GB_BREITENGRAD_NOERDLICH: '53.499302',
    GB_LAENGENGRAD_OESTLICH: '10.014390',
    GB_NETTOGRUNDFL_NGF: 3133.52,
    GB_NUTZFL_NF_VON_NGF: 2315.0,
    GB_TECHNIKFL_TF_VON_NGF: 78.76,
    GB_VERKEHRSFL_VF_VON_NGF: 739.76,
    GB_MIETFL_GESAMT: 3133.52,
    GB_MIETFL_1_BSB: 2861.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992675,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1975,
    GB_BREITENGRAD_NOERDLICH: '53.499404',
    GB_LAENGENGRAD_OESTLICH: '10.015092',
    GB_NETTOGRUNDFL_NGF: 2828.25,
    GB_NUTZFL_NF_VON_NGF: 1635.18,
    GB_TECHNIKFL_TF_VON_NGF: 657.26,
    GB_VERKEHRSFL_VF_VON_NGF: 535.81,
    GB_MIETFL_GESAMT: 2828.25,
    GB_MIETFL_1_BSB: 1949.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992676,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793194,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747046,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747046',
    GB_SAP_GB_ID_PKF: '7400/747046/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793195,
    GB_WE_OBJ_ID_FKT: 1989462,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1989,
    GB_BREITENGRAD_NOERDLICH: '53.493842',
    GB_LAENGENGRAD_OESTLICH: '10.014795',
    GB_NETTOGRUNDFL_NGF: 653.03,
    GB_NUTZFL_NF_VON_NGF: 613.19,
    GB_TECHNIKFL_TF_VON_NGF: 15.45,
    GB_VERKEHRSFL_VF_VON_NGF: 24.39,
    GB_MIETFL_GESAMT: 653.03,
    GB_MIETFL_1_BSB: 653.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992651,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1940,
    GB_BREITENGRAD_NOERDLICH: '53.494175533',
    GB_LAENGENGRAD_OESTLICH: '10.014000295',
    GB_NETTOGRUNDFL_NGF: 2287.98,
    GB_NUTZFL_NF_VON_NGF: 1156.13,
    GB_TECHNIKFL_TF_VON_NGF: 387.41,
    GB_VERKEHRSFL_VF_VON_NGF: 744.44,
    GB_MIETFL_GESAMT: 2287.98,
    GB_MIETFL_1_BSB: 1808.96,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992652,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/13',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793227,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 411.46,
    GB_NUTZFL_NF_VON_NGF: 243.71,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 167.75,
    GB_MIETFL_GESAMT: 411.46,
    GB_MIETFL_1_BSB: 411.46,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992570,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1963,
    GB_BREITENGRAD_NOERDLICH: '53.512806',
    GB_LAENGENGRAD_OESTLICH: '10.018968',
    GB_NETTOGRUNDFL_NGF: 625.89,
    GB_NUTZFL_NF_VON_NGF: 590.15,
    GB_TECHNIKFL_TF_VON_NGF: 16.76,
    GB_VERKEHRSFL_VF_VON_NGF: 18.98,
    GB_MIETFL_GESAMT: 625.89,
    GB_MIETFL_1_BSB: 625.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992571,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1968,
    GB_BREITENGRAD_NOERDLICH: '53.512187',
    GB_LAENGENGRAD_OESTLICH: '10.019343',
    GB_NETTOGRUNDFL_NGF: 164.84,
    GB_NUTZFL_NF_VON_NGF: 153.1,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 11.74,
    GB_MIETFL_GESAMT: 164.84,
    GB_MIETFL_1_BSB: 164.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992572,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1940,
    GB_BREITENGRAD_NOERDLICH: '53.493865559',
    GB_LAENGENGRAD_OESTLICH: '10.014031882',
    GB_NETTOGRUNDFL_NGF: 3018.11,
    GB_NUTZFL_NF_VON_NGF: 1896.74,
    GB_TECHNIKFL_TF_VON_NGF: 758.57,
    GB_VERKEHRSFL_VF_VON_NGF: 362.8,
    GB_MIETFL_GESAMT: 3018.11,
    GB_MIETFL_1_BSB: 2259.54,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992653,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1940,
    GB_BREITENGRAD_NOERDLICH: '53.493544950',
    GB_LAENGENGRAD_OESTLICH: '10.014200432',
    GB_NETTOGRUNDFL_NGF: 1975.76,
    GB_NUTZFL_NF_VON_NGF: 1325.88,
    GB_TECHNIKFL_TF_VON_NGF: 117.57,
    GB_VERKEHRSFL_VF_VON_NGF: 532.31,
    GB_MIETFL_GESAMT: 1975.76,
    GB_MIETFL_1_BSB: 1383.56,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992654,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/5',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1987,
    GB_BREITENGRAD_NOERDLICH: '53.494367',
    GB_LAENGENGRAD_OESTLICH: '10.014476',
    GB_NETTOGRUNDFL_NGF: 802.21,
    GB_NUTZFL_NF_VON_NGF: 681.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 120.24,
    GB_MIETFL_GESAMT: 802.21,
    GB_MIETFL_1_BSB: 802.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992655,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.493708850',
    GB_LAENGENGRAD_OESTLICH: '10.013476708',
    GB_NETTOGRUNDFL_NGF: 636.9,
    GB_NUTZFL_NF_VON_NGF: 512.78,
    GB_TECHNIKFL_TF_VON_NGF: 7.93,
    GB_VERKEHRSFL_VF_VON_NGF: 116.19,
    GB_MIETFL_GESAMT: 636.9,
    GB_MIETFL_1_BSB: 636.9,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992656,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/7',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1940,
    GB_BREITENGRAD_NOERDLICH: '53.493200',
    GB_LAENGENGRAD_OESTLICH: '10.013953',
    GB_NETTOGRUNDFL_NGF: 128.18,
    GB_NUTZFL_NF_VON_NGF: 102.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 26.13,
    GB_MIETFL_GESAMT: 128.18,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992657,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.494150',
    GB_LAENGENGRAD_OESTLICH: '10.014919',
    GB_NETTOGRUNDFL_NGF: 1006.28,
    GB_NUTZFL_NF_VON_NGF: 706.7,
    GB_TECHNIKFL_TF_VON_NGF: 4.44,
    GB_VERKEHRSFL_VF_VON_NGF: 295.14,
    GB_MIETFL_GESAMT: 1006.28,
    GB_MIETFL_1_BSB: 1002.59,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992658,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/9',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5427888,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747044,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747044',
    GB_SAP_GB_ID_PKF: '7400/747044/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 175.3,
    GB_NUTZFL_NF_VON_NGF: 150.29,
    GB_TECHNIKFL_TF_VON_NGF: 9.7,
    GB_VERKEHRSFL_VF_VON_NGF: 15.31,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.08.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '24.06.22',
    GB_GB_OBJ_ID_PKT: 6940209,
    GB_WE_OBJ_ID_FKT: 1989460,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793222,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.504289',
    GB_LAENGENGRAD_OESTLICH: '9.995166',
    GB_NETTOGRUNDFL_NGF: 626.37,
    GB_NUTZFL_NF_VON_NGF: 585.41,
    GB_TECHNIKFL_TF_VON_NGF: 21.89,
    GB_VERKEHRSFL_VF_VON_NGF: 19.07,
    GB_MIETFL_GESAMT: 626.37,
    GB_MIETFL_1_BSB: 626.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992810,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/3',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 2001,
    GB_BREITENGRAD_NOERDLICH: '53.504290',
    GB_LAENGENGRAD_OESTLICH: '9.993761',
    GB_NETTOGRUNDFL_NGF: 353.22,
    GB_NUTZFL_NF_VON_NGF: 294.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 58.28,
    GB_MIETFL_GESAMT: 353.22,
    GB_MIETFL_1_BSB: 353.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992811,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1991,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.04.21',
    GB_GB_OBJ_ID_PKT: 1992812,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1993,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 228.64,
    GB_NUTZFL_NF_VON_NGF: 174.12,
    GB_TECHNIKFL_TF_VON_NGF: 3.44,
    GB_VERKEHRSFL_VF_VON_NGF: 51.08,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.04.21',
    GB_GB_OBJ_ID_PKT: 1992813,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '08.04.21',
    GB_GB_OBJ_ID_PKT: 1992814,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1959,
    GB_BREITENGRAD_NOERDLICH: '53.504261',
    GB_LAENGENGRAD_OESTLICH: '9.994249',
    GB_NETTOGRUNDFL_NGF: 1587.79,
    GB_NUTZFL_NF_VON_NGF: 1161.55,
    GB_TECHNIKFL_TF_VON_NGF: 105.46,
    GB_VERKEHRSFL_VF_VON_NGF: 320.78,
    GB_MIETFL_GESAMT: 1587.79,
    GB_MIETFL_1_BSB: 1480.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992815,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793223,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 2002,
    GB_BREITENGRAD_NOERDLICH: '53.504674106',
    GB_LAENGENGRAD_OESTLICH: '9.9925707533',
    GB_NETTOGRUNDFL_NGF: 2115.63,
    GB_NUTZFL_NF_VON_NGF: 1527.01,
    GB_TECHNIKFL_TF_VON_NGF: 44.53,
    GB_VERKEHRSFL_VF_VON_NGF: 544.09,
    GB_MIETFL_GESAMT: 2115.63,
    GB_MIETFL_1_BSB: 2053.2,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992817,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/10',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793224,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/11',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793225,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/12',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793226,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2019,
    GB_BREITENGRAD_NOERDLICH: '53.504661328',
    GB_LAENGENGRAD_OESTLICH: '9.9935363557',
    GB_NETTOGRUNDFL_NGF: 6720.62,
    GB_NUTZFL_NF_VON_NGF: 4715.9,
    GB_TECHNIKFL_TF_VON_NGF: 119.37,
    GB_VERKEHRSFL_VF_VON_NGF: 1885.35,
    GB_MIETFL_GESAMT: 6720.62,
    GB_MIETFL_1_BSB: 6708.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '13.12.19',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5426937,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/15',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2023,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1863.74,
    GB_NUTZFL_NF_VON_NGF: 1713.44,
    GB_TECHNIKFL_TF_VON_NGF: 11.1,
    GB_VERKEHRSFL_VF_VON_NGF: 139.2,
    GB_MIETFL_GESAMT: 1863.74,
    GB_MIETFL_1_BSB: 1863.74,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '05.04.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5426939,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2014,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 236.28,
    GB_NUTZFL_NF_VON_NGF: 220.12,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.16,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 4352795,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747060,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747060',
    GB_SAP_GB_ID_PKF: '7400/747060/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2013,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 93.6,
    GB_NUTZFL_NF_VON_NGF: 81.2,
    GB_TECHNIKFL_TF_VON_NGF: 3.2,
    GB_VERKEHRSFL_VF_VON_NGF: 9.2,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '29.02.20',
    GB_GB_OBJ_ID_PKT: 5337418,
    GB_WE_OBJ_ID_FKT: 1989475,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.492219',
    GB_LAENGENGRAD_OESTLICH: '10.009853',
    GB_NETTOGRUNDFL_NGF: 450.24,
    GB_NUTZFL_NF_VON_NGF: 379.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 70.43,
    GB_MIETFL_GESAMT: 450.24,
    GB_MIETFL_1_BSB: 450.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992659,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/2',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.492593',
    GB_LAENGENGRAD_OESTLICH: '10.010098',
    GB_NETTOGRUNDFL_NGF: 1302.57,
    GB_NUTZFL_NF_VON_NGF: 1018.85,
    GB_TECHNIKFL_TF_VON_NGF: 89.34,
    GB_VERKEHRSFL_VF_VON_NGF: 194.38,
    GB_MIETFL_GESAMT: 1302.57,
    GB_MIETFL_1_BSB: 1134.51,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992660,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/3',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1971,
    GB_BREITENGRAD_NOERDLICH: '53.492968',
    GB_LAENGENGRAD_OESTLICH: '10.010994',
    GB_NETTOGRUNDFL_NGF: 625.04,
    GB_NUTZFL_NF_VON_NGF: 589.89,
    GB_TECHNIKFL_TF_VON_NGF: 12.88,
    GB_VERKEHRSFL_VF_VON_NGF: 22.27,
    GB_MIETFL_GESAMT: 625.04,
    GB_MIETFL_1_BSB: 625.04,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992661,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/4',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1984,
    GB_BREITENGRAD_NOERDLICH: '53.493010',
    GB_LAENGENGRAD_OESTLICH: '10.011836',
    GB_NETTOGRUNDFL_NGF: 530.61,
    GB_NUTZFL_NF_VON_NGF: 513.47,
    GB_TECHNIKFL_TF_VON_NGF: 9.12,
    GB_VERKEHRSFL_VF_VON_NGF: 8.02,
    GB_MIETFL_GESAMT: 530.61,
    GB_MIETFL_1_BSB: 530.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992662,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.492533',
    GB_LAENGENGRAD_OESTLICH: '10.011241',
    GB_NETTOGRUNDFL_NGF: 468.8,
    GB_NUTZFL_NF_VON_NGF: 421.85,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.95,
    GB_MIETFL_GESAMT: 468.8,
    GB_MIETFL_1_BSB: 468.8,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992663,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.492347',
    GB_LAENGENGRAD_OESTLICH: '10.010915',
    GB_NETTOGRUNDFL_NGF: 469.45,
    GB_NUTZFL_NF_VON_NGF: 423.0,
    GB_TECHNIKFL_TF_VON_NGF: 4.4,
    GB_VERKEHRSFL_VF_VON_NGF: 42.05,
    GB_MIETFL_GESAMT: 469.45,
    GB_MIETFL_1_BSB: 469.45,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992664,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1969,
    GB_BREITENGRAD_NOERDLICH: '53.492130',
    GB_LAENGENGRAD_OESTLICH: '10.010593',
    GB_NETTOGRUNDFL_NGF: 470.61,
    GB_NUTZFL_NF_VON_NGF: 423.66,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 46.95,
    GB_MIETFL_GESAMT: 470.61,
    GB_MIETFL_1_BSB: 470.61,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992665,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1997,
    GB_BREITENGRAD_NOERDLICH: '53.491824',
    GB_LAENGENGRAD_OESTLICH: '10.010271',
    GB_NETTOGRUNDFL_NGF: 152.91,
    GB_NUTZFL_NF_VON_NGF: 138.45,
    GB_TECHNIKFL_TF_VON_NGF: 0.92,
    GB_VERKEHRSFL_VF_VON_NGF: 13.54,
    GB_MIETFL_GESAMT: 152.91,
    GB_MIETFL_1_BSB: 152.91,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992666,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.491849',
    GB_LAENGENGRAD_OESTLICH: '10.009874',
    GB_NETTOGRUNDFL_NGF: 1150.27,
    GB_NUTZFL_NF_VON_NGF: 907.22,
    GB_TECHNIKFL_TF_VON_NGF: 1.71,
    GB_VERKEHRSFL_VF_VON_NGF: 241.34,
    GB_MIETFL_GESAMT: 1150.27,
    GB_MIETFL_1_BSB: 1150.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992667,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/10',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.492772',
    GB_LAENGENGRAD_OESTLICH: '10.011480',
    GB_NETTOGRUNDFL_NGF: 488.35,
    GB_NUTZFL_NF_VON_NGF: 411.97,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 76.38,
    GB_MIETFL_GESAMT: 488.35,
    GB_MIETFL_1_BSB: 488.35,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992668,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/11',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1973,
    GB_BREITENGRAD_NOERDLICH: '53.492721',
    GB_LAENGENGRAD_OESTLICH: '10.009661',
    GB_NETTOGRUNDFL_NGF: 117.97,
    GB_NUTZFL_NF_VON_NGF: 98.02,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 19.95,
    GB_MIETFL_GESAMT: 117.97,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992669,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/12',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1979,
    GB_BREITENGRAD_NOERDLICH: '53.491492',
    GB_LAENGENGRAD_OESTLICH: '10.009681',
    GB_NETTOGRUNDFL_NGF: 648.99,
    GB_NUTZFL_NF_VON_NGF: 492.3,
    GB_TECHNIKFL_TF_VON_NGF: 11.9,
    GB_VERKEHRSFL_VF_VON_NGF: 144.79,
    GB_MIETFL_GESAMT: 648.99,
    GB_MIETFL_1_BSB: 648.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992670,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/13',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: '53.493278',
    GB_LAENGENGRAD_OESTLICH: '10.011881',
    GB_NETTOGRUNDFL_NGF: 608.87,
    GB_NUTZFL_NF_VON_NGF: 392.17,
    GB_TECHNIKFL_TF_VON_NGF: 8.44,
    GB_VERKEHRSFL_VF_VON_NGF: 208.26,
    GB_MIETFL_GESAMT: 608.87,
    GB_MIETFL_1_BSB: 605.63,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.11',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5174596,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5448083,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 42,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/42',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 104.7,
    GB_NUTZFL_NF_VON_NGF: 83.0,
    GB_TECHNIKFL_TF_VON_NGF: 7.7,
    GB_VERKEHRSFL_VF_VON_NGF: 14.0,
    GB_MIETFL_GESAMT: 104.7,
    GB_MIETFL_1_BSB: 104.7,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: '18.08.22',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.09.22',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7462198,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 43,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/43',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 90.0,
    GB_NUTZFL_NF_VON_NGF: 90.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: '01.08.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7552771,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 2020,
    GB_BREITENGRAD_NOERDLICH: '53.493725',
    GB_LAENGENGRAD_OESTLICH: '10.011999',
    GB_NETTOGRUNDFL_NGF: 1004.34,
    GB_NUTZFL_NF_VON_NGF: 771.72,
    GB_TECHNIKFL_TF_VON_NGF: 12.44,
    GB_VERKEHRSFL_VF_VON_NGF: 220.18,
    GB_MIETFL_GESAMT: 1004.34,
    GB_MIETFL_1_BSB: 1001.06,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '18.12.20',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.21',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5462207,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747045,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747045',
    GB_SAP_GB_ID_PKF: '7400/747045/41',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2015,
    GB_BREITENGRAD_NOERDLICH: '53.493094',
    GB_LAENGENGRAD_OESTLICH: '10.011337',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: '01.10.15',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '30.06.22',
    GB_GB_OBJ_ID_PKT: 5450618,
    GB_WE_OBJ_ID_FKT: 1989461,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1903,
    GB_BREITENGRAD_NOERDLICH: '53.512202954',
    GB_LAENGENGRAD_OESTLICH: '10.019955563',
    GB_NETTOGRUNDFL_NGF: 1001.76,
    GB_NUTZFL_NF_VON_NGF: 693.3,
    GB_TECHNIKFL_TF_VON_NGF: 40.64,
    GB_VERKEHRSFL_VF_VON_NGF: 267.82,
    GB_MIETFL_GESAMT: 1001.76,
    GB_MIETFL_1_BSB: 687.17,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992569,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.512674',
    GB_LAENGENGRAD_OESTLICH: '10.020487',
    GB_NETTOGRUNDFL_NGF: 432.58,
    GB_NUTZFL_NF_VON_NGF: 306.45,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 126.13,
    GB_MIETFL_GESAMT: 432.58,
    GB_MIETFL_1_BSB: 432.58,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992573,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/6',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1956,
    GB_BREITENGRAD_NOERDLICH: '53.512614',
    GB_LAENGENGRAD_OESTLICH: '10.020132',
    GB_NETTOGRUNDFL_NGF: 412.23,
    GB_NUTZFL_NF_VON_NGF: 324.67,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 87.56,
    GB_MIETFL_GESAMT: 412.23,
    GB_MIETFL_1_BSB: 412.23,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992574,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/7',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.512544',
    GB_LAENGENGRAD_OESTLICH: '10.018818',
    GB_NETTOGRUNDFL_NGF: 196.94,
    GB_NUTZFL_NF_VON_NGF: 196.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 196.94,
    GB_MIETFL_1_BSB: 196.94,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992575,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.512529',
    GB_LAENGENGRAD_OESTLICH: '10.019328',
    GB_NETTOGRUNDFL_NGF: 196.84,
    GB_NUTZFL_NF_VON_NGF: 196.84,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 196.84,
    GB_MIETFL_1_BSB: 196.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992576,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: '53.512402564',
    GB_LAENGENGRAD_OESTLICH: '10.019926072',
    GB_NETTOGRUNDFL_NGF: 91.92,
    GB_NUTZFL_NF_VON_NGF: 91.92,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 91.92,
    GB_MIETFL_1_BSB: 91.92,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992577,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7092248,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.512917',
    GB_LAENGENGRAD_OESTLICH: '10.019511',
    GB_NETTOGRUNDFL_NGF: 489.6,
    GB_NUTZFL_NF_VON_NGF: 433.81,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 55.79,
    GB_MIETFL_GESAMT: 489.6,
    GB_MIETFL_1_BSB: 489.6,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.17',
    GB_GEBAEUDE_KLASSE_ALT: 'Container',
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 4352787,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747034,
    GB_SAP_GB_NUMMER: 41,
    GB_SAP_WE_ID_FKF: '7400/747034',
    GB_SAP_GB_ID_PKF: '7400/747034/41',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 115.4,
    GB_NUTZFL_NF_VON_NGF: 90.79,
    GB_TECHNIKFL_TF_VON_NGF: 3.22,
    GB_VERKEHRSFL_VF_VON_NGF: 21.39,
    GB_MIETFL_GESAMT: 115.4,
    GB_MIETFL_1_BSB: 115.4,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container_A_P',
    GB_GEBAEUDE_KLASSE_SEIT: '01.02.23',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.02.23',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7546649,
    GB_WE_OBJ_ID_FKT: 1989451,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793204,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793205,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1964,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 829.84,
    GB_NUTZFL_NF_VON_NGF: 643.5,
    GB_TECHNIKFL_TF_VON_NGF: 2.38,
    GB_VERKEHRSFL_VF_VON_NGF: 183.96,
    GB_MIETFL_GESAMT: 829.84,
    GB_MIETFL_1_BSB: 829.84,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '31.12.10',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992730,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/4',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: '53.511519834',
    GB_LAENGENGRAD_OESTLICH: '9.9915716989',
    GB_NETTOGRUNDFL_NGF: 3705.61,
    GB_NUTZFL_NF_VON_NGF: 2856.84,
    GB_TECHNIKFL_TF_VON_NGF: 75.47,
    GB_VERKEHRSFL_VF_VON_NGF: 773.3,
    GB_MIETFL_GESAMT: 3705.61,
    GB_MIETFL_1_BSB: 2580.27,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '2',
    GB_GEBAEUDE_KLASSE_SEIT: '16.12.22',
    GB_GEBAEUDE_KLASSE_ALT: '4',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Sanierung',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992731,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/5',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: '53.511698543',
    GB_LAENGENGRAD_OESTLICH: '9.9909279433',
    GB_NETTOGRUNDFL_NGF: 1795.5,
    GB_NUTZFL_NF_VON_NGF: 1569.82,
    GB_TECHNIKFL_TF_VON_NGF: 30.21,
    GB_VERKEHRSFL_VF_VON_NGF: 195.47,
    GB_MIETFL_GESAMT: 1795.5,
    GB_MIETFL_1_BSB: 1792.57,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.09.12',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992732,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747052,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747052',
    GB_SAP_GB_ID_PKF: '7400/747052/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: '53.510937715',
    GB_LAENGENGRAD_OESTLICH: '9.9915542418',
    GB_NETTOGRUNDFL_NGF: 1033.32,
    GB_NUTZFL_NF_VON_NGF: 818.47,
    GB_TECHNIKFL_TF_VON_NGF: 17.93,
    GB_VERKEHRSFL_VF_VON_NGF: 196.92,
    GB_MIETFL_GESAMT: 1033.32,
    GB_MIETFL_1_BSB: 1033.32,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '06.06.18',
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5431019,
    GB_WE_OBJ_ID_FKT: 1989468,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747071',
    GB_SAP_GB_ID_PKF: '7400/747071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 894.9,
    GB_NUTZFL_NF_VON_NGF: 824.46,
    GB_TECHNIKFL_TF_VON_NGF: 11.93,
    GB_VERKEHRSFL_VF_VON_NGF: 58.51,
    GB_MIETFL_GESAMT: 894.9,
    GB_MIETFL_1_BSB: 719.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6793231,
    GB_WE_OBJ_ID_FKT: 6793125,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.505831',
    GB_LAENGENGRAD_OESTLICH: '9.990309',
    GB_NETTOGRUNDFL_NGF: 701.85,
    GB_NUTZFL_NF_VON_NGF: 686.2,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.65,
    GB_MIETFL_GESAMT: 701.85,
    GB_MIETFL_1_BSB: 504.67,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992819,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/2',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.505592',
    GB_LAENGENGRAD_OESTLICH: '9.997366',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992820,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/3',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1927,
    GB_BREITENGRAD_NOERDLICH: '53.505586959',
    GB_LAENGENGRAD_OESTLICH: '9.9901626527',
    GB_NETTOGRUNDFL_NGF: 3597.42,
    GB_NUTZFL_NF_VON_NGF: 2461.63,
    GB_TECHNIKFL_TF_VON_NGF: 63.61,
    GB_VERKEHRSFL_VF_VON_NGF: 1072.18,
    GB_MIETFL_GESAMT: 3597.42,
    GB_MIETFL_1_BSB: 2998.89,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992821,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/4',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 1910,
    GB_BREITENGRAD_NOERDLICH: '53.505971',
    GB_LAENGENGRAD_OESTLICH: '9.990305',
    GB_NETTOGRUNDFL_NGF: 1314.92,
    GB_NUTZFL_NF_VON_NGF: 1030.73,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 284.19,
    GB_MIETFL_GESAMT: 1314.92,
    GB_MIETFL_1_BSB: 924.47,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992822,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1986,
    GB_BREITENGRAD_NOERDLICH: '53.506169',
    GB_LAENGENGRAD_OESTLICH: '9.990040',
    GB_NETTOGRUNDFL_NGF: 1573.96,
    GB_NUTZFL_NF_VON_NGF: 1357.45,
    GB_TECHNIKFL_TF_VON_NGF: 17.21,
    GB_VERKEHRSFL_VF_VON_NGF: 199.3,
    GB_MIETFL_GESAMT: 1573.96,
    GB_MIETFL_1_BSB: 1097.99,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '3',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992823,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/6',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: 2009,
    GB_BREITENGRAD_NOERDLICH: '53.506698520',
    GB_LAENGENGRAD_OESTLICH: '9.9902527708',
    GB_NETTOGRUNDFL_NGF: 1014.79,
    GB_NUTZFL_NF_VON_NGF: 767.08,
    GB_TECHNIKFL_TF_VON_NGF: 3.61,
    GB_VERKEHRSFL_VF_VON_NGF: 244.1,
    GB_MIETFL_GESAMT: 1014.79,
    GB_MIETFL_1_BSB: 1014.79,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '1',
    GB_GEBAEUDE_KLASSE_SEIT: '01.01.09',
    GB_GEBAEUDE_KLASSE_ALT: '3',
    GB_AENDERUNGSGRUND_GB_KLASSE: 'Neubau',
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992824,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747061,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747061',
    GB_SAP_GB_ID_PKF: '7400/747061/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.17',
    GB_GB_OBJ_ID_PKT: 6793228,
    GB_WE_OBJ_ID_FKT: 1989476,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 320.81,
    GB_NUTZFL_NF_VON_NGF: 213.44,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 107.37,
    GB_MIETFL_GESAMT: 320.81,
    GB_MIETFL_1_BSB: 320.81,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992464,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.42,
    GB_NUTZFL_NF_VON_NGF: 70.7,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.72,
    GB_MIETFL_GESAMT: 78.42,
    GB_MIETFL_1_BSB: 78.42,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992465,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/3',
    GB_SAP_GB_BEZEICHNUNG: 'Nebengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Nebengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 152.03,
    GB_NUTZFL_NF_VON_NGF: 146.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 5.09,
    GB_MIETFL_GESAMT: 152.03,
    GB_MIETFL_1_BSB: 152.03,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992466,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/4',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 201.77,
    GB_NUTZFL_NF_VON_NGF: 170.63,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 31.14,
    GB_MIETFL_GESAMT: 201.77,
    GB_MIETFL_1_BSB: 201.77,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992467,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/5',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.41,
    GB_NUTZFL_NF_VON_NGF: 70.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.63,
    GB_MIETFL_GESAMT: 78.41,
    GB_MIETFL_1_BSB: 78.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992468,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/6',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 205.21,
    GB_NUTZFL_NF_VON_NGF: 171.95,
    GB_TECHNIKFL_TF_VON_NGF: 4.35,
    GB_VERKEHRSFL_VF_VON_NGF: 28.91,
    GB_MIETFL_GESAMT: 205.21,
    GB_MIETFL_1_BSB: 205.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992469,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/7',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 206.21,
    GB_NUTZFL_NF_VON_NGF: 168.74,
    GB_TECHNIKFL_TF_VON_NGF: 6.42,
    GB_VERKEHRSFL_VF_VON_NGF: 31.05,
    GB_MIETFL_GESAMT: 206.21,
    GB_MIETFL_1_BSB: 206.21,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992470,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 280.66,
    GB_NUTZFL_NF_VON_NGF: 264.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 16.16,
    GB_MIETFL_GESAMT: 280.66,
    GB_MIETFL_1_BSB: 280.66,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992471,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/9',
    GB_SAP_GB_BEZEICHNUNG: 'Gymnastikhalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Gymnastikhalle',
    GB_BAUJAHR: 1966,
    GB_BREITENGRAD_NOERDLICH: '53.511116',
    GB_LAENGENGRAD_OESTLICH: '9.994666',
    GB_NETTOGRUNDFL_NGF: 303.41,
    GB_NUTZFL_NF_VON_NGF: 280.99,
    GB_TECHNIKFL_TF_VON_NGF: 14.09,
    GB_VERKEHRSFL_VF_VON_NGF: 8.33,
    GB_MIETFL_GESAMT: 303.41,
    GB_MIETFL_1_BSB: 303.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992472,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/10',
    GB_SAP_GB_BEZEICHNUNG: 'Wohnhaus',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Wohnhaus',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: '53.511152',
    GB_LAENGENGRAD_OESTLICH: '9.995091',
    GB_NETTOGRUNDFL_NGF: 267.28,
    GB_NUTZFL_NF_VON_NGF: 194.54,
    GB_TECHNIKFL_TF_VON_NGF: 54.35,
    GB_VERKEHRSFL_VF_VON_NGF: 18.39,
    GB_MIETFL_GESAMT: 267.28,
    GB_MIETFL_1_BSB: 155.71,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '4',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992473,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/11',
    GB_SAP_GB_BEZEICHNUNG: 'Garage',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Garage',
    GB_BAUJAHR: 2011,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 18.82,
    GB_NUTZFL_NF_VON_NGF: 18.82,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: 18.82,
    GB_MIETFL_1_BSB: 0.0,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Garagen',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 1992474,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/13',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.37,
    GB_NUTZFL_NF_VON_NGF: 70.65,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.72,
    GB_MIETFL_GESAMT: 78.37,
    GB_MIETFL_1_BSB: 78.37,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078250,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/14',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.5,
    GB_NUTZFL_NF_VON_NGF: 70.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.72,
    GB_MIETFL_GESAMT: 78.5,
    GB_MIETFL_1_BSB: 78.5,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078251,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/15',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.18,
    GB_NUTZFL_NF_VON_NGF: 70.46,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.72,
    GB_MIETFL_GESAMT: 78.18,
    GB_MIETFL_1_BSB: 78.18,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078252,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/16',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.22,
    GB_NUTZFL_NF_VON_NGF: 70.5,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.72,
    GB_MIETFL_GESAMT: 78.22,
    GB_MIETFL_1_BSB: 78.22,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078253,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 17,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/17',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.41,
    GB_NUTZFL_NF_VON_NGF: 70.78,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.63,
    GB_MIETFL_GESAMT: 78.41,
    GB_MIETFL_1_BSB: 78.41,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078254,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 18,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/18',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.25,
    GB_NUTZFL_NF_VON_NGF: 70.62,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.63,
    GB_MIETFL_GESAMT: 78.25,
    GB_MIETFL_1_BSB: 78.25,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078255,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 19,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/19',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.24,
    GB_NUTZFL_NF_VON_NGF: 70.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.63,
    GB_MIETFL_GESAMT: 78.24,
    GB_MIETFL_1_BSB: 78.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078256,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 20,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/20',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 78.24,
    GB_NUTZFL_NF_VON_NGF: 70.61,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 7.63,
    GB_MIETFL_GESAMT: 78.24,
    GB_MIETFL_1_BSB: 78.24,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: '5',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 2078257,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747020,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747020',
    GB_SAP_GB_ID_PKF: '7400/747020/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2012,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 311.95,
    GB_NUTZFL_NF_VON_NGF: 255.3,
    GB_TECHNIKFL_TF_VON_NGF: 11.58,
    GB_VERKEHRSFL_VF_VON_NGF: 45.07,
    GB_MIETFL_GESAMT: 311.95,
    GB_MIETFL_1_BSB: 311.95,
    GB_MIETFL_2_DRITTE: 0.0,
    GB_GEBAEUDE_KLASSE: 'Container',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 5892622,
    GB_WE_OBJ_ID_FKT: 1989438,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331869,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749057,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749057',
    GB_SAP_GB_ID_PKF: '7400/749057/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7549561,
    GB_WE_OBJ_ID_FKT: 7540052,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749018,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749018',
    GB_SAP_GB_ID_PKF: '7400/749018/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1995,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798789,
    GB_WE_OBJ_ID_FKT: 6793139,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749023,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749023',
    GB_SAP_GB_ID_PKF: '7400/749023/1',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6798793,
    GB_WE_OBJ_ID_FKT: 6793144,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749015,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749015',
    GB_SAP_GB_ID_PKF: '7400/749015/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: '01.01.18',
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6800179,
    GB_WE_OBJ_ID_FKT: 6793137,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743082,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743082',
    GB_SAP_GB_ID_PKF: '7400/743082/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7390140,
    GB_WE_OBJ_ID_FKT: 7390138,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749038,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749038',
    GB_SAP_GB_ID_PKF: '7400/749038/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7182679,
    GB_WE_OBJ_ID_FKT: 7182678,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749041,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749041',
    GB_SAP_GB_ID_PKF: '7400/749041/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189774,
    GB_WE_OBJ_ID_FKT: 7189767,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749055,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749055',
    GB_SAP_GB_ID_PKF: '7400/749055/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7549560,
    GB_WE_OBJ_ID_FKT: 7508675,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749042,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749042',
    GB_SAP_GB_ID_PKF: '7400/749042/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189775,
    GB_WE_OBJ_ID_FKT: 7189768,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749040,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749040',
    GB_SAP_GB_ID_PKF: '7400/749040/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189773,
    GB_WE_OBJ_ID_FKT: 7189766,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 7440147,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743071,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743071',
    GB_SAP_GB_ID_PKF: '7400/743071/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7138957,
    GB_WE_OBJ_ID_FKT: 7138955,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749037,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749037',
    GB_SAP_GB_ID_PKF: '7400/749037/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7180035,
    GB_WE_OBJ_ID_FKT: 7180034,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749048,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749048',
    GB_SAP_GB_ID_PKF: '7400/749048/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7252169,
    GB_WE_OBJ_ID_FKT: 7252167,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743076,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743076',
    GB_SAP_GB_ID_PKF: '7400/743076/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220152,
    GB_WE_OBJ_ID_FKT: 7220143,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743077',
    GB_SAP_GB_ID_PKF: '7400/743077/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1961,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220153,
    GB_WE_OBJ_ID_FKT: 7220144,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743072,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743072',
    GB_SAP_GB_ID_PKF: '7400/743072/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1955,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220149,
    GB_WE_OBJ_ID_FKT: 7220139,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743080',
    GB_SAP_GB_ID_PKF: '7400/743080/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 2018,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220156,
    GB_WE_OBJ_ID_FKT: 7220147,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743074,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743074',
    GB_SAP_GB_ID_PKF: '7400/743074/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220151,
    GB_WE_OBJ_ID_FKT: 7220141,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743073,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743073',
    GB_SAP_GB_ID_PKF: '7400/743073/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220150,
    GB_WE_OBJ_ID_FKT: 7220140,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743078',
    GB_SAP_GB_ID_PKF: '7400/743078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1965,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220154,
    GB_WE_OBJ_ID_FKT: 7220145,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743079',
    GB_SAP_GB_ID_PKF: '7400/743079/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Gymnastikhalle',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1960,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7220155,
    GB_WE_OBJ_ID_FKT: 7220146,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749056,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749056',
    GB_SAP_GB_ID_PKF: '7400/749056/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7531128,
    GB_WE_OBJ_ID_FKT: 7531127,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749047,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749047',
    GB_SAP_GB_ID_PKF: '7400/749047/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7239237,
    GB_WE_OBJ_ID_FKT: 7239236,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749052,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749052',
    GB_SAP_GB_ID_PKF: '7400/749052/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7406179,
    GB_WE_OBJ_ID_FKT: 7406178,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749054,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749054',
    GB_SAP_GB_ID_PKF: '7400/749054/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.574659791',
    GB_LAENGENGRAD_OESTLICH: '9.8874026294',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7507038,
    GB_WE_OBJ_ID_FKT: 7507036,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749033,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749033',
    GB_SAP_GB_ID_PKF: '7400/749033/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7097074,
    GB_WE_OBJ_ID_FKT: 7097072,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749039,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749039',
    GB_SAP_GB_ID_PKF: '7400/749039/1',
    GB_SAP_GB_BEZEICHNUNG: 'B?rogeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'B?rogeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189772,
    GB_WE_OBJ_ID_FKT: 7189765,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749049,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749049',
    GB_SAP_GB_ID_PKF: '7400/749049/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7252170,
    GB_WE_OBJ_ID_FKT: 7252168,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749044,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749044',
    GB_SAP_GB_ID_PKF: '7400/749044/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189777,
    GB_WE_OBJ_ID_FKT: 7189770,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749046,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749046',
    GB_SAP_GB_ID_PKF: '7400/749046/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: 1900,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7237466,
    GB_WE_OBJ_ID_FKT: 7237463,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749043,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749043',
    GB_SAP_GB_ID_PKF: '7400/749043/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7189776,
    GB_WE_OBJ_ID_FKT: 7189769,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 7440147,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/1',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.472527273',
    GB_LAENGENGRAD_OESTLICH: '9.8446655364',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7229127,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/2',
    GB_SAP_GB_BEZEICHNUNG: 'Fachklassengeb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.472030',
    GB_LAENGENGRAD_OESTLICH: '9.844812',
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489149,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471648',
    GB_LAENGENGRAD_OESTLICH: '9.844469',
    GB_NETTOGRUNDFL_NGF: 716.6,
    GB_NUTZFL_NF_VON_NGF: 615.74,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 100.86,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489150,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471483026',
    GB_LAENGENGRAD_OESTLICH: '9.8448101679',
    GB_NETTOGRUNDFL_NGF: 205.57,
    GB_NUTZFL_NF_VON_NGF: 201.05,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 4.52,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489151,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471486',
    GB_LAENGENGRAD_OESTLICH: '9.845212',
    GB_NETTOGRUNDFL_NGF: 862.12,
    GB_NUTZFL_NF_VON_NGF: 633.37,
    GB_TECHNIKFL_TF_VON_NGF: 98.78,
    GB_VERKEHRSFL_VF_VON_NGF: 129.97,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489152,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471410',
    GB_LAENGENGRAD_OESTLICH: '9.845739',
    GB_NETTOGRUNDFL_NGF: 174.45,
    GB_NUTZFL_NF_VON_NGF: 158.99,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 15.46,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489153,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachklassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachklassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471863',
    GB_LAENGENGRAD_OESTLICH: '9.845158',
    GB_NETTOGRUNDFL_NGF: 664.34,
    GB_NUTZFL_NF_VON_NGF: 574.42,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 89.92,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489154,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/8',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471567',
    GB_LAENGENGRAD_OESTLICH: '9.846172',
    GB_NETTOGRUNDFL_NGF: 212.41,
    GB_NUTZFL_NF_VON_NGF: 186.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489155,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/9',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471779',
    GB_LAENGENGRAD_OESTLICH: '9.84622',
    GB_NETTOGRUNDFL_NGF: 212.41,
    GB_NUTZFL_NF_VON_NGF: 186.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489156,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/10',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.471985',
    GB_LAENGENGRAD_OESTLICH: '9.846263',
    GB_NETTOGRUNDFL_NGF: 212.41,
    GB_NUTZFL_NF_VON_NGF: 186.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489157,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/11',
    GB_SAP_GB_BEZEICHNUNG: 'Pavillon',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pavillon',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: '53.472206',
    GB_LAENGENGRAD_OESTLICH: '9.846300',
    GB_NETTOGRUNDFL_NGF: 212.41,
    GB_NUTZFL_NF_VON_NGF: 186.9,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 25.51,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489158,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/14',
    GB_SAP_GB_BEZEICHNUNG: 'Mischgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mischgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489159,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747077,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747077',
    GB_SAP_GB_ID_PKF: '7400/747077/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7489160,
    GB_WE_OBJ_ID_FKT: 7229122,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747079,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747079',
    GB_SAP_GB_ID_PKF: '7400/747079/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7229129,
    GB_WE_OBJ_ID_FKT: 7229124,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747079,
    GB_SAP_GB_NUMMER: 12,
    GB_SAP_WE_ID_FKF: '7400/747079',
    GB_SAP_GB_ID_PKF: '7400/747079/12',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: 'Hamburger Klassenhaus',
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392697,
    GB_WE_OBJ_ID_FKT: 7229124,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747079,
    GB_SAP_GB_NUMMER: 13,
    GB_SAP_WE_ID_FKF: '7400/747079',
    GB_SAP_GB_ID_PKF: '7400/747079/13',
    GB_SAP_GB_BEZEICHNUNG: 'Mischgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mischgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392698,
    GB_WE_OBJ_ID_FKT: 7229124,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747079,
    GB_SAP_GB_NUMMER: 14,
    GB_SAP_WE_ID_FKF: '7400/747079',
    GB_SAP_GB_ID_PKF: '7400/747079/14',
    GB_SAP_GB_BEZEICHNUNG: 'Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392699,
    GB_WE_OBJ_ID_FKT: 7229124,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747078,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747078',
    GB_SAP_GB_ID_PKF: '7400/747078/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7229128,
    GB_WE_OBJ_ID_FKT: 7229123,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748003,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/748003',
    GB_SAP_GB_ID_PKF: '7400/748003/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7439706,
    GB_WE_OBJ_ID_FKT: 7439705,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/1',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 541.61,
    GB_NUTZFL_NF_VON_NGF: 457.02,
    GB_TECHNIKFL_TF_VON_NGF: 3.86,
    GB_VERKEHRSFL_VF_VON_NGF: 80.73,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200607,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/2',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1985,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 209.67,
    GB_NUTZFL_NF_VON_NGF: 165.94,
    GB_TECHNIKFL_TF_VON_NGF: 7.51,
    GB_VERKEHRSFL_VF_VON_NGF: 36.22,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200608,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/3',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200609,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/4',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 208.28,
    GB_NUTZFL_NF_VON_NGF: 165.94,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 42.34,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200610,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/5',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Sporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Sporthalle',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 386.24,
    GB_NUTZFL_NF_VON_NGF: 349.33,
    GB_TECHNIKFL_TF_VON_NGF: 9.22,
    GB_VERKEHRSFL_VF_VON_NGF: 27.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200611,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/6',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 448.77,
    GB_NUTZFL_NF_VON_NGF: 368.3,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: 80.47,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200612,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/7',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 379.81,
    GB_NUTZFL_NF_VON_NGF: 306.82,
    GB_TECHNIKFL_TF_VON_NGF: 20.3,
    GB_VERKEHRSFL_VF_VON_NGF: 52.69,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200613,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/8',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Pausenmehrzweckhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausenmehrzweckhalle',
    GB_BAUJAHR: 1994,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 464.89,
    GB_NUTZFL_NF_VON_NGF: 370.55,
    GB_TECHNIKFL_TF_VON_NGF: 15.13,
    GB_VERKEHRSFL_VF_VON_NGF: 79.21,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200614,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747011,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747011',
    GB_SAP_GB_ID_PKF: '7400/747011/9',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: 1977,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 232.05,
    GB_NUTZFL_NF_VON_NGF: 178.91,
    GB_TECHNIKFL_TF_VON_NGF: 4.51,
    GB_VERKEHRSFL_VF_VON_NGF: 48.63,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '13.05.22',
    GB_GB_OBJ_ID_PKT: 7200615,
    GB_WE_OBJ_ID_FKT: 7200606,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5895163,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748001,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/748001',
    GB_SAP_GB_ID_PKF: '7400/748001/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: 1.0,
    GB_NUTZFL_NF_VON_NGF: 1.0,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7117146,
    GB_WE_OBJ_ID_FKT: 7117145,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7244385,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748002,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/748002',
    GB_SAP_GB_ID_PKF: '7400/748002/1',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa- und Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa- und Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7390141,
    GB_WE_OBJ_ID_FKT: 7390139,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748002,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/748002',
    GB_SAP_GB_ID_PKF: '7400/748002/2',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7390142,
    GB_WE_OBJ_ID_FKT: 7390139,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748002,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/748002',
    GB_SAP_GB_ID_PKF: '7400/748002/3',
    GB_SAP_GB_BEZEICHNUNG: 'Einfeldsporthalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Einfeldsporthalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7418233,
    GB_WE_OBJ_ID_FKT: 7390139,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 748002,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/748002',
    GB_SAP_GB_ID_PKF: '7400/748002/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7418234,
    GB_WE_OBJ_ID_FKT: 7390139,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747080,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747080',
    GB_SAP_GB_ID_PKF: '7400/747080/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: 'Schulgeb?ude',
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7250141,
    GB_WE_OBJ_ID_FKT: 7250140,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331814,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7229126,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392684,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/3',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392685,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/4',
    GB_SAP_GB_BEZEICHNUNG: 'Pausenhalle',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Pausenhalle',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392686,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/5',
    GB_SAP_GB_BEZEICHNUNG: 'Verwaltungsgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Verwaltungsgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392687,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/6',
    GB_SAP_GB_BEZEICHNUNG: 'Mensa',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mensa',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392688,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/7',
    GB_SAP_GB_BEZEICHNUNG: 'Fachgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Fachgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392689,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/8',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392690,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/9',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392691,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/10',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392692,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 11,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/11',
    GB_SAP_GB_BEZEICHNUNG: 'Klassengeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Klassengeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392693,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 15,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/15',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392694,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 16,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/16',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392695,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747076,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747076',
    GB_SAP_GB_ID_PKF: '7400/747076/40',
    GB_SAP_GB_BEZEICHNUNG: 'Mobile Klassenr?ume',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7392696,
    GB_WE_OBJ_ID_FKT: 7229121,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 747081,
    GB_SAP_GB_NUMMER: 40,
    GB_SAP_WE_ID_FKF: '7400/747081',
    GB_SAP_GB_ID_PKF: '7400/747081/40',
    GB_SAP_GB_BEZEICHNUNG: 'BEENDET | Mobile Klassenr?ume',
    GB_GEBAEUDETYP: 'Mobilklasse',
    GB_GEBAEUDEART: 'Mobile Klassenr?ume',
    GB_BAUJAHR: 2021,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: 'BEENDET',
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'N',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.01.23',
    GB_GB_OBJ_ID_PKT: 7371387,
    GB_WE_OBJ_ID_FKT: 7371385,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 5331870,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743088,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743088',
    GB_SAP_GB_ID_PKF: '7400/743088/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437719,
    GB_WE_OBJ_ID_FKT: 7437708,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7062824,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437710,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/3',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437711,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/4',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437712,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/5',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437713,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 6,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/6',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437714,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 7,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/7',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437715,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 8,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/8',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437716,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 9,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/9',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437717,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743070,
    GB_SAP_GB_NUMMER: 10,
    GB_SAP_WE_ID_FKF: '7400/743070',
    GB_SAP_GB_ID_PKF: '7400/743070/10',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437718,
    GB_WE_OBJ_ID_FKT: 7062816,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749045,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749045',
    GB_SAP_GB_ID_PKF: '7400/749045/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7203213,
    GB_WE_OBJ_ID_FKT: 7203212,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743090,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743090',
    GB_SAP_GB_ID_PKF: '7400/743090/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7439991,
    GB_WE_OBJ_ID_FKT: 7439990,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 7227538,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749036,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749036',
    GB_SAP_GB_ID_PKF: '7400/749036/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7142909,
    GB_WE_OBJ_ID_FKT: 7142908,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749035,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749035',
    GB_SAP_GB_ID_PKF: '7400/749035/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7138958,
    GB_WE_OBJ_ID_FKT: 7138956,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743089,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743089',
    GB_SAP_GB_ID_PKF: '7400/743089/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437720,
    GB_WE_OBJ_ID_FKT: 7437709,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743089,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/743089',
    GB_SAP_GB_ID_PKF: '7400/743089/4',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437721,
    GB_WE_OBJ_ID_FKT: 7437709,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743089,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/743089',
    GB_SAP_GB_ID_PKF: '7400/743089/5',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437722,
    GB_WE_OBJ_ID_FKT: 7437709,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743067,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743067',
    GB_SAP_GB_ID_PKF: '7400/743067/1',
    GB_SAP_GB_BEZEICHNUNG: 'Technikgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Technikgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6984930,
    GB_WE_OBJ_ID_FKT: 6793122,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743067,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743067',
    GB_SAP_GB_ID_PKF: '7400/743067/2',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6984931,
    GB_WE_OBJ_ID_FKT: 6793122,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743068,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743068',
    GB_SAP_GB_ID_PKF: '7400/743068/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6772863,
    GB_WE_OBJ_ID_FKT: 6772861,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 749034,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/749034',
    GB_SAP_GB_ID_PKF: '7400/749034/1',
    GB_SAP_GB_BEZEICHNUNG: 'Geb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Geb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7103379,
    GB_WE_OBJ_ID_FKT: 7103378,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 5423111,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743087,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743087',
    GB_SAP_GB_ID_PKF: '7400/743087/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437351,
    GB_WE_OBJ_ID_FKT: 7437345,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743087,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743087',
    GB_SAP_GB_ID_PKF: '7400/743087/2',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437352,
    GB_WE_OBJ_ID_FKT: 7437345,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743069,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743069',
    GB_SAP_GB_ID_PKF: '7400/743069/1',
    GB_SAP_GB_BEZEICHNUNG: 'Hauptgeb?ude',
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: 'Hauptgeb?ude',
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 6967487,
    GB_WE_OBJ_ID_FKT: 6960570,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743086,
    GB_SAP_GB_NUMMER: 1,
    GB_SAP_WE_ID_FKF: '7400/743086',
    GB_SAP_GB_ID_PKF: '7400/743086/1',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437346,
    GB_WE_OBJ_ID_FKT: 7437344,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743086,
    GB_SAP_GB_NUMMER: 2,
    GB_SAP_WE_ID_FKF: '7400/743086',
    GB_SAP_GB_ID_PKF: '7400/743086/2',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437347,
    GB_WE_OBJ_ID_FKT: 7437344,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743086,
    GB_SAP_GB_NUMMER: 3,
    GB_SAP_WE_ID_FKF: '7400/743086',
    GB_SAP_GB_ID_PKF: '7400/743086/3',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437348,
    GB_WE_OBJ_ID_FKT: 7437344,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743086,
    GB_SAP_GB_NUMMER: 4,
    GB_SAP_WE_ID_FKF: '7400/743086',
    GB_SAP_GB_ID_PKF: '7400/743086/4',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437349,
    GB_WE_OBJ_ID_FKT: 7437344,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: 7400,
    GB_SAP_WE_NUMMER: 743086,
    GB_SAP_GB_NUMMER: 5,
    GB_SAP_WE_ID_FKF: '7400/743086',
    GB_SAP_GB_ID_PKF: '7400/743086/5',
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: 'J',
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: '31.12.99',
    GB_GB_OBJ_ID_PKT: 7437350,
    GB_WE_OBJ_ID_FKT: 7437344,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749010',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793134,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749058',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7545076,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749008',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793132,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749007',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793131,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/747069',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 5914309,
    GB_RGN_OBJ_ID_FKT: 1799942,
    GB_OM_OBJ_ID_FKT: 7042135,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749009',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793133,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749005',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793130,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7000/704076',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 1989271,
    GB_RGN_OBJ_ID_FKT: 1799941,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749003',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 6793129,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749051',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7375989,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749050',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7375988,
    GB_RGN_OBJ_ID_FKT: 6791231,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/743075',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7220142,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749059',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7545077,
    GB_RGN_OBJ_ID_FKT: 5418905,
    GB_OM_OBJ_ID_FKT: 6783127,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/743091',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7545215,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: 7227538,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7400/749053',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 7499346,
    GB_RGN_OBJ_ID_FKT: 6993694,
    GB_OM_OBJ_ID_FKT: null,
  },
  {
    GO_TYP: 'GB',
    GB_SAP_BUCHUNGSKREIS: null,
    GB_SAP_WE_NUMMER: null,
    GB_SAP_GB_NUMMER: null,
    GB_SAP_WE_ID_FKF: '7000/709999',
    GB_SAP_GB_ID_PKF: null,
    GB_SAP_GB_BEZEICHNUNG: null,
    GB_GEBAEUDETYP: null,
    GB_GEBAEUDEART: null,
    GB_BAUJAHR: null,
    GB_BREITENGRAD_NOERDLICH: null,
    GB_LAENGENGRAD_OESTLICH: null,
    GB_NETTOGRUNDFL_NGF: null,
    GB_NUTZFL_NF_VON_NGF: null,
    GB_TECHNIKFL_TF_VON_NGF: null,
    GB_VERKEHRSFL_VF_VON_NGF: null,
    GB_MIETFL_GESAMT: null,
    GB_MIETFL_1_BSB: null,
    GB_MIETFL_2_DRITTE: null,
    GB_GEBAEUDE_KLASSE: null,
    GB_GEBAEUDE_KLASSE_SEIT: null,
    GB_GEBAEUDE_KLASSE_ALT: null,
    GB_AENDERUNGSGRUND_GB_KLASSE: null,
    GB_ANZ_GYM_FELD: null,
    GB_ANZ_1_FELD: null,
    GB_ANZ_2_FELD: null,
    GB_ANZ_3_FELD: null,
    GB_GUELTIG_J_N: null,
    GB_GUELTIG_VON: null,
    GB_GUELTIG_BIS: null,
    GB_GB_OBJ_ID_PKT: null,
    GB_WE_OBJ_ID_FKT: 5455417,
    GB_RGN_OBJ_ID_FKT: null,
    GB_OM_OBJ_ID_FKT: null,
  },
];

export const getGebaeudeByID = (id: string) => {
  return C_V_STM_GB.find((value) => value.GB_SAP_GB_ID_PKF === id);
};
